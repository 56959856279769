import { object, string,  } from 'yup';

export const loginValidationSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
  method: string()
});

export const requestPasswordResetValidationSchema = object().shape({
  email: string().email().required(),
  captcha: string().required(),
});


export const resetPasswordValidationSchema = object().shape({
  password: string().required(),
  captcha: string().required(),
  token: string().required()
});
