import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../enums/folder-type-enums";
import { IFolderType } from "../folders/IFolderType";
import base_types from "./base_types";

export const nashville_types: { [key: string]: IFolderType } = {
  nashville_root: {
    ...base_types.root,
    name: FOLDER_TYPE.nashville_root,
    display_name: "Nashville Root",
    acceptable_folder_types: [FOLDER_TYPE.nashville_grouping_folder],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      }
    },
    preview: {
      aspect_ratio: 1.78,
      type: PREVIEW_TYPE.IMAGE,
      path: "/example_preview.png"
    }
  },
  nashville_grouping_folder: {
    ...base_types.grouping_folder,
    name: FOLDER_TYPE.nashville_grouping_folder,
    display_name: "Nashville Grouping Folder",
    acceptable_folder_types: [FOLDER_TYPE.nashville_donor],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      }
    }
  },
  nashville_donor: {
    ...base_types.donor,
    name: FOLDER_TYPE.nashville_donor,
    display_name: "Nashville Donor",
    field_groups: {},
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: "",
        read_only: true
      },
      sorting_order: {
        type: "text",
        title: "Sorting Order",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: ""
      },
      preview: {
        aspect_ratio: 1.78,
        type: "image",
        path: "/example_donor_preview.png"
      },
    },
    csv_options: {
      download: false,
      upload: false,
      include_in_csv: true,
      fields: ["name", "sorting_order"]
    }
  }
};
