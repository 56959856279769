import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { wysiwyg } from "../standardized_fields";
import { transitions, alpineFonts, quillFontSizes, wysiwygSettings } from "./alpine_settings";

export const alpine_story_with_single_image: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.alpine_story_with_single_image,
  display_name: "Story with Single Image",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    story: {
      title: "Story with Single Image",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "QR Code",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "general"
    },
    headline: {
      type: "hybrid_text_input",
      title: "Headline",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            bold: true,
            font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
            size: "40px",
            lineHeight: 1.05
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: {
        ...wysiwygSettings
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_header"
      },
      field_group: "story"
    },
    subhead: {
      type: "hybrid_text_input",
      title: "Sub Head",
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            bold: true,
            font: fonts[FontsFamilies.RECTOR][FontVariants.MEDIUM],
            size: "80px",
            lineHeight: 1.05
          },
          ops: []
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: {
        ...wysiwygSettings
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_subhead"
      },
      field_group: "story"
    },
    body: {
      type: "hybrid_text_input",
      title: "Body Text",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
            size: "30px",
            lineHeight: 1.05
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_body"
      },
      field_group: "story"
    },
    image_1: {
      title: "Image 1",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 1080,
        height: 960
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.125
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_single_image"
      },
      field_group: "story"
    },
    background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_background"
      },
      field_group: "background"
    },
    color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "#937496",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_color_overlay"
      },
      field_group: "background"
    },
    //Logo / qrCode
    switch_to_logo: {
      type: "select",
      title: "Logo or QR Code",
      field_group: "qr",
      default: "none",
      options: [
        { title: "None", value: "none" },
        { title: "Logo", value: "logo" },
        { title: "QR Code", value: "qrCode" },
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "image_with_caption_switch_to_logo"
      }
    },
    mirror_logo_qr: {
      type: "bool",
      title: "Mirror Logo/QR",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: false,
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_single_image_mirror_logo_qr"
      }
    },
    call_to_action_header: {
      title: "Call To Action Header",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: alpineFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
            size: "48px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_mirror_logo_qr"
      }
    },
    call_to_action_body: {
      title: "Call to Action Body",
      field_group: "qr",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: alpineFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
            size: "32px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_call_to_action_body"
      }
    },
    qr_link: {
      type: "text",
      title: "QR Code Link",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      show_char_count: false,
      field_group: "qr",
      validation: {
        rules: [
          //{ name: "isRequiredExclusiveBoolean", otherFieldName: 'switch_to_logo', message: "Please enter a valid link. I.E: https://www.google.com" },
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_qr_link"
      }
    },
    qr_color: {
      type: "color",
      title: "QR Color",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      default: "#ffffff",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_with_media_1_qr_color"
      }
    },
    logo: {
      title: "Default Logo",
      isVisible: (values) => values.switch_to_logo === 'logo',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_with_caption_logo"
      }
    },
    qr_background_color: {
      type: "color",
      title: "QR / Logo Background Color",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: "#0000007F",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_with_media_1_qr_background_color"
      }
    }
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.AlpineContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "/images/folder_types/alpine/alpine_story_with_single_image.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
