import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Modal, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import {useDispatch, useSelector} from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { addSubmitListViewCallback } from "../../services/unsavedModalService";
import { PDG_COLORS } from "../../shared-global/enums/ui-enums";
import { strToUpper } from "../../shared/utils/generalUtils";
import DynamicForm from "../form/DynamicForm";
import AlertNotification from "../layout/AlertNotification";
import { XXLT_GRAY } from "../layout/LayoutConstants";
import ResourceContentLibraryAssociated from "../resource/ResourceContentLibraryAssociated";
import TextWithHighlights from "../tree/TextWithHighlights";
import Spaner from "../utils/Spaner";
import FilterActions from "./FilterActions";
import { SortableListMemo } from "./SortableListMemo";
import { SortableListMemoResource } from "./SortableListMemoResource";
import MultiFolderActions from "./MultiFolderActions";
import {IState} from "../../interfaces/main-state.interface";

let timeoutHandler = null;
let timeoutHandler2 = null;
const MODE_SELECT = 0;
const MODE_EDIT = 1;
const MODE_SORT = 2;
const SORT_RANK = "rank";
const SORT_OTHER = "other";
const DYNAMIC = "dynamic";

const WIDTH_EXTRA_SPACE_X_2 = 1200;
const WIDTH_EXTRA_SPACE_X_3 = 1500;
const MIN_WIDTH_FULL_RESOLUTION = 670;
const MIN_WIDTH_MEDIUM_RESOLUTION = 300;

const lt_gray_border = `.5px solid ${XXLT_GRAY}`;

const getCellWidthProportionCalculation = (base, tableWidth) => {
  let proportion = 1;
  if (tableWidth > WIDTH_EXTRA_SPACE_X_3) {
    proportion = 2; // 160%
  } else if (tableWidth > WIDTH_EXTRA_SPACE_X_2) {
    proportion = 1.6; // 130%
  }
  return base * proportion;
};

const countCellWith = (columns) => {
  return columns.reduce(
    (accumulator, current) => {
      const type = _.get(current, "type", _.get(current, "field", "null"));
      switch (type) {
        case "actions":
        case "integer":
        case "cycle_time":
        case "file":
        case "sorting_options":
        case "bool":
        case "hamburger":
        case "list_view_selected":
          accumulator.small += 1;
          break;
        case "select":
        case "textarea":
        case "text":
        case "type":
        case "wysiwyg":
          accumulator.large += 5;
          break;
        default:
          accumulator.medium += 1;
          break;
      }
      return accumulator;
    },
    { large: 0, medium: 0, small: 0 }
  );
};

const getCellBaseLongWidth = (width, columnCountWith) => {
  const widthSmallItems = 60 * columnCountWith.small;
  const widthMediumAndLargeItems =
    (width - widthSmallItems) /
    (columnCountWith.large + columnCountWith.medium);
  return widthMediumAndLargeItems || 150;
};

const getCellWidth = (type, tableWidth, columns = null) => {
  switch (type) {
    case "actions":
    case "integer":
    case "cycle_time":
    case "file":
    case "sorting_options":
      return 100;
    case "select":
      return 300;
    case "icon":
    case "bool":
      return 50;
    case "textarea":
    case "text":
    case "date":
    case "wysiwyg":
      if (columns) {
        const columnCountWith = countCellWith(columns);
        const baseWidth = getCellBaseLongWidth(tableWidth, columnCountWith);
        return baseWidth;
      }
      return getCellWidthProportionCalculation(150, tableWidth);
    case "associations":
      return getCellWidthProportionCalculation(150, tableWidth);
    case "text-lg":
      return 200;
    case "type":
      return 300;
    case "updatedBy":
      return 150;
    case "updatedAt":
      return getCellWidthProportionCalculation(100, tableWidth);
    default:
      return 0;
  }
};

const getFilterFunction = (field) => {
  switch (field) {
    case "updatedBy":
      return (value, record) => {
        if (
          !value ||
          value.length === 0 ||
          !Array.isArray(value) ||
          record.new
        ) {
          return true;
        }
        if (value.includes("(empty)")) {
          return !record.updatedBy;
        } else {
          return value.includes(record.updatedBy);
        }
      };

    default:
      return () => {};
  }
};

const styles = {
  cell: {
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  cellFlex: {
    display: "flex",
    textOverflow: "ellipsis",
  },
  cellRichTextDisabled: {
    color: "rgba(0, 0, 0, 0.65)",
    backgroundColor: "#f5f5f5",
    border: "1px solid #d9d9d9",
    borderRadius: "2px",
    cursor: "not-allowed",
    opacity: 1,
    padding: "4px 11px",
    minHeight: 32,
  },
  rowGrow: {
    flexGrow: 2,
  },
  rowGrowPadding: {
    paddingLeft: 3,
  },
  rowRight: {
    justifyContent: "right",
    paddingRight: 15,
  },
  checkboxCell: {
    marginTop: 10,
    padding: 0,
    minWidth: 50,
    width: 50,
  },
  iconCell: {
    display: "flex",
    padding: 0,
    width: 50,
    minWidth: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  cellEditMode: {
    marginTop: 10,
    padding: 0,
  },

  beingDragged: {
    background: "#2699FB",
    color: "white",
    "& svg": {
      color: "white",
    },
  },
  smallButton: {
    width: 30,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    marginRight: 5,
    marginLeft: 5,
  },
  listContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& #actions": {
      display: "flex",
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      "& #multi-action": {
        flex: 1,
      },
      "& #top-buttons": {
        textAlign: "right",
        flex: 1,
        height: '40px'
      },
    },
    "& #list": {
      width: "100%",
      marginTop: 20,
      overflowX: "scroll",
      "& .list_new": {
        background: "#1890ff94",
      },
    },
    "& #bottom-buttons": {
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      textAlign: "right",
      marginTop: 20,
    },
    "& #headers": {
      background: "#F9F9F9",
      color: "#666666",
      width: "100%",
    },
    "& .list-container": {
      height: ({ listContainerHeight }) => listContainerHeight,
    },
    "& .ReactVirtualized__Grid ReactVirtualized__List list-container": {
      // width: '100%',
      "& .ReactVirtualized__Grid__innerScrollContainer": {
        // width: ({ columnsWidthSum }) => [[columnsWidthSum], '!important'],
        // maxWidth: '100%!important',
      },
    },
  },
};

const getCellTypeSafe = (column) => {
  let cellType;
  if (column.type) {
    cellType = column.type;
  } else if (column.field) {
    cellType = column.field;
  } else {
    cellType = "file";
  }
  return cellType;
};

const useStyles = createUseStyles(styles);
const getWidthFromColumns = (listColumns, componentWidth) => {
  let width = 25; // Buffer scrollbar etc
  listColumns.forEach((column) => {
    const columnType = getCellTypeSafe(column);
    const cellWidth = getCellWidth(columnType, componentWidth);

    width += cellWidth;
  });

  return width;
};

const hamburgerColumn = {
  type: "icon",
  sort: false,
  editable: false,
  title: "",
  field: "hamburger",
};

const actionsColumn = {
  sort: false,
  editable: false,
  title: "",
  field: "actions",
  type: "actions",
};

const ResourceListView = (props) => {
  const wrappingDivRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const client_options = useSelector((state: IState) => state.client_options);
  const [unsavedChanges, _setUnsavedChanges] = useState(false);
  const [folderType, setFolderType] = useState();
  const [associationsModalOpen, setAssociationsModalOpen] = useState(false);
  const [
    selectedResourceAssociationsModal,
    setSelectedResourceAssociationsModal,
  ] = useState(null);
  const unsavedChangesRef = useRef(unsavedChanges);
  const setUnsavedChanges = (data) => {
    unsavedChangesRef.current = data;
    _setUnsavedChanges(data);
  };
  const [showDeleteConfirmationModal, setShowDeleteConfirmModal] =
    useState(false);
  const [unsavedChangedItems, _setUnsavedChangedItems] = useState([]);
  const unsavedChangedItemsRef = useRef(unsavedChangedItems);
  const setUnsavedChangedItems = (data) => {
    unsavedChangedItemsRef.current = data;
    _setUnsavedChangedItems(data);
  };
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [outerWidth, _setOuterWidth] = useState(575);
  const outerWithRef = useRef(outerWidth);
  const setOuterWidth = (data) => {
    outerWithRef.current = data;
    _setOuterWidth(data);
  };
  const [componentWidth, setComponentWidth] = useState(575);
  const [items, _setItems] = useState(props.listItems);
  const itemsRef = useRef(items);
  const setItems = (data) => {
    itemsRef.current = data;
    _setItems(data);
  };
  const [mode, setMode] = useState(MODE_SELECT);
  const [sortMode, setSortMode] = useState(
    props.sorting === "rank" ? SORT_RANK : SORT_OTHER
  );
  const [acceptableFolderTypes, _setAcceptableFolderTypes] = useState([]);
  const acceptableFolderTypesRef = useRef(acceptableFolderTypes);
  const setAcceptableFolderTypes = (data) => {
    acceptableFolderTypesRef.current = data;
    _setAcceptableFolderTypes(data);
  };
  const [newAddedItemIndex, setNewAddedItemIndex] = useState(0);
  const [editButtonDisabled, setEditButtonDisabled] = useState(false);
  const [forceUpdateInt, setForceUpdateInt] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [listContainerHeight, setListContainerHeight] = useState(
    props.listHeight
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    field: props.sorting,
    direction: "asc",
  });
  const [addedItems, _setAddedItems] = useState([]);
  const addedItemsRef = useRef(addedItems);
  const setAddedItems = (data) => {
    addedItemsRef.current = data;
    _setAddedItems(data);
  };
  // In this array are saved all initial folder ids in the init of this session (list loaded first time)
  const [allFolderIdsInThisSession, setAllFolderIdsInThisSession] =
    useState(null);
  const [allItems, setAllItems] = useState(props.listItems);
  const [filteredItems, setFilteredItems] = useState(allItems);
  const [columns, setColumns] = useState([
    hamburgerColumn,
    ...props.listColumns,
  ]);
  const [columnsExtraClass, setColumnsExtraClass] = useState({});
  const [columnsWidthSum, setColumnsWidthSum] = useState(
    getWidthFromColumns(columns, outerWithRef.current)
  );
  const [styleValues, setStyleValues] = useState({
    listContainerHeight,
    columnsWidthSum,
  });

  // filters
  const [columnFilters, setColumnFilters] = useState({});
  const [initialColumnFilters, setInitialColumnFilters] = useState({});
  const [functionFilters, setFunctionFilters] = useState({});

  // search
  const [searchString, setSearchString] = useState(null);

  const [showAddButton, setShowAddButton] = useState(true);

  const [focusInputKey, setFocusInputKey] = useState("");

  const classes = useStyles(styleValues);

  const getCellValueSafe = (cellType, cellValue) => {
    switch (cellType) {
      case "wysiwyg":
        const textRichArray = _.get(cellValue, "ops", []);
        const textRich = textRichArray.reduce((acc, curr) => {
          const text = acc + _.get(curr, "insert", "");
          return text;
        }, "");
        return textRich;
      default:
        return cellValue;
    }
  };

  const renderReadOnlySpan = (
    key,
    cellValue,
    cellWidth,
    cellType,
    onClick = null
  ) => {
    const tooltipVisible =
      cellType === "text" || (cellType === "wysiwyg" && cellValue);
    const cellValueSafe = getCellValueSafe(cellType, cellValue);

    const cellValueAndClick = onClick ? (
      <Button
        key={key}
        className={`${key}`}
        type="link"
        onClick={() => onClick(key)}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
          paddingLeft: 0,
        }}
      >
        <TextWithHighlights
          text={cellValueSafe}
          highlight={searchString}
          highlightStyle={{
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </Button>
    ) : (
      cellValueSafe
    );

    const tag = (
      <span
        key={key}
        className={classes.cell}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <TextWithHighlights
          text={cellValueAndClick}
          highlight={searchString}
          highlightStyle={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </span>
    );

    return tooltipVisible ? (
      <Tooltip key={`tooltip-${key}`} placement="bottom" title={cellValueSafe}>
        {tag}
      </Tooltip>
    ) : (
      tag
    );
  };

  const renderReadOnlyRichTextSpan = (
    key,
    cellValue,
    cellWidth,
    cellType,
    column,
    item
  ) => {
    const template_type = item.type;
    const richTextExistInTemplate = _.get(
      props.folderTypes,
      `[${template_type}].fields[${column.field}]`,
      null
    );
    if (!richTextExistInTemplate) {
      return (
        <span
          key={key}
          style={{
            width: cellWidth,
            minWidth: cellWidth,
          }}
        ></span>
      );
    }

    const cellValueSafe = getCellValueSafe(cellType, cellValue);

    const tag = (
      <span
        key={key}
        className={`${classes.cell} ${classes.cellRichTextDisabled}`}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <TextWithHighlights
          text={cellValueSafe}
          highlight={searchString}
          highlightStyle={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </span>
    );

    return (
      <Tooltip
        key={`tooltip-${key}`}
        placement="bottom"
        title={`This is a rich text filed which cannot be edited in list view. Please edit this 
                       field by exiting edit mode and clicking the link in the list view, or by selecting the 
                       item in the folder tree`}
      >
        {tag}
      </Tooltip>
    );
  };

  const renderDynamicFormSelect = (
    key,
    fieldGroupsConfig,
    fieldsConfig,
    fieldValues,
    cellWidth,
    item,
    field
  ) => {
    return (
      <span
        key={key}
        className={[classes.cell, classes.cellEditMode].join(" ")}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
        }}
      >
        <DynamicForm
          key={key}
          fieldGroupsConfig={fieldGroupsConfig}
          fieldsConfig={fieldsConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => {}}
          onError={() => {}}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onChangeHandlers={[
            {
              name: field,
              callBackFunction: (name, value, fieldValues) =>
                onChange(name, value, fieldValues, item),
            },
          ]}
          classOverride="noclass"
          canUpdate={mode === MODE_EDIT}
          submitting={false}
        />
      </span>
    );
  };

  const renderDynamicFormSpan = (
    key,
    fieldGroupsConfig,
    fieldsConfig,
    fieldValues,
    cellWidth,
    item,
    mode
  ) => {
    return (
      <span
        key={key}
        className={[classes.cell, classes.cellEditMode].join(" ")}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
        }}
      >
        <DynamicForm
          key={key}
          fieldGroupsConfig={fieldGroupsConfig}
          fieldsConfig={fieldsConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => {}}
          onError={() => {}}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onBlur={(fields) => {
            const fieldNames = Object.keys(fields);
            fieldNames.forEach((f) => {
              onChange(f, fields[f], fields, item);
            });
          }}
          classOverride="noclass"
          canUpdate={mode === MODE_EDIT}
          submitting={false}
        />
      </span>
    );
  };

  const getUserNameAndLastName = (cellValue) => {
    let userName = "";
    try {
      const user = props.users[cellValue];
      if (user) {
        userName = user.first_name + " " + user.last_name;
      }
    } catch (e) {}
    return userName;
  };

  const isNewOrSavedNewInThisSession = (item) => {
    if (item.new) {
      return true;
    } else if (!allFolderIdsInThisSession) {
      return false;
    } else {
      return !allFolderIdsInThisSession.includes(item.folderId);
    }
  };

  const getExpandedFolderTree = (parentFolderId) => {
    try {
      const parentId = `folder-${parentFolderId}`;
      let tree_expanded = [...props.applied_content_tree_expanded];
      if (parentFolderId) {
        const findParent = tree_expanded.find((te) => te === parentId);
        if (!findParent) {
          tree_expanded = [...props.applied_content_tree_expanded, parentId];
        }
      }
      return tree_expanded;
    } catch (e) {
      return null;
    }
  };

  const cleanComponent = () => {
    setUnsavedChangedItems([]);
    setAddedItems([]);
    setUnsavedChanges(false);
    setUnsavedChangesModal(false);
  };

  const Row = ({
    style,
    item,
    columns,
    mode,
    onChange,
    openAssociationsModal,
  }) => (
    <span
      key={item}
      className={`flex-center-left ${
        isNewOrSavedNewInThisSession(item) ? "list_new" : ""
      }`}
      style={{ ...style, border: lt_gray_border }}
    >
      {columns.map((column, index) => {
        const key = `column_${index}_row_${item.id}`;
        let cellValue = item[column.field];
        // const cellType = getCellTypeSafe(column);
        const cellWidth = getCellWidth(
          column.type,
          outerWithRef.current,
          columns
        );
        let onClickCallback = null;

        if (column.field === "associations") {
          const resource__folders = Object.values(props.resource__folders);
          if (
            item &&
            resource__folders &&
            resource__folders.length &&
            props.folders
          ) {
            const resourceId = item.id;
            const associatedResources = resource__folders.filter((rf: any) => {
              const folderId = _.get(rf, "folder", null);
              return rf.resource === resourceId && props.folders[folderId];
            }).length;
            if (!associatedResources) {
              return (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    minWidth: cellWidth,
                    width: cellWidth,
                  }}
                >
                  <span>Without association</span>
                </div>
              );
            }
          }
          return (
            <div
              key={key}
              style={{ display: "flex", minWidth: cellWidth, width: cellWidth }}
            >
              <a
                href="#"
                onClick={() => {
                  openAssociationsModal(item);
                  // setSelectedResourceAssociationsModal(item);
                  // setAssociationsModalOpen(true);
                }}
              >
                Associations
              </a>
            </div>
          );
        }

        // special checkbox
        if (column.field === "list_view_selected") {
          if (item.new) {
            return (
              <span
                key={key}
                className={[classes.cell, classes.checkboxCell].join(" ")}
              />
            );
          }
          return (
            <span
              key={key}
              className={[classes.cell, classes.checkboxCell].join(" ")}
            >
              <DynamicForm
                key={key}
                fieldGroupsConfig={{
                  default: { title: "", default_expanded: true },
                }}
                fieldsConfig={{
                  [column.field]: { type: column.type, title: "" },
                }}
                fieldValues={{ [column.field]: !!item.list_view_selected }}
                mode="edit"
                onSubmit={() => {}}
                onError={() => {}}
                showSubmitButton={false}
                enableFieldGrouping={false}
                showLabels={false}
                onChangeHandlers={[
                  {
                    name: column.field,
                    callBackFunction: (name, value, fieldValues) =>
                      onChange(name, value, fieldValues, item),
                  },
                ]}
                classOverride="noclass"
                canUpdate={mode === MODE_SELECT}
                submitting={false}
              />
            </span>
          );
        }

        if (column.field === "hamburger") {
          if (item.new) {
            return (
              <span
                key={key}
                className={classes.iconCell}
                style={{
                  color: "#666",
                }}
              >
                <FontAwesomeIcon size="lg" icon={["fas", "asterisk"]} />
              </span>
            );
          }

          const noMovable = sortMode === SORT_OTHER || mode === MODE_SELECT;
          return (
            <span
              key={key}
              className={[classes.iconCell, "drag-icon"].join(" ")}
              style={{
                color: noMovable ? "#CCC" : "#666",
                cursor: noMovable ? "not-allowed" : "pointer",
              }}
            >
              <FontAwesomeIcon size="lg" icon={["far", "bars"]} />
            </span>
          );
        }

        const typeFieldGroupsConfig = {
          default: { title: "", default_expanded: true },
        };
        let typeFieldsConfig = {
          [column.field]: {
            title: "",
            type: column.type,
            show_char_count: false,
            settings: {},
            options: [],
          },
        };
        let typeFieldValues = { [column.field]: cellValue };

        if (column.field === "updatedBy") {
          let userName = getUserNameAndLastName(cellValue);
          return renderReadOnlySpan(key, userName, cellWidth, column.type);
        }

        switch (column.type) {
          case "date":
            cellValue = moment(cellValue).format("L");
            break;
          case "text-lg":
          case "text":
            typeFieldsConfig[column.field].type = "text";
            const suffix = _.get(column, "settings.suffix", "");
            const prefix = _.get(column, "settings.prefix", "");
            cellValue = `${prefix}${cellValue}${suffix}`;
            cellValue =
              cellValue === undefined || cellValue === "undefined"
                ? ""
                : cellValue;
            typeFieldsConfig[column.field].show_char_count = false;
            break;
          case "textarea_modal":
            typeFieldsConfig[column.field].title = column.title;
            break;
          case "textarea":
            typeFieldsConfig[column.field].settings = {
              autosize: {
                minRows: 1,
                maxRows: 1,
              },
            };
            typeFieldsConfig[column.field].show_char_count = false;
            break;
          case "select":
            if (cellValue) {
              const foundOption = column.options.find(
                (c) => c.value === cellValue
              );
              if (foundOption) {
                cellValue = foundOption.title;
              }
            }
            typeFieldsConfig[column.field].options = column.options;
            typeFieldsConfig[column.field].settings = column.settings;
            break;
          case "associations":
            break;
        }

        switch (mode) {
          case MODE_SELECT:
          case MODE_SORT:
            return renderReadOnlySpan(
              key,
              cellValue,
              cellWidth,
              column.type,
              onClickCallback
            );
          case MODE_EDIT:
            if (column.type === "wysiwyg") {
              return renderReadOnlyRichTextSpan(
                key,
                cellValue,
                cellWidth,
                column.type,
                column,
                item
              );
            } else {
              let newMode = MODE_EDIT;
              if (!column.editable) {
                newMode = MODE_SELECT;
              }
              return renderDynamicFormSpan(
                key,
                typeFieldGroupsConfig,
                typeFieldsConfig,
                typeFieldValues,
                cellWidth,
                item,
                newMode
              );
            }
        }
      })}
    </span>
  );

  useEffect(() => {
    if (props.sorting === SORT_RANK) {
      setSortMode(SORT_RANK);
    } else {
      setSortMode(SORT_OTHER);
    }
    setCurrentSort({ field: props.sorting, direction: "asc" });
  }, [props.sorting]);

  useEffect(() => {
    const currentType = props.folderTypes[props.parentFolder.folder_type];
    setFolderType(currentType);
    const acceptableTypes = currentType.acceptable_folder_types.map(
      (t) => props.folderTypes[t]
    );
    setAcceptableFolderTypes(acceptableTypes);
  }, [props.folderTypes, props.parentFolder]);

  useEffect(() => {
    const currentType = props.folderTypes[props.parentFolder.folder_type];
    setFolderType(currentType);
    if (currentType && currentType.prevent_add_children) {
      setShowAddButton(false);
    }
    addSubmitListViewCallback(saveEdits);
    cleanComponent();
  }, [props.parentFolder]);

  useEffect(() => {
    if (allFolderIdsInThisSession === null) {
      const folderIds = props.listItems.map((ai) => ai.folderId);
      setAllFolderIdsInThisSession(folderIds);
    }
    const { field, direction } = currentSort as {
      field: string;
      direction: "asc" | "desc" | boolean;
    };
    const orderedItems =
      sortMode === SORT_RANK
        ? [...props.listItems].sort((a, b) => a.rank - b.rank)
        : _.orderBy(
            [...props.listItems],
            [(i) => (i[field] ? i[field].toLowerCase() : "")],
            direction
          );
    setItems(orderedItems);
  }, [props.listItems]);

  useEffect(() => {
    const foundItems = itemsRef.current.filter((i) => i.list_view_selected);
    setSelectedItems(foundItems);
    setSelectedItemsCount(foundItems.length);
    if (foundItems.length === itemsRef.current.length) {
      setAllSelected(true);
    }
  }, [items]);

  // check also folder__status, because the item can change it status and rebuilt all tablet
  useEffect(() => {
    if (sortMode === SORT_RANK) {
      const sortedItems = [...addedItemsRef.current, ...itemsRef.current].sort(
        (a, b) => a.rank - b.rank
      );
      setAllItems(sortedItems);
    } else {
      setAllItems([...addedItemsRef.current, ...itemsRef.current]);
    }
  }, [items, addedItems, props.folder__status]);

  const applyAllFilters = (item, columnFilters, fnObject) => {
    const shouldBeFiltered = Object.keys(fnObject).some((fnkey) => {
      let isIncluded;
      const currentFunction = fnObject[fnkey];
      const currentFilterValue = columnFilters[fnkey];
      if (currentFunction) {
        isIncluded = currentFunction(currentFilterValue, item);
        if (!isIncluded) {
          return true;
        }
      }
    });
    if (shouldBeFiltered) {
      // return false to not be added to the filtered array
      return false;
    } else {
      // return true bo be added to the filtered array
      return true;
    }
  };

  useEffect(() => {
    const myFilteredItems = allItems.filter((ai) =>
      applyAllFilters(ai, columnFilters, functionFilters)
    ).filter((resource) => {
      if (!client_options.search_string_main.length) {
        return true
      } else {
        return resource.modifiedName.toLowerCase().includes(client_options.search_string_main.toLowerCase())
      }
    });
    setFilteredItems(myFilteredItems);
  }, [allItems, columnFilters, functionFilters, client_options]);

  useEffect(() => {
    if (mode === MODE_EDIT) {
      setAllSelected(false);
      if (selectedItemsCount > 0) {
        const indexes = selectedItems.map((i) =>
          itemsRef.current.findIndex((ii) => ii.id === i.id)
        );
        const newItems = [...itemsRef.current];
        indexes.forEach((i) => (newItems[i].list_view_selected = false));
        setSelectedItems([]);
        setItems(newItems);
      }
    }
  }, [mode]);

  const getTypeColumnWidth = () => {
    if (acceptableFolderTypes && acceptableFolderTypes.length > 1) {
      return 300;
    } else return 0;
  };

  const handleResize = () => {
    const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
    const realWidth = boundingClientRect.width + getTypeColumnWidth();
    setOuterWidth(boundingClientRect.width - 40);
    setComponentWidth(boundingClientRect.width - 40);
  };

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        handleResize();
      }
    }, 500);
    return () => clearTimeout(timeoutHandler);
  }, [wrappingDivRef, JSON.stringify(props.recalculateWidth)]);

  useEffect(() => {
    if (wrappingDivRef && wrappingDivRef.current) {
      const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
      setListContainerHeight(boundingClientRect.height - 96 - 40 - 50 - 300);
    }
  }, [wrappingDivRef]);

  useEffect(() => {
    /**
     * handle clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrappingDivRef.current &&
        !wrappingDivRef.current.contains(event.target)
      ) {
        if (unsavedChangesRef.current) {
          setUnsavedChangesModal(true);
        }
      }
    }

    // resize
    window.addEventListener("resize", handleResize);
    // Bind the event listener
    // document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the resize event
      window.removeEventListener("resize", handleResize);
      // Unbind the event listener on clean up
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setStyleValues({ columnsWidthSum, listContainerHeight });
  }, [columnsWidthSum, listContainerHeight]);

  useEffect(() => {
    const newList = _.orderBy(
      itemsRef.current,
      [
        (i) => {
          if (props.sorting === SORT_RANK) {
            return i[props.sorting];
          }
          const field = _.get(i, props.sorting, "zzz");
          return field.toLowerCase();
        },
      ],
      "asc"
    );
    setItems(newList);
  }, [sortMode, props.sorting]);

  useEffect(() => {
    setEditButtonDisabled(mode === MODE_EDIT);
  }, [mode]);

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler2 = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        const boundingClientRect =
          wrappingDivRef.current.getBoundingClientRect();
        setComponentWidth(boundingClientRect.width - 40);
        if (boundingClientRect.width - 40 > columnsWidthSum) {
          setOuterWidth(boundingClientRect.width - 40);
        } else {
          setOuterWidth(columnsWidthSum);
        }
      }
    }, 500);
    return () => clearTimeout(timeoutHandler2);
  }, [columnsWidthSum, JSON.stringify(props.recalculateWidth)]);

  useEffect(() => {
    setColumnsWidthSum(getWidthFromColumns(columns, outerWithRef.current));
  }, [columns, outerWidth]);

  useEffect(() => {
    if (props.listColumns) {
      const filters = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = [];
        }
        return accumulator;
      }, {});
      setInitialColumnFilters(filters);

      const filtersFn = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = getFilterFunction(current.field);
        }
        return accumulator;
      }, {});
      setFunctionFilters(filtersFn);
    }
  }, [props.listColumns]);

  useEffect(() => {
    const searchStringHighlight = _.get(
      props,
      "client_options.search_string_highlight",
      null
    );
    setSearchString(searchStringHighlight);

    const list_view_last_select_folder = _.get(
      props,
      "client_options.list_view_last_select_folder",
      null
    );
    const list_view_last_select_key = _.get(
      props,
      "client_options.list_view_last_select_key",
      null
    );

    if (list_view_last_select_folder === null && list_view_last_select_key) {
      setTimeout(() => {
        const element = document.querySelector(
          `.${list_view_last_select_key}`
        ) as unknown as { focus: Function };
        if (element) {
          element.focus();
        }
        const updateClientOptions = {
          list_view_last_select_key: null,
        };
        props.setClientOptions(updateClientOptions);
      }, 500);
    }
  }, [props.client_options]);

  useEffect(() => {
    props.setUpdateListViewTouch({ touch: unsavedChanges });
  }, [unsavedChanges, unsavedChangesRef]);

  const switchMode = (mode) => {
    switch (mode) {
      case MODE_SELECT:
        break;
      case MODE_EDIT:
        setEditButtonDisabled(true);
        setMode(MODE_EDIT);
        setForceUpdateInt(forceUpdateInt + 1);
        break;
    }
  };

  const switchSortMode = () => {
    setSortMode(SORT_RANK);
    setCurrentSort({ field: "rank", direction: "asc" });
    setMode(MODE_SORT);
    const newItems = _.orderBy(itemsRef.current, "rank", "asc");
    setItems(newItems);
  };

  const onAddBulkSuccess = () => {
    setAddedItems([]);
    AlertNotification(
      "success",
      "Success",
      "New items have been saved and they are now  in the list according to the sorting order"
    );
  };

  const onUpdateBulkSuccess = () => {
    AlertNotification(
      "success",
      "Success",
      "New items have been saved and they are now  in the list according to the sorting order"
    );
    cleanStateAfterSaveSuccess();
  };

  const onDeleteBulkSuccess = () => {
    setSelectedItems([]);
    AlertNotification(
      "success",
      "Success",
      "Records were deleted successfully"
    );
  };

  const cleanStateAfterSaveSuccess = () => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null })
    );

    setUnsavedChangedItems([]);
    setUnsavedChanges(false);
    setUnsavedChangesModal(false);
  };

  const checkCancelEditsPopModal = () => {
    if (unsavedChangesRef.current || addedItems.length > 0) {
      setUnsavedChangesModal(true);
    } else {
      setMode(MODE_SELECT);
      if (sortMode === SORT_RANK) {
        setUnsavedChangedItems([]);
        setUnsavedChanges(false);
        setItems(props.listItems.sort((a, b) => a.rank - b.rank));
      }
    }
  };

  const cancelEdits = () => {
    setItems(props.listItems);
    setAddedItems([]);
    setUnsavedChangesModal(false);
    setUnsavedChanges(false);
    setUnsavedChangedItems([]);
    setMode(MODE_SELECT);
    setForceUpdateInt(forceUpdateInt + 1);
    setEditButtonDisabled(false);
  };

  const buildCallOnAddBulkSuccess = () => {
    let callOnAddBulkSuccess = () => {};
    let callCleanState = () => {};

    // only call one success message
    if (unsavedChangedItemsRef.current.length === 0) {
      callOnAddBulkSuccess = onAddBulkSuccess;
    } else {
      callOnAddBulkSuccess = () => setAddedItems([]);
    }

    if (!shouldUpdateBulk()) {
      callOnAddBulkSuccess = onAddBulkSuccess;
      callCleanState = cleanStateAfterSaveSuccess;
    }

    return () => {
      callOnAddBulkSuccess();
      callCleanState();
    };
  };

  const saveEdits = async () => {
    const { addBulk, updateBulk } = props;
    setMode(MODE_SELECT);
    setForceUpdateInt(forceUpdateInt + 1);
    setEditButtonDisabled(false);
    dispatch(
      setClientOptions({
        indeterminate: true,
        lock_screen: true,
        message_for_progress: "We are saving your changes",
      })
    );

    // only call one success message
    const callOnAddBulkSuccess = buildCallOnAddBulkSuccess();

    if (addedItemsRef.current.length > 0) {
      const propertiesToExclude = ["folder", "id"];
      const folderFormattedItems = addedItemsRef.current.map((i) => {
        const newItem = {
          ...i,
          tree_type: "resource",
          parent_folder__folder_id: i.folder,
        };
        // Object cleanance.
        propertiesToExclude.forEach((p) => delete newItem[p]);
        return newItem;
      });
      // await addBulk(
      //   folderFormattedItems,
      //   callOnAddBulkSuccess,
      //   (message) =>
      //     AlertNotification(
      //       "error",
      //       "Error",
      //       message || "Unexpect error happened"
      //     )
      // );
    }

    // If items
    if (shouldUpdateBulk()) {
      const folderFormattedItemsForUpdate = itemsRef.current.filter((i) =>
        unsavedChangedItemsRef.current.includes(i.id)
      );

      updateBulk(
        folderFormattedItemsForUpdate,
        onUpdateBulkSuccess,
        (message) => {
          AlertNotification(
            "error",
            "Error",
            message || "Unexpect error happened"
          );
        }
      );
    }
  };

  const handleBulkDelete = () => {
    const resourceIds = selectedItems.map((i) => i.id);
    props.deleteBulk(resourceIds, onDeleteBulkSuccess, null);
  };

  const shouldUpdateBulk = () => {
    if (items.length > 0 && unsavedChangedItemsRef.current.length > 0) {
      const folderFormattedItemsForUpdate = itemsRef.current.filter((i) =>
        unsavedChangedItemsRef.current.includes(i.id)
      );
      return folderFormattedItemsForUpdate.length > 0;
    }
    return false;
  };

  const customSort = (field) => {
    let direction: "asc" | "desc" = "asc";
    if (currentSort.field === field) {
      direction = currentSort.direction === "asc" ? "desc" : "asc";
    }
    setSortMode(SORT_OTHER);
    setCurrentSort({ field, direction });
    const nonNewItems = itemsRef.current.filter((i) => i.rank >= 0);
    const sortedNoNewItems = _.orderBy(
      nonNewItems,
      [(i) => i[field].toLowerCase()],
      direction
    );
    setItems(sortedNoNewItems);
  };

  const onChange = (name, field, fieldValues, item, isMedia = false) => {
    // if the change is in a media file the content manager handle the save to the database
    // so we do not need to mark the component as touch
    if (!isMedia) {
      setUnsavedChanges(true);
      const removedDuplicated = [
        ...new Set([...unsavedChangedItemsRef.current, item.id]),
      ];
      setUnsavedChangedItems(removedDuplicated);
    }
    switch (name) {
      case "list_view_selected":
        const foundIndex = itemsRef.current.findIndex((i) => i.id === item.id);
        const newItems = [...itemsRef.current];
        newItems.splice(foundIndex, 1, {
          ...item,
          list_view_selected: fieldValues[name],
        });
        setItems(newItems);
        break;
      default:
        if (item.rank >= 0 && !item.new) {
          const index = itemsRef.current.findIndex((i) => i.id === item.id);
          if (index >= 0) {
            const foundItem = {
              ...itemsRef.current[index],
              [name]: field,
              changed: true,
            };
            // Remove copy item in a new array to prevent loss focus (tab press)
            const newItems = [...itemsRef.current];
            newItems.splice(index, 1, foundItem);
            setItems(newItems);
          }
        } else {
          const index = addedItemsRef.current.findIndex(
            (i) => i.id === item.id
          );
          if (index >= 0) {
            const foundItem = {
              ...addedItemsRef.current[index],
              [name]: field,
            };
            // Remove copy item in a new array to prevent loss focus (tab press)
            const newItems = [...addedItemsRef.current];
            newItems.splice(index, 1, foundItem);
            setAddedItems(newItems);
          }
        }
        break;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newAllItems = JSON.parse(JSON.stringify(allItems));
    const rankFix = newIndex > oldIndex ? 0.001 : -0.001;
    const rerankedItems = [];

    const newAddedItems = [];
    const newItems = [];

    newAllItems[oldIndex].rank = Number((newIndex + rankFix).toFixed(3));
    newAllItems
      .sort((a, b) => a.rank - b.rank)
      .forEach((item, index) => {
        item.rank = Number(index);
        rerankedItems.push(item.id);
        if (item.new) {
          newAddedItems.push(item);
        } else {
          newItems.push(item);
        }
      });
    setUnsavedChangedItems(rerankedItems);
    setUnsavedChanges(true);

    setAddedItems(newAddedItems);
    setItems(newItems);
  };

  const onSelectAllChange = (value) => {
    const newItems = items.map((i) => {
      const newItem = { ...i };
      newItem.list_view_selected = value;
      return newItem;
    });
    setAllSelected(value);
    setItems(newItems);
  };

  const getFilterUpdatedByObject = (data, property, include_blank = false) => {
    const returnObject = [
      ...new Set(data.filter((x) => x[property]).map((x) => x[property])),
    ].map((x) => {
      let userName = getUserNameAndLastName(x);
      return { text: userName, value: x };
    });
    if (include_blank) {
      returnObject.push({ text: "(Empty)", value: "(empty)" });
    }
    return returnObject;
  };

  const onFilterColumn = (key, filterValue) => {
    const newFilters = { ...columnFilters };
    newFilters[key] = filterValue;
    setColumnFilters({ ...columnFilters, ...newFilters });
  };

  const renderHeaders = (tableWidth, allItems) => (
    <span
      id="headers"
      className="flex-center-left"
      style={{
        border: lt_gray_border,
        height: 50,
        width: outerWithRef.current,
      }}
    >
      {columns.map((column, index) => {
        const cellWidth = getCellWidth(column.type, tableWidth, columns);

        let sortDirection = "none";
        if (currentSort.field === column.field) {
          if (currentSort.direction === "asc") {
            sortDirection = "asc";
          } else {
            sortDirection = "desc";
          }
        }

        switch (column.type) {
          case "actions":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "icon":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "bool":
            return (
              <span
                key={index}
                className={`${classes.cell} form-field-wrapper`}
                style={{ minWidth: cellWidth }}
              >
                {/* <Checkbox
                  disabled={mode === MODE_EDIT}
                  checked={allSelected}
                  onChange={(e) => onSelectAllChange(e.target.checked)}
                /> */}
              </span>
            );
          case "integer":
          case "text":
          case "associations":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth, cursor: "pointer" }}
                onClick={() => customSort(column.field)}
              >
                <b>
                  {column.title} &nbsp;&nbsp;
                  {sortDirection === "none" && (
                    <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                  )}
                  {sortDirection === "asc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-down"]} />
                  )}
                  {sortDirection === "desc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-up"]} />
                  )}
                </b>
              </span>
            );
          default:
            let extraClass = _.get(columnsExtraClass, `[${column.field}]`, "");
            return (
              <span
                key={index}
                className={`${classes.cell} ${classes.cellFlex} ${extraClass}`}
                style={{ minWidth: cellWidth }}
              >
                <div
                  className={`${classes.rowGrow} ${
                    column.filter ? classes.rowGrowPadding : ""
                  }`}
                >
                  <div>
                    <b>
                      {column.title} &nbsp;&nbsp;
                      {sortDirection === "none" && (
                        <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                      )}
                      {sortDirection === "asc" && (
                        <FontAwesomeIcon
                          size="sm"
                          icon={["fas", "sort-down"]}
                        />
                      )}
                      {sortDirection === "desc" && (
                        <FontAwesomeIcon size="sm" icon={["fas", "sort-up"]} />
                      )}
                    </b>
                  </div>
                </div>
                {column.filter ? (
                  <div className={classes.rowRight}>
                    <FilterActions
                      filterKey={column.field}
                      options={getFilterUpdatedByObject(
                        allItems,
                        column.type,
                        true
                      )}
                      onFilter={onFilterColumn}
                      onHoverIn={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] =
                          "list-view_header-hover";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                      onHoverOut={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] = "";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                    ></FilterActions>
                  </div>
                ) : null}
              </span>
            );
        }
      })}
    </span>
  );

  const shouldCancelStart = (event) => {
    if (sortMode !== SORT_RANK || mode === MODE_SELECT) {
      return true;
    }
    let targetEl = event.target;
    do {
      if (typeof targetEl.className === "string") {
        if (targetEl.className.includes("drag-icon")) {
          return false;
        }
      }
      targetEl = targetEl.parentNode;
    } while (targetEl.parentNode);
    return true;
  };

  const getButtonSize = () => {
    if (componentWidth > MIN_WIDTH_FULL_RESOLUTION) {
      return "large";
    } else if (componentWidth > MIN_WIDTH_MEDIUM_RESOLUTION) {
      return "large";
    }
    return "small";
  };

  const openAssociationsModal = (item) => {
    setAssociationsModalOpen(true);
    setSelectedResourceAssociationsModal(item);
  };

  const isComponentWidthFull = componentWidth > MIN_WIDTH_FULL_RESOLUTION;
  const sortBy = `Sort by ${
    sortMode !== SORT_RANK ? "Rank" : strToUpper(props.sorting)
  }`;

  return (
    <div className={classes.listContainer} ref={wrappingDivRef}>
      <Modal
        width={480}
        visible={associationsModalOpen}
        onOk={() => setAssociationsModalOpen(false)}
        onCancel={() => setAssociationsModalOpen(false)}
        title={<React.Fragment>Associations</React.Fragment>}
      >
        {selectedResourceAssociationsModal && (
          <ResourceContentLibraryAssociated
            key={selectedResourceAssociationsModal.id}
            selected_resource={selectedResourceAssociationsModal}
            resource__folders={Object.values(props.resource__folders)}
            folders={props.folders}
            folder__folders={props.folder__folders}
            setClientOptions={props.setClientOptions}
            toggleFolderExpanded={props.toggleFolderExpanded}
            setFoldersExpanded={props.setFoldersExpanded}
            projects={props.projects}
          />
        )}
      </Modal>

      <div id="actions">
        <div id="multi-action">
          {mode === MODE_SELECT && selectedItemsCount > 0 && (
            <MultiFolderActions
              selectedItemsCount={selectedItemsCount}
              handleBulkMove={() => {}}
              handleBulkDelete={handleBulkDelete}
              handleBulkInstance={() => {}}
              caller_folder={0}
              checked_folders={[]}
              maxWidth={componentWidth}
              folderType={folderType}
              onOkay={() => {
                setUnsavedChanges(false);
              }}
            />
          )}
        </div>
        <div id="top-buttons">
          {/* {props.sorting === "rank" && (
            <Button
              disabled={currentSort.field === "rank"}
              onClick={() => switchSortMode()}
              className="list-view_button"
              type="primary"
              size={getButtonSize()}
              style={{ width: "auto", marginRight: 5 }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">{sortBy}</span>
              ) : (
                <Tooltip
                  placement="bottom"
                  title={sortBy}
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={["fas", "sort"]}
                    onClick={() => switchSortMode()}
                  />
                </Tooltip>
              )}
            </Button>
          )} */}
          <Button
            onClick={() => switchMode(MODE_EDIT)}
            disabled={editButtonDisabled}
            className="list-view_button"
            type="primary"
            size={getButtonSize()}
            style={{ width: "30%", height: "100%" }}
          >
            {isComponentWidthFull ? (
              <span className="list-view_button-text">Edit Mode</span>
            ) : (
              <Tooltip
                placement="bottom"
                title="Edit Mode"
                className="list-view_button-icon"
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={["fas", "edit"]}
                  onClick={() => switchMode(MODE_EDIT)}
                />
              </Tooltip>
            )}
          </Button>
        </div>
      </div>
      <div id="list">
        {renderHeaders(outerWithRef.current, allItems)}
        <SortableListMemoResource
          key={`resource-${_.get(props, "parentFolder.id", 0)}`}
          outerWidth={outerWithRef.current}
          listContainerHeight={listContainerHeight}
          allItems={filteredItems}
          onSortEnd={onSortEnd}
          forceUpdateInt={forceUpdateInt}
          shouldCancelStart={shouldCancelStart}
          helperClass={classes.beingDragged}
          mode={mode}
          columns={columns}
          onChange={onChange}
          openAssociationsModal={openAssociationsModal}
          Row={Row}
        />
      </div>
      <div id="bottom-buttons">
        <Button
          onClick={checkCancelEditsPopModal}
          disabled={mode === MODE_SELECT}
          size="large"
          style={{ width: "auto" }}
        >
          CANCEL
        </Button>
        <Spaner width="sm" />
        <Button
          onClick={() => saveEdits()}
          disabled={mode === MODE_SELECT}
          type="primary"
          size="large"
          style={{ width: "auto" }}
        >
          SAVE
        </Button>
      </div>
      <Modal
        visible={unsavedChangesModal}
        title="Cancel Edits"
        onCancel={() => setUnsavedChangesModal(false)}
        onOk={cancelEdits}
        destroyOnClose
      >
        <div>Are you sure you want discard the changes made?</div>
      </Modal>
    </div>
  );
};

export default ResourceListView;
