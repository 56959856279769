import React, {useCallback} from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import MediaCarousel from "../../standard/MediaCarousel";
import CycleWrapper from "../../standard/CycleWrapper";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";

const MarinMediaBlank = (props: IDisplayComponentProps) => {

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["media"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  return (
    <div
      style={{
        position: "absolute",
        height: props.refHeight,
        width: props.refWidth
      }}
    >
      {
        !resourceFoldersCarousel.length && (
          <CycleWrapper {...props}/>
        )
      }
      <MediaCarousel
        {...props}
        height="100%"
        width="100%"
        slotNames={["media"]}
        resourceFoldersCarousel={resourceFoldersCarousel}
        data={{
          ...props.data,
          componentSpecificData: {
            ...props.data?.componentSpecificData,
            slickConfiguration: {
              fade: true,
              speed: 2000
            }
          }
        }}
      />
    </div>
  );
};

export default MarinMediaBlank;
