export enum URI_ACCESS_TYPE {
    PUBLIC = 'public',
    PASSWORD = 'password',
    USER = 'user',
    NONE = 'none'
}

export interface IURIAccess {
    id?: number
    identifier?: string;
    folder?: number;
    access_type?: URI_ACCESS_TYPE;
    encrypted_password?: string;
}