import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const berkeley_donor_sea_background: IFolderType = {
  name: FOLDER_TYPE.berkeley_donor_sea_background,
  display_name: "Donor Sea Background",
  folder_category: FOLDER_CATEGORY.STORY,
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 15, max: 100 }] },
      default: 40,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_cycle_time"
      }
    },
    image: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.666666666666667,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.666666666666667
      }
    }
  },
  preview: {
    aspect_ratio: 2.666666666666667,
    component_name: COMPONENT_NAME.BerkeleyContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 2}],
  },
  client: {
    component_name: COMPONENT_NAME.Slide1911
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.slide
    },
    closed: {
      ...tree_icon_types.slide
    }
  }
};
