import { createUseStyles } from "react-jss";
import { FOOTER_HEIGHT, ICON_WIDTH } from "../constants";
import { MODE } from "../resource-explorer.interface";

const useStyles = createUseStyles({
  item: {
    position: 'relative',
    width: "100%",
    height: "100%",
    border: "1px solid #CCC",
    "& #image": {
      height: (props: any) => props.height - FOOTER_HEIGHT,
      backgroundSize: "cover!important",
      backgroundPosition: "center!important"
    },
    '& #overlay': {
      position: 'absolute',
      width: '100%',
      height: (props: any) => props.height - FOOTER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        position: 'absolute'
      }
    },
    "& #footer": {
      display: "flex",
      alignItems: "center",
      borderTop: "1px solid #CCC",
      height: FOOTER_HEIGHT,
      "& #text": {
        color: "inherit",
        maxWidth: (props: any) => props.width - ICON_WIDTH,
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        whiteSpace: "nowrap",
        paddingLeft: 5,
        paddingRight: 5
      },
      "& #icon": {
        color: "#2699FB",
        paddingLeft: 5,
        float: "left",
        width: ICON_WIDTH
      }
    },
    '& input[type=checkbox]': {
      position: 'absolute',
      top: 5,
      right: 5,
      visibility: (props: any) => props.mode === MODE.MULTIPLE_SELECTION ? 'initial' : 'hidden'
    }
  }
});

export default useStyles;
