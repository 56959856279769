import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";

export const boston_donor_sea_background: IFolderType = {
  name: FOLDER_TYPE.boston_donor_sea_background,
  display_name: "Donor Sea Background",
  folder_category: FOLDER_CATEGORY.STORY,
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  fields: {
    name,
    default_preview: {
      type: "select",
      title: "Default Preview",
      validation: { rules: [{ name: "isRequired" }] },
      default: "1",
      options: [
        {
          title: "Mission Bernal",
          value: "1"
        },
        {
          title: "Pacific",
          value: "2"
        }
      ]
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    media_horizontal: {
      title: "Media Horizontal 3x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.666666667,
      recommended_size: {
        width: 5760,
        height: 2160
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.666666667
      }
    },
    media_horizontal_5x2: {
      title: "Media Horizontal 5x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 4.444444444,
      recommended_size: {
        width: 9600,
        height: 2160
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 4.444444444
      }
    },
    media_vertical_2x5: {
      title: "Media Vertical 2x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.711111111,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.711111111
      }
    },
    media_vertical_1x5: {
      title: "Media Vertical 1x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.355555556,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.355555556
      }
    }
  },
  preview: [
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston1x2and1x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "1x2, 1x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/1x2_1x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_1x2_and_1x5_and_3x2
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    }
  ],
  client: {
    component_name: COMPONENT_NAME.Slide1911
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.slide
    },
    closed: {
      ...tree_icon_types.slide
    }
  }
};
