import { Col, Modal, Row } from "antd";
import get from "lodash/get";
import React, { Component } from "react";
import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { IResource } from "../../../shared-global/interfaces/models/resource.interface";
import { IResourceFolder } from "../../../shared-global/interfaces/models/resource__folder.interface";
import { getThemeClass } from "../../../utils/themeUtils";
import { TREE_MODE_SELECT_RESOURCE } from "../../constants";
import PreviewResource from "../../preview/PreviewResource";
import { RESOURCE_EXPLORER_MODE } from "../../resource/ResourceExplorer/resource-explorer.interface";
import ResourceExplorerWrapper from "../../resource/ResourceExplorer/ResourceExplorerWrapper/ResourceExplorerWrapper";
import ResourceTree from "../../resource/ResourceTree";
import WithInputWrapper from "../../utils/WithInputWrapper";

interface ISelectExistingResourceProps {
  filters: string[];
  onOkay: (resource: any) => {};
  folders?: { [key: number]: IFolder };
  resource__folders?: { [key: number]: IResourceFolder };
}

interface ISelectExistingResourceState {
  modalGalleryVisible: boolean;
  previewResource: IResource | null;
}

class selectExistingResource extends Component<
  ISelectExistingResourceProps,
  ISelectExistingResourceState
> {
  constructor(props: ISelectExistingResourceProps) {
    super(props);
    this.state = {
      modalGalleryVisible: false,
      previewResource: null
    };
  }

  openGallery = () => {
    this.setState({
      modalGalleryVisible: true
    });
  };

  closeGallery = () => {
    this.setState({
      modalGalleryVisible: false
    });
  };

  selectPreviewResource = (resource: IResource & { url: string; thumbUrl: string }) => {
    if (!resource) {
      if (this.state.previewResource) {
        this.setState({
          previewResource: null
        });
      }
    } else {
      this.setState({
        previewResource: resource
      });
    }
  };

  selectExistingResource = () => {
    this.props.onOkay(this.state.previewResource);
    this.closeGallery();
  };

  render() {
    return (
      <React.Fragment>
        {/* Select Resource Modal */}
        <Modal
          title={"Please select a resource"}
          visible={this.state.modalGalleryVisible}
          onCancel={this.closeGallery}
          onOk={this.selectExistingResource}
          destroyOnClose
          width={1024}
        >
          <div
            className={
              "primary-tree-wrapper " +
              getThemeClass(
                get(this.props, "persistent_settings.data.theme", "light"),
                "primary-tree"
              )
            }
          >
            <div
              style={{
                width: 464,
                height: window.innerHeight * 0.5,
                display: "inline-block"
              }}
            >
              <WithInputWrapper>
                <ResourceTree
                  mode={"modal"}
                  modeContext={TREE_MODE_SELECT_RESOURCE}
                  filters={this.props.filters}
                  selectPreviewResource={this.selectPreviewResource}
                />
              </WithInputWrapper>
            </div>
            <div
              style={{
                width: 512,
                height: window.innerHeight * 0.5,
                display: "inline-block"
              }}
            >
              <ResourceExplorerWrapper
                width={512}
                height={window.innerHeight * 0.5}
                folders={this.props.folders}
                resource__folders={this.props.resource__folders}
                mode={RESOURCE_EXPLORER_MODE.MODAL}
                setSelectedResource={this.selectPreviewResource}
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default selectExistingResource;
