import { Switch, Tabs, Tooltip } from "antd";
import _, { isArray } from "lodash";
import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { updatePersistentSettings } from "../../actions/userActions";
import FAStack from "../../shared/components/utils/FAStack";
import AdminDashboardArea from "../admin/AdminDashboardArea";
import SystemAdminDashboardArea from "../admin/SystemDashboardArea";
import { TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE } from "../constants";
import { convertObjectIntoArray } from "../form/input/DynamicFileInput/FileInputService";
import SelectInput from "../form/input/SelectInput";
import LayoutConstants from "../layout/LayoutConstants";
import ListContainer from "../list_view/ListContainer";
import PreviewContainer from "../preview/PreviewContainer";
import ZoomableContainer from "../preview/ZoomableContainer";
import ResourceExplorerWrapper from "../resource/ResourceExplorer/ResourceExplorerWrapper/ResourceExplorerWrapper";
import { createUseStyles } from "react-jss";
import { PREVIEW_TYPE } from "../../shared-global/enums/folder-type-enums";
import FitHeight from '../../icons/zoom/fit_height.svg'
import FitWidth from '../../icons/zoom/fit_width.svg'
import ZoomIn from '../../icons/zoom/zoom_in.svg'
import ZoomOut from '../../icons/zoom/zoom_out.svg'
import FitAll from '../../icons/zoom/fit_all.svg'
import AddFolder from "../folder/AddFolder";
import { withRouter } from "../../hooks/withRouter";

const TabPane = Tabs.TabPane;

const TAB_PANE_THUMBNAIL_KEY = "1";
const TAB_PANE_PREVIEW_KEY = "2";
const TAB_PANE_LIST_VIEW_KEY = "3";
const TAB_PANE_ADMIN_KEY = "4";
const TAB_PANE_SYS_ADMIN_KEY = "5";

const DynamicClasses = ({ children, client_options }) => {
  const style = {
    centerTabs: {
      '& .ant-tabs-nav-wrap': {
        left: (client_options) => client_options.tree_panel_open ? 0 : 40
      }
    }
  }
  const useStyles = createUseStyles(style)
  const classes = useStyles(client_options);
  return children(classes);
}

class CenterArea extends Component {
  constructor(props) {
    super(props);
    const results = this.calculate();

    this._zoomableContainerRef = null;
    this._zoomOptions = [
      { title: "Fit All", value: "fit" },
      { title: "125%", value: 125 },
      { title: "150%", value: 150 },
      { title: "175%", value: 175 },
      { title: "200%", value: 200 },
      { title: "225%", value: 225 },
      { title: "250%", value: 250 },
      { title: "275%", value: 275 },
      { title: "300%", value: 300 },
    ];

    this.state = {
      previewWidth: 0,
      previewHeight: 0,
      currentDropdownZoom: "fit",
      zoomTop: 100,
      zoomLeft: 0,
      activeKey: "2",
      previewKey: 0,
      showPlaceholder: false,
      showContent: true,
      show_actives_on_presentations: false,
      ...results,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);

    if (this.props.persistent_settings) {
      const show_placeholder = _.get(
        this.props,
        "persistent_settings.data.show_placeholder",
        false
      );

      const show_actives_on_presentations = _.get(
        this.props,
        "persistent_settings.data.show_actives_on_presentations",
        false
      );
      this.setState({
        showPlaceholder: show_placeholder,
        show_actives_on_presentations: show_actives_on_presentations,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let activeTab = '';
    let newState = {};
    let resetToOriginalZoom = false;

    const treeMode = _.get(this.props, "client_options.treemode", TREE_MODE_APPLIED_CONTENT);
    const folderType = this.props.folder_type;

    // If selected folder changed
    if (prevProps.selected_folder !== this.props.selected_folder ||
      this.props.client_options !== prevProps.client_options ||
      this.props.folder_type !== prevProps.folder_type ||
      this.props.resource_selected_folder !== prevProps.resource_selected_folder ||
      this.props.treeMode !== prevProps.treeMode
    ) {
      // If tree mode changes we clear the url.
      const params = new URLSearchParams(this.props.location.search);
      if (this.props.client_options.treemode !== prevProps.client_options.treemode) {
        params.set('activeTab', '');
      }

      // Calculate width and height
      const results = this.calculate();
      newState = { ...newState, ...results };

      // Check tree mode.
      if (treeMode === TREE_MODE_RESOURCE) {
        // Maintaining active tabs
        activeTab = TAB_PANE_THUMBNAIL_KEY;

        if (prevState.activeKey === TAB_PANE_LIST_VIEW_KEY) {
          activeTab = TAB_PANE_LIST_VIEW_KEY
        }

        if (this.props.resource_selected_folder === prevProps.resource_selected_folder && this.props.client_options !== prevProps.client_options) {
          if (params.get('activeTab') !== null && params.get('activeTab') !== '') {
            activeTab = params.get('activeTab');
          }
        }

        // We check if the path is set to interactive preview (theme) and change it to /dashboard
        if (this.props.location.pathname.includes("theme")) {
          this.props.navigate("/dashboard", { replace: true });
        }
      } else if (treeMode === TREE_MODE_APPLIED_CONTENT) {
        // Check if the preview is interactive.
        const interactivePreviewUrlPath = folderType?.preview?.react_url;
        if (interactivePreviewUrlPath) {
          this.props.navigate(`/dashboard/${interactivePreviewUrlPath}`);
        } else {
          this.props.navigate(`/dashboard`, { replace: true });
          // Let's check if current preview is zoomable
          const zoomable = _.get(folderType, "preview.zoomable", false);

          // We check if dimensions of preview changed and recalculate with zoom
          const origZoom = this.getOriginalZoom(results.vWidth, results.vHeight);
          let previewWidth = origZoom.previewWidth;
          let previewHeight = origZoom.previewHeight;

          if (zoomable) {
            if (this.props.selected_folder !== prevProps.selected_folder ||
              prevState.vWidth !== results.vWidth ||
              prevState.vHeight !== results.vHeight ||
              prevState.previewWidth !== previewWidth ||
              prevState.previewHeight !== previewHeight
            ) {
              newState.currentDropdownZoom = 'fit';
              resetToOriginalZoom = true;
            }
          }
          newState.previewWidth = previewWidth;
          newState.previewHeight = previewHeight;
        }

        // If folders changed or params is empty, we set active tab
        if (this.props.selected_folder !== prevProps.selected_folder || params.get('activeTab') === null) {
          // Active Tab
          if (this.props.preview) {
            activeTab = TAB_PANE_PREVIEW_KEY;
          } else if (this.props.list_view) {
            activeTab = TAB_PANE_LIST_VIEW_KEY;
          } else if (this.props.selected_folder && this.props.selected_folder.pullable) {
            activeTab = TAB_PANE_ADMIN_KEY;
          } else if (this.props.user.system_admin) {
            activeTab = TAB_PANE_SYS_ADMIN_KEY;
          }
        } else {
          activeTab = params.get('activeTab');
        }
      }

      newState.activeKey = activeTab;
      this.props.navigate(`?activeTab=${activeTab}`);

      if (prevProps.persistent_settings !== this.props.persistent_settings) {
        const show_placeholder = _.get(
          this.props,
          "persistent_settings.data.show_placeholder",
          false
        );
        newState.showPlaceholder = show_placeholder;
      }

      this.setState(newState, () => {
        if (resetToOriginalZoom && this._zoomableContainerRef) {
          this._zoomableContainerRef.resetPosition();
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const results = this.calculate();
    this.setState({ ...results });
  };

  calculate = () => {
    const clientWidth = document.documentElement.clientWidth;
    const { client_options, folder_type } = this.props;

    let detail_drawer_width = 0;
    let primary_tree_width = 0;

    if (client_options && client_options.detail_drawer_open === true) {
      detail_drawer_width = LayoutConstants().dashboard.applied_content_tree_width;
    }

    if (client_options && client_options.tree_panel_open) {
      primary_tree_width = LayoutConstants().dashboard.applied_content_tree_width;
    }

    const vWidth = clientWidth - detail_drawer_width - primary_tree_width;
    const vHeight =
      window.innerHeight -
      LayoutConstants().tabs.height -
      LayoutConstants().topnav.height;

    const tabClass =
      client_options && client_options.tree_panel_open
        ? ""
        : "margin-for-open-button";
    const zoomable = _.get(folder_type, "preview.zoomable", false);

    return {
      primary_tree_width,
      detail_drawer_width,
      vWidth,
      vHeight,
      tabClass,
      zoomable,
    };
  };

  zoomOut = () => {
    const { currentDropdownZoom } = this.state;
    let newPercent = 25;

    if (currentDropdownZoom === "fit") {
      return
    }

    if (currentDropdownZoom === 125) {
      this.originalZoom();
      this.setState({ currentDropdownZoom: "fit" });
      return;
    }

    newPercent = currentDropdownZoom - newPercent;
    this.setState({ currentDropdownZoom: newPercent });
    this.changeZoomPercent(newPercent);
  };

  zoomIn = () => {
    const { currentDropdownZoom } = this.state;
    let newPercent = 25;

    if (currentDropdownZoom === 300) {
      return
    }

    if (currentDropdownZoom === "fit") {
      newPercent += 100
      this.setState({ currentDropdownZoom: newPercent });
      this.changeZoomPercent(newPercent);
      return;
    }

    newPercent = currentDropdownZoom + newPercent;
    this.setState({ currentDropdownZoom: newPercent });
    this.changeZoomPercent(newPercent);
  };

  goFullscreen = () => {
    const {
      openTreePane,
      openDetailPane,
      closeTreePane,
      closeDetailPane,
      client_options,
    } = this.props;

    if (client_options.detail_drawer_open || client_options.tree_panel_open) {
      closeTreePane();
      closeDetailPane();
    } else {
      openTreePane();
      openDetailPane();
    }
  };

  getValuesForZoom = () => {
    const previewAspectRatio = _.get(
      this.props.folder_type,
      "preview.aspect_ratio",
      1.77777777777777777778
    );
    const percentageOrientation = _.get(
      this.props.folder_type,
      "preview.view_percentage.orientation",
      "width"
    );
    const percentageOrientationValue = _.get(
      this.props.folder_type,
      "preview.view_percentage.value",
      10
    );

    return { previewAspectRatio, percentageOrientation, percentageOrientationValue }
  }

  getOriginalZoom = (vWidth = 0, vHeight = 0) => {
    const { previewAspectRatio } = this.getValuesForZoom();

    let previewWidth = 0;
    let previewHeight = 0;

    if (vWidth === 0) {
      vWidth = this.state.vWidth;
    }

    if (vHeight === 0) {
      vHeight = this.state.vHeight;
    }

    // Landscape
    if (previewAspectRatio > 1) {
      previewWidth = vWidth;
      previewHeight = previewWidth / previewAspectRatio;
      if (previewHeight > vHeight) {
        previewHeight = vHeight;
        previewWidth = previewHeight * previewAspectRatio;
      }
    } else { // Portrait
      previewHeight = vHeight;
      previewWidth = previewHeight * previewAspectRatio;
      if (previewWidth > vWidth) {
        previewWidth = vWidth - 40;
        previewHeight = previewWidth / previewAspectRatio;
      }
    }

    return { previewWidth, previewHeight };
  };

  getCustomScreenZoom = (fitType) => {
    const { previewAspectRatio } = this.getValuesForZoom();

    let previewWidth = 0;
    let previewHeight = 0;

    if (fitType === "width") {
      previewWidth = this.state.vWidth - 40;
      previewHeight = previewWidth / previewAspectRatio;
    } else {
      previewHeight = this.state.vHeight - 40;
      previewWidth = previewHeight * previewAspectRatio;
    }

    return { previewWidth, previewHeight }

  }

  originalZoom = () => {
    const { previewHeight, previewWidth } = this.getOriginalZoom();
    this.setState({
      previewWidth,
      previewHeight,
    }, () => {
      if (this._zoomableContainerRef) {
        this._zoomableContainerRef.resetPosition();
      }
    });
  };

  changeZoomPercent = (percent) => {
    const { previewHeight, previewWidth } = this.getOriginalZoom();
    let factor = percent / 100;
    factor = percent > 100 ? factor + 1 : factor
    this.setState({
      previewWidth: previewWidth * factor,
      previewHeight: previewHeight * factor,
    }, () => {
      if (this._zoomableContainerRef) {
        this._zoomableContainerRef.resetPosition();
      }
    });
  };

  fitPreviewToScreen = (fitType) => {
    const { previewHeight, previewWidth } = this.getCustomScreenZoom(fitType);
    this.setState({
      previewWidth,
      previewHeight,
    }, () => {
      this._zoomableContainerRef.resetPosition();
    });
  }

  handleChangeTabs = (activeKey) => {
    this.props.navigate(`?activeTab=${activeKey}`);
    this.setState({ activeKey });
  };

  toggleShowPlaceholder = (show) => {
    this.props.updatePersistentSettings({ show_placeholder: show });
    this.setState({
      showPlaceholder: show,
      showContent: false,
    });
    setTimeout(() => {
      this.setState({
        showContent: true,
      });
    }, 1);
  };

  toggleShowOnlyActiveElements = (show) => {
    this.props.updatePersistentSettings({ show_actives_on_presentations: show });
    this.setState({
      show_actives_on_presentations: show
    })
  };

  renderShowPlaceholderToggle = () => {
    const { showPlaceholder } = this.state;

    const switchElement = (
      <Tooltip title="Show Placeholder">
        <span style={{ cursor: "pointer" }}>
          <Switch
            checked={showPlaceholder}
            onChange={this.toggleShowPlaceholder}
            checkedChildren="Placeholder"
            unCheckedChildren="Placeholder"
          />
        </span>
      </Tooltip>
    );

    return switchElement;
  };

  renderShowOnlyActiveElements = () => {
    const { show_actives_on_presentations } = this.state;

    const switchElement = (
      <Tooltip title="Show only active elements on presentations">
        <span style={{ cursor: "pointer", marginRight: '.5em' }}>
          <Switch
            checked={show_actives_on_presentations}
            onChange={this.toggleShowOnlyActiveElements}
            checkedChildren="Actives"
            unCheckedChildren="Actives"
          />
        </span>
      </Tooltip>
    );

    return switchElement;
  };



  renderTabBarExtraContent = () => {
    return (
      <div>
        {
          !this.props.propertiesPanelIsFullyExpanded && (
            <span
              style={{
                marginRight: this.props.client_options.detail_drawer_open
                  ? 10
                  : 125,
              }}
            >
              {this.renderShowOnlyActiveElements()}
              {this.renderShowPlaceholderToggle()}
            </span>
          )
        }
    </div>
    )
  }
  renderZoomBar = () => {
    const { currentDropdownZoom } = this.state;
    const element =
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <span
          className="zoom-container"
        >

          <Tooltip placement="top" title="Fit All">
            <span
              style={{ cursor: "pointer", marginRight: '20px', marginLeft: '20px' }}
              onClick={() => {
                this.originalZoom();
                this.setState({ currentDropdownZoom: "fit" });
              }}
            >
              <img src={FitAll} alt="Fit All"/>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Fit Height">
            <span
              style={{ cursor: "pointer", marginRight: '20px' }}
              onClick={() => {
                this.fitPreviewToScreen('height')
              }}
            >
              <img src={FitHeight} alt="Fit Height"/>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Fit Width">
            <span
              style={{ cursor: "pointer", marginRight: '20px' }}
              onClick={() => {
                this.fitPreviewToScreen('width')
              }}
            >
              <img src={FitWidth} alt="Fit Width"/>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Zoom In">
            <span style={{ cursor: "pointer", marginRight: '20px' }} onClick={this.zoomIn}>
              <img src={ZoomIn} alt="Zoom In"/>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Zoom Out">
            <span style={{ cursor: "pointer" }} onClick={this.zoomOut}>
              <img src={ZoomOut} alt="Zoom Out"/>
            </span>
          </Tooltip>
          <SelectInput
            canUpdate={true}
            defaultValue={currentDropdownZoom}
            className="select-input zoom-input"
            options={this._zoomOptions}
            onChange={(_fName, value) => {
              if (value === "fit") {
                this.originalZoom();
              } else {
                this.changeZoomPercent(value);
              }
              this.setState({ currentDropdownZoom: value });
            }}
            onBlur={() => {}}
            settings={{ minWidth: 70 }}
          />
          <Tooltip placement="top" title="Toggle Side Panels">
            <span style={{ cursor: "pointer" }} onClick={this.goFullscreen}>
              <FAStack
                style={{ zIndex: 20, borderLeft: '2px solid white' }}
                fontSize={30}
                frontClass={"compress zoom-icon"}
              />
            </span>
          </Tooltip>
        </span>
      </div>
    return element;
  };

  render() {
    const {
      list_view,
      preview,
      folder_type,
      selected_folder,
      mobile,
      client_options,
      preparePublishFolder,
      openBackupsModal,
      propertiesPanelIsFullyExpanded
    } = this.props;
    const {
      vWidth,
      vHeight,
      zoomable,
      tabClass,
      previewWidth,
      previewHeight,
      activeKey,
    } = this.state;

    if (this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
      if (!selected_folder) {
        return null;
      }
      if ((!list_view &&
        !preview &&
        !selected_folder.pullable &&
        !(this.props.user.system_admin))
      ) {
        return null;
      }
    }

    let offset = 0;
    if (this.props.client_options.tree_panel_open) {
      offset = LayoutConstants().dashboard.applied_content_tree_width;
    }

    const tabsStyle =
      client_options.treemode === TREE_MODE_APPLIED_CONTENT
        ? {}
        : { width: "100%", height: "100%" };
    const tabPaneDivStyle =
      client_options.treemode === TREE_MODE_APPLIED_CONTENT
        ? {
            display: "table",
            margin: "0 auto",
          }
        : {};

    const showAddFolder = this.props.addFolderProps && this.props.addFolderProps.mode === 'center';
    return (
        <DynamicClasses client_options={client_options}>
          {classes => {
            return (
              <div
                className={classes.centerTabs}
                id="center_tabs_container"
                style={{
                  width: propertiesPanelIsFullyExpanded ? vWidth * 0.5 : vWidth,
                  height: vHeight + LayoutConstants().tabs.height + 16 // 16 is tabs margin
                }}
              >
                {showAddFolder && (
                  <AddFolder {...this.props.addFolderProps} />
                )}

                {!showAddFolder && this.state.showContent && (
                  <Tabs
                    style={tabsStyle}
                    onChange={this.handleChangeTabs}
                    activeKey={activeKey}
                    defaultActiveKey="1"
                    animated={{ inkBar: true, tabPane: false }}
                    className={tabClass}
                    tabBarExtraContent={this.renderTabBarExtraContent()}
                  >
                    {client_options.treemode === "resource" && (
                      <TabPane
                        style={{ width: "100%", height: "100%" }}
                        tab="Gallery"
                        key={TAB_PANE_THUMBNAIL_KEY}
                      >
                        <ResourceExplorerWrapper
                          width={vWidth}
                          folder__types={this.props.folder_types}
                          height={vHeight}
                          folders={this.props.folders}showContent
                          resource__folders={this.props.resource__folders}
                          mode="content_library"
                        />
                      </TabPane>
                    )}

                    {this.props.preview ? (
                      <TabPane
                        style={{ width: "100%", height: "100%" }}
                        tab="Preview"
                        key={TAB_PANE_PREVIEW_KEY}
                      >
                        {client_options.treemode === TREE_MODE_APPLIED_CONTENT ? (
                          <div>
                            {zoomable && !propertiesPanelIsFullyExpanded ? (
                             <div>
                               <ZoomableContainer
                                 ref={(el) => (this._zoomableContainerRef = el)}
                                 width={propertiesPanelIsFullyExpanded ? previewWidth * 0.5 : previewWidth}
                                 height={previewHeight}
                                 containerWidth={propertiesPanelIsFullyExpanded ? vWidth * 0.5 : vWidth}
                                 containerHeight={vHeight}
                                 offset={offset}
                                 selected_folder={selected_folder}
                                 folder_type={folder_type}
                                 zoomIn={this.zoomIn}
                                 zoomOut={this.zoomOut}
                               />
                               <div
                                 style={{
                                   zIndex: 5,
                                   position: 'absolute',
                                   bottom: 100,
                                   width: propertiesPanelIsFullyExpanded ? vWidth * 0.5 : vWidth,
                                   height: 40,
                                   opacity: this.state.opacity
                                 }}
                                 className="zoom-fields-wrapper"

                               >
                                 {this.renderZoomBar()}
                               </div>
                             </div>
                            ) : (
                              <PreviewContainer
                                key={this.props.selected_folder.id}
                                width={propertiesPanelIsFullyExpanded ? vWidth * 0.5 : vWidth}
                                height={vHeight}
                                selected_folder={selected_folder}
                                folder_type={folder_type}
                                mobile={mobile}
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            {zoomable && !propertiesPanelIsFullyExpanded ? (
                              <ZoomableContainer
                                ref={(el) => (this._zoomableContainerRef = el)}
                                width={previewWidth}
                                height={previewHeight}
                                selected_folder={selected_folder}
                                folder_type={folder_type}
                                zoomIn={this.zoomIn}
                                zoomOut={this.zoomOut}
                              />
                            ) : (
                              <PreviewContainer
                                key={this.state.previewKey}
                                width={vWidth}
                                height={vHeight}
                                selected_folder={selected_folder}
                                folder_type={folder_type}
                                mobile={mobile}
                              />
                            )}
                          </>
                        )}
                      </TabPane>
                    ) : null}

                    {list_view ? (
                      <TabPane tab="List" key={TAB_PANE_LIST_VIEW_KEY}>
                        {selected_folder ? (
                          <ListContainer
                            key={this.props.currentListView}
                            selected_folder={selected_folder}
                            folder_type={folder_type}
                          />
                        ) : (
                          ""
                        )}
                      </TabPane>
                    ) : null}

                    {selected_folder && selected_folder.pullable ? (
                      <TabPane tab="Admin" key={TAB_PANE_ADMIN_KEY}>
                        <AdminDashboardArea
                          key={selected_folder.id}
                          preparePublishFolder={preparePublishFolder}
                          openBackupsModal={openBackupsModal}
                        />
                      </TabPane>
                    ) : null}

                    {this.props.user.system_admin &&
                      this.props.client_options.treemode === "applied_content" && (
                        <TabPane tab="System Admin" key={TAB_PANE_SYS_ADMIN_KEY}>
                          <SystemAdminDashboardArea
                          preparePublishFolder={preparePublishFolder}
                          openBackupsModal={openBackupsModal}/>
                        </TabPane>
                      )}
                  </Tabs>
                )}
            </div>
            )
          }}
        </DynamicClasses>
    );
  }
}

const findResourceDefaultFolder = (resourceFolders, company) => {
  const finded = convertObjectIntoArray(resourceFolders).find(
    (rf) => rf.company === company && rf.folder_type === "resource_default"
  );
  return finded;
};

const mapStateToProps = (state) => {
  let selected_folder = null;
  let folder_type = null;
  let list_view = false;
  let preview = false;
  let currentListView = "folder";
  let resource_selected_folder = null;

  if (state.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
    if (state.client_options.applied_content_selected_folder) {
      selected_folder =
        state.data.folders[
          state.client_options.applied_content_selected_folder
        ];
      if (!selected_folder) {
        return {};
      }
      folder_type = state.data.folder_types[selected_folder.folder_type];
    }
    if (_.get(folder_type, "list_view.enabled", false)) {
      list_view = true;
    }
    if (folder_type && folder_type.preview) {
      if (isArray(folder_type.preview)) {
        if (_.get(folder_type, "preview[0].type", null) !== null) {
          preview = true;
        }
      } else {
        if (_.get(folder_type, "preview.type", PREVIEW_TYPE.NONE) !== PREVIEW_TYPE.NONE) {
          preview = true;
        }
      }
    }
    currentListView += `-${selected_folder?.id ?? ""}`;
  } else {
    resource_selected_folder =
      state.data.resourcetree_folders[state.client_options.resource_selected_folder];
    if (
      state.client_options.resource_tree_selected_id !== undefined &&
      state.client_options.resource_tree_selected_id !== null &&
      (state.client_options.resource_tree_selected_id.includes(
        "resource-"
      ) ||
        state.client_options.resource_tree_selected_id.includes("folder-"))
    ) {
      const resource_id_str =
        state.client_options.resource_tree_selected_id;
      list_view = true;
      if (resource_id_str.startsWith("resource")) {
        preview = true;
        const resource_id = Number(
          resource_id_str.substring(resource_id_str.indexOf("-") + 1)
        );
        if (state.data.resources && state.data.resourcetree_resource_folders) {
          selected_folder =
            state.data.resources[
              state.data.resourcetree_resource_folders[resource_id].resource
            ];
        }
        currentListView = "resource";
        list_view = false;
      } else {
        currentListView = "resource-folder";
        selected_folder =
          state.data.resourcetree_folders[
            state.client_options.resource_selected_folder
          ];
      }
    } else if (
      state.client_options.resource_tree_selected_id !== undefined &&
      state.client_options.resource_tree_selected_id !== null &&
      state.client_options.resource_tree_selected_id.includes("company-")
    ) {
      // If select company in content library then is the first folder
      list_view = true;
      currentListView = "resource-folder";
      // the selected folder is tricky, because is no the company but the default folder
      selected_folder = findResourceDefaultFolder(
        state.data.resourcetree_folders,
        state.data.user.company
      );
    }
  }

  return {
    currentListView,
    selected_folder: selected_folder,
    folder_type: folder_type,
    folder_types: state.data.folder_types,
    list_view: list_view,
    preview: preview,
    client_options: state.client_options,
    persistent_settings: state.data.persistent_settings,
    user: state.data.user,
    resource__folders: state.data.resource__folders,
    folders: state.data.folders,
    resource_selected_folder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
    updatePersistentSettings: (data) => {
      dispatch(updatePersistentSettings(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CenterArea)
);
