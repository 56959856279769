import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/authActions";
import { withRouter } from "../../hooks/withRouter";

interface ILogoutProps {
  logout: Function;
  navigate: any;
}

class Logout extends Component<ILogoutProps> {
  componentDidMount() {
    this.handleSubmit();
  }

  handleSubmit = () => {
    const onSuccess = () => this.handleSuccess();
    this.props.logout(onSuccess);
  };

  handleSuccess = () => {
    this.props.navigate("/login");
  };

  handleFail = () => {};

  render() {
    return (
      <div style={{ color: "blue", textAlign: "center", fontSize: 16 }}>
        <br />
        <br />
        Logging out...
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: (onSuccess) => {
      dispatch(logout(onSuccess));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));
