import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../enums/folder-type-enums";
import { IFolderType } from "../folders/IFolderType";
import base_types from "./base_types";
import { tree_icon_types } from "./tree_icon_types";

export const toledo_types: { [key: string]: IFolderType } = {
  toledo_root: {
    ...base_types.root,
    name: FOLDER_TYPE.toledo_root,
    display_name: "Toledo Root",
    acceptable_folder_types: [FOLDER_TYPE.toledo_grouping_folder],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      }
    },
    preview: {
      aspect_ratio: 1.78,
      type: PREVIEW_TYPE.IMAGE,
      path: "/example_preview.png"
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.client
      },
      closed: {
        ...tree_icon_types.client
      }
    }
  },
  toledo_grouping_folder: {
    ...base_types.grouping_folder,
    name: FOLDER_TYPE.toledo_grouping_folder,
    display_name: "Toledo Grouping Folder",
    instancing_options: {
      enabled: true,
      cross_project: false
    },
    acceptable_folder_types: [FOLDER_TYPE.toledo_donor_list, FOLDER_TYPE.toledo_stories, FOLDER_TYPE.timeline],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      }
    }
  },
  toledo_donor_list: {
    ...base_types.donor_list,
    name: FOLDER_TYPE.toledo_donor_list,
    display_name: "Toledo Donor List",
    acceptable_folder_types: [FOLDER_TYPE.toledo_donor],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      },
      sorting_options: {
        type: "select",
        title: "Sorting Options",
        validation: { rules: [{ name: "isRequired" }] },
        default: "rank",
        options: [
          {
            title: "Rank",
            value: "rank"
          },
          {
            title: "Sorting Order",
            value: "sorting_order"
          }
        ]
      }
    },
    preview: {
      aspect_ratio: 1.78,
      type: PREVIEW_TYPE.IMAGE,
      path: "/example_donor_list_preview.png"
    },
    list_view: {
      enabled: true,
      list_folder_types: [FOLDER_TYPE.toledo_donor], //will need to handle missing fields if more then one type
      columns: [
        {
          folder_types: [FOLDER_TYPE.toledo_donor],
          field: "list_view_selected",
          title: "",
          sort: true,
          editable: true,
          default_shown: true
        },
        { folder_types: [FOLDER_TYPE.toledo_donor], field: "name", title: "Name", sort: true, editable: false, default_shown: true }, //array of types should only be used if both fields are identical just on different folder_types
        {
          folder_types: [FOLDER_TYPE.toledo_donor],
          field: "sorting_order",
          title: "Sorting Order",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.toledo_donor],
          field: "headline",
          title: "Headline",
          sort: true,
          editable: true,
          default_shown: true
        }
      ]
    },
    // sorting_options: [
    //  { type: "rank", options: null },
    //  { type: "field", options: { field_name: "sorting_order" } }
    //],
    csv_options: {
      download: true,
      upload: true,
      include_in_csv: false,
      fields: ["name", "sorting_order", "quote", "color", "donor_bio"]
    }
  },
  toledo_stories: {
    ...base_types.stories,
    name: FOLDER_TYPE.toledo_stories,
    display_name: "Toledo Stories",
    acceptable_folder_types: [FOLDER_TYPE.toledo_story],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null
      },
      sorting_options: {
        type: "select",
        title: "Sorting Options",
        validation: { rules: [{ name: "isRequired" }] },
        default: "rank",
        options: [
          {
            title: "Rank",
            value: "rank"
          },
          {
            title: "Sorting Order",
            value: "sorting_order"
          }
        ]
      }
    },
    preview: {
      aspect_ratio: 1.78,
      type: PREVIEW_TYPE.IMAGE,
      path: "/example_donor_list_preview.png"
    },
    list_view: {
      enabled: true,
      list_folder_types: [FOLDER_TYPE.toledo_story], //will need to handle missing fields if more then one type
      columns: [
        { folder_types: [FOLDER_TYPE.toledo_story], field: "name", title: "Name", sort: true, editable: false, default_shown: true }, //array of types should only be used if both fields are identical just on different folder_types
        {
          folder_types: [FOLDER_TYPE.toledo_story],
          field: "sorting_order",
          title: "Story Sorting Order",
          sort: true,
          editable: true,
          default_shown: true
        }
      ]
    },
    // sorting_options: [
    //   { type: "rank", options: null },
    //   { type: "field", options: { field_name: "sorting_order" } }
    // ]
  },
  toledo_donor: {
    ...base_types.donor,
    name: FOLDER_TYPE.toledo_donor,
    display_name: "Toledo Donor",
    field_groups: {
      advanced: {
        title: "Advanced",
        default_expanded: false,
        order: 0
      }
    },
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: "",
        read_only: true
      },
      sorting_order: {
        type: "text",
        title: "Sorting Order",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: ""
      },
      quote: { type: "text_object", title: "Quote", validation: { rules: [] }, default: {}, resource_linkable: true },
      active: { type: "bool", title: "Active", validation: { rules: [] }, default: false },
      main_text: { type: "wysiwyg", title: "Main Text", validation: { rules: [] }, default: null, resource_linkable: true }, //don't handle yet
      headline: {
        type: "textarea",
        title: "Headline",
        validation: { rules: [{ name: "isString", min: 1, max: 75 }, { name: "isRequired" }] },
        default: ""
      },
      color: {
        type: "color",
        title: "Background Color",
        default: "#ffffff",
        // permissions: ["toledo_donor_color_field", "toledo_donor_color_field_read"]
      },
      donation_level: {
        type: "select",
        title: "Donation Level",
        validation: { rules: [] },
        default: "bronze",
        options: [
          {
            title: "Bronze",
            value: "bronze"
          },
          {
            title: "Silver",
            value: "silver"
          },
          {
            title: "Gold",
            value: "gold"
          },
          {
            title: "Platinum",
            value: "platinum"
          },
          {
            title: "Diamond",
            value: "diamond"
          }
        ]
      },
      display_options: {
        type: "multiselect",
        title: "Display Options",
        validation: { rules: [] },
        default: [],
        options: [
          {
            title: "Show Quote",
            value: "show_quote"
          },
          {
            title: "Show Detail",
            value: "detail"
          },
          {
            title: "Show Overlay",
            value: "show_overlay"
          }
        ],
        field_group: "advanced"
      },
      list_images: {
        title: "List of images",
        type: "file",
        slot_type: "fixed",
        acceptable_contents: ["image/*"],
        limit: null,
        slots: [
          {
            name: "title_image",
            title: "Title Image",
            aspect: 1
          }
        ],
        default: null,
        image_presets: ["thumbnail", "small", "medium", "large"],
        video_presets: [360]
      },
      folder_preview_image: {
        title: "List of images",
        type: "file",
        slot_type: "fixed",
        acceptable_contents: ["image/*"],
        limit: null,
        slots: [
          {
            name: "preview_image",
            title: "Preview Image"
          }
        ],
        default: null,
        sys_admin_only: true
      },
      list_random_backgrounds: {
        title: "Photos",
        type: "file",
        slot_type: "dynamic",
        acceptable_contents: ["image/*"],
        limit: 10,
        slot_names: null,
        default: null,
        image_presets: ["thumbnail", "small", "large"],
        video_presets: [360, 480, 720],
        aspect: 1.77777777777777777778
      }
    },
    preview: {
      aspect_ratio: 1.78,
      type: PREVIEW_TYPE.IMAGE,
      path: "/example_donor_preview.png"
    },
    csv_options: {
      download: false,
      upload: false,
      include_in_csv: true,
      fields: ["name", "sorting_order", "quote"]
    }
  },
  toledo_story: {
    ...base_types.story,
    name: FOLDER_TYPE.toledo_story,
    display_name: "Toledo Story",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: "",
        read_only: true
      },
      sorting_order: {
        type: "text",
        title: "Sorting Order",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: ""
      },
      quote: { type: "text_object", title: "Quote", validation: { rules: [] }, default: {}, resource_linkable: true },
      list_random_backgrounds: {
        title: "Images",
        type: "file",
        slot_type: "dynamic",
        acceptable_contents: ["image/*"],
        limit: 10,
        slot_names: null,
        default: null,
        image_presets: ["thumbnail", "small", "large"],
        aspect: 1.77777777777777777778
      },
      list_random_videos: {
        title: "Videos",
        type: "file",
        slot_type: "dynamic",
        acceptable_contents: ["video/*"],
        limit: 10,
        slot_names: null,
        default: null,
        video_presets: [720]
      },
      list_fixed_videos: {
        title: "Fixed Videos",
        type: "file",
        slot_type: "fixed",
        acceptable_contents: ["video/*"],
        limit: null,
        slots: [
          {
            name: "upper_video",
            title: "Upper Video"
          }
        ],
        default: null,
        video_presets: [720, 480]
      }
    },
    csv_options: {
      download: false,
      upload: false,
      include_in_csv: true,
      fields: ["name", "sorting_order", "quote"]
    }
  },
  toledo_timeline: {
    ...base_types.timeline,
    acceptable_folder_types: [FOLDER_TYPE.event],
    display_name: "Toledo Timeline",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: "",
        read_only: true
      }
    },
    csv_options: {
      download: true,
      upload: true,
      include_in_csv: false,
      fields: ["name", "description"]
    },
    // sorting_options: [{ type: "date", options: { field_name: "date" } }]
  },
  toledo_event: {
    ...base_types.event,
    display_name: "Toledo Event",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: ""
      },
      description: {
        type: "text",
        title: "Description",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: ""
      },
      date: { type: "date", title: "Date", validation: { rules: [] }, default: "" }
    },
    csv_options: {
      download: false,
      upload: false,
      include_in_csv: true,
      fields: ["name", "description"]
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.event
      },
      closed: {
        ...tree_icon_types.event
      }
    }
  }
};
