import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import {DragSource, DropTarget} from 'react-dnd';
import TreeItemWrap from './TreeItemWrap.tsx';
import _ from 'lodash';
import {isNull} from "../../shared/utils/generalUtils";
import {TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE} from "../constants";

let errorCanDrop = null;

const itemSource = {
  canDrag(props) {
    // Don't drag spacers!
    return !props.spacer;
  },

  isDragging(props, monitor, component) {
    // If your component gets unmounted while dragged
    // (like a card in Kanban board dragged between lists)
    // you can implement something like this to keep its
    // appearance dragged:
    return true;
  },

  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    return {
      path: props.path,
      folder__folder_id: props.folder__folder_id,
      resource__folder_id: props.resource__folder_id
    };
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      // You can check whether the drop was successful
      // or if the drag ended but nobody handled the drop
      if (errorCanDrop) {
        const {allow, error} = errorCanDrop;
        if (!allow) {
          props.onEndDrag(error);
        }
      }
      return;
    }

    // When dropped on a compatible target, do something.
    // Read the original dragged item from getItem():
    const draggedItem = monitor.getItem();
    //console.log(monitor);
    // You may also read the drop result from the drop target
    // that handled the drop, if it returned an object from
    // its drop() method.
    const droppedResult = monitor.getDropResult();

    props.onEndDrag(null, draggedItem, droppedResult);
    props.handleToggleExpandedItem(props.treeId, {
      ...props.metaData,
      path: props.path,
      folder__folder_id: props.folder__folder_id
    }, false, "name-click")
  }
};

const checkFolderCanDrop = (props, draggedItem) => {
// is a display content
  const origFolderFolderId = draggedItem.folder__folder_id;
  const origFolderFolder = props.folder__folders[origFolderFolderId];
  const destFolderFolderId = props.folder__folder_id;
  const subFolder = props.spacer ? 'parent_folder' : 'child_folder';
  const destFolderFolder = _.get(props, `folder__folders[${destFolderFolderId}]`, null);
  const destFolderId = _.get(props, `folder__folders[${destFolderFolderId}].${subFolder}`, null);
  return acceptableFolderType(props, origFolderFolder, destFolderId, destFolderFolder);
};

const isDestinationAllowToDrop = (props) => {
  return !!props.folder__folder_id || !!props.resource__folder_id;
};

const isDestinationAFolder = (props) => {
  return !!props.folder__folder_id;
};

const checkResourceCanDrop = (props, draggedItem) => {
  // is a display content


  if (!isDestinationAllowToDrop(props)) {
    const error = 'The item you are dragging can not be drop here.';
    return {allow: false, error};
  }

  if (!isDestinationAFolder(props)) {
    const error = 'The item you are dragging can not be drop into a resource.';
    return {allow: false, error};
  }

  if (draggedItem.folder__folder_id) {
    // drag a folder
    const origFolderFolderId = draggedItem.folder__folder_id;
    const origFolderFolder = props.resourcetreeFolder__folders[origFolderFolderId];
    const destFolderFolderId = props.folder__folder_id;
    const subFolder = props.spacer ? 'parent_folder' : 'child_folder';
    const destFolderFolder = _.get(props, `resourcetreeFolder__folders[${destFolderFolderId}]`, null);
    const destFolderId = _.get(props, `resourcetreeFolder__folders[${destFolderFolderId}].${subFolder}`, null);

    return acceptableFolderTypeFromResource(props, origFolderFolder, destFolderId, destFolderFolder);
  }

  return {allow: true, error: null};
};

const itemTarget = {
  drop(item, monitor, component) {
    const {folder__folder_id, resource__folder_id, treeId, spacer = false, path} = item;
    return {folder__folder_id, resource__folder_id, tree_id: treeId, spacer, path};
  },

  canDrop(props, monitor) {
    try {
      // When dropped on a compatible target, do something.
      // Read the original dragged item from getItem():
      const draggedItem = monitor.getItem();
      errorCanDrop = null;

      if (monitor.isOver()) {
        // console.log("DDTI this.props", props)
        // console.log("DDTI monitor.getDropResult()", monitor.getDropResult());
        if (props.modeContext === TREE_MODE_APPLIED_CONTENT) {
          // is a display content
          errorCanDrop = checkFolderCanDrop(props, draggedItem);
          const {allow} = errorCanDrop;
          return allow;
        } else if (props.modeContext === TREE_MODE_RESOURCE) {
          // is a content library
          errorCanDrop = checkResourceCanDrop(props, draggedItem);
          const {allow} = errorCanDrop;
          return allow;
        }
      }
      return true;
    } catch (e) {
      console.error("error validation can drop", e)
      return false;
    }
  },
}

const acceptableFolderType = (props, origFolderFolder, destFolderId, destFolderFolder) => {
  if (isNull(origFolderFolder) || isNull(destFolderId)) {
    return false;
  }

  const origFolderId = origFolderFolder.child_folder;
  const origFolder = props.folders[origFolderId];
  const destFolder = props.folders[destFolderId];
  const destFolderType = props.folderTypes[destFolder.folder_type];
  let error = null;

  if (props.spacer && origFolderFolder.parent_folder === destFolderFolder.child_folder) {
    // case when drop in first space, just after parent folder
    return {allow: true, error};
  } else if (origFolderFolder.parent_folder === destFolderFolder.child_folder) {
    // do not allow drag and drop in its same folder
    error = 'The item you are dragging can not be drop in the same folder.';
    return {allow: false, error};
  }

  if (!destFolderType || !Array.isArray(destFolderType.acceptable_folder_types)) {
    error = 'The item you are dragging has a folder type that is not allowed in the destination folder.';
    return {allow: false, error};
  }

  if (!destFolderType.acceptable_folder_types.includes(origFolder.folder_type)) {
    error = 'The item you are dragging has a folder type that is not allowed in the destination folder.';
    return {allow: false, error};
  }

  return {allow: true, error: null};
};

const acceptableFolderTypeFromResource = (props, origFolderFolder, destFolderId, destFolderFolder) => {
  if (isNull(origFolderFolder) || isNull(destFolderId)) {
    return false;
  }

  const origFolderId = origFolderFolder.child_folder;
  const origFolder = props.resourcetreeFolders[origFolderId];
  const destFolder = props.resourcetreeFolders[destFolderId];
  const destFolderType = props.folderTypes[destFolder.folder_type];
  let error = null;

  if (props.spacer && origFolderFolder.parent_folder === destFolderFolder.child_folder) {
    // case when drop in first space, just after parent folder
    return {allow: true, error};
  } else if (origFolderFolder.parent_folder === destFolderFolder.child_folder) {
    // do not allow drag and drop in its same folder
    error = 'The item you are dragging can not be drop in the same folder.';
    return {allow: false, error};
  }

  if (!destFolderType || !Array.isArray(destFolderType.acceptable_folder_types)) {
    error = 'The item you are dragging has a folder type that is not allowed in the destination folder.';
    return {allow: false, error};
  }

  if (!destFolderType.acceptable_folder_types.includes(origFolder.folder_type)) {
    error = 'The item you are dragging has a folder type that is not allowed in the destination folder.';
    return {allow: false, error};
  }

  return {allow: true, error: null};
};

class DraggableTreeItem extends Component {

  // react-dnd won't let us connect a drag source/drop target to a custom element --
  // need to use a ref
  connectDOMNode = (instance) => {
    const node = findDOMNode(instance);
    this.props.connectDragSource(node);
    this.props.connectDropTarget(node);
  }

  handleOnClick = (e, treeId, clickSource, path) => {
    if (e) {
      e.stopPropagation();
    }
    this.props.handleToggleExpandedItem(treeId, {
      ...this.props.metaData,
      path,
      folder__folder_id: this.props.folder__folder_id
    }, false, clickSource)
  }

  render() {
    return (
      <TreeItemWrap
        name={this.props.name}
        path={this.props.path}
        expanded={this.props.expanded}
        ref={this.connectDOMNode}
        style={this.props.style}
        isOver={this.props.isOver}
        canDrop={this.props.canDrop}
        isDragging={this.props.isDragging}
        handleOnClick={this.handleOnClick}
        selectedTreeId={this.props.selectedTreeId}
        treeId={this.props.treeId}
        searchText={this.props.searchText}
        addSubFolder={this.props.addSubFolder}
        addResource={this.props.addResource}
        deleteResource={this.props.deleteResource}
        deleteFolder={this.props.deleteFolder}
        itemIcons={this.props.itemIcons}
        defaultIcons={this.props.defaultIcons}
        acceptableFolderTypes={this.props.acceptableFolderTypes || []}
        folderTypes={this.props.folderTypes}
        modeContext={this.props.modeContext}
        openCsvModal={this.props.openCsvModal}
        folder__folder_id={this.props.folder__folder_id}
        parent__folder_id={this.props.parentFolder}
        csvDownload={this.props.csvDownload}
        resource_tree_crud={this.props.resource_tree_crud}
        spacer={this.props.spacer}
        resource={this.props.resource}
        resource__folder_id={this.props.resource__folder_id}
        folders={this.props.folders}
        isContent={this.props.isContent}
        folder__folders={this.props.folder__folders}
        meta={this.props.metaData}
        saveOptions={this.props.saveOptions}
        folder_type={this.props.folder_type}
        showGray={this.props.showGray}
        showChildAcceptableIcon={this.props.showChildAcceptableIcon}
        resourcetreeFolders={this.props.resourcetreeFolders}
      >
      </TreeItemWrap>
    );
  }
}

const dragSource = DragSource('TREE_ITEM', itemSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}));

const dropTarget = DropTarget('TREE_ITEM', itemTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
  isDragging: monitor.isOver(),
}));

export default _.flow(dragSource, dropTarget)(DraggableTreeItem);
