import get from "lodash/get";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import { convertPxStringToEm } from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import DonorListWithRegions2108 from "../../standard/DonorListWithRegions2108";
import {TRANSITION_CLASS} from "../../../../shared-global/enums/ui-enums";
import transitions from "../../../../shared-global/constants/transitions";

const BerkeleyDonorList = (props) => {
  const [containerEl, setContainerEl] = useState(null);
  const [donorListWrapperEl, setDonorListWrapperEl] = useState(null);
  const [timeToMount, setTimeToMount] = useState(false);
  const [donorsScreens, setDonorsScreens] = useState([]);
  const [canRenderDonorList, setCanRenderDonorList] = useState(false);
  const [donorsScreenIndex, setDonorsScreenIndex] = useState(0);

  const colorHeight = props.containerHeight;

  const { getBaseFolder, getBaseFolderFieldValue } = useFolder(props.data.base_folder);
  const { getBaseFolderFontSize, getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);

  const baseFolder = getBaseFolder();

  const fontSize = getBaseFolderFontSize();
  const useStyles = getBaseFolderRichTextStylesFn();
  const classes = useStyles();

  const sectionHeight = props.refHeight / 2;

  const screenLeftRightPadding = Number(getBaseFolderFieldValue("screen_left_and_right_padding", 0));
  const screenTopBottomPadding = Number(getBaseFolderFieldValue("screen_top_and_bottom_padding", 0));

  const setScreensCovered = (screens) => {
    setDonorsScreens(screens);
    setTimeout(() => {
      setCanRenderDonorList(true);
    }, 1000);
  };

  const getPropertiesFromFolder = () => {
    const donorNameFontSize = convertPxStringToEm(get(baseFolder, "fields.donor_names.fontSize", 16));

    const header = getBaseFolderFieldValue("donor_list_header");
    const description = getBaseFolderFieldValue("description");
    const category = getBaseFolderFieldValue("category");

    // const listMarginLeft = Number(getBaseFolderFieldValue("list_margin_left", 5));
    // const listMarginTop = Number(getBaseFolderFieldValue("list_margin_top", 0));
    

    const backgroundColorOverlay = getBaseFolderFieldValue("donor_background_image_overlay", "");

    return {
      header,
      description,
      category,
      // listMarginLeft,
      // listMarginTop,
      donorNameFontSize,
      backgroundColorOverlay
    };
  };

  const {
    header,
    description,
    category,
    donorNameFontSize,
    backgroundColorOverlay
  } = getPropertiesFromFolder();

  useEffect(() => {
    setTimeout(() => setTimeToMount(true), 500);
  }, []);

  const donorTransition = useMemo(() => {
    const transitionName = get(baseFolder, "fields.donor_transition", TRANSITION_CLASS.TRASITION_FADE);
    let foundTransition = transitions.find((t) => t.value === transitionName);
    if (!foundTransition) {
      foundTransition = transitions.find((t) => t.value === TRANSITION_CLASS.TRASITION_FADE);
    }
    return foundTransition;
  }, [baseFolder]);

  const setCurrentScreenIndex = (index) => {
    // Fix implemented because the cycle was starting over and on the
    // exit transition more screens were being rendered than the ones that
    // were supposed to be rendered (AV-2055)
    if (index > 0) {
      setTimeout(() => {
        setDonorsScreenIndex(index);
      }, donorTransition?.fade_out_duration);
    }
  };

  const getDonorListHeightAndOffset = useCallback(() => {
    let effectiveDonorListHeight = props.refHeight;
    let totalOffset = 0;

    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl);
      const paddingArr = computedStyles.getPropertyValue("padding-top").split("px");
      const paddingBotArr = computedStyles.getPropertyValue("padding-bottom").split("px");
      const paddingTopNumber = Number(paddingArr[0]);
      const paddingBottomNumber = Number(paddingBotArr[0]);
      effectiveDonorListHeight -= paddingTopNumber + paddingBottomNumber;
      totalOffset += paddingTopNumber;
    }

    if (donorListWrapperEl) {
      const computedStyles = window.getComputedStyle(donorListWrapperEl);
      const marginArr = computedStyles.getPropertyValue("margin-top").split("px");
      const marginNumber = Number(marginArr[0]);
      effectiveDonorListHeight -= marginNumber;
      totalOffset += marginNumber;
    }

    return { effectiveDonorListHeight, totalOffset };
  }, [containerEl, donorListWrapperEl, props.refHeight]);

  const getOverlayWidth = () => {
    if (donorsScreens.length) {
      switch (donorsScreens[donorsScreenIndex].length) {
        case 1:
          return props.containerWidth / 3
        case 2:
        case 3:
          return (props.containerWidth / 3) * 2
        case 4:
        case 5:
          return props.containerWidth
        default:
          return 0
      }
    }
    return 0
  };

  const getOverlayHeight = () => {
    return colorHeight;
  };

  const getOverlayTop = () => {
    return getDonorListHeightAndOffset().totalOffset;
  };

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight
      }}
    >
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["donor_background_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            videoIsShorterThanCycleTime: true
          }}
        />
      </div>

      {/* Background color overlay */}
      <TransitionGroup>
        <CSSTransition
          key={canRenderDonorList.toString() + (donorsScreens[donorsScreenIndex] ?? []).length}
          timeout={5000}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          {backgroundColorOverlay && canRenderDonorList ? (
            <div
              style={{
                position: "absolute",
                width: getOverlayWidth(),
                height: getOverlayHeight(),
                backgroundColor: backgroundColorOverlay
              }}
            />
          ) : <></>}
        </CSSTransition>
      </TransitionGroup>
      <div
        style={{
          width: props.containerWidth,
          height: props.containerHeight,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          // paddingTop: 5 * fontSize,
          // paddingLeft: 5 * fontSize,
          // paddingRight: 5 * fontSize,
          // paddingBottom: fontSize * 2.75
        }}
        ref={(htmlEl) => setContainerEl(htmlEl)}
      >
        <div
          style={{
            position: 'absolute',
            width: props.containerWidth,
            height: props.containerHeight,
          }}
        >
          <div
            style={{
              width: props.containerWidth,
              flexWrap: "wrap",
              display: "flex",
              position: "relative",
              overflow: "hidden",
              height: "auto",
              marginTop: screenTopBottomPadding / 100 * props.containerHeight / 2,
              marginLeft: screenLeftRightPadding / 100 * props.containerWidth / 3
            }}
          >
            <div
              style={{
                width: props.containerWidth,
                alignSelf: "flex-start",
                position: "relative"
              }}
            >
              <div
                style={{
                  width: props.containerWidth / 3 - 5 * fontSize * 2,
                  position: "relative"
                }}
              >
                <div className={classes.componentContainer}>
                  <RichTextRender htmlString={header} />
                </div>
                <div className={classes.componentContainer}>
                  <RichTextRender htmlString={description} />
                </div>
                <div className={classes.componentContainer}>
                  <RichTextRender htmlString={category} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {containerEl && timeToMount && (
            <div
              style={{
                position: "relative",
                height: getDonorListHeightAndOffset().effectiveDonorListHeight,
                width: props.containerWidth,
                opacity: canRenderDonorList ? 1 : 0
              }}
            >
              <DonorListWithRegions2108
                {...props}
                key={canRenderDonorList.toString()}
                containerHeight={getDonorListHeightAndOffset().effectiveDonorListHeight}
                data={{
                  ...props.data,
                  componentSpecificData: {
                    ...props.data.componentSpecificData,
                    donor_transition: donorTransition?.value,
                    setScreensCovered,
                    setCurrentScreenIndex: setCurrentScreenIndex,
                    horizontal_screens: 3,
                    needsColor: true,
                    colorWidth: getOverlayWidth(),
                    colorHeight: getOverlayHeight(),
                    colorTop: getOverlayTop(),
                    backgroundColorOverlay: backgroundColorOverlay,
                    vertical_screens: 2,
                    donor_name_field: "name",
                    do_not_repeat_donors: true,
                    use_em_new_format: true,
                    time_to_fade_donors_in: 0,
                    sort_type: "COLUMN_TO_COLUMN",
                    donor_name_style: {
                      font: get(baseFolder, "fields.donor_names.font", "Gotham Book"),
                      fontSize: `${donorNameFontSize}`,
                      color: get(baseFolder, "fields.donor_names.color", "#000000"),
                      fontWeight: get(baseFolder, "fields.donor_names.bold", false) ? "bold" : "normal",
                      textDecoration: get(baseFolder, "fields.donor_names.bold", false) ? "underline" : "normal",
                      fontStyle: get(baseFolder, "fields.donor_names.italic", false) ? "italic" : "normal",
                      textAlign: get(baseFolder, "fields.donor_names.alignment", "left"),
                      lineHeight: get(baseFolder, "fields.donor_names.lineHeight", 1)
                    },
                    disabled_screens: "[0,0]"
                  }
                }}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default BerkeleyDonorList;
