import React, { useContext } from "react";
import { PreviewContext } from "../../../Contexts";
import Slide1911 from "../Slide1911";
import { IResourceFolder } from "../../../../shared-global/interfaces/models/resource__folder.interface";

export type CheckerboardSlideProps = {
  componentData: any;
  slides: IResourceFolder[];
  slot: string;
  index: number;
  width: number;
  height: number;
};

export const CheckerboardSlide = (props: CheckerboardSlideProps) => {
  const { aws, aws_bucket, mode, webview_signedurls } =
    useContext(PreviewContext);

  if (!props.slides[props.index]) return null;

  return (
    <Slide1911
      aws={aws}
      aws_bucket={aws_bucket}
      mode={mode}
      containerWidth={props.width}
      containerHeight={props.height}
      handleEndOfPlay={null}
      data={{
        ...props.componentData,
        index: props.slides[props.index].rank,
        slotNames: [props.slot],
        placeholder: "Image",
        fieldType: "image",
        showPlaceholder: false,
        backgroundSize: "cover",
      }}
      webview_signedurls={webview_signedurls}
    />
  );
};
