import { memphis_slide } from "./memphis_slide";
import { memphis_donor_list } from "./memphis_donor_list";
import { memphis_slides } from "./memphis_slides";
import { memphis_root } from "./memphis_root";
import { memphis_donor } from "./memphis_donor";

export const memphis_types = {
  memphis_root,
  memphis_donor_list,
  memphis_donor,
  memphis_slides,
  memphis_slide
};
