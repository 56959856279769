import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import Projects from './Projects'
import formConfig from '../../config/company/company_fields';
import { connect } from "react-redux";
import { Collapse } from 'antd';
import { addCompany } from '../../actions/companyActions';
import { getCompany, updateCompany } from '../../actions/companyActions';
import UsersTable from './UsersTable';
import Roles from './Roles';
import Permissions from './Permissions';
import CompanyBreadCrumbs from './CompanyBreadCrumbs';
import { hasPermission } from '../../utils/permissionUtils';
import Locations from './Locations';
import AlertNotification from '../layout/AlertNotification';
import { BackLink } from "../../components/utils/BackLink";
import { withRouter } from '../../hooks/withRouter';

const { Panel } = Collapse;
class CompanyForm extends Component {
  constructor(props) {
    super(props);
    let editMode = false;
    if (this.props.params.companyId) {
      editMode = true;
    }
    this.state =
    {
      editMode: editMode,
      initialized: false,
      submitting: false
    };
  }

  componentDidMount() {
    if (this.state.editMode === true) {
      this.props.getcompany(this.props.params.companyId, () => { this.onInit() }, this.onError);
    }
    else {
      this.onInit();
    }
  }

  onInit = () => {
    this.setState({
      initialized: true
    })
  }


  onSubmit = (values) => {
    this.setState({ submitting: true })
    if (this.state.editMode === false) {
      this.props.addCompany(values.name, values.active, this.onInsert, this.onError);
    }
    else {
      this.props.updateCompany(this.props.params.companyId, values.name, values.active, values.sns_alerts_enabled ?? false, values.sns_alerts_email_address ?? null, this.onUpdate, this.onError);
    }
  }

  onUpdate = () => {
    this.setState({ submitting: false })
    AlertNotification('success', 'Sucess', 'Company updated')
  }

  onInsert = () => {
    this.setState({ submitting: false })
    this.setState({
      submitting: false
    })
    AlertNotification('success', 'Sucess', 'Company created')
    this.props.navigate("/companies")
  }


  onError = (e) => {
    console.log(e)
    this.setState({ submitting: false })
  }

  render() {
    if (!this.state.initialized) {
      return <div>Loading...</div>
    }
    let obj;
    if (this.state.editMode === true && this.props.company === undefined) {
      return null;
    }
    let mode = "";
    if (this.state.editMode === false) {
      obj = {
        name: "",
        active: false
      };
      mode = 'add';
    } else {
      mode = 'edit';
      obj = {
        name: this.props.company.name,
        active: this.props.company.active,
        sns_alerts_enabled: this.props.company.sns_alerts_enabled,
        sns_alerts_email_address: this.props.company.sns_alerts_email_address,
      };
    }
    if (mode === 'edit') {
      let projectUrl = "/company/" + this.props.company.id + "/project/new/";
      let userUrl = "/company/" + this.props.company.id + "/user/new/";
      let roleUrl = "/company/" + this.props.company.id + "/role/new/";
      let permissionUrl = "/company/" + this.props.company.id + "/permission/new/";
      return (
        <div>
          <div>
            <CompanyBreadCrumbs crumbs={this.props} />
          </div>
          <div>
            <BackLink />
          </div>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="Company Details" key="1">
              <div>
                <DynamicForm
                  submitting={this.state.submitting}
                  fieldGroupsConfig={formConfig.field_groups}
                  fieldsConfig={formConfig.fields}
                  fieldValues={obj}
                  mode={mode}
                  onSubmit={this.onSubmit}
                  onError={this.onError}
                  enableFieldGrouping={false}
                  canUpdate={hasPermission(this.props.grouped_permissions, 'company', 'update', this.props.user, null, null, this.props.user?.company ?? null)}
                />
              </div>
            </Panel>
            <Panel header="Locations" key="2" style={{ padding: 0, margin: 0 }}>
              <Locations companyId={this.props.company.id} />
            </Panel>
            <Panel header="Projects" key="3" style={{ padding: 0, margin: 0 }}>
              <Projects companyId={this.props.company.id} projectUrl={projectUrl} />
            </Panel>
            <Panel header="Users" key="4" >
              <UsersTable companyId={this.props.company.id} userUrl={userUrl} users={this.props.users} />
            </Panel>
            <Panel header="Roles" key="5" >
              <Roles companyId={this.props.company.id} roleUrl={roleUrl} />
            </Panel>
            <Panel header="Permissions" key="6" >
              <Permissions companyId={this.props.company.id} permissionUrl={permissionUrl} />
            </Panel>
          </Collapse>


        </div>
      );
    }
    else {
      return (
        <div>
          <div>
            <CompanyBreadCrumbs crumbs={this.props} />
          </div>
          <div>
            <BackLink />
          </div>
          <div>
            <DynamicForm
              submitting={this.state.submitting}
              fieldGroupsConfig={formConfig.field_groups}
              fieldsConfig={formConfig.fields}
              fieldValues={obj}
              mode={mode}
              onSubmit={this.onSubmit}
              onError={this.onError}
              name={"Company Details "}
              enableFieldGrouping={false}
            />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let company = null;
  if (ownProps.params.companyId) {
    company = state.data.companies[ownProps.params.companyId] || null;
  }
  return {
    company: company,
    users: state.data.users,
    grouped_permissions: state.data.user_grouped_permissions,
    user: state.data.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCompany: (name, active, onSuccess, onFail) => {
      dispatch(addCompany(name, active, onSuccess, onFail));
    },
    updateCompany: (id, name, active, sns_alerts_enabled, sns_alerts_email_address, onSuccess, onFail) => {
      dispatch(updateCompany(id, name, active, sns_alerts_enabled, sns_alerts_email_address, onSuccess, onFail));
    },
    getcompany: (id, onSuccess, onFail) => {
      dispatch(getCompany(id, onSuccess, onFail));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyForm));