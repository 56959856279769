import {
  COMPONENT_NAME,
  FOLDER_TYPE, FOLDER_TYPE_SELECTOR, PREVIEW_TYPE,
} from '../../../../enums/folder-type-enums';
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const berkeley_content_stories: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.berkeley_content_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.berkeley_template_001,
    FOLDER_TYPE.berkeley_template_002,
    FOLDER_TYPE.berkeley_template_003,
    FOLDER_TYPE.berkeley_template_004,
    FOLDER_TYPE.berkeley_template_005,
  ],
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    }
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.berkeley_template_001,
      FOLDER_TYPE.berkeley_template_002,
      FOLDER_TYPE.berkeley_template_003,
      FOLDER_TYPE.berkeley_template_004,
      FOLDER_TYPE.berkeley_template_005,
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.berkeley_template_001,
          FOLDER_TYPE.berkeley_template_002,
          FOLDER_TYPE.berkeley_template_003,
          FOLDER_TYPE.berkeley_template_004,
          FOLDER_TYPE.berkeley_template_005,
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.berkeley_template_001,
          FOLDER_TYPE.berkeley_template_002,
          FOLDER_TYPE.berkeley_template_003,
          FOLDER_TYPE.berkeley_template_004,
          FOLDER_TYPE.berkeley_template_005,
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [
          FOLDER_TYPE.berkeley_template_001,
          FOLDER_TYPE.berkeley_template_002,
          FOLDER_TYPE.berkeley_template_003,
          FOLDER_TYPE.berkeley_template_004,
          FOLDER_TYPE.berkeley_template_005,
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.berkeley_template_001,
          FOLDER_TYPE.berkeley_template_002,
          FOLDER_TYPE.berkeley_template_003,
          FOLDER_TYPE.berkeley_template_004,
          FOLDER_TYPE.berkeley_template_005,
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.berkeley_template_001,
          FOLDER_TYPE.berkeley_template_002,
          FOLDER_TYPE.berkeley_template_003,
          FOLDER_TYPE.berkeley_template_004,
          FOLDER_TYPE.berkeley_template_005,
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    mode: "center",
    scrollable: true,
    option_height: 180,
    columns: 0
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
