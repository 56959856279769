export default {
  field_groups: {
    default: {
      title: 'Location: ',
      defaultExpaded: true,
    }
  }, fields: {
    name: {
      type: 'text',
      title: 'Name',
      validation: {rules: [{name: 'isRequired'}]}
    },
    shows_in_tree: {
      type: 'bool',
      title: 'Show in tree?'
    }
  }
}