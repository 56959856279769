// @ts-ignore
import React, {useCallback, useEffect, useMemo, useState} from "react";
// @ts-ignore
import get from "lodash/get";
// @ts-ignore
import {createUseStyles} from "react-jss";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {useScale} from "../../../hooks/useScale";
import {useFolder} from "../../../hooks/useFolder";
import PhoenixQrWidget from "./PhoenixQrWidget";

const PhoenixMessage = (props) => {
  const {getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue} = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const baseFolderFields = baseFolder.fields
  const {getBaseFolderRichTextStylesFn} = useScale(props.data.base_folder);
  const useStyles = getBaseFolderRichTextStylesFn();
  const classes = useStyles();


  const qrPosition = getBaseFolderFieldValue("qr_position", "bottom-left")
  const backgroundColor = getBaseFolderFieldValue("message_background_color")
  const header = getBaseFolderFieldValue("message_header")
  const body = getBaseFolderFieldValue("message_body")
  const messagePosition = getBaseFolderFieldValue("message_position", "bottom")

  const getMessagePosition = () => {
    switch (messagePosition) {
      case "top":
        return "0";
      case "middle":
        return "67.5em";
      case "bottom":
        return "135em";
    }
  }

  return (
    <div style={{
      position: 'absolute',
      width: props.containerWidth,
      height: props.containerHeight,
      zIndex: 0,
    }}>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          top: "0%",
          bottom: "0%",
          left: "0%",
          right: "0%",
          zIndex: 0,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            componentSpecificData: {
              ...props.data?.componentSpecificData,
              useKeyCycler: false,
            },
          }}
          handleEndOfPlay={null}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      <div style={{
        width: "100%",
        height: "67.5em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        backgroundColor: backgroundColor,
        position: "relative",
        top: getMessagePosition()
      }}>
        <div style={{
          width: "120em",
          height: "67.5em",
          flexDirection: "column",
          justifyContent: "center",
          padding: "9em",
          position: "relative",
          display: "flex",
        }}>
          <div
            style={{
              marginBottom: "4em",
              position: "relative"
            }}
            className={classes.componentContainer}
          >
            <RichTextRender htmlString={header}/>
          </div>
          <div
            style={{
              position: "relative"
            }}
            className={classes.componentContainer}
          >
            <RichTextRender htmlString={body}/>
          </div>
        </div>
      </div>
      <PhoenixQrWidget
        {...props}
        baseFolderFields={baseFolderFields}
        qrPosition={qrPosition}
        baseFolderType={baseFolderType}
      />
    </div>
  )
};

export default PhoenixMessage;
