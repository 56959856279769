import React, {useCallback} from "react"
import {getFieldValue} from "../../../utils/generalUtils";
import {IDisplayComponentProps} from "../../../interfaces/display-component-props.interface";
import MediaCarousel from "../../standard/MediaCarousel";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";
import MarinQuote from "./MarinQuote";

const MarinLargeMediaWithQuote = (props: IDisplayComponentProps) => {

  const backgroundColor = getFieldValue(
    props.baseFolder,
    "background_color",
    props.baseFolderType
  );

  const quoteBackgroundColor = getFieldValue(
    props.baseFolder,
    "quote_background_color",
    props.baseFolderType
  );

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  return (
    <div
      style={{
        width: props.containerWidth,
        height: props.containerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor,
      }}
    >
      <div
        style={{
          height: "70%",
          width: "100%"
        }}
      >
        <MediaCarousel
          {...props}
          componentSpecificData={{
            customStyleImage: {
              maxHeight: '84em',
              width: "100%",
              backgroundPosition: "center",
              backgroundSize: "cover"
            },
            customStyleVideo: {
              hideOffset: true,
              maxHeight: '40em',
              position: 'relative'
            },
            slickConfiguration: {
              fade: true,
              speed: 2000
            }
          }}
          resourceFoldersCarousel={resourceFoldersCarousel}
          width="100%"
          height="100%"
          slotNames={["image"]}
          dotsStyles={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            marginTop: '2em'
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "30%",
          backgroundColor: quoteBackgroundColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "top"
        }}
      >
        <div
          style={{
            padding: "6em 8em",
            width: "50%"
          }}
        >
          <MarinQuote
            {...props}
            quoteKey="quote_body_left"
            quote={props.baseFolder}
            baseFolderType={props.baseFolderType}
          />
        </div>

        <div
          style={{
            padding: "6em 8em",
            width: "50%"
          }}
        >
          <MarinQuote
            {...props}
            quoteKey="quote_body_right"
            quote={props.baseFolder}
            baseFolderType={props.baseFolderType}
          />
        </div>
      </div>
    </div>
  )
}

export default MarinLargeMediaWithQuote
