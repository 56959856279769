import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const fremont_donor_list_category: IFolderType = {
  folder_category: FOLDER_CATEGORY.CONTENTS,
  name: FOLDER_TYPE.fremont_donor_list_category,
  display_name: "Fund Giving Level",
  acceptable_folder_types: [FOLDER_TYPE.fremont_donor_list_002], // New type is giving level.
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    display_name: {
      type: "text",
      title: "Display Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  tree_dropped_into_action: [{ action: "move", options: {} }]
};
