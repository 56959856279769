import { FOLDER_TYPE } from "../../shared-global/enums/folder-type-enums";

export const glenwoodRelevantStoryTypeNames = [
  FOLDER_TYPE.glenwood_template_001,
  FOLDER_TYPE.glenwood_template_002,
  FOLDER_TYPE.glenwood_template_003,
  FOLDER_TYPE.glenwood_template_004,
  FOLDER_TYPE.glenwood_template_005,
]

export const glenwoodRelevantSubStoryTypeNames = [
  FOLDER_TYPE.glenwood_stories,
]

export const glenwoodRelevantDonorListMenuTypeNames = [
  FOLDER_TYPE.glenwood_donor_list_group,
]

export const glenwoodRelevantFolderTypeNames = [
  ...glenwoodRelevantStoryTypeNames,
  ...glenwoodRelevantSubStoryTypeNames,
  ...glenwoodRelevantDonorListMenuTypeNames,
  FOLDER_TYPE.glenwood_donor,
]