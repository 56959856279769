import { springfield_portrait_category_with_giving_level } from "./springfield_portrait_category_with_giving_level";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FOLDER_TYPE } from "../../../../enums/folder-type-enums";
import { tree_icon_types } from "../tree_icon_types";

export const springfield_portrait_donor_lists: IFolderType = {
  ...springfield_portrait_category_with_giving_level,
  name: FOLDER_TYPE.springfield_portrait_donor_lists,
  display_name: "Lists",
  acceptable_folder_types: [
    FOLDER_TYPE.springfield_portrait_category_with_giving_level,
    FOLDER_TYPE.springfield_portrait_donor_list
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
