import {isNull} from '../shared/utils/generalUtils'
import {arrayFromKeyedObject} from '../shared/utils/collectionUtils'
import { TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE, TREE_MODE_SELECT_RESOURCE, TREE_MODE_SELECT_APPLIED_CONTENT } from '../components/constants'
import { some } from 'lodash'
import { searchInFolders } from '../shared/utils/searchUtils';


function recursiveParentJoins (_searchFoldersExpanded, _searchFolderFolders, filteredFolders, folders, folderTypes, join) {
  if (!_searchFoldersExpanded.includes(join.child_folder)) {
    _searchFoldersExpanded.push(join.child_folder);
  }
  if (!isNull(join.parent_folder)) {
    let parent_joins = _searchFolderFolders.filter(x => x.child_folder === join.parent_folder)
    parent_joins.filter(jj => {
      if (!jj.parent_folder) {
        return true;
      }
      const foundFolder = folders.find(f => f.id === jj.parent_folder);
      if (foundFolder) {
        if(folderTypes !== undefined){
          const folderType = folderTypes[foundFolder.folder_type];
          if (folderType && folderType.hide_nested_children !== undefined && folderType.hide_nested_children !== null) {
            return !folderType.hide_nested_children;
          }
        }
      }
      return true;
    }).map(jj => {
      return recursiveParentJoins(_searchFoldersExpanded, _searchFolderFolders, filteredFolders, folders, folderTypes, jj);
    })
  }
}

export function handleTreeSearch(
  value,
  modeContext,
  foldersProps,
  folder__foldersProps,
  resourcetree_foldersProps,
  resourcesProps,
  resourcetree_resource__foldersProps,
  resourcetree_folder__foldersProps,
  userCompanyProps,
  locationsProps,
  projectsProps,
  folderResults,
  resourceResults,
  folderTypes,
  )
{
  let cleanSearch = value;
  let _searchFolderFolders, _searchFoldersExpanded;
  if (value === null || value === undefined){
    value = ''
  }
  let tree_type = modeContext
  switch(modeContext){
    case TREE_MODE_SELECT_RESOURCE:
      tree_type = TREE_MODE_RESOURCE;
      break;
    case TREE_MODE_SELECT_APPLIED_CONTENT:
      tree_type = TREE_MODE_APPLIED_CONTENT
      break;
    default:
      break;
  }
  let folders, resources, resourcetree_resource__folders
  if (tree_type === TREE_MODE_APPLIED_CONTENT){
    folders = arrayFromKeyedObject(foldersProps);
    _searchFolderFolders = arrayFromKeyedObject(folder__foldersProps)
  }
  else {
    folders = arrayFromKeyedObject(resourcetree_foldersProps);
    resources = arrayFromKeyedObject(resourcesProps);

    //for opening parents of resources
    resourcetree_resource__folders = arrayFromKeyedObject(resourcetree_resource__foldersProps);
    _searchFolderFolders = arrayFromKeyedObject(resourcetree_folder__foldersProps)
  }

  if (value.replace(/\s/g, '').length !== 0) { //ensure we have values other then blank spaces
      let search = value.toLowerCase();

      if (!folderResults){
        folderResults = [];
      }

      if (!resourceResults){
        resourceResults = [];
      }

      let filteredFolders = folders.filter(x=> folderResults.includes(String(x.id)))

      if(resourceResults.length == 0 && filteredFolders.length==0 && tree_type !== "resource"){
        filteredFolders = folders.filter(folder=> folder.company === userCompanyProps && folder.name.toLowerCase().indexOf(search) !== -1)
      }

      if (tree_type === "resource"){
        resources = resources.filter(x => x.modifiedName.toLowerCase().includes(search) || resourceResults.includes(String(x.id)));
      }

      //these are the filtered folders now find the joins for expanding
      _searchFoldersExpanded = [];

      //add folders container resources
      if (tree_type === "resource") {
        resources.map(resource => {
          const matched_resource__folders = resourcetree_resource__folders.filter(resource__folder =>
            resource__folder.resource === resource.id
          )
          for (var rr = 0; rr < matched_resource__folders.length; rr++) {
            filteredFolders.push(resourcetree_foldersProps[matched_resource__folders[rr].folder])
          }
          return null;
        })
      }

      filteredFolders = filteredFolders.filter((f) => f !== undefined)

      //find all joins with child_folder xyz, expand the parent folder, and recurse parents up to null
      filteredFolders.map(folder => {
        let ff_joins = _searchFolderFolders.filter(ff => {
          return ff.child_folder === folder.id
        })
        ff_joins.map(join => {
          return recursiveParentJoins(_searchFoldersExpanded, _searchFolderFolders, filteredFolders, folders, folderTypes, join);
        })
        return null;
      })

      for (var a = 0; a < _searchFoldersExpanded.length; a++) {
        _searchFoldersExpanded[a] = 'folder-' + _searchFoldersExpanded[a]
      }
      if (tree_type===TREE_MODE_APPLIED_CONTENT){
        //TODO need to open project folders, temporary method to open all locations and projects
        _searchFoldersExpanded.push('company-'+userCompanyProps)
        for (let location in locationsProps){
          _searchFoldersExpanded.push('location-'+locationsProps[location].id)
        }
        for (let project in projectsProps){
          _searchFoldersExpanded.push('project-'+projectsProps[project].id)
        }
      }
      else{
        _searchFoldersExpanded.push('company-'+userCompanyProps)
      }
  }
  else {
    cleanSearch = null;
  }
  return {_searchFoldersExpanded, cleanSearch}
}

export function isInPresentationsFolder(path, folder__folders, folders, folderTypes) {
  return some(path, (folderFolderId) => {
    const folderFolder = folder__folders[folderFolderId]
    if (!folderFolder) return false
    if (folderFolder.tree_type === TREE_MODE_RESOURCE) return false
    const folder = folders[folderFolder.child_folder]
    const folderType = folderTypes[folder.folder_type]
    return folderType.isPresentations === true
  })
}

export function isInPresentationFolder(path, folder__folders, folders, folderTypes, folderId) {
  return some(path, (folderFolderId) => {
    const folderFolder = folder__folders[folderFolderId]
    if (!folderFolder) return false
    if (folderFolder.tree_type === TREE_MODE_RESOURCE) return false
    if (folderFolder.parent_folder) {
      const folder = folders[folderFolder.parent_folder]
      const folderType = folderTypes[folder.folder_type]
      return folderType.isPresentation === true
    }
    return false
  })
}

export function isInDisplayFolder(path, folder__folders, folders, folderTypes) {
  return some(path, (folderFolderId) => {
    const folderFolder = folder__folders[folderFolderId]
    if (!folderFolder) return false
    if (folderFolder.tree_type === TREE_MODE_RESOURCE) return false
    const folder = folders[folderFolder.child_folder]
    const folderType = folderTypes[folder.folder_type]
    return folderType.isDisplay === true
  })
}

export function isLowLevelItem(path, folder__folders, folders, folderTypes) {
  if(!path || path.length < 2) return false;
  const folderFolder = folder__folders[path[path.length - 1]];
  if (!folderFolder) return false;
  const parentFolder = folders[folderFolder.parent_folder];
  const isInPresentation = isInPresentationFolder(path, folder__folders, folders, folderTypes);
  const isSearch = parentFolder.folder_type.includes('search');
  const isMenu = parentFolder.folder_type.includes('menu');
  const isPresentation = parentFolder.folder_type.includes('presentation');
  return isInPresentation && !isPresentation && !isSearch && !isMenu;
}

export function isInContentFolder(path, folder__folders, folders, folderTypes) {
  return some(path, (folderFolderId) => {
    const folderFolder = folder__folders[folderFolderId]
    if (folderFolder.tree_type === TREE_MODE_RESOURCE) return false
    const folder = folders[folderFolder.child_folder]
    const folderType = folderTypes[folder.folder_type]
    return folderType.isContent === true
  })
}

export function findAllPossiblePathsToFolder(folderId, folder__folders) {
  const folderFolders = folder__folders.filter(ff => ff.child_folder === folderId)
  return folderFolders.reduce((arr, ff) => {
    if (ff.parent_folder) {
      const parentPaths = findAllPossiblePathsToFolder(ff.parent_folder, folder__folders)
      return arr.concat(parentPaths.map(path => {
        path.push(ff.id)
        return path
      }))
    } else {
      arr.push([ff.id])
      return arr
    }
  }, [])
}
