import {
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_BY_FOLDER
} from './actionTypes';

export const get_audit_logs = (companyId, onSuccess?, onFail?) => ({
  type: GET_AUDIT_LOGS,
  payload: {
    companyId
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getAuditLogsByFolder = (folder_id, onSuccess?, onFail?) => ({
  type: GET_AUDIT_LOGS_BY_FOLDER,
  payload: {
    folder_id
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});