import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";

export const boston_donor_sea: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.boston_donor_sea,
  display_name: "Boston Donor Sea",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor_sea_categories, FOLDER_TYPE.boston_donor_sea_backgrounds],
  fields: {
    name,
    default_preview: {
      type: "select",
      title: "Default Preview",
      validation: { rules: [{ name: "isRequired" }] },
      default: "1",
      options: [
        {
          title: "Mission Bernal",
          value: "1"
        },
        {
          title: "Pacific",
          value: "2"
        }
      ]
    },
    fade_in_donors_time: {
      type: "integer",
      title: "Fade In Donors Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip: "Time it will take for donors to fade in after the background is cycled into."
    },
    fade_out_donors_time: {
      type: "integer",
      title: "Fade Out Donors Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip: "Time it will take for donors to fade out before the background is cycled."
    },
    screen_left_and_right_padding: {
      type: "integer",
      title: "Screen Left and Right Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region"
    },
    screen_top_and_bottom_padding: {
      type: "integer",
      title: "Screen Top and Bottom Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region"
    },
    min_margin_between_names_horizontally: {
      type: "integer",
      title: "Min Horizontal distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Minimum distance a name can have from another horizontally. The distance is expressed in percentage and is relative to the width of the region minus the specified margin"
    },
    min_margin_between_names_vertically: {
      type: "integer",
      title: "Min Vertical distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Minimum distance a name can have from another vertically. The distance is expressed in percentage and is relative to the height of the region minus the specified margin"
    },
    horizontal_color_overlay: {
      type: "color",
      title: "Horizontal Color Overlay",
      default: "",
      sys_admin_only: true,
      tooltip: "Color overlay for the horizontal portion of the donor sea"
    },
    vertical_color_overlay: {
      type: "color",
      title: "Vertical Color Overlay",
      default: "",
      tooltip: "Color overlay for the vertical portion of the donor sea",
      sys_admin_only: true
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true
    }
  },
  preview: [
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston1x2and1x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "1x2, 1x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/1x2_1x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_1x2_and_1x5_and_3x2
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  },
  default_children: [
    {
      name: "Backgrounds",
      folder_type: FOLDER_TYPE.boston_donor_sea_backgrounds
    },
    {
      name: "Categories",
      folder_type: FOLDER_TYPE.boston_donor_sea_categories
    }
  ]
};
