import React, { Component } from 'react';
import { Modal, Checkbox} from 'antd';
import { connect } from "react-redux";
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Show Match Siblings'];
//const defaultCheckedList = ['Images', 'Videos', 'Audio', 'Plain Text', 'Rich Text'];


class ManageSearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedList: this.props.clientOptions.manage_tree_settings,
      indeterminate: true,
      checkAll: false,
    };
  }

  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };


  render() {
    return(
      <Modal visible={this.props.visible}
        title = {"Manage Tree Settings"}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.props.onOk(this.state.checkedList)}
        destroyOnClose="true"
      >
      <div>
        <div style={{ borderBottom: '1px solid #E9E9E9' }}>
          <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.onCheckAllChange}
            checked={this.state.checkAll}
          >
            Check all
          </Checkbox>
        </div>
        <br />
        <CheckboxGroup
          options={plainOptions}
          value={this.state.checkedList}
          onChange={this.onChange}
        />
      </div>
      </Modal>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    clientOptions: state.client_options,
  };
};

export default connect(mapStateToProps, null)(ManageSearchModal);