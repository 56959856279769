/* eslint-disable */
import { createSelector } from "@reduxjs/toolkit";

import { getAppliedTreeDataResourcesNoEmpty } from "./folderNoEmptySelectors";

const getClientOptionSearchResource = (state) => {
  return state.client_options.search_string_existing_resource;
};

export const getAppliedTreeDataAndFilter = createSelector(
  [getAppliedTreeDataResourcesNoEmpty, getClientOptionSearchResource],
  (folderSelectorResourcesNoEmpty, searchResource) => {
    if (searchResource) {
      return folderSelectorResourcesNoEmpty.filter((f) => {
        const searchResourceLowerCase = searchResource
          ? searchResource.toLowerCase()
          : "";
        if (!f.type) {
          return true;
        }
        if (
          f.modifiedName &&
          f.modifiedName.toLowerCase().includes(searchResourceLowerCase)
        ) {
          return true;
        }
        return false;
      });
    }
    return folderSelectorResourcesNoEmpty;
  }
);
