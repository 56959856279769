export default {
  field_groups: {
    default: {
      title: 'Role:',
      default_expanded: true
    }
  },
  fields: {
    name: {
      type: 'text',
      title: 'Role Name:',
      validation: { rules: [{ name: 'isRequired' }] },
      //default: ''
    },
    scope: {
      type: 'select',
      title: "Scope",
      validation: {
        rules: [
          { name: 'isRequired' }
        ]
      },
      //default: "kitchen",
      options: [{
        title: "Company",
        value: "company"
      }, {
        title: "Project",
        value: "project"
      }]
    },
    permissions: {
      type: 'multiselect',
      title: "Permissions",
      validation: {
        rules: [
          { name: 'isRequired' }
        ]
      },
    }
  }
};

