import React from 'react';
import AlertNotification from '.././layout/AlertNotification';

const CheckAuthComponent = ({ checkAuth, Component, FallbackComponent }) => {
    return checkAuth() ? <Component /> : <>
        {AlertNotification('error', 'You are not authorized to access this page. Please contact your administrator.')}
        <FallbackComponent />
    </>;
};

export default CheckAuthComponent;
