// import _ from 'lodash';
import {arrayFromKeyedObject} from '../shared/utils/collectionUtils'
export function getIdFromTreeNode(node, checkResource, checkFolder){
    if (checkResource && node.startsWith("resource-")){
      return Number(node.substring(9));
    }
    else if (checkFolder && node.startsWith("folder-")){
      return Number(node.substring(7));
    }
    else{
      return null;
    }
}

function reverseTreeRecurse(reverse_paths, ff, breaker = 1) {
  if (breaker > 100){
    console.log("recursive null")
    return null;
  }

  breaker++
  let new_nodes = [];
  let parents = [];
  //find all parents of current path item
  for (let a=0; a<reverse_paths.length; a++){
    //find parents

    parents = ff.filter(x=> x.child_folder === reverse_paths[a][0] && x.parent_folder)
    let cached_path;

    for (var b=0; b<parents.length; b++){
      //find where child is first element. at least one will match
      // eslint-disable-next-line
      let matched = reverse_paths.find(y => y[0] === parents[b].child_folder);
      if (matched){
        //clone for cache
        cached_path = matched.slice(0);
        matched.unshift(parents[b].parent_folder)
      }
      else{
        //create new branch
        cached_path.unshift(parents[b].parent_folder);
        new_nodes.push(cached_path)
        break;
      }
    }
  }

  reverse_paths.concat(new_nodes)
  if (new_nodes.length || parents.length){
    return reverseTreeRecurse(reverse_paths, ff, breaker);
  }
  else{
    return reverse_paths;
  }
}

// function recursivePathFind(folderFolders, nodeId, allFolderFolders) {
//   for (let i = 0; i < folderFolders.length; i++) {
//     const children = allFolderFolders.filter(aff => aff.parent_folder === folderFolders[i].child_folder);
//     if (children.length) {
//       const
//     }
//   }
// }

export function reversePathsFromNode(node_id, folder__folders, ignoreFolderFolders = []){
  let reverse_paths = []
  let folderFolders = arrayFromKeyedObject(folder__folders);
  let ff = folderFolders.filter(x=> x.child_folder === node_id && !ignoreFolderFolders.includes(x.id));
  ff.map(x => reverse_paths.push([x.parent_folder, x.child_folder]))

  let rTree = reverseTreeRecurse(reverse_paths, arrayFromKeyedObject(folder__folders))

  return rTree;
}

export function getChildrenDeep(acc, folder__folders, folders, parent_folder) {
  acc.push(parent_folder);
  const children = folder__folders.filter(x => x.parent_folder === parent_folder.id)
  if (children.length) {
    children.forEach(child => getChildrenDeep(acc, folder__folders, folders, folders.find(x => x.id === child.child_folder)))
  }
  return acc;
}

function recursiveSetProject(all_folder__folders, items_to_analyze) {
  items_to_analyze.forEach(element => {
    let children_without_project = all_folder__folders.filter(x => x.project === null && x.parent_folder === element.child_folder);
    children_without_project.forEach(x => x.project = element.project);
    if (children_without_project.length === 0) {
      return;
    } else {
      recursiveSetProject(all_folder__folders, children_without_project);
    }
  });
}

export function setProjectForAllChildren(folder__folders) {
  const folders_with_project = folder__folders.filter(x => x.project !== null);
  recursiveSetProject(folder__folders, folders_with_project);
  return folder__folders;
}

export function humanReadableFolderPath(tree, folders) {
  return tree.map(f => folders[f].name).join(' / ')
}

export function isFromContentFolder(path, folder__folders, folders, folder_types) {
  let folderfolderList = path.map(folder_folder_id => folder__folders[folder_folder_id]).filter(f => !!f)
  let folderList = folderfolderList.map(folder_folder => folders[folder_folder.child_folder]).filter(f => !!f)
  let folderTypeList = folderList.map(folder => folder_types[folder.folder_type]).filter(f => !!f)
  return folderTypeList.filter(t => t.isContent).length > 0
}