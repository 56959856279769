import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React, { ReactText, useState } from "react";

interface IAuditLogTableProps {
  dataSource: any;
}

interface filters {
  action: ReactText[];
  status: ReactText[];
  user: ReactText[];
  area: ReactText[];
  email: ReactText[];
}

interface IAuditRow {
  user: string;
  key: number;
  id: number;
  action: string;
  status: string;
  area: string;
  message: string;
  email: string;
  date: string;
}

const AuditLogTable: React.FC<IAuditLogTableProps> = (
  props: IAuditLogTableProps
) => {
  const initialFilters: filters = {
    action: [],
    status: [],
    user: [],
    area: [],
    email: []
  };

  const [columnFilters, setColumnInfo] = useState(initialFilters);

  const handleChange = (pagination, filters) => {
    setColumnInfo(filters);
  };

  const clearFilters = () => {
    setColumnInfo(initialFilters);
  };

  const getFilterObject = (
    data,
    property,
    include_blank = false
  ): ColumnFilterItem[] => {
    const returnObject = [
      ...new Set(
        data
          .filter((x) => x[property] && x[property].length > 0)
          .map((x) => x[property])
      )
    ].map((x) => {
      return { text: x, value: x };
    });
    if (include_blank) {
      returnObject.push({ text: "(Empty)", value: "(empty)" });
    }
    return returnObject as ColumnFilterItem[];
  };

  const dataSource = props.dataSource.map((x) => {
    const createdAt = new Date(x.createdAt);
    return {
      user: x.user_name,
      key: x.id,
      id: x.id,
      action: x.action,
      status: x.status,
      area: x.area,
      message: x.message,
      email: x.email,
      date:
        createdAt.toLocaleDateString("en-US") +
        " " +
        createdAt.toLocaleTimeString("en-US")
    };
  });

  let auditLogColumns: ColumnsType<IAuditRow> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        if (Date.parse(a.date) > Date.parse(b.date)) return -1;
        if (Date.parse(b.date) > Date.parse(a.date)) return 1;
        return 0;
      },
      defaultSortOrder: "ascend"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filters: getFilterObject(dataSource, "action"),
      filteredValue: columnFilters.action,
      onFilter: (value, record) => {
        if (!record.action) {
          return false;
        } else {
          return record.action.includes(value.toString());
        }
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: getFilterObject(dataSource, "status"),
      filteredValue: columnFilters.status,
      onFilter: (value, record) => {
        if (!record.status) {
          return false;
        } else {
          return record.status.includes(value.toString());
        }
      }
    },
    {
      title: "Responsible",
      dataIndex: "user",
      key: "user",
      sorter: (a, b) => {
        a.user = a.user || "";
        b.user = b.user || "";
        if (a.user.toLowerCase() > b.user.toLowerCase()) return -1;
        if (a.user.toLowerCase() < b.user.toLowerCase()) return 1;
        return 0;
      },
      filteredValue: columnFilters.user,
      onFilter: (value, record) => {
        if (value === "(empty)") {
          return record.user.length === 0;
        } else {
          return record.user.includes(value.toString());
        }
      },
      filters: getFilterObject(dataSource, "user", true)
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      filters: getFilterObject(dataSource, "area", true),
      filteredValue: columnFilters.area,
      onFilter: (value, record) => {
        if (!record.area) {
          return false;
        } else {
          return record.area.includes(value.toString());
        }
      }
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        if (a.email.length === 0) return 1;
        if (b.email.length === 0) return -1;
        if (a.email.toLowerCase() > b.email.toLowerCase()) return -1;
        if (a.email.toLowerCase() < b.email.toLowerCase()) return 1;
        return 0;
      },
      filteredValue: columnFilters.email,
      onFilter: (value, record) => {
        if (value === "(empty)") {
          return record.user.length === 0;
        } else {
          return record.email.includes(value.toString());
        }
      },
      filters: getFilterObject(dataSource, "email", true)
    }
  ];

  return (
    <div>
      <Button onClick={clearFilters}>Clear filters</Button>
      <Table
        title={() => "History Log"}
        rowKey={(record) => record.key}
        dataSource={dataSource}
        pagination={{ position: ["bottomRight"] }}
        bordered={false}
        columns={auditLogColumns}
        onChange={handleChange}
      />
    </div>
  );
};

export default AuditLogTable;
