import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const monterrey_content_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.monterrey_content_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.monterrey_story_with_fact,
    FOLDER_TYPE.monterrey_fullscreen_fact_story,
    FOLDER_TYPE.monterrey_media_blank_1,
    FOLDER_TYPE.monterrey_media_blank_2,
    FOLDER_TYPE.monterrey_media_blank_3,
    FOLDER_TYPE.monterrey_media_blank_4,
    FOLDER_TYPE.monterrey_media_blank_5,
    FOLDER_TYPE.monterrey_story_with_media,
    FOLDER_TYPE.monterrey_story_media_with_quote,
    FOLDER_TYPE.monterrey_profile_with_media_story,
    FOLDER_TYPE.monterrey_intro_story
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 2.8125,
    component_name: COMPONENT_NAME.Monterrey5x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 5, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.monterrey_intro_story,
      FOLDER_TYPE.monterrey_story_with_fact,
      FOLDER_TYPE.monterrey_fullscreen_fact_story,
      FOLDER_TYPE.monterrey_media_blank_1,
      FOLDER_TYPE.monterrey_media_blank_2,
      FOLDER_TYPE.monterrey_media_blank_3,
      FOLDER_TYPE.monterrey_media_blank_4,
      FOLDER_TYPE.monterrey_media_blank_5,
      FOLDER_TYPE.monterrey_story_with_media,
      FOLDER_TYPE.monterrey_story_media_with_quote,
      FOLDER_TYPE.monterrey_profile_with_media_story
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.monterrey_intro_story,
          FOLDER_TYPE.monterrey_story_with_fact,
          FOLDER_TYPE.monterrey_fullscreen_fact_story,
          FOLDER_TYPE.monterrey_media_blank_1,
          FOLDER_TYPE.monterrey_media_blank_2,
          FOLDER_TYPE.monterrey_media_blank_3,
          FOLDER_TYPE.monterrey_media_blank_4,
          FOLDER_TYPE.monterrey_media_blank_5,
          FOLDER_TYPE.monterrey_story_with_media,
          FOLDER_TYPE.monterrey_story_media_with_quote,
          FOLDER_TYPE.monterrey_profile_with_media_story
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.monterrey_intro_story,
          FOLDER_TYPE.monterrey_story_with_fact,
          FOLDER_TYPE.monterrey_fullscreen_fact_story,
          FOLDER_TYPE.monterrey_media_blank_1,
          FOLDER_TYPE.monterrey_media_blank_2,
          FOLDER_TYPE.monterrey_media_blank_3,
          FOLDER_TYPE.monterrey_media_blank_4,
          FOLDER_TYPE.monterrey_media_blank_5,
          FOLDER_TYPE.monterrey_story_with_media,
          FOLDER_TYPE.monterrey_story_media_with_quote,
          FOLDER_TYPE.monterrey_profile_with_media_story
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [
          FOLDER_TYPE.monterrey_intro_story,
          FOLDER_TYPE.monterrey_media_blank_1,
          FOLDER_TYPE.monterrey_media_blank_2,
          FOLDER_TYPE.monterrey_media_blank_3,
          FOLDER_TYPE.monterrey_media_blank_4,
          FOLDER_TYPE.monterrey_media_blank_5,
          FOLDER_TYPE.monterrey_story_with_media,
          FOLDER_TYPE.monterrey_story_media_with_quote,
          FOLDER_TYPE.monterrey_profile_with_media_story
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.monterrey_intro_story,
          FOLDER_TYPE.monterrey_story_with_fact,
          FOLDER_TYPE.monterrey_fullscreen_fact_story,
          FOLDER_TYPE.monterrey_media_blank_1,
          FOLDER_TYPE.monterrey_media_blank_2,
          FOLDER_TYPE.monterrey_media_blank_3,
          FOLDER_TYPE.monterrey_media_blank_4,
          FOLDER_TYPE.monterrey_media_blank_5,
          FOLDER_TYPE.monterrey_story_with_media,
          FOLDER_TYPE.monterrey_story_media_with_quote,
          FOLDER_TYPE.monterrey_profile_with_media_story
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.monterrey_intro_story,
          FOLDER_TYPE.monterrey_story_with_fact,
          FOLDER_TYPE.monterrey_fullscreen_fact_story,
          FOLDER_TYPE.monterrey_media_blank_1,
          FOLDER_TYPE.monterrey_media_blank_2,
          FOLDER_TYPE.monterrey_media_blank_3,
          FOLDER_TYPE.monterrey_media_blank_4,
          FOLDER_TYPE.monterrey_media_blank_5,
          FOLDER_TYPE.monterrey_story_with_media,
          FOLDER_TYPE.monterrey_story_media_with_quote,
          FOLDER_TYPE.monterrey_profile_with_media_story
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false
};
