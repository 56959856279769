import {
  ADD_PROJECT,
  DELETE_PROJECT,
  DOWNLOAD_PROJECT_CSV,
  GET_ALL_NOTIFIABLE_USERS,
  GET_PROJECT,
  GET_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SETTINGS,
  UPLOAD_PROJECT_CSV
} from "./actionTypes";

export const uploadProjectCsv = (value, onProgress, onSuccess?, onFail?) => ({
  type: UPLOAD_PROJECT_CSV,
  payload: {
    value,
    request: { onProgress }
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateProject = (id, values, onSuccess?, onFail?) => ({
  type: UPDATE_PROJECT,
  payload: { id: id, ...values },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateProjectSettings = (id, settings, onSuccess?, onFail?) => ({
  type: UPDATE_PROJECT_SETTINGS,
  payload: { id, settings },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getProject = (id, onSuccess?, onFail?) => ({
  type: GET_PROJECT,
  payload: { id: id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addProject = (companyId, values, onSuccess?, onFail?) => ({
  type: ADD_PROJECT,
  payload: { company: companyId, ...values },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const downloadProjectCsv = (id, onSuccess?, onFail?) => ({
  type: DOWNLOAD_PROJECT_CSV,
  payload: {
    project: id
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getProjects = (onSuccess?, onFail?) => ({
  type: GET_PROJECTS,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getAllNotifiableUsers = (project_id, onSuccess?, onFail?) => ({
  type: GET_ALL_NOTIFIABLE_USERS,
  payload: { project_id: project_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteProject = (
  id,
  deleteUnusedResources,
  onSuccess?,
  onFail?
) => ({
  type: DELETE_PROJECT,
  payload: { id, deleteUnusedResources },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
