/* eslint-disable */
import { createSelector } from "@reduxjs/toolkit";

import { getAppliedTreeDataResourcesNoEmpty } from "./folderNoEmptySelectors";

const getFolderResults = (state) => {
  return state.data.folder_results || [];
};

const getSearchStringMain = (state) => {
  if (state.search_string_main) {
    return state.search_string_main;
  }
  return null;
};

const removeSiblings = (folders, childId, folderResults) => {
  let result = folders;
  const alreadyProcessedFolderResults = [];
  const childFinded = folders.find((f) => f.id === Number(childId));
  // check is a child and no a folder
  if (childFinded && childFinded.last_node_item !== false) {
    // is a child
    const findParent = folders.find((f) => f.id === childFinded.parent_folder);
    if (findParent) {
      result = folders.filter((f) => {
        const isTrue =
          f.parent_folder !== findParent.id ||
          (f.parent_folder === findParent.id &&
            folderResults.includes(f.id + ""));

        if (
          f.parent_folder === findParent.id &&
          folderResults.includes(f.id + "")
        ) {
          alreadyProcessedFolderResults.push(f.id + "");
        }
        return isTrue;
      });
    }
  }
  return { folders: result, processed: alreadyProcessedFolderResults };
};

const removeLastChildNotIncludedInResult = (folders, folderResult) => {
  if (!folderResult) {
    return folders;
  }

  const result = folders.filter((f) => {
    // depth > 3 is not a company, location, project
    return (
      !f.last_node_item ||
      f.depth > 3 ||
      (f.last_node_item && folderResult.includes(f.id + ""))
    );
  });
  return result;
};

export const getAppliedTreeDataFilterSiblingsNoMatch = createSelector(
  [getAppliedTreeDataResourcesNoEmpty, getSearchStringMain, getFolderResults],
  (folderSelector, searchStringMain, folderResults) => {
    let folderSelectorFiltered = folderSelector;
    const folderResultsProcessed = [];
    if (folderResults && folderResults.length) {
      let result = removeLastChildNotIncludedInResult(
        folderSelector,
        folderResults
      );
      for (let i = 0; i < folderResults.length; i++) {
        const filterItemId = folderResults[i];
        let folders;
        let processed = [];
        if (!folderResultsProcessed.includes(filterItemId)) {
          const obj = removeSiblings(result, filterItemId, folderResults);
          folders = obj.folders;
          processed = obj.processed;
        }
        result = folders || result;
        folderResultsProcessed.push(...processed);
      }
      folderSelectorFiltered = result;
    }

    return folderSelectorFiltered;
  }
);
