import { call, put } from "redux-saga/effects";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  mainSearchValidationSchema,
  searchTagsValidationSchema,
} from "../shared-global/validations/SearchValidation";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";

async function searchMainAPI(payload) {
  return Request(
    "/search/main",
    HTTP_METHODS.POST,
    { ...payload },
    mainSearchValidationSchema
  );
}

async function searchTagAPI(payload) {
  return Request(
    "/search/tag",
    HTTP_METHODS.POST,
    { ...payload },
    searchTagsValidationSchema
  );
}

const searchSaga = {
  searchMain: function* (action) {
    try {
      const response = yield call(searchMainAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "SEARCH_MAIN_SUCCESS",
          folder_results: response.data.folder_ids,
          resource_results: response.data.resource_ids,
          tag_results: response.data.tag_ids,
          resource__notes_results: response.data.resource__note_ids,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "SEARCH_MAIN_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "SEARCH_MAIN_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  searchTag: function* (action) {
    try {
      const response = yield call(searchTagAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "SEARCH_TAG_SUCCESS",
          tag_suggestion_results: response.data.tag_ids,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "SEARCH_TAG_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "SEARCH_TAG_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default searchSaga;
