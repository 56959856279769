import React, {useEffect, useState} from "react";
import {Button, List} from 'antd';
import _ from 'lodash';
import {dateFormatted} from "../../utils/dateUtils";
import {getImageDimensionsFromS3, getOriginalImageFromS3} from "../form/input/DynamicFileInput/FileInputService";

interface IResourceContentLibraryDetailProps {
    selected_resource: any;
    resources: { [key: string]: any };
    aws_instance: any;
    users: { [key: string]: any };
    user: any;
}

export const ResourceContentLibraryDetail: React.FC<IResourceContentLibraryDetailProps> = (props: IResourceContentLibraryDetailProps) => {
    const [dimensionWidth, setDimensionWidth] = useState(null);
    const [dimensionHeight, setDimensionHeight] = useState(null);
    const [resourceData, setResourceData] = useState([])

    useEffect(() => {
        if (props.selected_resource) {
            const data = getResourceListInformation()
            setResourceData(data);

            getImageDimension(props.selected_resource.id).then(myDimensions => {
                setDimensionWidth(_.get(myDimensions, 'width', null));
                setDimensionHeight(_.get(myDimensions, 'height', null));
            });
        }
    }, [props.selected_resource])

    useEffect(() => {
        if (dimensionWidth && dimensionHeight) {
            const data = getResourceListInformation()
            setResourceData(data);
        }

    }, [dimensionWidth, dimensionHeight])

    const getUser = userId => {
        const {users, user} = props;
        try {
            if (users && users[userId]) {
                const userFound = users[userId];
                const first_name = _.get(userFound, 'first_name', '');
                const last_name = _.get(userFound, 'last_name', null);
                return first_name + last_name;
            } else if ( user.id === userId ) {
                const first_name = _.get(user, 'first_name', '');
                const last_name = _.get(user, 'last_name', null);
                return first_name + ' ' + last_name;
            }
            return '';
        } catch (e) {
            return '';
        }
    };

    const getImageDimension = async resourceId => {
        const {aws_instance, resources} = props;
        const urlImage = getOriginalImageFromS3({resource: resourceId}, resources, aws_instance);
        const dimensions = await getImageDimensionsFromS3(urlImage);
        return dimensions;
    };

    const getDimensionInfo = () => {
        let dimensionsInfo = '';

        if (dimensionWidth && dimensionHeight) {
            dimensionsInfo = `${dimensionWidth} x ${dimensionHeight} px`;
        }
        return dimensionsInfo;
    };

    const getResourceListInformation = () => {
        const {selected_resource} = props;
        let data = [];
        if (selected_resource) {
            const size = selected_resource.size ? (selected_resource.size / 1024 / 1024).toFixed(2) : 0;
            const userId = _.get(selected_resource, 'updatedBy', null)
            const format = _.get(selected_resource, 'extension');
            const updatedAt = dateFormatted(_.get(selected_resource, 'updatedAt', ''), 'm/dd/yyyy hh:mm:ss')
            const userName = getUser(userId);
            const dimensionInfo = getDimensionInfo();

            data = [
                {title: 'Filename (Original)', value: selected_resource.originalName},
                {title: 'Type', value: selected_resource.type},
                {title: 'Format', value: format},
                {title: 'Size', value: `${size} MB`},
                {title: 'Dimensions (w x h)', value: dimensionInfo},
                {title: 'Uploaded', value: updatedAt},
                {title: 'Uploaded By', value: `${userName}`, type: 'link'}
            ]
        }

        return data;
    }

    const renderList = item => {
        switch (item.type) {
            case 'link':
                return (<List.Item><b>{item.title}</b>: <Button type='link'>{item.value}</Button></List.Item>)
            default:
                return (<List.Item><b>{item.title}</b>: {item.value}</List.Item>)
        }
    }

    return (
        <div className="resource-content-library-detail">
            <List
                bordered
                dataSource={resourceData}
                renderItem={renderList}
            />
        </div>
    )
}

export default ResourceContentLibraryDetail;