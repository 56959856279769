import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

const textstyle_options = {
  font: {
    enabled: true,
    items: [FontsFamilies.SERIF, FontsFamilies.SANS_SERIF, FontsFamilies.ROBOTO, FontsFamilies.ROBOTO_SLAB]
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: [
      { title: "10em", value: 10 },
      { title: "15em", value: 15 },
      { title: "20em", value: 20 },
      { title: "25em", value: 25 },
      { title: "30em", value: 30 },
      { title: "35em", value: 35 },
      { title: "40em", value: 40 }
    ]
  },
  lineHeight: {
    enabled: false
  },
  bold: {
    enabled: true
  },
  color: {
    enabled: true
  },
  underline: {
    enabled: true
  },
  italic: {
    enabled: true
  }
};

const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const eugene_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.eugene_root,
  field_groups: {
    general: {
      title: "General",
      default_expanded: false,
      order: 0,
      tab: "general"
    },
    defaults: {
      title: "Defaults",
      default_expanded: false,
      order: 0,
      tab: "defaults"
    }
  },
  tabs: {
    general: {
      title: "General"
    },
    defaults: {
      title: "Defaults"
    }
  },
  display_name: "Eugene Root",
  acceptable_folder_types: [FOLDER_TYPE.eugene_displays, FOLDER_TYPE.eugene_presentations, FOLDER_TYPE.eugene_content],
  save_options: [
    {
      title: "SAVE AND OVERRIDE DEFAULT SETTINGS",
      action: "push_down_fields"
    }
  ],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true,
      field_group: "general"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    accent_color: {
      type: "color",
      title: "Accent Color",
      default: "#B14182",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    heading: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Headings",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_title: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Titles",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_items: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Items",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Subhead",
      show_char_count: false,
      default: textstyle_default,
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    name_list: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Donor Name List",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneRootPreview,
    type: PREVIEW_TYPE.COMPONENT
  }
};
