import {COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const berkeley_donor_sea_categories: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.berkeley_donor_sea_categories,
  display_name: "Donor Sea Categories",
  acceptable_folder_types: [FOLDER_TYPE.berkeley_donor_sea_category],
  fields: {
    // #region General
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
    // #endregion
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
  },
};
