/* eslint-disable react/prop-types */
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { List } from "react-virtualized";

const SortableList = SortableContainer(List);

let MySortableRow = null;

const buildSortableRow = (Row) => {
  if (MySortableRow === null) {
    MySortableRow = SortableElement((props) => {
      return (
        // eslint-disable-next-line react/jsx-filename-extension
        <Row
          itemIndex={props.itemIndex}
          style={props.style}
          item={props.item}
          columns={props.columns}
          mode={props.mode}
          onChange={props.onChange}
        />
      );
    });
  }

  return MySortableRow;
};

const SortableListComponent = ({
  outerWidth,
  listContainerHeight,
  allItems,
  Row,
  onSortEnd,
  columns,
  onChange,
  forceUpdateInt,
  shouldCancelStart,
  helperClass,
  mode,
}) => {
  const rowRenderer = (rowProps) => {
    const SortableRow = buildSortableRow(Row);

    return (
      <SortableRow
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rowProps}
        key={rowProps.index}
        item={allItems[rowProps.index]}
        columns={columns || []}
        mode={mode}
        onChange={onChange}
      />
    );
  };

  return (
    <SortableList
      className="list-container"
      width={outerWidth}
      height={listContainerHeight}
      rowCount={allItems.length}
      rowHeight={50}
      rowRenderer={rowRenderer}
      onSortEnd={onSortEnd}
      list={allItems}
      forceUpdateInt={forceUpdateInt}
      shouldCancelStart={shouldCancelStart}
      helperClass={helperClass}
    />
  );
};

const areEqualSortableList = (prevProps, nextProps) => {
  const areEqualAllItems = prevProps.allItems === nextProps.allItems;
  const areEquals = areEqualAllItems
    && prevProps.outerWidth === nextProps.outerWidth
    && prevProps.listContainerHeight === nextProps.listContainerHeight
    && prevProps.mode === nextProps.mode;

  return areEquals;
};

// eslint-disable-next-line import/prefer-default-export
export const SortableListMemo = React.memo(
  SortableListComponent,
  areEqualSortableList,
);
