import {
  FOLDER_TYPE,
  COMPONENT_NAME,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
  FOLDER_CATEGORY
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./springfield_portrait_settings";

export const springfield_portrait_presentation: IFolderType = {
  folder_category: FOLDER_CATEGORY.PRESENTATION,
  name: FOLDER_TYPE.springfield_portrait_presentation,
  display_name: "Presentation",
  acceptable_folder_types: [
    FOLDER_TYPE.springfield_portrait_template_001,
    FOLDER_TYPE.springfield_portrait_template_002,
    FOLDER_TYPE.springfield_portrait_template_003,
    FOLDER_TYPE.springfield_portrait_template_004,
    FOLDER_TYPE.springfield_portrait_donor_list,
    FOLDER_TYPE.springfield_portrait_donor_lists
  ],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions],
    },
  },
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.SpringfieldPortraitContainer,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  list_view: {
    enabled: false,
    list_folder_types: [
      FOLDER_TYPE.springfield_portrait_template_001,
      FOLDER_TYPE.springfield_portrait_template_002,
      FOLDER_TYPE.springfield_portrait_template_003,
      FOLDER_TYPE.springfield_portrait_template_004,
      FOLDER_TYPE.springfield_portrait_donor_list
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004,
          FOLDER_TYPE.springfield_portrait_donor_list
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004,
          FOLDER_TYPE.springfield_portrait_donor_list
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004,
          FOLDER_TYPE.springfield_portrait_donor_list
        ],
        field: "header01",
        title: "Header",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004,
          FOLDER_TYPE.springfield_portrait_donor_list
        ],
        field: "file",
        title: "Image / Media",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004,
          FOLDER_TYPE.springfield_portrait_donor_list
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  instancing_options: {
    enabled: true,
    cross_project: false
  },
  hide_nested_children: true,
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  isPresentation: true
};
