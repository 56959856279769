import React, { useState, useRef, useEffect } from "react";

const VideoLoader = ({ url, style, controls }) => {
  const [paused, setPaused] = useState(true);
  const [clickedOn, setClickedOn] = useState(false);
  const videoRef = useRef(null);
  
  const bindEvent = () => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.addEventListener('ended', e => {
          setPaused(true);
        });
      } else {
        bindEvent();
      }
    }, 100);
  }
  
  useEffect(() => {
    bindEvent();
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', e => {
          setPaused(true);
        });
      }
    }
  }, []);

  const toggleVideo = () => {
    if (!clickedOn) {
      setClickedOn(true);
    }
    if (paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setPaused(!paused);
  };

  return (
    <React.Fragment>
      {url ? (
        <>
          {controls ? (
            <video ref={videoRef} style={style} controls>
              <source src={url} />
              Your browser does not support HTML5 Video
            </video>
          ) : (
            <video ref={videoRef} style={style}>
              <source src={url} />
              Your browser does not support HTML5 Video
            </video>
          )}
          {!clickedOn && (
            <div
              className="video-loader"
              style={{
                display: "flex",
                top: 0,
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                position: "absolute",
              }}
              onClick={toggleVideo}
            >
              {paused && (
                <img className="play-button" alt={"loading"} style={{ position: "absolute", width: "25%" }} src={process.env.PUBLIC_URL + "/images/play.png"} />
              )}
            </div>
          )}
        </>
      ) : (
        <img alt={"loading"} src="/images/icons/video.png" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      )}
    </React.Fragment>
  );
};

export default VideoLoader;
