import React, { Component } from 'react';
import _ from 'lodash';
import { getBaseFolder } from '../../../utils/commonDataUtils';
import { getFileDynamic, getFileTypeFromResources, videoType } from '../../../utils/fileUtils';
import { bestFit } from '../../../utils/screenUtils'
import { DEFAULT_CYCLE_TIME }  from './FremontConstants'
class FremontOverlaySlide1603 extends Component {

  constructor(props) {
    super(props);

    this._file = null;

    // const baseFolder = getBaseFolder(props.data);
    this._cycle_time = null;

    const containerHeight = props.containerHeight || window.innerHeight
    const containerWidth = props.containerWidth || window.innerWidth

    const dims = bestFit(containerWidth, containerHeight, containerWidth, containerHeight);
    this._videoRef = null;
    this._videoTimeout = null;
    this._metadataLoaded = false;
    this._dataLoaded = false;

    // initialize timeouts
    this._initPlayerTimeout = null;
    this._positionVideoTimeout = null;
    this._cycleTimeout = null;

    this.state = {
      width: dims.width,
      height: dims.height,
      file_type: '',
      containerHeight,
      containerWidth,
      videoOffsetY: 0,
      videoOffsetX: 0,
      showVideo: false
    }
  }

  componentDidMount() {
    this.initializeNewData();
    let file_type = getFileTypeFromResources(this.props.data.resources, this.props.data.resource__folders, ["file", "slide"], this.props.data.base_folder);
    this.setCycleTimeout(this._cycle_time, file_type);
    let preset = this.props.mode === "preview" ? "preview" : null;

    this._file = getFileDynamic(this.props.aws, this.props.aws_bucket, this.props.data.resources, this.props.data.resource__folders, ["file", "slide"], this.props.data.base_folder, preset, this.props.webview_signedurls)

    this.setState({
      file_type: file_type
    });
    if (file_type === "video") {
      this.initPlayer();
    }
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowLeft': //left
        //todo: handle previouse?
        break;
      case 'ArrowUp': //up
        break;
      case 'ArrowRight': //right
        console.log("forcing next slide");
        this.props.handleEndOfPlay();
        break;
      case 'ArrowDown': //down
        break;
      default:
        break;
    }
  }

  unbindEvents = () => {
    this._videoRef.removeEventListener('loadedmetadata', this.loadedMetadata);
    this._videoRef.removeEventListener('loadeddata', this.loadedData);
    this._videoRef.removeEventListener('ended', this.videoEnd);
  }

  initPlayer = () => {
    let player = this._videoRef;
    if (player) {
      player.addEventListener('loadedmetadata', this.loadedMetadata);
      player.addEventListener('loadeddata', this.loadedData);
      player.addEventListener('ended', this.videoEnd);
      if (player.readyState >= 2) {
        this.loadedMetadata();
        this.loadedData();
      }
    } else {
      clearTimeout(this._initPlayerTimeout);
      this._initPlayerTimeout = setTimeout(() => {
        this.initPlayer();
      }, 100);
    }
  }

  loadedMetadata = () => {
    this._metadataLoaded = true;
    if (this._videoRef) {
      const duration = this._videoRef.duration;
      const newDuration = (duration * 1000) - 2000;
      this._videoCycleTimeoutHandler = setTimeout(() => {
          this.props.handleEndOfPlay();
      }, newDuration);
      this.checkIfAllDataLoaded();
    }
  }

  loadedData = () => {
    this._dataLoaded = true;
    this.checkIfAllDataLoaded();
  }

  checkIfAllDataLoaded = () => {
    if (this._metadataLoaded && this._dataLoaded) {
      this.setState({
        showVideo: true
      });
      this.positionVideo();
    }
  }

  positionVideo = () => {

    if (!this._videoRef) {
      clearTimeout(this._positionVideoTimeout);
      this._positionVideoTimeout = setTimeout(() => {
        this.positionVideo();
      }, 100);
      return;
    }

    let videoHeight = this._videoRef.getBoundingClientRect().height;
    let videoWidth = this._videoRef.getBoundingClientRect().width;

    let videoOffsetY = (this.state.containerHeight - videoHeight) / 2
    let videoOffsetX = (this.state.containerWidth - videoWidth) / 2

    let videoControlsOffset = videoOffsetY + (videoHeight / 2);

    this.setState({
      videoOffsetY: videoOffsetY,
      videoOffsetX: videoOffsetX,
      videoControlsOffset: videoControlsOffset
    });
  }

  componentWillUnmount() {
    clearTimeout(this._cycleTimeout);
    clearTimeout(this._initPlayerTimeout);
    clearTimeout(this._positionVideoTimeout);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.base_folder !== prevProps.data.base_folder) {
      this.initializeNewData();
      this.setCycleTimeout(this._cycle_time)
    }
    if (prevProps.containerWidth !== this.props.containerWidth || prevProps.containerHeight !== this.props.containerHeight) {
      const dims = bestFit(this.props.containerWidth, this.props.containerHeight, this.props.containerWidth, this.props.containerHeight)
      this.setState({
        width: dims.width,
        height: dims.height
      })
    }
  }

  initializeNewData() {
    let base_folder = getBaseFolder(this.props.data)

    // use prop cycle_time if available otherwise use field value cycle time
    if (this.props.cycle_time) {
      this._cycle_time = this.props.cycle_time;
    } else {
      const cycleTime = _.get(base_folder, 'fields.cycle_time', null);
      if (cycleTime !== null && cycleTime !== 0) {
        this._cycle_time = base_folder.fields.cycle_time * 1000;
      } else {
        this._cycleTime = null;
      }
    }
    // if its a video set cycle time to length of video. Confirming with Tyler any options for this.

    this.setState({
      base_folder: base_folder
    })
  }

  setCycleTimeout(cycle_time, file_type) {
    clearTimeout(this._cycleTimeout);
    if (cycle_time === 0){
      cycle_time = DEFAULT_CYCLE_TIME
    }
    if (file_type === 'image' || !file_type) {
      let newCycleTime = 5000;
      if (cycle_time) {
        newCycleTime = cycle_time;
      }
      this._cycleTimeout = setTimeout(() => {
        if (this.props.handleEndOfPlay) {
          this.props.handleEndOfPlay();
        }
      }, newCycleTime);
    } else if (file_type === 'video') {
      if (cycle_time !== 0 && cycle_time !== null) {
        this._cycleTimeout = setTimeout(() => {
          if (this.props.handleEndOfPlay) {
            this.props.handleEndOfPlay();
          }
        }, cycle_time);
      }
    }
  }

  videoEnd = () => {
    this.props.handleEndOfPlay();
  }

  stepToNext() {
    if (this.props.handleEndOfPlay) {
      this.props.handleEndOfPlay()
    }
  }

  getContentSlide = (file_type) => {
    switch (file_type) {
      case 'image':
        return (
          <div style={{ position: 'absolute', backgroundImage: `url(${this._file})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: this.state.containerHeight, width: this.state.containerWidth, top: 0, left: 0 }}>
          </div>
        );
      case 'video':
        let _videoStyle = {
          preload: 'auto',
          position: 'absolute',
          width: this.state.containerWidth,
          height: this.state.containerHeight,
          zIndex: -1
        }
        let cType = videoType(this._file);
        return(
          <div className="video-no-fullscreen" style={{ display: this.state.showVideo ? 'block' : 'block' }}>
            <video preload="metadata" style={{ ..._videoStyle, top: this.state.videoOffsetY, left: this.state.videoOffsetX }} ref={el => this._videoRef = el} muted autoPlay>
              <source src={this._file} type={cType} />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    if (!this.state.base_folder) {
      return null;
    }

    let preset = this.props.mode === "preview" ? "preview" : null;
    let default_overlay = getFileDynamic(this.props.aws, this.props.aws_bucket, this.props.data.resources, this.props.data.resource__folders, ["default_overlay"], this.props.parent_folder.id, preset, this.props.webview_signedurls);
    let override_overlay = getFileDynamic(this.props.aws, this.props.aws_bucket, this.props.data.resources, this.props.data.resource__folders, ["override_overlay"], this.props.data.base_folder, preset, this.props.webview_signedurls);

    const overlay = override_overlay || default_overlay;

    return (
      <div>
        {this.getContentSlide(this.state.file_type)}
        {
          overlay ?
            <div style={{
              width: this.state.containerWidth,
              height: this.state.containerHeight,
              backgroundImage: `url('${overlay}')`,
              backgroundColor: 'transparent',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'absolute'}}
              >
            </div> : null
        }
      </div>
    )
  }

}

export default FremontOverlaySlide1603;
