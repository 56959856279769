/* eslint-disable */
import { createSelector } from "@reduxjs/toolkit";

import { getAppliedTreeData } from "./folderSelectors";

const getResourcetree_resource__folders = (state) => {
  return state.data.resourcetree_resource__folders;
};

const getResource = (state) => {
  return state.data.resources;
};

export const getAppliedTreeDataResourcesNoEmpty = createSelector(
  [getAppliedTreeData, getResource, getResourcetree_resource__folders],
  (appliedTreeData, resources, resourcetree_resource__folders) => {
    const folderSelectorNoEmpty = appliedTreeData.filter((fs) => {
      if (fs.treeId && fs.treeId.includes("resource-")) {
        const treeId = fs.treeId;
        const resourceFolderId = treeId.replace("resource-", "");
        const resourceFolder = resourcetree_resource__folders[resourceFolderId];
        return resources[resourceFolder.resource];
      } else {
        return true;
      }
    });
    return folderSelectorNoEmpty;
  }
);
