import { FolderType } from "../folders/FolderType";
import { Content } from "../folders/Content";
import { Contents } from "../folders/Contents";
import { Display } from "../folders/Display";
import { Displays } from "../folders/Displays";
import { Layout } from "../folders/Layout";
import { Layouts } from "../folders/Layouts";
import { List } from "../folders/List";
import { Lists } from "../folders/Lists";
import { Presentation } from "../folders/Presentation";
import { Presentations } from "../folders/Presentations";
import { Root } from "../folders/Root";
import { Stories } from "../folders/Stories";
import { Story } from "../folders/Story";

import base_types, { root } from "./base_types";
import { toledo_types } from "./toledo_types";
import { nashville_types } from "./nashville_types";
import { brighton_types } from "./brighton_types";
import { memphis_types } from "./memphis/memphis_types";
import { fremont_types } from "./fremont/fremont_types";
import { walterville_types } from "./walterville_types";
import { jasper_types } from "./jasper/jasper_types";
import { los_angeles_types } from "./los_angeles/los_angeles_types";
import { springfield_types } from "./springfield/springfield_types";
import { new_orleans_types } from "./new_orleans/new_orleans_types";
import { phoenix_types } from "./phoenix/phoenix_types";
import { berkeley_types } from "./berkeley/berkeley_types";
import { boston_types } from "./boston/boston_types";
import { eugene_types } from "./eugene/eugene_types";
import { coburg_types } from "./coburg/coburg_types";
import { springfield_portrait_types } from "./springfield_portrait/springfield_portrait_types";
import { naples_types } from "./naples/naples_types";
import { mckenzie_types } from "./mckenzie/mckenzie_types";
import { everett_types } from "./everett/everett_types";
import { monterrey_types } from "./monterrey/monterrey_types";
import { marin_types } from "./marin/marin_types";
import { glenwood_types } from "./glenwood/glenwood_types";
import { alpine_types } from "./alpine/alpine_types";
import { tangent_types } from "./tangent/tangent_types";
import { IFolderType } from "../../folder-type-interfaces";
import { FOLDER_CATEGORY } from "../../../enums/folder-type-enums";

const allTypes: { [key: string]: IFolderType } = {
  ...base_types,
  ...toledo_types,
  ...nashville_types,
  ...brighton_types,
  ...memphis_types,
  ...fremont_types,
  ...walterville_types,
  ...jasper_types,
  ...los_angeles_types,
  ...springfield_types,
  ...new_orleans_types,
  ...boston_types,
  ...eugene_types,
  ...coburg_types,
  ...springfield_portrait_types,
  ...naples_types,
  ...mckenzie_types,
  ...everett_types,
  ...monterrey_types,
  ...marin_types,
  ...phoenix_types,
  ...glenwood_types,
  ...berkeley_types,
  ...alpine_types,
  ...tangent_types,
};

const folderTypeFactory = (folderType: IFolderType): FolderType => {
  switch (folderType.folder_category) {
    case FOLDER_CATEGORY.ROOT:
      return new Root(folderType);
    case FOLDER_CATEGORY.DONOR_LIST:
      return new List(folderType);
    case FOLDER_CATEGORY.DONOR_LISTS:
      return new Lists(folderType);
    case FOLDER_CATEGORY.STORIES:
      return new Stories(folderType);
    case FOLDER_CATEGORY.STORY:
      return new Story(folderType);
    case FOLDER_CATEGORY.PRESENTATION:
      return new Presentation(folderType);
    case FOLDER_CATEGORY.PRESENTATIONS:
      return new Presentations(folderType);
    case FOLDER_CATEGORY.LAYOUT:
      return new Layout(folderType);
    case FOLDER_CATEGORY.LAYOUTS:
      return new Layouts(folderType);
    case FOLDER_CATEGORY.DISPLAY:
      return new Display(folderType);
    case FOLDER_CATEGORY.DISPLAYS:
      return new Displays(folderType);
    case FOLDER_CATEGORY.CONTENTS:
      return new Contents(folderType);
    default:
      return new Content(folderType);
  }
};

const folderTypes = Object.keys(allTypes).reduce(
  (acc: { [key: string]: FolderType }, key: string) => {
    const fpPlain = allTypes[key];
    const fpTyped = folderTypeFactory(fpPlain);
    acc[key] = fpTyped;

    return acc;
  },
  {}
);

export default folderTypes;
