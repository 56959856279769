import React, {Component} from "react";
import {getBaseFolder} from "../../../utils/commonDataUtils";
import _ from "lodash";
import {bestFit} from "../../../utils/screenUtils";
import {
  getFileTypeFromResources,
  getFileDynamic,
} from "../../../utils/fileUtils";
import {GREY_HEADER} from "./FremontConstants";
import { DEFAULT_CYCLE_TIME }  from './FremontConstants'

class FremontTemplate003 extends Component {
  constructor(props) {
    super(props);

    this._file = null;

    const baseFolder = getBaseFolder(props.data);
    this._cycle_time = _.get(baseFolder, "fields.cycle_time", 5) * 1000;

    const containerHeight = props.containerHeight || window.innerHeight;
    const containerWidth = props.containerWidth || window.innerWidth;

    const dims = bestFit(
      containerWidth,
      containerHeight,
      containerWidth,
      containerHeight
    );

    this._metadataLoaded = false;
    this._dataLoaded = false;

    // initialize timeouts
    this._cycleTimeout = null;

    this.state = {
      width: dims.width,
      height: dims.height,
      file_type: "",
      containerHeight,
      containerWidth,
      base_folder: baseFolder,
    };
  }

  componentDidMount() {
    this.initializeNewData();
    let file_type = getFileTypeFromResources(
      this.props.data.resources,
      this.props.data.resource__folders,
      ["file", "slide"],
      this.props.data.base_folder
    );
    this.setCycleTimeout(this._cycle_time, file_type);
    let preset = this.props.mode === "preview" ? "preview" : null;

    this._file = getFileDynamic(
      this.props.aws,
      this.props.aws_bucket,
      this.props.data.resources,
      this.props.data.resource__folders,
      ["file", "slide"],
      this.props.data.base_folder,
      preset,
      this.props.webview_signedurls
    );
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft": //left
        //todo: handle previouse?
        break;
      case "ArrowUp": //up
        break;
      case "ArrowRight": //right
        this.props.handleEndOfPlay();
        break;
      case "ArrowDown": //down
        break;
      default:
        break;
    }
  };

  unbindEvents = () => {
    this._videoRef.removeEventListener("loadedmetadata", this.loadedMetadata);
    this._videoRef.removeEventListener("loadeddata", this.loadedData);
    this._videoRef.removeEventListener("ended", this.videoEnd);
  };

  initPlayer = () => {
    let player = this._videoRef;
    if (player) {
      player.addEventListener("loadedmetadata", this.loadedMetadata);
      player.addEventListener("loadeddata", this.loadedData);
      player.addEventListener("ended", this.videoEnd);
      if (player.readyState >= 2) {
        this.loadedMetadata();
        this.loadedData();
      }
    } else {
      clearTimeout(this._initPlayerTimeout);
      this._initPlayerTimeout = setTimeout(() => {
        this.initPlayer();
      }, 100);
    }
  };

  loadedMetadata = () => {
    this._metadataLoaded = true;
    this.checkIfAllDataLoaded();
  };

  loadedData = () => {
    this._dataLoaded = true;
    this.checkIfAllDataLoaded();
  };

  checkIfAllDataLoaded = () => {
    if (this._metadataLoaded && this._dataLoaded) {
    }
  };

  componentWillUnmount() {
    clearTimeout(this._cycleTimeout);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.base_folder !== prevProps.data.base_folder) {
      this.initializeNewData();
      this.setCycleTimeout(this._cycle_time);
    }
    if (
      this.props.data.s3Cache &&
      this.props.data.s3Cache !== prevProps.data.s3Cache
    ) {
      const preset = this.props.mode === "preview" ? "preview" : null;
      this._file = getFileDynamic(
        this.props.aws,
        this.props.aws_bucket,
        this.props.data.resources,
        this.props.data.resource__folders,
        ["file", "slide"],
        this.props.data.base_folder,
        preset, 
        this.props.webview_signedurls
      );
      this.setState({});
    }
    if (
      prevProps.containerWidth !== this.props.containerWidth ||
      prevProps.containerHeight !== this.props.containerHeight
    ) {
      const dims = bestFit(
        this.props.containerWidth,
        this.props.containerHeight,
        this.props.containerWidth,
        this.props.containerHeight
      );
      this.setState({
        width: dims.width,
        height: dims.height,
      });
    }
  }

  initializeNewData() {
    let base_folder = getBaseFolder(this.props.data);

    // use prop cycle_time if available otherwise use field value cycle time
    if (this.props.cycle_time) {
      this._cycle_time = this.props.cycle_time;
    } else if (_.get(base_folder, "fields.cycle_time", null)) {
      this._cycle_time = base_folder.fields.cycle_time * 1000;
    }
    this.setState({
      base_folder: base_folder,
    });
  }

  setCycleTimeout(cycle_time, file_type) {
    clearTimeout(this._cycleTimeout);
    if (cycle_time === 0){
      cycle_time = DEFAULT_CYCLE_TIME
    }
    if (file_type === "image" || !file_type) {
      this._cycleTimeout = setTimeout(() => {
        if (this.props.handleEndOfPlay) {
          this.props.handleEndOfPlay();
        }
      }, cycle_time);
    }
  }

  render() {
    if (!this.state.base_folder || !this.state.base_folder.fields) {
      return null;
    }
    return (
      <div style={{color: "#FFFFFF"}}>
        <div
          style={{
            position: "absolute",
            width: this.state.width,
            left: 0,
            right: 0,
            textAlign: "center",
            top: this.state.height * 0.0249,
            fontSize: this.state.height * 0.0249,
            textTransform: "uppercase",
            fontWeight: "bold",
            color: GREY_HEADER,
            fontFamily: "whitney-bold",
            whiteSpace: 'pre-wrap'
          }}
        >
          {this.state.base_folder.fields.header01}
        </div>
        <div
          style={{
            position: "absolute",
            textAlign: "end",
            top: this.state.height * 0.2185,
            left: this.state.width * 0.15,
            width: this.state.width * 0.7,
            fontSize: this.state.height * 0.01999,
            fontFamily: "whitney-book",
            whiteSpace: 'pre-wrap'
          }}
        >
          {`"${this.state.base_folder.fields.quote01}"`}
          <div
            style={{
              height: '5px'
            }}
          ></div>
          <span style={{
            fontSize: this.state.height * 0.0224,
            fontFamily: 'whitney-light',
            textTransform: "uppercase"
          }}>
            {`- ${this.state.base_folder.fields.quotedPerson01}`}
          </span>
          <br/>
          <br/>
          <div
            style={{
              backgroundImage: `url(${this._file})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
              width: this.state.width * 0.7,
              height: 0.4 * this.state.height,
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default FremontTemplate003;
