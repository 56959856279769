import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { IDisplayComponentProps } from '../../interfaces/display-component-props.interface';
import get from 'lodash/get';

interface IBezelException {
  orientation: string;
  x: number;
  y: number;
}

const Bezels2107: React.FC<IDisplayComponentProps> = (props) => {
  const exceptions: IBezelException[] = get(props, 'data.componentSpecificData.exceptions', []);
  const [showBezels, _setShowBezels] = useState(false);
  
  const showBezelsRef = useRef(showBezels);
  const setShowBezels = data => {
    showBezelsRef.current = data;
    _setShowBezels(data);
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    const alwaysShow = get(props, 'data.componentSpecificData.alwaysShow', null);
    if (alwaysShow !== null && showBezelsRef.current !== alwaysShow) {
      setShowBezels(alwaysShow);
    }
  }, [props.data]);
  
  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case "d":
        setShowBezels(!showBezelsRef.current);
        break;
      default:
        break;
    }
  }, []);

  const hScreens = get(props, 'data.componentSpecificData.horizontal_screens');
  const vScreens = get(props, 'data.componentSpecificData.vertical_screens');

  const hLines = Array.apply(null, { length: (vScreens + 1) }).map(Number.call, Number);
  const vLines = Array.apply(null, { length: (hScreens + 1) }).map(Number.call, Number);

  const regionHeight = props.containerHeight / vScreens;
  const regionWidth = props.containerWidth / hScreens;
  const bezel = 2;

  const debugBoxStyle: CSSProperties = {
    position: 'absolute',
    opacity: .7,
    backgroundColor: "grey"
  }

  const renderedHlines = hLines.map((lNumber, key) => {
    let lines = [];
    let bezelOffset = lNumber > 0 ? bezel / 2 : 0;
    bezelOffset = lNumber === (hLines.length - 1) ? bezel : bezelOffset;
    for (let i = 0 ; i < hScreens; i++) {
      const foundException = exceptions.find(e => e.orientation === 'horizontal' && e.y === lNumber && e.x === i);
      if (foundException) {
        continue;
      }
      const style: CSSProperties = {
        top: lNumber * regionHeight - bezelOffset,
        left: regionWidth * i,
        height: bezel,
        width: regionWidth
      }
      lines.push(<div key={`horizontal-${key}-${i}`} style={{ ...debugBoxStyle, ...style }} />)
    }
    return lines;
  });

  const renderedVlines = vLines.map((lNumber, key) => {
    let lines = [];
    let bezelOffset = lNumber > 0 ? bezel / 2 : 0;
    bezelOffset = lNumber === (vLines.length - 1) ? bezel : bezelOffset;
    for (let i = 0; i < vScreens; i++) {
      const foundException = exceptions.find(e => e.orientation === 'vertical' && e.y === i && e.x === lNumber);
      if (foundException) {
        continue;
      }
      const style: CSSProperties = {
        left: lNumber * regionWidth - bezelOffset,
        top: regionHeight * i,
        width: bezel,
        height: regionHeight
      }
      lines.push(<div key={`vertical-${key}-${i}`} style={{ ...debugBoxStyle, ...style }} />);
    }
    return lines;
  });
  
  if (showBezels) {
    return (
      <div>
        {renderedVlines}
        {renderedHlines}
      </div>
    );
  }

  return <></>;
}

export default Bezels2107;