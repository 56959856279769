import { call, put, all } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  addResourceNoteValidationSchema,
  addResourcesValidationSchema,
  addResourceTagsValidationSchema,
  addTextResourcesValidationSchema,
  deleteResourceBulkValidation,
  deleteResourceNoteValidationSchema,
  deleteResourcesValidationSchema,
  deleteResourceTagsValidationSchema,
  getResourceNotesValidationSchema,
  getResourceTagsValidationSchema,
  moveResourceFolderValidationSchema,
  moveResourcesBulkValidation,
  updateFileResourceValidationSchema,
  updateResourceBulkValidation,
  updateResourceNoteValidationSchema,
  updateResourcesValidation,
  updateTextResourcesValidationSchema,
  updateUploadedResourcesValidationSchema,
} from "../shared-global/validations/ResourceValidation";
import { TREE_MODE_RESOURCE } from "../components/constants";
import { clientOptionsSlice } from "../reducers/clientOptionsSlice";
const resourcetree_resource__folderSchema = new schema.Entity(
  "resourcetree_resource__folders"
);
const resource__folderSchema = new schema.Entity("resource__folders");
const resourceSchema = new schema.Entity("resources");
const resource__tagsSchema = new schema.Entity("resource__tags");
const tagsSchema = new schema.Entity("tags");
const resource__notesSchema = new schema.Entity("resource__notes");

async function addResourcesAPI(payload) {
  return Request(
    "/resource/add_resources",
    HTTP_METHODS.POST,
    { ...payload },
    addResourcesValidationSchema
  );
}

async function updateResourcesAPI(payload) {
  return Request(
    "/resource/update_resource",
    HTTP_METHODS.POST,
    { ...payload },
    updateResourcesValidation
  );
}

async function updateUploadedResourcesAPI(payload) {
  return Request(
    "/resource/update_uploaded_resources",
    HTTP_METHODS.POST,
    { ...payload },
    updateUploadedResourcesValidationSchema
  );
}

async function addTextResourcesAPI(payload) {
  return Request(
    "/resource/add_text_resources",
    HTTP_METHODS.POST,
    { ...payload },
    addTextResourcesValidationSchema
  );
}

async function updateTextResourceAPI(payload) {
  return Request(
    "/resource/update_text_resource",
    HTTP_METHODS.POST,
    { ...payload },
    updateTextResourcesValidationSchema
  );
}

async function updateFileResourceAPI(payload) {
  return Request(
    "/resource/update_file_resource",
    HTTP_METHODS.POST,
    { ...payload },
    updateFileResourceValidationSchema
  );
}

async function deleteResourcesAPI(payload) {
  return Request(
    "/resource/delete_resources",
    HTTP_METHODS.POST,
    { ...payload },
    deleteResourcesValidationSchema
  );
}

async function getResourceTagsAPI(payload) {
  return Request(
    "/resource/tags",
    HTTP_METHODS.GET,
    { ...payload },
    getResourceTagsValidationSchema
  );
}

async function addResourceTagAPI(payload) {
  return Request(
    "/resource/add_tag",
    HTTP_METHODS.POST,
    { ...payload },
    addResourceTagsValidationSchema
  );
}

async function deleteResourceTagAPI(payload) {
  return Request(
    "/resource/delete_tag",
    HTTP_METHODS.POST,
    { ...payload },
    deleteResourceTagsValidationSchema
  );
}

async function getResourceNotesAPI(payload) {
  return Request(
    "/resource/notes",
    HTTP_METHODS.GET,
    { ...payload },
    getResourceNotesValidationSchema
  );
}

async function addResourceNotesAPI(payload) {
  return Request(
    "/resource/add_note",
    HTTP_METHODS.POST,
    { ...payload },
    addResourceNoteValidationSchema
  );
}

async function deleteResourceNotesAPI(payload) {
  return Request(
    "/resource/delete_note",
    HTTP_METHODS.POST,
    { ...payload },
    deleteResourceNoteValidationSchema
  );
}

async function updateResourceNotesAPI(payload) {
  return Request(
    "/resource/update_note",
    HTTP_METHODS.POST,
    { ...payload },
    updateResourceNoteValidationSchema
  );
}

async function moveResourceFolderAPI(payload) {
  return Request(
    "/resource/move_resource_folder",
    HTTP_METHODS.POST,
    { ...payload },
    moveResourceFolderValidationSchema
  );
}

async function updateBulkResourcesAPI(payload) {
  return Request(
    "/resource/update_resource_bulk",
    HTTP_METHODS.POST,
    { ...payload },
    updateResourceBulkValidation
  );
}

async function deleteBulkResourcesAPI(payload) {
  return Request(
    "/resource/delete_resource_bulk",
    HTTP_METHODS.POST,
    { ...payload },
    deleteResourceBulkValidation
  );
}

async function moveBulkResourcesAPI(payload) {
  return Request(
    "/resource/move_resources_bulk",
    HTTP_METHODS.POST,
    { ...payload },
    moveResourcesBulkValidation
  );
}

const resourceSaga = {
  addResources: function* (action) {
    try {
      const response = yield call(addResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "ADD_RESOURCE_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
          put({
            type: "ADD_RESOURCE_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(
              response.data.resourcetree_resource__folders,
              [resourcetree_resource__folderSchema]
            ),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateResource: function* (action) {
    try {
      const response = yield call(updateResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        console.log("Action Payload", action.payload);
        yield all([
          put({
            type: "UPDATE_RESOURCE_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "ADD_RESOURCES_FAIL" });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  updateUploadedResources: function* (action) {
    try {
      const response = yield call(updateUploadedResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_UPLOADED_RESOURCES_SUCCESS",
          normalized: normalize(response.data.resources, [resourceSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "UPDATE_UPLOADED_RESOURCES_FAIL" });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_UPLOADED_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  cleanResources: function* (action) {
    let removeKeys = [];
    if (
      action.payload.removed_resource_folders &&
      action.payload.removed_resources.length
    ) {
      removeKeys.push({
        entities: "resources",
        ids: action.payload.removed_resources,
      });
    }
    if (
      action.payload.removed_resource_folders &&
      action.payload.removed_resource_folders.length
    ) {
      removeKeys.push({
        entities: "resourcetree_resource__folders",
        ids: action.payload.removed_resource_folders,
      });
    }
    if (
      action.payload.removed_resource_folders &&
      action.payload.removed_resource_folders.length
    ) {
      removeKeys.push({
        entities: "resource__folders",
        ids: action.payload.removed_resource_folders,
      });
    }

    if (removeKeys.length) {
      yield put({
        type: "REMOVE_KEYS_SUCCESS",
        removeKeys: removeKeys,
      });
    }

    if (action.payload.resource__folders.length) {
      yield put({
        type: "UPDATE_RESOURCE_FOLDERS_SUCCESS",
        normalized: normalize(action.payload.resource__folders, [
          resource__folderSchema,
        ]),
      });
    }
  },

  *toggleResourceFolderExpanded(action) {
    try {
      if (action.payload.doSelect) {
        yield put(
          clientOptionsSlice.actions.folderSelect({
            treeId: action.payload.treeId,
            treemode: action.payload.treemode,
          })
        );
      }
      yield put({
        type: "TOGGLE_RESOURCE_FOLDER_EXPANDED_SUCCESS",
        treeId: action.payload.treeId,
        doOpenClose: action.payload.doOpenClose,
        doSelect: action.payload.doSelect,
      });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "TOGGLE_RESOURCE_FOLDER_EXPANDED_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  toggleModalResourceFolderExpanded: function* (action) {
    try {
      yield put(
        clientOptionsSlice.actions.resourceSelectModal({
          treeId: action.payload.treeId,
        })
      );
      yield put({
        type: "TOGGLE_MODAL_TREE_FOLDER_EXPANDED_SUCCESS",
        treeId: action.payload.treeId,
      });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "TOGGLE_MODAL_TREE_FOLDER_EXPANDED_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  addTextResources: function* (action) {
    try {
      const response = yield call(addTextResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "ADD_RESOURCE_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(
              response.data.resourcetree_resource__folders,
              [resourcetree_resource__folderSchema]
            ),
          }),
          put({
            type: "ADD_TEXT_RESOURCE_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  updateTextResource: function* (action) {
    try {
      const response = yield call(updateTextResourceAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_TEXT_RESOURCE_SUCCESS",
          normalized: normalize(response.data.resource, resourceSchema),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  updateFileResource: function* (action) {
    try {
      const response = yield call(updateFileResourceAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_FILE_RESOURCE_SUCCESS",
          normalized: normalize(response.data.resources, [resourceSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_TEXT_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deleteResources: function* (action) {
    try {
      const response = yield call(deleteResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_RESOURCES_SUCCESS",
          removeKeys: [
            { entities: "resources", ids: action.payload.resource_ids },
            {
              entities: "resourcetree_resource__folders",
              ids: response.data.resourcetree_resource__folders.map(
                (x) => x.id
              ),
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_RESOURCES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getResourceTags: function* (action) {
    try {
      const response = yield call(getResourceTagsAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_RESOURCE_TAGS_SUCCESS",
          normalized: normalize(response.data.resource__tags, [
            resource__tagsSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_RESOURCE_TAGS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_RESOURCE_TAGS_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deleteResourceTags: function* (action) {
    try {
      const response = yield call(deleteResourceTagAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_RESOURCE_TAG_SUCCESS",
          removeKeys: [
            {
              entities: "resource__tags",
              ids: response.data.resource__tags.map((x) => x.id),
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_RESOURCE_TAG_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_RESOURCE_TAG_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  addResourceTags: function* (action) {
    try {
      const response = yield call(addResourceTagAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          yield put({
            type: "ADD_RESOURCE_TAG_SUCCESS",
            normalized: normalize(response.data.resource__tags, [
              resource__tagsSchema,
            ]),
          }),
          yield put({
            type: "ADD_RESOURCE_TAG_SUCCESS",
            normalized: normalize(response.data.tags, [tagsSchema]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_RESOURCE_TAG_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_RESOURCE_TAG_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getResourceNotes: function* (action) {
    try {
      const response = yield call(getResourceNotesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_RESOURCE_NOTES_SUCCESS",
          normalized: normalize(response.data.resource__notes, [
            resource__notesSchema,
          ]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_RESOURCE_NOTES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_RESOURCE_NOTES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  addResourceNotes: function* (action) {
    try {
      const response = yield call(addResourceNotesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_RESOURCE_NOTES_SUCCESS",
          normalized: normalize(response.data.resource__notes, [
            resource__notesSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_RESOURCE_NOTES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_RESOURCE_NOTES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deleteResourceNotes: function* (action) {
    try {
      const response = yield call(deleteResourceNotesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_RESOURCE_NOTES_SUCCESS",
          removeKeys: [
            {
              entities: "resource__notes",
              ids: response.data.resource__notes.map((x) => x.id),
            },
          ],
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_RESOURCE_NOTES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_RESOURCE_NOTES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateResourceNotes: function* (action) {
    try {
      const response = yield call(updateResourceNotesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_RESOURCE_NOTES_SUCCESS",
          normalized: normalize(response.data.resource__notes, [
            resource__notesSchema,
          ]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_RESOURCE_NOTES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_RESOURCE_NOTES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  moveResourceFolder: function* (action) {
    try {
      const response = yield call(moveResourceFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "MOVE_RESOURCE_FOLDER_SUCCESS",
          normalized: normalize(response.data.resourcetree_resource__folders, [
            resourcetree_resource__folderSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "MOVE_RESOURCE_FOLDER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "MOVE_RESOURCE_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateBulkResources: function* (action) {
    try {
      const response = yield call(updateBulkResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "UPDATE_RESOURCES_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
          put({
            type: "UPDATE_RESOURCE_FOLDERS_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resourcetree_resource__folderSchema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  deleteBulkResources: function* (action) {
    try {
      const response = yield call(deleteBulkResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        const removeKeys = [];
        if (
          response.data.removed_resource__folders &&
          response.data.removed_resource__folders.length
        ) {
          removeKeys.push({
            entities: "resourcetree_resource__folders",
            ids: response.data.removed_resource__folders,
          });
        }
        if (
          response.data.removed_resources &&
          response.data.removed_resources.length
        ) {
          removeKeys.push({
            entities: "resources",
            ids: response.data.removed_resources,
          });
        }
        if (removeKeys.length > 0) {
          yield put({ type: "REMOVE_FOLDER_SUCCESS", removeKeys });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data.error);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  moveBulkResources: function* (action) {
    try {
      const response = yield call(moveBulkResourcesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "FETCH_RESOURCETREE_RESOURCE__FOLDERS_SUCCESS",
          normalized: normalize(response.data.resourcetree_resource__folders, [
            resourcetree_resource__folderSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },
};

export default resourceSaga;
