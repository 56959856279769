import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextObjInput from './TextObjInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectExistingResource from './SelectExistingResource';
import { connect } from "react-redux";
import _ from 'lodash';

class WysiwygLinkableInput extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    onBlur: () => {}
  };

  constructor(props) {
    super(props);
    //console.log("constructor props?", props);
    let stateObj = {
      value: null,
      resource_linked: false,
      resource_id: null
    }
    if (this.props.defaultValue){
      if (this.props.defaultValue.resource_linked === true){
        stateObj.resource_linked = true;
        stateObj.resource_id = this.props.defaultValue.resource_id
      }
    }
    this.state = stateObj
    this._localValue = this.props.defaultValue || { resource_linked: false}
    this._selectExistingResource = null;
  }


  // shouldComponentUpdate() {

  // }
  
  onChange = (content, delta, source, editor) => {

  }

  unlink = () => {
    this._localValue.resource_linked = false;
    this.props.onChange(this.props.fieldName, this._localValue);
    this.setState({
      resource_linked: false
    })
  }

  link = () => {
    this._selectExistingResource.openGallery();
    this.props.onChange(this.props.fieldName, this._localValue);
  }

  renderLinkButton = () => {
    if (this.state.resource_linked === true){
      return(
          <span style={{cursor: 'pointer'}}><FontAwesomeIcon onClick={() => this.unlink()} icon={['far', 'unlink']} /></span>
      )    }
      return(
          <span style={{cursor: 'pointer'}}><FontAwesomeIcon onClick={() => this.link()} icon={['far', 'link']} /></span>
      )
  }

  renderTextObject = () => {
    if (this.state.resource_linked === true) {
      if (this.state.resource_id){
        let text = _.get(this.props.resources[this.state.resource_id], 'string_data', "");
        return <div>{text}</div>
      }
      return (
        <div>...broken link</div>
      )
    }
    return (
      <TextObjInput {...this.props} onBlur={this.onFieldBlur} onChange={this.onChange} showLabel={false}/>
    )
  }

  onBlur = (fieldName, contents) => {
    //console.log("blur", fieldName, contents);
    contents.resource_linked = this.state.resource_linked
    this._localValue = contents;
    this.props.onBlur(fieldName, contents); 
  }
  
  onChange = (fieldName, contents) => {
    //console.log("onChange", fieldName, contents);
    //console.log(JSON.stringify(contents));
    contents.resource_linked = this.state.resource_linked
    this._localValue = contents;
    this.props.onChange(fieldName, contents);
  }

  handleLinkToResource = (resource) => {
    //console.log("THE RESOURCE", resource)
    if (resource && resource.id){
      this._localValue.resource_linked = true;
      this._localValue.resource_id = resource.id;
      this.props.onChange(this.props.fieldName, this._localValue);
      this.setState({
        resource_linked: true,
        resource_id: resource.id
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <label className="form-input-label">{this.props.title} {this.renderLinkButton()}</label><br />
        {this.renderTextObject()}
        <SelectExistingResource filters={['plain_text']} onOkay={this.handleLinkToResource} ref={ref => this._selectExistingResource = ref}/>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    resources: state.data.resources
  };
};

export default connect(mapStateToProps, null)(WysiwygLinkableInput);