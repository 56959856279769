import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { name } from "../standardized_fields";
export const los_angeles_slides: IFolderType = {
  name: FOLDER_TYPE.los_angeles_slides,
  display_name: "Slides",
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  acceptable_folder_types: [FOLDER_TYPE.los_angeles_slide],
  fields: {
    name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "crossfade",
      options: [
        {
          title: "Crossfade",
          value: "crossfade"
        },
        {
          title: "Fade Out",
          value: "transition-fadeout"
        },
        {
          title: "Slide In from Right",
          value: "transition-slide-in-from-right"
        },
        {
          title: "Slide In from Left",
          value: "transition-slide-in-from-left"
        },
        {
          title: "Slide In from Top",
          value: "transition-slide-in-from-top"
        },
        {
          title: "Slide In from Bottom",
          value: "transition-slide-in-from-bottom"
        }
      ]
    },
    file: {
      title: "Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.98,
      recommended_size: {
        width: 191,
        height: 96
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.185
      }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name"]
  }
};
