import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    width: "100%",
    height: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    "& #actions": {
      height: 'auto',
      display: "flex",
      width: "100%",
      paddingRight: 10,
      paddingLeft: 10,
      marginBottom: 20,
      "& #multi-action": {
        flex: 1
      },
      "& #top-buttons": {
        textAlign: "right",
        flex: 1
      }
    },
  },

  modal:{
    "& .ant-modal-content":{
      borderRadius:"0.25rem",
      height:"7.6rem",
      "& .ant-modal-footer": {
        height: "4.25rem",
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
      }
    }
  },
  selectedItem: {
    background: "#2699FB"
  },
  foldersWrapper: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    flexFlow: 'wrap',
    marginBottom: 20,
  },
  folder: {
    padding: 10
  },
  goBackFolder: {
    padding: 10,
    cursor: 'pointer'
  },
  selectedFolder: {
    padding: 10,
    background: "#2699FB"
  }
});

export default useStyles;