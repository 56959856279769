import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { tree_icon_types } from "../tree_icon_types";
import { list_styles, transitions, textstyle_options, springfieldFonts } from "./springfield_settings";

export const springfield_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.springfield_root,
  display_name: "Springfield Root",
  acceptable_folder_types: [
    FOLDER_TYPE.springfield_content,
    FOLDER_TYPE.springfield_presentations,
    FOLDER_TYPE.springfield_displays
  ],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    templates: {
      title: "Templates",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "templates"
    },
  },
  fields: {
    // ROOT
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
        related_field: "media_with_quote_1_cycle_time"
      }
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "transition-fade",
      options: [...transitions],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Default Background",
      field_group: "general",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    default_fonts: {
      type: "fonts",
      title: "Default Font Style",
      field_group: "general",
      default: springfieldFonts,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: false
      }
    },
    templates_select: {
      type: "select",
      title: "Choose a template to edit the default definitions",
      field_group: "templates",
      default: "none",
      options: [
        { title: "Select a template", value: "none" },
        { title: "Story Fullscreen", value: "story_fullscreen"},
        { title: "Story with Single Image", value: "story_single" },
        { title: "Story with Two Images", value: "story_double"},
        { title: "Story with Quote", value: "story_quote" },
        { title: "List", value: "list" }
      ],
    },
    // STORY FULLSCREEN
    story_fullscreen_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_fullscreen',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_fullscreen_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_fullscreen',
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    // STORY WITH SINGLE IMAGE
    story_single_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "20px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Subhead Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "15px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "11px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_image: {
      title: "Default Story Image",
      type: "file",
      slot_type: "dynamic",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.095334685598377,
      recommended_size: {
        width: 986,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.095334685598377
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
    },
    story_single_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_single',
    },
    // STORY DOUBLE IMAGES
    story_double_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "20px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Subhead Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "15px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "11px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_image_1: {
      title: "Default Story Image 1",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_image_2: {
      title: "Default Story Image 2",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_double_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
    },
    story_double_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_double',
    },
    // STORY QUOTE
    story_quote_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_text: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Quote Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "50px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_attribution: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Attribution Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "45px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Headline Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "20px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Subhead Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "15px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "11px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_image: {
      title: "Default Story Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
    },
    story_quote_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
    },
    story_quote_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_quote',
    },
    // LIST
    list_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "18px",
        bold: true,
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_description: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Description Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "11px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_category: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Category Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "12px",
        bold: true,
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_names: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Donor Names Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "13px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_footer: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Footer Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "10px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    show_upper_rule: {
      type: "bool",
      title: "Default Show upper horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    show_lower_rule: {
      type: "bool",
      title: "Default Show lower horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Default Horizontal Rule Color",
      default: "#000000",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Default Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.1,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_image: {
      title: "Default List Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    sorting_options: {
      type: "select",
      title: "Default List Sorting Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "rank",
      options: [
        {
          title: "Rank",
          value: "rank"
        },
        {
          title: "Sorting Order",
          value: "sorting_order"
        },
        {
          title: "Name",
          value: "name"
        }
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_list_style: {
      type: "select",
      title: "Default List Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles
    }
  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  has_font_config: true,
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.springfield_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.springfield_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.springfield_content
    }
  ],
  hide_add_icon_in_tree: true
};
