import React from "react";
import { InputNumber } from "antd";
import InputTooltip from "./InputTooltip";
import { BaseInputProps } from "./types";

export type NumberInputProps = BaseInputProps<number> & { step?: number };

const NumberInput = (props: NumberInputProps) => {
  return (
    <>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          {props.tooltip ? <InputTooltip tooltip={props.tooltip} /> : null}
          <br />
        </span>
      ) : null}
      <InputNumber
        className="number-input"
        defaultValue={props.defaultValue}
        value={props.defaultValue}
        onChange={(ev) => {
          const val = ev === "" || ev === null ? null : Number(ev);
          props.onChange(props.fieldName, val);
          props.onBlur(props.fieldName, val);
        }}
        disabled={!props.canUpdate}
        step={props.step || 1}
        min={0}
      />
    </>
  );
};

export default NumberInput;
