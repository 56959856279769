import type { PayloadAction } from "@reduxjs/toolkit";
import { call, put, all, race, take, delay } from "redux-saga/effects";
import _ from "lodash";
import { normalize, schema } from "normalizr";
import request from "../utils/api/ServerRequest";
import {
  START_POLL_FOLDER_STATUS,
  STOP_POLL_FOLDER_STATUS,
} from "../actions/actionTypes";
import { getJsonFromS3SignedUrl } from "../shared-global/utils/general";
import { addError, addExceptionError } from "../actions/notificationActions";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import folder_types from "../shared-global/interfaces/folder-types/themes/folder_types";
import { tree_icon_types } from "../shared-global/interfaces/folder-types/themes/tree_icon_types";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  addBulkFoldersValidationSchema,
  addFolderValidationSchema,
  cloneBulkFoldersValidationSchema,
  cloneFolderValidationSchema,
  deleteDisplayIdentifierValidationSchema,
  downloadCsvValidationSchema,
  downloadDisplayHardwareInfoCsvValidationSchema,
  downloadDisplayHardwareInfoTxtValidationSchema,
  getCompanyRootFoldersValidationSchema,
  getDisplayInfoValidationSchema,
  getFolderInfoValidationSchema,
  instanceFolderValidationSchema,
  makePullableValidationSchema,
  moveBulkFoldersValidationSchema,
  moveFolderValidationSchema,
  publishFolderValidationSchema,
  pushDownInheritableFieldsValidationSchema,
  removeFolderValidationSchema,
  updateBulkFoldersValidationSchema,
  updateDisplayHardwareInfoValidationSchema,
  updateFolderArModelValidationSchema,
  updateFolderContentsValidationSchema,
  updateFolderValidationSchema,
  updateUploadedResourcesValidationSchema,
  uriGetAccessDataValidationSchema,
} from "../shared-global/validations/FolderValidation";
import { generateClientConfigValidationSchema } from "../shared-global/validations/AdminValidation";
import { IFolder } from "../shared-global/interfaces/models/folder.interface";
import { TREE_MODE_APPLIED_CONTENT } from "../components/constants";
import { clientOptionsSlice } from "../reducers/clientOptionsSlice";

const folderSchema = new schema.Entity("folders");
const folder__folderSchema = new schema.Entity("folder__folders");
const fontGroupSchema = new schema.Entity("font_groups");

const resourcetree_folderSchema = new schema.Entity("resourcetree_folders");
const resourcetree_folder__folderSchema = new schema.Entity(
  "resourcetree_folder__folders"
);
const resourcetree_resource__folderSchema = new schema.Entity(
  "resourcetree_resource__folders"
);
const folder_ar_modelSchema = new schema.Entity("folder_ar_models");

const companySchema = new schema.Entity("companies");
const projectSchema = new schema.Entity("projects");
const locationSchema = new schema.Entity("locations");

const resource__folderSchema = new schema.Entity("resource__folders");
const resourceSchema = new schema.Entity("resources");
const role__usersSchema = new schema.Entity("role__users");

const folder__statusSchema = new schema.Entity("folder__status");
const folder__metasSchema = new schema.Entity(
  "folder_metas",
  {},
  { idAttribute: "folder" }
);

const folderPathSchema = new schema.Entity(
  "folder_paths",
  {},
  { idAttribute: "path" }
);

const tagsSchema = new schema.Entity("tags");

const systemSettingsSchema = new schema.Entity("system_settings");

const webview_signedurlsSchema = new schema.Entity(
  "webview_signedurls",
  {},
  { idAttribute: "uuid_preset" }
);

async function getFolderArModelApi(payload) {
  return Request("/folder/get_folder_ar_model", HTTP_METHODS.GET, payload);
}

async function upsertFolderArModelAPI(payload) {
  return Request(
    "/folder/update_folder_ar_model",
    HTTP_METHODS.POST,
    payload,
    updateFolderArModelValidationSchema
  );
}

async function pushDownInheritableFieldsAPI(payload) {
  return Request(
    "/folder/push_down_inheritable_fields",
    HTTP_METHODS.POST,
    payload,
    pushDownInheritableFieldsValidationSchema
  );
}

async function uriAccessGetDataAPI(payload) {
  return Request(
    "/folder/uri_access_get_data",
    HTTP_METHODS.POST,
    payload.params,
    uriGetAccessDataValidationSchema
  );
}

async function updateFolderContentsAPI(payload) {
  return Request(
    "folder/update_folder_contents",
    HTTP_METHODS.POST,
    payload,
    updateFolderContentsValidationSchema
  );
}

async function generateClientConfigAPI(payload) {
  return Request(
    "admin/generate_client_config",
    HTTP_METHODS.GET,
    {
      ...payload,
    },
    generateClientConfigValidationSchema
  );
}

async function makeFolderPullableAPI(payload) {
  return Request(
    "folder/make_pullable",
    HTTP_METHODS.GET,
    {
      ...payload,
    },
    makePullableValidationSchema
  );
}

async function getFolderInfoAPI(payload) {
  return Request(
    "folder/get_folder_info",
    HTTP_METHODS.GET,
    {
      ...payload,
    },
    getFolderInfoValidationSchema
  );
}

async function fetchProjectFoldersAPI(payload) {
  return Request("/app/ls_data", HTTP_METHODS.GET, {
    ...payload,
  });
}

async function fetchCompanyRootFoldersAPI(payload) {
  return Request(
    "/folder/get_company_root_folders",
    HTTP_METHODS.GET,
    {
      ...payload,
    },
    getCompanyRootFoldersValidationSchema
  );
}

async function moveFolderAPI(payload) {
  return Request(
    "/folder/move_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    moveFolderValidationSchema
  );
}

async function cloneFolderAPI(payload) {
  return Request(
    "/folder/clone_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    cloneFolderValidationSchema
  );
}

async function addFolderAPI(payload) {
  return Request(
    "/folder/add_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    addFolderValidationSchema
  );
}

async function updateFolderAPI(payload) {
  return Request(
    "/folder/update",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    updateFolderValidationSchema
  );
}

async function addBulkFoldersAPI(payload) {
  return Request(
    "/folder/add_bulk",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    addBulkFoldersValidationSchema
  );
}

async function updateBulkFoldersAPI(payload) {
  return Request(
    "/folder/update_bulk",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    updateBulkFoldersValidationSchema
  );
}

async function downloadFolderCsvAPI(payload) {
  return Request(
    "/folder/download_csv",
    HTTP_METHODS.GET_FILE,
    {
      ...payload,
    },
    downloadCsvValidationSchema,
    {
      responseType: "blob",
    }
  );
}

async function downloadHardwareInformationCsvAPI(payload) {
  return Request(
    "/folder/download_display_hardware_info_csv",
    HTTP_METHODS.GET_FILE,
    {
      ...payload,
    },
    downloadDisplayHardwareInfoCsvValidationSchema,
    {
      responseType: "blob",
    }
  );
}

async function downloadHardwareInformationTxtAPI(payload) {
  return Request(
    "/folder/download_display_hardware_info_txt",
    HTTP_METHODS.GET_FILE,
    {
      ...payload,
    },
    downloadDisplayHardwareInfoTxtValidationSchema,
    {
      responseType: "blob",
    }
  );
}

async function uploadFolderCsvAPI(payload) {
  try {
    const formData = new FormData();
    formData.append("folder", payload.value.folder);
    formData.append("removeAll", payload.value.removeAll);
    formData.append(payload.value.fileFieldName, payload.value.file);
    const response = await request.post("/folder/upload_csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress(progressEvent) {
        if (payload.request && payload.request.onProgress) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          payload.request.onProgress(percentCompleted);
        }
      },
      timeout: 150000,
    });
    return response;
  } catch (e) {
    return e;
  }
}

async function updateUploadedResourcesAPI(payload) {
  return Request(
    "/folder/update_uploaded_resources",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    updateUploadedResourcesValidationSchema
  );
}

async function removeFolderAPI(payload) {
  return Request(
    "/folder/remove_folder",
    HTTP_METHODS.DELETE,
    {
      ...payload,
    },
    removeFolderValidationSchema
  );
}

async function instanceFolderAPI(payload) {
  return Request(
    "/folder/instance_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    instanceFolderValidationSchema
  );
}

async function publishFolderAPI(payload) {
  return Request(
    "/folder/publish_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    publishFolderValidationSchema
  );
}

async function deleteBulkFoldersAPI(payload) {
  return Request(
    "/folder/remove_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    removeFolderValidationSchema
  );
}

async function bulkMoveFolderAPI(payload) {
  return Request(
    "/folder/move_bulk_folders",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    moveBulkFoldersValidationSchema
  );
}

async function bulkCloneFolderAPI(payload) {
  return Request(
    "/folder/clone_bulk_folders",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    cloneBulkFoldersValidationSchema
  );
}

async function deleteDisplayIdentifierAPI(payload) {
  return Request(
    "/folder/delete_display_identifier",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    deleteDisplayIdentifierValidationSchema
  );
}

async function getDisplayAPI(payload) {
  return Request(
    "/folder/get_display_info",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    getDisplayInfoValidationSchema
  );
}

async function getFolderStatusAPI(payload) {
  return Request("/folder_status", HTTP_METHODS.GET, {
    ...payload,
  });
}

async function updateDisplayHardwareInfoAPI(payload) {
  return Request(
    "/folder/update_display_hardware_info",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    updateDisplayHardwareInfoValidationSchema
  );
}

const folderSaga = {
  // Temporary -- break these out into separate sagas
  *fetchProjectFolders(action) {
    try {
      const response = yield call(fetchProjectFoldersAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "LS_DATA_SUCCESS",
          normalized: {
            entities: {
              ...normalize(response.data.companies, [companySchema]).entities,
              ...normalize(response.data.projects, [projectSchema]).entities,
              ...normalize(response.data.locations, [locationSchema]).entities,
              ...normalize(response.data.folders, [folderSchema]).entities,
              ...normalize(response.data.folder__folders, [
                folder__folderSchema,
              ]).entities,
              ...{ folder_types: folder_types },
              ...normalize(response.data.resources, [resourceSchema]).entities,
              ...normalize(response.data.resource__folders, [
                resource__folderSchema,
              ]).entities,
              ...{ tree_icon_types: tree_icon_types },
              ...normalize(response.data.resourcetree_folder__folders, [
                resourcetree_folder__folderSchema,
              ]).entities,
              ...normalize(response.data.resourcetree_resource__folders, [
                resourcetree_resource__folderSchema,
              ]).entities,
              ...normalize(response.data.role__users, [role__usersSchema])
                .entities,
              ...normalize(response.data.tags, [tagsSchema]).entities,
              ...normalize(response.data.system_settings, [
                systemSettingsSchema,
              ]).entities,
              ...normalize(response.data.folder_paths, [folderPathSchema])
                .entities,
              ...normalize(response.data.font_groups, [fontGroupSchema])
                .entities,
            },
          },
        });

        yield put({
          type: "UPDATE_RESOURCE_TREE",
          ...normalize(response.data.resourcetree_folders, [
            resourcetree_folderSchema,
          ]).entities,
        });

        yield put({
          type: "THEME_SETTINGS_SUCCESS",
          theme_settings: response.data.theme_settings,
        });
      } else {
        yield put({
          type: "FETCH_PROJECT_FOLDERS_FAIL",
          payload: { departments: [] },
        });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({
        type: "FETCH_PROJECT_FOLDERS_FAIL",
        payload: { departments: [] },
      });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *fetchCompanyRootFolders(action) {
    try {
      const response = yield call(fetchCompanyRootFoldersAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "FETCH_COMPANY_FOLDER_FOLDERS_SUCCESS",
          normalized: normalize(response.data.folder__folders, [
            folder__folderSchema,
          ]),
        });
        yield put({
          type: "FETCH_COMPANY_FOLDERS_SUCCESS",
          normalized: normalize(response.data.folders, [folderSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "FETCH_COMPANY_FOLDERS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "FETCH_COMPANY_FOLDERS_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *moveFolder(action) {
    try {
      const response = yield call(moveFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        if (action.payload.tree_type === "resource") {
          yield put({
            type: "MOVE_RESOURCE_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.resourcetree_folder__folders, [
              resourcetree_folder__folderSchema,
            ]),
          });
        } else {
          yield all([
            put({
              type: "MOVE_FOLDER_SUCCESS",
              normalized: normalize(response.data.folder_folders, [
                folder__folderSchema,
              ]),
            }),
            put({
              type: "FETCH_FOLDER_PATHS_SUCCESS",
              normalized: normalize(response.data.folder_paths, [
                folderPathSchema,
              ]),
            }),
          ]);
        }

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "MOVE_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *cloneFolder(action) {
    let response;
    try {
      response = yield call(cloneFolderAPI, action.payload);

      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "ADD_CONTENT_FOLDER_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__folderSchema,
            ]),
          }),
          put({
            type: "ADD_CONTENT_RESOURCE__FOLDER_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
          put({
            type: "TOGGLE_FOLDER_EXPANDED",
            payload: {
              treeId: `folder-${response.data.main_cloned_folder.id}`,
              doOpenClose: false,
              doSelect: true,
            },
          }),
        ]);

        if (action.onSuccess) {
          let firstFolderId;
          if (response.data.folders && response.data.folders.length > 0) {
            firstFolderId = response.data.folders[0].id;
          } else if (
            response.data.resourcetree_folders &&
            response.data.resourcetree_folders.length > 0
          ) {
            firstFolderId = response.data.resourcetree_folders[0].id;
          }
          action.onSuccess(firstFolderId);
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "ADD_FOLDER_FAIL" });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error happened"
          );
        }
      }
    } catch (e) {
      yield put({ type: "ADD_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail && e) {
        action.onFail(e.message || "Unexpected error happened");
      }
    }
  },

  *addFolder(action) {
    let response;
    try {
      response = yield call(addFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        if (action.payload.tree_type === "resource") {
          yield put({
            type: "ADD_RESOURCE_FOLDER_SUCCESS",
            normalized: normalize(response.data.resourcetree_folders, [
              resourcetree_folderSchema,
            ]),
          });
          yield put({
            type: "ADD_RESOURCE_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.resourcetree_folder__folders, [
              resourcetree_folder__folderSchema,
            ]),
          });
        } else {
          yield all([
            put({
              type: "ADD_CONTENT_FOLDER_SUCCESS",
              normalized: normalize(response.data.folders, [folderSchema]),
            }),
            put({
              type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
              normalized: normalize(response.data.folder__folders, [
                folder__folderSchema,
              ]),
            }),
            put({
              type: "FETCH_FOLDER_PATHS_SUCCESS",
              normalized: normalize(response.data.folder_paths, [
                folderPathSchema,
              ]),
            }),
            put({
              type: "ADD_CONTENT_RESOURCE__FOLDER_SUCCESS",
              normalized: normalize(response.data.resource__folders, [
                resource__folderSchema,
              ]),
            }),
          ]);
        }
        if (action.onSuccess) {
          let firstFolderId;
          if (response.data.folders && response.data.folders.length > 0) {
            firstFolderId = response.data.folders[0].id;
          } else if (
            response.data.resourcetree_folders &&
            response.data.resourcetree_folders.length > 0
          ) {
            firstFolderId = response.data.resourcetree_folders[0].id;
          }
          action.onSuccess(firstFolderId);
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "ADD_FOLDER_FAIL" });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error happened"
          );
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      yield put({ type: "ADD_FOLDER_FAIL" });
      if (action.onFail && e) {
        action.onFail(e.message || "Unexpected error happened");
      }
    }
  },

  *updateUploadedResources(action) {
    try {
      const response = yield call(updateUploadedResourcesAPI, action.payload);

      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_UPLOADED_RESOURCES_SUCCESS",
          normalized: normalize(response.data.resources, [resourceSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_UPLOADED_RESOURCES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      yield put({ type: "UPDATE_UPLOADED_RESOURCES_FAIL" });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *updateFolder(action) {
    try {
      const response = yield call(updateFolderAPI, action.payload);

      if (isResponseSuccess(response)) {
        const removeKeys = [];
        if (
          response.data.removed_resources &&
          response.data.removed_resources.length > 0
        ) {
          removeKeys.push({
            entities: "resources",
            ids: response.data.removed_resources,
          });
        }
        if (
          response.data.removed_resource_folders &&
          response.data.removed_resource_folders.length > 0
        ) {
          removeKeys.push({
            entities: "resource__folders",
            ids: response.data.removed_resource_folders,
          });
        }

        if (removeKeys.length > 0) {
          yield put({
            type: "REMOVE_KEYS_SUCCESS",
            removeKeys,
          });
        }

        yield all([
          put({
            type: "UPDATE_FOLDER_STATUS_SUCCESS",
            normalized: normalize(response.data.folder__status, [
              folder__statusSchema,
            ]),
          }),
          put({
            type: "UPDATE_RESOURCE_FOLDERS_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
          put({
            type: "UPDATE_FOLDER_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "UPDATE_RESOURCES_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
          put({
            type: "FETCH_RESOURCETREE_RESOURCE__FOLDERS_SUCCESS",
            normalized: normalize(
              response.data.resourcetree_resource__folders,
              [resourcetree_resource__folderSchema]
            ),
          }),
          put({
            type: "FETCH_FOLDER_PATHS_SUCCESS",
            normalized: normalize(response.data.folder_paths, [
              folderPathSchema,
            ]),
          }),
        ]);
        yield put({
          type: "UPDATE_S3_CACHE_SUCCESS",
          resources: response.data.resources ?? [],
          resource__folders: response.data.resource__folders ?? [],
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "UPDATE_FAIL" });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      yield put({ type: "UPDATE_FAIL" });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *updateResourceTreeFolder(action) {
    try {
      const response = yield call(updateFolderAPI, action.payload);

      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "ADD_RESOURCE_FOLDER_SUCCESS",
            normalized: normalize(response.data.resourcetree_folders, [
              resourcetree_folderSchema,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *removeFolder(action) {
    try {
      const response = yield call(removeFolderAPI, action.payload);
      const removeKeys = [];
      if (isResponseSuccess(response)) {
        if (action.payload.type === "applied_content") {
          if (response.data.removed_folder__folders) {
            removeKeys.push({
              entities: "folder__folders",
              ids: response.data.removed_folder__folders,
            });
          }
          if (response.data.removed_folders) {
            removeKeys.push({
              entities: "folders",
              ids: response.data.removed_folders,
            });
          }
          if (response.data.removed_resource__folders) {
            removeKeys.push({
              entities: "resource__folders",
              ids: response.data.removed_resource__folders,
            });
          }
        } else {
          removeKeys.push({
            entities: "resourcetree_folders",
            ids: [action.payload.folder_id],
          });
          removeKeys.push({
            entities: "resourcetree_folder__folders",
            ids: [action.payload.folder__folder_id],
          });
        }
        yield put({ type: "REMOVE_FOLDER_SUCCESS", removeKeys });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "REMOVE_FOLDER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.data?.detail ||
              "The folder has children and does not have more instances. No deletion was performed."
          );
        }
      }
    } catch (e) {
      yield put({ type: "REMOVE_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *toggleFolderExpanded(action) {
    try {
      if (action.payload.doSelect) {
        yield put(
          clientOptionsSlice.actions.folderSelect({
            treeId: action.payload.treeId,
            treemode: action.payload.treemode,
          })
        );
      }
      yield put({
        type: "TOGGLE_FOLDER_EXPANDED_SUCCESS",
        treeId: action.payload.treeId,
        doOpenClose: action.payload.doOpenClose,
        doSelect: action.payload.doSelect,
      });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "TOGGLE_FOLDER_EXPANDED_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *setFoldersExpanded(action) {
    try {
      yield put({
        type: "SET_FOLDERS_EXPANDED_SUCCESS",
        treeMode: action.payload.treeMode,
        treeIds: action.payload.treeIds,
      });
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "SET_FOLDERS_EXPANDED_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *downloadFolderCsv(action) {
    try {
      const response = yield call(downloadFolderCsvAPI, action.payload);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let filename = "folder.csv";
      let groups;
      if (response.headers && response.headers["content-disposition"]) {
        const regex = new RegExp('.+filename="(.+)"');
        groups = response.headers["content-disposition"].match(regex);
        if (groups) {
          filename = groups[1];
        }
      }
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "DOWNLOAD_CSV_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *downloadHardwareInformationCsv(action) {
    try {
      const response = yield call(
        downloadHardwareInformationCsvAPI,
        action.payload
      );
      if (isResponseSuccess(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = "folder.csv";
        let groups;
        if (response.headers && response.headers["content-disposition"]) {
          const regex = new RegExp('.+filename="(.+)"');
          groups = response.headers["content-disposition"].match(regex);
          if (groups) {
            filename = groups[1];
          }
        }
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DOWNLOAD_CSV_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DOWNLOAD_CSV_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *downloadHardwareInformationTxt(action) {
    try {
      const response = yield call(
        downloadHardwareInformationTxtAPI,
        action.payload
      );
      if (isResponseSuccess(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = "folder.txt";
        let groups;
        if (response.headers && response.headers["content-disposition"]) {
          const regex = new RegExp('.+filename="(.+)"');
          groups = response.headers["content-disposition"].match(regex);
          if (groups) {
            filename = groups[1];
          }
        }
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DOWNLOAD_TXT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DOWNLOAD_TXT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *uploadFolderCsv(action) {
    let success = false;
    let error;
    try {
      const response = yield call(uploadFolderCsvAPI, action.payload);
      if (isResponseSuccess(response)) {
        success = true;
        const removeKeys = [];
        if (
          response.data.removed_folder__folders &&
          response.data.removed_folder__folders.length > 0
        ) {
          removeKeys.push({
            entities: "folder__folders",
            ids: response.data.removed_folder__folders,
          });
        }
        yield all([
          put({
            type: "ADD_CONTENT_FOLDER_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__folderSchema,
            ]),
          }),
        ]);
        if (removeKeys.length > 0) {
          yield put({
            type: "REMOVE_KEYS_SUCCESS",
            removeKeys,
          });
        }
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        error = _.get(response, "errors", []);
        yield put({ type: "UPLOAD_CSV_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(error);
        }
      }
    } catch (e) {
      yield put({ type: "UPLOAD_CSV_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *instanceFolder(action) {
    try {
      const response = yield call(instanceFolderAPI, action.payload);
      let removeKeys = [];
      if (isResponseSuccess(response)) {
        yield put({
          type: "INSTANCE_FOLDER_SUCCESS",
          normalized: normalize(response.data.folder__folders, [
            folder__folderSchema,
          ]),
        });
        if (response.data.removed_folder__folders) {
          removeKeys.push({
            entities: "folder__folders",
            ids: response.data.removed_folder__folders,
          });
          yield put({
            type: "FOLDER__FOLDER_SUCCESS",
            removeKeys: removeKeys,
          });
        }
        if (action.onSuccess) {
          action.onSuccess(response.data.type);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *publishFolder(action) {
    try {
      const response = yield call(publishFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "PUBLISH_FOLDER_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else if (action.onFail) {
        yield put({ type: "PUBLISH_FOLDER_FAILURE" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "An error ocurred"
          );
        }
      }
    } catch (e) {
      yield put({ type: "PUBLISH_FOLDER_FAILURE" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *deleteBulkFolders(action) {
    try {
      const response = yield call(deleteBulkFoldersAPI, action.payload);
      if (isResponseSuccess(response)) {
        const removeKeys = [];
        if (action.payload.type === "applied_content") {
          if (response.data.removed_folder__folders) {
            removeKeys.push({
              entities: "folder__folders",
              ids: response.data.removed_folder__folders,
            });
          }
          if (response.data.removed_folders) {
            removeKeys.push({
              entities: "folders",
              ids: response.data.removed_folders,
            });
          }
        } else {
          removeKeys.push({
            entities: "resourcetree_folders",
            ids: [action.payload.folder_id],
          });
          removeKeys.push({
            entities: "resourcetree_folder__folders",
            ids: [action.payload.folder__folder_id],
          });
          removeKeys.push({
            entities: "folders",
            ids: [action.payload.folder_id],
          });
          removeKeys.push({
            entities: "folder__folders",
            ids: [action.payload.folder__folder_id],
          });
        }
        yield put({ type: "REMOVE_BULK_FOLDER_SUCCESS", removeKeys });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "REMOVE_BULK_FOLDER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.data
              ? response.data.detail
              : "There was an error while deleting folders"
          );
        }
      }
    } catch (e) {
      yield put({ type: "REMOVE_BULK_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *toggleFolderExpandedMoveBulkModal(action) {
    try {
      yield put({
        type: "TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL_SUCCESS",
        payload: { treeId: action.payload.treeId },
      });
      yield put(
        clientOptionsSlice.actions.folderSelectModal({
          treeId: action.payload.treeId,
        })
      );
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put({ type: "TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *bulkMoveFolder(action) {
    try {
      const response = yield call(bulkMoveFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "BULK_MOVE_FOLDER_SUCCESS",
          normalized: normalize(response.data.folder__folders, [
            folder__folderSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "BULK_MOVE_FOLDER_FAIL" });
        const err = _.get(
          response,
          "response.data.detail",
          "Folder types not compatible"
        );
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(err);
        }
      }
    } catch (e) {
      yield put({ type: "BULK_MOVE_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail && e) {
        action.onFail(e.message);
      }
    }
  },

  *bulkCloneFolder(action) {
    try {
      const response = yield call(bulkCloneFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "ADD_CONTENT_FOLDER_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__folderSchema,
            ]),
          }),
          put({
            type: "ADD_CONTENT_RESOURCE__FOLDER_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "BULK_CLONE_FOLDER_FAIL" });
        yield put(addError(response.errors));
        const err = _.get(
          response,
          "response.data.detail",
          "Folder types not compatible"
        );
        if (action.onFail) {
          action.onFail(err);
        }
      }
    } catch (e) {
      yield put({ type: "BULK_CLONE_FOLDER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail && e) {
        action.onFail(e.message);
      }
    }
  },

  *makeFolderPullable(action) {
    try {
      const response = yield call(makeFolderPullableAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "MAKE_FOLDER_PULLABLE_SUCCESS",
          normalized: normalize(response.data.folder, folderSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "MAKE_FOLDER_PULLABLE_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "MAKE_FOLDER_PULLABLE_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *getFolderInfo(action) {
    try {
      const response = yield call(getFolderInfoAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_FOLDER_INFO_SUCCESS",
          normalized: normalize(response.data.folder, folderSchema),
        });
        if (action.onSuccess) {
          action.onSuccess(response);
        }
      } else {
        yield put({ type: "GET_FOLDER_INFO_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "GET_FOLDER_INFO_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *generateClientConfig(action) {
    try {
      const response = yield call(generateClientConfigAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GENERATE_CLIENT_CONFIG_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      } else {
        yield put({ type: "GENERATE_CLIENT_CONFIG_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "GENERATE_CLIENT_CONFIG_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail && e) {
        action.onFail(e.message);
      }
    }
  },

  *deleteDisplayIdentifier(action) {
    try {
      const response = yield call(deleteDisplayIdentifierAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_DISPLAY_IDENTIFIER_SUCCESS",
          new_obj: JSON.stringify(response.data.new_obj),
          folder_id: action.payload.folder_id,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_DISPLAY_IDENTIFIER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_DISPLAY_IDENTIFIER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *get_display_info(action) {
    try {
      const response = yield call(getDisplayAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_DISPLAY_SUCCESS",
          new_obj: response.data.last_reported,
          folder_id: action.payload.folder_id,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_DISPLAY_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "GET_DISPLAY_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *get_folder_status(action) {
    try {
      const response = yield call(getFolderStatusAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { folderStatus } = response.data;

        yield put({
          type: "GET_FOLDER_STATUS_SUCCESS",
          normalized: normalize(folderStatus, [folder__statusSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_FOLDER_STATUS_FAILURE" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "GET_FOLDER_STATUS_FAILURE" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  *pollFolderStatus(action) {
    while (true) {
      try {
        const response = yield call(getFolderStatusAPI, action.payload);
        if (isResponseSuccess(response)) {
          const { folderStatus } = response.data;

          yield put({
            type: "GET_FOLDER_STATUS_SUCCESS",
            normalized: normalize(folderStatus, [folder__statusSchema]),
          });

          if (action.onSuccess) {
            action.onSuccess(folderStatus);
          }

          const folderStatusProcessing = folderStatus.filter(
            (fs) => fs.folder_status === "processing"
          );
          if (folderStatusProcessing.length === 0) {
            yield put({ type: STOP_POLL_FOLDER_STATUS });
          } else {
            yield delay(10000);
          }
        } else {
          yield put({ type: "GET_FOLDER_STATUS_FAILURE" });
          yield put({ type: STOP_POLL_FOLDER_STATUS });
          yield put(addError(response.errors));
          if (action.onFail) {
            action.onFail(response.response.data.data.detail);
          }
        }
      } catch (e) {
        yield put({ type: "GET_FOLDER_STATUS_FAILURE" });
        yield put({ type: STOP_POLL_FOLDER_STATUS });
        yield put(addExceptionError(e));
        if (action.onFail) {
          action.onFail(e.message);
        }
      }
    }
  },

  *pollFolderStatusWatcher() {
    while (true) {
      const action = yield take(START_POLL_FOLDER_STATUS);
      yield race({
        task: call(folderSaga.pollFolderStatus, action),
        cancel: take(STOP_POLL_FOLDER_STATUS),
      });
    }
  },

  *addBulkFolders(action) {
    try {
      const response = yield call(addBulkFoldersAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { folders, folder__folders } = response.data;
        yield all([
          put({
            type: "ADD_CONTENT_FOLDER_SUCCESS",
            normalized: normalize(folders, [folderSchema]),
          }),
          put({
            type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(folder__folders, [folder__folderSchema]),
          }),
          put({
            type: "FETCH_FOLDER_PATHS_SUCCESS",
            normalized: normalize(response.data.folder_paths || [], [
              folderPathSchema,
            ]),
          }),
          put({
            type: "ADD_CONTENT_RESOURCE__FOLDER_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess({ folders: Object.values(folders) });
        }
      } else {
        yield put({ type: "ADD_BULK_FOLDERS_FAILURE" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "CREATE_MANY_FOLDERS_FAILURE" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  *updateBulkFolders(action) {
    try {
      const response = yield call(updateBulkFoldersAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { folders, folder__folders } = response.data;
        yield all([
          put({
            type: "UPDATE_BULK_FOLDERS_SUCCESS",
            normalized: normalize(folders, [folderSchema]),
          }),
          put({
            type: "UPDATE_BULK_FOLDER__FOLDERS_SUCCESS",
            normalized: normalize(folder__folders, [folder__folderSchema]),
          }),
          put({
            type: "FETCH_FOLDER_PATHS_SUCCESS",
            normalized: normalize(response.data.folder_paths, [
              folderPathSchema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_BULK_FOLDERS_FAILURE" });
        yield put(addError(response.errors));
        const err = _.get(
          response,
          "response.data.data.detail",
          "An unknown error ocurred"
        );
        if (action.onFail) {
          action.onFail(err);
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_BULK_FOLDERS_FAILURE" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  *update_folder_contents(action) {
    try {
      const response = yield call(updateFolderContentsAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_FOLDER_SUCCESS",
          normalized: normalize(response.data.folder, folderSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_FOLDER_CONTENTS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_FOLDER_CONTENTS_" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *updateDisplayHardwareInfo(action) {
    try {
      const response = yield call(updateDisplayHardwareInfoAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_DISPLAY_HARDWARE_INFO_SUCCESS",
          normalized: normalize(response.data.folder, folderSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_DISPLAY_HARDWARE_INFO_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_DISPLAY_HARDWARE_INFO_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *set_update_folder_touch(action) {
    try {
      yield put({
        type: "SET_UPDATE_FOLDER_TOUCH_SUCCESS",
        normalized: {
          entities: {
            update_folder_touch: { ...action.payload, updated: new Date() },
          },
        },
      });

      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *set_update_list_view_touch(action) {
    try {
      yield put({
        type: "SET_UPDATE_LIST_VIEW_TOUCH_SUCCESS",
        normalized: {
          entities: {
            update_list_view_touch: { ...action.payload, updated: new Date() },
          },
        },
      });

      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *uriAccessGetData(action) {
    try {
      const response = yield call(uriAccessGetDataAPI, action.payload);
      if (isResponseSuccess(response)) {
        const urlsraw = _.get(response, "data.signed_urls.urls", []);
        // urlsraw.map((url: string) => {console.log(url)})
        const s3Entries = urlsraw.map((url: string) =>
          getJsonFromS3SignedUrl(url)
        );
        yield all([
          put({
            type: "GET_SIGNED_FOLDER_URLS_SUCCESS",
            normalized: normalize(s3Entries, [webview_signedurlsSchema]),
          }),
          put({
            type: "FETCH_COMPANIES_SUCCESS",
            normalized: normalize(response.data.company, companySchema),
          }),
          put({
            type: "FETCH_FOLDERS_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "FETCH_FOLDER__FOLDERS_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__folderSchema,
            ]), // naming: should be "folder__folders" from back-end?
          }),
          put({
            type: "FETCH_FOLDER_TYPES_SUCCESS",
            normalized: {
              entities: { folder_types: response.data.folder_types },
            },
          }),
          put({
            type: "FETCH_RESOURCES_SUCCESS",
            normalized: normalize(response.data.resources, [resourceSchema]),
          }),
          put({
            type: "FETCH_RESOURCE_FOLDERS_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
          put({
            type: "FETCH_URI_ACCESS_INHERITABLE_FIELDS_SUCCESS",
            inheritable_fields: response.data.inheritable_fields,
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *setFolderTreeInheritableSettings(action) {
    try {
      yield put({
        type: "SET_FOLDER_TREE_INHERITABLE_SETTINGS_SUCCESS",
        normalized: normalize(action.payload.settings, [
          resource__folderSchema,
        ]),
      });
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *pushDownInheritableFields(action) {
    try {
      const response = yield call(pushDownInheritableFieldsAPI, action.payload);
      if (isResponseSuccess(response)) {
        const removeKeys = [];
        if (
          response.data.removedResourceFolders &&
          response.data.removedResourceFolders.length > 0
        ) {
          removeKeys.push({
            entities: "resource__folders",
            ids: response.data.removedResourceFolders,
          });
        }

        if (removeKeys.length > 0) {
          yield put({
            type: "REMOVE_KEYS_SUCCESS",
            removeKeys,
          });
        }

        yield all([
          put({
            type: "PUSH_DOWN_INHERITABLE_FIELDS_FOLDERS_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "PUSH_DOWN_INHERITABLE_FIELDS_RESOURCES_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__folderSchema,
            ]),
          }),
          put({
            type: "UPDATE_S3_CACHE_SUCCESS",
            resource__folders: response.data.resource__folders ?? [],
            resources: [],
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "PUSH_DOWN_INHERITABLE_FIELDS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response?.data?.data?.detail);
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *updateFolderAlone(action) {
    if (action.payload.folder) {
      yield put({
        type: "UPDATE_FOLDER_SUCCESS",
        normalized: normalize(action.payload.folder, folderSchema),
      });
    }
  },

  *checkUpdateFolderAlone(
    action: PayloadAction<{
      folder: IFolder;
      applied_content_selected_folder: number;
      updater: any;
    }>
  ) {
    if (action.payload.folder) {
      yield put({
        type: "CHECK_UPDATE_FOLDER_ALONE_SUCCESS",
        folder: action.payload.folder,
        updater: action.payload.updater,
      });
    }
  },

  *upsertFolderArModel(action) {
    try {
      const response = yield call(upsertFolderArModelAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPSERT_FOLDER_AR_MODEL_SUCCESS",
          normalized: normalize(
            response.data.folder_ar_model,
            folder_ar_modelSchema
          ),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  *getFolderArModel(action) {
    try {
      const response = yield call(getFolderArModelApi, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_FOLDER_AR_MODEL_SUCCESS",
          normalized: normalize(
            response.data.folder_ar_model,
            folder_ar_modelSchema
          ),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },
};

export default folderSaga;
