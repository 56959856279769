import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { everettFonts, quillFontSizes, transitions } from "./everett_settings";
import { tree_icon_types } from "../tree_icon_types";
import { wysiwyg } from "../standardized_fields";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const everett_full_screen_image: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORY,
  name: FOLDER_TYPE.everett_full_screen_image,
  tree_icon_types: {
    open: {
      ...tree_icon_types.story
    },
    closed: {
      ...tree_icon_types.story
    }
  },
  display_name: "Full Screen Image",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "Logo / QR Code",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    // General
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    fullscreen_cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    fullscreen_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    fullscreen_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    switch_to_logo: {
      type: "select",
      title: "Logo or QR Code",
      field_group: "qr",
      default: "none",
      options: [
        { title: "None", value: "none" },
        { title: "Logo", value: "logo" },
        { title: "QR Code", value: "qrCode" },
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_switch_to_logo"
      }
    },
    mirror_logo_qr: {
      type: "bool",
      title: "Mirror Logo/QR",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: false,
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_mirror_logo_qr"
      }
    },
    call_to_action_header: {
      title: "Call To Action Header",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
            size: "64px",
            lineHeight: "1.15"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_call_to_action_header"
      }
    },
    call_to_action_body: {
      title: "Call to Action Body",
      field_group: "qr",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
            size: "44px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_call_to_action_body"
      }
    },
    qr_link: {
      type: "text",
      title: "QR Code Link",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      show_char_count: false,
      field_group: "qr",
      validation: {
        rules: [
          //{ name: "isRequiredExclusiveBoolean", otherFieldName: 'switch_to_logo', message: "Please enter a valid link. I.E: https://www.google.com" },
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_qr_link"
      }
    },
    qr_color: {
      type: "color",
      title: "QR Color",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      default: "#ffffff",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "fullscreen_qr_color"
      }
    },
    logo: {
      title: "Default Logo",
      isVisible: (values) => values.switch_to_logo === 'logo',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fullscreen_logo"
      }
    },
    qr_background_color: {
      type: "color",
      title: "QR / Logo Background Color",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: "#0000007F",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "fullscreen_qr_background_color"
      }
    }
  },
  preview: {
    aspect_ratio: 16 / 9,
    component_name: COMPONENT_NAME.Everett2x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "2x2", orientation: "landscape", horizontal: 2, vertical: 2 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/everett/fullscreen.svg"
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false,
  cycle_field_name: "fullscreen_cycle_time"
};
