
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorPicker from "rc-color-picker-corbin";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectSettings } from "../../actions/projectActions";
import { IState } from "../../interfaces/main-state.interface";
import get from 'lodash/get';
import { convertHexToHexWithAlpha, rgbaToHex } from "../../utils/commonUtils";

interface ISwatchesProps {
  values: string[];
  onClick?: (color: string) => void;
  onRemove?: (index: number) => void;
  onAdd?: () => void;
  setSetSwatches?: (cb: any) => void;
}

const Swatches: React.FC<ISwatchesProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [currentValues, _setCurrentValues] = useState([]);
  const currentValuesRef = useRef(currentValues);
  const setCurrentValues = (data) => {
    currentValuesRef.current = data;
    _setCurrentValues(data);
  };

  useEffect(() => {
    if (props.setSetSwatches) {
      props.setSetSwatches((swatches) => {
        setCurrentValues(swatches);
      });
    }

    setCurrentValues(props.values);
  }, []);

  useEffect(() => {
    setCurrentValues(props.values);
  }, [props.values]);

  return (
    <>
      {currentValuesRef.current?.map((v, i) => {
        const borderColor = i === selectedIndex ? "#2699FB" : "#CCC";
        return (
          <div
            key={`swatch-${i}`}
            onClick={() => {
              props.onClick(currentValuesRef.current[i]);
              setSelectedIndex(i);
            }}
            style={{
              cursor: "pointer",
              width: 18,
              height: 18,
              background: v,
              borderRadius: "3px",
              border: `2.5px solid ${borderColor}`,
              marginRight: 5,
              marginBottom: 5,
              float: "left"
            }}
          />
        );
      })}
      <div
        onClick={props.onAdd}
        style={{
          width: 18,
          height: 18,
          float: "left",
          marginRight: 5,
          marginBottom: 5,
          marginTop: -2,
        }}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          className="fa"
          icon={["fas", "plus"]}
        />
      </div>
      {selectedIndex !== null && (
        <div
          onClick={() => {
            props.onRemove(selectedIndex);
            setSelectedIndex(null);
          }}
          style={{
            width: 18,
            height: 18,
            float: "left",
            marginRight: 5,
            marginBottom: 5,
            marginTop: -2,
          }}
        >
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            className="fa"
            icon={["fas", "minus"]}
          />
        </div>
      )}
    </>
  );
};

interface IColorPickerToolProps {
  value: string;
  swatches: string[];
  onChange?: (color: any) => void;
  onOpen?: () => any;
  onClose?: (color: any) => void;
}

const ColorPickerTool: React.FC<IColorPickerToolProps> = (props) => {
  const [currentSwatchColor, setCurrentSwatchColor] = useState(null); // This is used to set the color of the swatch when the user clicks on a swatch.
  const [visibility, setVisibility] = useState('hidden');
  const [currentSwatches, _setCurrentSwatches] = useState(props.swatches);
  const currentSwatchesRef = useRef(currentSwatches);
  const setCurrentSwatches = (data) => {
    currentSwatchesRef.current = data;
    _setCurrentSwatches(data);
  };

  const [currentValue, _setCurrentValue] = useState(null);
  const currentValueRef = useRef(currentValue);
  const setCurrentValue = (data) => {
    currentValueRef.current = data;
    _setCurrentValue(data);
  };

  const [setSwatches, _setSetSwatches] = useState(null); // This function is used as a reversed function for changing the state in the Swtches component.
  const setSwatchesRef = useRef(setSwatches);
  const setSetSwatches = (data) => {
    setSwatchesRef.current = data;
    _setSetSwatches(data);
  };

  const swatchesRef = useRef(null);
  const colorPickerRef = useRef(null);
  const dispatch = useDispatch();
  const clientOptions = useSelector(
    (state: IState) => state.client_options
  );
  const projects = useSelector((state: IState) => state.data.projects);

  const onAddSwatch = () => {
    const newSwatches = [
      ...currentSwatchesRef.current,
      currentValueRef.current
    ];
    setCurrentSwatches(newSwatches);
    setSwatchesRef.current(newSwatches);
  };

  const onRemoveSwatch = (index) => {
    const newSwatches = [...currentSwatchesRef.current];
    newSwatches.splice(index, 1);
    setCurrentSwatches(newSwatches);
    setSwatchesRef.current(newSwatches);
  };

  const updateSwatches = () => {
    const selectedFolderMeta = clientOptions.selected_folder_meta;
    const project = projects[selectedFolderMeta.project];
    dispatch(
      updateProjectSettings(selectedFolderMeta.project, {
        ...project.settings,
        swatches: currentSwatches
      })
    );
  };

  const convertHexWithAlphaToColorObj = (color: string) => {
    if (!color) {
      return null;
    }

    if (color.includes('rgba')) {
      color = rgbaToHex(color);
    }

    const hex = color.slice(0, 7);
  
    let alphaPercentage = 100;
    if (color.length > 7) {
      const alpha = color.slice(-2);
      alphaPercentage = Math.round((parseInt(alpha, 16) / 255 * 100));
    }

    return { color: hex, alpha: alphaPercentage };
  }

  useEffect(() => {
    window.addEventListener("wheel", () => {
      colorPickerRef.current?.close();
    });
    return () => {
      window.removeEventListener("wheel", () => {
        colorPickerRef.current?.close();
      });
    };
  }, []);

  useEffect(() => {
    if (setSwatchesRef.current) {
      setSwatchesRef.current(props.swatches);
    }
    setCurrentSwatches(props.swatches);
  }, [props.swatches]);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const mountSwatches = () => {
    const colorPickerPanel = document.getElementsByClassName(
      "rc-color-picker-panel-inner"
    );
    colorPickerPanel[0].insertAdjacentHTML(
      "beforeend",
      '<div style="display: inline-block; width: 100%; margin: 8px 8px 0px;" id="rc-color-picker-swatches"></div>'
    );
    ReactDOM.render(
      <Swatches
        values={currentSwatches}
        onClick={(color) => {
          setCurrentSwatchColor(color);
          setCurrentValue(color);
          props.onChange({ color });
        }}
        onAdd={onAddSwatch}
        onRemove={onRemoveSwatch}
        setSetSwatches={(f) => {
          setSetSwatches(f);
        }}
      />,
      document.getElementById("rc-color-picker-swatches")
    );
  };

  return (
    <ColorPicker
      ref={colorPickerRef}
      animation="slide-up"
      color={get(convertHexWithAlphaToColorObj(currentValue), 'color', null)}
      alpha={get(convertHexWithAlphaToColorObj(currentValue), 'alpha', 100)}
      swatchColor={get(convertHexWithAlphaToColorObj(currentSwatchColor), 'color', "#FFFFFF")}
      onChange={(c) => {
        setCurrentValue(convertHexToHexWithAlpha(c));
        props.onChange(c);
      }}
      onOpen={() => {
        setTimeout(() => {
          mountSwatches();
          colorPickerRef.current.onChange({
            color: get(convertHexWithAlphaToColorObj(currentValue), 'color', null),
            alpha: get(convertHexWithAlphaToColorObj(currentValue), 'alpha', 100),
          });
          setVisibility('visible')
        }, 100);

        if (props.onOpen) {
          props.onOpen();
        }
      }}
      onClose={(c) => {
        updateSwatches();
        setVisibility('hidden')
        props.onClose(c);
      }}
      style={{ visibility: visibility }}
    >
      {props.children}
    </ColorPicker>
  );
};

export default ColorPickerTool;