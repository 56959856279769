import React, {useCallback} from "react"
import {generateFontSizeClassesFromFields, getFieldValue} from "../../../utils/generalUtils";
import { createUseStyles } from "react-jss";
import MarinQuote from "./MarinQuote";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";
import MediaCarousel from "../../standard/MediaCarousel";

const MarinMediaWithQuote21 = (props) => {

  const getStyles = () => {
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.baseFolder?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, props.baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();
  const backgroundColor = getFieldValue(
    props.baseFolder,
    "background_color",
    props.baseFolderType
  );

  const quoteBackgroundColor = getFieldValue(
    props.baseFolder,
    "quote_background_color",
    props.baseFolderType
  );

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();
  return (
    <div
      style={{
        width: "100%",
        height: props.containerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor,
      }}
    >
      <div className="profBg">

      </div>
      <div
        style={{
          height: "70%",
          width: "100%"
        }}
      >
        <MediaCarousel
          {...props}
          componentSpecificData={{
            customStyleImage: {
              maxHeight: '84em',
              width: "100%",
              backgroundPosition: "bottom center",
              backgroundSize: "contain"
            },
            customStyleVideo: {
              hideOffset: true,
              maxHeight: '40em',
              position: 'relative'
            },
            slickConfiguration: {
              fade: true,
              speed: 2000
            }
          }}
          resourceFoldersCarousel={resourceFoldersCarousel}
          width="100%"
          height="100%"
          slotNames={["image"]}
          dotsStyles={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            marginTop: "2em"
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "30%",
          padding: "6em 8em",
          backgroundColor: quoteBackgroundColor
        }}
      >
        <MarinQuote
          {...props}
          quote={props.baseFolder}
          baseFolderType={props.baseFolderType}
        />
      </div>
    </div>
  )
}

export default MarinMediaWithQuote21
