import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ImageLoader from "../../../utils/ImageLoader";
import { callFunctionWithMediaObjectFromDefaultValue } from "./FileInputService";

interface IMediaFileInputProps {
  defaultValue: any;
  onClick: any;
  updateDefaultValueItem: any;
  onlyShowRemoveOnLoad: boolean;
  gallery: boolean;
  disabled: boolean;
  showVideoIcon: boolean;
  showIcons?: boolean;
  size?: string | number;
  item?: any;
  acceptable?: any;
  data?: any;
  onEdit?: any;
}

export const MEDIA_FILE_INPUT_STATE_FULL = "full";
export const MEDIA_FILE_INPUT_STATE_NONE = "none";
export const MEDIA_FILE_INPUT_STATE_REMOVED = "removed";
export const MEDIA_FILE_INPUT_STATE_CREATED = "created";

// image loaded in memory
export const MEDIA_FILE_INPUT_STATE_LOADED = "loaded";

// image available in s3
export const  MEDIA_FILE_INPUT_STATE_AVAILABLE = "available";
export const MEDIA_FILE_INPUT_STATE_LOADING = "Loading";

const MediaFileInput: React.FunctionComponent<IMediaFileInputProps> = (
  props: IMediaFileInputProps
) => {
  
  const [mediaFile, setMediaFile] = useState({
    status: "none",
    fileName: null,
    originalName: null,
    modifiedName: null,
    thumb: null,
    url: null,
    previewResource: null,
    cropPreview: "",
    isImage: false,
    modalVisible: false,
    file: null,
  });

  

  useEffect(() => {
    const callback = async () => {await callFunctionWithMediaObjectFromDefaultValue(
      (data) => {
        setMediaFile(data);
      },
      props.data,
      mediaFile,
      true,
      !!props.data.isFileInputChange
    )};
    callback();
  }, [props.data]);

  const onClickImage = () => {
    const { onClick, item } = props;
    if (onClick) {
      onClick({ ...mediaFile, rank: item.rank }, item);
    }
  };

  const editInput = () => {
    const { item, onEdit } = props;
    if (onEdit) {
      onEdit(item);
    }
  };

  const { status, thumb } = mediaFile;
  
  const { disabled, showIcons, size, showVideoIcon } = props;
  const hasImage =
    ["available", "loaded"].indexOf(status) > -1 && thumb !== null;
  const extraClass = size ? `preview-gallery_container-${size}` : "";

  return (
    <>
      <div className={`preview-gallery_container ${extraClass}`}>
        <div
          className={
            hasImage && !disabled
              ? "preview-gallery_outer"
              : "preview-gallery_outer preview-gallery_outer-media"
          }
          /*   darkMode ? 'image-cropper_container-dark' : 'image-cropper_container-white' */
        >
          <div className={ props.data.contents && props.data.contents.content_manager_dark_mode ? 'preview-gallery_inner image-cropper_container-dark' : "preview-gallery_inner"}>
            {/* image box*/}
            <div
              className="preview-gallery_image-container"
              onClick={onClickImage}
            >
              {hasImage && !disabled ? (
                <ImageLoader key={thumb} url={thumb} />
              ) : (
                <div className="preview-gallery_spinner">
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={["fas", "spinner"]}
                    spin
                  />
                </div>
              ) }
            </div>

            {showIcons && !disabled ? (
              <>
                <div className="preview-gallery__icons-overlap" />
                <div className="preview-gallery__icons-container">
                  {/* edit icon*/}
                  <div className="preview-gallery_icon preview-gallery_icon-center">
                    <Tooltip title="Edit">
                      <FontAwesomeIcon
                        onClick={editInput}
                        className="fa-1x"
                        icon="edit"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </>
            ) : null}
            {showVideoIcon && !disabled ? (
              <>
                <div className="preview-gallery__icons-video-container">
                  {/* edit icon*/}
                  <div className="preview-gallery_icon preview-gallery__icons-video-center">
                    <FontAwesomeIcon
                      onClick={onClickImage}
                      className="fa-1x"
                      icon={["fas", "play-circle"]}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaFileInput;
