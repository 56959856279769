import base_types from "./base_types";
import { tree_icon_types } from "./tree_icon_types";
import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE, PREVIEW_VIEW_PERCENTAGE_ORIENTATION } from "../../../enums/folder-type-enums";
import { IFolderType } from "../folders/IFolderType";

export const walterville_types: { [key: string]: IFolderType } = {
  walterville_root: {
    ...base_types.root,
    name: FOLDER_TYPE.walterville_root,
    display_name: "walterville Root",
    acceptable_folder_types: [FOLDER_TYPE.walterville_landscape_slides, FOLDER_TYPE.walterville_portrait_slides],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      }
    },
    preview: {
      aspect_ratio: 1.77777777777777777778,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    default_dimensions: {
      x: 0,
      y: 0,
      scale_x: 1080,
      scale_y: 1920
    }
  },
  walterville_landscape_slides: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.walterville_landscape_slides,
    display_name: "Landscape Slides",
    acceptable_folder_types: [FOLDER_TYPE.walterville_landscape_slide],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      },
      transition: {
        type: "select",
        title: "Transition",
        validation: { rules: [{ name: "isRequired" }] },
        default: "crossfade",
        options: [
          {
            title: "Fade",
            value: "transition-fade"
          },
          {
            title: "Fade Out",
            value: "transition-fadeout"
          }
        ]
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      left: -60,
      zoomable: true,
      view_percentage: {
        orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
        value: 30
      },
      aspect_ratio: 1.77777777777777777778,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.slideshow
      },
      closed: {
        ...tree_icon_types.slideshow
      }
    }
  },
  walterville_landscape_slide: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.walterville_landscape_slide,
    display_name: "Landscape Slide",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time (seconds)",
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
      },
      file: {
        title: "Image / Video",
        type: "file",
        slot_type: "fixed",
        acceptable_contents: ["image/*", "video/*"],
        video_presets: [1080],
        limit: null,
        slots: [
          {
            name: "File",
            aspect: 1.77777777777777777778
          }
        ],
        default: null
      }
    },
    preview: {
      aspect_ratio: 1.77777777777777777778,
      component_name: COMPONENT_NAME.Slide1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.Slide1911
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.slide
      },
      closed: {
        ...tree_icon_types.slide
      }
    }
  },
  walterville_portrait_slides: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.walterville_portrait_slides,
    display_name: "Portrait Slides",
    acceptable_folder_types: [FOLDER_TYPE.walterville_portrait_slide],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      },
      transition: {
        type: "select",
        title: "Transition",
        validation: { rules: [{ name: "isRequired" }] },
        default: "fade",
        options: [
          {
            title: "Fade",
            value: "transition-fade"
          },
          {
            title: "Fade Out",
            value: "transition-fadeout"
          }
        ]
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      left: -60,
      zoomable: true,
      view_percentage: {
        orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
        value: 24
      },
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.slideshow
      },
      closed: {
        ...tree_icon_types.slideshow
      }
    }
  },
  walterville_portrait_slide: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.walterville_portrait_slide,
    display_name: "Portrait Slide",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time (seconds)",
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
      },
      file: {
        title: "Image / Video",
        type: "file",
        slot_type: "fixed",
        acceptable_contents: ["image/*", "video/*"],
        video_presets: [1080],
        limit: null,
        slots: [
          {
            name: "File",
            aspect: 0.5625
          }
        ],
        default: null
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.Slide1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.Slide1911
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.slide
      },
      closed: {
        ...tree_icon_types.slide
      }
    }
  }
};
