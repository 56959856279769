import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const marin_main_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.marin_main_display,
  display_name: "Main Display",
  acceptable_folder_types: [FOLDER_TYPE.marin_presentation_5x1],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  children_conditions: [{ type: "slot_limit", value: 6 }],
  prevent_add_children: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    },
    main_presentation: {
      type: "select",
      title: "Main Presentation",
      validation: { rules: [{ name: "isRequired" }] },
      settings: { options: "children" } // Get the option values in the dropdown from children of this folder
    }
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  }
};
