import React, { Component } from "react";
import { connect } from 'react-redux';
import CompanyBreadCrumbs from "./CompanyBreadCrumbs";
import DynamicForm from "../form/DynamicForm";
import { getCompany } from "../../actions/companyActions";
import formConfig from '../../config/company/company_location_fields';
import AlertNotification from '../layout/AlertNotification';
import { add_location, update_location } from '../../actions/locationActions';
import _ from 'lodash';
import { BackLink } from "../utils/BackLink";
import { withRouter } from "../../hooks/withRouter";

class CompanyLocationForm extends Component {
  constructor(props) {
    super(props);
    let new_record = false;
    if (!this.props.params.locationId) {
      new_record = true
    }
    this._company_id = this.props.params.companyId;
    this.state = {
      new_record: new_record,
      submitting: false
    }
    if (!this.props.locations) {
      this.props.get_company(this.props.params.companyId);
    }
  }

  onError = (e) => {
    this.setState({ submitting: false })
  }

  onUpdate = () => {
    this.setState({ submitting: false });
    AlertNotification('success', 'Success', 'Location updated successfully')
    this.props.navigate('/company/' + this._company_id);
  }

  onInsert = () => {
    this.setState({ submitting: false });
    AlertNotification('success', 'Success', 'Location created successfully')
    this.props.navigate('/company/' + this._company_id);
  }

  onErrorInsert = (message) => {
    this.setState({ submitting: false });
  }

  onErrorUpdate = (message) => {
    this.setState({ submitting: false });
  }

  onSubmit = (values) => {
    this.setState({
      submitting: true
    })
    if (this.state.new_record) {
      this.props.add_location(this._company_id, values.name, values.shows_in_tree, this.onInsert, this.onErrorInsert);
    } else {
      this.props.update_location(Number(this.props.params.locationId), values.name, values.shows_in_tree, this.onUpdate, this.onErrorUpdate);
    }
  }

  render() {
    if (!this.props.locations) {
      return (<p>Loading...</p>);
    } else {
      let fieldValues, formTitle, mode;
      if (this.state.new_record) {
        // Creating a new record
        fieldValues = {
          name: '',
          shows_in_tree: true // Default to true
        }
        formTitle = 'New location';
        mode = 'add';
      } else {
        // Updating a previous one
        fieldValues = {
          name: this.props.selected_location.name,
          shows_in_tree: this.props.selected_location.shows_in_tree
        };
        formTitle = 'Edit location';
        mode = 'edit';
      }
      return (
        <div>
          <CompanyBreadCrumbs crumbs={this.props} />
          <div>
            <BackLink />
          </div>
          <h1>{formTitle}</h1>
          <DynamicForm
            fieldGroupsConfig={formConfig.field_groups}
            fieldsConfig={formConfig.fields}
            onError={this.onError}
            onSubmit={this.onSubmit}
            fieldValues={fieldValues}
            mode={mode}
            name={formTitle}
            enableFieldGrouping={false}
            submitting={this.state.submitting}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let company = _.get(state.data, 'companies[' + ownProps.params.companyId + ']', null);
  const location = _.get(state.data, 'locations[' + ownProps.params.locationId + ']', null);
  return {
    company: company,
    locations: state.data.locations,
    selected_location: location
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  get_company: (companyId, onSuccess, onFail) => {
    dispatch(getCompany(companyId, onSuccess, onFail));
  },
  add_location: (companyId, name, shows_in_tree, onSuccess, onFail) => {
    dispatch(add_location(companyId, name, shows_in_tree, onSuccess, onFail));
  },
  update_location: (location_id, name, shows_in_tree, onSuccess, onFail) => {
    dispatch(update_location(location_id, name, shows_in_tree, onSuccess, onFail));
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyLocationForm));
