export enum FOLDER_TYPE {
  group = "group",
  donor_list = "donor_list",
  donor = "donor",
  stories = "stories",
  story = "story",
  timeline = "timeline",
  event = "event",
  resource_default = "resource_default",
  resource_root = "resource_root",
  resource = "resource",
  brighton_root = "brighton_root",
  brighton_slides = "brighton_slides",
  brighton_slide = "brighton_slide",
  fremont_1x2 = "fremont_1x2",
  fremont_1x2_slide = "fremont_1x2_slide",
  fremont_1x2_slideshow = "fremont_1x2_slideshow",
  fremont_1x4 = "fremont_1x4",
  fremont_1x4_slide = "fremont_1x4_slide",
  fremont_1x4_slideshow = "fremont_1x4_slideshow",
  fremont_attract_loop = "fremont_attract_loop",
  fremont_donor_001 = "fremont_donor_001",
  fremont_donor_list_001 = "fremont_donor_list_001",
  fremont_donor_list_002 = "fremont_donor_list_002",
  fremont_donor_list_category = "fremont_donor_list_category",
  fremont_donor_lists = "fremont_donor_lists",
  fremont_media_template_001 = "fremont_media_template_001",
  fremont_media_template_002 = "fremont_media_template_002",
  fremont_root = "fremont_root",
  fremont_template_001 = "fremont_template_001",
  fremont_template_002 = "fremont_template_002",
  fremont_template_003 = "fremont_template_003",
  fremont_template_004 = "fremont_template_004",
  fremont_template_005 = "fremont_template_005",
  fremont_templates = "fremont_templates",
  jasper_root = "jasper_root",
  jasper_stories = "jasper_stories",
  jasper_template_001 = "jasper_template_001",
  jasper_slides = "jasper_slides",
  jasper_slide = "jasper_slide",
  los_angeles_root = "los_angeles_root",
  los_angeles_donor_lists = "los_angeles_donor_lists",
  los_angeles_donor_list = "los_angeles_donor_list",
  los_angeles_three_panel = "los_angeles_three_panel",
  los_angeles_slides = "los_angeles_slides",
  los_angeles_slide = "los_angeles_slide",
  los_angeles_donor = "los_angeles_donor",
  los_angeles_donor_list_giving_level = "los_angeles_donor_list_giving_level",
  memphis_donor = "memphis_donor",
  memphis_donor_list = "memphis_donor_list",
  memphis_root = "memphis_root",
  memphis_slide = "memphis_slide",
  memphis_slides = "memphis_slides",
  nashville_root = "nashville_root",
  nashville_grouping_folder = "nashville_grouping_folder",
  nashville_donor = "nashville_donor",
  project = "project",
  toledo_root = "toledo_root",
  toledo_grouping_folder = "toledo_grouping_folder",
  toledo_donor = "toledo_donor",
  toledo_stories = "toledo_stories",
  toledo_story = "toledo_story",
  toledo_donor_list = "toledo_donor_list",
  walterville_root = "walterville_root",
  walterville_landscape_slides = "walterville_landscape_slides",
  walterville_landscape_slide = "walterville_landscape_slide",
  walterville_portrait_slides = "walterville_portrait_slides",
  walterville_portrait_slide = "walterville_portrait_slide",
  springfield_display = "springfield_display",
  springfield_content = "springfield_content",
  springfield_presentations = "springfield_presentations",
  springfield_content_group_folder = "springfield_content_group_folder",
  springfield_displays = "springfield_displays",
  springfield_presentation = "springfield_presentation",
  springfield_root = "springfield_root",
  springfield_template_001 = "springfield_template_001",
  springfield_template_002 = "springfield_template_002",
  springfield_template_003 = "springfield_template_003",
  springfield_template_004 = "springfield_template_004",
  springfield_donor_lists = "springfield_donor_lists",
  springfield_category_with_giving_level = "springfield_category_with_giving_level",
  springfield_donor_list = "springfield_donor_list",
  springfield_donor = "springfield_donor",
  springfield_stories = "springfield_stories",
  springfield_portrait_display = "springfield_portrait_display",
  springfield_portrait_attract_loop = "springfield_portrait_attract_loop",
  springfield_portrait_root = "springfield_portrait_root",
  springfield_portrait_template_001 = "springfield_portrait_template_001",
  springfield_portrait_template_002 = "springfield_portrait_template_002",
  springfield_portrait_template_003 = "springfield_portrait_template_003",
  springfield_portrait_template_004 = "springfield_portrait_template_004",
  springfield_portrait_donor_lists = "springfield_portrait_donor_lists",
  springfield_portrait_category_with_giving_level = "springfield_portrait_category_with_giving_level",
  springfield_portrait_donor_list = "springfield_portrait_donor_list",
  springfield_portrait_donor = "springfield_portrait_donor",
  springfield_portrait_stories = "springfield_portrait_stories",
  springfield_portrait_content = "springfield_portrait_content",
  springfield_portrait_content_group_folder = "springfield_portrait_content_group_folder",
  springfield_portrait_presentations = "springfield_portrait_presentations",
  springfield_portrait_displays = "springfield_portrait_displays",
  springfield_portrait_presentation = "springfield_portrait_presentation",
  new_orleans_attract_loop = "new_orleans_attract_loop",
  new_orleans_stories = "new_orleans_stories",
  new_orleans_root = "new_orleans_root",
  new_orleans_template_001 = "new_orleans_template_001",
  new_orleans_template_002 = "new_orleans_template_002",
  new_orleans_template_003 = "new_orleans_template_003",
  new_orleans_1x2 = "new_orleans_1x2",
  new_orleans_1x2_slide = "new_orleans_1x2_slide",
  new_orleans_donor = "new_orleans_donor",
  new_orleans_donor_list = "new_orleans_donor_list",
  new_orleans_donor_lists = "new_orleans_donor_lists",
  new_orleans_donor_list_category = "new_orleans_donor_list_category",
  new_orleans_1x2_slideshow = "new_orleans_1x2_slideshow",

  boston_displays = "boston_displays",
  boston_display = "boston_display",
  boston_presentations = "boston_presentations",
  boston_presentation_3x2 = "boston_presentation_3x2",
  boston_presentation_2x5_and_3x2 = "boston_presentation_2x5_and_3x2",
  boston_presentation_1x2_and_1x5_and_3x2 = "boston_presentation_1x2_and_1x5_and_3x2",
  boston_presentation_3x2_and_1x4 = "boston_presentation_3x2_and_1x4",
  boston_presentation_mobile = "boston_presentation_mobile",
  boston_content = "boston_content",
  boston_root = "boston_root",
  boston_donor = "boston_donor",
  boston_donor_sea = "boston_donor_sea",
  boston_checkerboard = "boston_checkerboard",
  boston_media_with_quote = "boston_media_with_quote",
  boston_fullscreen_media = "boston_fullscreen_media",
  boston_diamond_platinum_list = "boston_diamond_platinum_list",
  boston_donor_list = "boston_donor_list",
  boston_donor_sea_backgrounds = "boston_donor_sea_backgrounds",
  boston_donor_sea_background = "boston_donor_sea_background",
  boston_donor_sea_category = "boston_donor_sea_category",
  boston_donor_sea_categories = "boston_donor_sea_categories",
  boston_mobile = "boston_mobile",

  boston_content_media_with_quote = "boston_content_media_with_quote",
  boston_content_checkerboards = "boston_content_checkerboards",
  boston_content_fullscreen_media = "boston_content_fullscreen_media",
  boston_content_donor_seas = "boston_content_donor_seas",
  boston_content_donor_lists = "boston_content_donor_lists",
  boston_content_diamond_platinum_lists = "boston_content_diamond_platinum_lists",
  boston_content_mobile = "boston_content_mobile",

  naples_root = "naples_root",
  naples_display = "naples_display",
  naples_displays = "naples_displays",
  naples_presentations = "naples_presentations",
  naples_presentation_1x3 = "naples_presentation_1x3",
  naples_fullscreen_media = "naples_fullscreen_media",
  naples_content = "naples_content",
  naples_content_stories = "naples_content_stories",
  naples_content_donor_lists = "naples_content_donor_lists",
  naples_fund_giving_level = "naples_fund_giving_level",
  naples_fund_folder_red = "naples_fund_folder_red",
  naples_fund_folder_blue = "naples_fund_folder_blue",
  naples_template_red_001 = "naples_template_red_001",
  naples_template_blue_001 = "naples_template_blue_001",
  naples_template_red_002 = "naples_template_red_002",
  naples_template_blue_002 = "naples_template_blue_002",
  naples_donor = "naples_donor",

  eugene_root = "eugene_root",
  eugene_menu = "eugene_menu",
  eugene_attract_loop = "eugene_attract_loop",
  eugene_slide = "eugene_slide",
  eugene_stories = "eugene_stories",
  eugene_stories_menu = "eugene_stories_menu",
  eugene_template_001 = "eugene_template_001",
  eugene_template_002 = "eugene_template_002",
  eugene_template_003 = "eugene_template_003",
  eugene_template_004 = "eugene_template_004",
  eugene_template_005 = "eugene_template_005",
  eugene_category_with_giving_level = "eugene_category_with_giving_level",
  eugene_category_without_giving_level = "eugene_category_without_giving_level",
  eugene_donor = "eugene_donor",
  eugene_search = "eugene_search",
  eugene_donor_list = "eugene_donor_list",
  eugene_donor_lists = "eugene_donor_lists",
  eugene_donor_list_menu = "eugene_donor_list_menu",
  eugene_display = "eugene_display",
  eugene_displays = "eugene_displays",
  eugene_presentations = "eugene_presentations",
  eugene_presentation = "eugene_presentation",
  eugene_content = "eugene_content",
  coburg_root = "coburg_root",
  coburg_menu = "coburg_menu",
  coburg_attract_loop = "coburg_attract_loop",
  coburg_slide = "coburg_slide",
  coburg_stories = "coburg_stories",
  coburg_story_group = "coburg_story_group",
  coburg_stories_overview = "coburg_stories_overview",
  coburg_template_001 = "coburg_template_001",
  coburg_template_002 = "coburg_template_002",
  coburg_template_003 = "coburg_template_003",
  coburg_template_004 = "coburg_template_004",
  coburg_template_005 = "coburg_template_005",
  coburg_category_with_giving_level = "coburg_category_with_giving_level",
  coburg_category_without_giving_level = "coburg_category_without_giving_level",
  coburg_donor = "coburg_donor",
  coburg_search = "coburg_search",
  coburg_donor_list = "coburg_donor_list",
  coburg_donor_list_overview = "coburg_donor_list_overview",
  coburg_donor_list_group = "coburg_donor_list_group",
  coburg_display = "coburg_display",
  coburg_displays = "coburg_displays",
  coburg_presentations = "coburg_presentations",
  coburg_grouping_folder = "coburg_grouping_folder",
  coburg_presentation = "coburg_presentation",
  coburg_content = "coburg_content",
  coburg_grouped_presentations = "coburg_grouped_presentations",

  mckenzie_display = "mckenzie_display",
  mckenzie_displays = "mckenzie_displays",
  mckenzie_content = "mckenzie_content",
  mckenzie_content_mobile = "mckenzie_content_mobile",
  mckenzie_mobile = "mckenzie_mobile",
  mckenzie_root = "mckenzie_root",

  everett_root = "everett_root",
  everett_display = "everett_display",
  everett_displays = "everett_displays",
  everett_presentations = "everett_presentations",
  everett_presentation_2x2 = "everett_presentation_2x2",
  everett_content = "everett_content",
  everett_content_stories = "everett_content_stories",
  everett_story_with_quote = "everett_story_with_quote",
  everett_story = "everett_story",
  everett_content_donor_lists = "everett_content_donor_lists",
  everett_donor = "everett_donor",
  everett_donor_list_with_quote = "everett_donor_list_with_quote",
  everett_donor_list = "everett_donor_list",
  everett_donor_list_group = "everett_donor_list_group",
  everett_content_donor_seas = "everett_content_donor_seas",
  everett_donor_sea = "everett_donor_sea",
  everett_donor_sea_background = "everett_donor_sea_background",
  everett_donor_sea_backgrounds = "everett_donor_sea_backgrounds",
  everett_donor_sea_lists = "everett_donor_sea_lists",
  everett_donor_sea_list = "everett_donor_sea_list",
  everett_full_screen_image_with_caption = "everett_full_screen_image_with_caption",
  everett_full_screen_image = "everett_full_screen_image",

  monterrey_root = "monterrey_root",
  monterrey_display = "monterrey_display",
  monterrey_control_panel_display = "monterrey_control_panel_display",
  monterrey_main_display = "monterrey_main_display",
  monterrey_displays = "monterrey_displays",
  monterrey_presentations = "monterrey_presentations",
  monterrey_presentation_5x1 = "monterrey_presentation_5x1",
  monterrey_presentation_1x1 = "monterrey_presentation_1x1",
  monterrey_content = "monterrey_content",
  monterrey_content_stories = "monterrey_content_stories",
  monterrey_content_donor_lists = "monterrey_content_donor_lists",
  monterrey_donor_list = "monterrey_donor_list",
  monterrey_content_layouts = "monterrey_content_layouts",
  monterrey_layout = "monterrey_layout",
  monterrey_donor = "monterrey_donor",
  monterrey_donor_list_group = "monterrey_donor_list_group",
  monterrey_story_media_with_quote = "monterrey_story_media_with_quote",
  monterrey_story_with_fact = "monterrey_story_with_fact",
  monterrey_fullscreen_fact_story = "monterrey_fullscreen_fact_story",
  monterrey_profile_with_media_story = "monterrey_profile_with_media_story",
  monterrey_story_with_media = "monterrey_story_with_media",
  monterrey_story_fact = "monterrey_story_fact",
  monterrey_story_quote = "monterrey_story_quote",
  monterrey_intro_story = "monterrey_intro_story",
  monterrey_content_attract_loops = "monterrey_content_attract_loops",
  monterrey_control_panel_attract_loop = "monterrey_control_panel_attract_loop",
  monterrey_control_panel_attract_loop_slide = "monterrey_control_panel_attract_loop_slide",
  monterrey_media_blank_1 = "monterrey_media_blank_1",
  monterrey_media_blank_2 = "monterrey_media_blank_2",
  monterrey_media_blank_3 = "monterrey_media_blank_3",
  monterrey_media_blank_4 = "monterrey_media_blank_4",
  monterrey_media_blank_5 = "monterrey_media_blank_5",

  marin_root = "marin_root",
  marin_display = "marin_display",
  marin_control_panel_display = "marin_control_panel_display",
  marin_main_display = "marin_main_display",
  marin_displays = "marin_displays",
  marin_presentations = "marin_presentations",
  marin_presentation_5x1 = "marin_presentation_5x1",
  marin_presentation_1x1 = "marin_presentation_1x1",
  marin_content = "marin_content",
  marin_content_stories = "marin_content_stories",
  marin_content_donor_lists = "marin_content_donor_lists",
  marin_donor_list = "marin_donor_list",
  marin_content_layouts = "marin_content_layouts",
  marin_layout = "marin_layout",
  marin_donor = "marin_donor",
  marin_donor_list_group = "marin_donor_list_group",
  marin_story_media_with_quote = "marin_story_media_with_quote",
  marin_story_media_with_quote_2_1 = "marin_story_media_with_quote_2_1",
  marin_story_large_media_with_quote = "marin_story_large_media_with_quote",
  marin_story_with_fact = "marin_story_with_fact",
  marin_fullscreen_fact_story = "marin_fullscreen_fact_story",
  marin_profile_with_media_story = "marin_profile_with_media_story",
  marin_story_with_media = "marin_story_with_media",
  marin_story_fact = "marin_story_fact",
  marin_story_quote = "marin_story_quote",
  marin_intro_story = "marin_intro_story",
  marin_content_attract_loops = "marin_content_attract_loops",
  marin_control_panel_attract_loop = "marin_control_panel_attract_loop",
  marin_control_panel_attract_loop_slide = "marin_control_panel_attract_loop_slide",
  marin_media_blank_1 = "marin_media_blank_1",
  marin_media_blank_2 = "marin_media_blank_2",
  marin_media_blank_3 = "marin_media_blank_3",
  marin_media_blank_4 = "marin_media_blank_4",
  marin_media_blank_5 = "marin_media_blank_5",
  marin_media_blank_6 = "marin_media_blank_6",
  marin_media_blank_7 = "marin_media_blank_7",
  marin_media_blank_8 = "marin_media_blank_8",

  phoenix_root = "phoenix_root",
  phoenix_content = "phoenix_content",
  phoenix_presentations = "phoenix_presentations",
  phoenix_displays = "phoenix_displays",
  phoenix_story = "phoenix_story",
  phoenix_content_stories = "phoenix_content_stories",
  phoenix_message = "phoenix_message",
  phoenix_donor_list = "phoenix_donor_list",
  phoenix_donor_list_group = "phoenix_donor_list_group",
  phoenix_content_donor_lists = "phoenix_content_donor_lists",
  phoenix_donor_sea = "phoenix_donor_sea",
  phoenix_donor_sea_category = "phoenix_donor_sea_category",
  phoenix_donor_sea_categories = "phoenix_donor_sea_categories",
  phoenix_donor_sea_backgrounds = "phoenix_donor_sea_backgrounds",
  phoenix_donor_sea_background = "phoenix_donor_sea_background",
  phoenix_donor = "phoenix_donor",
  phoenix_content_checkerboards = "phoenix_content_checkerboards",
  phoenix_checkerboard = "phoenix_checkerboard",
  phoenix_fullscreen = "phoenix_fullscreen",
  phoenix_presentation_3x3 = "phoenix_presentation_3x3",
  phoenix_display = "phoenix_display",
  phoenix_content_donor_seas = "phoenix_content_donor_seas",

  berkeley_root = "berkeley_root",
  berkeley_content = "berkeley_content",
  berkeley_presentations = "berkeley_presentations",
  berkeley_displays = "berkeley_displays",
  berkeley_content_stories = "berkeley_content_stories",
  berkeley_donor_list = "berkeley_donor_list",
  berkeley_donor_list_group = "berkeley_donor_list_group",
  berkeley_content_donor_lists = "berkeley_content_donor_lists",
  berkeley_donor_sea = "berkeley_donor_sea",
  berkeley_donor_sea_category = "berkeley_donor_sea_category",
  berkeley_donor_sea_categories = "berkeley_donor_sea_categories",
  berkeley_donor_sea_backgrounds = "berkeley_donor_sea_backgrounds",
  berkeley_donor_sea_background = "berkeley_donor_sea_background",
  berkeley_donor = "berkeley_donor",
  berkeley_template_001 = "berkeley_template_001",
  berkeley_template_002 = "berkeley_template_002",
  berkeley_template_003 = "berkeley_template_003",
  berkeley_template_004 = "berkeley_template_004",
  berkeley_template_005 = "berkeley_template_005",
  berkeley_presentation_3x2 = "berkeley_presentation_3x2",
  berkeley_display = "berkeley_display",
  berkeley_content_donor_seas = "berkeley_content_donor_seas",

  glenwood_root = "glenwood_root",
  glenwood_menu = "glenwood_menu",
  glenwood_attract_loop = "glenwood_attract_loop",
  glenwood_slide = "glenwood_slide",
  glenwood_stories = "glenwood_stories",
  glenwood_story_group = "glenwood_story_group",
  glenwood_stories_overview = "glenwood_stories_overview",
  glenwood_template_001 = "glenwood_template_001",
  glenwood_template_002 = "glenwood_template_002",
  glenwood_template_003 = "glenwood_template_003",
  glenwood_template_004 = "glenwood_template_004",
  glenwood_template_005 = "glenwood_template_005",
  glenwood_category_with_giving_level = "glenwood_category_with_giving_level",
  glenwood_category_without_giving_level = "glenwood_category_without_giving_level",
  glenwood_donor = "glenwood_donor",
  glenwood_search = "glenwood_search",
  glenwood_donor_list = "glenwood_donor_list",
  glenwood_donor_list_overview = "glenwood_donor_list_overview",
  glenwood_donor_list_group = "glenwood_donor_list_group",
  glenwood_display = "glenwood_display",
  glenwood_displays = "glenwood_displays",
  glenwood_presentations = "glenwood_presentations",
  glenwood_grouping_folder = "glenwood_grouping_folder",
  glenwood_presentation = "glenwood_presentation",
  glenwood_content = "glenwood_content",
  glenwood_grouped_presentations = "glenwood_grouped_presentations",
  glenwood_content_donor_seas = "glenwood_content_donor_seas",
  glenwood_donor_sea = "glenwood_donor_sea",
  glenwood_donor_sea_category = "glenwood_donor_sea_category",
  glenwood_donor_sea_categories = "glenwood_donor_sea_categories",
  glenwood_donor_sea_backgrounds = "glenwood_donor_sea_backgrounds",
  glenwood_donor_sea_background = "glenwood_donor_sea_background",
  glenwood_baby_alerts = "glenwood_baby_alerts",
  glenwood_baby_alert = "glenwood_baby_alert",

  alpine_root = "alpine_root",
  alpine_content = "alpine_content",
  alpine_displays = "alpine_displays",
  alpine_display = "alpine_display",
  alpine_presentations = "alpine_presentations",
  alpine_presentation_1x1 = "alpine_presentation_1x1",
  alpine_content_donor_lists = "alpine_content_donor_lists",
  alpine_donor_list = "alpine_donor_list",
  alpine_donor = "alpine_donor",
  alpine_donor_list_group = "alpine_donor_list_group",
  alpine_content_stories = "alpine_content_stories",
  alpine_profile_with_media_story = "alpine_profile_with_media_story",
  alpine_profile_with_media = "alpine_profile_with_media",
  alpine_story_fact = "alpine_story_fact",
  alpine_story_with_fact = "alpine_story_with_fact",
  alpine_story_with_single_image = "alpine_story_with_single_image",
  alpine_media_with_quote = "alpine_media_with_quote",
  alpine_media_with_quote_2 = "alpine_media_with_quote_2",
  alpine_fullscreen_fact = "alpine_fullscreen_fact",
  alpine_one_screen_media = "alpine_one_screen_media",
  alpine_story_with_media = "alpine_story_with_media",
  alpine_story_without_media = "alpine_story_without_media",

  tangent_root = "tangent_root",
  tangent_content = "tangent_content",
  tangent_displays = "tangent_displays",
  tangent_display = "tangent_display",
  tangent_presentations = "tangent_presentations",
  tangent_presentation_1x1 = "tangent_presentation_1x1",
  tangent_content_donor_lists = "tangent_content_donor_lists",
  tangent_donor_list = "tangent_donor_list",
  tangent_donor = "tangent_donor",
  tangent_donor_list_group = "tangent_donor_list_group",
  tangent_content_stories = "tangent_content_stories",
  tangent_story_with_single_image = "tangent_story_with_single_image",
  tangent_media_with_quote = "tangent_media_with_quote",
  tangent_fullscreen_media = "tangent_fullscreen_media",
  tangent_story_with_media = "tangent_story_with_media",
}

export enum FOLDER_TYPE_SELECTOR {
  select = "select",
  images = "images",
}

export enum FIELD_NAME {
  name = "name",
  donor_name_justification = "donor_name_justification",
  donor_name_font_size = "donor_name_font_size",
  sorting_options = "sorting_options",
  file = "file",
}

export enum FIELD_TYPE {
  hybrid_text_input = "hybrid_text_input",
  text = "text",
  select = "select",
  integer = "integer",
  color = "color",
  textstyle = "textstyle",
  wysiwyg = "wysiwyg",
}

export enum DROPPED_INTO_ACTION {
  MOVE = "move",
  INSTANCE = "instance",
}

export enum PREVIEW_TYPE {
  COMPONENT = "component",
  NONE = "none",
  IMAGE = "image",
}

export enum COMPONENT_NAME {
  // Standard Components
  ComponentLoop1911 = "ComponentLoop1911",
  Slide1911 = "Slide1911",
  DonorList1911 = "DonorList1911",
  DonorSea = "DonorSea",
  Alert = "Alert",
  None = "None",
  MobileTemplate = "MobileTemplate",
  MobTemplate = "MobTemplate",
  // Theme components
  FremontOverlaySlide = "FremontOverlaySlide",
  Fremont1x2Container2002 = "Fremont1x2Container2002",
  Donor2103 = "Donor2103",
  ScrollableMenu2103 = "ScrollableMenu2103",
  FremontTemplate001 = "FremontTemplate001",
  FremontTemplate002 = "FremontTemplate002",
  FremontTemplate003 = "FremontTemplate003",
  FremontTemplate004 = "FremontTemplate004",
  FremontTemplate005 = "FremontTemplate005",
  FremontMediaTemplate001 = "FremontMediaTemplate001",
  FremontMediaTemplate002 = "FremontMediaTemplate002",
  FreemontDonorList001 = "FreemontDonorList001",
  FreemontDonor001 = "FreemontDonor001",
  LosAngelesThreePanel = "LosAngelesThreePanel",
  LosAnglesDonorLists = "LosAngelesDonorLists",
  LosAnglesSlide = "LosAngelesSlide",

  SpringfieldRoot = "SpringfieldRoot",
  SpringfieldContainer = "SpringfieldContainer",
  SpringfieldTemplate001 = "SpringFieldTemplate001",
  SpringfieldTemplate002 = "SpringFieldTemplate002",
  SpringfieldTemplate003 = "SpringFieldTemplate003",
  SpringfieldTemplate004 = "SpringFieldTemplate004",
  SpringfieldDonorLists = "SpringfieldDonorLists",
  SpringfieldDonorList = "SpringfieldDonorList",
  SpringfieldRootPreview = "SpringfieldRootPreview",
  SpringfieldDonor = "SpringfieldDonor",

  SpringfieldPortraitContainer = "SpringfieldPortraitContainer",
  SpringfieldPortraitTemplate001 = "SpringFieldPortraitTemplate001",
  SpringfieldPortraitTemplate002 = "SpringFieldPortraitTemplate002",
  SpringfieldPortraitTemplate003 = "SpringFieldPortraitTemplate003",
  SpringfieldPortraitTemplate004 = "SpringFieldPortraitTemplate004",
  SpringfieldPortraitDonorLists = "SpringfieldPortraitDonorLists",
  SpringfieldPortraitDonorList = "SpringfieldPortraitDonorList",
  SpringfieldPortraitRootPreview = "SpringfieldPortraitRootPreview",
  SpringfieldPortraitDonor = "SpringfieldPortraitDonor",

  NewOrleansTemplate001 = "NewOrleansTemplate001",
  NewOrleansTemplate002 = "NewOrleansTemplate002",
  NewOrleansTemplate003 = "NewOrleansTemplate003",
  NewOrleansDonor = "NewOrleansDonor",
  NewOrleansDonorList = "NewOrleansDonorList",
  NewOrleans1x2Container = "NewOrleans1x2Container",

  BostonDonor = "BostonDonor",
  BostonDiamondPlatinumListDonor = "BostonDiamondPlatinumListDonor",
  BostonDonorSeaDonor = "BostonDonorSeaDonor",
  BostonRoot = "BostonRoot",
  BostonDonorSea = "BostonDonorSea",
  Boston3x2and1x4Container = "Boston3x2and1x4Container",
  Boston2x5and3x2Container = "Boston2x5and3x2Container",
  Boston1x2and1x5and3x2Container = "Boston1x2and1x5and3x2Container",
  Boston3x2Container = "Boston3x2Container",

  Boston3x2HorizontalContainer = "Boston3x2HorizontalContainer",
  BostonCheckerboard = "BostonCheckerboard",
  BostonCheckerboards = "BostonCheckerboards",
  BostonContentMediaWithQuote = "BostonContentMediaWithQuote",
  BostonContentFullscreenMedia = "BostonContentFullscreenMedia",

  Naples1x3Container = "Naples1x3Container",
  NaplesTemplateRed001 = "NaplesTemplateRed001",
  NaplesTemplateBlue001 = "NaplesTemplateBlue001",

  EugeneContainer = "EugeneContainer",
  EugeneMenu = "EugeneMenu",
  EugeneStories = "EugeneStories",
  EugeneStoriesMenu = "EugeneStoriesMenu",
  EugeneStoriesMenuGallery = "EugeneStoriesMenuGallery",
  EugeneStoriesWrapper = "EugeneStoriesWrapper",
  EugeneTemplate001 = "EugeneTemplate001",
  EugeneTemplate002 = "EugeneTemplate002",
  EugeneTemplate003 = "EugeneTemplate003",
  EugeneTemplate004 = "EugeneTemplate004",
  EugeneTemplate005 = "EugeneTemplate005",
  EugeneDonorLists = "EugeneDonorLists",
  EugeneDonorList = "EugeneDonorList",
  EugeneSlide = "EugeneSlide",
  EugeneSearch = "EugeneSearch",
  EugeneDonorListCategory = "EugeneDonorListCategory",
  EugeneDonorListMenu = "EugeneDonorListMenu",
  EugeneDonorListCategoryWrapper = "EugeneDonorListCategoryWrapper",
  EugeneRootPreview = "EugeneRootPreview",
  EugeneTemplateCardView = "EugeneTemplateCardView",
  CoburgContainer = "CoburgContainer",
  CoburgMenu = "CoburgMenu",
  CoburgRootPreview = "CoburgRootPreview",
  CoburgStories = "CoburgStories",
  CoburgStoryGroup = "CoburgStoryGroup",
  CoburgStoriesGallery = "CoburgStoriesGallery",
  CoburgTemplate001 = "CoburgTemplate001",
  CoburgTemplate002 = "CoburgTemplate002",
  CoburgTemplate003 = "CoburgTemplate003",
  CoburgTemplate004 = "CoburgTemplate004",
  CoburgTemplate005 = "CoburgTemplate005",
  CoburgTemplateCardView = "CoburgTemplateCardView",
  CoburgDonorListsOverview = "CoburgDonorListsOverview",
  CoburgDonorList = "CoburgDonorList",
  CoburgSlide = "CoburgSlide",
  CoburgSearch = "CoburgSearch",
  CoburgDonorListCategory = "CoburgDonorListCategory",
  CoburgDonorListGroup = "CoburgDonorListGroup",
  CoburgDonorListCategoryWrapper = "CoburgDonorListCategoryWrapper",
  PassThroughWrapper = "PassThroughWrapper",

  Everett2x2Container = "Everett2x2Container",

  Monterrey5x1Container = "Monterrey5x1Container",
  Monterrey1x1Container = "Monterrey1x1Container",
  MonterreyDynamicContainer = "MonterreyDynamicContainer",

  Marin8x1Container = "Marin8x1Container",
  Marin1x1Container = "Marin1x1Container",
  MarinDynamicContainer = "MarinDynamicContainer",

  PhoenixContainer = "PhoenixContainer",

  BerkeleyContainer = "BerkeleyContainer",

  AlpineContainer = "AlpineContainer",

  TangentContainer = "TangentContainer",

  GlenwoodContainer = "GlenwoodContainer",
  GlenwoodBackground = "GlenwoodBackground",
  GlenwoodDonorList = "GlenwoodDonorList",
  GlenwoodDonorListCategory = "GlenwoodDonorListCategory",
  GlenwoodDonorListCategoryWrapper = "GlenwoodDonorListCategoryWrapper",
  GlenwoodDonorListGroup = "GlenwoodDonorListGroup",
  GlenwoodDonorListsOverview = "GlenwoodDonorListsOverview",
  GlenwoodDonorSea = "GlenwoodDonorSea",
  GlenwoodMenu = "GlenwoodMenu",
  GlenwoodRootPreview = "GlenwoodRootPreview",
  GlenwoodSearch = "GlenwoodSearch",
  GlenwoodSlide = "GlenwoodSlide",
  GlenwoodStories = "GlenwoodStories",
  GlenwoodStoriesGallery = "GlenwoodStoriesGallery",
  GlenwoodStoryGroup = "GlenwoodStoryGroup",
  GlenwoodTemplate001 = "GlenwoodTemplate001",
  GlenwoodTemplate002 = "GlenwoodTemplate002",
  GlenwoodTemplate003 = "GlenwoodTemplate003",
  GlenwoodTemplate004 = "GlenwoodTemplate004",
  GlenwoodTemplate005 = "GlenwoodTemplate005",
  GlenwoodTemplateCardView = "GlenwoodTemplateCardView",

  LoadingDefault = "LoadingDefault",
}

export enum PREVIEW_VIEW_PERCENTAGE_ORIENTATION {
  WIDTH = "width",
}

export enum FOLDER_CATEGORY {
  CONTENT = "content",
  CONTENTS = "contents",
  DISPLAY = "display",
  DISPLAYS = "displays",
  SLIDE = "slide",
  SLIDESHOW = "slideshow",
  ROOT = "root",
  GROUP = "group",
  DONOR_LISTS = "donor_lists",
  DONOR_LIST = "donor_list",
  STORIES = "stories",
  STORY = "story",
  DONOR = "donor",
  TIMELINE = "timeline",
  PRESENTATION = "presentation",
  PRESENTATIONS = "presentations",
  LAYOUT = "layout",
  LAYOUTS = "layouts",
  EVENT = "event",
  RESOURCE_ROOT = "resource_root",
  RESOURCE = "resource",
}

export enum SORTING_METHOD {
  rank = "rank",
  sorting_order = "sorting_order",
  name = "name",
}

export enum SORTING_DIRECTION {
  asc = "asc",
  desc = "desc",
}
