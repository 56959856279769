import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const eugene_menu: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.eugene_menu,
  display_name: "Eugene Main Menu",
  acceptable_folder_types: [
    FOLDER_TYPE.eugene_stories,
    FOLDER_TYPE.eugene_stories_menu,
    FOLDER_TYPE.eugene_donor_list_menu,
    FOLDER_TYPE.eugene_template_001,
    FOLDER_TYPE.eugene_template_002,
    FOLDER_TYPE.eugene_template_003,
    FOLDER_TYPE.eugene_template_004
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_add_children: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    heading: {
      type: "wysiwyg",
      title: "Menu Heading",
      validation: { rules: [] },
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "45em",
          color: "#FFFFFF"
        },
        ops: [],
        dark_mode: true
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: {
        fonts: [
          {
            title: "Serif",
            value: "serif"
          },
          {
            title: "Sans-Serif",
            value: "sansserif"
          },
          {
            title: "Roboto Regular",
            value: "roboto"
          },
          {
            title: "Roboto Slab Regular",
            value: "robotoslabregular"
          },
          {
            title: "Lato",
            value: "lato"
          }
        ],
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 50
        },
        recommended_length: 42,
        controls: {
          fontSize: true,
          bold: true,
          italic: true,
          underline: true,
          numberedList: true,
          bulletList: true,
          alignment: true,
          indentation: true,
          clearFormat: true
        },
        theme: "eugene"
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    menu_items: {
      type: "textstyle",
      title: "Menu Items",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO_SLAB, FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: true,
          items: [
            { title: "Left", value: "left" },
            { title: "Center", value: "center" },
            { title: "Right", value: "right" }
          ]
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        },
        bold: {
          enabled: true
        },
        underline: {
          enabled: true
        },
        italic: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneContainer,
    type: PREVIEW_TYPE.COMPONENT,
    react_url: "theme/eugene"
  },
  client: {
    component_name: COMPONENT_NAME.EugeneContainer
  }
};
