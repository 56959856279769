import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { glenwood_attract_loop } from "./glenwood_attract_loop";
import { glenwood_baby_alert } from "./glenwood_baby_alert";
import { glenwood_baby_alerts } from "./glenwood_baby_alerts";
import { glenwood_category_with_giving_level } from "./glenwood_category_with_giving_level";
import { glenwood_category_without_giving_level } from "./glenwood_category_without_giving_level";
import { glenwood_content } from "./glenwood_content";
import { glenwood_content_donor_seas } from "./glenwood_content_donor_seas";
import { glenwood_display } from "./glenwood_display";
import { glenwood_displays } from "./glenwood_displays";
import { glenwood_donor } from "./glenwood_donor";
import { glenwood_donor_list_group } from "./glenwood_donor_list_group";
import { glenwood_donor_sea } from "./glenwood_donor_sea";
import { glenwood_donor_sea_background } from "./glenwood_donor_sea_background";
import { glenwood_donor_sea_backgrounds } from "./glenwood_donor_sea_backgrounds";
import { glenwood_donor_sea_categories } from "./glenwood_donor_sea_categories";
import { glenwood_donor_sea_category } from "./glenwood_donor_sea_category";
import { glenwood_grouped_presentations } from "./glenwood_grouped_presentations";
import { glenwood_grouping_folder } from "./glenwood_grouping_folder";
import { glenwood_menu } from "./glenwood_menu";
import { glenwood_presentation } from "./glenwood_presentation";
import { glenwood_presentations } from "./glenwood_presentations";
import { glenwood_root } from "./glenwood_root";
import { glenwood_search } from "./glenwood_search";
import { glenwood_slide } from "./glenwood_slide";
import { glenwood_stories } from "./glenwood_stories";
import { glenwood_stories_overview } from "./glenwood_stories_overview";
import { glenwood_story_group } from "./glenwood_story_group";
import { glenwood_template_001 } from "./glenwood_template_001";
import { glenwood_template_002 } from "./glenwood_template_002";
import { glenwood_template_003 } from "./glenwood_template_003";
import { glenwood_template_004 } from "./glenwood_template_004";
import { glenwood_template_005 } from "./glenwood_template_005";

export const glenwood_types: { [key: string]: IFolderType } = {
  glenwood_attract_loop,
  glenwood_baby_alert,
  glenwood_baby_alerts,
  glenwood_category_with_giving_level,
  glenwood_category_without_giving_level,
  glenwood_content,
  glenwood_content_donor_seas,
  glenwood_display,
  glenwood_displays,
  glenwood_donor,
  glenwood_donor_list_group,
  glenwood_donor_sea,
  glenwood_donor_sea_background,
  glenwood_donor_sea_backgrounds,
  glenwood_donor_sea_categories,
  glenwood_donor_sea_category,
  glenwood_grouped_presentations,
  glenwood_grouping_folder,
  glenwood_menu,
  glenwood_presentation,
  glenwood_presentations,
  glenwood_root,
  glenwood_search,
  glenwood_slide,
  glenwood_stories,
  glenwood_stories_overview,
  glenwood_story_group,
  glenwood_template_001,
  glenwood_template_002,
  glenwood_template_003,
  glenwood_template_004,
  glenwood_template_005
};
