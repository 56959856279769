import React, { PropsWithChildren, useContext } from "react";
import { PreviewContext } from "../../Contexts";

export const Container = (props: PropsWithChildren<unknown>) => {
  const { containerHeight, containerWidth } = useContext(PreviewContext);

  return (
    <div
      style={{
        position: "absolute",
        width: containerWidth,
        height: containerHeight,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: containerWidth,
          height: containerHeight,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
