let saveCallback = [];
let submitFolderCallback = null;
let submitListViewCallback = null;

export const resetUnsavedService = () => {
    saveCallback = [];
}

export const addCallbackUnsavedService = (callback) => {
    saveCallback.push(callback)
}

export const addSubmitFolderCallback = (callback) => {
    submitFolderCallback = callback;
}

export const addSubmitListViewCallback = (callback) => {
    submitListViewCallback = callback;
}

export const executeAllCallbacksUnsavedService = () => {
    saveCallback.map((cb) => {
        cb();
    })
    resetUnsavedService()
}

const executeIfCan = (callback) => {
    if (callback) {
        callback();
    }
}

export const executeAllCallbacksAndSubmitUnsavedService = (folder_touch, list_view_touch) => {
    if (folder_touch) {
        executeIfCan(submitFolderCallback);
    }

    if (list_view_touch) {
        executeIfCan(submitListViewCallback);
    }

    saveCallback.map((cb) => {
        cb();
    })
    resetUnsavedService()
}