import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { wysiwygSettings } from "./monterrey_settings";
import { tree_icon_types } from "../tree_icon_types";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";
import { cycle_time_tooltips } from "../fremont/fremont_settings";

export const monterrey_story_fact: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORY,
  name: FOLDER_TYPE.monterrey_story_fact,
  tree_icon_types: {
    open: {
      ...tree_icon_types.story
    },
    closed: {
      ...tree_icon_types.story
    }
  },
  display_name: "Story Fact",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    // General
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    fact_cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fact_cycle_time"
      }
    },
    fact_heading: {
      title: "Fact Heading",
      field_group: "general",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "34px",
            bold: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fact_heading"
      }
    },
    fact_body: {
      title: "Fact Body",
      field_group: "general",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "30px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "fact_body"
      }
    }
  },
  preview: {
    aspect_ratio: 9 / 16,
    component_name: COMPONENT_NAME.Monterrey1x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    hide_border: false,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: false
};
