import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from '../../../../enums/folder-type-enums';
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from '../../folders/IFolderType';
import base_types from '../base_types';
import { wysiwyg } from "../standardized_fields";
import { transitions, wysiwygSettings } from "./berkeley_settings";

export const berkeley_template_003: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.berkeley_template_003,
  display_name: "Story 3",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    story: {
      title: "Story",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    quote: {
      title: "Quote",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
  },
  fields: {
    // General
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_quote_cycle_time'
      }
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_quote_transition'
      }
    },
    // Story
    mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_with_quote_mirror_content"
      }
    },
    header: {
      title: "Header",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "80px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_header"
      }
    },
    subhead: {
      title: "Subhead",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "60px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_subhead"
      }
    },
    body: {
      title: "Body",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "60px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_body"
      }
    },
    story_background: {
      type: "color",
      title: "Story Background Color",
      field_group: "story",
      default: "#42709C",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_background"
      }
    },

    // Quote
    quote: {
      title: "Quote",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "50px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_quote"
      }
    },
    attribution: {
      title: "Attribution",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "right",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "45px",
            lineHeight: "1.25",
            italic: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_attribution"
      }
    },
    quote_background: {
      type: "color",
      title: "Quote Background Color",
      field_group: "quote",
      default: "#42709C99",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_quote_background"
      }
    },
    // Background
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_background_image"
      }
    },
    background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_quote_background_image_overlay"
      }
    },
  },
  preview: {
    aspect_ratio: 2.666666666666667,
    component_name: COMPONENT_NAME.BerkeleyContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 2 }],
    zoomable: true,
  },
  folder_type_selector: {
    image_url: "/images/folder_types/berkeley/berkeley_template_003.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
