import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import RichTextRender from "../../standard/RichTextRender";
import { getResourceFoldersOfFileDynamic } from "../../../utils/fileUtils";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import styles from "./styles";
import Slide1911 from "../../standard/Slide1911";
import { get } from "lodash";
import TangentQrWidget from "./TangentQrWidget";

const useStyles = createUseStyles(styles);

const TangentStoryWithMedia = (props) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } = useFolder(
    props.data.base_folder
  );
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const { standardSizing, getUnitBasedOnHeight } = useScale(
    props.data.base_folder
  );

  const showPlaceholder = useMemo(() => {
	return props?.data?.persistent_settings?.data?.show_placeholder || false;
  }, [props.data]);

  const tagline = getBaseFolderFieldValue("tagline", baseFolderType);
  const header = getBaseFolderFieldValue("header", baseFolderType);
  const body = getBaseFolderFieldValue("body", baseFolderType);
  const backgroundColor = getBaseFolderFieldValue(
    "background_color",
    baseFolderType
  );
  const imageFieldType = getBaseFolderFieldValue("media", null);
  const backgroundColorOverlay = getBaseFolderFieldValue(
    "background_color_overlay",
    "transparent"
  );

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    backgroundColorOverlay,
    standardSizing,
    getUnitBasedOnHeight,
  });

  const propsClasses = get(props, 'classes.componentContainer', '');

  return (
    <div className={[classes.storyWithMedia, propsClasses].join(" ")}>
      <Slide1911
        aws={props.aws}
        aws_bucket={props.aws_bucket}
        mode={props.mode}
        containerWidth={props.containerWidth}
        containerHeight={props.containerHeight}
        data={{
          ...props.data,
          slotNames: ["background_image"],
          style: {
            backgroundPosition: "inherit",
          }
        }}
        webview_signedurls={props.webview_signedurls}
      />
      {/* Background Overlay */}
      <div className={classes.storySingleImageOverlay} />

      <div className={classes.sheets}>
        <div className="sheet1">
          <div className="headerWrapper">
            <div className="sectionPadding">
              <div className={`sectionHeader`}>
                <RichTextRender className="tagline" htmlString={tagline} />
                <RichTextRender className="header" htmlString={header} />
              </div>
            </div>
          </div>
          <Slide1911
            aws={props.aws}
            aws_bucket={props.aws_bucket}
            mode={props.mode}
            containerWidth={props.containerWidth}
            containerHeight={props.containerHeight / 6}
            data={{
              ...props.data,
              slotNames: ["media"],
              positionRelative: "relative",
              placeholder: "image",
              fieldType: imageFieldType,
              showPlaceholder: showPlaceholder,
              backgroundSize: "cover",
              style: {
                backgroundPosition: "inherit",
              },
              componentSpecificData: {
                customStyleImage: {
                  backgroundPosition: "center",
                },
              },
            }}
            webview_signedurls={props.webview_signedurls}
          />
        </div>
        <TangentQrWidget {...props} {...baseFolder.fields} />
        <div className="sheet2">
          <div className={`sectionBody`}>
            <RichTextRender className="body" htmlString={body} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TangentStoryWithMedia;
