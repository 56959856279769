import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields, getFieldValue } from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {
  SPR_PORTRAIT_BODY_LINE_HEIGHT,
  SPR_PORTRAIT_BODY_MARGIN_TOP,
  SPR_PORTRAIT_HEADING_LINE_HEIGHT,
  SPR_PORTRAIT_HEADING_MARGIN_TOP,
  SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM,
  SPR_PORTRAIT_SUBHEAD_LINE_HEIGHT,
  SPR_PORTRAIT_SUBHEAD_MARGIN_TOP
} from "./SpringfieldPortraitConstants";

const SpringfieldPortraitTemplate004 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const color = useMemo(() => {
    return get(baseFolder, "fields.color_overlay", "transparent");
  }, [baseFolder]);

  const getStyles = useCallback(() => {
    if (baseFolder) {
      const folderType = props.data?.folder_types?.find((f) => f.name === baseFolder.folder_type);
      const styles = createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder.fields));
      return styles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, [baseFolder?.folder_type, props.data?.folder_types]);

  const useStyles = getStyles();
  const classes = useStyles();

  const headline = getFieldValue(baseFolder, "headline", baseFolderType);
  const subhead = getFieldValue(baseFolder, "subhead", baseFolderType);
  const bodyText = getFieldValue(baseFolder, "body", baseFolderType);
  const quote = getFieldValue(baseFolder, "quote", baseFolderType);
  const attribution = getFieldValue(baseFolder, "attribution", baseFolderType);

  const mirrorContent = get(baseFolder, "fields.mirror_content", false);
  const showQuotes = get(baseFolder, "fields.show_quotes", true);

  return (
    <>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{ ...props.data, slotNames: ["background"] }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: color,
          zIndex: 0
        }}
      />
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          display: "flex",
          flex: 1,
          flexDirection: !mirrorContent ? "column" : "column-reverse"
        }}
      >
        {/* Text */}
        <div
          style={{
            height: "100%",
            color: "#000000",
            overflowY: "hidden",
            textAlign: "center",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              margin: 0,
              position: "absolute",
              maxWidth: "100%",
              // top: "50%",
              // msTransform: "translateY(-50%)",
              // transform: "translateY(-50%)",
              //backgroundColor: 'orange'
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: 0.166583375 * 0.5625 * props.containerHeight,
                margin: "auto",
                display: showQuotes ? "block" : "none"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  fontFamily: "Lora-Bold",
                  fontSize: props.containerHeight * (0.277777778 * 0.5625),
                  top: -(0.074962519 * 0.5625) * props.containerHeight,
                  color: "#cdcdcd"
                }}
              >
                “
              </div>
            </div>

            <div
              style={{
                overflowX: "hidden",
                overflowY: "hidden",
                paddingLeft: 0.12 * props.containerWidth,
                paddingRight: 0.12 * props.containerWidth,
                paddingTop: SPR_PORTRAIT_HEADING_MARGIN_TOP * props.containerHeight,
                paddingBottom: 0.035 * props.containerHeight
              }}
              className={classes.componentContainer}
            >
              <RichTextRender
                htmlString={quote}
                style={{
                  lineHeight: "1.2",
                  padding: 0
                }}
              />
            </div>
            <div style={{ marginTop: 0.006658337 * 0.5625 * props.containerHeight }}>
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "hidden",
                  paddingLeft: 0.12 * props.containerWidth,
                  paddingRight: 0.12 * props.containerWidth,
                  paddingTop: SPR_PORTRAIT_HEADING_MARGIN_TOP * props.containerHeight,
                  paddingBottom: 0.035 * props.containerHeight
                }}
                className={classes.componentContainer}
              >
                <RichTextRender
                  htmlString={attribution}
                  style={{
                    lineHeight: "1.2",
                    padding: 0
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Text */}
        <div
          style={{
            height: "100%",
            color: "#000000",
            paddingLeft: 0.12 * props.containerWidth,
            paddingRight: 0.12 * props.containerWidth,
            paddingTop: SPR_PORTRAIT_HEADING_MARGIN_TOP * props.containerHeight,
            paddingBottom: 0.035 * props.containerHeight
          }}
        >
          <div
            style={{
              width: "100%",
              maxHeight: "100%",
              overflowY: "hidden",
              overflowX: "hidden"
            }}
          >
            {/* Headline */}
            <div
              className={classes.componentContainer}
              style={{
                padding: 0,
                lineHeight: SPR_PORTRAIT_HEADING_LINE_HEIGHT,
                overflowX: "hidden",
                overflowY: "hidden",
                paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight
              }}
            >
              <RichTextRender
                htmlString={headline}
                style={{
                  lineHeight: "1.2",
                  padding: 0
                }}
              />
            </div>
            {/* Sub Head */}
            {subhead && (
              <div style={{ marginTop: SPR_PORTRAIT_SUBHEAD_MARGIN_TOP * props.containerHeight }}>
                <div
                  className={classes.componentContainer}
                  style={{
                    padding: 0,
                    overflowX: "hidden",
                    overflowY: "hidden",
                    lineHeight: SPR_PORTRAIT_SUBHEAD_LINE_HEIGHT,
                    paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight
                  }}
                >
                  <RichTextRender
                    htmlString={subhead}
                    style={{
                      lineHeight: "1.2",
                      padding: 0
                    }}
                  />
                </div>
              </div>
            )}
            {/* Body Text */}
            <div
              style={{
                marginTop: SPR_PORTRAIT_BODY_MARGIN_TOP * props.containerHeight
              }}
            >
              <div
                className={classes.componentContainer}
                style={{
                  padding: 0,
                  overflowX: "hidden",
                  overflowY: "hidden",
                  lineHeight: SPR_PORTRAIT_BODY_LINE_HEIGHT,
                  paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight
                }}
              >
                <RichTextRender
                  htmlString={bodyText}
                  style={{
                    lineHeight: "1.2",
                    padding: 0
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpringfieldPortraitTemplate004;
