import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
} from "../enums/folder-type-enums";

export interface ITreeDroppedIntoAction {
  action: string;
  options: any;
}

export interface IListColumn {
  folder_types: FOLDER_TYPE[];
  field: string;
  title: string;
  sort: boolean;
  filter?: boolean;
  editable: boolean;
  default_shown: boolean;
  required?: boolean;
}

export interface IInheritableSettings {
  inherit_on_create: boolean;
  pass_down_to_children: boolean;
  related_field?: string;
}

export enum FOLDER_TYPE_NAME {
  COLOR = "color",
  TEXT_STYLE = "textstyle",
  WYSIWYG = "wysiwyg",
}

export interface IFolderTypeButton {
  title: string;
  action: string;
  icon?: string[];
  icon_class?: string;
}

export interface IFolderTypeField {
  // Only used for fileds of type file
  acceptable_contents?: any;
  
  // At the time of writing this comment, this property is not being used
  aspect_ratio?: number;
  
  // Only used for fields of type file.
  aspect?: number;
  
  // Only used for fields of type file.
  // It determines the behavior of the content manager as well
  // as the apsect ratio of the image in the content manager.
  // the aspect property nested in this property should be merged with the aspect property above.
  container?: any;
  
  // Default value for this field when a new folder is created
  default?: any;

  // Only used for fields of type file.
  // Determines the image presets that should be used for image conversion.
  image_presets?: any;

  // Only used for fields of type file.
  // Determines the maximum number of files that can be uploaded to this field.
  limit?: any;

  // Used only for "select" fields.
  // Prepopulates the select field with the specified options.
  options?: any;
  
  // Not being used
  path?: any;

  // When enabled, the field will be displayed in the properties panel but will not be editable.
  read_only?: any;
  
  // Only used for fields of type file.
  // Should contain an object with the width and height recoommended for the image.
  recommended_size?: any;
  
  // Used to define different properties for different types of fields.
  // This can be typed better for all the different cases.
  settings?: any;  
  
  // Used for fields of type file.
  // Determines whether to show the caption field in the content manager
  show_caption?: boolean;
  
  slot_names?: any;
  slot_type?: any;
  slots?: any;
  step?: any;
  
  // Determines whether this field can be edited by a sys admin only.
  sys_admin_only?: boolean;
  
  // Title shown in the properties panel for this field.
  title?: any;
  
  // Optional text for a tooltip that will be shown when the user hovers over question mark icon next to the field title.
  tooltip?: any;
  type?: any;

  // Used for validation of the field value.
  validation?: any;
  video_presets?: any;

  // Whether to show the character count for this field.
  show_char_count?: boolean;

  // Field group to which this field belongs.
  field_group?: string;
  
  // Whether this field inherits its value from a parent folder.
  // Can be replaced by checking if inheritable_settings is defined.
  is_inheritable?: boolean;

  // Does not seem to be used for anything.
  associated_to_field?: string;
  
  // Configuration for inheritable fields.
  inheritable_settings?: IInheritableSettings;
  
  // Currently only being used for Boston Checkerboard
  // Does not seem to be used for anything else.
  show_conditional_met?: boolean;
  
  isVisible?: (values: any) => boolean;

  // Does not seem to be used for anything.
  mode?: string;
  conditional_show?: { field: string, value: string }[]
  resource_linked?: boolean
  resource_linkable?: boolean;
  content_manager_dark_mode?: boolean;
  crop?: any;
  status?: string;
  selected_from_tree?: boolean;
  resource?: number;
  id?: number;
  slotName?: string;
  fileName?: string;
  randomFileName?: string;
  originalName?: string;
  modifiedName?: string;
  size?: number;
  folder?: number;
  rank?: number
  complex?: boolean
}

export interface IScreenSet {
  name: string;
  orientation: string;
  horizontal: number;
  vertical: number;
}

// I think we need this for a specific project
// Where the webview is pointing to a child folder type
// We should probably find a better way to do this
export interface IFolderTypeWebview {
  use_child_folder_type?: FOLDER_TYPE; // Point webview to a child folder type.
  is_loading_message_enabled?: boolean // Indicates if folder has custom loading message
  loading_message_field?: string // Indicates the field key of the loading message input
  loading_component?: COMPONENT_NAME // Define the component to use in the loading screen
}

export interface IDefaultChild {
  name: string;
  folder_type: FOLDER_TYPE;
  children?: IDefaultChild[];
}

export interface IChildrenCondition {
  type: string;
  value: any;
}

export * from './folder-types/folders/IFolderType'
export * from './folder-types/folders/IFolderTypePreview'
