import { getFontSizes } from "../../../../utils/general";
import { FontsFamilies } from "../../../../fonts";
import { wysiwyg as wysiwyg_standard } from "../standardized_fields";
import { cloneDeep } from "lodash";
import { IFolderTypeField } from "../../../../interfaces/folder-type-interfaces";

export const naplesFonts = [
  FontsFamilies.GOTHAM,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.LUSITANA
];

const fontMinSize = 1;
const fontMaxSize = 10;

export const textStyleFontSizes = getFontSizes(10, 40, 1, "px", (i) => {
  return `${i}px`;
});

export const quillFontSizes = {
  min: fontMinSize,
  max: fontMaxSize,
  type: "range"
};

export const textStyleOptions = {
  font: {
    enabled: true,
    items: naplesFonts
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: textStyleFontSizes
  },
  lineHeight: {
    enabled: false
  },
  bold: {
    enabled: true
  },
  color: {
    enabled: true
  },
  italic: {
    enabled: true
  },
  underline: {
    enabled: true
  }
};

export const settings = {
  use_em_units: true,
  wysiwyg_input_type: "text"
};

export const transitions = [
  {
    title: "Fade (1/2 s)",
    value: "transition-fade"
  },
  {
    title: "Fade (1 s)",
    value: "transition-fade1s"
  },
  {
    title: "Fade (2 s)",
    value: "transition-fade-2s"
  },
  {
    title: "Slow Fade In / Fast Fade Out",
    value: "transition-slowinfastout"
  },
  {
    title: "Fast Fade In / Slow Fade Out",
    value: "transition-fastinslowout"
  },
  {
    title: "Slide in right",
    value: "transition-slide-in-from-right"
  },
  {
    title: "Slide in left",
    value: "transition-slide-in-from-left"
  },
  {
    title: "Slide in top",
    value: "transition-slide-in-from-top"
  },
  {
    title: "Slide in bottom",
    value: "transition-slide-in-from-bottom"
  }
];

export const list_styles = [
  {
    title: "Column Filled",
    value: "filled"
  },
  {
    title: "Column Balanced",
    value: "balanced"
  }
];

const _wysiwyg: IFolderTypeField = cloneDeep(wysiwyg_standard);
_wysiwyg.settings.controls.rule = true;

export const wysiwyg = _wysiwyg;
