import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { generateFontSizeClassesFromFields } from "../../../utils/generalUtils";
import { IFolderType } from "../../../../shared-global/interfaces/folder-type-interfaces";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import RichTextRender from "../../standard/RichTextRender";

interface ICoburgMenuItemProps {
  text: any;
  folder: IFolder;
  folderType: IFolderType;
  refHeight: number;
}

const CoburgMenuItem: React.FunctionComponent<ICoburgMenuItemProps> = (props) => {
  const getStyles = useCallback(
    () => createUseStyles(generateFontSizeClassesFromFields(props.folderType, props.folder.fields)),
    [props.folder, props.folderType]
  );

  const useStyles = getStyles();
  const classes = useStyles();

  return (
    <>
      {props.folder.folder_type === FOLDER_TYPE.coburg_presentation ? (
        <RichTextRender containerHeight={props.refHeight} htmlString={props.text} />
      ) : (
        <span>{props.text}</span>
      )}
    </>
  );
};

export default CoburgMenuItem;
