import React, { Component } from 'react';
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
class WithInputTouch extends Component {

  render() {
    return (
      <DndProvider backend={TouchBackend}>
        {this.props.children}
      </DndProvider>
    )
  }
}

export default WithInputTouch


