import get from "lodash/get";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

interface IScrollableFixContainerProps {
  containerHeight: number;
  containerWidth: number;
  showScrollbar?: boolean;
  fadeClass?: string;
  barColor?: string;
  fadePaddingBottomPercentage?: number;
  hidePaddingRight?: boolean;
  paddingRightPercent?: number;
  overrideHeight?: any;
  fadePercentage?: number;
}

const calculateStyles = (color) => {
  const styles = {
    scrollableFixContainer2103: {
      "& ::-webkit-scrollbar": {
        width: 2
      },
      "& ::-webkit-scrollbar-track": {
        // background: 'red',
      },
      "& ::-webkit-scrollbar-thumb": {
        background: `${color}`
      }
    }
  };

  return styles;
};

const ScrollableFixContainer2103: React.FunctionComponent<IScrollableFixContainerProps> = (props) => {
  const textContainerRef = useRef<HTMLDivElement>();
  const [fadeStyle, _setFadeStyle] = useState({});
  const fadeStyleRef = useRef(fadeStyle);
  const setFadeStyle = (data) => {
    fadeStyleRef.current = data;
    _setFadeStyle(data);
  };
  const [scrollOffsetBottom, setScrollOffsetBottom] = useState(0);
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const [textContainerHeight, setTextContainerHeight] = useState(0);
  const [showUpperFading, setShowUpperFading] = useState(false);

  const [scrollOffset, _setScrollOffset] = useState(0);
  const scrollOffsetRef = useRef(scrollOffset);
  const setScrollOffset = (data) => {
    scrollOffsetRef.current = data;
    _setScrollOffset(data);
  };

  const [contentHeight, setContentHeight] = useState(props.containerHeight * 0.75);
  // const [classes, setClasses] = useState<any>({})
  const elementRef = useRef(null);
  const fadeClass = props.fadeClass || "scrollable-fix-container";
  const [fadeBottomPaddingPercentage, setFadeBottomPaddingPercentage] = useState(50);
  const useStyles = createUseStyles(calculateStyles(props.barColor));
  const classes = useStyles();

  const setupScrollOffsetTop = () => {
    setTimeout(() => {
      if (textContainerRef.current) {
        setTextContainerHeight(textContainerRef.current.clientHeight);
      } else {
        setupScrollOffsetTop();
      }
    }, 250);
  };

  const listenToScroll = useCallback((event) => {
    const offset2 = get(event, "srcElement.scrollTop", 0);
    setScrollOffset(offset2);
  }, []);

  useEffect(() => {
    if (elementRef && elementRef.current) {
      elementRef.current.addEventListener("scroll", listenToScroll, true);
    }

    if (props.overrideHeight) {
      setContentHeight(props.overrideHeight);
    }

    setupScrollOffsetTop();

    return () => {
      if (elementRef && elementRef.current) {
        elementRef.current.removeEventListener("scroll", listenToScroll);
      }
    };
  }, []);

  useEffect(() => {
    const scrollbar = get(props, "showScrollbar", false);
    setScrollbarVisible(scrollbar);
  }, [props.showScrollbar]);

  useEffect(() => {
    if (
      props.fadePaddingBottomPercentage &&
      props.fadePaddingBottomPercentage > 0 &&
      props.fadePaddingBottomPercentage < 100
    ) {
      const paddingCalculated = 100 - props.fadePaddingBottomPercentage;
      setFadeBottomPaddingPercentage(paddingCalculated);
    }
  }, [props.fadePaddingBottomPercentage]);

  useEffect(() => {
    const fadeStyleObj = {};
    const fadePercentageDecimal = props.fadePercentage ? props.fadePercentage / 100 : 0.2;

    let calcTopPercentage = 0;
    let calcBottomPercentage = fadePercentageDecimal * 100;

    if (scrollOffsetBottom > 0) {
      let actualPercentage = scrollOffsetRef.current / scrollOffsetBottom;
      actualPercentage = actualPercentage > 1 ? 1 : actualPercentage;
      calcTopPercentage = (actualPercentage > fadePercentageDecimal ? fadePercentageDecimal : actualPercentage) * 100;
      calcBottomPercentage = (actualPercentage < 1 - fadePercentageDecimal ? 1 - fadePercentageDecimal : actualPercentage) * 100;

      let webKitStyleString = "";
      if (calcTopPercentage > 0) {
        webKitStyleString += `-webkit-gradient(linear, left ${calcTopPercentage}%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))`;
      }
      if (webKitStyleString.length > 0 && calcBottomPercentage > 0) {
        webKitStyleString += ", ";
      }
      if (calcBottomPercentage < 100) {
        webKitStyleString += `-webkit-gradient(linear, left ${calcBottomPercentage}%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))`;
      }
  
      fadeStyleObj["WebkitMaskImage"] = webKitStyleString;
      if (calcBottomPercentage > 0 && calcTopPercentage > 0) {
        fadeStyleObj["WebkitMaskComposite"] = "source-in";
      }
  
      setFadeStyle(fadeStyleObj);
    }
  }, [scrollOffsetRef.current, textContainerHeight, scrollOffsetBottom]);

  useEffect(() => {
    if (textContainerHeight > props.containerHeight) {
      setScrollOffsetBottom(textContainerHeight - props.containerHeight);
    }
  }, [textContainerHeight]);

  return (
    <div className={classes.scrollableFixContainer2103}>
      <div
        ref={elementRef}
        className={fadeClass}
        style={{
          overflow: scrollbarVisible ? "inherit" : "hidden"
        }}
      >
        <div
          style={{
            height: props.containerHeight,
            ...fadeStyleRef.current
          }}
        >
          <div
            style={{
              height: props.containerHeight
            }}
          >
            <div
              className={fadeClass + "_content"}
              style={{
                overflowX: "hidden",
                marginRight: scrollbarVisible ? 0 : -props.containerWidth * 0.0115 * 2,
                boxSizing: "content-box",
                width: props.containerWidth + props.containerWidth * 0.0115
              }}
            >
              <div
                className={fadeClass + "_container"}
                style={{
                  height: props.containerHeight,
                  marginTop: 20,
                  margin: "0 auto"
                }}
              >
                <div
                  ref={textContainerRef}
                  className={fadeClass + "_text"}
                  style={{
                    paddingRight: props.hidePaddingRight
                      ? 0
                      : (props.containerWidth * get(props, "paddingRightPercent", 10)) / 100
                  }}
                >
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollableFixContainer2103;
