import React from "react"
import MarinFactCarousel from "./MarinFactCarousel";
import get from "lodash/get";
import MarinFooter from "./MarinFooter";
import {generateFontSizeClassesFromFields, getFieldValue} from "../../../utils/generalUtils";
import { createUseStyles } from "react-jss";

const MarinFullscreenFactStory = (props) => {

  const factBackgroundColor = get(props.baseFolder, "fields.fact_background_color", "#76232f")
  const getStyles = () => {
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.baseFolder?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, props.baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();
  const backgroundColor = getFieldValue(props.baseFolder, "background_color", props.baseFolderType);


  return (
    <div
      className="wrapper"
      style={{
        width: "100%",
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor
      }}
    >
      <MarinFactCarousel
        {...props}
        factBackgroundColor={factBackgroundColor}
        style={{
          display: 'flex',
          width: '100%',
          marginTop: '7.5em',
          paddingRight: '7.5em',
          paddingLeft: '7.5em',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          maxHeight: "98em"
        }}
        minHeight="100%"
      />
      <MarinFooter
        {...props}
        classes={classes}
      />
    </div>
  )
}

export default MarinFullscreenFactStory
