import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { boston_checkerboard } from "./boston_checkerboard";
import { boston_content } from "./boston_content";
import { boston_content_checkerboards } from "./boston_content_checkerboards";
import { boston_content_donor_seas } from "./boston_content_donor_seas";
import { boston_content_fullscreen_media } from "./boston_content_fullscreen_media";
import { boston_content_media_with_quote } from "./boston_content_media_with_quote";
import { boston_display } from "./boston_display";
import { boston_displays } from "./boston_displays";
import { boston_donor } from "./boston_donor";
import { boston_donor_sea } from "./boston_donor_sea";
import { boston_donor_sea_background } from "./boston_donor_sea_background";
import { boston_donor_sea_backgrounds } from "./boston_donor_sea_backgrounds";
import { boston_donor_sea_categories } from "./boston_donor_sea_categories";
import { boston_donor_sea_category } from "./boston_donor_sea_category";
import { boston_fullscreen_media } from "./boston_fullscreen_media";
import { boston_media_with_quote } from "./boston_media_with_quote";
import { boston_presentations } from "./boston_presentations";
import { boston_root } from "./boston_root";
import { boston_presentation_3x2_and_1x4 } from "./boston_presentation_3x2_and_1x4";
import { boston_presentation_2x5_and_3x2 } from "./boston_presentation_2x5_and_3x2";
import { boston_presentation_1x2_and_1x5_and_3x2 } from "./boston_presentation_1x2_and_1x5_and_3x2";
import { boston_presentation_3x2 } from "./boston_presentation_3x2";
import { boston_donor_list } from "./boston_donor_list";
import { boston_content_donor_lists } from "./boston_content_donor_lists";
import { boston_content_diamond_platinum_lists } from "./boston_content_diamond_platinum_lists";
import { boston_diamond_platinum_list } from "./boston_diamond_platinum_list";

export const boston_types: { [key: string]: IFolderType } = {
  boston_root,
  boston_donor,
  boston_content_donor_seas,
  boston_donor_sea,
  boston_media_with_quote,
  boston_fullscreen_media,
  boston_checkerboard,
  boston_displays,
  boston_presentations,
  boston_content,
  boston_content_checkerboards,
  boston_content_media_with_quote,
  boston_content_fullscreen_media,
  boston_content_donor_lists,
  boston_content_diamond_platinum_lists,
  boston_donor_list,
  boston_display,
  boston_donor_sea_categories,
  boston_donor_sea_category,
  boston_donor_sea_background,
  boston_donor_sea_backgrounds,
  boston_diamond_platinum_list,
  boston_presentation_3x2_and_1x4,
  boston_presentation_1x2_and_1x5_and_3x2,
  boston_presentation_2x5_and_3x2,
  boston_presentation_3x2
};
