import React, {useState} from "react";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {List} from "react-virtualized";

const SortableList = SortableContainer(List);

const SortableListComponent = ({outerWidth, listContainerHeight, allItems, Row, onSortEnd, columns, onChange, forceUpdateInt, shouldCancelStart, helperClass, mode}) => {

  const SortableRow = SortableElement(props => {
    return (
      <Row
        itemIndex={props.itemIndex}
        style={props.style}
        item={props.item}
        columns={props.columns}
        mode={props.mode}
        onChange={props.onChange}
      />
    );
  });

  const rowRenderer = (rowProps) => {
    return (
      <SortableRow
        {...rowProps}
        key={rowProps.index}
        itemIndex={rowProps.index}
        item={allItems[rowProps.index]}
        columns={columns || []}
        mode={mode}
        onChange={onChange}
      />
    );
  };

  return (
    <SortableList
      className="list-container"
      width={outerWidth}
      height={listContainerHeight}
      rowCount={allItems.length}
      rowHeight={50}
      rowRenderer={rowRenderer}
      onSortEnd={onSortEnd}
      list={allItems}
      forceUpdateInt={forceUpdateInt}
      shouldCancelStart={shouldCancelStart}
      helperClass={helperClass}
    />)
}

const areEqualSortableList = (prevProps, nextProps) => {
  const areEqualAllItems = prevProps.allItems === nextProps.allItems;
  const areEquals = areEqualAllItems && prevProps.outerWidth === nextProps.outerWidth &&
    prevProps.listContainerHeight === nextProps.listContainerHeight &&
    prevProps.mode === nextProps.mode;

  return areEquals;
};

export const SortableJsonListViewMemo = React.memo(SortableListComponent, areEqualSortableList)