import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
  },
  item: {
    position: 'relative',
    margin: 10,
    '& #image': {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover!important',
      backgroundPosition: 'center!important'
    },
    '& #icon': {
      color: '#FFF',
      position: 'absolute',
      top: 2.5,
      right: 5,
      cursor: 'pointer'
    },
    height: 100,
    width: 100,
    border: "1px solid #CCC",
  }
});

export default useStyles;