import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import { getCSRF } from "../actions/authActions";
import {
  startFolderStatusPoll,
  stopFolderStatusPoll,
} from "../actions/folderActions";
import { getNotifications } from "../actions/notificationActions";
import { getAllUser, userGetData } from "../actions/userActions";
import { IState } from "../interfaces/main-state.interface";
import { checkIfFolderStatusInProgress } from "../services/notificationService";
import { IUser } from "../shared-global/interfaces/models/user.interface";
import { NOTIFICATION_TYPE_ALL } from "../shared/constants";
import TestProps from "../test-list-props";
import { hasPermission } from "../utils/permissionUtils";
import Configuration from "./admin/Configuration";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import PasswordReset from "./auth/PasswordReset";
import PasswordResetRequest from "./auth/PasswordResetRequest";
import Companies from "./company/Companies";
import CompanyAuditLog from "./company/CompanyAuditLog";
import CompanyForm from "./company/CompanyForm";
import CompanyLocationForm from "./company/CompanyLocationForm";
import CompanyProjectForm from "./company/CompanyProjectForm";
import CompanyRoleForm from "./company/CompanyRoleForm";
import CompanyUserForm from "./company/CompanyUserForm";
import UserList from "./company/UserList";
import UspertPermission from "./company/UspertPermission";
import Dashboard from "./dashboard/Dashboard";
import Demo from "./ImageUpload/Demo";
import AlternateLayout from "./layout/Alternate";
import MainLayout from "./layout/Main";
import DragDropHoc from "./list_view/DragDropHoc";
import { Notifications } from "./Notifications";
import WebviewGate from "./preview/WebviewGate";
import ReleaseNotes from "./site/ReleaseNotes";
import Test from "./test/Test";
import Test2 from "./test/Test2";
import Account from "./user/Account";
import ConfigureCompanies from "./user/ConfigureCompanies";
import WithInputMode from "./utils/WithInputMode";
import { initSocket } from "../actions/socketActions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TRANSITION_CLASS } from "../shared-global/enums/ui-enums";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import CheckAuthComponent from "./auth/CheckAuthComponent";

const AdminRoute = (props) => {
  return (
    <MainLayout>
      <div className="admin-main">{props.children}</div>
    </MainLayout>
  );
};

const DashboardRoute = (props) => {
  return <MainLayout>{props.children}</MainLayout>;
};

const AlternateRoute = (props) => {
  return <AlternateLayout>{props.children}</AlternateLayout>;
};

interface IAppProps {
  user?: IUser;
  folder_status?: any;
  grouped_permissions?: any;
}

const RouterContainer: React.FC<IAppProps> = (props: IAppProps) => {
  const [initialized, setInitialized] = useState(false);
  const [startedToPollFolderStatus, setStartedToPollFolderStatus] =
    useState(false);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state: IState) => state.data.user);
  const grouped_permissions = useSelector(
    (state: IState) => state.data.user_permissions_grouped
  );
  const folder__status = useSelector(
    (state: IState) => state.data.folder__status
  );

  useEffect(() => {
    dispatch(
      getCSRF(() => {
        dispatch(
          userGetData(() => {
            setInitialized(true);
          })
        );
      })
    );
    if (user && user.company) {
      console.log("DEBUG: this should not call");
      dispatch(getAllUser(user.company));
    }
  }, []);

  useEffect(() => {
    if (!socketInitialized && checkAuth([])) {
      dispatch(initSocket(null, null));
      setSocketInitialized(true);
    }

    if (!startedToPollFolderStatus && checkAuth([])) {
      // Start polling if user is authenticated.
      dispatch(startFolderStatusPoll(user.company, null, null));
      // dispatch(
      //   startFolderStatusPoll(
      //     user.company,
      //     (folderStatusArr) =>
      //       checkIfFolderStatusInProgress(
      //         folderStatusArr,
      //         () => dispatch(stopFolderStatusPoll())
      //       ),
      //     null
      //   )
      // );
      setStartedToPollFolderStatus(true);
    }
  }, [user]);

  useEffect(() => {
    if (user?.company) {
      dispatch(getNotifications(user.company, NOTIFICATION_TYPE_ALL));
    }
  }, [folder__status]);

  const checkAuth = (qualifiers, permissionsRequired: any = {}) => {
    if (!user.id) {
      return false;
    }
    if (!qualifiers) {
      return true;
    }
    let hasAuth = true;
    qualifiers.map((qualifier) => {
      switch (qualifier) {
        case "sysadmin":
          if (user.system_admin !== true) {
            hasAuth = false;
          }
          break;
        case "company_manage_users":
          //if (props.permissionsGrouped) {
          //TODO implement
          //}
          break;
        case "permissions":
          hasAuth = hasPermission(
            grouped_permissions,
            permissionsRequired.area,
            permissionsRequired.action,
            user,
            null,
            null,
            user.company
          );
          break;
        default:
          break;
      }
      return null;
    });
    if (hasAuth === true) {
      return true;
    }
    return false;
  };

  // if (initialized === false) {
  //   return <div>Loading ...</div>;
  // }
  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
  return (
    <div style={{ fontFamily: "Roboto" }}>
      <TransitionGroup>
        <CSSTransition
          key={initialized.toString()}
          timeout={500}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          {!initialized ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin indicator={antIcon} size="large" />
            </div>
          ) : (
            <>
              <WithInputMode />
              <Notifications />
              {/* <CacheBuster /> */}
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <AlternateRoute>
                        <Login />
                      </AlternateRoute>
                    }
                  />
                  {/* <DashboardRoute exact path="/uriaccess" component={UriAccess} /> */}
                  <Route
                    path="/account"
                    element={
                      <DashboardRoute>
                        <Account />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/release_notes"
                    element={
                      <DashboardRoute>
                        <ReleaseNotes />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/dashboard/*"
                    element={
                      <DashboardRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth([])}
                          Component={Dashboard}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/webview/:identifier"
                    element={
                      <AlternateRoute>
                        <WebviewGate />
                      </AlternateRoute>
                    }
                  />
                  <Route
                    path="/list"
                    element={
                      <DashboardRoute>
                        <ReleaseNotes />
                        {checkAuth([]) ? (
                          <div>
                            <br />
                            <br />
                            <br />
                            <DragDropHoc
                              listItems={TestProps.listItems}
                              listColumns={TestProps.listColumns}
                              listHeight={window.innerHeight - 400}
                            />
                          </div>
                        ) : (
                          <Navigate to="/login" />
                        )}
                      </DashboardRoute>
                    }
                  />

                  <Route
                    path="/login/:status?"
                    element={
                      <DashboardRoute>
                        <Login />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/test/route/one"
                    element={
                      <DashboardRoute>
                        <Test />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/test/route/two"
                    element={
                      <DashboardRoute>
                        <Test2 />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/logout"
                    element={
                      <DashboardRoute>
                        <Logout />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/request_password_reset"
                    element={
                      <DashboardRoute>
                        <PasswordResetRequest />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/reset_password"
                    element={
                      <DashboardRoute>
                        <PasswordReset />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/configure_companies"
                    element={
                      <DashboardRoute>
                        <ConfigureCompanies />
                      </DashboardRoute>
                    }
                  />
                  <Route
                    path="/configuration"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={Configuration}
                          FallbackComponent={() => <Navigate to="/" />} />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/companies"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={Companies}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/new"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={CompanyForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/audit_logs"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={CompanyAuditLog}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/project/:projectId"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={CompanyProjectForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/project/new"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"])}
                          Component={CompanyProjectForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/location/:locationId"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin"],
                            { area: "location", action: "read" })}
                          Component={CompanyLocationForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/location/new"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin", "permissions"],
                            { area: "location", action: "create" })}
                          Component={CompanyLocationForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/users"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth([])}
                          Component={UserList}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/user/:userId"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin", "permissions"], {
                            area: "user",
                            action: "read",
                          })}
                          Component={CompanyUserForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/user/new"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin", "permissions"], {
                            area: "user",
                            action: "create",
                          })}
                          Component={CompanyUserForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/role/:roleId"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin", "company_manage_users"])}
                          Component={CompanyRoleForm}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId/permission/:permissionId"
                    element={
                      <AdminRoute>
                        <CheckAuthComponent
                          checkAuth={() => checkAuth(["sysadmin", "company_manage_users"])}
                          Component={UspertPermission}
                          FallbackComponent={() => <Navigate to="/" />}
                        />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/company/:companyId"
                    element={
                      <AdminRoute>
                        <AdminRoute>
                          <CheckAuthComponent
                            checkAuth={() => checkAuth(["sysadmin", "permissions"], {
                              area: "company",
                              action: "read",
                            })}
                            Component={CompanyForm}
                            FallbackComponent={() => <Navigate to="/" />}
                          />
                        </AdminRoute>
                      </AdminRoute>
                    }
                  />
                  <Route path="/plain-old-route" element={Test}></Route>
                  <Route path="/demo" element={Demo}></Route>
                </Routes>
              </BrowserRouter>
            </>
          )}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default RouterContainer;
