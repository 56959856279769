import React, { PropsWithChildren, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";

export type CheckerboardGridProps = {
  delay: number;
  row: number;
  col: number;
  width: number;
  height: number;
  colorOverlay?: string;
};

export const CheckerboardCell = (
  props: PropsWithChildren<CheckerboardGridProps>
) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) return;
    const timer = setTimeout(() => setIsVisible(true), props.delay * 1000);
    return () => clearTimeout(timer);
  }, [props.delay, isVisible]);

  useEffect(() => {
    setIsVisible(false);
  }, []);

  return (
    <TransitionGroup>
      {isVisible && (
        <CSSTransition
          timeout={1000}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          <div
            style={{
              position: "absolute",
              top: props.row * props.height,
              left: props.col * props.width,
              width: props.width,
              height: props.height,
            }}
          >
            {props.colorOverlay && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: props.colorOverlay,
                }}
              />
            )}
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props.children}
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
