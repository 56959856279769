export default () => {
  const width = window.innerWidth;
  let panelWidth = 550;

  if (width <= 1600 && width > 1400) {
    panelWidth = 450;
  }

  if (width <= 1400) {
    panelWidth = 420;
  }

  if (width <= 1280) {
    panelWidth = 375;
  }

  return {
      topnav: {
        height: 64
      },
      tabs: {
        height: 64,
      },
      dashboard: {
        section_margin: 16,
        applied_content_tree_width: panelWidth,
        detail_drawer_width_percent: .35,
        tree_v_offset: 308,//226,
        tree_height_min: 200,
        tree_row_height: 28
      }
    }
}

export const BREAK_POINT_MOBILE = 768

// deprecated use PDG_COLORS enum instead
export const LT_GRAY = '#707070'
export const XLT_GRAY = '#c5c9cc';
export const XLT_BLUE = '#e6f3fe'
export const XXLT_GRAY = '#F6F6F7';
export const LT_GRAY_BLUE = '#657077';
export const MD_BLUE = '#2699FB'; //'#34be5b';
export const MD_PRESSED_BLUE = '#3F51B5';
export const MD_WARNING = '#FFB300';
export const MD_RED = '#ff4d4f';
export const WHITE = "#ffffff"

export const TREE_ICON_TEXT_MARGIN = 10

export const ALERT_SUCCESS_BACKGROUND = '#f6ffed'
export const ALERT_SUCCESS_BORDER = '1px solid #b7eb8f'
export const ALERT_SUCCESS_COLOR ='#52c41a'

export const ALERT_INFO_BACKGROUND = '#e6f7ff'
export const ALERT_INFO_BORDER = '1px solid #91d5ff'
export const ALERT_INFO_COLOR ='#1890ff'

export const ALERT_WARNING_BACKGROUND = '#fffbe6'
export const ALERT_WARNING_BORDER = '1px solid #ffe58f'
export const ALERT_WARNING_COLOR ='#faad14'

export const ALERT_ERROR_BACKGROUND = '#fff2f0'
export const ALERT_ERROR_BORDER = '1px solid #ffccc7'
export const ALERT_ERROR_COLOR ='#ff4d4f'

export function getFullDrawerHeight () {
  const wHeight = window.innerHeight;
  return wHeight - 42;
}

export function getTreeListHeight () {
  return getFullDrawerHeight() - 80
}
