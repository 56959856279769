import { normalize, schema } from "normalizr";
import { put } from "redux-saga/effects";
const testSchema = new schema.Entity("test");

const utilSaga = {
  breadcrumbSet: function* (action) {
    let success = false;
    try {
      yield put({
        type: "BREADCRUMB_SET_SUCCESS",
        breadcrumbs: action.payload.breadcrumbs,
      });
      success = true;
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {}
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  saveSocketInstance: function* (action) {
    yield put({
      type: "SAVE_SOCKET_INSTANCE_SUCCESS",
      socket_instance: action.payload.socket_instance,
    });
  },

  testChange: function* (action) {
    let val = { id: 1, val: action.payload.val };
    let val2 = normalize(val, testSchema);
    yield put({
      type: "TEST_CHANGE_SUCCESS",
      normalized: val2,
    });
  },

  broadcastNewMobileHeader: function* (action) {
    yield put({
      type: "BROADCAST_MOBILE_HEADER_SUCCESS",
      normalized: {
        entities: {
          mobile_state: [action.payload.mobile_state],
        },
      },
    });
  },

  initializeS3Cache: function* (action) {
    yield put({
      type: "INITIALIZE_S3_CACHE_SUCCESS",
      data: action.payload,
    });
  },

  updateS3Cache: function* (action) {
    yield put({
      type: "UPDATE_S3_CACHE_SUCCESS",
      data: action.payload,
    });
  },
};

export default utilSaga;
