import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";
import { wysiwygSettings } from "./monterrey_settings";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";

export const monterrey_intro_story: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORY,
  name: FOLDER_TYPE.monterrey_intro_story,
  acceptable_folder_types: [],
  tree_icon_types: {
    open: {
      ...tree_icon_types.story
    },
    closed: {
      ...tree_icon_types.story
    }
  },
  display_name: "Intro Story",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    intro: {
      title: "Intro",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    // General
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      field_group: "general",
      default: 10,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_cycle_time"
      }
    },
    body: {
      title: "Body",
      field_group: "intro",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "70px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_body"
      }
    },
    media: {
      title: "Media",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.3,
      recommended_size: {
        width: 920,
        height: 400
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.3
      },
      field_group: "intro",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_media"
      }
    },
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.125
      },
      field_group: "intro",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_background_image"
      }
    },
    background_color: {
      type: "color",
      title: "Background Color",
      default: "#76232f",
      field_group: "intro",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_background_color"
      }
    },
    footer_line_color: {
      type: "color",
      title: "Horizontal Line Color",
      field_group: "general",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_footer_line_color"
      }
    },
    footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "general",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "intro_2_footer_icons_color"
      }
    }
  },
  preview: {
    aspect_ratio: 18 / 16,
    component_name: COMPONENT_NAME.MonterreyDynamicContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 2, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    },
    hide_border: false
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  show_clone_children_checkbox: false,
  slots: 2
};
