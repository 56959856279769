import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Checkbox, Tooltip, Modal, Select } from "antd";
import React, { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "react-virtualized";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import { setClientOptions } from "../../../../reducers/clientOptionsSlice";
import { toggleFolderExpanded } from "../../../../actions/folderActions";
import {
  deleteBulkResources,
  moveBulkResources,
} from "../../../../actions/resourceActions";
import { IState } from "../../../../interfaces/main-state.interface";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IResource } from "../../../../shared-global/interfaces/models/resource.interface";
import { getFileType } from "../../../../shared/utils/fileUtils";
import AlertNotification from "../../../layout/AlertNotification";
import MultiFolderActions from "../../../list_view/MultiFolderActions";
import {
  DOUBLE_CLICK_DELAY,
  MIN_WIDTH_FULL_RESOLUTION,
  MIN_WIDTH_MEDIUM_RESOLUTION,
  THUMBNAIL_WIDTH,
} from "../constants";
import {
  IFolderFolderFolder,
  IResourceResourceFolder,
  MODE,
  RESOURCE_EXPLORER_MODE,
  RESOURCE_FOLDER_TYPE,
} from "../resource-explorer.interface";
import ResourceExplorerFolder from "../ResourceExplorerFolder/ResourceExplorerFolder";
import ResourceExplorerItem from "../ResourceExplorerItem/ResourceExplorerItem";
import useStyles from "./styles";
import _ from 'lodash';

let firstClickTimeoutHandler = null;

export interface IResourceExplorerProps {
  width: number;
  height: number;
  folders: any;
  resource__folders: any;
  folder__types?: any;
  mode: RESOURCE_EXPLORER_MODE;
  setCurrentResourceResourceFolderIndex: (index: number, row: number) => void;
  setIsPreviewOpen: (isOpen: boolean) => void;
  setCurrentResourceResourceFolders: (rffs: IResourceResourceFolder[]) => void;
  setSelectedResource?: (
    resource: IResource & { url: string; thumbUrl: string }
  ) => void;
  rowToScroll: number;
}

interface IFilters {
  [x: string]: boolean;
}

const ResourceExplorerThumbsAndFolders: React.FC<IResourceExplorerProps> = (
  props
) => {
  const foldersWrapperRef = useRef<HTMLDivElement>(null);
  const actionsAndFilterRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [firstClickOn, setFirstClickOn] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [disableDeleteAll, setDisableDeleteAll] = useState(false);
  const [filters, setFilters] = useState({} as IFilters);
  const [otherDivsHeight, setOtherDivsHeight] = useState(0);
  const [noAssociationIds, setNoAssociationIds] = useState([] as number[]);
  const [selectedIds, setSelectedIds] = useState([] as number[]);
  const [
    currentResourceResourceFolderIndex,
    setCurrentResourceResourceFolderIndex,
  ] = useState<number>(null);
  const [
    currentResourceResourceFolderIndexes,
    setCurrentResourceResourceFolderIndexes,
  ] = useState<number[]>([]);
  const [selectedFolderIndex, setSelectedFolderIndex] = useState<number>(null);
  const [currentFolderFolderFolders, setCurrentFolderFolderFolders] = useState<
    IFolderFolderFolder[]
  >([]);
  const [mode, switchMode] = useState<MODE>(MODE.SINGLE_SELECTION);
  const {
    aws_instance,
    resourcetree_resource__folders,
    resources,
    resourcetree_folders,
    resourcetree_folder__folders,
    user
  } = useSelector((state: IState) => state.data);
  const client_options = useSelector((state: IState) => state.client_options);
  const [currentResourceResourceFolders, setCurrentResourceResourceFolders] =
    useState<IResourceResourceFolder[]>([]);
  const [currentFolderType, setCurrentFolderType] =
    useState<RESOURCE_FOLDER_TYPE>(null);
  const [parentFolderId, setParentFolderId] = useState<number>(null);

  const isComponentWidthFull = useMemo(() => {
    return props.width > MIN_WIDTH_FULL_RESOLUTION;
  }, [props.width]);

  const selectedItemsCount = useMemo(() => {
    if (mode === MODE.SINGLE_SELECTION) {
      if (currentResourceResourceFolderIndex !== null) {
        return 1;
      }
      return 0;
    } else {
      return currentResourceResourceFolderIndexes.length;
    }
  }, [
    currentResourceResourceFolderIndex,
    currentResourceResourceFolderIndexes,
    mode,
  ]);

  const resourceTreeSelectedKey = useMemo(() => {
    return props.mode === RESOURCE_EXPLORER_MODE.CONTENT_LIBRARY
      ? "resource_tree_selected_id"
      : "resource_modal_tree_selected_id";
  }, [props.mode]);

  const currentFolderFolderId = useMemo(() => {
    let folderId = Number(
      client_options[resourceTreeSelectedKey].split("-")[1]
    );
    if (client_options[resourceTreeSelectedKey].includes("company")) {
      const folder = Object.values(resourcetree_folders).find(
        (f) => f.folder_type === FOLDER_TYPE.resource_root
      );
      if (folder) {
        folderId = folder.id;
      }
    }
    if (resourcetree_folder__folders) {
      const folderFolderId = Object.values(resourcetree_folder__folders).find(
        (rff) => rff.child_folder === folderId
      );
      return folderFolderId;
    }
    return null;
  }, [client_options]);

  const getS3Url = (resourceName, companyId) => {
    const s3 = new aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2",
    });
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${companyId}/resource`,
      Key: resourceName,
    };
    return s3.getSignedUrl("getObject", params);
  };

  const getButtonSize = () => {
    if (props.width > MIN_WIDTH_FULL_RESOLUTION) {
      return "large";
    } else if (props.width > MIN_WIDTH_MEDIUM_RESOLUTION) {
      return "large";
    }
    return "small";
  };

  const getColumnsBasedOnWidth = () => {
    if (props.mode === RESOURCE_EXPLORER_MODE.MODAL) {
      return 3;
    }
    return Math.floor(props.width / THUMBNAIL_WIDTH);
  };

  const goGoToFolder = (folderId, folderFolderId, openClose = true) => {
    dispatch(
      setClientOptions({
        resource_selected_folder: folderId,
        selected_folder_meta: {
          project: null,
          path: null,
          folder__folder_id: folderFolderId,
        },
      })
    );

    const folder = Object.values(resourcetree_folders).find(
      (f) => f.id === folderId
    );
    let folderStrId = "folder";
    if (folder.folder_type === FOLDER_TYPE.resource_root) {
      folderStrId = "company";
      folderId = folder.company;
    }
    dispatch(
      toggleFolderExpanded(`${folderStrId}-${folderId}`, openClose, true, client_options.treemode)
    );
    setSelectedFolderIndex(null);
  };

  const setSelectedResource = (currentResource: IResourceResourceFolder) => {
    let thumbUrl = "";
    const fileType = getFileType(currentResource.resource.name);
    let previewName = null;
    if (fileType === "image") {
      const thumbName = `${currentResource.resource.id}_preview.${currentResource.resource__folder.extension}`;
      thumbUrl = getS3Url(thumbName, currentResource.resource.company);
      previewName = `${currentResource.resource.id}_preview.${currentResource.resource__folder.extension}`;
    } else {
      previewName = `${currentResource.resource.id}_preview.${currentResource.resource.extension}`;
    }
    const url = getS3Url(previewName, currentResource.resource.company);
    props.setSelectedResource({ ...currentResource.resource, url, thumbUrl });
  };

  const onClickSelectResourceResourceFolder = (
    currentResource: IResourceResourceFolder,
    key: number,
    rowIndex: number
  ): void => {
    const resourceId = currentResource.resource.id;
    if (mode === MODE.SINGLE_SELECTION) {
      // This handler is for selecting a resource in the thumbnail view
      // in the modal when assigning an existing resource.
      if (props.setSelectedResource) {
        setSelectedResource(currentResource);
      }

      clearTimeout(firstClickTimeoutHandler);
      if (firstClickOn) {
        if (currentResource.resource.id === currentResourceResourceFolderIndex) {
          props.setCurrentResourceResourceFolderIndex(key, rowIndex);
          props.setIsPreviewOpen(true)
        }
        setFirstClickOn(false);
      } else {
        setFirstClickOn(true);
        firstClickTimeoutHandler = setTimeout(() => {
          setFirstClickOn(false);
        }, DOUBLE_CLICK_DELAY);
      }

      dispatch(
        setClientOptions({
          resource_selected_resource: currentResource.resource__folder.id,
        })
      );
      setCurrentResourceResourceFolderIndex(resourceId);
      setResourceTreeSelectedFonder();

    } else {
      const index = currentResourceResourceFolderIndexes.indexOf(key);
      const copyOfSeletedItems = [...currentResourceResourceFolderIndexes];
      if (index > -1) {
        copyOfSeletedItems.splice(index, 1);
        setSelectedIds(selectedIds.filter((id) => id != resourceId))
        setCurrentResourceResourceFolderIndexes(copyOfSeletedItems);
      } else {
        setSelectedIds([...selectedIds, resourceId])
        setCurrentResourceResourceFolderIndexes([
          ...currentResourceResourceFolderIndexes,
          key,
        ]);
      }
    }
  };

  const setResourceTreeSelectedFonder = () => {
    dispatch(
      setClientOptions({ resource_selected_folder: null })
    );
  }

  const onClickSelectFolder = (index: number): void => {
    setSelectedFolderIndex(index);
    clearTimeout(firstClickTimeoutHandler);
    if (firstClickOn) {
      if (index === selectedFolderIndex) {
        goGoToFolder(
          currentFolderFolderFolders[index].folder.id,
          currentFolderFolderFolders[index].folder__folder.id
        );
      }
    } else {
      setFirstClickOn(true);
      firstClickTimeoutHandler = setTimeout(() => {
        setFirstClickOn(false);
      }, DOUBLE_CLICK_DELAY);
    }
  };

  const onClickSwitchMode = (): void => {
    if (mode === MODE.SINGLE_SELECTION) {
      switchMode(MODE.MULTIPLE_SELECTION);
    } else {
      switchMode(MODE.SINGLE_SELECTION);
    }
  };

  const onClickDeleteBulkResources = (cb) => {
    let resourceIdsToDeBeDeleted = [];

    try {
      if (mode === MODE.SINGLE_SELECTION) {
        const foundResource = currentResourceResourceFolders.find(r => r.resource.id === currentResourceResourceFolderIndex);

        if (
          foundResource &&
          checkIfResourceAssociateToStory(foundResource)
        ) {
          AlertNotification(
            "error",
            "Error",
            "This item can't be deleted, as it is being used in a story."
          );
          return;
        }
        resourceIdsToDeBeDeleted.push(currentResourceResourceFolderIndex);
      } else {
        resourceIdsToDeBeDeleted = selectedIds;
      }
      dispatch(
        deleteBulkResources(
          resourceIdsToDeBeDeleted,
          (error: boolean) => {
            cb();
            setCurrentResourceResourceFolderIndex(null);
            setCurrentResourceResourceFolderIndexes([]);
            setSelectedIds([]);
            error ? AlertNotification(
                "warning",
                "Warning",
                `Some of the selected resources cannot be deleted because they are being used by one or more folders.`
            ) : AlertNotification(
                "success",
                "Success",
                `Selected items have been deleted successfully`
            )
          },
          cb
        )
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const checkIfResourceAssociateToStory = (currentResourceResourceFolders) => {
    const { resource__folders, folders, folder__types } = props;

    if (
      currentResourceResourceFolders &&
      resource__folders &&
      Object.values(resource__folders).length &&
      folders
    ) {
      const resourceId = currentResourceResourceFolders.id;
      const data = Object.values(resource__folders)
        .filter((rf: any) => {
          const folderId = _.get(rf, "folder", null);
          return rf.resource === resourceId && props.folders[folderId];
        })
        .map((f: any) => {
          return {
            folder_type: folders[f.folder].folder_type,
            ...f,
          };
        });

      if (
        data.filter(
          (d) =>
            folder__types[d.folder_type] &&
            folder__types[d.folder_type].folder_category === "story"
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const checkIfElementIsChildOrEqual = (targetElement, compareElement) => {
    let newTargetElement = targetElement;
    do {
      if (newTargetElement === compareElement) {
        return true;
      }
      newTargetElement = newTargetElement.parentNode;
    } while (newTargetElement);
    return false;
  };

  const handleDeleteAll = () => {
    const cb = () => {
      setShowDeleteAllModal(false);
    };
    dispatch(
      deleteBulkResources(
        noAssociationIds,
        (error: boolean) => {
          cb();
          setCurrentResourceResourceFolderIndex(null);
          setCurrentResourceResourceFolderIndexes([]);
          error ? AlertNotification(
              "warning",
              "Warning",
              `Some of the selected resources cannot be deleted because they are being used by one or more folders.`
          ) : AlertNotification(
              "success",
              "Success",
              `Selected items have been deleted successfully`
          )
        },
        cb
      )
    );
  };

  const onClickMoveBulkResources = (folderFolderId) => {
    const resourceFolder = currentResourceResourceFolders.find((resFolder) => resFolder.resource.id === currentResourceResourceFolderIndex);
    let itemsToBeMoved = [];
    if (mode === MODE.MULTIPLE_SELECTION) {
      itemsToBeMoved = currentResourceResourceFolderIndexes.map(
        (i) => currentResourceResourceFolders[i].resource__folder.id
      );
    } else {
      itemsToBeMoved = [resourceFolder.resource__folder.id];
    }
    dispatch(
      moveBulkResources(
        itemsToBeMoved,
        folderFolderId.folder__folder_id,
        () => {
          setCurrentResourceResourceFolderIndex(null);
          setCurrentResourceResourceFolderIndexes([]);
          AlertNotification(
            "success",
            "Success",
            `Selected items have been moved successfully`
          );
        }
      )
    );
  };

  const onClickGoBack = () => {
    const resourceFolderFolder = Object.values(
      resourcetree_folder__folders
    ).find((rff) => rff.child_folder === parentFolderId);
    const folder = Object.values(resourcetree_folders).find(
      (f) => f.id === resourceFolderFolder.child_folder
    );

    // If the current folder is the default folder for resources, instead we check for its parent
    // and selected that folder. This is because we are no longer showing the default folder
    // in the resource tree anymore.
    if (folder.folder_type === FOLDER_TYPE.resource_default) {
      const newFoundResourceFolder = Object.values(resourcetree_folders).find(
        (f) => f.folder_type === FOLDER_TYPE.resource_root
      );
      const newFoundResourceFolderFolder = Object.values(
        resourcetree_folder__folders
      ).find((rff) => rff.child_folder === newFoundResourceFolder.id);
      goGoToFolder(
        newFoundResourceFolder.id,
        newFoundResourceFolderFolder.id,
        false
      );
      return;
    }
    goGoToFolder(parentFolderId, resourceFolderFolder.id, false);
  };

  const checkingDivHeights = () => {
    let height = 0;
    if (
      props.mode !== RESOURCE_EXPLORER_MODE.MODAL &&
      currentFolderFolderFolders.length > 0
    ) {
      if (foldersWrapperRef.current) {
        const foldersRectClientHeight = foldersWrapperRef.current.clientHeight;
        if (foldersRectClientHeight === 0) {
          setTimeout(() => {
            checkingDivHeights();
          }, 100);
          return;
        } else {
          height += foldersRectClientHeight + 20;
        }
      } else {
        setTimeout(() => {
          checkingDivHeights();
        }, 100);
        return;
      }
    }
    if (actionsAndFilterRef && actionsAndFilterRef.current) {
      const filtersAndActionsClientHeight =
        actionsAndFilterRef.current.clientHeight;
      height += filtersAndActionsClientHeight + 20;
    }
    setOtherDivsHeight(height);
  };

  useEffect(() => {
    props.setCurrentResourceResourceFolders(currentResourceResourceFolders);
  }, [currentResourceResourceFolders]);

  useEffect(() => {
    let foundResourceSelectedFolder = null;
    let foundCurrentFolders = [];
    let foundCurrentResourceFolderType = RESOURCE_FOLDER_TYPE.FOLDER;

    if (client_options[resourceTreeSelectedKey].includes("company")) {
      // Let's find the resource root folder
      const companyId = Number(
        user.company
      );
      foundResourceSelectedFolder = Object.values(resourcetree_folders).find(
        (f) =>
          f.folder_type === FOLDER_TYPE.resource_default &&
          f.company === companyId
      );

      if (foundResourceSelectedFolder) {
        foundCurrentFolders = Object.values(resourcetree_folder__folders)
          .filter((rf) => rf.parent_folder === foundResourceSelectedFolder.id)
          .sort((a, b) => a.rank - b.rank)
          .map((rf) => ({
            folder: resourcetree_folders[rf.child_folder],
            folder__folder: rf,
          }));
        foundCurrentResourceFolderType = RESOURCE_FOLDER_TYPE.COMPANY;
      }
    } else {
      const folderId = Number(
        client_options[resourceTreeSelectedKey].split("-")[1]
      );
      foundResourceSelectedFolder = Object.values(resourcetree_folders).find(
        (f) => f.id === folderId
      );
      if (foundResourceSelectedFolder === undefined) {
        let resource_selected_folder = parentFolderId
        if (!resourcetree_folders[parentFolderId]) {
          for (const resourcetreeFolderKey in resourcetree_folders) {
            if (resourcetree_folders[resourcetreeFolderKey].name === "default") {
              resource_selected_folder = resourcetree_folders[resourcetreeFolderKey].id
              break
            }
          }
        }
        dispatch(
          setClientOptions({ resource_tree_selected_id: `folder-${resource_selected_folder}`, resource_selected_folder })
        )
        return
      }
      foundCurrentFolders = Object.values(resourcetree_folder__folders)
        .filter((rf) => rf.parent_folder === foundResourceSelectedFolder.id)
        .sort((a, b) => a.rank - b.rank)
        .map((rf) => ({
          folder: resourcetree_folders[rf.child_folder],
          folder__folder: rf,
        }));
      const foundResourceSelectedFolderFolder = Object.values(
        resourcetree_folder__folders
      ).find((rff) => rff.child_folder === folderId);
      setParentFolderId(foundResourceSelectedFolderFolder.parent_folder);
    }

    let foundCurrentResources = []

    if (foundResourceSelectedFolder) {
      foundCurrentResources = Object.values(resourcetree_resource__folders)
        .filter((rf) => rf.folder === foundResourceSelectedFolder.id)

        .sort((a, b) => a.rank - b.rank)
        .map((rf) => {
          const resource = resources[rf.resource];
          return {
            resource,
            resource__folder: rf,
          };
        }).filter((resourceResourceFolder) => {
          if (!resourceResourceFolder.resource) return false;
          const selected_resource = resourceResourceFolder.resource;
          let includeAssociation = true;
          const fileType = getFileType(selected_resource.name);
          const keys = Object.keys(filters);
          let includeType = true;

          if (filters.image || filters.video || filters.audio) {
            includeType = keys.includes(fileType) && filters[fileType];
          }

          if (filters.association) {
            const resource__folders = Object.values(
              props.resource__folders
            );
            const folders = props.folders;
            if (
              selected_resource &&
              resource__folders &&
              resource__folders.length &&
              folders
            ) {
              const resourceId = selected_resource.id;
              const data = resource__folders.filter((rf: any) => {
                const folderId = rf.folder || null;
                return (
                  rf.resource === resourceId && props.folders[folderId]
                );
              });
              includeAssociation = !data.length;
            }
          }

          return includeAssociation && includeType;
        })
        .filter((resource) => {
          if (!client_options.search_string_main.length) {
            return true;
          } else {
            return resource.resource.modifiedName
              .toLowerCase()
              .includes(
                client_options.search_string_main.toLowerCase()
              );
          }
        })
        .filter((resource) => {
          if (!client_options.search_string_existing_resource.length) {
            return true;
          } else {
            return resource.resource.modifiedName
              .toLowerCase()
              .includes(
                client_options.search_string_existing_resource.toLowerCase()
              );
          }
        });

      setCurrentResourceResourceFolders(foundCurrentResources);
      setCurrentFolderFolderFolders(foundCurrentFolders);
      setCurrentFolderType(foundCurrentResourceFolderType);
    }

    // If there's a selected resource in the client options
    // if (client_options.resource_selected_resource) {
    // const foundIndex = foundCurrentResources.findIndex(
    //   (rfr) =>
    //     rfr.resource__folder.id === client_options.resource_selected_resource
    // );
    // if (foundIndex > -1) {
    // setCurrentResourceResourceFolderIndex(client_options.resource_selected_resource);
    // }
    // }
  }, [client_options, resources, resourcetree_resource__folders, resourcetree_folders]);

  useEffect(() => {
    if (
      selectedFolderIndex !== null &&
      currentResourceResourceFolderIndex !== null
    ) {
      setSelectedFolderIndex(null);
    }
  }, [currentResourceResourceFolderIndex]);

  useEffect(() => {
    if (
      currentResourceResourceFolderIndex !== null &&
      selectedFolderIndex !== null
    ) {
      setCurrentResourceResourceFolderIndex(null);
    }
  }, [selectedFolderIndex]);

  useEffect(() => {
    if (mode === MODE.SINGLE_SELECTION) {
      setCurrentResourceResourceFolderIndexes([]);
    } else {
      dispatch(
        setClientOptions({
          resource_selected_resource: null,
        })
      );
      setCurrentResourceResourceFolderIndex(null);
      setSelectedFolderIndex(null);
    }
  }, [mode]);

  useEffect(() => {
    checkingDivHeights();
  }, [currentFolderFolderFolders, props.mode]);

  const handleFilter = (values: string[]) => {
    const filters: IFilters = values.reduce((obj, value) => {
      obj[value] = true
      return obj
    }, {})
    setFilters(filters);
    if (filters.association === true) {
      const ids = [];
      currentResourceResourceFolders.forEach((resourceResourceFolder) => {
        const selected_resource = resourceResourceFolder.resource;
        const resource__folders = Object.values(props.resource__folders);
        const folders = props.folders;
        if (
          selected_resource &&
          resource__folders &&
          resource__folders.length &&
          folders
        ) {
          const resourceId = selected_resource.id;
          const data = resource__folders.filter((rf: any) => {
            const folderId = rf.folder || null;
            return rf.resource === resourceId && props.folders[folderId];
          });
          if (!data.length) {
            ids.push(resourceId);
          }
        }
      });
      setNoAssociationIds(ids);
    } else {
      setNoAssociationIds([]);
    }
  };

  return (
    <>
      <Modal
        style={{ borderRadius: "0.25rem" }}
        visible={showDeleteAllModal}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={() => setShowDeleteAllModal(false)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={() => handleDeleteAll()}>
            Delete
          </Button>,
        ]}
      >
        <Card
          className={classes.modal}
          bodyStyle={{
            width: "85%",
            textAlign: "center",
            fontWeight: "bold",
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          bordered={false}
        >
          <h3 style={{ fontWeight: 600, fontSize: "17px", color: "#878787" }}>
            Are you sure you want to delete all the selected items?
          </h3>
          <p style={{ fontWeight: 600, fontSize: "15px" }}>
            This action cannot be undone
          </p>
        </Card>
      </Modal>
      {/* Folders */}
      {props.mode !== RESOURCE_EXPLORER_MODE.MODAL && (
        <div ref={foldersWrapperRef} className={classes.foldersWrapper}>
          {currentFolderType === RESOURCE_FOLDER_TYPE.FOLDER && (
            <div
              onClick={() => onClickGoBack()}
              className={classes.goBackFolder}
            >
              <ResourceExplorerFolder
                width={180}
                height={75}
                title={"Back"}
                selected={false}
                icon={["fas", "arrow-alt-circle-left"]}
              />
            </div>
          )}
          {currentFolderFolderFolders && currentFolderFolderFolders.length > 0 && (
            <>
              {currentFolderFolderFolders.map((f, index) => (
                <div
                  key={f.folder.id}
                  onClick={() => onClickSelectFolder(index)}
                  className={
                    index === selectedFolderIndex
                      ? classes.selectedFolder
                      : classes.folder
                  }
                >
                  <ResourceExplorerFolder
                    key={f.folder.id}
                    width={180}
                    height={75}
                    title={f.folder.name}
                    selected={index === selectedFolderIndex}
                    icon={["fas", "folder"]}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      )}

      {/* Thumbnail List and Buttons */}
      <div className={classes.wrapper}>
        {props.mode !== RESOURCE_EXPLORER_MODE.MODAL && (
          <div ref={actionsAndFilterRef}>
            <div id="actions">
              {/* Actions */}
              <div id="multi-action">
                <MultiFolderActions
                  selectedItemsCount={selectedItemsCount}
                  handleBulkMove={onClickMoveBulkResources}
                  handleBulkDelete={onClickDeleteBulkResources}
                  handleBulkInstance={null}
                  caller_folder={currentFolderFolderId}
                  checked_folders={[]}
                  maxWidth={props.width}
                  onOkay={null}
                  enabledActions={["move", "delete"]}
                />
              </div>

              {/* Edit Button */}
              <div id="top-buttons">
                <Button
                  id="edit-button"
                  onClick={onClickSwitchMode}
                  type="primary"
                  size={getButtonSize()}
                  style={{ width: "auto" }}
                >
                  {isComponentWidthFull ? (
                    <span>
                      {mode === MODE.SINGLE_SELECTION
                        ? "MULTIPLE SELECTION MODE"
                        : "SINGLE SELECTION MODE"}
                    </span>
                  ) : (
                    <Tooltip placement="bottom" title="MULTIPLE SELECTION MODE">
                      <FontAwesomeIcon
                        size="sm"
                        icon={["fas", "edit"]}
                        onClick={onClickSwitchMode}
                      />
                    </Tooltip>
                  )}
                </Button>
              </div>
            </div>
            <div style={{ padding: '10px', paddingTop: 0 }}>
              <Select
                mode="multiple"
                placeholder="Filter"
                style={{ width: '100%' }}
                options={[
                  { label: 'Without Association', value: 'association' },
                  { label: 'Video', value: 'video' },
                  { label: 'Audio', value: 'audio' },
                  { label: 'Image', value: 'image' },
                ]}
                onChange={handleFilter}
              />
            </div>
          </div>
        )}

        {/* Virtualized thumbnail list */}
        {(otherDivsHeight > 0 || props.mode === RESOURCE_EXPLORER_MODE.MODAL) && (
          <AutoSizer disableHeight>
            {({ width }) => {
              const columnCount = getColumnsBasedOnWidth();
              const thumbnailWidth = width / columnCount;

              const rowCount = Math.ceil(
                currentResourceResourceFolders.length / columnCount
              );

              // setDisableDeleteAll(!rowCount)

              return (
                <Grid
                  autoContainerWidth
                  cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                    const index = columnIndex + rowIndex * columnCount;
                    const resourceResourceFolder =
                      currentResourceResourceFolders[index];
                    if (!resourceResourceFolder) {
                      return null;
                    }

                    let selected = false;
                    if (mode === MODE.SINGLE_SELECTION) {
                      selected = resourceResourceFolder.resource.id === currentResourceResourceFolderIndex;
                    } else {
                      selected =
                        currentResourceResourceFolderIndexes.indexOf(index) >
                        -1;
                    }

                    return (
                      <div
                        className={selected ? classes.selectedItem : ""}
                        onClick={() =>
                          onClickSelectResourceResourceFolder(
                            resourceResourceFolder,
                            index,
                            rowIndex
                          )
                        }
                        key={key}
                        style={{ ...style, padding: 10 }}
                      >
                        <ResourceExplorerItem
                          mode={mode}
                          width={thumbnailWidth - 20}
                          height={thumbnailWidth - 20}
                          resourceResourceFolder={resourceResourceFolder}
                          selected={selected}
                        />
                      </div>
                    );
                  }}
                  columnCount={columnCount}
                  columnWidth={thumbnailWidth}
                  height={props.height - otherDivsHeight}
                  rowCount={rowCount}
                  rowHeight={thumbnailWidth}
                  width={width}
                  scrollToRow={props.rowToScroll}
                />
              );
            }}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default ResourceExplorerThumbsAndFolders;
