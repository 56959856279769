import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const glenwood_content: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.glenwood_content,
  display_name: "Glenwood Content",
  acceptable_folder_types: [
    FOLDER_TYPE.glenwood_attract_loop,
    FOLDER_TYPE.glenwood_content_donor_seas,
    FOLDER_TYPE.glenwood_grouping_folder,
    FOLDER_TYPE.glenwood_stories,
    FOLDER_TYPE.glenwood_story_group,
    FOLDER_TYPE.glenwood_baby_alerts,
    FOLDER_TYPE.glenwood_search
    // FOLDER_TYPE.glenwood_donor_list_group,
  ],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.content
    },
    closed: {
      ...tree_icon_types.content
    }
  },
  isContent: true
};
