import get from "lodash/get";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import React, { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { richTextHasValue } from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";

const BostonMediaWithQuote: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const baseFolder = props.data.folders.find(
    (f) => f.id === props.data.base_folder
  );

  const baseFolderType = props.data.folder_types.find(f => f.name === baseFolder.folder_type);
  const media_side = props.data.media_side || "left";

  const single_screen_width = props.containerWidth / 3;
  const single_screen_height = props.containerHeight / 2;
  const mediaFieldType = get(baseFolderType, "fields[media]", null);

  let cycleTime = get(props, 'cycle_time', get(baseFolder, "fields.cycle_time", 10) * 1000);
  if (cycleTime <= 5000) {
    cycleTime = 10000;
  }

  const [textVisible, setTextVisible] = useState(false);
  const [mediaVisible, setMediaVisible] = useState(true);

  const [converterBodyTextTop, setConverterBodyTextTop] = useState(null);
  const [converterBodyTextBottom, setConverterBodyTextBottom] = useState(null);
  const [converterAttributionText, setConverterAttributionText] = useState(null);

  const [timeoutHandler, _setTimeoutHandler] = useState(null);
  const timeoutHandlerRef = useRef(timeoutHandler);
  const setTimeoutHandler = data => {
    timeoutHandlerRef.current = data;
    _setTimeoutHandler(data);
  }

  const handleCycle = () => {
    clearTimeout(timeoutHandlerRef.current);
    const newTimeoutHandler = setTimeout(() => {
      props.handleEndOfPlay();
    }, cycleTime);
    setTimeoutHandler(newTimeoutHandler);
  }
  
  useEffect(() => {
    if (props.handleEndOfPlay) {
      handleCycle();
    }
  }, []);

  useEffect(() => {
    setConverterBodyTextTop(
      new QuillDeltaToHtmlConverter(
        get(baseFolder, "fields.body_text_top.ops", []),
        {}
      )
    );
    setConverterBodyTextBottom(
      new QuillDeltaToHtmlConverter(
        get(baseFolder, "fields.body_text_bottom.ops", []),
        {}
      )
    );
    setConverterAttributionText(
      new QuillDeltaToHtmlConverter(
        get(baseFolder, "fields.attribution_text.ops", []),
        {}
      )
    );
  }, []);

  useEffect(() => {
    const textFadeIn = setTimeout(() => {
      setTextVisible(true);
    }, 2000);
    return () => clearTimeout(textFadeIn);
  }, []);

  useEffect(() => {
    const textFadeOut = setTimeout(() => {
      setTextVisible(false);
    }, cycleTime * 1000 - 2000);
    return () => clearTimeout(textFadeOut);
  }, []);

  useEffect(() => {
    const mediaFadeOut = setTimeout(() => {
      setMediaVisible(false);
    }, cycleTime * 1000 - 1000);
    return () => clearTimeout(mediaFadeOut);
  }, []);

  const convertRichText = (convertor) => {
    let html = convertor ? convertor.convert() : "";
    return html;
  };

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={mediaVisible.toString()}
          timeout={1000}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          {mediaVisible ? (
            <div
              style={{
                position: "absolute",
                left: media_side === "left" ? 0 : single_screen_width,
                top: 0,
                width: single_screen_width * 2,
                height: single_screen_height * 2
              }}
            >
              <Slide1911
                aws={props.aws}
                aws_bucket={props.aws_bucket}
                mode={props.mode}
                containerWidth={single_screen_width * 2}
                containerHeight={single_screen_height * 2}
                data={{
                  ...props.data,
                  slotNames: ["media"],
                  placeholder: "Image",
                  fieldType: mediaFieldType,
                  showPlaceholder: true,
                  // positionRelative: false,s
                }}
                webview_signedurls={props.webview_signedurls}
              />
            </div>
          ) : (
            <div></div>
          )}
        </CSSTransition>
      </TransitionGroup>
      <TransitionGroup>
        <CSSTransition
          key={textVisible.toString()}
          timeout={1000}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          {textVisible ? (
            <div
              style={{
                position: "absolute",
                left: media_side === "left" ? single_screen_width * 2 : 0,
                top: 0,
                width: single_screen_width * 1,
                height: single_screen_height
              }}
            >
              <div
                style={{
                  position: "relative",
                  paddingTop: single_screen_width * 0.05,
                  paddingRight: single_screen_width * 0.05,
                  paddingLeft: single_screen_width * 0.05,
                  height: single_screen_height * 0.90,
                  overflowY: 'hidden',
                  display: 'flex',
                  alignItems: 'flex-end'

                }}
              >
                <RichTextRender
                  style={{ maxHeight: single_screen_height * 0.90, overflowY: 'hidden' }}
                  containerHeight={props.containerHeight}
                  htmlString={convertRichText(converterBodyTextTop)}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </CSSTransition>
      </TransitionGroup>
        
      <TransitionGroup>
        <CSSTransition
          key={textVisible.toString()}
          timeout={1000}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          {textVisible ? (
            <div
              style={{
                position: "absolute",
                left: media_side === "left" ? single_screen_width * 2 : 0,
                top: single_screen_height,
                width: single_screen_width * 1,
                height: single_screen_height
              }}
            >
              <div
                style={{
                  position: "relative",
                  padding: single_screen_width * 0.05
                }}
              >
                <RichTextRender
                  style={{ maxHeight: single_screen_height * 0.90, overflowY: 'hidden' }}
                  containerHeight={props.containerHeight}
                  htmlString={convertRichText(converterBodyTextBottom)}
                />

                {richTextHasValue(get(baseFolder, "fields.attribution_text.ops", [])) && richTextHasValue(get(baseFolder, "fields.body_text_bottom.ops", [])) && (
                  <div style={{ height: single_screen_height * 0.1 }} />
                )}

                <RichTextRender
                  style={{ maxHeight: single_screen_height * 0.90, overflowY: 'hidden' }}
                  containerHeight={props.containerHeight}
                  htmlString={convertRichText(converterAttributionText)}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default BostonMediaWithQuote;
