import { createUseStyles } from "react-jss";
import { ICON_FOLDER_PADDING, ICON_FOLDER_WIDTH } from "../constants";

const useStyles = createUseStyles({
  folder: {
    width: (props: any) => props.width,
    height: (props: any) => props.height,
    border: "1px solid #CCC",
    display: "flex",
    alignItems: "center",
    "& #icon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: ICON_FOLDER_WIDTH,
      padding: ICON_FOLDER_PADDING,
      float: "left",
      color: (props: any) => (props.selected ? "white" : "#2699FB"),
      height: "100%"
    },
    "& #text": {
      color: (props: any) => (props.selected ? "white" : "inherit"),
      display: "-webkit-box",
      "-webkitLineClamp": 3,
      "-webkitBoxOrient": "vertical",
      overflow: "hidden",
      width: (props: any) => props.width - ICON_FOLDER_WIDTH - ICON_FOLDER_PADDING * 2
    }
  }
});

export default useStyles;