import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const everett_donor_sea_lists: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.everett_donor_sea_lists,
  display_name: "Donor Sea Lists",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_sea_list],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    // #region General

    name: {
      type: "text",
      title: "Name",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
      },
    },

    // #endregion
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
  },
};
