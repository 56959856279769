import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const mckenzie_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.mckenzie_display,
  display_name: "McKenzie Display",
  prevent_add_children: true,
  acceptable_folder_types: [FOLDER_TYPE.mckenzie_mobile],
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  hide_nested_children: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  },
  webview: {
    use_child_folder_type: FOLDER_TYPE.mckenzie_mobile
  },
  object_recognition_settings: true,
  is_responsive: true
};
