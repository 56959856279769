import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../interfaces/main-state.interface";
import { IFolderArModel } from "../../shared-global/interfaces/models/folder_ar_model.interface";
import JsonListView from "../list_view/JsonListView";
import get from "lodash/get";
import TextInput from "../form/input/TextInput";
import FieldWrapper from "../form/FieldWrapper";

interface IObjectRecognitionSettingsFormProps {
  updateFolderArModel: any;
}

const ObjectRecognitionSettingsForm: React.FC<IObjectRecognitionSettingsFormProps> = (props) => {
  const [currentFolderArModel, setCurrentFolderArModel] =
    useState<IFolderArModel>(null);

  const selected_folder_id = useSelector(
    (state: IState) => state.client_options.applied_content_selected_folder
  );
  const folder_ar_models = useSelector(
    (state: IState) => state.data.folder_ar_models
  );
  const [folderArModel, setFolderArModel] = useState<IFolderArModel>(null);
  const client_options = useSelector(
    (state: IState) => state.client_options
  );

  const [currentDetectionSettings, setCurrentDectectionSettings] = useState([]);

  useEffect(() => {
    if (selected_folder_id) {
      const folderArModels = Object.values(folder_ar_models).filter(
        (f) => f.folder === selected_folder_id
      );
      if (folderArModels.length) {
        setFolderArModel(folderArModels[0]);
        if (!currentFolderArModel) {
          setCurrentFolderArModel(folderArModels[0]);
          setCurrentDectectionSettings(get(folderArModels[0], 'contents.detection_settings', []));
        }
      }
    }
  }, [folder_ar_models]);

  const updateCurrentFolderArModel = (fieldName, fieldValue, cb) => {
    const newFolderArModel = {
      ...currentFolderArModel,
      [fieldName]: fieldValue
    };
    setCurrentFolderArModel(newFolderArModel);
    props.updateFolderArModel({ ...newFolderArModel, folder_id: selected_folder_id });

    if (cb) {
      cb();
    }
  };

  return (
    <>
      <FieldWrapper>
        <TextInput
          showLabel
          fieldName="phrase"
          defaultValue={currentFolderArModel?.phrase}
          title="Phrase"
          onChange={updateCurrentFolderArModel}
          canUpdate
          onBlur={null}
          showCharCount={false}
        />
      </FieldWrapper>

      <FieldWrapper>
        <TextInput
          showLabel
          fieldName="model_url"
          defaultValue={currentFolderArModel?.model_url}
          title="Model Url"
          onChange={updateCurrentFolderArModel}
          canUpdate
          onBlur={null}
          showCharCount={false}
        />
      </FieldWrapper>

      <JsonListView
        key="detection_settings"
        showAddButton={true}
        listHeight={200}
        listItems={currentDetectionSettings}
        listColumns={[
          {
            type: "text",
            title: "Detection Word",
            field: "detection_word",
            sort: true,
            editable: true
          },
          {
            type: "text",
            title: "Detection Url",
            field: "detection_url",
            sort: true,
            editable: true
          },
          {
            type: "integer",
            title: "Accuracy",
            field: "accuracy",
            sort: true,
            editable: true
          }
        ]}
        updateBulk={(items, onSuccess, onError) => {
          const newContents = { ...currentFolderArModel.contents, detection_settings: items };
          onSuccess();
          updateCurrentFolderArModel("contents", newContents, () => {
            props.updateFolderArModel({ ...currentFolderArModel, contents: newContents, folder_id: selected_folder_id });
          });
        }}
        notifications={{ update: false }}
        parentFolder={null}
        recalculateWidth={[
          client_options.detail_drawer_open,
          client_options.tree_panel_open
        ]}
        sorting="other"
        applied_content_tree_expanded={false}
        client_options={client_options}
        moveBulk={() => {}}
        instanceBulk={() => {}}
        deleteBulk={() => {}}
        setUpdateListViewTouch={() => {}}
        setClientOptions={() => {}}
        toggleFolderExpanded={() => {}}
        setFoldersExpanded={() => {}}
        aws_instance={null}
        resources={{}}
        resource__folders={{}}
        folder__status={{}}
        users={{}}
        folderTypes={[]}
      />
    </>
  );
};

export default ObjectRecognitionSettingsForm;
