import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import DonorList2107 from "../../standard/DonorList2107";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import { getFontSizeInEm } from "../../../utils/screenUtils";
import styles from "./styles";
import { useScale } from "../../../hooks/useScale";
import { useFolder } from "../../../hooks/useFolder";

const useStyles = createUseStyles(styles);
const fontColorDefault = "#000000";

const TangentDonorList = (props) => {
  const [donorListHeight, setDonorListHeight] =
    useState(63.0952380952380952381);
  const [headerDivElement, setHeaderDivElement] = useState(null);
  const [donorListDivElement, setDonorListDivElement] = useState(null);

  const {
    getBaseFolder,
    getBaseFolderFolderType,
    getBaseFolderFieldValue,
    getFolderCycleTime,
  } = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const {
    getBaseFolderRichTextStylesFn,
    standardSizing,
    getUnitBasedOnHeight,
  } = useScale(props.data.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();    

  const header = getBaseFolderFieldValue("header", baseFolderType);
  const category = getBaseFolderFieldValue("category", baseFolderType);
  const description = getBaseFolderFieldValue("description", baseFolderType);
  const footer = getBaseFolderFieldValue("footer", baseFolderType);
  const donorListNamesStyle = getBaseFolderFieldValue("list_names", {});
  const backgroundColor = getBaseFolderFieldValue(
    "color_overlay",
    "transparent"
  );
  const donorNameFontSize = getFontSizeInEm(
    get(baseFolder, "fields.list_names.fontSize", 16)
  );
  const donorListBackgroundColor = getBaseFolderFieldValue(
    "donor_list_background_color",
    "transparent"
  );

  useEffect(() => {
    const divListHeight = donorListDivElement?.getBoundingClientRect().height;
    const containerMarginTop = get(
      baseFolder,
      "fields.container_margin_top",
      0
    );
    const effectiveDonorListHeight =
      divListHeight - (divListHeight / 100) * containerMarginTop;
    setDonorListHeight(effectiveDonorListHeight);
  }, [donorListDivElement?.getBoundingClientRect().height]);

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    donorListBackgroundColor,
    horizontalRuleColor: getBaseFolderFieldValue(
      "horizontal_rule_color",
      "#000000"
    ),
    horizontalRuleHeight: `${Math.ceil(
      (getBaseFolderFieldValue("horizontal_rule_size", 0.1) / 100) *
        props.containerHeight
    )}px`,
    standardSizing,
    getUnitBasedOnHeight,
  });

  return (
    <div className={classes.donorListContainer}>
      <div className={classes.donorListContainer}>
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          data={{
            ...props.data,
            slotNames: ["background"],
          }}
        />
      </div>
      {/* Background Overlay */}
      <div className="backgroundOverlay" />

      <div className={classes.donorListContent}>
        <div className="workspaceContainer">
          <div ref={(divElement) => { setHeaderDivElement(divElement) }} className="header">
            {/* Header */}
            <div className={`headerText`}>
              <RichTextRender
                htmlString={header}
                className={classes.noPadding}
              />
            </div>

            {/* Description */}
            <div className="description">
              <div
                className={`descriptionText`}
              >
                <RichTextRender
                  htmlString={description}
                  className={classes.noPadding}
                />
              </div>
            </div>

            <div
              className={`categoryText`}
            >
              <RichTextRender
                htmlString={category}
                className={classes.noPadding}
              />
            </div>
          </div>
          {/* end description */}

          {getBaseFolderFieldValue("show_upper_rule", true) && (
            <div>
              <hr className="horizontalRule" />
            </div>
          )}

          {/* Donor list container */}
          <div
            ref={(divElement) => {
              setDonorListDivElement(divElement);
            }}
            className="spring-donorlist-container"
            style={{
              color:
                props.inheritable_fields?.donor_list_font_color ||
                fontColorDefault,
              flexGrow: 1,
            }}
          >
            <div
              style={{
                minHeight: "100%",
              }}
            >
              <div
                className="donor-list-container"
                style={{
                  position: "relative",
                }}
              >
                {headerDivElement && donorListDivElement && (
                  <DonorList2107
                    {...props}
                    containerWidth={props.containerWidth * (1 - 232 / 1080)}
                    containerHeight={donorListHeight}
                    data={{
                      ...props.data,
                      componentSpecificData: {
                        list_style: getBaseFolderFieldValue(
                          "donor_list_style",
                          "filled"
                        ),
                        gapOptions: {
                          showGap: true,
                          gapPositions: [((donorListDivElement.getBoundingClientRect().height - headerDivElement.getBoundingClientRect().height) / 2) + 10],
                          listOffset: 0,
                          gapHeight: props.refHeight * (get(baseFolder, "fields.list_margin_bezel", 0) / 100)
                        },
                        columns: getBaseFolderFieldValue("columns", 2),
                        container_margin_top: getBaseFolderFieldValue(
                          "container_margin_top",
                          0
                        ),
                        donor_list_margin_left: getBaseFolderFieldValue(
                          "donor_list_margin_left",
                          0
                        ),
                        donor_list_margin_right: getBaseFolderFieldValue(
                          "donor_list_margin_right",
                          0
                        ),
                        donor_list_column_margin_right: getBaseFolderFieldValue(
                          "donor_list_column_margin_right",
                          0
                        ),
                        cycle: getFolderCycleTime(baseFolder),
                        donor_vertical_separation: get(baseFolder, "fields.donor_name_margin_bottom", 2.5),
                        donor_line_height: get(baseFolder, "fields.list_names.lineHeight", 1),
                        text_alignment: get(
                          donorListNamesStyle,
                          "alignment",
                          "left"
                        ),
                        donor_line_height_in_percentage: false,
                        donorNameStyle: {
                          fontFamily: get(donorListNamesStyle, "font", "Lato"),
                          fontSize: donorNameFontSize,
                          color: get(
                            donorListNamesStyle,
                            "color",
                            fontColorDefault
                          ),
                          fontWeight: get(donorListNamesStyle, "bold", false)
                            ? "bold"
                            : "normal",
                          textDecoration: get(
                            donorListNamesStyle,
                            "bold",
                            false
                          )
                            ? "underline"
                            : "normal",
                          fontStyle: get(donorListNamesStyle, "italic", false)
                            ? "italic"
                            : "normal",
                          textAlign: get(
                            donorListNamesStyle,
                            "alignment",
                            "left"
                          ),
                          lineHeight: get(donorListNamesStyle, "lineHeight", 1),
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {getBaseFolderFieldValue("show_lower_rule", true) && (
            <div>
              <hr className="horizontalRule" />
            </div>
          )}
          <div className="footer">
            <div className={`footerText ${richTextClasses.componentContainer}`}>
              <RichTextRender htmlString={footer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TangentDonorList;
