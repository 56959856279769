export default {
  field_groups: {
    default: {
      title: 'General',
      default_expanded: true,
    },
  },
  fields: {
    name: {
      type: 'text',
      title: 'Name',
      validation: { rules: [{ name: 'isRequired' }] },
      default: ''
    },
    rich_text: { 
      type: 'wysiwyg', 
      title: 'Rich Text', 
      validation: { rules: [] }, 
      default: null 
    }
  }
};