import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuditLogTable from '../audit_logs/AuditLogTable';
import { get_audit_logs } from '../../actions/auditLogActions';
import { getAllUser } from '../../actions/userActions';
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from '../../hooks/withRouter';

class CompanyAuditLog extends Component {

  constructor(props) {
    super(props);
    const company_id = this.props.params.companyId
    this.props.get_audit_logs(company_id, this.get_audit_logs_success, this.api_failure);
    if (!props.users) {
      this.props.get_all_users(company_id, this.get_users_success, this.api_failure);
    }
    this.state = {
      audit_logs_obtained: false,
      users_obtained: props.users ? true : false
    }
  }

  get_audit_logs_success = () => {
    this.setState({
      audit_logs_obtained: true
    })
  }

  get_users_success = () => {
    this.setState({
      users_obtained: true
    })
  }

  api_failure = (detail) => {
    AlertNotification('error', 'Error', detail || 'An error ocurred obtaining information from the server')
  }

  render() {
    if (this.state.audit_logs_obtained && this.state.users_obtained) {
      this.props.audit_logs.forEach(x => {
        const current_user = this.props.users[x.user]
        x['user_name'] = current_user ? current_user.first_name + ' ' + current_user.last_name : '';
        x['email'] = current_user ? current_user.email : '';
      });
    }
    return (
      <div>
        {
          this.state.audit_logs_obtained && this.state.users_obtained ?
          <AuditLogTable dataSource={this.props.audit_logs} /> :
            <span>Loading...</span>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if(state.data.audit_logs) {
    return {
      audit_logs: Object.values(state.data.audit_logs),
      users: state.data.users
    }
  }
  return {};
}

const mapDispatchToProps = (dispatch) => ({
  get_audit_logs: (company_id, onSuccess, onFail) => {
    dispatch(get_audit_logs(company_id, onSuccess, onFail))
  },
  get_all_users: (company_id, onSuccess, onFail) => {
    dispatch(getAllUser(company_id, onSuccess, onFail))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyAuditLog));