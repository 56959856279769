import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { marin_content } from "./marin_content";
import { marin_content_stories } from "./marin_content_stories";
import { marin_content_layouts } from "./marin_content_layouts";
import { marin_layout } from "./marin_layout";
import { marin_display } from "./marin_display";
import { marin_displays } from "./marin_displays";
import { marin_fullscreen_fact_story } from "./marin_fullscreen_fact_story";
import { marin_main_display } from "./marin_main_display";
import { marin_media_blank_1 } from "./marin_media_blank_1";
import { marin_media_blank_2 } from "./marin_media_blank_2";
import { marin_media_blank_3 } from "./marin_media_blank_3";
import { marin_media_blank_4 } from "./marin_media_blank_4";
import { marin_media_blank_5 } from "./marin_media_blank_5";
import { marin_media_blank_6 } from "./marin_media_blank_6";
import { marin_media_blank_7 } from "./marin_media_blank_7";
import { marin_media_blank_8 } from "./marin_media_blank_8";
import { marin_presentations } from "./marin_presentations";
import { marin_presentation_1x1 } from "./marin_presentation_1x1";
import { marin_presentation_5x1 } from "./marin_presentation_5x1";
import { marin_root } from "./marin_root";
import { marin_story_fact } from "./marin_story_fact";
import { marin_story_media_with_quote } from "./marin_story_media_with_quote";
import { marin_story_with_fact } from "./marin_story_with_fact";
import { marin_profile_with_media_story } from "./marin_profile_with_media_story";
import { marin_story_with_media } from "./marin_story_with_media";
import { marin_intro_story } from "./marin_intro_story";
import { marin_control_panel_attract_loop } from "./marin_control_panel_attract_loop";
import { marin_control_panel_attract_loop_slide } from "./marin_control_panel_attract_loop_slide";
import { marin_content_attract_loops } from "./marin_content_attract_loops";
import { marin_story_quote } from "./marin_story_quote";
import { marin_donor_list } from "./marin_donor_list";
import { marin_content_donor_lists } from "./marin_content_donor_lists";
import { marin_donor } from "./marin_donor";
import { marin_story_media_with_quote_2_1 } from "./marin_story_media_with_quote_2_1";
import { marin_story_large_media_with_quote } from "./marin_story_large_media_with_quote";

export const marin_types: { [key: string]: IFolderType } = {
  marin_root,
  marin_displays,
  marin_presentations,
  marin_content: marin_content,
  marin_display,
  marin_presentation_5x1,
  marin_presentation_1x1,
  marin_content_stories,
  marin_content_layouts,
  marin_layout,
  marin_story_media_with_quote,
  marin_story_with_fact,
  marin_fullscreen_fact_story,
  marin_story_with_media,
  marin_story_fact,
  marin_profile_with_media_story,
  marin_main_display,
  marin_media_blank_1,
  marin_media_blank_2,
  marin_media_blank_3,
  marin_media_blank_4,
  marin_media_blank_5,
  marin_media_blank_6,
  marin_media_blank_7,
  marin_media_blank_8,
  marin_intro_story,
  marin_control_panel_attract_loop,
  marin_control_panel_attract_loop_slide,
  marin_content_attract_loops,
  marin_story_quote,
  marin_donor_list,
  marin_donor,
  marin_content_donor_lists,
  marin_story_media_with_quote_2_1,
  marin_story_large_media_with_quote
};
