import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import { bestFit } from "../../shared/utils/screenUtils";
import SelectInput from "../form/input/SelectInput";
import { PreviewContext, MobilePreviewContext } from "../../shared/Contexts";

interface IDevicePreviewWrapper {
  children?: any;
  width: number;
  height: number;
}

const barHeight = 55;
const options = [
  { title: "IPhone SE", value: "iphone_se" },
  { title: "IPhone 6/6s/6 plus/7/8", value: "iphone_6_6s_6_plus_7_8" },
  { title: "IPhone 7 plus/8 plus", value: "iphone_7plus_8_plus" },
  { title: "IPhone X", value: "iphone_x" },
  {
    title: "IPad Mini 4/Air 2/Pro (9.7 inch)",
    value: "ipad_mini_4_air2_pro"
  },
  { title: "IPad Pro (12.9 inch)", value: "ipad_pro_12" },
  { title: "IPad Pro (10.5 inch)", value: "ipad_pro_10" },
  {
    title: "Samsung Galaxy S8 / S8 Plus / S9 / S9 Plus",
    value: "samsung_galaxy_s9"
  },
  { title: "Xioami Redmi Note 7/8/8 Pro/9", value: "xiaomi_7_8_9" },
  { title: "WXGA 1280X800", value: "wxga_1280X800" },
  { title: "WUXGA 1920X1200", value: "wuxga_1920x1200" },
  { title: "1080p 1920x1080", value: "1080p_1920x1080" },
  { title: "720p 1280x720", value: "720p_1280x720" },
];
const resolutions = [
  {
    name: "iphone_se",
    width: 320,
    height: 568
  },
  {
    name: "iphone_6_6s_6_plus_7_8",
    width: 375,
    height: 667
  },
  {
    name: "iphone_7plus_8_plus",
    width: 414,
    height: 736
  },
  {
    name: "iphone_x",
    width: 375,
    height: 812
  },
  {
    name: "ipad_mini_4_air2_pro",
    width: 768,
    height: 1024
  },
  {
    name: "ipad_pro_12",
    width: 1024,
    height: 1366
  },
  {
    name: "ipad_pro_10",
    width: 1112,
    height: 834
  },
  {
    name: "samsung_galaxy_s9",
    width: 360,
    height: 740
  },
  {
    name: "xiaomi_7_8_9",
    width: 360,
    height: 780
  },
  {
    name: "wxga_1280X800",
    width: 1280,
    height: 800,
  },
  {
    name: "wuxga_1920x1200",
    width: 1920,
    height: 1200,
  },
  {
    name: "1080p_1920x1080",
    width: 1920,
    height: 1080,
  },
  {
    name: "720p_1280x720",
    width: 1280,
    height: 720,
  }
];

enum ORIENTATION {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape"
}

const DevicePreviewWrapper: React.FC<IDevicePreviewWrapper> = (props) => {
  const [orientation, setOrientation] = useState(ORIENTATION.PORTRAIT);
  const [selectedDevice, setSelectedDevice] = useState(options[0].value);
  const [currentRotation, setCurrentRotation] = useState(0);

  const previewContext = useContext(PreviewContext);

  const resolution = useMemo(() => {
    return resolutions.find((r) => r.name === selectedDevice);
  }, [selectedDevice]);

  const bestFitDims = useMemo(() => {
    const { width, height } = resolution;
    const aspectRatio =
      orientation === ORIENTATION.PORTRAIT ? width / height : height / width;
    return bestFit(aspectRatio, 1, props.width, props.height - barHeight);
  }, [props.width, props.height, resolution, orientation]);

  const scaleFactor = bestFitDims.width / resolution.width;

  const toggleOrientation = () => {
    if (orientation === ORIENTATION.PORTRAIT) {
      setOrientation(ORIENTATION.LANDSCAPE);
    } else {
      setOrientation(ORIENTATION.PORTRAIT);
    }
  };

  useEffect(() => {
    if (!(currentRotation === 0 && orientation === ORIENTATION.PORTRAIT)) {
      if (orientation === ORIENTATION.PORTRAIT) {
        setCurrentRotation(0);
      } else {
        setCurrentRotation(-90);
      }
    }
  }, [orientation]);

  return (
    <div style={{ display: "block" }}>
      <div
        style={{
          height: barHeight,
          width: props.width,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          background: "#2699FB",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <span style={{ transform: `rotate(${currentRotation}deg)` }}>
          <FontAwesomeIcon className="fa-lg" icon="mobile-alt" />
        </span>
        &nbsp;&nbsp;
        <SelectInput
          settings={{ minWidth: 150 }}
          fieldName="devices"
          defaultValue={selectedDevice}
          title="Devices"
          showLabel={false}
          canUpdate={true}
          options={options}
          onChange={(_fName, value) => setSelectedDevice(value)}
          onBlur={() => { }}
        />
        &nbsp; &nbsp;
        <span style={{ cursor: "pointer" }} onClick={() => toggleOrientation()}>
          <FontAwesomeIcon className="fa-lg" icon="sync" />
        </span>
      </div>
      <div
        style={{
          height: props.height - barHeight,
          width: props.width,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          key={`DevicePreviewWrapper-${bestFitDims.width}-${bestFitDims.height}`}
          style={{
            height: bestFitDims.height,
            width: bestFitDims.width,
            borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgray'
          }}
        >
          <MobilePreviewContext.Provider
            key={`MobilePreviewContext-${bestFitDims.width}-${bestFitDims.height}`}
            value={{
              ...previewContext,
                data: {
                ...previewContext.data,
                containerWidth: bestFitDims.width,
                containerHeight: bestFitDims.height,
                componentSpecificData: {
                  ...previewContext.data?.componentSpecificData,
                  scale_factor: scaleFactor,
                  resolution: bestFitDims,
                  orientation,
                },
              },
            }}
          >
            {props.children}
          </MobilePreviewContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default DevicePreviewWrapper;
