import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const phoenix_content_checkerboards: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.phoenix_content_checkerboards,
  display_name: "Checkerboards",
  acceptable_folder_types: [FOLDER_TYPE.phoenix_checkerboard],
  tabs: {
    general: {
      title: "General",
    },
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general",
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
      },
      default: null,
      show_char_count: false,
      field_group: "general",
    },
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    screen_sets: [
      { name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 },
    ],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25,
    },
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"],
    },
    {
      title: "SAVE",
      action: "just_save",
    },
  ],
};
