import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { naples_content } from "./naples_content";
import { naples_display } from "./naples_display";
import { naples_displays } from "./naples_displays";
import { naples_fullscreen_media } from "./naples_fullscreen_media";
import { naples_presentations } from "./naples_presentations";
import { naples_root } from "./naples_root";
import { naples_presentation_1x3 } from "./naples_presentation_1x3";
import { naples_content_stories } from "./naples_content_stories";
import { naples_template_red_001 } from "./naples_template_red_001";
import { naples_template_blue_001 } from "./naples_template_blue_001";
import { naples_template_red_002 } from "./naples_template_red_002";
import { naples_template_blue_002 } from "./naples_template_blue_002";
import { naples_content_donor_lists } from "./naples_content_donor_lists";
import { naples_fund_giving_level } from "./naples_content_fund_giving_level";
import { naples_fund_folder_red } from "./naples_fund_folder_red";
import { naples_fund_folder_blue } from "./naples_fund_folder_blue";
import { naples_donor } from "./naples_donor";

export const naples_types: { [key: string]: IFolderType } = {
  naples_root,
  naples_fullscreen_media,
  naples_displays,
  naples_presentations,
  naples_content,
  naples_display,
  naples_presentation_1x3,
  naples_content_stories,
  naples_template_red_001,
  naples_template_blue_001,
  naples_template_red_002,
  naples_template_blue_002,
  naples_content_donor_lists,
  naples_fund_folder_red,
  naples_fund_folder_blue,
  naples_donor,
  naples_fund_giving_level
};
