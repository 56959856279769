import React from "react";
import { Tooltip } from "antd";
import { MD_BLUE } from "../../layout/LayoutConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function convertTooltip(tooltip) {
  if (Array.isArray(tooltip)) {
    return (
      <span>
        {tooltip.map((el, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: el }}></div>
        ))}
      </span>
    );
  } else {
    return tooltip;
  }
}

export type InputTooltipProps = { tooltip: string | string[] };

export default function InputTooltip(props: InputTooltipProps) {
  return (
    <Tooltip title={convertTooltip(props.tooltip)}>
      <span>
        <span
          style={{
            display: "inline-block",
            width: 5,
          }}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer", color: MD_BLUE }}
          icon={["fas", "question-circle"]}
        />
      </span>
    </Tooltip>
  );
}
