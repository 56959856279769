import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
  FOLDER_CATEGORY
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { sorting_options } from "../standardized_fields";
import { list_styles, naplesFonts, quillFontSizes, textStyleOptions, transitions, wysiwyg } from "./naples_settings";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";

export const naples_fund_folder_red: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  name: FOLDER_TYPE.naples_fund_folder_red,
  display_name: "Fund - Red",
  acceptable_folder_types: [FOLDER_TYPE.naples_donor],
  tabs: {
    main: {
      title: "Main"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "main"
    },
    top_screen: {
      title: "Top Screen",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "main"
    },
    middle_screen: {
      title: "Middle Screen",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "main"
    },
    list_settings: {
      title: "List Settings",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "main"
    },
    bottom_screen: {
      title: "Bottom Screen",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "main"
    }
  },
  fields: {
    //top screen
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "general",
      default: 5
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    header: {
      title: "Header",
      field_group: "top_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.BOLD],
            size: "60px",
            color: "#70263f",
            bold: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background_color: {
      type: "color",
      title: "Header Subhead Background Color",
      default: "#c5aa75",
      field_group: "top_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background_image_top: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 48 / 23,
      recommended_size: {
        width: 1920,
        height: 920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 48 / 23
      },
      field_group: "top_screen"
    },
    color_overlay_top: {
      type: "color",
      title: "Background Overlay",
      default: "#c5aa75",
      field_group: "top_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    //middle screen
    list_header: {
      title: "List Header",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.MEDIUM],
            size: "32px",
            color: "#c5aa75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    list_description: {
      title: "List Description",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.LIGHT],
            size: "24px",
            color: "#c5aa75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      field_group: "middle_screen"
    },
    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      field_group: "middle_screen"
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#c5aa75",
      field_group: "middle_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.125,
      field_group: "middle_screen"
    },
    list_category: {
      title: "List Category",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.MEDIUM],
            size: "24px",
            color: "#c5aa75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    list_footer: {
      title: "List Footer",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts,
        crossButton: true
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.LIGHT],
            size: "20px",
            color: "#c5aa75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    background_image_middle: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "middle_screen"
    },
    color_overlay_middle: {
      type: "color",
      title: "Background Color",
      default: "#70263f",
      field_group: "middle_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    text_alignment: {
      type: "select",
      title: "Donor List Text Alignment",
      validation: { rules: [{ name: "isRequired" }] },
      default: "left",
      options: [
        {
          title: "Left",
          value: "left"
        },
        {
          title: "Center",
          value: "center"
        },
        {
          title: "Right",
          value: "right"
        }
      ],
      field_group: "list_settings"
    },
    name_list: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#c5aa75",
        alignment: "left",
        font: fonts[FontsFamilies.GOTHAM][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "list_settings"
    },
    columns: {
      title: "Columns",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 10 }] },
      default: 3,
      field_group: "list_settings"
    },
    description_margin_top: {
      title: "Description Margin Top",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 5,
      field_group: "list_settings"
    },
    container_margin_top: {
      title: "Container Margin Top",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    container_margin_left: {
      title: "Container Margin Left",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    container_height: {
      title: "Donor List Container Height",
      type: "integer",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 100,
      field_group: "list_settings"
    },
    container_width: {
      title: "Donor List Container Width",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 100,
      field_group: "list_settings"
    },
    donor_vertical_separation: {
      title: "Donor Vertical Separation",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.25,
      field_group: "list_settings"
    },
    donor_line_space: {
      title: "Donor Line Space",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.125,
      field_group: "list_settings"
    },
    donor_list_style: {
      type: "select",
      title: "List Style",
      field_group: "list_settings",
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles
    },
    //bottom screen
    background_image_bottom: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "bottom_screen"
    },
    color_overlay_bottom: {
      type: "color",
      title: "Background Overlay",
      default: "#c5aa75",
      field_group: "bottom_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    sorting_options: {
      ...sorting_options,
      field_group: "general",
      default: "sorting_order"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.naples_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.naples_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.naples_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.naples_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name", "sorting_order"]
  },
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "1x3", orientation: "landscape", horizontal: 1, vertical: 3 }],
    hide_border: true,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/naples/fund-red.svg",
    element_type: FOLDER_TYPE_SELECTOR.select
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false
};
