import {
  ADD_ROLE_USER,
  ADD_USER_COMPANY,
  CHANGE_COMPANY,
  CHANGE_PLACEHOLDER_CONFIGURATION,
  COMPANYUSER_ADD,
  COMPANYUSER_UPDATE,
  DELETE_ROLE_USER,
  DELETE_USER,
  GET_ALLUSER,
  GET_ALLUSERS_ALLCOMPANIES,
  GET_SINGLEUSER,
  REMOVE_USER_COMPANY,
  RESEND_SET_USER_PASSWORD_EMAIL,
  UPDATE_PERSISTENT_SETTINGS,
  USER_ACC_UPDATE,
  USER_CREATE,
  USER_FETCH,
  USER_GET_DATA,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_REQUEST,
  USER_REGISTER,
  USER_UPDATE
} from "./actionTypes";

export const getSingleUser = (userId, onSuccess?, onFail?) => ({
  type: GET_SINGLEUSER,
  payload: { id: userId },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getAllUser = (companyId, onSuccess?, onFail?) => ({
  type: GET_ALLUSER,
  payload: { company: companyId },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getAllUserOfAllCompanies = (onSuccess?, onFail?) => ({
  type: GET_ALLUSERS_ALLCOMPANIES,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const companyUserUpdate = (
  id,
  first_name,
  last_name,
  onSuccess?,
  onFail?
) => ({
  type: COMPANYUSER_UPDATE,
  payload: {
    id: id,
    first_name: first_name,
    last_name: last_name
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const companyUserAdd = (
  companyId,
  first_name,
  last_name,
  email,
  onSuccess?,
  onFail?
) => ({
  type: COMPANYUSER_ADD,
  payload: {
    first_name: first_name,
    last_name: last_name,
    company: Number(companyId),
    email: email
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const roleUserAdd = (
  role,
  user,
  project,
  company,
  onSuccess?,
  onFail?
) => ({
  type: ADD_ROLE_USER,
  payload: {
    role,
    user,
    project,
    company
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteRoleUser = (id, onSuccess?, onFail?) => ({
  type: DELETE_ROLE_USER,
  payload: { id: id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

// previous user module
export const userUpdate = (
  id,
  first_name,
  last_name,
  restaurants,
  departments,
  current_password,
  new_password,
  onSuccess?,
  onFail?
) => ({
  type: USER_UPDATE,
  payload: {
    id: id,
    first_name: first_name,
    last_name: last_name,
    restaurants: restaurants,
    departments: departments,
    current_password: current_password,
    new_password: new_password
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userCreate = (
  first_name,
  last_name,
  email,
  restaurants,
  departments,
  roles,
  onSuccess?,
  onFail?
) => ({
  type: USER_CREATE,
  payload: {
    first_name: first_name,
    last_name: last_name,
    email: email,
    restaurants: restaurants,
    departments: departments,
    roles: roles
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userRegister = (
  email,
  first_name,
  last_name,
  password,
  restaurants,
  departments,
  captcha,
  onSuccess?,
  onFail?
) => ({
  type: USER_REGISTER,
  payload: {
    email: email,
    first_name: first_name,
    last_name: last_name,
    password: password,
    restaurants: restaurants,
    departments: departments,
    captcha: captcha
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userFetch = (onSuccess?, onFail?) => ({
  type: USER_FETCH,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userPasswordResetRequest = (
  email,
  captcha,
  onSuccess?,
  onFail?
) => ({
  type: USER_PASSWORD_RESET_REQUEST,
  payload: {
    email: email,
    captcha: captcha
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userPasswordReset = (password, token, onSuccess?, onFail?) => ({
  type: USER_PASSWORD_RESET,
  payload: {
    password: password,
    token: token
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userGetData = (onSuccess?, onFail?) => ({
  type: USER_GET_DATA,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userAcountUpdate = (
  first_name,
  last_name,
  current_password,
  new_password,
  confirmpassword,
  onSuccess?,
  onFail?
) => ({
  type: USER_ACC_UPDATE,
  payload: {
    first_name: first_name,
    last_name: last_name,
    current_password: current_password,
    new_password: new_password,
    confirmpassword: confirmpassword
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updatePersistentSettings = (data, onSuccess?, onFail?) => ({
  type: UPDATE_PERSISTENT_SETTINGS,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteUser = (user_id, company_id, onSuccess?, onFail?) => ({
  type: DELETE_USER,
  payload: { user_id, company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const changeCompany = (user_id, company_id, old_company_id, onSuccess?, onFail?) => ({
  type: CHANGE_COMPANY,
  payload: { user_id: user_id, company_id: company_id, old_company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const resendSetUserPasswordEmail = (id, onSuccess?, onFail?) => ({
  type: RESEND_SET_USER_PASSWORD_EMAIL,
  payload: { id: id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addUserCompany = (user_id, company_id, onSuccess?, onFail?) => ({
  type: ADD_USER_COMPANY,
  payload: { user_id, company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const removeUserCompany = (
  user_id,
  company_id,
  onSuccess?,
  onFail?
) => ({
  type: REMOVE_USER_COMPANY,
  payload: { user_id, company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const changePlaceholderConfiguration = (data, onSuccess?, onFail?) => ({
  type: CHANGE_PLACEHOLDER_CONFIGURATION,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
