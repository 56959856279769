import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Modal, Tooltip} from "antd";
import React, { useRef, useState, useMemo } from "react";
import { createUseStyles } from "react-jss";
import variables from "../../scss/_variables.scss";
import SelectExistingFolder from "../form/input/SelectExistingFolder";
import InstanceFolderIcon from "../../icons/instance-folder.svg";
import ConfirmDeleteFolderModal from "../folder/ConfirmDeleteFolderModal";
import {getChildrenData} from "../../shared/utils/commonDataUtils";
import {useSelector} from "react-redux";
import {TREE_MODE_RESOURCE} from '../constants'

const useStyles = createUseStyles({
  multiActionbar: {
    height: 40,
    display: "flex",
    color: "white",
    "& #selected-text": {
      backgroundColor: variables.titlebarColor,
      height: "100%",
      marginRight: 0.5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& #action-buttons": {
      backgroundColor: variables.titlebarColor,
      width: "50%",
      height: "100%",
      marginLeft: 0.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      "& svg": {
        cursor: "pointer",
      },
    },
  },
});

const MIN_WIDTH_FULL_RESOLUTION = 400;

const MultiFolderActions = (props) => {
  const folderModalRef = useRef();
  const classes = useStyles();
  const [multiFolderAction, setMultiFolderAction] = useState("move");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [viewData, setViewData] = useState({
    modalText: "Are you sure you want to move the selected items?",
    submitButtonText: "Move",
  });
  const treeMode = useSelector((state) => state.client_options.treemode);

  const isMoveEnabled = useMemo(() => {
    if (!props.enabledActions) {
      return true;
    }
    return props.enabledActions.indexOf('move') > -1;
  }, [props.enabledActions]);

  const isCopyEnabled = useMemo(() => {
    if (treeMode === TREE_MODE_RESOURCE) {
      return false
    }
    if (!props.enabledActions) {
      return true;
    }
    return props.enabledActions.indexOf('copy') > -1;
  }, [props.enabledActions]);

  const isInstanceEnabled = useMemo(() => {
    if (treeMode === TREE_MODE_RESOURCE) {
      return false
    }
    if (!props.enabledActions) {
      return true;
    }
    return props.enabledActions.indexOf('instance') > -1;
  }, [props.enabledActions]);

  const isDeleteEnabled = useMemo(() => {
    if (!props.enabledActions) {
      return true;
    }
    return props.enabledActions.indexOf('delete') > -1;
  }, [props.enabledActions]);

  const handleDelete = () => {
    const { handleBulkDelete, onOkay } = props;
    handleBulkDelete(() => {
      setShowDeleteConfirmationModal(false);
    });
    if (onOkay) {
      onOkay();
    }
  }

  const foldersSelectedHasChildren = () => {
    if (props.selectedItems) {
      for (const selectedItem of props.selectedItems) {
        const childrenData = getChildrenData(
          {
            folder__folders: Object.keys(props.folder__folders).map((k) => props.folder__folders[k]),
            folders: Object.keys(props.folders).map((k) => props.folders[k])
          },
          selectedItem.folderId
        )
        if (childrenData.folders.length) {
          return true
        }
      }
    }
    return false
  }

  const onWarningModalOkay = () => {
    const { onOkay } = props;
    setShowConfirmationModal(false);
    switch (multiFolderAction) {
      case "instance":
      case "move":
      case "copy":
        folderModalRef.current.openGallery(multiFolderAction);
        break;
      case "delete":
        if (foldersSelectedHasChildren()) {
          setShowConfirmDeleteModal(true)
          return
        }
        handleDelete()
        break;
      default:
        break;
    }
    if (onOkay) {
      onOkay();
    }
  };

  const onSelectedFolderOkay = selectedFolder => {
    const { handleBulkMove, handleBulkInstance, handleBulkClone } = props;
    switch (multiFolderAction) {
      case 'instance':
        handleBulkInstance(selectedFolder);
        break;
      case 'move':
        handleBulkMove(selectedFolder);
        break;
      case 'copy':
        handleBulkClone(selectedFolder)
        break;
    }
  }

  const handleActionChange = (action) => {
    if (props.selectedItemsCount <= 0) {
      return;
    }
    const view_data = {
      modalText: `Are you sure you want to ${action} the selected items?`,
      submitButtonText: `${action[0].toUpperCase()}${action.slice(1)}`,
    };
    setMultiFolderAction(action);
    setViewData(view_data);

    switch (action) {
      case 'move':
      case 'copy':
      case 'instance':
        setShowConfirmationModal(true);
        break;
      case 'delete':
      setShowDeleteConfirmationModal(true);
      break;
    }
  };

  const handleActionDelete = (action) => {
    const view_data = {
      modalText: `Are you sure you want to ${action} the selected items?`,
      submitButtonText: `${action[0].toUpperCase()}${action.slice(1)}`,
    }
  }

  return (
    <div>
      <SelectExistingFolder
        ref={folderModalRef}
        caller_folder={props.caller_folder}
        checked_folders={props.checked_folders}
        multiFolderAction={multiFolderAction}
        folderTypes={props.folderTypes}
        onOkay={onSelectedFolderOkay}
        selectedItems={props.selectedItems}
      />
      <Modal
        visible={showConfirmationModal || showDeleteConfirmationModal}
        onCancel={() => {
          setShowConfirmationModal(false);
          setShowDeleteConfirmationModal(false);
        }}
        onOk={onWarningModalOkay}
        destroyOnClose="true"
      >
        {viewData.modalText}
      </Modal>
      <ConfirmDeleteFolderModal
        closeModal={() => setShowConfirmDeleteModal(false)}
        folder={null}
        onSave={handleDelete}
        visible={showConfirmDeleteModal}
      />

      <div className={classes.multiActionbar} width={props.maxWidth > MIN_WIDTH_FULL_RESOLUTION ? '280px' : '40px'}>
        <div id="selected-text" style={{width: props.maxWidth > MIN_WIDTH_FULL_RESOLUTION ? '50%' : '25%'}}>
          {props.selectedItemsCount} {props.maxWidth > MIN_WIDTH_FULL_RESOLUTION ? 'selected' : ''}
        </div>
        <div id="action-buttons">
          {isMoveEnabled && (
            <Tooltip placement="bottom" title="Move">
              <FontAwesomeIcon
                size="lg"
                icon={["far", "exchange"]}
                onClick={() => handleActionChange("move")}
              />
            </Tooltip>
          )}
          {isCopyEnabled && (
            <Tooltip placement="bottom" title="Copy">
              <FontAwesomeIcon
                size="lg"
                icon={["fas", "copy"]}
                onClick={() => handleActionChange("copy")}
              />
            </Tooltip>
          )}
          {isInstanceEnabled && (
            <Tooltip placement="bottom" title="Instance">
              <img
                src={InstanceFolderIcon}
                onClick={() => handleActionChange("instance")}
                alt="Fit All"
                style={{
                  width: '20px',
                  cursor: 'pointer'
                }}
              />
            </Tooltip>
          )}
          {isDeleteEnabled && (
            <Tooltip placement="bottom" title="Delete">
              <FontAwesomeIcon
                size="lg"
                icon={["fas", "trash"]}
                onClick={() => handleActionChange("delete")}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiFolderActions;
