import { IProjectModel } from './project';

export enum PERMISSION_SCOPE {
  COMPANY = 'company',
  PROJECT = 'project'
}

export enum PERMISSION_AREA {
  APPLIED_CONTENT = 'applied_content',
  BACKUP = 'backup',
  CSV = 'csv',
  RESOURCE = 'resource',
  USER = 'user',
  FOLDER = 'folder',
  FIELD = 'field',
  COMPANY = 'company',
  AUDIT_LOG = 'audit_log',
  LOCATION = 'location'
}

export enum PERMISSION_ACTION_TYPE {
  GRANT = 'grant',
  RESTRICT = 'restrict',
  REJECT = 'reject'
}

export interface IPermissionModel {
  id?: number;
  name: string;
  scope: PERMISSION_SCOPE;
  company: number;
  area: PERMISSION_AREA;
  action_type: PERMISSION_ACTION_TYPE;
  action: string;
  action_item?: string;
  roles?: number;
  options?: any;
  project?: number;
}
