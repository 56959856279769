import React from "react";
import get from "lodash/get";
import { createUseStyles } from "react-jss";
import RichTextRender from "../../standard/RichTextRender";
import AlpineStoryWithFactCarousel from "./AlpineStoryWithFactCarousel";
import { getFieldValue } from "../../../utils/generalUtils";
import styles from "./styles";
import { useScale } from "../../../hooks/useScale";
import { useFolder } from "../../../hooks/useFolder";

const AlpineStoryWithFact = (props) => {
  const { getBaseFolder, getBaseFolderFolderType } = useFolder();
  const { standardSizing, getUnitBasedOnHeight } = useScale(
    props.data.base_folder
  );

  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const tagline = getFieldValue(
    props.baseFolder,
    "tagline",
    props.baseFolderType
  );
  const header = getFieldValue(
    props.baseFolder,
    "header",
    props.baseFolderType
  );
  const body = getFieldValue(props.baseFolder, "body", props.baseFolderType);
  const factBackgroundColor = get(
    props.baseFolder,
    "fields.fact_background_color",
    "#76232f"
  );
  const backgroundColor = getFieldValue(
    props.baseFolder,
    "background_color",
    props.baseFolderType
  );

  const useStyles = createUseStyles(styles);
  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    standardSizing,
    getUnitBasedOnHeight,
  });

  return (
    <div className={classes.componentContainer}>
      <div className={classes.storyWithFact}>
        <div className="sectionPadding">
          <RichTextRender className="sectionTagline" htmlString={tagline} />
          <RichTextRender className="sectionHeader" htmlString={header} />
          <RichTextRender className="sectionBody" htmlString={body} />
        </div>
        <div className="interactiveFooter">
          <div
            style={{
              height: "46em",
            }}
          >
            <AlpineStoryWithFactCarousel
              {...props}
              baseFolder={baseFolder}
              baseFolderType={baseFolderType}
              factBackgroundColor={factBackgroundColor}
              style={{
                display: "flex",
                width: "100%",
                marginTop: "2em",
                paddingRight: "7.5em",
                paddingLeft: "7.5em",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                maxHeight: "40em",
              }}
              minHeight="40em"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlpineStoryWithFact;
