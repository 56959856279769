import React, { useEffect, useState } from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import Slide1911 from "../../standard/Slide1911";
import get from "lodash/get";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import { DEFAULT_SCROLLBAR_COLOR } from "./EugeneConstants";
import { getColorFromRichText } from "./EugeneUtils";

const EugeneTemplate004 = (props: IDisplayComponentProps) => {
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [baseFolder, setBaseFolder] = useState(null);
    const [baseFolderType, setBaseFolderType] = useState(null);
    const [headlineConverter, setHeadlineConverter] = useState(null);
    const [subHeadConverter, setSubHeadConverter] = useState(null);
    const [bodyTextConverter, setBodyTextConverter] = useState(null);
    const [bodyTextColor, setBodyTextColor] = useState(DEFAULT_SCROLLBAR_COLOR);
    const [quoteConverter, setQuoteConverter] = useState(null);
    const [attributionConverter, setAttributionConverter] = useState(null);
    const [showPlaceholder, setShowPlaceholder] = useState(props.data?.persistent_settings?.data?.show_placeholder || false);

    useEffect(() => {
        const myBaseFolderType = getBaseFolderType(props.data, get(baseFolder, 'folder_type', {}));

        const myHeadlineConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.headline.ops", []),
            { multiLineParagraph: false }
        );
        const mySubHeadConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.subhead.ops", []),
            { multiLineParagraph: false }
        );
        const myBodyTextOps = get(baseFolder, "fields.body.ops", []);
        const myBodyTextConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.body.ops", []),
            { multiLineParagraph: false }
        );
        const myBodyTextColor = getColorFromRichText(myBodyTextOps)
        setBodyTextColor(myBodyTextColor);
        const myQuoteConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.quote.ops", []),
            { multiLineParagraph: false }
        );

        const myAttributionConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.attribution.ops", []),
            { multiLineParagraph: false }
        );


        const color = get(baseFolder, 'fields.color_overlay', 'transparent')
        setBackgroundColor(color);

        setHeadlineConverter(myHeadlineConverter);
        setSubHeadConverter(mySubHeadConverter);
        setBodyTextConverter(myBodyTextConverter);
        setQuoteConverter(myQuoteConverter);
        setAttributionConverter(myAttributionConverter);

        setBaseFolderType(myBaseFolderType)
    }, [baseFolder])

    useEffect(() => {
        const myBaseFolder = getBaseFolder(props.data);
        setBaseFolder(myBaseFolder)
    }, [props.data])

    const isTextRichEmpty = (text) => {
        if (text && text !== '<p><br/></p>') {
            return false;
        } else {
            return true;
        }
    }

    const getPlaceholder = (placeholder) => {
        if (!showPlaceholder) {
            return '';
        }

        switch (placeholder) {
            case 'headline':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-40em\">${get(baseFolderType, "fields[headline].title", '')}</strong></p>`
            case 'subhead':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(baseFolderType, "fields[subhead].title", '')}</strong></p>`
            case 'body':
                return `<p><strong class=\"ql-font-roboto ql-size-21em\">${get(baseFolderType, "fields[body].title", '')}</strong></p>`
            case 'quote':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(baseFolderType, "fields[quote].title", '')}</strong></p>`
            case 'attribution':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(baseFolderType, "fields[attribution].title", '')}</strong></p>`


        }
    }

    const getHeaderLineOrPlaceholder = (convertor, type) => {
        let html = convertor ? convertor.convert() : '';

        if (isTextRichEmpty(html) && props.mode === COMPONENT_MODE_CONTEXT.PREVIEW) {
            html = getPlaceholder(type);
        }

        return html
    }

    const headline = getHeaderLineOrPlaceholder(headlineConverter, 'headline');
    const subhead = getHeaderLineOrPlaceholder(subHeadConverter, 'subhead');
    const bodyText = getHeaderLineOrPlaceholder(bodyTextConverter, 'body');
    const quote = getHeaderLineOrPlaceholder(quoteConverter, 'quote');
    const attribution = getHeaderLineOrPlaceholder(attributionConverter, 'attribution');

    return (
        <>
            {/* Background Image */}
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    zIndex: -2
                }}
            >
                <Slide1911
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    mode={props.mode}
                    containerWidth={props.containerWidth}
                    containerHeight={props.containerHeight}
                    data={{ ...props.data, slotNames: ["background"] }}
                    webview_signedurls={props.webview_signedurls}
                />
            </div>
            {/* Background Overlay */}
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    backgroundColor: backgroundColor,
                    zIndex: -1
                }}
            />
            <div
                style={{
                    width: props.containerWidth,
                    height: props.containerHeight,
                    position: 'relative',
                }}
            >
                <div style={{ width: "50%", height: "100%", float: "left" }}>
                    <div
                        style={{
                            height: "100%",
                            paddingTop: (9.259259259 / 100) * props.containerHeight,
                            paddingBottom: (9.259259259 / 100) * props.containerHeight,
                            paddingLeft: 0.104166667 * props.containerWidth,
                            paddingRight: 0.104166667 * props.containerWidth,
                        }}
                    >
                        {/* Text */}
                        <div
                            style={{
                                height: "100%",
                                color: "#707070",
                            }}
                        >
                            <div style={{
                                width: props.containerWidth * 0.291666667,
                                maxHeight: '100%',
                                overflowY: 'hidden'
                            }}>
                                {/* Headline */}
                                <div className="ql-editor-preview">
                                    <div
                                        className="ql-editor"
                                        style={{ fontSize: props.containerHeight / 1000, padding: 0 }}
                                        dangerouslySetInnerHTML={{ __html: headline }}
                                    />
                                </div>
                                {/* Sub Head */}
                                {subhead && (
                                    <div
                                        className="ql-editor-preview eugene-template-004_subhead"
                                        style={{ marginTop: props.containerHeight * 0.008329169 }}
                                    >
                                        <div
                                            className="ql-editor"
                                            style={{ fontSize: props.containerHeight / 1000, padding: 0 }}
                                            dangerouslySetInnerHTML={{ __html: subhead }}
                                        />
                                    </div>
                                )}
                                {/* Body Text */}
                                <div
                                    className="ql-editor-preview eugene-template-004_body-text"
                                    style={{ marginTop: props.containerHeight * 0.046296296 }}
                                >
                                    <ScrollableFixContainer2103
                                        key={`key-${bodyTextColor}`}
                                        containerHeight={props.containerHeight * 0.7}
                                        containerWidth={props.containerWidth * 0.28}
                                        showScrollbar
                                        barColor={bodyTextColor}
                                    >
                                        <div
                                            className="ql-editor"
                                            style={{
                                                fontSize: props.containerHeight / 1000,
                                                padding: 0,
                                                lineHeight: "30em",
                                                color: "#707070",
                                            }}
                                            dangerouslySetInnerHTML={{ __html: bodyText }}
                                        />
                                    </ScrollableFixContainer2103>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "50%", height: "100%", float: "right" }}>
                    <div
                        style={{
                            height: "100%",
                            paddingTop: (9.259259259 / 100) * props.containerHeight,
                            paddingBottom: (9.259259259 / 100) * props.containerHeight,
                            paddingLeft: 0.104166667 * props.containerWidth,
                            paddingRight: 0.104166667 * props.containerWidth,
                        }}
                    >
                        {/* Text */}
                        <div
                            style={{
                                height: "100%",
                                color: "#707070",
                                overflowY: 'hidden',
                                textAlign: "center",
                                position: "relative",
                                display: "block",
                            }}
                        >
                            <div style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50%',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                                maxWidth: '100%',
                            }}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: 0.166583375 * props.containerHeight,
                                        margin: "auto",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            fontFamily: "Lora-Bold",
                                            fontSize: props.containerHeight * 0.277777778,
                                            top: -0.074962519 * props.containerHeight,
                                            color: "#cdcdcd",
                                        }}
                                    >
                                        “
                                    </div>
                                </div>
                                <div className="ql-editor-preview">
                                    <div
                                        className="ql-editor"
                                        style={{ fontSize: props.containerHeight / 1000, padding: 0 }}
                                        dangerouslySetInnerHTML={{ __html: quote }}
                                    />
                                </div>
                                <div
                                    style={{ marginTop: 0.016658337 * props.containerHeight }}
                                    className="ql-editor-preview"
                                >
                                    <div
                                        className="ql-editor"
                                        style={{ fontSize: props.containerHeight / 1000, padding: 0 }}
                                        dangerouslySetInnerHTML={{ __html: attribution }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EugeneTemplate004;
