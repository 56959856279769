import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { transitions } from "./new_orleans_settings";
import { tree_icon_types } from "../tree_icon_types";

export const new_orleans_attract_loop: IFolderType = {
  folder_category: FOLDER_CATEGORY.CONTENT,
  name: FOLDER_TYPE.new_orleans_attract_loop,
  display_name: "Attract Loop",
  acceptable_folder_types: [
    FOLDER_TYPE.new_orleans_template_001,
    FOLDER_TYPE.new_orleans_template_002,
    FOLDER_TYPE.new_orleans_template_003,
    FOLDER_TYPE.new_orleans_donor_list,
    FOLDER_TYPE.new_orleans_donor_list_category
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.attract_loops
    },
    closed: {
      ...tree_icon_types.attract_loops
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "crossfade",
      options: transitions
    }
  },
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/new_orleans_background.jpg"
  },
  list_view: {
    enabled: false,
    list_folder_types: [
      FOLDER_TYPE.new_orleans_template_001,
      FOLDER_TYPE.new_orleans_template_002,
      FOLDER_TYPE.new_orleans_template_003,
      FOLDER_TYPE.new_orleans_donor_list,
      FOLDER_TYPE.new_orleans_donor_list_category
    ],
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.new_orleans_template_001,
          FOLDER_TYPE.new_orleans_template_002,
          FOLDER_TYPE.new_orleans_template_003,
          FOLDER_TYPE.new_orleans_donor_list,
          FOLDER_TYPE.new_orleans_donor_list_category
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.new_orleans_template_001,
          FOLDER_TYPE.new_orleans_template_002,
          FOLDER_TYPE.new_orleans_template_003,
          FOLDER_TYPE.new_orleans_donor_list
        ],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.new_orleans_template_001,
          FOLDER_TYPE.new_orleans_template_002,
          FOLDER_TYPE.new_orleans_template_003,
          FOLDER_TYPE.new_orleans_donor_list
        ],
        field: "header01",
        title: "Header",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.new_orleans_template_001,
          FOLDER_TYPE.new_orleans_template_002,
          FOLDER_TYPE.new_orleans_template_003,
          FOLDER_TYPE.new_orleans_donor_list
        ],
        field: "file",
        title: "Image / Media",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.new_orleans_template_001,
          FOLDER_TYPE.new_orleans_template_002,
          FOLDER_TYPE.new_orleans_template_003,
          FOLDER_TYPE.new_orleans_donor_list
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  instancing_options: {
    enabled: true,
    cross_project: false
  },
  hide_nested_children: true,
  isPresentation: true
};
