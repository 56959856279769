import get from "lodash/get";
import React, {useCallback} from "react";
import { createUseStyles } from "react-jss";
import {
  generateFontSizeClassesFromFields,
  getFieldValue
} from "../../../utils/generalUtils";
import CycleWrapper from '../../standard/CycleWrapper';
import Slide1911 from "../../standard/Slide1911";
import MonterreyFooter from "./MonterreyFooter";
import MonterreyQuote from "./MonterreyQuote";
import MediaCarousel from "../../standard/MediaCarousel";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";

const MonterreyMediaWithQuote = (props) => {
  const getStyles = () => {
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.baseFolder?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, props.baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();
  const backgroundColor = getFieldValue(
    props.baseFolder,
    "background_color",
    props.baseFolderType
  );

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  return (
    <div
      className="wrapper"
      style={{
        width: "100%",
        height: props.containerHeight,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor,
      }}
    >
      {
        !resourceFoldersCarousel.length && (
          <CycleWrapper {...props}/>
        )
      }
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MediaCarousel
          style={{
            marginBottom: '4em',
          }}
          {...props}
          componentSpecificData={{
            customStyleImage: {
              maxHeight: '60em',
              width: "100%",
              backgroundPosition: "center center"
            },
            customStyleVideo: {
              hideOffset: true,
              maxHeight: '60em',
              position: 'relative'
            },
            slickConfiguration: {
              fade: true,
              speed: 2000
            }
          }}
          resourceFoldersCarousel={resourceFoldersCarousel}
          width="100%"
          height="60em"
          slotNames={["image"]}
          dotsStyles={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
            marginTop: '2em'
          }}
        />
        <div
          className="section-padding"
          style={{
            display: "flex",
            width: "100%",
            paddingRight: "7.5em",
            paddingLeft: "7.5em",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            maxHeight: "40em"
          }}
        >
          <MonterreyQuote
            {...props}
            quote={props.baseFolder}
            baseFolderType={props.baseFolderType}
          />
        </div>
        <div className="interactiveFooter">
          <MonterreyFooter {...props} classes={classes} />
        </div>
      </div>
    </div>
  );
};

export default MonterreyMediaWithQuote;
