export enum CLIENT_URL {
    LOCALHOST = 'http://localhost:3000',
    PRODUCTION = 'https://app.pdgcms.com',
    STAGING = 'https://app-staging.pdgcms.com',
}

export enum SERVER_URL {
    LOCALHOST = 'http://localhost:8081',
    PRODUCTION = 'https://app-server.pdgcms.com',
    STAGING = 'https://app-staging-server.pdgcms.com',
}

export enum S3_BUCKET {
    PDGV2_DEVELOP = 'pdgv2-develop',
    PDGV2_STAGING = 'pdgv2-staging',
    PDGV2_PRODUCTION = 'pdgv2-production',
    PDGV2_SHARED = 'pdgv2-shared'
}

export enum ENVINROMENT {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
    STAGING = 'staging'
}

export enum MODEL_MIGRATE {
    SAFE = 'safe',
    ALTER = 'alter'
}

export enum DEFAULT_PASSWORD_METHOD {
    FIXED = 'fixed',
    RANDOMIZED = 'randomized'
}

export enum COMPONENT_MODE_CONTEXT {
    PREVIEW = 'preview',
    LOCAL = 'local',
    WEBVIEW = 'webview'
}