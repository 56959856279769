import { IListViewColumn } from "../interfaces/custom/list-view-column.interface";

export const computerColumns: IListViewColumn[] = [
  {
    type: "bool",
    title: "",
    field: "list_view_selected",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Name",
    field: "name",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Brand",
    field: "brand",
    sort: true,
    editable: true,
  },
  {
    type: "text_link",
    title: "Model",
    field: "model",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Serial Number",
    field: "serial_number",
    sort: true,
    editable: true,
  },
  {
    type: "money",
    title: "Price",
    field: "price",
    sort: true,
    editable: true,
  },
  {
    type: "date",
    title: "Purchase Date",
    field: "purchase_date",
    sort: true,
    editable: true,
  },
  {
    type: "months-left",
    title: "Warranty Period",
    field: "warranty_period",
    sort: true,
    editable: true,
    settings: {
      date_field: 'purchase_date',
      states: [{
        unit: 'months',
        value: 6,
        color: '#d35400'
      },{
        unit: 'months',
        value: 3,
        color: '#d35400'
      }, {
        unit: 'days',
        value: 0,
        color: '#c0392b'
      }]
    }
  },
  {
    type: "date",
    title: "MFG Date",
    field: "mfg_date",
    sort: true,
    editable: true,
  },
  {
    type: "text-lg",
    title: "OS",
    field: "os",
    sort: true,
    editable: true,
  },
  {
    type: "text-lg",
    title: "Processor",
    field: "processor",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Hard Drive",
    field: "hard_drive",
    sort: true,
    editable: true,
    settings: {
      suffix: ' GB'
    }
  },
  {
    type: "text",
    title: "Ram",
    field: "ram",
    sort: true,
    editable: true,
    settings: {
      suffix: ' GB'
    }
  },
  {
    type: "text-lg",
    title: "Computer Name",
    field: "computer_name",
    sort: true,
    editable: true,
  },
  {
    type: "select",
    options: [{
      title: "Wired",
      value: "wired",
    }, {
      title: "Wireless",
      value: "wireless",
    }, {
      title: "None",
      value: "none"
    }],
    title: "Network Connection",
    field: "network_connection",
    sort: true,
    editable: true,
    settings: { minWidth: 150 }
  },
  {
    type: "text",
    title: "MAC (LAN)",
    field: "mac_lan",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "MAC (Wireless)",
    field: "mac_wireless",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "IP",
    field: "ip",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Subnet",
    field: "subnet",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Gateway",
    field: "gateway",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "DNS1",
    field: "dns1",
    sort: true,
    editable: true,
  },
  {
    title: "DNS2",
    type: "text",
    field: "dns2",
    sort: true,
    editable: true,
  },
  {
    type: "text",
    title: "Auto-Login",
    field: "auto_login",
    sort: true,
    editable: true,
  },
  {
    type: "textarea_modal",
    title: "Accounts",
    field: "accounts",
    sort: true,
    editable: true,
  },
  {
    type: "textarea_modal",
    title: "Notes",
    field: "notes",
    sort: true,
    editable: true,
  },
];

export const monitorColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Brand",
  field: "brand",
  sort: true,
  editable: true,
},
{
  type: "text_link",
  title: "Model",
  field: "model",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Resolution",
  field: "resolution",
  sort: true,
  editable: true,
},
{
  type: "select",
  title: "Orientation",
  field: "orientation",
  options: [{
    title: "Landscape",
    value: "landscape",
  }, {
    title: "Portrait",
    value: "portrait",
  }],
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Serial Number",
  field: "serial_number",
  sort: true,
  editable: true,
}, 
{
  type: "date",
  title: "Purchase Date",
  field: "purchase_date",
  sort: true,
  editable: true,
},
{
  type: "months-left",
  title: "Warranty Period",
  field: "warranty_period",
  sort: true,
  editable: true,
  settings: {
    date_field: 'purchase_date',
  }
},
{
  type: "textarea_modal",
  title: "Mount",
  field: "mount",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Display Connection",
  field: "display_connection",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Notes",
  field: "notes",
  sort: true,
  editable: true,
}];

export const ipOutletsColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Brand",
  field: "brand",
  sort: true,
  editable: true,
},
{
  type: "text_link",
  title: "Model",
  field: "model",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Serial Number",
  field: "serial_number",
  sort: true,
  editable: true,
}, 
{
  type: "date",
  title: "Purchase Date",
  field: "purchase_date",
  sort: true,
  editable: true,
},
{
  type: "months-left",
  title: "Warranty Period",
  field: "warranty_period",
  sort: true,
  editable: true,
  settings: {
    date_field: 'purchase_date',
  }
},
{
  type: "select",
  options: [{
    title: "Wired",
    value: "wired",
  }, {
    title: "Wireless",
    value: "wireless",
  }, {
    title: "None",
    value: "none"
  }],
  title: "Network Connection",
  field: "network_connection",
  sort: true,
  editable: true,
  settings: { minWidth: 150 }
},
{
  type: "text",
  title: "Device Name",
  field: "device_name",
  sort: true,
  editable: true,
},
{
  type: "date",
  title: "MFG Date",
  field: "mfg_date",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "MAC (LAN)",
  field: "mac_lan",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "MAC (Wireless)",
  field: "mac_wireless",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "IP",
  field: "ip",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Subnet",
  field: "subnet",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Gateway",
  field: "gateway",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "DNS1",
  field: "dns1",
  sort: true,
  editable: true,
},
{
  title: "DNS2",
  type: "text",
  field: "dns2",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Accounts",
  field: "accounts",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Notes",
  field: "notes",
  sort: true,
  editable: true,
}];

export const upsColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Brand",
  field: "brand",
  sort: true,
  editable: true,
},
{
  type: "text_link",
  title: "Model",
  field: "model",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Serial Number",
  field: "serial_number",
  sort: true,
  editable: true,
}, 
{
  type: "date",
  title: "Purchase Date",
  field: "purchase_date",
  sort: true,
  editable: true,
},
{
  type: "months-left",
  title: "Warranty Period",
  field: "warranty_period",
  sort: true,
  editable: true,
  settings: {
    date_field: 'purchase_date',
  }
},
{
  type: "select",
  options: [{
    title: "Wired",
    value: "wired",
  }, {
    title: "Wireless",
    value: "wireless",
  }, {
    title: "None",
    value: "none"
  }],
  title: "Network Connection",
  field: "network_connection",
  sort: true,
  editable: true,
  settings: { minWidth: 150 }
},
{
  type: "text",
  title: "Device Name",
  field: "device_name",
  sort: true,
  editable: true,
},
{
  type: "date",
  title: "MFG Date",
  field: "mfg_date",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "MAC (LAN)",
  field: "mac_lan",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "MAC (Wireless)",
  field: "mac_wireless",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "IP",
  field: "ip",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Subnet",
  field: "subnet",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Gateway",
  field: "gateway",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "DNS1",
  field: "dns1",
  sort: true,
  editable: true,
},
{
  title: "DNS2",
  type: "text",
  field: "dns2",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Accounts",
  field: "accounts",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Notes",
  field: "notes",
  sort: true,
  editable: true,
}];

export const otherColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Brand",
  field: "brand",
  sort: true,
  editable: true,
},
{
  type: "text_link",
  title: "Model",
  field: "model",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Serial Number",
  field: "serial_number",
  sort: true,
  editable: true,
}, 
{
  type: "date",
  title: "Purchase Date",
  field: "purchase_date",
  sort: true,
  editable: true,
},
{
  type: "months-left",
  title: "Warranty Period",
  field: "warranty_period",
  sort: true,
  editable: true,
  settings: {
    date_field: 'purchase_date',
  }
},
{
  type: "textarea_modal",
  title: "Notes",
  field: "notes",
  sort: true,
  editable: true,
}];

export const contactColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Title",
  field: "title",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Department",
  field: "department",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Email",
  field: "email",
  sort: true,
  editable: true,
}, 
{
  type: "text",
  title: "Phone",
  field: "phone",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Notes",
  field: "notes",
  sort: true,
  editable: true,
}];

export const accessColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "select",
  options: [{
    title: "LogMeIn",
    value: "logmein",
  }, {
    title: "Client VPN",
    value: "client_vpn",
  }, {
    title: "Other",
    value: "other"
  }],
  title: "Type",
  field: "type",
  sort: true,
  editable: true,
  settings: { minWidth: 150 }
},
{
  type: "text_link",
  title: "Access Website",
  field: "access_website",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Accounts",
  field: "accounts",
  sort: true,
  editable: true,
},
{
  type: "textarea_modal",
  title: "Proprietary Software",
  field: "proprietary_software",
  sort: true,
  editable: true,
}];

export const locationColumns: IListViewColumn[] = [{
  type: "bool",
  title: "",
  field: "list_view_selected",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Name",
  field: "name",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Address",
  field: "address",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Internal Location",
  field: "internal_location",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "City",
  field: "city",
  sort: true,
  editable: true,
}, 
{
  type: "text",
  title: "State",
  field: "state",
  sort: true,
  editable: true,
},
{
  type: "text",
  title: "Zip",
  field: "zip",
  sort: true,
  editable: true,
}];