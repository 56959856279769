import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { glenwood_template_shared } from "./glenwood_template_shared";
import {name} from "../standardized_fields";

const modifiedFieldGroups = {
  ...glenwood_template_shared.field_groups,
  image_1: {
    title: "Image 1",
    default_expanded: false,
    order: 5,
    accordion: true,
    tab: "main"
  }
};

export const glenwood_template_002: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.glenwood_template_002,
  display_name: "Template 2 - Landscape - Image Left - Story Right",
  tabs: {
    ...glenwood_template_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...glenwood_template_shared.fields,
    image_1: {
      title: "Image 1",
      type: "file",
      slot_type: "dynamic",
      limit: 10,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.888888889,
      recommended_size: {
        width: 960,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.888888889
      },
      field_group: "image_1"
    }
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodTemplate002,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  tab_previews: [
    {
      tab_key: "main",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.GlenwoodTemplate002,
      type: PREVIEW_TYPE.COMPONENT,
      screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
    },
    {
      tab_key: "card_view",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.GlenwoodTemplateCardView,
      type: PREVIEW_TYPE.COMPONENT,
      screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
    }
  ],
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_002.svg"
  },
  save_options: [...glenwood_template_shared.save_options]
};
