import React, {useEffect, useState} from "react";
import {
  getBaseFolder,
  getBaseFolderType,
} from "../../../utils/commonDataUtils";
import {getFieldValue,} from "../../../utils/generalUtils";
import Slide1911 from "../../standard/Slide1911";
import get from "lodash/get";
import RichTextRender from "../../standard/RichTextRender";

const NaplesTemplateRed001 = (props) => {
  const [baseFolder, setBaseFolder] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);

  useEffect(() => {
    const myBaseFolder = getBaseFolder(props.data);
    setBaseFolder(myBaseFolder);
    const myBaseFolderType = getBaseFolderType(
      props.data,
      get(myBaseFolder, "folder_type", {})
    );
    setBaseFolderType(myBaseFolderType);
  }, [props.data]);

  if (!baseFolder) {
    return <div>No base folder</div>;
  }



  const getPropertiesFromFolder = (folder) => {
    const showPlaceholder = get(props.data.persistent_settings, "show_placeholder", false)
    const body_text = getFieldValue(folder, 'body_text', baseFolderType, showPlaceholder);
    const header = getFieldValue(folder, 'header', baseFolderType, showPlaceholder);

    return {
      header,
      body_text,
    };
  };

  const { header, body_text } = getPropertiesFromFolder(baseFolder);

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      <div
        className="top-screen"
        style={{
          height: props.containerHeight / 3,
          top: 0,
          position: "relative",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "flex-end",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: "57.5em",
            backgroundColor: get(baseFolder, "fields.color_overlay_top"),
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight="100%"
            containerWidth="100%"
            data={{
              ...props.data,
              slotNames: ["background_image_top"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
              componentSpecificData: {
                ...props.data.componentSpecificData,
                customStyleImage: {
                  width: props.containerWidth,
                },
              },
            }}
          />
        </div>
        <div
          className="upper-footer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "absolute",
            bottom: 0,
            height: "10em",
            width: props.containerWidth,
            padding: "5em",
            backgroundColor: get(
              baseFolder,
              `fields.background_color`,
              "transparent"
            ),
          }}
        >
          <div
            className={"upper-footer-text " + props.classes.componentContainer}
          >
            <RichTextRender htmlString={header} />
          </div>
        </div>
      </div>
      <div
        className="middle-screen"
        style={{
          height: props.containerHeight / 3,
          top: props.containerHeight / 3,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight / 3,
          }}
        >
          <Slide1911
            {...props}
            containerHeight={props.containerHeight / 3}
            handleEndOfPlay={null}
            data={{
              ...props.data,
              slotNames: ["background_image_middle"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight / 3,
            backgroundColor: get(baseFolder, "fields.color_overlay_middle"),
          }}
        />
        <div
          className={props.classes.componentContainer}
          style={{
            position: "absolute",
            padding: "5em",
            width: props.containerWidth,
            height: props.containerHeight,
          }}
        >
          <div className="ql-editor-preview">
            <RichTextRender htmlString={body_text} />
          </div>
        </div>
      </div>
      <div
        className="bottom-screen"
        style={{
          height: props.containerHeight / 3,
          top: (props.containerHeight / 3) * 2,
          backgroundColor: "#c5aa75",
          width: props.containerWidth,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight / 3,
            backgroundColor: get(baseFolder, "fields.color_overlay_bottom"),
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight / 3}
            data={{
              ...props.data,
              slotNames: ["background_image_bottom"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NaplesTemplateRed001;
