import React, { useEffect, useState, useRef } from "react";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import DonorList2107 from "../../standard/DonorList2107";
import Slide1911 from "../../standard/Slide1911";
import {
  getBaseFolder,
  getBaseFolderType,
} from "../../../utils/commonDataUtils";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import {getFieldValue, wysiwygDeltaOpPxToEm} from "../../../utils/generalUtils";
import { getFileDynamicByRank } from "../../../utils/fileUtils";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import { getHeaderLineOrPlaceholder } from "./naples_setting";
import RichTextRender from "../../standard/RichTextRender";

const NaplesFundFolderMiddleScreen = (props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(
    get(props.data.persistent_settings, "show_placeholder", false)
  );
  const [baseFolder, setBaseFolder] = useState(null);
  const fontColorDefault = props.defaultDonorColor;
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [headerEl, setHeaderEl] = useState(null)
  const [footerEl, setFooterEl] = useState(null)
  const [containerEl, setContainerEl] = useState(null)
  const [categoryEl, setCategoryEl] = useState(null)
  const [lineRef, setLineRef] = useState(null)
  const [lineHeight, setLineHeight] = useState(0)

  useEffect(() => {
    if (lineRef !== null) {
      setLineHeight(lineRef.getBoundingClientRect().height)
    }
  }, [lineRef])

  useEffect(() => {
    const myBaseFolder = getBaseFolder(props.data);
    setBaseFolder(myBaseFolder);
    const myBaseFolderType = getBaseFolderType(
      props.data,
      get(myBaseFolder, "folder_type", {})
    );
    setBaseFolderType(myBaseFolderType);
  }, [props.data]);


  if (!baseFolder) {
    return <div>No base folder</div>;
  }

  const convertPxToEm = (value) => {
    return value / 16
  }

  const getPropertiesFromFolder = (folder) => {
    const donorPercentageHeight = Number(get(
      folder,
      "fields.container_height",
      100
    ));

    const descriptionMarginTop = Number(get(
      folder,
      "fields.description_margin_top",
      5
    ));

    const containerMarginLeft = Number(get(
      folder,
      "fields.container_margin_left",
      0
    ));

    const showUpperRule = get(
      folder,
      "fields.show_upper_rule",
      true
    );

    const showLowerRule = get(
      folder,
      "fields.show_lower_rule",
      true
    );

    const horizontalRuleColor = get(
      folder,
      "fields.horizontal_rule_color",
      "#c5aa75"
    );

    const horizontalRuleSize = get(
      folder,
      "fields.horizontal_rule_size",
      0.125
    );

    let donorNameFontSize = convertPxToEm(get(baseFolder, "fields.name_list.fontSize", 16))

    const overlayColor = get(folder, "fields.color_overlay_middle", null);


    const header = getFieldValue(folder, 'list_header', baseFolderType, showPlaceholder);
    const description = getFieldValue(folder, 'list_description', baseFolderType, showPlaceholder);
    const category = getFieldValue(folder, 'list_category', baseFolderType, showPlaceholder);
    const footer = getFieldValue(folder, 'list_footer', baseFolderType, showPlaceholder);


    console.log(header)

    return {
      header,
      description,
      category,
      footer,
      horizontalRuleColor,
      horizontalRuleSize,
      overlayColor,
      donorNameFontSize,
      showUpperRule,
      showLowerRule,
      donorPercentageHeight,
      descriptionMarginTop,
      containerMarginLeft
    };
  };

  const {
    header,
    description,
    category,
    footer,
    horizontalRuleColor,
    horizontalRuleSize,
    overlayColor,
    donorNameFontSize,
    showUpperRule,
    showLowerRule,
    donorPercentageHeight,
    descriptionMarginTop,
    containerMarginLeft
  } = getPropertiesFromFolder(baseFolder);

  const getDonorListHeight = () => {
    let effectiveDonorListHeight = (props.containerHeight / 3);

    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl)
      const paddingArr = computedStyles.getPropertyValue('padding-top').split('px')
      const paddingNumber = Number(paddingArr[0])
      effectiveDonorListHeight -= (paddingNumber * 2)
    }

    if (headerEl) {
      const headerHeight = headerEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= headerHeight
    }

    if (categoryEl) {
      const categoryHeight = categoryEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= categoryHeight
    }


    if (footerEl) {
      const footerHeight = footerEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= footerHeight
    }

    if (lineRef) {
      const lineHeight = lineRef.getBoundingClientRect().height;
      effectiveDonorListHeight -= lineHeight * 2
    }

    return (effectiveDonorListHeight) * (donorPercentageHeight / 100);
  };

  const getDonorListWidth = () => {

    let donorsListWidth = get(
      baseFolder,
      "donor_list_margin_left",
      props.containerWidth
    )

    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl)
      const paddingArr = computedStyles.getPropertyValue('padding-top').split('px')
      const paddingNumber = Number(paddingArr[0])
      donorsListWidth -= (paddingNumber * 2)
    }

    return donorsListWidth
  };

  return (
    <>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight / 3,
          zIndex: -2,
          top: props.containerHeight / 3,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight / 3}
          handleEndOfPlay={null}
          data={{
            ...props.data,
            slotNames: ["background"],
            backgroundSize: "contain",
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight / 3,
          backgroundColor: overlayColor,
          zIndex: -1,
          top: props.containerHeight / 3,
        }}
      />
      <div
        className="list-container"
        style={{
          padding: "5em",
          marginTop: "container_margin_top",
          marginLeft: "container_margin_left",
          width: "container_width%",
          height: "100%",
        }}
        ref={(el) => setContainerEl(el)}
      >
        <div className={"upper-list-text " + props.classes.componentContainer} ref={(divElement) => setHeaderEl(divElement)}>
          {header && (
            <RichTextRender htmlString={header} />
          )}
          {description && (
            <div style={{ marginBottom: ".5em", marginTop: descriptionMarginTop }}>
              <RichTextRender htmlString={description} />
            </div>
          )}
        </div>
        {showUpperRule && <div className="upper-bar">
          <hr
            style={{
              backgroundColor: horizontalRuleColor,
              border: "0 none",
              color: horizontalRuleColor,
              height: props.containerWidth * (horizontalRuleSize / 100),
              marginBottom: "1.5em",
            }}
          />
        </div>}
        {category && (<div
          className={props.classes.componentContainer}
          style={{
            width: props.containerWidth,
            marginBottom: "1em",
            paddingRight: '10em'
          }}
          ref={(el) => setCategoryEl(el)}
        >
          <RichTextRender htmlString={category} />
        </div>)}
        {/* list */}
        <div
          className="donor-list-container"
          style={{
            height: getDonorListHeight(),
            position: "relative",
          }}
        >
          {
            headerEl && containerEl && (footerEl || !footer) && (
              <DonorList2107
                {...props}
                key={baseFolder.id}
                containerHeight={getDonorListHeight()}
                refWidth={props.containerWidth} // idisplaycoponentprop?
                refHeight={props.containerHeight}
                containerWidth={getDonorListWidth()}
                data={{
                  ...props.data,
                  componentSpecificData: {
                    list_style: get(
                      baseFolder,
                      "fields.donor_list_style",
                      "filled"
                    ),
                    columns: get(baseFolder, "fields.columns", 3),
                    container_margin_top: get(
                      baseFolder,
                      "fields.container_margin_top",
                      0
                    ),
                    donor_list_margin_left: containerMarginLeft,
                    donor_list_margin_right: get(
                      baseFolder,
                      "fields.donor_list_margin_right",
                      0
                    ),
                    donor_list_column_margin_right: get(
                      baseFolder,
                      "fields.donor_list_column_margin_right",
                      0
                    ),
                    cycle: get(baseFolder, "fields.cycle_time", 5) * 1000,
                    donor_vertical_separation: get(
                      baseFolder,
                      "fields.donor_vertical_separation",
                      2.5
                    ),
                    donor_line_height:
                      get(baseFolder, "fields.donor_line_height", 1) * 100,
                    text_alignment: get(baseFolder, "fields.name_list.alignment", "left"),
                    donorNameStyle: {
                      fontFamily: get(baseFolder, "fields.name_list.font", "Gotham Book"),
                      fontSize: `${donorNameFontSize}em`,
                      color: get(baseFolder, "fields.name_list.color", fontColorDefault),
                      fontWeight: get(baseFolder, "fields.name_list.bold", false) ? "bold" : "normal",
                      textDecoration: get(baseFolder, "fields.name_list.bold", false) ? "underline" : "normal",
                      fontStyle: get(baseFolder, "fields.name_list.italic", false) ? "italic" : "normal",
                    },
                  },
                }}
              />
            )
          }
        </div>
        {/* /list */}
        {showLowerRule && <div className="lower-bar" ref={(htmlEl) => setLineRef(htmlEl)}>
          <hr
            style={{
              backgroundColor: horizontalRuleColor,
              border: "0 none",
              color: horizontalRuleColor,
              height: `${horizontalRuleSize}em`,
              marginBottom: "1.5em",
            }}
          />
        </div>}
        {
          footer && (
            <div
              className={props.classes.componentContainer}
              style={{
                position: "absolute",
                width: props.containerWidth,
                paddingRight: '10em',
                paddingBottom: '5em',
                bottom: (props.containerHeight / 3) - (props.containerHeight * 0.007614)
              }}
              ref={(el) => setFooterEl(el)}
            >
              <RichTextRender htmlString={footer} />
            </div>
          )
        }
      </div>
    </>
  );
};

export default NaplesFundFolderMiddleScreen;
