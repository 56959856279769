import _ from "lodash";

export function indexFromInterval(intervalMs, arrLength) {
  let totalTimePassed = new Date().getTime();
  let loops = totalTimePassed / intervalMs;
  return Math.floor(loops % arrLength);
}

export function elementIndexCurrentSeekTime(intervalMs) {
  let totalTimePassed = new Date().getTime();
  let loops = totalTimePassed / intervalMs;
  let currentLoop = Math.floor(loops);
  let currentTimeInInterval = totalTimePassed - currentLoop * intervalMs;

  return currentTimeInInterval;
}

export function currentSeekTimeArray(intervals) {
  let intervalsSum = [];
  intervals.reduce((a, b, i) => {
    return (intervalsSum[i] = a + b);
  }, 0);

  let totalTimePassed = new Date().getTime();
  let totalCycleTime = _.sum(intervals);
  let loops = totalTimePassed / totalCycleTime;
  let percentageIntoLoop = loops % 1;
  let timeIntoLoop = percentageIntoLoop * totalCycleTime;

  let index = _.findIndex(intervalsSum, (sum) => {
    return timeIntoLoop / sum < 1;
  });

  return timeIntoLoop % intervals[index];
}

// Returns the index of an element in array that contains
// several cycle times. I.E: intervals = [10, 15, 20]
export function indexFromIntervalArray(intervals) {
  let intervalsSum = [];
  intervals.reduce((a, b, i) => {
    return (intervalsSum[i] = a + b);
  }, 0);

  let totalTimePassed = new Date().getTime();
  let totalCycleTime = _.sum(intervals);
  let loops = totalTimePassed / totalCycleTime;
  let percentageIntoLoop = loops % 1;
  let timeIntoLoop = percentageIntoLoop * totalCycleTime;

  let index = _.findIndex(intervalsSum, (sum) => {
    return timeIntoLoop / sum < 1;
  });
  return index;
}

// Returns the total remainder cycle time of a loop that's
// conformed by an array that contains
// several cycle times. I.E: intervals = [10, 15, 20]
export function timeIntervalRemainderArrayMS(intervals, pushToTemp = false) {
  let intervalsSum = [];
  let totalTimePassed = new Date().getTime();
  intervals.reduce((a, b, i) => {
    return (intervalsSum[i] = a + b);
  }, 0);

  let totalCycleTime = _.sum(intervals);
  let loops = totalTimePassed / totalCycleTime;
  let percentageIntoLoop = loops % 1;
  let timeIntoLoop = percentageIntoLoop * totalCycleTime;

  let index = _.findIndex(intervalsSum, (sum) => {
    return timeIntoLoop < sum;
  });

  if (pushToTemp) {
    window.tmpLogArray.push(
      new Date() + " total cycle time: " + totalCycleTime
    );
    window.tmpLogArray.push(new Date() + " loops: " + loops);
    window.tmpLogArray.push(
      new Date() + " percentageIntoLoop: " + percentageIntoLoop
    );
    window.tmpLogArray.push(new Date() + " index calculated: " + timeIntoLoop);
  }

  return intervalsSum[index] - timeIntoLoop;
}

export function timeIntervalRemainderPercent(intervalMs) {
  let totalTimePassed = new Date().getTime();
  return 1 - ((totalTimePassed / intervalMs) % 1);
}

export function timeIntervalRemainderMS(intervalMs) {
  return this.timeIntervalRemainderPercent(intervalMs) * intervalMs;
}

export function diff(d1, d2, span) {
  let diff = Math.abs(d2 - d1);
  switch (span) {
    case "milliseconds":
      return diff;
    case "seconds":
      return diff / 1000;
    case "minutes":
      return diff / 1000 / 60;
    case "hours":
      return diff / 1000 / 60 / 60;
    case "days":
      return diff / 1000 / 60 / 60 / 24;
    default:
      return diff;
  }
}
