import { ErrorObject, ERROR_NAME, HTTP_STATUS } from "../interfaces/api/api-response.interface"

export const isResponseSuccess = (response: any): boolean => {
  if (!response || !response.status){
    return false;
  }
  return [HTTP_STATUS.ACCEPTED, HTTP_STATUS.CREATED, HTTP_STATUS.OK].includes(response.status)
}
export const ErrorByName = (name: ERROR_NAME, data = {}): ErrorObject => {
  switch (name){
    case ERROR_NAME.NOT_FOUND:
      return { message: 'The record could not be found', data}
    case ERROR_NAME.INVALID_PARAMS:
      return { message: 'Invalid params, please send valid params', data }
    case ERROR_NAME.UNAUTHORIZED:
      return { message: 'Not authorized', data }
    case ERROR_NAME.INVALID_CREDS:
      return { message: 'Email password combination not valid', data }
    case ERROR_NAME.INVALID_FILE:
      return { message: 'Invalid File', data }
    case ERROR_NAME.RECORD_EXISTS:
      return { message: 'Record already exists', data }
    case ERROR_NAME.RECORD_NOT_FOUND:
      return { message: 'Record not found', data }
    default:
      return { message: 'Something went wrong', data }
  }
}

export const ErrorCustom = (message: string, data = {}): ErrorObject => {
  return { message, data}
}
