import React, {Component} from "react";
import {connect} from "react-redux";
import DragDropTree from "../tree/DragDropTree";
import {
  fetchProjectFolders,
  moveResourceFolder,
  setFoldersExpanded,
} from "../../actions/folderActions";
import {setClientOptions} from "../../reducers/clientOptionsSlice";
import {
  toggleResourceFolderExpanded,
  toggleModalResourceFolderExpanded,
} from "../../actions/resourceActions";
import {getFileType} from "../../shared/utils/fileUtils";
import _ from "lodash";
import {handleTreeSearch} from "../../services/treeService";
import {TREE_MODE_SELECT_RESOURCE, TREE_MODE_RESOURCE} from "../constants";
import {getAppliedTreeDataAndFilter} from "../../selectors/resourcesAndFilterSelectors";

class ResourceTree extends Component {
  constructor(props) {
    super(props);
    this._searchString = null;
  }

  componentDidMount() {
    // this.props.setClientOptions({ resourcetree_modal_open: true });
  }

  componentWillUnmount() {
    // this.props.setClientOptions({ resourcetree_modal_open: false });
  }

  onDrop = (...args) => {
    let idOfFolderBeingMoved = args[0].id;
    let destId = args[1].id;

    // The id being moved, is being moved below the destId folder.
    // Otherwise, it will be moved (drop) into another folder (destId)
    if (typeof destId === "string") {
      this.props.moveResourceFolder(idOfFolderBeingMoved, destId, "move");
    } else {
      this.props.moveResourceFolder(idOfFolderBeingMoved, destId, "drop");
    }
  };

  onFilter = (filters) => {
    //console.log('filters in resource tree', filters)
  };

  selectResourceInTree = (treeId) => {
    // let resourceName;
    let url, thumbUrl;
    const resourceFolderId = treeId.replace("resource-", "");
    const resourceFolder = this.props.resourcetree_resource__folders[
      resourceFolderId
      ];
    const resource = this.props.resources[resourceFolder.resource];
    if (!resource) {
      return;
    }
    const fileType = getFileType(resource.name);
    let previewName = null;
    if (fileType === "video") {
      const videoPresets = _.get(resource, "contents.video_presets", null);
      if (videoPresets && videoPresets[360]) {
        // resourceName = `resouce_${resource.id}_360.${resource.extension}`;
      } else {
        // resourceName = resource.name;
      }
      previewName = `${resource.id}.${resource.extension}`;
    } else if (fileType === "image") {
      // const imagePresets = _.get(resource, 'contents.image_presets', null);
      // resourceName = resource.name;
      const thumbName = `${resource.id}_preview.${resource.extension}`;
      thumbUrl = this.getS3Url(thumbName, resource.company);
      previewName = `${resource.id}_preview.${resource.extension}`;
    } else {
      // resourceName = resource.name;
      previewName = `${resource.id}_preview.${resource.extension}`;
    }
    url = this.getS3Url(previewName, resource.company);
    // if (!thumbUrl) {
    //   thumbUrl = url;
    // }
    this.props.selectPreviewResource({
      ...resource,
      url: url,
      thumbUrl: thumbUrl,
    });
  }

  handleToggleFolderExpanded = (treeId, metaData, doOpenClose, doSelect) => {
    if (treeId.includes("folder-")) {
      let folderId = treeId.replace("folder-", "");
      let folder_type = this.props.resourcetree_folders[folderId].folder_type;
      this._addFolderAcceptableTypes = this.props.folder_types[
        folder_type
        ].acceptable_folder_types;
      this.props.selectPreviewResource(null);
    } else {
      if (treeId.includes("resource-")) {
        this.selectResourceInTree(treeId);
      } else {
        this.props.selectPreviewResource(null);
      }
      this._addFolderAcceptableTypes = [];
    }

    if (this.props.mode === "modal") {
      if (doOpenClose) {
        this.props.toggleModalResourceFolderExpanded(
          treeId,
          doOpenClose,
          doSelect
        );
      } else if (doSelect) {
        this.props.setClientOptions({resource_modal_tree_selected_id: treeId});
      }
    } else {
      this.props.toggleResourceFolderExpanded(treeId, doOpenClose, doSelect);
    }
  };

  getS3Url = (resourceName, companyId) => {
    const s3 = new this.props.aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2",
    });
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${companyId}/resource`,
      Key: resourceName,
    };
    return s3.getSignedUrl("getObject", params);
  };

  handleSearch = (value, modeContext) => {
    let result = handleTreeSearch(
      value,
      modeContext,
      this.props.folders,
      {},
      this.props.resourcetree_folders,
      this.props.resources,
      this.props.resourcetree_resource__folders,
      this.props.resourcetree_folder__folders,
      this.props.user.company,
      this.props.locations,
      this.props.projects
    );
    this._searchString = result.cleanSearch;
    if (this._searchString) {
      this.props.setFoldersExpanded(
        TREE_MODE_SELECT_RESOURCE,
        result._searchFoldersExpanded
      );
    } else {
      this.setState({});
    }
    this.props.setClientOptions({search_string_existing_resource: value});
  };

  render() {
    let resourceTree = this.props.treeData;
    return (
      <DragDropTree
        toggleDetail={() => {
        }}
        onDrop={() => {
        }}
        onFilter={(filters) => this.props.onFilter(filters)}
        isDraggingDisabled={true}
        treeData={resourceTree}
        defaultIcons={this.props.tree_icon_types}
        toggleExpandedItem={(treeId, metaData, doOpenClose, doSelect) => {
          this.handleToggleFolderExpanded(
            treeId,
            metaData,
            doOpenClose,
            doSelect
          );
        }}
        searchText={this._searchString}
        onSearch={(value, modeContext) => this.handleSearch(value, modeContext)}
        addSubfolder={null}
        addResource={null}
        deleteResource={null}
        folders={this.props.folders}
        resourcetreeFolders={this.props.resourcetree_folders}
        folderTypes={this.props.folder_types}
        mode={"resource"}
        modeContext={this.props.modeContext}
        width={407}
        height={0.5 * window.innerHeight}
        showSettings={this.props.showSettings || false}
        inputMode="modal"
        updateSelectedTreeId={(treeId) => {
          const updateClientOptions = {};
          if (treeId.startsWith("folder-")) {
            updateClientOptions.resource_selected_folder = Number(
              treeId.substring(7)
            );
          } else if (treeId.startsWith("resource-")) {
            updateClientOptions.resource_modal_tree_selected_folder = Number(
              treeId.substring(9)
            );
          } else {
            updateClientOptions.resource_modal_tree_selected_folder = null;
          }
          this.props.setClientOptions(
            (updateClientOptions.resource_modal_tree_selected_folder = null)
          );
        }}
        selectedTreeId={
          this.props.clientOptions.resource_modal_tree_selected_id
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let timer = new Date();
  let filters = [];
  if (ownProps.filters) {
    filters = ownProps.filters;
  } else {
    filters = state.client_options.resource_tree_filters;
    //get filters from client options
  }

  let modState = {
    ...state,
    filters: filters || [],
    treemode_override: TREE_MODE_RESOURCE,
    mode: ownProps.mode ? ownProps.mode : "normal",
  };

  let treeData = getAppliedTreeDataAndFilter(modState);

  let difference = new Date().getTime() - timer.getTime();
  return {
    clientOptions: state.client_options,
    treeData: treeData,
    time: difference,
    resourcetree_folders: state.data.resourcetree_folders,
    resourcetree_folder__folders: state.data.resourcetree_folder__folders,
    resourcetree_resource__folders: state.data.resourcetree_resource__folders,
    tree_icon_types: state.data.tree_icon_types,
    folders: state.data.folders,
    folder_types: state.data.folder_types,
    resources: state.data.resources,
    aws_instance: state.data.aws_instance,
    modeContext: ownProps.modeContext,
    searchString: state.data.searchString,
    user: state.data.user,
    locations: state.data.locations,
    projects: state.data.projects,
    folder_results: state.data.folder_results,
    resource_results: state.data.resource_results,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectFolders: (onSuccess) => {
    dispatch(fetchProjectFolders(ownProps.type, onSuccess));
  },
  toggleResourceFolderExpanded: (treeId, doOpenClose, doSelect) => {
    dispatch(toggleResourceFolderExpanded(treeId, doOpenClose, doSelect, TREE_MODE_RESOURCE));
  },
  toggleModalResourceFolderExpanded: (
    treeId,
    doOpenClose,
    doSelect,
    onSuccess = null,
    onFail = null
  ) => {
    dispatch(
      toggleModalResourceFolderExpanded(
        treeId,
        doOpenClose,
        doSelect,
        onSuccess,
        onFail
      )
    );
  },
  moveResourceFolder: (id, destId, actionType, onSuccess, onFail) => {
    dispatch(moveResourceFolder(id, destId, actionType, onSuccess, onFail));
  },
  setClientOptions: (client_options) => {
    dispatch(setClientOptions(client_options));
  },
  setFoldersExpanded: (treeMode, treeIds) => {
    dispatch(setFoldersExpanded(treeMode, treeIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceTree);
