import { createSelector } from "@reduxjs/toolkit";
import { getFlattenedFolderFields } from "../shared/utils/generalUtils";

const getSelectedFolder = (_state, props) => props.selected_folder;
const getFolderTypes = (state) => state.data.folder_types;
const getFolderType = (_state, props) => props.folder_type;
const getFolderFolders = (state) => state.data.folder__folders;
const getFolders = (state) => state.data.folders;
const getColumns = (_state, props) => props.folder_type.list_view.columns;
// if the resource or the resource folder change means a file props changed, so it listen that change too
const getResources = (state) => state.data.resources;
const getResourcesFolder = (state) => state.data.resource__folders;

export const selectedFolderAcceptableTypes = createSelector(
  [getSelectedFolder, getFolderTypes],
  (selectedFolder, folderTypes) => {
    const selectedFolderType = folderTypes[selectedFolder.type];
    const acceptable = selectedFolderType.acceptable;
    return acceptable.map((t) => folderTypes[t]);
  }
);

export const references = createSelector([], () => {
  return [];
});

/**
 *
 * @param field
 * @param folder_types: array of acceptable folder_types for a list_view
 * @param folderTypesObj: all folderTypes
 */
const findFirstFieldFromFolderType = (field, folder_types, folderTypesObj) => {
  let result = null;
  folder_types.some((ft) => {
    const folderTypeObj = folderTypesObj[ft];
    if (folderTypeObj && folderTypeObj.fields && folderTypeObj.fields[field]) {
      result = folderTypeObj.fields[field];
      return true;
    }
  });
  return result;
};

export const getItemsAndColumns = createSelector(
  [
    getFolderFolders,
    getFolders,
    getFolderType,
    getFolderTypes,
    getColumns,
    getSelectedFolder,
    getResources,
    getResourcesFolder,
  ],
  (
    folderFolders,
    folders,
    folderType,
    folderTypes,
    columns,
    selectedFolder,
    resources,
    resourcesFolder
  ) => {
    const folderFoldersArr = Object.values(folderFolders);
    const listItems = [];
    const listColumns = columns;
    folderFoldersArr.forEach((item: any) => {
      if (item.parent_folder === selectedFolder.id) {
        const childFolder = folders[item.child_folder];
        if (
          childFolder &&
          folderType.list_view.list_folder_types.includes(
            childFolder.folder_type
          )
        ) {
          let itemFolderType = folderTypes[childFolder.folder_type];
          let flattenedFields = getFlattenedFolderFields(childFolder);
          flattenedFields.list_view_selected = false;
          flattenedFields.rank = item.rank;
          flattenedFields.updatedAt = item.updatedAt;
          flattenedFields.updatedBy = childFolder.updatedBy;

          //push item to list
          listItems.push({
            id: listItems.length,
            folderFolderId: item.id,
            folderId: childFolder.id,
            type: itemFolderType.name,
            ...flattenedFields,
          });
        }
      }
    });

    listColumns.forEach((column) => {
      const { folder_types, field } = column;

      let field_data = findFirstFieldFromFolderType(
        field,
        folder_types,
        folderTypes
      );
      if (field_data) {
        column.type = field_data.type;
        column.field_data = { [column.field]: field_data };
        return;
      }
      if (column.field === "list_view_selected") {
        column.field_data = {
          list_view_selected: {
            type: "bool",
            title: "",
            validation: { rules: [] },
            default: false,
          },
        };
        return;
      }
    });

    return { listItems, listColumns };
  }
);
