import _, { get } from "lodash";
import React, { Component, useState } from "react";
import { IState } from "../../interfaces/main-state.interface";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces.js";
import PreviewContainer from "./PreviewContainer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils';
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IURIAccess } from "../../shared-global/interfaces/models/uri_access.interface";
import { bestFit } from "../../shared/utils/screenUtils";
import { COMPONENT_MODE_CONTEXT } from "../../shared-global/enums/config-enums";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import {getCurrentTemplate} from "../../utils/themeUtils";
import { getFileType } from "../../shared/utils/fileUtils";

interface IWebviewContainerProps {
  uri_access: IURIAccess
}

const WebviewContainer: React.FC<IWebviewContainerProps> = (props: IWebviewContainerProps) => {
  const dispatch = useDispatch();
  const folder_types: IFolderType[] = useSelector((state: IState) => arrayFromKeyedObject(state.data.folder_types));
  const signed_urls = useSelector((state: IState) => state.data.webview_signedurls);
  const folders = useSelector((state: IState) => state.data.folders);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [width, setWidth] = useState<null | number>(null);
  const [height, setHeight] = useState<null | number>(null)

  const [isLoading, setIsLoading] = useState(true);
  const [folderType, setFolderType] = useState<IFolderType>(null);
  const [viewWidth, setViewWidth] = useState(document.documentElement.clientWidth)
  const [viewHeight, setViewHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    dispatch(setClientOptions({ selected_folder_meta: { folder__folder_id: 30100 } }));

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (!props.uri_access){
      return;
    }
    if (!folderType) {
      const foldersArr = arrayFromKeyedObject(folders);
      const folder: IFolder = foldersArr.find((x: IFolder) => x.id === props.uri_access.folder);
      let useChildFolder = false;
      let foundSelectedFolderId = props.uri_access.folder;

      let matchedType = folder_types.find(
        (ft: IFolderType) => ft.name === folder.folder_type
      );

      if (matchedType) {
        // Check if there's a specific settings for the webview.
        if (matchedType.webview && matchedType.webview?.use_child_folder_type) {
          useChildFolder = true;
          matchedType = folder_types.find((ft: IFolderType) => ft.name === matchedType.webview.use_child_folder_type);
          const foundSelectedFolder = foldersArr.find(f => f.folder_type === matchedType.name);
          foundSelectedFolderId = foundSelectedFolder.id;
        }
      }

      const aspectRatio = get(matchedType, 'preview.aspect_ratio', 1.777778);
      let ww = aspectRatio
      let hh = 1
      const dims = bestFit(ww, hh, document.documentElement.clientWidth, window.innerHeight);
      setWidth(dims.width)
      setHeight(dims.height)
      if (matchedType) {
        setSelectedFolder(foundSelectedFolderId);
        setFolderType(matchedType);
      }
    }

    const signed_urls_to_preload = Object.values(signed_urls).filter(i => i.uuid_preset.indexOf('-preview') > -1).map(i => i.url)

    const preloadResource = async (u: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        try {
          const url = new URL(u)
          const fileName = _.last(url.pathname.split('/'))
          const type = getFileType(fileName)
          if (type === 'image') {
            const img = new Image()
            img.src = url.toString()
            img.onload = () => resolve()
            img.onerror = () => reject()
          }
        } catch (ex) {
          reject()
        }
      })
    }

    const preloadResources = async (urls: string[]): Promise<void> => {
      Promise.all(await urls.map(url => preloadResource(url)))
      setIsLoading(false);
    }

    // ensure all required data is present
    if (folderType && signed_urls && !hasEmptyObject([folders])) {
      preloadResources(signed_urls_to_preload);
    }
  }, [folder_types, signed_urls, viewHeight, viewWidth]);

  const handleWindowResize = () => {
    setViewWidth(document.documentElement.clientWidth)
    setViewHeight(window.innerHeight)
  };

  const hasEmptyObject = (objects: any) => {
      let hasEmpty = false;
        objects.forEach((obj: any) => {
            if (Object.keys(obj).length === 0 && obj.constructor === Object){
                hasEmpty = true;
            }
      })
      return hasEmpty;
  }

  if (isLoading || !width || !height) {
    return <div>Loading ...</div>;
  }

  const currentTemplate = getCurrentTemplate(folderType, folder_types);

  return (
    <div style={{height: '100vh', width: '100vw', backgroundColor: currentTemplate === 'monterrey' ? '#9AA0A6' : "#FFFFFF"}} className="flexcenter" key={viewWidth * viewHeight}>
    {selectedFolder && (
      <PreviewContainer
        mode={COMPONENT_MODE_CONTEXT.WEBVIEW}
        width={width}
        height={height}
        selected_folder={folders[selectedFolder]}
        folder_type={folderType}
        mobile={false}
        webview_signedurls={signed_urls}
      />
    )}
    </div>

  );
};

export default WebviewContainer;
