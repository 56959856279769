import { get } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";

const IDCWrap: React.FC<IDisplayComponentProps> = (props) => {
  const params = useParams();

  const tryInherit = (folderId) => {
    const matchedFolder = props.data?.folders?.find((f) => f.id === folderId);

    const matchedType = props.data?.folder_types?.find(
      (ft) => ft.name === matchedFolder?.folder_type
    );

    let returnInherit = {};
    if (matchedFolder && matchedType) {
      const fieldKeys = Object.keys(matchedFolder.fields);
      fieldKeys.forEach((fieldKey) => {
        const passDown = get(
          matchedType,
          "fields[" + fieldKey + "].inheritable_settings.pass_down_to_children",
          false
        );
        if (passDown) {
          returnInherit = {
            ...returnInherit,
            [fieldKey]: matchedFolder.fields[fieldKey],
          };
        }
      });
    }
    return returnInherit;
  };

  const getClonedChildren = (children) => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      let inheritableFields = props.inheritable_fields || {};

      if (props.nestedInRouter === true) {
        const routerParams = Object.keys(params);
        routerParams.forEach((pkey) => {
          const numParam = Number(params[pkey]);
          if (!isNaN(numParam)) {
            inheritableFields = {
              ...inheritableFields,
              ...tryInherit(numParam),
            };
          }
        });
      } else {
        inheritableFields = {
          ...inheritableFields,
          ...tryInherit(props.data?.base_folder),
        };
      }

      const inheritable_fields = {
        inheritable_fields: inheritableFields,
      };
      return React.cloneElement(child, inheritable_fields);
    });
  };

  return <>{getClonedChildren(props.children)}</>;
};

export default IDCWrap;
