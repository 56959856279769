module.exports = {
    "listHeight": 539,
    "listItems": [
        {
            "folderId": 43,
            "name": "21st. Century Oncology",
            "sorting_order": "As Entered",
            "list_view_selected": false,
            "id": 0
        },
        {
            "folderId": 47,
            "name": "Ms. Pamela Lynn Abraham",
            "list_view_selected": false,
            "id": 1
        },
        {
            "folderId": 48,
            "name": "Acuson Computed Sonography",
            "list_view_selected": false,
            "id": 2
        },
        {
            "folderId": 49,
            "name": "Mr. James Adams",
            "list_view_selected": false,
            "id": 3
        },
        {
            "folderId": 50,
            "name": "Aetna Foundation",
            "list_view_selected": false,
            "id": 4
        },
        {
            "folderId": 51,
            "name": "Cheryl & Vlad Agranovich",
            "list_view_selected": false,
            "id": 5
        },
        {
            "folderId": 52,
            "name": "Aileen S. Andrew Foundation",
            "list_view_selected": false,
            "id": 6
        },
        {
            "folderId": 53,
            "name": "Akerman, LLP",
            "list_view_selected": false,
            "id": 7
        },
        {
            "folderId": 54,
            "name": "Mr. & Mrs. Richard B. Akin",
            "list_view_selected": false,
            "id": 8
        },
        {
            "folderId": 55,
            "name": "Ms. Betsy Alderman",
            "list_view_selected": false,
            "id": 9
        },
        {
            "folderId": 56,
            "name": "Dr. O. A. Alea",
            "list_view_selected": false,
            "id": 10
        },
        {
            "folderId": 57,
            "name": "Mr. & Mrs. Jules Allen",
            "list_view_selected": false,
            "id": 11
        },
        {
            "folderId": 58,
            "name": "Mr. & Mrs. Robert R. Allen",
            "list_view_selected": false,
            "id": 12
        },
        {
            "folderId": 59,
            "name": "Alliance Financial Group",
            "list_view_selected": false,
            "id": 13
        },
        {
            "folderId": 60,
            "name": "Allyn International Services, Inc.",
            "list_view_selected": false,
            "id": 14
        },
        {
            "folderId": 61,
            "name": "Alpha Home Care Services, LLC",
            "list_view_selected": false,
            "id": 15
        },
        {
            "folderId": 62,
            "name": "American Cancer Society ‑ Service Business Center",
            "sorting_order": "111",
            "list_view_selected": false,
            "id": 16
        },
        {
            "folderId": 63,
            "name": "American International Group, Inc.",
            "list_view_selected": false,
            "id": 17
        },
        {
            "folderId": 64,
            "name": "Mrs. Florence Anderson",
            "list_view_selected": false,
            "id": 18
        },
        {
            "folderId": 65,
            "name": "Mrs. Karen Anderson",
            "list_view_selected": false,
            "id": 19
        },
        {
            "folderId": 66,
            "name": "Mr. Edward R. Andrus",
            "list_view_selected": false,
            "id": 20
        },
        {
            "folderId": 67,
            "name": "Ms. Anna M. St Angelo",
            "list_view_selected": false,
            "id": 21
        },
        {
            "folderId": 68,
            "name": "Ann F. Dodson Trust",
            "list_view_selected": false,
            "id": 22
        },
        {
            "folderId": 69,
            "name": "Anonymous (5)",
            "list_view_selected": false,
            "id": 23
        },
        {
            "folderId": 70,
            "name": "Mrs. Gaile H. Anthony",
            "list_view_selected": false,
            "id": 24
        },
        {
            "folderId": 71,
            "name": "Dr. & Mrs. Lawrence R. Antonucci",
            "list_view_selected": false,
            "id": 25
        },
        {
            "folderId": 72,
            "name": "J. Keith & Maureen Arnold",
            "list_view_selected": false,
            "id": 26
        },
        {
            "folderId": 73,
            "name": "Arthrex Foundation",
            "list_view_selected": false,
            "id": 27
        },
        {
            "folderId": 74,
            "name": "Arthur B. McBride Sr. Family Foundation",
            "list_view_selected": false,
            "id": 28
        },
        {
            "folderId": 75,
            "name": "Arthur Rutenberg Homes",
            "list_view_selected": false,
            "id": 29
        },
        {
            "folderId": 76,
            "name": "Mr. & Mrs. Ronald Ascione",
            "list_view_selected": false,
            "id": 30
        },
        {
            "folderId": 77,
            "name": "Joseph Clark Atkins & Elizabeth Atkins Charitable Remainder",
            "list_view_selected": false,
            "id": 31
        },
        {
            "folderId": 78,
            "name": "Ms. & Mr. Rick Atkinson",
            "list_view_selected": false,
            "id": 32
        },
        {
            "folderId": 79,
            "name": "AtriCure",
            "list_view_selected": false,
            "id": 33
        },
        {
            "folderId": 80,
            "name": "Audi of Fort Myers",
            "list_view_selected": false,
            "id": 34
        },
        {
            "folderId": 81,
            "name": "Audi of Naples/Volkswagen of Naples",
            "list_view_selected": false,
            "id": 35
        },
        {
            "folderId": 82,
            "name": "Mr. & Mrs. Norman R. Augustine",
            "list_view_selected": false,
            "id": 36
        },
        {
            "folderId": 83,
            "name": "Ayco Charitable Foundation",
            "list_view_selected": false,
            "id": 37
        },
        {
            "folderId": 84,
            "name": "B & I Contractors, Inc.",
            "list_view_selected": false,
            "id": 38
        },
        {
            "folderId": 85,
            "name": "Mr. G. C. Bailey",
            "list_view_selected": false,
            "id": 39
        },
        {
            "folderId": 86,
            "name": "Mrs. June E. Bailey",
            "list_view_selected": false,
            "id": 40
        },
        {
            "folderId": 87,
            "name": "Bailey's General Store",
            "list_view_selected": false,
            "id": 41
        },
        {
            "folderId": 88,
            "name": "In Memory of Betty Ball",
            "list_view_selected": false,
            "id": 42
        },
        {
            "folderId": 89,
            "name": "Bank of America",
            "list_view_selected": false,
            "id": 43
        },
        {
            "folderId": 90,
            "name": "Bank of the Islands",
            "list_view_selected": false,
            "id": 44
        },
        {
            "folderId": 91,
            "name": "Dr. & Mrs. Mark Banks",
            "list_view_selected": false,
            "id": 45
        },
        {
            "folderId": 92,
            "name": "Baptist Health System South Florida",
            "list_view_selected": false,
            "id": 46
        },
        {
            "folderId": 93,
            "name": "Ms. Barbara E. Truitt",
            "list_view_selected": false,
            "id": 47
        },
        {
            "folderId": 94,
            "name": "Mrs. Alice Barrett",
            "list_view_selected": false,
            "id": 48
        },
        {
            "folderId": 95,
            "name": "Barron Collier, Jr. Foundation, Inc.",
            "list_view_selected": false,
            "id": 49
        },
        {
            "folderId": 96,
            "name": "Barsull Corporation INC.",
            "list_view_selected": false,
            "id": 50
        },
        {
            "folderId": 97,
            "name": "Ms. Mary S. Barth",
            "list_view_selected": false,
            "id": 51
        },
        {
            "folderId": 98,
            "name": "Mr. Richard A. Baus",
            "list_view_selected": false,
            "id": 52
        },
        {
            "folderId": 99,
            "name": "Mr. David J. Bautsch",
            "list_view_selected": false,
            "id": 53
        },
        {
            "folderId": 100,
            "name": "BB&T",
            "list_view_selected": false,
            "id": 54
        },
        {
            "folderId": 101,
            "name": "BBDO",
            "list_view_selected": false,
            "id": 55
        },
        {
            "folderId": 102,
            "name": "BD Global, LLC",
            "list_view_selected": false,
            "id": 56
        },
        {
            "folderId": 103,
            "name": "Mr. & Mrs. Paul Beechen",
            "list_view_selected": false,
            "id": 57
        },
        {
            "folderId": 104,
            "name": "Mr. Eugene A. Beeler",
            "list_view_selected": false,
            "id": 58
        },
        {
            "folderId": 105,
            "name": "Mr. & Mrs. Lee D. Behrhorst",
            "list_view_selected": false,
            "id": 59
        },
        {
            "folderId": 106,
            "name": "Ms. Karen Bell",
            "list_view_selected": false,
            "id": 60
        },
        {
            "folderId": 107,
            "name": "Edward & Rachel Bell",
            "list_view_selected": false,
            "id": 61
        },
        {
            "folderId": 108,
            "name": "Shane and Sheldon Bellestri",
            "list_view_selected": false,
            "id": 62
        },
        {
            "folderId": 109,
            "name": "Mr. Jeff Belzer",
            "list_view_selected": false,
            "id": 63
        },
        {
            "folderId": 110,
            "name": "Mr. Peter J. Bender",
            "list_view_selected": false,
            "id": 64
        },
        {
            "folderId": 111,
            "name": "Mr. & Mrs. Michael L. Bennett",
            "list_view_selected": false,
            "id": 65
        },
        {
            "folderId": 112,
            "name": "Mr. & Mrs. Robert T. Berendt",
            "list_view_selected": false,
            "id": 66
        },
        {
            "folderId": 113,
            "name": "Mr. & Mrs. James Berg",
            "list_view_selected": false,
            "id": 67
        },
        {
            "folderId": 114,
            "name": "Lynne & Marvin Birdt",
            "list_view_selected": false,
            "id": 68
        },
        {
            "folderId": 115,
            "name": "Frank W. Bireley",
            "list_view_selected": false,
            "id": 69
        },
        {
            "folderId": 116,
            "name": "Mr. Frank Bireley Jr.",
            "list_view_selected": false,
            "id": 70
        },
        {
            "folderId": 117,
            "name": "Mr. & Mrs. Roger Bireley",
            "list_view_selected": false,
            "id": 71
        },
        {
            "folderId": 118,
            "name": "Mr. Edward G. Bischoff Jr.",
            "list_view_selected": false,
            "id": 72
        },
        {
            "folderId": 119,
            "name": "Mr. C. W. Black",
            "list_view_selected": false,
            "id": 73
        },
        {
            "folderId": 120,
            "name": "Mrs. Betty Blackwell",
            "list_view_selected": false,
            "id": 74
        },
        {
            "folderId": 121,
            "name": "Willis & Mary Blackwood",
            "list_view_selected": false,
            "id": 75
        },
        {
            "folderId": 122,
            "name": "Virginia Blaesi",
            "list_view_selected": false,
            "id": 76
        },
        {
            "folderId": 123,
            "name": "Mr. Andrew Bloom",
            "list_view_selected": false,
            "id": 77
        },
        {
            "folderId": 124,
            "name": "Blue Cross & Blue Shield of Florida",
            "list_view_selected": false,
            "id": 78
        },
        {
            "folderId": 125,
            "name": "Boater's Landing",
            "list_view_selected": false,
            "id": 79
        },
        {
            "folderId": 126,
            "name": "Mr. & Mrs. William W. Bohn",
            "list_view_selected": false,
            "id": 80
        },
        {
            "folderId": 127,
            "name": "Mrs. Pam K. Bolton",
            "list_view_selected": false,
            "id": 81
        },
        {
            "folderId": 128,
            "name": "Bonita Bay Club",
            "list_view_selected": false,
            "id": 82
        },
        {
            "folderId": 129,
            "name": "The Bonita Bay Group",
            "list_view_selected": false,
            "id": 83
        },
        {
            "folderId": 130,
            "name": "Bonita Springs Assistance Office, Inc.",
            "list_view_selected": false,
            "id": 84
        },
        {
            "folderId": 131,
            "name": "Bonita Springs Lions Charities, Inc.",
            "list_view_selected": false,
            "id": 85
        },
        {
            "folderId": 132,
            "name": "Mrs. Nancy Boots",
            "list_view_selected": false,
            "id": 86
        },
        {
            "folderId": 133,
            "name": "Ms. Alexandra Bordiuk",
            "list_view_selected": false,
            "id": 87
        },
        {
            "folderId": 134,
            "name": "Mr. & Mrs. Robert W. Boreham",
            "list_view_selected": false,
            "id": 88
        },
        {
            "folderId": 135,
            "name": "Steve King & Susan Boren",
            "list_view_selected": false,
            "id": 89
        },
        {
            "folderId": 136,
            "name": "Boston Red Sox",
            "list_view_selected": false,
            "id": 90
        },
        {
            "folderId": 137,
            "name": "Boston Retirement Advisors, LLC",
            "list_view_selected": false,
            "id": 91
        },
        {
            "folderId": 138,
            "name": "Bovis Lend Lease, Inc.",
            "list_view_selected": false,
            "id": 92
        },
        {
            "folderId": 139,
            "name": "Mr. & Mrs. Wayne Boyd",
            "list_view_selected": false,
            "id": 93
        },
        {
            "folderId": 140,
            "name": "Mr. & Mrs. Thomas F. Boyle",
            "list_view_selected": false,
            "id": 94
        },
        {
            "folderId": 141,
            "name": "Bracco Diagnostics, Inc.",
            "list_view_selected": false,
            "id": 95
        },
        {
            "folderId": 142,
            "name": "Mr. & Mrs. John Brando",
            "list_view_selected": false,
            "id": 96
        },
        {
            "folderId": 143,
            "name": "Mr. & Mrs. Stephen Bransfield",
            "list_view_selected": false,
            "id": 97
        },
        {
            "folderId": 144,
            "name": "Mr. & Mrs. Stephen R. Bright",
            "list_view_selected": false,
            "id": 98
        },
        {
            "folderId": 145,
            "name": "Britt and Diane Newhouse Charitable Fund",
            "list_view_selected": false,
            "id": 99
        },
        {
            "folderId": 146,
            "name": "Broadway Palm Dinner Theater",
            "list_view_selected": false,
            "id": 100
        },
        {
            "folderId": 147,
            "name": "Mr. & Mrs. Barry Bronstein",
            "list_view_selected": false,
            "id": 101
        },
        {
            "folderId": 148,
            "name": "Brooke's Restaurant & Lounge",
            "list_view_selected": false,
            "id": 102
        },
        {
            "folderId": 149,
            "name": "Brown & Brown of Florida, Inc.",
            "list_view_selected": false,
            "id": 103
        },
        {
            "folderId": 150,
            "name": "Mr. & Mrs. Richard L. Brown",
            "list_view_selected": false,
            "id": 104
        },
        {
            "folderId": 151,
            "name": "Mr. & Mrs. Rick Brown",
            "list_view_selected": false,
            "id": 105
        },
        {
            "folderId": 152,
            "name": "Mr. & Mrs. Charles Bruning",
            "list_view_selected": false,
            "id": 106
        },
        {
            "folderId": 153,
            "name": "Bruno's of Brooklyn Italian Eatery",
            "list_view_selected": false,
            "id": 107
        },
        {
            "folderId": 154,
            "name": "Mr. William T. Bryan",
            "list_view_selected": false,
            "id": 108
        },
        {
            "folderId": 155,
            "name": "Buchanan, Ingersoll & Rooney",
            "list_view_selected": false,
            "id": 109
        },
        {
            "folderId": 156,
            "name": "Mr. Thomas A. Buckley",
            "list_view_selected": false,
            "id": 110
        },
        {
            "folderId": 157,
            "name": "Mr. & Mrs. Charles Budreck",
            "list_view_selected": false,
            "id": 111
        },
        {
            "folderId": 158,
            "name": "Build, L.L.C.",
            "list_view_selected": false,
            "id": 112
        },
        {
            "folderId": 159,
            "name": "Mr. & Mrs. Edmund Burke",
            "list_view_selected": false,
            "id": 113
        },
        {
            "folderId": 160,
            "name": "Mr. & Mrs. Jack Burns",
            "list_view_selected": false,
            "id": 114
        },
        {
            "folderId": 161,
            "name": "Burt L. Saunders Campaign Account",
            "list_view_selected": false,
            "id": 115
        },
        {
            "folderId": 162,
            "name": "Bush Industries, Inc.",
            "list_view_selected": false,
            "id": 116
        },
        {
            "folderId": 163,
            "name": "Mr. Peter Malen & Ms. Mary E. Byck",
            "list_view_selected": false,
            "id": 117
        },
        {
            "folderId": 164,
            "name": "Byck‑Rothschild Foundation, Inc.",
            "list_view_selected": false,
            "id": 118
        },
        {
            "folderId": 165,
            "name": "Dr. & Mrs. Craig Byers",
            "list_view_selected": false,
            "id": 119
        },
        {
            "folderId": 166,
            "name": "Mr. & Mrs. Robert T. Cahill",
            "list_view_selected": false,
            "id": 120
        },
        {
            "folderId": 167,
            "name": "Mr. Mike Calbot",
            "list_view_selected": false,
            "id": 121
        },
        {
            "folderId": 168,
            "name": "Cameratta Companies",
            "list_view_selected": false,
            "id": 122
        },
        {
            "folderId": 169,
            "name": "Mr. & Mrs. Don L. Canterna",
            "list_view_selected": false,
            "id": 123
        },
        {
            "folderId": 170,
            "name": "Cape Coral Community Foundation",
            "list_view_selected": false,
            "id": 124
        },
        {
            "folderId": 171,
            "name": "Capital Properties Group, Inc.",
            "list_view_selected": false,
            "id": 125
        },
        {
            "folderId": 172,
            "name": "Captiva Cruises",
            "list_view_selected": false,
            "id": 126
        },
        {
            "folderId": 173,
            "name": "Mr. & Mrs. Ed Catalano",
            "list_view_selected": false,
            "id": 127
        },
        {
            "folderId": 174,
            "name": "Catania Spagna Corp.",
            "list_view_selected": false,
            "id": 128
        },
        {
            "folderId": 175,
            "name": "Mr. & Mrs. B. Proctor Caudill, Jr.",
            "list_view_selected": false,
            "id": 129
        },
        {
            "folderId": 176,
            "name": "Centex Construction Healthcare",
            "list_view_selected": false,
            "id": 130
        },
        {
            "folderId": 177,
            "name": "Central Indiana Community Foundation & The Indianapolis FDN",
            "list_view_selected": false,
            "id": 131
        },
        {
            "folderId": 178,
            "name": "Mr. & Mrs. Duane Chaney",
            "list_view_selected": false,
            "id": 132
        },
        {
            "folderId": 179,
            "name": "Charlotte County Chamber of Commerce",
            "list_view_selected": false,
            "id": 133
        },
        {
            "folderId": 180,
            "name": "Chatlos Foundation",
            "list_view_selected": false,
            "id": 134
        },
        {
            "folderId": 181,
            "name": "Mr. & Mrs. Jack Chestnut",
            "list_view_selected": false,
            "id": 135
        },
        {
            "folderId": 182,
            "name": "Chick‑fil‑A",
            "list_view_selected": false,
            "id": 136
        },
        {
            "folderId": 183,
            "name": "Chick‑fil‑A at Port Charlotte FSU",
            "list_view_selected": false,
            "id": 137
        },
        {
            "folderId": 184,
            "name": "Mr. & Mrs. Thomas W. Childress",
            "list_view_selected": false,
            "id": 138
        },
        {
            "folderId": 185,
            "name": "City Mattress of Florida, Inc.",
            "list_view_selected": false,
            "id": 139
        },
        {
            "folderId": 186,
            "name": "Clark Family Foundation Inc.",
            "list_view_selected": false,
            "id": 140
        },
        {
            "folderId": 187,
            "name": "Mr. & Mrs. Arthur L. Cleary",
            "list_view_selected": false,
            "id": 141
        },
        {
            "folderId": 188,
            "name": "Mr. & Mrs. Philip J. Clemente",
            "list_view_selected": false,
            "id": 142
        },
        {
            "folderId": 189,
            "name": "Mr. & Mrs. Gary A. Clewell",
            "list_view_selected": false,
            "id": 143
        },
        {
            "folderId": 190,
            "name": "P.O.L.O. Club of Lee County",
            "list_view_selected": false,
            "id": 144
        },
        {
            "folderId": 191,
            "name": "Coconut Point Ford",
            "list_view_selected": false,
            "id": 145
        },
        {
            "folderId": 192,
            "name": "Mrs. Phyllis L. Coffin",
            "list_view_selected": false,
            "id": 146
        },
        {
            "folderId": 193,
            "name": "Dr. & Mrs. Richard C. Cohan",
            "list_view_selected": false,
            "id": 147
        },
        {
            "folderId": 194,
            "name": "Mr. & Mrs. Howard K. Cohen",
            "list_view_selected": false,
            "id": 148
        },
        {
            "folderId": 195,
            "name": "Sandra & Dr. Sanford Cohen",
            "list_view_selected": false,
            "id": 149
        },
        {
            "folderId": 196,
            "name": "Ms. Brittany F. Cohill",
            "list_view_selected": false,
            "id": 150
        },
        {
            "folderId": 197,
            "name": "Mr. & Mrs. Steve Colgate",
            "list_view_selected": false,
            "id": 151
        },
        {
            "folderId": 198,
            "name": "Collector Cars TV Show",
            "list_view_selected": false,
            "id": 152
        },
        {
            "folderId": 199,
            "name": "Dr. & Mrs. Michael E. Collier",
            "list_view_selected": false,
            "id": 153
        },
        {
            "folderId": 200,
            "name": "Mrs. Bonnie Collins",
            "list_view_selected": false,
            "id": 154
        },
        {
            "folderId": 201,
            "name": "Mr. & Mrs. David F. Collins",
            "list_view_selected": false,
            "id": 155
        },
        {
            "folderId": 202,
            "name": "John Collins",
            "list_view_selected": false,
            "id": 156
        },
        {
            "folderId": 203,
            "name": "Colonial Country Club Ladies 9 Hole Golf Assn.",
            "list_view_selected": false,
            "id": 157
        },
        {
            "folderId": 204,
            "name": "Colonial Country Club of Lee Co. Master Association",
            "list_view_selected": false,
            "id": 158
        },
        {
            "folderId": 205,
            "name": "Amazon.com",
            "list_view_selected": false,
            "id": 159
        },
        {
            "folderId": 206,
            "name": "Commonwealth Purchasing Group",
            "list_view_selected": false,
            "id": 160
        },
        {
            "folderId": 207,
            "name": "WCI Communities, Inc.",
            "list_view_selected": false,
            "id": 161
        },
        {
            "folderId": 208,
            "name": "Community Foundation of Collier County",
            "list_view_selected": false,
            "id": 162
        },
        {
            "folderId": 209,
            "name": "Community Foundation of Sanibel‑Captiva",
            "list_view_selected": false,
            "id": 163
        },
        {
            "folderId": 210,
            "name": "Mr. & Mrs. Bruce D. Conley",
            "list_view_selected": false,
            "id": 164
        },
        {
            "folderId": 211,
            "name": "Mr. & Mrs. Tracy A. Connelly",
            "list_view_selected": false,
            "id": 165
        },
        {
            "folderId": 212,
            "name": "Ms. Jane Cookson",
            "list_view_selected": false,
            "id": 166
        },
        {
            "folderId": 213,
            "name": "Mr. & Mrs. Gordon R. Coons",
            "list_view_selected": false,
            "id": 167
        },
        {
            "folderId": 214,
            "name": "Cornerstone Financial Planning",
            "list_view_selected": false,
            "id": 168
        },
        {
            "folderId": 215,
            "name": "Corvettes On The Gulf",
            "list_view_selected": false,
            "id": 169
        },
        {
            "folderId": 216,
            "name": "Eileen A. Courtney",
            "list_view_selected": false,
            "id": 170
        },
        {
            "folderId": 217,
            "name": "Cove Crafters",
            "list_view_selected": false,
            "id": 171
        },
        {
            "folderId": 218,
            "name": "George M. Cox Estate",
            "list_view_selected": false,
            "id": 172
        },
        {
            "folderId": 219,
            "name": "Mr. & Mrs. Morton J. Crane",
            "list_view_selected": false,
            "id": 173
        },
        {
            "folderId": 220,
            "name": "Mr. Richard B. Karp & Ms. Christina Crawfis",
            "list_view_selected": false,
            "id": 174
        },
        {
            "folderId": 221,
            "name": "Mr. Hugh W. Crawford, Jr.",
            "list_view_selected": false,
            "id": 175
        },
        {
            "folderId": 222,
            "name": "Cre8 Salon & Spa",
            "list_view_selected": false,
            "id": 176
        },
        {
            "folderId": 223,
            "name": "Creative Foam",
            "list_view_selected": false,
            "id": 177
        },
        {
            "folderId": 224,
            "name": "Francis J. Crimmins",
            "list_view_selected": false,
            "id": 178
        },
        {
            "folderId": 225,
            "name": "Ms. Susan O'Bryan Croft",
            "list_view_selected": false,
            "id": 179
        },
        {
            "folderId": 226,
            "name": "Mr. & Mrs. William D. Cronin",
            "list_view_selected": false,
            "id": 180
        },
        {
            "folderId": 227,
            "name": "Ms. Lindsay Torpey Cross",
            "list_view_selected": false,
            "id": 181
        },
        {
            "folderId": 228,
            "name": "Crowther Roofing & Sheet Metal of Florida",
            "list_view_selected": false,
            "id": 182
        },
        {
            "folderId": 229,
            "name": "Mr. & Mrs. Ben H. Crump",
            "list_view_selected": false,
            "id": 183
        },
        {
            "folderId": 230,
            "name": "Ms. Jeannie M. Cummings",
            "list_view_selected": false,
            "id": 184
        },
        {
            "folderId": 231,
            "name": "Cure Search for Children's Cancer",
            "list_view_selected": false,
            "id": 185
        },
        {
            "folderId": 232,
            "name": "Mr. & Mrs. R. W. Curler",
            "list_view_selected": false,
            "id": 186
        },
        {
            "folderId": 233,
            "name": "Mr. and Mrs. Scott Curvey",
            "list_view_selected": false,
            "id": 187
        },
        {
            "folderId": 234,
            "name": "Custom Packaging & Products, Inc.",
            "list_view_selected": false,
            "id": 188
        },
        {
            "folderId": 235,
            "name": "Ms. Joanne M. Cutway",
            "list_view_selected": false,
            "id": 189
        },
        {
            "folderId": 236,
            "name": "Cypress Woods Cares‑4‑Kids",
            "list_view_selected": false,
            "id": 190
        },
        {
            "folderId": 237,
            "name": "Mr. & Mrs. Elton O. Dalton",
            "list_view_selected": false,
            "id": 191
        },
        {
            "folderId": 238,
            "name": "K. Alex Daneshmand, D.O. and Patricia Jara Danesmand, D.O.",
            "list_view_selected": false,
            "id": 192
        },
        {
            "folderId": 239,
            "name": "Estate of W. Daniels, Jr.",
            "list_view_selected": false,
            "id": 193
        },
        {
            "folderId": 240,
            "name": "Mr. & Mrs. John C. Dannemiller",
            "list_view_selected": false,
            "id": 194
        },
        {
            "folderId": 241,
            "name": "Mr. & Mrs. Nader F. Darehshori",
            "list_view_selected": false,
            "id": 195
        },
        {
            "folderId": 242,
            "name": "Mr. & Mrs. John B. Darling",
            "list_view_selected": false,
            "id": 196
        },
        {
            "folderId": 243,
            "name": "Mr. Frank G. Daveler",
            "list_view_selected": false,
            "id": 197
        },
        {
            "folderId": 244,
            "name": "Mr. & Mrs. David Turk",
            "list_view_selected": false,
            "id": 198
        },
        {
            "folderId": 245,
            "name": "Mrs. Judtih A. Davidson",
            "list_view_selected": false,
            "id": 199
        },
        {
            "folderId": 246,
            "name": "Davis Love III Foundation, Inc.",
            "list_view_selected": false,
            "id": 200
        },
        {
            "folderId": 247,
            "name": "Mr. & Mrs. Ronald L. Davis",
            "list_view_selected": false,
            "id": 201
        },
        {
            "folderId": 248,
            "name": "Mr. Steve Davis",
            "list_view_selected": false,
            "id": 202
        },
        {
            "folderId": 249,
            "name": "SPADA Day Spa",
            "list_view_selected": false,
            "id": 203
        },
        {
            "folderId": 250,
            "name": "Dean & Monica Piccirillo Family Fund",
            "list_view_selected": false,
            "id": 204
        },
        {
            "folderId": 251,
            "name": "DeAngelis Diamond Construction, Inc.",
            "list_view_selected": false,
            "id": 205
        },
        {
            "folderId": 252,
            "name": "Mr. David A. Powar & Mrs. Maria DeCavalles‑Powar",
            "list_view_selected": false,
            "id": 206
        },
        {
            "folderId": 253,
            "name": "Mr. Mark Deprey",
            "list_view_selected": false,
            "id": 207
        },
        {
            "folderId": 254,
            "name": "DeRoyal, Inc.",
            "list_view_selected": false,
            "id": 208
        },
        {
            "folderId": 255,
            "name": "Mr. & Mrs. Peter Dessak",
            "list_view_selected": false,
            "id": 209
        },
        {
            "folderId": 256,
            "name": "Mr. & Mrs. Wayne Detling",
            "list_view_selected": false,
            "id": 210
        },
        {
            "folderId": 257,
            "name": "Mr. George Deveau",
            "list_view_selected": false,
            "id": 211
        },
        {
            "folderId": 258,
            "name": "DeWitt Wallace‑Reader's Digest Fund",
            "list_view_selected": false,
            "id": 212
        },
        {
            "folderId": 259,
            "name": "Mr. & Mrs. Norman S. DeWolfe",
            "list_view_selected": false,
            "id": 213
        },
        {
            "folderId": 260,
            "name": "Mr. Scott Plank & Ms. Dana M. DiCarlo",
            "list_view_selected": false,
            "id": 214
        },
        {
            "folderId": 261,
            "name": "Mr. & Mrs. John E. Dick",
            "list_view_selected": false,
            "id": 215
        },
        {
            "folderId": 262,
            "name": "Ms. Shannon Diener",
            "list_view_selected": false,
            "id": 216
        },
        {
            "folderId": 263,
            "name": "Digestive Health Physicians, P.A.",
            "list_view_selected": false,
            "id": 217
        },
        {
            "folderId": 264,
            "name": "Dr. David Yu and DeeDee DiSesa",
            "list_view_selected": false,
            "id": 218
        },
        {
            "folderId": 265,
            "name": "Mr. & Mrs. Robert C. Dix",
            "list_view_selected": false,
            "id": 219
        },
        {
            "folderId": 266,
            "name": "Mr. & Mrs. Richard J. Donahue",
            "list_view_selected": false,
            "id": 220
        },
        {
            "folderId": 267,
            "name": "Donald W. Goodwin Pooled Income Fund",
            "list_view_selected": false,
            "id": 221
        },
        {
            "folderId": 268,
            "name": "Mr. David L. Doran",
            "list_view_selected": false,
            "id": 222
        },
        {
            "folderId": 269,
            "name": "Dr. Daniel Dosoretz",
            "list_view_selected": false,
            "id": 223
        },
        {
            "folderId": 270,
            "name": "Mr. & Mrs. James A. Doss Sr.",
            "list_view_selected": false,
            "id": 224
        },
        {
            "folderId": 271,
            "name": "Mr. & Mrs. William K. Douglas",
            "list_view_selected": false,
            "id": 225
        },
        {
            "folderId": 272,
            "name": "Mr. & Mrs. Wayne Doyle",
            "list_view_selected": false,
            "id": 226
        },
        {
            "folderId": 273,
            "name": "Mr. & Mrs. Edward C. Drennan",
            "list_view_selected": false,
            "id": 227
        },
        {
            "folderId": 274,
            "name": "Mr. & Mrs. William E. Drum",
            "list_view_selected": false,
            "id": 228
        },
        {
            "folderId": 275,
            "name": "Mr. & Mrs. John G. D. Dubuque",
            "list_view_selected": false,
            "id": 229
        },
        {
            "folderId": 276,
            "name": "Duke Clinical Research Institute",
            "list_view_selected": false,
            "id": 230
        },
        {
            "folderId": 277,
            "name": "Dunkin's Diamonds",
            "list_view_selected": false,
            "id": 231
        },
        {
            "folderId": 278,
            "name": "Mrs. Gail L. Dupre'",
            "list_view_selected": false,
            "id": 232
        },
        {
            "folderId": 279,
            "name": "Mr. & Mrs. Robert P. Duvin",
            "list_view_selected": false,
            "id": 233
        },
        {
            "folderId": 280,
            "name": "Mr. & Mrs. Henri Dyner",
            "list_view_selected": false,
            "id": 234
        },
        {
            "folderId": 281,
            "name": "Eau Claire Community Foundation",
            "list_view_selected": false,
            "id": 235
        },
        {
            "folderId": 282,
            "name": "Edison National Bank",
            "list_view_selected": false,
            "id": 236
        },
        {
            "folderId": 283,
            "name": "Mr. Allan L. Denbesten & Mrs. Annie Edsall",
            "list_view_selected": false,
            "id": 237
        },
        {
            "folderId": 284,
            "name": "Edward Don & Company",
            "list_view_selected": false,
            "id": 238
        },
        {
            "folderId": 285,
            "name": "Edwards Lifesciences LLC",
            "list_view_selected": false,
            "id": 239
        },
        {
            "folderId": 286,
            "name": "Ms. Amanda Egan",
            "list_view_selected": false,
            "id": 240
        },
        {
            "folderId": 287,
            "name": "Elias Brothers Painting & Waterproofing",
            "list_view_selected": false,
            "id": 241
        },
        {
            "folderId": 288,
            "name": "Mrs. Sande L. Ellis",
            "list_view_selected": false,
            "id": 242
        },
        {
            "folderId": 289,
            "name": "Mr. William M. Ellis",
            "list_view_selected": false,
            "id": 243
        },
        {
            "folderId": 290,
            "name": "Don & Jennie Baum Endowment",
            "list_view_selected": false,
            "id": 244
        },
        {
            "folderId": 291,
            "name": "Enterprise Holdings Foundation",
            "list_view_selected": false,
            "id": 245
        },
        {
            "folderId": 292,
            "name": "Enterprise Rent‑A‑Car Foundation",
            "list_view_selected": false,
            "id": 246
        },
        {
            "folderId": 293,
            "name": "Entertainment Industry Foundation",
            "list_view_selected": false,
            "id": 247
        },
        {
            "folderId": 294,
            "name": "Epic Charitable Fund",
            "list_view_selected": false,
            "id": 248
        },
        {
            "folderId": 295,
            "name": "Ms. Jeanette L. Epstein",
            "list_view_selected": false,
            "id": 249
        },
        {
            "folderId": 296,
            "name": "Mr. & Mrs. James Ermer",
            "list_view_selected": false,
            "id": 250
        },
        {
            "folderId": 297,
            "name": "Esperia at Bonita Bay, Inc.",
            "list_view_selected": false,
            "id": 251
        },
        {
            "folderId": 298,
            "name": "Estate of Robert E. Grasso",
            "list_view_selected": false,
            "id": 252
        },
        {
            "folderId": 299,
            "name": "Estate of Rosalie Payne",
            "list_view_selected": false,
            "id": 253
        },
        {
            "folderId": 300,
            "name": "Estero Country Club, Inc.",
            "list_view_selected": false,
            "id": 254
        },
        {
            "folderId": 301,
            "name": "EV3, Inc.",
            "list_view_selected": false,
            "id": 255
        },
        {
            "folderId": 302,
            "name": "Mr. & Mrs. Homer L. Evans",
            "list_view_selected": false,
            "id": 256
        },
        {
            "folderId": 303,
            "name": "Faith Fellowship World Outreach",
            "list_view_selected": false,
            "id": 257
        },
        {
            "folderId": 304,
            "name": "Family Health Centers of SW FL, Inc.",
            "list_view_selected": false,
            "id": 258
        },
        {
            "folderId": 305,
            "name": "The Sublett Family",
            "list_view_selected": false,
            "id": 259
        },
        {
            "folderId": 306,
            "name": "Family Thrift Center",
            "list_view_selected": false,
            "id": 260
        },
        {
            "folderId": 307,
            "name": "Mr. Anthony J. Farina",
            "list_view_selected": false,
            "id": 261
        },
        {
            "folderId": 308,
            "name": "Ms. Anne Fassett",
            "list_view_selected": false,
            "id": 262
        },
        {
            "folderId": 309,
            "name": "Faux Bonita Tennis",
            "list_view_selected": false,
            "id": 263
        },
        {
            "folderId": 310,
            "name": "Faux Funky Golf",
            "list_view_selected": false,
            "id": 264
        },
        {
            "folderId": 311,
            "name": "Ms. Kelly L. Fayer",
            "list_view_selected": false,
            "id": 265
        },
        {
            "folderId": 312,
            "name": "Mr. & Mrs. Donald Feiner",
            "list_view_selected": false,
            "id": 266
        },
        {
            "folderId": 313,
            "name": "Mrs. Frances C. Fenning",
            "list_view_selected": false,
            "id": 267
        },
        {
            "folderId": 314,
            "name": "Dr. Bianca Ferrari",
            "list_view_selected": false,
            "id": 268
        },
        {
            "folderId": 315,
            "name": "Mr. & Mrs. Thomas Ferraro",
            "list_view_selected": false,
            "id": 269
        },
        {
            "folderId": 316,
            "name": "Mr. Jose Fichmann",
            "list_view_selected": false,
            "id": 270
        },
        {
            "folderId": 317,
            "name": "Fiddlesticks Country Club",
            "list_view_selected": false,
            "id": 271
        },
        {
            "folderId": 318,
            "name": "Fifth Avenue Advisors, LLC",
            "list_view_selected": false,
            "id": 272
        },
        {
            "folderId": 319,
            "name": "UBS Financial Services",
            "list_view_selected": false,
            "id": 273
        },
        {
            "folderId": 320,
            "name": "FineMark National Bank & Trust",
            "list_view_selected": false,
            "id": 274
        },
        {
            "folderId": 321,
            "name": "Mr. & Mrs. Daniel Fink",
            "list_view_selected": false,
            "id": 275
        },
        {
            "folderId": 322,
            "name": "Mr. Matthew T. Finn",
            "list_view_selected": false,
            "id": 276
        },
        {
            "folderId": 323,
            "name": "First Community Bank",
            "list_view_selected": false,
            "id": 277
        },
        {
            "folderId": 324,
            "name": "Mrs. Lina C. Fisher",
            "list_view_selected": false,
            "id": 278
        },
        {
            "folderId": 325,
            "name": "Mr. Donald L. Fjellin",
            "list_view_selected": false,
            "id": 279
        },
        {
            "folderId": 326,
            "name": "Ms. Patricia Fleischman",
            "list_view_selected": false,
            "id": 280
        },
        {
            "folderId": 327,
            "name": "Ms. Virginia L. Fleming",
            "list_view_selected": false,
            "id": 281
        },
        {
            "folderId": 328,
            "name": "Florida Cancer Specialists",
            "list_view_selected": false,
            "id": 282
        },
        {
            "folderId": 329,
            "name": "Florida Department of Education",
            "list_view_selected": false,
            "id": 283
        },
        {
            "folderId": 330,
            "name": "Florida Department of Transportation",
            "list_view_selected": false,
            "id": 284
        },
        {
            "folderId": 331,
            "name": "Florida Gulf Coast University Foundation, Inc.",
            "list_view_selected": false,
            "id": 285
        },
        {
            "folderId": 332,
            "name": "Florida Dept. of State ‑ Div of Library & Information Svcs.",
            "list_view_selected": false,
            "id": 286
        },
        {
            "folderId": 333,
            "name": "Florida Weekly",
            "list_view_selected": false,
            "id": 287
        },
        {
            "folderId": 334,
            "name": "Florida Wood Window & Door, Inc.",
            "list_view_selected": false,
            "id": 288
        },
        {
            "folderId": 335,
            "name": "Ms. Stacy Forman",
            "list_view_selected": false,
            "id": 289
        },
        {
            "folderId": 336,
            "name": "BMW of Fort Myers",
            "list_view_selected": false,
            "id": 290
        },
        {
            "folderId": 337,
            "name": "DF Fort Myers Beach, LLC",
            "list_view_selected": false,
            "id": 291
        },
        {
            "folderId": 338,
            "name": "Fort Myers Beach Lions Foundation, Inc.",
            "list_view_selected": false,
            "id": 292
        },
        {
            "folderId": 339,
            "name": "Fort Myers Miracle Baseball",
            "list_view_selected": false,
            "id": 293
        },
        {
            "folderId": 340,
            "name": "Fort Myers Seventh‑Day Adventist Church",
            "list_view_selected": false,
            "id": 294
        },
        {
            "folderId": 341,
            "name": "Ms. Janet F. Foster",
            "list_view_selected": false,
            "id": 295
        },
        {
            "folderId": 342,
            "name": "TJX Foundation, Inc.",
            "list_view_selected": false,
            "id": 296
        },
        {
            "folderId": 343,
            "name": "RSM US Foundation",
            "list_view_selected": false,
            "id": 297
        },
        {
            "folderId": 344,
            "name": "Bill & Andrea Douglas Foundation",
            "list_view_selected": false,
            "id": 298
        },
        {
            "folderId": 345,
            "name": "Peggy M. Harold Foundation",
            "list_view_selected": false,
            "id": 299
        },
        {
            "folderId": 346,
            "name": "UBS Foundation USA Matching Gift Program",
            "list_view_selected": false,
            "id": 300
        },
        {
            "folderId": 347,
            "name": "GE Foundation Matching Gifts",
            "list_view_selected": false,
            "id": 301
        },
        {
            "folderId": 348,
            "name": "Foxfire Country Club",
            "list_view_selected": false,
            "id": 302
        },
        {
            "folderId": 349,
            "name": "Francis P. Hindelong Memorial Trust",
            "list_view_selected": false,
            "id": 303
        },
        {
            "folderId": 350,
            "name": "Mrs. Geraldine A. Freels",
            "list_view_selected": false,
            "id": 304
        },
        {
            "folderId": 351,
            "name": "Freeman and Hasselwander Resort Properties LLC.",
            "list_view_selected": false,
            "id": 305
        },
        {
            "folderId": 352,
            "name": "Mr. James L. French",
            "list_view_selected": false,
            "id": 306
        },
        {
            "folderId": 353,
            "name": "Mr. & Mrs. Alan Fritze",
            "list_view_selected": false,
            "id": 307
        },
        {
            "folderId": 354,
            "name": "Mr. Robert R. Frost",
            "list_view_selected": false,
            "id": 308
        },
        {
            "folderId": 355,
            "name": "Mr. Allan W. Fulkerson",
            "list_view_selected": false,
            "id": 309
        },
        {
            "folderId": 356,
            "name": "Mr. & Mrs. John Dannemiller Jubilee Fund",
            "list_view_selected": false,
            "id": 310
        },
        {
            "folderId": 357,
            "name": "Mr. & Mrs. James Gabrick",
            "list_view_selected": false,
            "id": 311
        },
        {
            "folderId": 358,
            "name": "Mr. & Mrs. Pason Gaddis",
            "list_view_selected": false,
            "id": 312
        },
        {
            "folderId": 359,
            "name": "Mr. & Mrs. Marty Gallagher",
            "list_view_selected": false,
            "id": 313
        },
        {
            "folderId": 360,
            "name": "Gallant Family Gift Fund",
            "list_view_selected": false,
            "id": 314
        },
        {
            "folderId": 361,
            "name": "Mr. & Mrs. Stephen S. Gallant",
            "list_view_selected": false,
            "id": 315
        },
        {
            "folderId": 362,
            "name": "Galloway Ford, Inc.",
            "list_view_selected": false,
            "id": 316
        },
        {
            "folderId": 363,
            "name": "Drs. Vijay & Jayshree Ganatra",
            "list_view_selected": false,
            "id": 317
        },
        {
            "folderId": 364,
            "name": "Gannett Communities Fund",
            "list_view_selected": false,
            "id": 318
        },
        {
            "folderId": 365,
            "name": "Gannett Foundation",
            "list_view_selected": false,
            "id": 319
        },
        {
            "folderId": 366,
            "name": "Gannett Match Fdn ‑ c/o Community Foundation of Louisville",
            "list_view_selected": false,
            "id": 320
        },
        {
            "folderId": 367,
            "name": "Dr. Carmen T. Garcia",
            "list_view_selected": false,
            "id": 321
        },
        {
            "folderId": 368,
            "name": "Mr. Ron C. Gardenhire",
            "list_view_selected": false,
            "id": 322
        },
        {
            "folderId": 369,
            "name": "Mr. & Mrs. R. E. Garlinghouse",
            "list_view_selected": false,
            "id": 323
        },
        {
            "folderId": 370,
            "name": "Garlinghouse Foundation, Inc.",
            "list_view_selected": false,
            "id": 324
        },
        {
            "folderId": 371,
            "name": "Mr. & Mrs. Jeffrey S. Gately",
            "list_view_selected": false,
            "id": 325
        },
        {
            "folderId": 372,
            "name": "Gendron Funeral & Cremation Services",
            "list_view_selected": false,
            "id": 326
        },
        {
            "folderId": 373,
            "name": "Genentech, Inc.",
            "list_view_selected": false,
            "id": 327
        },
        {
            "folderId": 374,
            "name": "Mr. Bernard Genevish",
            "list_view_selected": false,
            "id": 328
        },
        {
            "folderId": 375,
            "name": "Mr. & Mrs. Larry Gentine",
            "list_view_selected": false,
            "id": 329
        },
        {
            "folderId": 376,
            "name": "German American Club Gemuetlichkert of Naples, FL Inc.",
            "list_view_selected": false,
            "id": 330
        },
        {
            "folderId": 377,
            "name": "Mr. & Mrs. Michael J. German",
            "list_view_selected": false,
            "id": 331
        },
        {
            "folderId": 378,
            "name": "Mr. & Mrs. Daniel F. Gerner",
            "list_view_selected": false,
            "id": 332
        },
        {
            "folderId": 379,
            "name": "Mr. David J. Giarla",
            "list_view_selected": false,
            "id": 333
        },
        {
            "folderId": 380,
            "name": "Mr. Ken Gill",
            "list_view_selected": false,
            "id": 334
        },
        {
            "folderId": 381,
            "name": "Mr. & Mrs. Robert W. Gillespie",
            "list_view_selected": false,
            "id": 335
        },
        {
            "folderId": 382,
            "name": "Mr. & Mrs. Robert A. Glancy",
            "list_view_selected": false,
            "id": 336
        },
        {
            "folderId": 383,
            "name": "Mrs. Linda Glantz",
            "list_view_selected": false,
            "id": 337
        },
        {
            "folderId": 384,
            "name": "Glase Golf",
            "list_view_selected": false,
            "id": 338
        },
        {
            "folderId": 385,
            "name": "Mr. & Mrs. John M. Gleeson",
            "list_view_selected": false,
            "id": 339
        },
        {
            "folderId": 386,
            "name": "Mr. Patrick Glennon",
            "list_view_selected": false,
            "id": 340
        },
        {
            "folderId": 387,
            "name": "Mr. & Mrs. Robert Glick",
            "list_view_selected": false,
            "id": 341
        },
        {
            "folderId": 388,
            "name": "Mr. & Mrs. John W. Gnagey III",
            "list_view_selected": false,
            "id": 342
        },
        {
            "folderId": 389,
            "name": "Goede, Adamczyck & DeBoest, PLLC",
            "list_view_selected": false,
            "id": 343
        },
        {
            "folderId": 390,
            "name": "Mr. Mark Goggin",
            "list_view_selected": false,
            "id": 344
        },
        {
            "folderId": 391,
            "name": "Goldman Sachs & Company",
            "list_view_selected": false,
            "id": 345
        },
        {
            "folderId": 392,
            "name": "Mr. & Mrs. John O. Goldsmith",
            "list_view_selected": false,
            "id": 346
        },
        {
            "folderId": 393,
            "name": "Mr. & Mrs. Anthony Golobic",
            "list_view_selected": false,
            "id": 347
        },
        {
            "folderId": 394,
            "name": "Good Neighbor Community Foundation of Sanibel‑Captiva",
            "list_view_selected": false,
            "id": 348
        },
        {
            "folderId": 395,
            "name": "Mr. Mark S. Goodman",
            "list_view_selected": false,
            "id": 349
        },
        {
            "folderId": 396,
            "name": "Mrs. Louise M. Goodwin",
            "list_view_selected": false,
            "id": 350
        },
        {
            "folderId": 397,
            "name": "Mr. & Mrs. Clark L. Gordon",
            "list_view_selected": false,
            "id": 351
        },
        {
            "folderId": 398,
            "name": "Mr. & Mrs. N. Bryson Goss",
            "list_view_selected": false,
            "id": 352
        },
        {
            "folderId": 399,
            "name": "The Honorable & Mrs. Porter J. Goss",
            "list_view_selected": false,
            "id": 353
        },
        {
            "folderId": 400,
            "name": "Mr. Alvin R. Gould",
            "list_view_selected": false,
            "id": 354
        },
        {
            "folderId": 401,
            "name": "Gould Family Trust of Gulf Coast Community",
            "list_view_selected": false,
            "id": 355
        },
        {
            "folderId": 402,
            "name": "Matt and Laura Grabinski",
            "list_view_selected": false,
            "id": 356
        },
        {
            "folderId": 403,
            "name": "Mr. Bruce Grachek",
            "list_view_selected": false,
            "id": 357
        },
        {
            "folderId": 404,
            "name": "Mr. Randy Grachek",
            "list_view_selected": false,
            "id": 358
        },
        {
            "folderId": 405,
            "name": "Mrs. Leone A. Graham",
            "list_view_selected": false,
            "id": 359
        },
        {
            "folderId": 406,
            "name": "Mr. & Mrs. Robert J. Grappone",
            "list_view_selected": false,
            "id": 360
        },
        {
            "folderId": 407,
            "name": "Mrs. Jeannette M. Grasso",
            "list_view_selected": false,
            "id": 361
        },
        {
            "folderId": 408,
            "name": "Mr. & Mrs. Vincent Grattarola",
            "list_view_selected": false,
            "id": 362
        },
        {
            "folderId": 409,
            "name": "Mr. & Mrs. Bary W. Gray",
            "list_view_selected": false,
            "id": 363
        },
        {
            "folderId": 410,
            "name": "Great Clips",
            "list_view_selected": false,
            "id": 364
        },
        {
            "folderId": 411,
            "name": "REALTOR Assoc. of Greater Ft Myers & the Beach, Inc.",
            "list_view_selected": false,
            "id": 365
        },
        {
            "folderId": 412,
            "name": "Mr. & Mrs. Lloyd L. Green",
            "list_view_selected": false,
            "id": 366
        },
        {
            "folderId": 413,
            "name": "Mr. & Mrs. Jay Gribble",
            "list_view_selected": false,
            "id": 367
        },
        {
            "folderId": 414,
            "name": "Mr. & Mrs. Chetan Gulati",
            "list_view_selected": false,
            "id": 368
        },
        {
            "folderId": 415,
            "name": "Gulf Coast Dodge, Inc.",
            "list_view_selected": false,
            "id": 369
        },
        {
            "folderId": 416,
            "name": "Gulf Coast Harmonizers",
            "list_view_selected": false,
            "id": 370
        },
        {
            "folderId": 417,
            "name": "Gulf Coast Homes of Lee County, Inc.",
            "list_view_selected": false,
            "id": 371
        },
        {
            "folderId": 418,
            "name": "Gulf Harbour Golf & Country Club",
            "list_view_selected": false,
            "id": 372
        },
        {
            "folderId": 419,
            "name": "Gulfcoast Coin & Jewelry Brokers, Inc.",
            "list_view_selected": false,
            "id": 373
        },
        {
            "folderId": 420,
            "name": "Mr. & Mrs. Bruce Gurall",
            "list_view_selected": false,
            "id": 374
        },
        {
            "folderId": 421,
            "name": "Mr. & Mrs. George M. Guthrie",
            "list_view_selected": false,
            "id": 375
        },
        {
            "folderId": 422,
            "name": "Ms. Beverly Haas",
            "list_view_selected": false,
            "id": 376
        },
        {
            "folderId": 423,
            "name": "Mr. & Mrs. William D. Haas",
            "list_view_selected": false,
            "id": 377
        },
        {
            "folderId": 424,
            "name": "James D. &  Karen J. Hall",
            "list_view_selected": false,
            "id": 378
        },
        {
            "folderId": 425,
            "name": "Mr. & Mrs. Chuck Hallberg",
            "list_view_selected": false,
            "id": 379
        },
        {
            "folderId": 426,
            "name": "Halliday Lumber",
            "list_view_selected": false,
            "id": 380
        },
        {
            "folderId": 427,
            "name": "Mr. & Mrs. William R. Hallisey",
            "list_view_selected": false,
            "id": 381
        },
        {
            "folderId": 428,
            "name": "Mr. & Mrs. Donald F. Halucha",
            "list_view_selected": false,
            "id": 382
        },
        {
            "folderId": 429,
            "name": "Mr. & Mrs. George Roland Hamilton",
            "list_view_selected": false,
            "id": 383
        },
        {
            "folderId": 430,
            "name": "Mr. & Mrs. Richard Hammer",
            "list_view_selected": false,
            "id": 384
        },
        {
            "folderId": 431,
            "name": "Diane and Steve Hancock",
            "list_view_selected": false,
            "id": 385
        },
        {
            "folderId": 432,
            "name": "Dr. & Mrs. Stephen E. Hannan",
            "list_view_selected": false,
            "id": 386
        },
        {
            "folderId": 433,
            "name": "Mr. & Mrs. Chris Hansen",
            "list_view_selected": false,
            "id": 387
        },
        {
            "folderId": 434,
            "name": "Mr. & Mrs. Robert M. Hansen",
            "list_view_selected": false,
            "id": 388
        },
        {
            "folderId": 435,
            "name": "Cindy & Richard Harding",
            "list_view_selected": false,
            "id": 389
        },
        {
            "folderId": 436,
            "name": "Harley‑Davidson of Fort Myers",
            "list_view_selected": false,
            "id": 390
        },
        {
            "folderId": 437,
            "name": "Ms. Margaret M. Harold",
            "list_view_selected": false,
            "id": 391
        },
        {
            "folderId": 438,
            "name": "Mr. & Mrs. Donald C. Harralson",
            "list_view_selected": false,
            "id": 392
        },
        {
            "folderId": 439,
            "name": "Mr. & Mrs. Richard Haskins",
            "list_view_selected": false,
            "id": 393
        },
        {
            "folderId": 440,
            "name": "Dr. Edward C. Wheeler & Ms. Anne E. Haslem",
            "list_view_selected": false,
            "id": 394
        },
        {
            "folderId": 441,
            "name": "Mr. Timothy Hasse",
            "list_view_selected": false,
            "id": 395
        },
        {
            "folderId": 442,
            "name": "HCA, Inc. ‑ Florida Consult A Nurse",
            "list_view_selected": false,
            "id": 396
        },
        {
            "folderId": 443,
            "name": "CVS Health",
            "list_view_selected": false,
            "id": 397
        },
        {
            "folderId": 444,
            "name": "VHA Health Foundation, Inc.",
            "list_view_selected": false,
            "id": 398
        },
        {
            "folderId": 445,
            "name": "PNC Healthcare",
            "list_view_selected": false,
            "id": 399
        },
        {
            "folderId": 446,
            "name": "Hearing Research Institute, Inc.",
            "list_view_selected": false,
            "id": 400
        },
        {
            "folderId": 447,
            "name": "Hearts of Miromar",
            "list_view_selected": false,
            "id": 401
        },
        {
            "folderId": 448,
            "name": "Dr. Edgar W. Hedges",
            "list_view_selected": false,
            "id": 402
        },
        {
            "folderId": 449,
            "name": "Mr. & Mrs. Donald G. Heeringa",
            "list_view_selected": false,
            "id": 403
        },
        {
            "folderId": 450,
            "name": "Henderson, Franklin, Starnes & Holt, P.A.",
            "list_view_selected": false,
            "id": 404
        },
        {
            "folderId": 451,
            "name": "Henderson, Franklin, Starnes & Holt, P.A.",
            "list_view_selected": false,
            "id": 405
        },
        {
            "folderId": 452,
            "name": "Dr. & Mrs. Douglas G. Henricks",
            "list_view_selected": false,
            "id": 406
        },
        {
            "folderId": 453,
            "name": "John & Jane Henshaw",
            "list_view_selected": false,
            "id": 407
        },
        {
            "folderId": 454,
            "name": "Frank & Patsy Hiatt",
            "list_view_selected": false,
            "id": 408
        },
        {
            "folderId": 455,
            "name": "Mr. & Mrs. Duane M. Higgins",
            "list_view_selected": false,
            "id": 409
        },
        {
            "folderId": 456,
            "name": "Ms. Jennifer S. Higgins",
            "list_view_selected": false,
            "id": 410
        },
        {
            "folderId": 457,
            "name": "Ms. Barbara S. High",
            "list_view_selected": false,
            "id": 411
        },
        {
            "folderId": 458,
            "name": "Highland Associates, Inc.",
            "list_view_selected": false,
            "id": 412
        },
        {
            "folderId": 459,
            "name": "Mr. James C. Park & Ms. Georgene L. Hildebrand",
            "list_view_selected": false,
            "id": 413
        },
        {
            "folderId": 460,
            "name": "Mr. & Mrs. Thomas A. Himes",
            "list_view_selected": false,
            "id": 414
        },
        {
            "folderId": 461,
            "name": "Mr. & Mrs. John F. Hindelong",
            "list_view_selected": false,
            "id": 415
        },
        {
            "folderId": 462,
            "name": "Mr. & Mrs. Larry J. Hinman",
            "list_view_selected": false,
            "id": 416
        },
        {
            "folderId": 463,
            "name": "Ambassador & Mrs. Alfred Hoffman, Jr.",
            "list_view_selected": false,
            "id": 417
        },
        {
            "folderId": 464,
            "name": "Mr. & Mrs. William M. Holcomb",
            "list_view_selected": false,
            "id": 418
        },
        {
            "folderId": 465,
            "name": "Mrs. Margie Holland",
            "list_view_selected": false,
            "id": 419
        },
        {
            "folderId": 466,
            "name": "Mrs. Jeannine S. Holway",
            "list_view_selected": false,
            "id": 420
        },
        {
            "folderId": 467,
            "name": "Hooters/LTP Management Group",
            "list_view_selected": false,
            "id": 421
        },
        {
            "folderId": 468,
            "name": "Hooters/Marinos Charity Fund",
            "list_view_selected": false,
            "id": 422
        },
        {
            "folderId": 469,
            "name": "Mr. & Mrs. Sean M. Hoover",
            "list_view_selected": false,
            "id": 423
        },
        {
            "folderId": 470,
            "name": "Hope For Haiti",
            "list_view_selected": false,
            "id": 424
        },
        {
            "folderId": 471,
            "name": "Mr. & Mrs. Richard A. Hopkins",
            "list_view_selected": false,
            "id": 425
        },
        {
            "folderId": 472,
            "name": "Mrs. Mary W. Hotchkiss",
            "list_view_selected": false,
            "id": 426
        },
        {
            "folderId": 473,
            "name": "House of Wu",
            "list_view_selected": false,
            "id": 427
        },
        {
            "folderId": 474,
            "name": "Mr. & Ms. John Howard",
            "list_view_selected": false,
            "id": 428
        },
        {
            "folderId": 475,
            "name": "Mr. & Mrs. John D. Huether",
            "list_view_selected": false,
            "id": 429
        },
        {
            "folderId": 476,
            "name": "Huff Insurance Agency",
            "list_view_selected": false,
            "id": 430
        },
        {
            "folderId": 477,
            "name": "Mrs. Margaret F. Hulit",
            "list_view_selected": false,
            "id": 431
        },
        {
            "folderId": 478,
            "name": "Dr. & Mrs. Brian W. Hummel",
            "list_view_selected": false,
            "id": 432
        },
        {
            "folderId": 479,
            "name": "Jim and Nancy Humphrey & Family",
            "list_view_selected": false,
            "id": 433
        },
        {
            "folderId": 480,
            "name": "Mr. Gerald W. Hunter",
            "list_view_selected": false,
            "id": 434
        },
        {
            "folderId": 481,
            "name": "Iglewski Family Foundation",
            "list_view_selected": false,
            "id": 435
        },
        {
            "folderId": 482,
            "name": "PGT Industries",
            "list_view_selected": false,
            "id": 436
        },
        {
            "folderId": 483,
            "name": "Mr. & Mrs. Ronald E. Inge",
            "list_view_selected": false,
            "id": 437
        },
        {
            "folderId": 484,
            "name": "USI Insurance Services",
            "list_view_selected": false,
            "id": 438
        },
        {
            "folderId": 485,
            "name": "PPD Investigator Services, LLC",
            "list_view_selected": false,
            "id": 439
        },
        {
            "folderId": 486,
            "name": "Islands Night",
            "list_view_selected": false,
            "id": 440
        },
        {
            "folderId": 487,
            "name": "Jackie's Family Restaurant",
            "list_view_selected": false,
            "id": 441
        },
        {
            "folderId": 488,
            "name": "Mr. & Mrs. Jeffrey Alan Jacobson",
            "list_view_selected": false,
            "id": 442
        },
        {
            "folderId": 489,
            "name": "Ms. Deborah Jacobson",
            "list_view_selected": false,
            "id": 443
        },
        {
            "folderId": 490,
            "name": "Mr. & Mrs. Friedrich N. Jaeger",
            "list_view_selected": false,
            "id": 444
        },
        {
            "folderId": 491,
            "name": "Jaguar/Landrover/Audi ‑ Ft. Myers",
            "list_view_selected": false,
            "id": 445
        },
        {
            "folderId": 492,
            "name": "James C and Susen H Berg Family Fund",
            "list_view_selected": false,
            "id": 446
        },
        {
            "folderId": 493,
            "name": "Jason's Deli of Southwest Florida",
            "list_view_selected": false,
            "id": 447
        },
        {
            "folderId": 494,
            "name": "Mr. & Mrs. Jeffrey Swan",
            "list_view_selected": false,
            "id": 448
        },
        {
            "folderId": 495,
            "name": "Mr. Bill Jergens",
            "list_view_selected": false,
            "id": 449
        },
        {
            "folderId": 496,
            "name": "R.B. Jergens Contractors, Inc.",
            "list_view_selected": false,
            "id": 450
        },
        {
            "folderId": 497,
            "name": "Jerry H. Welty Family Fund",
            "list_view_selected": false,
            "id": 451
        },
        {
            "folderId": 498,
            "name": "Jersey Mike's Subs",
            "list_view_selected": false,
            "id": 452
        },
        {
            "folderId": 499,
            "name": "John P. Stabile Family Foundation",
            "list_view_selected": false,
            "id": 453
        },
        {
            "folderId": 500,
            "name": "Mr. & Mrs. John C. Trudeau",
            "list_view_selected": false,
            "id": 454
        },
        {
            "folderId": 501,
            "name": "John R. Wood Island Real Estate, Inc.",
            "list_view_selected": false,
            "id": 455
        },
        {
            "folderId": 502,
            "name": "Johnnie B. Byrd Alzheimer's Center & Research Institute",
            "list_view_selected": false,
            "id": 456
        },
        {
            "folderId": 503,
            "name": "Mr. Charles E. Johnson",
            "list_view_selected": false,
            "id": 457
        },
        {
            "folderId": 504,
            "name": "R.D. Johnson Construction",
            "list_view_selected": false,
            "id": 458
        },
        {
            "folderId": 505,
            "name": "Ms. Elizabeth G. Johnson",
            "list_view_selected": false,
            "id": 459
        },
        {
            "folderId": 506,
            "name": "Johnson & Johnson Family of Companies",
            "list_view_selected": false,
            "id": 460
        },
        {
            "folderId": 507,
            "name": "Mr. & Mrs. Lance E. Johnson",
            "list_view_selected": false,
            "id": 461
        },
        {
            "folderId": 508,
            "name": "Mr. Courtney P. Jones",
            "list_view_selected": false,
            "id": 462
        },
        {
            "folderId": 509,
            "name": "Joyful Yoga, Meditation And More",
            "list_view_selected": false,
            "id": 463
        },
        {
            "folderId": 510,
            "name": "Mr. & Mrs. Brian W. Judd",
            "list_view_selected": false,
            "id": 464
        },
        {
            "folderId": 511,
            "name": "Judge Franklin Mann, Jr.",
            "list_view_selected": false,
            "id": 465
        },
        {
            "folderId": 512,
            "name": "Mrs. Judith C. Sykora",
            "list_view_selected": false,
            "id": 466
        },
        {
            "folderId": 513,
            "name": "Loralie H. Junda",
            "list_view_selected": false,
            "id": 467
        },
        {
            "folderId": 514,
            "name": "Kaiser Family Foundation",
            "list_view_selected": false,
            "id": 468
        },
        {
            "folderId": 515,
            "name": "Dr. & Mrs. George C. Kalemeris",
            "list_view_selected": false,
            "id": 469
        },
        {
            "folderId": 516,
            "name": "Mrs. Mary Ann Kanzius",
            "list_view_selected": false,
            "id": 470
        },
        {
            "folderId": 517,
            "name": "Mrs. Karen E. O'Bryan",
            "list_view_selected": false,
            "id": 471
        },
        {
            "folderId": 518,
            "name": "Karezi, INC",
            "list_view_selected": false,
            "id": 472
        },
        {
            "folderId": 519,
            "name": "Mr. & Mrs. Scott Kashman",
            "list_view_selected": false,
            "id": 473
        },
        {
            "folderId": 520,
            "name": "Mr. & Mrs. William E. Kassling",
            "list_view_selected": false,
            "id": 474
        },
        {
            "folderId": 521,
            "name": "Mr. & Mrs. Frank G. Kavane",
            "list_view_selected": false,
            "id": 475
        },
        {
            "folderId": 522,
            "name": "Thad Kazmierski",
            "list_view_selected": false,
            "id": 476
        },
        {
            "folderId": 523,
            "name": "Marilyn & Jim Keller",
            "list_view_selected": false,
            "id": 477
        },
        {
            "folderId": 524,
            "name": "Kelly Crossing, LLC",
            "list_view_selected": false,
            "id": 478
        },
        {
            "folderId": 525,
            "name": "Ms. Linda Kelly",
            "list_view_selected": false,
            "id": 479
        },
        {
            "folderId": 526,
            "name": "Mr. Michael F. Kelly",
            "list_view_selected": false,
            "id": 480
        },
        {
            "folderId": 527,
            "name": "Mr. & Mrs. Brian Kemmis",
            "list_view_selected": false,
            "id": 481
        },
        {
            "folderId": 528,
            "name": "Mr. & Mrs. Roger B. Kennedy",
            "list_view_selected": false,
            "id": 482
        },
        {
            "folderId": 529,
            "name": "Chuck & Helen Ketteman",
            "list_view_selected": false,
            "id": 483
        },
        {
            "folderId": 530,
            "name": "Key Private Bank",
            "list_view_selected": false,
            "id": 484
        },
        {
            "folderId": 531,
            "name": "Mr. Edward J. Kfoury",
            "list_view_selected": false,
            "id": 485
        },
        {
            "folderId": 532,
            "name": "Mrs. Barbara Kimber",
            "list_view_selected": false,
            "id": 486
        },
        {
            "folderId": 533,
            "name": "Mr. & Mrs. Gary A. King",
            "list_view_selected": false,
            "id": 487
        },
        {
            "folderId": 534,
            "name": "Mr. & Mrs. Russell W. Kirchner",
            "list_view_selected": false,
            "id": 488
        },
        {
            "folderId": 535,
            "name": "John J. Kirlin, LLC",
            "list_view_selected": false,
            "id": 489
        },
        {
            "folderId": 536,
            "name": "Dr. & Mrs. William S. Kiser",
            "list_view_selected": false,
            "id": 490
        },
        {
            "folderId": 537,
            "name": "Kiwanis Club of Iona McGregor",
            "list_view_selected": false,
            "id": 491
        },
        {
            "folderId": 538,
            "name": "Mr. & Mrs. Fred Klopp",
            "list_view_selected": false,
            "id": 492
        },
        {
            "folderId": 539,
            "name": "Charles V. Klucka, D.O., P.A.",
            "list_view_selected": false,
            "id": 493
        },
        {
            "folderId": 540,
            "name": "Knott, Ebelini, & Hart P.A.",
            "list_view_selected": false,
            "id": 494
        },
        {
            "folderId": 541,
            "name": "Knox Rental",
            "list_view_selected": false,
            "id": 495
        },
        {
            "folderId": 542,
            "name": "Mrs. Margaret W. Korten",
            "list_view_selected": false,
            "id": 496
        },
        {
            "folderId": 543,
            "name": "Mr. & Mrs. Dennis Kozlowski",
            "list_view_selected": false,
            "id": 497
        },
        {
            "folderId": 544,
            "name": "Kraft Construction Company, Inc.",
            "list_view_selected": false,
            "id": 498
        },
        {
            "folderId": 545,
            "name": "Mr. & Mrs. Carl A. Kreager",
            "list_view_selected": false,
            "id": 499
        },
        {
            "folderId": 546,
            "name": "Mrs. Nora Krieger",
            "list_view_selected": false,
            "id": 500
        },
        {
            "folderId": 547,
            "name": "Mr. & Mrs. Al Kriss",
            "list_view_selected": false,
            "id": 501
        },
        {
            "folderId": 548,
            "name": "Dr. & Mrs. Charles A. Krivenko",
            "list_view_selected": false,
            "id": 502
        },
        {
            "folderId": 549,
            "name": "Ms. Cynthia F. Kruesi",
            "list_view_selected": false,
            "id": 503
        },
        {
            "folderId": 550,
            "name": "Kruse International",
            "list_view_selected": false,
            "id": 504
        },
        {
            "folderId": 551,
            "name": "Dr. & Mrs. LeRoy H. Kulis",
            "list_view_selected": false,
            "id": 505
        },
        {
            "folderId": 552,
            "name": "Mr. & Mrs. Grant Kurtz",
            "list_view_selected": false,
            "id": 506
        },
        {
            "folderId": 553,
            "name": "Ms. Jill B. Kushner",
            "list_view_selected": false,
            "id": 507
        },
        {
            "folderId": 554,
            "name": "Mr. & Mrs. Thomas F. Lachner",
            "list_view_selected": false,
            "id": 508
        },
        {
            "folderId": 555,
            "name": "Ms. Dawn Lagergren",
            "list_view_selected": false,
            "id": 509
        },
        {
            "folderId": 556,
            "name": "Mrs. Carole Laird",
            "list_view_selected": false,
            "id": 510
        },
        {
            "folderId": 557,
            "name": "Laird Youth Leadership Foundation",
            "list_view_selected": false,
            "id": 511
        },
        {
            "folderId": 558,
            "name": "Ms. Rose Marie Lajoie",
            "list_view_selected": false,
            "id": 512
        },
        {
            "folderId": 559,
            "name": "Lake Michigan Credit Union",
            "list_view_selected": false,
            "id": 513
        },
        {
            "folderId": 560,
            "name": "Mr. & Mrs. Kenneth J. LaMotte",
            "list_view_selected": false,
            "id": 514
        },
        {
            "folderId": 561,
            "name": "Mr. & Mrs. Michael C. Landin",
            "list_view_selected": false,
            "id": 515
        },
        {
            "folderId": 562,
            "name": "Landmatrix L.L.C",
            "list_view_selected": false,
            "id": 516
        },
        {
            "folderId": 563,
            "name": "Larry G. Scoggin Foundation",
            "list_view_selected": false,
            "id": 517
        },
        {
            "folderId": 564,
            "name": "Mr. & Mrs. Richard D. Larson",
            "list_view_selected": false,
            "id": 518
        },
        {
            "folderId": 565,
            "name": "Mr. & Mrs. G. J. Lashley",
            "list_view_selected": false,
            "id": 519
        },
        {
            "folderId": 566,
            "name": "Jim & Vanita Laurito",
            "list_view_selected": false,
            "id": 520
        },
        {
            "folderId": 567,
            "name": "Mr. Leo J. LeBlanc",
            "list_view_selected": false,
            "id": 521
        },
        {
            "folderId": 568,
            "name": "Lee County Electric Co‑op",
            "list_view_selected": false,
            "id": 522
        },
        {
            "folderId": 569,
            "name": "Lee County Medical Society Alliance Fdn, Inc.",
            "list_view_selected": false,
            "id": 523
        },
        {
            "folderId": 570,
            "name": "Mr. & Mrs. Lloyd L. Leider",
            "list_view_selected": false,
            "id": 524
        },
        {
            "folderId": 571,
            "name": "Mr. & Mrs. John H. Lein",
            "list_view_selected": false,
            "id": 525
        },
        {
            "folderId": 572,
            "name": "Mrs. Linda L. Brown Lenz, RN",
            "list_view_selected": false,
            "id": 526
        },
        {
            "folderId": 573,
            "name": "Ms. Patricia D. Leonard",
            "list_view_selected": false,
            "id": 527
        },
        {
            "folderId": 574,
            "name": "Mr. & Mrs. Richard A. Lepola",
            "list_view_selected": false,
            "id": 528
        },
        {
            "folderId": 575,
            "name": "Lester and Dorothy Jones Charitable Designated Fund",
            "list_view_selected": false,
            "id": 529
        },
        {
            "folderId": 576,
            "name": "Dr. & Mrs. Stuart D. Levy",
            "list_view_selected": false,
            "id": 530
        },
        {
            "folderId": 577,
            "name": "Michael & Mary Lou Leyden",
            "list_view_selected": false,
            "id": 531
        },
        {
            "folderId": 578,
            "name": "LFG, Inc.",
            "list_view_selected": false,
            "id": 532
        },
        {
            "folderId": 579,
            "name": "Ms. Carol S. Libs",
            "list_view_selected": false,
            "id": 533
        },
        {
            "folderId": 580,
            "name": "Mr. & Mrs. Dale Lichy",
            "list_view_selected": false,
            "id": 534
        },
        {
            "folderId": 581,
            "name": "Lift Stak & Stor",
            "list_view_selected": false,
            "id": 535
        },
        {
            "folderId": 582,
            "name": "Kathy & Paul Liles",
            "list_view_selected": false,
            "id": 536
        },
        {
            "folderId": 583,
            "name": "Mr. Alexandre R. Blagojevic & Ms. Elizabeth M. Lilly",
            "list_view_selected": false,
            "id": 537
        },
        {
            "folderId": 584,
            "name": "Lily & Co, Inc.",
            "list_view_selected": false,
            "id": 538
        },
        {
            "folderId": 585,
            "name": "Dr. & Mrs. Dean Lin in Honor of Ava, Reese and Jaxson Lin",
            "list_view_selected": false,
            "id": 539
        },
        {
            "folderId": 586,
            "name": "Mr. & Mrs. Robert J. Lindgren",
            "list_view_selected": false,
            "id": 540
        },
        {
            "folderId": 587,
            "name": "Dr. Donald S. Linton",
            "list_view_selected": false,
            "id": 541
        },
        {
            "folderId": 588,
            "name": "Mr. & Mrs. David Lionett",
            "list_view_selected": false,
            "id": 542
        },
        {
            "folderId": 589,
            "name": "Little Luxe Children's Boutique",
            "list_view_selected": false,
            "id": 543
        },
        {
            "folderId": 590,
            "name": "Dr. William F. Liu and Dr. Marilyn Young Liu",
            "list_view_selected": false,
            "id": 544
        },
        {
            "folderId": 591,
            "name": "Mr. & Ms. Derek C. Lowe",
            "list_view_selected": false,
            "id": 545
        },
        {
            "folderId": 592,
            "name": "Mr. & Mrs. Edward W. Ludemann",
            "list_view_selected": false,
            "id": 546
        },
        {
            "folderId": 593,
            "name": "Lujensue Foundation, Inc.",
            "list_view_selected": false,
            "id": 547
        },
        {
            "folderId": 594,
            "name": "Mr. & Mrs. Chad Lund",
            "list_view_selected": false,
            "id": 548
        },
        {
            "folderId": 595,
            "name": "Mr. James D. Lundeen, Jr.",
            "list_view_selected": false,
            "id": 549
        },
        {
            "folderId": 596,
            "name": "Lutgert Companies",
            "list_view_selected": false,
            "id": 550
        },
        {
            "folderId": 597,
            "name": "Mr. & Mrs. Scott Lyons",
            "list_view_selected": false,
            "id": 551
        },
        {
            "folderId": 598,
            "name": "Dr. & Mrs. Craig A. MacArthur",
            "list_view_selected": false,
            "id": 552
        },
        {
            "folderId": 599,
            "name": "Ms. Kathryn M. MacDonald",
            "list_view_selected": false,
            "id": 553
        },
        {
            "folderId": 600,
            "name": "Mr. & Mrs. Kenneth A. Mack",
            "list_view_selected": false,
            "id": 554
        },
        {
            "folderId": 601,
            "name": "MacKoul Pediatrics Fort Myers",
            "list_view_selected": false,
            "id": 555
        },
        {
            "folderId": 602,
            "name": "Ms. Jane M. Macksood",
            "list_view_selected": false,
            "id": 556
        },
        {
            "folderId": 603,
            "name": "MacLeod Stewardship Foundation, Inc.",
            "list_view_selected": false,
            "id": 557
        },
        {
            "folderId": 604,
            "name": "Manhattan Construction",
            "list_view_selected": false,
            "id": 558
        },
        {
            "folderId": 605,
            "name": "Mr. & Mrs. Melvin R. Manker",
            "list_view_selected": false,
            "id": 559
        },
        {
            "folderId": 606,
            "name": "Manone Family Foundation",
            "list_view_selected": false,
            "id": 560
        },
        {
            "folderId": 607,
            "name": "Mr. & Mrs. Vito Manone",
            "list_view_selected": false,
            "id": 561
        },
        {
            "folderId": 608,
            "name": "March of Dimes Birth Defects Foundation",
            "list_view_selected": false,
            "id": 562
        },
        {
            "folderId": 609,
            "name": "Mr. & Mrs. Frank R. Marfino",
            "list_view_selected": false,
            "id": 563
        },
        {
            "folderId": 610,
            "name": "Mr. Stewart Mills & Ms. Andre Margaux",
            "list_view_selected": false,
            "id": 564
        },
        {
            "folderId": 611,
            "name": "MarineMax Fort Myers",
            "list_view_selected": false,
            "id": 565
        },
        {
            "folderId": 612,
            "name": "James and Brigitte Marino",
            "list_view_selected": false,
            "id": 566
        },
        {
            "folderId": 613,
            "name": "Marion & Jack Gruver Fund of Gulf Coast Community Foundation",
            "list_view_selected": false,
            "id": 567
        },
        {
            "folderId": 614,
            "name": "Mark Loren Designs, Inc.",
            "list_view_selected": false,
            "id": 568
        },
        {
            "folderId": 615,
            "name": "Mr. & Mrs. Mark Suwyn",
            "list_view_selected": false,
            "id": 569
        },
        {
            "folderId": 616,
            "name": "Leroy G. Markle Estate",
            "list_view_selected": false,
            "id": 570
        },
        {
            "folderId": 617,
            "name": "Mr. & Mrs. J. Marnocha",
            "list_view_selected": false,
            "id": 571
        },
        {
            "folderId": 618,
            "name": "Marquette Electronics, Inc.",
            "list_view_selected": false,
            "id": 572
        },
        {
            "folderId": 619,
            "name": "Mr. & Mrs. Eugene H. Massey",
            "list_view_selected": false,
            "id": 573
        },
        {
            "folderId": 620,
            "name": "Mr. & Mrs. Dale M. Mast",
            "list_view_selected": false,
            "id": 574
        },
        {
            "folderId": 621,
            "name": "Master Craft Plumbing, Inc.",
            "list_view_selected": false,
            "id": 575
        },
        {
            "folderId": 622,
            "name": "VJ Mattson Company",
            "list_view_selected": false,
            "id": 576
        },
        {
            "folderId": 623,
            "name": "Mr. & Mrs. Nicholas A. Maurillo Jr.",
            "list_view_selected": false,
            "id": 577
        },
        {
            "folderId": 624,
            "name": "Mr. & Mrs. Victor W. Mayeron",
            "list_view_selected": false,
            "id": 578
        },
        {
            "folderId": 625,
            "name": "McBLT Ventures, Inc.",
            "list_view_selected": false,
            "id": 579
        },
        {
            "folderId": 626,
            "name": "Mr. & Mrs. Tom McAuley",
            "list_view_selected": false,
            "id": 580
        },
        {
            "folderId": 627,
            "name": "Mr. & Mrs. James D. McCamant",
            "list_view_selected": false,
            "id": 581
        },
        {
            "folderId": 628,
            "name": "Family and Friends of Ryan McCleskey",
            "list_view_selected": false,
            "id": 582
        },
        {
            "folderId": 629,
            "name": "Mr. & Mrs. David McCurry",
            "list_view_selected": false,
            "id": 583
        },
        {
            "folderId": 630,
            "name": "Mrs. Catherine McCusker",
            "list_view_selected": false,
            "id": 584
        },
        {
            "folderId": 631,
            "name": "McDonald Family Fund at Schwab Charitable",
            "list_view_selected": false,
            "id": 585
        },
        {
            "folderId": 632,
            "name": "Mr. & Mrs. Harold McEachern",
            "list_view_selected": false,
            "id": 586
        },
        {
            "folderId": 633,
            "name": "Mary McGillicuddy",
            "list_view_selected": false,
            "id": 587
        },
        {
            "folderId": 634,
            "name": "Mrs. Rosalyn J. McGlynn",
            "list_view_selected": false,
            "id": 588
        },
        {
            "folderId": 635,
            "name": "Ms. Susan McGrogan",
            "list_view_selected": false,
            "id": 589
        },
        {
            "folderId": 636,
            "name": "Daniel & Holly McKenna",
            "list_view_selected": false,
            "id": 590
        },
        {
            "folderId": 637,
            "name": "Ms. Mary Ann McKoane",
            "list_view_selected": false,
            "id": 591
        },
        {
            "folderId": 638,
            "name": "Mr. Timothy McLaughlin",
            "list_view_selected": false,
            "id": 592
        },
        {
            "folderId": 639,
            "name": "Ms. Crystal D. McMillan",
            "list_view_selected": false,
            "id": 593
        },
        {
            "folderId": 640,
            "name": "Larry  & Chuckie McPherson",
            "list_view_selected": false,
            "id": 594
        },
        {
            "folderId": 641,
            "name": "Richard Lauber and Nicole Lauber McQuade",
            "list_view_selected": false,
            "id": 595
        },
        {
            "folderId": 642,
            "name": "Mrs. Emily C. Mead",
            "list_view_selected": false,
            "id": 596
        },
        {
            "folderId": 643,
            "name": "Ms. Patricia Means",
            "list_view_selected": false,
            "id": 597
        },
        {
            "folderId": 644,
            "name": "Medline Industries",
            "list_view_selected": false,
            "id": 598
        },
        {
            "folderId": 645,
            "name": "Mr. & Mrs. Douglas C. Mehle",
            "list_view_selected": false,
            "id": 599
        },
        {
            "folderId": 646,
            "name": "Mr. & Mrs. Donald Melchiorre",
            "list_view_selected": false,
            "id": 600
        },
        {
            "folderId": 647,
            "name": "Merck & Co, Inc.",
            "list_view_selected": false,
            "id": 601
        },
        {
            "folderId": 648,
            "name": "Ms. Martha Meyers",
            "list_view_selected": false,
            "id": 602
        },
        {
            "folderId": 649,
            "name": "Miami Children's Hospital",
            "list_view_selected": false,
            "id": 603
        },
        {
            "folderId": 650,
            "name": "Dr. & Mrs. James J. Milford",
            "list_view_selected": false,
            "id": 604
        },
        {
            "folderId": 651,
            "name": "Millennium Physician Group, LLC",
            "list_view_selected": false,
            "id": 605
        },
        {
            "folderId": 652,
            "name": "Mrs. & Mrs. Jeffrey M. Miloff",
            "list_view_selected": false,
            "id": 606
        },
        {
            "folderId": 653,
            "name": "Mr. & Mrs. Daniel Minton",
            "list_view_selected": false,
            "id": 607
        },
        {
            "folderId": 654,
            "name": "Mr. & Mrs. Kevin G. Mirner",
            "list_view_selected": false,
            "id": 608
        },
        {
            "folderId": 655,
            "name": "Miromar Development Corporation",
            "list_view_selected": false,
            "id": 609
        },
        {
            "folderId": 656,
            "name": "Miromar Outlets",
            "list_view_selected": false,
            "id": 610
        },
        {
            "folderId": 657,
            "name": "Ms. Margery Knepp‑Dodson & Mr. F.Paul Mitchell",
            "list_view_selected": false,
            "id": 611
        },
        {
            "folderId": 658,
            "name": "Ms. Karin Moe",
            "list_view_selected": false,
            "id": 612
        },
        {
            "folderId": 659,
            "name": "Mr. & Mrs. Thomas G. Mogren",
            "list_view_selected": false,
            "id": 613
        },
        {
            "folderId": 660,
            "name": "Moms Club of South Fort Myers",
            "list_view_selected": false,
            "id": 614
        },
        {
            "folderId": 661,
            "name": "Mr. Bruce Monaghan",
            "list_view_selected": false,
            "id": 615
        },
        {
            "folderId": 662,
            "name": "Mr. & Mrs. Joseph J. Mondelli",
            "list_view_selected": false,
            "id": 616
        },
        {
            "folderId": 663,
            "name": "Dr. Roberto Monge",
            "list_view_selected": false,
            "id": 617
        },
        {
            "folderId": 664,
            "name": "Mr. & Mrs. John R. Moore",
            "list_view_selected": false,
            "id": 618
        },
        {
            "folderId": 665,
            "name": "Mrs. Renee L. Moore",
            "list_view_selected": false,
            "id": 619
        },
        {
            "folderId": 666,
            "name": "Mr. & Mrs. Robert Moore",
            "list_view_selected": false,
            "id": 620
        },
        {
            "folderId": 667,
            "name": "Moorings Park Foundation, Inc.",
            "list_view_selected": false,
            "id": 621
        },
        {
            "folderId": 668,
            "name": "Mr. Thomas E. Morack",
            "list_view_selected": false,
            "id": 622
        },
        {
            "folderId": 669,
            "name": "Mrs. Ann L. Moran",
            "list_view_selected": false,
            "id": 623
        },
        {
            "folderId": 670,
            "name": "Mr. & Mrs. Roger T. Morgan",
            "list_view_selected": false,
            "id": 624
        },
        {
            "folderId": 671,
            "name": "Morgan Stanley Global Impact Funding Trust",
            "list_view_selected": false,
            "id": 625
        },
        {
            "folderId": 672,
            "name": "Morgan Stanley Private Wealth Management",
            "list_view_selected": false,
            "id": 626
        },
        {
            "folderId": 673,
            "name": "Mrs. Elizabeth A. Morris",
            "list_view_selected": false,
            "id": 627
        },
        {
            "folderId": 674,
            "name": "John R. & Kay S. Morse",
            "list_view_selected": false,
            "id": 628
        },
        {
            "folderId": 675,
            "name": "Mr. & Mrs. Robert J. Moses",
            "list_view_selected": false,
            "id": 629
        },
        {
            "folderId": 676,
            "name": "Mrs. Edna L. Mudry",
            "list_view_selected": false,
            "id": 630
        },
        {
            "folderId": 677,
            "name": "Muni Designs",
            "list_view_selected": false,
            "id": 631
        },
        {
            "folderId": 678,
            "name": "Dr. Murali Muppala",
            "list_view_selected": false,
            "id": 632
        },
        {
            "folderId": 679,
            "name": "Mr. & Mrs. Frank Murphy",
            "list_view_selected": false,
            "id": 633
        },
        {
            "folderId": 680,
            "name": "Ms. Priscilla Myers",
            "list_view_selected": false,
            "id": 634
        },
        {
            "folderId": 681,
            "name": "Nagin Foundation",
            "list_view_selected": false,
            "id": 635
        },
        {
            "folderId": 682,
            "name": "Nancy J. Boots Charitable Fund",
            "list_view_selected": false,
            "id": 636
        },
        {
            "folderId": 683,
            "name": "Mr. and Mrs. Robert Nanovic",
            "list_view_selected": false,
            "id": 637
        },
        {
            "folderId": 684,
            "name": "Naples Community Hospital",
            "list_view_selected": false,
            "id": 638
        },
        {
            "folderId": 685,
            "name": "Naples United Church of Christ",
            "list_view_selected": false,
            "id": 639
        },
        {
            "folderId": 686,
            "name": "National Childhood Cancer Foundation",
            "list_view_selected": false,
            "id": 640
        },
        {
            "folderId": 687,
            "name": "Mr. & Mrs. James E. Nelson",
            "list_view_selected": false,
            "id": 641
        },
        {
            "folderId": 688,
            "name": "Nemours Children's Clinic",
            "list_view_selected": false,
            "id": 642
        },
        {
            "folderId": 689,
            "name": "New Hampshire Charitable Foundation",
            "list_view_selected": false,
            "id": 643
        },
        {
            "folderId": 690,
            "name": "New World Regulatory Solutions, Inc.",
            "list_view_selected": false,
            "id": 644
        },
        {
            "folderId": 691,
            "name": "Mr. & Mrs. Paul B. Newhouse",
            "list_view_selected": false,
            "id": 645
        },
        {
            "folderId": 692,
            "name": "Mr. & Mrs. Norman G. Newman",
            "list_view_selected": false,
            "id": 646
        },
        {
            "folderId": 693,
            "name": "M. Nice Charitable Trust",
            "list_view_selected": false,
            "id": 647
        },
        {
            "folderId": 694,
            "name": "Mr. & Mrs. John A. Nice",
            "list_view_selected": false,
            "id": 648
        },
        {
            "folderId": 695,
            "name": "Mr. Richard H. Niehaus",
            "list_view_selected": false,
            "id": 649
        },
        {
            "folderId": 696,
            "name": "Nordstrom",
            "list_view_selected": false,
            "id": 650
        },
        {
            "folderId": 697,
            "name": "Norman Love Confections",
            "list_view_selected": false,
            "id": 651
        },
        {
            "folderId": 698,
            "name": "North Fort Myers Senior Center, Inc.",
            "list_view_selected": false,
            "id": 652
        },
        {
            "folderId": 699,
            "name": "North Sound Capital LLC",
            "list_view_selected": false,
            "id": 653
        },
        {
            "folderId": 700,
            "name": "Northern Trust",
            "list_view_selected": false,
            "id": 654
        },
        {
            "folderId": 701,
            "name": "Mr. Martin Norville",
            "list_view_selected": false,
            "id": 655
        },
        {
            "folderId": 702,
            "name": "Nova Southeastern University",
            "list_view_selected": false,
            "id": 656
        },
        {
            "folderId": 703,
            "name": "Nova Wealth Management",
            "list_view_selected": false,
            "id": 657
        },
        {
            "folderId": 704,
            "name": "Mr. & Mrs. Robert J. Nunn",
            "list_view_selected": false,
            "id": 658
        },
        {
            "folderId": 705,
            "name": "Dr. & Mrs. Scott Nygaard",
            "list_view_selected": false,
            "id": 659
        },
        {
            "folderId": 706,
            "name": "Oasis Charter Elementary",
            "list_view_selected": false,
            "id": 660
        },
        {
            "folderId": 707,
            "name": "Mr. & Mrs. Gary Oatey",
            "list_view_selected": false,
            "id": 661
        },
        {
            "folderId": 708,
            "name": "O'Bryan Foundation",
            "list_view_selected": false,
            "id": 662
        },
        {
            "folderId": 709,
            "name": "Ms. Joan M. Odorizzi",
            "list_view_selected": false,
            "id": 663
        },
        {
            "folderId": 710,
            "name": "Office Furniture & Design Concepts, Inc.",
            "list_view_selected": false,
            "id": 664
        },
        {
            "folderId": 711,
            "name": "Mr. & Mrs. Peter R. O'Flinn",
            "list_view_selected": false,
            "id": 665
        },
        {
            "folderId": 712,
            "name": "Mr. & Mrs. Joe Olson",
            "list_view_selected": false,
            "id": 666
        },
        {
            "folderId": 713,
            "name": "Optimist Club of Fort Myers",
            "list_view_selected": false,
            "id": 667
        },
        {
            "folderId": 714,
            "name": "Mr. & Mrs. Philip V. Otero",
            "list_view_selected": false,
            "id": 668
        },
        {
            "folderId": 715,
            "name": "Outback Steakhouse",
            "list_view_selected": false,
            "id": 669
        },
        {
            "folderId": 716,
            "name": "Owen‑Ames‑Kimball Company",
            "list_view_selected": false,
            "id": 670
        },
        {
            "folderId": 717,
            "name": "Padgett Family Charitable Fund",
            "list_view_selected": false,
            "id": 671
        },
        {
            "folderId": 718,
            "name": "Mr. and Mrs. Mark Padgett",
            "list_view_selected": false,
            "id": 672
        },
        {
            "folderId": 719,
            "name": "Pagel Foundation",
            "list_view_selected": false,
            "id": 673
        },
        {
            "folderId": 720,
            "name": "Palm Printing & Forms",
            "list_view_selected": false,
            "id": 674
        },
        {
            "folderId": 721,
            "name": "PalmCo",
            "list_view_selected": false,
            "id": 675
        },
        {
            "folderId": 722,
            "name": "Palmetto‑Pine Country Club Women's Golf Association",
            "list_view_selected": false,
            "id": 676
        },
        {
            "folderId": 723,
            "name": "Mr. Anthony Paolo",
            "list_view_selected": false,
            "id": 677
        },
        {
            "folderId": 724,
            "name": "Ms. Jennifer Parisi",
            "list_view_selected": false,
            "id": 678
        },
        {
            "folderId": 725,
            "name": "Christina and Clifford Parker",
            "list_view_selected": false,
            "id": 679
        },
        {
            "folderId": 726,
            "name": "Pat & Don Parsons",
            "list_view_selected": false,
            "id": 680
        },
        {
            "folderId": 727,
            "name": "Paul Bush Family Foundation",
            "list_view_selected": false,
            "id": 681
        },
        {
            "folderId": 728,
            "name": "Paul Todd Charities, Inc.",
            "list_view_selected": false,
            "id": 682
        },
        {
            "folderId": 729,
            "name": "Peace Community Church",
            "list_view_selected": false,
            "id": 683
        },
        {
            "folderId": 730,
            "name": "Mr. Michael B. Peceri",
            "list_view_selected": false,
            "id": 684
        },
        {
            "folderId": 731,
            "name": "The Pediatric Cancer Foundation, Inc.",
            "list_view_selected": false,
            "id": 685
        },
        {
            "folderId": 732,
            "name": "Mr. & Mrs. Michael Peers",
            "list_view_selected": false,
            "id": 686
        },
        {
            "folderId": 733,
            "name": "Pelican Preserve",
            "list_view_selected": false,
            "id": 687
        },
        {
            "folderId": 734,
            "name": "Pelican's Nest",
            "list_view_selected": false,
            "id": 688
        },
        {
            "folderId": 735,
            "name": "Mr. & Mrs. Dennis H. Pellicci",
            "list_view_selected": false,
            "id": 689
        },
        {
            "folderId": 736,
            "name": "Mr. & Mrs. Steven Peltzman",
            "list_view_selected": false,
            "id": 690
        },
        {
            "folderId": 737,
            "name": "Mr. & Mrs. Peter Kendall",
            "list_view_selected": false,
            "id": 691
        },
        {
            "folderId": 738,
            "name": "Elizabeth Peters",
            "list_view_selected": false,
            "id": 692
        },
        {
            "folderId": 739,
            "name": "Ms. Christine Peterson",
            "list_view_selected": false,
            "id": 693
        },
        {
            "folderId": 740,
            "name": "Ms. Elizabeth M. Pfriem",
            "list_view_selected": false,
            "id": 694
        },
        {
            "folderId": 741,
            "name": "Philips Medical Systems",
            "list_view_selected": false,
            "id": 695
        },
        {
            "folderId": 742,
            "name": "Physicians' Primary Care of SW Florida, PL",
            "list_view_selected": false,
            "id": 696
        },
        {
            "folderId": 743,
            "name": "Ms. Wendy I. Piascik",
            "list_view_selected": false,
            "id": 697
        },
        {
            "folderId": 744,
            "name": "Dean and Monica Piccirillo",
            "list_view_selected": false,
            "id": 698
        },
        {
            "folderId": 745,
            "name": "Picone Financial Partners, LLC",
            "list_view_selected": false,
            "id": 699
        },
        {
            "folderId": 746,
            "name": "Mr. & Mrs. John Picone",
            "list_view_selected": false,
            "id": 700
        },
        {
            "folderId": 747,
            "name": "Pieces of Eight Pirate Cruise",
            "list_view_selected": false,
            "id": 701
        },
        {
            "folderId": 748,
            "name": "Annette M. St. Pierre‑Mackoul, MD",
            "sorting_order": "Annette",
            "list_view_selected": false,
            "id": 702
        },
        {
            "folderId": 749,
            "name": "Dr. & Mrs. Anthony G. Pietroniro",
            "list_view_selected": false,
            "id": 703
        },
        {
            "folderId": 750,
            "name": "Mr. & Mrs. William Pinkerton",
            "list_view_selected": false,
            "id": 704
        },
        {
            "folderId": 751,
            "name": "Mr. & Mrs. John A. Pinti",
            "list_view_selected": false,
            "id": 705
        },
        {
            "folderId": 752,
            "name": "Mr. & Mrs. Richard B. Pitbladdo, Jr.",
            "list_view_selected": false,
            "id": 706
        },
        {
            "folderId": 753,
            "name": "Mr. & Mrs. David M. Platt",
            "list_view_selected": false,
            "id": 707
        },
        {
            "folderId": 754,
            "name": "Dr. & Mrs. Richard W. Plummer",
            "list_view_selected": false,
            "id": 708
        },
        {
            "folderId": 755,
            "name": "Police Assoc. of Cape Coral, Inc.",
            "list_view_selected": false,
            "id": 709
        },
        {
            "folderId": 756,
            "name": "Mr. David A. Powers",
            "list_view_selected": false,
            "id": 710
        },
        {
            "folderId": 757,
            "name": "Mr. & Mrs. Jeff Powers",
            "list_view_selected": false,
            "id": 711
        },
        {
            "folderId": 758,
            "name": "Mr. & Mrs. Michael J. Pratko",
            "list_view_selected": false,
            "id": 712
        },
        {
            "folderId": 759,
            "name": "Premier Properties of SW Florida, Inc.",
            "list_view_selected": false,
            "id": 713
        },
        {
            "folderId": 760,
            "name": "Premier Sotheby's International Realty",
            "list_view_selected": false,
            "id": 714
        },
        {
            "folderId": 761,
            "name": "Principal Life Insurance Company",
            "list_view_selected": false,
            "id": 715
        },
        {
            "folderId": 762,
            "name": "Private Client Insurance Services",
            "list_view_selected": false,
            "id": 716
        },
        {
            "folderId": 763,
            "name": "Private Sky Aviation Services, Inc.",
            "list_view_selected": false,
            "id": 717
        },
        {
            "folderId": 764,
            "name": "Promotional Incentives",
            "list_view_selected": false,
            "id": 718
        },
        {
            "folderId": 765,
            "name": "Mr. & Mrs. Phil Prosapio",
            "list_view_selected": false,
            "id": 719
        },
        {
            "folderId": 766,
            "name": "Mr. & Mrs. Frederick A. Quinn",
            "list_view_selected": false,
            "id": 720
        },
        {
            "folderId": 767,
            "name": "Jorge M. Quinonez, MD",
            "list_view_selected": false,
            "id": 721
        },
        {
            "folderId": 768,
            "name": "Mr. & Mrs. Jack Rasmussen",
            "list_view_selected": false,
            "id": 722
        },
        {
            "folderId": 769,
            "name": "Raymond Building Supply Corp.",
            "list_view_selected": false,
            "id": 723
        },
        {
            "folderId": 770,
            "name": "Raymond James & Associates Inc.",
            "list_view_selected": false,
            "id": 724
        },
        {
            "folderId": 771,
            "name": "VIP Realty Group, Inc.",
            "list_view_selected": false,
            "id": 725
        },
        {
            "folderId": 772,
            "name": "Mr. & Mrs. Garrett H. Reasoner",
            "list_view_selected": false,
            "id": 726
        },
        {
            "folderId": 773,
            "name": "Reese Family Charitable Foundation",
            "list_view_selected": false,
            "id": 727
        },
        {
            "folderId": 774,
            "name": "Regions Bank",
            "list_view_selected": false,
            "id": 728
        },
        {
            "folderId": 775,
            "name": "Mr. & Mrs. Robert W. Reid",
            "list_view_selected": false,
            "id": 729
        },
        {
            "folderId": 776,
            "name": "Representative Heather D. Fitzenhagen",
            "list_view_selected": false,
            "id": 730
        },
        {
            "folderId": 777,
            "name": "Dr. & Mrs. Peter B. Reuling",
            "list_view_selected": false,
            "id": 731
        },
        {
            "folderId": 778,
            "name": "Mr. & Mrs. Ronald A. Rex",
            "list_view_selected": false,
            "id": 732
        },
        {
            "folderId": 779,
            "name": "Mr. & Mrs. Stuart G. Rice",
            "list_view_selected": false,
            "id": 733
        },
        {
            "folderId": 780,
            "name": "Mr. & Mrs. Harold R. Rieck",
            "list_view_selected": false,
            "id": 734
        },
        {
            "folderId": 781,
            "name": "Steve and Gisela Riggs",
            "list_view_selected": false,
            "id": 735
        },
        {
            "folderId": 782,
            "name": "Mr. & Mrs. Richard E. Riley",
            "list_view_selected": false,
            "id": 736
        },
        {
            "folderId": 783,
            "name": "Gerald & Judeth Risch‑In Honor of Risch Grandchildren",
            "list_view_selected": false,
            "id": 737
        },
        {
            "folderId": 784,
            "name": "Mr. & Mrs. Brian D. Rist",
            "list_view_selected": false,
            "id": 738
        },
        {
            "folderId": 785,
            "name": "Mr. & Mrs. Charles G. Roach, Jr.",
            "list_view_selected": false,
            "id": 739
        },
        {
            "folderId": 786,
            "name": "Mr. & Mrs. Roby L. Roberts",
            "list_view_selected": false,
            "id": 740
        },
        {
            "folderId": 787,
            "name": "Mr. & Mrs. William C. Roberts",
            "list_view_selected": false,
            "id": 741
        },
        {
            "folderId": 788,
            "name": "Mr. & Mrs. Donald B. Rolley",
            "list_view_selected": false,
            "id": 742
        },
        {
            "folderId": 789,
            "name": "Rooms To Go",
            "list_view_selected": false,
            "id": 743
        },
        {
            "folderId": 790,
            "name": "Mr. & Mrs. Shawn & Sharon Rooney",
            "list_view_selected": false,
            "id": 744
        },
        {
            "folderId": 791,
            "name": "Mr. & Mrs. Louis F. Rosellini",
            "list_view_selected": false,
            "id": 745
        },
        {
            "folderId": 792,
            "name": "Mr. & Mrs. Ronald Rosen",
            "list_view_selected": false,
            "id": 746
        },
        {
            "folderId": 793,
            "name": "Mr. & Mrs. David M. Rosenberg",
            "list_view_selected": false,
            "id": 747
        },
        {
            "folderId": 794,
            "name": "Mr. & Mrs. Franz Rosinus",
            "list_view_selected": false,
            "id": 748
        },
        {
            "folderId": 795,
            "name": "Mr. Russell Bilgore & Ms. June Rosner",
            "list_view_selected": false,
            "id": 749
        },
        {
            "folderId": 796,
            "name": "Mr. James J. Fait & Ms. Barbara K. Ross",
            "list_view_selected": false,
            "id": 750
        },
        {
            "folderId": 797,
            "name": "Mr. & Mrs. Joseph Ross",
            "list_view_selected": false,
            "id": 751
        },
        {
            "folderId": 798,
            "name": "Mrs. Pamela S. Rossiter",
            "list_view_selected": false,
            "id": 752
        },
        {
            "folderId": 799,
            "name": "Rotary Club of Bonita Springs",
            "list_view_selected": false,
            "id": 753
        },
        {
            "folderId": 800,
            "name": "Rotary Club Bonita Springs Noon",
            "list_view_selected": false,
            "id": 754
        },
        {
            "folderId": 801,
            "name": "Rotary Club of Cape Coral Goldcoast",
            "list_view_selected": false,
            "id": 755
        },
        {
            "folderId": 802,
            "name": "Rotary Club of Estero",
            "list_view_selected": false,
            "id": 756
        },
        {
            "folderId": 803,
            "name": "Rotary Club of Sanibel‑Captiva",
            "list_view_selected": false,
            "id": 757
        },
        {
            "folderId": 804,
            "name": "Rotary District 6960",
            "list_view_selected": false,
            "id": 758
        },
        {
            "folderId": 805,
            "name": "Mr. & Mrs. Paul F. Roth",
            "list_view_selected": false,
            "id": 759
        },
        {
            "folderId": 806,
            "name": "Lex & Eileen Roulston",
            "list_view_selected": false,
            "id": 760
        },
        {
            "folderId": 807,
            "name": "Mr. & Mrs. Roy O. Sweeney",
            "list_view_selected": false,
            "id": 761
        },
        {
            "folderId": 808,
            "name": "Col. & Mrs. Alan J. Rubin, USAF (RET)",
            "list_view_selected": false,
            "id": 762
        },
        {
            "folderId": 809,
            "name": "Mr. & Mrs. Jay Russo",
            "list_view_selected": false,
            "id": 763
        },
        {
            "folderId": 810,
            "name": "Ruth E. Harrington Trust",
            "list_view_selected": false,
            "id": 764
        },
        {
            "folderId": 811,
            "name": "Mr. & Mrs. Douglas A. Ryckman",
            "list_view_selected": false,
            "id": 765
        },
        {
            "folderId": 812,
            "name": "Dr. Frederick and Mrs. Susan Ryckman",
            "list_view_selected": false,
            "id": 766
        },
        {
            "folderId": 813,
            "name": "Mrs. Mary Ann D. Saegebarth",
            "list_view_selected": false,
            "id": 767
        },
        {
            "folderId": 814,
            "name": "Dr. Andree A. Dadrat & Dr. Mai F. Saif",
            "list_view_selected": false,
            "id": 768
        },
        {
            "folderId": 815,
            "name": "Saint Katherine Greek Orthodox Church",
            "list_view_selected": false,
            "id": 769
        },
        {
            "folderId": 816,
            "name": "Saks Fifth Avenue",
            "list_view_selected": false,
            "id": 770
        },
        {
            "folderId": 817,
            "name": "Saks Incorporated",
            "list_view_selected": false,
            "id": 771
        },
        {
            "folderId": 818,
            "name": "Mr. Louis A. Saldarini Jr.",
            "list_view_selected": false,
            "id": 772
        },
        {
            "folderId": 819,
            "name": "Dr. Emad and Alissar Salman",
            "list_view_selected": false,
            "id": 773
        },
        {
            "folderId": 820,
            "name": "Salty Crab Bar and Grill Ft Myers",
            "list_view_selected": false,
            "id": 774
        },
        {
            "folderId": 821,
            "name": "Myles R. Samotin, MD",
            "list_view_selected": false,
            "id": 775
        },
        {
            "folderId": 822,
            "name": "Mrs. Nancy S. Sampson",
            "list_view_selected": false,
            "id": 776
        },
        {
            "folderId": 823,
            "name": "Sam's Wholesale Club ‑ #8130",
            "list_view_selected": false,
            "id": 777
        },
        {
            "folderId": 824,
            "name": "Ms. Katharine DuPont Sanger",
            "list_view_selected": false,
            "id": 778
        },
        {
            "folderId": 825,
            "name": "Sanibel Outlets",
            "list_view_selected": false,
            "id": 779
        },
        {
            "folderId": 826,
            "name": "Sanibel Sea School",
            "list_view_selected": false,
            "id": 780
        },
        {
            "folderId": 827,
            "name": "Sarasota Kennel Club, Inc.",
            "list_view_selected": false,
            "id": 781
        },
        {
            "folderId": 828,
            "name": "Mr. & Mrs. John E. Scanlon",
            "list_view_selected": false,
            "id": 782
        },
        {
            "folderId": 829,
            "name": "Mrs. Joy Schein",
            "list_view_selected": false,
            "id": 783
        },
        {
            "folderId": 830,
            "name": "Mr. & Mrs. Gary Schmidt",
            "list_view_selected": false,
            "id": 784
        },
        {
            "folderId": 831,
            "name": "Alois Schultz",
            "list_view_selected": false,
            "id": 785
        },
        {
            "folderId": 832,
            "name": "Dr. & Mrs. Carl Schultz",
            "list_view_selected": false,
            "id": 786
        },
        {
            "folderId": 833,
            "name": "Mr. Douglas D. Schumann",
            "list_view_selected": false,
            "id": 787
        },
        {
            "folderId": 834,
            "name": "Schwab Fund for Charitable Giving",
            "list_view_selected": false,
            "id": 788
        },
        {
            "folderId": 835,
            "name": "Mr. JC Schwalback",
            "list_view_selected": false,
            "id": 789
        },
        {
            "folderId": 836,
            "name": "Scooter Shooter, Inc.",
            "list_view_selected": false,
            "id": 790
        },
        {
            "folderId": 837,
            "name": "Mr. & Mrs. Craig C. Scott, Sr.",
            "list_view_selected": false,
            "id": 791
        },
        {
            "folderId": 838,
            "name": "Mr. Willard H. Scott, Jr.",
            "list_view_selected": false,
            "id": 792
        },
        {
            "folderId": 839,
            "name": "Mr. & Mrs. Adrian J. Scribante",
            "list_view_selected": false,
            "id": 793
        },
        {
            "folderId": 840,
            "name": "Mr. John T. Seaman, Jr.",
            "list_view_selected": false,
            "id": 794
        },
        {
            "folderId": 841,
            "name": "Sear Family Foundation",
            "list_view_selected": false,
            "id": 795
        },
        {
            "folderId": 842,
            "name": "Mr. & Mrs. Robert Sebes",
            "list_view_selected": false,
            "id": 796
        },
        {
            "folderId": 843,
            "name": "Secure Shredding, Inc.",
            "list_view_selected": false,
            "id": 797
        },
        {
            "folderId": 844,
            "name": "Ms. Theresa R. Shea‑See & Mr. Terry See",
            "list_view_selected": false,
            "id": 798
        },
        {
            "folderId": 845,
            "name": "Mrs. Linda D. Seifert",
            "list_view_selected": false,
            "id": 799
        },
        {
            "folderId": 846,
            "name": "Mr. & Mrs. John D. Selby",
            "list_view_selected": false,
            "id": 800
        },
        {
            "folderId": 847,
            "name": "Mr. & Mrs. Matthew Sellick",
            "list_view_selected": false,
            "id": 801
        },
        {
            "folderId": 848,
            "name": "Service Painting of Florida",
            "list_view_selected": false,
            "id": 802
        },
        {
            "folderId": 849,
            "name": "Service Works of Ft. Lauderdale, LLC",
            "list_view_selected": false,
            "id": 803
        },
        {
            "folderId": 850,
            "name": "Dr. & Dr. John C. Sgarlata",
            "list_view_selected": false,
            "id": 804
        },
        {
            "folderId": 851,
            "name": "Mr. & Mrs. Timothy G. Shack",
            "list_view_selected": false,
            "id": 805
        },
        {
            "folderId": 852,
            "name": "Dr. and Mrs. F. Brett Shannon",
            "list_view_selected": false,
            "id": 806
        },
        {
            "folderId": 853,
            "name": "Shawn & Sharon Rooney Fund",
            "list_view_selected": false,
            "id": 807
        },
        {
            "folderId": 854,
            "name": "Mr. & Mrs. Charles H. Sheetz",
            "list_view_selected": false,
            "id": 808
        },
        {
            "folderId": 855,
            "name": "Mr. & Mrs. D. Michael Sherman",
            "list_view_selected": false,
            "id": 809
        },
        {
            "folderId": 856,
            "name": "Estate of Alma Z. Shields",
            "list_view_selected": false,
            "id": 810
        },
        {
            "folderId": 857,
            "name": "Dr. & Mrs. Butch Shorack",
            "list_view_selected": false,
            "id": 811
        },
        {
            "folderId": 858,
            "name": "Shoreline Aggregate, Inc.",
            "list_view_selected": false,
            "id": 812
        },
        {
            "folderId": 859,
            "name": "Heidi & Ken Shoriak",
            "list_view_selected": false,
            "id": 813
        },
        {
            "folderId": 860,
            "name": "ShowTurf LLC",
            "list_view_selected": false,
            "id": 814
        },
        {
            "folderId": 861,
            "name": "Mr. & Dr. John P. Siebart",
            "list_view_selected": false,
            "id": 815
        },
        {
            "folderId": 862,
            "name": "Siesta Pebble Inc.",
            "list_view_selected": false,
            "id": 816
        },
        {
            "folderId": 863,
            "name": "Katherine B. Simpson",
            "list_view_selected": false,
            "id": 817
        },
        {
            "folderId": 864,
            "name": "Mr. & Mrs. Robert A. Simpson",
            "list_view_selected": false,
            "id": 818
        },
        {
            "folderId": 865,
            "name": "Mr. Donald E. Singer",
            "list_view_selected": false,
            "id": 819
        },
        {
            "folderId": 866,
            "name": "Dr. Shaila Singh",
            "list_view_selected": false,
            "id": 820
        },
        {
            "folderId": 867,
            "name": "Skate for Hope",
            "list_view_selected": false,
            "id": 821
        },
        {
            "folderId": 868,
            "name": "Bill & Pat Smart",
            "list_view_selected": false,
            "id": 822
        },
        {
            "folderId": 869,
            "name": "Mr. & Mrs. Douglas Smith",
            "list_view_selected": false,
            "id": 823
        },
        {
            "folderId": 870,
            "name": "Mr. & Mrs. Michael W. Smith",
            "list_view_selected": false,
            "id": 824
        },
        {
            "folderId": 871,
            "name": "Mr. & Mrs. Paul M. Smith II",
            "list_view_selected": false,
            "id": 825
        },
        {
            "folderId": 872,
            "name": "Lisa Snyder",
            "list_view_selected": false,
            "id": 826
        },
        {
            "folderId": 873,
            "name": "Society First Federal Savings Bank",
            "list_view_selected": false,
            "id": 827
        },
        {
            "folderId": 874,
            "name": "CRMC South Advertising",
            "list_view_selected": false,
            "id": 828
        },
        {
            "folderId": 875,
            "name": "South Florida Mud Run",
            "list_view_selected": false,
            "id": 829
        },
        {
            "folderId": 876,
            "name": "South Seas Islands Resorts",
            "list_view_selected": false,
            "id": 830
        },
        {
            "folderId": 877,
            "name": "Southwest Florida Regional Medical Center",
            "list_view_selected": false,
            "id": 831
        },
        {
            "folderId": 878,
            "name": "Mr. & Mrs. Jack B. Spitler",
            "list_view_selected": false,
            "id": 832
        },
        {
            "folderId": 879,
            "name": "Mr. & Mrs. James D. Sprankle",
            "list_view_selected": false,
            "id": 833
        },
        {
            "folderId": 880,
            "name": "Mr. & Mrs. John Sprecher",
            "list_view_selected": false,
            "id": 834
        },
        {
            "folderId": 881,
            "name": "Ms. Virginia D. Stabile",
            "list_view_selected": false,
            "id": 835
        },
        {
            "folderId": 882,
            "name": "Ms. Nannette Starapoli",
            "list_view_selected": false,
            "id": 836
        },
        {
            "folderId": 883,
            "name": "Mrs. Ann Starck",
            "list_view_selected": false,
            "id": 837
        },
        {
            "folderId": 884,
            "name": "Steele Technical Services",
            "list_view_selected": false,
            "id": 838
        },
        {
            "folderId": 885,
            "name": "Mr. & Mrs. Ted Steele",
            "list_view_selected": false,
            "id": 839
        },
        {
            "folderId": 886,
            "name": "Stefanie Roth Memorial Cancer Fdn, Inc.",
            "list_view_selected": false,
            "id": 840
        },
        {
            "folderId": 887,
            "name": "Mr. & Mrs. Daniel K. Stegmann",
            "list_view_selected": false,
            "id": 841
        },
        {
            "folderId": 888,
            "name": "Mr. & Mrs. Robert E. Stein",
            "list_view_selected": false,
            "id": 842
        },
        {
            "folderId": 889,
            "name": "Ms. Amanda Stephens",
            "list_view_selected": false,
            "id": 843
        },
        {
            "folderId": 890,
            "name": "Steris Corporation",
            "list_view_selected": false,
            "id": 844
        },
        {
            "folderId": 891,
            "name": "Dr. & Mrs. John A. Steubs",
            "list_view_selected": false,
            "id": 845
        },
        {
            "folderId": 892,
            "name": "Mr. James Stewart",
            "list_view_selected": false,
            "id": 846
        },
        {
            "folderId": 893,
            "name": "Ms. LeAnne Stewart",
            "list_view_selected": false,
            "id": 847
        },
        {
            "folderId": 894,
            "name": "Mr. & Mrs. John G. Stimpson",
            "list_view_selected": false,
            "id": 848
        },
        {
            "folderId": 895,
            "name": "Mr. & Mrs. Brian Stock",
            "list_view_selected": false,
            "id": 849
        },
        {
            "folderId": 896,
            "name": "Storm Smart Building System, Inc.",
            "list_view_selected": false,
            "id": 850
        },
        {
            "folderId": 897,
            "name": "Ms. Adra M. Strom",
            "list_view_selected": false,
            "id": 851
        },
        {
            "folderId": 898,
            "name": "Subaru of America, Inc.",
            "list_view_selected": false,
            "id": 852
        },
        {
            "folderId": 899,
            "name": "Mr. Frank Jannelli & Ms. Deirdre Sullivan",
            "list_view_selected": false,
            "id": 853
        },
        {
            "folderId": 900,
            "name": "Mr. & Mrs. Michael Sullivan",
            "list_view_selected": false,
            "id": 854
        },
        {
            "folderId": 901,
            "name": "Mr. & Mrs. Thomas F. Sullivan",
            "list_view_selected": false,
            "id": 855
        },
        {
            "folderId": 902,
            "name": "Sun Broadcasting, Inc.",
            "list_view_selected": false,
            "id": 856
        },
        {
            "folderId": 903,
            "name": "Suncoast Beverage Sales Family Charitable Trust",
            "list_view_selected": false,
            "id": 857
        },
        {
            "folderId": 904,
            "name": "SunTrust Bank",
            "list_view_selected": false,
            "id": 858
        },
        {
            "folderId": 905,
            "name": "SunTrust Bank of Southwest Florida",
            "list_view_selected": false,
            "id": 859
        },
        {
            "folderId": 906,
            "name": "Sutherlin Nissan of Fort Myers",
            "list_view_selected": false,
            "id": 860
        },
        {
            "folderId": 907,
            "name": "Sweetbay Supermarket",
            "list_view_selected": false,
            "id": 861
        },
        {
            "folderId": 908,
            "name": "Swing for the Kids Charities, Inc.",
            "list_view_selected": false,
            "id": 862
        },
        {
            "folderId": 909,
            "name": "Synergy Commercial Group",
            "list_view_selected": false,
            "id": 863
        },
        {
            "folderId": 910,
            "name": "Dr. & Mrs. Ronny S. Taschner",
            "list_view_selected": false,
            "id": 864
        },
        {
            "folderId": 911,
            "name": "Ms. Lee A. Tauck",
            "list_view_selected": false,
            "id": 865
        },
        {
            "folderId": 912,
            "name": "Charles and Emmie Taylor",
            "list_view_selected": false,
            "id": 866
        },
        {
            "folderId": 913,
            "name": "Ms. Merrill J. Taylor",
            "list_view_selected": false,
            "id": 867
        },
        {
            "folderId": 914,
            "name": "Mr. & Mrs. Philip E. Taylor",
            "list_view_selected": false,
            "id": 868
        },
        {
            "folderId": 915,
            "name": "Mr. & Mrs. Richard F. Teerlink",
            "list_view_selected": false,
            "id": 869
        },
        {
            "folderId": 916,
            "name": "Mr. John Templeton",
            "list_view_selected": false,
            "id": 870
        },
        {
            "folderId": 917,
            "name": "Mr. & Mrs. Kostaq Terezi",
            "list_view_selected": false,
            "id": 871
        },
        {
            "folderId": 918,
            "name": "Mr. & Mrs. Romeo Terezi",
            "list_view_selected": false,
            "id": 872
        },
        {
            "folderId": 919,
            "name": "Mrs. Terry Weiner",
            "list_view_selected": false,
            "id": 873
        },
        {
            "folderId": 920,
            "name": "The ALS Association Florida Chapter",
            "list_view_selected": false,
            "id": 874
        },
        {
            "folderId": 921,
            "name": "The Andrew Family Foundation",
            "list_view_selected": false,
            "id": 875
        },
        {
            "folderId": 922,
            "name": "The Bahnik Foundation",
            "list_view_selected": false,
            "id": 876
        },
        {
            "folderId": 923,
            "name": "The Belk Foundation",
            "list_view_selected": false,
            "id": 877
        },
        {
            "folderId": 924,
            "name": "The Club at Mediterra",
            "list_view_selected": false,
            "id": 878
        },
        {
            "folderId": 925,
            "name": "The Colony Golf and Country Club",
            "list_view_selected": false,
            "id": 879
        },
        {
            "folderId": 926,
            "name": "The Donahue Family Foundation, Inc.",
            "list_view_selected": false,
            "id": 880
        },
        {
            "folderId": 927,
            "name": "The Ethel & W. George Kennedy Family Foundation, Inc.",
            "list_view_selected": false,
            "id": 881
        },
        {
            "folderId": 928,
            "name": "The Frances Pew Hayes Family Foundation",
            "list_view_selected": false,
            "id": 882
        },
        {
            "folderId": 929,
            "name": "The Hall Family Foundation",
            "list_view_selected": false,
            "id": 883
        },
        {
            "folderId": 930,
            "name": "The Hinman Foundation",
            "list_view_selected": false,
            "id": 884
        },
        {
            "folderId": 931,
            "name": "The Hoffer Family Foundation, Inc.",
            "list_view_selected": false,
            "id": 885
        },
        {
            "folderId": 932,
            "name": "The Jack Parker Corporation",
            "list_view_selected": false,
            "id": 886
        },
        {
            "folderId": 933,
            "name": "The Janice Michelle Foundation",
            "list_view_selected": false,
            "id": 887
        },
        {
            "folderId": 934,
            "name": "The John H. & Dorothy M. Castle Charitable Trust",
            "list_view_selected": false,
            "id": 888
        },
        {
            "folderId": 935,
            "name": "The Johnson Family Fund of the Ayco Charitable Foundation",
            "list_view_selected": false,
            "id": 889
        },
        {
            "folderId": 936,
            "name": "The Johnson Family Fund of the Ayco Charitable Foundation",
            "list_view_selected": false,
            "id": 890
        },
        {
            "folderId": 937,
            "name": "The Kinkle Family ‑ In Loving Memory of Kimmie Kinkle",
            "list_view_selected": false,
            "id": 891
        },
        {
            "folderId": 938,
            "name": "The Landings Yacht Golf & Tennis Club, Inc.",
            "list_view_selected": false,
            "id": 892
        },
        {
            "folderId": 939,
            "name": "The League Club, Inc.",
            "list_view_selected": false,
            "id": 893
        },
        {
            "folderId": 940,
            "name": "The Mary E. Dooner Foundation, Inc.",
            "list_view_selected": false,
            "id": 894
        },
        {
            "folderId": 941,
            "name": "The Medicines Company",
            "list_view_selected": false,
            "id": 895
        },
        {
            "folderId": 942,
            "name": "The Merck Company Foundation",
            "list_view_selected": false,
            "id": 896
        },
        {
            "folderId": 943,
            "name": "The Michael Magro Foundation",
            "list_view_selected": false,
            "id": 897
        },
        {
            "folderId": 944,
            "name": "The National Parkinson Foundation Care Center",
            "list_view_selected": false,
            "id": 898
        },
        {
            "folderId": 945,
            "name": "The O'Brien Family Foundation",
            "list_view_selected": false,
            "id": 899
        },
        {
            "folderId": 946,
            "name": "The Optimist Club Foundation of Sanibel Captiva, Inc.",
            "list_view_selected": false,
            "id": 900
        },
        {
            "folderId": 947,
            "name": "The Pete Renner Foundation, Inc.",
            "list_view_selected": false,
            "id": 901
        },
        {
            "folderId": 948,
            "name": "The Rajki Foundation",
            "list_view_selected": false,
            "id": 902
        },
        {
            "folderId": 949,
            "name": "The Ritz Carlton",
            "list_view_selected": false,
            "id": 903
        },
        {
            "folderId": 950,
            "name": "The Rutter Foundation",
            "list_view_selected": false,
            "id": 904
        },
        {
            "folderId": 951,
            "name": "The Sharp Family Foundation",
            "list_view_selected": false,
            "id": 905
        },
        {
            "folderId": 952,
            "name": "The Sidney & Esther Rabb Charitable Foundation",
            "list_view_selected": false,
            "id": 906
        },
        {
            "folderId": 953,
            "name": "The Strike 3 Foundation Inc.",
            "list_view_selected": false,
            "id": 907
        },
        {
            "folderId": 954,
            "name": "The Tarquini Foundation",
            "list_view_selected": false,
            "id": 908
        },
        {
            "folderId": 955,
            "name": "The Westin Cape Coral Resort at\\Marina Village",
            "list_view_selected": false,
            "id": 909
        },
        {
            "folderId": 956,
            "name": "The/Cape Coral Kiwanis Foundation, Inc.",
            "list_view_selected": false,
            "id": 910
        },
        {
            "folderId": 957,
            "name": "Mr. & Mrs. Kip W. Thomas",
            "list_view_selected": false,
            "id": 911
        },
        {
            "folderId": 958,
            "name": "Mr. & Mrs. Gary L. Tinkham",
            "list_view_selected": false,
            "id": 912
        },
        {
            "folderId": 959,
            "name": "Mr. & Ms. Bill Toler",
            "list_view_selected": false,
            "id": 913
        },
        {
            "folderId": 960,
            "name": "Mr. & Mrs. Edward A. Tombs",
            "list_view_selected": false,
            "id": 914
        },
        {
            "folderId": 961,
            "name": "Mr. & Mrs. Thomas J. Torpey",
            "list_view_selected": false,
            "id": 915
        },
        {
            "folderId": 962,
            "name": "TransAmerica Retirement Solutions",
            "list_view_selected": false,
            "id": 916
        },
        {
            "folderId": 963,
            "name": "Mr. & Mrs. David Trepkowski",
            "list_view_selected": false,
            "id": 917
        },
        {
            "folderId": 964,
            "name": "Trinity‑by‑the‑Cove Episcopal Church",
            "list_view_selected": false,
            "id": 918
        },
        {
            "folderId": 965,
            "name": "U.S. Trust",
            "list_view_selected": false,
            "id": 919
        },
        {
            "folderId": 966,
            "name": "Dr. Richard M. and Eleanor Klein Family Trust",
            "list_view_selected": false,
            "id": 920
        },
        {
            "folderId": 967,
            "name": "Turkey Trot",
            "list_view_selected": false,
            "id": 921
        },
        {
            "folderId": 968,
            "name": "Mr. & Mrs. Peter Uddo",
            "list_view_selected": false,
            "id": 922
        },
        {
            "folderId": 969,
            "name": "Ms. Mary B. Ullman",
            "list_view_selected": false,
            "id": 923
        },
        {
            "folderId": 970,
            "name": "Ultimate Sports Adventures",
            "list_view_selected": false,
            "id": 924
        },
        {
            "folderId": 971,
            "name": "Uncle Brewski's",
            "list_view_selected": false,
            "id": 925
        },
        {
            "folderId": 972,
            "name": "United States Surgical Corporation",
            "list_view_selected": false,
            "id": 926
        },
        {
            "folderId": 973,
            "name": "University of Florida",
            "list_view_selected": false,
            "id": 927
        },
        {
            "folderId": 974,
            "name": "Mr. & Mrs. Michael J. Valiquette",
            "list_view_selected": false,
            "id": 928
        },
        {
            "folderId": 975,
            "name": "Vanguard Charitable Endowment Program",
            "list_view_selected": false,
            "id": 929
        },
        {
            "folderId": 976,
            "name": "Mr. & Mrs. George Varsam",
            "list_view_selected": false,
            "id": 930
        },
        {
            "folderId": 977,
            "name": "Vasari Cares Foundation, Inc.",
            "list_view_selected": false,
            "id": 931
        },
        {
            "folderId": 978,
            "name": "Vasari Country Club",
            "list_view_selected": false,
            "id": 932
        },
        {
            "folderId": 979,
            "name": "Mr. & Mrs. David R. Venarge",
            "list_view_selected": false,
            "id": 933
        },
        {
            "folderId": 980,
            "name": "Verandah Club ‑ Bonita Bay Group",
            "list_view_selected": false,
            "id": 934
        },
        {
            "folderId": 981,
            "name": "VFW ‑ Mid Point Cape Coral James Crockford Memorial Post 492",
            "list_view_selected": false,
            "id": 935
        },
        {
            "folderId": 982,
            "name": "VHA, Inc.",
            "list_view_selected": false,
            "id": 936
        },
        {
            "folderId": 983,
            "name": "Mr. James R. Vincent",
            "list_view_selected": false,
            "id": 937
        },
        {
            "folderId": 984,
            "name": "Mrs. Mary Lou Viticonte",
            "list_view_selected": false,
            "id": 938
        },
        {
            "folderId": 985,
            "name": "Mr. & Mrs. Donald A. Vogt",
            "list_view_selected": false,
            "id": 939
        },
        {
            "folderId": 986,
            "name": "Mr. & Mrs. Conrad Voldstad",
            "list_view_selected": false,
            "id": 940
        },
        {
            "folderId": 987,
            "name": "Mr. & Mrs. Allan Voss",
            "list_view_selected": false,
            "id": 941
        },
        {
            "folderId": 988,
            "name": "Wachovia Bank",
            "list_view_selected": false,
            "id": 942
        },
        {
            "folderId": 989,
            "name": "Wachovia Securities",
            "list_view_selected": false,
            "id": 943
        },
        {
            "folderId": 990,
            "name": "Mr. James P. Waddel",
            "list_view_selected": false,
            "id": 944
        },
        {
            "folderId": 991,
            "name": "Mr. Alvin L. Wagner, Jr.",
            "list_view_selected": false,
            "id": 945
        },
        {
            "folderId": 992,
            "name": "Dr. & Mrs. Robert W. Wagner",
            "list_view_selected": false,
            "id": 946
        },
        {
            "folderId": 993,
            "name": "Mr. William Waldron",
            "list_view_selected": false,
            "id": 947
        },
        {
            "folderId": 994,
            "name": "Walgreens",
            "list_view_selected": false,
            "id": 948
        },
        {
            "folderId": 995,
            "name": "Mr. & Mrs. John L. Walkley",
            "list_view_selected": false,
            "id": 949
        },
        {
            "folderId": 996,
            "name": "Wal‑Mart Foundation",
            "list_view_selected": false,
            "id": 950
        },
        {
            "folderId": 997,
            "name": "R.S. Walsh Landscaping, Inc.",
            "list_view_selected": false,
            "id": 951
        },
        {
            "folderId": 998,
            "name": "Mr. Joseph M. Walton",
            "list_view_selected": false,
            "id": 952
        },
        {
            "folderId": 999,
            "name": "Mr. John Washburn",
            "list_view_selected": false,
            "id": 953
        },
        {
            "folderId": 1000,
            "name": "Mrs. & Mr. Sheilah R. Waters",
            "list_view_selected": false,
            "id": 954
        },
        {
            "folderId": 1001,
            "name": "Ms. Nanelle Wehmann",
            "list_view_selected": false,
            "id": 955
        },
        {
            "folderId": 1002,
            "name": "Wells Fargo Advisors, LLC",
            "list_view_selected": false,
            "id": 956
        },
        {
            "folderId": 1003,
            "name": "Wells Fargo Bank",
            "list_view_selected": false,
            "id": 957
        },
        {
            "folderId": 1004,
            "name": "Wells Fargo Wealth Management",
            "list_view_selected": false,
            "id": 958
        },
        {
            "folderId": 1005,
            "name": "Mrs. Debra H. Welsh",
            "list_view_selected": false,
            "id": 959
        },
        {
            "folderId": 1006,
            "name": "Mr. Jerry H. Welty",
            "list_view_selected": false,
            "id": 960
        },
        {
            "folderId": 1007,
            "name": "Wendy's Restaurants",
            "list_view_selected": false,
            "id": 961
        },
        {
            "folderId": 1008,
            "name": "Mr. & Mrs. Frederick Wenk",
            "list_view_selected": false,
            "id": 962
        },
        {
            "folderId": 1009,
            "name": "West Bay Club",
            "list_view_selected": false,
            "id": 963
        },
        {
            "folderId": 1010,
            "name": "Mr. & Mrs. Edgar West",
            "list_view_selected": false,
            "id": 964
        },
        {
            "folderId": 1011,
            "name": "Whitemore Fire Consultants, Inc.",
            "list_view_selected": false,
            "id": 965
        },
        {
            "folderId": 1012,
            "name": "Mr. & Mrs. Eric P. Wiesemann",
            "list_view_selected": false,
            "id": 966
        },
        {
            "folderId": 1013,
            "name": "Mr. & Mrs. Robert O. Wiesemann",
            "list_view_selected": false,
            "id": 967
        },
        {
            "folderId": 1014,
            "name": "Mr. & Mrs. John K. Wiest",
            "list_view_selected": false,
            "id": 968
        },
        {
            "folderId": 1015,
            "name": "Mr. Harold D. Wilcoxen",
            "list_view_selected": false,
            "id": 969
        },
        {
            "folderId": 1016,
            "name": "Mr. & Mrs. Andrew Williams",
            "list_view_selected": false,
            "id": 970
        },
        {
            "folderId": 1017,
            "name": "Mr. & Mrs. Clifford N. Williams",
            "list_view_selected": false,
            "id": 971
        },
        {
            "folderId": 1018,
            "name": "Charles & Paula Williamsen",
            "list_view_selected": false,
            "id": 972
        },
        {
            "folderId": 1019,
            "name": "Mr. & Mrs. Scott Willis",
            "list_view_selected": false,
            "id": 973
        },
        {
            "folderId": 1020,
            "name": "Mr. & Mrs. Gerald B. Wilson",
            "list_view_selected": false,
            "id": 974
        },
        {
            "folderId": 1021,
            "name": "Mr. & Mrs. Brad Wind",
            "list_view_selected": false,
            "id": 975
        },
        {
            "folderId": 1022,
            "name": "Mrs. Gayle G. Winter",
            "list_view_selected": false,
            "id": 976
        },
        {
            "folderId": 1023,
            "name": "Mr. & Mrs. Vincent Wolanin",
            "list_view_selected": false,
            "id": 977
        },
        {
            "folderId": 1024,
            "name": "Mr. & Mrs. John M. Wolf, Jr.",
            "list_view_selected": false,
            "id": 978
        },
        {
            "folderId": 1025,
            "name": "Wolf, Metzger, Maurillo & Banfield Wealth Management Group",
            "list_view_selected": false,
            "id": 979
        },
        {
            "folderId": 1026,
            "name": "Mr. James S. Wolfson",
            "list_view_selected": false,
            "id": 980
        },
        {
            "folderId": 1027,
            "name": "Mrs. Gloria F. Wood",
            "list_view_selected": false,
            "id": 981
        },
        {
            "folderId": 1028,
            "name": "Woods, Weidenmiller, Michetti & Rudnick, P.L.",
            "list_view_selected": false,
            "id": 982
        },
        {
            "folderId": 1029,
            "name": "Woodworth‑Upjohn Foundation",
            "list_view_selected": false,
            "id": 983
        },
        {
            "folderId": 1030,
            "name": "Wounded Warrior Stables",
            "list_view_selected": false,
            "id": 984
        },
        {
            "folderId": 1031,
            "name": "Markham Norton Mostellar Wright",
            "list_view_selected": false,
            "id": 985
        },
        {
            "folderId": 1032,
            "name": "Mr. & Mrs. Robert J. Wright",
            "list_view_selected": false,
            "id": 986
        },
        {
            "folderId": 1033,
            "name": "Mr. & Mrs. James A. Wurster",
            "list_view_selected": false,
            "id": 987
        },
        {
            "folderId": 1034,
            "name": "Young Artists Awards, INC.",
            "list_view_selected": false,
            "id": 988
        },
        {
            "folderId": 1035,
            "name": "Mr. & Mrs. William A. Young",
            "list_view_selected": false,
            "id": 989
        },
        {
            "folderId": 1036,
            "name": "Mr. & Mrs. Harvey B. Youngquist, Jr.",
            "list_view_selected": false,
            "id": 990
        },
        {
            "folderId": 1037,
            "name": "Mr. Tim G. Youngquist & Mrs. Sandra K. Youngquist",
            "list_view_selected": false,
            "id": 991
        },
        {
            "folderId": 1038,
            "name": "Staurt and Lucia Zaikov",
            "list_view_selected": false,
            "id": 992
        },
        {
            "folderId": 1039,
            "name": "Rick and Evette Zurbriggen",
            "list_view_selected": false,
            "id": 993
        },
        {
            "folderId": 1040,
            "name": "Zurbriggen Financial",
            "list_view_selected": false,
            "id": 994
        },
        {
            "folderId": 1078,
            "name": "Test Donorggg",
            "sorting_order": "abc",
            "list_view_selected": false,
            "id": 995
        },
        {
            "folderId": 1079,
            "name": "Timmy Timmy",
            "sorting_order": "abc",
            "list_view_selected": false,
            "id": 996
        },
        {
            "folderId": 1080,
            "name": "Test Smith",
            "sorting_order": "xyz",
            "list_view_selected": false,
            "id": 997
        },
        {
            "folderId": 1083,
            "name": "Test Smithz",
            "sorting_order": "supersorterrific",
            "list_view_selected": false,
            "id": 998
        },
        {
            "folderId": 1084,
            "name": "Test Donorggg",
            "sorting_order": "abc",
            "list_view_selected": false,
            "id": 999
        },
        {
            "folderId": 1085,
            "name": "Timmy Timmy",
            "sorting_order": "abc",
            "list_view_selected": false,
            "id": 1000
        },
        {
            "folderId": 1086,
            "name": "Tyler Ferdinand Wooley",
            "sorting_order": "Wooley, Tyler F",
            "list_view_selected": false,
            "id": 1001
        },
        {
            "folderId": 1087,
            "name": "Colby Whitenackz",
            "sorting_order": "Updates for all",
            "list_view_selected": false,
            "id": 1002
        },
        {
            "folderId": 1088,
            "name": "21st. Century Oncology",
            "sorting_order": "As Entered",
            "list_view_selected": false,
            "id": 1003
        },
        {
            "folderId": 1089,
            "name": "4 The Kids Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1004
        },
        {
            "folderId": 1090,
            "name": "Abbey Carpet Co, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1005
        },
        {
            "folderId": 1091,
            "name": "Mr. & Mrs. Don W. Abbott",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1006
        },
        {
            "folderId": 1092,
            "name": "Ms. Pamela Lynn Abraham",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1007
        },
        {
            "folderId": 1093,
            "name": "Acuson Computed Sonography",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1008
        },
        {
            "folderId": 1094,
            "name": "Mr. James Adams",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1009
        },
        {
            "folderId": 1095,
            "name": "Aetna Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1010
        },
        {
            "folderId": 1096,
            "name": "Cheryl & Vlad Agranovich",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1011
        },
        {
            "folderId": 1097,
            "name": "Aileen S. Andrew Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1012
        },
        {
            "folderId": 1098,
            "name": "Akerman, LLP",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1013
        },
        {
            "folderId": 1099,
            "name": "Mr. & Mrs. Richard B. Akin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1014
        },
        {
            "folderId": 1100,
            "name": "Ms. Betsy Alderman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1015
        },
        {
            "folderId": 1101,
            "name": "Dr. O. A. Alea",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1016
        },
        {
            "folderId": 1102,
            "name": "Mr. & Mrs. Jules Allen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1017
        },
        {
            "folderId": 1103,
            "name": "Mr. & Mrs. Robert R. Allen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1018
        },
        {
            "folderId": 1104,
            "name": "Alliance Financial Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1019
        },
        {
            "folderId": 1105,
            "name": "Allyn International Services, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1020
        },
        {
            "folderId": 1106,
            "name": "Alpha Home Care Services, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1021
        },
        {
            "folderId": 1107,
            "name": "American Cancer Society ‑ Service Business Center",
            "sorting_order": "111",
            "list_view_selected": false,
            "id": 1022
        },
        {
            "folderId": 1108,
            "name": "American International Group, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1023
        },
        {
            "folderId": 1109,
            "name": "Mrs. Florence Anderson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1024
        },
        {
            "folderId": 1110,
            "name": "Mrs. Karen Anderson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1025
        },
        {
            "folderId": 1111,
            "name": "Mr. Edward R. Andrus",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1026
        },
        {
            "folderId": 1112,
            "name": "Ms. Anna M. St Angelo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1027
        },
        {
            "folderId": 1113,
            "name": "Ann F. Dodson Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1028
        },
        {
            "folderId": 1114,
            "name": "Anonymous (5)",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1029
        },
        {
            "folderId": 1115,
            "name": "Mrs. Gaile H. Anthony",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1030
        },
        {
            "folderId": 1116,
            "name": "Dr. & Mrs. Lawrence R. Antonucci",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1031
        },
        {
            "folderId": 1117,
            "name": "J. Keith & Maureen Arnold",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1032
        },
        {
            "folderId": 1118,
            "name": "Arthrex Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1033
        },
        {
            "folderId": 1119,
            "name": "Arthur B. McBride Sr. Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1034
        },
        {
            "folderId": 1120,
            "name": "Arthur Rutenberg Homes",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1035
        },
        {
            "folderId": 1121,
            "name": "Mr. & Mrs. Ronald Ascione",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1036
        },
        {
            "folderId": 1122,
            "name": "Joseph Clark Atkins & Elizabeth Atkins Charitable Remainder",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1037
        },
        {
            "folderId": 1123,
            "name": "Ms. & Mr. Rick Atkinson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1038
        },
        {
            "folderId": 1124,
            "name": "AtriCure",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1039
        },
        {
            "folderId": 1125,
            "name": "Audi of Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1040
        },
        {
            "folderId": 1126,
            "name": "Audi of Naples/Volkswagen of Naples",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1041
        },
        {
            "folderId": 1127,
            "name": "Mr. & Mrs. Norman R. Augustine",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1042
        },
        {
            "folderId": 1128,
            "name": "Ayco Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1043
        },
        {
            "folderId": 1129,
            "name": "B & I Contractors, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1044
        },
        {
            "folderId": 1130,
            "name": "Mr. G. C. Bailey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1045
        },
        {
            "folderId": 1131,
            "name": "Mrs. June E. Bailey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1046
        },
        {
            "folderId": 1132,
            "name": "Bailey's General Store",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1047
        },
        {
            "folderId": 1133,
            "name": "In Memory of Betty Ball",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1048
        },
        {
            "folderId": 1134,
            "name": "Bank of America",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1049
        },
        {
            "folderId": 1135,
            "name": "Bank of the Islands",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1050
        },
        {
            "folderId": 1136,
            "name": "Dr. & Mrs. Mark Banks",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1051
        },
        {
            "folderId": 1137,
            "name": "Baptist Health System South Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1052
        },
        {
            "folderId": 1138,
            "name": "Ms. Barbara E. Truitt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1053
        },
        {
            "folderId": 1139,
            "name": "Mrs. Alice Barrett",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1054
        },
        {
            "folderId": 1140,
            "name": "Barron Collier, Jr. Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1055
        },
        {
            "folderId": 1141,
            "name": "Barsull Corporation INC.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1056
        },
        {
            "folderId": 1142,
            "name": "Ms. Mary S. Barth",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1057
        },
        {
            "folderId": 1143,
            "name": "Mr. Richard A. Baus",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1058
        },
        {
            "folderId": 1144,
            "name": "Mr. David J. Bautsch",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1059
        },
        {
            "folderId": 1145,
            "name": "BB&T",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1060
        },
        {
            "folderId": 1146,
            "name": "BBDO",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1061
        },
        {
            "folderId": 1147,
            "name": "BD Global, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1062
        },
        {
            "folderId": 1148,
            "name": "Mr. & Mrs. Paul Beechen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1063
        },
        {
            "folderId": 1149,
            "name": "Mr. Eugene A. Beeler",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1064
        },
        {
            "folderId": 1150,
            "name": "Mr. & Mrs. Lee D. Behrhorst",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1065
        },
        {
            "folderId": 1151,
            "name": "Ms. Karen Bell",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1066
        },
        {
            "folderId": 1152,
            "name": "Edward & Rachel Bell",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1067
        },
        {
            "folderId": 1153,
            "name": "Shane and Sheldon Bellestri",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1068
        },
        {
            "folderId": 1154,
            "name": "Mr. Jeff Belzer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1069
        },
        {
            "folderId": 1155,
            "name": "Mr. Peter J. Bender",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1070
        },
        {
            "folderId": 1156,
            "name": "Mr. & Mrs. Michael L. Bennett",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1071
        },
        {
            "folderId": 1157,
            "name": "Mr. & Mrs. Robert T. Berendt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1072
        },
        {
            "folderId": 1158,
            "name": "Mr. & Mrs. James Berg",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1073
        },
        {
            "folderId": 1159,
            "name": "Lynne & Marvin Birdt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1074
        },
        {
            "folderId": 1160,
            "name": "Frank W. Bireley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1075
        },
        {
            "folderId": 1161,
            "name": "Mr. Frank Bireley Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1076
        },
        {
            "folderId": 1162,
            "name": "Mr. & Mrs. Roger Bireley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1077
        },
        {
            "folderId": 1163,
            "name": "Mr. Edward G. Bischoff Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1078
        },
        {
            "folderId": 1164,
            "name": "Mr. C. W. Black",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1079
        },
        {
            "folderId": 1165,
            "name": "Mrs. Betty Blackwell",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1080
        },
        {
            "folderId": 1166,
            "name": "Willis & Mary Blackwood",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1081
        },
        {
            "folderId": 1167,
            "name": "Virginia Blaesi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1082
        },
        {
            "folderId": 1168,
            "name": "Mr. Andrew Bloom",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1083
        },
        {
            "folderId": 1169,
            "name": "Blue Cross & Blue Shield of Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1084
        },
        {
            "folderId": 1170,
            "name": "Boater's Landing",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1085
        },
        {
            "folderId": 1171,
            "name": "Mr. & Mrs. William W. Bohn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1086
        },
        {
            "folderId": 1172,
            "name": "Mrs. Pam K. Bolton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1087
        },
        {
            "folderId": 1173,
            "name": "Bonita Bay Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1088
        },
        {
            "folderId": 1174,
            "name": "The Bonita Bay Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1089
        },
        {
            "folderId": 1175,
            "name": "Bonita Springs Assistance Office, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1090
        },
        {
            "folderId": 1176,
            "name": "Bonita Springs Lions Charities, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1091
        },
        {
            "folderId": 1177,
            "name": "Mrs. Nancy Boots",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1092
        },
        {
            "folderId": 1178,
            "name": "Ms. Alexandra Bordiuk",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1093
        },
        {
            "folderId": 1179,
            "name": "Mr. & Mrs. Robert W. Boreham",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1094
        },
        {
            "folderId": 1180,
            "name": "Steve King & Susan Boren",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1095
        },
        {
            "folderId": 1181,
            "name": "Boston Red Sox",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1096
        },
        {
            "folderId": 1182,
            "name": "Boston Retirement Advisors, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1097
        },
        {
            "folderId": 1183,
            "name": "Bovis Lend Lease, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1098
        },
        {
            "folderId": 1184,
            "name": "Mr. & Mrs. Wayne Boyd",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1099
        },
        {
            "folderId": 1185,
            "name": "Mr. & Mrs. Thomas F. Boyle",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1100
        },
        {
            "folderId": 1186,
            "name": "Bracco Diagnostics, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1101
        },
        {
            "folderId": 1187,
            "name": "Mr. & Mrs. John Brando",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1102
        },
        {
            "folderId": 1188,
            "name": "Mr. & Mrs. Stephen Bransfield",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1103
        },
        {
            "folderId": 1189,
            "name": "Mr. & Mrs. Stephen R. Bright",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1104
        },
        {
            "folderId": 1190,
            "name": "Britt and Diane Newhouse Charitable Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1105
        },
        {
            "folderId": 1191,
            "name": "Broadway Palm Dinner Theater",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1106
        },
        {
            "folderId": 1192,
            "name": "Mr. & Mrs. Barry Bronstein",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1107
        },
        {
            "folderId": 1193,
            "name": "Brooke's Restaurant & Lounge",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1108
        },
        {
            "folderId": 1194,
            "name": "Brown & Brown of Florida, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1109
        },
        {
            "folderId": 1195,
            "name": "Mr. & Mrs. Richard L. Brown",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1110
        },
        {
            "folderId": 1196,
            "name": "Mr. & Mrs. Rick Brown",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1111
        },
        {
            "folderId": 1197,
            "name": "Mr. & Mrs. Charles Bruning",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1112
        },
        {
            "folderId": 1198,
            "name": "Bruno's of Brooklyn Italian Eatery",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1113
        },
        {
            "folderId": 1199,
            "name": "Mr. William T. Bryan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1114
        },
        {
            "folderId": 1200,
            "name": "Buchanan, Ingersoll & Rooney",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1115
        },
        {
            "folderId": 1201,
            "name": "Mr. Thomas A. Buckley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1116
        },
        {
            "folderId": 1202,
            "name": "Mr. & Mrs. Charles Budreck",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1117
        },
        {
            "folderId": 1203,
            "name": "Build, L.L.C.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1118
        },
        {
            "folderId": 1204,
            "name": "Mr. & Mrs. Edmund Burke",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1119
        },
        {
            "folderId": 1205,
            "name": "Mr. & Mrs. Jack Burns",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1120
        },
        {
            "folderId": 1206,
            "name": "Burt L. Saunders Campaign Account",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1121
        },
        {
            "folderId": 1207,
            "name": "Bush Industries, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1122
        },
        {
            "folderId": 1208,
            "name": "Mr. Peter Malen & Ms. Mary E. Byck",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1123
        },
        {
            "folderId": 1209,
            "name": "Byck‑Rothschild Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1124
        },
        {
            "folderId": 1210,
            "name": "Dr. & Mrs. Craig Byers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1125
        },
        {
            "folderId": 1211,
            "name": "Mr. & Mrs. Robert T. Cahill",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1126
        },
        {
            "folderId": 1212,
            "name": "Mr. Mike Calbot",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1127
        },
        {
            "folderId": 1213,
            "name": "Cameratta Companies",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1128
        },
        {
            "folderId": 1214,
            "name": "Mr. & Mrs. Don L. Canterna",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1129
        },
        {
            "folderId": 1215,
            "name": "Cape Coral Community Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1130
        },
        {
            "folderId": 1216,
            "name": "Capital Properties Group, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1131
        },
        {
            "folderId": 1217,
            "name": "Captiva Cruises",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1132
        },
        {
            "folderId": 1218,
            "name": "Mr. & Mrs. Ed Catalano",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1133
        },
        {
            "folderId": 1219,
            "name": "Catania Spagna Corp.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1134
        },
        {
            "folderId": 1220,
            "name": "Mr. & Mrs. B. Proctor Caudill, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1135
        },
        {
            "folderId": 1221,
            "name": "Centex Construction Healthcare",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1136
        },
        {
            "folderId": 1222,
            "name": "Central Indiana Community Foundation & The Indianapolis FDN",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1137
        },
        {
            "folderId": 1223,
            "name": "Mr. & Mrs. Duane Chaney",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1138
        },
        {
            "folderId": 1224,
            "name": "Charlotte County Chamber of Commerce",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1139
        },
        {
            "folderId": 1225,
            "name": "Chatlos Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1140
        },
        {
            "folderId": 1226,
            "name": "Mr. & Mrs. Jack Chestnut",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1141
        },
        {
            "folderId": 1227,
            "name": "Chick‑fil‑A",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1142
        },
        {
            "folderId": 1228,
            "name": "Chick‑fil‑A at Port Charlotte FSU",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1143
        },
        {
            "folderId": 1229,
            "name": "Mr. & Mrs. Thomas W. Childress",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1144
        },
        {
            "folderId": 1230,
            "name": "City Mattress of Florida, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1145
        },
        {
            "folderId": 1231,
            "name": "Clark Family Foundation Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1146
        },
        {
            "folderId": 1232,
            "name": "Mr. & Mrs. Arthur L. Cleary",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1147
        },
        {
            "folderId": 1233,
            "name": "Mr. & Mrs. Philip J. Clemente",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1148
        },
        {
            "folderId": 1234,
            "name": "Mr. & Mrs. Gary A. Clewell",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1149
        },
        {
            "folderId": 1235,
            "name": "P.O.L.O. Club of Lee County",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1150
        },
        {
            "folderId": 1236,
            "name": "Coconut Point Ford",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1151
        },
        {
            "folderId": 1237,
            "name": "Mrs. Phyllis L. Coffin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1152
        },
        {
            "folderId": 1238,
            "name": "Dr. & Mrs. Richard C. Cohan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1153
        },
        {
            "folderId": 1239,
            "name": "Mr. & Mrs. Howard K. Cohen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1154
        },
        {
            "folderId": 1240,
            "name": "Sandra & Dr. Sanford Cohen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1155
        },
        {
            "folderId": 1241,
            "name": "Ms. Brittany F. Cohill",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1156
        },
        {
            "folderId": 1242,
            "name": "Mr. & Mrs. Steve Colgate",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1157
        },
        {
            "folderId": 1243,
            "name": "Collector Cars TV Show",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1158
        },
        {
            "folderId": 1244,
            "name": "Dr. & Mrs. Michael E. Collier",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1159
        },
        {
            "folderId": 1245,
            "name": "Mrs. Bonnie Collins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1160
        },
        {
            "folderId": 1246,
            "name": "Mr. & Mrs. David F. Collins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1161
        },
        {
            "folderId": 1247,
            "name": "John Collins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1162
        },
        {
            "folderId": 1248,
            "name": "Colonial Country Club Ladies 9 Hole Golf Assn.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1163
        },
        {
            "folderId": 1249,
            "name": "Colonial Country Club of Lee Co. Master Association",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1164
        },
        {
            "folderId": 1250,
            "name": "Amazon.com",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1165
        },
        {
            "folderId": 1251,
            "name": "Commonwealth Purchasing Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1166
        },
        {
            "folderId": 1252,
            "name": "WCI Communities, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1167
        },
        {
            "folderId": 1253,
            "name": "Community Foundation of Collier County",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1168
        },
        {
            "folderId": 1254,
            "name": "Community Foundation of Sanibel‑Captiva",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1169
        },
        {
            "folderId": 1255,
            "name": "Mr. & Mrs. Bruce D. Conley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1170
        },
        {
            "folderId": 1256,
            "name": "Mr. & Mrs. Tracy A. Connelly",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1171
        },
        {
            "folderId": 1257,
            "name": "Ms. Jane Cookson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1172
        },
        {
            "folderId": 1258,
            "name": "Mr. & Mrs. Gordon R. Coons",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1173
        },
        {
            "folderId": 1259,
            "name": "Cornerstone Financial Planning",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1174
        },
        {
            "folderId": 1260,
            "name": "Corvettes On The Gulf",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1175
        },
        {
            "folderId": 1261,
            "name": "Eileen A. Courtney",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1176
        },
        {
            "folderId": 1262,
            "name": "Cove Crafters",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1177
        },
        {
            "folderId": 1263,
            "name": "George M. Cox Estate",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1178
        },
        {
            "folderId": 1264,
            "name": "Mr. & Mrs. Morton J. Crane",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1179
        },
        {
            "folderId": 1265,
            "name": "Mr. Richard B. Karp & Ms. Christina Crawfis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1180
        },
        {
            "folderId": 1266,
            "name": "Mr. Hugh W. Crawford, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1181
        },
        {
            "folderId": 1267,
            "name": "Cre8 Salon & Spa",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1182
        },
        {
            "folderId": 1268,
            "name": "Creative Foam",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1183
        },
        {
            "folderId": 1269,
            "name": "Francis J. Crimmins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1184
        },
        {
            "folderId": 1270,
            "name": "Ms. Susan O'Bryan Croft",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1185
        },
        {
            "folderId": 1271,
            "name": "Mr. & Mrs. William D. Cronin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1186
        },
        {
            "folderId": 1272,
            "name": "Ms. Lindsay Torpey Cross",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1187
        },
        {
            "folderId": 1273,
            "name": "Crowther Roofing & Sheet Metal of Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1188
        },
        {
            "folderId": 1274,
            "name": "Mr. & Mrs. Ben H. Crump",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1189
        },
        {
            "folderId": 1275,
            "name": "Ms. Jeannie M. Cummings",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1190
        },
        {
            "folderId": 1276,
            "name": "Cure Search for Children's Cancer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1191
        },
        {
            "folderId": 1277,
            "name": "Mr. & Mrs. R. W. Curler",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1192
        },
        {
            "folderId": 1278,
            "name": "Mr. and Mrs. Scott Curvey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1193
        },
        {
            "folderId": 1279,
            "name": "Custom Packaging & Products, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1194
        },
        {
            "folderId": 1280,
            "name": "Ms. Joanne M. Cutway",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1195
        },
        {
            "folderId": 1281,
            "name": "Cypress Woods Cares‑4‑Kids",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1196
        },
        {
            "folderId": 1282,
            "name": "Mr. & Mrs. Elton O. Dalton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1197
        },
        {
            "folderId": 1283,
            "name": "K. Alex Daneshmand, D.O. and Patricia Jara Danesmand, D.O.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1198
        },
        {
            "folderId": 1284,
            "name": "Estate of W. Daniels, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1199
        },
        {
            "folderId": 1285,
            "name": "Mr. & Mrs. John C. Dannemiller",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1200
        },
        {
            "folderId": 1286,
            "name": "Mr. & Mrs. Nader F. Darehshori",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1201
        },
        {
            "folderId": 1287,
            "name": "Mr. & Mrs. John B. Darling",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1202
        },
        {
            "folderId": 1288,
            "name": "Mr. Frank G. Daveler",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1203
        },
        {
            "folderId": 1289,
            "name": "Mr. & Mrs. David Turk",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1204
        },
        {
            "folderId": 1290,
            "name": "Mrs. Judtih A. Davidson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1205
        },
        {
            "folderId": 1291,
            "name": "Davis Love III Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1206
        },
        {
            "folderId": 1292,
            "name": "Mr. & Mrs. Ronald L. Davis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1207
        },
        {
            "folderId": 1293,
            "name": "Mr. Steve Davis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1208
        },
        {
            "folderId": 1294,
            "name": "SPADA Day Spa",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1209
        },
        {
            "folderId": 1295,
            "name": "Dean & Monica Piccirillo Family Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1210
        },
        {
            "folderId": 1296,
            "name": "DeAngelis Diamond Construction, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1211
        },
        {
            "folderId": 1297,
            "name": "Mr. David A. Powar & Mrs. Maria DeCavalles‑Powar",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1212
        },
        {
            "folderId": 1298,
            "name": "Mr. Mark Deprey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1213
        },
        {
            "folderId": 1299,
            "name": "DeRoyal, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1214
        },
        {
            "folderId": 1300,
            "name": "Mr. & Mrs. Peter Dessak",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1215
        },
        {
            "folderId": 1301,
            "name": "Mr. & Mrs. Wayne Detling",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1216
        },
        {
            "folderId": 1302,
            "name": "Mr. George Deveau",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1217
        },
        {
            "folderId": 1303,
            "name": "DeWitt Wallace‑Reader's Digest Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1218
        },
        {
            "folderId": 1304,
            "name": "Mr. & Mrs. Norman S. DeWolfe",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1219
        },
        {
            "folderId": 1305,
            "name": "Mr. Scott Plank & Ms. Dana M. DiCarlo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1220
        },
        {
            "folderId": 1306,
            "name": "Mr. & Mrs. John E. Dick",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1221
        },
        {
            "folderId": 1307,
            "name": "Ms. Shannon Diener",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1222
        },
        {
            "folderId": 1308,
            "name": "Digestive Health Physicians, P.A.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1223
        },
        {
            "folderId": 1309,
            "name": "Dr. David Yu and DeeDee DiSesa",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1224
        },
        {
            "folderId": 1310,
            "name": "Mr. & Mrs. Robert C. Dix",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1225
        },
        {
            "folderId": 1311,
            "name": "Mr. & Mrs. Richard J. Donahue",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1226
        },
        {
            "folderId": 1312,
            "name": "Donald W. Goodwin Pooled Income Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1227
        },
        {
            "folderId": 1313,
            "name": "Mr. David L. Doran",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1228
        },
        {
            "folderId": 1314,
            "name": "Dr. Daniel Dosoretz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1229
        },
        {
            "folderId": 1315,
            "name": "Mr. & Mrs. James A. Doss Sr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1230
        },
        {
            "folderId": 1316,
            "name": "Mr. & Mrs. William K. Douglas",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1231
        },
        {
            "folderId": 1317,
            "name": "Mr. & Mrs. Wayne Doyle",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1232
        },
        {
            "folderId": 1318,
            "name": "Mr. & Mrs. Edward C. Drennan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1233
        },
        {
            "folderId": 1319,
            "name": "Mr. & Mrs. William E. Drum",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1234
        },
        {
            "folderId": 1320,
            "name": "Mr. & Mrs. John G. D. Dubuque",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1235
        },
        {
            "folderId": 1321,
            "name": "Duke Clinical Research Institute",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1236
        },
        {
            "folderId": 1322,
            "name": "Dunkin's Diamonds",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1237
        },
        {
            "folderId": 1323,
            "name": "Mrs. Gail L. Dupre'",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1238
        },
        {
            "folderId": 1324,
            "name": "Mr. & Mrs. Robert P. Duvin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1239
        },
        {
            "folderId": 1325,
            "name": "Mr. & Mrs. Henri Dyner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1240
        },
        {
            "folderId": 1326,
            "name": "Eau Claire Community Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1241
        },
        {
            "folderId": 1327,
            "name": "Edison National Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1242
        },
        {
            "folderId": 1328,
            "name": "Mr. Allan L. Denbesten & Mrs. Annie Edsall",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1243
        },
        {
            "folderId": 1329,
            "name": "Edward Don & Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1244
        },
        {
            "folderId": 1330,
            "name": "Edwards Lifesciences LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1245
        },
        {
            "folderId": 1331,
            "name": "Ms. Amanda Egan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1246
        },
        {
            "folderId": 1332,
            "name": "Elias Brothers Painting & Waterproofing",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1247
        },
        {
            "folderId": 1333,
            "name": "Mrs. Sande L. Ellis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1248
        },
        {
            "folderId": 1334,
            "name": "Mr. William M. Ellis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1249
        },
        {
            "folderId": 1335,
            "name": "Don & Jennie Baum Endowment",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1250
        },
        {
            "folderId": 1336,
            "name": "Enterprise Holdings Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1251
        },
        {
            "folderId": 1337,
            "name": "Enterprise Rent‑A‑Car Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1252
        },
        {
            "folderId": 1338,
            "name": "Entertainment Industry Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1253
        },
        {
            "folderId": 1339,
            "name": "Epic Charitable Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1254
        },
        {
            "folderId": 1340,
            "name": "Ms. Jeanette L. Epstein",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1255
        },
        {
            "folderId": 1341,
            "name": "Mr. & Mrs. James Ermer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1256
        },
        {
            "folderId": 1342,
            "name": "Esperia at Bonita Bay, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1257
        },
        {
            "folderId": 1343,
            "name": "Estate of Robert E. Grasso",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1258
        },
        {
            "folderId": 1344,
            "name": "Estate of Rosalie Payne",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1259
        },
        {
            "folderId": 1345,
            "name": "Estero Country Club, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1260
        },
        {
            "folderId": 1346,
            "name": "EV3, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1261
        },
        {
            "folderId": 1347,
            "name": "Mr. & Mrs. Homer L. Evans",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1262
        },
        {
            "folderId": 1348,
            "name": "Faith Fellowship World Outreach",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1263
        },
        {
            "folderId": 1349,
            "name": "Family Health Centers of SW FL, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1264
        },
        {
            "folderId": 1350,
            "name": "The Sublett Family",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1265
        },
        {
            "folderId": 1351,
            "name": "Family Thrift Center",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1266
        },
        {
            "folderId": 1352,
            "name": "Mr. Anthony J. Farina",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1267
        },
        {
            "folderId": 1353,
            "name": "Ms. Anne Fassett",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1268
        },
        {
            "folderId": 1354,
            "name": "Faux Bonita Tennis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1269
        },
        {
            "folderId": 1355,
            "name": "Faux Funky Golf",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1270
        },
        {
            "folderId": 1356,
            "name": "Ms. Kelly L. Fayer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1271
        },
        {
            "folderId": 1357,
            "name": "Mr. & Mrs. Donald Feiner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1272
        },
        {
            "folderId": 1358,
            "name": "Mrs. Frances C. Fenning",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1273
        },
        {
            "folderId": 1359,
            "name": "Dr. Bianca Ferrari",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1274
        },
        {
            "folderId": 1360,
            "name": "Mr. & Mrs. Thomas Ferraro",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1275
        },
        {
            "folderId": 1361,
            "name": "Mr. Jose Fichmann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1276
        },
        {
            "folderId": 1362,
            "name": "Fiddlesticks Country Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1277
        },
        {
            "folderId": 1363,
            "name": "Fifth Avenue Advisors, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1278
        },
        {
            "folderId": 1364,
            "name": "UBS Financial Services",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1279
        },
        {
            "folderId": 1365,
            "name": "FineMark National Bank & Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1280
        },
        {
            "folderId": 1366,
            "name": "Mr. & Mrs. Daniel Fink",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1281
        },
        {
            "folderId": 1367,
            "name": "Mr. Matthew T. Finn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1282
        },
        {
            "folderId": 1368,
            "name": "First Community Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1283
        },
        {
            "folderId": 1369,
            "name": "Mrs. Lina C. Fisher",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1284
        },
        {
            "folderId": 1370,
            "name": "Mr. Donald L. Fjellin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1285
        },
        {
            "folderId": 1371,
            "name": "Ms. Patricia Fleischman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1286
        },
        {
            "folderId": 1372,
            "name": "Ms. Virginia L. Fleming",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1287
        },
        {
            "folderId": 1373,
            "name": "Florida Cancer Specialists",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1288
        },
        {
            "folderId": 1374,
            "name": "Florida Department of Education",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1289
        },
        {
            "folderId": 1375,
            "name": "Florida Department of Transportation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1290
        },
        {
            "folderId": 1376,
            "name": "Florida Gulf Coast University Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1291
        },
        {
            "folderId": 1377,
            "name": "Florida Dept. of State ‑ Div of Library & Information Svcs.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1292
        },
        {
            "folderId": 1378,
            "name": "Florida Weekly",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1293
        },
        {
            "folderId": 1379,
            "name": "Florida Wood Window & Door, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1294
        },
        {
            "folderId": 1380,
            "name": "Ms. Stacy Forman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1295
        },
        {
            "folderId": 1381,
            "name": "BMW of Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1296
        },
        {
            "folderId": 1382,
            "name": "DF Fort Myers Beach, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1297
        },
        {
            "folderId": 1383,
            "name": "Fort Myers Beach Lions Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1298
        },
        {
            "folderId": 1384,
            "name": "Fort Myers Miracle Baseball",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1299
        },
        {
            "folderId": 1385,
            "name": "Fort Myers Seventh‑Day Adventist Church",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1300
        },
        {
            "folderId": 1386,
            "name": "Ms. Janet F. Foster",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1301
        },
        {
            "folderId": 1387,
            "name": "TJX Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1302
        },
        {
            "folderId": 1388,
            "name": "RSM US Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1303
        },
        {
            "folderId": 1389,
            "name": "Bill & Andrea Douglas Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1304
        },
        {
            "folderId": 1390,
            "name": "Peggy M. Harold Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1305
        },
        {
            "folderId": 1391,
            "name": "UBS Foundation USA Matching Gift Program",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1306
        },
        {
            "folderId": 1392,
            "name": "GE Foundation Matching Gifts",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1307
        },
        {
            "folderId": 1393,
            "name": "Foxfire Country Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1308
        },
        {
            "folderId": 1394,
            "name": "Francis P. Hindelong Memorial Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1309
        },
        {
            "folderId": 1395,
            "name": "Mrs. Geraldine A. Freels",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1310
        },
        {
            "folderId": 1396,
            "name": "Freeman and Hasselwander Resort Properties LLC.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1311
        },
        {
            "folderId": 1397,
            "name": "Mr. James L. French",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1312
        },
        {
            "folderId": 1398,
            "name": "Mr. & Mrs. Alan Fritze",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1313
        },
        {
            "folderId": 1399,
            "name": "Mr. Robert R. Frost",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1314
        },
        {
            "folderId": 1400,
            "name": "Mr. Allan W. Fulkerson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1315
        },
        {
            "folderId": 1401,
            "name": "Mr. & Mrs. John Dannemiller Jubilee Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1316
        },
        {
            "folderId": 1402,
            "name": "Mr. & Mrs. James Gabrick",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1317
        },
        {
            "folderId": 1403,
            "name": "Mr. & Mrs. Pason Gaddis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1318
        },
        {
            "folderId": 1404,
            "name": "Mr. & Mrs. Marty Gallagher",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1319
        },
        {
            "folderId": 1405,
            "name": "Gallant Family Gift Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1320
        },
        {
            "folderId": 1406,
            "name": "Mr. & Mrs. Stephen S. Gallant",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1321
        },
        {
            "folderId": 1407,
            "name": "Galloway Ford, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1322
        },
        {
            "folderId": 1408,
            "name": "Drs. Vijay & Jayshree Ganatra",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1323
        },
        {
            "folderId": 1409,
            "name": "Gannett Communities Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1324
        },
        {
            "folderId": 1410,
            "name": "Gannett Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1325
        },
        {
            "folderId": 1411,
            "name": "Gannett Match Fdn ‑ c/o Community Foundation of Louisville",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1326
        },
        {
            "folderId": 1412,
            "name": "Dr. Carmen T. Garcia",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1327
        },
        {
            "folderId": 1413,
            "name": "Mr. Ron C. Gardenhire",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1328
        },
        {
            "folderId": 1414,
            "name": "Mr. & Mrs. R. E. Garlinghouse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1329
        },
        {
            "folderId": 1415,
            "name": "Garlinghouse Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1330
        },
        {
            "folderId": 1416,
            "name": "Mr. & Mrs. Jeffrey S. Gately",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1331
        },
        {
            "folderId": 1417,
            "name": "Gendron Funeral & Cremation Services",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1332
        },
        {
            "folderId": 1418,
            "name": "Genentech, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1333
        },
        {
            "folderId": 1419,
            "name": "Mr. Bernard Genevish",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1334
        },
        {
            "folderId": 1420,
            "name": "Mr. & Mrs. Larry Gentine",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1335
        },
        {
            "folderId": 1421,
            "name": "German American Club Gemuetlichkert of Naples, FL Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1336
        },
        {
            "folderId": 1422,
            "name": "Mr. & Mrs. Michael J. German",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1337
        },
        {
            "folderId": 1423,
            "name": "Mr. & Mrs. Daniel F. Gerner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1338
        },
        {
            "folderId": 1424,
            "name": "Mr. David J. Giarla",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1339
        },
        {
            "folderId": 1425,
            "name": "Mr. Ken Gill",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1340
        },
        {
            "folderId": 1426,
            "name": "Mr. & Mrs. Robert W. Gillespie",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1341
        },
        {
            "folderId": 1427,
            "name": "Mr. & Mrs. Robert A. Glancy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1342
        },
        {
            "folderId": 1428,
            "name": "Mrs. Linda Glantz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1343
        },
        {
            "folderId": 1429,
            "name": "Glase Golf",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1344
        },
        {
            "folderId": 1430,
            "name": "Mr. & Mrs. John M. Gleeson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1345
        },
        {
            "folderId": 1431,
            "name": "Mr. Patrick Glennon",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1346
        },
        {
            "folderId": 1432,
            "name": "Mr. & Mrs. Robert Glick",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1347
        },
        {
            "folderId": 1433,
            "name": "Mr. & Mrs. John W. Gnagey III",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1348
        },
        {
            "folderId": 1434,
            "name": "Goede, Adamczyck & DeBoest, PLLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1349
        },
        {
            "folderId": 1435,
            "name": "Mr. Mark Goggin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1350
        },
        {
            "folderId": 1436,
            "name": "Goldman Sachs & Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1351
        },
        {
            "folderId": 1437,
            "name": "Mr. & Mrs. John O. Goldsmith",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1352
        },
        {
            "folderId": 1438,
            "name": "Mr. & Mrs. Anthony Golobic",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1353
        },
        {
            "folderId": 1439,
            "name": "Good Neighbor Community Foundation of Sanibel‑Captiva",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1354
        },
        {
            "folderId": 1440,
            "name": "Mr. Mark S. Goodman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1355
        },
        {
            "folderId": 1441,
            "name": "Mrs. Louise M. Goodwin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1356
        },
        {
            "folderId": 1442,
            "name": "Mr. & Mrs. Clark L. Gordon",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1357
        },
        {
            "folderId": 1443,
            "name": "Mr. & Mrs. N. Bryson Goss",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1358
        },
        {
            "folderId": 1444,
            "name": "The Honorable & Mrs. Porter J. Goss",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1359
        },
        {
            "folderId": 1445,
            "name": "Mr. Alvin R. Gould",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1360
        },
        {
            "folderId": 1446,
            "name": "Gould Family Trust of Gulf Coast Community",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1361
        },
        {
            "folderId": 1447,
            "name": "Matt and Laura Grabinski",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1362
        },
        {
            "folderId": 1448,
            "name": "Mr. Bruce Grachek",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1363
        },
        {
            "folderId": 1449,
            "name": "Mr. Randy Grachek",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1364
        },
        {
            "folderId": 1450,
            "name": "Mrs. Leone A. Graham",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1365
        },
        {
            "folderId": 1451,
            "name": "Mr. & Mrs. Robert J. Grappone",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1366
        },
        {
            "folderId": 1452,
            "name": "Mrs. Jeannette M. Grasso",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1367
        },
        {
            "folderId": 1453,
            "name": "Mr. & Mrs. Vincent Grattarola",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1368
        },
        {
            "folderId": 1454,
            "name": "Mr. & Mrs. Bary W. Gray",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1369
        },
        {
            "folderId": 1455,
            "name": "Great Clips",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1370
        },
        {
            "folderId": 1456,
            "name": "REALTOR Assoc. of Greater Ft Myers & the Beach, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1371
        },
        {
            "folderId": 1457,
            "name": "Mr. & Mrs. Lloyd L. Green",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1372
        },
        {
            "folderId": 1458,
            "name": "Mr. & Mrs. Jay Gribble",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1373
        },
        {
            "folderId": 1459,
            "name": "Mr. & Mrs. Chetan Gulati",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1374
        },
        {
            "folderId": 1460,
            "name": "Gulf Coast Dodge, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1375
        },
        {
            "folderId": 1461,
            "name": "Gulf Coast Harmonizers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1376
        },
        {
            "folderId": 1462,
            "name": "Gulf Coast Homes of Lee County, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1377
        },
        {
            "folderId": 1463,
            "name": "Gulf Harbour Golf & Country Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1378
        },
        {
            "folderId": 1464,
            "name": "Gulfcoast Coin & Jewelry Brokers, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1379
        },
        {
            "folderId": 1465,
            "name": "Mr. & Mrs. Bruce Gurall",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1380
        },
        {
            "folderId": 1466,
            "name": "Mr. & Mrs. George M. Guthrie",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1381
        },
        {
            "folderId": 1467,
            "name": "Ms. Beverly Haas",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1382
        },
        {
            "folderId": 1468,
            "name": "Mr. & Mrs. William D. Haas",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1383
        },
        {
            "folderId": 1469,
            "name": "James D. &  Karen J. Hall",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1384
        },
        {
            "folderId": 1470,
            "name": "Mr. & Mrs. Chuck Hallberg",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1385
        },
        {
            "folderId": 1471,
            "name": "Halliday Lumber",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1386
        },
        {
            "folderId": 1472,
            "name": "Mr. & Mrs. William R. Hallisey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1387
        },
        {
            "folderId": 1473,
            "name": "Mr. & Mrs. Donald F. Halucha",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1388
        },
        {
            "folderId": 1474,
            "name": "Mr. & Mrs. George Roland Hamilton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1389
        },
        {
            "folderId": 1475,
            "name": "Mr. & Mrs. Richard Hammer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1390
        },
        {
            "folderId": 1476,
            "name": "Diane and Steve Hancock",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1391
        },
        {
            "folderId": 1477,
            "name": "Dr. & Mrs. Stephen E. Hannan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1392
        },
        {
            "folderId": 1478,
            "name": "Mr. & Mrs. Chris Hansen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1393
        },
        {
            "folderId": 1479,
            "name": "Mr. & Mrs. Robert M. Hansen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1394
        },
        {
            "folderId": 1480,
            "name": "Cindy & Richard Harding",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1395
        },
        {
            "folderId": 1481,
            "name": "Harley‑Davidson of Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1396
        },
        {
            "folderId": 1482,
            "name": "Ms. Margaret M. Harold",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1397
        },
        {
            "folderId": 1483,
            "name": "Mr. & Mrs. Donald C. Harralson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1398
        },
        {
            "folderId": 1484,
            "name": "Mr. & Mrs. Richard Haskins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1399
        },
        {
            "folderId": 1485,
            "name": "Dr. Edward C. Wheeler & Ms. Anne E. Haslem",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1400
        },
        {
            "folderId": 1486,
            "name": "Mr. Timothy Hasse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1401
        },
        {
            "folderId": 1487,
            "name": "HCA, Inc. ‑ Florida Consult A Nurse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1402
        },
        {
            "folderId": 1488,
            "name": "CVS Health",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1403
        },
        {
            "folderId": 1489,
            "name": "VHA Health Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1404
        },
        {
            "folderId": 1490,
            "name": "PNC Healthcare",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1405
        },
        {
            "folderId": 1491,
            "name": "Hearing Research Institute, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1406
        },
        {
            "folderId": 1492,
            "name": "Hearts of Miromar",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1407
        },
        {
            "folderId": 1493,
            "name": "Dr. Edgar W. Hedges",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1408
        },
        {
            "folderId": 1494,
            "name": "Mr. & Mrs. Donald G. Heeringa",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1409
        },
        {
            "folderId": 1495,
            "name": "Henderson, Franklin, Starnes & Holt, P.A.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1410
        },
        {
            "folderId": 1496,
            "name": "Henderson, Franklin, Starnes & Holt, P.A.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1411
        },
        {
            "folderId": 1497,
            "name": "Dr. & Mrs. Douglas G. Henricks",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1412
        },
        {
            "folderId": 1498,
            "name": "John & Jane Henshaw",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1413
        },
        {
            "folderId": 1499,
            "name": "Frank & Patsy Hiatt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1414
        },
        {
            "folderId": 1500,
            "name": "Mr. & Mrs. Duane M. Higgins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1415
        },
        {
            "folderId": 1501,
            "name": "Ms. Jennifer S. Higgins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1416
        },
        {
            "folderId": 1502,
            "name": "Ms. Barbara S. High",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1417
        },
        {
            "folderId": 1503,
            "name": "Highland Associates, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1418
        },
        {
            "folderId": 1504,
            "name": "Mr. James C. Park & Ms. Georgene L. Hildebrand",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1419
        },
        {
            "folderId": 1505,
            "name": "Mr. & Mrs. Thomas A. Himes",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1420
        },
        {
            "folderId": 1506,
            "name": "Mr. & Mrs. John F. Hindelong",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1421
        },
        {
            "folderId": 1507,
            "name": "Mr. & Mrs. Larry J. Hinman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1422
        },
        {
            "folderId": 1508,
            "name": "Ambassador & Mrs. Alfred Hoffman, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1423
        },
        {
            "folderId": 1509,
            "name": "Mr. & Mrs. William M. Holcomb",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1424
        },
        {
            "folderId": 1510,
            "name": "Mrs. Margie Holland",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1425
        },
        {
            "folderId": 1511,
            "name": "Mrs. Jeannine S. Holway",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1426
        },
        {
            "folderId": 1512,
            "name": "Hooters/LTP Management Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1427
        },
        {
            "folderId": 1513,
            "name": "Hooters/Marinos Charity Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1428
        },
        {
            "folderId": 1514,
            "name": "Mr. & Mrs. Sean M. Hoover",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1429
        },
        {
            "folderId": 1515,
            "name": "Hope For Haiti",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1430
        },
        {
            "folderId": 1516,
            "name": "Mr. & Mrs. Richard A. Hopkins",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1431
        },
        {
            "folderId": 1517,
            "name": "Mrs. Mary W. Hotchkiss",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1432
        },
        {
            "folderId": 1518,
            "name": "House of Wu",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1433
        },
        {
            "folderId": 1519,
            "name": "Mr. & Ms. John Howard",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1434
        },
        {
            "folderId": 1520,
            "name": "Mr. & Mrs. John D. Huether",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1435
        },
        {
            "folderId": 1521,
            "name": "Huff Insurance Agency",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1436
        },
        {
            "folderId": 1522,
            "name": "Mrs. Margaret F. Hulit",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1437
        },
        {
            "folderId": 1523,
            "name": "Dr. & Mrs. Brian W. Hummel",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1438
        },
        {
            "folderId": 1524,
            "name": "Jim and Nancy Humphrey & Family",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1439
        },
        {
            "folderId": 1525,
            "name": "Mr. Gerald W. Hunter",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1440
        },
        {
            "folderId": 1526,
            "name": "Iglewski Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1441
        },
        {
            "folderId": 1527,
            "name": "PGT Industries",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1442
        },
        {
            "folderId": 1528,
            "name": "Mr. & Mrs. Ronald E. Inge",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1443
        },
        {
            "folderId": 1529,
            "name": "USI Insurance Services",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1444
        },
        {
            "folderId": 1530,
            "name": "PPD Investigator Services, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1445
        },
        {
            "folderId": 1531,
            "name": "Islands Night",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1446
        },
        {
            "folderId": 1532,
            "name": "Jackie's Family Restaurant",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1447
        },
        {
            "folderId": 1533,
            "name": "Mr. & Mrs. Jeffrey Alan Jacobson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1448
        },
        {
            "folderId": 1534,
            "name": "Ms. Deborah Jacobson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1449
        },
        {
            "folderId": 1535,
            "name": "Mr. & Mrs. Friedrich N. Jaeger",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1450
        },
        {
            "folderId": 1536,
            "name": "Jaguar/Landrover/Audi ‑ Ft. Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1451
        },
        {
            "folderId": 1537,
            "name": "James C and Susen H Berg Family Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1452
        },
        {
            "folderId": 1538,
            "name": "Jason's Deli of Southwest Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1453
        },
        {
            "folderId": 1539,
            "name": "Mr. & Mrs. Jeffrey Swan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1454
        },
        {
            "folderId": 1540,
            "name": "Mr. Bill Jergens",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1455
        },
        {
            "folderId": 1541,
            "name": "R.B. Jergens Contractors, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1456
        },
        {
            "folderId": 1542,
            "name": "Jerry H. Welty Family Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1457
        },
        {
            "folderId": 1543,
            "name": "Jersey Mike's Subs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1458
        },
        {
            "folderId": 1544,
            "name": "John P. Stabile Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1459
        },
        {
            "folderId": 1545,
            "name": "Mr. & Mrs. John C. Trudeau",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1460
        },
        {
            "folderId": 1546,
            "name": "John R. Wood Island Real Estate, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1461
        },
        {
            "folderId": 1547,
            "name": "Johnnie B. Byrd Alzheimer's Center & Research Institute",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1462
        },
        {
            "folderId": 1548,
            "name": "Mr. Charles E. Johnson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1463
        },
        {
            "folderId": 1549,
            "name": "R.D. Johnson Construction",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1464
        },
        {
            "folderId": 1550,
            "name": "Ms. Elizabeth G. Johnson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1465
        },
        {
            "folderId": 1551,
            "name": "Johnson & Johnson Family of Companies",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1466
        },
        {
            "folderId": 1552,
            "name": "Mr. & Mrs. Lance E. Johnson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1467
        },
        {
            "folderId": 1553,
            "name": "Mr. Courtney P. Jones",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1468
        },
        {
            "folderId": 1554,
            "name": "Joyful Yoga, Meditation And More",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1469
        },
        {
            "folderId": 1555,
            "name": "Mr. & Mrs. Brian W. Judd",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1470
        },
        {
            "folderId": 1556,
            "name": "Judge Franklin Mann, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1471
        },
        {
            "folderId": 1557,
            "name": "Mrs. Judith C. Sykora",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1472
        },
        {
            "folderId": 1558,
            "name": "Loralie H. Junda",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1473
        },
        {
            "folderId": 1559,
            "name": "Kaiser Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1474
        },
        {
            "folderId": 1560,
            "name": "Dr. & Mrs. George C. Kalemeris",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1475
        },
        {
            "folderId": 1561,
            "name": "Mrs. Mary Ann Kanzius",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1476
        },
        {
            "folderId": 1562,
            "name": "Mrs. Karen E. O'Bryan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1477
        },
        {
            "folderId": 1563,
            "name": "Karezi, INC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1478
        },
        {
            "folderId": 1564,
            "name": "Mr. & Mrs. Scott Kashman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1479
        },
        {
            "folderId": 1565,
            "name": "Mr. & Mrs. William E. Kassling",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1480
        },
        {
            "folderId": 1566,
            "name": "Mr. & Mrs. Frank G. Kavane",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1481
        },
        {
            "folderId": 1567,
            "name": "Thad Kazmierski",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1482
        },
        {
            "folderId": 1568,
            "name": "Marilyn & Jim Keller",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1483
        },
        {
            "folderId": 1569,
            "name": "Kelly Crossing, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1484
        },
        {
            "folderId": 1570,
            "name": "Ms. Linda Kelly",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1485
        },
        {
            "folderId": 1571,
            "name": "Mr. Michael F. Kelly",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1486
        },
        {
            "folderId": 1572,
            "name": "Mr. & Mrs. Brian Kemmis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1487
        },
        {
            "folderId": 1573,
            "name": "Mr. & Mrs. Roger B. Kennedy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1488
        },
        {
            "folderId": 1574,
            "name": "Chuck & Helen Ketteman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1489
        },
        {
            "folderId": 1575,
            "name": "Key Private Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1490
        },
        {
            "folderId": 1576,
            "name": "Mr. Edward J. Kfoury",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1491
        },
        {
            "folderId": 1577,
            "name": "Mrs. Barbara Kimber",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1492
        },
        {
            "folderId": 1578,
            "name": "Mr. & Mrs. Gary A. King",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1493
        },
        {
            "folderId": 1579,
            "name": "Mr. & Mrs. Russell W. Kirchner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1494
        },
        {
            "folderId": 1580,
            "name": "John J. Kirlin, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1495
        },
        {
            "folderId": 1581,
            "name": "Dr. & Mrs. William S. Kiser",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1496
        },
        {
            "folderId": 1582,
            "name": "Kiwanis Club of Iona McGregor",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1497
        },
        {
            "folderId": 1583,
            "name": "Mr. & Mrs. Fred Klopp",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1498
        },
        {
            "folderId": 1584,
            "name": "Charles V. Klucka, D.O., P.A.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1499
        },
        {
            "folderId": 1585,
            "name": "Knott, Ebelini, & Hart P.A.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1500
        },
        {
            "folderId": 1586,
            "name": "Knox Rental",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1501
        },
        {
            "folderId": 1587,
            "name": "Mrs. Margaret W. Korten",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1502
        },
        {
            "folderId": 1588,
            "name": "Mr. & Mrs. Dennis Kozlowski",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1503
        },
        {
            "folderId": 1589,
            "name": "Kraft Construction Company, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1504
        },
        {
            "folderId": 1590,
            "name": "Mr. & Mrs. Carl A. Kreager",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1505
        },
        {
            "folderId": 1591,
            "name": "Mrs. Nora Krieger",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1506
        },
        {
            "folderId": 1592,
            "name": "Mr. & Mrs. Al Kriss",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1507
        },
        {
            "folderId": 1593,
            "name": "Dr. & Mrs. Charles A. Krivenko",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1508
        },
        {
            "folderId": 1594,
            "name": "Ms. Cynthia F. Kruesi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1509
        },
        {
            "folderId": 1595,
            "name": "Kruse International",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1510
        },
        {
            "folderId": 1596,
            "name": "Dr. & Mrs. LeRoy H. Kulis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1511
        },
        {
            "folderId": 1597,
            "name": "Mr. & Mrs. Grant Kurtz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1512
        },
        {
            "folderId": 1598,
            "name": "Ms. Jill B. Kushner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1513
        },
        {
            "folderId": 1599,
            "name": "Mr. & Mrs. Thomas F. Lachner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1514
        },
        {
            "folderId": 1600,
            "name": "Ms. Dawn Lagergren",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1515
        },
        {
            "folderId": 1601,
            "name": "Mrs. Carole Laird",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1516
        },
        {
            "folderId": 1602,
            "name": "Laird Youth Leadership Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1517
        },
        {
            "folderId": 1603,
            "name": "Ms. Rose Marie Lajoie",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1518
        },
        {
            "folderId": 1604,
            "name": "Lake Michigan Credit Union",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1519
        },
        {
            "folderId": 1605,
            "name": "Mr. & Mrs. Kenneth J. LaMotte",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1520
        },
        {
            "folderId": 1606,
            "name": "Mr. & Mrs. Michael C. Landin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1521
        },
        {
            "folderId": 1607,
            "name": "Landmatrix L.L.C",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1522
        },
        {
            "folderId": 1608,
            "name": "Larry G. Scoggin Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1523
        },
        {
            "folderId": 1609,
            "name": "Mr. & Mrs. Richard D. Larson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1524
        },
        {
            "folderId": 1610,
            "name": "Mr. & Mrs. G. J. Lashley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1525
        },
        {
            "folderId": 1611,
            "name": "Jim & Vanita Laurito",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1526
        },
        {
            "folderId": 1612,
            "name": "Mr. Leo J. LeBlanc",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1527
        },
        {
            "folderId": 1613,
            "name": "Lee County Electric Co‑op",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1528
        },
        {
            "folderId": 1614,
            "name": "Lee County Medical Society Alliance Fdn, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1529
        },
        {
            "folderId": 1615,
            "name": "Mr. & Mrs. Lloyd L. Leider",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1530
        },
        {
            "folderId": 1616,
            "name": "Mr. & Mrs. John H. Lein",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1531
        },
        {
            "folderId": 1617,
            "name": "Mrs. Linda L. Brown Lenz, RN",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1532
        },
        {
            "folderId": 1618,
            "name": "Ms. Patricia D. Leonard",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1533
        },
        {
            "folderId": 1619,
            "name": "Mr. & Mrs. Richard A. Lepola",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1534
        },
        {
            "folderId": 1620,
            "name": "Lester and Dorothy Jones Charitable Designated Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1535
        },
        {
            "folderId": 1621,
            "name": "Dr. & Mrs. Stuart D. Levy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1536
        },
        {
            "folderId": 1622,
            "name": "Michael & Mary Lou Leyden",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1537
        },
        {
            "folderId": 1623,
            "name": "LFG, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1538
        },
        {
            "folderId": 1624,
            "name": "Ms. Carol S. Libs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1539
        },
        {
            "folderId": 1625,
            "name": "Mr. & Mrs. Dale Lichy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1540
        },
        {
            "folderId": 1626,
            "name": "Lift Stak & Stor",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1541
        },
        {
            "folderId": 1627,
            "name": "Kathy & Paul Liles",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1542
        },
        {
            "folderId": 1628,
            "name": "Mr. Alexandre R. Blagojevic & Ms. Elizabeth M. Lilly",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1543
        },
        {
            "folderId": 1629,
            "name": "Lily & Co, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1544
        },
        {
            "folderId": 1630,
            "name": "Dr. & Mrs. Dean Lin in Honor of Ava, Reese and Jaxson Lin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1545
        },
        {
            "folderId": 1631,
            "name": "Mr. & Mrs. Robert J. Lindgren",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1546
        },
        {
            "folderId": 1632,
            "name": "Dr. Donald S. Linton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1547
        },
        {
            "folderId": 1633,
            "name": "Mr. & Mrs. David Lionett",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1548
        },
        {
            "folderId": 1634,
            "name": "Little Luxe Children's Boutique",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1549
        },
        {
            "folderId": 1635,
            "name": "Dr. William F. Liu and Dr. Marilyn Young Liu",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1550
        },
        {
            "folderId": 1636,
            "name": "Mr. & Ms. Derek C. Lowe",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1551
        },
        {
            "folderId": 1637,
            "name": "Mr. & Mrs. Edward W. Ludemann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1552
        },
        {
            "folderId": 1638,
            "name": "Lujensue Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1553
        },
        {
            "folderId": 1639,
            "name": "Mr. & Mrs. Chad Lund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1554
        },
        {
            "folderId": 1640,
            "name": "Mr. James D. Lundeen, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1555
        },
        {
            "folderId": 1641,
            "name": "Lutgert Companies",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1556
        },
        {
            "folderId": 1642,
            "name": "Mr. & Mrs. Scott Lyons",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1557
        },
        {
            "folderId": 1643,
            "name": "Dr. & Mrs. Craig A. MacArthur",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1558
        },
        {
            "folderId": 1644,
            "name": "Ms. Kathryn M. MacDonald",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1559
        },
        {
            "folderId": 1645,
            "name": "Mr. & Mrs. Kenneth A. Mack",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1560
        },
        {
            "folderId": 1646,
            "name": "MacKoul Pediatrics Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1561
        },
        {
            "folderId": 1647,
            "name": "Ms. Jane M. Macksood",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1562
        },
        {
            "folderId": 1648,
            "name": "MacLeod Stewardship Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1563
        },
        {
            "folderId": 1649,
            "name": "Manhattan Construction",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1564
        },
        {
            "folderId": 1650,
            "name": "Mr. & Mrs. Melvin R. Manker",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1565
        },
        {
            "folderId": 1651,
            "name": "Manone Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1566
        },
        {
            "folderId": 1652,
            "name": "Mr. & Mrs. Vito Manone",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1567
        },
        {
            "folderId": 1653,
            "name": "March of Dimes Birth Defects Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1568
        },
        {
            "folderId": 1654,
            "name": "Mr. & Mrs. Frank R. Marfino",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1569
        },
        {
            "folderId": 1655,
            "name": "Mr. Stewart Mills & Ms. Andre Margaux",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1570
        },
        {
            "folderId": 1656,
            "name": "MarineMax Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1571
        },
        {
            "folderId": 1657,
            "name": "James and Brigitte Marino",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1572
        },
        {
            "folderId": 1658,
            "name": "Marion & Jack Gruver Fund of Gulf Coast Community Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1573
        },
        {
            "folderId": 1659,
            "name": "Mark Loren Designs, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1574
        },
        {
            "folderId": 1660,
            "name": "Mr. & Mrs. Mark Suwyn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1575
        },
        {
            "folderId": 1661,
            "name": "Leroy G. Markle Estate",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1576
        },
        {
            "folderId": 1662,
            "name": "Mr. & Mrs. J. Marnocha",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1577
        },
        {
            "folderId": 1663,
            "name": "Marquette Electronics, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1578
        },
        {
            "folderId": 1664,
            "name": "Mr. & Mrs. Eugene H. Massey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1579
        },
        {
            "folderId": 1665,
            "name": "Mr. & Mrs. Dale M. Mast",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1580
        },
        {
            "folderId": 1666,
            "name": "Master Craft Plumbing, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1581
        },
        {
            "folderId": 1667,
            "name": "VJ Mattson Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1582
        },
        {
            "folderId": 1668,
            "name": "Mr. & Mrs. Nicholas A. Maurillo Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1583
        },
        {
            "folderId": 1669,
            "name": "Mr. & Mrs. Victor W. Mayeron",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1584
        },
        {
            "folderId": 1670,
            "name": "McBLT Ventures, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1585
        },
        {
            "folderId": 1671,
            "name": "Mr. & Mrs. Tom McAuley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1586
        },
        {
            "folderId": 1672,
            "name": "Mr. & Mrs. James D. McCamant",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1587
        },
        {
            "folderId": 1673,
            "name": "Family and Friends of Ryan McCleskey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1588
        },
        {
            "folderId": 1674,
            "name": "Mr. & Mrs. David McCurry",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1589
        },
        {
            "folderId": 1675,
            "name": "Mrs. Catherine McCusker",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1590
        },
        {
            "folderId": 1676,
            "name": "McDonald Family Fund at Schwab Charitable",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1591
        },
        {
            "folderId": 1677,
            "name": "Mr. & Mrs. Harold McEachern",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1592
        },
        {
            "folderId": 1678,
            "name": "Mary McGillicuddy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1593
        },
        {
            "folderId": 1679,
            "name": "Mrs. Rosalyn J. McGlynn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1594
        },
        {
            "folderId": 1680,
            "name": "Ms. Susan McGrogan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1595
        },
        {
            "folderId": 1681,
            "name": "Daniel & Holly McKenna",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1596
        },
        {
            "folderId": 1682,
            "name": "Ms. Mary Ann McKoane",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1597
        },
        {
            "folderId": 1683,
            "name": "Mr. Timothy McLaughlin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1598
        },
        {
            "folderId": 1684,
            "name": "Ms. Crystal D. McMillan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1599
        },
        {
            "folderId": 1685,
            "name": "Larry  & Chuckie McPherson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1600
        },
        {
            "folderId": 1686,
            "name": "Richard Lauber and Nicole Lauber McQuade",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1601
        },
        {
            "folderId": 1687,
            "name": "Mrs. Emily C. Mead",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1602
        },
        {
            "folderId": 1688,
            "name": "Ms. Patricia Means",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1603
        },
        {
            "folderId": 1689,
            "name": "Medline Industries",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1604
        },
        {
            "folderId": 1690,
            "name": "Mr. & Mrs. Douglas C. Mehle",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1605
        },
        {
            "folderId": 1691,
            "name": "Mr. & Mrs. Donald Melchiorre",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1606
        },
        {
            "folderId": 1692,
            "name": "Merck & Co, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1607
        },
        {
            "folderId": 1693,
            "name": "Ms. Martha Meyers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1608
        },
        {
            "folderId": 1694,
            "name": "Miami Children's Hospital",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1609
        },
        {
            "folderId": 1695,
            "name": "Dr. & Mrs. James J. Milford",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1610
        },
        {
            "folderId": 1696,
            "name": "Millennium Physician Group, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1611
        },
        {
            "folderId": 1697,
            "name": "Mrs. & Mrs. Jeffrey M. Miloff",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1612
        },
        {
            "folderId": 1698,
            "name": "Mr. & Mrs. Daniel Minton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1613
        },
        {
            "folderId": 1699,
            "name": "Mr. & Mrs. Kevin G. Mirner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1614
        },
        {
            "folderId": 1700,
            "name": "Miromar Development Corporation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1615
        },
        {
            "folderId": 1701,
            "name": "Miromar Outlets",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1616
        },
        {
            "folderId": 1702,
            "name": "Ms. Margery Knepp‑Dodson & Mr. F.Paul Mitchell",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1617
        },
        {
            "folderId": 1703,
            "name": "Ms. Karin Moe",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1618
        },
        {
            "folderId": 1704,
            "name": "Mr. & Mrs. Thomas G. Mogren",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1619
        },
        {
            "folderId": 1705,
            "name": "Moms Club of South Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1620
        },
        {
            "folderId": 1706,
            "name": "Mr. Bruce Monaghan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1621
        },
        {
            "folderId": 1707,
            "name": "Mr. & Mrs. Joseph J. Mondelli",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1622
        },
        {
            "folderId": 1708,
            "name": "Dr. Roberto Monge",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1623
        },
        {
            "folderId": 1709,
            "name": "Mr. & Mrs. John R. Moore",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1624
        },
        {
            "folderId": 1710,
            "name": "Mrs. Renee L. Moore",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1625
        },
        {
            "folderId": 1711,
            "name": "Mr. & Mrs. Robert Moore",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1626
        },
        {
            "folderId": 1712,
            "name": "Moorings Park Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1627
        },
        {
            "folderId": 1713,
            "name": "Mr. Thomas E. Morack",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1628
        },
        {
            "folderId": 1714,
            "name": "Mrs. Ann L. Moran",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1629
        },
        {
            "folderId": 1715,
            "name": "Mr. & Mrs. Roger T. Morgan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1630
        },
        {
            "folderId": 1716,
            "name": "Morgan Stanley Global Impact Funding Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1631
        },
        {
            "folderId": 1717,
            "name": "Morgan Stanley Private Wealth Management",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1632
        },
        {
            "folderId": 1718,
            "name": "Mrs. Elizabeth A. Morris",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1633
        },
        {
            "folderId": 1719,
            "name": "John R. & Kay S. Morse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1634
        },
        {
            "folderId": 1720,
            "name": "Mr. & Mrs. Robert J. Moses",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1635
        },
        {
            "folderId": 1721,
            "name": "Mrs. Edna L. Mudry",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1636
        },
        {
            "folderId": 1722,
            "name": "Muni Designs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1637
        },
        {
            "folderId": 1723,
            "name": "Dr. Murali Muppala",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1638
        },
        {
            "folderId": 1724,
            "name": "Mr. & Mrs. Frank Murphy",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1639
        },
        {
            "folderId": 1725,
            "name": "Ms. Priscilla Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1640
        },
        {
            "folderId": 1726,
            "name": "Nagin Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1641
        },
        {
            "folderId": 1727,
            "name": "Nancy J. Boots Charitable Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1642
        },
        {
            "folderId": 1728,
            "name": "Mr. and Mrs. Robert Nanovic",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1643
        },
        {
            "folderId": 1729,
            "name": "Naples Community Hospital",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1644
        },
        {
            "folderId": 1730,
            "name": "Naples United Church of Christ",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1645
        },
        {
            "folderId": 1731,
            "name": "National Childhood Cancer Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1646
        },
        {
            "folderId": 1732,
            "name": "Mr. & Mrs. James E. Nelson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1647
        },
        {
            "folderId": 1733,
            "name": "Nemours Children's Clinic",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1648
        },
        {
            "folderId": 1734,
            "name": "New Hampshire Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1649
        },
        {
            "folderId": 1735,
            "name": "New World Regulatory Solutions, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1650
        },
        {
            "folderId": 1736,
            "name": "Mr. & Mrs. Paul B. Newhouse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1651
        },
        {
            "folderId": 1737,
            "name": "Mr. & Mrs. Norman G. Newman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1652
        },
        {
            "folderId": 1738,
            "name": "M. Nice Charitable Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1653
        },
        {
            "folderId": 1739,
            "name": "Mr. & Mrs. John A. Nice",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1654
        },
        {
            "folderId": 1740,
            "name": "Mr. Richard H. Niehaus",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1655
        },
        {
            "folderId": 1741,
            "name": "Nordstrom",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1656
        },
        {
            "folderId": 1742,
            "name": "Norman Love Confections",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1657
        },
        {
            "folderId": 1743,
            "name": "North Fort Myers Senior Center, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1658
        },
        {
            "folderId": 1744,
            "name": "North Sound Capital LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1659
        },
        {
            "folderId": 1745,
            "name": "Northern Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1660
        },
        {
            "folderId": 1746,
            "name": "Mr. Martin Norville",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1661
        },
        {
            "folderId": 1747,
            "name": "Nova Southeastern University",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1662
        },
        {
            "folderId": 1748,
            "name": "Nova Wealth Management",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1663
        },
        {
            "folderId": 1749,
            "name": "Mr. & Mrs. Robert J. Nunn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1664
        },
        {
            "folderId": 1750,
            "name": "Dr. & Mrs. Scott Nygaard",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1665
        },
        {
            "folderId": 1751,
            "name": "Oasis Charter Elementary",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1666
        },
        {
            "folderId": 1752,
            "name": "Mr. & Mrs. Gary Oatey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1667
        },
        {
            "folderId": 1753,
            "name": "O'Bryan Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1668
        },
        {
            "folderId": 1754,
            "name": "Ms. Joan M. Odorizzi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1669
        },
        {
            "folderId": 1755,
            "name": "Office Furniture & Design Concepts, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1670
        },
        {
            "folderId": 1756,
            "name": "Mr. & Mrs. Peter R. O'Flinn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1671
        },
        {
            "folderId": 1757,
            "name": "Mr. & Mrs. Joe Olson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1672
        },
        {
            "folderId": 1758,
            "name": "Optimist Club of Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1673
        },
        {
            "folderId": 1759,
            "name": "Mr. & Mrs. Philip V. Otero",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1674
        },
        {
            "folderId": 1760,
            "name": "Outback Steakhouse",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1675
        },
        {
            "folderId": 1761,
            "name": "Owen‑Ames‑Kimball Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1676
        },
        {
            "folderId": 1762,
            "name": "Padgett Family Charitable Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1677
        },
        {
            "folderId": 1763,
            "name": "Mr. and Mrs. Mark Padgett",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1678
        },
        {
            "folderId": 1764,
            "name": "Pagel Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1679
        },
        {
            "folderId": 1765,
            "name": "Palm Printing & Forms",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1680
        },
        {
            "folderId": 1766,
            "name": "PalmCo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1681
        },
        {
            "folderId": 1767,
            "name": "Palmetto‑Pine Country Club Women's Golf Association",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1682
        },
        {
            "folderId": 1768,
            "name": "Mr. Anthony Paolo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1683
        },
        {
            "folderId": 1769,
            "name": "Ms. Jennifer Parisi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1684
        },
        {
            "folderId": 1770,
            "name": "Christina and Clifford Parker",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1685
        },
        {
            "folderId": 1771,
            "name": "Pat & Don Parsons",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1686
        },
        {
            "folderId": 1772,
            "name": "Paul Bush Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1687
        },
        {
            "folderId": 1773,
            "name": "Paul Todd Charities, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1688
        },
        {
            "folderId": 1774,
            "name": "Peace Community Church",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1689
        },
        {
            "folderId": 1775,
            "name": "Mr. Michael B. Peceri",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1690
        },
        {
            "folderId": 1776,
            "name": "The Pediatric Cancer Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1691
        },
        {
            "folderId": 1777,
            "name": "Mr. & Mrs. Michael Peers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1692
        },
        {
            "folderId": 1778,
            "name": "Pelican Preserve",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1693
        },
        {
            "folderId": 1779,
            "name": "Pelican's Nest",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1694
        },
        {
            "folderId": 1780,
            "name": "Mr. & Mrs. Dennis H. Pellicci",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1695
        },
        {
            "folderId": 1781,
            "name": "Mr. & Mrs. Steven Peltzman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1696
        },
        {
            "folderId": 1782,
            "name": "Mr. & Mrs. Peter Kendall",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1697
        },
        {
            "folderId": 1783,
            "name": "Elizabeth Peters",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1698
        },
        {
            "folderId": 1784,
            "name": "Ms. Christine Peterson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1699
        },
        {
            "folderId": 1785,
            "name": "Ms. Elizabeth M. Pfriem",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1700
        },
        {
            "folderId": 1786,
            "name": "Philips Medical Systems",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1701
        },
        {
            "folderId": 1787,
            "name": "Physicians' Primary Care of SW Florida, PL",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1702
        },
        {
            "folderId": 1788,
            "name": "Ms. Wendy I. Piascik",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1703
        },
        {
            "folderId": 1789,
            "name": "Dean and Monica Piccirillo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1704
        },
        {
            "folderId": 1790,
            "name": "Picone Financial Partners, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1705
        },
        {
            "folderId": 1791,
            "name": "Mr. & Mrs. John Picone",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1706
        },
        {
            "folderId": 1792,
            "name": "Pieces of Eight Pirate Cruise",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1707
        },
        {
            "folderId": 1793,
            "name": "Annette M. St. Pierre‑Mackoul, MD",
            "sorting_order": "Annette",
            "list_view_selected": false,
            "id": 1708
        },
        {
            "folderId": 1794,
            "name": "Dr. & Mrs. Anthony G. Pietroniro",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1709
        },
        {
            "folderId": 1795,
            "name": "Mr. & Mrs. William Pinkerton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1710
        },
        {
            "folderId": 1796,
            "name": "Mr. & Mrs. John A. Pinti",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1711
        },
        {
            "folderId": 1797,
            "name": "Mr. & Mrs. Richard B. Pitbladdo, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1712
        },
        {
            "folderId": 1798,
            "name": "Mr. & Mrs. David M. Platt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1713
        },
        {
            "folderId": 1799,
            "name": "Dr. & Mrs. Richard W. Plummer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1714
        },
        {
            "folderId": 1800,
            "name": "Police Assoc. of Cape Coral, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1715
        },
        {
            "folderId": 1801,
            "name": "Mr. David A. Powers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1716
        },
        {
            "folderId": 1802,
            "name": "Mr. & Mrs. Jeff Powers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1717
        },
        {
            "folderId": 1803,
            "name": "Mr. & Mrs. Michael J. Pratko",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1718
        },
        {
            "folderId": 1804,
            "name": "Premier Properties of SW Florida, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1719
        },
        {
            "folderId": 1805,
            "name": "Premier Sotheby's International Realty",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1720
        },
        {
            "folderId": 1806,
            "name": "Principal Life Insurance Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1721
        },
        {
            "folderId": 1807,
            "name": "Private Client Insurance Services",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1722
        },
        {
            "folderId": 1808,
            "name": "Private Sky Aviation Services, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1723
        },
        {
            "folderId": 1809,
            "name": "Promotional Incentives",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1724
        },
        {
            "folderId": 1810,
            "name": "Mr. & Mrs. Phil Prosapio",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1725
        },
        {
            "folderId": 1811,
            "name": "Mr. & Mrs. Frederick A. Quinn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1726
        },
        {
            "folderId": 1812,
            "name": "Jorge M. Quinonez, MD",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1727
        },
        {
            "folderId": 1813,
            "name": "Mr. & Mrs. Jack Rasmussen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1728
        },
        {
            "folderId": 1814,
            "name": "Raymond Building Supply Corp.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1729
        },
        {
            "folderId": 1815,
            "name": "Raymond James & Associates Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1730
        },
        {
            "folderId": 1816,
            "name": "VIP Realty Group, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1731
        },
        {
            "folderId": 1817,
            "name": "Mr. & Mrs. Garrett H. Reasoner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1732
        },
        {
            "folderId": 1818,
            "name": "Reese Family Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1733
        },
        {
            "folderId": 1819,
            "name": "Regions Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1734
        },
        {
            "folderId": 1820,
            "name": "Mr. & Mrs. Robert W. Reid",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1735
        },
        {
            "folderId": 1821,
            "name": "Representative Heather D. Fitzenhagen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1736
        },
        {
            "folderId": 1822,
            "name": "Dr. & Mrs. Peter B. Reuling",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1737
        },
        {
            "folderId": 1823,
            "name": "Mr. & Mrs. Ronald A. Rex",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1738
        },
        {
            "folderId": 1824,
            "name": "Mr. & Mrs. Stuart G. Rice",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1739
        },
        {
            "folderId": 1825,
            "name": "Mr. & Mrs. Harold R. Rieck",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1740
        },
        {
            "folderId": 1826,
            "name": "Steve and Gisela Riggs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1741
        },
        {
            "folderId": 1827,
            "name": "Mr. & Mrs. Richard E. Riley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1742
        },
        {
            "folderId": 1828,
            "name": "Gerald & Judeth Risch‑In Honor of Risch Grandchildren",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1743
        },
        {
            "folderId": 1829,
            "name": "Mr. & Mrs. Brian D. Rist",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1744
        },
        {
            "folderId": 1830,
            "name": "Mr. & Mrs. Charles G. Roach, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1745
        },
        {
            "folderId": 1831,
            "name": "Mr. & Mrs. Roby L. Roberts",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1746
        },
        {
            "folderId": 1832,
            "name": "Mr. & Mrs. William C. Roberts",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1747
        },
        {
            "folderId": 1833,
            "name": "Mr. & Mrs. Donald B. Rolley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1748
        },
        {
            "folderId": 1834,
            "name": "Rooms To Go",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1749
        },
        {
            "folderId": 1835,
            "name": "Mr. & Mrs. Shawn & Sharon Rooney",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1750
        },
        {
            "folderId": 1836,
            "name": "Mr. & Mrs. Louis F. Rosellini",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1751
        },
        {
            "folderId": 1837,
            "name": "Mr. & Mrs. Ronald Rosen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1752
        },
        {
            "folderId": 1838,
            "name": "Mr. & Mrs. David M. Rosenberg",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1753
        },
        {
            "folderId": 1839,
            "name": "Mr. & Mrs. Franz Rosinus",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1754
        },
        {
            "folderId": 1840,
            "name": "Mr. Russell Bilgore & Ms. June Rosner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1755
        },
        {
            "folderId": 1841,
            "name": "Mr. James J. Fait & Ms. Barbara K. Ross",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1756
        },
        {
            "folderId": 1842,
            "name": "Mr. & Mrs. Joseph Ross",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1757
        },
        {
            "folderId": 1843,
            "name": "Mrs. Pamela S. Rossiter",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1758
        },
        {
            "folderId": 1844,
            "name": "Rotary Club of Bonita Springs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1759
        },
        {
            "folderId": 1845,
            "name": "Rotary Club Bonita Springs Noon",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1760
        },
        {
            "folderId": 1846,
            "name": "Rotary Club of Cape Coral Goldcoast",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1761
        },
        {
            "folderId": 1847,
            "name": "Rotary Club of Estero",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1762
        },
        {
            "folderId": 1848,
            "name": "Rotary Club of Sanibel‑Captiva",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1763
        },
        {
            "folderId": 1849,
            "name": "Rotary District 6960",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1764
        },
        {
            "folderId": 1850,
            "name": "Mr. & Mrs. Paul F. Roth",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1765
        },
        {
            "folderId": 1851,
            "name": "Lex & Eileen Roulston",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1766
        },
        {
            "folderId": 1852,
            "name": "Mr. & Mrs. Roy O. Sweeney",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1767
        },
        {
            "folderId": 1853,
            "name": "Col. & Mrs. Alan J. Rubin, USAF (RET)",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1768
        },
        {
            "folderId": 1854,
            "name": "Mr. & Mrs. Jay Russo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1769
        },
        {
            "folderId": 1855,
            "name": "Ruth E. Harrington Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1770
        },
        {
            "folderId": 1856,
            "name": "Mr. & Mrs. Douglas A. Ryckman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1771
        },
        {
            "folderId": 1857,
            "name": "Dr. Frederick and Mrs. Susan Ryckman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1772
        },
        {
            "folderId": 1858,
            "name": "Mrs. Mary Ann D. Saegebarth",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1773
        },
        {
            "folderId": 1859,
            "name": "Dr. Andree A. Dadrat & Dr. Mai F. Saif",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1774
        },
        {
            "folderId": 1860,
            "name": "Saint Katherine Greek Orthodox Church",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1775
        },
        {
            "folderId": 1861,
            "name": "Saks Fifth Avenue",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1776
        },
        {
            "folderId": 1862,
            "name": "Saks Incorporated",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1777
        },
        {
            "folderId": 1863,
            "name": "Mr. Louis A. Saldarini Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1778
        },
        {
            "folderId": 1864,
            "name": "Dr. Emad and Alissar Salman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1779
        },
        {
            "folderId": 1865,
            "name": "Salty Crab Bar and Grill Ft Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1780
        },
        {
            "folderId": 1866,
            "name": "Myles R. Samotin, MD",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1781
        },
        {
            "folderId": 1867,
            "name": "Mrs. Nancy S. Sampson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1782
        },
        {
            "folderId": 1868,
            "name": "Sam's Wholesale Club ‑ #8130",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1783
        },
        {
            "folderId": 1869,
            "name": "Ms. Katharine DuPont Sanger",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1784
        },
        {
            "folderId": 1870,
            "name": "Sanibel Outlets",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1785
        },
        {
            "folderId": 1871,
            "name": "Sanibel Sea School",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1786
        },
        {
            "folderId": 1872,
            "name": "Sarasota Kennel Club, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1787
        },
        {
            "folderId": 1873,
            "name": "Mr. & Mrs. John E. Scanlon",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1788
        },
        {
            "folderId": 1874,
            "name": "Mrs. Joy Schein",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1789
        },
        {
            "folderId": 1875,
            "name": "Mr. & Mrs. Gary Schmidt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1790
        },
        {
            "folderId": 1876,
            "name": "Alois Schultz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1791
        },
        {
            "folderId": 1877,
            "name": "Dr. & Mrs. Carl Schultz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1792
        },
        {
            "folderId": 1878,
            "name": "Mr. Douglas D. Schumann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1793
        },
        {
            "folderId": 1879,
            "name": "Schwab Fund for Charitable Giving",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1794
        },
        {
            "folderId": 1880,
            "name": "Mr. JC Schwalback",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1795
        },
        {
            "folderId": 1881,
            "name": "Scooter Shooter, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1796
        },
        {
            "folderId": 1882,
            "name": "Mr. & Mrs. Craig C. Scott, Sr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1797
        },
        {
            "folderId": 1883,
            "name": "Mr. Willard H. Scott, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1798
        },
        {
            "folderId": 1884,
            "name": "Mr. & Mrs. Adrian J. Scribante",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1799
        },
        {
            "folderId": 1885,
            "name": "Mr. John T. Seaman, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1800
        },
        {
            "folderId": 1886,
            "name": "Sear Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1801
        },
        {
            "folderId": 1887,
            "name": "Mr. & Mrs. Robert Sebes",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1802
        },
        {
            "folderId": 1888,
            "name": "Secure Shredding, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1803
        },
        {
            "folderId": 1889,
            "name": "Ms. Theresa R. Shea‑See & Mr. Terry See",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1804
        },
        {
            "folderId": 1890,
            "name": "Mrs. Linda D. Seifert",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1805
        },
        {
            "folderId": 1891,
            "name": "Mr. & Mrs. John D. Selby",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1806
        },
        {
            "folderId": 1892,
            "name": "Mr. & Mrs. Matthew Sellick",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1807
        },
        {
            "folderId": 1893,
            "name": "Service Painting of Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1808
        },
        {
            "folderId": 1894,
            "name": "Service Works of Ft. Lauderdale, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1809
        },
        {
            "folderId": 1895,
            "name": "Dr. & Dr. John C. Sgarlata",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1810
        },
        {
            "folderId": 1896,
            "name": "Mr. & Mrs. Timothy G. Shack",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1811
        },
        {
            "folderId": 1897,
            "name": "Dr. and Mrs. F. Brett Shannon",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1812
        },
        {
            "folderId": 1898,
            "name": "Shawn & Sharon Rooney Fund",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1813
        },
        {
            "folderId": 1899,
            "name": "Mr. & Mrs. Charles H. Sheetz",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1814
        },
        {
            "folderId": 1900,
            "name": "Mr. & Mrs. D. Michael Sherman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1815
        },
        {
            "folderId": 1901,
            "name": "Estate of Alma Z. Shields",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1816
        },
        {
            "folderId": 1902,
            "name": "Dr. & Mrs. Butch Shorack",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1817
        },
        {
            "folderId": 1903,
            "name": "Shoreline Aggregate, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1818
        },
        {
            "folderId": 1904,
            "name": "Heidi & Ken Shoriak",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1819
        },
        {
            "folderId": 1905,
            "name": "ShowTurf LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1820
        },
        {
            "folderId": 1906,
            "name": "Mr. & Dr. John P. Siebart",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1821
        },
        {
            "folderId": 1907,
            "name": "Siesta Pebble Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1822
        },
        {
            "folderId": 1908,
            "name": "Katherine B. Simpson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1823
        },
        {
            "folderId": 1909,
            "name": "Mr. & Mrs. Robert A. Simpson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1824
        },
        {
            "folderId": 1910,
            "name": "Mr. Donald E. Singer",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1825
        },
        {
            "folderId": 1911,
            "name": "Dr. Shaila Singh",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1826
        },
        {
            "folderId": 1912,
            "name": "Skate for Hope",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1827
        },
        {
            "folderId": 1913,
            "name": "Bill & Pat Smart",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1828
        },
        {
            "folderId": 1914,
            "name": "Mr. & Mrs. Douglas Smith",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1829
        },
        {
            "folderId": 1915,
            "name": "Mr. & Mrs. Michael W. Smith",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1830
        },
        {
            "folderId": 1916,
            "name": "Mr. & Mrs. Paul M. Smith II",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1831
        },
        {
            "folderId": 1917,
            "name": "Lisa Snyder",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1832
        },
        {
            "folderId": 1918,
            "name": "Society First Federal Savings Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1833
        },
        {
            "folderId": 1919,
            "name": "CRMC South Advertising",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1834
        },
        {
            "folderId": 1920,
            "name": "South Florida Mud Run",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1835
        },
        {
            "folderId": 1921,
            "name": "South Seas Islands Resorts",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1836
        },
        {
            "folderId": 1922,
            "name": "Southwest Florida Regional Medical Center",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1837
        },
        {
            "folderId": 1923,
            "name": "Mr. & Mrs. Jack B. Spitler",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1838
        },
        {
            "folderId": 1924,
            "name": "Mr. & Mrs. James D. Sprankle",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1839
        },
        {
            "folderId": 1925,
            "name": "Mr. & Mrs. John Sprecher",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1840
        },
        {
            "folderId": 1926,
            "name": "Ms. Virginia D. Stabile",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1841
        },
        {
            "folderId": 1927,
            "name": "Ms. Nannette Starapoli",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1842
        },
        {
            "folderId": 1928,
            "name": "Mrs. Ann Starck",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1843
        },
        {
            "folderId": 1929,
            "name": "Steele Technical Services",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1844
        },
        {
            "folderId": 1930,
            "name": "Mr. & Mrs. Ted Steele",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1845
        },
        {
            "folderId": 1931,
            "name": "Stefanie Roth Memorial Cancer Fdn, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1846
        },
        {
            "folderId": 1932,
            "name": "Mr. & Mrs. Daniel K. Stegmann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1847
        },
        {
            "folderId": 1933,
            "name": "Mr. & Mrs. Robert E. Stein",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1848
        },
        {
            "folderId": 1934,
            "name": "Ms. Amanda Stephens",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1849
        },
        {
            "folderId": 1935,
            "name": "Steris Corporation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1850
        },
        {
            "folderId": 1936,
            "name": "Dr. & Mrs. John A. Steubs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1851
        },
        {
            "folderId": 1937,
            "name": "Mr. James Stewart",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1852
        },
        {
            "folderId": 1938,
            "name": "Ms. LeAnne Stewart",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1853
        },
        {
            "folderId": 1939,
            "name": "Mr. & Mrs. John G. Stimpson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1854
        },
        {
            "folderId": 1940,
            "name": "Mr. & Mrs. Brian Stock",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1855
        },
        {
            "folderId": 1941,
            "name": "Storm Smart Building System, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1856
        },
        {
            "folderId": 1942,
            "name": "Ms. Adra M. Strom",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1857
        },
        {
            "folderId": 1943,
            "name": "Subaru of America, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1858
        },
        {
            "folderId": 1944,
            "name": "Mr. Frank Jannelli & Ms. Deirdre Sullivan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1859
        },
        {
            "folderId": 1945,
            "name": "Mr. & Mrs. Michael Sullivan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1860
        },
        {
            "folderId": 1946,
            "name": "Mr. & Mrs. Thomas F. Sullivan",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1861
        },
        {
            "folderId": 1947,
            "name": "Sun Broadcasting, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1862
        },
        {
            "folderId": 1948,
            "name": "Suncoast Beverage Sales Family Charitable Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1863
        },
        {
            "folderId": 1949,
            "name": "SunTrust Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1864
        },
        {
            "folderId": 1950,
            "name": "SunTrust Bank of Southwest Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1865
        },
        {
            "folderId": 1951,
            "name": "Sutherlin Nissan of Fort Myers",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1866
        },
        {
            "folderId": 1952,
            "name": "Sweetbay Supermarket",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1867
        },
        {
            "folderId": 1953,
            "name": "Swing for the Kids Charities, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1868
        },
        {
            "folderId": 1954,
            "name": "Synergy Commercial Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1869
        },
        {
            "folderId": 1955,
            "name": "Dr. & Mrs. Ronny S. Taschner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1870
        },
        {
            "folderId": 1956,
            "name": "Ms. Lee A. Tauck",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1871
        },
        {
            "folderId": 1957,
            "name": "Charles and Emmie Taylor",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1872
        },
        {
            "folderId": 1958,
            "name": "Ms. Merrill J. Taylor",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1873
        },
        {
            "folderId": 1959,
            "name": "Mr. & Mrs. Philip E. Taylor",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1874
        },
        {
            "folderId": 1960,
            "name": "Mr. & Mrs. Richard F. Teerlink",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1875
        },
        {
            "folderId": 1961,
            "name": "Mr. John Templeton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1876
        },
        {
            "folderId": 1962,
            "name": "Mr. & Mrs. Kostaq Terezi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1877
        },
        {
            "folderId": 1963,
            "name": "Mr. & Mrs. Romeo Terezi",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1878
        },
        {
            "folderId": 1964,
            "name": "Mrs. Terry Weiner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1879
        },
        {
            "folderId": 1965,
            "name": "The ALS Association Florida Chapter",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1880
        },
        {
            "folderId": 1966,
            "name": "The Andrew Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1881
        },
        {
            "folderId": 1967,
            "name": "The Bahnik Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1882
        },
        {
            "folderId": 1968,
            "name": "The Belk Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1883
        },
        {
            "folderId": 1969,
            "name": "The Club at Mediterra",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1884
        },
        {
            "folderId": 1970,
            "name": "The Colony Golf and Country Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1885
        },
        {
            "folderId": 1971,
            "name": "The Donahue Family Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1886
        },
        {
            "folderId": 1972,
            "name": "The Ethel & W. George Kennedy Family Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1887
        },
        {
            "folderId": 1973,
            "name": "The Frances Pew Hayes Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1888
        },
        {
            "folderId": 1974,
            "name": "The Hall Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1889
        },
        {
            "folderId": 1975,
            "name": "The Hinman Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1890
        },
        {
            "folderId": 1976,
            "name": "The Hoffer Family Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1891
        },
        {
            "folderId": 1977,
            "name": "The Jack Parker Corporation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1892
        },
        {
            "folderId": 1978,
            "name": "The Janice Michelle Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1893
        },
        {
            "folderId": 1979,
            "name": "The John H. & Dorothy M. Castle Charitable Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1894
        },
        {
            "folderId": 1980,
            "name": "The Johnson Family Fund of the Ayco Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1895
        },
        {
            "folderId": 1981,
            "name": "The Johnson Family Fund of the Ayco Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1896
        },
        {
            "folderId": 1982,
            "name": "The Kinkle Family ‑ In Loving Memory of Kimmie Kinkle",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1897
        },
        {
            "folderId": 1983,
            "name": "The Landings Yacht Golf & Tennis Club, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1898
        },
        {
            "folderId": 1984,
            "name": "The League Club, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1899
        },
        {
            "folderId": 1985,
            "name": "The Mary E. Dooner Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1900
        },
        {
            "folderId": 1986,
            "name": "The Medicines Company",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1901
        },
        {
            "folderId": 1987,
            "name": "The Merck Company Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1902
        },
        {
            "folderId": 1988,
            "name": "The Michael Magro Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1903
        },
        {
            "folderId": 1989,
            "name": "The National Parkinson Foundation Care Center",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1904
        },
        {
            "folderId": 1990,
            "name": "The O'Brien Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1905
        },
        {
            "folderId": 1991,
            "name": "The Optimist Club Foundation of Sanibel Captiva, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1906
        },
        {
            "folderId": 1992,
            "name": "The Pete Renner Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1907
        },
        {
            "folderId": 1993,
            "name": "The Rajki Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1908
        },
        {
            "folderId": 1994,
            "name": "The Ritz Carlton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1909
        },
        {
            "folderId": 1995,
            "name": "The Rutter Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1910
        },
        {
            "folderId": 1996,
            "name": "The Sharp Family Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1911
        },
        {
            "folderId": 1997,
            "name": "The Sidney & Esther Rabb Charitable Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1912
        },
        {
            "folderId": 1998,
            "name": "The Strike 3 Foundation Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1913
        },
        {
            "folderId": 1999,
            "name": "The Tarquini Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1914
        },
        {
            "folderId": 2000,
            "name": "The Westin Cape Coral Resort at\\Marina Village",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1915
        },
        {
            "folderId": 2001,
            "name": "The/Cape Coral Kiwanis Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1916
        },
        {
            "folderId": 2002,
            "name": "Mr. & Mrs. Kip W. Thomas",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1917
        },
        {
            "folderId": 2003,
            "name": "Mr. & Mrs. Gary L. Tinkham",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1918
        },
        {
            "folderId": 2004,
            "name": "Mr. & Ms. Bill Toler",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1919
        },
        {
            "folderId": 2005,
            "name": "Mr. & Mrs. Edward A. Tombs",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1920
        },
        {
            "folderId": 2006,
            "name": "Mr. & Mrs. Thomas J. Torpey",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1921
        },
        {
            "folderId": 2007,
            "name": "TransAmerica Retirement Solutions",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1922
        },
        {
            "folderId": 2008,
            "name": "Mr. & Mrs. David Trepkowski",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1923
        },
        {
            "folderId": 2009,
            "name": "Trinity‑by‑the‑Cove Episcopal Church",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1924
        },
        {
            "folderId": 2010,
            "name": "U.S. Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1925
        },
        {
            "folderId": 2011,
            "name": "Dr. Richard M. and Eleanor Klein Family Trust",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1926
        },
        {
            "folderId": 2012,
            "name": "Turkey Trot",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1927
        },
        {
            "folderId": 2013,
            "name": "Mr. & Mrs. Peter Uddo",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1928
        },
        {
            "folderId": 2014,
            "name": "Ms. Mary B. Ullman",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1929
        },
        {
            "folderId": 2015,
            "name": "Ultimate Sports Adventures",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1930
        },
        {
            "folderId": 2016,
            "name": "Uncle Brewski's",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1931
        },
        {
            "folderId": 2017,
            "name": "United States Surgical Corporation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1932
        },
        {
            "folderId": 2018,
            "name": "University of Florida",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1933
        },
        {
            "folderId": 2019,
            "name": "Mr. & Mrs. Michael J. Valiquette",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1934
        },
        {
            "folderId": 2020,
            "name": "Vanguard Charitable Endowment Program",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1935
        },
        {
            "folderId": 2021,
            "name": "Mr. & Mrs. George Varsam",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1936
        },
        {
            "folderId": 2022,
            "name": "Vasari Cares Foundation, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1937
        },
        {
            "folderId": 2023,
            "name": "Vasari Country Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1938
        },
        {
            "folderId": 2024,
            "name": "Mr. & Mrs. David R. Venarge",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1939
        },
        {
            "folderId": 2025,
            "name": "Verandah Club ‑ Bonita Bay Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1940
        },
        {
            "folderId": 2026,
            "name": "VFW ‑ Mid Point Cape Coral James Crockford Memorial Post 492",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1941
        },
        {
            "folderId": 2027,
            "name": "VHA, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1942
        },
        {
            "folderId": 2028,
            "name": "Mr. James R. Vincent",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1943
        },
        {
            "folderId": 2029,
            "name": "Mrs. Mary Lou Viticonte",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1944
        },
        {
            "folderId": 2030,
            "name": "Mr. & Mrs. Donald A. Vogt",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1945
        },
        {
            "folderId": 2031,
            "name": "Mr. & Mrs. Conrad Voldstad",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1946
        },
        {
            "folderId": 2032,
            "name": "Mr. & Mrs. Allan Voss",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1947
        },
        {
            "folderId": 2033,
            "name": "Wachovia Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1948
        },
        {
            "folderId": 2034,
            "name": "Wachovia Securities",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1949
        },
        {
            "folderId": 2035,
            "name": "Mr. James P. Waddel",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1950
        },
        {
            "folderId": 2036,
            "name": "Mr. Alvin L. Wagner, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1951
        },
        {
            "folderId": 2037,
            "name": "Dr. & Mrs. Robert W. Wagner",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1952
        },
        {
            "folderId": 2038,
            "name": "Mr. William Waldron",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1953
        },
        {
            "folderId": 2039,
            "name": "Walgreens",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1954
        },
        {
            "folderId": 2040,
            "name": "Mr. & Mrs. John L. Walkley",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1955
        },
        {
            "folderId": 2041,
            "name": "Wal‑Mart Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1956
        },
        {
            "folderId": 2042,
            "name": "R.S. Walsh Landscaping, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1957
        },
        {
            "folderId": 2043,
            "name": "Mr. Joseph M. Walton",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1958
        },
        {
            "folderId": 2044,
            "name": "Mr. John Washburn",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1959
        },
        {
            "folderId": 2045,
            "name": "Mrs. & Mr. Sheilah R. Waters",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1960
        },
        {
            "folderId": 2046,
            "name": "Ms. Nanelle Wehmann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1961
        },
        {
            "folderId": 2047,
            "name": "Wells Fargo Advisors, LLC",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1962
        },
        {
            "folderId": 2048,
            "name": "Wells Fargo Bank",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1963
        },
        {
            "folderId": 2049,
            "name": "Wells Fargo Wealth Management",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1964
        },
        {
            "folderId": 2050,
            "name": "Mrs. Debra H. Welsh",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1965
        },
        {
            "folderId": 2051,
            "name": "Mr. Jerry H. Welty",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1966
        },
        {
            "folderId": 2052,
            "name": "Wendy's Restaurants",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1967
        },
        {
            "folderId": 2053,
            "name": "Mr. & Mrs. Frederick Wenk",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1968
        },
        {
            "folderId": 2054,
            "name": "West Bay Club",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1969
        },
        {
            "folderId": 2055,
            "name": "Mr. & Mrs. Edgar West",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1970
        },
        {
            "folderId": 2056,
            "name": "Whitemore Fire Consultants, Inc.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1971
        },
        {
            "folderId": 2057,
            "name": "Mr. & Mrs. Eric P. Wiesemann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1972
        },
        {
            "folderId": 2058,
            "name": "Mr. & Mrs. Robert O. Wiesemann",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1973
        },
        {
            "folderId": 2059,
            "name": "Mr. & Mrs. John K. Wiest",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1974
        },
        {
            "folderId": 2060,
            "name": "Mr. Harold D. Wilcoxen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1975
        },
        {
            "folderId": 2061,
            "name": "Mr. & Mrs. Andrew Williams",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1976
        },
        {
            "folderId": 2062,
            "name": "Mr. & Mrs. Clifford N. Williams",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1977
        },
        {
            "folderId": 2063,
            "name": "Charles & Paula Williamsen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1978
        },
        {
            "folderId": 2064,
            "name": "Mr. & Mrs. Scott Willis",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1979
        },
        {
            "folderId": 2065,
            "name": "Mr. & Mrs. Gerald B. Wilson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1980
        },
        {
            "folderId": 2066,
            "name": "Mr. & Mrs. Brad Wind",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1981
        },
        {
            "folderId": 2067,
            "name": "Mrs. Gayle G. Winter",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1982
        },
        {
            "folderId": 2068,
            "name": "Mr. & Mrs. Vincent Wolanin",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1983
        },
        {
            "folderId": 2069,
            "name": "Mr. & Mrs. John M. Wolf, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1984
        },
        {
            "folderId": 2070,
            "name": "Wolf, Metzger, Maurillo & Banfield Wealth Management Group",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1985
        },
        {
            "folderId": 2071,
            "name": "Mr. James S. Wolfson",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1986
        },
        {
            "folderId": 2072,
            "name": "Mrs. Gloria F. Wood",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1987
        },
        {
            "folderId": 2073,
            "name": "Woods, Weidenmiller, Michetti & Rudnick, P.L.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1988
        },
        {
            "folderId": 2074,
            "name": "Woodworth‑Upjohn Foundation",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1989
        },
        {
            "folderId": 2075,
            "name": "Wounded Warrior Stables",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1990
        },
        {
            "folderId": 2076,
            "name": "Markham Norton Mostellar Wright",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1991
        },
        {
            "folderId": 2077,
            "name": "Mr. & Mrs. Robert J. Wright",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1992
        },
        {
            "folderId": 2078,
            "name": "Mr. & Mrs. James A. Wurster",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1993
        },
        {
            "folderId": 2079,
            "name": "Young Artists Awards, INC.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1994
        },
        {
            "folderId": 2080,
            "name": "Mr. & Mrs. William A. Young",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1995
        },
        {
            "folderId": 2081,
            "name": "Mr. & Mrs. Harvey B. Youngquist, Jr.",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1996
        },
        {
            "folderId": 2082,
            "name": "Mr. Tim G. Youngquist & Mrs. Sandra K. Youngquist",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1997
        },
        {
            "folderId": 2083,
            "name": "Staurt and Lucia Zaikov",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1998
        },
        {
            "folderId": 2084,
            "name": "Rick and Evette Zurbriggen",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 1999
        },
        {
            "folderId": 2085,
            "name": "Zurbriggen Financial",
            "sorting_order": "",
            "list_view_selected": false,
            "id": 2000
        }
    ],
    "listColumns": [
        {
            "folder_types": [
                "fremont_donor_001"
            ],
            "field": "list_view_selected",
            "title": "",
            "sort": true,
            "editable": true,
            "default_shown": true,
            "field_data": {
                "list_view_selected": {
                    "type": "bool",
                    "title": "",
                    "validation": {
                        "rules": []
                    },
                    "default": false
                }
            }
        },
        {
            "folder_types": [
                "fremont_donor_001"
            ],
            "field": "name",
            "title": "Name",
            "sort": true,
            "editable": false,
            "default_shown": true,
            "field_data": {
                "name": {
                    "type": "text",
                    "title": "Name",
                    "validation": {
                        "rules": [
                            {
                                "name": "isString",
                                "min": 1,
                                "max": 75
                            }
                        ]
                    },
                    "default": "",
                    "read_only": true
                }
            }
        },
        {
            "folder_types": [
                "fremont_donor_001"
            ],
            "field": "sorting_order",
            "title": "Sorting Order",
            "sort": true,
            "editable": true,
            "default_shown": true,
            "field_data": {
                "sorting_order": {
                    "type": "text",
                    "title": "Sorting Order",
                    "validation": {
                        "rules": [
                            {
                                "name": "isString",
                                "min": 1,
                                "max": 75
                            }
                        ]
                    },
                    "default": ""
                }
            }
        }
    ]
}