import { createUseStyles } from "react-jss";
import { RESOURCE_EXPLORER_MODE } from "../resource-explorer.interface";
import React from "react";

const useStyles = createUseStyles({
  wrapper: {
    position: (props: any) =>
      props.mode === RESOURCE_EXPLORER_MODE.CONTENT_LIBRARY
        ? "relative"
        : "absolute",
    display: "flex",
    alignItems: "center",
    height: (props: any) => props.height,
    width: (props: any) => props.width,
    "& #back-button": {
      position: "absolute",
      top: 0,
      color: "#2699FB",
      width: "100%",
      paddingLeft: "20px",
      height: "5%",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
      "& img": {
        cursor: "pointer",
      }
    },
    "& #previous-button": {
      marginRight: 5,
      marginTop: 2,
      position: "absolute",
      color: "white",
      cursor: "pointer"
    },
    "& #next-button": {
      marginLeft: 5,
      marginTop: 2,
      position: "absolute",
      color: "white",
      cursor: "pointer"
    },
    "& .arrow-wrapper-left": {
      left: 20
    },
    "& .arrow-wrapper-right": {
      right: 20
    },
    "& .arrow-wrapper": {
      position: "absolute",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 50,
      height: 50,
      "& #arrow-overlay": {
        width: "100%",
        height: "100%",
        backgroundColor: "#525659",
        borderColor: "#494c50",
        borderRadius: "50%",
        opacity: 0.6
      }
    }
  }
});

export default useStyles;
