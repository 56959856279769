import { createSelector } from "@reduxjs/toolkit";
import { convertObjectIntoArray } from "../components/form/input/DynamicFileInput/FileInputService";

const getNotifications = (state, props) => state.data.notifications;

export const getNotification = createSelector(
  [getNotifications],
  (notificationsObject) => {
    const arrayNotifications = convertObjectIntoArray(notificationsObject);
    return arrayNotifications;
  }
);
