import React, { Component } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
class WithInputHTML5 extends Component {

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        {this.props.children}
      </DndProvider>
    )
  }
}

export default WithInputHTML5


