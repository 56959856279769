import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { alpine_root } from "./alpine_root";
import { alpine_content } from "./alpine_content";
import { alpine_content_stories } from "./alpine_content_stories";
import { alpine_display } from "./alpine_display";
import { alpine_displays } from "./alpine_displays";
import { alpine_presentations } from "./alpine_presentations";
import { alpine_presentation_1x1 } from "./alpine_presentation_1x1";
import { alpine_one_screen_media } from "./alpine_one_screen_media";
import { alpine_content_donor_lists } from "./alpine_content_donor_lists";
import { alpine_donor_list_group } from "./alpine_donor_list_group";
import { alpine_donor_list } from "./alpine_donor_list";
import { alpine_donor } from "./alpine_donor";
import { alpine_story_fact } from "./alpine_story_fact";
import { alpine_story_with_fact } from "./alpine_story_with_fact";
import { alpine_profile_with_media_story } from "./alpine_profile_with_media_story";
import { alpine_story_with_media } from "./alpine_story_with_media";
import { alpine_story_with_single_image } from "./alpine_story_with_single_image";
import { alpine_media_with_quote_2 } from "./alpine_media_with_quote_2";
import { alpine_fullscreen_fact } from "./alpine_fullscreen_fact";

export const alpine_types: { [key: string]: IFolderType } = {
  alpine_root,
  alpine_content,
  alpine_content_stories,
  alpine_display,
  alpine_displays,
  alpine_one_screen_media,
  alpine_presentations,
  alpine_presentation_1x1,
  alpine_content_donor_lists,
  alpine_donor_list_group,
  alpine_donor_list,
  alpine_donor,
  alpine_profile_with_media_story,
  alpine_story_fact,
  alpine_story_with_fact,
  alpine_story_with_media,
  alpine_story_with_single_image,
  alpine_media_with_quote_2,
  alpine_fullscreen_fact
};
