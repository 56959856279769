import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const naples_content_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.naples_content_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.naples_fullscreen_media,
    FOLDER_TYPE.naples_template_red_001,
    FOLDER_TYPE.naples_template_blue_001,
    FOLDER_TYPE.naples_template_red_002,
    FOLDER_TYPE.naples_template_blue_002
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.333,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.naples_template_red_001,
      FOLDER_TYPE.naples_template_blue_001,
      FOLDER_TYPE.naples_template_red_002,
      FOLDER_TYPE.naples_template_blue_002
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "transition",
        title: "Transition",
        sort: true,
        editable: true,
        default_shown: true,
        required: true
      },
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.naples_template_red_001,
          FOLDER_TYPE.naples_template_blue_001,
          FOLDER_TYPE.naples_template_red_002,
          FOLDER_TYPE.naples_template_blue_002
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.CoburgStoryGroup
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 400,
    columns: 3
  }
};
