import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./monterrey_settings";

export const monterrey_presentation_5x1: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.monterrey_presentation_5x1,
  display_name: "Presentation 5x1",
  acceptable_folder_types: [FOLDER_TYPE.monterrey_layout, FOLDER_TYPE.monterrey_content_layouts],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: transitions
    },
    button_color: {
      type: "color",
      title: "Control Panel Button Color",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    aspect_ratio: 2.8125,
    component_name: COMPONENT_NAME.Monterrey5x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 5 }],
    zoomable: true
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false,
  isPresentation: true
};
