import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { setClientOptions } from "../../../../reducers/clientOptionsSlice";
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";
import { IResource } from "../../../../shared-global/interfaces/models/resource.interface";
import { loopIndex } from "../../../../shared-global/utils/general";
import {
  IResourceResourceFolder,
  RESOURCE_EXPLORER_MODE,
} from "../resource-explorer.interface";
import ResourceExplorerPreview from "../ResourceExplorerPreview/ResourceExplorerPreview";
import ResourceExplorerThumbsAndFolders from "../ResourceExplorerThumbsAndFolders/ResourceExplorerThumbsAndFolders";
import useStyles from "./styles";

interface IResourceExplorerWrapperProps {
  width: number;
  height: number;
  folders: any;
  folder__types?: any;
  resource__folders: any;
  mode: RESOURCE_EXPLORER_MODE;
  setSelectedResource?: (
    resource: IResource & { url: string; thumbUrl: string }
  ) => void;
}

const ResourceExplorerWrapper: React.FC<IResourceExplorerWrapperProps> = (
  props
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentResourceResourceFolders, setCurrentResourceResourceFolders] =
    useState<IResourceResourceFolder[]>([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [currentResourceResourceFolder, setCurrentResourceResourceFolder] =
    useState<IResourceResourceFolder>(null);
  const [
    currentResourceResourceFolderIndex,
    _setCurrentResourceResourceFolderIndex,
  ] = useState<number>(null);
  const currentResourceResourceFolderIndexRef = useRef(
    currentResourceResourceFolderIndex
  );
  const setCurrentResourceResourceFolderIndex = (data, row?: number) => {
    currentResourceResourceFolderIndexRef.current = data;
    _setCurrentResourceResourceFolderIndex(data);
    setTemporalRowToScroll(row);
  };

  const [rowToScroll, setRowToScroll] = useState(0);
  const [temporalRowToScroll, setTemporalRowToScroll] = useState(0);

  const selectAdjacentResourceResourceFolder = (
    direction: string = "forward"
  ) => {
    const newIndex = loopIndex(
      currentResourceResourceFolderIndexRef.current,
      0,
      currentResourceResourceFolders.length - 1,
      direction
    );
    dispatch(
      setClientOptions({
        resource_selected_resource:
          currentResourceResourceFolders[newIndex].resource__folder.id,
      })
    );
    setCurrentResourceResourceFolderIndex(newIndex);
  };

  useEffect(() => {
    const foundResourceResourceFolder =
      currentResourceResourceFolders[
        currentResourceResourceFolderIndexRef.current
      ];
    setCurrentResourceResourceFolder(foundResourceResourceFolder);
  }, [currentResourceResourceFolderIndexRef.current]);

  const previewClosed = () => {
    setCurrentResourceResourceFolderIndex(null);
    setCurrentResourceResourceFolder(null);
    dispatch(
      setClientOptions({
        resource_selected_resource: null,
      })
    );
  };

  const togglePreview = (val: boolean) => {
    setIsPreviewOpen(val);
    if (!val) {
      setRowToScroll(temporalRowToScroll);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TransitionGroup>
        <CSSTransition
          timeout={500}
          classNames={TRANSITION_CLASS.TRASITION_FADE}
        >
          <>
            <ResourceExplorerThumbsAndFolders
              {...props}
              setIsPreviewOpen={togglePreview}
              setCurrentResourceResourceFolderIndex={
                setCurrentResourceResourceFolderIndex
              }
              setCurrentResourceResourceFolders={
                setCurrentResourceResourceFolders
              }
              setSelectedResource={props.setSelectedResource}
              mode={props.mode}
              rowToScroll={rowToScroll}
            />
            <TransitionGroup>
              {isPreviewOpen && (
                <CSSTransition
                  timeout={500}
                  classNames={TRANSITION_CLASS.TRASITION_FADE}
                  onExited={previewClosed}
                >
                  <div className={classes.previewWrapper}>
                    <ResourceExplorerPreview
                      {...props}
                      resourceFolderFoldersQty={
                        currentResourceResourceFolders.length
                      }
                      setIsPreviewOpen={togglePreview}
                      resourceResourceFolder={currentResourceResourceFolder}
                      mode={props.mode}
                      getNextResourceResourceFolder={() =>
                        selectAdjacentResourceResourceFolder("forward")
                      }
                      getPreviousResourceResourceFolder={() =>
                        selectAdjacentResourceResourceFolder("backwards")
                      }
                    />
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default ResourceExplorerWrapper;
