import {
  object,
  number,
  string
} from 'yup';

export const addOrRemoveUserCompanyValidationSchema = object().shape({
  user_id: number().required(),
  company_id: number().required()
});

export const generateFoldersValidationSchema = object().shape({
  simple_api_key: string().required(),
  parent_folder: number().required(),
  count: number().required(),
  folder_type: string().required(),
  company: number().required()
});

export const changePlaceholderConfigurationValidationSchema = object().shape({
  placeholder_single_image: string(),
  placeholder_multiple_image: string(),
  placeholder_background_color: string(),
});

export const generateClientConfigValidationSchema = object().shape({
  folder_ids: string().required(),
});
