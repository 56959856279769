import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { eugene_category_shared } from "./eugene_category_shared";

const modifiedFieldGroups = { ...eugene_category_shared.field_groups };
delete modifiedFieldGroups.list;

export const eugene_category_with_giving_level: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.eugene_category_with_giving_level,
  display_name: "Fund Giving Level",
  acceptable_folder_types: [FOLDER_TYPE.eugene_category_without_giving_level],
  tabs: {
    ...eugene_category_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...eugene_category_shared.fields
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.eugene_category_without_giving_level], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.eugene_category_without_giving_level],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.eugene_category_without_giving_level],
        field: "name",
        title: "Name",
        sort: true,
        editable: true,
        default_shown: true
      } //array of types should only be used if both fields are identical just on different folder_types
      // {
      //   folder_types: [FOLDER_TYPE.eugene_category_without_giving_level],
      //   field: "sorting_order",
      //   title: "Sorting Order",
      //   sort: true,
      //   editable: true,
      //   default_shown: true,
      // },
    ]
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneDonorListCategoryWrapper,
    type: PREVIEW_TYPE.COMPONENT
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
