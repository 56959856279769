import React from "react"
import RichTextRender from "../../standard/RichTextRender";
import MonterreyFactCarousel from "./MonterreyFactCarousel";
import {generateFontSizeClassesFromFields, getFieldValue} from "../../../utils/generalUtils";
import get from "lodash/get";
import { createUseStyles } from "react-jss";
import MonterreyFooter from "./MonterreyFooter";

const MonterreyStoryWithFact = (props) => {
  const getStyles = () => {
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.baseFolder?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, props.baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();

  const tagline = getFieldValue(props.baseFolder, 'tagline', props.baseFolderType);
  const header = getFieldValue(props.baseFolder, 'header', props.baseFolderType);
  const body = getFieldValue(props.baseFolder, 'body', props.baseFolderType);
  const factBackgroundColor = get(props.baseFolder, "fields.fact_background_color", "#76232f")
  const backgroundColor = getFieldValue(props.baseFolder, "background_color", props.baseFolderType);

  return (
    <div
      className="wrapper"
      style={{
        backgroundColor,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div
          className="section-padding"
          style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            marginBottom: '2em',
            padding: '7.5em 7.5em 0 7.5em',
            flexDirection: 'column',
          }}
        >
          <div
            className={classes.componentContainer}
          >
            <RichTextRender
              style={{
                marginBottom: '1.125em',
                lineHeight: '1.15',
                padding: 0,
                letterSpacing: '0.05em'
              }}
              htmlString={tagline}
            />
            <RichTextRender
              style={{
                marginBottom: '2.5em',
                lineHeight: '1.05',
                padding: 0,
              }}
              htmlString={header}
            />
            <RichTextRender
              style={{
                lineHeight: '1.15',
                padding: 0,
              }}
              htmlString={body}
            />
          </div>
        </div>
        <div className="interactiveFooter" >
          <div
            style={{
              height: '43em'
            }}
          >
            <MonterreyFactCarousel
              {...props}
              baseFolder={props.baseFolder}
              baseFolderType={props.baseFolderType}
              factBackgroundColor={factBackgroundColor}
              style={{
                display: "flex",
                width: "100%",
                marginTop: "2em",
                paddingRight: '7.5em',
                paddingLeft: '7.5em',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
                maxHeight: '40em'
              }}
              minHeight="40em"
            />
          </div>
          {/* Sin Margin Todp*/}
          <MonterreyFooter
            {...props}
            classes={classes}
          />
        </div>
      </div>
    </div>
  )
}

export default MonterreyStoryWithFact
