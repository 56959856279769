import React from 'react'
import RichTextRender from "../../standard/RichTextRender";

const EverettStoryWithQuoteText = (props) => {
  return (
    <>
      <div className="story-top-screen-wrapper" style={
        {
          height: '100%',
          width: '100%'
        }
      }>
        <div className="padding-top-screen" style={{
          padding: '10em 10em 7.5em',
          height: '100%',
          width: '100%'
        }}>
          <div className="story-wrapper" style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}>
            <div className="story-header" style={{
              marginBottom: '2.5em',
              lineHeight: '1.25',
              flex: '0 auto'
            }}>
              <RichTextRender htmlString={props.header} />
            </div>
            <div className="story-text" style={{
              lineHeight: '1.75'
            }}>
              <RichTextRender htmlString={props.text_top_screen} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EverettStoryWithQuoteText;
