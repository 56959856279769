import React, { Component, useEffect, useState } from "react";
import { Input } from "antd";
import _, { get } from "lodash";
import { PDG_COLORS } from "../../../shared-global/enums/ui-enums";
import { IState } from "../../../interfaces/main-state.interface";
import { useSelector } from "react-redux";
import InputTooltip from "./InputTooltip";

interface ITextInputProps {
  onBlur: any;
  showLabel: boolean;
  fieldName: string;
  defaultValue: string;
  title: string;
  onChange: any;
  showCharCount: boolean;
  canUpdate: boolean;
  tooltip?: string;
}

const TextInput: React.FC<ITextInputProps> = (props: ITextInputProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const [appliedStyle, setAppliedStyle] = useState({})

  const searchString = useSelector(
    (state: IState) => state.client_options.search_string_highlight
  );

  useEffect(() => {
    const searchStringOrEmpty = searchString || '';
    const defaultValueOrEmpty = _.get(props, 'defaultValue', '');
    if (searchStringOrEmpty.toLowerCase().length && defaultValueOrEmpty.toLowerCase().includes(searchStringOrEmpty.toLowerCase())) {
      setAppliedStyle({
        borderColor: PDG_COLORS.MD_WARNING
      })
    }
    else {
      setAppliedStyle({})
    }
  }, [searchString])

  const onBlur = (ev: any) => {
    if (props.onBlur) {
      props.onBlur(props.fieldName, ev.target.value);
    }
    setFocused(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const textLength = get(props, "defaultValue.length", 0);
  const recommendedLength = get(props, "settings.recommended_length", 50);
  let charCountColor = PDG_COLORS.XLT_GRAY;
  if (textLength > recommendedLength) {
    charCountColor = PDG_COLORS.MD_RED;
  }

  return (
    <React.Fragment>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          {
            props.tooltip ?
              <InputTooltip tooltip={props.tooltip} />
              : null
          }
          <br />
        </span>
      ) : null}
      <Input
        className="text-input"
        defaultValue={props.defaultValue}
        value={props.defaultValue}
        onChange={(ev) => {
          props.onChange(props.fieldName, ev.target.value)
        }}
        onBlur={(ev) => onBlur(ev)}
        onFocus={() => onFocus()}
        disabled={!props.canUpdate}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={appliedStyle}
      />
      {props.showCharCount !== false ? (
        <div
          style={{ width: "100%", textAlign: "right", display: "inline-block" }}
        >
          {focused || isHovered ? (
            <span style={{ color: charCountColor }}>
              {textLength}/{recommendedLength}
            </span>
          ) : (
            <span className="noselect">&nbsp;</span>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default TextInput;
