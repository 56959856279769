import React from 'react'
import RichTextRender from "../../standard/RichTextRender";

const EverettStoryWithQuoteText = (props) => {
  return (
    <>
      <div className="story-top-screen-wrapper" style={
        {
          height: '50%',
          width: '100%'
        }
      }>
        <div className="padding-top-screen" style={{
          padding: '10em 10em 7.5em',
          height: '100%',
          width: '100%'
        }}>
          <div className="story-wrapper" style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div className="story-header" style={{
              marginBottom: '2.5em',
              lineHeight: '1.25',
              flex: '0 auto'
            }}>
              <RichTextRender htmlString={props.header} />
            </div>
            <div className="story-text" style={{
              lineHeight: '1.75'
            }}>
              <RichTextRender htmlString={props.text_top_screen} />
            </div>
          </div>
        </div>
      </div>
      <div className="story-bottom-screen-wrapper" style={{
        width: '100%',
        height: '50%'
      }}>
        <div className="story-padding-bottom-screen" style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          padding: '7.5em 10em 10em'
        }}>
          <div className="story-wrapper" style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <div className="story-text-bottom" style={{
              lineHeight: '1.5'
            }}>
              <RichTextRender htmlString={props.text_bottom_screen} />
            </div>
            <div className="story-attribution" style={{
              lineHeight: 1,
              marginTop: '2.5em'
            }}>
              <RichTextRender htmlString={props.attribution} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EverettStoryWithQuoteText;
