import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { FIELD_NAME } from "../../../../enums/folder-type-enums";
import { justification, sorting_options, name } from "../standardized_fields";
export const los_angeles_donor_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.los_angeles_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Fund",
  acceptable_folder_types: [FOLDER_TYPE.los_angeles_donor],
  fields: {
    name,
    sorting_options,
    [FIELD_NAME.donor_name_font_size]: {
      type: "number",
      title: "Donor Name Font Size",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 30 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 2
    },
    container_margin_left: {
      type: "integer",
      title: "Donor Name Left Indent",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      default: 12
    },
    container_margin_right: {
      type: "integer",
      title: "Donor Name Right Indent",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      default: 6
    },
    donor_max_width: {
      type: "integer",
      title: "Max Donor Name Width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      tooltip: "Unit is % of container height",
      default: 90
    },
    donor_vertical_separation: {
      type: "integer",
      title: "Space Between Donor Names",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      tooltip: "Unit is in EM (Factor of selected font size)",
      default: 4
    },
    donor_line_space: {
      type: "integer",
      title: "Line Spacing",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      tooltip: "Unit is in EM (Factor of selected font size)",
      default: 1
    },
    donor_name_justification: {
      ...justification,
      title: "Donor Name Justification"
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.los_angeles_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.los_angeles_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.los_angeles_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.los_angeles_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    aspect_ratio: 0.5926,
    type: PREVIEW_TYPE.COMPONENT,
    component_name: COMPONENT_NAME.LosAnglesDonorLists
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
