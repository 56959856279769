import React, { Component } from "react";
import { connect } from 'react-redux';
import { getBackupsByCompany, restoreBackup, exportBackup } from "../../actions/backupActions";
import { Modal, Radio, Table, Button } from "antd";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import AlertNotification from '../layout/AlertNotification';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BackupsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selected_backup: null
    }
  }

  changedRadioSelection = (e) => {
    this.setState({
      selected_backup: e.target.value
    });
  }

  onSuccess = () => {
    AlertNotification('success', 'Success', "Backup restored successfully")
    this.props.set_client_options({
      lock_screen: false
    });
  }

  onFail = (message) => {
    AlertNotification('error', 'Error', message || "Unexpected error happened")
    this.props.set_client_options({
      lock_screen: false
    });
  }

  onSave = (selectedBackup) => {
    if (Object.values(this.props.folder__folders).filter(x => x.parent_folder === this.props.folder.id).length > 0) {
      // This folder has children. For now, not allow backup
      AlertNotification('error', 'Error', "Can't restore a folder with children.")
    } else {
     this.props.restoreBackup(selectedBackup, this.props.folder.id, this.onSuccess, this.onFail);
     // Show the progress
     this.props.set_client_options({
       indeterminate: true,
       lock_screen: true,
       progress: {
         percentage: 0
       },
       message_for_progress: "Restoring backup..."
     })
    }
     this.props.onSave();
  }

  onDownloadBackup = (selectedBackup) => {
    this.props.exportBackup(selectedBackup);
  }

  render() {
    let backupColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Folder name',
        dataIndex: 'folder_name',
        key: 'folder_name',
        sorter: (a, b) => {
          a.folder_name = a.folder_name || '';
          b.folder_name = b.folder_name || '';
          return a.folder_name.toLowerCase() > b.folder_name.toLowerCase() ? -1 : 1;
        }
      }, {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => a.createdAt > b.createdAt ? -1 : 1,
        render: (text, record) => {
          const date = new Date(Number(text));
          return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        },
        defaultSortOrder: 'ascend'
      }, {
        title: '',
        dataIndex: 'restore',
        render: (text, record) => {
          return(
            <Button
              type="primary"
              size="large"
              style={{
                width: "auto",
                fontSize: 14,
                padding: 9,
                fontWeight: "bold",
              }}
              onClick={() => this.onSave(record.id)}
            >
              RESTORE
            </Button>
          );
        }
      }, {
        title: '',
        dataIndex: 'download',
        render: (text, record) => {
          return(
            <FontAwesomeIcon
              className="fa-lg"
              style={{ cursor: "pointer" }}
              icon={["fas", "download"]}
              onClick={() => this.onDownloadBackup(record.id)}
            />
          );
        }
      }
    ];

    let backupDatasource = [];
    if (this.props.backups) {
      backupDatasource = Object.values(this.props.backups).map(x => {
        return {
          id: x.id,
          key: x.id,
          folder_name: x.description,
          createdAt: x.createdAt
        }
      });
    }

    return (
        <Modal
          visible={this.props.visible}
          onCancel={() => this.props.onCancel()}
          title={
            <React.Fragment>
              <FontAwesomeIcon className="fa" icon={["fas", "cog"]} />
              Backups
            </React.Fragment>
          }
          onOk={this.onSave}>
          {
            this.props.backups !== null ?
              <Radio.Group onChange={(e) => this.changedRadioSelection(e)}>
                <Table
                  rowKey={record => record.key}
                  columns={backupColumns}
                  dataSource={backupDatasource}
                  pagination={{ defaultPageSize: 5 }}
                  style={{width: '100%'}}
                  bordered={false}>

                </Table>
              </Radio.Group>
              : <p>Loading...</p>
          }
        </Modal>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const selected_id = state.client_options.applied_content_tree_selected_id;
  let folder = null;
  if (selected_id && selected_id.startsWith('folder-')) {
    const id = selected_id.substring(selected_id.indexOf('-') + 1);
    folder = state.data.folders[id];
  }
  return {
    backups: state.data.backups,
    folder: folder,
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    user: state.data.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>({
  getBackupsByCompany: (company_id, folder_type) => {
    dispatch(getBackupsByCompany(company_id, folder_type))
  },
  restoreBackup: (backup_id, folder_id, onSuccess, onFail) => {
    dispatch(restoreBackup(backup_id, folder_id, onSuccess, onFail))
  },
  set_client_options: (client_options) => {
    dispatch(setClientOptions(client_options))
  },
  exportBackup: (backup_id, onSuccess, onFail) => {
    dispatch(exportBackup(backup_id, onSuccess, onFail));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupsModal)