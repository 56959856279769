import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tabs } from "antd";
import _, { isArray } from "lodash";
import React, { Component, CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { IState } from "../../interfaces/main-state.interface";
import { COMPONENT_MODE_CONTEXT } from "../../shared-global/enums/config-enums";
import {
  PREVIEW_TYPE,
} from "../../shared-global/enums/folder-type-enums";
import { TRANSITION_CLASS } from "../../shared-global/enums/ui-enums";
import { IThemeSettings } from "../../shared-global/interfaces/custom/theme-settings.interface";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces.js";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface.js";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { IFolderStatus } from "../../shared-global/interfaces/models/folder__status.interface";
import { IResource } from "../../shared-global/interfaces/models/resource.interface.js";
import { getInheritableFields } from "../../shared-global/utils/globalDataUtils";
import { IDisplayComponentProps } from "../../shared/interfaces/display-component-props.interface.js";
import { getFileType, getURLFromCache } from "../../shared/utils/fileUtils";
import { bestFit } from "../../shared/utils/screenUtils";
import { TREE_MODE_APPLIED_CONTENT } from "../constants";
import LayoutConstants from "../layout/LayoutConstants";
import DevicePreviewWrapper from "./DevicePreviewWrapper";
import PreviewResource from "./PreviewResource.js";
import { IFolderTypePreview } from "../../shared-global/interfaces/folder-types/folders/IFolderTypePreview";
import { IClientOptions } from "../../reducers/clientOptionsSlice";
import { PreviewContext } from "../../shared/Contexts";
import { withRouter } from "../../hooks/withRouter";
import { folderTypeAlert } from "../../actions/companyActions";
import PreviewHandler from "../../shared/components/standard/Preview/PreviewHandler";

interface IPreviewContainerProps {
  aws_instance: any;
  alertTrigger: string;
  mode?: COMPONENT_MODE_CONTEXT;
  client_options: IClientOptions;
  compHeight: number;
  compWidth: number;
  folder_type: IFolderType;
  folder_types: { [key: string]: IFolderType };
  height: number;
  must_update: boolean;
  parent_folder: IFolder;
  preview_data: any;
  resources: { [key: string]: IResource };
  resourcetree_resource_folders: any;
  width: number;
  mobile: boolean;
  webview_signedurls: any;
  current_folder_types: { [key: string]: any };
  applied_content_tree_expanded: string[];
  folders: { [key: string]: IFolder };
  folder__folders: { [key: string]: IFolderFolder };
  location?: any;
  history?: any;
  match?: any;
  selected_folder?: any;
  zoomable: boolean;
  uri_access_inheritable_fields?: any;
  theme_settings: { [key: string]: IThemeSettings };
  navigate: any;
  setFolderTypeAlertTrigger: Function;
}

interface IPreviewContainerState {
  isBeingResized: boolean;
  resizekey: string;
  // showPreview: boolean;
  compWidth: number;
  compHeight: number;
  dimensionsComputed: boolean;
  activeKey: string;
  hideBorder: boolean;
}

const Style = ({ children, height, width }) => {
  const styles = {
    tabBody: {
      "& .inner-tab": {
        "& .ant-tabs-tabpane": {
          height: (props) => props.theme.tabHeight,
          width: (props) => props.theme.tabWidth,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  };
  const useStyles = createUseStyles(styles);
  const classes = useStyles({ theme: { tabHeight: height, tabWidth: width } });
  return children(classes);
};

class PreviewContainer extends Component<
  IPreviewContainerProps,
  IPreviewContainerState
> {
  _containerRef = null;
  constructor(props) {
    super(props);

    let defaultPreviewActiveKey = "1";
    let selectedFolder = null;
    if (props.preview_data) {
      selectedFolder = props.folders[props.preview_data.base_folder];
      defaultPreviewActiveKey = _.get(
        selectedFolder,
        "fields.default_preview",
        "1"
      );
    }

    this.state = {
      dimensionsComputed: false,
      resizekey: window.innerWidth + "-" + window.innerHeight,
      isBeingResized: true,
      compWidth: 0,
      compHeight: 0,
      activeKey: defaultPreviewActiveKey,
      hideBorder: false,
    };
  }

  private _showPreviewTimeout = null;
  private _resizeTimeout = null;
  private _reloadPrevieTimeout = null;

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.client_options.detail_drawer_open !==
      prevProps.client_options.detail_drawer_open ||
      this.props.client_options.tree_panel_open !==
      prevProps.client_options.tree_panel_open ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this.reloadPreview();
    }

    let hideBorder = false;
    if (this.props.folder_type) {
      if (isArray(this.props.folder_type.preview)) {
        const currentPreview =
          this.props.folder_type.preview[Number(this.state.activeKey) - 1];
        if (currentPreview) {
          hideBorder = currentPreview.hide_border;
        }
      } else {
        hideBorder = this.props.folder_type.preview.hide_border;
      }

      if (_.get(this.props, "folder_type.preview.is_mobile", false)) {
        hideBorder = true;
      }
    }

    if (
      this.props.folder_type !== prevProps.folder_type ||
      this.state.activeKey !== prevState.activeKey ||
      this.props.client_options.applied_content_selected_tab !==
      prevProps.client_options.applied_content_selected_tab
    ) {
      this.calculateBestFit();
    }
    if (this.state.hideBorder !== hideBorder) {
      this.setState({ hideBorder });
    }
  }

  componentDidMount() {
    this._showPreviewTimeout = setTimeout(() => {
      this.reloadPreview();
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this._resizeTimeout);
    clearTimeout(this._reloadPrevieTimeout);
    clearTimeout(this._showPreviewTimeout);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.width !== nextProps.width ||
      this.props.height !== nextProps.width
    ) {
      return true;
    }

    if (this.props.selected_folder !== nextProps.selected_folder) {
      return true;
    }
    if (this.props.client_options !== nextProps.client_options) {
      return true;
    }

    if (nextProps.must_update) {
      return true;
    }

    if (nextProps.must_update_by_folder_status) {
      return true;
    }

    if (this.state.compWidth !== nextState.compWidth) {
      return true;
    }

    if (this.state.compHeight !== nextState.compHeight) {
      return true;
    }

    if (this.props.folder_type !== nextProps.folder_type) {
      return true;
    }

    if (this.props.aws_instance !== nextProps.aws_instance) {
      return true;
    }

    if (this.state.isBeingResized !== nextState.isBeingResized) {
      return true;
    }

    if (this.state.activeKey !== nextState.activeKey) {
      return true;
    }

    if (
      this.props.resourcetree_resource_folders !==
      nextProps.resourcetree_resource_folders
    ) {
      return true;
    }

    if (this.props.resources !== nextProps.resources) {
      return true;
    }

    if (
      this.props.client_options.applied_content_selected_tab !==
      nextProps.client_options.applied_content_selected_tab
    ) {
      return true;
    }

    return false;
  }

  setInitialDimensions = () => {
    if (this._containerRef && !this.state.dimensionsComputed) {
      const rect = this._containerRef.getBoundingClientRect();
      this.setState({
        compHeight: rect.height,
        compWidth: rect.width,
        dimensionsComputed: true,
      });
    }
  };

  calculateBestFit = () => {
    if (this.props.folder_type) {
      let hasTabs = false;
      let previewObj: IFolderTypePreview = null;
      if (isArray(this.props.folder_type.preview)) {
        hasTabs = true;
        const folderTypeInParent = this.getParentFolderTypeInPath();
        if (folderTypeInParent) {
          hasTabs = false;
          previewObj = this.props.folder_type.preview.find(
            (p) => p.use_if_parent_is === folderTypeInParent
          );
        } else {
          previewObj =
            this.props.folder_type.preview[Number(this.state.activeKey) - 1];
        }
      } else {
        if (
          this.props.folder_type.tab_previews &&
          this.props.folder_type.tab_previews.length &&
          this.props.mode !== COMPONENT_MODE_CONTEXT.WEBVIEW
        ) {
          previewObj = this.props.folder_type.tab_previews.find(
            (tab) =>
              tab.tab_key ===
              this.props.client_options.applied_content_selected_tab
          );
        } else {
          previewObj = this.props.folder_type.preview;
        }
      }
      if (this.props.zoomable) {
        this.setState({
          compWidth: this.props.width,
          compHeight: this.props.height,
        });
      } else {
        const tabHeight = hasTabs ? 70 : 0;
        const aspect = previewObj.aspect_ratio ? previewObj.aspect_ratio : 1;

        const bestFitDims = bestFit(
          aspect,
          1,
          this.props.width,
          this.props.height - tabHeight
        );
        this.setState({
          compWidth: bestFitDims.width,
          compHeight: bestFitDims.height,
        });
      }
    } else {
      this.setInitialDimensions();
    }
  };

  reloadPreview = () => {
    this.setState({
      isBeingResized: true,
    });
    this.calculateBestFit();
    this._reloadPrevieTimeout = setTimeout(() => {
      this.setState({
        isBeingResized: false,
      });
    }, 1000);
  };

  handleChangePreviewTabs = (activeKey) => {
    this.setState({ activeKey });
  };

  getParentFolderTypeInPath = () => {
    const folderTypePreview = this.props.folder_type
      .preview as IFolderTypePreview[];
    const previewUseFolderTypes = folderTypePreview
      .map((p) => p.use_if_parent_is)
      .filter((p) => p !== undefined);
    // Check the path to see if the preview should use tabs or not.
    const path = this.props.client_options.selected_folder_meta.path;
    if (!path) {
      return null;
    }
    const pathFolderFolderTypes = path.reduce((acc, curr) => {
      const folderFolder = this.props.folder__folders[curr];
      if (folderFolder) {
        const folder = this.props.folders[folderFolder.child_folder];
        acc = acc.concat(folder.folder_type);
        return acc;
      }
    }, []);
    if (pathFolderFolderTypes) {
      const foundMatchingFolderTypeIndex = previewUseFolderTypes.findIndex(
        (p) => pathFolderFolderTypes.indexOf(p) > -1
      );
      if (foundMatchingFolderTypeIndex > -1) {
        return previewUseFolderTypes[foundMatchingFolderTypeIndex];
      }
    }
    return null;
  };

  showTabs = () => {
    if (isArray(this.props.folder_type.preview)) {
      const folderTypeInParent = this.getParentFolderTypeInPath();
      if (folderTypeInParent) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  getPreview = () => {
    if (this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
      let showTabs = false;
      let previewObj = null;
      let isMobile = false;

      if (isArray(this.props.folder_type.preview)) {
        showTabs = true;
        const folderTypeInParent = this.getParentFolderTypeInPath();
        if (folderTypeInParent) {
          showTabs = false;
          previewObj = this.props.folder_type.preview.find(
            (p) => p.use_if_parent_is === folderTypeInParent
          );
        }
      } else {
        isMobile = _.get(this.props, "folder_type.preview.is_mobile", false);
        showTabs = false;
        previewObj = this.props.folder_type.preview;
      }

      const previewStyle: CSSProperties = {
        position: "absolute",
        width: this.state.compWidth,
        height: this.state.compHeight,
      };

      if (showTabs && isArray(this.props.folder_type.preview)) {
        return (
          <Tabs
            className="inner-tab"
            onChange={this.handleChangePreviewTabs}
            activeKey={this.state.activeKey}
          >
            {this.props.folder_type.preview.map((p, i) => {
              let tab = <span>{p.preview_tab_alias}</span>;
              if (p.preview_tab_alias_url) {
                tab = (
                  <img style={{ maxWidth: 50 }} src={p.preview_tab_alias_url} />
                );
              }
              return (
                <Tabs.TabPane tab={tab} key={i + 1}>
                  <div style={previewStyle}>
                    {Number(this.state.activeKey) - 1 === i &&
                      this.getAppliedContentPreview(i)}
                  </div>
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        );
      } else {
        if (isMobile) {
          if (this.props.mode === COMPONENT_MODE_CONTEXT.WEBVIEW) {
            return this.getAppliedContentPreview();
          }
          return (
            <DevicePreviewWrapper
              width={this.state.compWidth}
              height={this.state.compHeight}
            >
              {this.getAppliedContentPreview()}
            </DevicePreviewWrapper>
          );
        }

        return (
          <div style={previewStyle}>
            {previewObj.static_url ? (
              <div
                style={{
                  width: this.state.compWidth,
                  height: this.state.compHeight,
                  background: "url('" + previewObj.static_url + "')",
                  backgroundSize: `${this.state.compWidth}px ${this.state.compHeight}px`,
                }}
              ></div>
            ) : null}
            {this.getAppliedContentPreview()}
          </div>
        );
      }
    } else {
      return this.getResourcePreview();
    }
  };

  getResourcePreview = () => {
    const treeId = this.props.client_options.resource_tree_selected_id;
    if (
      treeId !== null &&
      treeId !== undefined &&
      treeId.includes("resource-")
    ) {
      const resourceFolderId = treeId.replace("resource-", "");
      const resourceFolder =
        this.props.resourcetree_resource_folders[Number(resourceFolderId)];
      if (!resourceFolder) {
        return null;
      }
      const resource = this.props.resources[resourceFolder.resource];
      let url = null;
      if (resource && resource.type === "file") {
        const fileType = getFileType(resource.name);
        let name = resource.name;
        if (fileType === "video") {
          name = `${resource.id}_preview.${resourceFolder.extension}`;
        }
        if (fileType === "image") {
          name = `${resource.id}_preview.${resourceFolder.extension}`;
        }
        const params = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
          Key: name,
        };
        url = getURLFromCache(
          `resource-preview-${resource.id}`,
          params,
          this.props.aws_instance
        );
      }
      return (
        <PreviewResource
          key={url}
          containerWidth={this.state.compWidth}
          containerHeight={this.state.compHeight}
          resource={{ ...resource, url: url }}
          client_options={this.props.client_options}
        />
      );
    }
  };

  getAppliedContentPreview = (previewArrayIndex = 0) => {
    if (!this.props.folder_type) {
      return null;
    }
    if (!this.props.folder_type || !this.props.folder_type.preview) {
      return null;
    }

    let previewObj = null;
    const isMobile = _.get(this.props, "folder_type.preview.is_mobile", false);
    if (isArray(this.props.folder_type.preview)) {
      previewObj = this.props.folder_type.preview[previewArrayIndex];
      const previewUseFolderTypes = this.props.folder_type.preview.map(
        (p) => p.use_if_parent_is
      );
      // Check the path to see if the preview should use tabs or not.
      const path = this.props.client_options.selected_folder_meta.path;
      if (path) {
        const pathFolderFolderTypes = path.reduce((acc, curr) => {
          const folderFolder = this.props.folder__folders[curr];
          if (folderFolder) {
            const folder = this.props.folders[folderFolder.child_folder];
            acc = acc.concat(folder.folder_type);
            return acc;
          }
        }, []);
        if (pathFolderFolderTypes) {
          const foundMatchingFolderTypeIndex = previewUseFolderTypes.findIndex(
            (p) => pathFolderFolderTypes.indexOf(p) > -1
          );
          if (foundMatchingFolderTypeIndex > -1) {
            previewObj =
              this.props.folder_type.preview[foundMatchingFolderTypeIndex];
          }
        }
      }
    } else {
      previewObj = this.props.folder_type.preview;
    }

    if (previewObj.type === PREVIEW_TYPE.IMAGE) {
      return (
        <div
          style={{
            width: this.state.compWidth,
            height: this.props.height,
            backgroundColor: "#D3D3D3",
          }}
        >
          <img
            alt="preview"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={previewObj.path}
          />
        </div>
      );
    } else if (previewObj.type === "component") {
      const key = this.props.must_update
        ? this.props.preview_data.base_folder + new Date().getTime()
        : this.props.preview_data.base_folder;

      let inheritable_fields = getInheritableFields(
        Object.values(this.props.folder__folders),
        Object.values(this.props.folders),
        Object.values(this.props.folder_types),
        this.props.client_options.selected_folder_meta.path
      );

      if (this.props.mode === COMPONENT_MODE_CONTEXT.WEBVIEW) {
        inheritable_fields = this.props.uri_access_inheritable_fields;
      }

      const previewComponentProps: IDisplayComponentProps = {
        aws: this.props.aws_instance,
        aws_bucket: process.env.REACT_APP_S3_BUCKET,
        mode: this.props.mode
          ? this.props.mode
          : COMPONENT_MODE_CONTEXT.PREVIEW,
        containerWidth: this.state.compWidth,
        containerHeight: this.state.compHeight,
        refHeight: this.state.compHeight,
        refWidth: this.state.compWidth,
        data: this.props.preview_data,
        webview_signedurls: this.props.webview_signedurls,
        inheritable_fields: inheritable_fields,
      };
      previewComponentProps.data.parents = [this.props.parent_folder];

      if (previewObj.react_url) {
        previewComponentProps.data.basePath = `/dashboard/${previewObj.react_url}`;
      } else {
        if (this.props.location.pathname !== '/dashboard') {
          this.props.navigate('/dashboard', { replace: true });
        }
      }

      let componentName = previewObj.component_name;

      if (
        this.props.folder_type.tab_previews &&
        this.props.folder_type.tab_previews.length
      ) {
        let matchedTab = this.props.folder_type.tab_previews.find(
          (x) =>
            x.tab_key === this.props.client_options.applied_content_selected_tab
        );

        if (matchedTab) {
          componentName = matchedTab.component_name;
        }
      }

      const contentStyle: any = {
        overflowY: isMobile ? "scroll" : "inherit",
        overflowX: isMobile ? "hidden" : "inherit",
        width:
          this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT && !isMobile
            ? this.state.compWidth
            : "100%",
        height:
          this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT && !isMobile
            ? this.state.compHeight
            : "100%",
      };

      return (
        // Content
        <div style={contentStyle}>
          {/* Border */}
          {!(
            this.state.hideBorder ||
            this.props.mode === COMPONENT_MODE_CONTEXT.WEBVIEW
          ) && (
              <div
                style={{
                  position: "absolute",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "LightGray",
                  width:
                    this.props.client_options.treemode ===
                      TREE_MODE_APPLIED_CONTENT
                      ? this.state.compWidth + 2
                      : "100%",
                  height:
                    this.props.client_options.treemode ===
                      TREE_MODE_APPLIED_CONTENT
                      ? this.state.compHeight + 2
                      : "100%",
                  top: -1,
                  left: -1,
                }}
              />
            )}
          <PreviewContext.Provider value={previewComponentProps}>
            <PreviewHandler
              key={key}
              componentName={componentName}
              previewComponentProps={previewComponentProps}
              parent_folder={this.props.parent_folder}
              alertTrigger={this.props.alertTrigger}
              setFolderTypeAlertTrigger={this.props.setFolderTypeAlertTrigger}
            />
          </PreviewContext.Provider>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    if (
      !this.props.preview_data &&
      this.props.client_options.resource_tree_selected_id === "applied_content"
    ) {
      return null;
    }
    const preview = this.getPreview();
    if (this.props.width < this.state.compWidth && !this.props.mobile) {
      return <p>Not enough space</p>;
    }

    if (this.props.folder_type) {
      if (!isArray(this.props.folder_type.preview)) {
        if (
          this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT &&
          this.props.folder_type.preview.type === "none"
        ) {
          return null;
        }
      }
    }

    const containerStyle =
      this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT
        ? {
          overflow: "hidden",
        }
        : {
          height: "100%",
          width: "100%",
        };

    const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
    const previewStyle: CSSProperties = {
      width:
        this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT
          ? this.props.width
          : "100%",
      height:
        this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT
          ? this.props.height
          : "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    if (this.props.zoomable) {
      previewStyle["position"] = "absolute";
      previewStyle["left"] = 0;
    }

    return (
      <Style
        {...this.props}
        height={this.props.height - (LayoutConstants().tabs.height + 16)}
      >
        {(classes) => (
          <div
            className={classes.tabBody}
            ref={(el) => (this._containerRef = el)}
            style={{
              ...containerStyle,
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              zIndex: 1,
              backgroundColor: "#FFFFFF",
            }}
          >
            <TransitionGroup>
              <CSSTransition
                key={
                  this.state.resizekey +
                  "-" +
                  this.props.client_options.detail_drawer_open +
                  this.state.isBeingResized.toString()
                }
                timeout={500}
                classNames={TRANSITION_CLASS.TRASITION_FADE}
              >
                {this.props.zoomable ? (
                  <>
                    {this.state.isBeingResized ? (
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          left: 0,
                        }}
                      >
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <div style={previewStyle}>{preview}</div>
                    )}
                  </>
                ) : (
                  <>
                    {this.state.isBeingResized ? (
                      <div style={previewStyle}>
                        <Spin indicator={antIcon} size="large" />
                      </div>
                    ) : (
                      <div style={previewStyle}>{preview}</div>
                    )}
                  </>
                )}
              </CSSTransition>
            </TransitionGroup>
          </div>
        )}
      </Style>
    );
  }
}

let previousFolder = {} as any;

const mapStateToProps = (state: IState, ownProps) => {
  let preview_data = null;

  // prepare data in same format as endpoint for pull, using treeId or otherwise

  // get aspect ratio of this.props.folder_type.preview.component_name
  // if greater then 1 its in landscape otherwise its in portrait
  // if in landscape width = max width = this.props.width. Height is determined by max width / aspect ratio
  // if in portrait height = max height = this.props.height. Width is determined by max height * aspect ratio
  // left or align or center this result.

  // new props set for componentWidth, componentHeight set from this calc.
  let must_update = false;
  let must_update_by_folder_status = false;
  let currentFolderTypes = {};

  let folderTypePreview = null;

  if (ownProps.folder_type) {
    if (isArray(ownProps.folder_type.preview)) {
      folderTypePreview = ownProps.folder_type.preview[0];
    } else {
      folderTypePreview = ownProps.folder_type.preview;
    }
  }

  if (_.get(folderTypePreview, "type", null) === "component") {
    // preview_data = previewDataSelector(state, ownProps);
    preview_data = {
      base_folder: ownProps.selected_folder.id,
      folder__folder_id:
        state.client_options.selected_folder_meta.folder__folder_id,
      folders: Object.values(state.data.folders),
      folder__folders: Object.values(state.data.folder__folders),
      resources: Object.values(state.data.resources),
      resource__folders: Object.values(state.data.resource__folders),
      folder_types: Object.values(state.data.folder_types),
      persistent_settings: state.data.persistent_settings,
      system_settings: state.data.system_settings,
      s3Cache: state.data.s3Cache,
    };

    if (JSON.stringify(previousFolder) === "{}" || previousFolder === null) {
      previousFolder = ownProps.selected_folder;
    } else if (
      JSON.stringify(previousFolder.fields) !==
      JSON.stringify(ownProps.selected_folder.fields) ||
      previousFolder.name !== ownProps.selected_folder.name
    ) {
      previousFolder = ownProps.selected_folder;
      must_update = true;
      preview_data = { ...preview_data };
    }

    const foundAssociatedFolderStatus = Object.values<IFolderStatus>(
      state.data.folder__status
    ).filter((fs) => fs.folder === Number(preview_data.base_folder));
    if (foundAssociatedFolderStatus.length > 0) {
      must_update_by_folder_status = true;
    }
  }

  let possible_folder_folders, possible_parents, parent_folder;
  if (
    state.client_options.applied_content_selected_folder &&
    ownProps.selected_folder
  ) {
    // Check for possible parents
    possible_folder_folders = Object.values(state.data.folder__folders).filter(
      (x: any) => x.child_folder === ownProps.selected_folder.id
    );
    possible_parents = possible_folder_folders.map(
      (x) => state.data.folders[x.parent_folder]
    );
    parent_folder = possible_parents[0];

    const selectedFolderFolderType = ownProps.selected_folder.folder_type;
    const selectedFolderTemplateName = selectedFolderFolderType.split("_")[0];
    Object.keys(state.data.folder_types).forEach((template_key) => {
      if (template_key.includes(selectedFolderTemplateName)) {
        currentFolderTypes[template_key] = {
          ...state.data.folder_types[template_key],
        };
      }
    });
  }

  if (preview_data) preview_data.parent_folder = parent_folder;

  return {
    alertTrigger: state.data.alert_trigger,
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    folder_types: state.data.folder_types,
    client_options: state.client_options,
    resources: state.data.resources,
    resourcetree_resource_folders: state.data.resourcetree_resource__folders,
    aws_instance: state.data.aws_instance,
    webview_signedurls: state.data.webview_signedurls,
    preview_data,
    parent_folder,
    must_update,
    must_update_by_folder_status,
    project_templates: state.data.project_templates,
    current_folder_types: currentFolderTypes,
    applied_content_tree_expanded: state.data.applied_content_tree_expanded,
    zoomable: ownProps.zoomable,
    uri_access_inheritable_fields: state.data.uri_access_inheritable_fields,
    theme_settings: state.data.theme_settings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFolderTypeAlertTrigger: (alertTrigger) =>
    dispatch(folderTypeAlert(alertTrigger)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewContainer));
