import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { delete_company, getCompanies } from "../../actions/companyActions";
import { getProjects } from "../../actions/projectActions";
import { IState } from "../../interfaces/main-state.interface";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { dateFormatted } from "../../utils/dateUtils";
import TextInput from "../form/input/TextInput";
import AlertNotification from "../layout/AlertNotification";
import CompanyBreadCrumbs from "./CompanyBreadCrumbs";

interface ICompaniesProps { }

const Companies: React.FC<ICompaniesProps> = (props: ICompaniesProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(
    false
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [confirmText, setConfirmText] = useState("");
  const projects = useSelector((state: IState) => state.data.projects);
  const companies = useSelector((state: IState) => state.data.companies);

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getProjects());
  }, []);

  const onShowCompanyDeletionConfirmationModal = (id: number) => {
    setSelectedCompanyId(id);
    setConfirmDeleteModalVisible(true);
  };

  const onDeleteCompanySuccess = () => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null })
    );
    AlertNotification(
      "success",
      "Success",
      "Company has been deleted successfully"
    );
  };

  const onDeleteCompanyFail = (message) => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null })
    );
  };

  const onDeleteCompany = () => {
    if (
      Object.values(projects).filter((x) => x.company === selectedCompanyId)
        .length > 0
    ) {
      AlertNotification(
        "error",
        "Error",
        "Can't delete a company with projects related"
      );
      return;
    }
    if (confirmText !== "delete") {
      AlertNotification(
        "error",
        "Error",
        'Please type "delete" into the confirmation text input'
      );
    } else {
      dispatch(
        setClientOptions({
          lock_screen: true,
          indeterminate: true,
          message_for_progress: "Please wait while we save your changes",
        })
      );
      dispatch(
        delete_company(
          selectedCompanyId,
          onDeleteCompanySuccess,
          onDeleteCompanyFail
        )
      );
      setConfirmDeleteModalVisible(false);
      setSelectedCompanyId(null);
      setConfirmText("");
    }
  };

  const onCancelCompanyDeletion = () => {
    setSelectedCompanyId(null);
    setConfirmDeleteModalVisible(false);
    setConfirmText("");
  };

  let companyTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (!a.name) {
          a.name = "";
        }
        if (!b.name) {
          b.name = "";
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        return 0;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (text, record) => {
        return <span>{dateFormatted(text, "yyyymmdd")}</span>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (text, record) => {
        let url = "/company/" + record.id;
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(url);
            }}
          >
            <FontAwesomeIcon className="fa-lg" icon="edit" />
          </span>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (text, record) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onShowCompanyDeletionConfirmationModal(record.id)}
          >
            <FontAwesomeIcon className="fa-lg" icon={["far", "trash"]} />
          </span>
        );
      },
    },
  ];

  let companyArray = arrayFromKeyedObject(companies);
  let arr = [];
  companyArray.forEach((com: any) => {
    let obj = {
      name: com.name,
      updatedAt: new Date(com.updatedAt),
      createdAt: new Date(com.createdAt),
      active: String(com.active),
      key: com.id,
      id: com.id,
    };
    arr.push(obj);
  });
  return (
    <div>
      <Modal
        title={`Delete Company`}
        visible={confirmDeleteModalVisible}
        onCancel={onCancelCompanyDeletion}
        onOk={onDeleteCompany}
        width={720}
        destroyOnClose
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <span style={{ color: "#EED202" }}>
            <FontAwesomeIcon
              className="fa-4x"
              icon={["fas", "exclamation-triangle"]}
            />
          </span>
          <br />
          <br />
          <span>
            <b>WARNING: THIS IS A DESTRUCTIVE ACTION</b>
          </span>
        </div>
        <br />
        <span>
          Folders and media resources deleted as a consequence of this action
          won't be recoverable.
        </span>
        <br />
        <br />
        {selectedCompanyId && (
          <span>
            {`Are you sure you want to delete project `}
            <b>{companies[selectedCompanyId].name}</b>
          </span>
        )}
        <br />
        <br />
        <b>
          <span>
            Type <i>delete</i> to confirm
          </span>
        </b>
        <TextInput
          fieldName="delete_confirm"
          showLabel={false}
          defaultValue={confirmText}
          title='Type "delete" to confirm'
          showCharCount={false}
          canUpdate
          onBlur={(_, value) => setConfirmText(value)}
          onChange={(_, value) => setConfirmText(value)}
        />
      </Modal>

      <CompanyBreadCrumbs crumbs={{ location: window.location }} />
      <Button onClick={() => navigate("/company/new")}>Add Company</Button>
      <br />
      <br />
      <Table
        title={() => "Companies"}
        rowKey={(record) => record.key}
        columns={companyTableColumns}
        dataSource={arr}
        pagination={false}
        bordered={true}
      />
    </div>
  );
};

export default Companies;
