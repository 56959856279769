import { FOLDER_TYPE } from "../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentPropsData } from "../interfaces/display-component-props.interface";
import { coburgRelevantFolderTypeNames } from "./coburg-search-configuration";
import { glenwoodRelevantFolderTypeNames } from "./glenwood-search-configuration";
import { getChildrenFolderAndFolderFoldersDeep } from "./collectionUtils";
import { eugeneRelevantFolderTypeNames } from "./eugene-search-configuration";

export interface ISearchResult {
    id: number;
    folderFolderId: number
    title: string
    type: string
    children?: ISearchResult[]
    remove?: boolean
}

export enum PROJECT {
    COBURG,
    GLENWOOD,
    EUGENE
}

const searchPropertiesForProject: Record<PROJECT, {
    relevantFolderTypeNames: FOLDER_TYPE[],
    menuFolderType: FOLDER_TYPE
}> = {
    [PROJECT.COBURG] : {
        relevantFolderTypeNames: coburgRelevantFolderTypeNames,
        menuFolderType: FOLDER_TYPE.coburg_menu
    },
    [PROJECT.GLENWOOD] : {
        relevantFolderTypeNames: glenwoodRelevantFolderTypeNames,
        menuFolderType: FOLDER_TYPE.glenwood_menu
    },
    [PROJECT.EUGENE]: {
        relevantFolderTypeNames: eugeneRelevantFolderTypeNames,
        menuFolderType: FOLDER_TYPE.eugene_menu
    }
}

export const coburgSearchInFolders = (str: string, data: IDisplayComponentPropsData) => searchInFolders(str, data, PROJECT.COBURG)

export const glenwoodSearchInFolders = (str: string, data: IDisplayComponentPropsData) => searchInFolders(str, data, PROJECT.GLENWOOD)

export const eugeneSearchInFolders = (str: string, data: IDisplayComponentPropsData) => searchInFolders(str, data, PROJECT.EUGENE)

const findFolderFolderId = (folderFolders, parentFolder, childFolder) => {
    const folderFolder = folderFolders.find(ff => ff.parent_folder === parentFolder && ff.child_folder === childFolder);
    return folderFolder ? folderFolder.id : null;
}

const lookUpParentWithFolderType = (data: IDisplayComponentPropsData, folder: IFolder, lookUpFolderType: FOLDER_TYPE) => {
    let currentFolder = folder;

    while(currentFolder !== null && currentFolder.folder_type !== lookUpFolderType) {
        const folderFolder = data.folder__folders.find(el => el.child_folder === currentFolder.id);
        if (folderFolder) {
            const parentFolderId = data.folder__folders.find(el => el.child_folder === currentFolder.id).parent_folder
            currentFolder = data.folders.find(folder => folder.id === parentFolderId);
        } else {
            currentFolder = null;
        }
    }

    return currentFolder;
}

export const searchInFolders = (str: string, data: IDisplayComponentPropsData, project: PROJECT) => {
    if (!str) {
        return { matches: 0, results: [] };
    }

    const relevantFolderTypeNames = searchPropertiesForProject[project].relevantFolderTypeNames
    const menuFolderType = searchPropertiesForProject[project].menuFolderType

    // find menu children from where to start to run the tree
    const searchFolder = data.folders?.find(f => f.id === data.base_folder);
    const searchFolderFolders = data.folder__folders?.filter(ff => ff.child_folder === searchFolder.id);
    let searchFolderFolder = searchFolderFolders[0]

    // If there are more than one folder folder related to the Search folder then
    // we have to find the folder folder under presentation instead of content
    if (searchFolderFolders.length > 1) {
      for (const searchFolderFolderElement of searchFolderFolders) {
        const parentFolder = data.folders?.find((f) => f.id === searchFolderFolderElement.parent_folder)
        if (!parentFolder.folder_type.includes('content')) {
          searchFolderFolder = searchFolderFolderElement
        }
      }
    }
    const siblings = data.folder__folders?.filter(ff => ff.parent_folder === searchFolderFolder.parent_folder);

    // find menu that is a sibling of search folder
    const menu = data.folders.find(folder => folder.folder_type === menuFolderType && siblings.find(sibling => sibling.child_folder === folder.id)?.child_folder === folder.id);

    const menuChildrenDeep = getChildrenFolderAndFolderFoldersDeep(
        {},
        data.folder__folders,
        data.folders,
        data.resources,
        data.resource__folders,
        data.folder_types,
        data.persistent_settings,
        data.system_settings,
        menu
    );

    // find search matches
    const relevantFolders = menuChildrenDeep.folders.filter(folder => relevantFolderTypeNames.includes(folder.folder_type))
    const searchMatches = relevantFolders.filter(folder => folder.name.toLowerCase().includes(str.toLowerCase())).filter(folder => {
        // filter the ones that are children only of the menu
        return lookUpParentWithFolderType(menuChildrenDeep, folder, menuFolderType) !== null;
    })

    if (searchMatches && searchMatches.length === 0) {
        return { matches: 0, results: [] }
    }

    let currentFolder: IFolder;
    const matchesTree: Record<number, IFolder[]> = {};

    searchMatches.forEach(folder => {
        currentFolder = folder;
        do {
            const parentFolderId = menuChildrenDeep.folder__folders.find(el => el.child_folder === currentFolder.id).parent_folder
            const parentFolder = menuChildrenDeep.folders.find(folder => folder.id === parentFolderId);
            if (matchesTree[parentFolderId]) {
                if (!matchesTree[parentFolderId].includes(currentFolder)) {
                    matchesTree[parentFolderId] = [...matchesTree[parentFolderId], currentFolder]
                }
            } else {
                matchesTree[parentFolderId] = [currentFolder]
            }
            currentFolder = parentFolder;
        } while (currentFolder.folder_type !== menuFolderType)
    });


    const menuChildren = matchesTree[menu.id];

    const findRecursivelyChildren = (folder: IFolder): ISearchResult[] => {
        if (matchesTree[folder.id]) {
            return matchesTree[folder.id].map(childFolder => ({
                id: childFolder.id,
                folderFolderId: findFolderFolderId(menuChildrenDeep.folder__folders, menu?.id, childFolder.id),
                title: childFolder.name,
                type: childFolder.folder_type,
                children: findRecursivelyChildren(childFolder)
            }));
        } else {
            return [];
        }
    }

    const result: ISearchResult[] = menuChildren.map(menuChild => ({
        id: menuChild.id,
        folderFolderId: findFolderFolderId(menuChildrenDeep.folder__folders, menu?.id, menuChild.id),
        title: menuChild.name,
        type: menuChild.folder_type,
        children: findRecursivelyChildren(menuChild)
    }));

    return { matches: searchMatches.length, results: result };
}
