import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";

export const eugene_stories_shared = {
  tabs: {
    general: {
      title: "General"
    }
    // formatting: {
    //   title: "Formatting",
    // },
    // layout: {
    //   title: "Layout",
    // },
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    size_options: {
      title: "Size Options",
      default_expanded: false,
      order: 1,
      accordion: true,
      tab: "formatting"
    }
    // subhead: {
    //   title: "Subhead",
    //   default_expanded: false,
    //   order: 2,
    //   accordion: true,
    //   tab: "formatting",
    // },
    // upper_horizontal_rule: {
    //   title: "Upper Horizontal Rule",
    //   default_expanded: false,
    //   order: 3,
    //   accordion: true,
    //   tab: "formatting",
    // },
    // list: {
    //   title: "List",
    //   default_expanded: false,
    //   order: 4,
    //   accordion: true,
    //   tab: "formatting",
    // },
    // lower_horizontal_rule: {
    //   title: "Lower Horizontal Rule",
    //   default_expanded: false,
    //   order: 5,
    //   accordion: true,
    //   tab: "formatting",
    // },
    // footer: {
    //   title: "Footer",
    //   default_expanded: false,
    //   order: 6,
    //   accordion: true,
    //   tab: "formatting",
    // },
    // layout_header: {
    //   title: "Header",
    //   default_expanded: false,
    //   order: 1,
    //   accordion: true,
    //   tab: "layout"
    // },
    // layout_subhead: {
    //   title: "Subhead",
    //   default_expanded: false,
    //   order: 2,
    //   accordion: true,
    //   tab: "layout"
    // },
    // layout_upper_rule: {
    //   title: "Upper Horizontal Rule",
    //   default_expanded: false,
    //   order: 3,
    //   accordion: true,
    //   tab: "layout",
    // },
    // layout_list: {
    //   title: "List",
    //   default_expanded: false,
    //   order: 4,
    //   accordion: true,
    //   tab: "layout"
    // },
    // layout_lower_rule: {
    //   title: "Lower Horizontal Rule",
    //   default_expanded: false,
    //   order: 5,
    //   accordion: true,
    //   tab: "layout",
    // },
    // layout_footer: {
    //   title: "Footer",
    //   default_expanded: false,
    //   order: 6,
    //   accordion: true,
    //   tab: "layout"
    // },
  },
  fields: {
    name: {
      type: "text",
      title: "Reference Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    description: {
      type: "textarea",
      title: "Description",
      default: "",
      read_only: true,
      show_char_count: false,
      settings: {
        minRows: 2,
        maxRows: 6
      },
      field_group: "general"
    },
    size_options: {
      type: "select",
      title: "Size Card Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "large",
      options: [
        {
          title: "Small",
          value: "small"
        },
        {
          title: "Medium",
          value: "medium"
        },
        {
          title: "Large",
          value: "large"
        }
      ],
      field_group: "general"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
    // upper_horizontal_rule_color: {
    //   type: "color",
    //   title: "Color",
    //   default: "",
    //   field_group: "upper_horizontal_rule",
    // },
    // upper_horizontal_rule_weight: {
    //   type: "number",
    //   title: "Line Weight",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0.01, max: 4}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.01",
    //   default: 4,
    //   field_group: "upper_horizontal_rule",
    // },
    // lower_horizontal_rule_color: {
    //   type: "color",
    //   title: "Color",
    //   default: "",
    //   field_group: "lower_horizontal_rule",
    // },
    // lower_horizontal_rule_weight: {
    //   type: "number",
    //   title: "Line Weight",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0.01, max: 20}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.01",
    //   default: 4,
    //   field_group: "lower_horizontal_rule",
    // },
    // header_offset_top: {
    //   type: "number",
    //   title: "Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_header",
    // },
    // header_margin_left: {
    //   type: "number",
    //   title: "Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_header",
    // },
    // header_margin_right: {
    //   type: "number",
    //   title: "Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_header",
    // },
    // subhead_offset_top: {
    //   type: "number",
    //   title: "Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 10,
    //   field_group: "layout_subhead",
    // },
    // subhead_margin_left: {
    //   type: "number",
    //   title: "Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_subhead",
    // },
    // subhead_margin_right: {
    //   type: "number",
    //   title: "Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_subhead",
    // },
    // upper_horizontal_rule_offset_top: {
    //   type: "number",
    //   title: "Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 20,
    //   field_group: "layout_upper_rule",
    // },
    // upper_horizontal_rule_margin_left: {
    //   type: "number",
    //   title: "Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_upper_rule",
    // },
    // upper_horizontal_rule_margin_right: {
    //   type: "number",
    //   title: "Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_upper_rule",
    // },
    // list_offset_top: {
    //   type: "number",
    //   title: "Donor List Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 20,
    //   field_group: "layout_list",
    // },
    // list_margin_left: {
    //   type: "number",
    //   title: "Donor List Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_list",
    // },
    // list_margin_right: {
    //   type: "number",
    //   title: "Donor List Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_list",
    // },
    // list_height: {
    //   type: "number",
    //   title: "Donor List Height",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 65,
    //   field_group: "layout_list",
    // },
    // lower_horizontal_rule_offset_top: {
    //   type: "number",
    //   title: "Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 88,
    //   field_group: "layout_lower_rule",
    // },
    // lower_horizontal_rule_margin_left: {
    //   type: "number",
    //   title: "Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_lower_rule",
    // },
    // lower_horizontal_rule_margin_right: {
    //   type: "number",
    //   title: "Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_lower_rule",
    // },
    // footer_offset_top: {
    //   type: "number",
    //   title: "Offset Top",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container height",
    //   step: "0.1",
    //   default: 90,
    //   field_group: "layout_footer",
    // },
    // footer_margin_left: {
    //   type: "number",
    //   title: "Margin Left",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_footer",
    // },
    // footer_margin_right: {
    //   type: "number",
    //   title: "Margin Right",
    //   validation: {rules: [{ name: "isRequired" }, {name: "isNumber", min: 0, max: 100}]},
    //   tooltip: "Unit is % of container width",
    //   step: "0.1",
    //   default: 5,
    //   field_group: "layout_footer",
    // },
  }
};
