import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setClientOptions } from "../../../../reducers/clientOptionsSlice";
import {
  getFolderStatus,
  updateFolder,
  updateUploadedResources,
  startFolderStatusPoll,
  stopFolderStatusPoll,
} from "../../../../actions/folderActions";
import { getNotifications } from "../../../../actions/notificationActions"; // PUT THIS IN A SHARED CONSTANTS ARREA
import {
  CONTAIN,
  FLEX_CENTER,
  NOTIFICATION_TYPE_ALL,
} from "../../../../shared/constants";
import { arrayFromKeyedObject } from "../../../../shared/utils/collectionUtils";
import {
  CHUNK_SIZE,
  getCroppedImageFromFile,
  getCroppedImageFromImage,
  getMd5FromFile,
  getOriginalImageFromS3,
  isResourceMediaFile,
} from "../DynamicFileInput/FileInputService";
import {
  MEDIA_FILE_INPUT_STATE_AVAILABLE,
  MEDIA_FILE_INPUT_STATE_LOADED,
} from "../DynamicFileInput/MediaFileInput";
import ImageCropper from "../ImageCropper";
import ContentUploader, {
  STATE_ADD_ONLY_ONE,
  STATE_REPLACE,
  STATE_START,
} from "./ContentUploader";
import ContentView from "./ContentView";
import { getRandomStr } from "../../../../shared/utils/generalUtils";
import {
  callNotifications,
  checkIfFolderStatusInProgress,
} from "../../../../services/notificationService";
import { imageMimeTypes } from "../../../../shared/utils/fileUtils";
export const MODE_UPLOAD = "upload";
export const MODE_VIEW = "view";
export const MODE_CROP = "crop";
export const MODE_SCROLL = "scroll";

export const DEFAULT_CONTENT = {
  imgCover: CONTAIN,
  flexClass: FLEX_CENTER,
  aspect: 1.333, // 4/3
};

export const contMaxHeight = 370;
export const contMaxWidth = 730;

const calcPreviewDimensions = (aspect) => {
  // to quickly debug different container aspects, otherwise from folder_type.container.aspect
  // container.aspect = 3
  const contMaxAspect = contMaxWidth / contMaxHeight;

  let contWidth;
  let contHeight;

  if (aspect < contMaxAspect) {
    // will be height constrained
    contHeight = contMaxHeight;
    contWidth = contHeight * aspect;
  } else {
    // will be width constrained
    contWidth = contMaxWidth;
    contHeight = (1 / aspect) * contWidth;
  }

  // find scaling that works for width and scaling that works for height and take the minimum
  return {
    contWidth,
    contHeight,
  };
};

class ContentManager extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    defaultValue: PropTypes.array.isRequired,
    acceptable: PropTypes.array,
    handleAddMultipleFiles: PropTypes.func,
    limit: PropTypes.number,
    close: PropTypes.func.isRequired,
    closeForced: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    updateItems: PropTypes.func.isRequired,
    singleItem: PropTypes.object,
    updateTouch: PropTypes.func.isRequired,
    onCancelCallback: PropTypes.func.isRequired,
    selectedFolderFromWidget: PropTypes.object,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    // let previewDims = this.calcPreviewDimensions();
    this.state = {
      mode: MODE_UPLOAD,
      uploadMode: STATE_START,
      currentImage: null,
      editItem: null,
      itemsInitialState: [],
      defaultValueInitialState: [],
    };
  }

  componentDidMount() {
    this._componentIsMounted = true;
    const { singleItem, items, defaultValue, config, limit } = this.props;

    if (items) {
      this.setState({ itemsInitialState: [...items] });
    }
    if (defaultValue) {
      const defaultValueClone = this.cloneDefaultFalue(defaultValue);

      this.setState({
        defaultValueInitialState: defaultValueClone,
      });
    }
    if (singleItem) {
      this.updateSingleItemIfCan(false);
    }
    if (limit && limit === 1) {
      this.setState({
        uploadMode: STATE_ADD_ONLY_ONE,
      });
    }
    if (config) {
      const aspect = config.aspect ? config.aspect : DEFAULT_CONTENT.aspect;
      if (aspect) {
        const previewDims = calcPreviewDimensions(aspect);
        this.setState({
          contWidth: previewDims.contWidth,
          contHeight: previewDims.contHeight,
        });
      }
    }
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  cloneDefaultFalue(outsideDefaultValue) {
    const defaultValueClone = _.cloneDeep(outsideDefaultValue);
    if (outsideDefaultValue && outsideDefaultValue.file) {
      const newFile = new File(
        [outsideDefaultValue],
        outsideDefaultValue.name,
        { type: outsideDefaultValue.type }
      );
      defaultValueClone.file = newFile;
    }
    return defaultValueClone;
  }

  getNoRemovedFromDefaultValue = () => {
    const { defaultValue } = this.props;
    return defaultValue.filter((v) => v.status !== "removed");
  };

  componentDidUpdate(prevProps) {
    const { singleItem, currentIndex, defaultValue, items, config } =
      this.props;
    const { tempItems, editItem } = this.state;

    if (prevProps.singleItem !== singleItem) {
      this.updateSingleItemIfCan();
    }

    if (!editItem) {
      this.updateSingleItemIfCan();
    }

    if (prevProps.currentIndex !== currentIndex) {
      if (currentIndex >= 0) {
        const newCurrentItem = tempItems[currentIndex];
        this.setState({
          currentItem: newCurrentItem,
        });
      }
    }

    if (prevProps.defaultValue !== defaultValue) {
    }

    // TODO for now i take the value of items when it change in parent component
    // in the final version it should be handle locally in tempItems
    if (prevProps.items !== items) {
      this.setState({
        tempItems: items,
      });

      if (items && items.length > 0) {
        const newIndex = currentIndex <= items.length - 1 ? currentIndex : 0;
        const newCurrentItem = items[newIndex];
        this.setState({
          currentIndex: newIndex,
          currentItem: newCurrentItem,
        });
        this.updateImageInState(newCurrentItem);
      }

      this.updateSingleItemIfCan();
    }

    if (prevProps.config !== config) {
      const aspect = config.aspect ? config.aspect : DEFAULT_CONTENT.aspect;
      if (aspect) {
        const previewDims = calcPreviewDimensions(aspect);
        this.setState({
          contWidth: previewDims.contWidth,
          contHeight: previewDims.contHeight,
        });
      }
    }
  }

  updateSingleItemIfCan = (allowUpdateTouch) => {
    const { singleItem, items, updateTouch } = this.props;
    // const {items} = this.state;

    if (singleItem && items && items.length > 0) {
      if (allowUpdateTouch === true) {
        updateTouch(true);
      }

      this.handleSingleItem(items, singleItem);
    }
  };

  changeMode = (mode) => {
    this.setState({ mode });
  };

  getSelectedFolderId = (selectedFolder, client_options) => {
    let folderId = client_options.applied_content_selected_folder;
    if (selectedFolder && selectedFolder.id) {
      folderId = selectedFolder.id;
    }
    return folderId;
  };

  getCropFromFolderResource = (image) => {
    const { client_options, resource__folders, selectedFolderFromWidget } =
      this.props;
    let crop = null;
    const resourceId = image.resource;
    let folderId = this.getSelectedFolderId(
      selectedFolderFromWidget,
      client_options
    );

    let resourceFolder = arrayFromKeyedObject(resource__folders).find(
      (rf) => rf.folder === Number(folderId) && rf.resource === resourceId
    );

    if (
      resourceFolder &&
      resourceFolder.contents &&
      resourceFolder.contents.crop
    ) {
      crop = {
        crop: resourceFolder.contents.crop,
        cropOriginal: resourceFolder.contents.cropOriginal,
        content_manager_dark_mode: resourceFolder.contents
          .content_manager_dark_mode
          ? resourceFolder.contents.content_manager_dark_mode
          : false,
      };
    }
    return crop;
  };

  updateImageInState = (image) => {
    if (image) {
      this.setState({ currentImage: image });
    }
  };

  handleSingleItem = (innerItems, item) => {
    if (innerItems && innerItems.length > 0 && item) {
      // update current item
      const indexInnerItems = innerItems.findIndex(
        (i) => i.identifier === item.identifier
      );
      if (indexInnerItems >= 0) {
        this.goToImage(indexInnerItems);
      }
      this.setState({ editItem: item });
      this.changeMode(MODE_VIEW);
    }
  };

  findIndexDefaultValueByRank = (rank) => {
    const { defaultValue } = this.props;
    const indexDefaultValue = defaultValue.findIndex((v) => v.rank === rank);
    return indexDefaultValue;
  };

  findIndexDefaultValueByIdentifier = (identifier) => {
    const { defaultValue } = this.props;
    const indexDefaultValue = defaultValue.findIndex(
      (v) => v.identifier === identifier
    );
    return indexDefaultValue;
  };

  updateImageInStateByRank = (rank) => {
    const { defaultValue } = this.props;

    const indexDefaultValue = this.findIndexDefaultValueByRank(rank);
    if (indexDefaultValue >= 0) {
      // modify defaultValues without change array pointer
      const foundedImage = defaultValue[indexDefaultValue];
      this.updateImageInState(foundedImage);
    }
  };

  /**
   * The index is the index in the images array
   * Do not use index to access defaultValue because in that array also exist removed items
   */
  getMergedImageFromItemAndDefaultItem = (index) => {
    const { items, defaultValue } = this.props;
    const newCurrentItem = items[index];
    const defaultValueIndex = this.findIndexDefaultValueByRank(
      newCurrentItem.rank
    );
    const newCurrentDefaultValue = defaultValue[defaultValueIndex];

    let newCurrentImage = {
      ...newCurrentItem,
      ...newCurrentDefaultValue,
    };

    return newCurrentImage;
  };

  findItemIndexByRank = (rank) => {
    const { items } = this.props;
    const index = items.findIndex((v) => v.rank === rank);
    return index;
  };

  getMergedImageFromItemAndDefaultItemByRank = (rank) => {
    const { items, defaultValue } = this.props;

    const itemIndex = this.findItemIndexByRank(rank);
    const newCurrentItem = items[itemIndex];
    const defaultValueIndex = this.findIndexDefaultValueByRank(rank);
    const newCurrentDefaultValue = defaultValue[defaultValueIndex];

    let newCurrentImage = {
      ...newCurrentItem,
      ...newCurrentDefaultValue,
    };

    return newCurrentImage;
  };

  getCurrentImage = () => {
    const { currentImage } = this.state;

    if (currentImage) {
      const newCurrentImage = this.getMergedImageFromItemAndDefaultItemByRank(
        currentImage.rank
      );
      return newCurrentImage;
    }
    return currentImage;
  };

  goToImage = (newIndex) => {
    const noRemovedDefaultValue = this.getNoRemovedFromDefaultValue();
    if (noRemovedDefaultValue && noRemovedDefaultValue.length > 0) {
      const newCurrentImage =
        this.getMergedImageFromItemAndDefaultItem(newIndex);
      this.updateImageInState(newCurrentImage);
    } else {
      // no items
      this.setState({ currentImage: null });
    }
  };

  onHandleAddMultipleFiles = async (files, type) => {
    const { handleAddMultipleFiles, updateTouch } = this.props;
    const cantImagesBeforeAddNew = this.getNoRemovedFromDefaultValue();
    await handleAddMultipleFiles(files, type);
    updateTouch(true);
    const nextImage =
      cantImagesBeforeAddNew && cantImagesBeforeAddNew.length
        ? cantImagesBeforeAddNew.length
        : 0;
    this.goToImage(nextImage);
    this.changeMode(MODE_VIEW);
  };

  goToView = () => {
    this.changeMode(MODE_VIEW);
  };

  onCrop = async (rank, crop) => {
    // if the image came from s3 and it is recroped
    // re convert image to file and recrop it
    const { defaultValue, onChange, resources, aws_instance, updateTouch } =
      this.props;
    let isEditingInMemory;
    const index = defaultValue.findIndex((v) => v.rank === rank);
    const foundItem = defaultValue[index];
    if (!foundItem) {
      return;
    }

    // check if the image is cropped
    isEditingInMemory = true;
    const originalImageS3 = getOriginalImageFromS3(
      foundItem,
      resources,
      aws_instance
    );
    let base64Crop;
    if (originalImageS3) {
      base64Crop = await getCroppedImageFromImage(originalImageS3, crop.crop);
    } else {
      const { file } = foundItem;
      base64Crop = await getCroppedImageFromFile(file, crop.crop);
    }

    let selected_from_tree = false;
    if (
      foundItem.selected_from_tree !== undefined &&
      foundItem.selected_from_tree !== null
    ) {
      selected_from_tree = foundItem.selected_from_tree;
    }

    const replaceFieldItem = {
      isEditingInMemory,
      status: "loaded",
      type: foundItem.type === "file" ? "file" : "resource", // existent resources do not have this property
      selected_from_tree,
      resourceUrl: originalImageS3,
      thumb: base64Crop,
      ...crop,
    };

    const innerCurrentItem = this.getCurrentImage();

    if (innerCurrentItem.identifier) {
      await onChange(
        innerCurrentItem.identifier,
        "",
        replaceFieldItem,
        "replace"
      );
    }

    this.setState({
      currentImage: {
        ...innerCurrentItem,
        ...replaceFieldItem,
      },
    });
    updateTouch(true);
  };

  onClickUpload = (uploadMode = STATE_REPLACE) => {
    this.changeMode(MODE_UPLOAD);
    this.setState({
      uploadMode: uploadMode,
    });
  };

  buildReplaceFile = async (_currentImage, file) => {
    const chunks = [];
    for (let start = 0; start < file.size; start += CHUNK_SIZE) {
      let end = start + CHUNK_SIZE;
      if (end > file.size) {
        end = file.size;
      }
      chunks.push(file.slice(start, end));
    }

    const md5 = await getMd5FromFile(file);

    const replaceItem = {
      file,
      size: file.size,
      contents: {},
      crop: null,
      cropOriginal: null,
      thumb: null,
      status: "loaded",
      type: "file",
      selected_from_tree: false,
      isEditingInMemory: true,
      originalName: file.name,
      modifiedName: file.name,
      fileName: file.name,
      chunks,
      randomFileName: getRandomStr(),
      resourceUrl: null,
      url: null,
      md5,
    };

    return replaceItem;
  };

  onHandleReplaceFile = async (file, type = "replace") => {
    const { onChange, updateTouch } = this.props;
    const { currentImage } = this.state;
    let replaceItem = {};
    if (type === "replace") {
      replaceItem = await this.buildReplaceFile(currentImage, file);
    } else {
      replaceItem = { ...file, type: "resource" };
    }
    let identifier;
    let indexDefaultValue = 0;
    if (currentImage) {
      indexDefaultValue = this.findItemIndexByRank(currentImage.rank);
      const newCurrentImage = this.getMergedImageFromItemAndDefaultItemByRank(
        currentImage.rank
      );
      replaceItem.fieldName = currentImage.fieldName;
      identifier = newCurrentImage.identifier;

      this.setState({
        currentImage: {
          ...newCurrentImage,
          ...replaceItem,
        },
      });
    }

    await onChange(identifier, "", replaceItem, type);
    updateTouch(true);
    this.goToImage(indexDefaultValue);
    this.changeMode(MODE_VIEW);
  };

  onSuccessCloseModal = () => {
    const { closeForced } = this.props;
    closeForced();
  };

  onFail = () => {};

  fetchFolderStatus = () => {
    const { user } = this.props;
    const { getNotifications } = this.props;

    if (user && user.company) {
      callNotifications(user.company, NOTIFICATION_TYPE_ALL, getNotifications);
    }
  };

  pollFolderStatus = () => {
    const { user } = this.props;
    this.props.startFolderStatusPoll(user.company);
  };

  getCropInformationFromResourceFolder = (image) => {
    const cropFromMemory = image.crop;
    const cropFromResourceFolder = this.getCropFromFolderResource(image);
    const crop = image.isEditingInMemory
      ? cropFromMemory
      : cropFromResourceFolder;
    return crop || {};
  };

  getCropInformation = (image) => {
    return {
      crop: image.crop ? image.crop : null,
      cropOriginal: image.cropOriginal ? image.cropOriginal : null,
      content_manager_dark_mode: image.content_manager_dark_mode
        ? image.content_manager_dark_mode
        : false,
    };
  };

  onClickCrop = () => {
    const { resources, aws_instance, defaultValue } = this.props;
    const { currentImage } = this.state;
    let newCurrentImage = null;
    const { status } = currentImage;
    if (isResourceMediaFile(currentImage)) {
      const { url } = currentImage;
      const crop = this.getCropInformation(currentImage);
      newCurrentImage = {
        ...currentImage,
        url,
        resourceUrl: url,
        crop: crop.crop,
        cropOriginal: crop.cropOriginal,
        content_manager_dark_mode: crop.content_manager_dark_mode
          ? crop.content_manager_dark_mode
          : false,
      };
    } else if (status === MEDIA_FILE_INPUT_STATE_AVAILABLE) {
      // load original image
      const originalImageS3 = getOriginalImageFromS3(
        currentImage,
        resources,
        aws_instance
      );
      const crop = this.getCropInformationFromResourceFolder(currentImage);
      newCurrentImage = {
        ...currentImage,
        url: originalImageS3,
        resourceUrl: originalImageS3,
        crop: crop.crop,
        cropOriginal: crop.cropOriginal,
        content_manager_dark_mode: crop.content_manager_dark_mode
          ? crop.content_manager_dark_mode
          : false,
      };
    } else if (status === MEDIA_FILE_INPUT_STATE_LOADED) {
      // update crop information into current image if exist in tempDefaultValue
      const currentIndex = this.findIndexDefaultValueByRank(currentImage.rank);
      const tempImage = defaultValue[currentIndex];
      const crop = this.getCropInformation(tempImage);
      if (tempImage) {
        newCurrentImage = {
          ...tempImage,
          crop: crop.crop,
          cropOriginal: crop.cropOriginal,
          content_manager_dark_mode: crop.content_manager_dark_mode
            ? crop.content_manager_dark_mode
            : false,
        };
      }
    }
    if (newCurrentImage) {
      this.setState({ currentImage: newCurrentImage });
    }
    this.changeMode(MODE_CROP);
  };

  onCancel = () => {
    const { close, onCancelCallback } = this.props;

    close(() => {
      this.setState({ currentImage: null });
      onCancelCallback();
    });
  };

  getTitle = () => {
    const { title, selected_folder } = this.props;
    let myTitle =
      selected_folder && selected_folder.name ? selected_folder.name : "";
    if (title) {
      myTitle = title;
    }
    return myTitle;
  };

  render() {
    const {
      acceptable,
      config,
      aws_instance,
      resources,
      resource__folders,
      limit,
      items,
      defaultValue,
      client_options,
      selectedFolderFromWidget,
      user,
    } = this.props;
    const {
      close,
      updateFolder,
      updateUploadedResources,
      onChangeAllValues,
      setClientOptions,
      updateItems,
      updateTouch,
      closeForced,
    } = this.props;
    const {
      editItem,
      mode,
      currentImage,
      uploadMode,
      contWidth,
      contHeight,
      tempDefaultValue,
    } = this.state;

    const title = this.getTitle();
    if (mode === "view") {
      return (
        <>
          <ContentView
            editItem={editItem}
            items={items}
            defaultValue={defaultValue}
            currentImage={currentImage}
            acceptable={[imageMimeTypes, "video/*"]}
            config={config}
            aws_instance={aws_instance}
            resources={resources}
            limit={limit}
            client_options={client_options}
            resource__folders={resource__folders}
            contWidth={contWidth}
            contHeight={contHeight}
            title={title}
            selectedFolderFromWidget={selectedFolderFromWidget}
            updateTouch={updateTouch}
            onClose={close}
            onCloseForced={closeForced}
            onCancel={this.onCancel}
            updateFolder={updateFolder}
            onClickUpload={this.onClickUpload}
            onClickCrop={this.onClickCrop}
            goToImage={this.goToImage}
            onChangeAllValues={onChangeAllValues}
            updateUploadedResources={updateUploadedResources}
            fetchFolderStatus={this.pollFolderStatus}
            updateCurrentImage={(currentImage) =>
              this.setState({ currentImage })
            }
            setClientOptions={setClientOptions}
            updateItems={updateItems}
          />
        </>
      );
    }

    if (mode === MODE_CROP) {
      return (
        <>
          <ImageCropper
            key={currentImage.identifier}
            aspect={_.get(this.props, "config.aspect", 1.33)}
            contWidth={contWidth}
            contHeight={contHeight}
            changeMode={this.changeMode}
            image={currentImage}
            onCrop={this.onCrop}
          />
        </>
      );
    }

    if (mode === MODE_SCROLL) {
      return <>{/* <ScrollTest /> */}</>;
    }

    if (mode === MODE_UPLOAD) {
      return (
        <>
          <ContentUploader
            browseContentLibrary
            acceptable={[imageMimeTypes, "video/*"]}
            newMode={uploadMode}
            resources={resources}
            user={user}
            aws_instance={aws_instance}
            onChange={this.onHandleReplaceFile}
            onAdd={this.onHandleAddMultipleFiles}
            onClose={close}
            goBack={this.goToView}
            folders={this.props.folders}
            resource__folders={this.props.resource__folders}
            client_options={this.props.client_options}
            setClientOptions={this.props.setClientOptions}
            cancelButton={true}
          />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const client_options = state.client_options;
  const { user } = state.data;
  let selected_folder = null;
  let folder_type = null;
  if (state.client_options.applied_content_selected_folder) {
    selected_folder =
      state.data.folders[state.client_options.applied_content_selected_folder];
    if (!selected_folder) {
      return {
        render: false,
      };
    }
  }
  folder_type = state.data.folder_types[selected_folder.folder_type];
  return {
    user,
    client_options,
    aws_instance: state.data.aws_instance,
    folder_type,
    resources: state.data.resources,
    resource__folders: state.data.resource__folders,
    selected_folder,
    folders: state.data.folders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFolderStatus: (company_id, onSuccess, onFail) => {
      dispatch(getFolderStatus(company_id, onSuccess, onFail));
    },
    getNotifications: (company_id, type, onSuccess, onFail) => {
      dispatch(getNotifications(company_id, type, onSuccess, onFail));
    },
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
    updateUploadedResources: (fileNames, folderId, onSuccess) => {
      dispatch(updateUploadedResources(fileNames, folderId, onSuccess));
    },
    updateFolder: (id, name, data, path, onSuccess, onFail) => {
      dispatch(
        updateFolder(id, name, data, path, onSuccess, onFail, true, true)
      );
    },
    startFolderStatusPoll: (companyId, onSuccess = null, onFail = null) => {
      dispatch(startFolderStatusPoll(companyId, onSuccess, onFail));
    },
    stopFolderStatusPoll: () => {
      dispatch(stopFolderStatusPoll());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentManager);

// export default ContentManager;
