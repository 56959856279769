import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row } from "antd";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { LT_GRAY_BLUE } from "../../layout/LayoutConstants";
import ColorPickerTool from "../../utils/ColorPickerTool";
import {createUseStyles} from "react-jss";
import Plus from '../../../icons/plus.svg'
import Edit from "../../../icons/edit.svg";
import EditDark from "../../../icons/edit-dark.svg";

interface ColorAndTransparencyInputProps {
  fieldName: string;
  defaultValue: string;
  title: string;
  onChange: any;
  onBlur: any;
  canUpdate: boolean;
}

const styles = {
  colorContainer: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column",
    alignItems: 'center',
    borderRadius: "10px",
    backgroundColor: (props) => props.selectedColor,
    border: (props) => props.selectedColor ? '1px solid #808080' : '',
  }
}

const useStyles = createUseStyles(styles);

const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {
  try {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
  } catch (e) {
    return lightColor;
  }
};

const LIGHT_COLOR = "#ffffff";
const DARK_COLOR = "#000000";
const DISABLED_COLOR_BG = "#f5f5f5";
const DISABLED_COLOR = "#00000040";

const ColorAndTransparencyInput: React.FC<ColorAndTransparencyInputProps> = (
  props
) => {
  const [initialColor, setInitialColor] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [palletIconColor, setPalletIconColor] = useState("#ffffff");
  const [extraClass, setExtraClass] = useState("");

  const classes = useStyles({
    selectedColor
  });

  useEffect(() => {
    setInitialColor(props.defaultValue);
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      setSelectedColor(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    let palletColor;
    if (props.canUpdate) {
      palletColor = pickTextColorBasedOnBgColorSimple(
        selectedColor,
        LIGHT_COLOR,
        DARK_COLOR
      );
    } else {
      palletColor = DISABLED_COLOR;
    }
    setPalletIconColor(palletColor);
  }, [selectedColor]);

  useEffect(() => {
    const extraDisabledClass = !props.canUpdate
      ? "form-input-color_inner--disabled"
      : "";
    setExtraClass(extraDisabledClass);
  }, [props.canUpdate]);

  const convertHexToHexWithAlpha = (color) => {
    if (!color || !color.color) {
      return "";
    }
    let hexWithAlpha = color.color;
    if (color && color.alpha !== null && color.alpha !== undefined) {
      let alpha = Math.round((color.alpha / 100) * 255).toString(16);
      const hex = color.color;
      if (alpha.length < 2) {
        alpha = "0" + alpha;
      }
      hexWithAlpha = hex + alpha;
    }
    return hexWithAlpha;
  };

  const contrastWithWhite = (hexColor) => {
    const pickerColor = hexColor.replace("#", "");
    const r = parseInt(pickerColor.substring(0, 2), 16);
    const g = parseInt(pickerColor.substring(2, 4), 16);
    const b = parseInt(pickerColor.substring(4, 6), 16);
    const luma = (0.299 * (r / 100)) + (0.587 * (g / 100)) + (0.114 * (b / 100));
    return luma < 0.8;
  }

  const getIcon = () => {
    if (selectedColor) {
      if (contrastWithWhite(selectedColor)) {
        return (
          <img src={Edit} alt="Plus" style={{zIndex: 2, width: '25%', height: '25%'}}/>
        )
      } else {
        return (
          <img src={EditDark} alt="Plus" style={{zIndex: 2, width: '25%', height: '25%'}}/>
        )
      }
    } else {
      return (
        <img src={Plus} alt="Plus" style={{zIndex: 2, width: '25%', height: '25%'}}/>
      )
    }

  }

  const removeColor = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const noColor = "";
    setInitialColor(noColor);
    setSelectedColor(noColor);
    props.onChange(props.fieldName, noColor);
    props.onBlur(props.fieldName, noColor);
  };

  return (
    <React.Fragment>
      <Row>
        <label className="form-input-label">{props.title}</label>
      </Row>

      <Row>
        <ColorPickerTool
          value={selectedColor}
          onChange={(c) => {
            setSelectedColor(convertHexToHexWithAlpha(c));
            props.onChange(props.fieldName, convertHexToHexWithAlpha(c));
            props.onBlur(props.fieldName, convertHexToHexWithAlpha(c));
          }}
          swatches={get(props, "settings.swatches", [])}
          onClose={(c) => {
            props.onChange(props.fieldName, convertHexToHexWithAlpha(c));
          }}
        >
          <div className="form-input-color_container form-input-color_container-button">
            <div className="form-input-color_outer">
              <div className={`form-input-color_inner ${extraClass}`}>
                <div className={classes.colorContainer}>
                  {
                    !selectedColor && (
                      <img alt={"select color"} src="/images/icons/bg_color_input.png" style={{
                        position: 'absolute',
                        zIndex: 1,
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}/>
                    )
                  }
                  {
                    getIcon()
                  }
                </div>
              </div>
            </div>
            {selectedColor ? (
              <div className="form-input-color_outer-overlay">
                <span
                  className="form-input-color_outer-overlay-button"
                  onClick={removeColor}
                >
                  <FontAwesomeIcon
                    className="fa"
                    style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                    icon={["far", "times-circle"]}
                  />
                </span>
              </div>
            ) : null}
          </div>
        </ColorPickerTool>
      </Row>
    </React.Fragment>
  );
};

export default ColorAndTransparencyInput;
