import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import {
  IURIAccess,
  URI_ACCESS_TYPE
} from "../../shared-global/interfaces/models/uri_access.interface";
import SelectInput from "../form/input/SelectInput";

interface IURISettingsModalProps {
  open: boolean;
  selectedURIAccess: IURIAccess;
  handleClose: Function;
  selectedFolderId: number | null;
  handleOkay: Function;
}

const URISettingsModal: React.FC<IURISettingsModalProps> = (props) => {
  const [uriMode, setUriMode] = useState(
    get(props, "selectedURIAccess.access_type", URI_ACCESS_TYPE.NONE)
  );
  const [identifier, setIdentifier] = useState(
    get(props, "selectedURIAccess.identifier", URI_ACCESS_TYPE.NONE)
  );
  const [identifierError, setIdentifierError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    setURIAccessToState();
  }, [props.selectedURIAccess]);
  

  const setURIAccessToState =  () => {
    setUriMode(
      get(props, "selectedURIAccess.access_type", URI_ACCESS_TYPE.NONE)
    );
    setIdentifier(get(props, "selectedURIAccess.identifier", ""));
  };

  const validatePassword = (pass: string, confirmPass: string): boolean => {
    if (pass.length < 5) {
      setPasswordError("Mimimum length 5");
      setHasErrored(true);
      return false;
    } else if (pass !== confirmPass) {
      setPasswordError("Password confirmation does not match");
      setHasErrored(true);
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateIdentifier = (ident: string): boolean => {
    if (ident.length < 5) {
      setIdentifierError("Minimum length 5");
      return false;
    } else {
      setIdentifierError("");
      return true;
    }
  };

  const identifierInputChange = ({ target: { value } }: any) => {
    if (hasErrored) {
      validateIdentifier(value);
    }
    setIdentifier(value);
  };

  const passwordInputChange = ({ target: { value } }: any) => {
    if (hasErrored) {
      validatePassword(value, passwordConfirm);
    }
    setPassword(value);
  };

  const confirmPasswordInputChange = ({ target: { value } }: any) => {
    if (hasErrored) {
      validatePassword(password, value);
    }
    setPasswordConfirm(value);
  };

  const handleOkay = () => {
    const payload: any = {
      password: password,
      access_type: uriMode,
      identifier: identifier,
      isUpdate: false,
      folder: props.selectedFolderId,
    };
    if (props.selectedURIAccess) {
      payload.isUpdate = true;
      payload.id = props.selectedURIAccess.id;
    }

    const validIdentifier = validateIdentifier(identifier);
    let validPassword = true;

    if (uriMode === URI_ACCESS_TYPE.PASSWORD) {
      if (!validatePassword(password, passwordConfirm)) {
        validPassword = false;
      }
    }
    if (validIdentifier && validPassword) {
      props.handleOkay(payload);
    }
  };

  return (
    <>
      <Modal
        afterClose={() => {
          setPassword("");
          setPasswordConfirm("");
          setURIAccessToState();
        }}
        width={360}
        visible={props.open}
        onOk={() => handleOkay()}
        onCancel={() => props.handleClose()}
        title={<React.Fragment>Edit URL Access Settings</React.Fragment>}
      >
        <div style={{ marginTop: 16 }}>
          <SelectInput
            fieldName={"URIAccess"}
            title={"URL Access"}
            defaultValue={uriMode}
            onChange={(fieldName, value) => {
              setUriMode(value);
            }}
            onBlur={() => {}}
            options={[
              { title: "None", value: URI_ACCESS_TYPE.NONE },
              { title: "Public", value: URI_ACCESS_TYPE.PUBLIC },
              { title: "Password", value: URI_ACCESS_TYPE.PASSWORD }
              // { title: "User Limited", value: URI_ACCESS_TYPE.USER } // TODO implement for users
            ]}
            showLabel={true}
            canUpdate={true}
          />
        </div>
        <span>
          <label className="form-input-label">Identifier</label>
          <br />
        </span>
        <Input
          value={identifier}
          onChange={identifierInputChange}
          placeholder="Identifier"
        />
        {identifierError !== "" ? (
          <span style={{ color: "red" }}>{identifierError}</span>
        ) : null}
        {uriMode === URI_ACCESS_TYPE.PASSWORD ? (
          <div>
            <span>
              <label className="form-input-label">Set New Password</label>
              <br />
            </span>
            <Input.Password
              value={password}
              onChange={passwordInputChange}
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <Input.Password
              value={passwordConfirm}
              onChange={confirmPasswordInputChange}
              placeholder="Confirm Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {passwordError !== "" ? (
              <span style={{ color: "red" }}>{passwordError}</span>
            ) : null}
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default URISettingsModal;
