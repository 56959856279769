import { createContext } from "react";
import { IDisplayComponentProps } from "./interfaces/display-component-props.interface";

export const PreviewContext = createContext<IDisplayComponentProps>({
  containerWidth: 0,
  containerHeight: 0,
  inheritable_fields: [],
  data: {
    base_folder: 0,
    resource__folders: [],
    resources: []
  }
});

export const MobilePreviewContext = createContext<IDisplayComponentProps>({
  containerWidth: 0,
  containerHeight: 0,
  inheritable_fields: [],
  data: {
    base_folder: 0,
    resource__folders: [],
    resources: []
  }
});