
export default {
  field_groups: {
    default: {
      title: 'New Folder',
      default_expanded: true,
    },
  },
  fields: {
    name: {
      type: 'text',
      title: 'Name',
      validation: { rules: [{ name: 'isRequired' }] }
    }
  }
};



