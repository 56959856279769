import { array, object, string } from 'yup';

export const mainSearchValidationSchema = object().shape({
  search: string(),
  folder_categories: array().of(string())
});


export const searchTagsValidationSchema = object().shape({
  search: string(),
});

export const fullIndexSearchValidationSchema = object().shape({
  simple_api_key: string().required(),
});
