import { TRANSITION_CLASS } from "../enums/ui-enums";

const transitions = [
  {
    title: "Crossfade",
    value: TRANSITION_CLASS.CROSSFADE,
    fade_in_duration: 500,
    fade_out_duration: 500
  },
  {
    title: "Crossfade (250 ms)",
    value: TRANSITION_CLASS.CROSSFADE_250MS,
    fade_in_duration: 250,
    fade_out_duration: 250
  },
  {
    title: "Crossfade (2 s)",
    value: TRANSITION_CLASS.CROSSFADE_2S,
    fade_in_duration: 2000,
    fade_out_duration: 2000
  },
  {
    title: "Dissolve",
    value: TRANSITION_CLASS.DISSOLVE,
    fade_in_duration: 0,
    fade_out_duration: 2250
  },
  {
    title: "Fast in / Slow out",
    value: TRANSITION_CLASS.FASTINSLOWOUT,
    fade_in_duration: 10,
    fade_out_duration: 500
  },
  {
    title: "Slide in bottom",
    value: TRANSITION_CLASS.SLIDE_IN_FROM_BOTTOM,
    fade_in_duration: 500,
    fade_out_duration: 1000
  },
  {
    title: "Slide in left",
    value: TRANSITION_CLASS.SLIDE_IN_FROM_LEFT,
    fade_in_duration: 1000,
    fade_out_duration: 1000
  },
  {
    title: "Slide in right",
    value: TRANSITION_CLASS.SLIDE_IN_FROM_RIGHT,
    fade_in_duration: 1000,
    fade_out_duration: 1000
  },
  {
    title: "Slide in top",
    value: TRANSITION_CLASS.SLIDE_IN_FROM_TOP,
    fade_in_duration: 500,
    fade_out_duration: 1000
  },
  {
    title: "Slow Fade In / Fast Fade Out",
    value: TRANSITION_CLASS.SLOWINFASTOUT,
    fade_in_duration: 500,
    fade_out_duration: 10
  },
  {
    title: "Fade 500ms 1s",
    value: TRANSITION_CLASS.TRANSITION_FADE500MS1S,
    fade_in_duration: 500,
    fade_out_duration: 1000
  },
  {
    title: "Fade Out",
    value: TRANSITION_CLASS.TRANSITION_FADEOUT,
    fade_in_duration: 0,
    fade_out_duration: 500
  },
  {
    title: "Fade (1 s)",
    value: TRANSITION_CLASS.TRANSITION_FADE_1S,
    fade_in_duration: 1000,
    fade_out_duration: 1000
  },
  {
    title: "Fade (2 s)",
    value: TRANSITION_CLASS.TRANSITION_FADE_2S,
    fade_in_duration: 2000,
    fade_out_duration: 2000
  },
  {
    title: "Fade",
    value: TRANSITION_CLASS.TRASITION_FADE,
    fade_in_duration: 500,
    fade_out_duration: 500
  }
];

export default transitions;
