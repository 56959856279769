import React, { Component, CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TRANSITION_CLASS } from "../../../shared-global/enums/ui-enums";

interface IDonorProps {
  ref?: any;
  name: string;
  style: CSSProperties;
}

const DonorSeaName: React.FC<IDonorProps> = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleVisibility(status) {
      setIsVisible(status);
    }
  }));

  const cssTransitionKey = isVisible ? "true" : "false";
  return (
    <TransitionGroup>
      <CSSTransition
        key={cssTransitionKey}
        timeout={1000}
        classNames={TRANSITION_CLASS.TRASITION_FADE}
      >
        <div key={cssTransitionKey}>
          {isVisible ? (
            <span
              style={{ position: 'absolute', ...props.style, whiteSpace: "pre-wrap" }}
            >
              {props.name}
            </span>
          ) : null}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default DonorSeaName;
