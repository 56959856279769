import React, { Component } from 'react';
import { Link } from "react-router-dom";
import LayoutConstants from '../layout/LayoutConstants';
//import { getDashboard } from './sharedUtils';

const NavLink = props => (
  <Link {...props} />
);

class Breadcrumbs extends Component {
  prepareCrumbs = () => {
    return (
      this.props.crumbs.map((crumb, index) => {
        return (
          crumb.active?
          <span key={index}><NavLink to={crumb.link}><span className="" key={index}>{crumb.name}</span></NavLink>{index<this.props.crumbs.length - 1?<span>&nbsp; {">"} &nbsp;</span>:null}</span>
          :
          <span key={index}>{crumb.name}{index<this.props.crumbs.length - 1?<span>&nbsp; {">"} &nbsp;</span>:null}</span>
        )
      })
    )

  }

  render() {
    let renderedCrumbs = this.prepareCrumbs();
    return (

      <span className="bread-crumbs" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div style={{ marginLeft: LayoutConstants().dashboard.section_margin * 2 }}>
          {renderedCrumbs}
        </div>
      </span>
    );
  }
}

export default Breadcrumbs;