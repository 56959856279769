
export default {
  field_groups: {
    default: {
      title: 'Company Name:',
      default_expanded: true,
    }
  },
  fields: {
    name: {
      type: 'text',
      title: 'Project Name:',
      validation: { rules: [{ name: 'isRequired' }] },
      //default: ''
    },
    template: {
      type: 'select',
      title: "Theme",
      validation: {
        rules: [
          { name: 'isRequired' }
        ]
      },
      options: []
    },
    location: {
      type: 'select',
      title: "Location",
      validation: {
        rules: [{ name: 'isRequired' }]
      },
      options: []
    },
    yellow_alert_minutes: {
      title: 'Minutes until yellow alert is emitted',
      type: 'integer',
      validation: {rules: [{name: 'isRequired'}]},
      default: 15,
    },
    red_alert_minutes: {
      title: 'Minutes until red alert is emitted',
      type: 'integer',
      validation: { rules: [{ name: 'isRequired' }] },
      default: 30,
    },
    notify_users: {
      title: 'Users to be notified',
      type: 'multiselect',
      validation: {},
      options: []
    },
    allow_show_cross: {
      title: 'Allow cross symbol',
      type: 'bool',
      default: false
    }
  }
};

