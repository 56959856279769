import React, { useEffect, useState, useCallback, useMemo } from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import Slide1911 from "../../standard/Slide1911";
import get from "lodash/get";

const SpringfieldPortraitTemplate001 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const imageFieldType = useMemo(() => {
    return get(baseFolderType, "fields[background]", null);
  }, [baseFolderType]);

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings?.data, "show_placeholder", false);
  }, [props.data]);

  return (
    <div style={{ position: "absolute", width: props.containerWidth, height: props.containerHeight }}>
      <Slide1911
        {...props}
        handleEndOfPlay={null}
        data={{
          ...props.data,
          slotNames: ["background"],
          placeholder: "Image",
          fieldType: imageFieldType,
          showPlaceholder: showPlaceholder,
          componentSpecificData: {
            ...props.data?.componentSpecificData,
            useKeyCycler: false,
          },
        }}
      />
    </div>
  );
};

export default SpringfieldPortraitTemplate001;
