import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { tree_icon_types } from "../tree_icon_types";

export const springfield_portrait_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.springfield_portrait_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.springfield_portrait_template_001,
    FOLDER_TYPE.springfield_portrait_template_002,
    FOLDER_TYPE.springfield_portrait_template_003,
    FOLDER_TYPE.springfield_portrait_template_004
  ],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 20,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.SpringfieldPortraitContainer,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.springfield_portrait_template_001,
      FOLDER_TYPE.springfield_portrait_template_002,
      FOLDER_TYPE.springfield_portrait_template_003,
      FOLDER_TYPE.springfield_portrait_template_004
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "headline",
        title: "Headline",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "image_1",
        title: "Media 1",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "image_2",
        title: "Media 2",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "background",
        title: "Background",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.springfield_portrait_template_001,
          FOLDER_TYPE.springfield_portrait_template_002,
          FOLDER_TYPE.springfield_portrait_template_003,
          FOLDER_TYPE.springfield_portrait_template_004
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 300,
    columns: 2
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
