import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const everett_donor_list_group: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.everett_donor_list_group,
  display_name: "Donor List Group",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/everett/folder.svg",
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 400,
    columns: 1
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 16 / 9,
    component_name: COMPONENT_NAME.Everett2x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "2x2", orientation: "landscape", horizontal: 2, vertical: 2 }]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false,
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "transition",
        title: "Transition",
        sort: true,
        editable: true,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  }
};
