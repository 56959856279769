import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { wysiwyg } from "../standardized_fields";
import { glenwoodFonts, quillFontSizes } from "./glenwood_settings";

export const glenwood_category_shared = {
  tabs: {
    general: {
      title: "General"
    },
    formatting: {
      title: "Formatting"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    header: {
      title: "Header",
      default_expanded: false,
      order: 1,
      accordion: true,
      tab: "formatting"
    },
    subhead: {
      title: "Subhead",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "formatting"
    },
    upper_horizontal_rule: {
      title: "Upper Horizontal Rule",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "formatting"
    },
    list: {
      title: "List",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "formatting"
    },
    lower_horizontal_rule: {
      title: "Lower Horizontal Rule",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "formatting"
    },
    footer: {
      title: "Footer",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "formatting"
    },
    layout_header: {
      title: "Header",
      default_expanded: false,
      order: 1,
      accordion: true,
      tab: "advanced"
    },
    layout_subhead: {
      title: "Subhead",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "advanced"
    },
    layout_upper_rule: {
      title: "Upper Horizontal Rule",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "advanced"
    },
    layout_list: {
      title: "List",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "advanced"
    },
    layout_lower_rule: {
      title: "Lower Horizontal Rule",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "advanced"
    },
    layout_footer: {
      title: "Footer",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    header_color: {
      type: "color",
      title: "Color",
      default: "#FFFFFF"
    },
    footer: {
      title: "Footer",
      field_group: "footer",
      type: "hybrid_text_input",
      show_char_count: false,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "18px",
            italic: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts,
        theme: "glenwood"
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    upper_horizontal_rule_color: {
      type: "color",
      title: "Color",
      default: "",
      field_group: "upper_horizontal_rule"
    },
    upper_horizontal_rule_weight: {
      type: "number",
      title: "Line Weight",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0.01, max: 4 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 4,
      field_group: "upper_horizontal_rule"
    },
    lower_horizontal_rule_color: {
      type: "color",
      title: "Color",
      default: "",
      field_group: "lower_horizontal_rule"
    },
    lower_horizontal_rule_weight: {
      type: "number",
      title: "Line Weight",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0.01, max: 20 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 4,
      field_group: "lower_horizontal_rule"
    },
    header_offset_top: {
      type: "number",
      title: "Offset Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 7.8,
      field_group: "layout_header"
    },
    header_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0,
      field_group: "layout_header"
    },
    header_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0,
      field_group: "layout_header"
    },
    subhead_offset_top: {
      type: "number",
      title: "Offset Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 4.9,
      field_group: "layout_subhead"
    },
    subhead_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_subhead"
    },
    subhead_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_subhead"
    },
    upper_horizontal_rule_offset_top: {
      type: "number",
      title: "Offset Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 20,
      field_group: "layout_upper_rule"
    },
    upper_horizontal_rule_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_upper_rule"
    },
    upper_horizontal_rule_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_upper_rule"
    },
    list_offset_top: {
      type: "number",
      title: "Offset Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0,
      field_group: "layout_list"
    },
    list_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0,
      field_group: "layout_list"
    },
    list_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0,
      field_group: "layout_list"
    },
    list_height: {
      type: "number",
      title: "Height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 85,
      field_group: "layout_list"
    },
    lower_horizontal_rule_offset_top: {
      type: "number",
      title: "Offset Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 88,
      field_group: "layout_lower_rule"
    },
    lower_horizontal_rule_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_lower_rule"
    },
    lower_horizontal_rule_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_lower_rule"
    },
    footer_offset_bottom: {
      type: "number",
      title: "Offset Bottom",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 5,
      field_group: "layout_footer"
    },
    footer_margin_left: {
      type: "number",
      title: "Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 3.8,
      field_group: "layout_footer"
    },
    footer_margin_right: {
      type: "number",
      title: "Margin Right",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 5,
      field_group: "layout_footer"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    accent_color: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "general",
      isVisible: () => false,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    menu_slider_arrow_color: {
      type: "color",
      title: "Menu Arrows",
      default: "#FFFFFF",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true,
      field_group: "general"
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: true
};
