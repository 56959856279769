import React, { useState, useRef, useEffect, useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import FolderDetailForm from "../folder/FolderDetailForm";
import ResourceDetailForm from "../resource/ResourceDetailForm";
import LayoutConstants, { getTreeListHeight } from "../layout/LayoutConstants";
import { INPUT_MODE, TREE_TYPE } from "../../shared-global/enums/ui-enums";
const BOTTOM_SPACE = 40;

interface IDetailWindowProps {
  inputMode: INPUT_MODE;
  height: number;
  treemode: TREE_TYPE;
  client_options?: any;
  resourcetree_folders?: any;
  folders?: any;
}

const DetailWindow: React.FunctionComponent<IDetailWindowProps> = (
  props: IDetailWindowProps
) => {

  const vHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  const [componentMounted, setComponentMounted] = useState(false);
  const _detailWindow = useRef();

  let heightForScroll: number;
  if (props.inputMode === "html5") {
    heightForScroll = getTreeListHeight();
  } else {
    heightForScroll =
      props.height ||
      Math.max(
        vHeight - LayoutConstants().dashboard.tree_v_offset,
        LayoutConstants().dashboard.tree_height_min
      );
  }
  heightForScroll -= BOTTOM_SPACE;

  const scrollTop = () => {
    if (!componentMounted && _detailWindow.current) {
      const el: any = _detailWindow.current;
      el.scrollTo(0, 0);
      setComponentMounted(true);
    }
  };

  const selectedFolder = useMemo(() => {
    return cloneDeep(props.folders[props.client_options.applied_content_selected_folder])
  }, [props.client_options.applied_content_selected_folder])

  return (
    <div
      id="detail-window"
      style={{
        flex: "1 1 0%",
        padding: "0px 15px",
        overflow: "auto",
        height: heightForScroll,
      }}
      onScroll={() => scrollTop()}
      ref={_detailWindow}
    >
      {props.treemode === TREE_TYPE.APPLIED_CONTENT ? (
        <FolderDetailForm selected_folder={selectedFolder} />
      ) : (
        <ResourceDetailForm client_options={props.client_options} />
      )}
    </div>
  );
};

export default DetailWindow;
