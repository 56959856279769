import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/user_fields';
import { connect } from "react-redux";
import { userAcountUpdate } from '../../actions/userActions';
import { updatePersistentSettings } from '../../actions/userActions';
import _ from 'lodash';
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from '../../hooks/withRouter';

class Account extends Component {
  constructor(props) {
    super(props);
    console.log("go");
  }

  onSubmit = (values) => {
    //console.log(values.password);
    this.props.userAcountUpdate(values.first_name, values.last_name, values.current_password, values.new_password, values.confirmpassword, this.onUpdate, this.onFail);
  }


  onUpdate = () => {
    AlertNotification('success', 'Success', "Record successfully updated")
  }

  onError = (e) => {
    AlertNotification('error', 'Form error', e.message || 'An error has occurred')
  }

  handleThemeChange = (name, value) => {
    console.log("handle theme change", value);
    this.props.updatePersistentSettings({theme: value})
  }

  render() {
    let obj = {
      "first_name": this.props.user.first_name,
      "last_name": this.props.user.last_name,
      "current_password": this.props.user.current_password,
      "new_password": this.props.user.new_password,
      "confirmpassword": this.props.user.confirmpassword,
      "theme": _.get(this.props, 'persistent_settings.data.theme', 'light')
    }

    return (
      <div>
        <div>Account Settings</div>
        <div>Email :
          <span>{this.props.user.email}</span>
        </div> 
        <DynamicForm
          fieldGroupsConfig={formConfig.field_groups}
          fieldsConfig={formConfig.fields}
          fieldValues={obj}
          onSubmit={this.onSubmit}
          onError={this.onError}
          mode={"edit"}
          onChangeHandlers={[{name: "theme", callBackFunction: (name, value) => this.handleThemeChange(name, value)}]}
        />
      </div>
    )
  }
}

function ToUser(user) {
  user.password = "";
  user.newpassword = "";
  user.confirmpassword = "";
  return user;
}

const mapStateToProps = (state) => {
  return {
    user: ToUser(state.data.user),
    persistent_settings: state.data.persistent_settings
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    userAcountUpdate: (first_name, last_name, password, newpassword, confirmpassword, onSuccess, onFail) => {
      dispatch(userAcountUpdate(first_name, last_name, password, newpassword, confirmpassword, onSuccess, onFail));
    },
    updatePersistentSettings: (data) => {
      dispatch(updatePersistentSettings(data))
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));