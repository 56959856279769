import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

interface IScrollableContainerProps {
  containerHeight: number;
  containerWidth: number;
  scrollableContent: any;
  speed: number;
}

export const ScrollableContainer2010: React.FC<IScrollableContainerProps> = (
  props: IScrollableContainerProps
) => {
  let timeout = null;

  useEffect(() => {

    scrollSpy.update();

    Events.scrollEvent.register("begin", function (to, element) {
      //console.log("begin", to, element);
    });

    Events.scrollEvent.register("end", function (to, element) {
      //console.log("end", to, element);
      if (to === "top-element") {
        scrollToBottom(props.speed * 1000);
      } else if (to === "bottom-element") {
        scrollToTop(0);
      }
    });
    scrollToTop(0);
    timeout = setTimeout(() => {
      startScroll();
    }, 1000);
  }, []);

  // const handleUserKeyPress = useCallback((event) => {
  //   const { key, keyCode } = event;

  //   if (keyCode === 37) {
  //     scrollToTop(10000);
  //     console.log("left");
  //   }
  //   if (keyCode === 39) {
  //     scrollToBottom(10000);
  //     console.log("right");
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("keydown", handleUserKeyPress);

  //   return () => {
  //     window.removeEventListener("keydown", handleUserKeyPress);
  //   };
  // }, [handleUserKeyPress]);

  const startScroll = () => {
    scrollToBottom(props.speed * 1000);
  };

  const scrollToTop = (time: number) => {
    scrollToWithContainer("top-element", time);
  };

  const scrollToBottom = (time: number) => {
    scrollToWithContainer("bottom-element", time);
  };

  const scrollToWithContainer = (element_name, duration: number) => {
    let goToContainer = new Promise((resolve, reject) => {
      scroller.scrollTo("scroll-container", {
        duration: 0,
        delay: 0,
        smooth: "linear",
      });
    });

    let goToElement = new Promise((resolve, reject) => {
      scroller.scrollTo(element_name, {
        duration: duration,
        delay: 0,
        smooth: "linear",
        containerId: "scroll-container",
      });
    });

    goToContainer.then(() =>
      goToElement.then(() => {
        //console.log("finished");
      })
    );
  };

  return (
    <Element
      className="element"
      id="scroll-container"
      name="scroll-container-1"
      style={{
        position: "relative",
        height: props.containerHeight,
        overflow: "hidden",
      }}
    >
      <Element name="top-element" style={{}}></Element>
      {props.scrollableContent}
      <Element name="bottom-element" style={{}}></Element>
      {props.scrollableContent}
    </Element>
  );
};
