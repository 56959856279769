import React, { useEffect, useState } from "react";
import { Table } from "antd";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TREE_MODE_APPLIED_CONTENT } from "../constants";
import { reversePathsFromNode } from "../../utils/treeUtils";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../config/store";

interface IResourceContentLibraryAssociatedProps {
  selected_resource: any;
  resource__folders: any;
  folders: { [key: string]: any };
  folder__folders: { [key: string]: any };
  projects: { [key: string]: any };
  setClientOptions: any;
  toggleFolderExpanded: any;
  setFoldersExpanded: any;
}

const { Column } = Table;

export const ResourceContentLibraryAssociated: React.FC<
  IResourceContentLibraryAssociatedProps
> = (props: IResourceContentLibraryAssociatedProps) => {
  const [dataTable, setDataTable] = useState([]);
  const [folderFoldersArray, setFolderFoldersArray] = useState([]);
  const client_options = useSelector(
    (state: RootState) => state.client_options
  );

  useEffect(() => {
    const { selected_resource, resource__folders, folders } = props;
    if (
      selected_resource &&
      resource__folders &&
      resource__folders.length &&
      folders
    ) {
      const resourceId = selected_resource.id;
      const data = resource__folders
        .filter((rf) => {
          const folderId = _.get(rf, "folder", null);
          return rf.resource === resourceId && props.folders[folderId];
        })
        .map((f) => {
          return {
            key: `data-${f.resource}-${f.folder}`,
            ...f,
          };
        });
      setDataTable(data);
    }
  }, [props.selected_resource, props.resource__folders, props.folders]);

  useEffect(() => {
    if (props.folder__folders) {
      setFolderFoldersArray(arrayFromKeyedObject(props.folder__folders));
    }
  }, [props.folder__folders]);

  const getFolderPath = (folderId) => {
    const { folders, folder__folders } = props;
    const path = reversePathsFromNode(folderId, folder__folders);
    if (path && path.length > 0) {
      const pathIds = path[0];
      const foldersPath = pathIds
        .map((id) => {
          return folders[id];
        })
        .filter((f) => !!f);
      return foldersPath;
    }
    return [];
  };

  const getFolderPathInfo = (folderId) => {
    const pathFolder = getFolderPath(folderId);
    const path = pathFolder.map((pf) => pf.name);
    return path.reduce((accumulator, current, index, array) => {
      let str =
        accumulator + current + (index < array.length - 1 ? " / " : " ");
      return str;
    }, "");
  };

  const renderAssociatedWith = (obj) => {
    const folderId = _.get(obj, "folder", null);
    const folderName = _.get(props.folders[folderId], "name", "");
    const folderPath = getFolderPathInfo(folderId);
    return (
      <div className="resource-content-library-associated_associated-side">
        <div className="resource-content-library-associated_associated-side-left">
          <FontAwesomeIcon
            className="resource-content-library-associated_associated-side-icon"
            icon={["fas", "user"]}
          />
        </div>
        <div className="resource-content-library-associated_associated-side-right">
          <div className="resource-content-library-associated_associated-side-folder">
            <a
              onClick={() => {
                onClick(folderId);
              }}
            >
              {folderName}
            </a>
          </div>
          <div className="resource-content-library-associated_associated-side-path">
            {folderPath}
          </div>
        </div>
      </div>
    );
  };

  const getLocationAndCompanyPathFromFolderPath = (foldersPath) => {
    const { projects } = props;
    const path = [];
    if (foldersPath && foldersPath.length > 0) {
      const first = foldersPath[0];
      const folderFolderFinded = folderFoldersArray.find(
        (ff) => ff.child_folder === first.id
      );
      if (folderFolderFinded) {
        const projectId = folderFolderFinded.project;
        const project = projects[projectId];
        if (project) {
          const companyId = project.company;
          const locationId = project.location;
          path.push(`company-${companyId}`);
          path.push(`location-${locationId}`);
        }
      }
    }
    return path;
  };

  /**
   * this method return full path folder (including company and location)
   * @param folderId
   */
  const getFullExpandedFolderTree = (folderId) => {
    const foldersIdPath = getExpandedFolderTree(folderId);
    const foldersPath = getFolderPath(folderId);
    const partialPath = getLocationAndCompanyPathFromFolderPath(foldersPath);
    const fullPath = [...partialPath, ...foldersIdPath];

    return fullPath;
  };

  const getExpandedFolderTree = (parentFolderId) => {
    try {
      const foldersPath = getFolderPath(parentFolderId);
      const tree_expanded = foldersPath.map((fp) => `folder-${fp.id}`);
      return tree_expanded;
    } catch (e) {
      return null;
    }
  };

  const onClick = (folderId) => {
    const treeId = `folder-${folderId}`;

    const updateClientOptions = {
      treemode: TREE_MODE_APPLIED_CONTENT, // to change tab
      content_library_last_select_folder: folderId,
      applied_content_tree_selected_id: treeId,
      applied_content_modal_selected_folder: folderId,
      applied_content_selected_folder: folderId
    };

    props.setClientOptions(updateClientOptions);
    props.toggleFolderExpanded(treeId, false, true, client_options.treemode);
    const tree_expanded = getFullExpandedFolderTree(folderId);
    if (folderId && tree_expanded) {
      props.setFoldersExpanded(TREE_MODE_APPLIED_CONTENT, tree_expanded);
    }
  };

  const renderView = (obj) => {
    const folderId = _.get(obj, "folder", null);

    return (
      <div className="resource-content-library-associated_view">
        <a
          onClick={() => {
            onClick(folderId);
          }}
        >
          <FontAwesomeIcon
            className="resource-content-library-associated_view-side-icon"
            icon={["fas", "edit"]}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="resource-content-library-associated">
      <Table dataSource={dataTable} bordered pagination={false}>
        <Column
          title="Associated with"
          key="associated"
          render={renderAssociatedWith}
        />
        <Column
          title="View"
          key="view"
          width={100}
          align="center"
          render={renderView}
        />
      </Table>
    </div>
  );
};

export default ResourceContentLibraryAssociated;
