import React, { Component } from 'react';
import { connect } from "react-redux";
//import _ from 'lodash';
import PrimaryTree from '../tree/PrimaryTree';
import DetailWindow from '../dashboard/DetailWindow';
import {Modal, Select} from 'antd';
import WithInputWrapper from '../utils/WithInputWrapper'
import { Tabs, Badge, Tooltip} from 'antd';
import CenterArea from './CenterArea';
import { setClientOptions } from '../../reducers/clientOptionsSlice'
import LayoutConstants from '../layout/LayoutConstants';
import { checkForPermission } from '../../services/permissionService';
import { getThemeClass, getCompanyTemplates } from '../../utils/themeUtils';
import _, { isArray } from 'lodash';
import Spaner from '../utils/Spaner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BREAK_POINT_MOBILE, getFullDrawerHeight, MD_BLUE, MD_WARNING } from '../layout/LayoutConstants';
import { TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE } from '../constants';
import {cloneFolder, startFolderStatusPoll, toggleFolderExpanded} from "../../actions/folderActions";
import FormWrapper from "../form/FormWrapper";
import FieldWrapper from "../form/FieldWrapper";
import TextInput from "../form/input/TextInput";
import CheckboxInput from "../form/input/CheckboxInput";
import AlertNotification from "../layout/AlertNotification";
import { Resizable } from "re-resizable"
import MultiSelectInput from "../form/input/MultiSelectInput";
import {FontsFamilies} from "../../shared-global/fonts";
import {updateProjectSettings} from "../../actions/projectActions";
import AddFolderModal from '../folder/AddFolderModal'
import CaretExpand from '../../icons/caret-expand.svg'
import CaretCollapse from '../../icons/caret-collapse.svg'

const TabPane = Tabs.TabPane;
class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addFolderProps: null,
      forceAddFolder: false,
      detailPanelShow: false,
      detailPanelSlideIn: false,
      detailPanelSlideOut: false,
      treePanelShow: true,
      treePanelSlideIn: false,
      treePanelSlideOut: false,
      mobileMode: false,
      activeTabKey: "1",
      theme_list: [],
      treeWidth: LayoutConstants().dashboard.applied_content_tree_width,
      folderName: "",
      clone_children: false,
      show_clone_children_checkbox: true,
      show_default_fonts: true,
      fontsSelected: [],
      invalidName: false,
      propertiesPanelExpandedWidth: 0
    };
    this._primaryTreeRef = React.createRef();
  }


  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('focus', this.clearClipboard);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show_detail_panel !== this.props.show_detail_panel) {
      if (this.props.show_detail_panel) {
        this.openDetailPane();
      } else {
        this.closeDetailPane();
      }
    }

    if (prevProps.client_options &&
      this.props.client_options &&
      prevProps.client_options.applied_content_tree_selected_id !== this.props.client_options.applied_content_tree_selected_id)
    {
      this.setState({
        clone_children: _.get(this.props.folder_type, "clone_children_checkbox_default_value", false)
      })
    }

    if (
      !this.state.forceAddFolder &&
      this.state.addFolderProps && this.state.addFolderProps?.parentFolder &&
      'folder-' + this.state.addFolderProps.parentFolder.id !== this.props.client_options.applied_content_tree_selected_id
    ) {
      this.addSubfolderClose()
    }

    if (
      prevProps.default_fonts &&
      prevProps.default_fonts !== this.props.default_fonts
    ) {
      this.setState({
        fontsSelected: this.props.default_fonts,
        show_default_fonts: this.props.show_only_default_fonts
      })
    }


    if (this.props.treemode != prevProps.treemode) {
      this.props.setClientOptions({
        propertiesPanelIsFullyExpanded: false,
      })
      switch (this.props.treemode) {
        case TREE_MODE_APPLIED_CONTENT:
          this.setState({
            activeTabKey: "1",
            detail_drawer_open: true
          })
          break;
        case TREE_MODE_RESOURCE:
          this.setState({
            activeTabKey: "2"
          })
          break;
      }
    }

    if (this.props.folders != prevProps.folders) {
      const themeList = getCompanyTemplates(
        this.props.user.company,
        this.props.project_templates,
        this.props.folders
      );
      this.setState({
        theme_list: themeList,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('focus', this.clearClipboard);
    clearTimeout(this._resizeTimeout);
  }

  // #region Add Folder Modal

  addSubfolderOpen = (data) => {
    const { selectedTreeId, meta } = data;
    let folderId = Number(selectedTreeId.replace("folder-", ""));
    let parentFolder;
    if (this.props.client_options.treemode !== TREE_MODE_RESOURCE) {
      parentFolder = this.props.folders[folderId]
    } else {
      parentFolder = this.props.resourcetree_folders[folderId]
    }

    const folderType = this.props.folder_types[parentFolder.folder_type];

    this.props.setClientOptions({ applied_content_tree_selected_id: selectedTreeId });

    this.addSubfolderClose();

    setTimeout(() => {
      this.setState({
        forceAddFolder: true,
        addFolderProps: {
          mode: folderType.folder_type_selector?.mode || 'modal',
          visible: true,
          onOkay: this.addFolderModalSuccess,
          onCancel: this.addSubfolderClose,
          parentFolder,
          meta: meta,
        }
      })

      setTimeout(() => {
        this.setState({
          forceAddFolder: false,
        })
      }, 1000)
    })
    //add folder server side if passes
  }

  addSubfolderClose = () => {
    this.setState({
      addFolderProps: null
    })
  }

  addFolderModalSuccess = () => {
    if (this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
      if (this.props.applied_content_tree_expanded.includes('folder-' + this.state.addFolderProps?.parentFolder?.id)) {
        // parent folder is already open
      } else {
        // toggle open the parent folder as a new one was added
        this.props.toggleFolderExpanded('folder-' + this.state.addFolderProps?.parentFolder?.id, true, true, this.props.client_options.treemode);
      }
    }

    this.addSubfolderClose();
  }

  // #endregion

  checkMobileMode = () => {
    const width = window.innerWidth;
    if (width < BREAK_POINT_MOBILE) {
      return true;
    }
    return false;
  }

  clearClipboard = () => {
    if (this.props.client_options.apello_copy && navigator.clipboard) {
      navigator.clipboard.readText().then((text) => {
        const copied_text = text.replace(/(\r\n|\n|\r)/gm, "");
        if (copied_text !== this.props.client_options.copied_text) {
          this.props.setClientOptions({ apello_copy: false, copied_text: "" });
        }
      });
    }
  }

  handleResize = () => {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = null;
    this._resizeTimeout = setTimeout(() => {
      this.setState ({
        mobileMode: this.checkMobileMode(),
        treeWidth: LayoutConstants().dashboard.applied_content_tree_width
      })
    }, 100);
  }

  closeTreePane = () => {
    this.setState({
      treePanelSlideOut: true,
      treePanelSlideIn: false
    });
  }

  openTreePane = () => {
    this.setState({
      treePanelShow: true,
      treePanelSlideOut: true,
    });

    setTimeout(() => {
      this.setState({ treePanelSlideOut: false, treePanelSlideIn: true });
      this.props.setClientOptions({ tree_panel_open: true });
    }, 10);
  };

  closeDetailPane = () => {
    this.setState({
      detailPanelSlideOut: true,
      detailPanelSlideIn: false,
    });
    this.props.setClientOptions({ detail_drawer_open: false });
  };

  openDetailPane = () => {
    this.setState({
      detailPanelShow: true,
      detailPanelSlideOut: true,
    });

    setTimeout(() => {
      this.setState({ detailPanelSlideOut: false, detailPanelSlideIn: true });
      this.props.setClientOptions({ detail_drawer_open: true });
    }, 10);
  };

  toggleDetail = (open) => {
    if (!this.props.show_detail_panel) {
      return;
    }
    this.props.setClientOptions({ detail_drawer_open: open });
    if (open === false) {
      this.setState({
        detailPanelShow: open,
        detailButtonTimeout: 100,
      });
    } else {
      this.setState({
        detailPanelShow: open,
        detailButtonTimeout: 1000,
      });
    }
  };

  handleTabChange = (tabKey) => {
    switch (Number(tabKey)) {
      case 1:
        this.shouldOpenDrawer(TREE_MODE_APPLIED_CONTENT);
        this.props.setClientOptions({
          treemode: TREE_MODE_APPLIED_CONTENT,
          list_view_show_in_tab: false,
        });
        this.setState({
          activeKey: 1,
        });
        break;
      case 2:
        this.shouldOpenDrawer(TREE_MODE_RESOURCE);
        this.props.setClientOptions({ treemode: TREE_MODE_RESOURCE });
        if (!this.props.client_options.resource_tree_selected_id) {
          this.props.setClientOptions({ resource_tree_selected_id: `company-${this.props.user.company}` });
          this.props.toggleFolderExpanded(`company-${this.props.user.company}`, true, true, this.props.client_options.treemode);
        }
        this.setState({
          activeKey: 2,
        });
        break;
      default:
        return false;
    }
  };

  on_tree_transition_end = (event) => {
    if (
      event.target &&
      typeof event.target.className.includes !== "undefined" &&
      event.target.className.includes("slide-out")
    ) {
      this.setState({
        treePanelSlideOut: false,
        treePanelShow: false,
      });
      this.props.setClientOptions({ tree_panel_open: false });
    }
  };

  on_detail_tree_transition_end = (event) => {
    if (
      event.target &&
      typeof event.target.className.includes !== "undefined" &&
      event.target.className.includes("slide-out")
    ) {
      this.setState({
        detailPanelSlideOut: false,
        detailPanelShow: false,
      });
    }
  };

  shouldOpenDrawer = (treeMode) => {
    if (treeMode === TREE_MODE_APPLIED_CONTENT) {
      const selectedId = this.props.client_options
        .applied_content_tree_selected_id;
      if (selectedId && selectedId.includes("folder-")) {
        this.toggleDetail(true);
      } else {
        this.toggleDetail(false);
      }
    } else {
      const selectedId = this.props.client_options.resource_tree_selected_id;
      if (selectedId && selectedId.includes("resource-")) {
        this.toggleDetail(true);
      } else {
        this.toggleDetail(false);
      }
    }
  };

  callPreparePublishFolder = (uuid) => {
    this._primaryTreeRef.publishFolderModalOpen(uuid, this.props.selected_folder);
  };

  setPrimaryTreeRef = (ref) => {
    if (ref) {
      this._primaryTreeRef = ref;
    }
  };

  openBackupsModal = () => {
    this._primaryTreeRef.openBackupsModal();
  };

  renderPrimaryTreeTab = (name, count) => {
    const width = window.innerWidth;

    if (width <= 1600) {
      return (
        <span>
          <Badge count={count} style={{ backgroundColor: MD_WARNING }}>
            {name === 'Displays' && (
              <Tooltip title="Displays">
                <FontAwesomeIcon className='fa-lg' icon='desktop' />
              </Tooltip>
            )}

            {name === 'Content Library' && (
              <Tooltip title="Content Library">
                <FontAwesomeIcon className='fa-lg' icon='image' />
              </Tooltip>
            )}
          </Badge>
        </span>
      );
    } else {
      return (
        <span>
          <Badge count={count} style={{ backgroundColor: MD_WARNING }}>
            <span style={{ marginRight: 12 }}>
              {name === 'Displays' && (
                <Tooltip title="Displays">
                  <FontAwesomeIcon className='fa-lg' icon='desktop' style={{ marginRight: 4 }} />
                </Tooltip>
              )}
              {name === 'Content Library' && (
                <Tooltip title="Content Library">
                  <FontAwesomeIcon className='fa-lg' icon='image' style={{ marginRight: 4 }} />
                </Tooltip>
              )}
              {name}
            </span>
          </Badge>
        </span>
      );
    }

  };

  handleThemeChange = (newValue) => {
    this.props.setClientOptions({ current_template: newValue });
  };

  handleNameChange = (fieldName, value) => {
    this.setState({folderName: value})
    this.forceUpdate()
  }

  toggleCloneCurrentFolderModal = (val = true) => {
    this.props.setClientOptions({show_clone_modal: val})
  }


  toggleFontsModal = (val = true) => {
    this.props.setClientOptions({show_fonts_modal: val})
  }

  onSaveFontsSettings = () => {
    this.props.updateProjectSettings(this.props.client_options.selected_folder_meta.project, {
      default_fonts: this.state.fontsSelected,
      show_only_default_fonts: this.state.show_default_fonts
    }, this.onUpdate, this.onError)
    this.props.setClientOptions({show_fonts_modal: false})
  }


  toggleFontsModal = (val = true) => {
    this.props.setClientOptions({show_fonts_modal: val})
  }

  onSaveFontsSettings = () => {
    this.props.updateProjectSettings(this.props.client_options.selected_folder_meta.project, {
      default_fonts: this.state.fontsSelected,
      show_only_default_fonts: this.state.show_default_fonts
    }, this.onUpdate, this.onError)
    this.props.setClientOptions({show_fonts_modal: false})
  }

  onCloneChildrenChange = (val) =>  {
    this.setState({ clone_children: val })
    this.forceUpdate()
  }

  onDefaultFontChange = (val) => {
    this.setState({ show_default_fonts: val })
    this.forceUpdate()
  }

  handleFontsSelection = (val) => {
    this.setState({ fontsSelected: val })
    this.forceUpdate()
  }

  onOkClone = () => {
    this.props.setClientOptions({
      lock_screen: true,
      message_for_progress: 'We are saving your changes',
    })
    this.props.cloneFolder(
      this.props.clone_folder_id,
      this.state.folderName,
      this.state.clone_children,
      this.onCloneFolderSuccess,
      (message) => this.onCloneError(message)
    )

    this.toggleCloneCurrentFolderModal(false)
  }

  onCloneFolderSuccess = () => {
    this.setState({ folderName: ''})
    AlertNotification(
      'success',
      'Success',
      'Folder has been cloned successfully',
    );
    this.props.setClientOptions({lock_screen: false, message_for_progress: null});
    this.props.startFolderStatusPoll(this.props.user.company);
  }

  onCloneError = (message) => {
    AlertNotification('error', 'Error', message || "Something went wrong.")
    this.props.setClientOptions({lock_screen: false, message_for_progress: null});
  }

  handlePanelExpansion = () => {
    const clientWidth = document.documentElement.clientWidth;
    let detail_drawer_width = 0;
    let primary_tree_width = 0;

    const { client_options, folder_type } = this.props;

    if (client_options && client_options.detail_drawer_open === true) {
      detail_drawer_width = LayoutConstants().dashboard.applied_content_tree_width;
    }

    if (client_options && client_options.tree_panel_open) {
      primary_tree_width = LayoutConstants().dashboard.applied_content_tree_width;
    }
    const vWidth = clientWidth - detail_drawer_width - primary_tree_width
    const propertiesPanelExpandedWidth = LayoutConstants().dashboard.applied_content_tree_width + (vWidth * 0.5)
    this.props.setClientOptions({
      propertiesPanelIsFullyExpanded: !this.props.client_options.propertiesPanelIsFullyExpanded,
    })
    this.setState({
      propertiesPanelExpandedWidth
    })
  }

  render() {
    let { content_permission } = this.props;
    let drawerWrapClass = "s-panel-wrap";
    let primaryTreeWrapClass = "s-left-panel-wrap";

    // if (this.state.show_detail_panel !== this.props.show_detail_panel) {
    //   if (this.props.show_detail_panel) {
    //     this.openDetailPane();
    //   } else {
    //     this.closeDetailPane();
    //   }
    //   this.setState({
    //     previous_show_detail_panel: this.props.show_detail_panel
    //   });
    // }

    if (!this.state.treePanelShow) {
      primaryTreeWrapClass += " hide";
    }

    if (this.state.treePanelSlideIn) {
      primaryTreeWrapClass += " slide-in";
    } else if (this.state.treePanelSlideOut) {
      primaryTreeWrapClass += " slide-out";
    }

    if (!this.props.show_detail_panel) {
      drawerWrapClass += " hide";
    }

    if (!this.state.detailPanelShow) {
      drawerWrapClass += " hide";
    }

    if (this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
      if (
        this.state.detailPanelSlideIn === true &&
        this.props.client_options.applied_content_selected_folder
      ) {
        drawerWrapClass += " slide-in";
      } else if (this.state.detailPanelSlideOut) {
        drawerWrapClass += " slide-out";
      }
    } else {
      if (
        this.state.detailPanelSlideIn === true &&
        this.props.client_options.resource_tree_selected_id &&
        this.props.client_options.resource_tree_selected_id.includes(
          "resource-"
        )
      ) {
        drawerWrapClass += " slide-in";
      } else if (this.state.detailPanelSlideOut) {
        drawerWrapClass += " slide-out";
      }
    }

    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: 42,
          height: getFullDrawerHeight(),
          overflowY: "hidden",
        }}
        className="full-width"
      >
        {this.state.addFolderProps && this.state.addFolderProps.mode === 'modal' && (
          <AddFolderModal {...this.state.addFolderProps} />
        )}

        <Modal
          visible={this.props.show_clone_modal === true}
          onCancel={() => {
            this.toggleCloneCurrentFolderModal(false)
            this.handleNameChange('', '')
            this.onCloneChildrenChange(true)
          }}
          onOk={(e) => {
            if(!this.state.folderName.length){
              this.setState({invalidName: true})
            }else{
              this.setState({invalidName: false})
              e.preventDefault()
              this.onOkClone()
            }
          }}
          destroyOnClose={true}
          okText="COPY FOLDER"
          cancelText="CANCEL"
        >
          <div style={{ paddingLeft: 6, paddingRight: 6 }}>
            <div>
                <span>
                  <b>Please insert the new folder name</b>
                </span>
            </div>
          </div>
          <div>
            <FormWrapper>
              <FieldWrapper>
                <TextInput
                  onBlur={''}
                  canUpdate={true}
                  defaultValue={this.state.folderName}
                  fieldName={''}
                  showCharCount={false}
                  showLabel={true}
                  tooltip={''}
                  title={'Folder name'}
                  onChange={(fieldName, value) => this.handleNameChange(fieldName, value)}
                />
              </FieldWrapper>
              {
                _.get(this.props.folder_type, "show_clone_children_checkbox", true) && (
                  <FieldWrapper>
                    <CheckboxInput
                      fieldName={'clone_children'}
                      title="Copy Children"
                      onChange={(fieldName, value) => this.onCloneChildrenChange(value)}
                      defaultValue={this.state.clone_children}
                      canUpdate={true}
                    />
                  </FieldWrapper>
                )
              }
              <span>
                <span style={{visibility: this.state.invalidName ? 'visible' : 'hidden', color: 'red'}}>
                  Value is required
                </span>
              </span>
            </FormWrapper>
          </div>
        </Modal>
        <Modal
          visible={this.props.show_fonts_modal === true}
          onCancel={() => {
            this.toggleFontsModal(false)
          }}
          onOk={(e) => {
            e.preventDefault()
            this.onSaveFontsSettings()
          }}
          destroyOnClose={true}
          okText="SAVE"
          cancelText="CANCEL"
        >
          <div style={{ paddingLeft: 6, paddingRight: 6 }}>
            <div>
                <span>
                  <b>Mange Default Fonts</b>
                </span>
            </div>
          </div>
          <div>
            <FormWrapper>
              <FieldWrapper>
                <CheckboxInput
                  fieldName={'show_default_fonts'}
                  title="Show Only Default Fonts"
                  onChange={(fieldName, value) => this.onDefaultFontChange(value)}
                  defaultValue={this.state.show_default_fonts}
                  canUpdate={true}
                />
              </FieldWrapper>
              <FieldWrapper>
                <MultiSelectInput
                  fieldName={'fontsSelected'}
                  onChange={(fieldName, value) => this.handleFontsSelection(value)}
                  canUpdate={true}
                  defaultValue={this.state.fontsSelected}
                  title={'Available Fonts'}
                  options={Object.keys(FontsFamilies).map((k) => {
                    return {
                      title: FontsFamilies[k],
                      value: FontsFamilies[k]
                    }
                  })}
                />
              </FieldWrapper>
            </FormWrapper>
          </div>
        </Modal>

        {!this.state.mobileMode && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
              justifyContent: !this.props.show_detail_panel && !this.state.detailPanelShow ? "" : "space-between"
            }}
          >
            {/* Tabs */}
            <div
              className={primaryTreeWrapClass}
              style={{ zIndex: 10 }}
              onTransitionEnd={(e) => this.on_tree_transition_end(e)}
            >
              <div
                style={{
                  height: "100%"
                }}
              >
                <Resizable
                  className={
                    "primary-tree-wrapper " +
                    getThemeClass(
                      _.get(
                        this.props,
                        "persistent_settings.data.theme",
                        "light"
                      ),
                      "primary-tree"
                    )
                  }
                  defaultSize={{
                    width: LayoutConstants().dashboard.applied_content_tree_width,
                    height: "100%"
                  }}
                  size={{
                    width: this.state.treeWidth,
                    height: "100%"
                  }}
                  onResizeStop={(e, dir, el, size) => {
                    this.setState({treeWidth: (this.state.treeWidth + size.width)})
                  }}
                  minWidth={308}
                  maxWidth={LayoutConstants().dashboard.applied_content_tree_width}
                >
                  <Tabs
                    onChange={this.handleTabChange}
                    defaultActiveKey="1"
                    activeKey={this.state.activeTabKey}
                    animated={{ inkBar: true, tabPane: false }}
                    tabBarExtraContent={
                      <span
                        style={{
                          cursor: "pointer",
                          padding: "8px 12px 8px 8px",
                          fontSize: "13pt",
                          opacity: 1,
                          color: MD_BLUE,
                        }}
                      >
                      <Select
                        className="dashboard_select-current-theme"
                        onChange={(newValue) =>
                          this.handleThemeChange(newValue)
                        }
                        style={{ marginRight: 50, width: 160 }}
                        value={this.props.client_options.current_template}
                      >
                        {this.state.theme_list.length &&
                        this.state.theme_list.map((option) => {
                          return (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <span
                        onClick={() => {
                          this.closeTreePane();
                        }}
                      >
                        <FontAwesomeIcon
                          className="fa-lg"
                          icon={["fal", "arrow-square-left"]}
                        />
                      </span>
                    </span>
                    }
                  >
                    <TabPane
                      tab={this.renderPrimaryTreeTab(
                        "Displays",
                        this.props.folderResults.length
                      )}
                      key="1"
                    >
                      <WithInputWrapper>
                        <PrimaryTree
                          toggleDetail={this.toggleDetail}
                          ref={this.setPrimaryTreeRef}
                          width={this.state.treeWidth}
                          addSubfolderOpen={this.addSubfolderOpen}
                        />
                      </WithInputWrapper>
                    </TabPane>
                    {content_permission && (
                      <TabPane
                        tab={this.renderPrimaryTreeTab(
                          "Content Library",
                          this.props.resourceResults.length
                        )}
                        key="2"
                      >
                        <WithInputWrapper>
                          <PrimaryTree
                            toggleDetail={this.toggleDetail}
                            width={this.state.treeWidth}
                            addSubfolderOpen={this.addSubfolderOpen}
                          />
                        </WithInputWrapper>
                      </TabPane>
                    )}
                  </Tabs>
                </Resizable>
              </div>
            </div>

            {this.state.treePanelShow ? null : (
              <div>
                <TransitionGroup>
                  <CSSTransition
                    key={this.state.treePanelShow}
                    timeout={500}
                    classNames="transition-slowinfastout"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        padding: 8,
                        opacity: 1,
                        color: MD_BLUE,
                        position: "fixed",
                        fontSize: "11pt",
                        left: "10px",
                        top: "47px",
                        zIndex: "9",
                      }}
                    >
                      <span
                        onClick={() => {
                          this.openTreePane();
                        }}
                      >
                        <FontAwesomeIcon
                          className="fa-lg"
                          icon={["fal", "arrow-square-right"]}
                        />
                      </span>
                    </span>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            )}

            {/* Center Area */}
            {(this.props.client_options.treemode === TREE_MODE_RESOURCE || this.props.selected_folder) && (
              <CenterArea
                mobile={false}
                preparePublishFolder={this.callPreparePublishFolder}
                openBackupsModal={this.openBackupsModal}
                openTreePane={this.openTreePane}
                openDetailPane={this.openDetailPane}
                closeTreePane={this.closeTreePane}
                closeDetailPane={this.closeDetailPane}
                key={`company-center-area-${this.props.user.company}`}
                addFolderProps={this.state.addFolderProps}
                propertiesPanelIsFullyExpanded={this.props.client_options.propertiesPanelIsFullyExpanded}
              />
            )}

            {/* Drawer */}
            <div
              className={drawerWrapClass}
              style={{
                zIndex: 10,
                width: !this.props.client_options.propertiesPanelIsFullyExpanded ?
                  LayoutConstants().dashboard.applied_content_tree_width :
                  this.state.propertiesPanelExpandedWidth
              }}
              onTransitionEnd={(e) => this.on_detail_tree_transition_end(e)}
            >
              <div
                style={{
                  position: 'absolute',
                  left: this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT ? -15 : 'auto',
                  right: this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT ?
                    'auto' : !this.props.client_options.propertiesPanelIsFullyExpanded ?
                    LayoutConstants().dashboard.applied_content_tree_width - 15 :
                    this.state.propertiesPanelExpandedWidth - 15,
                  zIndex: 50,
                  top: '48.5%',
                  cursor: 'pointer'
                }}
                onClick={() => this.handlePanelExpansion()}
              >
                <img src={this.props.client_options.propertiesPanelIsFullyExpanded ? CaretExpand : CaretCollapse} alt=""/>
              </div>
              <div className="s-panel s-panel-bottom-border" style={{ width: "100%" }}>
                <div>
                  <Tabs defaultActiveKey="1">
                    <TabPane
                      tab={
                        <span>
                          <span onClick={() => this.closeDetailPane()}>
                            <FontAwesomeIcon
                              className="fa-lg"
                              style={{ cursor: "pointer" }}
                              icon={["fal", "arrow-square-right"]}
                            />
                            <Spaner width="sm" />
                            Properties
                          </span>
                        </span>
                      }
                      key="1"
                    ></TabPane>
                  </Tabs>
                  <DetailWindow
                    key={`${this.props.client_options.applied_content_tree_selected_id}-${this.props.client_options.resource_tree_selected_id}`}
                    treemode={this.props.client_options.treemode}
                    inputMode={this.props.client_options.inputMode}
                    client_options={this.props.client_options}
                    resourcetree_folders={this.props.resourcetree_folders}
                    folders={this.props.folders}
                  />
                </div>
              </div>
            </div>
            {this.state.detailPanelShow ? null : (
              <TransitionGroup>
                <CSSTransition
                  key={this.state.detailPanelShow}
                  timeout={500}
                  classNames="transition-slowinfastout"
                >
                  <div>
                    <span>
                      <span
                        style={{
                          width: 90,
                          padding: 8,
                          opacity: 0.8,
                          borderRadius: 16,
                          backgroundColor: "white",
                          position: "fixed",
                          right: 10,
                          top: 48,
                          zIndex: 0,
                          color: MD_BLUE,
                        }}
                      >
                        &nbsp;
                      </span>
                      <span
                        onClick={() => this.openDetailPane()}
                        style={{
                          cursor: "pointer",
                          padding: 8,
                          opacity: 1,
                          position: "fixed",
                          right: 10,
                          top: 48,
                          zIndex: 5,
                          color: MD_BLUE,
                        }}
                      >
                        <FontAwesomeIcon
                          className="fa-lg"
                          icon={["fal", "arrow-square-left"]}
                        />
                        <Spaner width="sm" />
                        Properties
                        <Spaner width="sm" />
                      </span>
                    </span>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            )}
          </div>
        )}
        {this.state.mobileMode && (
          <div className="full-width">
            {/* <MobileDashboard/> */}
            <div
              style={{
                textAlign: "center",
                display: "inline-block",
                width: "100vw",
              }}
            >
              <br />
              <br />
              Application requires a larger view area.
              <br />
              Please expand window.
            </div>
          </div>
        )}
        {/*<NotificationArea/>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const contentPermission = checkForPermission('resource', 'read', state.data.user_permissions_grouped);
  let open_tree_drawer_button_margin = 0;
  let folder_type = null
  let selected_folder = null;
  let show_detail_panel;
  if (state.client_options.treemode !== TREE_MODE_APPLIED_CONTENT) {
    show_detail_panel = true;
    if (state.client_options.treemode === TREE_MODE_RESOURCE) {
      if (
        state.client_options.resource_tree_selected_id !== undefined &&
        state.client_options.resource_tree_selected_id !== null &&
        state.client_options.resource_tree_selected_id.includes("resource-")
      ) {
        const resource_id_str =
          state.client_options.resource_tree_selected_id;
        if (resource_id_str.startsWith("resource")) {
          open_tree_drawer_button_margin = 125;
        }
      }
    }
  } else {
    selected_folder = state.data.folders[state.client_options.applied_content_selected_folder];
    if (!selected_folder) {
      show_detail_panel = false;
    } else {
      folder_type = state.data.folder_types[selected_folder.folder_type];
      let previewType = null;
      if (folder_type && folder_type.preview) {
        if (isArray(folder_type.preview)) {
          previewType = true;
        } else {
          previewType = _.get(folder_type, 'preview.type', null);
        }
      }

      show_detail_panel = _.get(folder_type, 'list_view.enabled', false) || previewType !== null;
      const center_has_list_view = _.get(folder_type, "list_view.enabled", false)
      if (center_has_list_view || previewType){
        if (center_has_list_view) {
          open_tree_drawer_button_margin = 180;
        } else {
          open_tree_drawer_button_margin = 125;
        }
      }
    }
  }

  let default_fonts = null
  let show_only_default_fonts = true

  if(!state.data?.projects)
    default_fonts = state.client_options.default_fonts
  else {
    if (
        state?.data?.projects[state?.client_options?.selected_folder_meta?.project]?.settings &&
        state?.data?.projects[state?.client_options?.selected_folder_meta?.project]?.settings?.default_fonts &&
        state?.data?.projects[state?.client_options?.selected_folder_meta?.project]?.settings?.default_fonts.length
    ) {
      default_fonts = state?.data?.projects[state?.client_options?.selected_folder_meta?.project]?.settings?.default_fonts
      show_only_default_fonts = state?.data?.projects[state?.client_options?.selected_folder_meta?.project]?.settings?.show_only_default_fonts
    } else {
      default_fonts = state.client_options.default_fonts
    }
  }

  return {
    applied_content_tree_expanded: state.data.applied_content_tree_expanded,
    project_templates: state.data.project_templates,
    user: state.data.user,
    folders: state.data.folders,
    folder_types: state.data.folder_types,
    content_permission: contentPermission,
    persistent_settings: state.data.persistent_settings,
    client_options: state.client_options,
    treemode: state.client_options.treemode,
    show_detail_panel: show_detail_panel,
    open_tree_drawer_button_margin,
    folderResults: state.data.folder_results,
    resourceResults: state.data.resource_results,
    show_clone_modal: state.client_options.show_clone_modal,
    clone_folder_id: state.client_options.clone_folder_id,
    resourcetree_folders: state.data.resourcetree_folders,
    show_fonts_modal: state.client_options.show_fonts_modal,
    projects: state.data.projects,
    default_fonts,
    show_only_default_fonts,
    folder_type,
    selected_folder
  };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options))
    },
    toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
      dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode, null, null));
    },
    cloneFolder: (folder__folder_id, folder_name, clone_children, onSuccess, onFail) => {
      dispatch(cloneFolder(folder__folder_id, folder_name, clone_children, onSuccess, onFail));
    },
    startFolderStatusPoll: (companyId, onSuccess = null, onFail = null) => {
      dispatch(startFolderStatusPoll(companyId, onSuccess, onFail));
    },
    updateProjectSettings: (id, values, onSuccess, onFail) => {
      dispatch(updateProjectSettings(id, values, onSuccess, onFail));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
