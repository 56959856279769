import React, { useContext } from "react";
import Slide1911 from "./Slide1911";
import { PreviewContext } from "../../Contexts";

export type BackgroundProps = {
  componentData: any;
  slotNames: string[];
  backgroundColorOverlay: string;
};

export const Background = (props: BackgroundProps) => {
  const {
    aws,
    aws_bucket,
    containerHeight,
    containerWidth,
    mode,
    webview_signedurls,
  } = useContext(PreviewContext);

  return (
    <div
      style={{
        position: "absolute",
        width: containerWidth,
        height: containerHeight,
        left: 0,
        top: 0,
      }}
    >
      <Slide1911
        aws={aws}
        aws_bucket={aws_bucket}
        mode={mode}
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        handleEndOfPlay={null}
        data={{
          ...props.componentData,
          index: 0,
          slotNames: props.slotNames,
          placeholder: "Image",
          fieldType: "image",
          showPlaceholder: props?.componentData?.persistent_settings?.data?.show_placeholder || false,
          backgroundSize: "cover",
        }}
        webview_signedurls={webview_signedurls}
      />
      <div
        style={{
          position: "absolute",
          width: containerWidth,
          height: containerHeight,
          backgroundColor: props.backgroundColorOverlay,
        }}
      />
    </div>
  );
};
