import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { transitions } from "./monterrey_settings";
import { tree_icon_types } from "../tree_icon_types";

export const monterrey_content_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.monterrey_content_donor_lists,
  display_name: "Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.monterrey_donor_list],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade",
      options: [...transitions.filter((t) => t.value !== "parent")]
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name", "sorting_order"]
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  slots: 4,
  preview: {
    aspect_ratio: 2.25,
    component_name: COMPONENT_NAME.MonterreyDynamicContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 4, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.monterrey_content_donor_lists], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "transition",
        title: "Transition",
        sort: true,
        editable: true,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_content_donor_lists],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  }
};
