import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class TextObjInput extends Component {

  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
  };

  constructor(props) {
    super(props);
    this._objData = props.defaultValue ? props.defaultValue : {
      resource_linked: false,
      value: ""
    }
    this.state = {
      value: this._objData.value
    }

  }

  onChange = (fieldName, value) => {
    this._objData.value = value;
    if (this.props.onChange){
      this.props.onChange(fieldName, this._objData);
    }
    this.setState({
      value: value
    })
  }

  onBlur = (fieldName, value) => {
    this._objData.value = value;
    if (this.props.onBlur){
      this.props.onBlur(fieldName, this._objData);
    }
    this.setState({
      value: value
    })
  }

  render(){
    return (
      <React.Fragment>
        {this.props.showLabel ? <span><label className="form-input-label">{this.props.title}</label><br/></span> : null}
        <Input
          disabled = {!this.props.canUpdate}
          className="text-input"
          defaultValue={this.props.defaultValue ? this.props.defaultValue.value : undefined}
          value={this.state.value}
          onChange={ev => this.onChange(this.props.fieldName, ev.target.value)}
          onBlur={ev => this.onBlur(this.props.fieldName, ev.target.value)}
        />
      </React.Fragment>
    );
  }

};

export default TextObjInput;