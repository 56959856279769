import request from "../utils/api/ServerRequest";
import { call, put } from "redux-saga/effects";
import _ from "lodash";
import { schema, normalize } from "normalizr";

const location_schema = new schema.Entity("location");
const location_array_schema = new schema.Entity("locations");

async function getLocationsAPI() {
  try {
    let response = await request.get("/location/get_locations");
    return response;
  } catch (ex) {
    return ex;
  }
}

async function addLocationAPI(payload) {
  try {
    let response = await request.post("/location/create", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

async function updateLocationAPI(payload) {
  try {
    let response = await request.post("/location/update", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

async function deleteLocationAPI(payload) {
  try {
    let response = await request.post("/location/delete_location", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

const locationSaga = {
  getLocations: function* (action) {
    try {
      const response = yield call(getLocationsAPI);
      if (_.get(response, "code", null) === "success") {
        yield put({
          type: "GET_LOCATIONS_SUCCESS",
          normalized: normalize(response.data.locations, [
            location_array_schema,
          ]),
        });
      } else {
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error happened"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "GET_LOCATIONS_FAIL",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  addLocation: function* (action) {
    try {
      const response = yield call(addLocationAPI, action.payload);
      if (_.get(response, "code", null) === "success") {
        const new_location = normalize(response.data.location, location_schema);
        const normalized = {
          entities: {
            ...new_location.entities,
          },
        };
        yield put({
          type: "ADD_LOCATION_SUCCESS",
          normalized: normalized,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "ADD_LOCATION_FAIL",
        });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "An error ocurred"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "ADD_LOCATION_FAIL",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  updateLocation: function* (action) {
    try {
      const response = yield call(updateLocationAPI, action.payload);
      if (_.get(response, "code", null) === "success") {
        yield put({
          type: "UPDATE_LOCATION_SUCCESS",
          normalized: normalize(response.data.location, location_schema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "UPDATE_LOCATION_FAIL",
        });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "UPDATE_LOCATION_FAIL",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  deleteLocation: function* (action) {
    try {
      const response = yield call(deleteLocationAPI, action.payload);
      let removeKeys = [];
      if (_.get(response, "code", null) === "success") {
        removeKeys.push({
          entities: "locations",
          ids: [response.data.location_id],
        });
        yield put({
          type: "DELETE_LOCATION_SUCCESS",
          removeKeys: removeKeys,
        });
      } else {
        yield put({
          type: "DELETE_LOCATION_FAIL",
        });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "DELETE_LOCATION_FAIL",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },
};

export default locationSaga;
