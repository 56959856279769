import { Modal } from "antd";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createCrossBackup } from "../../actions/backupActions";
import DynamicForm from "../form/DynamicForm";
import AlertNotification from "../layout/AlertNotification";

interface BackupRestoreModalProps {
    visible: boolean;
    onCancel: Function;
    createCrossBackup(file, onSuccess?, onFail?): void;
}

interface BackupRestoreModalState {
    submitting: boolean;
}

const backupFileForm = {
    field_groups: {
        default: {
            title: "Publishable",
            default_expanded: true,
        },
    },
    fields: {
        backup_file: {
            title: "Backup",
            type: "file",
            slot_type: "fixed",
            slots: [
                {
                    name: "file",
                    title: "Backup file",
                    aspect: null,
                },
            ],
            acceptable_contents: ["application/json"],
            limit: null,
            default: null,
            cropper: false,
        },
    },
};

class BackupRestoreModal extends Component<BackupRestoreModalProps, BackupRestoreModalState> {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    onSubmitCrossCreate = (values) => {
        this.props.createCrossBackup(
            values,
            this.onCrossCreateSuccess,
            this.onCrossCreateFailure
        );
    };

    onCrossCreateSuccess = () => {
        AlertNotification("success", "Success", "Cross environment backup created");
        this.props.onCancel();
    };

    onCrossCreateFailure = (message) => {
        AlertNotification("error", "Error", message || "Unexpected error happened");
    };

    onError = () => { };

    render() {
        return <Modal
            visible={this.props.visible}
            onCancel={() => this.props.onCancel()}
            title="Restore Backup"
            footer={null}>
            <p>
                Choose the json created from another environment, and submit to
                transfer the backup to the current environment. You can then restore
                the backup through the standard process.
            </p>

            <DynamicForm
                submitting={this.state.submitting}
                fieldGroupsConfig={backupFileForm.field_groups}
                fieldsConfig={backupFileForm.fields}
                fieldValues={{ backup_file: [] }}
                mode={"add"}
                onSubmit={(values) => this.onSubmitCrossCreate(values)}
                onError={this.onError}
                enableFieldGrouping={false}
                canUpdate={true}
                gallery={false}
                submit_button_title="Submit"
            />
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createCrossBackup: (file, onSuccess, onFail) => {
            dispatch(createCrossBackup(file, onSuccess, onFail));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackupRestoreModal);