import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const jasper_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.jasper_stories,
  display_name: "Stories",
  acceptable_folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
    columns: [
      {
        folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
        field: "header01",
        title: "Header",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
        field: "file",
        title: "Media",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.fremont_template_001, FOLDER_TYPE.jasper_template_001],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
