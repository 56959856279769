export const transitions = [
  {
    title: "Fade In/Out (250ms/250ms)",
    value: "transition-fade250ms"
  },
  {
    title: "Fade In/Out (500ms/500ms)",
    value: "transition-fade"
  },
  {
    title: "Fade In/Out (1s/1s)",
    value: "transition-fade1s"
  },
  {
    title: "Fade In/Out (2s/2s)",
    value: "transition-fade-2s"
  },
  {
    title: "Fade In/Out (500ms/1s)",
    value: "transition-fade500ms1s"
  },
  {
    title: "Slow Fade In / Fast Fade Out (500ms/10ms)",
    value: "transition-slowinfastout"
  },
  {
    title: "Fast Fade In / Slow Fade Out (10ms/500ms)",
    value: "transition-fastinslowout"
  }
];
