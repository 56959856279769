import _ from "lodash";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { COMPONENT_MODE_CONTEXT } from "../../../shared-global/enums/config-enums";
import {
  FOLDER_TYPE
} from "../../../shared-global/enums/folder-type-enums";
import { TRANSITION_CLASS } from "../../../shared-global/enums/ui-enums";
import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getChildrenFolderAndFolderFoldersDeep } from "../../utils/collectionUtils";
import { getBaseFolder, getChildrenData } from "../../utils/commonDataUtils";
import { indexFromIntervalArray, timeIntervalRemainderArrayMS } from '../../utils/timeHelpers';
import Boston3x2DiamondPlatinumList from "../theme/boston/Boston2x5and3x2/Boston3x2DiamondPlatinumList";
import BostonCheckerboard from "../theme/boston/BostonCheckerboard";
import BostonDonorSea from "../theme/boston/BostonDonorSea";
import BostonMediaWithQuote from "../theme/boston/BostonMediaWithQuote";
import BostonVerticalBackgroundLoop from "../theme/boston/BostonVerticalBackgroundLoop";
import CoburgSlide from "../theme/coburg/CoburgSlide";
import EugeneDonorListsOverview from "../theme/eugene/EugeneDonorListsOverview";
import EugeneMenu from "../theme/eugene/EugeneMenu";
import EugeneSlide from "../theme/eugene/EugeneSlide";
import EugeneStories from "../theme/eugene/EugeneStories";
import EugeneTemplate001 from "../theme/eugene/EugeneTemplate001";
import EugeneTemplate002 from "../theme/eugene/EugeneTemplate002";
import EugeneTemplate003 from "../theme/eugene/EugeneTemplate003";
import EugeneTemplate004 from "../theme/eugene/EugeneTemplate004";
import EugeneTemplate005 from "../theme/eugene/EugeneTemplate005";
import FreemontDonorList001 from "../theme/fremont/FreemontDonorList001";
import FremontMediaTemplate001 from "../theme/fremont/FremontMediaTemplate001";
import FremontMediaTemplate002 from "../theme/fremont/FremontMediaTemplate002";
import FremontOverlaySlide1603 from "../theme/fremont/FremontOverlaySlide1603";
import FremontTemplate001 from "../theme/fremont/FremontTemplate001";
import FremontTemplate002 from "../theme/fremont/FremontTemplate002";
import FremontTemplate003 from "../theme/fremont/FremontTemplate003";
import FremontTemplate004 from "../theme/fremont/FremontTemplate004";
import FremontTemplate005 from "../theme/fremont/FremontTemplate005";
import LosAngelesSlide from "../theme/los_angeles/LosAngelesSlide";
import LosAngelesSlides from "../theme/los_angeles/LosAngelesSlides";
import NaplesFundFolder from '../theme/naples/NaplesFundFolder';
import NaplesTemplateBlue001 from '../theme/naples/NaplesTemplateBlue001';
import NaplesTemplateBlue002 from '../theme/naples/NaplesTemplateBlue002';
import NaplesTemplateRed001 from '../theme/naples/NaplesTemplateRed001';
import NaplesTemplateRed002 from '../theme/naples/NaplesTemplateRed002';
import NewOrleansDonorList from "../theme/new_orleans/NewOrleansDonorList";
import NewOrleansTemplate001 from "../theme/new_orleans/NewOrleansTemplate001";
import NewOrleansTemplate002 from "../theme/new_orleans/NewOrleansTemplate002";
import NewOrleansTemplate003 from "../theme/new_orleans/NewOrleansTemplate003";
import SpringfieldDonorList from "../theme/springfield/SpringfieldDonorList";
import SpringfieldTemplate001 from "../theme/springfield/SpringfieldTemplate001";
import SpringfieldTemplate002 from "../theme/springfield/SpringfieldTemplate002";
import SpringfieldTemplate003 from "../theme/springfield/SpringfieldTemplate003";
import SpringfieldTemplate004 from "../theme/springfield/SpringfieldTemplate004";
import SpringfieldPortraitDonorList from "../theme/springfield_portrait/SpringfieldPortraitDonorList";
import SpringfieldPortraitTemplate001 from "../theme/springfield_portrait/SpringfieldPortraitTemplate001";
import SpringfieldPortraitTemplate002 from "../theme/springfield_portrait/SpringfieldPortraitTemplate002";
import SpringfieldPortraitTemplate003 from "../theme/springfield_portrait/SpringfieldPortraitTemplate003";
import SpringfieldPortraitTemplate004 from "../theme/springfield_portrait/SpringfieldPortraitTemplate004";
import CycleWrapper from "./CycleWrapper";
import IDCWrap from "./IDCWrap";
import Slide1911 from "./Slide1911";
import WaitComponent from "./WaitComponent";
import { createUseStyles } from "react-jss";
import { generateFontSizeClassesFromFields } from "../../utils/generalUtils";
import EverettBaseTemplate from "../theme/everett/EverettBaseTemplate";
import EverettFullScreen from "../theme/everett/EverettFullScreen";
import MonterreyLayout from "../theme/monterrey/MonterreyLayout";
import MonterreyDonorLists from "../theme/monterrey/MonterreyDonorLists";
import Monterrey5x1Container from "../theme/monterrey/Monterrey5x1Container";
import MarinLayout from "../theme/marin/MarinLayout";
import Marin8x1Container from "../theme/marin/Marin8x1Container";
import MarinDonorLists from "../theme/marin/MarinDonorLists";
import EverettDonorSea from "../theme/everett/EverettDonorSea";
import PhoenixDonorSea from '../theme/phoenix/PhoenixDonorSea';
import PhoenixStory from '../theme/phoenix/PhoenixStory';
import PhoenixDonorList from '../theme/phoenix/PhoenixDonorList';
import PhoenixFullScreen from "../theme/phoenix/PhoenixFullScreen";
import PhoenixMessage from "../theme/phoenix/PhoenixMessage";
import PhoenixCheckerboard from "../theme/phoenix/PhoenixCheckerboard";
import BerkeleyDonorSea from "../theme/berkeley/BerkeleyDonorSea";
import BerkeleyTemplate001 from "../theme/berkeley/BerkeleyTemplate001";
import BerkeleyDonorList from "../theme/berkeley/BerkeleyDonorList";
import AlpineDonorList from "../theme/alpine/AlpineDonorList";
import AlpineOneScreenMedia from "../theme/alpine/AlpineOneScreenMedia";
import AlpineProfileWithMedia from "../theme/alpine/AlpineProfileWithMedia";
import AlpineStoryWithMedia from "../theme/alpine/AlpineStoryWithMedia";
import TangentDonorList from "../theme/tangent/TangentDonorList";
import TangentStoryWithMedia from "../theme/tangent/TangentStoryWithMedia";
import TangentFullScreenMedia from "../theme/tangent/TangentFullScreenMedia";
import TangentStoryWithSingleImage from "../theme/tangent/TangentStoryWithSingleImage";
import TangentMediaWithQuote from "../theme/tangent/TangentMediaWithQuote";
import MonterreyDynamicContainer from "../theme/monterrey/MonterreyDynamicContainer";
import Monterrey1x1Container from "../theme/monterrey/Monterrey1x1Container";
import BerkeleyTemplate002 from "../theme/berkeley/BerkeleyTemplate002";
import BerkeleyTemplate003 from "../theme/berkeley/BerkeleyTemplate003";
import BerkeleyTemplate004 from "../theme/berkeley/BerkeleyTemplate004";
import BerkeleyTemplate005 from "../theme/berkeley/BerkeleyTemplate005";
import AlpineStoryWithFact from "../theme/alpine/AlpineStoryWithFact";
import AlpineStoryWithSingleImage from "../theme/alpine/AlpineStoryWithSingleImage";
import AlpineFullScreenFact from "../theme/alpine/AlpineFullScreenFact";
import AlpineMediaWithQuote2 from "../theme/alpine/AlpineMediaWithQuote2";
import EverettDonorList from "../theme/everett/EverettDonorList";

const DynamicClasses = ({ children, folderType, fields }) => {
  const getStyles = () => {
    const styles = createUseStyles(
      generateFontSizeClassesFromFields(folderType, fields || {})
    );
    return styles;
  };
  const useStyles = getStyles();
  const classes = useStyles();
  return children(classes);
}

interface IComponentLoop1911State {
  base_folder: any;
  component_index: number;
  component_data: any;
  components: any;
  persistent_data: any[];
  current_slide_cycle_time_left: number;
  useDistinctFolders: boolean;
  childComponentCycleTimes: number[];
  component_key: number;
}

class ComponentLoop1911 extends React.Component<
  IDisplayComponentProps,
  IComponentLoop1911State
> {
  _hasFirstElementLooped = false;
  constructor(props) {
    super(props);

    const base_folder = getBaseFolder(this.props.data);
    const useDistinctFolders = _.get(props, 'data.componentSpecificData.useDistinctFolders', false);
    const showOnlyActive = _.get(props.data, "persistent_settings.data.show_actives_on_presentations", false)
    let components = getChildrenData(
      this.props.data,
      this.props.data.base_folder,
      useDistinctFolders
    ).folders;

    if (showOnlyActive) {
      components = components.filter((component) => component.fields.hasOwnProperty('show_in_presentations') && component.fields.show_in_presentations)
    }

    // Check if this loop's cycle works with the computer clock.
    let childComponentCycleTimes = [];
    let componentIndex = 0;
    let currentSlideTimeLeft = 0;

    const isCycleTimeBasedOnComputerClock = _.get(props, 'data.componentSpecificData.isCycleTimeBasedOnComputerClock', false);
    if (isCycleTimeBasedOnComputerClock) {
      childComponentCycleTimes = components.reduce((acc, curr) => {
        const cycleTime = this.getCycleTimeBasedOnFolderType(curr, useDistinctFolders);
        acc = [...acc, cycleTime];
        return acc;
      }, []);

      const setCurrentSlideIndexCallback = _.get(props, 'data.componentSpecificData.setCurrentSlideIndexCallback', null);
      const setCurrentSlideTimeLeftCallback = _.get(props, 'data.componentSpecificData.setCurrentSlideTimeLeftCallback', null);
      
      // If time is overriden, means that the component loop should not start off with the first element
      // in the array but instead we should determine the index at which the loop should start based on the
      // props.cycle_time which is the time left of the entire loop.
      if (props.cycle_time) {
        const totalChildComponentCycleTime = childComponentCycleTimes.reduce((acc, curr) => {
          acc += curr;
          return acc;
        }, 0);
        // If times are different it means that the loop can potentially start
        // at a different place other than the first element.
        let currentSlideTimeLeft = childComponentCycleTimes[0];
        let currentSlideIndex = 0;
        if (props.cycle_time !== totalChildComponentCycleTime) {
          const childComponentCycleTimesSum = [];
          childComponentCycleTimes.reduce((a, b, i) => { return childComponentCycleTimesSum[i] = a + b}, 0);
          currentSlideIndex = childComponentCycleTimesSum.findIndex(i => props.cycle_time < i);
          currentSlideTimeLeft = childComponentCycleTimesSum[componentIndex] - props.cycle_time;
        }
        if (setCurrentSlideIndexCallback) {
          setCurrentSlideIndexCallback(currentSlideIndex);
        }

        if (setCurrentSlideTimeLeftCallback) {
          setCurrentSlideTimeLeftCallback(currentSlideTimeLeft);
        }
      } else {
        componentIndex = indexFromIntervalArray(childComponentCycleTimes); 
        
        // If there's a callback to notify the current slide index, call it. This is used only in the case 
        // that the cycle is based on computer's clock
        if (setCurrentSlideIndexCallback) {
          setCurrentSlideIndexCallback(componentIndex);
        }
  
        // If there's a callback to notify the current slide time left, call it. This is used only in the case 
        // that the cycle is based on computer's clock
        currentSlideTimeLeft = timeIntervalRemainderArrayMS(childComponentCycleTimes);
        if (setCurrentSlideTimeLeftCallback) {
          setCurrentSlideTimeLeftCallback(currentSlideTimeLeft);
        }

        // log.info(`${_.get(this.props, 'data.componentSpecificData.layout', 'ComponentLoop1911')}: ChildComponent CycleTimes => `, JSON.stringify(childComponentCycleTimes));
      }      
    }

    const component_data = getChildrenFolderAndFolderFoldersDeep(
      {},
      props.data.folder__folders,
      props.data.folders,
      props.data.resources,
      props.data.resource__folders,
      props.data.folder_types,
      props.data.persistent_settings,
      props.data.system_settings,
      components[componentIndex]
    );

    this.state = {
      current_slide_cycle_time_left: currentSlideTimeLeft,
      base_folder: base_folder,
      component_index: componentIndex,
      component_data,
      components,
      persistent_data: null,
      useDistinctFolders,
      childComponentCycleTimes,
      component_key: new Date().getTime(),
    };
  }

  getCycleTimeBasedOnFolderType(folder: IFolder, useDistinctFolders: boolean) {
    let cycleTime = 0;
    const children = getChildrenData(this.props.data, folder.id, useDistinctFolders).folders;
    const folderType = this.props.data.folder_types.find(f => f.name === folder.folder_type);
    switch (folder.folder_type) {
      case FOLDER_TYPE.boston_donor_sea:
        const backgroundsFolder = children.find(f => f.folder_type === FOLDER_TYPE.boston_donor_sea_backgrounds);
        const backgrounds = getChildrenData(this.props.data, backgroundsFolder.id, useDistinctFolders).folders;
        cycleTime = backgrounds.reduce((acc, curr) => {
          acc += _.get(curr, 'fields.cycle_time', 0);
          return acc;
        }, 0)
        break;
      case FOLDER_TYPE.boston_donor_list:
        cycleTime = children.reduce((acc, curr) => {
          acc += _.get(curr, 'fields.cycle_time', _.get(folderType, 'fields.cycle_time.default', 30));
          return acc;
        }, 0);
        break;
      case FOLDER_TYPE.boston_diamond_platinum_list:
        cycleTime = children.reduce((acc, curr) => {
          acc += curr.fields.cycle_time;
          return acc;
        }, 0);
        break;
      default:
        cycleTime = _.get(folder, 'fields.cycle_time', _.get(folderType, 'fields.cycle_time.default', 5));
        break;
    }
    return cycleTime * 1000;
  }

  renderComponent(component_type, component_data, key) {
    const mode = _.get(this.props, "mode", COMPONENT_MODE_CONTEXT.LOCAL);
    const aws = _.get(this.props, "aws", null);
    const aws_bucket = _.get(this.props, "aws_bucket", null);
    const componentFolder = getBaseFolder(component_data);
    const folderType = this.props.data.folder_types.find(f => f.name === componentFolder.folder_type);

    const previewComponentProps: IDisplayComponentProps = {
      aws,
      aws_bucket,
      mode: mode,
      containerWidth: this.props.containerWidth,
      containerHeight: this.props.containerHeight,
      refWidth: this.props.refWidth,
      refHeight: this.props.refHeight,
      data: { ...component_data, componentSpecificData: this.props.data.componentSpecificData },
      webview_signedurls: this.props.webview_signedurls,
      handleEndOfPlay: this.cycleComponent,
      persistent_data: this.state.persistent_data,
      inheritable_fields: this.props.inheritable_fields
    };

    const isCycleTimeBasedOnComputerClock = _.get(this.props, 'data.componentSpecificData.isCycleTimeBasedOnComputerClock', false);
    if (isCycleTimeBasedOnComputerClock) {
      if (!this._hasFirstElementLooped) {
        previewComponentProps.cycle_time = this.state.current_slide_cycle_time_left;
        this._hasFirstElementLooped = true;
        component_type = 'WAIT_COMPONENT';
      } else {
        const timeLeft = timeIntervalRemainderArrayMS(this.state.childComponentCycleTimes);
        previewComponentProps.cycle_time = timeLeft;
      }
    }

    const section = _.get(this.props, 'data.componentSpecificData.section', 'middle');
    const componentKey = `${section}_${component_type}_${componentFolder.id}_${this.state.component_key}`;
    
    switch (component_type) {
      case "memphis_donor_list":
      case "fremont_donor_list_001":
      case FOLDER_TYPE.fremont_donor_list_002:
        const handleEndOfPlay =
          this.state.components.length > 1 ? this.cycleComponent : null;
        return (
          <FreemontDonorList001
            {...previewComponentProps}
            handleEndOfPlay={handleEndOfPlay}
          />
        );
      case "memphis_slides":
      case "brighton_slides":
      case "fremont_1x2_background":
      case "fremont_attract_loop":
      case "fremont_1x4_slideshow":
      case "walterville_landscape_slides":
      case "walterville_portrait_slides":
      case "fremont_donor_list_category":
      case FOLDER_TYPE.springfield_donor_lists:
      case FOLDER_TYPE.springfield_category_with_giving_level:
      case FOLDER_TYPE.springfield_stories:
      case FOLDER_TYPE.springfield_display:
      case FOLDER_TYPE.springfield_portrait_donor_lists:
      case FOLDER_TYPE.springfield_portrait_category_with_giving_level:
      case FOLDER_TYPE.springfield_portrait_stories:
      case FOLDER_TYPE.springfield_portrait_display:
      case FOLDER_TYPE.new_orleans_attract_loop:
      case FOLDER_TYPE.new_orleans_donor_lists:
      case FOLDER_TYPE.new_orleans_donor_list_category:
      case FOLDER_TYPE.new_orleans_stories:
      case FOLDER_TYPE.new_orleans_1x2_slideshow:
      case FOLDER_TYPE.naples_fund_giving_level:
      case FOLDER_TYPE.everett_donor_list_group:
      case FOLDER_TYPE.phoenix_content_stories:
      case FOLDER_TYPE.phoenix_content_checkerboards:
      case FOLDER_TYPE.phoenix_donor_list_group:
      case FOLDER_TYPE.berkeley_content_stories:
      case FOLDER_TYPE.berkeley_donor_list_group:
      case FOLDER_TYPE.tangent_donor_list_group:
      case FOLDER_TYPE.alpine_donor_list_group:
        return <ComponentLoop1911 {...previewComponentProps} />;
      case "los_angeles_slides":
        return <LosAngelesSlides {...previewComponentProps} />;
      case "fremont_1x2_slide":
      case "fremont_1x4_slide":
      case FOLDER_TYPE.new_orleans_1x2_slide:
        return (
          <FremontOverlaySlide1603
            {...previewComponentProps}
            parent_folder={this.state.base_folder}
          />
        );
      case "memphis_slide":
      case "brighton_slide":
      case "walterville_landscape_slide":
      case "walterville_portrait_slide":
      case "los_angeles_slide":
        return <LosAngelesSlide {...previewComponentProps} />;
      case "fremont_template_001":
      case "fremont_template_006":
        return <FremontTemplate001 {...previewComponentProps} />;
      case "fremont_template_002":
        return <FremontTemplate002 {...previewComponentProps} />;
      case "fremont_template_003":
        return <FremontTemplate003 {...previewComponentProps} />;
      case "fremont_template_004":
        return <FremontTemplate004 {...previewComponentProps} />;
      case "fremont_template_005":
        return <FremontTemplate005 {...previewComponentProps} />;
      case "fremont_media_template_001":
        return <FremontMediaTemplate001 {...previewComponentProps} />;
      case "fremont_media_template_002":
        return <FremontMediaTemplate002 {...previewComponentProps} />;
      case FOLDER_TYPE.springfield_template_001:
        return (
          <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps} 
            data={{...previewComponentProps.data, 
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
            <SpringfieldTemplate001 key={key} {...previewComponentProps} />
          </CycleWrapper>
        );
      case FOLDER_TYPE.springfield_template_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <CycleWrapper
                  key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                  {...previewComponentProps}
                  data={{...previewComponentProps.data,
                    componentSpecificData: {
                      ...previewComponentProps.data?.componentSpecificData,
                      useKeyDownCycler: true
                    }
                  }}
                >
                  <SpringfieldTemplate002 key={key} {...previewComponentProps} classes={classes} />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.springfield_template_003:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <CycleWrapper
                  key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                  {...previewComponentProps}
                  data={{...previewComponentProps.data,
                    componentSpecificData: {
                      ...previewComponentProps.data?.componentSpecificData,
                      useKeyDownCycler: true
                    }
                  }}
                >
                  <SpringfieldTemplate003 key={key} {...previewComponentProps} classes={classes} />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.springfield_template_004:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <CycleWrapper
                  key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                  {...previewComponentProps}
                  data={{...previewComponentProps.data,
                    componentSpecificData: {
                      ...previewComponentProps.data?.componentSpecificData,
                      useKeyDownCycler: true
                    }
                  }}
                >
                  <SpringfieldTemplate004 key={key} {...previewComponentProps} classes={classes} />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.springfield_donor_list:
        return <IDCWrap {...previewComponentProps} nestedInRouter={false} >
          <SpringfieldDonorList
            key={`spingfield_donor_list_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
          />
        </IDCWrap>;
      case FOLDER_TYPE.springfield_portrait_template_001:
        return (
          <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
            <SpringfieldPortraitTemplate001 {...previewComponentProps} />
          </CycleWrapper>
        );
      case FOLDER_TYPE.springfield_portrait_template_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <SpringfieldPortraitTemplate002 {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.springfield_portrait_template_003:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <SpringfieldPortraitTemplate003 {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.springfield_portrait_template_004:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <SpringfieldPortraitTemplate004 {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.tangent_fullscreen_media:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <TangentFullScreenMedia {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.tangent_media_with_quote:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <TangentMediaWithQuote {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.tangent_story_with_media:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <TangentStoryWithMedia {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.tangent_story_with_single_image:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <TangentStoryWithSingleImage {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.tangent_donor_list:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes => (
              <TangentDonorList
                {...previewComponentProps}
                classes={classes}
              />
            ))}
          </DynamicClasses>
        )
      case FOLDER_TYPE.springfield_portrait_donor_list:
        return <IDCWrap {...previewComponentProps} nestedInRouter={false} >
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes => (
              <SpringfieldPortraitDonorList
                key={`spingfield_portrait_donor_list_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                classes={classes}
              />
            ))}
          </DynamicClasses>
          </IDCWrap>;
      case FOLDER_TYPE.new_orleans_donor_list:
        return <NewOrleansDonorList {...previewComponentProps} />;
      case FOLDER_TYPE.new_orleans_template_001:
        return <NewOrleansTemplate001 {...previewComponentProps} />;
      case FOLDER_TYPE.new_orleans_template_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <NewOrleansTemplate002
                  key={`new_orleans_template_002_${key}_${new Date().getTime()}`}
                  {...previewComponentProps}
                  classes={classes}
                />
            )}
          </DynamicClasses>
        )
        // return <NewOrleansTemplate002 {...previewComponentProps} />;
      case FOLDER_TYPE.new_orleans_template_003:
        return <NewOrleansTemplate003 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_template_001:
        return <EugeneTemplate001 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_template_002:
        return <EugeneTemplate002 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_template_003:
        return <EugeneTemplate003 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_template_004:
        return <EugeneTemplate004 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_template_005:
        return <EugeneTemplate005 {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_slide:
        return <EugeneSlide {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_stories:
        return <EugeneStories {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_menu:
        return <EugeneMenu {...previewComponentProps} />;
      case FOLDER_TYPE.eugene_donor_lists:
        return <EugeneDonorListsOverview {...previewComponentProps} />;
      case FOLDER_TYPE.coburg_slide:
        return <CoburgSlide {...previewComponentProps} />;
      case FOLDER_TYPE.jasper_slides:
        return <ComponentLoop1911 {...previewComponentProps} />;
      case FOLDER_TYPE.jasper_slide:
        return <Slide1911 {...previewComponentProps} />;
      case FOLDER_TYPE.boston_media_with_quote:
        if (section === 'middle') {
          return <BostonMediaWithQuote {...previewComponentProps} />
        }
        return <BostonVerticalBackgroundLoop 
          {...previewComponentProps}
        />
      case FOLDER_TYPE.boston_fullscreen_media:
        if (section === 'middle') {
          return <Slide1911
            {...previewComponentProps}
            data={{...previewComponentProps.data, slotNames: ['media_horizontal']}}
          />;
        }
        return <BostonVerticalBackgroundLoop 
          {...previewComponentProps}
        />
      case FOLDER_TYPE.boston_donor_sea:
        if (section === 'middle') {
          return <BostonDonorSea key={this.state.component_key} {...previewComponentProps} />
        }
        return <BostonVerticalBackgroundLoop 
          {...previewComponentProps}
        />
      case FOLDER_TYPE.naples_fullscreen_media:
      case FOLDER_TYPE.boston_donor_sea_background:
      case FOLDER_TYPE.everett_donor_sea_background:
      case FOLDER_TYPE.phoenix_donor_sea_background:
      case FOLDER_TYPE.berkeley_donor_sea_background:
      case FOLDER_TYPE.glenwood_donor_sea_background:
      case FOLDER_TYPE.glenwood_slide:
        const slidesSlotName = _.get(this.props, 'data.slidesSlotName', null);
        const showPlaceholder = _.get(this.props, 'data.componentSpecificData.showPlaceholder', false);
        const fieldType = slidesSlotName ? folderType.fields[slidesSlotName[0]] : null;
        if (this.props.data.slidesSlotName) {
          return <Slide1911
            {...previewComponentProps}
            key={this.state.component_key} // This fixes the issue of the slide not updating when the slide changes in the attract loop for glenwood
            data={{
              ...previewComponentProps.data, 
              slotNames: slidesSlotName,
              showPlaceholder,
              placeholder: "Image",
              fieldType
            }}
          />;
        }
        return <Slide1911 key={this.state.component_key} {...previewComponentProps} />;
      case FOLDER_TYPE.naples_fund_folder_blue:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <NaplesFundFolder
                key={`naples_fund_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                classes={classes}
                defaultBackgroundColor={"#1c2858"}
                showLogo={true}
                defaultDonorColor={"#1c2858"}
              />)}
          </DynamicClasses>
        )
      case FOLDER_TYPE.naples_fund_folder_red:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <NaplesFundFolder
                {...previewComponentProps}
                key={`naples_fund_${key}_${new Date().getTime()}`}
                classes={classes}
                defaultBackgroundColor={"#c5aa75"}
                defaultDonorColor={"#c5aa75"}
                showLogo={false}
              />)}
          </DynamicClasses>
        )
      case FOLDER_TYPE.naples_template_blue_001:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <NaplesTemplateBlue001 {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        )
      case FOLDER_TYPE.naples_template_red_001:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <NaplesTemplateRed001 {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.naples_template_blue_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <NaplesTemplateBlue002 {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.naples_template_red_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <NaplesTemplateRed002 {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.everett_donor_sea:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <EverettDonorSea
                  key={this.state.component_key}
                  {...previewComponentProps}
                  classes={classes}
                  data={{
                    ...previewComponentProps.data,
                    componentSpecificData: {
                      ...previewComponentProps.data?.componentSpecificData,
                      slidesSlotName: ["image"],
                      horizontal_screens: 2,
                      vertical_screens: 2,
                    }
                  }}
                  fontSize={16}
                />
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.everett_story_with_quote:
      case FOLDER_TYPE.everett_story:
        return (
            <DynamicClasses 
              folderType={folderType} 
              fields={componentFolder?.fields}
            >
              {(classes => 
                <CycleWrapper
                  key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                    {...previewComponentProps} 
                    data={{...previewComponentProps.data, 
                      componentSpecificData: {
                        ...previewComponentProps.data?.componentSpecificData,
                        useKeyDownCycler: true
                      }
                    }}
                  >
                    <EverettBaseTemplate folderType={folderType} {...previewComponentProps} classes={classes} />
                </CycleWrapper>)}
            </DynamicClasses>
        );
      case FOLDER_TYPE.everett_full_screen_image:
        return (
            <DynamicClasses 
              folderType={folderType} 
              fields={componentFolder?.fields}
            >
              {(classes => 
                <CycleWrapper
                  key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                    {...previewComponentProps} 
                    data={{...previewComponentProps.data, 
                      componentSpecificData: {
                        ...previewComponentProps.data?.componentSpecificData,
                        useKeyDownCycler: true
                      }
                    }}
                  >
                  <EverettFullScreen folderType={folderType} {...previewComponentProps} classes={classes} />
                </CycleWrapper>
              )}
            </DynamicClasses>
        );
      case FOLDER_TYPE.everett_full_screen_image_with_caption:
        return (
          <DynamicClasses 
            folderType={folderType} 
            fields={componentFolder?.fields}
          >
            {(classes => 
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                  {...previewComponentProps} 
                  data={{...previewComponentProps.data, 
                    componentSpecificData: {
                      ...previewComponentProps.data?.componentSpecificData,
                      useKeyDownCycler: true
                    }
                  }}
                >
                <EverettFullScreen folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.everett_donor_list:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <EverettDonorList
                  key={`everett_donor_list_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.everett_donor_list_with_quote:
        return (          
            <DynamicClasses 
              folderType={folderType} 
              fields={componentFolder?.fields}
            >
              {(classes => 
                  <EverettBaseTemplate folderType={folderType} {...previewComponentProps} classes={classes} />
              )}
            </DynamicClasses>
        );
      case FOLDER_TYPE.phoenix_checkerboard:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <PhoenixCheckerboard {...previewComponentProps} classes={classes} />
            )}
          </DynamicClasses>
        );
  
      case FOLDER_TYPE.phoenix_donor_sea:
        return <PhoenixDonorSea
          key={this.state.component_key}
          {...previewComponentProps}
          folderType={folderType}
        />
      case FOLDER_TYPE.phoenix_story:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <PhoenixStory folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.phoenix_fullscreen:
        return (
            <DynamicClasses
                folderType={folderType}
                fields={componentFolder?.fields}
            >
              {(classes =>
                  <CycleWrapper
                      key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                      {...previewComponentProps}
                      data={{...previewComponentProps.data,
                        componentSpecificData: {
                          ...previewComponentProps.data?.componentSpecificData,
                          useKeyDownCycler: true
                        }
                      }}
                  >
                    <PhoenixFullScreen folderType={folderType} {...previewComponentProps} classes={classes} />
                  </CycleWrapper>)}
            </DynamicClasses>
        );
      case FOLDER_TYPE.phoenix_message:
        return (
            <DynamicClasses
                folderType={folderType}
                fields={componentFolder?.fields}
            >
              {(classes =>
                  <CycleWrapper
                      key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                      {...previewComponentProps}
                      data={{...previewComponentProps.data,
                        componentSpecificData: {
                          ...previewComponentProps.data?.componentSpecificData,
                          useKeyDownCycler: true
                        }
                      }}
                  >
                    <PhoenixMessage folderType={folderType} {...previewComponentProps} classes={classes} />
                  </CycleWrapper>)}
            </DynamicClasses>
        );
        case FOLDER_TYPE.phoenix_donor_list:
          return (
            <DynamicClasses
              folderType={folderType}
              fields={componentFolder?.fields}
            >
              {(classes =>
                <PhoenixDonorList
                  key={`phoenix_donor_list_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
              )}
            </DynamicClasses>
          );
      case FOLDER_TYPE.berkeley_donor_sea:
        return <BerkeleyDonorSea
          key={this.state.component_key}
          {...previewComponentProps}
          folderType={folderType}
        />
      case FOLDER_TYPE.berkeley_template_001:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <BerkeleyTemplate001 folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.berkeley_template_002:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <BerkeleyTemplate002 folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.berkeley_template_003:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <BerkeleyTemplate003 folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.berkeley_template_004:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <BerkeleyTemplate004 folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.berkeley_template_005:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <CycleWrapper
                key={`cycle_wrapper_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                data={{...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data?.componentSpecificData,
                    useKeyDownCycler: true
                  }
                }}
              >
                <BerkeleyTemplate005 folderType={folderType} {...previewComponentProps} classes={classes} />
              </CycleWrapper>)}
          </DynamicClasses>
        );
      case FOLDER_TYPE.berkeley_donor_list:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <BerkeleyDonorList
                  key={`phoenix_donor_list_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_donor_list:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
                <AlpineDonorList
                  key={`alpine_donor_list_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_media_with_quote_2:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineMediaWithQuote2
                  key={`alpine_media_with_quote_2_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_one_screen_media:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineOneScreenMedia
                  key={`alpine_one_screen_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_profile_with_media_story:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineProfileWithMedia
                  key={`alpine_profile_with_media_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_story_with_media:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineStoryWithMedia
                  key={`alpine_story_with_media_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_story_with_fact:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineStoryWithFact
                  key={`alpine_story_with_fact_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_story_with_single_image:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineStoryWithSingleImage
                  key={`alpine_story_with_single_media_${key}_${new Date().getTime()}`}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.alpine_fullscreen_fact:
        return (
          <DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
            <CycleWrapper
            key={`cycle_wrapper_${key}_${new Date().getTime()}`}
            {...previewComponentProps}
            data={{...previewComponentProps.data,
              componentSpecificData: {
                ...previewComponentProps.data?.componentSpecificData,
                useKeyDownCycler: true
              }
            }}
          >
                <AlpineFullScreenFact
                  key={`alpine_fullscreen_fact_${key}_${new Date().getTime()}`}
                  folderType={folderType}
                  {...previewComponentProps}
                  classes={classes}
                />
                </CycleWrapper>
            )}
          </DynamicClasses>
        );
      case FOLDER_TYPE.boston_checkerboard:
        if (section === 'middle') {
          return <BostonCheckerboard {...previewComponentProps} />
        }
        return <BostonVerticalBackgroundLoop 
          {...previewComponentProps}
        />
      case FOLDER_TYPE.boston_donor_list:
        const isDiamondList = this.state.component_index === 0;
        if (section === 'middle') {
          return <Boston3x2DiamondPlatinumList 
            {...previewComponentProps } 
            data={{ ...previewComponentProps.data, 
              componentSpecificData: { 
                ...previewComponentProps.data.componentSpecificData, 
                is_diamond_list: isDiamondList
              } 
            }}
          />;          
        }
        const bostonDonorListSlidesSlotName = _.get(this.props, 'data.slidesSlotName', null);
        return <Slide1911
          {...previewComponentProps}
          data={{
            ...previewComponentProps.data, 
            slotNames: bostonDonorListSlidesSlotName,
            showPlaceholder: _.get(this.props, 'data.componentSpecificData.showPlaceholder', false),
            placeholder: "Image",
            fieldType: bostonDonorListSlidesSlotName ? folderType.fields[bostonDonorListSlidesSlotName[0]] : null
          }}
        />;
      case FOLDER_TYPE.monterrey_layout:
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
            <MonterreyLayout
              key={key}
              {...previewComponentProps}
              classes={classes}
              data={{
                ...previewComponentProps.data,
                componentSpecificData: {
                  ...previewComponentProps.data.componentSpecificData,
                  siblings: _.get(this.props, 'data.componentSpecificData.siblings', 1)
                }
              }}
            />
          )}
        </DynamicClasses>);
      case FOLDER_TYPE.monterrey_media_blank_1:
      case FOLDER_TYPE.monterrey_media_blank_2:
      case FOLDER_TYPE.monterrey_media_blank_3:
      case FOLDER_TYPE.monterrey_media_blank_4:
      case FOLDER_TYPE.monterrey_media_blank_5:
      case FOLDER_TYPE.monterrey_donor_list:
      case FOLDER_TYPE.monterrey_intro_story:
      case FOLDER_TYPE.monterrey_content_donor_lists:
        const slots = folderType.slots

        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
              <MonterreyDynamicContainer
                key={key}
                {...previewComponentProps}
                containerWidth={(previewComponentProps.containerWidth / 5) * slots}
                classes={classes}
                data={{
                  ...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data.componentSpecificData,
                    siblings: _.get(this.props, 'data.componentSpecificData.siblings', 1)
                  }
                }}
              />
          )}
        </DynamicClasses>);

      case FOLDER_TYPE.monterrey_story_with_fact:
      case FOLDER_TYPE.monterrey_story_media_with_quote:
      case FOLDER_TYPE.monterrey_fullscreen_fact_story:
      case FOLDER_TYPE.monterrey_profile_with_media_story:
      case FOLDER_TYPE.monterrey_story_with_media:
        const storySlots = folderType.slots
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
              <Monterrey1x1Container
                key={key}
                {...previewComponentProps}
                containerWidth={(previewComponentProps.containerWidth / 5) * storySlots}
                classes={classes}
                data={{
                  ...previewComponentProps.data,
                  componentSpecificData: {
                    ...previewComponentProps.data.componentSpecificData,
                    siblings: _.get(this.props, 'data.componentSpecificData.siblings', 1)
                  }
                }}
              />
          )}
        </DynamicClasses>);

        case FOLDER_TYPE.monterrey_content_layouts:
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
            <Monterrey5x1Container
              key={`layout_${key}_${new Date().getTime()}`}
              {...previewComponentProps}
              classes={classes}
            />
          )}
        </DynamicClasses>);
        case FOLDER_TYPE.monterrey_donor_list:
          return (<DynamicClasses
            folderType={folderType}
            fields={componentFolder?.fields}
          >
            {(classes =>
              <MonterreyDonorLists
                key={componentKey}
                {...previewComponentProps}
                classes={classes}
              />
            )}
          </DynamicClasses>);
      case FOLDER_TYPE.monterrey_control_panel_attract_loop_slide:
        return <Slide1911 {...previewComponentProps} />;
      case FOLDER_TYPE.marin_layout:
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
              <MarinLayout
                {...previewComponentProps}
                classes={classes}
              />
          )}
        </DynamicClasses>);

      case FOLDER_TYPE.marin_content_layouts:
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
              <Marin8x1Container
                key={`layout_${key}_${new Date().getTime()}`}
                {...previewComponentProps}
                classes={classes}
              />
          )}
        </DynamicClasses>);
      case FOLDER_TYPE.marin_donor_list:
        return (<DynamicClasses
          folderType={folderType}
          fields={componentFolder?.fields}
        >
          {(classes =>
              <MarinDonorLists
                {...previewComponentProps}
                classes={classes}
              />
          )}
        </DynamicClasses>);
      case 'WAIT_COMPONENT':
        return <WaitComponent {...previewComponentProps} />
      case FOLDER_TYPE.boston_diamond_platinum_list:
        if (section === 'middle') {
          const componentOverrides = _.get(this.props, 'data.componentSpecificData.component_overrides', []);
          if (componentOverrides.length)  {
            const foundOverride = componentOverrides.find(o => o.folder_type === FOLDER_TYPE.boston_diamond_platinum_list);
            if (foundOverride) {
              const Component = foundOverride.component;
              return <Component 
                {...previewComponentProps}
                data={{
                  ...previewComponentProps.data, 
                  componentSpecificData: { 
                    ...previewComponentProps.data.componentSpecificData,
                    isCycleTimeBasedOnComputerClock: false
                  },
                }} 
              />
            }
          }
        }
        return <BostonVerticalBackgroundLoop 
          {...previewComponentProps}
        />
      default:
        console.log(
          "component_type: " +
            component_type +
            " not handled in ComponentLoop1911"
        );
        return <span></span>;
    }
  }

  updateSetComponentData = (new_component_index) => {
    const component_data = getChildrenFolderAndFolderFoldersDeep(
      {},
      this.props.data?.folder__folders,
      this.props.data?.folders,
      this.props.data?.resources,
      this.props.data?.resource__folders,
      this.props.data?.folder_types,
      this.props.data?.persistent_settings,
      this.props.data?.system_settings,
      this.state.components[new_component_index],
    );
    this.setState({
      component_index: new_component_index,
      component_data,
      component_key: new Date().getTime(),
    });
  };

  cycleComponent = (direction = 'forward', persistent_data = null) => {
    const isCycleTimeBasedOnComputerClock = _.get(this.props, 'data.componentSpecificData.isCycleTimeBasedOnComputerClock', false);
    if (isCycleTimeBasedOnComputerClock) {
      const timeLeft = timeIntervalRemainderArrayMS(this.state.childComponentCycleTimes);
      if (timeLeft < 250) {
        setTimeout(() => {
          this.cycleComponent('forward', persistent_data);
        }, timeLeft);
        return;
      }
    }
    // We save the persistent data here.
    if (persistent_data) {
      let foundPersistentDataElementIndex = null;
      let newPersistentData = [];
      if (this.state.persistent_data) {
        foundPersistentDataElementIndex = this.state.persistent_data
          .findIndex(d => d.id === persistent_data.id && d.type === persistent_data.type);
        newPersistentData = [...this.state.persistent_data];
      }
      if (foundPersistentDataElementIndex > -1 && foundPersistentDataElementIndex !== null) {
        newPersistentData[foundPersistentDataElementIndex] = persistent_data;
      } else {
        newPersistentData.push(persistent_data);
      }
      this.setState({ persistent_data: newPersistentData });
    }

    let new_component_index = 0;
    if (direction === 'forward') {
      if (this.state.component_index < this.state.components.length - 1) {
        new_component_index = this.state.component_index + 1;
      } else {
        if (this.props.handleEndOfPlay) {
          return this.props.handleEndOfPlay();
        }
      }
    } else {
      new_component_index = this.state.component_index > 0 ? this.state.component_index - 1 : this.state.components.length - 1;
    }

    this.updateSetComponentData(new_component_index);
  };

  render() {
    // set key by default to folder_type override with force_remount to id
    const component = this.state.components[this.state.component_index];

    if (!component) {
      return null;
    }

    let key = component.folder_type;
    if (_.get(component, "fields.force_remount", true) === true) {
      key = component.id;
    }

    let transitionclass = TRANSITION_CLASS.TRASITION_FADE;
    if (
      _.has(component, "fields.transition") &&
      component.fields.transition !== "parent"
    ) {
      transitionclass = component.fields.transition;
    } else {
      if (_.get(this.state, "base_folder.fields.transition", null) !== null) {
        transitionclass = this.state.base_folder.fields.transition;
      }
    }

    // If the component is the same, force a remount.
    let past_component = this.state.components[this.state.component_index - 1];
    if ((past_component && past_component.id === component.id) || this.state.components.length <= 2) {
      key = component.id + "_" + new Date().getTime();
    }

    return (
      <div>
        <TransitionGroup>
          <CSSTransition key={key} timeout={5000} classNames={transitionclass}>
            <div key={key}>
              {this.renderComponent(
                component.folder_type,
                this.state.component_data,
                key
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

export default ComponentLoop1911;
