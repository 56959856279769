import { TREE_MODE_RESOURCE } from "../components/constants";
import {
  ADD_RESOURCES,
  ADD_RESOURCE_NOTES,
  ADD_RESOURCE_TAGS,
  ADD_TEXT_RESOURCES,
  CLEAN_RESOURCES,
  DELETE_BULK_RESOURCES,
  DELETE_RESOURCES,
  DELETE_RESOURCE_NOTES,
  DELETE_RESOURCE_TAGS,
  GET_RESOURCE_NOTES,
  GET_RESOURCE_TAGS,
  TOGGLE_FOLDER_EXPANDED,
  TOGGLE_MODAL_TREE_FOLDER_EXPANDED,
  UPDATE_BULK_RESOURCES,
  UPDATE_FILE_RESOURCE,
  UPDATE_RESOURCES,
  UPDATE_RESOURCE_NOTES,
  UPDATE_TEXT_RESOURCE,
  UPDATE_UPLOADED_RESOURCES_RESOURCE_TREE,
  MOVE_BULK_RESOURCES
} from "./actionTypes";

export const addResources = (
  resources,
  parent_folder__folder_id,
  onSuccess?,
  onFail?
) => ({
  type: ADD_RESOURCES,
  payload: { resources, parent_folder__folder_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateResources = (data, onSuccess?, onFail?) => ({
  type: UPDATE_RESOURCES,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addTextResources = (
  resources,
  parent_folder__folder_id,
  onSuccess?,
  onFail?
) => ({
  type: ADD_TEXT_RESOURCES,
  payload: { resources, parent_folder__folder_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateTextResource = (resource_id, data, onSuccess?, onFail?) => ({
  type: UPDATE_TEXT_RESOURCE,
  payload: { resource_id, data },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateFileResource = (resource_id, data, onSuccess?, onFail?) => ({
  type: UPDATE_FILE_RESOURCE,
  payload: { resource_id, data },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateUploadedResources = (
  fileNames,
  folderId,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_UPLOADED_RESOURCES_RESOURCE_TREE,
  payload: { fileNames, folderId },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteResources = (resource_ids, onSuccess?, onFail?) => ({
  type: DELETE_RESOURCES,
  payload: { resource_ids },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const cleanResources = (
  removed_resources,
  removed_resource_folders,
  resource__folders,
  onSuccess?,
  onFail?
) => ({
  type: CLEAN_RESOURCES,
  payload: { removed_resources, removed_resource_folders, resource__folders },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const toggleResourceFolderExpanded = (
  treeId,
  doOpenClose,
  doSelect,
  treemode,
  onSuccess?,
  onFail?
) => ({
  // naming?
  type: TOGGLE_FOLDER_EXPANDED,
  payload: { treeId, doOpenClose, doSelect, treemode: treemode },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const toggleModalResourceFolderExpanded = (
  treeId,
  doOpenClose,
  doSelect,
  onSuccess?,
  onFail?
) => ({
  // naming?
  type: TOGGLE_MODAL_TREE_FOLDER_EXPANDED,
  payload: { treeId, doOpenClose, doSelect },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getTagResource = (resource_id, onSuccess?, onFail?) => ({
  type: GET_RESOURCE_TAGS,
  payload: { resource_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteTagResource = (resource_tag_id, onSuccess?, onFail?) => ({
  type: DELETE_RESOURCE_TAGS,
  payload: { resource_tag_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addTagResource = (tag, onSuccess?, onFail?) => ({
  type: ADD_RESOURCE_TAGS,
  payload: tag,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getNotesResource = (resource_id, onSuccess?, onFail?) => ({
  type: GET_RESOURCE_NOTES,
  payload: { resource_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addNotesResource = (note, onSuccess?, onFail?) => ({
  type: ADD_RESOURCE_NOTES,
  payload: note,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteNotesResource = (resource_note_id, onSuccess?, onFail?) => ({
  type: DELETE_RESOURCE_NOTES,
  payload: { id: resource_note_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateNotesResource = (data, onSuccess?, onFail?) => ({
  type: UPDATE_RESOURCE_NOTES,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateBulkResources = (resources, onSuccess?, onFail?) => ({
  type: UPDATE_BULK_RESOURCES,
  payload: { resources },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteBulkResources = (resourceIds, onSuccess?, onFail?) => ({
  type: DELETE_BULK_RESOURCES,
  payload: { resourceIds },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const moveBulkResources = (resourceFolderIds, folderFolderId, onSuccess?, onFail?) => ({
  type: MOVE_BULK_RESOURCES,
  payload: { resourceFolderIds, folderFolderId },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});