import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { name } from "../standardized_fields";

export const boston_content_diamond_platinum_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.boston_content_diamond_platinum_lists,
  display_name: "Boston Platinum Diamond Lists",
  acceptable_folder_types: [FOLDER_TYPE.boston_diamond_platinum_list],
  fields: {
    name
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  }
};
