export default {
  field_groups: {
    default: {
      title: 'General',
      default_expanded: true,
    },
  },
  fields: {
    modifiedName: {
      type: 'text',
      title: 'Alias'
    }
  }
};