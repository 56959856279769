import { FOLDER_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";

export const boston_content_donor_seas: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.boston_content_donor_seas,
  display_name: "Boston Donor Seas",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor_sea],
  fields: {
    name
  }
};
