import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from "react";
import {CONTAIN, COVER, STRETCH} from "../../shared/constants";

export default class ImageLoader extends Component {
  constructor(props) {
    super(props);
    this._retryTimeoutHandler = null;
    this._timeoutHandler = null;
    this._tries = 0;
    this._willUnmount = false;
    this.state = {
      loaded: false,
      imgStyle: {
        width: "100%",
      },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.url !== this.props.url) {
      return true;
    }

    if (nextState.loaded !== this.state.loaded) {
      return true;
    }

    if (nextState.imgStyle !== this.state.imgStyle) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    const loadedPromise = new Promise(resolve => {
      const image = new Image();
      image.addEventListener('load', () => resolve(true));
      image.src = this.props.url;
    });
    loadedPromise.then(() => {
      if (!this._willUnmount) {
        this.setState({loaded: true})
      }
    });
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props !== prevProps) {
      this.updateImageStyleInState();
    }
  }

  componentWillUnmount() {
    this.setState({loaded: false});
    this._willUnmount = true;
    clearTimeout(this._retryTimeoutHandler);
    clearTimeout(this._timeoutHandler);
  }

  onImageLoad = ({target: img}) => {
    let newStyle;
    const {imgStyle} = this.state;
    const {imgCover, maxHeight, maxWidth} = this.props;
    const {naturalWidth, naturalHeight} = img;

    const containerRatio = maxHeight / maxWidth;
    const imageRatio = naturalHeight / naturalWidth;

    if (imgCover === CONTAIN) {
      if (maxHeight < maxWidth) {
        if (imageRatio < containerRatio ) {
          newStyle = {
            ...imgStyle,
            width: "100%",
            height: "auto",
          };
        } else {
          newStyle = {
            ...imgStyle,
            height: "100%",
            width: "auto",
          };
        }
      } else {
        if (naturalHeight < naturalWidth) {
          newStyle = {
            ...imgStyle,
            width: "100%",
            height: "auto",
          };
        } else {
          newStyle = {
            ...imgStyle,
            height: "100%",
            width: 'auto',
          };
        }
      }

      this.setState({
        imgStyle: newStyle,
      });
    } else {
      this.updateImageStyleInState();
    }
  };

  updateImageStyleInState = () => {
    const displayImage = this.state.loaded ? "inline-block" : "none";
    const imgStyle = {
      display: displayImage,
      maxWidth: this.props.maxWidth,
      maxHeight: this.props.maxHeight,
    };

    if (this.props.imgCover === STRETCH || this.props.imgCover === COVER) {
      imgStyle.width = this.props.maxWidth;
      imgStyle.height = this.props.maxHeight;
    }
    if (this.props.imgCover === COVER) {
      imgStyle.objectFit = "cover";
      imgStyle.width = "100%";
      imgStyle.height = "100%";
    } else if (this.props.imgCover === CONTAIN) {
      // in this point Dont know image size
    }

    this.setState({
      imgStyle,
    });
  };

  render() {
    const displayImage = this.state.loaded ? "inline-block" : "none";
    const imgStyle = {...this.state.imgStyle, display: displayImage};

    return (
      <>
        {this.state.loaded ? (
          <img
            src={this.props.url}
            style={imgStyle}
            height={this.state.imgStyle.height}
            width={this.state.imgStyle.width}
            onLoad={this.onImageLoad}
            alt={"loading"}
          />
        ) : (
          <div className="preview-gallery_spinner-disabled">
            <FontAwesomeIcon
              style={{cursor: "pointer"}}
              icon={["fas", "spinner"]}
              spin
              size={this.props.spinnerSize ? this.props.spinnerSize : 'sm'}
            />
          </div>
        )}
      </>
    );
  }
}
