import React, { useEffect } from "react";

interface IRichTextRenderProps {
  className?: string;
  style?: {};
  containerHeight?: number;
  htmlString: string;
}

const RichTextRender: React.FC<IRichTextRenderProps> = (props) => {
  useEffect(() => {
    const elements = document.querySelectorAll('.ql-editor-preview > .ql-editor')
    let spaceElement =  document.createElement('span')
    if (elements.length) {
      // @ts-ignore
      for (const element of elements) {
        const paragraph = document.createElement('p')
        for (const child of element.children) {
          if (child.innerHTML !== "<br>" && spaceElement.innerText !== " ") {
            for (const child1 of child.children) {
              if (child1) {
                for (const attribute of child1.attributes) {
                  spaceElement.setAttribute(attribute.name, attribute.value)
                }
                spaceElement.innerText = " "
                paragraph.appendChild(spaceElement)
                break
              }
            }
          }
        }
        for (const child of element.children) {
          if (child.innerHTML === "<br>") {
            const newElem = paragraph.cloneNode(true)
            element.insertBefore(newElem, child)
            child.remove()
          }
        }
        spaceElement =  document.createElement('span')
      }
    }
  }, [props.htmlString])

  if (props.containerHeight) {
    return (
      <div className={"ql-editor-preview " + (props.className || "")}>
        <div
          className="ql-editor"
          style={
            !props.style
              ? { fontSize: props.containerHeight / 1000, padding: 0 }
              : {
                  fontSize: props.containerHeight / 1000,
                  padding: 0,
                  ...props.style,
                }
          }
          dangerouslySetInnerHTML={{ __html: props.htmlString }}
        />
      </div>
    );
  }
  return (
    <div className={"ql-editor-preview " + (props.className || "")}>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: props.htmlString }}
        style={props.style ? { ...props.style, padding: 0 } : { padding: 0 }}
      />
    </div>
  );
};

export default RichTextRender;
