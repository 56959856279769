export default {
  field_groups: {
    default: {
      title: 'Permission',
      default_expanded: true,
    },
  },
  fields: {
    name: {
      type: 'text',
      title: 'Name',
      validation: { rules: [{ name: 'isRequired' }] },
      default: '',
    },
    scope: {
      type: 'select',
      title: 'Scope',
      validation: {
        rules: [{ name: 'isRequired' }],
      },
      // default: "kitchen",
      options: [
        {
          title: 'Company',
          value: 'company',
        },
        {
          title: 'Project',
          value: 'project',
        },
      ],
    },
    area: {
      type: 'select',
      title: 'Area',
      validation: {
        rules: [{ name: 'isRequired' }],
      },
      options: [
        {
          title: 'Applied Content',
          value: 'applied_content',
        },
        {
          title: 'Audit log',
          value: 'audit_log',
        },
        {
          title: 'Backup',
          value: 'backup',
        },
        {
          title: 'CSV',
          value: 'csv',
        },
        {
          title: 'Company',
          value: 'company',
        },
        {
          title: 'Resource',
          value: 'resource',
        },
        {
          title: 'Field',
          value: 'field',
        },
        {
          title: 'Folder',
          value: 'folder',
        },
        {
          title: 'Location',
          value: 'location',
        },
        {
          title: 'User',
          value: 'user',
        },
      ],
    },
    action_type: {
      type: 'select',
      title: 'Action Type',
      validation: {
        rules: [{ name: 'isRequired' }],
      },
      // default: "kitchen",
      options: [
        {
          title: 'Grant',
          value: 'grant',
        },
        {
          title: 'Restrict',
          value: 'restrict',
        },
      ],
    },
    action: {
      type: 'text',
      title: 'Action',
      validation: { rules: [{ name: 'isRequired' }] },
      default: '',
    },
  },
};
