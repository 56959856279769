import React, { useState, useEffect } from "react";
import TextAreaInput from "./TextAreaInput";
import Modal from "antd/lib/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ITextAreaModalProps {
  onBlur: any;
  showLabel: boolean;
  fieldName: string;
  defaultValue: string;
  title: string;
  onChange: any;
  showCharCount: boolean;
  canUpdate: boolean;
}

const TextAreaModal: React.FC<ITextAreaModalProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [text, setText] = useState(props.defaultValue);

  useEffect(() => {
    setText(props.defaultValue);
  }, [props.defaultValue]);

  const onOk = () => {
    props.onBlur(props.fieldName, text);
    setModalOpen(false);
  };

  return (
    <>
      <a href="#" onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon className="fa" icon={["fas", "external-link"]} />
      </a>
      <Modal
        width={360}
        visible={modalOpen}
        onOk={onOk}
        onCancel={() => setModalOpen(false)}
        title={
          <React.Fragment>
            Edit {props.title}
          </React.Fragment>
        }
      >
        <TextAreaInput
          title={props.title}
          fieldName={props.fieldName}
          onChange={(fieldName, value) => props.onChange(fieldName, value)}
          onBlur={(ev) => props.onBlur(ev)}
          showCharCount={props.showCharCount}
          defaultValue={text}
          showLabel={props.showLabel}
          canUpdate={props.canUpdate}
          settings={{ autosize: { minRows: 12, maxRows: 12 } }}
        />
      </Modal>
    </>
  );
};

export default TextAreaModal;
