import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const springfield_portrait_category_with_giving_level: IFolderType = {
  folder_category: FOLDER_CATEGORY.GROUP,
  name: FOLDER_TYPE.springfield_portrait_category_with_giving_level,
  display_name: "List with Giving Level",
  acceptable_folder_types: [FOLDER_TYPE.springfield_portrait_donor_list],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    cycle: {
      type: "integer",
      title: "Default Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 1000 }] },
      default: 30
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    component_name: COMPONENT_NAME.SpringfieldPortraitContainer,
    aspect_ratio: 0.5625,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
