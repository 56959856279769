import React, {Component} from 'react';
import {Row, Col, Button, Modal, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
import {
  MEDIA_PROGRESS_STATUS_DONE,
  MEDIA_PROGRESS_STATUS_ERROR,
  MEDIA_PROGRESS_STATUS_PROCESSING
} from './MediaProgressStatus';
import {
  NOTIFICATION_DISPLAY_ALERT_TYPE, NOTIFICATION_FOLDER_STATUS_TYPE,
} from '../../../../shared/constants';

class MediaProgressStatusRow extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._componentIsMounted = true;
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
    }
  }

  getRenderedStatusIcon = () => {
    const {status} = this.props;
    switch (status) {
      case MEDIA_PROGRESS_STATUS_PROCESSING:
        return <FontAwesomeIcon className="fa-lg media-progress-status_icon-loading" style={{cursor: 'pointer'}}
                                icon={['fas', 'spinner']} spin/>
      case MEDIA_PROGRESS_STATUS_ERROR:
        return <FontAwesomeIcon className="fa-lg media-progress-status_icon-error" style={{cursor: 'pointer'}}
                                icon={['fas', 'check-circle']}/>
      case MEDIA_PROGRESS_STATUS_DONE:
        return <FontAwesomeIcon className="fa-lg media-progress-status_icon-complete" style={{cursor: 'pointer'}}
                                icon={['fas', 'check-circle']}/>

    }
  };

  getStatusColor = status => {
    switch (status) {
      case 'yellow':
        return '#e5e68f';
      case 'red':
        return 'red';
      default:
        return 'grey';
    }
  };

  getRenderTypeIcon = () => {
    const {type, status} = this.props;

    switch (type) {
      case NOTIFICATION_DISPLAY_ALERT_TYPE:
        const color = this.getStatusColor(status);
        return <FontAwesomeIcon size="1x" style={{cursor: 'pointer', color: color}}
                                icon={['fa', 'exclamation-circle']}/>
      case NOTIFICATION_FOLDER_STATUS_TYPE:
        return <FontAwesomeIcon size="1x" style={{cursor: 'pointer'}} icon={['fa', 'upload']}/>
      default:
        return <FontAwesomeIcon size="1x" style={{cursor: 'pointer'}} icon="image"/>
    }
  };

  render() {
    const {name, index} = this.props;

    const iconRendered = this.getRenderedStatusIcon();
    const iconTypeRendered = this.getRenderTypeIcon();
    return (
      <Tooltip title={name}>
        <Row className="media-progress-status_body-row" key={`status-item-${index}`}>
          <Col span={2} className="media-progress-status_body-col">
            {iconTypeRendered}
          </Col>
          <Col span={20} className="media-progress-status_body-col media-progress-status_body-col-text">
            {name}
          </Col>
          <Col span={2} className="media-progress-status_body-col">
            {iconRendered}
          </Col>
        </Row>
      </Tooltip>
    );
  }
}

export default MediaProgressStatusRow;
