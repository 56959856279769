import { Button, Col, Input, Row } from "antd";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { request_password_reset } from "../../actions/authActions";
import AlertNotification from "../layout/AlertNotification";
import { withRouter } from "../../hooks/withRouter";

enum RESET_STEP {
  NOT_SUBMITTED,
  FAIL,
  SUCCESS
}

enum CALLBACK_ROUTE {
  LOGIN,
  REFRESH
}

interface IPasswordResetRequestProps {
  request_password_reset: Function;
  navigate: any;
}

interface IPasswordResetRequestState {
  email: string;
  password: string;
  captcha: any;
  reset_step: RESET_STEP;
}

class PasswordResetRequest extends React.Component<
  IPasswordResetRequestProps,
  IPasswordResetRequestState
> {
  private _delayReloadTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      captcha: null,
      reset_step: RESET_STEP.NOT_SUBMITTED
    };
  }

  onCaptchaChange = (value) => {
    this.setState({
      captcha: value
    });
  };

  delayedReload = () => {
    clearTimeout(this._delayReloadTimeout);
    this._delayReloadTimeout = null;
    this._delayReloadTimeout = setTimeout(() => {
      this.refreshNow();
    }, 3000);
  };

  onSuccess = () => {
    AlertNotification(
      "success",
      "Success",
      "An email was sent to you with instructions for recovering your password"
    );
    this.setState({
      reset_step: RESET_STEP.SUCCESS
    });
    this.delayedReload();
  };

  onFail = () => {
    this.setState({
      reset_step: RESET_STEP.FAIL
    });
    this.delayedReload();
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.request_password_reset(
      this.state.email,
      this.state.captcha,
      this.onSuccess,
      this.onFail
    );
  };

  refreshNow = () => {
    if (this.state.reset_step === RESET_STEP.SUCCESS) {
      this.props.navigate("/login");
    } else if (this.state.reset_step === RESET_STEP.FAIL) {
      window.location.reload();
    }
  };

  goToLogin = () => {
    this.props.navigate("/login");
  };

  render() {
    if (this.state.reset_step === RESET_STEP.FAIL) {
      return (
        <div className="auth-container">
          <img
            className="auth-container_bg"
            alt="login"
            src="https://pdg-public.s3-us-west-2.amazonaws.com/cms/assets/login_image.jpg"
          />
          <Row>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={8}
              className="auth-container_form-container"
            >
              <div className="auth-container_form-panel auth-container-password-reset-request_form-panel">
                <img
                  className="auth-container_logo"
                  alt="login"
                  src="/images/appello_cms_logo_H_white.png"
                />
                <div className="login-form auth-container_form">
                  <h2 className="auth-container_h2">
                    The page will refresh shortly.
                  </h2>

                  <div className="auth-container_buttons">
                    <Button
                      className="auth-container_button"
                      onClick={() => this.refreshNow()}
                    >
                      Refresh Now
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={11} lg={13}></Col>
          </Row>
        </div>
      );
    }

    if (this.state.reset_step === RESET_STEP.SUCCESS) {
      return (
        <div className="auth-container">
          <img
            className="auth-container_bg"
            alt="login"
            src="https://pdg-public.s3-us-west-2.amazonaws.com/cms/assets/login_image.jpg"
          />
          <Row>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={8}
              className="auth-container_form-container"
            >
              <div className="auth-container_form-panel auth-container-password-reset-request_form-panel">
                <img
                  className="auth-container_logo"
                  alt="login"
                  src="/images/appello_cms_logo_H_white.png"
                />
                <div className="login-form auth-container_form">
                  <h2 className="auth-container_h2">
                    Password Reset successfully
                  </h2>

                  <div className="auth-container_buttons">
                    <Button
                      className="auth-container_button"
                      onClick={() => this.refreshNow()}
                    >
                      Go to Login
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={11} lg={13}></Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="auth-container">
        <img
          className="auth-container_bg"
          alt="login"
          src="https://pdg-public.s3-us-west-2.amazonaws.com/cms/assets/login_image.jpg"
        />
        <Row>
          <Col
            xs={24}
            sm={24}
            md={11}
            lg={8}
            className="auth-container_form-container"
          >
            <div className="auth-container_form-panel">
              <img
                className="auth-container_logo auth-container_logo-link"
                alt="login"
                src="/images/appello_cms_logo_H_white.png"
                onClick={this.goToLogin}
              />
              <form className="login-form auth-container_form">
                <h2 className="auth-container_h2">Forgot Password?</h2>

                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <Input
                      className="auth-container_form-input-text"
                      autoFocus
                      tabIndex={1}
                      placeholder="email"
                      required
                      type="email"
                      value={this.state.email}
                      dir="auto"
                      onChange={this.handleEmailChange}
                    />
                  </div>
                </div>
                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <ReCAPTCHA
                      className="auth-container_form-input-recaptcha"
                      ref="recaptcha"
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                      onChange={(e) => this.onCaptchaChange(e)}
                    />
                  </div>
                </div>
                <div className="auth-container_buttons">
                  <Button
                    className="auth-container_button"
                    onClick={this.handleSubmit}
                    htmlType="submit"
                  >
                    Email me a recovery link
                  </Button>
                </div>
              </form>
            </div>
          </Col>
          <Col xs={0} sm={0} md={11} lg={13}></Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  request_password_reset: (email, captcha, onSuccess, onFail) => {
    dispatch(request_password_reset(email, captcha, onSuccess, onFail));
  }
});

export default withRouter(
  connect(null, mapDispatchToProps)(PasswordResetRequest)
);
