import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FontsFamilies } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { textstyle_default } from "./boston_root";

export const boston_donor_sea_category: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.boston_donor_sea_category,
  display_name: "Donor Sea Category",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor],
  fields: {
    name,
    donor_name_style: {
      type: "textstyle",
      title: "Donor Name Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
