import React, { CSSProperties, useCallback, useMemo, useRef } from "react";
import QRCode from "react-qr-code";
import { useFolder } from "../../hooks/useFolder";
import { getFileDynamic } from "../../utils/fileUtils";
import { hex8ToRgbA, isTextRichEmpty } from "../../utils/generalUtils";
import RichTextRender from "./RichTextRender";
import { get } from 'lodash'

const QRLogoWidget = (props) => {
  const qrContainer = useRef<HTMLDivElement>(null);
  const { getBaseFolderFieldValue } = useFolder(props.data.base_folder);

  const getFileDynamicCallback = useCallback(() => {
    return getFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["logo"],
      props.data.base_folder,
      null
    );
  }, []);

  const checkLongQR = () => {
    return !isTextRichEmpty(header) || !isTextRichEmpty(body);
  };

  const getInnerHeight = (elm) => {
    const computed = getComputedStyle(elm),
      padding =
        parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

    return elm.clientHeight - padding;
  };

  const qrSize = useMemo(() => qrContainer.current ? getInnerHeight(qrContainer.current) : null, [qrContainer.current])

  const header = getBaseFolderFieldValue("call_to_action_header");
  const body = getBaseFolderFieldValue("call_to_action_body");

  const displayLogoQr = () => {
    if (props.switch_to_logo === 'logo') {
      const file = getFileDynamicCallback();
      return (
        file && (
          <img
            src={getFileDynamicCallback()}
            style={{
              width: "100%",
              objectFit: "contain",
              height: "100%"
            }}
          />
        )
      );
    }

    let longQR = checkLongQR();

    return (
      <div
        style={{
          display: "flex",
          flexDirection: props.mirror_logo_qr ? "row-reverse" : "row",
          gridColumnGap: get(props, 'componentSpecificData.gridColumnGap', "5em"),
          height: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          placeContent: "center space-around"
        }}
      >
        {props.qr_link ? (
          <div
            ref={qrContainer}
            style={{
              height: "100%",
            }}
          >
            { qrSize && <QRCode
              value={props.qr_link}
              size={qrSize}
              fgColor={props.qr_color}
              bgColor={getQRColor("qr_code_background_color")}
              level="H"
            />}
          </div>
        ) : null}
        {longQR ? (
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "column",
              maxWidth: get(props, 'componentSpecificData.textMaxWidth', "50em"),
              height: "100%",
              maxHeight: "100%",
              overflow: "hidden ",
              gridRowGap: "0.5em"
            }}
          >
            <span>
              <RichTextRender htmlString={header} />
            </span>
            <span>
              <RichTextRender htmlString={body} />
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  if (props.switch_to_logo === 'none') return null;

  const getQRColor = (field) => {
    const hexColor = getBaseFolderFieldValue(field);
    const colors = hex8ToRgbA(hexColor);
    return colors;
  };

  if (isTextRichEmpty(header) && isTextRichEmpty(body) && !props.qr_link && props.switch_to_logo === 'qrCode') {
    return null;
  }

  const width = get(props, "componentSpecificData.width", null);
  const position = get(props, "componentSpecificData.position", "absolute");

  const getStyles = (): CSSProperties => {
    const styles = {
      display: "flex",
      position,
      bottom: props.bottom || "5em",
      right: props.mirror_logo_qr ? "0" : "auto",
      justifyContent: "center",
      height: "25em",
      width,
      justifySelf: props.mirror_logo_qr ? "right" : "left",
      borderTopRightRadius: props.mirror_logo_qr ? "0em" : "2em",
      borderBottomRightRadius: props.mirror_logo_qr ? "0em" : "2em",
      borderTopLeftRadius: props.mirror_logo_qr ? "2em" : "0em",
      borderBottomLeftRadius: props.mirror_logo_qr ? "2em" : "0em",
      background: getQRColor("qr_background_color"),
      padding: "5em",
      zIndex: props.zIndex || 1
    } as CSSProperties

    const customStyles = get(props, 'componentSpecificData.customStyles', null)
    if (customStyles) {
      return {
        ...styles,
        ...customStyles
      }
    }

    return styles;
  }

  return (
    <div
      style={getStyles()}
    >
      {displayLogoQr()}
    </div>
  );
};

export default QRLogoWidget;
