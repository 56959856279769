import React, { Component, createRef } from "react";
import resourceConfig from "../../config/resource";
import resourcePlainTextConfig from "../../config/resource_plain_text";
import resourceRichTextConfig from "../../config/resource_rich_text";
import DynamicForm from "../form/DynamicForm";
import { connect } from "react-redux";
import { Button, Collapse, Row } from "antd";
import {
  updateTextResource,
  updateFileResource,
  deleteResources,
  getTagResource,
  getNotesResource,
  deleteTagResource,
  addTagResource,
  addNotesResource,
  deleteNotesResource,
  updateNotesResource,
  updateResources,
} from "../../actions/resourceActions";
import _ from "lodash";
import { ResourceContentLibraryDetail } from "./ResourceContentLibraryDetail";
import ResourceContentLibraryAssociated from "./ResourceContentLibraryAssociated";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import {
  setFoldersExpanded,
  toggleFolderExpanded,
} from "../../actions/folderActions";
import ResourceContentLibraryUserGenerated from "./ResourceContentLibraryUserGenerated";
import ResourceFolderDetailForm from "./ResourceFolderDetailForm";
import { searchTag } from "../../actions/searchActions";

const { Panel } = Collapse;
let  resource_selected_folder = null;

class ResourceDetailForm extends Component {
  constructor(props) {
    super(props);
    this._userGeneratedRef = null;
    this.state = {};
  }

  componentDidUpdate(prevProps) {}

  // onSubmit = (values) => {
  //   console.log("Submitting", values);
  //   const success = () => AlertNotification("success", "Success", "Resource updated." )
  //   const fail = () => AlertNotification("error", "Error", "Resource failed to update." )
  //   switch (this.props.selected_resource.type) {
  //     case "plain_text":
  //         this.props.updateTextResource(this.props.selected_resource.id, values, success, fail)
  //       break;
  //     case "rich_text":
  //         this.props.updateTextResource(this.props.selected_resource.id, values, success, fail)
  //       break;
  //     case "file":
  //         this.props.updateFileResource(this.props.selected_resource.id, values, success, fail)
  //       break;
  //     default:
  //       break;
  //   }
  // }

  onError = () => {};

  onSubmitResource = () => {
    if (this._userGeneratedRef) {
      this._userGeneratedRef.onSubmitResource();
    }
  };

  render() {
    let fieldsConfig, fieldGroupsConfig;
    let fieldValues = {};
    if (this.props.selected_resource && !this.props.client_options.resource_selected_folder) {
      switch (this.props.selected_resource.type && !this.props.client_options.resource_selected_folder) {
        case "plain_text":
          fieldsConfig = resourcePlainTextConfig.fields;
          fieldGroupsConfig = resourcePlainTextConfig.field_groups;
          fieldValues.name = this.props.selected_resource.name;
          fieldValues.text = this.props.selected_resource.string_data;
          break;
        case "rich_text":
          fieldsConfig = resourceRichTextConfig.fields;
          fieldGroupsConfig = resourceRichTextConfig.field_groups;
          fieldValues.rich_text = _.get(
            this.props.selected_resource,
            "contents.rich_text",
            {}
          );
          fieldValues.name = this.props.selected_resource.name;
          break;
        case "file":
          fieldsConfig = resourceConfig.fields;
          fieldGroupsConfig = resourceConfig.field_groups;
          fieldValues.modifiedName = this.props.selected_resource.modifiedName;
          break;
        default:
          break;
      }
    } else if (this.props.client_options.resource_selected_folder) {
      return <ResourceFolderDetailForm />;
    } else {
      return null;
    }
    return (
      <div className="resource-detail">
        <Collapse defaultActiveKey={["1"]}>
          <Panel
            header="Content Details"
            key="1"
            className="resource-detail_panel"
          >
            <div>
              <ResourceContentLibraryDetail
                selected_resource={this.props.selected_resource}
                resources={this.props.resources}
                aws_instance={this.props.aws_instance}
                users={this.props.users}
                user={this.props.user}
              ></ResourceContentLibraryDetail>
            </div>
          </Panel>
          <Panel
            header="Associated with"
            key="2"
            className="resource-detail_panel"
          >
            <div>
              <ResourceContentLibraryAssociated
                selected_resource={this.props.selected_resource}
                resource__folders={this.props.resource__folders}
                folders={this.props.folders}
                folder__folders={this.props.folder__folders}
                setClientOptions={this.props.setClientOptions}
                toggleFolderExpanded={this.props.toggleFolderExpanded}
                setFoldersExpanded={this.props.setFoldersExpanded}
                applied_content_tree_expanded={
                  this.props.applied_content_tree_expanded
                }
                projects={this.props.projects}
              ></ResourceContentLibraryAssociated>
            </div>
          </Panel>
          <Panel
            header="User Generated"
            key="3"
            className="resource-detail_panel"
            a
          >
            <div>
              <ResourceContentLibraryUserGenerated
                ref={(ref) => {
                  if (ref) {
                    this._userGeneratedRef = ref;
                  }
                }}
                key={`content-lybrary-user-generated-resource-${this.props.selected_resource.id}`}
                selected_resource={this.props.selected_resource}
                resource__notes={this.props.resource__notes}
                resource__tags={this.props.resource__tags}
                tags={this.props.tags}
                user={this.props.user}
                users={this.props.users}
                searchStringHighlight={this.props.searchStringHighlight}
                tagSuggestionResults={this.props.tagSuggestionResults}
                getTagResource={this.props.getTagResource}
                deleteTagResource={this.props.deleteTagResource}
                addTagResource={this.props.addTagResource}
                getNotesResource={this.props.getNotesResource}
                addNotesResource={this.props.addNotesResource}
                deleteNotesResource={this.props.deleteNotesResource}
                updateNotesResource={this.props.updateNotesResource}
                updateResource={this.props.updateResource}
                searchTag={this.props.searchTag}
              ></ResourceContentLibraryUserGenerated>
            </div>
          </Panel>
        </Collapse>
        <div className="resource-detail_actions">
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            className="form-submit-button"
            style={{ width: "auto" }}
            onClick={this.onSubmitResource}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let selected_resource = null;
  if (state.client_options.resource_selected_resource) {
    const resourceFolder =
      state.data.resourcetree_resource__folders[
        state.client_options.resource_selected_resource
      ];
    if (resourceFolder) {
      selected_resource = state.data.resources[resourceFolder.resource];
    }

    if(state.client_options.resource_selected_folder){
      resource_selected_folder = state.client_options.resource_selected_folder;
    }
  }

  const resource__folders = arrayFromKeyedObject(state.data.resource__folders);
  const resource__notes = arrayFromKeyedObject(state.data.resource__notes);
  const resource__tags = arrayFromKeyedObject(state.data.resource__tags);

  return {
    applied_content_tree_expanded: state.data.applied_content_tree_expanded,
    resourcetree_folders: state.data.resourcetree_folders,
    aws_instance: state.data.aws_instance,
    user: state.data.user,
    users: state.data.users,
    selected_resource: selected_resource,
    resources: state.data.resources,
    resource__notes,
    resource__tags,
    resource__folders,
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    projects: state.data.projects,
    tags: state.data.tags,
    resource_selected_folder:resource_selected_folder,
    searchStringHighlight: state.client_options.search_string_highlight,
    tagSuggestionResults: state.data.tag_suggestion_results,
  };
};

 const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateTextResource: (resource_id, data, onSuccess, onFail) => {
      dispatch(updateTextResource(resource_id, data, onSuccess, onFail));
    },
    updateFileResource: (resource_id, data, onSuccess, onFail) => {
      dispatch(updateFileResource(resource_id, data, onSuccess, onFail));
    },
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
    toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
      dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode));
    },
    setFoldersExpanded: (treeMode, treeIds) => {
      dispatch(setFoldersExpanded(treeMode, treeIds));
    },
    getTagResource: (resource_ids, onSuccess, onFail) => {
      dispatch(getTagResource(resource_ids, onSuccess, onFail));
    },
    deleteTagResource: (tag_id, onSuccess, onFail) => {
      dispatch(deleteTagResource(tag_id, onSuccess, onFail));
    },
    addTagResource: (tag, onSuccess, onFail) => {
      dispatch(addTagResource(tag, onSuccess, onFail));
    },
    getNotesResource: (resource_ids, onSuccess, onFail) => {
      dispatch(getNotesResource(resource_ids, onSuccess, onFail));
    },
    addNotesResource: (note, onSuccess, onFail) => {
      dispatch(addNotesResource(note, onSuccess, onFail));
    },
    deleteNotesResource: (resource_note_id, onSuccess, onFail) => {
      dispatch(deleteNotesResource(resource_note_id, onSuccess, onFail));
    },
    updateNotesResource: (note, onSuccess, onFail) => {
      dispatch(updateNotesResource(note, onSuccess, onFail));
    },
    updateResource: (resource, onSuccess, onFail) => {
      dispatch(updateResources(resource, onSuccess, onFail));
    },
    searchTag: (text, onSuccess, onFail) => {
      dispatch(searchTag(text, onSuccess, onFail));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDetailForm);
