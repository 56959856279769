import React, { useState, useRef, useEffect } from "react";

const VideoLoader = ({ url, width, height }) => {
  const [paused, setPaused] = useState(true);
  const videoRef = useRef(null);
  

  const toggleVideo = () => {
    if (paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setPaused(!paused);
  };

  return (
    <React.Fragment>
      {url ? (
        <>
          <video ref={videoRef} style={{ width, height }} muted>
            <source src={url} />
            Your browser does not support HTML5 Video
          </video>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "absolute",
            }}
            onClick={toggleVideo}
          >
            {paused && (
              <img alt={"loading"} style={{ position: "absolute" }} src="/images/play.png" />
            )}
          </div>
        </>
      ) : (
        <img alt={"loading"} src="/images/icons/video.png" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      )}
    </React.Fragment>
  );
};

export default VideoLoader;
