import get from "lodash/get";
import React from "react";
import { createUseStyles } from "react-jss";
import { useFolder } from "../../../hooks/useFolder";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const BerkeleyTemplate004 = (props) => {
  const {getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue} = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const backgroundOverlay = getBaseFolderFieldValue("background_image_overlay")
  const mirrorContent = getBaseFolderFieldValue("mirror_content")
  const storyBackground = getBaseFolderFieldValue("story_background")
  const quoteBackground = getBaseFolderFieldValue("quote_background")
  const header = getBaseFolderFieldValue("header", baseFolderType)
  const subhead = getBaseFolderFieldValue("subhead", baseFolderType)
  const body = getBaseFolderFieldValue("body", baseFolderType)
  const propsClasses = get(props, 'classes.componentContainer', '');

  const screenWidth = props.containerWidth / 3
  const screenHeight = props.containerHeight / 2

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundOverlay,
    mirrorContent,
    quoteBackground,
    storyBackground,
    screenWidth,
    screenHeight
  });

  return (
    <div className={[classes.componentContainer, propsClasses].join(" ")}>
      <Slide1911
        {...props}
        handleEndOfPlay={null}
        containerHeight={props.containerHeight}
        data={{
          ...props.data,
          muted: false,
          slotNames: ["background_image"],
          base_folder: get(baseFolder, "id", null),
          backgroundSize: "contain",
        }}
      />
      <div className={classes.componentOverlay} />
      <div className={classes.storyWithMedia}>
        <div className="imageLeft">
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            containerWidth={screenWidth}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["media_left"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
        <div className="storyCenter">
          <div className={classes.story}>
            <div className="storyTop">
              <RichTextRender
                htmlString={header}
              />
              <RichTextRender
                htmlString={subhead}
              />
            </div>
            <div className="storyBottom">
              <RichTextRender
                htmlString={body}
              />
            </div>
          </div>
        </div>
        <div className="imageRight">
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            containerWidth={screenWidth}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["media_right"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BerkeleyTemplate004;
