import { array, boolean, lazy, mixed, number, object, string } from 'yup';
import { FOLDER_TYPE } from '../../shared-global/enums/folder-type-enums';
import { TREE_TYPE } from '../../shared-global/enums/ui-enums';
import { IFolderModel } from '../interfaces/models';

export const updateFolderValidationSchema = object().shape({
  id: number().required(),
  name: string().nullable(),
  fields: object(),
});

export const addFolderValidationSchema = object().shape({
  folder_name: string().required(),
  tree_type: mixed<TREE_TYPE>().oneOf(Object.values(TREE_TYPE)).required(),
  type: mixed<FOLDER_TYPE>().oneOf(Object.values(FOLDER_TYPE)).required(),
  parent_folder__folder_id: number().required(),
  folder_path: array().of(number())
});

export const cloneFolderValidationSchema = object().shape({
  folder_name: string().required(),
  clone_children: boolean(),
  folder__folder_id: number().required()
});

export const moveFolderValidationSchema = object().shape({
  orig_folder__folder_id: number().required(),
  dest_folder__folder_id: number().required(),
  tree_type: string().required(),
  move_action: string().required(),
  action: string()
});

export const removeFolderValidationSchema = object().shape({
  folder_id: lazy(value => Array.isArray(value) ? array().of(number()).required() : number().required()),
  folder__folder_id: lazy(value => Array.isArray(value) ? array().of(number()).required() : number().required()),
  hasDeleteConfirmation: boolean(),
  origin_path: string()
});

export const uriGetAccessDataValidationSchema = object().shape({
  identifier: string().required(),
  password: string().nullable(),
  getBaseFolderOnly: boolean().default(false)
});

export const addBulkFoldersValidationSchema = object().shape({
  folders: array().of(object<IFolderModel>().shape({
    name: string().required(),
    parent_folder__folder_id: number().required(),
    type: mixed<FOLDER_TYPE>().oneOf(Object.values(FOLDER_TYPE)).required(),
    tree_type: mixed<TREE_TYPE>().oneOf(Object.values(TREE_TYPE)).required(),
    id: number(),
    folder_path: array().of(number())
  })).required()
});

export const updateBulkFoldersValidationSchema = object().shape({
  folders: array().of(object<IFolderModel>().shape({
    name: string().required(),
    type: mixed<FOLDER_TYPE>().oneOf(Object.values(FOLDER_TYPE)).required(),
    folderId: number(),
    folderFolderId: number(),
    rank: number()
  })).required()
});

export const updateUploadedResourcesValidationSchema = object().shape({
  folderId: number().required(),
  fileNames: array().of(string()).required()
});

export const downloadCsvValidationSchema = object().shape({
  folder: number().required()
});

export const instanceFolderValidationSchema = object().shape({
  parent_folder_id: number().required(),
  instanced_folder_ids: lazy(value => Array.isArray(value) ? array().of(number()).required() : number().required()),
  dest_folder_folder_id: number(),
  spacer: boolean(),
  moveIntoActionName: string(),
  origin_path: string(),
  destination_path: string()
});

export const makePullableValidationSchema = object().shape({
  uuid: string().required(),
  pullable: string()
});

export const getCompanyRootFoldersValidationSchema = object().shape({
  company_id: number().required()
});

export const getFolderInfoValidationSchema = object().shape({
  uuid: string().required()
});

export const updateFolderContentsValidationSchema = object().shape({
  contents: string().required(),
  folder_id: number().required()
});

export const publishFolderValidationSchema = object().shape({
  uuid: string().required(),
  folderFolderPath: array().of(number())
});

export const fetchContentsValidationSchema = object().shape({
  api_identifier: string().required(),
  company_id: number().required(),
  last_update: string().required(),
  display_identifier: string().required()
});

export const moveBulkFoldersValidationSchema = object().shape({
  destination_folder_id: number().required(),
  folder__folder_ids: array().of(number()).required()
});

export const cloneBulkFoldersValidationSchema = object().shape({
  destination_folder_id: number().required(),
  folders_to_clone: array().of(object().shape({
    folderFolderId: number().required(),
    name: string().required()
  })).required()
});

export const deleteDisplayIdentifierValidationSchema = object().shape({
  folder_id: number().required(),
  record_id: string()
});

export const getDisplayInfoValidationSchema = object().shape({
  folder_id: number().required()
});

export const addDisplayHardwareInfoValidationSchema = object().shape({
  folder_id: number().required(),
  display_index: number().required(),
  type: string().required(),
  data: object().nonNullable().required()
});

export const updateDisplayHardwareInfoValidationSchema = object().shape({
  folder_id: number().required(),
  display_index: number().required(),
  type: string().required(),
  data: object().nonNullable().required()
});

export const downloadDisplayHardwareInfoCsvValidationSchema = object().shape({
  folder_id: number().required(),
  display_index: number().required(),
  type: string().required(),
});

export const downloadDisplayHardwareInfoTxtValidationSchema = object().shape({
  folder_id: number().required(),
  display_index: number().required(),
});

export const pushDownInheritableFieldsValidationSchema = object().shape({
  folderId: number().required(),
  fieldsToInherit: object()
});

export const updateFolderArModelValidationSchema = object().shape({
  phrase: string().required(),
  model_url: string().required(),
  contents: object().required(),
  folder_id: number().required(),
  detection_url: string(),
  detection_word: string(),
});


export const getFolderArModelValidationSchema = object().shape({
  folder_id: number().required()
});

export const getArModelByPhraseValidationSchema = object().shape({
  phrase: string().required()
});
