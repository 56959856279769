import React from 'react'
import QRLogoWidget from "../../standard/QRLogoWidget";
import {IDisplayComponentProps} from "../../../interfaces/display-component-props.interface";
import {IFolderType} from "../../../../shared-global/interfaces/folder-types/folders/IFolderType";
import {useScale} from "../../../hooks/useScale";

interface IPhoenixQrWidgetProps extends IDisplayComponentProps {
  baseFolderFields: any
  qrPosition: string
  baseFolderType: IFolderType
  componentSpecificData: any
}

const PhoenixQrWidget = (props: IPhoenixQrWidgetProps) => {
  const { getBaseFolderFontSize } = useScale(props.data.base_folder);
  const fontSize = getBaseFolderFontSize();
  const singleScreenHeight = props.containerHeight / 3;
  const singleScreenWidth = props.containerWidth / 3;

  const getQRVerticalPosition = () => {
    if (props.qrPosition.includes("top")) {
      return (props.containerHeight - (6 * fontSize)) - (singleScreenHeight * 0.444444)
    } else {
      return 6 * fontSize
    }
  }

  const getStylesBasedOnQrPosition = () => {
    if (props.qrPosition.includes("left")) {
      return {
        left: "6em"
      }
    } else if (props.qrPosition.includes("right")) {
      return {
        right: "6em"
      }
    }
  }

  return (
    <QRLogoWidget
      {...props}
      {...props.baseFolderFields}
      containerWidth={0.078 * props.refHeight}
      bottom={getQRVerticalPosition()}
      folderType={props.baseFolderType}
      mirror_logo_qr={props.qrPosition.includes("right")}
      zIndex={4}
      componentSpecificData={{
        ...props.componentSpecificData,
        textMaxWidth: '',
        customStyles: {
          ...getStylesBasedOnQrPosition(),
          width: singleScreenWidth - (fontSize * 12),
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          justifyContent: props.qrPosition.includes("left") ? "left" : "right",
          height: singleScreenHeight * 0.444444,
          flexFlow: ''
        }
      }}
    />
  )
}

export default PhoenixQrWidget
