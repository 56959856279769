export const DEFAULT_COBURG_SCROLLBAR_COLOR = '#707070';
export const DEFAULT_COBURG_BACKGROUND_COLOR = '#DCDCDC';
export const DEFAULT_COBURG_TEXT_COLOR = '#000000';
export const COBURG_QUOTE_COLOR = '#cdcdcd';
export const DEFAULT_COBURG_ACCENT = '#9e3e76';

export const DONOR_COBURG_STORY_MENU_WIDTH = .25;
export const COBURG_DONOR_LIST_OFFSET_LEFT = .5;
export const COBURG_GROUP_MARGIN_TOP = 0.078



