import React from "react";
import { Input } from "antd";
import { BaseInputProps } from "./types";

export type PasswordInputProps = BaseInputProps<string>;

const PasswordInput = (props: PasswordInputProps) => {
  return (
    <>
      <label className="form-input-label">{props.title}</label>
      <br />
      <Input
        className="text-input"
        type="password"
        defaultValue={props.defaultValue}
        onChange={(ev) => props.onChange(props.fieldName, ev.target.value)}
        onBlur={(ev) => props.onBlur(props.fieldName, ev.target.value)}
      />
    </>
  );
};

export default PasswordInput;
