import { createSelector } from "@reduxjs/toolkit";
import { convertObjectIntoArray } from "../components/form/input/DynamicFileInput/FileInputService";

const getFolderStatusFromState = (state, props) => state.data.folder__status;

export const getFolderStatusSelector = createSelector(
  [getFolderStatusFromState],
  (folderStatusObject) => {
    const folder__status = convertObjectIntoArray(folderStatusObject);
    return folder__status;
  }
);
