import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { faSpinnerThird } from 'fcbpriv/@fortawesome/pro-solid-svg-icons';
import React, { Component } from "react";
import config from '../../config/csv_fields';
import DynamicForm from '../form/DynamicForm';
import { MD_BLUE } from '../layout/LayoutConstants';

class CsvModal extends Component {
  constructor(props) {
    super(props);
    this._dynamicFormRef = null;
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      return true;
    }
    return false;
  };

  onSave = () => {
    const isValid = this._dynamicFormRef.isValid();
    if (isValid) {
      let values = this._dynamicFormRef.getFieldValues();
      this.props.ok(values);
    }
  }

  render() {
    if (this.props.type === "folder") {
      config.fields.removeAll = { type: 'bool', title: 'Delete and replace everything within the target folder', validation: { rules: [] }, default: false };
    }
    return (
      <React.Fragment>
        <Modal
          visible={this.props.visible}
          onOk={this.onSave}
          onCancel={this.props.cancel}
          title={(
            <React.Fragment>
              <FontAwesomeIcon className="fa" icon={['fas', 'file-download']} />
              &nbsp;&nbsp;Global content Import/Export tool
            </React.Fragment>
          )}
        >
          <span>Import and Export content rapidly using a CSV file.</span><br/><br/>
          <p><b>Step 1: Download Global Template</b></p>
          <p>The template includes all editable content for this project. You can update fields and add new content to the spreadsheet. Please review the following instructions:</p>
          <ul>
            <li>The column "parents: behaves like an array, meaning that an item can be associated with many folders. Ids must be separated by a dash character (-) if more than one is specified</li>
            <li>If the record's id doesn't exist it will be created and associated to the specified folder(s).</li>
            <li>If the record's id does exist, it will be associated to the specified folder(s). Previous associations will be removed.</li>
            <li>Associations in other projects won't be affected.</li>
            <li>Columns will affect the folders depending on its type. For instance, if a column is named "toledo_donor.quote", it means that this column's value will only apply to those items that have a type of "toledo_donor"</li>
          </ul>
          <div style={{ textAlign: "right", cursor: 'pointer', color: MD_BLUE, textDecoration: 'underline' }}>
            <span onClick={this.props.downloadCsv}><FontAwesomeIcon className="fa" icon={['fas', 'file-download']} /> Download template</span>
          </div>
          <br/>
          <p><b>Step 2: Upload Edited Template</b></p>
            {this.props.visible && (
              <DynamicForm
                ref={ref => {
                  if(ref) {
                    this._dynamicFormRef = ref;
                  }
                }}
                fieldGroupsConfig={config.field_groups}
                fieldsConfig={config.fields}
                fieldValues={{resource: []}}
                mode={'add'}
                onSubmit={this.onSave}
                showSubmitButton={false}
                enableFieldGrouping={false}
                showRankIcons={false}
                gallery={false}
                name={null}
                onChangeHandlers={null}
              />
            )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default CsvModal;