/* eslint-disable */
import { createSelector } from "@reduxjs/toolkit";

import { getAppliedTreeDataResourcesNoEmpty } from "./folderNoEmptySelectors";

const getSearchStringMain = (state) => {
  if (state.search_string_main) {
    return state.search_string_main;
  }
  return "";
};

const getResourceResults = (state) => {
  if (state.data.resource_results && state.data.resource_results.length) {
    return state.data.resource_results;
  }

  return [];
};

export const getAppliedTreeDataResourcesFilterSiblingsNoMatch = createSelector(
  [getAppliedTreeDataResourcesNoEmpty, getSearchStringMain, getResourceResults],
  (folderFiltered, searchStringMain, resourceResults) => {
    if (searchStringMain) {
      const searchStringMainLower = searchStringMain.toLowerCase();
      folderFiltered = folderFiltered.filter((fs) => {
        return (
          fs.type !== "file" ||
          (fs.modifiedName &&
            fs.modifiedName.toLowerCase().includes(searchStringMainLower)) ||
          resourceResults.includes(String(fs.id))
        );
      });
    }

    return folderFiltered;
  }
);
