import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE, PREVIEW_TYPE, PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from '../../../../enums/folder-type-enums';
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { sorting_options, wysiwyg } from '../standardized_fields';
import { transitions, wysiwygSettings, list_styles, textstyle_options } from './phoenix_settings';
import { fonts, FontsFamilies, FontVariants } from '../../../../fonts';
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from '../../../../enums/ui-enums';

export const phoenix_donor_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.phoenix_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Donor List",
  acceptable_folder_types: [
    FOLDER_TYPE.phoenix_donor
  ],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    donor_list: {
      title: "Donor List",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    horizontal_rules: {
      title: "Horizontal Rules",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "Logo / QR Code",
      default_expanded: true,
      order: 6,
      accordion: true,
      tab: "general"
    },
    list_settings: {
      title: "List Settings",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    // General Field Group
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      tooltip: "Cycle time in seconds",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 1000 }] },
      default: 15,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_transition"
      }
    },
    sorting_options: {
      ...sorting_options,
      title: "Sort Order",
      field_group: "general"
    },
    // Donor List Field Group
    donor_list_header: {
      title: "Header",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "80px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    description: {
      title: "Description",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "60px",
            italic: true,
            lineHeight: "1.15"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_list_description"
      }
    },
    category: {
      title: "Donor List Category",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "72px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_list_category"
      }
    },
    columns: {
      title: "Columns",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 10 }] },
      default: 6,
      field_group: "donor_list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 5,
      field_group: "donor_list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 68,
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_donor_names"
      },
      field_group: "donor_list"
    },
    footer: {
      title: "Footer",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "48px",
            color: "#FFFFFF",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_footer"
      }
    },
    // Horizontal Rules Field Group
    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    // Background Field Group
    donor_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_background_image"
      },
      field_group: "background"
    },
    donor_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_background_color_overlay"
      }
    },
    // List Settings Field Group
    list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    list_margin_bezel: {
      type: "integer",
      title: "Donor List Margin Around Bezel",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 3,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    donor_line_height: {
      title: "Donor Line Height",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 1,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    donor_name_margin_bottom: {
      type: "integer",
      title: "Donor Name Bottom Margin",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    cycle_forward_key: {
      type: "text",
      title: "Cycle Forward Key",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      default: "ArrowRight",
      field_group: "list_settings",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    donor_list_style: {
      type: "select",
      title: "List Style",
      field_group: "list_settings",
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.phoenix_donor],
    columns: [
      {
        folder_types: [FOLDER_TYPE.phoenix_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.phoenix_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.phoenix_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
