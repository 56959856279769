import React from "react";
import { IResource } from "../../shared-global/interfaces/models/resource.interface";
import TreeItem from "./TreeItem";
import DragDropTreeItem from "./DragDropTreeItem";

interface TreeItemWrapProps {
    name: string;
    expanded: boolean;
    style: any;
    isOver: boolean;
    isDragging: boolean;
    canDrop: boolean;
    handleOnClick: any;
    selectedTreeId: number;
    treeId: string;
    searchText: string;
    addSubFolder: any;
    addResource: any;
    deleteResource: any;
    deleteFolder: any;
    itemIcons: any;
    defaultIcons: any;
    acceptableFolderTypes: Array<any>;
    folderTypes: any;
    modeContext: string;
    openCsvModal: any;
    folder__folder_id: number;
    parent__folder_id: number;
    csvDownload: any;
    resource_tree_crud: any;
    spacer: boolean;
    resource: IResource;
    resource__folder_id: number;
    inProgress: boolean;
    folders: any;
    folder__folders: any;
    path: number[];
    isContent: boolean;
    folderType: any;
    meta: any;
    saveOptions: any;
    folder_type?: any;
    showGray?: boolean;
    showChildAcceptableIcon?: boolean;
    resourcetreeFolders?: any;

  }

interface ISearchMainState {

}

class TreeItemWrap extends React.Component<
    TreeItemWrapProps,
    ISearchMainState
    > {

        constructor(props: any){
            super(props);
            this.state = {
              isHovered: false,
              focused: false
            }
          }

          render(){
              return(
                  <span>
                    <TreeItem
                        name={this.props.name}
                        path={this.props.path}
                        expanded={this.props.expanded}
                        style={this.props.style}
                        isOver={this.props.isOver}
                        canDrop={this.props.canDrop}
                        isDragging={this.props.isDragging}
                        handleOnClick={this.props.handleOnClick}
                        selectedTreeId={this.props.selectedTreeId}
                        treeId={this.props.treeId}
                        searchText={this.props.searchText}
                        addSubFolder={this.props.addSubFolder}
                        addResource={this.props.addResource}
                        deleteResource={this.props.deleteResource}
                        deleteFolder={this.props.deleteFolder}
                        itemIcons={this.props.itemIcons}
                        defaultIcons={this.props.defaultIcons}
                        acceptableFolderTypes={this.props.acceptableFolderTypes}
                        folderTypes={this.props.folderTypes}
                        modeContext={this.props.modeContext}
                        openCsvModal={this.props.openCsvModal}
                        folder__folder_id={this.props.folder__folder_id}
                        parent__folder_id={this.props.parent__folder_id}
                        csvDownload={this.props.csvDownload}
                        resource_tree_crud={this.props.resource_tree_crud}
                        spacer={this.props.spacer}
                        resource={this.props.resource}
                        resource__folder_id={this.props.resource__folder_id}
                        inProgress={false}
                        folders={this.props.folders}
                        isContent={this.props.isContent}
                        folder__folders={this.props.folder__folders}
                        meta={this.props.meta}
                        saveOptions={this.props.saveOptions}
                        folder_type={this.props.folder_type}
                        showGray={this.props.showGray}
                        showChildAcceptableIcon={this.props.showChildAcceptableIcon}
                        resourcetreeFolders={this.props.resourcetreeFolders}
                    >
                    </TreeItem>
                  </span>
              )
          }

}

export default TreeItemWrap
