import { call, put, all } from "redux-saga/effects";
import request from "../utils/api/ServerRequest";
import _ from "lodash";
import { schema, normalize } from "normalizr";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError } from "../actions/notificationActions";
const backupsSchema = new schema.Entity("backups");
const foldersSchema = new schema.Entity("folders");
const folder__foldersSchema = new schema.Entity("folder__folders");
const resourcesSchema = new schema.Entity("resources");
const resource__foldersSchema = new schema.Entity("resource__folders");
const resourcetree_resource__folderSchema = new schema.Entity(
  "resourcetree_resource__folders"
);

async function createBackupAPI(payload) {
  return await request.post("/backup/create", {
    ...payload,
  });
}

async function getByCompanyAPI(payload) {
  try {
    let response = await request.post("/backup/get_by_company", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

async function restoreBackupAPI(payload) {
  try {
    let response = await request.post("/backup/restore", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

async function exportBackupAPI(payload) {
  try {
    let response = await request.post("/backup/exportBackup", {
      ...payload,
    });
    return response;
  } catch (ex) {
    return ex;
  }
}

async function createCrossBackupAPI(payload) {
  try {
    const form_data = new FormData();
    const field_name = Object.keys(payload.file)[0];
    if (!payload.file[field_name][0]) {
      throw new Error("No file uploaded");
    }
    const file = payload.file[field_name][0].file;
    form_data.append(field_name, file);
    let response = await request.post(
      "/backup/create_cross_backup",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    return response;
  } catch (ex) {
    return ex;
  }
}

const backupSaga = {
  create: function* (action) {
    try {
      const response = yield call(createBackupAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "CREATE_BACKUP_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "" });
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  get_by_company: function* (action) {
    try {
      const response = yield call(getByCompanyAPI, action.payload);
      if (_.get(response, "code", null) === "success") {
        yield put({
          type: "GET_BACKUPS_BY_COMPANY_SUCCESS",
          overrides: [
            {
              key: "backups",
              value: normalize(response.data.backups, [backupsSchema]).entities
                .backups,
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_BACKUPS_BY_COMPANY_FAIL" });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "An unexpected error happened"
          );
        }
      }
    } catch (ex) {
      yield put({ type: "GET_BACKUPS_BY_COMPANY_FAIL" });
      if (action.onFail) {
        action.onFail("An unexpected error happened");
      }
    }
  },

  restore: function* (action) {
    try {
      const response = yield call(restoreBackupAPI, action.payload);
      if (_.get(response, "code", null) === "success") {
        yield all([
          put({
            type: "RESTORE_BACKUP_FOLDERS_SUCCESS",
            normalized: normalize(response.data.folders, [foldersSchema]),
          }),
          put({
            type: "RESTORE_BACKUP_FOLDER__FOLDERS_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__foldersSchema,
            ]),
          }),
          put({
            type: "RESTORE_BACKUP_RESOURCES_SUCCESS",
            normalized: normalize(response.data.resources, [resourcesSchema]),
          }),
          put({
            type: "RESTORE_BACKUP_RESOURCE__FOLDERS_SUCCESS",
            normalized: normalize(response.data.resource__folders, [
              resource__foldersSchema,
            ]),
          }),
          put({
            type: "RESTORE_BACKUP_RESOURCETREE_RESOURCE__FOLDERS_SUCCESS",
            normalized: normalize(
              response.data.resourcetree_resource__folders,
              [resourcetree_resource__folderSchema]
            ),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "RESTORE_BACKUP_FAIL",
        });
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected message"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "RESTORE_BACKUP_FAIL",
      });
      if (action.onFail) {
        action.onFail(ex.message || "Unexpected message");
      }
    }
  },

  export: function* (action) {
    try {
      const response = yield call(exportBackupAPI, action.payload);
      // Create the file from JSON
      if (_.get(response, "code", null) === "success") {
        const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
          response.data.content
        )}`;
        const link = document.createElement("a");
        link.href = dataString;
        link.setAttribute("download", response.data.filename);
        document.body.appendChild(link);
        link.click();
        yield put({
          type: "EXPORT_BACKUP_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "EXPORT_BACKUP_FAILURE",
        });
        action.onFail(
          response.response.data.data.detail || "Unexpected error happened"
        );
      }
    } catch (ex) {
      yield put({
        type: "EXPORT_BACKUP_FAILURE",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  create_cross_backup: function* (action) {
    try {
      const response = yield call(createCrossBackupAPI, action.payload);
      if (_.get(response, "code", null) === "success") {
        yield put({
          type: "CREATE_CROSS_BACKUP_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "CREATE_CROSS_BACKUP_FAILURE " });
        if (action.onFail) {
          action.onFail(
            response.message ||
              response.response.data.data.detail ||
              "Unexpected error happened"
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "CREATE_CROSS_BACKUP_FAILURE",
      });
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },
};

export default backupSaga;
