import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { name } from "../standardized_fields";

export const los_angeles_three_panel: IFolderType = {
  folder_category: FOLDER_CATEGORY.PRESENTATION,
  name: FOLDER_TYPE.los_angeles_three_panel,
  display_name: "Three Panel",
  acceptable_folder_types: [FOLDER_TYPE.los_angeles_donor_lists, FOLDER_TYPE.los_angeles_slides],
  fields: {
    name,
    donor_list_header: {
      type: "text",
      title: "Donor List Header",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.LosAngelesThreePanel,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
