import { getFontSizes } from "../../../../utils/general";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { wysiwyg } from "../standardized_fields";

export const alpineFonts = [
  FontsFamilies.RECTOR,
  FontsFamilies.ABC_SOCIAL,
  FontsFamilies.PANTO0GRAPH_CONDENSED,
  FontsFamilies.PANTO0GRAPH_EXTENDED,
  FontsFamilies.PANTO0GRAPH_WIDE,
  FontsFamilies.OPEN_SANS
];

const fontMinSize = 1;
const fontMaxSize = 10;

export const textStyleFontSizes = getFontSizes(10, 60, 1, "px", (i) => {
  return `${i}px`;
});

export const quillFontSizes = {
  min: fontMinSize,
  max: fontMaxSize,
  type: "range"
};

export const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.RECTOR][FontVariants.MEDIUM],
  fontSize: 15,
  lineHeight: 1.05
};

export const textstyle_options = {
  font: {
    enabled: true,
    items: alpineFonts
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: [
      { title: "10 px", value: 10 },
      { title: "15 px", value: 15 },
      { title: "20 px", value: 20 },
      { title: "25 px", value: 25 },
      { title: "30 px", value: 30 },
      { title: "35 px", value: 35 },
      { title: "40 px", value: 40 },
      { title: "45 px", value: 45 },
      { title: "50 px", value: 50 }
    ]
  },
  color: {
    enabled: true
  },
  bold: {
    enabled: false
  },
  italic: {
    enabled: true
  },
  underline: {
    enabled: true
  },
  lineHeight: {
    enabled: true
  }
};

export const settings = {
  use_em_units: true,
  wysiwyg_input_type: "text"
};

export const wysiwygSettings = {
  ...wysiwyg.settings,
  controls: {
    ...wysiwyg.settings.controls,
    bold: false
  },
  font_size_unit: "px",
  fontSizeOptions: quillFontSizes,
  fonts: alpineFonts
};

export const transitions = [
  {
    title: "Fade (1/2 s)",
    value: "transition-fade"
  },
  {
    title: "Fade (1 s)",
    value: "transition-fade1s"
  },
  {
    title: "Fade (2 s)",
    value: "transition-fade-2s"
  },
  {
    title: "Slow Fade In / Fast Fade Out",
    value: "transition-slowinfastout"
  },
  {
    title: "Fast Fade In / Slow Fade Out",
    value: "transition-fastinslowout"
  },
  {
    title: "Slide in right",
    value: "transition-slide-in-from-right"
  },
  {
    title: "Slide in left",
    value: "transition-slide-in-from-left"
  },
  {
    title: "Slide in top",
    value: "transition-slide-in-from-top"
  },
  {
    title: "Slide in bottom",
    value: "transition-slide-in-from-bottom"
  }
];

export const list_styles = [
  {
    title: "Column Filled",
    value: "filled"
  },
  {
    title: "Column Balanced",
    value: "balanced"
  }
];

export const cycle_time_tooltips = [
  "<span><b>Unit: </b>Seconds.</span><br /><br />",
  "<span><b>Images: </b>If cycle time is set to zero, the default cycle time will be 5 seconds.</span><br /><br />",
  "<span><b>Videos: </b>Regardless of provided cycle time, template will cycle when the video is done playing.</span><br /><br />",
];
