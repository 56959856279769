import { array, boolean, number, object, string } from 'yup';

export const getUsersValidationSchema = object().shape({
  company: number().required()
});

export const getUserValidationSchema = object().shape({
  id: number().required(),
});

export const updateUserValidationSchema = object().shape({
  id: number().required(),
  first_name: string().required(),
  last_name: string().required(),
});

export const addUserValidationSchema = object().shape({
  company: number().required(),
  email: string().email().required(),
  first_name: string().required(),
  last_name: string().required(),
});

export const addRoleUserValidationSchema = object().shape({
  role: number().required(),
  user: number().required(),
  project: number(),
  company: number().required(),
});

export const deleteRoleUserValidationSchema = object().shape({
  id: number().required(),
});

export const accountUpdateValidationSchema = object().shape({
  new_password: string().nullable().notRequired(),
  current_password: string().nullable().notRequired(),
  first_name: string().nullable().notRequired(),
  last_name: string().nullable().notRequired(),
});

export const updatePersistentSettingsValidationSchema = object().shape({
  theme: string().notRequired(),
  search_history: array().of(string()).notRequired(),
  show_placeholder: boolean().notRequired(),
  show_actives_on_presentations: boolean().notRequired()
});


export const deleteUserValidationSchema = object().shape({
  user_id: number().required(),
  company_id: number().required(),
});

export const changeCompanyValidationSchema = object().shape({
  user_id: number().required(),
  company_id: number().required(),
});

export const resendSetPasswordEmailValidationSchema = object().shape({
  id: number().required(),
});
