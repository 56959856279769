import React, { Component } from 'react';
import { connect } from "react-redux";
import SelectAppliedContentModalTree from '../../tree/SelectAppliedContentModalTree';
import { Row, Col, Modal } from 'antd';
import WithInputWrapper from '../../utils/WithInputWrapper';
import { getThemeClass } from '../../../utils/themeUtils';
import {TREE_MODE_SELECT_APPLIED_CONTENT} from '../../constants'
import _ from 'lodash';
import AlertNotification from '../../layout/AlertNotification';
import { setClientOptions } from '../../../reducers/clientOptionsSlice';

class SelectExistingFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalGalleryVisible: false,
      selected_folder: null,
      actionType: ''
    }
  }

  openGallery = (actionType) => {
    this.setState({
      modalGalleryVisible: true,
      actionType
    }, () => {
      this.props.setClientOptions({
        inputMode: 'modal',
        modalGalleryVisible: true
      });
    });
  }

  closeGallery = () => {
    this.setState({
      modalGalleryVisible: false
    }, () => {
      this.props.setClientOptions({
        inputMode: 'html5',
        modalGalleryVisible: false
      });
    });
  }

  onSelectFolder = (folder, metaData) => {
    let folder__folder_id = null;
    if (metaData && metaData.folder__folder_id) {
      folder__folder_id = metaData.folder__folder_id;
    }

    if (this.props.client_options.treemode !== 'resource') {
      if (this.props.multiFolderAction === 'move' && folder) {
        const folderType = this.props.folder_types[folder.folder_type];
        if (folderType && folderType.tree_dropped_into_action){
          const action = folderType.tree_dropped_into_action.find((f)=> f.action === 'instance');
          if(action){
            AlertNotification('warning', 'Warning',' This folder cannot be moved to the destination. Please try to copy it instead.')
          } else {
            this.setState({
              selected_folder: { ...folder, folder__folder_id }
            });
          }
        }
      } else {
        this.setState({
          selected_folder: { ...folder, folder__folder_id }
        });
      }
    } else {
      this.setState({
        selected_folder: { ...folder, folder__folder_id }
      });
    }
  }

  onOkay = () => {
    if (this.state.selected_folder.showGray) {
      AlertNotification('error', 'Error', "This item is not allowed in the destination folder")
      return;
    }
    if (this.props.caller_folder.id === this.state.selected_folder.id) {
      AlertNotification('warning', 'Warning', "The origin and destination folder are the same")
      return;
    }
    if(!this.state.selected_folder){
      AlertNotification('error', 'Move Folder Error','The item you are dragging is not allowed in the destination folder');
      return;
    }
    const checked_folders_types = [...new Set(this.props.checked_folders.map(x => this.props.folders[x].folder_type))];
    const allowed_folder_types = checked_folders_types.filter(element => {
      return this.props.folder_types[this.state.selected_folder.folder_type].acceptable_folder_types.includes(element)
    });
    if (checked_folders_types.length === allowed_folder_types.length) {
      this.props.onOkay(this.state.selected_folder);
      this.setState({modalGalleryVisible: false})
    } else {
      const unallowed_folder_names = this.props.checked_folders.filter(x => !allowed_folder_types.includes(x.folder_type)).map(x => x.name).join(', ')
      AlertNotification('error', 'Error', "The folders " + unallowed_folder_names + "can't be moved to the folder " + this.state.selected_folder.name)
    }
  }


  render() {
    return (
      <React.Fragment>
        {/* Select Resource Modal */}
        <Modal
          title={"Please select a destination folder"}
          visible={this.state.modalGalleryVisible}
          onCancel={this.closeGallery}
          onOk={this.onOkay}
          destroyOnClose="true"
          width={600}
          height={'60vh'}
        >
          <Row className={"primary-tree-wrapper " + getThemeClass(_.get(this.props, 'persistent_settings.data.theme', 'light'), 'primary-tree')}>
            <Col md={24}>
              <WithInputWrapper>
                <SelectAppliedContentModalTree
                  mode={'bulk_move_modal'}
                  modeContext={TREE_MODE_SELECT_APPLIED_CONTENT}
                  filters={this.props.filters}
                  onSelectFolder={this.onSelectFolder}
                  selectedItems={this.props.selectedItems}
                  resourcetreeFolders={this.props.resourcetreeFolders}
                  appliedModeAction={this.state.actionType}
                />
              </WithInputWrapper>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    resourcetreeFolders: state.data.resourcetree_folders,
    folder_types: state.data.folder_types,
    folders: state.data.folders,
    client_options: state.client_options
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SelectExistingFolder);
