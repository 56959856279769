import React, { Component } from 'react';
import WithInputTouch from './WithInputTouch';
import WithInputHTML5 from './WithInputHTML5';
import { connect } from "react-redux";

class WithInputWrapper extends Component {

  render() {
    return (
      this.props.client_options.inputMode === 'touch' ?
        <WithInputTouch children={this.props.children} />  //drag and drop context set correctly at export
        :
        <WithInputHTML5 children={this.props.children} />   //drag and drop context set correctly at export
    )
  }
}

const mapStateToProps = (state) => {
  return {
    client_options: state.client_options
  };
};


export default connect(mapStateToProps, null)(WithInputWrapper)

