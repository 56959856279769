import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { coburg_category_shared } from "./coburg_category_shared";
import { wysiwyg } from "../standardized_fields";
import { coburgFonts, quillFontSizes } from "./coburg_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

const modifiedFieldGroups = { ...coburg_category_shared.field_groups };
delete modifiedFieldGroups.list;

export const coburg_category_with_giving_level: IFolderType = {
  folder_category: FOLDER_CATEGORY.GROUP,
  name: FOLDER_TYPE.coburg_category_with_giving_level,
  display_name: "Fund Giving Level",
  acceptable_folder_types: [FOLDER_TYPE.coburg_category_without_giving_level],
  tabs: {
    ...coburg_category_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    header: {
      type: "hybrid_text_input",
      title: "Header",
      show_char_count: false,
      field_group: "header",
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "30px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "header_fund_with_giving_levels"
      }
    },
    subhead: {
      type: "hybrid_text_input",
      title: "Subhead",
      show_char_count: false,
      field_group: "subhead",
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "20px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "subhead_fund_with_giving_levels"
      }
    },
    ...coburg_category_shared.fields
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.coburg_category_without_giving_level], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.coburg_category_without_giving_level],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.coburg_category_without_giving_level],
        field: "name",
        title: "Name",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.CoburgDonorListCategoryWrapper,
    type: PREVIEW_TYPE.COMPONENT
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  save_options: [...coburg_category_shared.save_options]
};
