import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const naples_content: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.naples_content,
  display_name: "Naples Content",
  acceptable_folder_types: [
    FOLDER_TYPE.naples_content_stories,
    FOLDER_TYPE.naples_fund_giving_level,
    FOLDER_TYPE.naples_content_donor_lists
  ],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.content
    },
    closed: {
      ...tree_icon_types.content
    }
  },
  isContent: true
};
