import { ErrorObject } from "../shared-global/interfaces/api/api-response.interface";
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_DELETE,
  UPDATE_NOTIFICATIONS_READ,
  ADD_ERROR
} from "./actionTypes";

export const getNotifications = (company_id, type, onSuccess?, onFail?) => ({
  type: GET_NOTIFICATIONS,
  payload: { company: company_id, type },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateNotificationRead = (
  notifications,
  type,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_NOTIFICATIONS_READ,
  payload: { notifications },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateNotificationDelete = (
  notifications,
  type,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_NOTIFICATIONS_DELETE,
  payload: { notifications },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addError = (errors: ErrorObject[] | null) => ({
  payload: {
    errors: errors && errors.length ? errors : [{ message: "Something went wrong", data: null }]
  },
  type: "ADD_ERROR"
});

export const addExceptionError = (exception: Error) => ({
  payload: {
    errors: [
      { message: exception.message || "Something went wrong", data: null }
    ]
  },
  type: "ADD_ERROR"
});
