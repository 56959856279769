import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Col, Modal, Row, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFontGroup,
  removeFontGroup
} from "../../actions/fontGroupCompanyActions";
import { IState } from "../../interfaces/main-state.interface";
import { FIELD_TYPE } from "../../shared-global/enums/folder-type-enums";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
import { IFontGroup } from "../../shared-global/interfaces/models/font_group.interface";
import { validate } from "../../utils/validate";
import FieldWrapper from "../form/FieldWrapper";
import TextInput from "../form/input/TextInput";
import AlertNotification from "../layout/AlertNotification";

interface IFontGroupLinkModalProps {
  getFieldValues: () => any;
  createFontGroup: () => void;
  folder_type: IFolderType;
}

enum MODE {
  CREATE = "create",
  VIEW = "view"
}

const FontGroupLinkModal: React.FC<IFontGroupLinkModalProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IState) => state.data.user);

  const [validationResults, setValidationResults] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [isSelectedFieldsInitialized, setIsSelectedFieldsInitialized] =
    useState(false);
  const [fontGroupName, setFontGroupName] = useState("");
  const [mode, setMode] = useState<MODE>(MODE.VIEW);
  const [selectedFontGroupId, setSelectedFontGroupId] = useState(null);
  const [selectedFontGroup, setSelectedFontGroup] = useState<IFontGroup>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const fontGroups = useSelector((state: IState) => state.data.font_groups);

  const validateForm = (cb) => {
    const result = validate(
      { font_group_name: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      { font_group_name: fontGroupName }
    );
    if (result.isFormValid) {
      cb();
    }
    setValidationResults(result.fieldResults);
  };

  const onSuccessFontGroupDeleted = () => {
    AlertNotification(
      "success",
      "Success",
      "The font group has been successfully deleted"
    );
    setIsDeleteModalOpen(false);
  };

  const deleteFontGroup = () => {
    dispatch(removeFontGroup(selectedFontGroupId, onSuccessFontGroupDeleted));
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (!a.name) {
          a.name = "";
        }
        if (!b.name) {
          b.name = "";
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        return 0;
      }
    },
    {
      title: "Delete",
      dataIndex: "delete",
      render: (_t, record) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFontGroupId(record.id);
              setSelectedFontGroup(record);
              setIsDeleteModalOpen(true);
            }}
          >
            <FontAwesomeIcon className="fa-lg" icon={["far", "trash"]} />
          </span>
        );
      }
    }
  ];

  const fontGroupsArr = useMemo(() => {
    return Object.values(fontGroups)
      .filter((fg) => fg.folder_type === props.folder_type.name || props.folder_type.font_groups.compatible_folder_types.includes(fg.folder_type));
  }, [fontGroups]);

  const toggleField = (fieldName) => {
    let newFields = [...selectedFields];
    const indexOfElement = selectedFields.indexOf(fieldName);
    if (indexOfElement > -1) {
      newFields.splice(indexOfElement, 1);
    } else {
      newFields.push(fieldName);
    }
    setSelectedFields(newFields);
  };

  const onSuccessFontGroupAdded = () => {
    AlertNotification(
      "success",
      "Success",
      "The font group was created successfully"
    );
    setIsModalOpen(false);
    setMode(MODE.VIEW);
    setFontGroupName("");
  };

  const createFontGroup = () => {
    let selectedFieldValues = {};
    const fieldValues = props.getFieldValues();
    Object.keys(fieldValues).forEach((fieldName) => {
      if (selectedFields.indexOf(fieldName) > -1) {
        selectedFieldValues[fieldName] = { ...fieldValues[fieldName] };
        delete selectedFieldValues[fieldName].color;
      }
    });

    dispatch(
      addFontGroup(
        {
          name: fontGroupName,
          contents: selectedFieldValues,
          folder_type: props.folder_type.name,
          company: currentUser.company
        },
        onSuccessFontGroupAdded
      )
    );
  };

  useEffect(() => {
    // Initializing all the fields that are selected by default
    if (props.folder_type && !isSelectedFieldsInitialized) {
      const initialSelectedFields = Object.keys(props.folder_type.fields)
        .filter(
          (fieldName) =>
            props.folder_type.fields[fieldName].type === FIELD_TYPE.textstyle
        )
        .map((fieldName) => fieldName);
      setSelectedFields(initialSelectedFields);
      setIsSelectedFieldsInitialized(true);
    }
  }, [props.folder_type]);

  let fontGroupNameValidationError = '';
  if (validationResults['font_group_name'] && !validationResults['font_group_name'].isValid) {
    fontGroupNameValidationError = validationResults['font_group_name'].error;
  }

  return (
    <>
      {/* Link */}
      <a
        onClick={() => setIsModalOpen(true)}
        style={{
          float: "right",
          fontStyle: "italic",
          textDecoration: "underline"
        }}
      >
        Manage Font Groups &nbsp;&nbsp;
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          className="fa"
          icon={["fas", "chevron-right"]}
        />
      </a>

      <Modal
        title="Font Groups"
        visible={isModalOpen}
        onOk={() => {
          if (mode === MODE.CREATE) {
            validateForm(() => {
              createFontGroup();
              setIsModalOpen(false);
            });
          } else {
            setIsModalOpen(false);
          }
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setMode(MODE.VIEW);
          setFontGroupName("");
        }}
      >
        {mode === MODE.VIEW && (
          <>
            <Button
              type="primary"
              size="middle"
              onClick={() => setMode(MODE.CREATE)}
            >
              Create Font Group
            </Button>
            <br />
            <br />
            {fontGroupsArr.length > 0 ? (
              <Table
                title={() => "Font Groups"}
                rowKey={(record) => record.key}
                columns={tableColumns}
                dataSource={fontGroupsArr}
                pagination={false}
                bordered={true}
              />
            ) : (
              <p
                style={{
                  fontSize: 14,
                  fontStyle: "italic",
                  textAlign: "center"
                }}
              >
                There are no items here
              </p>
            )}
          </>
        )}

        {mode === MODE.CREATE && (
          <>
            <a onClick={() => setMode(MODE.VIEW)}>
              <FontAwesomeIcon icon={["far", "chevron-left"]} />
              &nbsp;&nbsp;Back
            </a>
            <br />
            <br />
            <FieldWrapper
              validationError={fontGroupNameValidationError}
            >
              <TextInput
                title="Font Group Name"
                fieldName="font_group_name"
                defaultValue={fontGroupName}
                showCharCount={false}
                onBlur={(_n: string, value: string) => setFontGroupName(value)}
                onChange={(_n: string, value: string) =>
                  setFontGroupName(value)
                }
                canUpdate
                showLabel
              />
            </FieldWrapper>
            <br />
            <br />
            Select the fields you would like to use to create a font group. All
            the settings from text style options fields will saved except for
            color.
            <br />
            <br />
            <Row>
              {Object.keys(props.folder_type.fields)
                .filter(
                  (fieldName) =>
                    props.folder_type.fields[fieldName].type ===
                    FIELD_TYPE.textstyle
                )
                .map((fieldName) => {
                  const field = props.folder_type.fields[fieldName];
                  return (
                    <Col key={fieldName} xs={8}>
                      <Checkbox
                        key={fieldName}
                        defaultChecked={true}
                        className="checkbox-input"
                        checked={selectedFields.indexOf(fieldName) > -1}
                        onChange={() => toggleField(fieldName)}
                      >
                        <label className="form-input-label">
                          {field.title}
                        </label>
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </>
        )}
      </Modal>

      <Modal
        visible={isDeleteModalOpen}
        onOk={deleteFontGroup}
        onCancel={() => setIsDeleteModalOpen(false)}
        title="Delete Font Group"
      >
        {`Are you sure you want to delete ${selectedFontGroup?.name}?`}
      </Modal>
    </>
  );
};

export default FontGroupLinkModal;
