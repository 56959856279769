import React, { Component } from 'react';
import ImageCropper from '../utils/ImageCropper';

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: ""
    };
  }
  render() {
    //alert("change");
    return (
       <div>
      {/* //   <Upload onChange={this.onChange}>
      //     <Button>
      //       <Icon type="upload" /> Click to Upload
      //   </Button>
      //   </Upload> */}
        <input 
        ref="file" 
        type="file" 
        name="user[image]" 
        multiple="true"
        onChange={this._onChange}/>
        <ImageCropper url={this.props.url} aspect={16/9} />
      </div>
    );
  }

  _onChange = (upload) => {
    //console.log(JSON.stringify(upload.file.name));
    var file = this.refs.file.files[0];
    var reader = new FileReader();
    reader.onloadend = function (e) {
      this.setState({
        imgSrc: [reader.result]
      })
    }.bind(this);
    console.log(file) // Would see a path?

  }
}

export default Demo;