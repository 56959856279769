import _ from "lodash";
import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { sortDonors } from "../../utils/commonDataUtils";
import { getFileDynamic } from "../../utils/fileUtils";
import get from 'lodash/get';

const NAME_PADDING = 0.16; // Em based

class DonorList2107 extends Component {
  _donorRefs = [];
  _donorContainerRef = null;
  _donorCycleHandler = null;

  constructor(props) {
    super(props);
    const {
      data,
      mode,
      aws,
      aws_bucket,
      containerHeight,
      containerWidth,
      webview_signedurls,
    } = props;
    const donorList = _.cloneDeep(
      data.folders.find((f) => f.id === data.base_folder)
    );
    const donors = data.folder__folders
      .filter((ff) => ff.parent_folder === donorList.id)
      .filter((v, i, arr) => arr.indexOf(v) === i)
      .map((ff) => {
        let donor = {...data.folders.find((f) => f.id === ff.child_folder)};
        donor.rank = ff.rank;
        return donor;
      });

    const sorting_method = _.get(donorList, "fields.sorting_options", "rank");

    let sortedDonors = sortDonors(sorting_method, donors);

    const preset = mode === "preview" ? "preview" : null;
    const background = getFileDynamic(
      aws,
      aws_bucket,
      data.resources,
      data.resource__folders,
      ["background_image"],
      data.base_folder,
      preset,
      webview_signedurls
    );
    const settings = this.getSettings(donorList);

    const marginRight =
      (this.props.data.componentSpecificData.donor_list_column_margin_right /
        100) *
      this.props.refWidth;
    const missingMarginAdder =
      marginRight / this.props.data.componentSpecificData.columns - 1;
    const columnWidth =
      ((this.innerNamesWidth() / 100) * containerWidth) /
      this.props.data.componentSpecificData.columns -
      marginRight +
      missingMarginAdder;

    const useDonorLineHeightInPercentage = _.get(this.props.data, 'componentSpecificData.donor_line_height_in_percentage', true);
    let lineHeight = this.props.data.componentSpecificData.donor_line_height;
    if (useDonorLineHeightInPercentage) {
      lineHeight = `${lineHeight}%`;
    }

    const listStyle = this.props.data.componentSpecificData.list_style || 'filled'

    let showGap = false
    let gapHeight = 0
    let listOffset = 0
    let gapPositions = []

    if (this.props.data.componentSpecificData.hasOwnProperty('gapOptions') && this.props.data.componentSpecificData.gapOptions.showGap) {
      showGap = true
      gapHeight = this.props.data.componentSpecificData.gapOptions.gapHeight
      listOffset = this.props.data.componentSpecificData.gapOptions.listOffset
    }

    this.state = {
      width: containerWidth,
      height: containerHeight,
      donors: sortedDonors,
      donorList,
      background,
      donorIndex: 0,
      lastPageCount: 0,
      drawDonors: [],
      finishedPreRender: false,
      columnWidth,
      lineHeight,
      cycleForwardKey: _.get(donorList, "fields.cycle_forward", "ArrowRight"),
      marginRight,
      showGap,
      gapHeight,
      listOffset,
      gapPositions,
      listStyle,
      didCycle: false,
      ...settings,
    };
  }

  innerNamesWidth() {
    const leftMargin =
      this.props.data.componentSpecificData.donor_list_margin_left;
    const rightMargin =
      this.props.data.componentSpecificData.donor_list_margin_right;
    return 100 - leftMargin - rightMargin;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    setTimeout(() => {
      this.handlePreRender();
      this.cycleDonors(true);
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.containerHeight !== this.props.containerHeight) {
      this.setState({ height: this.props.containerHeight });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    clearTimeout(this._donorCycleHandler);
  }

  handleKeyDown = (event) => {
    if (!this.state.didCycle) {
      switch (event.key) {
        case this.state.cycleForwardKey:
          clearTimeout(this._donorCycleHandler);
          this.cycleDonors();
          break;
        default:
          break;
      }
    }
  };

  getSettings = () => {
    const settings = {};
    const defaultSettings = [];
    for (const item of defaultSettings) {
      const value = _.get(this.props, `${item.name}`, item.value);
      settings[item.name] = value;
    }
    return settings;
  };

  getDonorStyle = (donor) => {
    const { donors, columnWidth, lineHeight } = this.state;

    let colMarginRight =
      ((this.props.data.componentSpecificData.donor_list_column_margin_right ||
        0) /
        100) *
      this.props.refWidth;

    if (donor.column === this.props.data.componentSpecificData.columns) {
      colMarginRight = 0;
    }

    const style = this.getStyle();

    return {
      ...style,
      ...this.props.data.componentSpecificData.donorNameStyle,
      position: "absolute",
      overflow: "hidden",
      maxWidth: columnWidth,
      width: columnWidth,
      textAlign: this.props.data.componentSpecificData.text_alignment || "left",
      whiteSpace: "pre-wrap",
      lineHeight,
      fontVariant: 'normal',
      WebkitFontFeatureSettings: 'normal',
    }
  };

  getStyle = () => {
    const { width, height } = this.state;
    const scaleBasedDim = height;

    const style = {
      fontSize: _.get(
        this.props,
        "overrides.donorFontSize",
        scaleBasedDim * 0.025
      ),
      fontStyle: "Normal",
      fontFamily: _.get(this.props, "overrides.donorFontFamily", "Lato"),
      fontWeight: "100",
      textAlign: this.props.data.componentSpecificData.text_alignment || "left",
      color: _.get(this.props, "overrides.fontColor", "#000000"),
    };
    return style;
  };

  handleCycleInterval = () => {
    clearTimeout(this._donorCycleHandler);
    this._donorCycleHandler = setTimeout(() => {
      this.cycleDonors();
    }, this.props.data.componentSpecificData.cycle);
  };

  cycleDonors = (initial = false) => {
    const { donorIndex, lastPageCount, donors } = this.state;
    const { handleEndOfPlay } = this.props;

    if (((donorIndex + lastPageCount >= donors.length) && donors.length > 0) || (donors.length === 0 && !initial)) {
      if (handleEndOfPlay) {
        return this.setState({ didCycle: true }, () => {
          return handleEndOfPlay();
        });
      }
      const { donors, lastPageCount: lpc, drawDonors } = this.prepareNames(0);
      const newDonorIndex = lpc;
      this.setState({
        finishedPreRender: true,
        donorIndex: newDonorIndex,
        donors,
        lastPageCount,
        drawDonors,
      });
      this.handleCycleInterval();
    } else {
      const {
        donors,
        lastPageCount: lpc,
        drawDonors,
      } = this.prepareNames(donorIndex);
      const newDonorIndex = donorIndex + lpc;
      this.setState({
        finishedPreRender: true,
        donorIndex: newDonorIndex,
        donors,
        lastPageCount,
        drawDonors,
      });
      this.handleCycleInterval();
    }
  };

  rowNames = (donorIndex) => {
    const { width, height, donors } = this.state;
    const donorVerticalSeparation =
      (this.props.data.componentSpecificData.donor_vertical_separation / 100) *
      this.props.refHeight;

    let lastPageCount = 0;
    let currentWidth = 0;
    let drawDonors = [];
    const columnsWidth = width / this.props.data.componentSpecificData.columns;
    const maxWidth = width - columnsWidth
    let currentTop = 0
    for (let i = donorIndex; i < donors.length; i = i + 1) {
      const donor = donors[i];
      if (Math.round(currentWidth) > Math.round(maxWidth)) {
        currentTop += donor.height + donorVerticalSeparation
        currentWidth = 0
      }
      if (currentTop + donor.height + donorVerticalSeparation > height) {
        break;
      }
      donor.left = currentWidth
      donor.top = currentTop
      currentWidth += columnsWidth
      drawDonors.push(donor);
      lastPageCount++;
    }
    return { donors, lastPageCount, drawDonors };
  }

  prepareNames = (donorIndex = 0, isRefilling = false) => {
    const { height, donors, showGap } = this.state;

    let drawDonors = [];
    let currentGap = 0;
    let currentColumn = 1;
    let lastPageCount = 0;
    let allColumnsFilled = false
    let currentTopPosition = 0;

    // Used for balanced list style
    const donorsLeft = donors.length - donorIndex
    const lastLongestColumn = donorsLeft % this.props.data.componentSpecificData.columns
    let donorsColumnRendered = 0
    let roundedUpColumnLength = Math.ceil(donorsLeft / this.props.data.componentSpecificData.columns);

    const style = this.getStyle();
    const donorVerticalSeparation =
      (this.props.data.componentSpecificData.donor_vertical_separation / 100) *
      this.props.refHeight;

    // TODO: Add support so the offset can be applied to specific screens only
    // const horizontalScreenIndexArrayToApplyOffsetOn = get(props, 'data.componentSpecificData.gapOptions.horizontalScreenIndexArrayToApplyOffsetOn', []);
    // const horizontalIndex = currentColumn / this.props.data.componentSpecificData.columns;

    for (let i = donorIndex; i < donors.length; i = i + 1) {
      const donor = donors[i];
      const donorTotalHeight = donor.height +
        donorVerticalSeparation +
        style.fontSize * NAME_PADDING * 2;

      if (!isRefilling) {
        // We check if the next donor fits in the current column.
        if (currentTopPosition + donorTotalHeight > height) {
          if (currentColumn < this.props.data.componentSpecificData.columns) {
            // If it doesn't fit, we check if we can move to the next column.
            currentColumn++;
            currentGap = 0;
            currentTopPosition = 0;
          } else {
            // If we can't move to the next column, we break the loop.
            allColumnsFilled = true;
            break;
          }
        }
      } else {
        if (currentColumn === lastLongestColumn && donorsColumnRendered === roundedUpColumnLength) {
          roundedUpColumnLength--
        }
        if (donorsColumnRendered >= roundedUpColumnLength) {
          currentColumn++;
          currentGap = 0
          donorsColumnRendered = 0
          currentTopPosition = 0;
        }

        donorsColumnRendered++
      }

      // We set the donor's column and top position.
      donor.top = currentTopPosition;
      donor.column = currentColumn;

      const gapPositions = get(this.props, 'data.componentSpecificData.gapOptions.gapPositions', []);

      // We determine how we position the donor based on the gap settings.
      if (!allColumnsFilled) {
        if (showGap) {
          if (currentTopPosition + donorTotalHeight > gapPositions[currentGap] - this.state.gapHeight) {
            currentTopPosition = gapPositions[currentGap] + this.state.gapHeight;
            donor.top = currentTopPosition;
            currentTopPosition += donorTotalHeight;
            currentGap++
          } else {
            currentTopPosition += donorTotalHeight;
          }
        } else {
          currentTopPosition += donorTotalHeight;
        }
      }

      drawDonors.push(donor);
      lastPageCount++;
    }

    // If we're using the balanced list style and we haven't filled all columns, we redo the page.
    if (!allColumnsFilled && this.state.listStyle === 'balanced' && !isRefilling) {
      this.prepareNames(donorIndex, true);
    }

    return { donors, lastPageCount, drawDonors };
  }

  renderNames = () => {
    const { drawDonors, columnWidth, lineHeight } = this.state;
    const style = this.getStyle();

    return drawDonors.map((donor, i) => {
      return (
        <div
          key={i}
          style={{
            ...style,
            ...this.getDonorStyle(donor),
            fontVariant: 'normal',
            WebkitFontFeatureSettings: 'normal',
            top: donor.top,
            left: (donor.column - 1) * (columnWidth + this.state.marginRight),
            opacity: 1,
            lineHeight,
            paddingTop: `${NAME_PADDING}em`,
            paddingBottom: `${NAME_PADDING}em`,
          }}
        >
          <div>
            {this.replaceCrossSymbol(donor.name)}
            <br />
          </div>
        </div>
      );
    });
  };

  handlePreRender = () => {
    const { donors } = this.state;
    if (this._donorRefs.length > 0 && this.state.finishedPreRender === false) {
      let counter = -1;

      donors.forEach((donor) => {
        counter++;
        if (!this._donorRefs[counter]) return
        donor.height = this._donorRefs[counter].offsetHeight;
        donor.width = this._donorRefs[counter].offsetWidth;
      });

      this.setState({
        donors,
      });
    }
  };

  replaceCrossSymbol = (name) => {
    return name.replace("†", "✝");
  };

  preRendered = () => {
    const { donors } = this.state;
    return donors.map((donor, i) => {
      return (
        <div
          key={i}
          ref={(el) => (this._donorRefs[i] = el)}
          style={{
            ...this.getDonorStyle(donor),
            opacity: 0,
            paddingTop: 0.002983507 * this.props.containerWidth,
            paddingBottom: 0.002983507 * this.props.containerWidth
          }}
        >
          {this.replaceCrossSymbol(donor.name)}
        </div>
      );
    });
  };

  render() {
    const { finishedPreRender, background, donorIndex, height, width } =
      this.state;
    const donors = finishedPreRender ? this.renderNames() : this.preRendered();
    return (
      <div
        style={{
          top:
            this.props.data.componentSpecificData.container_margin_top *
            (this.props.containerHeight / 100),
          left:
            this.props.data.componentSpecificData.donor_list_margin_left *
            (this.props.containerHeight / 100),
          background: `url(${background})`,
          backgroundSize: "cover",
          position: "absolute",
          height,
          width: (this.innerNamesWidth() / 100) * width,
          color: _.get(this.props, "overrides.fontColor", "#000000"),
        }}
      >
        <div
          ref={(el) => (this._donorContainerRef = el)}
          style={{
            position: "absolute",
            height,
            width: (this.innerNamesWidth() / 100) * width,
          }}
        >
          <TransitionGroup>
            <CSSTransition
              key={donorIndex}
              timeout={500}
              classNames="transition-fade"
            >
              <div>{donors}</div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
  }
}

export default DonorList2107;
