import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { transitions } from "./monterrey_settings";

export const monterrey_control_panel_attract_loop: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  name: FOLDER_TYPE.monterrey_control_panel_attract_loop,
  display_name: "Attract Loop",
  acceptable_folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      options: transitions
    },
    background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      default: "#0000004d"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.6,
    component_name: COMPONENT_NAME.Monterrey1x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop_slide],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },

  client: {
    component_name: COMPONENT_NAME.None
  }
};
