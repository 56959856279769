import React, { useRef } from "react";
import RichTextRender from "../../standard/RichTextRender";
import { getFieldValue } from "../../../utils/generalUtils";
import get from "lodash/get";
import HandTouch from "../../../images/global/HandTouch";
import QRCode from "react-qr-code";

const MarinFooter = (props) => {
  const call_to_action = getFieldValue(
    props.baseFolder,
    "call_to_action",
    props.baseFolderType
  );
  const qr_call_to_action = getFieldValue(
    props.baseFolder,
    "qr_call_to_action",
    props.baseFolderType
  );
  const qr_link = get(props.baseFolder, "fields.qr_link", "");
  const footer_line_color =
    get(props.baseFolder, "fields.footer_line_color") || "#EFEFEF";
  const footer_icons_color = get(
    props.baseFolder,
    "fields.footer_icons_color",
    "#000"
  );
  const horizontalRuleSize = get(
    props.baseFolder,
    "fields.horizontal_rule_size",
    0.125
  );
  const lineHeight = props.containerHeight * (horizontalRuleSize / 100);

  const wrapperRef = useRef<any>();

  const show_border = call_to_action || qr_call_to_action;
  const centered = !call_to_action || !qr_call_to_action;

  const renderCallToActionSection = () => {
    return call_to_action ? (
      <div
        className={props.classes.componentContainer}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "1em",
          width: centered ? "auto" : "100%",
          maxWidth: "50%",
        }}
      >
        <div
          style={{
            marginRight: "1em",
            padding: "0.5em",
            width: "4em",
            height: "4em",
          }}
        >
          <HandTouch color={footer_icons_color} />
        </div>
        <RichTextRender
          style={{
            padding: 0,
          }}
          htmlString={call_to_action}
        />
      </div>
    ) : (
      ""
    );
  };

  const renderQrCodeSection = () => {
    return qr_call_to_action ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "1em",
          width: centered ? "auto" : "100%",
          maxWidth: "50%",
        }}
      >
        <div className={props.classes.componentContainer}>
          <RichTextRender
            style={{
              padding: 0,
            }}
            htmlString={qr_call_to_action}
          />
        </div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: props.refWidth * 0.0592,
            width: "100%",
            marginLeft: "1em",
          }}
          ref={wrapperRef}
        >
          {qr_link ? (
            <QRCode
              value={qr_link}
              size={props.refWidth * 0.0592}
              fgColor={footer_icons_color}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    ) : (
      ""
    );
  };

  return (
    <div
      className="cta-qr"
      style={{
        margin: "2.5em 5em",
        flexDirection: "row",
        justifyContent: "center",
        gridColumnGap: "2em",
      }}
    >
      <div
        className="cta-qr-wrapper"
        style={{
          width: "100%",
          marginTop: "2.5em",
          display: "flex",
          justifyContent: "center",
          gridColumnGap: "2em",
          borderTop: show_border
            ? `${lineHeight}px solid` + footer_line_color
            : "none",
          alignItems: "center",
        }}
      >
        {renderCallToActionSection()}
        {renderQrCodeSection()}
      </div>
    </div>
  );
};

export default MarinFooter;
