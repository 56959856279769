import get from "lodash/get";
import React from "react";
import { createUseStyles } from "react-jss";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const BerkeleyTemplate002 = (props) => {
  const {getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue} = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType()
  const { getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();

  const backgroundOverlay = getBaseFolderFieldValue("background_image_overlay")
  const mirrorContent = getBaseFolderFieldValue("mirror_content")
  const quoteBackground = getBaseFolderFieldValue("quote_background")
  const textTop = getBaseFolderFieldValue("quote_text_top", baseFolderType)
  const textBottom = getBaseFolderFieldValue("quote_text_bottom", baseFolderType)


  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundOverlay,
    mirrorContent,
    quoteBackground
  });

  const propsClasses = get(props, 'classes.componentContainer', '');

  return (
    <div className={[classes.componentContainer, propsClasses].join(" ")}>
      <div className={classes.componentContainer}>
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["background_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain"
          }}
        />
      </div>
      <div className={classes.componentOverlay}/>
      <div className={classes.componentContent}>
        <div className={`quote ${richTextClasses.componentContainer}`}>
          <div className="quoteTextTop">
            <RichTextRender
              htmlString={textTop}
            />
          </div>
          <div className="quoteTextBottom">
            <RichTextRender
              htmlString={textBottom}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BerkeleyTemplate002;
