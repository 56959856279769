import { FOLDER_TYPE } from "../../shared-global/enums/folder-type-enums";

export const eugeneRelevantStoryTypeNames = [
  FOLDER_TYPE.eugene_template_001,
  FOLDER_TYPE.eugene_template_002,
  FOLDER_TYPE.eugene_template_003,
  FOLDER_TYPE.eugene_template_004,
  FOLDER_TYPE.eugene_template_005,
]

export const eugeneRelevantSubStoryTypeNames = [
  FOLDER_TYPE.eugene_stories,
]

export const eugeneRelevantDonorListMenuTypeNames = [
  FOLDER_TYPE.eugene_donor_list_menu,
]

export const eugeneRelevantFolderTypeNames = [
  ...eugeneRelevantStoryTypeNames,
  ...eugeneRelevantSubStoryTypeNames,
  ...eugeneRelevantDonorListMenuTypeNames,
  FOLDER_TYPE.eugene_donor,
]

export const eugeneStopFolderTypes = [
  FOLDER_TYPE.eugene_menu,
  FOLDER_TYPE.eugene_attract_loop,
  FOLDER_TYPE.eugene_search
]