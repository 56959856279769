
import React from 'react';
import { Select } from 'antd';
import get from 'lodash/get';

interface ISelectOption {
  title: any,
  value: any
}

interface ISelectInputProps {
  fieldName: string,
  title: string,
  defaultValue: string | number
  onChange: Function;
  onBlur: Function;
  options: ISelectOption[];
  showLabel: boolean;
  canUpdate: boolean;
  settings?: any;
  className?: string;
}

const SelectInput = (props: ISelectInputProps) => {

  const selectOptions = props.options.map(option => {
    return (
      <Select.Option key={option.value} value={option.value}>
        {option.title}
      </Select.Option>
    );
  });
  const onlyOption = props.options.length === 1;
  return (
    <React.Fragment>
      {props.showLabel ?<span><label className="form-input-label">{props.title}</label><br/></span> : null}
      <Select
        style={{ minWidth: get(props, 'settings.minWidth', 250) }}
        disabled = {!props.canUpdate || onlyOption}
        className={`select-input ${props.className ? props.className : ''}`}
        defaultValue={props.defaultValue}
        onChange={newValue => {
          props.onChange(props.fieldName, newValue);
          props.onBlur(props.fieldName, newValue);
        }}
        value={props.defaultValue}
      >
        {selectOptions}
      </Select>
    </React.Fragment>
  );
};

export default SelectInput;
