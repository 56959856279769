import { shuffle } from "lodash";
import React from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  Checkerboard,
  CheckerboardGridType,
} from "../../standard/Checkerboard/Checkerboard";
import { useFolder } from "../../../hooks/useFolder";
import { textStyleOptionsToRegularStyle } from "../../../utils/generalUtils";

const PhoenixCheckerboard: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const { getBaseFolderFieldValue, getBaseResourcesBySlot } = useFolder(
    props.data.base_folder
  );

  const grids: CheckerboardGridType[] = [
    [
      ["slide", "inspirationalWord", "slide"],
      ["relatedWord", "slide", "relatedWord"],
      ["slide", "relatedWord", "slide"],
    ],
    [
      ["relatedWord", "slide", "relatedWord"],
      ["slide", "inspirationalWord", "slide"],
      ["relatedWord", "slide", "relatedWord"],
    ],
  ];

  const backgroundColorOverlay = getBaseFolderFieldValue(
    "background_color_overlay"
  );

  const slides = shuffle(getBaseResourcesBySlot("checkerboard_media") ?? []);

  const wordsColorOverlay = getBaseFolderFieldValue(
    "inspirational_words_color_overlay"
  );

  const inspirationalWords = shuffle(
    getBaseFolderFieldValue("inspirational_words", "")
      .split(",")
      .map((w) => w.trim())
  );
  const inspirationalWordsStyle = textStyleOptionsToRegularStyle(
    getBaseFolderFieldValue("inspirational_words_style", {})
  );
  const relatedWords = shuffle(
    getBaseFolderFieldValue("related_inspirational_words", "")
      .split(",")
      .map((w) => w.trim())
  );
  const relatedWordsStyle = textStyleOptionsToRegularStyle(
    getBaseFolderFieldValue("related_inspirational_words_style", {})
  );

  return (
    <Checkerboard
      componentData={props.data}
      grids={grids}
      backgroundColorOverlay={backgroundColorOverlay}
      backgroundSlots={["background_image"]}
      slides={slides}
      slideSlot={"checkerboard_media"}
      wordsColorOverlay={wordsColorOverlay}
      inspirationalWords={inspirationalWords}
      inspirationalWordsStyle={inspirationalWordsStyle}
      relatedWords={relatedWords}
      relatedWordsStyle={relatedWordsStyle}
      handleEndOfPlay={props.handleEndOfPlay}
    />
  );
};

export default PhoenixCheckerboard;
