import { templates } from '../shared-global/constants/templates';


export function getThemeClass(theme, class_name) {
    switch (theme) {
      case "dark":
        switch (class_name) {
          case "layout-main":
            return "dark-theme";
          case "primary-tree":
            return "dark-theme-primary-tree";
          default:
            return null;
        }
      case "light":
      default:
        switch (class_name) {
          case "layout-main":
            return "light-theme";
          case "primary-tree":
            return "light-theme-primary-tree";
          default:
            return null;
        }
    }
  }

export function getCompanyTemplates(companyId, project_templates, folders) {
  const returnTemplates = [{title: "All themes", value: "All themes"}];
  const foldersArray = Object.values(folders);
  const projectTemplates = Object.values(project_templates);
  const companyFolders = foldersArray.filter(
    (folder) => folder.company === companyId
  );

  projectTemplates.forEach((projectTemplate) => {
    const foundFolder = companyFolders.find(
      (companyFolder) =>
        companyFolder.folder_type === projectTemplate.root_folder
    );
    if (foundFolder) {
      returnTemplates.push({
        title: projectTemplate.display_name,
        value: projectTemplate.root_folder,
      });
    }
  });

  return returnTemplates;
}

export function getCurrentTemplate(currentFolderType, folderTypes) {
  let foundTemplate = '';

  while (!foundTemplate) {
    const foundParent = Object.values(folderTypes).find(f => (f.acceptable_folder_types ?? []).indexOf(currentFolderType.name) > -1);
    if (!foundParent) {
      const matches = currentFolderType.name.match(/(\w+)_(root|presentation|display)/);
      if (matches) {
        foundTemplate = matches[1];
      }
    } else {
      const matches = foundParent.name.match(/(\w+)_(root|presentation|display)/);
      if (matches) {
        foundTemplate = matches[1];
      } else {
        currentFolderType = foundParent;
      }
    }
  }

  return foundTemplate;  
}