import React, { lazy } from 'react';
import { COMPONENT_NAME } from "../../../../shared-global/enums/folder-type-enums";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { Alert } from "../../standard/Alert/Alert";
import CoburgMenu from '../../theme/coburg/CoburgMenu';
import GlenwoodSlide from '../../theme/glenwood/GlenwoodSlide';
import GlenwoodBackground from '../../theme/glenwood/GlenwoodBackground';
import { LosAngelesDonorLists } from "../../theme/los_angeles/LosAngelesDonorLists";
import { LosAngelesThreePanel } from "../../theme/los_angeles/LosAngelesThreePanel";
const FremontDonor001 = lazy(() => import("../../theme/fremont/FremontDonor001"))
const ComponentLoop1911 = lazy(() => import("../ComponentLoop1911"))
const Donor2103 = lazy(() => import("../Donor2103"))
const DonorList1911 = lazy(() => import("../DonorList1911"))
const IDCWrap = lazy(() => import("../IDCWrap"))
const MobileTemplate = lazy(() => import("../MobileTemplate"))
const None = lazy(() => import("../None"))
const PassThroughWrapper = lazy(() => import("../PassThroughWrapper"))
const Slide1911 = lazy(() => import("../Slide1911"))
const Boston1x2and1x5and3x2Container = lazy(() => import("../../theme/boston/Boston1x2and1x5and3x2/Boston1x2and1x5and3x2Container"))
const Boston2x5and3x2Container = lazy(() => import("../../theme/boston/Boston2x5and3x2/Boston2x5and3x2Container"))
const Boston3x2Container = lazy(() => import("../../theme/boston/Boston3x2/Boston3x2Container"))
const Boston3x2and1x4Container = lazy(() => import("../../theme/boston/Boston3x2and1x4/Boston3x2and1x4Container"))
const BostonDiamondPlatinumListDonor = lazy(() => import("../../theme/boston/BostonDiamondPlatinumListDonor"))
const BostonDonorSeaDonor = lazy(() => import("../../theme/boston/BostonDonorSeaDonor"))
const DonorSea = lazy(() => import("../../theme/boston/DonorSea"))
const CoburgCardPreview = lazy(() => import("../../theme/coburg/CoburgCardPreview"))
const CoburgContainer = lazy(() => import("../../theme/coburg/CoburgContainer"))
const CoburgDonorListCategoryWrapper = lazy(() => import("../../theme/coburg/CoburgDonorListCategoryWrapper"))
const CoburgDonorListGroup = lazy(() => import("../../theme/coburg/CoburgDonorListGroup"))
const CoburgDonorListsOverview = lazy(() => import("../../theme/coburg/CoburgDonorListsOverview"))
const CoburgRootPreview = lazy(() => import("../../theme/coburg/CoburgRootPreview"))
const CoburgSearch = lazy(() => import("../../theme/coburg/CoburgSearch"))
const CoburgStoriesGallery = lazy(() => import("../../theme/coburg/CoburgStoriesGallery"))
const CoburgStoriesWrapper = lazy(() => import("../../theme/coburg/CoburgStoriesWrapper"))
const CoburgStoryGroup = lazy(() => import("../../theme/coburg/CoburgStoryGroup"))
const CoburgTemplate001 = lazy(() => import("../../theme/coburg/CoburgTemplate001"))
const CoburgTemplate002 = lazy(() => import("../../theme/coburg/CoburgTemplate002"))
const CoburgTemplate003 = lazy(() => import("../../theme/coburg/CoburgTemplate003"))
const CoburgTemplate004 = lazy(() => import("../../theme/coburg/CoburgTemplate004"))
const EugeneCardPreview = lazy(() => import("../../theme/eugene/EugeneCardPreview"))
const EugeneContainer = lazy(() => import("../../theme/eugene/EugeneContainer"))
const EugeneDonorListCategoryWrapper = lazy(() => import("../../theme/eugene/EugeneDonorListCategoryWrapper"))
const EugeneDonorListMenu = lazy(() => import("../../theme/eugene/EugeneDonorListMenu"))
const EugeneDonorListsOverview = lazy(() => import("../../theme/eugene/EugeneDonorListsOverview"))
const EugeneMenu = lazy(() => import("../../theme/eugene/EugeneMenu"))
const EugeneRootPreview = lazy(() => import("../../theme/eugene/EugeneRootPreview"))
const EugeneSearch = lazy(() => import("../../theme/eugene/EugeneSearch"))
const EugeneSlide = lazy(() => import("../../theme/eugene/EugeneSlide"))
const EugeneStories = lazy(() => import("../../theme/eugene/EugeneStories"))
const EugeneStoriesMenu = lazy(() => import("../../theme/eugene/EugeneStoriesMenu"))
const EugeneStoriesMenuGallery = lazy(() => import("../../theme/eugene/EugeneStoriesMenuGallery"))
const EugeneStoriesWrapper = lazy(() => import("../../theme/eugene/EugeneStoriesWrapper"))
const EugeneTemplate001 = lazy(() => import("../../theme/eugene/EugeneTemplate001"))
const EugeneTemplate002 = lazy(() => import("../../theme/eugene/EugeneTemplate002"))
const EugeneTemplate003 = lazy(() => import("../../theme/eugene/EugeneTemplate003"))
const EugeneTemplate004 = lazy(() => import("../../theme/eugene/EugeneTemplate004"))
const EugeneTemplate005 = lazy(() => import("../../theme/eugene/EugeneTemplate005"))
const FreemontDonorList001 = lazy(() => import("../../theme/fremont/FreemontDonorList001.js"))
const Fremont1x2Container2002 = lazy(() => import("../../theme/fremont/Fremont1x2Container2002"))
const FremontMediaTemplate001 = lazy(() => import("../../theme/fremont/FremontMediaTemplate001.js"))
const FremontMediaTemplate002 = lazy(() => import("../../theme/fremont/FremontMediaTemplate002.js"))
const FremontOverlaySlide1603 = lazy(() => import("../../theme/fremont/FremontOverlaySlide1603.js"))
const FremontTemplate001 = lazy(() => import("../../theme/fremont/FremontTemplate001"))
const FremontTemplate002 = lazy(() => import("../../theme/fremont/FremontTemplate002"))
const FremontTemplate003 = lazy(() => import("../../theme/fremont/FremontTemplate003"))
const FremontTemplate004 = lazy(() => import("../../theme/fremont/FremontTemplate004"))
const FremontTemplate005 = lazy(() => import("../../theme/fremont/FremontTemplate005"))
const LosAngelesSlide = lazy(() => import("../../theme/los_angeles/LosAngelesSlide"))
const Naples1x3Container = lazy(() => import("../../theme/naples/Naples1x3Container"))
const Everett2x2Container = lazy(() => import("../../theme/everett/Everett2x2Container"))
const Marin8x1Container = lazy(() => import("../../theme/marin/Marin8x1Container"))
const Marin1x1Container = lazy(() => import("../../theme/marin/Marin1x1Container"))
const Monterrey5x1Container = lazy(() => import("../../theme/monterrey/Monterrey5x1Container"))
const Monterrey1x1Container = lazy(() => import("../../theme/monterrey/Monterrey1x1Container"))
const NewOrleans1x2Container = lazy(() => import("../../theme/new_orleans/NewOrleans1x2Container"))
const NewOrleansDonor = lazy(() => import("../../theme/new_orleans/NewOrleansDonor"))
const NewOrleansDonorList = lazy(() => import("../../theme/new_orleans/NewOrleansDonorList"))
const NewOrleansTemplate001 = lazy(() => import("../../theme/new_orleans/NewOrleansTemplate001"))
const NewOrleansTemplate002 = lazy(() => import("../../theme/new_orleans/NewOrleansTemplate002"))
const NewOrleansTemplate003 = lazy(() => import("../../theme/new_orleans/NewOrleansTemplate003"))
const PhoenixContainer = lazy(() => import("../../theme/phoenix/PhoenixContainer"))
const SpringfieldContainer = lazy(() => import("../../theme/springfield/SpringfieldContainer"))
const SpringfieldDonor = lazy(() => import("../../theme/springfield/SpringfieldDonor"))
const SpringfieldDonorList = lazy(() => import("../../theme/springfield/SpringfieldDonorList"))
const SpringfieldRootPreview = lazy(() => import("../../theme/springfield/SpringfieldRootPreview"))
const SpringfieldTemplate001 = lazy(() => import("../../theme/springfield/SpringfieldTemplate001"))
const SpringfieldTemplate002 = lazy(() => import("../../theme/springfield/SpringfieldTemplate002"))
const SpringfieldTemplate003 = lazy(() => import("../../theme/springfield/SpringfieldTemplate003"))
const SpringfieldTemplate004 = lazy(() => import("../../theme/springfield/SpringfieldTemplate004"))
const SpringfieldPortraitContainer = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitContainer"))
const SpringfieldPortraitDonor = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitDonor"))
const SpringfieldPortraitDonorList = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitDonorList"))
const SpringfieldPortraitRootPreview = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitRootPreview"))
const SpringfieldPortraitTemplate001 = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitTemplate001"))
const SpringfieldPortraitTemplate002 = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitTemplate002"))
const SpringfieldPortraitTemplate003 = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitTemplate003"))
const SpringfieldPortraitTemplate004 = lazy(() => import("../../theme/springfield_portrait/SpringfieldPortraitTemplate004"))
const MonterreyDynamicContainer = lazy(() => import("../../theme/monterrey/MonterreyDynamicContainer"))
const MarinDynamicContainer = lazy(() => import("../../theme/marin/MarinDynamicContainer"))
const GlenwoodTemplateCardPreview = lazy(() => import("../../theme/glenwood/GlenwoodTemplateCardPreview"))
const GlenwoodContainer = lazy(() => import("../../theme/glenwood/GlenwoodContainer"))
const GlenwoodDonorListCategoryWrapper = lazy(() => import("../../theme/glenwood/GlenwoodDonorListCategoryWrapper"))
const GlenwoodDonorListGroup = lazy(() => import("../../theme/glenwood/GlenwoodDonorListGroup"))
const GlenwoodDonorListsOverview = lazy(() => import("../../theme/glenwood/GlenwoodDonorListsOverview"))
const GlenwoodRootPreview = lazy(() => import("../../theme/glenwood/GlenwoodRootPreview"))
const GlenwoodSearch = lazy(() => import("../../theme/glenwood/GlenwoodSearch"))
const GlenwoodStoriesGallery = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodStoriesGallery"))
const GlenwoodStoryGroup = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodStoryGroup"))
const GlenwoodTemplate001 = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodTemplate001/GlenwoodTemplate001"))
const GlenwoodTemplate002 = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodTemplate002/GlenwoodTemplate002"))
const GlenwoodTemplate003 = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodTemplate003/GlenwoodTemplate003"))
const GlenwoodTemplate004 = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodTemplate004/GlenwoodTemplate004"))
const GlenwoodTemplate005 = lazy(() => import("../../theme/glenwood/GlenwoodStories/GlenwoodTemplate005/GlenwoodTemplate005"))
const BerkeleyContainer = lazy(() => import("../../theme/berkeley/BerkeleyContainer"))
const AlpineContainer = lazy(() => import("../../theme/alpine/AlpineContainer"))
const TangentContainer = lazy(() => import("../../theme/tangent/TangentContainer"))
const WebviewLoader = lazy(() => import("../../standard/WebviewLoader"))

interface IPreviewHandlerProps {
  componentName: string,
  previewComponentProps?: IDisplayComponentProps,
  parent_folder?: any
  alertTrigger?: string
  setFolderTypeAlertTrigger?: Function
}
const PreviewHandler: React.FC<IPreviewHandlerProps> = (props) => {
  const getAppliedContentPreviewComponent = (
    componentName: string,
    previewComponentProps?: IDisplayComponentProps,
  ) => {
    const key = componentName;
    switch (componentName) {
      case COMPONENT_NAME.MobileTemplate:
        return <MobileTemplate key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.PassThroughWrapper:
        return <PassThroughWrapper key={key} {...previewComponentProps} />;
      case "DonorList1911":
        return <DonorList1911 key={key} {...previewComponentProps} />;
      case "Slide1911":
        return (
          <Slide1911
            key={key}
            handleEndOfPlay={() => {}}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.BostonDiamondPlatinumListDonor:
        return (
          <BostonDiamondPlatinumListDonor
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.BostonDonorSeaDonor:
        return <BostonDonorSeaDonor key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Boston3x2and1x4Container:
        return (
          <Boston3x2and1x4Container key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.Boston2x5and3x2Container:
        return (
          <Boston2x5and3x2Container key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.Boston3x2Container:
        return <Boston3x2Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Boston1x2and1x5and3x2Container:
        return (
          <Boston1x2and1x5and3x2Container
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.Naples1x3Container:
        return <Naples1x3Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Everett2x2Container:
        return <Everett2x2Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.PhoenixContainer:
        return <PhoenixContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.BerkeleyContainer:
        return <BerkeleyContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.AlpineContainer:
        return <AlpineContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.TangentContainer:
        return <TangentContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Marin8x1Container:
        return <Marin8x1Container key={key} {...previewComponentProps} />;

      case COMPONENT_NAME.Marin1x1Container:
        return <Marin1x1Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.MarinDynamicContainer:
        return <MarinDynamicContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Monterrey5x1Container:
        return <Monterrey5x1Container key={key} {...previewComponentProps} />;

      case COMPONENT_NAME.Monterrey1x1Container:
        return <Monterrey1x1Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.MonterreyDynamicContainer:
        return (
          <MonterreyDynamicContainer key={key} {...previewComponentProps} />
        );
      case "FremontOverlaySlide":
        return (
          <FremontOverlaySlide1603
            key={key}
            handleEndOfPlay={() => {}}
            parent_folder={props.parent_folder}
            {...previewComponentProps}
          />
        );
      case "ComponentLoop1911":
        return <ComponentLoop1911 key={key} {...previewComponentProps} />;
      case "Fremont1x2Container2002":
        return <Fremont1x2Container2002 key={key} {...previewComponentProps} />;
      case "FremontTemplate001":
        return <FremontTemplate001 key={key} {...previewComponentProps} />;
      case "FremontTemplate002":
        return <FremontTemplate002 key={key} {...previewComponentProps} />;
      case "FremontTemplate003":
        return <FremontTemplate003 key={key} {...previewComponentProps} />;
      case "FremontTemplate004":
        return <FremontTemplate004 key={key} {...previewComponentProps} />;
      case "FremontTemplate005":
        return <FremontTemplate005 key={key} {...previewComponentProps} />;
      case "FremontMediaTemplate001":
        return <FremontMediaTemplate001 key={key} {...previewComponentProps} />;
      case "FremontMediaTemplate002":
        return <FremontMediaTemplate002 key={key} {...previewComponentProps} />;
      case "FreemontDonorList001":
        return <FreemontDonorList001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.FreemontDonor001:
        return <FremontDonor001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.LosAngelesThreePanel:
        return <LosAngelesThreePanel key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.LosAnglesDonorLists:
        return <LosAngelesDonorLists key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.LosAnglesSlide:
        return (
          <LosAngelesSlide
            key={key}
            handleEndOfPlay={() => {}}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.SpringfieldContainer:
        return <SpringfieldContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldTemplate001:
        return <SpringfieldTemplate001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldTemplate002:
        return <SpringfieldTemplate002 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldTemplate003:
        return <SpringfieldTemplate003 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldTemplate004:
        return <SpringfieldTemplate004 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldDonorList:
        return <SpringfieldDonorList key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldDonor:
        return <SpringfieldDonor key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.SpringfieldRootPreview:
        return <SpringfieldRootPreview key={key} {...previewComponentProps} />;

      case COMPONENT_NAME.SpringfieldPortraitContainer:
        return (
          <SpringfieldPortraitContainer key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.SpringfieldPortraitTemplate001:
        return (
          <SpringfieldPortraitTemplate001
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.SpringfieldPortraitTemplate002:
        return (
          <SpringfieldPortraitTemplate002
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.SpringfieldPortraitTemplate003:
        return (
          <SpringfieldPortraitTemplate003
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.SpringfieldPortraitTemplate004:
        return (
          <SpringfieldPortraitTemplate004
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.SpringfieldPortraitDonorList:
        return (
          <SpringfieldPortraitDonorList key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.SpringfieldPortraitDonor:
        return (
          <SpringfieldPortraitDonor key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.SpringfieldPortraitRootPreview:
        return (
          <SpringfieldPortraitRootPreview
            key={key}
            {...previewComponentProps}
          />
        );

      case COMPONENT_NAME.NewOrleans1x2Container:
        return <NewOrleans1x2Container key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.NewOrleansDonor:
        return <NewOrleansDonor key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.NewOrleansDonorList:
        return <NewOrleansDonorList key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.NewOrleansTemplate001:
        return <NewOrleansTemplate001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.NewOrleansTemplate002:
        return <NewOrleansTemplate002 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.NewOrleansTemplate003:
        return <NewOrleansTemplate003 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Alert:
        return <Alert key={key} />;
      case COMPONENT_NAME.DonorSea:
        return <DonorSea key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneContainer:
        return <EugeneContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplate001:
        return <EugeneTemplate001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplate002:
        return <EugeneTemplate002 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplate003:
        return <EugeneTemplate003 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplate004:
        return <EugeneTemplate004 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplate005:
        return <EugeneTemplate005 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneSlide:
        return <EugeneSlide key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneSearch:
        return <EugeneSearch key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneStories:
        return <EugeneStories key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneStoriesWrapper:
        return <EugeneStoriesWrapper key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneStoriesMenu:
        return <EugeneStoriesMenu key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneStoriesMenuGallery:
        return (
          <EugeneStoriesMenuGallery key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.EugeneMenu:
        return <EugeneMenu key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneDonorLists:
        return (
          <EugeneDonorListsOverview key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.EugeneDonorListCategoryWrapper:
        return (
          <EugeneDonorListCategoryWrapper
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.EugeneRootPreview:
        return <EugeneRootPreview key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.Donor2103:
        return <Donor2103 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgMenu:
        return <CoburgMenu key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgContainer:
        return <CoburgContainer key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgStoryGroup:
        return (
          <IDCWrap {...previewComponentProps}>
            <CoburgStoryGroup key={key} {...previewComponentProps} />
          </IDCWrap>
        );
      case COMPONENT_NAME.CoburgStoriesGallery:
        return <CoburgStoriesGallery key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgStories:
        return <CoburgStoriesWrapper key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneDonorListMenu:
        return <EugeneDonorListMenu key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgTemplate001:
        return <CoburgTemplate001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgTemplate002:
        return <CoburgTemplate002 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgTemplate003:
        return <CoburgTemplate003 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgTemplate004:
        return <CoburgTemplate004 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.LoadingDefault:
        return <WebviewLoader key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgDonorListsOverview:
        return (
          <CoburgDonorListsOverview key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.CoburgDonorListGroup:
        return <CoburgDonorListGroup key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgDonorListCategoryWrapper:
        return (
          <CoburgDonorListCategoryWrapper
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.CoburgSearch:
        return <CoburgSearch key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgTemplateCardView:
        return <CoburgCardPreview key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.CoburgRootPreview:
        return <CoburgRootPreview key={key} {...previewComponentProps} />;

      // #region Glenwood

      case COMPONENT_NAME.GlenwoodMenu:
      case COMPONENT_NAME.GlenwoodContainer:
        return <GlenwoodContainer
          key={key}
          {...previewComponentProps}
          alertTrigger={props.alertTrigger}
          handleEndOfPlay={() => {
            if (props.setFolderTypeAlertTrigger) {
              props.setFolderTypeAlertTrigger(null);
            }
          }}
        />;
      case COMPONENT_NAME.GlenwoodStoryGroup:
        return (
          <IDCWrap {...previewComponentProps}>
            <GlenwoodStoryGroup key={key} {...previewComponentProps} />
          </IDCWrap>
        );
      case COMPONENT_NAME.GlenwoodStoriesGallery:
        return <GlenwoodStoriesGallery key={key} {...previewComponentProps} />;
      // case COMPONENT_NAME.GlenwoodStories:
      //   return <GlenwoodStoriesWrapper key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodSlide:
        return <GlenwoodSlide key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodBackground:
        return <GlenwoodBackground key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneDonorListMenu:
        return <EugeneDonorListMenu key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplate001:
        return <GlenwoodTemplate001 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplate002:
        return <GlenwoodTemplate002 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplate003:
        return <GlenwoodTemplate003 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplate004:
        return <GlenwoodTemplate004 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplate005:
        return <GlenwoodTemplate005 key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodDonorListsOverview:
        return (
          <GlenwoodDonorListsOverview key={key} {...previewComponentProps} />
        );
      case COMPONENT_NAME.GlenwoodDonorListGroup:
        return <GlenwoodDonorListGroup key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodDonorListCategoryWrapper:
        return (
          <GlenwoodDonorListCategoryWrapper
            key={key}
            {...previewComponentProps}
          />
        );
      case COMPONENT_NAME.GlenwoodSearch:
        return <GlenwoodSearch key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodTemplateCardView:
        return <GlenwoodTemplateCardPreview key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodRootPreview:
        return <GlenwoodRootPreview key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.GlenwoodRootPreview:
        return <GlenwoodRootPreview key={key} {...previewComponentProps} />;
      case COMPONENT_NAME.EugeneTemplateCardView:
        return <EugeneCardPreview key={key} {...previewComponentProps} />;
      default:
        console.log("Component type not found: " + componentName);
        return <div></div>;
      case COMPONENT_NAME.None:
        return <None />;
    }
  };

  return (
    <React.Suspense fallback={<></>}>
      {
        getAppliedContentPreviewComponent(
          props.componentName,
          props.previewComponentProps
        )
      }
    </React.Suspense>
  )
}

export default PreviewHandler
