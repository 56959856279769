import { Schema } from 'yup'
import { HTTP_METHODS } from './methods'
import request from './ServerRequest';

export default async (route: string, method: HTTP_METHODS, payload?: any, schema?: Schema, options?: any) => {
  if (schema) {
    try {
      await schema.validate(payload)
    } catch (e) {
      console.error(e)
      return e;
    }
  }

  try {
    switch (method) {
      case HTTP_METHODS.POST:
        return await request.post(route, payload, options);
      case HTTP_METHODS.GET:
        return await request.get(route, payload);
      case HTTP_METHODS.PUT:
        return await request.put(route, payload);
      case HTTP_METHODS.DELETE:
        return await request.delete(route, payload);
      case HTTP_METHODS.GET_FILE:
        return await request.getFile(route, payload);
    }

  } catch (e) {
    return e
  }
}
