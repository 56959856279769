import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const jasper_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.jasper_root,
  display_name: "Jasper",
  acceptable_folder_types: [FOLDER_TYPE.jasper_slides, FOLDER_TYPE.jasper_stories],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
