import { call, put, all } from "redux-saga/effects";
import _ from "lodash";
import { normalize, schema } from "normalizr";
import {
  GET_FOLDER_STATUS_FAILURE,
  GET_NOTIFICATION_SUCCESS,
  GET_USER_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATIONS_DELETE_FAILURE,
  UPDATE_NOTIFICATIONS_DELETE_SUCCESS,
  UPDATE_NOTIFICATIONS_READ_FAILURE,
  UPDATE_NOTIFICATIONS_READ_SUCCESS,
} from "../actions/actionTypes";
import { strToBool } from "../shared-global/utils/general";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import { updateNotificationValidationSchema } from "../shared-global/validations/NotificationsValidation";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";

const notificationsSchema = new schema.Entity("notifications");
const user__notificationsSchema = new schema.Entity("user__notifications");

async function getNotificationsAPI(payload) {
  return Request("/get_notification", HTTP_METHODS.GET, { ...payload });
}

async function updateNotificationsReadAPI(payload) {
  return Request(
    "/update_notification_read",
    HTTP_METHODS.POST,
    { ...payload },
    updateNotificationValidationSchema
  );
}

async function updateNotificationsDeleteAPI(payload) {
  return Request(
    "/update_notification_delete",
    HTTP_METHODS.POST,
    { ...payload },
    updateNotificationValidationSchema
  );
}

const notificationSaga = {
  *get_notifications(action) {
    if (!strToBool(process.env.REACT_APP_NOTIFICATIONS_ENABLED)) {
      return;
    }
    try {
      const response = yield call(getNotificationsAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { notifications, user__notifications } = response.data;
        yield all([
          put({
            type: GET_NOTIFICATION_SUCCESS,
            notifications,
            notifications_normalizad: normalize(notifications, [
              notificationsSchema,
            ]),
            // notifications: normalize(notifications, [notificationsSchema]),
          }),
          put({
            type: GET_USER_NOTIFICATION_SUCCESS,
            user__notifications,
            user__notification_normalized: normalize(user__notifications, [
              user__notificationsSchema,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: GET_FOLDER_STATUS_FAILURE });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: GET_FOLDER_STATUS_FAILURE });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  *update_notifications_read(action) {
    if (!strToBool(process.env.REACT_APP_NOTIFICATIONS_ENABLED)) {
      return;
    }
    try {
      const response = yield call(updateNotificationsReadAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { user__notifications } = response.data;
        yield all([
          put({
            type: UPDATE_NOTIFICATIONS_READ_SUCCESS,
            normalized: normalize(user__notifications, [
              user__notificationsSchema,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: UPDATE_NOTIFICATIONS_READ_FAILURE });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: UPDATE_NOTIFICATIONS_READ_FAILURE });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  *update_notifications_delete(action) {
    if (!strToBool(process.env.REACT_APP_NOTIFICATIONS_ENABLED)) {
      return;
    }
    try {
      const response = yield call(updateNotificationsDeleteAPI, action.payload);
      if (isResponseSuccess(response)) {
        const { user__notifications } = response.data;
        yield all([
          put({
            type: UPDATE_NOTIFICATIONS_DELETE_SUCCESS,
            normalized: normalize(user__notifications, [
              user__notificationsSchema,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: UPDATE_NOTIFICATIONS_DELETE_FAILURE });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: UPDATE_NOTIFICATIONS_DELETE_FAILURE });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },
};

export default notificationSaga;
