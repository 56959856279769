import React from "react";
import { connect } from "react-redux";
import { Input, Card, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LT_GRAY_BLUE, MD_BLUE, LT_GRAY, XLT_GRAY } from "./LayoutConstants";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import ManageSearchModal from "../tree/ManageSearchModal";
import ResourceSearchModal from "../tree/ResourceSearchModal";
import { searchMain } from "../../actions/searchActions";
import { toggleFolderExpanded } from "../../actions/folderActions";
import { toggleResourceFolderExpanded } from "../../actions/resourceActions";
import TreeItemIcon from "../tree/TreeItemIcon";
import { updatePersistentSettings } from "../../actions/userActions";
import { TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE } from "../constants";
import _ from "lodash";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { IState } from "../../interfaces/main-state.interface";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { SEARCH_CATEGORY } from "./layout-enums";
import { stripQuotes } from "../../shared-global/utils/general";
const Search = Input.Search;

const iconConfig = {
  folder: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "folder"] },
  },
  donor_list: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fac", "donor-list"] },
    // icon_type: 'custom-svg',
    // options: {
    //   file_name: 'donor-bios.svg'
    // }
  },
  donor: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "user"] },
  },
  story: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "book"] },
  },
  timeline: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "calendar-alt"] },
  },
  slideshow: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "images"] },
  },
  media: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["fas", "images"] },
  },
  search: {
    icon_type: "fontawesome",
    options: { class_name: "fa-lg", icon: ["far", "search"] },
  },
};

interface ISearchMainProps {
  clientOptions: any;
  expandedFolders: any;
  searchString: string;
  searchMain: any;
  setClientOptions: any;
  toggleFolderExpanded: any;
  resourcetree_resource__folders: any;
  toggleResourceFolderExpanded: any;
  updatePersistentSettings: any;
  folder_types: any;
  folderResults: any;
  resourceResults: any;
  folders: { [key: string]: IFolder };
  resources: any;
}

interface ISearchMainState {
  searchValue: any;
  appliedContentSearchModalVisible: boolean;
  searching: boolean;
  folder_categories: {
    folder: boolean;
    donor_list: boolean;
    donor: boolean;
    story: boolean;
    timeline: boolean;
    resource: boolean;
  };
  anyFilterSelected: boolean;
  openPanel: number;
  resourceSearchModalVisible: boolean;
}

class SearchMain extends React.Component<ISearchMainProps, ISearchMainState> {
  private autoSearchTimeout: any = null;
  private searchIndicatorTimeout: any = null;
  private blurTimeout: any = null;
  private _keyCounter: any = 0;
  private _searchAreaRef: any = null;
  private _searchBarRef: any = null;
  private _searchBarWidth = 500;

  constructor(props: any) {
    super(props);
    this.state = {
      searchValue: "",
      appliedContentSearchModalVisible: false,
      searching: false,
      folder_categories: {
        folder: false,
        donor_list: false,
        donor: false,
        story: false,
        timeline: false,
        resource: false,
      },
      anyFilterSelected: false,
      openPanel: 0,
      resourceSearchModalVisible: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.clientOptions.search_prior_expanded_folders?.length !== prevProps.clientOptions.search_prior_expanded_folders?.length) {
      if (this.state.searching === true) {
        this.setState({ searching: false });
      }
    }
    if (this.props.searchString !== prevProps.searchString) {
      //this.onSearchChange({ target: { value: this.props.searchString } });
      //this.setState({ searchValue: this.props.searchString });
    }
    if (
      this.props.clientOptions.search_clean !==
      prevProps.clientOptions.search_clean
    ) {
      if (this.props.clientOptions.search_clean) {
        this.cleanSearchFromOutside();
      }
    }
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClickAway, false);
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("resize", this.handleWindowResize);
    this.handleResponsiveSearchBar();
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickAway, false);
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("resize", this.handleWindowResize);
  };

  cleanSearchFromOutside = () => {
    this.cleanSearch();
    this.props.setClientOptions({
      search_clean: false,
    });
  };

  cleanSearch = () => {
    this.setState({
      searchValue: "",
    });
    this.setPanel(0, "click close");
    this.onSearchChange({ target: { value: "" } });

    this.props.setClientOptions({
      search_action: "collapse",
      search_old_text: this.props.clientOptions.search_string_main,
    });
  };

  handleResponsiveSearchBar = () => {
    if (window.innerWidth < 500) {
      this._searchBarWidth = window.innerWidth;
    } else {
      this._searchBarWidth = 500;
    }
  };

  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      // panel already closed clear search
      if (this.state.openPanel === 0) {
        this.onSearchChange({ target: { value: "" } });
      }
      this.setPanel(0, "hit escape");
    }
  };

  handleWindowResize = () => {
    this.handleResponsiveSearchBar();
    this.setState({});
  };

  handleClickAway = (e) => {
    // only check if panel is open
    if (this.state.openPanel === 1) {
      if (this._searchAreaRef.contains(e.target)) {
        //target clicked
      } else if (this._searchBarRef.contains(e.target)) {
        //search bar clicked
      } else {
        this.setPanel(0, "click away");
      }
    }
  };

  onRecentSearch = (value) => {
    this.onSearchChange({ target: { value } });
  };

  onSearchChange = ({ target: { value } }, forceValue?: number) => {
    this.setState({ searchValue: value });
    if (forceValue !== undefined) {
      if (this.state.openPanel !== forceValue) {
        this.setPanel(forceValue, "onSearchChange forceOpen");
      }
    }
    clearTimeout(this.autoSearchTimeout);
    this.autoSearchTimeout = setTimeout(() => {
      this.setState({
        searching: true,
        // openPanel: 1,
      });
      this.searchIndicatorTimeout = setTimeout(() => {
        this.doSearch(this.state.folder_categories);
      }, 50);
    }, 500);
  };

  doSearch = (folder_categories) => {
    const filter_array = [];
    Object.keys(folder_categories).forEach((key) => {
      if (folder_categories[key] === true) {
        filter_array.push(key);
      }
    });
    this.props.searchMain(this.state.searchValue, filter_array);
    if (this.state.searchValue !== "") {
      this.recordSearchTerm();
      this.props.setClientOptions({
        search_string_main: this.state.searchValue,
        search_string_highlight: stripQuotes(this.state.searchValue),
        search_action: "none",
        search_old_text: null,
        search_prior_expanded_folders: this.props.expandedFolders,
      });
    } else {
      this.props.setClientOptions({
        search_string_main: this.state.searchValue,
        search_string_highlight: stripQuotes(this.state.searchValue),
      });
    }
  };

  handleClickResult = (result, result_tab) => {
    //this.onSearchChange({target: {value: result.name}})
    let treemode, display_name;
    switch (result_tab) {
      case TREE_MODE_APPLIED_CONTENT:
        this.props.toggleFolderExpanded("folder-" + result.id, true, true, this.props.clientOptions.treemode);
        treemode = TREE_MODE_APPLIED_CONTENT;
        display_name = result.name;
        break;
      case TREE_MODE_RESOURCE:
        // find the ?
        const match = this.props.resourcetree_resource__folders.find(
          (rr_f) => rr_f.resource === result.id
        );
        //find results who has resource = to id and toggle it expanded
        if (match) {
          this.props.toggleResourceFolderExpanded(
            "resource-" + match.folder,
            true,
            true
          );
        }

        treemode = TREE_MODE_RESOURCE;
        display_name = result.modifiedName;
        break;
    }
    this.props.setClientOptions({
      search_string_main: display_name,
      search_string_highlight: stripQuotes(this.state.searchValue),
      treemode: treemode,
    });

    // clearTimeout(this.blurTimeout)
    //       this.blurTimeout = setTimeout(() => {
    //         this.props.toggleFolderExpanded('folder-'+result.id, true, true, this.props.clientOptions.treemode)
    //       }, 250)

    this.setPanel(0, "handleClickResult");
    // this.setState({
    //   searchValue: result.name,
    // });
  };

  recordSearchTerm = () => {
    let search_history =
      _.get(this.props, "persistent_settings.data.search_history", null) || [];
    const existingIndex = search_history.indexOf(this.state.searchValue);

    if (existingIndex !== -1) {
      //already searched swap to position 1 and prevent duplicates
      search_history.splice(existingIndex, 1);
      search_history.unshift(this.state.searchValue);
    } else {
      const history_length = search_history.unshift(this.state.searchValue);
      if (history_length > 10) {
        search_history = search_history.slice(0, 10); // store last 10 searches
      }
    }
    this.props.updatePersistentSettings({ search_history: search_history });
  };

  hardSearch = (value) => {
    this.props.setClientOptions({
      search_string_main: value,
      search_string_highlight: stripQuotes(value),
    });
    this.setState({ searchValue: value });
  };

  showSearchSettings = () => {
    if (this.props.clientOptions.treemode === TREE_MODE_RESOURCE) {
      this.setState({
        resourceSearchModalVisible: true,
      });
    } else {
      this.setState({
        appliedContentSearchModalVisible: true,
      });
    }
  };

  onChangeResourceSettings = (filters) => {
    filters = filters.map((x) => {
      switch (x) {
        case "Rich Text":
          return "rich_text";
        case "Plain Text":
          return "plain_text";
        case "Audio":
          return "audio";
        case "Videos":
          return "video";
        case "Images":
          return "image";
        default:
          return x;
      }
    });
    this.props.setClientOptions({ resource_tree_filters: filters });
    // console.log("false");
    this.setState({
      resourceSearchModalVisible: false,
    });
  };

  onChangeAppiedContentSettings = (settings) => {
    const options = this.props.clientOptions.manage_tree_options;
    options.show_match_siblings = false;
    settings.map((x) => {
      switch (x) {
        case "Show Match Siblings":
        default:
          options.show_match_siblings = true;
          return "show_match_siblings";
      }
    });
    this.props.setClientOptions({ manage_tree_options: options });
    this.setState({
      appliedContentSearchModalVisible: false,
    });
  };

  toggleFilterSelected = (key) => {
    const newFolderCategories = {
      ...this.state.folder_categories,
      [key]: !this.state.folder_categories[key],
    };

    let anyFilterSelected = false;
    Object.keys(newFolderCategories).forEach((key) => {
      if (newFolderCategories[key] === true) {
        anyFilterSelected = true;
      }
    });

    this.setState({
      folder_categories: newFolderCategories,
      anyFilterSelected,
    });

    this.doSearch(newFolderCategories);
  };

  renderSearchResultIcon = (type, searchResult) => {
    let folder_category;
    if (type === "media") {
      folder_category = "media";
    } else {
      folder_category =
        this.props.folder_types[searchResult.folder_type].folder_category ||
        "folder";
    }
    const config = iconConfig[folder_category] || iconConfig.folder;
    return (
      <TreeItemIcon config={config} isSelected={true} context={"search-main"} />
    );
  };

  renderFilterIcon = (
    key: string,
    name: string,
    config: any,
    selected: boolean,
    containerWidth: number,
    offset: number
  ) => {
    const icon_wrap_style = {
      display: "inline-block",
      width: containerWidth,
      height: 32,
      // backgroundColor: "#" + Math.floor(Math.random()*16777215).toString(16)
    };

    return (
      <span
        style={icon_wrap_style}
        onClick={() => this.toggleFilterSelected(key)}
      >
        <span
          style={{
            cursor: "pointer",
            position: "absolute",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: 0,
              left: offset,
              color: selected ? MD_BLUE : XLT_GRAY,
            }}
          >
            <TreeItemIcon
              config={config}
              isSelected={selected}
              context={"search-main"}
            />
          </span>
          <span
            style={{
              position: "absolute",
              top: 28,
              left: 0,
              color: selected ? LT_GRAY : XLT_GRAY,
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </span>
        </span>
      </span>
    );
  };

  renderFolderResult = (result) => {
    return (
      <div
        style={{ cursor: "pointer", width: this._searchBarWidth - 16 }}
        onClick={() =>
          this.handleClickResult(result, TREE_MODE_APPLIED_CONTENT)
        }
      >
        {this.renderSearchResultIcon("folder", result)}
        <span
          style={{
            width: this._searchBarWidth - 76,
            position: "absolute",
            left: 56,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {result.name}
        </span>
      </div>
    );
  };

  renderResourceResult = (result) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.handleClickResult(result, TREE_MODE_RESOURCE)}
      >
        {this.renderSearchResultIcon("media", result)}
        <span
          style={{
            width: this._searchBarWidth - 76,
            position: "absolute",
            left: 56,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {result.modifiedName}
        </span>
      </div>
    );
  };

  setPanel = (val, sender) => {
    //console.log("setting panel to: " + val + " from: " + sender);
    this.setState({
      openPanel: val,
    });
  };

  render() {
    // const icon_wrap_style = { display: "inline-block", width: 64, height: 32 };

    const search_history =
      _.get(this.props, "persistent_settings.data.search_history", null) || [];

    return (
      <>
        <ManageSearchModal
          onOk={(settings) => {
            this.onChangeAppiedContentSettings(settings);
          }}
          onCancel={() => {
            this.setState({ appliedContentSearchModalVisible: false });
          }}
          visible={this.state.appliedContentSearchModalVisible}
        />
        <ResourceSearchModal
          onOk={(filters) => {
            this.onChangeResourceSettings(filters);
          }}
          onCancel={() => {
            this.setState({ resourceSearchModalVisible: false });
          }}
          visible={this.state.resourceSearchModalVisible}
        />
        <div>
          <div style={{ width: "100vw", textAlign: "center" }}>
            <div
              ref={(ref) => (this._searchBarRef = ref)}
              style={{
                display: "inline-block",
                position: "relative",
                width: this._searchBarWidth,
              }}
            >
              <Search
                loading={this.state.searching}
                placeholder="input search text"
                onSearch={(value) => {
                  this.hardSearch(value);
                }}
                value={this.state.searchValue}
                onChange={(event) => this.onSearchChange(event, 1)}
                onFocus={() => {
                  this.setPanel(1, "search onFocus");
                }}
                onClick={() => {
                  this.setPanel(1, "search onClick");
                }}
                onBlur={() => {
                  // clearTimeout(this.blurTimeout)
                  // this.blurTimeout = setTimeout(() => {
                  //   this.setState({
                  //     openPanel: 0
                  //   })
                  // }, 250)
                }}
                style={{
                  zIndex: 15,
                  height: 36,
                  marginTop: 4,
                  marginLeft: 0,
                  width: this._searchBarWidth,
                }}
                size="large"
                suffix={
                  <span key={this._keyCounter++}>
                    {this.state.searchValue !== "" ? (
                      <span
                        style={{
                          width: 350 * 0.2,
                          textAlign: "right",
                          marginRight: 5,
                        }}
                      >
                        &nbsp;
                        <span onClick={this.cleanSearch}>
                          <FontAwesomeIcon
                            className="fa"
                            style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                            icon={["far", "times-circle"]}
                          />
                        </span>
                      </span>
                    ) : null}
                    <span
                      style={{
                        width: 350 * 0.2,
                        textAlign: "right",
                        marginRight: 8,
                      }}
                    >
                      &nbsp;
                      <span
                        onClick={() => {
                          this.showSearchSettings();
                        }}
                      >
                        <FontAwesomeIcon
                          className="fa"
                          style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                          icon={["far", "cog"]}
                        />
                      </span>
                    </span>
                  </span>
                }
              />
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              display: "inline-block",
              width: "100vw",
              top: 40,
              left: window.innerWidth / 2 - 250,
            }}
          >
            <div
              style={{
                display: this.state.openPanel === 1 ? "inline-block" : "none",
              }}
            >
              <div ref={(ref) => (this._searchAreaRef = ref)}>
                <Card style={{ width: this._searchBarWidth }}>
                  <Divider style={{ marginTop: 0 }} orientation="left">
                    Recent Searches
                  </Divider>
                  {search_history.map((recent_search, index) => {
                    return index < 4 ? (
                      <div
                        onClick={() => this.onRecentSearch(recent_search)}
                        style={{ height: 36, cursor: "pointer" }}
                        key={index}
                      >
                        <span style={{ color: XLT_GRAY }}>
                          <TreeItemIcon
                            config={iconConfig.search}
                            isSelected={true}
                            context={"search-main"}
                          />
                        </span>
                        <span
                          style={{
                            width: this._searchBarWidth - 76,
                            position: "absolute",
                            left: 56,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {recent_search}
                        </span>
                      </div>
                    ) : null;
                  })}
                  {this.props.folderResults.length > 0 ? (
                    <div>
                      <Divider style={{ marginTop: 0 }} orientation="left">
                        Displays
                      </Divider>
                      {this.props.folderResults.map(
                        (result: any, index: number) => {
                          let matched = this.props.folders[Number(result)];
                          return matched && index < 4 ? (
                            <div style={{ height: 36 }} key={index}>
                              {this.renderFolderResult(matched)}
                            </div>
                          ) : null;
                        }
                      )}
                    </div>
                  ) : null}
                  {this.props.resourceResults.length > 0 &&
                  (!this.state.anyFilterSelected ||
                    this.state.folder_categories["resource"] === true) ? (
                    <div>
                      <Divider style={{ marginTop: 0 }} orientation="left">
                        Content Library
                      </Divider>
                      {this.props.resourceResults.map((result, index) => {
                        let matched = this.props.resources[Number(result)];
                        return matched && index < 4 ? (
                          <div style={{ height: 36 }} key={index}>
                            {this.renderResourceResult(matched)}
                          </div>
                        ) : null;
                      })}
                    </div>
                  ) : null}

                  <Divider
                    style={{ marginTop: 0, marginBottom: 0 }}
                    orientation="left"
                  >
                    Filter By
                  </Divider>
                  <br />
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.FOLDER,
                    "Folder",
                    iconConfig.folder,
                    this.state.folder_categories.folder,
                    70,
                    9
                  )}
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.DONOR_LIST,
                    "Donor List",
                    iconConfig.donor_list,
                    this.state.folder_categories.donor_list,
                    94,
                    19
                  )}
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.DONOR,
                    "Donor",
                    iconConfig.donor,
                    this.state.folder_categories.donor,
                    69,
                    10
                  )}
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.STORY,
                    "Story",
                    iconConfig.story,
                    this.state.folder_categories.story,
                    62,
                    8
                  )}
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.TIMELINE,
                    "Timeline",
                    iconConfig.timeline,
                    this.state.folder_categories.timeline,
                    82,
                    19
                  )}
                  {this.renderFilterIcon(
                    SEARCH_CATEGORY.RESOURCE,
                    "Media",
                    iconConfig.media,
                    this.state.folder_categories.resource,
                    73,
                    8
                  )}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    user: state.data.user,
    permissions: state.data.user_permissions_grouped,
    // TODO: How to set what is current company???
    companyId: state.data.user.company,
    expandedFolders: state.data.applied_content_tree_expanded,
    clientOptions: state.client_options,
    searchString: state.client_options.search_string_main,
    folderResults: state.data.folder_results || [],
    resourceResults: state.data.resource_results || [],
    folders: state.data.folders,
    folder_types: state.data.folder_types,
    resources: state.data.resources,
    persistent_settings: state.data.persistent_settings,
    resourcetree_resource__folders: arrayFromKeyedObject(
      state.data.resourcetree_resource__folders
    ), //TODO consider caching on resource?
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setClientOptions: (client_options) => {
    dispatch(setClientOptions(client_options));
  },
  searchMain: (search, folder_categories) => {
    dispatch(
      searchMain(
        { search: search, folder_categories: folder_categories },
        null,
        null
      )
    );
  },
  toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
    dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode, null, null));
  },
  toggleResourceFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
    dispatch(toggleResourceFolderExpanded(treeId, doOpenClose, doSelect, treemode));
  },
  updatePersistentSettings: (data) => {
    dispatch(updatePersistentSettings(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMain);
