import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name, wysiwyg } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { textstyle_default } from "../boston/boston_root";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

const fontOptions = [
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.MYRIAD_PRO,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.SERIF,
  FontsFamilies.WHITNEY,
  FontsFamilies.MERRIWEATHER
];

export const mckenzie_mobile: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.mckenzie_mobile,
  display_name: "McKenzie Mobile",
  acceptable_folder_types: [],
  fields: {
    name: name,
    header_text: {
      ...wysiwyg,
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "30px"
        },
        ops: []
      },
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fonts: fontOptions,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 30
        }
      },
      title: "Header"
    },
    body_text: {
      ...wysiwyg,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "20px"
        },
        ops: []
      },
      settings: {
        font_size_unit: "px",
        ...wysiwyg.settings,
        fonts: fontOptions,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 30
        }
      },
      title: "Body Text"
    },
    button_text: {
      type: "text",
      title: "Button Text",
      default: null,
      settings: {
        recommended_length: 38
      }
    },
    button_link: {
      type: "text",
      title: "Button Link",
      default: null
    },
    button_text_style: {
      type: "textstyle",
      title: "Button Text Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: false,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        bold: {
          enabled: true
        },
        color: {
          enabled: true
        },
        italic: {
          enabled: true
        },
        underline: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    button_background_color: {
      type: "color",
      title: "Button Background Color",
      default: "",
      sys_admin_only: true
    },
    logo: {
      title: "Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    },
    media: {
      title: "Media",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    }
  },
  preview: {
    aspect_ratio: 0,
    component_name: COMPONENT_NAME.MobileTemplate,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: false,
    is_mobile: true
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  }
};
