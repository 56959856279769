import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";

export const monterrey_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.monterrey_display,
  display_name: "Display",
  prevent_add_children: true,
  acceptable_folder_types: [FOLDER_TYPE.monterrey_presentation_5x1, FOLDER_TYPE.monterrey_presentation_1x1],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  tabs: {
    main: {
      title: "Main"
    },
    control_panel: {
      title: "Control Panel"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "main"
    },
    control_panel: {
      title: "Control Panel",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "control_panel"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true,
      field_group: "general"
    },
    main_presentation: {
      type: "select",
      title: "Main Presentation",
      validation: { rules: [{ name: "isRequired" }] },
      settings: { options: "children" }, // Get the option values in the dropdown from children of this folder
      field_group: "general"
    },
    media: {
      title: "Media",
      field_group: "control_panel",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.3,
      recommended_size: {
        width: 920,
        height: 400
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.3
      }
    },
    background_image: {
      title: "Background Image",
      field_group: "control_panel",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.6,
      recommended_size: {
        width: 1280,
        height: 800
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_TOP_CENTER,
        aspect: 1.6
      }
    },
    background_overlay: {
      type: "color",
      title: "Background Overlay",
      field_group: "control_panel",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    aspect_ratio: 2.8125,
    component_name: COMPONENT_NAME.Monterrey5x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [
      { name: "unique", orientation: "portrait", horizontal: 1, vertical: 5 },
      { name: "1x1", orientation: "landscape", horizontal: 1, vertical: 1 }
    ]
  },
  tab_previews: [
    {
      tab_key: "main",
      aspect_ratio: 2.8125,
      component_name: COMPONENT_NAME.Monterrey5x1Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 5 }]
    },
    {
      tab_key: "control_panel",
      aspect_ratio: 1.6,
      component_name: COMPONENT_NAME.MonterreyDynamicContainer,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: false,
      screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  },
  hide_nested_children: true,
  check_warnings_on_publish: true
};
