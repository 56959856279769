import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { getFieldValue } from "../../../utils/generalUtils";
import Slide1911 from "../../standard/Slide1911";
import RichTextRender from "../../standard/RichTextRender";
import QRLogoWidget from "../../standard/QRLogoWidget";

const EverettFullScreen = (props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(
    get(props.data.persistent_settings, "show_placeholder", false)
  );
  const [baseFolder, setBaseFolder] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [baseFolderFields, setBaseFolderFields] = useState({});

  const isCaption = props.folderType.name === FOLDER_TYPE.everett_full_screen_image_with_caption;

  useEffect(() => {
    const myBaseFolder = getBaseFolder(props.data);
    setBaseFolder(myBaseFolder);
    setBaseFolderFields(myBaseFolder.fields);
    const myBaseFolderType = getBaseFolderType(props.data, get(myBaseFolder, "folder_type", {}));
    setBaseFolderType(myBaseFolderType);
  }, [props.data]);

  const header = getFieldValue(baseFolder, "image_with_caption_header", baseFolderType);
  const body = getFieldValue(baseFolder, "image_with_caption_body", baseFolderType);

  const mirrorContent = get(baseFolder, "fields.mirror_content", false);

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
        display: "grid"
      }}
    >
      <div
        style={{
          position: "relative",
          width: props.containerWidth,
          height: props.containerHeight,
          display: "flex",
          flexDirection: !mirrorContent ? "row" : "row-reverse",
          alignItems: "stretch"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            data={{
              ...props.data,
              muted: false,
              slotNames: isCaption ? ["image_with_caption_image"] : ["fullscreen_image"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "cover",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight,
            backgroundColor: get(
              baseFolder,
              isCaption ? "fields.image_with_caption_image_overlay" : "fields.fullscreen_image_overlay"
            )
          }}
        />
        <div
          className="section-spacer"
          style={{
            width: "50%",
            height: "100%"
          }}
        />
        {isCaption && (
          <div
            className="section-caption"
            style={{
              position: "relative",
              display: "flex",
              width: "50%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "stretch"
            }}
          >
            <div
              className="caption-wrapper"
              style={{
                position: "relative",
                width: "100%"
              }}
            >
              <div
                className="caption-text-wrapper"
                style={{
                  position: "relative",
                  display: "flex",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  padding: "6em",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  backgroundColor: get(baseFolder, "fields.image_with_caption_background")
                }}
              >
                <div
                  className={props.classes.componentContainer}
                  style={{
                    width: "100%",
                    marginBottom: "0.75em",
                    lineHeight: "1.25",
                    letterSpacing: "0.05em",
                    overflow: "hidden"
                  }}
                >
                  <RichTextRender htmlString={header} />
                </div>
                <div
                  className={props.classes.componentContainer}
                  style={{
                    width: "100%",
                    lineHeight: "1.5",
                    overflow: "hidden"
                  }}
                >
                  <RichTextRender htmlString={body} />
                </div>
              </div>
            </div>
          </div>
        )}
        <QRLogoWidget
          {...props}
          {...baseFolderFields}
          containerWidth={0.111111111 * props.refHeight}
        />
      </div>
    </div>
  );
};

export default EverettFullScreen;
