import isElectron from 'is-electron';
import { getJsonFromUrl } from './commonDataUtils';
const mimeDb = require("mime-db");
import get from 'lodash/get';

export const imageExtensions = ['jpg', 'jpeg', 'png', 'svg', 'gif'];
export const imageMimeTypes = imageExtensions.map(e => `.${e}`).join(",");

export function getFileType(str) {
  str = str.split('.');
  let lowercaseExt = str[str.length - 1].toLowerCase();
  let audioExtensions = ['mp3', 'wav', 'ogg', 'aac'];
  let videoExtensions = ['webm', 'mp4', 'mov', 'mpeg', 'mpg', 'avi', 'flv'];

  if (audioExtensions.indexOf(lowercaseExt) > -1) {
    return "audio";
  }

  if (imageExtensions.indexOf(lowercaseExt) > -1) {
    return "image";
  }

  if (videoExtensions.indexOf(lowercaseExt) > -1) {
    return "video";
  }

  return "unsupported";
}

export const getFileIcon = (file) => {
  let str = file.split('.');
  let lowercaseExt = str[str.length - 1].toLowerCase();
  let supportedFiles = [
    'ae', 'ai', 'csv', 'doc', 'docx', 'exe', 'mkv',
    'mobi', 'mov', 'mp3', 'mpg', 'otf', 'pdf',
    'ppt', 'pptx', 'psd', 'rar', 'tar', 'txt', 'wav',
    'wma', 'xls', 'xlsx', 'zip'
  ];

  if (supportedFiles.indexOf(lowercaseExt) > -1) {
    return `/images/icons/${lowercaseExt}.png`;
  }

  return `/images/icons/unknown.png`;
}

export function getFileAcceptableMimeTypes(type) {
  let acceptableTypes = [];
  if (type.includes("/")) {
    if (mimeDb[type]) {
      return [type];
    }
    return acceptableTypes;
  } else {
    for (let mimeType in mimeDb) {
      const splittedMime = mimeType.split("/");
      if (splittedMime[0].toLowerCase() === type.toLowerCase()) {
        acceptableTypes.push(mimeType);
      }
    }
  }
  return acceptableTypes;
}

export function getFileTypeFromResources(resources, resource__folders, slot_names, base_folder, index = 0) {
  slot_names = slot_names.map(name => name.toLowerCase());
  const resourceFolder = resource__folders.find(rf => slot_names.includes(rf.slotName.toLowerCase()) && rf.folder === base_folder && rf.rank === index);
  if (!resourceFolder) {
    // console.log('Not found resource folder');
    return null;
  }
  const resource = resources.find(r => r.id === resourceFolder.resource);
  if (!resource) {
    // console.log("Not found resource");
    return null;
  }
  return getFileType(resource.name);
}

export function getURLFromCache(uuid, params, aws) {
  if (!aws) {
    return null;
  }
  let filePath = '';
  let s3cache = JSON.parse(localStorage.getItem("s3cache"));

  if (!s3cache) {
    s3cache = []
  }
  else {
    if (s3cache.length > 300) {
      s3cache = []
    }
  }
  const existingItem = s3cache.find((x) =>
    x.uuid === uuid
  )
  if (existingItem) {
    const now_epoch = ((new Date().getTime()) / 1000);
    if (existingItem.expires - now_epoch >= 5) {
      // use the cached image
      return existingItem.url;
    }
    else { // remove existing item from the array its expired
      s3cache = s3cache.filter((obj) => {
        return obj.uuid !== uuid;
      });
    }
  }

  try {
    const s3 = new aws.S3({ apiVersion: '2006-03-01', region: 'us-west-2' });
    filePath = s3.getSignedUrl('getObject', { ...params, Expires: 21600 });
  }
  catch (e) {
    console.log('s3 fileUtils error');
    console.log(e);
  }
  const urlParams = getJsonFromUrl(filePath) as any;
  s3cache.push(
    {
      uuid: uuid,
      url: filePath,
      expires: Number(urlParams.Expires)
    }
  )
  localStorage.setItem("s3cache", JSON.stringify(s3cache));
  return filePath;
}

export function removesURLFromCache(uuid) {
  let s3cache = JSON.parse(localStorage.getItem("s3cache"));
  if (s3cache) {
    const existingItemIndex = s3cache.findIndex(x => x.uuid === uuid);
    if (existingItemIndex >= 0) {
      s3cache.splice(existingItemIndex, 1);
      localStorage.setItem("s3cache", JSON.stringify(s3cache));
    }
  }
}

export function getContentLibraryResourceUrl(resourceFolder, aws, bucket, preset) {
  let key = `${resourceFolder.resource}.${resourceFolder.extension}`;
  if (preset) {
    // getting from resource_folder to get the correct preset extension
    key = `${resourceFolder.resource}_${preset}.${resourceFolder.extension}`;
  }
  const params = { Bucket: `${bucket}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resourceFolder.company}/resource`, Key: key };
  if (preset) {
    return getURLFromCache(`resource-${preset}-${resourceFolder.id}`, params, aws);
  }
  return getURLFromCache(`resource-${resourceFolder.id}`, params, aws);
}

export function getVideoThumbnailUrl(resource, aws, bucket) {
  const key = `${resource.id}_00001.png`;
  const params = { Bucket: `${bucket}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`, Key: key };
  return getURLFromCache(`resource-thumbnail-${resource.id}`, params, aws);
}


export function getUrlResource(resourceFolder, asset_mode, aws, bucket, resources, preset, webview_signedurls) {
  if (!resourceFolder) {
    return '';
  }

  const resource = resources.find(r => r.id === resourceFolder.resource);

  if (!resource) {
    return '';
  }
  let key, params;

  let relativePath = ''

  let fileType = getFileType('.' + resource.extension);

  if (asset_mode === 'webview') {
    switch (fileType) {
      case 'video':
        key = 'resource-' + resource.id
        break;
      case 'image':
        key = 'resource-folder-' + resourceFolder.id
        break;
      case 'audio':
      case 'unsupported':
      default:
        break;
    }


    let foundKey = null;

    if (fileType === "image") {
      if (webview_signedurls[key + '-preview']) {
        foundKey = webview_signedurls[key + '-preview']
      }
      else if (webview_signedurls[key]) {
        foundKey = webview_signedurls[key]
      }
      else if (webview_signedurls['resource-' + resource.id]) { // WHY DID THIS HIT
        foundKey = webview_signedurls['resource-' + resource.id]
      }
    }
    else if (fileType === "video") {
      if (webview_signedurls[key + '-1080']) {
        foundKey = webview_signedurls[key + '-1080']
      }
      else if (webview_signedurls[key]) {
        foundKey = webview_signedurls[key]
      }
    }

    if (foundKey) {
      return foundKey.url
    }
  }
  else if (asset_mode === 'local') {
    switch (getFileType('.' + resource.extension)) {
      case 'video':
        let file = `/company/${resource.company}/resources/${resource.id}`;
        if (preset) {
          file += '_' + preset;
        }
        file += '.' + resourceFolder.extension;
        relativePath = file;
        break;
      case 'image':
        relativePath = `/company/${resource.company}/resource__folders/${resourceFolder.id}.${resourceFolder.extension}`;
        break;
      case 'audio':
      case 'unsupported':
      default:
        relativePath = '';
        break;
    }

    // electron check
    // @ts-ignore
    if (isElectron()) {
      const fileServer = process.env.REACT_APP_FILE_SERVER_URL ?? 'http://localhost';
      const fileServerPort = process.env.REACT_APP_FILE_SERVER_PORT ?? '3002';
      return fileServer + ':' + fileServerPort + "/data/" + relativePath;
    } else {
      return '/data/' + relativePath;
    }
  } else { //aws mode
    switch (getFileType('.' + resource.extension)) {
      case 'video':
        key = `${resource.id}_preview.webm`;
        params = { Bucket: `${bucket}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`, Key: key };
        return getURLFromCache("resource-preview-" + resource.id, params, aws);
      case 'image':
        key = `${resourceFolder.id}_preview.${resourceFolder.extension}`;
        if (preset) {
          key = `${resourceFolder.id}_${preset}.${resourceFolder.extension}`;
        }
        params = { Bucket: `${bucket}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource__folders`, Key: key };
        return getURLFromCache("resource-folder-preview-" + resourceFolder.id, params, aws);
      case 'audio':
      case 'unsupported':
      default:
        return '';
    }
  }
}

export function getFileDynamic(aws, bucket, resources, resource__folders, slot_names, base_folder, preset, webview_signedurls = null) {
  // let config;
  let asset_mode = get(process.env, 'REACT_APP_ASSET_MODE', 'aws');
  if (asset_mode === 'aws' && webview_signedurls) {
    asset_mode = 'webview'
  }

  slot_names = slot_names.map(name => name.toLowerCase());
  const resourceFolder = resource__folders.find(rf => slot_names.includes(rf.slotName.toLowerCase()) && rf.folder === base_folder);

  return getUrlResource(resourceFolder, asset_mode, aws, bucket, resources, preset, webview_signedurls);
}

export function getResourceFoldersOfFileDynamic(aws, bucket, resources, resource__folders, slot_names, base_folder) {
  slot_names = slot_names.map(name => name.toLowerCase());
  let resourceFolder = resource__folders.filter(rf => slot_names.includes(rf.slotName.toLowerCase()) && rf.folder === base_folder);

  return resourceFolder || [];

}

export function getFileDynamicByRank(rank, aws, bucket, resources, resource__folders, slot_names, base_folder, preset, webview_signedurls = null) {
  let asset_mode = 'aws';
  try {
    if (process.env && process.env.REACT_APP_ASSET_MODE) {
      if (process.env.REACT_APP_ASSET_MODE) {
        asset_mode = process.env.REACT_APP_ASSET_MODE;
      }
    }
    if (asset_mode === 'aws') {
      if (webview_signedurls) {
        asset_mode = 'webview'
      }
    }
  } catch (e) {
  }

  slot_names = slot_names.map(name => name.toLowerCase());


  // PREVIOUSE CODE returns the first match when provided multiple slot names.
  //resourceFolder = resource__folders.find(rf => slot_names.includes(rf.slotName.toLowerCase()) && rf.folder === base_folder && rf.rank === rank);

  // New code will cycle in order or slot names thereby returning the first match by slot name then remaining criteria
  // all joined resource__folders
  let filteredResource__folders = resource__folders.filter(rf => rf.folder === base_folder);
  let resourceFolder;

  let matched = false
  for (let i = 0; i < slot_names.length; i++) {
    if (!matched) {
      resourceFolder = filteredResource__folders.find(rf => slot_names[i].toLowerCase() === rf.slotName.toLowerCase() && rf.folder === base_folder && rf.rank === rank);
      if (resourceFolder) {
        matched = true;
      }
    }
  }

  //TODO potential fix if folder__folder is null
  //let resourceFolder = resource__folders.find(rf => slot_names.includes(rf.slotName.toLowerCase()) && rf.folder === base_folder && (rf.rank === rank || rf.rank === null));

  return getUrlResource(resourceFolder, asset_mode, aws, bucket, resources, preset, webview_signedurls);
}

export function videoType(file_name) {
  // console.log("attempting to get videoType", file_name)
  if (!file_name) {
    // console.log("no videoType file_name provided");
    return null;
  }
  let videoExt = file_name.split('.').pop();
  switch (videoExt) {
    case 'mov':
    case 'mp4':
      return "video/mp4";
    case 'webm':
      return "video/webm";
    case "ogg":
      return "video/ogg";
    default:
      console.log("failed to get videoType", file_name)
      return null;
  }
}

export async function getCroppedImage(imageSrc, crop) {
  const image = new Image();
  image.crossOrigin = "anonymous";
  const base64Image = await new Promise((resolve, _reject) => {
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = crop.width * image.width / 100;
      canvas.height = crop.height * image.height / 100;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x / 100 * image.naturalWidth,
        crop.y / 100 * image.naturalHeight,
        crop.width / 100 * image.naturalWidth,
        crop.height / 100 * image.naturalHeight,
        0,
        0,
        crop.width / 100 * image.width,
        crop.height / 100 * image.height,
      );

      // As Base64 string
      const base64 = canvas.toDataURL('image/jpeg');
      return resolve(base64);
    }
    image.src = imageSrc.src;
  });
  return base64Image;
}

export async function getBase64Image(img) {
  // img.crossOrigin = "anonymous";
  // Create an empty canvas element
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  // Copy the image contents to the canvas
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  var dataURL = canvas.toDataURL("image/jpeg");
  return dataURL;
}

export function getFileTypeGeneric(src) {
  const type = getFileType(src);
  if (type !== 'unsupported') {
    return `/images/icons/${type}.png`;
  }

  return `/images/icons/unknown.png`;
}
