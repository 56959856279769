import { connect } from "react-redux";
import React, { Component } from 'react';
import { getAllUser } from '../../actions/userActions';
import UsersTable from "./UsersTable";
import { withRouter } from "../../hooks/withRouter";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.props.getAllUsers(this.props.params.companyId, this.onLoaded, null);
    this.state = {
      loading: false
    }
  }

  onLoaded() {
    this.setState({
      loading: true
    });
  }

  render() {
    if(this.state.loading && !this.props.users) {
      return(
      <p> Loading... </p>
      )
    } else {
      return(
        <UsersTable companyId={this.props.params.companyId} users={this.props.users}/>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.data.users
  };
};

const mapDispatchToProps = (dispatch, ownProps) =>({
  getAllUsers: (companyId, onSuccess, onFail) => {
    dispatch(getAllUser(companyId, onSuccess, onFail));
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));