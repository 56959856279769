import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Select, Tooltip} from "antd";
import get from "lodash/get";
import PropTypes from "prop-types";
import Delta from "quill-delta";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { hexToRgbA } from "../../../shared/utils/generalUtils";
import { LT_GRAY_BLUE, MD_RED, XLT_GRAY } from "../../layout/LayoutConstants";
import ColorPickerTool from "../../utils/ColorPickerTool";
import InputTooltip from "./InputTooltip";
import { convertHexToHexWithAlpha } from "../../../utils/commonUtils";
import { InputNumber } from "antd";
import AlertNotification from "../../layout/AlertNotification";
import { connect } from "react-redux";
import { setClientOptions } from "../../../reducers/clientOptionsSlice";
import FontFamilyInput from "./FontFamilyInput";
import { allFontsVariants, fonts, FontsFamilies, FontVariants } from "../../../shared-global/fonts";
import {faIndent, faOutdent, faListOl, faListUl} from "fcbpriv/@fortawesome/free-solid-svg-icons";
import {validate} from "../../../utils/validate";

const DEFAULT_EM_SIZE = "20em";
const DEFAULT_PX_SIZE = "14px";
const DEFAULT_FONTS = [fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR]];
const Option = Select.Option;
class WysiwygInput extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    defaultStyle: PropTypes.any,
    settings: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    resetToDefaultValue: PropTypes.any,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    onBlur: () => { },
  };

  fontSize = "16px";
  lineHeight = "1.05"

  constructor(props) {
    super(props);
    this._editorRef = null;
    this._pickerContainer = null;
    this._defaultValueUpdated = false;
    this._colorIconRef = null;
    this._scrollTimeout = null;
    this._parentDetailWindow = null;
    this._wysiwygContainerRef = null;
    this._numberInputRef = null;
    this._spacingNumberInputRef = null;
    this._currentPositionScroll = { top: 0, left: 0 };
    this._defaultStyle = {};
    this.timeoutId = null;
    if (
      props.defaultValue.inheritable_style &&
      Object.keys(props.defaultValue.inheritable_style).length > 0
    ) {
      this._defaultStyle = { ...props.defaultValue.inheritable_style };
    } else if (
      props.defaultStyle &&
      Object.keys(props.defaultStyle).length > 0
    ) {
      this._defaultStyle = { ...props.defaultStyle };
    }

    const parchment = ReactQuill.Quill.import('parchment')
    const lineHeightConfig = {
      scope: parchment.Scope.INLINE
    }
    const lineHeightStyle = new parchment.Attributor.Style('lineHeight', 'line-height', lineHeightConfig)

    const fontSizeStyle = ReactQuill.Quill.import("attributors/style/size");
    const fontTypeStyle = ReactQuill.Quill.import("formats/font");
    fontTypeStyle.whitelist = allFontsVariants;

    const fontSizeUnit = get(props, "settings.font_size_unit", "em");
    fontSizeStyle.whitelist = [...Array(999).keys()].map(
      (n) => `${n + 1}${fontSizeUnit}`
    );

    let BlockEmbed = ReactQuill.Quill.import('blots/block/embed');

    class RuleBlot extends BlockEmbed {
      static create(value) {
        const node = super.create(value);
        if (value) {
          node.setAttribute('style', value);
        }
        return node;
      }

      static value(domNode) {
        return domNode.getAttribute('style');
      }

      static formats(domNode) {
        domNode.style.height = '2px'
        domNode.style.borderWidth = '0'
      }

      format(name, value) {
        if (name === 'color') {
          this.domNode.style.color = value;
          this.domNode.style.backgroundColor = value;
        } else {
          this.domNode.style[name] = value;
        }
      }
    }
    RuleBlot.blotName = 'rule';
    RuleBlot.tagName = 'hr';

    ReactQuill.Quill.register(fontSizeStyle, true);
    ReactQuill.Quill.register(lineHeightStyle, true)
    ReactQuill.Quill.register(RuleBlot, true);

    this._modules = {
      toolbar: {
        container: `#toolbar-${this.props.fieldName}`,
      },
    };

    this.state = {
      value: null,
      focused: false,
      isHovered: false,
      charCount: 0,
      overCharRecommended: false,
      charCountColor: XLT_GRAY,
      colorPickerOpen: false,
      selectedColor: "#FFFFFF",
      font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
      colorIconLocation: null,
      quillKey: 0,
      fontSizeUnit,
      range: null,
      validationResults: {},
    };
  }

  //this.props.defaultStyle override with field inheritable_style if present.

  componentDidMount() {
    const self = this;
    if (this._editorRef) {
      const editor = this._editorRef.getEditor();
      const toolbar = editor.getModule('toolbar');

      toolbar.addHandler('rule', () => {
        const range = editor.getSelection(true);
        editor.insertText(range.index, '\n', ReactQuill.Quill.sources.USER);
        editor.insertEmbed(range.index + 1, 'rule', { color: this.state.selectedColor }, ReactQuill.Quill.sources.USER);
        editor.formatText(range.index + 1, range.index + 2, 'color', this.state.selectedColor)
        editor.setSelection(range.index + 2, ReactQuill.Quill.sources.SILENT);
      })

      const format = editor.getFormat();
      if (format.hasOwnProperty("size")) {
        this.fontSize = format.size.replace("px", "");
      }
      if (format.hasOwnProperty("lineHeight")) {
        this.lineHeight = format.lineHeight;
      }
      if (format.hasOwnProperty("font")) {
        this.setState({ font: format.font })
      }

      this.setCharCount(editor.getLength());
      if (
        !this.props.defaultValue.ops ||
        this.props.defaultValue.ops.length === 0
      ) {
        if (this._defaultStyle && Object.keys(this._defaultStyle).length) {
          Object.keys(this._defaultStyle).forEach((property) => {
            if (property === 'font') {
              this.setFont(this._defaultStyle[property])
            } else if (property === 'lineHeight') {
              this.setLineHeight(this._defaultStyle[property])
            } else {
              editor.format(property, this._defaultStyle[property]);
              editor.blur();
            }
          });
        } else {
          editor.format(
            "size",
            this.state.fontSizeUnit === "em" ? DEFAULT_EM_SIZE : DEFAULT_PX_SIZE
          );
          this.setFont(DEFAULT_FONTS[0]);
          this.setLineHeight(this.lineHeight);
        }
      }
      editor.on("text-change", () => {
        const contents = editor.getContents();
        const lastOp = contents.ops[contents.ops.length - 1];

        // Setting default format if there's not text in the editor.
        if (editor.getLength() <= 1) {
          this.formatEditorCurrentSelectionToDefault(editor);
        }

        const editorLength = editor.getLength();
        const editorSelection = editor.getSelection();

        if (this.state.fontSizeUnit === "em") {
          // This prevents the text from losing format when a new line is entirely removed
          // and the cursor stays on this empty line.
          if (lastOp && lastOp.insert.includes("\n")) {
            const currentFormat = editor.getFormat();
            if (!currentFormat.size) {
              this.formatEditorCurrentSelectionToDefault(editor);
            }
          }

          // This prevents the cursor from moving back to the beginning after typing first letter.
          if (editorLength === 2 && editorSelection.index === 0) {
            editor.setSelection(1, 0);
          }
        }
      });

      const defaultFontSize = get(
        this._defaultStyle,
        "size",
        this.state.fontSizeUnit === "em" ? DEFAULT_EM_SIZE : DEFAULT_PX_SIZE
      );
      const _self = this

      const removeBackgroundAttribute = (delta) => {
        const newOps = delta.ops.map((op) => {
          if (op.attributes && op.attributes.background) {
            delete op.attributes.background;
          }
          return op;
        });

        return new Delta(newOps);
      };

      editor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
        const newDelta = removeBackgroundAttribute(delta);

        if (!_self.props.client_options.apello_copy) {
          const plaintext = node.innerText;
          const newDelta = new Delta().insert(plaintext, {
            size: defaultFontSize,
            color: self._defaultStyle.color || "white",
          });
          return newDelta;
        }

        return newDelta;
      });
      editor.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
        const newDelta = removeBackgroundAttribute(delta);

        if (!_self.props.client_options.apello_copy) {
          const plaintext = node.wholeText;
          const newDelta = new Delta().insert(plaintext, {
            size: defaultFontSize,
            color: self._defaultStyle.color || "white",
          });
          return newDelta;
        }

        return newDelta;
      });

      editor.on("selection-change", (range, oldRange, source) => {
        if (editor.hasFocus()) {
          const format = editor.getFormat(range);
          try {
            if (this.state.fontSizeUnit === "em") {
              if (
                (!format.size || format.size === "") &&
                range.length === 0 &&
                range.index === 0
              ) {
                editor.format("size", defaultFontSize);
              }
            }

            if (typeof format.size === "string") {
              this.fontSize = format.size.replace("px", "");
            } else if (typeof format.size === 'object') {
              this.fontSize = format.size[format.size.length - 1].replace("px", "");

            }
            if (format.color) {
              this.setState({ selectedColor: format.color });
            }
            if (format.lineHeight) {
              this.lineHeight = format.lineHeight;
            }
            if (format.font && source === 'user') {
              this.setState({ font: format.font })
            }
          } catch (e) {
            console.log("selection-change", e);
          }
        }
      });
    }

    if (this._colorIconRef) {
      const top = this._colorIconRef.offsetTop + 30;
      const left = this._colorIconRef.offsetLeft / 2;
      this._currentPositionScroll = { top, left };
      this.setState({ colorIconLocation: { top, left } });
    }

    const parentDetailWindow = document.getElementById("detail-window");
    if (parentDetailWindow) {
      this._parentDetailWindow = parentDetailWindow;
      this._parentDetailWindow.addEventListener("scroll", (e) => {
        if (this.state.colorPickerOpen) {
          this.setState({ colorPickerOpen: false }, () =>
            this.toggleEditorEditable(true)
          );
        }
        if (this._colorIconRef) {
          const scrollTop = e.target.scrollTop;
          this._currentPositionScroll = {
            left: this._colorIconRef.offsetLeft / 2,
            top: this._colorIconRef.offsetTop - scrollTop + 30,
          };
        }
      });
    }

    this.initializedDarkMode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.initializedDarkMode();
    }
  }

  formatEditorCurrentSelectionToDefault(editor) {
    if (this._defaultStyle && Object.keys(this._defaultStyle).length) {
      Object.keys(this._defaultStyle).forEach((property) => {
        if (property === 'font') {
          this.setFont(this._defaultStyle[property])
        } else {
          editor.format(property, this._defaultStyle[property]);
        }
      });
    } else {
      editor.format(
        "size",
        this.state.fontSizeUnit === "em" ? DEFAULT_EM_SIZE : DEFAULT_PX_SIZE
      );
      this.setFont(DEFAULT_FONTS[0]);
    }
  }

  checkIfElementIsChildOrEqual = (targetElement, compareElement) => {
    let newTargetElement = targetElement;
    do {
      if (newTargetElement === compareElement) {
        return true;
      }
      newTargetElement = newTargetElement.parentNode;
    } while (newTargetElement);
    return false;
  };

  componentWillUnmount() {
    if (this._parentDetailWindow) {
      this._parentDetailWindow.removeEventListener("scroll", (e) => {
        if (this.state.colorPickerOpen) {
          this.setState({ colorPickerOpen: false }, () =>
            this.toggleEditorEditable(true)
          );
        }
        if (this._colorIconRef) {
          const scrollTop = e.target.scrollTop;
          this._currentPositionScroll = {
            left: this._colorIconRef.offsetLeft,
            top: this._colorIconRef.offsetTop - scrollTop + 30,
          };
        }
      });
    }

    clearTimeout(this.timeoutId);
  }

  toggleDarkMode = () => {
    const editor = this._editorRef.getEditor();
    const contents = editor.getContents();
    this.props.onChange(this.props.fieldName, {
      ...contents,
      dark_mode: !get(this.props, "defaultValue.dark_mode", false),
    });
  };

  getBackgroundColor = (color) => {
    const rgba = hexToRgbA(color);
    if (rgba.r + rgba.g + rgba.b >= 650) {
      return "dark";
    }
    return "light";
  };

  getColorFromAllOps = (defaultValue) => {
    let color = null
    if (!defaultValue) {
      return color
    }
    const ops = defaultValue.ops
    if (!ops || !ops.length) {
      return color
    }

    for (const op of ops) {
      color = get(op, "attributes.color", null)
      if (color) {
        break
      }
    }
    return color
  }

  initializedDarkMode = () => {
    const isDarkMode = get(this.props, "defaultValue.dark_mode", false);
    if (
      this.props.defaultValue.ops &&
      this.props.defaultValue.ops.length === 0
    ) {
      let shouldBeInDarkMode = true;
      const defaultStyleColor = get(
        this.props,
        "defaultValue.inheritable_style.color",
        "#000"
      );
      if (defaultStyleColor) {
        shouldBeInDarkMode =
          this.getBackgroundColor(defaultStyleColor) === "light" ? false : true;
      }
      if (isDarkMode !== shouldBeInDarkMode) {
        this.toggleDarkMode();
      }
    } else {
      const color = this.getColorFromAllOps(get(this.props, "defaultValue", null))
      if (!color) {
        if (isDarkMode) {
          this.toggleDarkMode();
        }
      } else {
        const backgroundMode = this.getBackgroundColor(color);
        const isBackgroundModeDark = backgroundMode === "dark" ? true : false;
        if (isBackgroundModeDark !== isDarkMode) {
          this.toggleDarkMode();
        }
      }
    }
  };

  handleSelectionChange = (newRange, oldRange, source) => {
    if (newRange) {
      this.setState({ range: newRange });
    }
  };

  customToolBar = ({
    controls,
    fontSizes,
    toggleColorPicker,
    fonts,
    clearFormat,
    quotate,
    setFont,
    setFontSize,
    setLineHeight,
    editorRef,
    showOnlyDefaultFonts = true
  }) => {
    const openPalette = (e) => {
      e.preventDefault();
      toggleColorPicker();
    };
    const clearFormatHandler = (e) => {
      e.preventDefault();
      clearFormat();
    };
    const quotateSelected = (e) => {
      e.preventDefault();
      quotate();
    };

    const checkProjectAllowCross = () => {
      const projectId = get(this.props.client_options, 'selected_folder_meta.project', null);
      const project = this.props.projects[projectId]
      return get(project, 'settings.allow_show_cross', false)
    }

    const addCross = () => {
      const editor = this._editorRef.getEditor();
      const range = editor.getSelection();
      if (range) {
        if (range.length > 0) {
          AlertNotification("error", "Character insertion is not available while selecting text")
        } else {
          editor.insertText(range.index, "†")
        }
      }
    }

    const handleListChange = (value) => {
      const editor = this._editorRef.getEditor();

      switch (value) {
        case 'numberedList':
          editor.format('list', 'ordered');
          break;
        case 'bulletList':
          editor.format('list', 'bullet');
          const selectedFontColor = this.state.selectedColor;

          const styleElement = document.createElement("style");
          styleElement.innerHTML = `.ql-editor li:not(.ql-direction-rtl)::before { color: ${selectedFontColor};}`;
          document.head.appendChild(styleElement);
          break;
        default:
          break;
      }
    }

    const handleIndentationChange = (value) => {
      const editor = this._editorRef.getEditor();

      switch (value) {
        case 'indentMinus':
          editor.format('indent', '-1');
          break;
        case 'indentPlus':
          editor.format('indent', '+1');
          break;
        default:
          break;
      }
    }

    const toggleTextCase = (textCase) => {
      const editor = this._editorRef.getEditor();
      let contents = editor.getContents();
      const { range } = this.state;
      if (range) {
        if (range.length > 0) {
          let content = editor.getText(range.index, range.length);
          content = content.split('\n')
          contents = contents.ops.map((op) => {
            switch (textCase) {
              case "uppercase":
                content.forEach(line => {
                  const words = line.split(' ');
                  words.forEach(word => {
                    op.insert = op.insert.replace(word, word.toUpperCase());
                  })
                })
                break;
              case "lowercase":
                content.forEach(line => {
                  const words = line.split(' ');
                  words.forEach(word => {
                    op.insert = op.insert.replace(word, word.toLowerCase());
                  })
                })
                break;
              case "capitalize":
                content.forEach(line => {
                  const words = line.split(' ');
                  words.forEach(word => {
                    op.insert = op.insert.replace(
                      word,
                      word
                        .split(" ")
                        .map(
                          (w) =>
                            w.substring(0, 1).toUpperCase() +
                            w.substring(1, w.length).toLowerCase()
                        )
                        .join(" ")
                    );
                  })
                })
                break;
              default:
                break;
            }
            return op;
          });
          editor.setContents(contents);
        }
      }
    };
    const wysiwygInputType = get(
      this.props.themeSettings,
      "wysiwyg_input_type",
      "select"
    );

    let lineHeightValidationError = '';
    if (this.state.validationResults['line_height'] && !this.state.validationResults['line_height'].isValid) {
      lineHeightValidationError = this.state.validationResults['line_height'].error;
    }

    return (
      <div id={`toolbar-${this.props.fieldName}`}>
        {(controls.fontFamily || controls.fontFamily === undefined) && (
          <FontFamilyInput
            onChange={setFont}
            fonts={fonts}
            showOnlyDefaultFonts={showOnlyDefaultFonts}
            value={this.state.font}
          />
        )}
        {(controls.lineHeight || controls.lineHeight === undefined) && (
            <Tooltip title="Line Height" mouseEnterDelay={0.5}>
            <span>
                <span style={{ marginRight: '0px' }}>
                  <i className="fas fa-line-height"></i>
                </span>
                  <InputNumber
                      ref={(el) => (this._spacingNumberInputRef = el)}
                      className="number-input ql-size"
                      defaultValue={this.lineHeight}
                      value={this.lineHeight}
                      onChange={(ev) => setLineHeight(ev, true)}
                      onBlur={(ev) => setLineHeight(ev.target.value)}
                      disabled={false}
                      step={0.1}
                      min={0.0}
                      max={100.0}
                      style={{ width: "5em" }}
                  />
            </span>
              <span>
                {lineHeightValidationError ? (
                    <div className="form-field-error">{lineHeightValidationError}</div>
                ) : (
                    ""
                )}
                </span>
            </Tooltip>
        )
        }
        {(!controls ||
          controls.fontSize ||
          controls.fontSize === undefined) && (
          <Tooltip title="Font Size" mouseEnterDelay={0.5}>
            <span className="ql-formats">
              {wysiwygInputType === "text" ? (
                <>
                  <InputNumber
                    ref={(el) => (this._numberInputRef = el)}
                    className="number-input ql-size"
                    defaultValue={this.fontSize}
                    value={this.fontSize}
                    onBlur={(ev) => setFontSize(ev.target.value)}
                    onChange={(ev) => setFontSize(ev, true)}
                    disabled={false}
                    step={1}
                    min={1}
                    max={300}
                    style={{ width: "4em" }}
                  />
                  <span style={{ marginLeft: ".5em" }}>px</span>
                </>
              ) : (
                <select className="ql-size">
                  {fontSizes && fontSizes.type === "range" && (
                    <>
                      {[...Array(fontSizes.max).keys()]
                        .filter(
                          (n) =>
                            n + 1 >= fontSizes.min && n + 1 <= fontSizes.max
                        )
                        .map((n) => (
                          <option
                            key={n + 1}
                            value={`${n + 1}${this.state.fontSizeUnit}`}
                          >{`${(n + 1) /
                          (this.state.fontSizeUnit === "em" ? 10 : 1)
                          } ${this.state.fontSizeUnit}`}</option>
                        ))}
                    </>
                  )}

                  {fontSizes &&
                  fontSizes.type === "custom" &&
                  fontSizes.map((s) => (
                    <option
                      key={s.em}
                      value={`${s.em}${this.state.fontSizeUnit}`}
                    >
                      {s.alias}
                    </option>
                  ))}

                  {!fontSizes && (
                    <>
                      {[...Array(100).keys()].map((n) => (
                        <option
                          key={n}
                          value={`${n + 1}${this.state.fontSizeUnit}`}
                        >{`${n + 1}${this.state.fontSizeUnit}`}</option>
                      ))}
                    </>
                  )}
                </select>
              )}
          </span>
          </Tooltip>
        )}
        {controls.strike && (
          <Tooltip title="Strike" mouseEnterDelay={0.5}>
            <button
                className="ql-strike hint--bottom-right"
                aria-label="Strike"
            ></button>
          </Tooltip>
        )}
        {(!controls ||
            controls.fontColor ||
            controls.fontColor === undefined) && (
              <span className="ql-formats" style={{marginTop: '0.5em'}}>
                  <Tooltip title="Font Color" mouseEnterDelay={0.5}>
                    <ColorPickerTool
                        ref={this._colorIconRef}
                        value={this.state.selectedColor}
                        onOpen={() => this.toggleEditorEditable(false)}
                        onChange={(c) => {
                          this.setState({
                            selectedColor: convertHexToHexWithAlpha(c),
                          });
                        }}
                        onClose={(c) => {
                          const editor = this._editorRef.getEditor();
                          editor.format("color", convertHexToHexWithAlpha(c), "api");
                          this.toggleEditorEditable(true);
                        }}
                        swatches={get(this.props, "settings.swatches", [])}
                    />
                </Tooltip>
              </span>
        )}
        {(controls.italic ||
          controls.italic === undefined ||
          controls.underline ||
          controls.underline === undefined) && controls.clearFormat && (
            <span className="ql-formats" style={{marginTop: '0.5em'}}>
              {controls.clearFormat && (
                  <Tooltip title="Reset Format" mouseEnterDelay={0.5}>
                    <button onClick={clearFormatHandler} aria-label="Reset format">
                      <FontAwesomeIcon
                          className="fa"
                          style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                          icon={["far", "remove-format"]}
                      />
                    </button>
                  </Tooltip>
              )}
              {(!controls || controls.bold || controls.bold === undefined) && (
                  <button
                      className="ql-bold hint--bottom-right"
                      aria-label="Bold"
                  />
              )}
              {(!controls ||
                controls.italic ||
                controls.italic === undefined) && (
                  <button
                    className="ql-italic hint--bottom-right"
                    aria-label="Italic"
                  />
                )}
              {(!controls ||
                controls.underline ||
                controls.underline === undefined) && (
                  <button
                    className="ql-underline hint--bottom-right"
                    aria-label="Underline"
                  />
                )}
            </span>
          )}
        {
          <span className="ql-formats" style={{marginTop: '0.5em'}}>
            <Tooltip title="Indent" mouseEnterDelay={0.5}>
              <Select className="ql-list-options" defaultValue="indentMinus" onChange={handleIndentationChange}>
                <Option value="indentMinus"><FontAwesomeIcon icon={faIndent} /></Option>
                <Option value="indentPlus"><FontAwesomeIcon icon={faOutdent} /></Option>
              </Select>
            </Tooltip>
          </span>
        }
        {
          <span className="ql-formats" style={{marginTop: '0.5em'}}>
            <Tooltip title="List" mouseEnterDelay={0.5}>
            <Select className="ql-list-options" defaultValue="numberedList" onChange={handleListChange}>
              <Option value="bulletList"><FontAwesomeIcon icon={faListUl} /></Option>
              <Option value="numberedList"><FontAwesomeIcon icon={faListOl} /></Option>
            </Select>
            </Tooltip>
          </span>
        }
        {controls.direction ||
          (controls.alignment && (
            <span className="ql-formats">
              {controls.direction && (
                <Tooltip title="Direction" mouseEnterDelay={0.5}>
                  <button
                    className="ql-direction hint--bottom-right"
                    value="rtl"
                    aria-label="Direction"
                  ></button>
                </Tooltip>
              )}
            </span>
          ))}
        {(!controls ||
          controls.alignment ||
          controls.alignment === undefined) && (
              <Tooltip title="Alignment" mouseEnterDelay={0.5}>
                <span className="ql-formats" style={{marginTop: '0.5em'}}>
                  <select
                    className="ql-align hint--bottom-right"
                    aria-label="Alignment"
                  ></select>
                </span>
              </Tooltip>
          )}
        <span className="ql-formats" style={{marginTop: '0.5em'}}>
          <Select
              onSelect={(v, e) => {
                toggleTextCase(v);
              }}
              defaultValue="uppercase">
            <Option value="uppercase">ABC</Option>
            <Option value="lowercase">abc</Option>
            <Option value="capitalize">Abc</Option>
          </Select>
        </span>
        <span className="ql-formats" style={{marginTop: '0.5em'}}>
          <Tooltip title="Quotation Attribute Dash" mouseEnterDelay={0.5}>
            <button
                onClick={quotateSelected}
                aria-label="Quotation Attribute Dash"
            >
              <FontAwesomeIcon
                  className="fa"
                  style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                  icon={["far", "minus"]}
              />
            </button>
          </Tooltip>
        </span>
        {(controls.hyperlink || controls.video || controls.formula) && (
            <span className="ql-formats" style={{marginTop: '0.5em'}}>
            {controls.hyperlink && (
              <button
                className="ql-link hint--bottom-right"
                aria-label="Hyperlink"
              ></button>
            )}
            {controls.video && (
              <>
                <button
                  className="ql-video hint--bottom-right"
                  aria-label="Attach video from URL"
                ></button>{" "}
                <span>--{">"}</span>
              </>
            )}
            {controls.formula && (
              <button
                className="ql-formula hint--bottom-right"
                aria-label="Enter a Formula"
              ></button>
            )}
          </span>
        )}
        {(controls.h1 ||
            controls.h2 ||
            controls.blockquote ||
            controls.rule ||
            controls.codeBlock) && (
            <span className="ql-formats" style={{marginTop: '0.5em'}}>
              {controls.h1 && (
                  <Tooltip title="H1" mouseEnterDelay={0.5}>
                    <button
                        className="ql-header hint--bottom-right"
                        value="1"
                        aria-label="Wrap in H1"
                    ></button>
                  </Tooltip>
              )}
              {controls.h2 && (
                  <Tooltip title="H2" mouseEnterDelay={0.5}>
                    <button
                        className="ql-header hint--bottom-right"
                        value="2"
                        aria-label="Wrap in H2"
                    ></button>
                  </Tooltip>
              )}
              {controls.blockquote && (
                  <Tooltip title="Blockquote" mouseEnterDelay={0.5}>
                    <button
                        className="ql-blockquote hint--bottom-right"
                        aria-label="Blockquote"
                    ></button>
                  </Tooltip>
              )}
              {controls.codeBlock && (
                  <Tooltip title="Codeblock" mouseEnterDelay={0.5}>
                    <button
                        className="ql-code-block hint--bottom-right"
                        aria-label="Code Block"
                    ></button>
                  </Tooltip>
              )}
              {controls.sub ||
                  (controls.super && (
                    <span className="ql-formats" style={{marginTop: '0.5em'}}>
                      {controls.sub && (
                          <Tooltip title="Sub" mouseEnterDelay={0.5}>
                            <button
                                className="ql-script hint--bottom-right"
                                value="sub"
                                aria-label="Sub"
                            ></button>
                          </Tooltip>
                      )}
                      {controls.super && (
                          <Tooltip title="Super" mouseEnterDelay={0.5}>
                            <button
                                className="ql-script hint--bottom-right"
                                value="super"
                                aria-label="Super"
                            ></button>
                          </Tooltip>
                      )}
                    </span>
                  ))}
              {controls.rule && (
                  <Tooltip title="Rule" mouseEnterDelay={0.5}>
                    <button
                        className="ql-rule hint--bottom-right"
                        aria-label="Rule"
                    >
                      <FontAwesomeIcon
                          className="fa"
                          style={{ color: LT_GRAY_BLUE, cursor: "pointer" }}
                          icon={["far", "horizontal-rule"]}
                      />
                    </button>
                  </Tooltip>
              )}
            </span>
        )}
        {
          get(
            this.props,
            "settings.crossButton",
            false
          ) && checkProjectAllowCross() && (
            <span className="ql-formats" style={{marginTop: '0.5em'}}>
              <Tooltip title="Add Cross" mouseEnterDelay={0.5}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    addCross();
                  }}
                  aria-label="Capitalize Text"
                >
                  <span>†</span>
                </button>
              </Tooltip>
            </span>
          )
        }
      </div>
    );
  };

  setCharCount = (charCount) => {
    const recommendedLength = get(
      this.props,
      "settings.recommended_length",
      50
    );
    let charCountColor = XLT_GRAY;
    if (charCount > recommendedLength) {
      charCountColor = MD_RED;
    }
    this.setState({
      charCount: charCount,
      charCountColor: charCountColor,
    });
  };

  onBlur = (previousRange, source, editor) => {
    const contents = editor.getContents();
    this.props.onBlur(this.props.fieldName, contents, editor);
    this.setState({
      focused: false,
    });
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onChange = (_content, _delta, _source, editor) => {
    const contents = editor.getContents();
    // Check for ops without font family applied
    contents.ops = contents.ops.map((op, i) => {
      if (op.insert.rule || op.insert.includes("\n")) {
        return op;
      }
      if (!op.attributes) {
        op.attributes = {};
      }
      if (!op.attributes.font || !op.attributes.size) {
        for (let w = i - 1; w >= 0; w--) {
          const previousOp = contents.ops[w];
          if (!op.attributes.font) {
            if (
              previousOp &&
              previousOp.attributes &&
              previousOp.attributes.font
            ) {
              op.attributes.font = previousOp.attributes.font;
            }
          }

          if (!op.attributes.size) {
            if (
              previousOp &&
              previousOp.attributes &&
              previousOp.attributes.size
            ) {
              op.attributes.size = previousOp.attributes.size;
            }
          }
        }
      }
      return op;
    });

    this.setCharCount(editor.getLength());

    this.props.onChange(this.props.fieldName, {
      ...this.props.defaultValue,
      ...contents,
    });
    this.props.onBlur(this.props.fieldName, contents, editor);
  };

  toggleColorPicker = () => {
    this.setState(
      {
        colorIconLocation: this._currentPositionScroll,
        colorPickerOpen: !this.state.colorPickerOpen,
      },
      () => {
        this.toggleEditorEditable(!this.state.colorPickerOpen);
      }
    );
  };

  clearFormat = () => {
    const { resetToDefaultValue } = this.props;
    if (resetToDefaultValue.type === "style") {
      const editor = this._editorRef.getEditor();
      const delta = editor.getContents();
      const ops = delta.ops;
      if (ops && ops.length) {
        for (let i = 0; i < ops.length; i++) {
          ops[i].attributes = {
            ...resetToDefaultValue.style,
            ...this._defaultStyle,
          };
        }
      }
      editor.setContents(ops);
    }
  };

  quotate = () => {
    const editor = this._editorRef.getEditor();
    const selection = editor.getSelection();
    if (
      selection &&
      selection.index !== null &&
      selection.index !== undefined
    ) {
      editor.insertText(selection.index, "— ", "user");
    }
  };

  toggleEditorEditable = (editable = true) => {
    const qlEditor = findDOMNode(
      this._wysiwygContainerRef
    ).getElementsByClassName("ql-editor");
    if (qlEditor && qlEditor[0]) {
      qlEditor[0].setAttribute("contenteditable", editable);
      if (editable) {
        qlEditor[0].style = "";
      } else {
        qlEditor[0].style = "user-select:none";
      }
    }
  };

  setFont = (font) => {
    this.setState({ font: font })
    const editor = this._editorRef.getEditor();
    editor.format("font", font, "silent");
  };



  setFontSize = (e, isOnChange = false) => {
    const editor = this._editorRef.getEditor();
    editor.format("size", `${e}px`, "silent");
    this.fontSize = e;
    if (isOnChange) {
      this._numberInputRef.focus();
    }
  };

  setLineHeight = (e, isOnChange = false) => {
    const editor = this._editorRef.getEditor();
    const lineHeightValue = Number(e);
    const result = validate(
        { line_height: [{ name: "isNumber", min: 0.15, max: 100 }] },
        { line_height: lineHeightValue }
    );

    if (!result.isFormValid) {
      this.state.validationResults = result.fieldResults;
      this.lineHeight = 1.0;
      editor.format("lineHeight", 1.0, "silent");

      if (this.timeoutId)
        clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.setState({ validationResults: "" });
      }, 4000);
      return;
    }

    this.state.validationResults = result.fieldResults;
    editor.format("lineHeight", `${e}`, "silent");
    this.lineHeight = e;
    if (isOnChange) {
      this._spacingNumberInputRef.focus();
    }
  };

  handleCopy = (ev) => {
    const copied_text = window.getSelection().toString().replace(/(\r\n|\n|\r)/gm, "");
    this.props.setClientOptions({ apello_copy: true, copied_text })
  }

  render() {
    const { defaultValue, settings } = this.props;
    let editorClassName = get(this.props, "defaultValue.dark_mode", false)
      ? "text-editor-wysiwyg-dark"
      : "text-editor-wysiwyg";
    if (this.state.fontSizeUnit === "px") {
      editorClassName = get(this.props, "defaultValue.dark_mode", false)
        ? "text-editor-wysiwyg-dark-px"
        : "";
    }
    const recommendedCharCount = get(
      this.props,
      "settings.recommended_length",
      50
    );
    const showRecommendedLength = get(
      this.props,
      "settings.show_recommended_length",
      true
    );

    let fonts = settings?.fonts || DEFAULT_FONTS;
    let showOnlyDefaultFonts = true

    const projectId = get(this.props.client_options, 'selected_folder_meta.project', null);
    const project = this.props.projects[projectId];

    if (
      project &&
      project.settings &&
      project.settings.default_fonts &&
      project.settings.default_fonts.length
    ) {
      fonts = project.settings.default_fonts
      showOnlyDefaultFonts = project.settings.show_only_default_fonts
    }


    const toolbar = this.customToolBar({
      controls: settings?.controls || {},
      fontSizes: settings?.fontSizeOptions || null,
      fonts,
      editorRef: this.editorRef,
      toggleColorPicker: this.toggleColorPicker,
      clearFormat: this.clearFormat,
      quotate: this.quotate,
      setFont: this.setFont,
      setFontSize: this.setFontSize,
      setLineHeight: this.setLineHeight,
      showOnlyDefaultFonts,
    });

    return (
      <>
        <div
          ref={(el) => (this._wysiwygContainerRef = el)}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          onCopyCapture={(ev) => this.handleCopy(ev)}
        >
          {this.props.showLabel !== false ? (
            <div>
              <label className="form-input-label">{this.props.title}</label>
              {this.props.tooltip ? (
                <InputTooltip tooltip={this.props.tooltip} />
              ) : null}
            </div>
          ) : null}
          <div className={`text-editor-wysiwyg-panel ${editorClassName}`}>
            {toolbar}
            <div>
              <ReactQuill
                key={this.props.fieldName}
                ref={(el) => (this._editorRef = el)}
                defaultValue={defaultValue}
                onChange={this.onChange}
                modules={this._modules}
                onFocus={this.onFocus}
                onChangeSelection={this.handleSelectionChange}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              display: "inline-block",
            }}
          >
            {showRecommendedLength &&
              (this.state.focused || this.state.isHovered) ? (
              <span style={{ color: this.state.charCountColor }}>
                {this.state.charCount}/{recommendedCharCount}
              </span>
            ) : (
              <span className="noselect">&nbsp;</span>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    client_options: state.client_options,
    projects: state.data.projects
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WysiwygInput);

// export default WysiwygInput;
