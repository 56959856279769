
import React from 'react';

export default props => {
  let formClass = 'form-wrapper';
  if (props.classOverride){
    formClass = props.classOverride
  }
  return (
    <div className={formClass}>{props.children}</div>
  );
};

