import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tangent_root } from "./tangent_root";
import { tangent_content } from "./tangent_content";
import { tangent_content_stories } from "./tangent_content_stories";
import { tangent_display } from "./tangent_display";
import { tangent_displays } from "./tangent_displays";
import { tangent_presentations } from "./tangent_presentations";
import { tangent_presentation_1x1 } from "./tangent_presentation_1x1";
import { tangent_fullscreen_media } from "./tangent_fullscreen_media";
import { tangent_content_donor_lists } from "./tangent_content_donor_lists";
import { tangent_donor_list_group } from "./tangent_donor_list_group";
import { tangent_donor_list } from "./tangent_donor_list";
import { tangent_donor } from "./tangent_donor";
import { tangent_story_with_media } from "./tangent_story_with_media";
import { tangent_story_with_single_image } from "./tangent_story_with_single_image";
import { tangent_media_with_quote } from "./tangent_media_with_quote";

export const tangent_types: { [key: string]: IFolderType } = {
  tangent_root,
  tangent_content,
  tangent_content_stories,
  tangent_display,
  tangent_displays,
  tangent_fullscreen_media,
  tangent_presentations,
  tangent_presentation_1x1,
  tangent_content_donor_lists,
  tangent_donor_list_group,
  tangent_donor_list,
  tangent_donor,
  tangent_story_with_media,
  tangent_story_with_single_image,
  tangent_media_with_quote,
};
