import { call, put, all } from "redux-saga/effects";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { normalize, schema } from "normalizr";
import { GET_TAGS_FAILURE, GET_TAGS_SUCCESS } from "../actions/actionTypes";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
const tagSchema = new schema.Entity("tags");
import { addError, addExceptionError } from "../actions/notificationActions";

async function getTagsByCompanyAPI(payload) {
  return Request("/tag/get_tags", HTTP_METHODS.POST, { ...payload });
}

const tagSaga = {
  getTags: function* (action) {
    try {
      const response = yield call(getTagsByCompanyAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: GET_TAGS_SUCCESS,
            normalized: normalize(response.data.tags, [tagSchema]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: GET_TAGS_FAILURE });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      yield put({ type: GET_TAGS_FAILURE });
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default tagSaga;
