import {
  COMPONENT_NAME,
  FOLDER_TYPE, PREVIEW_TYPE, PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from '../../../../enums/folder-type-enums';
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { transitions } from "./tangent_settings";

export const tangent_donor_list_group: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.tangent_donor_list_group,
  display_name: "Donor List Group",
  acceptable_folder_types: [FOLDER_TYPE.tangent_donor_list],
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
  },
  preview: {
    aspect_ratio: 9 / 32,
    component_name: COMPONENT_NAME.TangentContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
