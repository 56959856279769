import React, { useEffect, useRef, useState } from "react";
import {
  getBaseFolderType,
  getChildrenData,
} from "../../../utils/commonDataUtils";
import Slider from "react-slick";
import get from "lodash/get";
import MonterreyFact from "./MonterreyFact";

const MonterreyFactCarousel = (props) => {
  let baseFolderType = {};

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slideRef = useRef(null);

  let facts = getChildrenData(props.data, props.baseFolder.id).folders;

  if (facts.length) {
    baseFolderType = getBaseFolderType(
      props.data,
      get(facts[0], "folder_type", {})
    );
  }

  if (props.isChild) {
    baseFolderType = getBaseFolderType(
      props.data,
      get(props.child, "folder_type", {})
    );
    facts = [props.child];
  }

  useEffect(() => {
    if (facts.length) {
      // If it's the last fact, we call handleEndOfPlay on the parent.
      // else we keep going with the next fact.
      const cycleTime = get(facts[currentSlideIndex], "fields.fact_cycle_time", 5) * 1000;
      if (currentSlideIndex === facts.length - 1) {
        setTimeout(() => {
          // We notify that the last fact has been shown.
          // but we keep cycling through the facts nonetheless.
          if (props.handleEndOfPlay) {
            props.handleEndOfPlay();
          }
          setCurrentSlideIndex(0);
          if (slideRef && slideRef.current) {
            slideRef.current.slickNext();
          }
        }, cycleTime);
      } else {
        setTimeout(() => {
          if (slideRef && slideRef.current) {
            slideRef.current.slickNext();
          }
        }, cycleTime);
      }
    }
  }, [currentSlideIndex]);

  return (
    <div className="factWrapper" style={props.style}>
      {facts.length && facts.length > 1 ? (
        <>
          <Slider
            ref={slideRef}
            style={{
              display: "flex",
              overflow: "hidden",
              width: "100%",
              padding: "5em",
              flexDirection: "column",
              backgroundColor: props.factBackgroundColor,
              minHeight: props.minHeight,
            }}
            dots={false}
            infinite={true}
            arrows={false}
            slidesToShow={1}
            speed={1000}
            initialSlide={0}
            beforeChange={(prev, next) => {
              setCurrentSlideIndex(next);
            }}
          >
            {facts.map((fact, k) => (
              <MonterreyFact
                {...props}
                fact={fact}
                key={k}
                baseFolderType={baseFolderType}
              />
            ))}
          </Slider>
          <div
            className="factCarouselContainer"
            style={{
              display: "flex",
              marginTop: "2.5em",
              marginBottom: "2.5em",
              justifyContent: "space-around",
              alignSelf: "center",
            }}
          >
            {facts.map((fact, index) => (
              <div
                key={`fact-${index}`}
                className="carouselItem"
                style={{
                  width: "1em",
                  height: "1em",
                  marginRight: "1em",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: props.factBackgroundColor,
                  borderRadius: "50%",
                  backgroundColor:
                    index === currentSlideIndex
                      ? props.factBackgroundColor
                      : "",
                }}
              />
            ))}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            width: "100%",
            padding: "5em",
            flexDirection: "column",
            backgroundColor: props.factBackgroundColor,
            minHeight: props.minHeight,
          }}
        >
          {facts.length ? (
            <MonterreyFact
              {...props}
              fact={facts[0]}
              baseFolderType={baseFolderType}
            />
          ) : (
            <div
              style={{
                display: "flex",
                overflow: "hidden",
                width: "100%",
                flexDirection: "column",
                height: "100%",
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MonterreyFactCarousel;
