import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const glenwood_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.glenwood_display,
  display_name: "Glenwood Display",
  prevent_add_children: true,
  acceptable_folder_types: [FOLDER_TYPE.glenwood_presentation, FOLDER_TYPE.glenwood_grouped_presentations],
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  hide_nested_children: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.GlenwoodContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  }
};
