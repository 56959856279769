import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

interface IBackLinkProps {

}

export const BackLink: React.FC<IBackLinkProps> = (props: IBackLinkProps) => {

    const navigate = useNavigate();

    return (
        <a
            className="back-link"
            onClick={() => {
                navigate(-1)
            }}>&nbsp; {'<'} &nbsp;Back
         </a>
    )

}