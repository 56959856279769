import { Input, Tooltip, Button, Popover } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { PDG_COLORS } from "../../../shared-global/enums/ui-enums";
import { MD_RED, XLT_GRAY } from "../../layout/LayoutConstants";
import { useSelector } from "react-redux";
import { IState } from "../../../interfaces/main-state.interface";
import InputTooltip from "./InputTooltip";
interface ITextAreaInputProps {
  fieldName: string;
  defaultValue: string;
  title: string;
  onBlur?: any;
  onChange?: any;
  showLabel?: boolean;
  canUpdate?: boolean;
  showCharCount?: boolean;
  settings?: any;
  tooltip?: string;
}

const TextAreaInput: React.FunctionComponent<ITextAreaInputProps> = (props: ITextAreaInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [appliedStyle, setAppliedStyle] = useState({})

  const showCrossButton = get(
    props,
    "settings.crossButton",
    false
  )


  const searchString = useSelector(
    (state: IState) => state.client_options.search_string_highlight
  );

  const projectId = useSelector(
    (state: IState) => state.client_options.selected_folder_meta.project
  );

  const projects = useSelector(
    (state: IState) => state.data.projects
  )

  const project = projects[projectId];


  useEffect(() => {
    const searchStringOrEmpty = searchString || '';
    const defaultValueOrEmpty = _.get(props, 'defaultValue', '');
    if (searchStringOrEmpty.toLowerCase().length && defaultValueOrEmpty.toLowerCase().includes(searchStringOrEmpty.toLowerCase())) {
      setAppliedStyle({
        borderColor: PDG_COLORS.MD_WARNING
      })
    }
    else {
      setAppliedStyle({})
    }
  }, [searchString])

  const onFocus = () => {
    setIsFocused(true);
  };

  const addCross = () => {
    const val = props.defaultValue + " †"
    props.onChange(props.fieldName, val)
  }

  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const getCharCount = () => {
    const textLength = get(props, "defaultValue.length", 0);
    const recommendedLength = get(props, "settings.recommended_length", 50);
    let charCountColor = XLT_GRAY;
    if (textLength > recommendedLength) {
      charCountColor = MD_RED;
    }
    return (
      <div
        style={{ width: "100%", textAlign: "right", display: "inline-block" }}
      >
        {isFocused || isHovered ? (
          <span style={{ color: charCountColor }}>
            {textLength}/{recommendedLength}
          </span>
        ) : (
          <span className="noselect">&nbsp;</span>
        )}
      </div>
    );
  };

  return (
    <>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          {
            props.tooltip ?
              <InputTooltip tooltip={props.tooltip} />
              : null
          }
          <br />
        </span>
      ) : null}
      <Popover
        content={<Button type="primary" onClick={addCross}>Add Cross †</Button>}
        trigger="click"
        placement="topRight"
        visible={showCrossButton && get(project, 'settings.allow_show_cross', false) ? visible : false}
        onVisibleChange={handleVisibleChange}
      >
        <Input.TextArea
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={!props.canUpdate}
          value={props.defaultValue}
          className="text-area-input"
          defaultValue={props.defaultValue}
          onChange={(ev) => {
            props.onBlur(props.fieldName, ev.target.value)
            props.onChange(props.fieldName, ev.target.value)
          }}
          onFocus={() => onFocus()}
          autoSize={{
            minRows: get(props, "settings.autosize.minRows", 3),
            maxRows: get(props, "settings.autosize.maxRows", 6),
          }}
          autoFocus={get(props, "settings.autoFocus", false)}
          style={appliedStyle}
        />
      </Popover>
      {props.showCharCount !== false ? getCharCount() : null}
    </>
  );
}

export default TextAreaInput;
