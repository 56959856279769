import {
  COMPONENT_NAME,
  FOLDER_TYPE, PREVIEW_TYPE
} from '../../../../enums/folder-type-enums';
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const berkeley_content_donor_seas: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.berkeley_content_donor_seas,
  display_name: "Donor Seas",
  acceptable_folder_types: [FOLDER_TYPE.berkeley_donor_sea],
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    }
  },
  preview: {
    aspect_ratio: 2.666666666666667,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
