import React from "react";
import { DatePicker } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { BaseInputProps } from "./types";

const defaultDateFormat = "YYYY-MM-DD HH:mm";

export type DateTimeInputProps = BaseInputProps<number> & { utc: boolean };

const DateTimeInput = (props: DateTimeInputProps) => {

  const onBlur = (ev) => {
    if (props.onBlur) {
      if (props.utc) {
        props.onBlur(props.fieldName, new Date(ev.target.value).getTime());
      } else {
        props.onBlur(
          props.fieldName,
          moment(ev.target.value).utc().toDate().getTime()
        );
      }
    }
  };

  const onChange = (moment) => {
    let value = 0
    if (moment) {
      value = moment.utc().toDate().getTime()
    }
    props.onChange(props.fieldName, value)
  }

  let defaultValue = null

  if (props.defaultValue) {
    defaultValue = moment(props.defaultValue);
    if (props.utc) {
      defaultValue = moment(props.defaultValue).utc();
    }
  }

  return (
    <>
      {props.title !== "" && (
        <>
          <label className="form-input-label">{props.title}</label>
          <br />
        </>
      )}
      <DatePicker
        showTime
        disabled={!props.canUpdate}
        defaultValue={defaultValue}
        format={defaultDateFormat}
        onChange={(moment) => onChange(moment)}
        onBlur={(ev) => onBlur(ev)}
      />
    </>
  );
};

DateTimeInput.defaultProps = {
  onBlur: () => {},
  defaultDateFormat,
  defaultValue: moment(Date.now()).format(defaultDateFormat),
  utc: false,
};

DateTimeInput.propTypes = {
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  utc: PropTypes.bool,
};

export default DateTimeInput;
