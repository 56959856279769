import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PDG_COLORS, TREE_TYPE } from "../../shared-global/enums/ui-enums";
import { IState } from "../../interfaces/main-state.interface";
import { IResource } from "../../shared-global/interfaces/models/resource.interface";
import { countOnTrue } from "../../utils/commonUtils";
import {
  TREE_MODE_APPLIED_CONTENT,
  TREE_MODE_RESOURCE,
  TREE_MODE_SELECT_RESOURCE,
} from "../constants";
import { TREE_ICON_TEXT_MARGIN } from "../layout/LayoutConstants";
import Spaner from "../utils/Spaner";
import TextWithHighlights from "./TextWithHighlights";
import TreeItemIcon from "./TreeItemIcon";
import { MEDIA_PROGRESS_STATUS_PROCESSING } from "../form/input/MediaProgressStatus/MediaProgressStatus";
import { hasPermission } from "../../utils/permissionUtils";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { get } from "lodash";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
import { getFolderUsedSlots } from '../../shared-global/utils/globalDataUtils';
import { isInPresentationsFolder, isInPresentationFolder, isLowLevelItem } from "../../services/treeService";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";

interface ITreeItemProps {
  acceptableFolderTypes: Array<any>;
  addResource: any;
  addSubFolder: any;
  csvDownload: any;
  defaultIcons: any;
  deleteFolder: any;
  deleteResource: any;
  expanded: boolean;
  folderTypes: any;
  folder__folder_id: number;
  folders: any;
  parent__folder_id: number;
  handleOnClick: any;
  inProgress: boolean;
  isOver: boolean;
  isDragging: boolean;
  canDrop: boolean;
  itemIcons: any;
  modeContext: string;
  name: string;
  openCsvModal: any;
  resource: IResource;
  resource__folder_id: number;
  resource_tree_crud: any;
  searchText: string;
  selectedTreeId: number;
  spacer: boolean;
  style: any;
  treeId: string;
  path: number[];
  isContent: boolean;
  folder__folders: { [key: string]: IFolderFolder };
  meta: any;
  saveOptions?: any[];
  folder_type?: any;
  showGray?: boolean;
  showChildAcceptableIcon?: boolean;
  resourcetreeFolders?: any
}

const iconButtonStyle = {
  width: 24,
  height: 24,
  margin: "auto",
  display: "inline-block",
  marginTop: 2,
};
const iconMargin = 5;

const TreeItem: React.FunctionComponent<ITreeItemProps> = (
  props: ITreeItemProps
) => {
  const spanNameRef = useRef<HTMLSpanElement>(null);
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [isOpenable, setIsOpenable] = useState(false);
  const [goToContentEnabled, setGoToContentEnabled] = useState(false);
  const [textColor, setTextColor] = useState<string>(PDG_COLORS.LT_GRAY_BLUE);
  const [iconState, setIconState] = useState<string>(
    props.expanded ? "open" : "closed"
  );
  const [isSelected, setIsSelected] = useState(false);
  // const [iconBackColor, setIconBackColor] = useState<string>(PDG_COLORS.WHITE);
  const [iconColor, setIconColor] = useState<string>(PDG_COLORS.MD_BLUE);
  const [folderId, setFolderId] = useState<number | null>(null);
  const [folder__folder, setFolder__folder] = useState([]);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [projectFolder, setProjectFolder] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(PDG_COLORS.WHITE);
  const [mouseCursor, setMouseCursor] = useState("pointer");
  const [canActionDrop, setCanActionDrop] = useState(true);
  const [ellipsisMargin, setEllipsisMargin] = useState(16);
  const [renderedStyle, setRenderedStyle] = useState({
    ...props.style,
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
  });
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedResourceFolderId, setSelectedResourceFolderId] = useState<
    string | null
  >(null);
  const [checkedTree, setCheckedTree] = useState<string>(
    isBeingHovered ? props.treeId : `${props.selectedTreeId}`
  );
  const [addFolderOption, setAddFolderOption] = useState(false);
  const [cloneFolderOption, setCloneFolderOption] = useState(false);
  const [addFileOption, setAddFileOption] = useState(false);
  const [deleteResourceOption, setDeleteResourceOption] = useState(false);
  const [deleteFolderOption, setDeleteFolderOption] = useState(false);
  const [renderDataFolderFolder, setRenderDataFolderFolder] = useState(false);
  const [addResourceOption, setAddResourceOption] = useState(false);
  const [showCaret, setShowCaret] = useState(false);

  const [showSlots, setShowSlots] = useState(false);
  const [slots, setSlots] = useState(0);
  const [slotsUsedMessage, setSlotsUsedMessage] = useState('');
  const [slotsExceeded, setSlotsExceeded] = useState(false);

  const dispatch = useDispatch()

  const folderResults = useSelector(
    (state: IState) => state.data.folder_results
  );
  const resourceResults = useSelector(
    (state: IState) => state.data.resource_results
  );
  const folderMetas = useSelector((state: IState) => state.data.folder_metas);
  const folder__folders = useSelector(
    (state: IState) => state.data.folder__folders
  );
  const folder_types = useSelector((state: IState) => state.data.folder_types);
  const user = useSelector((state: IState) => state.data.user);
  const folders = useSelector((state: IState) => state.data.folders);
  const grouped_permissions = useSelector(
    (state: IState) => state.data.user_permissions_grouped
  );
  const resourcetree_folders = useSelector(
    (state: IState) => state.data.resourcetree_folders
  );
  const resourcetree_folder__folders = useSelector(
    (state: IState) => state.data.resourcetree_folder__folders
  );

  const [acceptableFolderTypes] = useState(props.acceptableFolderTypes || []);
  const [iconCount, setIconCount] = useState(0);
  const [iconConfig, setIconConfig] = useState(null);
  const [folderInProgress, setFolderInProgress] = useState(false);

  const canNestedChildrenAllowExpandItsFolder = (parentFolder) => {
    try {
      return !props.folderTypes[parentFolder.folder_type].hide_nested_children;
    } catch (e) {
      // if any error happen, then allow nest children
      return true;
    }
  };

  const checkParentHasUniqueFolderTypeInstances = () => {
    let parentHasUniqueFolderTypeInstances = false;
    const parentFolder = props.folders[props.parent__folder_id];
    if (parentFolder) {
      const parentFolderFolderType =
        props.folderTypes[parentFolder.folder_type];
      if (
        parentFolderFolderType?.tree_dropped_into_action &&
        parentFolderFolderType.tree_dropped_into_action[0]
      ) {
        if (
          parentFolderFolderType.tree_dropped_into_action[0].action ===
          "instance_replace"
        ) {
          parentHasUniqueFolderTypeInstances = true;
        }
      }
    }
    return parentHasUniqueFolderTypeInstances;
  };

  const checkShowArrow = () => {
    if (
      props.modeContext === "resource" ||
      props.modeContext === "select_existing_resource" ||
      props.modeContext === "select_existing_applied_content"
    ) {
      const folder__folder =
        resourcetree_folder__folders[props.folder__folder_id];
      const hasChildren = Object.keys(resourcetree_folder__folders).some(
        (key) => {
          if (!props.folder__folder_id) {
            return (
              props.resourcetreeFolders[resourcetree_folder__folders[key].child_folder] &&
              props.resourcetreeFolders[resourcetree_folder__folders[key].child_folder].folder_type === 'resource'
            )
          } else {
            return (
              resourcetree_folder__folders[key].parent_folder ===
              folder__folder?.child_folder
            );
          }
        }
      );
      setShowCaret(hasChildren);
    }
    if (props.modeContext === "applied_content") {
      const folder__folder = props.folder__folders[props.folder__folder_id];
      const hasChildren = Object.keys(props.folder__folders).some((key) => {
        return (
          props.folder__folders[key].parent_folder ===
          folder__folder?.child_folder
        );
      });
      setShowCaret(hasChildren || !props.path);
    }
  }

  useMemo(() => {
    setAddFileOption(props.modeContext === TREE_MODE_RESOURCE);
  }, [props.modeContext]);


  useEffect(() => {

    if (props.treeId.startsWith("folder-")) {
      const parentHasUniqueFolderTypeInstances =
        checkParentHasUniqueFolderTypeInstances();
      setRenderDataFolderFolder(true);
      let isOpenable = false
      if (props.modeContext === "select_existing_applied_content") {
        isOpenable = true;
      }
      if (props.acceptableFolderTypes.length) {
        // check if parent folder allow expand children folders (for example Attract Loop do not allow this action)
        const parentFolder = props.folders[props.parent__folder_id];
        const isChildrenFolderOpenable =
          canNestedChildrenAllowExpandItsFolder(parentFolder);
        isOpenable = isChildrenFolderOpenable && !parentHasUniqueFolderTypeInstances;
      }

      if (props.modeContext === 'applied_content') {
        const isInPresentations = isInPresentationsFolder(props.path, props.folder__folders, props.folders, props.folderTypes)
        setGoToContentEnabled(!isOpenable && isInPresentations)
      }
      setIsOpenable(isOpenable)

      if (props.folders && props.modeContext === TREE_MODE_APPLIED_CONTENT) {
        const parentFolder = props.folders[props.parent__folder_id];
        // if modeContext is TREE_MODE_APPLIED_CONTENT
        // AND has parent folder
        // then show delete icon

        // Check folder type here
        const id = props.treeId.split("-")[1];
        const currentFolder = folders[id];
        const currentFolderType = folder_types[currentFolder.folder_type];

        if (parentFolder) {
          const canDeleteFolders = hasPermission(
            grouped_permissions,
            "applied_content",
            "delete",
            user,
            "project",
            Object.values(folder__folders).find(
              (x) => x.child_folder === folderId
            )?.project || null,
            user.company
          );
          const canDeleteThisFolderType = currentFolderType.name.includes('menu') || currentFolderType.name.includes('search') ? false : true;
          setDeleteFolderOption(canDeleteFolders && canDeleteThisFolderType);
        }

        if (currentFolderType.slots) {
          setShowSlots(true);
          setSlots(currentFolderType.slots);
        }
      } else if (props.modeContext === TREE_MODE_RESOURCE) {
        const canDeleteFolders = true;
        setDeleteFolderOption(canDeleteFolders);
      }
    } else if (props.treeId.startsWith("company-")) {
      if (props.modeContext === TREE_MODE_RESOURCE) {
      }
      setIsOpenable(true);
    } else if (props.treeId.startsWith("location-")) {
      setIsOpenable(true);
    } else if (props.treeId.startsWith("project-")) {
      setProjectFolder(true);
    } else if (
      props.treeId.includes("resource") &&
      props.modeContext !== TREE_MODE_SELECT_RESOURCE
    ) {
      if (props.modeContext === TREE_MODE_RESOURCE) {
        setDeleteResourceOption(props.resource_tree_crud.delete);
      } else {
        setDeleteResourceOption(true);
      }
    }

    if (props.isContent) {
      setRenderedStyle({
        ...renderedStyle,
        color: PDG_COLORS.CONTENT_ICON_COLOR,
        fontWeight: "bold",
      });
      setTextColor(PDG_COLORS.CONTENT_ICON_COLOR);
      setIconColor(PDG_COLORS.CONTENT_ICON_COLOR);
      setBackgroundColor(PDG_COLORS.CONTENT_COLOR);
    }
  }, [props.resourcetreeFolders]);

  useEffect(() => {
    if (props.treeId.startsWith("folder-") && props.modeContext === TREE_TYPE.APPLIED_CONTENT) {
      const id = props.treeId.split("-")[1];

      if (folders && folder_types) {
        const currentFolder = folders[id];
        const currentFolderType = folder_types[currentFolder.folder_type];

        if (currentFolderType.children_conditions && currentFolderType.children_conditions.length) {
          const slotLimitCondition = currentFolderType.children_conditions.find(c => c.type === 'slot_limit');
          if (slotLimitCondition) {
            const usedSlots = getFolderUsedSlots(
              currentFolder,
              Object.values(folder__folders),
              Object.values(folders),
              Object.values(folder_types)
            );

            setSlotsUsedMessage(`(${usedSlots}/${slotLimitCondition.value})`);
            setSlotsExceeded(usedSlots > slotLimitCondition.value)
          }
        }
      }
    }
  }, [folder__folders, folder_types]);

  useEffect(() => {
    checkShowArrow()
  }, [props]);




  useEffect(() => {
    setCheckedTree(isBeingHovered ? props.treeId : `${props.selectedTreeId}`);
  }, [isBeingHovered]);

  useEffect(() => {
    switch (props.modeContext) {
      case TREE_TYPE.APPLIED_CONTENT:
        if (folderResults && props.treeId.includes("folder-")) {
          const cleanId = props.treeId.replace("folder-", "");
          if (folderResults.includes(cleanId)) {
            setIsSearchResult(true);
          } else {
            setIsSearchResult(false);
          }
        }
        break;
      case TREE_TYPE.RESOURCE:
        if (props.treeId.includes("resource-")) {
          const cleanId = props.resource.toString();
          if (resourceResults.includes(cleanId)) {
            setIsSearchResult(true);
          } else {
            setIsSearchResult(false);
          }
        }
        break;
    }
  }, [folderResults, resourceResults]);

  useEffect(() => {
    setIsSelected(props.treeId === `${props.selectedTreeId}`);

    if (props.isContent) {
      setRenderedStyle({
        ...renderedStyle,
        color: PDG_COLORS.CONTENT_ICON_COLOR,
        fontWeight: "bold",
      });
      setTextColor(PDG_COLORS.CONTENT_ICON_COLOR);
      setIconColor(PDG_COLORS.CONTENT_ICON_COLOR);
      setBackgroundColor(PDG_COLORS.CONTENT_COLOR);
    }
  }, [props.selectedTreeId, props.treeId]);

  useEffect(() => {
    const newIconCount = countOnTrue([
      props.name === "Loading",
      projectFolder,
      addFolderOption,
      addFileOption,
      addResourceOption,
      deleteFolderOption,
      renderDataFolderFolder && props.csvDownload,
      deleteResourceOption,
      folderInProgress,
      cloneFolderOption
    ]);
    setIconCount(newIconCount);
    let ellipsisMargin;
    if (newIconCount > 0) {
      ellipsisMargin = newIconCount * 28 + 12;
    } else {
      ellipsisMargin = 16;
    }
    setEllipsisMargin(ellipsisMargin);
  }, [
    projectFolder,
    addFolderOption,
    addFileOption,
    addResourceOption,
    deleteFolderOption,
    renderDataFolderFolder,
    props.csvDownload,
    deleteResourceOption,
    folderInProgress,
  ]);

  useEffect(() => {
    if (isSelected) {
      if (props.isContent) {
        setBackgroundColor(PDG_COLORS.CONTENT_COLOR);
      } else {
        setBackgroundColor(PDG_COLORS.MD_BLUE);
      }
    } else {
      if (isBeingHovered || props.isOver) {
        setBackgroundColor(PDG_COLORS.XXLT_GRAY);
      } else if (isSearchResult) {
        setBackgroundColor(PDG_COLORS.XLT_BLUE);
      } else {
        setBackgroundColor(PDG_COLORS.XXLT_GRAY);
      }
    }
  }, [isSelected, isBeingHovered, isSearchResult, props.isOver, props.canDrop]);

  useEffect(() => {
    if (props.name && !props.canDrop && props.isDragging) {
      setMouseCursor("not-allowed");
      setCanActionDrop(false);
    } else {
      setMouseCursor("pointer");
      setCanActionDrop(true);
    }
  }, [props.canDrop, props.isDragging]);

  useEffect(() => {
    if (isSelected || isBeingHovered) {
      if (checkedTree.includes("project")) {
        const splittedTreeId = checkedTree.split("-");
        setSelectedItemId(Number(splittedTreeId[1]));
      }
      if (checkedTree.includes("company")) {
        const splitCompanyId = checkedTree.split("-");
        setSelectedCompanyId(Number(splitCompanyId[1]));
      }
      if (checkedTree.includes("folder")) {
        const checkedFolderId = Number(checkedTree.replace("folder-", ""));
        if (checkedFolderId) {
          setFolderId(checkedFolderId);
        }
      }
    }
  }, [
    isSelected,
    isBeingHovered,
    checkedTree,
    props.modeContext,
    acceptableFolderTypes,
  ]);

  useEffect(() => {
    if (props.isContent) {
      setRenderedStyle({
        ...renderedStyle,
        color: PDG_COLORS.CONTENT_ICON_COLOR,
        fontWeight: "bold",
      });
      setTextColor(PDG_COLORS.CONTENT_ICON_COLOR);
      setIconColor(PDG_COLORS.CONTENT_ICON_COLOR);
    } else {
      if (isSelected) {
        setRenderedStyle({
          ...renderedStyle,
          color: PDG_COLORS.WHITE,
          fontWeight: "bold",
        });
        setTextColor(PDG_COLORS.WHITE);
        setIconColor(PDG_COLORS.WHITE);
      } else {
        setTextColor(PDG_COLORS.LT_GRAY_BLUE);
        setIconColor(PDG_COLORS.MD_BLUE);
        setRenderedStyle({
          ...renderedStyle,
          color: PDG_COLORS.LT_GRAY_BLUE,
          fontWeight: "normal",
        });
      }
    }
  }, [isSelected]);

  const getFirstPartCheckedTree = () => {
    const splitCheckedTree = checkedTree.split("-");
    if (splitCheckedTree && splitCheckedTree.length > 0) {
      return splitCheckedTree[0];
    }
    return null;
  };

  const hasCloneOption = (saveOptions: any[]) => {
    return saveOptions?.length && !!saveOptions?.find((opt) => opt.action === 'clone_folder')
  }

  useEffect(() => {
    if (props && typeof props === 'object') {
      if (props.hasOwnProperty('folder_type') && props.folder_type && props.folder_type.hasOwnProperty('save_options') && hasCloneOption(props.folder_type.save_options)) {
        setCloneFolderOption(true)
      }
    }
    if (props.modeContext === TREE_MODE_RESOURCE) {
      const resourceFolder = resourcetree_folders[folderId];
      if (resourceFolder) {
        const typeTreeItem = getFirstPartCheckedTree();
        let canCreateFolders = false;
        if (typeTreeItem && typeTreeItem === "folder" || typeTreeItem === "company") {
          canCreateFolders = true;
        }
        setAddFolderOption(canCreateFolders);
      }
    } else {
      const folder = folders[folderId];
      const folderType: IFolderType = props.folderTypes[folder?.folder_type];
      if (folderType && folderType.hide_add_icon_in_tree) {
        setAddFolderOption(false);
        return;
      }

      if (folder && props.acceptableFolderTypes.length) {
        const preventAddChildren = get(
          props,
          "folderTypes[" + folder.folder_type + "].prevent_add_children",
          false
        );
        const parentHasUniqueFolderTypeInstances =
          checkParentHasUniqueFolderTypeInstances();
        if (!preventAddChildren && !parentHasUniqueFolderTypeInstances) {
          // TODO: bring back permission check
          let projectId = null;
          const folderFolder = Object.values(folder__folders).find(
            (x: IFolderFolder) => x.child_folder === folderId,
          );

          if (folderFolder) {
            projectId = folderFolder.project;
          }
          const canCreateFolders = hasPermission(
            grouped_permissions,
            "applied_content",
            "create",
            user,
            "project",
            projectId,
            user.company,
          );
          setAddFolderOption(canCreateFolders);
        } else {
          setAddFolderOption(false);
        }
      }
    }
  }, [folderId]);

  useEffect(() => {
    if (props.modeContext === TREE_MODE_RESOURCE) {
      const companyId = `company-${selectedCompanyId}`;
      let canCreateFolders = false;
      if (checkedTree === companyId) {
        const resourceFolder =
          getResourceDefaultFolderForAction(selectedCompanyId);
        if (resourceFolder) {
          const resourceFolderId = resourceFolder.id;
          // const project = Object.values(folder__folders).find(
          //     (x) => x.child_folder === resourceFolderId
          // ).project
          // TODO until confirmation everyone is allowed to create folders
          canCreateFolders = true;
          // const canCreateFolders = hasPermission(
          //     grouped_permissions,
          //     TREE_MODE_RESOURCE,
          //     "create",
          //     user,
          //     "project",
          //     project,
          //     user.company,
          // );
          setSelectedResourceFolderId(`folder-${resourceFolderId}`);
          setAddFolderOption(canCreateFolders);
        }
      }
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    let newIconConfig = props.itemIcons ? props.itemIcons[iconState] : null;
    if (!newIconConfig && props.defaultIcons) {
      newIconConfig = props.defaultIcons[`default_${iconState}`];
    }
    setIconConfig(newIconConfig);
  }, [props.itemIcons, iconState, props.defaultIcons, props.expanded]);

  useEffect(() => {
    setIconState(props.expanded ? "open" : "closed");
  }, [props.expanded]);

  useEffect(() => {
    if (folderMetas && props.treeId) {
      if (folderResults && props.treeId.includes("folder-")) {
        const cleanId = props.treeId.replace("folder-", "");
        const myFolder = folderMetas[cleanId];
        if (myFolder) {
          setFolderInProgress(
            myFolder.folder_status === MEDIA_PROGRESS_STATUS_PROCESSING
          );
        }
      }
    }
  }, [folderMetas]);

  const getBaseWidth = useCallback(() => {
    let iconsWidth = 0;
    if (projectFolder) iconsWidth += 24;
    if (addFolderOption) iconsWidth += 24;
    if (cloneFolderOption) iconsWidth += 24;
    if (addFileOption) iconsWidth += 24;
    if (deleteResourceOption) iconsWidth += 24;
    if (deleteFolderOption) iconsWidth += 24;
    if (renderDataFolderFolder && props.csvDownload) iconsWidth += 24;

    if (window.innerWidth <= 1280) {
      return 340 - iconsWidth;
    } else if (window.innerWidth <= 1366) {
      return 395 - iconsWidth
    } else if (window.innerWidth <= 1600) {
      return 420;
    } else if (window.innerWidth <= 1920) {
      return 510;
    } else {
      return 510;
    }
  }, [window.innerWidth]);

  const getResourceDefaultFolderForAction = (companyId) => {
    const resourcetree_foldersArr = arrayFromKeyedObject(resourcetree_folders);
    const rootFolders3 = resourcetree_foldersArr.find(
      (folder) =>
        folder.folder_type === "resource_default" &&
        folder.company === companyId
    );
    return rootFolders3;
  };

  const getArrowColor = () => {
    return !isSelected && isBeingHovered ? PDG_COLORS.LT_GRAY_BLUE : textColor;
  };

  const getColor = () => {
    let color = iconColor;
    if (props.showGray && props.showChildAcceptableIcon) {
      color = '#c39b13'
    } else if (props.showGray) {
      color = '#808080'
    }
    return color
  }


  const renderPrefixIcons = () => {
    return (
      <div style={{ flex: "0 1 0", color: getColor(), display: 'flex', opacity: isLowLevelItem(props.path, props.folder__folders, props.folders, props.folderTypes) ? 0.5 : 1 }}>
        {(isSelected || isBeingHovered) && isOpenable && props.expanded ? (
          <span style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: -4,
                left: -iconMargin * 3 - 14,
                paddingTop: 4,
                paddingRight: 10,
                paddingBottom: 4,
                paddingLeft: 10,
              }}
              onClick={(e) =>
                props.handleOnClick(e, props.treeId, "arrow-click", props.path)
              }
            >
              <FontAwesomeIcon
                className="fa"
                style={{
                  color: getArrowColor(),
                  cursor: "pointer",
                }}
                icon={["fas", "caret-down"]}
              />
            </span>
          </span>
        ) : null}
        {(isSelected || isBeingHovered) && isOpenable && !props.expanded ? (
          <span style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: -3,
                left: -iconMargin * 3 - 10,
                paddingTop: 2,
                paddingRight: 10,
                paddingBottom: 2,
                paddingLeft: 10,
              }}
              onClick={(e) =>
                props.handleOnClick(e, props.treeId, "arrow-click", props.path)
              }
            >
              {showCaret && (
                <FontAwesomeIcon
                  className="fa"
                  style={{
                    color: getArrowColor(),
                    cursor: "pointer",
                    marginRight: 4,
                  }}
                  icon={["fas", "caret-right"]}
                />
              )}
            </span>
          </span>
        ) : null}
        <span
          onClick={(e) =>
            props.handleOnClick(e, props.treeId, "icon-click", props.path)
          }
        >
          <TreeItemIcon
            config={iconConfig}
            isSelected={isSelected}
            context={"tree-item"}
          />
        </span>
      </div>
    );
  };

  const renderPrefixIconsDropNoAllow = () => {
    const itemIcons = {
      icon_type: "fontawesome",
      options: {
        class_name: "fa-lg",
        icon: ["far", "times-circle"],
      },
    };

    return (
      <div style={{ flex: "0 1 0", color: iconColor }}>
        <span>
          <TreeItemIcon
            config={itemIcons}
            isSelected={isSelected}
            context={"tree-item"}
          />
        </span>
      </div>
    );
  };

  const showCloneFolderModal = () => {
    dispatch(setClientOptions({ show_clone_modal: true, clone_folder_id: props.folder__folder_id }))
  }

  const goToContent = (e) => {
    props.handleOnClick(e, props.treeId, "go-to-content-click", props.path)
  }

  const addSubFolder = () => {
    if (props.modeContext === TREE_MODE_RESOURCE) {
      if (selectedResourceFolderId) {
        props.addSubFolder({ selectedTreeId: selectedResourceFolderId });
      } else {
        props.addSubFolder({ selectedTreeId: checkedTree });
      }
    } else {
      props.addSubFolder({ selectedTreeId: checkedTree, meta: { ...props.meta, folder__folder_id: props.folder__folder_id, path: props.path } });
    }
  };

  const deleteFolder = () => {
    props.deleteFolder(folderId, props.folder__folder_id, props.modeContext);
  };

  const renderRowIcons = () => {
    let isFolderInPresentations = false;
    if (props.modeContext === TREE_MODE_APPLIED_CONTENT) {
      isFolderInPresentations = isInPresentationFolder(props.path, props.folder__folders, props.folders, props.folderTypes, folderId);
    }
    return (
      <div style={{ flex: "1 0 0", marginBottom: 0, marginTop: 0 }}>
        {(props.name === "Loading" || props.inProgress || folderInProgress) && (
          <span>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={["fas", "spinner"]}
              spin
            />
          </span>
        )}
        {
          goToContentEnabled && (
            <Tooltip
              title="Go to Content"
            >
              <span
                style={{
                  color: iconColor,
                  ...iconButtonStyle,
                }}
                onClick={goToContent}
              >
                <FontAwesomeIcon
                  className="fa"
                  style={{ cursor: "pointer", color: iconColor }}
                  icon={['fas', 'arrow-alt-square-up']}
                />
              </span>
            </Tooltip>
          )
        }
        {
          projectFolder &&
          hasPermission(
            grouped_permissions,
            "csv",
            "create",
            user,
            "company",
            user.company,
            user.company
          ) && (
          <Tooltip title="Download/Upload CSV Data">
            <span
              style={{ marginRight: iconMargin, color: iconColor }}
              onClick={() => props.openCsvModal({ id: selectedItemId, type: "project" })}
            >
              <FontAwesomeIcon
                className="fa"
                style={{ color: iconColor, cursor: "pointer" }}
                icon={["fas", "file-csv"]}
              />
            </span>
          </Tooltip>
        )}
        {
          cloneFolderOption && !isFolderInPresentations && (
            <Tooltip
              title="Copy"
            >
              <span
                style={{
                  color: iconColor,
                  ...iconButtonStyle,
                }}
                onClick={showCloneFolderModal}
              >
                <FontAwesomeIcon
                  className="fa"
                  style={{ cursor: "pointer", color: iconColor }}
                  icon={['fas', 'copy']}
                />
              </span>
            </Tooltip>
          )
        }
        {addFolderOption && !isFolderInPresentations && (
          <Tooltip
            title={
              props.modeContext === TREE_MODE_RESOURCE
                ? "Add a Folder"
                : "Add an Item"
            }
          >
            <span
              style={{
                color: iconColor,
                ...iconButtonStyle,
              }}
              onClick={addSubFolder}
            >
              <FontAwesomeIcon
                className="fa"
                style={{ cursor: "pointer", color: iconColor }}
                icon={["fas", "folder-plus"]}
              />
            </span>
          </Tooltip>
        )}
        {addFileOption && (
          <Tooltip title="Add a Resource">
            <span
              style={{
                ...iconButtonStyle,
                color: iconColor,
              }}
              onClick={() => props.addResource(props.selectedTreeId)}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                className="fa"
                icon={["fas", "file-plus"]}
              />
            </span>
          </Tooltip>
        )}
        {deleteResourceOption && (
          <Tooltip title="Delete this Resource">
            <span
              style={{
                ...iconButtonStyle,
                color: iconColor,
              }}
              onClick={() => props.deleteResource(props.selectedTreeId)}
            >
              {/* <span className="fa" style={{marginBottom: 0}}>
                      <i className="fas fa-file"></i>
                    </span> */}
              <FontAwesomeIcon
                className="fa"
                style={{ color: iconColor, cursor: "pointer" }}
                icon={["fas", "trash"]}
              />
            </span>
          </Tooltip>
        )}
        {deleteFolderOption && (
          <Tooltip title="Delete this Item">
            <span
              style={{
                color: iconColor,
                ...iconButtonStyle,
              }}
              onClick={deleteFolder}
            >
              <span className="fa">
                <i className="fas fa-trash"></i>
              </span>
            </span>
          </Tooltip>
        )}
        {
          renderDataFolderFolder &&
          props.csvDownload &&
          !isFolderInPresentations &&
          hasPermission(
            grouped_permissions,
            "csv",
            "create",
            user,
            "company",
            user.company,
            user.company
          ) &&
          (
          <Tooltip title="Download/Upload CSV Data">
            <span
              style={{
                color: iconColor,
                ...iconButtonStyle,
              }}
              onClick={() =>
                props.openCsvModal({ id: props.folder__folder_id, type: "folder" })
              }
            >
              <FontAwesomeIcon
                className="fa"
                style={{ color: iconColor, cursor: "pointer" }}
                icon={["fas", "file-csv"]}
              />
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderRowIconsOnlyForInProgress = () => {
    return (
      <div style={{ flex: "1 0 0", marginBottom: 0, marginTop: 0 }}>
        {(props.name === "Loading" || props.inProgress || folderInProgress) && (
          <span>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={["fas", "spinner"]}
              spin
            />
          </span>
        )}
      </div>
    );
  };

  if (props.spacer) {
    return (
      <>
        {props.isOver ? (
          <div
            style={{
              position: "absolute",
              top: renderedStyle.top,
              width: "100%",
              height: 6,
              backgroundColor: PDG_COLORS.MD_BLUE,
            }}
          ></div>
        ) : null}
        <div
          style={renderedStyle}
          className="tree-item"
          onMouseOver={() => setIsBeingHovered(true)}
          onMouseLeave={() => setIsBeingHovered(false)}
        ></div>
      </>
    );
  } else {
    let spanWidth = 0;
    let useToolTip = false;
    if (spanNameRef && spanNameRef.current) {
      spanWidth = spanNameRef.current.offsetWidth;
      if (spanWidth > (getBaseWidth() - ellipsisMargin - props.style.paddingLeft)) {
        useToolTip = true;
      }
    }

    return (
      <>
        <div
          onClick={(e) =>
            props.handleOnClick(e, props.treeId, "name-click", props.path)
          }
          style={{
            ...renderedStyle,
            paddingLeft: props.style.paddingLeft,
            backgroundColor: backgroundColor,
            height: 40,
            cursor: mouseCursor,
            color: textColor,
          }}
          onMouseOver={() => setIsBeingHovered(true)}
          onMouseLeave={() => setIsBeingHovered(false)}
        >
          <Spaner width="md" />
          {canActionDrop ? renderPrefixIcons() : renderPrefixIconsDropNoAllow()}

          {/* Dummy Span for measuring width only */}
          <span
            ref={spanNameRef}
            onClick={(e) =>
              props.handleOnClick(e, props.treeId, "name-click", props.path)
            }
            style={{
              opacity: 0,
              position: "absolute",
              left: props.style.paddingLeft + 37,
              fontSize: 15.4,
              marginLeft: TREE_ICON_TEXT_MARGIN,
              overflowX: "hidden",
              overflowY: "visible",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: 40,
              marginTop: 0,
            }}
          >
            <TextWithHighlights
              text={props.name}
              highlight={props.searchText}
              highlightStyle={{ backgroundColor: PDG_COLORS.MD_WARNING }}
            />
          </span>

          {useToolTip ? (
            <Tooltip title={props.name}>
              <>
                <span
                  onClick={(e) =>
                    props.handleOnClick(e, props.treeId, "name-click", props.path)
                  }
                  style={{
                    position: "absolute",
                    left: props.style.paddingLeft + 37,
                    fontSize: 15.4,
                    marginLeft: TREE_ICON_TEXT_MARGIN,
                    overflowX: "hidden",
                    overflowY: "visible",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    height: 40,
                    marginTop: 0,
                    opacity: isLowLevelItem(props.path, props.folder__folders, props.folders, props.folderTypes) ? 0.5 : 1,
                    width: getBaseWidth() - ellipsisMargin - props.style.paddingLeft,
                  }}
                >
                  <TextWithHighlights
                    text={(
                      <>
                        {props.name}
                        {showSlots && (
                          <>
                            &nbsp;
                            <span style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold' }}>({slots})</span>
                          </>
                        )}

                        {slotsUsedMessage && slotsUsedMessage.length && (
                          <>
                            &nbsp;
                            <span style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold', color: slotsExceeded ? '#FF0000' : null }}>{slotsUsedMessage}</span>
                          </>
                        )}
                      </>
                    )}
                    highlight={props.searchText}
                    highlightStyle={{ backgroundColor: PDG_COLORS.MD_WARNING }}
                  />
                </span>
                {/* {showSlots && (
                  <>
                    &nbsp;
                    <span style={{ textDecoration: 'italic' }}>({slots})</span>
                  </>
                )} */}
              </>
            </Tooltip>
          ) : (
            <>
              <span
                onClick={(e) =>
                  props.handleOnClick(e, props.treeId, "name-click", props.path)
                }
                style={{
                  opacity: isLowLevelItem(props.path, props.folder__folders, props.folders, props.folderTypes) ? 0.5 : 1,
                  position: "absolute",
                  left: props.style.paddingLeft + 37,
                  fontSize: 15.4,
                  marginLeft: TREE_ICON_TEXT_MARGIN,
                  overflowX: "hidden",
                  overflowY: "visible",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  height: 40,
                  marginTop: 0,
                  width: getBaseWidth() - ellipsisMargin - props.style.paddingLeft,
                  color: props.showGray !== undefined || props.showChildAcceptableIcon ? getColor() : ''
                }}
              >
                <TextWithHighlights
                  text={(
                    <>
                      {props.name}
                      {showSlots && (
                        <>
                          &nbsp;
                          <span style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold' }}>({slots})</span>
                        </>
                      )}

                      {slotsUsedMessage && slotsUsedMessage.length && (
                        <>
                          &nbsp;
                          <span style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold', color: slotsExceeded ? '#FF0000' : null }}>{slotsUsedMessage}</span>
                        </>
                      )}
                    </>
                  )}
                  highlight={props.searchText}
                  highlightStyle={{ backgroundColor: PDG_COLORS.MD_WARNING }}
                />
              </span>
              {/* {showSlots && (
                <>
                  &nbsp;
                  <span style={{ textDecoration: 'italic' }}>({slots})</span>
                </>
              )} */}
            </>
          )}


          {/* Space for icons */}
          <span
            style={{
              position: "absolute",
              top: 0,
              right: 10,
              textAlign: "right",
              maxWidth: iconCount * 32,
              height: 40,
            }}
          >
            {
              props.showGray === undefined && props.showChildAcceptableIcon === undefined && (
                isBeingHovered || isSelected
                  ? renderRowIcons()
                  : renderRowIconsOnlyForInProgress()
              )
            }

          </span>
        </div>
      </>
    );
  }
};

export default TreeItem;
