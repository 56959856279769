import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import { coburgFonts, quillFontSizes, textStyleOptions } from "./coburg_settings";
import { wysiwyg } from "../standardized_fields";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const coburg_menu: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.coburg_menu,
  display_name: "Coburg Main Menu",
  acceptable_folder_types: [
    FOLDER_TYPE.coburg_stories_overview,
    FOLDER_TYPE.coburg_stories,
    FOLDER_TYPE.coburg_story_group,
    FOLDER_TYPE.coburg_donor_list_group,
    FOLDER_TYPE.coburg_template_001,
    FOLDER_TYPE.coburg_template_002,
    FOLDER_TYPE.coburg_template_003,
    FOLDER_TYPE.coburg_template_004
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_add_children: true,
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: false,
      order: 0,
      tab: "general"
    },
    defaults: {
      title: "Defaults",
      default_expanded: false,
      order: 0,
      tab: "defaults"
    },
    menu_title: {
      title: "Menu Title Settings",
      default_expanded: false,
      order: 0,
      accordion: true,
      tab: "advanced"
    },
    menu_settings: {
      title: "Menu Settings",
      default_expanded: false,
      order: 0,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    heading: {
      title: "Menu Heading",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "ops", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
            size: "40px"
          },
          ops: [{ insert: "Menu" }],
          dark_mode: true
        },
        textarea: "Menu"
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "general"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "general"
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    menu_title_margin_top: {
      title: "Menu Title Margin Top",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_title"
    },
    menu_title_margin_left: {
      title: "Menu Title Margin Left",
      tooltip: "Unit is % of container width",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_title"
    },
    menu_title_margin_bottom: {
      title: "Menu Title Margin Bottom",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      default: 9,
      field_group: "menu_title"
    },
    menu_items: {
      type: "textstyle",
      title: "Menu Items",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "menu_settings"
    },
    menu_container_margin_top: {
      title: "Menu Container Margin Top",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_margin_left: {
      title: "Menu Container Margin Left",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_height: {
      title: "Menu Container Height",
      type: "integer",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 50,
      field_group: "menu_settings"
    },
    menu_container_width: {
      title: "Menu Container Width",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 80,
      field_group: "menu_settings"
    },
    menu_item_vertical_separation: {
      title: "Menu Item Vertical Separation",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 9,
      field_group: "menu_settings"
    }
  },
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.CoburgMenu,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.CoburgMenu
  }
};
