import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../utils/commonDataUtils";
import { richTextHasValue } from "../../utils/generalUtils";
import { get, cloneDeep } from "lodash";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import { COMPONENT_MODE_CONTEXT } from "../../../shared-global/enums/config-enums";
import Slide1911 from "./Slide1911";
import { createUseStyles } from "react-jss";
import { getFileDynamicByRank, getFileTypeFromResources } from "../../utils/fileUtils";
import { IFolderType } from '../../../shared-global/interfaces/folder-type-interfaces';
import { MobilePreviewContext } from "../../Contexts";

const getMarginTop = ({ width, height, scaleFactor }) =>
  (height * 0.1) / scaleFactor;
const getLeft = ({ width, scaleFactor }) => (width - width / scaleFactor) / 2;

const styles = {
  mobileTheme: {
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    "& .ql-editor br": {
      content: '""',
      display: "block"
    },
    "& .ql-editor br::after": {
      content: '" "',
      fontSize: "10px"
    },
    "& .button-wrapper": {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      bottom: 0,
      background: "white",
      "& a": {
        marginBottom: 16,
        marginTop: 16,
        background: (props) => props.buttonBackgroundColor,
        padding: 16,
        borderRadius: 10,
        color: (props) => props.buttonTextStyle?.color ?? "#FFF",
        fontFamily: (props) => props.buttonTextStyle?.font ?? "Roboto",
        lineHeight: (props) => props.buttonTextStyle?.lineHeight ?? 1,
        textAlign: (props) => props.buttonTextStyle?.alignment ?? "left",
        boxShadow: (props) => `3px 3px 7px 0px ${props.buttonBackgroundColor}`
      }
    },
    '& video::-webkit-media-controls-fullscreen-button': {
      display: "block",
    },
    "@media (max-width: 600px)": {
      "& .ql-editor": {
        fontSize: "1px !important"
      },
      marginTop: 16,
      "& .logo-wrapper": {
        width: "100%",
        height: 80,
        position: "relative",
        "& div": {
          width: "100% !important",
          height: "80px !important"
        }
      },
      "& .header-wrapper": {
        width: "100%",
        marginTop: 8
      },
      "& .media-wrapper": {
        position: "relative !important",
        width: "100%",
        marginTop: 8,
        "& img": {
          width: "100%",
        },
        "& div": {
          position: "relative !important",
          display: "inherit !important",
          width: "100%",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          }
        },
        "& div.video-loader": {
          display: "none"
        },
        "& .loader-btn-wrapper": {
          display: "none"
        }
      },
      "& .body-wrapper": {
        marginTop: 16,
        position: "relative",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 96
      }
    },
    "@media (min-width: 600px)": {
      "& .ql-editor": {
        fontSize: "1px !important"
      },
      marginTop: 16,
      "& .logo-wrapper": {
        width: "100%",
        height: 80,
        position: "relative",
        "& div": {
          width: "100% !important",
          height: "80px !important"
        }
      },
      "& .header-wrapper": {
        width: "100%",
        marginTop: 8
      },
      "& .media-wrapper": {
        position: "relative !important",
        width: "100%",
        marginTop: 8,
        textAlign: " center",
        "& img": {
          width: "80%",
        },
        "& div": {
          position: "relative",
          display: "inherit !important",
          width: "100%",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          }
        },
        "& div.video-loader": {
          position: "absolute !important",
          width: "100%",
          display: "flex !important",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          },
          "& .play-button": {
            width: "25%"
          }
        }
      },
      "& .body-wrapper": {
        marginTop: 16,
        position: "relative",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 64
      }
    },
    "@media (min-width: 768px)": {
      "& .ql-editor": {
        fontSize: "1.5px !important",
        "& br": {
          fontSize: 10
        }
      },
      marginTop: 16,
      "& .logo-wrapper": {
        width: "100%",
        height: 100,
        position: "relative",
        "& div": {
          width: "100% !important",
          height: "100px !important"
        }
      },
      "& .header-wrapper": {
        width: "100%",
        marginTop: 16
      },
      "& .media-wrapper": {
        position: "relative !important",
        width: "100%",
        marginTop: 8,
        display: "flex",
        justifyContent: "center",
        textAlign: " center",
        "& img": {
          width: "80%",
        },
        "& div": {
          position: "relative",
          display: "inherit !important",
          width: "720px",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "720px !important",
            top: "0px !important",
            left: "0px !important"
          }
        },
        "& div.video-loader": {
          position: "absolute !important",
          width: "100%",
          display: "flex !important",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          },
          "& .play-button": {
            width: "25%"
          }
        }
      },
      "& .body-wrapper": {
        marginTop: 16,
        position: "relative",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 96
      },
      "& .button-wrapper": {
        fontSize: 16
      }
    },
    "@media (min-width: 992px)": {
      "& .ql-editor": {
        fontSize: "1.5px !important"
      },
      marginTop: 48,
      "& .logo-wrapper": {
        width: "100%",
        height: 137,
        position: "relative",
        "& div": {
          width: "100% !important",
          height: "137px !important"
        }
      },
      "& .header-wrapper": {
        width: "100%",
        marginTop: 16
      },
      "& .media-wrapper": {
        position: "relative",
        width: "100%",
        marginTop: 8,
        display: "flex",
        justifyContent: "center",
        textAlign: " center",
        "& img": {
          width: "70%",
        },
        "& div": {
          position: "relative !important",
          display: "inherit !important",
          width: "900px",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "900px !important",
            top: "0px !important",
            left: "0px !important"
          }
        },
        "& div.video-loader": {
          position: "absolute !important",
          width: "100%",
          display: "flex !important",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          },
          "& .play-button": {
            width: "25%"
          }
        }
      },
      "& .body-wrapper": {
        marginTop: 16,
        position: "relative",
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: 144
      },
      "& .button-wrapper": {
        fontSize: 25
      }
    },
    "@media (min-width: 1200px)": {
      "& .ql-editor": {
        fontSize: "1.25px !important"
      },
      marginTop: 16,
      "& .logo-wrapper": {
        width: "100%",
        height: 137,
        position: "relative",
        "& div": {
          width: "100% !important",
          height: "137px !important"
        }
      },
      "& .header-wrapper": {
        width: "100%",
        marginTop: 16
      },
      "& .media-wrapper": {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: 16,
        textAlign: " center",
        "& img": {
          width: "40%",
        },
        "& div": {
          position: "relative !important",
          display: "inherit !important",
          width: "100%",
          maxWidth: 940,
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            maxWidth: 940,
            top: "0px !important",
            left: "0px !important"
          }
        },
        "& div.video-loader": {
          position: "absolute !important",
          width: "100%",
          display: "flex !important",
          "& video": {
            position: "relative !important",
            height: "inherit !important",
            width: "100% !important",
            top: "0px !important",
            left: "0px !important"
          },
          "& .play-button": {
            width: "25%"
          }
        }
      },
      "& .body-wrapper": {
        width: "100%",
        marginTop: 16,
        position: "relative",
        paddingBottom: 128,
        display: "flex",
        justifyContent: "center",
        "& div": {
          width: 800
        }
      },
      "& .button-wrapper": {
        fontSize: 16
      }
    }
  },
  previewMobileTheme2: (props) => {
    const resolution = props.resolution;
    const width = resolution.width - 2;
    const nativeScaledFactor = (props.width / width) - 0.2;

    const orientation = props.width / props.height > 1 ? "horizontal" : "vertical";
    const buttonBottom = orientation === "horizontal" ? ((props.refHeight - props.height) / 2) - 2 : 25;
    
    let defaultStyle = {
      // transform: `scale(${props.scaleFactor})`,
      position: 'relative',
      "& .ql-editor br": {
        content: '""',
        display: "block"
      },
      "& .ql-editor br::after": {
        content: '" "',
        fontSize: "10px"
      },
      "& .button-wrapper": {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        width: width,
        bottom: buttonBottom,
        background: "white",
        "& a": {
          marginBottom: 16 * nativeScaledFactor,
          marginTop: 16 * nativeScaledFactor,
          background: props.buttonBackgroundColor ?? "#FFF",
          padding: 16 * nativeScaledFactor,
          borderRadius: 10 * nativeScaledFactor,
          color: props.buttonTextStyle?.color ?? "#FFF",
          fontFamily: props.buttonTextStyle?.font ?? "Roboto",
          lineHeight: props.buttonTextStyle?.lineHeight ?? 1,
          textAlign: props.buttonTextStyle?.alignment ?? "left",
          boxShadow: `3px 3px 7px 0px ${props.buttonBackgroundColor}`
        }
      }
    }

    let resStyle = {};
    switch (true) {
      case width >= 1200:
        resStyle = {
          "& .ql-editor": {
            fontSize: `${1.25 * nativeScaledFactor}px !important`
          },
          marginTop: 16 * nativeScaledFactor,
          "& .logo-wrapper": {
            width: "100%",
            height: `${137 * nativeScaledFactor}px !important`,
            position: "relative",
            "& div": {
              width: "100% !important",
              height: `${137 * nativeScaledFactor}px !important`
            }
          },
          "& .header-wrapper": {
            width: "100%",
            marginTop: 16 * nativeScaledFactor
          },
          "& .media-wrapper": {
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 16 * nativeScaledFactor,
            textAlign: " center",
            "& img": {
              width: "40%",
            },
            "& div": {
              position: "relative !important",
              display: "inherit !important",
              width: "100%",
              maxWidth: 940 * nativeScaledFactor,
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                maxWidth: 940 * nativeScaledFactor,
                top: "0px !important",
                left: "0px !important"
              }
            },
            "& div.video-loader": {
              position: "absolute !important",
              width: "100%",
              display: "flex !important",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              },
              "& .play-button": {
                width: "25%"
              }
            }
          },
          "& .body-wrapper": {
            width: "100%",
            marginTop: 16 * nativeScaledFactor,
            position: "relative",
            paddingBottom: 128 * nativeScaledFactor,
            display: "flex",
            justifyContent: "center",
            "& div": {
              width: 800 * nativeScaledFactor
            }
          },
          // "& .button-wrapper": {
          //   fontSize: 16 * nativeScaledFactor
          // }
        }
        break;
      case width >= 992 && width < 1200:
        resStyle = {
          "& .ql-editor": {
            fontSize: `${1.5 * nativeScaledFactor}px !important`
          },
          marginTop: 48,
          "& .logo-wrapper": {
            width: "100%",
            height: `${137 * nativeScaledFactor}px !important`,
            position: "relative",
            "& div": {
              width: "100% !important",
              height: `${137 * nativeScaledFactor}px !important`
            }
          },
          "& .header-wrapper": {
            width: "100%",
            marginTop: 16 * nativeScaledFactor
          },
          "& .media-wrapper": {
            position: "relative",
            width: "100%",
            marginTop: 8 * nativeScaledFactor,
            display: "flex",
            justifyContent: "center",
            textAlign: " center",
            "& img": {
              width: "70%",
            },
            "& div": {
              position: "relative !important",
              display: "inherit !important",
              width: `${900 * nativeScaledFactor}px`,
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: `${900 * nativeScaledFactor}px !important`,
                top: "0px !important",
                left: "0px !important"
              }
            },
            "& div.video-loader": {
              position: "absolute !important",
              width: "100%",
              display: "flex !important",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              },
              "& .play-button": {
                width: "25%"
              }
            }
          },
          "& .body-wrapper": {
            marginTop: 16 * nativeScaledFactor,
            position: "relative",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingBottom: 144 * nativeScaledFactor
          },
          // "& .button-wrapper": {
          //   fontSize: 25 * nativeScaledFactor
          // }
        }
        break;
      case width >= 768 && width < 992:
        resStyle = {
          "& .ql-editor": {
            fontSize: `${1.5 * nativeScaledFactor}px !important`,
            "& br": {
              fontSize: 10 * nativeScaledFactor
            }
          },
          marginTop: 16 * nativeScaledFactor,
          "& .logo-wrapper": {
            width: "100%",
            height: `${100 * nativeScaledFactor}px !important`,
            position: "relative",
            "& div": {
              width: "100% !important",
              height: `${100 * nativeScaledFactor}px !important`,
            }
          },
          "& .header-wrapper": {
            width: "100%",
            marginTop: 16 * nativeScaledFactor
          },
          "& .media-wrapper": {
            position: "relative !important",
            width: "100%",
            marginTop: 8 * nativeScaledFactor,
            display: "flex",
            justifyContent: "center",
            textAlign: " center",
            "& img": {
              width: "80%",
            },
            "& div": {
              position: "relative",
              display: "inherit !important",
              width: `${720 * nativeScaledFactor}px`,
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: `${720 * nativeScaledFactor}px !important`,
                top: "0px !important",
                left: "0px !important"
              }
            },
            "& div.video-loader": {
              position: "absolute !important",
              width: "100%",
              display: "flex !important",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              },
              "& .play-button": {
                width: "25%"
              }
            }
          },
          "& .body-wrapper": {
            marginTop: 16 * nativeScaledFactor,
            position: "relative",
            paddingLeft: 16 * nativeScaledFactor,
            paddingRight: 16 * nativeScaledFactor,
            paddingBottom: 96 * nativeScaledFactor
          },
          "& .button-wrapper": {
            fontSize: 16 * nativeScaledFactor
          }
        }
        break;
      case width >= 600 && width < 768:
        resStyle = {
          "& .ql-editor": {
            fontSize: `${nativeScaledFactor}px !important`
          },
          marginTop: 16 * nativeScaledFactor,
          "& .logo-wrapper": {
            width: "100%",
            height: `${80 * nativeScaledFactor}px !important`,
            position: "relative",
            "& div": {
              width: "100% !important",
              height: `${80 * nativeScaledFactor}px !important`
            }
          },
          "& .header-wrapper": {
            width: "100%",
            marginTop: 8 * nativeScaledFactor
          },
          "& .media-wrapper": {
            position: "relative !important",
            width: "100%",
            marginTop: 8 * nativeScaledFactor,
            textAlign: " center",
            "& img": {
              width: "80%",
            },
            "& div": {
              position: "relative",
              display: "inherit !important",
              width: "100%",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              }
            },
            "& div.video-loader": {
              position: "absolute !important",
              width: "100%",
              display: "flex !important",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              },
              "& .play-button": {
                width: "25%"
              }
            }
          },
          "& .body-wrapper": {
            marginTop: 16 * nativeScaledFactor,
            position: "relative",
            paddingLeft: 16 * nativeScaledFactor,
            paddingRight: 16 * nativeScaledFactor,
            paddingBottom: 64 * nativeScaledFactor
          }
        }
        break;
      case width < 600:
        resStyle = {
          "& .ql-editor": {
            fontSize: `${nativeScaledFactor}px !important`
          },
          marginTop: 16 * nativeScaledFactor,
          "& .logo-wrapper": {
            width: "100%",
            height: `${80 * nativeScaledFactor}px !important`,
            position: "relative",
            "& div": {
              width: "100% !important",
              height: `${80 * nativeScaledFactor}px !important`,
            }
          },
          "& .header-wrapper": {
            width: "100%",
            marginTop: 8 * nativeScaledFactor
          },
          "& .media-wrapper": {
            position: "relative !important",
            width: "100%",
            marginTop: 8 * nativeScaledFactor,
            "& img": {
              width: "100%",
            },
            "& div": {
              position: "relative !important",
              display: "inherit !important",
              width: "100%",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              }
            },
            "& div.video-loader": {
              position: "absolute !important",
              width: "100%",
              display: "flex !important",
              "& video": {
                position: "relative !important",
                height: "inherit !important",
                width: "100% !important",
                top: "0px !important",
                left: "0px !important"
              },
              "& .play-button": {
                width: "25%"
              }
            }
          },
          "& .body-wrapper": {
            marginTop: 16 * nativeScaledFactor,
            position: "relative",
            paddingLeft: 16 * nativeScaledFactor,
            paddingRight: 16 * nativeScaledFactor,
            paddingBottom: 96 * nativeScaledFactor
          }
        }
        break;
    }

    return { ...resStyle, ...defaultStyle };
  },
};

const MobileTemplate: React.FC<IDisplayComponentProps> = (props) => {
  const [headerWrapperHeight, setHeaderWrapperHeight] = useState(0);
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const previewContext = useContext(MobilePreviewContext);
  
  const scaleFactor = useMemo(() => {
    return previewContext?.data?.componentSpecificData?.scale_factor ??
      get(props, "data.componentSpecificData.scale_factor", 1);
  }, [props.data]);

  const resolution = useMemo(() => {
    return previewContext?.data?.componentSpecificData?.resolution ??
      get(props, "data.componentSpecificData.resolution", {});
  }, [props.data]);

  const scaledWidth = props.containerWidth / scaleFactor;
  const scaledHeight = props.containerHeight / scaleFactor;

  const [baseFolder, setBaseFolder] = useState<IFolder>(null);
  const [headerConverter, setHeaderConverter] = useState(null);
  const [bodyConverter, setBodyConverter] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(
    get(props.data.persistent_settings, "show_placeholder", false)
  );
  const [baseFolderType, setBaseFolderType] = useState<IFolderType>(null);

  const getFileDynamicCallback = useCallback((slotNames, preset) => {
    return getFileDynamicByRank(
      props.data.index || 0,
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      slotNames,
      props.data.base_folder,
      preset,
      props.webview_signedurls
    );
  }, [
    props.data.index,
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder,
    props.webview_signedurls,
  ]);

  const getFileTypeFromResourcesCallback = (slotNames) => {
    return getFileTypeFromResources(
      props.data.resources,
      props.data.resource__folders,
      slotNames,
      props.data.base_folder
    );
  };

  let buttonHeight = 0;
  if (buttonRef.current) {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    buttonHeight = buttonRect.height;
  }
  
  const propsStyles = {
    buttonTextStyle: baseFolder?.fields.button_text_style ?? null,
    buttonBackgroundColor: baseFolder?.fields.button_background_color ?? null,
    headerWrapperHeight,
    width: previewContext.data?.containerWidth ?? props.containerWidth,
    height: previewContext.data?.containerHeight ?? props.containerHeight,
    scaleFactor,
    resolution,
    scaledHeight,
    scaledWidth,
    refWidth: props.refWidth,
    refHeight: props.refHeight,
    buttonHeight
  };

  const useStyles = createUseStyles(styles);

  const classes = useStyles(propsStyles);

  const isTextRichEmpty = (text) => {
    if (text && text !== "<p><br/></p>") {
      return false;
    } else {
      return true;
    }
  };

  const getHeaderLineOrPlaceholder = (convertor, type) => {
    let html = convertor ? convertor.convert() : "";

    if (
      isTextRichEmpty(html) &&
      props.mode === COMPONENT_MODE_CONTEXT.PREVIEW
    ) {
      html = getPlaceholder(type);
    }

    return html;
  };

  const getPlaceholder = (placeholder) => {
    if (!showPlaceholder) {
      return "";
    }

    switch (placeholder) {
      case "headline":
        return `<p><strong class=\"ql-font-robotoslabregular ql-size-40em\">${get(
          baseFolderType,
          "fields[headline].title",
          ""
        )}</strong></p>`;
      case "subhead":
        return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(
          baseFolderType,
          "fields[subhead].title",
          ""
        )}</strong></p>`;
      case "body":
        return `<p><strong class=\"ql-font-roboto ql-size-21em\">${get(
          baseFolderType,
          "fields[body].title",
          ""
        )}</strong></p>`;
    }
  };

  const updateHeaderWrapperHeight = () => {
    setTimeout(() => {
      if (headerWrapperRef.current) {
        const headerWrapperClientHeight = headerWrapperRef.current.clientHeight;
        setHeaderWrapperHeight(headerWrapperClientHeight);
      } else {
        updateHeaderWrapperHeight();
      }
    }, 100);
  };

  // 1st Effect
  useEffect(() => {
    if (props.mode === COMPONENT_MODE_CONTEXT.LOCAL) {
      document.body.style.overflow = "auto";
    }
    const foundBaseFolder = getBaseFolder(props.data);
    setBaseFolder(foundBaseFolder);
    const foundBaseFolderType = getBaseFolderType(
      props.data,
      get(foundBaseFolder, "folder_type", {})
    );
    setBaseFolderType(foundBaseFolderType);
  }, []);

  useEffect(() => {
    let headlineOps = cloneDeep(get(baseFolder, "fields.header_text.ops", []));
    let bodyOps = cloneDeep(get(baseFolder, "fields.body_text.ops", []));

    const headlineFontSizeUnit = get(baseFolderType, 'fields.header_text.settings.font_size_unit', 'em');
    const bodyFontSizeUnit = get(baseFolderType, 'fields.body_text.settings.font_size_unit', 'em');

    if (headlineFontSizeUnit === "px") {
      headlineOps = headlineOps.map(op => {
        if (op.attributes && op.attributes.size) {
          op.attributes.size = op.attributes.size.replace("px", "em");
        }
        return op;
      });
    }

    if (bodyFontSizeUnit === "px") {
      bodyOps = bodyOps.map(op => {
        if (op.attributes && op.attributes.size) {
          op.attributes.size = op.attributes.size.replace("px", "em");
        }
        return op;
      });
    }

    const myHeadlineConverter = new QuillDeltaToHtmlConverter(
      headlineOps,
      { multiLineParagraph: false }
    );
    const myBodyConverter = new QuillDeltaToHtmlConverter(
      bodyOps,
      { multiLineParagraph: false }
    );
    setBodyConverter(myBodyConverter);
    setHeaderConverter(myHeadlineConverter);
  }, [baseFolder]);

  useEffect(() => {
    updateHeaderWrapperHeight();
  }, [props.data]);

  const header = getHeaderLineOrPlaceholder(headerConverter, "header_text");
  const body = getHeaderLineOrPlaceholder(bodyConverter, "body_text");

  const preset = props.mode === "preview" ? "preview" : null;
  const mediaFile = getFileDynamicCallback(['media'], preset);
  const mediaFileType = getFileTypeFromResourcesCallback(['media']);

  return (
    <div
      className={
        props.mode === COMPONENT_MODE_CONTEXT.PREVIEW
          ? classes.previewMobileTheme2
          : classes.mobileTheme
      }
    >
      {/* Logo */}
      <div className="logo-wrapper">
        <Slide1911
          {...props}
          containerWidth={scaledWidth}
          containerHeight={scaledHeight * 0.1}
          data={{
            ...props.data,
            slotNames: ["logo"]
          }}
        />
      </div>

      {/* Header */}
      <div className="header-wrapper" ref={headerWrapperRef}>
        {richTextHasValue(get(baseFolder, "fields.header_text.ops", [])) && (
          <div
            className="ql-editor"
            style={{
              padding: 0,
              overflowX: "hidden",
              overflowY: "hidden"
            }}
            dangerouslySetInnerHTML={{ __html: header }}
          />
        )}
      </div>

      {/* Media */}
      <div className="media-wrapper">
        {mediaFileType === "video" && (
          <Slide1911
          {...props}
          containerWidth={scaledWidth}
          containerHeight={scaledHeight * .25}
          data={{
            ...props.data,
            slotNames: ["media"],
            componentSpecificData: {
              scale_factor: scaleFactor,
              use_video_player: true
            }
          }}
        />
        )}
        {mediaFileType === "image" && (
          <img src={mediaFile} />
        )}        
      </div>

      {/* Body */}
      <div className="body-wrapper">
        {richTextHasValue(get(baseFolder, "fields.body_text.ops", [])) && (
          <div
            className="ql-editor"
            style={{
              fontSize:
                props.mode === COMPONENT_MODE_CONTEXT.PREVIEW
                  ? props.containerHeight / 1000
                  : 1,
              padding: 0,
              overflowX: "hidden",
              overflowY: "hidden"
            }}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
      </div>

      {/* Button */}
      <div ref={buttonRef} className="button-wrapper">
        <a className="button" href={baseFolder?.fields.button_link ?? ""}>
          {baseFolder?.fields.button_text}
        </a>
      </div>
    </div>
  );
};

export default MobileTemplate;
