import React, { Component } from 'react';
import { Row } from 'antd';
import FileInput from './FileInput';
import { getRandomStr } from '../../../shared/utils/generalUtils';
import _ from 'lodash';
import PropTypes from 'prop-types';

const DYNAMIC = 'dynamic';
// const FIXED = 'fixed';
const CHUNK_SIZE = 7168000;

class FixedFileInput extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    slots: PropTypes.array,
    limit: PropTypes.number,
    gallery: PropTypes.bool
  }

  static defaultProps = {
    type: DYNAMIC,
    limit: 999,
    gallery: false
  }

  constructor(props) {
    super(props);
    let items = [];

    if (this.props.type === DYNAMIC) {
      if (this.props.defaultValue) {
        let higherRank = 0;
        items = this.props.defaultValue.map(dv => {
          if (dv.rank > higherRank) {
            higherRank = dv.rank;
          }
          return { rank: dv.rank, identifier: getRandomStr() }
        }).sort((a, b) => {
          return a.rank - b.rank
        });
      }
    } else {
      items = _.cloneDeep(this.props.slots);
    }

    this.state = {
      items: items
    }
  }

  handleRemoveInput = (identifier) => {
    let allValues = _.cloneDeep(this.props.defaultValue);
    let value, valueIndex, itemIndex;
    let items = this.state.items;
    if (this.props.type === DYNAMIC) {
      itemIndex = items.findIndex(i => i.identifier === identifier);
      let item = items[itemIndex];
      valueIndex = allValues.findIndex(v => v.rank === item.rank);
    } else {
      valueIndex = allValues.findIndex(v => v.slotName === identifier);
    }

    value = allValues[valueIndex];
    if (value) {
      if (value.status === "loaded") {
        if (this.props.type === DYNAMIC) {
          items.splice(itemIndex, 1);
          items = items.sort((a, b) => {
            return a - b;
          }).map((item, i) => {
            item.rank = i;
            return item;
          });
          this.setState({ items });
        }
        allValues.splice(valueIndex, 1);
        allValues = allValues.sort((a, b) => {
          return a - b;
        }).map((value, j) => {
          value.rank = j;
          return value;
        });
      } else {
        value.status = "removed";
      }
    } else {
      if (this.props.type === DYNAMIC) {
        items.splice(itemIndex, 1);
        items = items.sort((a, b) => {
          return a - b;
        }).map((item, i) => {
          item.rank = i;
          return item;
        });
        allValues = allValues.sort((a, b) => {
          return a - b;
        }).map((value, j) => {
          value.rank = j;
          return value;
        });

        this.setState({ items });
      }
    }
    this.props.onChange(this.props.fieldName, allValues);
  }

  handleOpenGallery = () => {
    this.setState({
      modalGalleryVisible: true
    });
  }

  onFieldChange = async (identifier, key, value, type = "new") => {
    // console.log("ON field Change", identifier, key, value, type);
    let fileFieldValue, allValues, rank;
    if (this.props.type === DYNAMIC) {
      let item = this.state.items.find(i => i.identifier === identifier);
      rank = item.rank;
      allValues = this.props.defaultValue;
      fileFieldValue = allValues.find(dv => dv.rank === item.rank);
    } else {
      allValues = this.props.defaultValue;
      fileFieldValue = this.props.defaultValue.find(dv => dv.slotName === identifier);
    }

    if (type === "existing") {
      if (fileFieldValue) {
        for (let field in fileFieldValue) {
          delete fileFieldValue[field];
        }
        fileFieldValue.id = value.id;
        fileFieldValue.fieldName = this.props.fieldName;
        fileFieldValue.fileName = value.name;
        fileFieldValue.originalName = value.name;
        fileFieldValue.modifiedName = value.name;
        fileFieldValue.thumb = value.thumbUrl ? value.thumbUrl : value.url;
        fileFieldValue.url = value.url;
        fileFieldValue.status = "loaded";
        fileFieldValue.type = "resource";
      } else {
        fileFieldValue = {
          id: value.id,
          fieldName: this.props.fieldName,
          fileName: value.name,
          originalName: value.name,
          modifiedName: value.name,
          thumb: value.thumbUrl ? value.thumbUrl : value.url,
          url: value.url,
          status: "loaded",
          type: "resource"
        }

        if (allValues === null || allValues === undefined) {
          allValues = [fileFieldValue];
        } else {
          allValues.push(fileFieldValue);
        }
      }
    } else {
      if (fileFieldValue) {
        if (value instanceof File) {
          let chunks = [];
          for (let start = 0; start < value.size; start += CHUNK_SIZE) {
            let end = start + CHUNK_SIZE;
            if (end > value.size) {
              end = value.size;
            }
            chunks.push(value.slice(start, end));
          }

          let size = await this.getFileSize(value);
          fileFieldValue.fieldName = this.props.fieldName;
          fileFieldValue.fileName = value.name;
          fileFieldValue.originalName = value.name;
          fileFieldValue.modifiedName = value.name;
          fileFieldValue.randomFileName = getRandomStr();
          fileFieldValue.status = "loaded";
          fileFieldValue.file = value;
          fileFieldValue.chunks = chunks;
          fileFieldValue.size = size;
          fileFieldValue.type = "file";
        } else {
          fileFieldValue[key] = value;
        }
      } else {
        let chunks = [];
        for (let start = 0; start < value.size; start += CHUNK_SIZE) {
          let end = start + CHUNK_SIZE;
          if (end > value.size) {
            end = value.size;
          }
          chunks.push(value.slice(start, end));
        }

        let size = await this.getFileSize(value);
        fileFieldValue = {
          fieldName: this.props.fieldName,
          fileName: value.name,
          originalName: value.name,
          modifiedName: value.name,
          randomFileName: getRandomStr(),
          status: "loaded",
          file: value,
          size: size,
          chunks: chunks,
          type: "file"
        }
        if (allValues === null || allValues === undefined) {
          allValues = [fileFieldValue];
        } else {
          allValues.push(fileFieldValue);
        }
      }
    }

    if (this.props.type === "fixed") {
      fileFieldValue.slotName = identifier;
    }
    if (this.props.type === "dynamic") {
      fileFieldValue.rank = rank;
    }

    this.props.onChange(this.props.fieldName, allValues);
  }

  getFileSize = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = e => {
        if (!e) {
          return reject();
        }
        return resolve(e.total);
      }
      reader.readAsDataURL(file);
    });
  }

  getFixedRenderedInputs = () => {
    const cropper = _.get(this.props, 'cropper', true);
    let renderedItems = this.state.items.map((item, i) => {
      let data = null;
      if (this.props.defaultValue && this.props.defaultValue.length > 0) {
        data  = this.props.defaultValue.find(i => item.name === i.slotName);
      }
      item.identifier = item.name;

      return (
        <React.Fragment key={item.name}>
          <FileInput
            disabled={this.props.disabled}
            key={item.name}
            data={data}
            item={item}
            title={item.title}
            onlyShowRemoveOnLoad={true}
            onRemove={this.handleRemoveInput}
            onChange={this.onFieldChange}
            gallery={this.props.gallery}
            cropper={cropper}
            acceptable={this.props.acceptable}
            showTitle={true}
            />
          <br/>
        </React.Fragment>
      );
    });

    return renderedItems;
  }

  render() {
    const renderedInputs = this.getFixedRenderedInputs();

    return (
      <React.Fragment>
        <Row>
          {renderedInputs}
        </Row>
      </React.Fragment>

    );
  }
}

export default FixedFileInput;
