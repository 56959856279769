import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { phoenix_root } from "./phoenix_root";
import { phoenix_displays } from "./phoenix_displays";
import { phoenix_display } from "./phoenix_display";
import { phoenix_content } from "./phoenix_content";
import { phoenix_presentations } from "./phoenix_presentations";
import { phoenix_presentation_3x3 } from "./phoenix_presentation_3x3";
import { phoenix_checkerboard } from "./phoenix_checkerboard";
import { phoenix_donor_list } from "./phoenix_donor_list";
import { phoenix_content_donor_seas } from "./phoenix_content_donor_seas";
import { phoenix_donor_list_group } from "./phoenix_donor_list_group";
import { phoenix_content_donor_lists } from "./phoenix_content_donor_lists";
import { phoenix_donor } from "./phoenix_donor";
import { phoenix_donor_sea } from "./phoenix_donor_sea";
import { phoenix_donor_sea_category } from "./phoenix_donor_sea_category";
import { phoenix_donor_sea_categories } from "./phoenix_donor_sea_categories";
import { phoenix_donor_sea_backgrounds } from "./phoenix_donor_sea_backgrounds";
import { phoenix_donor_sea_background } from "./phoenix_donor_sea_background";
import { phoenix_fullscreen } from "./phoenix_fullscreen";
import { phoenix_message } from "./phoenix_message";
import { phoenix_story } from "./phoenix_story";
import { phoenix_content_stories } from "./phoenix_content_stories";
import { phoenix_content_checkerboards } from "./phoenix_content_checkerboards";

export const phoenix_types: { [key: string]: IFolderType } = {
  phoenix_root,
  phoenix_content,
  phoenix_presentations,
  phoenix_presentation_3x3,
  phoenix_displays,
  phoenix_display,
  phoenix_checkerboard,
  phoenix_donor_list,
  phoenix_donor_sea,
  phoenix_fullscreen,
  phoenix_message,
  phoenix_story,
  phoenix_content_stories,
  phoenix_donor_list_group,
  phoenix_content_donor_lists,
  phoenix_content_donor_seas,
  phoenix_donor_sea_category,
  phoenix_donor,
  phoenix_donor_sea_categories,
  phoenix_donor_sea_backgrounds,
  phoenix_donor_sea_background,
  phoenix_content_checkerboards,
};
