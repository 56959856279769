import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { addResources } from "../../actions/resourceActions";
import { IState } from "../../interfaces/main-state.interface";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { imageMimeTypes } from "../../shared/utils/fileUtils";
import { getRandomStr } from "../../shared/utils/generalUtils";
import ContentUploader from "../form/input/ContentManager/ContentUploader";
import { getMd5FromFile } from "../form/input/DynamicFileInput/FileInputService";
import AlertNotification from "../layout/AlertNotification";
import ResourceUploaderThumbnailView from "./ResourceUploaderThumbnailView/ResourceUploaderThumbnailView";

interface IAddResourceModalProps {
  visible: boolean;
  parentFolder: IFolderFolder;
  onCloseModal: () => void;
}

const CHUNK_SIZE = 7168000;
const AddResourceModal: React.FC<IAddResourceModalProps> = (props) => {
  const [showUploaderState, setShowUploaderState] = useState(true);
  const [files, setFiles] = useState<File[]>([]);

  const { aws_instance, resources, user, folders, resource__folders } =
    useSelector((state: IState) => state.data);

  const client_options = useSelector((state: IState) => state.client_options);

  const dispatch = useDispatch();

  const uploadToS3 = async (
    file: File,
    name: string,
    progressCb?: (percentage: number) => void
  ) => {
    const s3 = new aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2",
    });
    let params = {
      Body: file,
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/temp`,
      Key: name,
    };
    try {
      await new Promise((resolve, reject) => {
        s3.putObject(params, function (err, data) {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }).on("httpUploadProgress", function (progress) {
          if (progressCb && progress.lengthComputable) {
            progressCb((progress.loaded / progress.total) * 100);
          }
        });
      });
      return true;
    } catch (e) {
      console.log("error uploading:", e);
      return false;
    }
  };

  const multipartUploadToS3 = async (
    file: File,
    chunks: Blob[],
    name: string,
    progressCb?: (percentage: number) => void
  ) => {
    const s3 = new aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2",
    });

    let multiPartResponseArray = [];
    let multipartResponse: { UploadId: number } = await new Promise(
      (resolve, reject) => {
        s3.createMultipartUpload(
          {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${name}`,
            ContentType: file.type,
          },
          function (err, data) {
            if (err) {
              return reject(err);
            }

            return resolve(data);
          }
        );
      }
    );

    let errors = 0;
    for (let j = 0; j < chunks.length; j++) {
      try {
        let uploadResponse: { ETag: string } = await new Promise(
          (resolve, reject) => {
            s3.uploadPart(
              {
                Body: chunks[j],
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${name}`,
                PartNumber: j + 1,
                UploadId: multipartResponse.UploadId,
              },
              function (err, data) {
                if (err) {
                  return reject(err);
                }
                return resolve(data);
              }
            ).on("httpUploadProgress", function (progress) {
              const percentage =
                ((progress.loaded / progress.total) * 100) / chunks.length +
                (100 / chunks.length) * j;
              if (progressCb && progress.lengthComputable) {
                progressCb(percentage);
              }
            });
          }
        );
        multiPartResponseArray.push({
          ETag: uploadResponse.ETag,
          PartNumber: j + 1,
        });
        errors = 0;
      } catch (e) {
        errors++;
        j--;
      }

      if (errors === 3) {
        await new Promise((resolve, reject) => {
          s3.abortMultipartUpload(
            {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${name}`,
              UploadId: multipartResponse.UploadId,
            },
            function () {
              return resolve(false);
            }
          );
        });
        break;
      }
    }

    await new Promise((resolve, reject) => {
      s3.completeMultipartUpload(
        {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${name}`,
          MultipartUpload: { Parts: multiPartResponseArray },
          UploadId: multipartResponse.UploadId,
        },
        function (err, data) {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }
      );
    });

    return true;
  };

  const getFileChunks = (file: File) => {
    const chunks = [];
    for (let start = 0; start < file.size; start += CHUNK_SIZE) {
      let end = start + CHUNK_SIZE;
      if (end > file.size) {
        end = file.size;
      }
      chunks.push(file.slice(start, end));
    }
    return chunks;
  };

  const onUploadProgress = (percentage, fileName, current, max) => {
    dispatch(
      setClientOptions({
        progress: { percentage, fileName, current, max },
      })
    );
  };

  const onSave = async () => {
    // Upload the files directly to AWS S3
    const resourcesToCreate = [];
    const randomFileNames = [];

    // Lock Screen with overlay
    dispatch(
      setClientOptions({ lock_screen: true, message_for_progress: null })
    );

    for (let index in files) {
      const file = files[index];
      const splittedName = file.name.split(".");
      const fileExtension = splittedName[splittedName.length - 1].toLowerCase();
      const randomFileName = `${getRandomStr()}.${fileExtension}`;
      const fileMd5 = await getMd5FromFile(file);
      dispatch(
        setClientOptions({
          progress: {
            percentage: 0,
            fileName: randomFileName,
            current: Number(index),
            max: files.length - 1,
          },
        })
      );
      if (file.size > CHUNK_SIZE) {
        const chunks = getFileChunks(file);
        await multipartUploadToS3(file, chunks, randomFileName, (percentage) =>
          onUploadProgress(
            percentage,
            file.name,
            Number(index),
            files.length - 1
          )
        );
      } else {
        await uploadToS3(file, randomFileName, (percentage) =>
          onUploadProgress(
            percentage,
            file.name,
            Number(index),
            files.length - 1
          )
        );
      }
      resourcesToCreate.push({
        name: randomFileName,
        originalName: file.name,
        modifiedName: file.name,
        size: file.size,
        extension: fileExtension,
        md5: fileMd5,
      });
      randomFileNames.push(randomFileName);
    }

    // Finalizing screen
    dispatch(setClientOptions({ message_for_progress: "Finalizing upload" }));

    // Create resources and generate crops.
    dispatch(
      addResources(resourcesToCreate, props.parentFolder.id, () => {
        dispatch(
          setClientOptions({ lock_screen: false, message_for_progress: null })
        );
        props.onCloseModal();
        setShowUploaderState(true);
        setFiles([]);
        AlertNotification(
          "success",
          "Files Upload",
          "Files has been uploaded successfully"
        );
      })
    );
  };

  const onAdd = (addedFiles, type = "new") => {
    setFiles([...files, ...addedFiles]);
    setShowUploaderState(false);
  };

  const onAddMore = () => {
    setShowUploaderState(true);
  };

  const onRemove = (fileIndex: number) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    if (newFiles.length === 0) {
      setShowUploaderState(true);
    }
    setFiles(newFiles);
  };

  const onCancel = () => {
    setShowUploaderState(true);
    setFiles([]);
    props.onCloseModal();
  };

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        visible={props.visible}
        onOk={onSave}
        onCancel={onCancel}
        okText={!showUploaderState ? "Upload" : "Ok"}
        okButtonProps={{
          style: {
            visibility: showUploaderState ? "hidden" : "visible",
            float: showUploaderState ? "left" : "inherit",
          },
        }}
        width={720}
        wrapClassName="modal_content_manager"
        destroyOnClose
      >
        <div className="unsaved-changes-modal_header">Upload Content</div>
        {showUploaderState && (
          <ContentUploader
            acceptable={[imageMimeTypes, "video/*"]}
            newMode="START"
            browseContentLibrary={false}
            aws_instance={aws_instance}
            resources={resources}
            user={user}
            folders={folders}
            resource__folders={resource__folders}
            client_options={client_options}
            setClientOptions={setClientOptions}
            cancelButton={false}
            onAdd={onAdd}
            onChange={() => {}}
            onClose={() => {}}
            goBack={() => {}}
          />
        )}

        {!showUploaderState && (
          <ResourceUploaderThumbnailView
            files={files}
            onRemove={onRemove}
            onAddMore={onAddMore}
          />
        )}
      </Modal>
    </>
  );
};

export default AddResourceModal;
