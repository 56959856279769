import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { eugene_root } from "./eugene_root";
import { eugene_stories } from "./eugene_stories";
import { eugene_template_001 } from "./eugene_template_001";
import { eugene_template_002 } from "./eugene_template_002";
import { eugene_template_003 } from "./eugene_template_003";
import { eugene_template_004 } from "./eugene_template_004";
import { eugene_template_005 } from "./eugene_template_005";
import { eugene_attract_loop } from "./eugene_attract_loop";
import { eugene_menu } from "./eugene_menu";
import { eugene_category_with_giving_level } from "./eugene_category_with_giving_level";
import { eugene_category_without_giving_level } from "./eugene_category_without_giving_level";
import { eugene_slide } from "./eugene_slide";
import { eugene_search } from "./eugene_search";
import { eugene_donor_lists } from "./eugene_donor_lists";
import { eugene_donor } from "./eugene_donor";
import { eugene_stories_menu } from "./eugene_stories_menu";
import { eugene_donor_list_menu } from "./eugene_donor_list_menu";
import { eugene_displays } from "./eugene_displays";
import { eugene_display } from "./eugene_display";
import { eugene_presentations } from "./eugene_presentations";
import { eugene_presentation } from "./eugene_presentation";
import { eugene_content } from "./eugene_content";

export const eugene_types: { [key: string]: IFolderType } = {
  eugene_root,
  eugene_stories,
  eugene_stories_menu,
  eugene_template_001,
  eugene_template_002,
  eugene_template_003,
  eugene_template_004,
  eugene_template_005,
  eugene_attract_loop,
  eugene_slide,
  eugene_search,
  eugene_menu,
  eugene_donor,
  eugene_donor_lists,
  eugene_category_with_giving_level,
  eugene_category_without_giving_level,
  eugene_donor_list_menu,
  eugene_displays,
  eugene_display,
  eugene_presentations,
  eugene_presentation,
  eugene_content
};
