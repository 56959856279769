import React, { Component } from "react";
import PreviewContainer from "./PreviewContainer";
import get from 'lodash/get';

class ZoomableContainer extends Component {
  _containerRef = null;
  _wrapperContainerRef = null;
  constructor(props) {
    super(props);
    this.state = {
      mouseDown: false,
      top: 100,
      left: props.offset,
    };
  }

  componentDidMount() {
    const context = this;
    this._containerRef.addEventListener("mouseout", function (e) {
      let event = e.toElement || e.relatedTarget;
      let parentFound = false;
      while (event && event.parentNode) {
        if (event.parentNode === this || event === this) {
          parentFound = true;
          break;
        }
        event = event.parentNode;
      }

      if (!parentFound) {
        context.setState({ mouseDown: false });
      }
    });
    this._containerRef.addEventListener("mousedown", this.mouseDown);
    this._containerRef.addEventListener("mouseup", this.mouseUp);
    this._containerRef.addEventListener("mousemove", this.mouseMove);
    this._containerRef.addEventListener("wheel", this.wheel);

    this.resetPosition();
  }

  componentWillUnmount() {
    const context = this;
    this._containerRef.removeEventListener("mouseout", function (e) {
      let event = e.toElement || e.relatedTarget;
      let parentFound = false;
      while (event.parentNode) {
        if (event.parentNode === this || event === this) {
          parentFound = true;
          break;
        }
        event = event.parentNode;
      }

      if (!parentFound) {
        context.setState({ mouseDown: false });
      }
    });
    this._containerRef.removeEventListener("mousedown", this.mouseDown);
    this._containerRef.removeEventListener("mouseup", this.mouseUp);
    this._containerRef.removeEventListener("mousemove", this.mouseMove);
    this._containerRef.removeEventListener("wheel", this.wheel);
  }

  mouseDown = (e) => {
    if (e.layerX < 0 || e.layerY < 0) {
      this.setState({ mouseDown: false });
    } else {
      this.setState({ mouseDown: true });
    }
  };

  mouseUp = () => {
    this.setState({ mouseDown: false });
  };

  mouseMove = (e) => {
    const { mouseDown } = this.state;
    if (mouseDown) {
      const rect = this._wrapperContainerRef.getBoundingClientRect();
      const top = `${rect.y + e.movementY}px`;
      const left = `${rect.x + e.movementX}px`;
      this.setState({ top, left });
    }
  };

  wheel = (e) => {
    e.preventDefault();
    const { zoomIn, zoomOut } = this.props;
    if (e.deltaY > 0) {
      zoomOut();
    } else {
      zoomIn();
    }
  };

  resetPosition = (top = 100) => {
    const initialLeft = this.props.offset + (this.props.containerWidth / 2) - (this.props.width / 2);
    const initialTop = top + (this.props.containerHeight / 2) - (this.props.height / 2);
    this.setState({ top: initialTop, left: initialLeft });
  };

  render() {
    const { width, height, selected_folder, folder_type } = this.props;
    const { top, left } = this.state;
  
    return (
      <div style={{ width: this.props.containerWidth, height: this.props.containerHeight }}>
        <div
          ref={(el) => (this._wrapperContainerRef = el)}
          style={{
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: top,
            left: left,
            zIndex: 0,
          }}
        >
          <div
            ref={(el) => (this._containerRef = el)}
            style={{
              left: `${get(folder_type, "preview.left", 0)}px`,
              position: "relative",
              width,
              height,
              cursor: "pointer",
              userSelect: "none",
              zIndex: 99,
            }}
            className="no-select"
          >
            <PreviewContainer
              width={width}
              height={height}
              selected_folder={selected_folder}
              folder_type={folder_type}
              zoomable={true}
              mobile={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ZoomableContainer;
