import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
import { getFileType, getFileIcon } from '../../../shared/utils/fileUtils'
import TextInput from './TextInput.tsx';
import ImageCropper from '../../utils/ImageCropper';
import _ from 'lodash';
import ImageLoader from '../../utils/ImageLoader';
import SelectExistingResource from './SelectExistingResource';
import { removesURLFromCache } from '../../../shared/utils/fileUtils';

class FileInput extends Component {
  static propTypes = {
    title: PropTypes.string,
    defaultValue: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    showTitle: PropTypes.bool,
    showRankIcons: PropTypes.bool,
    onlyShowRemoveOnLoad: PropTypes.bool,
    gallery: PropTypes.bool
  }

  static defaultProps = {
    showTitle: true,
    showRankIcons: false,
    onlyShowRemoveOnLoad: false,
    gallery: false
  }

  constructor(props) {
    super(props);
    this._inputEvent = null;
    this._inputRef = null;
    this._componentIsMounted = false;
    this._imageCrop = null;
    this._imageLoadedForCropping = null;
    this._fileInputChanged = false;
    this._selectExistingResource = null;
    this.state = {
      status: "none",
      fileName: null,
      originalName: null,
      modifiedName: null,
      thumb: null,
      url: null,
      modalGalleryVisible: false,
      previewResource: null,
      cropPreview: '',
      cropData: {},
      isImage: false,
      cropperToolVisible: false
    };
  }

  componentDidMount() {
    this._componentIsMounted = true;
    if (this.props.data) {
      this.updateStateOnChange();
    }
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.data) {
        if (this._componentIsMounted) {
          this.updateStateOnChange();
        }
      } else {
        this.setState({
          status: "none",
          fileName: null,
          originalName: null,
          modifiedName: null,
          thumb: null,
          url: null,
          modalGalleryVisible: false,
          cropData: null
        })
      }
    }
  }

  updateStateOnChange = async () => {
    let fileType;
    let thumb = null;

    switch (this.props.data.status) {
      case "available":
        fileType = getFileType(this.props.data.fileName);
        if (fileType === "image") {
          thumb = this.props.data.resourceUrl;
          if (this.props.data.thumb) {
            thumb = this.props.data.thumb;
          }
        } else {
          thumb = getFileIcon(this.props.data.fileName);
        }
        this.setState({
          fileName: this.props.data.fileName,
          originalName: this.props.data.originalName,
          modifiedName: this.props.data.modifiedName,
          status: "available",
          thumb: thumb,
          url: this.props.data.resourceUrl
        });
      break;
      case "loaded":
        if (this._componentIsMounted) {
          fileType = getFileType(this.props.data.fileName);
          if (fileType === "image") {
            if (this.props.data.type === "file") {
              let reader = new FileReader();
              const cropperToolVisible = this._fileInputChanged && this.props.cropper ? true : false;
              reader.onload = async (e) => {
                if (this._componentIsMounted && this.props.data) {
                  let thumb = e.target.result;
                  const crop = _.get(this.props, 'data.crop', null)
                  if (crop && !this._fileInputChanged) {
                    const image = new Image();
                    image.src = thumb;
                    thumb = await this.getCroppedImage(image, this.props.data.crop);
                  }
                  if (this._fileInputChanged) {
                    delete this.props.data.crop;
                  }
                  this.setState({
                    fileName: this.props.data.fileName,
                    originalName: this.props.data.originalName,
                    modifiedName: this.props.data.modifiedName,
                    status: "loaded",
                    thumb: thumb,
                    isImage: true,
                    cropperToolVisible: cropperToolVisible
                  });
                }
              }
              if (this.props.data.file && this.props.data.file instanceof File) {
                reader.readAsDataURL(this.props.data.file);
              }
            } else {
              const cropperToolVisible = this._fileInputChanged && this.props.cropper ? true : false;
              let thumb = this.props.data.url;
              if (this.props.data.crop && !this._fileInputChanged) {
                const image = new Image();
                image.crossOrigin = "anonymous";
                image.src = thumb;
                thumb = await this.getCroppedImage(image, this.props.data.crop);
              }
              if (this._fileInputChanged && this.props.data.crop) {
                delete this.props.data.crop;
              }

              this.setState({
                fileName: this.props.data.fileName,
                originalName: this.props.data.originalName,
                modifiedName: this.props.data.modifiedName,
                status: "loaded",
                thumb: thumb,
                cropperToolVisible: cropperToolVisible,
                isImage: true,
              });
            }
          } else {
            thumb = getFileIcon(this.props.data.fileName);
            this.setState({
              fileName: this.props.data.fileName,
              originalName: this.props.data.originalName,
              modifiedName: this.props.data.modifiedName,
              status: "loaded",
              thumb: thumb,
              isImage: false,
              cropperToolVisible: false
            });
          }
        }
      break;
      case "created":
        this.setState({
          fileName: "Loading",
          originalName: null,
          modifiedName: null,
          thumb: null,
          status: "created"
        });
      break;
      case "removed":
        this.setState({
          fileName: "Marked for removal",
          originalName: null,
          modifiedName: null,
          thumb: null,
          status: "removed"
        });
      break;
      default:
      break;
    }
  }

  openGallery = () => {
    if (this._selectExistingResource) {
      this._selectExistingResource.openGallery();
    }
    // this.setState({
    //   modalGalleryVisible: true
    // });
  }

  closeGallery = () => {
    if (this._selectExistingResource) {
      this._selectExistingResource.closeGallery();
    }
    // this.setState({
    //   modalGalleryVisible: false
    // });
  }

  selectPreviewResource = (resource) => {
    if (!resource) {
      if (this.state.previewResource) {
        this.setState({
          previewResource: null
        });
      }
    } else {
      this.setState({
        previewResource: resource
      });
    }
  }

  selectExistingResource = (previewResource) => {
    this._fileInputChanged = true;
    this.props.onChange(this.props.item.identifier, 'file', previewResource, 'existing');
    this.closeGallery();
  }

  onFileInputChange = e => {
    this._fileInputChanged = true;
    let file = e.target.files[0];
    if (file) {
      this.props.onChange(this.props.item.identifier, "file", file);
      if (this.state.status !== 'none') {
        const id = _.get(this.props, 'data.id', null);
        if (id) {
          removesURLFromCache(`resourceFolder-${this.props.data.id}`);
        }
      }
    }
  }

  getBase64Image = (img) => {
    // img.crossOrigin = "anonymous";
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;

    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  closeCropperTool = () => {
    this._fileInputChanged = false;
    this._imageCrop = null;
    this._imageLoadedForCropping = null;
    this.setState({
      cropperToolVisible: false
    });
  }

  cropImage = (crop) => {
    this._imageCrop = crop;
  }

  imageLoaded = (image) => {
    image.crossOrigin = "anonymous";
    this._imageLoadedForCropping = image;
  }

  getCroppedImage = async (imageSrc, crop) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    const base64Image = await new Promise((resolve, reject) => {
      image.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = crop.width * image.width / 100;
        canvas.height = crop.height * image.height / 100;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x / 100 * image.naturalWidth,
          crop.y / 100 * image.naturalHeight,
          crop.width / 100 * image.naturalWidth,
          crop.height / 100 * image.naturalHeight,
          0,
          0,
          crop.width / 100 * image.width,
          crop.height / 100 * image.height,
        );

        // As Base64 string
        const base64 = canvas.toDataURL('image/jpeg');
        return resolve(base64);
      }
      image.src = imageSrc.src;
    });
    return base64Image;
  }

  getAcceptableFiles =  () => {
    let totalAcceptableMimes = [];
    if (this.props.acceptable && this.props.acceptable.length) {
      for (let i = 0; i < this.props.acceptable.length; i++) {
        if (this.props.acceptable[i].includes("csv")) {
          totalAcceptableMimes.push(".csv");
        } else {
          totalAcceptableMimes.push(this.props.acceptable[i]);
        }
        // const acceptable = getFileAcceptableMimeTypes(this.props.acceptable[i]);
        // totalAcceptableMimes = totalAcceptableMimes.concat(acceptable);
      }
    }
    return totalAcceptableMimes.join(",");
  }

  selectCroppedImage = async () => {
    if (!this._imageCrop) {
      return this.closeCropperTool();
    }
    this._fileInputChanged = false;
    const base64 = await this.getCroppedImage(this._imageLoadedForCropping, this._imageCrop);
    this.onBlurInput("crop", this._imageCrop);
    this._imageCrop = null;
    this._imageLoadedForCropping = null;
    this.setState({
      thumb: base64,
      cropperToolVisible: false
    });
  }

  clearInput = () => {
    if (this.props.onRemove) {
      this._inputRef.value = null;
      this.props.onRemove(this.props.item.identifier);
    }
  }

  onChangeInput = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  }

  onBlurInput = (fieldName, value) => {
    this.props.onChange(this.props.item.identifier, fieldName, value);
  }

  render() {
    const aspect = _.get(this.props.item, 'aspect', null);
    const acceptableFiles = this.getAcceptableFiles();
    return (
      <React.Fragment>
        <Row className="file-input-wrapper">
          <Col md={12}>
            {
              this.props.showTitle ?
              <React.Fragment>
                <label className="form-input-label">
                  {this.props.title}
                </label>
                <br/>
              </React.Fragment>
              : null
            }
            <input disabled={this.props.disabled} ref={ref => this._inputRef = ref} type="file" accept={acceptableFiles} id={"file" + this.props.item.identifier} onChange={(e) => this.onFileInputChange(e)}/>
            {/* <input hidden value={this.state.cropData} /> */}
            <label htmlFor={"file" + this.props.item.identifier} className={this.props.disabled ? "disabled" : ""}>
              <span style={{height: 20, marginBottom: 0}} className="fa-layers fa-fw">
                <FontAwesomeIcon style={{cursor: 'pointer'}} icon={['fas', 'upload']} />
              </span>
              <span>Upload</span>
            </label>

            {
              this.props.gallery ?
              <React.Fragment>
                <Button onClick={this.openGallery} disabled={this.props.disabled}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon style={{cursor: 'pointer'}} icon={['fas', 'images']} />
                  </span>
                  <span>
                    Content
                  </span>
                </Button>
              </React.Fragment>
              : null
            }
          </Col>
          <Col md={5} className={this.props.showTitle ? "file-name" : "file-name-no-title"}>
            {
              this.state.status === "available" ?
              <a href={this.state.url} target="_blank" rel="noopener noreferrer">{this.state.fileName}</a>
              :
              <span>{this.state.fileName}</span>
            }
          </Col>
          {
            ["available", "loaded"].indexOf(this.state.status) > -1 ?
            <Col md={6} className={this.props.showTitle ? "file-name" : "file-name-no-title"}>
              <TextInput
                title=""
                defaultValue={this.state.modifiedName}
                fieldName={"modifiedName"}
                onChange={this.onChangeInput}
                onBlur={this.onBlurInput}
                canUpdate={true}
              />
            </Col>
            : null
          }
          <Col md={3} className={this.props.showTitle ? "file-preview" : "file-preview-no-title"}>
            {
              ["available", "loaded"].indexOf(this.state.status) > -1 && this.state.thumb !== null ?
              <div style={{ width:"100%", height:"100%", textAlign:"center" }}>
                <ImageLoader url={this.state.thumb}/>
              </div>
              : null
            }

            {
              this.state.status === "created" && this.state.thumb === null ?
              <div style={{ width:"100%", height:"100%", textAlign:"center" }}>
                <FontAwesomeIcon style={{cursor: 'pointer'}} icon={['fas', 'spinner']} spin />
              </div>
              : null
            }
          </Col>
          <Col md={4}>
            {
              this.props.showRankIcons && this.state.status !== "removed" ?
              <React.Fragment>
                <span className={this.props.showTitle ? "fa-layers fa-fw file-remove" : "fa-layers fa-fw"}>
                  <FontAwesomeIcon onClick={(e) => this.props.moveDown(this.props.item.identifier)} style={{cursor: 'pointer'}} icon={['far', 'arrow-circle-down']} />
                </span>
                <span className={this.props.showTitle ? "fa-layers fa-fw file-remove" : "fa-layers fa-fw"}>
                  <FontAwesomeIcon onClick={(e) => this.props.moveUp(this.props.item.identifier)} style={{cursor: 'pointer'}} icon={['far', 'arrow-circle-up']} />
                </span>
              </React.Fragment>
              :null
            }
            {
              ["created"].indexOf(this.state.status) === -1 ?
              <span className={this.props.showTitle ? "fa-layers fa-fw file-remove" : "fa-layers fa-fw"}>
                <FontAwesomeIcon onClick={(e) => this.clearInput(e)} style={{cursor: 'pointer'}} icon={['far', 'times-circle']} />
              </span>
              : null
            }
          </Col>
        </Row>

        <SelectExistingResource filters={['image', 'video']} onOkay={this.selectExistingResource} ref={ref => this._selectExistingResource = ref}/>

        {/*<div onClick={() => {this.setState({contentManagerOpen: true})}}>New Modal</div>*/}

        {/*<Modal*/}
        {/*  title={"New Image Editor"}*/}
        {/*  visible={this.state.contentManagerOpen}*/}
        {/*  onCancel={() => {this.setState({*/}
        {/*    contentManagerOpen: false*/}
        {/*  })}}*/}
        {/*  onOk={() => {console.log("onOkay")}}*/}
        {/*  destroyOnClose="true"*/}
        {/*  width={1024}*/}
        {/*  footer={[*/}
        {/*    <Button key="Cancel" onClick={() => {}}>*/}
        {/*      Cancel*/}
        {/*    </Button>,*/}
        {/*    <Button key="Ok" type="primary" onClick={() => {}}>*/}
        {/*      Ok*/}
        {/*    </Button>,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <ContentManager />*/}
        {/*</Modal>*/}

        {/* Image Cropper Modal */}
        <Modal
          title={"Cropper tool"}
          visible={this.state.cropperToolVisible}
          onCancel={this.closeCropperTool}
          onOk={this.selectCroppedImage}
          destroyOnClose="true"
          width={1024}
          footer={[
            <Button key="Cancel" onClick={this.closeCropperTool}>
              Cancel
            </Button>,
            <Button key="Ok" type="primary" onClick={this.selectCroppedImage}>
              Ok
            </Button>,
          ]}
        >
          <div style={{ maxWidth: "1000px", maxHeight: "600px", textAlign: "center" }}>
            <ImageCropper url={this.state.thumb} onChange={this.cropImage} aspect={aspect} imageLoaded={this.imageLoaded} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default FileInput;
