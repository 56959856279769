import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FOLDER_TYPE, COMPONENT_NAME, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../cycle_time_tooltips";
import { transitions } from "./new_orleans_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const new_orleans_template_002: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.new_orleans_template_002,
  display_name: "Header, Rich Text",
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    header01: {
      type: "text",
      title: "Header",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      settings: {
        recommended_length: 38
      }
    },
    text01: {
      type: "hybrid_text_input",
      title: "Rich Text",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.HIND][FontVariants.REGULAR],
            size: "20px"
          },
          ops: [],
          dark_mode: false
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: {
        fonts: [FontsFamilies.HIND, FontsFamilies.GOTHAM],
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 50
        },
        font_size_unit: "px",
        recommended_length: 1420,
        controls: {
          fontSize: true,
          bold: true,
          italic: true,
          underline: true,
          numberedList: true,
          bulletList: true,
          alignment: true,
          indentation: true,
          clearFormat: true
        }
      }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 0,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips
    }
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.NewOrleansTemplate002,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/new_orleans_background.jpg",
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
  }
};
