// export enum FOLDER_TYPE {
//     FREMONT_ROOT = 'fremont_root'
// }

export enum TREE_TYPE {
  APPLIED_CONTENT = "applied_content",
  RESOURCE = "resource",
  SELECT_EXISTING_RESOURCE = "select_existing_resource",
  SELECT_APPLIED_CONTENT = "select_existing_applied_content",
}

export enum PDG_COLORS {
  LT_GRAY = "#707070",
  XLT_GRAY = "#c5c9cc",
  XLT_BLUE = "#e6f3fe",
  XXLT_GRAY = "#F6F6F7",
  LT_GRAY_BLUE = "#657077",
  MD_BLUE = "#2699FB",
  MD_PRESSED_BLUE = "#3F51B5",
  MD_WARNING = "#FFB300",
  MD_RED = "#ff4d4f",
  WHITE = "#ffffff",
  TRANSPARENT = "rgba(0, 0, 0, 0)",
  CONTENT_ICON_COLOR = "#009610",
  CONTENT_COLOR = "#e5f4e7",
}

export enum INPUT_MODE {
  HTML5 = "html5",
  TOUCH = "touch",
}

export enum IMAGE_ALIGNMENT {
  STRETCH = "stretch",
  COVER = "cover",
  CONTAIN = "contain",
}

export enum FLEX_CLASSES {
  FLEX_TOP_LEFT = "flex-top-left",
  FLEX_TOP_CENTER = "flex-top-center",
  FLEX_TOP_RIGHT = "flex-top-right",
  FLEX_CENTER = "flex-center",
  FLEX_BOTTOM_LEFT = "flex-bottom-left",
  FLEX_BOTTOM_CENTER = "flex-bottom-center",
  FLEX_BOTTOM_RIGHT = "flex-bottom-right",
}

export enum FONT_FAMILY {
  WHITNEY_BOLD = "whitney-bold",
  WHITNEY_LIGHT = "whitney-light",
  WHITNEY_REGULAR = "whitney-regular",
  WHITNEY_BOOK = "whitney-book",
  ROBOTO_MEDIUM = "Roboto Medium",
  ROBOTO = "Roboto",
  ROBOTO_LIGHT = "Roboto Light",
  ROBOTO_BOLD = "Roboto Bold",
}

export enum TRANSITION_CLASS {
  CROSSFADE = "transition-fade",
  CROSSFADE_250MS = "transition-fade250ms",
  CROSSFADE_2S = "transition-fade-2s",
  DISSOLVE = 'transition-dissolve',
  FASTINSLOWOUT = "transition-fastinslowout",
  SLIDE_IN_FROM_BOTTOM = "transition-slide-in-from-bottom",
  SLIDE_IN_FROM_LEFT = "transition-slide-in-from-left",
  SLIDE_IN_FROM_RIGHT = "transition-slide-in-from-right",
  SLIDE_IN_FROM_TOP = "transition-slide-in-from-top",
  SLOWINFASTOUT = "transition-slowinfastout",
  TRANSITION_FADE500MS1S = "transition-fade500ms1s",
  TRANSITION_FADEOUT = "transition-fadeout",
  TRANSITION_FADEOUT_2S = "transition-fadeout-2s",
  TRANSITION_FADE_1S = "transition-fade1s",
  TRANSITION_FADE_2S = "transition-fade-2s",
  TRANSITION_FADE_2S_LINEAR = "transition-fade-2s-linear",
  TRASITION_FADE = "transition-fade",
}

export enum JUSTIFICATION {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}
