import React from 'react'
import RichTextRender from "../../standard/RichTextRender";
import { createUseStyles } from "react-jss";
import {useScale} from "../../../hooks/useScale";
import {useFolder} from "../../../hooks/useFolder";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const emptyHTML = "<p><br/></p>";

const TangentQuote = (props) => {
  const {
    getBaseFolder,
    getBaseFolderFolderType,
    getBaseFolderFieldValue,
  } = useFolder(props.quote.id);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  let quote_body = getBaseFolderFieldValue(props.quoteKey || 'quote_body', baseFolderType);

  const {
    getBaseFolderRichTextStylesFn,
    standardSizing,
    getUnitBasedOnHeight,
  } = useScale(props.data.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    standardSizing,
    getUnitBasedOnHeight,
  });

  if (quote_body === emptyHTML) {
    quote_body = '';
  }



  return (
    <div className={`${richTextClasses.componentContainer} ${classes.quoteContainer}`}
    >
      {quote_body.length > 0 && <RichTextRender
        className="quoteHeading"
        htmlString={quote_body}
      />}
    </div>
  )
}

export default TangentQuote
