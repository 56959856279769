// @tsignore
import get from "lodash/get";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import styles from "./styles";
import AlpineQrWidget from "./AlpineQrWidget";

const useStyles = createUseStyles(styles);

const AlpineStoryWithSingleImage = (props: IDisplayComponentProps) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } = useFolder(props?.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const baseFolderFields = baseFolder?.fields

  const {
    getBaseFolderRichTextStylesFn,
    standardSizing,
    getUnitBasedOnHeight,
    getBaseFolderFontSize
  } = useScale(props.data?.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();

  const showPlaceholder = useMemo(() => {
    return props?.data?.persistent_settings?.data?.show_placeholder || false;
  }, [props.data]);

  const headline = getBaseFolderFieldValue("headline", baseFolderType);
  const subhead = getBaseFolderFieldValue("subhead", baseFolderType);
  const bodyText = getBaseFolderFieldValue("body", baseFolderType);
  const mirrorContent = getBaseFolderFieldValue("mirror_content", false);
  const imageFieldType = getBaseFolderFieldValue("background", null);
  const backgroundColor = getBaseFolderFieldValue("color_overlay", "transparent");
  const fontSize = getBaseFolderFontSize()


  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    standardSizing,
    getUnitBasedOnHeight,
    mirrorContent,
    fontSize
  });

  return (
    <>
      {/* Background Image */}
      <div className={classes.storyWithSingleImageContainer}>
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            style: {
              backgroundPosition: "inherit",
            }
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div className={classes.storySingleImageOverlay} />
      <div className={classes.storySingleImageContent}>
        {/* Images */}
        <div className="image">
          <div className="imageWrapper">
            <Slide1911
              aws={props.aws}
              aws_bucket={props.aws_bucket}
              mode={props.mode}
              containerWidth={props.containerWidth}
              containerHeight={(986 / 1920) * props.containerHeight}
              data={{
                ...props.data,
                slotNames: ["image_1"],
                positionRelative: "relative",
                placeholder: "image 1",
                fieldType: imageFieldType,
                showPlaceholder: showPlaceholder,
                backgroundSize: "cover",
                style: {
                  backgroundPosition: "inherit",
                },
                componentSpecificData: {
                  customStyle: {
                    height: "100%",
                    width: "100%"
                  }
                }
              }}
              webview_signedurls={props.webview_signedurls}
            />
          </div>
        </div>
        {/* Text */}
        <div className="text">
          <div className="textWrapper">
            {/* Headline */}
            <div className={`headline ${richTextClasses.componentContainer}`}>
              <RichTextRender className="headlineText" htmlString={headline} />
            </div>
            {/* Sub Head */}
            {subhead && (
              <div className="subhead">
                <div className={`subheadTextWrapper ${richTextClasses.componentContainer}`}>
                  <RichTextRender className="subheadText" htmlString={subhead} />
                </div>
              </div>
            )}
            {/* Body Text */}
            <div className="body">
              <div className={`bodyTextWrapper ${richTextClasses.componentContainer}`}>
                <RichTextRender className="bodyText" htmlString={bodyText} />
              </div>
            </div>
          </div>
        </div>
        <AlpineQrWidget
          {...props}
          baseFolderFields={baseFolderFields}
          baseFolderType={baseFolderType}
        />
      </div>
    </>
  );
};

export default AlpineStoryWithSingleImage;
