import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType,
} from "../../../utils/commonDataUtils";
import {
  generateFontSizeClassesFromFields,
  getFieldValue,
} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {
  SPR_LANDSCAPE_BODY_LINE_HEIGHT,
  SPR_LANDSCAPE_BODY_MARGIN_TOP,
  SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
  SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM,
  SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
  SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
  SPR_LANDSCAPE_VERTICAL_MARGIN,
} from "./SpringfieldConstants";

const SpringfieldTemplate003 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const image1FieldType = useMemo(() => {
    return get(baseFolderType, "fields[image_1]", null);
  }, [baseFolderType]);

  const image2FieldType = useMemo(() => {
    return get(baseFolderType, "fields[image_2]", null);
  }, [baseFolderType]);

  const color = useMemo(() => {
    return get(baseFolder, "fields.color_overlay", "transparent");
  }, [baseFolder]);

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings?.data, "show_placeholder", false);
  }, [props.data]);

  const getStyles = useCallback(() => {
    if (baseFolder) {
      const folderType = props.data?.folder_types?.find(
        (f) => f.name === baseFolder.folder_type
      );
      const styles = createUseStyles(
        generateFontSizeClassesFromFields(folderType, baseFolder.fields)
      );
      return styles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, [baseFolder?.folder_type, props.data?.folder_types]);

  const useStyles = getStyles();
  const classes = useStyles();

  const headline = getFieldValue(baseFolder, "headline", baseFolderType);
  const subhead = getFieldValue(baseFolder, "subhead", baseFolderType);
  const bodyText = getFieldValue(baseFolder, "body", baseFolderType);
  const mirrorContent = get(baseFolder, "fields.mirror_content", false);

  const CONATINER_HORIZONTAL_MARGIN = 0.06 * props.containerWidth;
  const IMAGE_HEIGHT_PERCENT = 0.4;
  const IMAGE_HEIGHT = IMAGE_HEIGHT_PERCENT * props.containerHeight;
  const MARGIN_BETWEEN_IMAGES =
    1 - IMAGE_HEIGHT_PERCENT * 2 - SPR_LANDSCAPE_VERTICAL_MARGIN * 2;
  const IMAGE_WIDTH = IMAGE_HEIGHT * 1.7777777;
  //const IMAGE_WIDTH = props.containerHeight *

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            componentSpecificData: {
              ...props.data?.componentSpecificData,
              useKeyCycler: false,
            },
          }}
          webview_signedurls={props.webview_signedurls}
          handleEndOfPlay={null}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: color,
          zIndex: 0,
        }}
      />

      <div
        style={{
          width: props.containerWidth,
          height: props.containerHeight,
          position: "relative",
          display: "flex",
          flexDirection: !mirrorContent ? "row" : "row-reverse",
          alignItems: "stretch",
        }}
      >
        {/* Text */}

        <div
          style={{
            height: "100%",
            width: props.containerWidth * 0.5,
            //backgroundColor: 'teal',
            paddingTop: props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
            paddingBottom:
              props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
            paddingLeft: CONATINER_HORIZONTAL_MARGIN,
            paddingRight: CONATINER_HORIZONTAL_MARGIN,
          }}
        >
          <div style={{ maxHeight: "100%", overflowY: "hidden" }}>
            {/* Headline */}
            <div
              className={classes.componentContainer}
              style={{
                padding: 0,
                lineHeight: SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
                overflowX: "hidden",
                overflowY: "hidden",
                paddingBottom:
                  SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                  props.containerHeight,
              }}
            >
              <RichTextRender
                htmlString={headline}
                style={{
                  padding: 0,
                }}
              />
            </div>
            {/* Sub Head */}
            {subhead && (
              <div
                className="springfield-template-003_subhead"
                style={{
                  marginTop:
                    props.containerHeight * SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
                }}
              >
                <div
                  className={classes.componentContainer}
                  style={{
                    padding: 0,
                    lineHeight: SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingBottom:
                      SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                      props.containerHeight,
                  }}
                >
                  <RichTextRender
                    htmlString={subhead}
                    style={{
                      padding: 0,
                    }}
                  />
                </div>
              </div>
            )}
            {/* Body Text */}
            <div
              className="springfield-template-003_body-text"
              style={{
                marginTop:
                  props.containerHeight * SPR_LANDSCAPE_BODY_MARGIN_TOP,
              }}
            >
              <div
                className={classes.componentContainer}
                style={{
                  padding: 0,
                  lineHeight: SPR_LANDSCAPE_BODY_LINE_HEIGHT,
                  overflowX: "hidden",
                  overflowY: "hidden",
                  paddingBottom:
                    SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                    props.containerHeight,
                }}
              >
                <RichTextRender
                  htmlString={bodyText}
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Images */}

        <div
          style={{
            height: "100%",
            paddingTop: props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
            paddingBottom:
              props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
            paddingRight: !mirrorContent ? CONATINER_HORIZONTAL_MARGIN : 0,
            paddingLeft: !mirrorContent ? 0 : CONATINER_HORIZONTAL_MARGIN,
            //backgroundColor: "green",
            width: props.containerWidth * 0.5,
            textAlign: !mirrorContent ? "right" : "left",
          }}
        >
          <div
            style={{
              width: IMAGE_WIDTH,
              height: IMAGE_HEIGHT,
              position: "relative",
              //backgroundColor: 'teal',
              float: !mirrorContent ? "right" : "left",
            }}
          >
            <Slide1911
              aws={props.aws}
              aws_bucket={props.aws_bucket}
              mode={props.mode}
              containerWidth={IMAGE_WIDTH}
              containerHeight={IMAGE_HEIGHT}
              data={{
                ...props.data,
                slotNames: ["image_1"],
                style: { backgroundPosition: "left bottom" },
                placeholder: "image 1",
                fieldType: image1FieldType,
                showPlaceholder: showPlaceholder,
                backgroundSize: "cover",
                componentSpecificData: {
                  ...props.data?.componentSpecificData,
                  useKeyCycler: false,
                },
              }}
              handleEndOfPlay={null}
              webview_signedurls={props.webview_signedurls}
            />
          </div>
          <div
            style={{
              width: IMAGE_WIDTH,
              height: IMAGE_HEIGHT,
              marginTop: MARGIN_BETWEEN_IMAGES * props.containerHeight,
              position: "relative",
              //backgroundColor: 'teal',
              float: !mirrorContent ? "right" : "left",
            }}
          >
            <Slide1911
              aws={props.aws}
              aws_bucket={props.aws_bucket}
              mode={props.mode}
              containerWidth={IMAGE_WIDTH}
              containerHeight={IMAGE_HEIGHT}
              data={{
                ...props.data,
                slotNames: ["image_2"],
                style: { backgroundPosition: "left top" },
                placeholder: "image 2",
                fieldType: image2FieldType,
                showPlaceholder: showPlaceholder,
                backgroundSize: "contain",
                componentSpecificData: {
                  ...props.data?.componentSpecificData,
                  useKeyCycler: false,
                },
              }}
              handleEndOfPlay={null}
              webview_signedurls={props.webview_signedurls}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpringfieldTemplate003;
