import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/company/company_user_fields';
import { connect } from "react-redux";
import { getCompany } from '../../actions/companyActions';
import { getSingleUser, companyUserUpdate, companyUserAdd, resendSetUserPasswordEmail } from '../../actions/userActions';
import { hasPermission } from '../../utils/permissionUtils';
import { Button } from 'antd';
import AlertNotification from '../layout/AlertNotification';
import UserRoles from './UserRoles';
import AddUserRoleModal from '../company/AddUserRoleModal';

import CompanyBreadCrumbs from './CompanyBreadCrumbs';
import _ from 'lodash'
import {BackLink} from "../utils/BackLink";
import { withRouter } from '../../hooks/withRouter';

class CompanyUserForm extends Component {
  constructor(props) {

    //alert("k"+(JSON.stringify("ok")));
    super(props);
    let editMode = false;
    if (this.props.params.userId) {
      editMode = true;
    }
    this.state =
      {
        editMode: editMode,
        addroleModalVisible: false,
        email: "",
        first_name: "",
        last_name: "",
        submitting: false
      };
  }


  componentDidMount() {

    //alert(JSON.stringify(this.props));
    if (this.props.user == null) {
      this.props.getCompany(this.props.params.companyId, this.onSuccess, this.onFail);
    }

    else if (this.props.params.userId !== null || this.props.params.userId !== undefined || this.props.params.userId !== "") {
      this.props.getSingleUser(this.props.params.userId, this.onSuccess, this.onFail);
    }

    if (this.state.editMode === true) {

      // console.log("called method getcompany  on page load...");
      //this.props.getcompany(this.props.params.companyId ,this.onSuccess,this.onFail);
    }
  }

  onSubmit = (values) => {
    this.setState({ submitting: true })
    if (this.state.editMode === false) {
      this.props.companyUserAdd(this.props.params.companyId, values.first_name, values.last_name, values.email, this.onInsert, this.onError);
    }
    else {
      this.props.companyUserUpdate(this.props.params.userId, values.first_name, values.last_name, this.onUpdate, this.onError);
    }
  }


  onUpdate = () => {
    this.setState({ submitting: false })
    AlertNotification('success', 'Success', "User updated")
  }

  onInsert = (id) => {
    this.setState({ submitting: false })
    AlertNotification('success', 'Success', "User created")

    this.setState({editMode:true});
    let url = "/company/" + this.props.params.companyId + "/user/" + id + "/";
    this.props.navigate(url);
  }

  addRoleModalClose = () => {
    this.setState({
      addroleModalVisible: false
    });
  }

  closeAddRoleModal = () => {
    this.setState({
      addroleModalVisible: false
    });
  }

  onError = (message) => {
    this.setState({ submitting: false })
    AlertNotification('error', 'Form error', message || 'An error has occurred')
  }

  onResendSuccess = () => {
    AlertNotification('success', 'Success', 'Email sent successfully')
  }

  onResendFailure = (message) => {
    AlertNotification('error', 'Error', message || 'Failure sending resend email')
  }

  render() {
    let obj;
    let hasUpdatePermission = hasPermission(this.props.grouped_permissions, 'user', 'update', this.props.loggedInUser, null, null, this.props.user?.company ?? null);

    if (this.state.editMode === true && this.props.user == null) {
      return null;
    }
    let mode = "";
    let formTitle="";
    if (this.state.editMode === false && this.props.company == null) {
      return null;
    }
    else if (this.state.editMode === false) {
      obj = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email
      };
      mode = 'add';
      formTitle="Add User ";
    }
    else {
      mode = 'edit';
      obj = {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email
      };

      formTitle="Edit User ";
    }
    if (mode === 'edit') {
      return (
        <div>

        <CompanyBreadCrumbs crumbs={this.props}/>
        <h1>{formTitle}</h1>
          <DynamicForm
            formName={"UserForm"}
            fieldGroupsConfig={formConfig.field_groups}
            fieldsConfig={formConfig.fields}
            fieldValues={obj}
            mode={mode}
            onSubmit={this.onSubmit}
            onError={this.onError}
            enableFieldGrouping={false}
            canUpdate={hasUpdatePermission}
          />
          {hasUpdatePermission ?
            <Button onClick={() => {
              this.setState({
                addroleModalVisible: true
              });
            }}>Add Role</Button> : ''}


        {this.state.addroleModalVisible ? <AddUserRoleModal
            visible={this.state.addroleModalVisible}
            onOkay={() => this.closeAddRoleModal()}
            onCancel={this.addRoleModalClose}
            companyId={this.props.params.companyId}
            userId={this.props.params.userId}
          /> : null}  
          {hasUpdatePermission ?
            <UserRoles userId={this.props.params.userId} canDelete={hasUpdatePermission} /> : ''}
          <br/>
          {hasUpdatePermission ?
            <Button
              onClick= {() => this.props.resendSetUserPasswordEmail(this.props.params.userId, this.onResendSuccess, this.onResendFailure)}>
              Resend set password email
            </Button> : null}

        </div>
      );

    }
    else
      return (
        <div>
        <CompanyBreadCrumbs crumbs={this.props}/>
        <div>
          <BackLink/>
        </div>
        <h1>{formTitle}</h1>
          <DynamicForm
            submitting={this.state.submitting}
            fieldGroupsConfig={formConfig.field_groups}
            fieldsConfig={formConfig.fields}
            fieldValues={obj}
            mode={mode}
            onSubmit={this.onSubmit}
            onError={this.onError}
            enableFieldGrouping={false}
          />
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  // Already loaded the users... let's check if the Id is in the company users

  if (state.data.users && !!ownProps.params.userId && !state.data.users[ownProps.params.userId]) {
    // This user doesn't belong to company. Redirect...
    ownProps.navigate('/dashboard');
    AlertNotification('error', 'Forbiddden', "Insufficient permissions.")
    return {};
  }
  return {
    user:  _.get(state, 'data.users['+ownProps.params.userId+']'),
    loggedInUser: state.data.user,
    company: _.get(state, 'data.companies['+ownProps.params.companyId+']'),
    grouped_permissions: state.data.user_permissions_grouped
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSingleUser: (id, onSuccess, onFail) => {
      dispatch(getSingleUser(id, onSuccess, onFail));
    },
    companyUserAdd: (companyid, firstName, lastName, email, role, scope, project, onSuccess, onFail) => {
      dispatch(companyUserAdd(companyid, firstName, lastName, email, role, scope, project, onSuccess, onFail));
    },
    companyUserUpdate: (id, firstName, lastName, onSuccess, onFail) => {
      dispatch(companyUserUpdate(id, firstName, lastName, onSuccess, onFail));
    },
    getCompany: (id, onSuccess, onFail) => {
      dispatch(getCompany(id, onSuccess, onFail));
    },
    resendSetUserPasswordEmail: (id, onSuccess, onFail) => {
      dispatch(resendSetUserPasswordEmail(id, onSuccess, onFail))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyUserForm));