import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import React, {
  createRef,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FOLDER_TYPE } from "../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData } from "../../utils/commonDataUtils";
import get from "lodash/get";
import shuffle from "lodash/shuffle";
import cloneDeep from "lodash/cloneDeep";
import {
  indexFromIntervalArray,
  timeIntervalRemainderArrayMS
} from "../../utils/timeHelpers";
import { loopIndex } from "../../../shared-global/utils/general";
import DonorSeaName from "./DonorSeaName";
import {convertPxStringToEm} from "../../utils/generalUtils";

interface IDonor {
  name: string;
  show: boolean;
  sorting_name: string;
  style: CSSProperties;
  actual_index?: number;
}

enum WAVESTATUS {
  FADED_IN = "faded_in",
  FADED_OUT = "faded_out"
}

enum DONOR_VISIBILITY {
  FADE_IN = "fade_in",
  FADE_OUT = "fade_out"
}

// interface IDonorSea2106ComponentData {
//   nextDonorIndexCb?: () => {} // Used to save the next donor index in the case of a loop,
//   nextDonorIndex?: number; // In the case this exists, the donor sea will pick up the the list where it left off in the previous render.
// }

interface IScreen {
  style: {
    position: string;
    left: number;
    top: number;
    width: number;
    height: number;
  };
}

interface IDonorRow {
  donors: IDonor[];
  height: number;
  width: number;
  marginFromTop: number;
}

interface IRegion {
  rows: IDonorRow[];
  style: CSSProperties;
}

interface IPersistentData {
  id: number;
  type: string;
  data: { [key: string]: unknown };
}

/*
  Documentation on component specific properties in props:
  props.data.componentSpecificData {
    setFinishedPrerenderCallback: Function that gets a boolean value called when the prerender of donors is finished.
    cycleTimes: Cycle times that are passed down from parent and which are usually the same as the background videos.
    currentCycleTimeIndex: The index of the current cycle time in the cycleTimes array. Usually calculated based on computer's clock
    currentWaveTimeLeft: Time left in the current cycle index of the cycleTimes array. Usually calculated based on computer's clock.
  }
*/

/*
  Chronology of a wave
  |----------------|------------------------------|----------------------------------------------------|------------------------------|----------------|
      WAVE_DELAY         fadeInDonorsTimeOut                    Donors on the screen here                    fadeOutDonorsTimeOut         WAVE_DELAY
  |----------------------------------------------------------------------------------------------------------------------------------------------------|
                                                            Wave Cycle Time = Background Cycle Time
  WAVE_DELAY: Time we wait until donors start fading in after a background loop start. Current hardcoded to 4 seconds
  fadeInDonorsTimeOut: Total time it takes to fade in all donors. Loop will fade in donors one by one taking the total amount of fadeInDonorsTimeOut
  fadeOutDonorsTimeOut: Total time it takes to fade out all donors. Loop will fade out donors one by one taking the total amount of fadeOutDonorsTimeOut
  WAVE_DELAY: Time we wait after donors have faded out for the next cycle to start. Current hardcoded to 4 seconds
*/

/*
  Donor Sea algorithm:
  - Donors will fade in in the first wave as long as there's enough time left to do so.
  This is given the following condition: (CURRENT_WAVE_CYCLE_TIME - CYCLE_TIME_LEFT_IN_WAVE < CURRENT_WAVE_CYCLE_TIME - WAVE_FADE_DELAY - FADE_OUT_DONORS_CYCLE_TIME)
  I.E: CURRENT_WAVE_CYCLE_TIME = 30 SECONDS (Properties Panel Value set in the Background Folder)
       CYCLE_TIME_LEFT_IN_WAVE = 25 SECONDS (Calculated based on computer's clock)
       WAVE_DELAY = 4 SECONDS (Hardcoded value)
       FADE_OUT_DONORS_CYCLE_TIME = 5 SECONDS (Properties Panel Value set in Donor Sea Folder)
       The result of this is true which means there's enough time to fade donors in and out.
  - The time each donor fades in FADE_OUT_DONORS_CYCLE_TIME / AMOUNT_OF_DONORS_IN_THE_CYCLE
  FADE_OUT_DONORS_CYCLE_TIME: Set in properties panel in the Donor Sea Folder.
  AMOUNT_OF_DONORS_IN_THE_CYCLE: Determined by height and width of each donor as well as each screen height and width
  and padding and margins set for each screen.
*/

let _loopWaveCycleTimeoutHandler = null;
let _fadeInNextDonorTimeoutHandler = null;
let donorNameRefs = [];

const DonorSea2106: React.FC<IDisplayComponentProps> = (props) => {
  const WAVE_FADE_DELAY = 4000;
  const PRERENDER_TIMEOUT = 1000;
  let _highestDonorFound = null;
  let _widestDonorFound = null;
  let _narrowestDonorFound = null;
  let _donorsThatDontFitInTheRegion = [];
  let _longDonorFactor = 0.025;
  let _donorRefIndexKeeper = 0; // Keeps the current index of the donor ref saved.

  // State Variables.
  const [error, setError] = useState(-1);
  const [finishedPrerender, setFinishedPrerender] = useState(false);
  const [donorRefs, setDonorRefs] = useState([]);
  const [donorCategoryFolders, setDonorCategoryFolders] = useState<IFolder[]>(
    []
  );
  const [horizontalScreens, setHorizontalScreens] = useState(3);
  const [verticalScreens, setVerticalScreens] = useState(3);
  const [sortedDonors, setSortedDonors] = useState<IDonor[]>([]);
  const [shuffledDonors, _setShuffledDonors] = useState<IDonor[]>([]);
  const shuffledDonorsRef = useRef(shuffledDonors);
  const setShuffledDonors = data => {
    shuffledDonorsRef.current = data;
    _setShuffledDonors(data);
  }
  const [screens, setScreens] = useState<IScreen[]>([]);
  const [baseFolder, setBaseFolder] = useState<IFolder>(null);
  const [regionWidth, setRegionWidth] = useState(null);
  const [regionHeight, setRegionHeight] = useState(null);

  const [waveStatus, _setWaveStatus] = useState<WAVESTATUS>(
    WAVESTATUS.FADED_OUT
  );
  const waveStatusRef = useRef(waveStatus);
  const setWaveStatus = data => {
    waveStatusRef.current = data;
    _setWaveStatus(data);
  }

  const [waveIndex, _setWaveIndex] = useState(0);
  const waveIndexRef = useRef(waveIndex);
  const setWaveIndex = data => {
    waveIndexRef.current = data;
    _setWaveIndex(data);
  }

  const [epochMountedOn, _setEpochMountedOn] = useState(0);
  const epochMountedOnRef = useRef(epochMountedOn);
  const setEpochMountedOn = data => {
    epochMountedOnRef.current = data;
    _setEpochMountedOn(data);
  }

  const [lastCheckedDonor, _setLastCheckedDonor] = useState(0);
  const lastCheckedDonorRef = useRef(lastCheckedDonor);
  const setLastCheckedDonor = data => {
    lastCheckedDonorRef.current = data;
    _setLastCheckedDonor(data);
  }

  const screenLeftAndRightPadding = useMemo(() => {
    return get(baseFolder, 'fields.screen_left_and_right_padding', null) ?? get(baseFolder, 'fields.screen_left_and_right_margin', 0);
  }, [baseFolder]);

  const screenTopAndBottomPadding = useMemo(() => {
    return get(baseFolder, 'fields.screen_top_and_bottom_padding', null) ?? get(baseFolder, 'fields.screen_top_and_bottom_margin', 0);
  }, [baseFolder]);

  const [loopWaveCycleStarted, setLoopWaveCycleStarted] = useState(false);

  const renderError = () => {
    switch (error) {
      case 0:
        return <>Donor Sea Categories Folder Not Found</>;
      default:
        return <>An Unknown Error has Occured</>;
    }
  };

  const setupDonors = () => {
    const flattenDonors = donorCategoryFolders.reduce((acc, curr) => {
      const donorFolders = getChildrenData(props.data, curr.id, true).folders.map(
        (df) => {
          const categoriesFolderType = get(props, 'data.componentSpecificData.categoriesFolderType', FOLDER_TYPE.boston_donor_sea_categories)
          const fontSize = categoriesFolderType === FOLDER_TYPE.boston_donor_sea_categories ?
            get(curr, "fields.donor_name_style.fontSize", 10) + "em" :
            convertPxStringToEm(get(curr, "fields.donor_name_style.fontSize", 10)) + "em"
          return {
            name: df.name,
            sorting_name: df.fields.sorting_order ?? df.name,
            style: {
              fontSize,
              color: get(curr, "fields.donor_name_style.color", '#000000'),
              fontFamily: get(curr, "fields.donor_name_style.font", 'Roboto'),
              lineHeight: get(curr, 'fields.donor_name_style.lineHeight', 1),
            }
          }
        }
      );
      acc = [...acc, ...donorFolders];
      return acc;
    }, []);
    const flattenSortedDonors = orderBy(
      flattenDonors,
      [(donors) => donors.sorting_name.toLowerCase()],
      ["asc"]
    );
    setSortedDonors(flattenSortedDonors);
  };

  const setupScreenRegions = () => {
    /*
    I.E: Example of a 5 horizontal screens and 2 vertical screens
    |------------- props.container_width-------------------|
    -------------------------------------------------------- -
    |          |          |          |          |          | |
    |   [0,0]  |   [1,0]  |   [2,0]  |   [3,0]  |  [4,0]   | |
    |__________|__________|__________|__________|__________| props.container_height
    |          |          |          |          |          | |
    |   [0,1]  |   [1,1]  |   [2,1]  |   [3,1]  |  [4,1]   | |
    |          |          |          |          |          | |
    -------------------------------------------------------- -
    each element of the screen is a region. [0,0] is a region for instance.
    */

    const calculatedRegionWidth =
      props.containerWidth / horizontalScreens;
    const calculatedRegionHeight =
      props.containerHeight / verticalScreens
    const totalScreens =
      horizontalScreens * verticalScreens;

    // Effective region width is total width minus the padding passed in the props for all the regions.
    // Same applies to effective region height.
    const effectiveRegionWidth =
      calculatedRegionWidth -
      calculatedRegionWidth *
      screenLeftAndRightPadding / 100 *
      2;
    const effectiveRegionHeight =
      calculatedRegionHeight -
      calculatedRegionHeight *
      screenTopAndBottomPadding / 100 *
      2;

    const arrayOfScreens: IScreen[] = [];
    for (let i = 0; i < totalScreens; i++) {
      // Vertical index or position is calculated with the module operator (%) instead of
      // looping through another set of vertical screens.
      // Vertical index corresponds to y in a [x,y] screen position.
      const horizontalIndex = Math.floor(i / verticalScreens);
      const verticalIndex = i % verticalScreens;

      // Here we check if the current screen we are checking is in the disabledScreens
      // array. I.E: Checking if current screen [0,1] is in the array [[0,1]] of disabled screens
      // Screen location is [x,y]
      const disabledScreensArr = get(props, 'data.componentSpecificData.disabled_screens', baseFolder.fields.disabled_screens);
      if (disabledScreensArr) {
        // Parse the string '[0,1], [1, 1]' into an array that looks like
        // [[0,1], [1,1]]
        const parsedDisabledScreensCoordinates =
          disabledScreensArr
            .split(",")
            .map((s) => Number(s.trim().replace("[", "").replace("]", "").split(",")));
        let disabledScreens = [];
        let currentDisabledScreensIndex = 0;
        for (let i = 0; i < parsedDisabledScreensCoordinates.length; i += 1) {
          if (!disabledScreens[currentDisabledScreensIndex]) {
            disabledScreens.push([parsedDisabledScreensCoordinates[i]]);
          } else {
            disabledScreens[currentDisabledScreensIndex].push(parsedDisabledScreensCoordinates[i]);
            currentDisabledScreensIndex += 1;
          }
        }
        const foundDisabledScreen = disabledScreens.find((s) =>
          isEqual(s, [horizontalIndex, verticalIndex])
        );
        if (foundDisabledScreen) {
          continue;
        }
      }

      let top =
        (i % verticalScreens) * calculatedRegionHeight +
        calculatedRegionHeight *
        screenTopAndBottomPadding / 100;
      let left =
        Math.floor(i / verticalScreens) *
        calculatedRegionWidth +
        calculatedRegionWidth * screenLeftAndRightPadding / 100;

      arrayOfScreens.push({
        style: {
          position: "absolute",
          left: left,
          top: top,
          width: effectiveRegionWidth,
          height: effectiveRegionHeight
        }
      });
    }

    setScreens(arrayOfScreens);
    setRegionWidth(effectiveRegionWidth);
    setRegionHeight(effectiveRegionHeight);
  };

  const preRenderDonors = () => {
    const renderedDonors = sortedDonors.map((donor, i) => {
      const donorStyle: CSSProperties = {
        fontSize: donor.style.fontSize,
        fontFamily: donor.style.fontFamily ?? "Roboto",
        lineHeight: donor.style.lineHeight,
        color: donor.style.fontFamily ?? "#000000",
        opacity: 0,
        position: "absolute",
        whiteSpace: "pre-wrap"
      };
      return (
        <span
          key={i}
          ref={(el) => (donorRefs[i] = el)}
          style={{ ...donorStyle }}
        >
          {donor.name}
        </span>
      );
    });

    return renderedDonors;
  };

  // 1st effect
  useEffect(() => {
    setEpochMountedOn(new Date().getTime());
    const foundBaseFolder = getBaseFolder(props.data);

    // Settings screens
    const foundHorizontalScreens = get(props, 'data.componentSpecificData.horizontal_screens', foundBaseFolder.fields.horizontal_screens) ?? 3;
    const foundVerticalScreens = get(props, 'data.componentSpecificData.vertical_screens', foundBaseFolder.fields.vertical_screens) ?? 2;

    setHorizontalScreens(foundHorizontalScreens);
    setVerticalScreens(foundVerticalScreens);

    if (props.persistent_data) {
      // console.log('DonorSea2106: foundPersistentData', props.persistent_data);
      // console.log('DonorSea2106: Base Folder', foundBaseFolder);
      const persistentDataArray = (props.persistent_data as IPersistentData[]);
      // console.log('DonorSea2106: PersistentData ARray', persistentDataArray);
      const foundPersistentData = persistentDataArray.find(d => d.id === foundBaseFolder.id);
      if (foundPersistentData) {
        setLastCheckedDonor(foundPersistentData.data.donor_index as number);
        // _lastCheckedDonor = foundPersistentData.data.donor_index as number;
      }
    }

    const childFolders = getChildrenData(
      props.data,
      foundBaseFolder.id,
      true
    ).folders;

    const categoriesFolderType = get(props, 'data.componentSpecificData.categoriesFolderType', FOLDER_TYPE.boston_donor_sea_categories)

    const categoriesFolder = childFolders.find(
      (f) => f.folder_type === categoriesFolderType
    );
    if (!categoriesFolder) {
      setError(0);
      return;
    }
    const childCategoriesFolders = getChildrenData(
      props.data,
      categoriesFolder.id,
      true
    ).folders;
    setDonorCategoryFolders(childCategoriesFolders);
    setBaseFolder(foundBaseFolder);

    const currentWaveCycleTimeIndex = get(
      props,
      " data.componentSpecificData.currentWaveCycleTimeIndex",
      0
    );
    if (currentWaveCycleTimeIndex !== 0) {
      setWaveIndex(currentWaveCycleTimeIndex);
    }

    return () => {
      setLastCheckedDonor(0);
      setWaveStatus(WAVESTATUS.FADED_OUT);
      clearTimeout(_loopWaveCycleTimeoutHandler);
      clearTimeout(_fadeInNextDonorTimeoutHandler);
    };
  }, []);

  // 2nd effect
  useEffect(() => {
    if (donorCategoryFolders) {
      setupDonors();
    }
  }, [donorCategoryFolders]);

  // 3rd effect
  useEffect(() => {
    if (sortedDonors && sortedDonors.length) {
      // We create empty ref for each donor in the array.
      if (donorRefs.length !== sortedDonors.length) {
        Array.from(Array(sortedDonors.length).keys()).forEach(
          (_, i) => donorRefs[i] || createRef()
        );
      }

      // The reason we set a timeout is so we give enough time for donors to
      // be prerendered.
      const donorRefsTimeoutHandler = setTimeout(() => {
        // console.log("running the timeout function");
        if (donorRefs.length > 0 && !finishedPrerender) {
          for (let index in donorRefs) {
            const ref = donorRefs[index];
            const dimensions = ref.getBoundingClientRect();
            sortedDonors[index].style.height = dimensions.height;
            sortedDonors[index].style.width = dimensions.width;
            _highestDonorFound =
              sortedDonors[index].style.height > _highestDonorFound
                ? sortedDonors[index].style.height
                : _highestDonorFound;
            _widestDonorFound =
              sortedDonors[index].style.width > _widestDonorFound
                ? sortedDonors[index].style.width
                : _widestDonorFound;
            _narrowestDonorFound =
              sortedDonors[index].style.height > _narrowestDonorFound
                ? sortedDonors[index].style.height
                : _narrowestDonorFound;
          }
        }
        setupScreenRegions();
        setFinishedPrerender(true);
      }, PRERENDER_TIMEOUT);

      return () => {
        clearTimeout(donorRefsTimeoutHandler);
      };
    }
  }, [sortedDonors]);

  // Called randomizeDonorPositions on v1
  const randomizeScreenRegionDonorPositions = (regions: IRegion[]) => {
    var donors = [];
    const minMarginBetweenNamesHorizontally = get(
      baseFolder,
      "fields.minMarginBetweenNamesHorizontally",
      3
    );

    const headerHeight = get(props, 'data.componentSpecificData.headerHeight', 0)

    for (let i = 0; i < regions.length; i++) {
      var rowTopAccum = 0;
      for (let j = 0; j < regions[i].rows.length; j++) {
        var donorLeftAccum = 0;
        var occupiedSpace = 0;

        let marginFromTop = regions[i].rows[j].marginFromTop;
        let totalEmptyRowSpace = regionWidth - regions[i].rows[j].width;
        let emptyRowSpaceLeft = totalEmptyRowSpace;
        for (let k = 0; k < regions[i].rows[j].donors.length; k++) {
          // Top
          let maxTopPadding =
            regions[i].rows[j].height -
            Number(regions[i].rows[j].donors[k].style.height);
          let minTopPadding = 0;
          let randomTopPadding = 0;
          if (maxTopPadding > 0) {
            randomTopPadding =
              Math.random() * (maxTopPadding - minTopPadding) + minTopPadding;
          }
          let top =
            (i === 0 && headerHeight ? headerHeight : 0) +
            Number(regions[i].style.top) +
            rowTopAccum +
            randomTopPadding +
            marginFromTop * j;

          // Left
          let max = emptyRowSpaceLeft / (regions[i].rows[j].donors.length - k);
          let min =
            (minMarginBetweenNamesHorizontally / 100) * regionWidth >= max
              ? max
              : (minMarginBetweenNamesHorizontally / 100) * regionWidth;
          let randomLeft = Math.random() * (max - min) + min;
          occupiedSpace += randomLeft;
          emptyRowSpaceLeft -= randomLeft;
          let left =
            Number(regions[i].style.left) + donorLeftAccum + occupiedSpace;
          donorLeftAccum += Number(regions[i].rows[j].donors[k].style.width);

          let newStyle = Object.assign({}, regions[i].rows[j].donors[k].style);
          newStyle.top = top;
          newStyle.left = left;
          regions[i].rows[j].donors[k].style = newStyle;
          donors.push(Object.assign({}, regions[i].rows[j].donors[k]));
        }
        rowTopAccum += regions[i].rows[j].height;
      }
    }

    return donors;
  };

  // Called setupDonorRows on v1
  const setupScreenRegionsAndDonors = (): IRegion[] => {
    // console.log('Donor Sea: setupScreenRegionsAndDonors');
    let rowDonors = [];
    let rows = [];
    let rowHeight = 0;
    let rowWidth = 0;
    let rowWidthCondition = 0;
    let totalRowHeight = 0;
    let index = lastCheckedDonorRef.current;
    let tmpRow = [];
    let regions = [];

    const minMarginBetweenNamesVertically = get(
      baseFolder,
      "fields.min_margin_between_names_vertically",
      3
    );
    const minMarginBetweenNamesHorizontally = get(
      baseFolder,
      "fields.min_margin_between_names_horizontally",
      3
    );

    const headerHeight = get(props, 'data.componentSpecificData.headerHeight', 0)

    for (let screenIndex in screens) {
      const screenIndexNumber = Number(screenIndex);
      const screenHeight = screenIndexNumber === 0 && headerHeight ? regionHeight - headerHeight : regionHeight
      do {
        // If the donor is wider than the screen, continue loop.
        if (Number(sortedDonors[index].style.width) > regionWidth) {
          index = loopIndex(index, 0, sortedDonors.length - 1, "forward");
          continue;
        }

        if (
          rowWidthCondition + Number(sortedDonors[index].style.width) >
          regionWidth
        ) {
          totalRowHeight +=
            rowHeight + (screenHeight * minMarginBetweenNamesVertically) / 100;

          // Row Width Correction.
          if (totalRowHeight < screenHeight) {
            let marginFromTop =
              rows.length === 0
                ? 0
                : screenHeight * (minMarginBetweenNamesVertically / 100);
            rows.push({
              donors: rowDonors,
              height: rowHeight,
              width: rowWidth,
              marginFromTop: marginFromTop
            });
          }

          tmpRow = [
            {
              donors: rowDonors,
              height: rowHeight,
              width: rowWidth,
              marginFromTop: 0
            }
          ];
          rowDonors = [];
          rowWidth = 0;
          rowWidthCondition = 0;
          rowHeight = totalRowHeight < screenHeight ? 0 : rowHeight;
        } else {
          sortedDonors[index].actual_index = index;
          rowDonors.push(cloneDeep(sortedDonors[index]));
          rowHeight =
            rowHeight > Number(sortedDonors[index].style.height)
              ? rowHeight
              : Number(sortedDonors[index].style.height);
          rowWidth += Number(sortedDonors[index].style.width);
          rowWidthCondition +=
            Number(sortedDonors[index].style.width) * (1 + _longDonorFactor) +
            (regionWidth * minMarginBetweenNamesHorizontally) / 100;
          index = loopIndex(index, 0, sortedDonors.length - 1, "forward");
        }
      } while (totalRowHeight < screenHeight);

      regions[screenIndexNumber] = {
        rows: rows,
        style: Object.assign({}, screens[screenIndexNumber].style)
      };

      totalRowHeight = tmpRow && tmpRow.length > 0 ? tmpRow[0].height : 0;
      rows = tmpRow && tmpRow.length > 0 ? tmpRow : [];

      // If there's a temp row and there are no more regions availables,
      // Set the lastCheckedDonor equal to the current donor index (last index checked)
      // Minus the length of the donors contained in the temp row which will be wasted
      if (screenIndexNumber === screens.length - 1 && tmpRow.length > 0) {
        if (index < tmpRow[0].donors.length) {
          setLastCheckedDonor(tmpRow[0].donors[0].actual_index);
          // _lastCheckedDonor = tmpRow[0].donors[0].actual_index;
        } else {
          setLastCheckedDonor(index - tmpRow[0].donors.length);
          // _lastCheckedDonor = index - tmpRow[0].donors.length;
        }
      } else {
        setLastCheckedDonor(index);
        // _lastCheckedDonor = index;
      }

      rowWidth = 0;
      rowHeight = 0;
      tmpRow = [];
    }

    return regions;
  };

  // This functions returns regions with rows
  const setupShuffledDonorsForWave = (keepCurrentWave = false) => {
    // console.log('Donor Sea: Shuffling Donors Wave');
    // Check if all the donors fit the region's width.
    for (let donor of sortedDonors) {
      if (donor.style.width > regionWidth) {
        _donorsThatDontFitInTheRegion.push(donor);
      }
    }

    let newShuffledDonors = [];
    if (keepCurrentWave) {
      if (shuffledDonors.length > 0) {
        newShuffledDonors = shuffledDonors;
      } else {
        const regions = setupScreenRegionsAndDonors();
        const donors = randomizeScreenRegionDonorPositions(regions);
        newShuffledDonors = shuffle(donors);
      }
    } else {
      // _donorRefIndexKeeper = 0;
      const regions = setupScreenRegionsAndDonors();
      const donors = randomizeScreenRegionDonorPositions(regions);
      newShuffledDonors = shuffle(donors);
    }

    donorNameRefs = [];
    setShuffledDonors(newShuffledDonors);
  };

  const fadeNextDonor = (type: DONOR_VISIBILITY = DONOR_VISIBILITY.FADE_IN, shuffleDonors = false) => {
    // console.log('Donor Sea: Fade Next Donor In');
    const fadeInDonorsCycleTime = get(
      baseFolder,
      "fields.fade_in_donors_time",
      0
    );
    let donorGroupsAmount = Math.ceil(shuffledDonors.length / 20);

    let donorCycleTime = 0;
    if (_donorRefIndexKeeper !== 0) {
      donorCycleTime = fadeInDonorsCycleTime * 1000 / shuffledDonors.length - 1;
    }

    // console.log('Donor Sea: Donor Cycle Time is: ' + donorCycleTime + ' ms');

    clearTimeout(_fadeInNextDonorTimeoutHandler);
    _fadeInNextDonorTimeoutHandler = setTimeout(() => {
      // console.log('Donor Sea: executing fade in');
      if (shuffledDonors.length - _donorRefIndexKeeper < donorGroupsAmount) {
        donorGroupsAmount = shuffledDonors.length - _donorRefIndexKeeper;
      }

      // Here's where we actually fade in/out the current donor index.
      const isVisible = type === DONOR_VISIBILITY.FADE_IN ? true : false;
      // console.log('Donor Sea: Refs', donorNameRefs);
      // console.log('Donor Sea: _donorRefIndexKeeper: ' + _donorRefIndexKeeper);
      donorNameRefs[_donorRefIndexKeeper].toggleVisibility(isVisible);

      if (_donorRefIndexKeeper < shuffledDonorsRef.current.length - 1) {
        _donorRefIndexKeeper += 1;
        fadeNextDonor(type, shuffleDonors);
      } else {
        _donorRefIndexKeeper = 0;
        // If last donor to be faded out, we shuffle donors.
        if (type === DONOR_VISIBILITY.FADE_OUT && shuffleDonors) {
          setupShuffledDonorsForWave();
        }
      }
    }, donorCycleTime);
  };

  const getNextWaveIndex = () => {
    const cycleTimes = get(
      props,
      "data.componentSpecificData.cycleTimes",
      []
    );
    if (waveIndexRef.current === cycleTimes.length - 1) {
      return 0;
    }
    return waveIndexRef.current + 1;
  }

  const loopWaveCycle = (timeToCheck = 0, lastLoop = false) => {
    clearTimeout(_loopWaveCycleTimeoutHandler);
    // console.log('Donor Sea: Scheduling next cycle for: ' + timeToCheck + " ms from now");
    _loopWaveCycleTimeoutHandler = setTimeout(() => {
      // console.log('Donor Sea: Executing cycle');
      if (lastLoop && props.handleEndOfPlay) {
        // console.log('Donor Sea: Last Checked Donor', lastCheckedDonorRef.current);
        props.handleEndOfPlay(lastCheckedDonorRef.current);
        return;
      }

      const cycleTimes = get(
        props,
        "data.componentSpecificData.cycleTimes",
        []
      );

      const isCycleTimeBasedOnComputerClock = get(props, 'data.componentSpecificData.isCycleTimeBasedOnComputerClock', false);
      // console.log('Donor Sea: isCycleTimeBasedOnComputerClock', isCycleTimeBasedOnComputerClock);
      let cycleTimeLeftInCurrentWave =
        timeIntervalRemainderArrayMS(cycleTimes);
      let currentWaveCycleTimeIndex = indexFromIntervalArray(cycleTimes);

      if (timeToCheck === 0 && !isCycleTimeBasedOnComputerClock) {
        cycleTimeLeftInCurrentWave = cycleTimes[0];
        currentWaveCycleTimeIndex = 0;
      }

      if (isCycleTimeBasedOnComputerClock) {
        cycleTimeLeftInCurrentWave =
          timeIntervalRemainderArrayMS(cycleTimes);
        currentWaveCycleTimeIndex = indexFromIntervalArray(cycleTimes);
      } else {
        currentWaveCycleTimeIndex = waveIndexRef.current;
        const timePassedSinceMounted = new Date().getTime() - epochMountedOnRef.current;
        // console.log('Donor Sea: Time passed since mounted ' + timePassedSinceMounted);
        let cycleTimesSum = [];
        cycleTimes.reduce((a, b, i) => {
          return (cycleTimesSum[i] = a + b);
        }, 0);
        // console.log('Donor Sea: Cycle Times Sum ' + cycleTimesSum);
        const index = cycleTimesSum.findIndex(c => c > timePassedSinceMounted);
        cycleTimeLeftInCurrentWave = cycleTimesSum[index] - timePassedSinceMounted;
      }

      const fadeOutDonorsCycleTime = get(
        baseFolder,
        "fields.fade_out_donors_time",
        0
      ) * 1000;
      const fadeInDonorsCycleTime = get(
        baseFolder,
        "fields.fade_in_donors_time",
        0
      ) * 1000;

      // console.log('Donor Sea: Current Wave is ' + currentWaveCycleTimeIndex + ' and lasts ' + cycleTimes[currentWaveCycleTimeIndex] + ' ms');
      // console.log('Donor Sea: Current Wave\'s time left is: ' + cycleTimeLeftInCurrentWave);
      // console.log('Donor Sea: Wave status is: ' + _waveStatus);

      // Determine if donors should be faded in or out.
      if (timeToCheck !== 0) {
        if (
          cycleTimes[currentWaveCycleTimeIndex] - cycleTimeLeftInCurrentWave <
          cycleTimes[currentWaveCycleTimeIndex] - WAVE_FADE_DELAY - fadeOutDonorsCycleTime
        ) {
          const cycleTimeToCheckNext =
            cycleTimeLeftInCurrentWave - WAVE_FADE_DELAY - fadeOutDonorsCycleTime;
          const safeCycleTimeToCheckNext = cycleTimeToCheckNext < 0 ? 0 : cycleTimeToCheckNext;

          // If wave is faded out we faded it in.
          if (waveStatusRef.current === WAVESTATUS.FADED_OUT) {
            // console.log('Donor Sea: Donors will fade in because there\'s enough time');
            // _waveStatus = WAVESTATUS.FADED_IN;
            setWaveStatus(WAVESTATUS.FADED_IN);
            fadeNextDonor(DONOR_VISIBILITY.FADE_IN);

            // Calculate next wave check which will be for fading out donors X seconds before the
            // current cycle ends. X seconds correspond to WAVE_FADE_DELAY
            loopWaveCycle(safeCycleTimeToCheckNext);
          } else {
            // console.log('Donor Sea: Looks like donors are on the screen, scheduling for ' + cycleTimeToCheckNext + ' ms from now');
            loopWaveCycle(safeCycleTimeToCheckNext);
          }
        } else {
          // If wave is faded in we fade it out.
          if (waveStatusRef.current === WAVESTATUS.FADED_IN) {
            // console.log('Donor Sea: Donors will fade out');
            // _waveStatus = WAVESTATUS.FADED_OUT;
            setWaveStatus(WAVESTATUS.FADED_OUT);

            // We check if this is the last cycle, if so, the next call should be for handleEndOfPlay.
            // else we schedule the next cycle.
            if (currentWaveCycleTimeIndex === cycleTimes.length - 1) {
              // TODO: We need to save the current index of the last donor in the wave plus 1 in the
              // higher level component
              if (props.handleEndOfPlay) {
                // console.log('Donor Sea: Handle End Of Play');
                fadeNextDonor(DONOR_VISIBILITY.FADE_OUT);
                loopWaveCycle(cycleTimeLeftInCurrentWave, true);
              } else {
                fadeNextDonor(DONOR_VISIBILITY.FADE_OUT, true);
                setWaveIndex(getNextWaveIndex());
                // epochMountedOn = new Date().getTime();
                setEpochMountedOn(new Date().getTime());
                const cycleTimeToCheckNext =
                  cycleTimeLeftInCurrentWave + WAVE_FADE_DELAY;
                // console.log('Donor Sea: waveIndex ' + waveIndexRef.current);
                // console.log('Donor Sea: cycleTimeToCheckNext ' + cycleTimeToCheckNext);

                loopWaveCycle(cycleTimeToCheckNext);
              }
            } else {
              fadeNextDonor(DONOR_VISIBILITY.FADE_OUT, true);
              const cycleTimeToCheckNext =
                cycleTimeLeftInCurrentWave + WAVE_FADE_DELAY;
              // console.log('Donor Sea: cycleTimeLeftInCurrentWave: ', cycleTimeLeftInCurrentWave);
              // console.log('Donor Sea: Scheduling next cycle for ' + cycleTimeToCheckNext + ' ms from now');
              setWaveIndex(getNextWaveIndex());
              loopWaveCycle(cycleTimeToCheckNext);
            }
          } else {
            const cycleTimeToCheckNext =
              cycleTimeLeftInCurrentWave + WAVE_FADE_DELAY;
            // console.log('Donor Sea: Doing nothing. Scheduling cycle for ' + cycleTimeToCheckNext + ' ms from now');
            loopWaveCycle(cycleTimeToCheckNext);
          }
        }
      } else {
        if (cycleTimeLeftInCurrentWave > WAVE_FADE_DELAY + fadeOutDonorsCycleTime + fadeInDonorsCycleTime) {
          loopWaveCycle(1);
        } else {
          const cycleTimeToCheckNext = cycleTimeLeftInCurrentWave + WAVE_FADE_DELAY;
          loopWaveCycle(cycleTimeToCheckNext);
        }
      }
    }, timeToCheck);
  };

  // 4th effect
  useEffect(() => {
    if (finishedPrerender) {
      // When the prerender is finished, we let the parent component know this by calling, if available,
      // the callback prop function setFinishedPrerenderCallback. An example of the usage is when we
      // want to preserve synchronicity with a backgrounds loop component.
      // const setFinishedPrerenderCallback = get(
      //   props,
      //   "data.componentSpecificData.setFinishedPrerenderCallback",
      //   null
      // );
      // if (setFinishedPrerenderCallback) {
      //   setFinishedPrerenderCallback(true);
      // }

      setupShuffledDonorsForWave();
    }
  }, [finishedPrerender]);

  // 5th effect. The start of our wave cycle.
  useEffect(() => {
    if (shuffledDonors.length && !loopWaveCycleStarted) {
      // console.log('Donor Sea: LoopWaveCycle()');
      loopWaveCycle();
      setLoopWaveCycleStarted(true);
    }
  }, [shuffledDonors]);

  if (error > -1) {
    return renderError();
  }

  if (!finishedPrerender && sortedDonors && sortedDonors.length) {
    const donorStyle: any = {};
    if (get(props, 'data.componentSpecificData.categoriesFolderType', FOLDER_TYPE.boston_donor_sea_categories) === FOLDER_TYPE.boston_donor_sea_categories) {
      donorStyle.fontSize = props.containerHeight / 1000
    }
    return <div style={donorStyle}>{preRenderDonors()}</div>;
  }

  // console.log('Donor Sea: ShuffledDonors are: ', shuffledDonors);
  // console.log('Donor Sea: donorNameRefs are: ', donorNameRefs);
  // console.log('Donor Sea: Sorted Donors are: ', sortedDonors);
  // console.log('Donor Sea: ShuffledDonors length is: ', shuffledDonors.length);

  if (shuffledDonors && shuffledDonors.length) {
    const donorStyle: any = { width: props.containerWidth, height: props.containerHeight };
    if (get(props, 'data.componentSpecificData.categoriesFolderType', FOLDER_TYPE.boston_donor_sea_categories) === FOLDER_TYPE.boston_donor_sea_categories) {
      donorStyle.fontSize = props.containerHeight / 1000
    }
    return (
      <div style={donorStyle}>
        {shuffledDonors.map((donor, i) => (
          <DonorSeaName
            ref={el => donorNameRefs[i] = el}
            key={`rendered_donor_${i}`}
            name={donor.name}
            style={donor.style}
          />
        ))}
      </div>
    );
  }

  return <></>;
};

export default DonorSea2106;
