import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { IFolderFolder } from "../../../shared-global/interfaces/models/folder__folder.interface";
import { IResource } from "../../../shared-global/interfaces/models/resource.interface";
import { IResourceFolder } from "../../../shared-global/interfaces/models/resource__folder.interface";

export interface IResourceResourceFolder {
  resource: IResource;
  resource__folder: IResourceFolder;
}

export interface IFolderFolderFolder {
  folder: IFolder;
  folder__folder: IFolderFolder;
}

export enum MODE {
  SINGLE_SELECTION = "single",
  MULTIPLE_SELECTION = "multiple"
}

export enum RESOURCE_EXPLORER_MODE {
  MODAL = 'modal',
  CONTENT_LIBRARY = 'content_library'
}

export enum RESOURCE_FOLDER_TYPE {
  COMPANY = 'companny',
  FOLDER = 'folder'
}

export enum THUMBNAIL_SIZE {
  SMALL = "small",
  NORMAL = "normal",
  BIG = "big"
}
