import React, {useCallback, useEffect, useMemo, useState} from 'react'
import get from "lodash/get";
import DonorList2107 from '../../standard/DonorList2107'
import {getFieldValue} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";

const EverettDonorListWithQuote = (props) => {

  const [topElements, setTopElements] = useState(null)
  const [footerEl, setFooterEl] = useState(null)
  const [containerEl, setContainerEl] = useState(null)
  const [topBarEl, setTopBarEl] = useState(null)
  const [donorListWrapperEl, setDonorListWrapperEl] = useState(null)
  const fontColorDefault = props.defaultDonorColor;


  const convertPxToEm = (value) => {
    if (typeof value === 'string') {
      value = Number(value.replace('px', '').replace('em', '').trim());
    }
    return value / 16
  }

  const getPropertiesFromFolder = () => {
    const folder = props.baseFolder

    const donorNameFontSize = convertPxToEm(get(folder, "fields.donor_names.fontSize", 16))

    const header = getFieldValue(folder, 'donor_list_header', props.baseFolderType, props.showPlaceholder )
    const description = getFieldValue(folder, 'description', props.baseFolderType, props.showPlaceholder )
    const category = getFieldValue(folder, 'category', props.baseFolderType, props.showPlaceholder )
    const footer = getFieldValue(folder, 'footer', props.baseFolderType, props.showPlaceholder )

    const showUpperRule = get(
      folder,
      "fields.show_upper_rule",
      true
    );

    const showLowerRule = get(
      folder,
      "fields.show_lower_rule",
      true
    );

    const horizontalRuleColor = get(
      folder,
      "fields.horizontal_rule_color",
      "#c5aa75"
    );

    const horizontalRuleSize = get(
      folder,
      "fields.horizontal_rule_size",
      0.125
    );

    const listMarginLeft = Number(get(
      folder,
      "fields.list_margin_left",
      5
    ));

    const listMarginTop = Number(get(
      folder,
      "fields.list_margin_top",
      0
    ));

    return {
      header,
      description,
      category,
      horizontalRuleColor,
      horizontalRuleSize,
      showUpperRule,
      showLowerRule,
      footer,
      listMarginLeft,
      listMarginTop,
      donorNameFontSize
    };
  };

  const {
    header,
    description,
    category,
    horizontalRuleColor,
    horizontalRuleSize,
    showUpperRule,
    showLowerRule,
    footer,
    donorNameFontSize
  } = getPropertiesFromFolder();

  const getDonorListHeightAndOffset = () => {
    let effectiveDonorListHeight = props.refHeight;
    let totalOffset = 0

    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl)
      const paddingArr = computedStyles.getPropertyValue('padding-top').split('px')
      const paddingBotArr = computedStyles.getPropertyValue('padding-bottom').split('px')
      const paddingTopNumber = Number(paddingArr[0])
      const paddingBottomNumber = Number(paddingBotArr[0])
      effectiveDonorListHeight -= (paddingTopNumber + paddingBottomNumber)
      totalOffset += paddingTopNumber
    }

    if (topElements) {
      const topHeight = topElements.getBoundingClientRect().height;
      effectiveDonorListHeight -= topHeight
      totalOffset += topHeight
    }

    if (topBarEl) {
      const topBarHeight = topBarEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= topBarHeight
      totalOffset += topBarHeight
    }

    if (donorListWrapperEl) {
      const computedStyles = window.getComputedStyle(donorListWrapperEl)
      const marginArr = computedStyles.getPropertyValue('margin-top').split('px')
      const marginNumber = Number(marginArr[0])
      effectiveDonorListHeight -= marginNumber
      totalOffset += marginNumber
    }

    if (footerEl) {
      const footerHeight = footerEl.getBoundingClientRect().height;
      const computedStyles = window.getComputedStyle(footerEl)
      const marginArr = computedStyles.getPropertyValue('margin-top').split('px')
      const marginTopNumber = Number(marginArr[0])
      effectiveDonorListHeight -= footerHeight + marginTopNumber
    }

    const listMarginTop = get(
      props.baseFolder,
      "fields.list_margin_top",
      0
    )

    if (listMarginTop > 0) {
      const donorListMarginTop = listMarginTop * (effectiveDonorListHeight / 100)
      totalOffset += donorListMarginTop
    }


    return {effectiveDonorListHeight, totalOffset};
  };

  const getDonorListWidth = () => {
    let effectiveDonorListWidth = props.refWidth / 2
    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl)
      const paddingArr = computedStyles.getPropertyValue('padding-left').split('px')
      const paddingNumber = Number(paddingArr[0])
      effectiveDonorListWidth -= paddingNumber * 2
    }

    return effectiveDonorListWidth
  }

  return (
    <div style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingTop: '7.5em',
        paddingLeft: '7.5em',
        paddingRight: '7.5em',
        paddingBottom: '2em'
      }}
         ref={(htmlEl) => setContainerEl(htmlEl)}
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          overflow: 'hidden',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <div
          ref={(divElement) => setTopElements(divElement) }
        >
          <div
            style={{
              marginBottom: '0.5em',
              lineHeight: 1.25,
              letterSpacing: '0.075em'
            }}
          >
            <RichTextRender htmlString={header} />
          </div>
          <div
            style={{
              lineHeight: 1.25,
              marginBottom: '1.5em'
            }}
          >
            <RichTextRender htmlString={description} />
          </div>
          <div
            style={{
              marginBottom: '0.5em',
              letterSpacing: '0.1em'
            }}
          >
            <RichTextRender htmlString={category} />
          </div>
        </div>
        {showUpperRule &&
          <div
            className="upper-bar"
            style={{
              width: '100%'
            }}
            ref={(htmlEl) => setTopBarEl(htmlEl)}
          >
            <hr
              style={{
                backgroundColor: horizontalRuleColor,
                border: "0 none",
                color: horizontalRuleColor,
                height: props.containerWidth * (horizontalRuleSize / 100),
                marginTop: "0.5em",
              }}
            />
          </div>
        }
      {/*  DONORS */}
        <div
          style={{
            marginTop: '3em',
            position: 'relative',
            height: getDonorListHeightAndOffset().effectiveDonorListHeight,
          }}
          ref={(htmlEl) => setDonorListWrapperEl(htmlEl)}
        >
          {
            topElements && footerEl && (
              <DonorList2107
                {...props}
                key={props.baseFolder.id}
                containerHeight={getDonorListHeightAndOffset().effectiveDonorListHeight}
                refWidth={props.refWidth / 2} // idisplaycoponentprop?
                refHeight={props.refHeight}
                containerWidth={getDonorListWidth()}
                data={{
                  ...props.data,
                  componentSpecificData: {
                    list_style: get(
                      props.baseFolder,
                      "fields.donor_list_style",
                      "filled"
                    ),
                    gapOptions: {
                      showGap: true,
                      gapPositions: [props.refHeight / 2 - getDonorListHeightAndOffset().totalOffset],
                      listOffset: getDonorListHeightAndOffset().totalOffset,
                      gapHeight: props.refHeight * (get(
                        props.baseFolder,
                        "fields.list_margin_bezel",
                        0
                      ) / 100)
                    },
                    columns: get(props.baseFolder, "fields.columns", 3),
                    container_margin_top: get(
                      props.baseFolder,
                      "fields.list_margin_top",
                      0
                    ),
                    donor_list_margin_left: get(
                      props.baseFolder,
                      "fields.list_margin_left",
                      0
                    ),
                    donor_list_margin_right: get(
                      props.baseFolder,
                      "fields.list_margin_right",
                      0
                    ),
                    donor_list_column_margin_right: get(
                      props.baseFolder,
                      "fields.column_margin_right",
                      0
                    ),
                    cycle: get(props.baseFolder, "fields.donor_cycle_time", 5) * 1000,
                    donor_vertical_separation: get(
                      props.baseFolder,
                      "fields.donor_name_margin_bottom",
                      0
                    ),
                    donor_line_height:
                      get(props.baseFolder, "fields.donor_line_height", 1) * 100,
                    text_alignment: get(props.baseFolder, "fields.donor_names.alignment", "left"),
                    donorNameStyle: {
                      fontFamily: get(props.baseFolder, "fields.donor_names.font", "Gotham Book"),
                      fontSize: `${donorNameFontSize}em`,
                      color: get(props.baseFolder, "fields.donor_names.color", fontColorDefault),
                      fontWeight: get(props.baseFolder, "fields.donor_names.bold", false) ? "bold" : "normal",
                      textDecoration: get(props.baseFolder, "fields.donor_names.bold", false) ? "underline" : "normal",
                      fontStyle: get(props.baseFolder, "fields.donor_names.italic", false) ? "italic": "normal",
                    },
                  },
                }}
              />
            )
          }
        </div>

      {/* END DONORS */}
      <div
        style={{
          width: '100%',
          marginTop: '2em'
        }}
        ref={(htmlEl) => setFooterEl(htmlEl)}
      >
        {showLowerRule && <div className="lower-bar">
          <hr
            style={{
              backgroundColor: horizontalRuleColor,
              border: "0 none",
              color: horizontalRuleColor,
              height: props.containerWidth * (horizontalRuleSize / 100),
              marginTop: "0.5em",
            }}
          />
        </div>}
        <div
          style={{
            lineHeight: '1.25em'
          }}
        >
          <RichTextRender htmlString={footer} />
        </div>

      </div>
      </div>

    </div>
  )
}


export default EverettDonorListWithQuote;
