import { Table } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addBulkFolders,
  bulkMove,
  deleteBulkFolders,
  updateBulkFolders,
  updateFolder,
  instanceFolder,
  setUpdateListViewTouch,
  toggleFolderExpanded,
  setFoldersExpanded,
  bulkClone,
} from "../../actions/folderActions";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { getIdFromTreeNode, reversePathsFromNode } from "../../utils/treeUtils";
import { TREE_MODE_APPLIED_CONTENT } from "../constants";
import AlertNotification from "../layout/AlertNotification";
import LayoutConstants from "../layout/LayoutConstants";
import DragDropHoc from "../list_view/DragDropHoc";
import { getItemsAndColumns } from "../../selectors/listContainerSelectors";
import { getFolderStatusSelector } from "../../selectors/folderStatusSelectors";
import { getResourceListViewItems } from "../../selectors/resourceListViewSelector";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { listContainerAndFilterSelectors } from "../../selectors/listContainerAndFilterSelectors";
import ResourceListView from "../list_view/ResourceListView";
import {
  updateBulkResources,
  deleteBulkResources,
} from "../../actions/resourceActions";

class ListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_folders: [],
      filteredInfo: {},
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.selected_folder &&
      nextProps.selected_folder &&
      this.props.selected_folder.id === nextProps.selected_folder.id
    ) {
      const previousSort = _.get(
        this.props.selected_folder,
        "fields.sorting_options",
        "rank"
      );
      const currentSort = _.get(
        nextProps.selected_folder,
        "fields.sorting_options",
        "rank"
      );
      if (previousSort != currentSort) {
        return true;
      }
      if (
        Object.keys(this.props.folders).length !==
        Object.keys(nextProps.folders).length
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  getList = () => {
    if (this.props.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
      const { detail_drawer_open, tree_panel_open } = this.props.client_options;
      const headerHeight = 50;
      const listHeight =
        window.innerHeight -
        LayoutConstants().tabs.height -
        LayoutConstants().topnav.height -
        headerHeight;

      return (
        <DragDropHoc
          listHeight={listHeight}
          listItems={this.props.list_items}
          listColumns={_.cloneDeep(this.props.list_columns)}
          addBulk={this.props.addBulk}
          updateBulk={this.props.updateBulk}
          moveBulk={this.props.moveBulk}
          instanceBulk={this.props.instanceFolder}
          deleteBulk={this.props.deleteBulkFolders}
          cloneBulk={this.props.cloneBulk}
          setUpdateListViewTouch={this.props.setUpdateListViewTouch}
          setClientOptions={this.props.setClientOptions}
          toggleFolderExpanded={this.props.toggleFolderExpanded}
          setFoldersExpanded={this.props.setFoldersExpanded}
          parentFolder={this.props.selected_folder}
          folderTypes={this.props.folder_types}
          recalculateWidth={[detail_drawer_open, tree_panel_open]}
          aws_instance={this.props.aws_instance}
          resources={this.props.resources}
          folders={this.props.folders}
          resource__folders={this.props.resource__folders}
          folder__status={this.props.folder__status}
          sorting={_.get(
            this.props.selected_folder,
            "fields.sorting_options",
            "rank"
          )}
          users={this.props.users}
          applied_content_tree_expanded={
            this.props.applied_content_tree_expanded
          }
          client_options={this.props.client_options}
          canUpdate={this.props.canUpdate}
          hasResponsiveDesign={
            this.props.selected_folder.folder_type.includes("giving_level") ||
            this.props.selected_folder.folder_type.includes("slides")
          }
        />
      );
    } else {
      return this.getResourceList();
    }
  };

  bulkDeleteSuccess = () => {
    AlertNotification("success", "Success", "Bulk delete success");
  };

  bulkDeleteFailure = (message) => {
    AlertNotification("error", "Error", message);
  };

  handleBulkDelete = () => {
    const folder__folder_ids = this.state.selected_folders.map((element) => {
      return Object.values(this.props.folder__folders).find(
        (x) =>
          x.child_folder === element &&
          this.props.selected_folder.id === x.parent_folder
      ).id;
    });
    this.setState({
      selected_folders: [],
      form_key: this.state.form_key + 1,
    });
    this.props.deleteBulkFolders(
      this.state.selected_folders,
      folder__folder_ids,
      this.props.client_options.treemode,
      this.bulkDeleteSuccess,
      this.bulkDeleteFailure
    );
  };

  getResourceList = () => {
    const headerHeight = 50;
    const listHeight =
      window.innerHeight -
      LayoutConstants().tabs.height -
      LayoutConstants().topnav.height -
      headerHeight;
    const { detail_drawer_open, tree_panel_open } = this.props.client_options;

    // careful if key is added in ResourceListView
    // component is re-rendered and clean state
    return (
      <ResourceListView
        listHeight={listHeight}
        listItems={this.props.list_items}
        listColumns={this.props.list_columns}
        addBulk={this.props.addBulk}
        updateBulk={this.props.updateBulkResources}
        moveBulk={() => {}}
        instanceBulk={this.props.instanceFolder}
        cloneBulk={this.props.cloneBulk}
        deleteBulk={this.props.deleteBulkResources}
        setUpdateListViewTouch={this.props.setUpdateListViewTouch}
        setClientOptions={this.props.setClientOptions}
        toggleFolderExpanded={this.props.toggleFolderExpanded}
        setFoldersExpanded={this.props.setFoldersExpanded}
        parentFolder={this.props.selected_folder}
        folderTypes={this.props.folder_types}
        recalculateWidth={[detail_drawer_open, tree_panel_open]}
        aws_instance={this.props.aws_instance}
        resources={this.props.resources}
        resource__folders={this.props.resource__folders}
        folder__status={this.props.folder__status}
        users={this.props.users}
        applied_content_tree_expanded={this.props.applied_content_tree_expanded}
        client_options={this.props.client_options}
        projects={this.props.projects}
        folders={this.props.folders}
        folder__folders={this.props.folder__folders}
        sorting={_.get(
          this.props.selected_folder,
          "fields.sorting_options",
          "rank"
        )}
      />
    );
  };

  getS3Url = (resourceName, companyId) => {
    const s3 = new this.props.aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2",
    });
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${companyId}/resource__folders`,
      Key: resourceName,
    };
    return s3.getSignedUrl("getObject", params);
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ filteredInfo: filters, sortedInfo: sorter });
  };

  handleInputBlur = (fieldValues, fieldValidationResults, folderId) => {
    for (let field in fieldValues) {
      if (fieldValidationResults[field].isValid === true) {
        this.updateField(fieldValues, folderId);
      }
    }
  };

  handleListSelectedInputChange = (field_name, value, folderId) => {
    let selected = this.state.selected_folders;
    const existing = selected.find((x) => x === folderId);
    if (value === true && !existing) {
      selected.push(folderId);
    } else if (value === false) {
      selected.splice(selected.indexOf(folderId), 1);
    }
    this.setState({
      selected_folders: selected,
    });
  };

  updateField = (values, folderId) => {
    let newValues = _.cloneDeep(values);
    let name = null;
    if (newValues.name) {
      name = newValues.name;
      delete newValues.name;
    }
    this.props.updateFolder(folderId, name, newValues, null, null, null); //TODO: path work here
  };

  // handleBulkMove = (selected_folder, folder__folders_ids) => {
  //   this.props.bulkMove(selected_folder.id, folder__folders_ids);
  // };

  render() {
    const content = this.getList();
    return (
      <div>
        <React.Fragment>{content}</React.Fragment>
      </div>
    );
  }
}

/**
 * check if user have a restriction permission on this folder
 */
const hasRestrictPermissionInFolder = (appliedContent, projectId) => {
  const restricts = _.get(appliedContent, "restrict", []);
  const restrictFinded = restricts.find((r) => r.project === projectId);
  return !!restrictFinded;
};

/**
 * check if they have a grant permission for "applied_content" with update: true,
 * scope "project", project id of the folder
 */
const hasUpdatePermissionForProjectInFolder = (grants, projectId) => {
  const grantFinded = grants.find(
    (g) => g.scope === "project" && g.project === projectId
  );
  let hasPermission = !!grantFinded && grantFinded.update;
  return hasPermission;
};

/**
 * // check if they have a grant permission for "applied_content" with update: true scope "company"
 */
const hasUpdatePermissionForCompany = (grants) => {
  const grantFinded = grants.find((g) => g.scope === "company");
  let hasPermission = !!grantFinded && grantFinded.update;
  return hasPermission;
};

/**
 * has update permission in this folder
 */
const hasUpdatePermissionInFolder = (appliedContent, projectId) => {
  const grants = _.get(appliedContent, "grant", []);
  let hasPermission =
    hasUpdatePermissionForCompany(grants) ||
    hasUpdatePermissionForProjectInFolder(grants, projectId);

  return hasPermission;
};

/**
 * check if user have update permission on this folder
 */
const hasUpdatePermissionFolder = (state, projectId) => {
  const applied_content = state.data.user_permissions_grouped.applied_content;

  const hasRestriction = hasRestrictPermissionInFolder(
    applied_content,
    projectId
  );
  const hasUpdatePermission = hasUpdatePermissionInFolder(
    applied_content,
    projectId
  );
  const hasPermission = !hasRestriction && hasUpdatePermission;

  return hasPermission;
};

const mapStateToProps = (state, ownProps) => {
  let list_items = [];
  let list_columns = [];
  let references = [];

  if (state.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
    const response = listContainerAndFilterSelectors({ ...state }, ownProps);
    list_items = response.listItems;
    list_columns = response.listColumns;
  } else {
    if (
      state.client_options.resource_tree_selected_id.startsWith("resource")
    ) {
      let resource_tree_selected_id = getIdFromTreeNode(
        state.client_options.resource_tree_selected_id,
        true,
        false
      );
      let resource_id =
        state.data.resourcetree_resource__folders[resource_tree_selected_id]
          .resource;
      references = arrayFromKeyedObject(state.data.resource__folders);
      references = references.filter((x) => x.resource === resource_id);
      references.map((x) => {
        x.paths = reversePathsFromNode(
          x.folder,
          state.data.folder__folders,
          state.data.folders
        );
        x.stringPaths = [];
        x.paths.map((path) => {
          let stringPart = "";
          path.map((part) => {
            stringPart += state.data.folders[part].name + "/";
            return null;
          });
          x.stringPaths.push(stringPart);
          return null;
        });
        return null;
      });
    } else {
      // can be folder or company (first folder)
      list_items = getResourceListViewItems(
        state.client_options.resource_tree_selected_id
      )(state, ownProps);
      list_columns = [
        {
          type: "bool",
          title: "",
          field: "list_view_selected",
          sort: false,
          editable: false,
        },
        {
          type: "text-lg",
          title: "Name",
          field: "modifiedName",
          sort: true,
          editable: true,
        },
        {
          type: "associations",
          title: "Associated with",
          field: "associations",
          sort: false,
          editable: false,
        },
        {
          type: "text",
          title: "File Type",
          field: "file_type",
          sort: true,
          editable: false,
        },
        {
          type: "date",
          title: "Modified",
          field: "updatedAt",
          sort: true,
          editable: false,
        },
        {
          type: "text-lg",
          title: "Updated by",
          field: "updatedBy",
          sort: false,
          editable: false,
        },
      ];
    }
  }

  const folder__status = getFolderStatusSelector(state, ownProps);

  const projectId =
    state.client_options.selected_folder_meta.project || -1;
  const canUpdate = hasUpdatePermissionFolder(state, projectId);

  return {
    projects: state.data.projects,
    folders: state.data.folders,
    list_items,
    list_columns,
    references: references,
    client_options: state.client_options,
    aws_instance: state.data.aws_instance,
    resources: state.data.resources,
    resource__folders: state.data.resource__folders,
    folder__folders: state.data.folder__folders,
    folder_types: state.data.folder_types,
    users: state.data.users,
    folder__status,
    applied_content_tree_expanded: state.data.applied_content_tree_expanded,
    canUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBulk: (data, onSuccess, onfail) => {
      dispatch(addBulkFolders(data, onSuccess, onfail));
    },
    updateBulkResources: (resources, onSuccess, onfail) => {
      dispatch(updateBulkResources(resources, onSuccess, onfail));
    },
    deleteBulkResources: (resourceIds, onSuccess, onFail) => {
      dispatch(deleteBulkResources(resourceIds, onSuccess, onFail));
    },
    updateBulk: (data, onSuccess, onfail) => {
      dispatch(updateBulkFolders(data, onSuccess, onfail));
    },
    updateFolder: (id, name, data, path, onSuccess, onFail) => {
      dispatch(updateFolder(id, name, data, path, onSuccess, onFail));
    },
    deleteBulkFolders: (
      folder_ids,
      folder__folder_ids,
      type,
      onSuccess,
      onFail,
      hasDeleteConfirmation = false
    ) => {
      dispatch(
        deleteBulkFolders(
          folder_ids,
          folder__folder_ids,
          type,
          onSuccess,
          onFail,
          hasDeleteConfirmation
        )
      );
    },
    moveBulk: (
      destination_folder,
      folder__folders,
      onSuccess = null,
      onFail = null
    ) => {
      dispatch(
        bulkMove(destination_folder, folder__folders, onSuccess, onFail)
      );
    },
    cloneBulk: (
      destination_folder,
      folders_to_clone,
      onSuccess = null,
      onFail = null
    ) => {
      dispatch(
        bulkClone(destination_folder, folders_to_clone, onSuccess, onFail)
      );
    },
    instanceFolder: (payload, onSuccess, onFail) => {
      dispatch(instanceFolder(payload, onSuccess, onFail));
    },
    setUpdateListViewTouch: (touch) => {
      dispatch(setUpdateListViewTouch(touch));
    },
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
    toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
      dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode));
    },
    setFoldersExpanded: (treeMode, treeIds) => {
      dispatch(setFoldersExpanded(treeMode, treeIds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
