import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DropTarget } from 'react-dnd';

import ScrollItem from './ScrollItem';

let intervalWorking = false;
let dragDownInterval;

const itemTarget = {
  
  drop (props, monitor, component) {
    return { id: props.id };
  },

  hover(props, monitor, component) {

    if (intervalWorking===false){
      intervalWorking = true;
      
      clearInterval(this.dragDownInterval)
      dragDownInterval = setInterval(() => {
        
        const isJustOverThisOne = monitor.isOver({ shallow: true });
        if (isJustOverThisOne===true){
          props.onEnter();

        }
        else{
          intervalWorking=false;
          clearInterval(dragDownInterval)
        }
      }, 10);
    }
  }
}

class ScrollTrigger extends Component {

  componentDidMount() {
  }
  
  // react-dnd won't let us connect a drag source/drop target to a custom element --
  // need to use a ref
  connectDOMNode = (instance) => {
    const node = findDOMNode(instance);
    //this.props.connectDragSource(node);
    this.props.connectDropTarget(node);
  }

  render() {
    return (
      <ScrollItem scrollType={this.props.scrollType} ref={this.connectDOMNode} style={this.props.style}>Something</ScrollItem>
    );
  }
}

// const dragSource = DragSource('SCROLL_TRIGGER', itemSource, (connect, monitor) => ({
//   connectDragSource: connect.dragSource(),
//   connectDragPreview: connect.dragPreview(),
//   isDragging: monitor.isDragging()
// }));

export default DropTarget('TREE_ITEM', itemTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(ScrollTrigger);

//export default _.flow(dropTarget)(ScrollTrigger);
