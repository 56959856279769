import React, { useMemo, useRef, useState } from "react";
import TextAreaInput from "../TextAreaInput";
import WysiwygInput from "../WysiwygInput";
import useStyles from "./styles";
import Delta from "quill-delta";
import { Modal } from "antd";
import get from "lodash/get";

enum MODE {
  WYSIWYG = "wysiwyg",
  PLAIN_TEXT = "plain_text",
}

interface IHybridTextInputProps {
  fieldName: string;
  title: string;
  defaultValue: any;
  showLabel?: boolean;
  canUpdate?: boolean;
  settings?: any;
  resetToDefaultValue?: any;
  onChange: Function;
  onBlur: Function;
  themeSettings?: any;
}

const HybridTextInput: React.FC<IHybridTextInputProps> = (props) => {
  const wysiwygInputRef = useRef(null);
  const classes = useStyles();
  const [mode, setMode] = useState(
    props?.defaultValue?.mode ?? MODE.PLAIN_TEXT
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const wysywygDefaultValue = useMemo(() => {
    return (
      props.defaultValue.wysiwyg ?? {
        ops: [],
        inheritable_style: props.defaultValue.inheritable_style ?? {},
      }
    );
  }, [props.defaultValue]);

  const textAreaDefaultValue = useMemo(() => {
    return props.defaultValue.textarea ?? "";
  }, [props.defaultValue]);

  const onClickAdvancedCheckbox = () => {
    if (mode === MODE.PLAIN_TEXT) {
      setMode(MODE.WYSIWYG);
      convertPlainTextToOps();
    } else {
      setMode(MODE.PLAIN_TEXT);
      setIsModalOpen(false);
      convertOpsToPlainText();
    }
  };

  const onChange = (name, value: any) => {
    const key = mode === MODE.PLAIN_TEXT ? "textarea" : "wysiwyg";
    const otherKey = key === "textarea" ? "wysiwyg" : "textarea";
    const valueToBeSaved = {
      mode,
      [key]: value,
      [otherKey]: props.defaultValue[otherKey],
    };
    if (props.defaultValue.inheritable_style) {
      valueToBeSaved.inheritable_style = props.defaultValue.inheritable_style;
    }
    props.onChange(name, valueToBeSaved);
    props.onBlur(name, valueToBeSaved);
  };

  const clearWysiwygInputFormat = () => {
    setTimeout(() => {
      if (!wysiwygInputRef.current) {
        clearWysiwygInputFormat();
      } else {
        wysiwygInputRef.current.clearFormat();
      }
    }, 100);
  };

  const convertOpsToPlainText = () => {
    const ops = props.defaultValue?.wysiwyg?.ops ?? [];
    let newValue = "";
    for (let key in ops) {
      const op = ops[key];
      if (Number(key) === ops.length - 1) {
        if (op.insert === "\n") {
          continue;
        }
      }
      if (op.insert && typeof op.insert === "string") {
        newValue += op.insert;
      } else {
        newValue += " ";
      }
    }
    props.onChange(props.fieldName, {
      ...props.defaultValue,
      mode: MODE.PLAIN_TEXT,
      textarea: newValue,
    });
  };

  const convertPlainTextToOps = () => {
    if (props.defaultValue.hasOwnProperty('textarea')) {
      const newDelta = new Delta().insert(props.defaultValue.textarea);
      let ops = newDelta.ops;
      let styles = get(props.defaultValue, "inheritable_style", {});
      if (Object.keys(styles).length === 0) {
        styles = get(props, 'defaultStyle', {});
      }
      if (styles) {
        ops = newDelta.ops.map((op) => ({
          ...op,
          attributes: styles,
        }));

        if (ops.length === 1 && styles.align) {
          ops.push({ insert: "\n", attributes: styles });
        }
      }
      const valueToBeSaved = {
        ...props.defaultValue,
        mode: MODE.WYSIWYG,
        wysiwyg: { ops, inheritable_style: props.defaultValue.inheritable_style ?? {} },
      };

      props.onChange(props.fieldName, valueToBeSaved);
      clearWysiwygInputFormat();
    }
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => onClickAdvancedCheckbox()}
      >
        Are you sure you want to switch to basic mode? Any style changes you
        made to the text will be lost.
      </Modal>

      <div className={classes.checkboxWrapper}>
        Advanced &nbsp;
        <input
          checked={mode === MODE.WYSIWYG}
          type="checkbox"
          onChange={() => {
            if (mode === MODE.WYSIWYG) {
              setIsModalOpen(true);
            } else {
              onClickAdvancedCheckbox();
            }
          }}
        />
      </div>

      {mode === MODE.PLAIN_TEXT && (
        <TextAreaInput
          {...props}
          onChange={onChange}
          defaultValue={textAreaDefaultValue}
        />
      )}

      {mode === MODE.WYSIWYG && (
        <WysiwygInput
          {...props}
          // @ts-ignore
          ref={wysiwygInputRef}
          onChange={onChange}
          defaultValue={wysywygDefaultValue}
          resetToDefaultValue={props.resetToDefaultValue?.wysiwyg ?? {}}
          themeSettings={props.themeSettings}
        />
      )}
    </>
  );
};

export default HybridTextInput;
