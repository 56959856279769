import { Select, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { fonts, FontsFamilies, FontVariants, findFontFamily, findFontVariant, findFontDefaultVariant } from "../../../shared-global/fonts";

const Option = Select.Option;

export interface IFontFamilyProps {
  onChange: (val: string) => void,
  fonts: FontsFamilies[],
  value: string,
  showOnlyDefaultFonts?: boolean,
  defaultFonts?: FontsFamilies[]
}

const FontFamilyInput: React.FC<IFontFamilyProps> = (props: IFontFamilyProps) => {
    const [fontFamily, setFontFamily] = useState<FontsFamilies>(props.fonts[0]);
    const [variant, setVariant] = useState<FontVariants>();

    const allFonts = Object.keys(FontsFamilies).map((k) => {
      return FontsFamilies[k]
    }).filter((fontFamily) => {
      return !props.fonts.includes(fontFamily)
    }).map(f => ({ label: f, value: f }))

    const fontOptions = useMemo(() => {
        if (!props.fonts) return []
        return props.fonts.sort().map(f => ({ label: f, value: f }))
    }, [props.fonts])

    const variantOptions = useMemo(() => {
        if (!fontFamily) return []
        const font = fonts[fontFamily];
        return Object.keys(font).sort().map((key) => ({ label: key, value: key }))
    }, [fontFamily])

    const fontDefinition = useMemo(() => {
        if (!fontFamily || !variant) return null
        return fonts[fontFamily][variant]
    }, [fontFamily, variant])

    const handleFontChange = (f: FontsFamilies) => {
        setFontFamily(f)
        handleVariantChange(f, findFontDefaultVariant(f))
    }

    const handleVariantChange = (f: FontsFamilies, v: FontVariants) => {
        setVariant(v)
        props.onChange(fonts[f][v])
    }

    useEffect(() => {
        setFontFamily(findFontFamily(props.value))
        setVariant(findFontVariant(props.value))
    }, [props.value])

    return (
        <>
            <Tooltip title="Font Family" mouseEnterDelay={0.5}>
                <span>
                    <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode }
                        className={`ql-font-${fontDefinition}`}
                        style={{ width: '25%' }}
                        value={fontFamily}
                        onSelect={(e) => handleFontChange(e as FontsFamilies)}
                    >
                      {
                        !props.showOnlyDefaultFonts ?
                          <>
                            {
                              fontOptions.map((item, i) => (
                                <Option
                                  className={`ql-font-${fonts[item.value][findFontDefaultVariant(item.value)]}`}
                                  value={item.value}
                                  style={{
                                    borderBottom: i === fontOptions.length - 1 ? '1px solid black' : ''
                                  }}
                                >
                                  {item.label}
                                </Option>
                              ))
                            }
                            {
                              allFonts.map((item) => (
                                <Option className={`ql-font-${fonts[item.value][findFontDefaultVariant(item.value)]}`} value={item.value}>{item.label}</Option>
                              ))
                            }
                          </>

                          :
                          fontOptions.map((item) => (
                            <Option className={`ql-font-${fonts[item.value][findFontDefaultVariant(item.value)]}`} value={item.value}>{item.label}</Option>
                          ))
                      }
                    </Select>
                </span>
            </Tooltip>
            <Tooltip title="Font Weight" mouseEnterDelay={0.5}>
                <span>
                    <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode }
                        className={`ql-font-${fontDefinition}`}
                        style={{ width: '40%', marginLeft: '2%', marginRight: '2%' }}
                        value={variant}
                        onSelect={(e) => handleVariantChange(fontFamily, e as FontVariants)}
                    >
                        {variantOptions.map((item) => (
                            <Option className={`ql-font-${fonts[fontFamily][item.value]}`} value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                </span>
            </Tooltip>
        </>
    );
}

export default FontFamilyInput;
