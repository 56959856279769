import { object, string, number, boolean, array, mixed } from 'yup';
import { ROLE_SCOPE } from '../interfaces/models/role';
import { PERMISSION_SCOPE, PERMISSION_AREA, PERMISSION_ACTION_TYPE } from '../interfaces/models/permission';

export const getCompanyValidationSchema = object().shape({
  id: number().required()
});

export const createCompanyValidationSchema = object().shape({
  name: string().required(),
  active: boolean().required()
});

export const updateCompanyValidationSchema = object().shape({
  name: string().required(),
  active: boolean().required(),
  id: number().required(),
  sns_alerts_enabled: boolean().required(),
  sns_alerts_email_address: string().when('sns_alerts_enabled', {
    is: true,
    then: (schema) => schema.required('SNS alerts email address is required'),
    otherwise: (schema) => schema.notRequired()
  }),
});

export const addRoleValidationSchema = object().shape({
  scope: mixed<ROLE_SCOPE>().oneOf(Object.values(ROLE_SCOPE)),
  company: number().required(),
  name: string().required(),
  selectedOptions: array().of(number()).required(),

});

export const updateRoleValidationSchema = object().shape({
  roleId: number().required(),
  name: string().required(),
  scope: mixed<ROLE_SCOPE>().oneOf(Object.values(ROLE_SCOPE)),
  permissions: array().of(number()).required(),
});

export const destroyRoleValidationSchema = object().shape({
  roleId: number().required()
});

export const destroyUserRoleValidationSchema = object().shape({
  userRoleId: number().required()
});

export const createDefaultRolesValidationSchema = object().shape({
  company: number().required()
});

export const addPermissionValidationSchema = object().shape({
  permission: object().shape({
    name: string().required(),
    scope: mixed<PERMISSION_SCOPE>().oneOf(Object.values(PERMISSION_SCOPE)).required(),
    area: mixed<PERMISSION_AREA>().oneOf(Object.values(PERMISSION_AREA)).required(),
    action_type: mixed<PERMISSION_ACTION_TYPE>().oneOf(Object.values(PERMISSION_ACTION_TYPE)).required(),
    action: string().required(),
    action_items: string(),
    company: number().required()
  })
});

export const updatePermissionValidationSchema = object().shape({
  permission: object().shape({
    id: number().required(),
    name: string().required(),
    scope: mixed<PERMISSION_SCOPE>().oneOf(Object.values(PERMISSION_SCOPE)).required(),
    area: mixed<PERMISSION_AREA>().oneOf(Object.values(PERMISSION_AREA)).required(),
    action_type: mixed<PERMISSION_ACTION_TYPE>().oneOf(Object.values(PERMISSION_ACTION_TYPE)).required(),
    action: string().required(),
    action_items: string(),
    company: number().required()
  })
});

export const deletePermissionValidationSchema = object().shape({
  permissionId: number().required(),
});

export const createDefaultPermissionValidationSchema = object().shape({
  company: number().required(),
});

export const deleteCompanyValidationSchema = object().shape({
  company_id: number().required(),
});

