import React, {Component} from 'react';
import {Row, Col, Button, Modal} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';

class AddNewMediaFileInput extends Component {
  static propTypes = {
    add: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    processing: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    extraClassStyle: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this._inputRef = null;
  }

  componentDidMount() {
    this._componentIsMounted = true;
    if (this.props.data) {
    }
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
    }
  }

  render() {
    const { disabled, processing, show, size, extraClassStyle } = this.props;
    const { add } = this.props;
    const extraClass = size ? `preview-gallery_container-${size}` : "";
    const extraDisabledClass = (processing || disabled) ? "preview-gallery_inner--disabled" : "";

    return (
      show ?
        <div className={`preview-gallery_container preview-gallery_container-button ${extraClass} ${extraClassStyle}`}>
          <div className="preview-gallery_outer">
            <div className={`preview-gallery_inner ${extraDisabledClass}`}>
              <Button onClick={add} disabled={processing || disabled} type="primary" className="preview-gallery_inner-button" >
                <span className="fa-layers fa-fw">
                  {
                    processing ?
                      <FontAwesomeIcon style={{cursor: 'pointer'}} icon={['fas', 'spinner']} className="fa-2x" spin/> :
                      <FontAwesomeIcon style={{cursor: 'pointer'}} icon='plus' className="fa-2x"/>
                  }
                </span>
              </Button>
            </div>
          </div>
        </div>
        : null
    );
  }
}

export default AddNewMediaFileInput;
