import { isArray } from "lodash";
import { useCallback, useContext } from "react";
import { createUseStyles } from "react-jss";
import { COMPONENT_NAME } from "../../shared-global/enums/folder-type-enums";
import { IFolderType, IFolderTypePreview } from "../../shared-global/interfaces/folder-type-interfaces";
import { PreviewContext } from "../Contexts";
import { generateFontSizeClassesFromFields } from "../utils/generalUtils";
import { getFontSizeBasedOnPreviewData } from "../utils/screenUtils";
import { useFolder } from "./useFolder";

/*
 * useScale Hook
 *
 * Description:
 * The functions of this hook component are meant to be used within the preview context,
 * more specifically, within any theme component that is a child of the PreviewContainer component.
 *
 * The functions described in this hook are meant to be used for retrieving the values used
 * to scale the components in the preview. These values are both the font size and the dynamic
 * css classes used for rich text.
 *
 */

export const useScale = (folderId?: number) => {
  const { refHeight, refWidth } = useContext(PreviewContext);
  const { getBaseFolder, getBaseFolderFolderType } = useFolder(folderId);

  // Gets the Font Size which is the base unit for scaling the components
  // On a 1080p screen, the font size is 16px making the base unit be 1em = 16px
  const getBaseFolderFontSize = useCallback(
    (previewScreenNumber = 0) => {
      const baseFolderType = getBaseFolderFolderType();
      const previewSettings = baseFolderType?.preview;
      let componentName: COMPONENT_NAME | undefined;
      if (isArray(previewSettings)) {
        componentName = previewSettings[previewScreenNumber].component_name;
      } else {
        componentName = (previewSettings as IFolderTypePreview)?.component_name;
      }
      return getFontSizeBasedOnPreviewData(baseFolderType, componentName, "unique", {
        containerWidth: refWidth,
        containerHeight: refHeight
      });
    },
    [refWidth, refHeight, getBaseFolderFolderType]
  );

  // Gets the dynamic css classes for all the rich text fields of the current folder
  // This is calculated dynamically due to the fact that a ql-font-size class is added to the rich text
  // by the quill editor, and this class is used to scale the font size of the rich text.
  const getBaseFolderRichTextStylesFn = useCallback<() => any>(() => {
    const baseFolder = getBaseFolder();
    if (baseFolder) {
      const folderType = getBaseFolderFolderType();
      const useStyles = createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder.fields));
      return useStyles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, []);

  const getRichTextStylesByFolderType = useCallback((folderType: IFolderType, fields) => {
    const useStyles = createUseStyles(generateFontSizeClassesFromFields(folderType, fields));
    return useStyles;
  }, []);

  // Gets the space covered by a container depending on a specific measure
  // This is commonly used to set an standard height of a container
  // This makes the container looks the same height in every preview screen size
  const getUnitBasedOnHeight = useCallback((measure) => {
    return (measure / 1920) * refHeight;
  }, [refHeight]);

  // Calculates and standardize the percentage of any field value depending on the height of the preview container
  const standardSizing = useCallback((item) => {
    return (item / 100) * refHeight;
  }, [refHeight])

  return {
    getBaseFolderRichTextStylesFn,
    getBaseFolderFontSize,
    getRichTextStylesByFolderType,
    getUnitBasedOnHeight,
    standardSizing
  };
};
