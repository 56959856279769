import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE, PREVIEW_VIEW_PERCENTAGE_ORIENTATION } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name, wysiwyg } from "../standardized_fields";
import { wysiwygSettings } from "./glenwood_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from '../../../../enums/ui-enums';

export const glenwood_donor_sea: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.glenwood_donor_sea,
  display_name: "Donor Sea",
  acceptable_folder_types: [
    FOLDER_TYPE.glenwood_donor_sea_categories,
    FOLDER_TYPE.glenwood_donor_sea_backgrounds,
  ],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    title: {
      title: "Name/Label",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    donor_sea: {
      title: "Donor Sea",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    horizontal_rule: {
      title: "Horizontal Rule",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    advanced: {
      title: "Advanced",
      default_expanded: true,
      order: 4,
      accordion: false,
      tab: "advanced"
    }
  },
  fields: {
    // #region General
    header: {
      title: "Header",
      field_group: "donor_sea",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: 5 * 16 + "px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_header"
      }
    },
    description: {
      title: "Description",
      field_group: "donor_sea",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: 3.75 * 16 + "px",
            italic: true,
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_description"
      }
    },
    category: {
      title: "Category",
      field_group: "donor_sea",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "center",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: 4.5 * 16 + "px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_category"
      }
    },
    // This name is confusing because the title says "Don't Repeat Donor Names" but the name is repeat_names.
    // I won't change it to avoid having to migrate the database, but I'll handle it as the title suggests.
    repeat_names: {
      type: "bool",
      title: "Dont Repeat Donor Names",
      default: false,
      field_group: "donor_sea",
      sys_admin_only: true
    },
    color_overlay: {
      type: "color",
      title: "Background Overlay",
      default: "",
      field_group: "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Horizontal Rule Fields
    horizontal_rule: {
      type: "bool",
      title: "Horizontal Rule",
      default: true,
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_horizontal_rule"
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.3,
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_horizontal_rule_size"
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_sea_horizontal_rule_color"
      }
    },

    // Gallery
    gallery_title: {
      title: "Gallery Title",
      type: "hybrid_text_input",
      field_group: "donor_sea",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px",
            lineHeight: 1.2
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "gallery_title"
      }
    },
    gallery_description: {
      title: "Gallery Description",
      type: "hybrid_text_input",
      field_group: "donor_sea",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px",
            lineHeight: 1.2
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "gallery_description"
      }
    },
    gallery_spacing: {
      type: "number",
      title: "Space between Gallery Title and Gallery Description",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 20 }] },
      tooltip: "Unit is % of container height",
      step: 1,
      default: 0,
      field_group: "donor_sea"
    },

    // Advanced Tab

    fade_in_donors_time: {
      type: "integer",
      title: "Fade In Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade in after the background is cycled into.",
      field_group: "advanced",
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    fade_out_donors_time: {
      type: "integer",
      title: "Fade Out Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade out before the background is cycled.",
      field_group: "advanced",
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    screen_top_and_bottom_margin: {
      type: "integer",
      title: "Screen Top and Bottom Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "advanced",
      default: 7,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    screen_left_and_right_margin: {
      type: "integer",
      title: "Screen Left and Right Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "advanced",
      default: 4,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    min_margin_between_names_horizontally: {
      type: "integer",
      title: "Min Horizontal distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another horizontally. The distance is expressed in percentage and is relative to the width of the region minus the specified margin",
      field_group: "advanced",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    min_margin_between_names_vertically: {
      type: "integer",
      title: "Min Vertical distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another vertically. The distance is expressed in percentage and is relative to the height of the region minus the specified margin",
      field_group: "advanced",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }],
    type: PREVIEW_TYPE.COMPONENT,
    react_url: "theme/glenwood",
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  },
  default_children: [
    {
      name: "Backgrounds",
      folder_type: FOLDER_TYPE.glenwood_donor_sea_backgrounds
    },
    {
      name: "Categories",
      folder_type: FOLDER_TYPE.glenwood_donor_sea_categories
    }
  ]
};
