import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const everett_donor_sea_backgrounds: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.everett_donor_sea_backgrounds,
  display_name: "Donor Sea Backgrounds",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_sea_background],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
};
