import get from "lodash/get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import { convertPxStringToEm } from "../../../utils/generalUtils";
import DonorList2107 from "../../standard/DonorList2107";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";

const EverettDonorList = (props) => {
  const [topElements, setTopElements] = useState(null);
  const [footerEl, setFooterEl] = useState(null);
  const [containerEl, setContainerEl] = useState(null);
  const [donorListWrapperEl, setDonorListWrapperEl] = useState(null);
  const [timeToMount, setTimeToMount] = useState(false);

  const { getBaseFolder, getBaseFolderFieldValue, getBaseFolderFolderType } = useFolder(props.data.base_folder);
  const { getBaseFolderFontSize, getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);
  const baseFolderType = getBaseFolderFolderType();
  const verticalScreens = useMemo(() => 
    get(props, 'data.componentSpecificData.verticalScreens', get(baseFolderType, 'preview.screen_sets[0].vertical', 2))
    ,[props.data]
  );

  const baseFolder = getBaseFolder();

  const fontSize = getBaseFolderFontSize();
  const useStyles = getBaseFolderRichTextStylesFn();
  const classes = useStyles();

  const sectionHeight = props.refHeight / verticalScreens;

  const getPropertiesFromFolder = () => {
    const donorNameFontSize = convertPxStringToEm(get(baseFolder, "fields.donor_names.fontSize", 16));

    const header = getBaseFolderFieldValue("donor_list_header");
    const description = getBaseFolderFieldValue("description");
    const category = getBaseFolderFieldValue("category");
    const footer = getBaseFolderFieldValue("footer");

    const showUpperRule = getBaseFolderFieldValue("show_upper_rule", true);

    const showLowerRule = getBaseFolderFieldValue("show_lower_rule", true);

    const horizontalRuleColor = getBaseFolderFieldValue("horizontal_rule_color", "#c5aa75");

    const horizontalRuleSize = getBaseFolderFieldValue("horizontal_rule_size", 0.125);

    const listMarginLeft = Number(getBaseFolderFieldValue("list_margin_left", 5));

    const listMarginTop = Number(getBaseFolderFieldValue("list_margin_top", 0));

    const backgroundColorOverlay = getBaseFolderFieldValue("donor_background_image_overlay", "");

    return {
      header,
      description,
      category,
      horizontalRuleColor,
      horizontalRuleSize,
      showUpperRule,
      showLowerRule,
      footer,
      listMarginLeft,
      listMarginTop,
      donorNameFontSize,
      backgroundColorOverlay
    };
  };

  const {
    header,
    description,
    category,
    horizontalRuleColor,
    horizontalRuleSize,
    showUpperRule,
    showLowerRule,
    footer,
    donorNameFontSize,
    backgroundColorOverlay
  } = getPropertiesFromFolder();

  useEffect(() => {
    setTimeout(() => setTimeToMount(true), 500);
  }, []);

  const getDonorListHeightAndOffset = useCallback(() => {
    let effectiveDonorListHeight = props.refHeight;
    let totalOffset = 0;

    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl);
      const paddingArr = computedStyles.getPropertyValue("padding-top").split("px");
      const paddingBotArr = computedStyles.getPropertyValue("padding-bottom").split("px");
      const paddingTopNumber = Number(paddingArr[0]);
      const paddingBottomNumber = Number(paddingBotArr[0]);
      effectiveDonorListHeight -= paddingTopNumber + paddingBottomNumber;
      totalOffset += paddingTopNumber;
    }

    if (topElements) {
      const topHeight = topElements.getBoundingClientRect().height;
      const computedStyles = window.getComputedStyle(topElements);
      const marginBottomArr = computedStyles.getPropertyValue("margin-bottom").split("px");
      const marginBottomNumber = Number(marginBottomArr[0]);
      effectiveDonorListHeight -= marginBottomNumber;
      totalOffset += marginBottomNumber;
      effectiveDonorListHeight -= topHeight;
      totalOffset += topHeight;
    }

    if (donorListWrapperEl) {
      const computedStyles = window.getComputedStyle(donorListWrapperEl);
      const marginArr = computedStyles.getPropertyValue("margin-top").split("px");
      const marginNumber = Number(marginArr[0]);
      effectiveDonorListHeight -= marginNumber;
      totalOffset += marginNumber;
    }

    if (footerEl) {
      const footerHeight = footerEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= footerHeight;
    }

    return { effectiveDonorListHeight, totalOffset };
  }, [topElements, containerEl, footerEl, donorListWrapperEl, props.refHeight]);

  const getDonorListWidth = () => {
    let effectiveDonorListWidth = props.refWidth;
    if (containerEl) {
      const computedStyles = window.getComputedStyle(containerEl);
      const paddingArr = computedStyles.getPropertyValue("padding-left").split("px");
      const paddingNumber = Number(paddingArr[0]);
      effectiveDonorListWidth -= paddingNumber * 2;
    }

    return effectiveDonorListWidth;
  };

  const getGapOptions = useCallback(
    (verticalScreens, horizontalScreenIndexArrayToApplyOffsetOn: number[] = []) => {
      // This function assumes that the offset if present will only take place on the first screen since
      // the position within the donor list component is relative to its parent
      // TODO: abstract this function so it can be used in other components
      // TODO: add support for determining the offset on the other screens
      const gapPositionsWithOffset: number[] = [];
      const ratioSectionHeightAndOffset = getDonorListHeightAndOffset().totalOffset / sectionHeight;
      for (let i = 1; i < verticalScreens; i++) {
        if (i > ratioSectionHeightAndOffset) {
          const gapPosition = sectionHeight * i - getDonorListHeightAndOffset().totalOffset;
          gapPositionsWithOffset.push(gapPosition);
        }
      }

      const gapPositions: number[] = [];
      for (let i = 1; i < verticalScreens; i++) {
        const gapPosition = sectionHeight * i;
        gapPositions.push(gapPosition);
      }

      return {
        showGap: true,
        // gapPositionsWithOffset,
        gapPositions: gapPositionsWithOffset,
        gapHeight: props.refHeight * (get(baseFolder, "fields.list_margin_bezel", 0) / 100),
        list_style: get(baseFolder, "fields.donor_list_style", "filled"),
        horizontalScreenIndexArrayToApplyOffsetOn
      };
    },
    [baseFolder, props.refHeight, sectionHeight, getDonorListHeightAndOffset().totalOffset]
  );

  const gapOptions = getGapOptions(verticalScreens, [0]);

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight
      }}
    >
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["donor_background_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            videoIsShorterThanCycleTime: true
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColorOverlay
        }}
      />
      <div
        style={{
          width: props.containerWidth,
          height: props.containerHeight,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          padding: "10em 10em",
        }}
        ref={(htmlEl) => setContainerEl(htmlEl)}
      >
        {/* Header, category and description */}
        <div
          style={{
            width: "50%",
            paddingRight: "10em",
            flexWrap: "wrap",
            display: "flex",
            position: "relative",
            overflow: "hidden",
            height: "auto",
            marginBottom: (get(baseFolder, "fields.list_margin_top", 0) / 100) * props.containerHeight
          }}
          ref={(divElement) => setTopElements(divElement)}
        >
          <div
            style={{
              width: props.containerWidth,
              alignSelf: "flex-start",
              position: "relative"
            }}
          >
            <div
              style={{
                width: props.containerWidth / verticalScreens - 5 * fontSize * 2,
                position: "relative"
              }}
            >
              <div className={classes.componentContainer}>
                <RichTextRender htmlString={header} />
              </div>
              <div className={classes.componentContainer}>
                <RichTextRender htmlString={description} />
              </div>
              <div className={classes.componentContainer}>
                <RichTextRender htmlString={category} />
              </div>
              {showUpperRule && (
                <div
                  className="upper-bar"
                  style={{
                    width: props.containerWidth / verticalScreens - 5 * fontSize * 2
                  }}
                >
                  <hr
                    style={{
                      backgroundColor: horizontalRuleColor,
                      border: "0 none",
                      color: horizontalRuleColor,
                      height: props.containerHeight * (horizontalRuleSize / 100),
                      marginTop: fontSize
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Donor List */}
        <>
          {topElements && footerEl && containerEl && timeToMount && (
            <div
              style={{
                width: props.containerWidth,
                height: getDonorListHeightAndOffset().effectiveDonorListHeight,
                flexFlow: "wrap",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
                position: "relative"
              }}
              ref={(htmlEl) => setDonorListWrapperEl(htmlEl)}
            >
              <DonorList2107
                {...props}
                key={baseFolder.id}
                containerHeight={getDonorListHeightAndOffset().effectiveDonorListHeight}
                refWidth={props.refWidth} // idisplaycoponentprop?
                refHeight={props.refHeight}
                containerWidth={getDonorListWidth()}
                data={{
                  ...props.data,
                  componentSpecificData: {
                    list_style: get(baseFolder, "fields.donor_list_style", "filled"),
                    gapOptions,
                    columns: get(baseFolder, "fields.columns", 3),
                    donor_list_margin_left: get(baseFolder, "fields.list_margin_left", 0),
                    donor_list_margin_right: get(baseFolder, "fields.list_margin_right", 0),
                    donor_list_column_margin_right: get(baseFolder, "fields.donor_list_column_margin_right", 0),
                    cycle: get(baseFolder, "fields.cycle_time", 5) * 1000,
                    donor_vertical_separation: get(baseFolder, "fields.donor_name_margin_bottom", 0),
                    donor_line_height: get(baseFolder, "fields.donor_names.lineHeight", 1) * 100,
                    text_alignment: get(baseFolder, "fields.donor_names.alignment", "left"),
                    donorNameStyle: {
                      fontFamily: get(baseFolder, "fields.donor_names.font", "Gotham Book"),
                      fontSize: `${donorNameFontSize}em`,
                      color: get(baseFolder, "fields.donor_names.color", "#FFFFFF"),
                      fontWeight: get(baseFolder, "fields.donor_names.bold", false) ? "bold" : "normal",
                      textDecoration: get(baseFolder, "fields.donor_names.bold", false) ? "underline" : "normal",
                      fontStyle: get(baseFolder, "fields.donor_names.italic", false) ? "italic" : "normal"
                    }
                  }
                }}
              />
            </div>
          )}
        </>

        {/* Footer */}
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            padding: `${fontSize * 1.75} ${fontSize * 5} ${fontSize * 2.75}`,
            display: "flex",
            position: "relative"
          }}
          ref={(htmlEl) => setFooterEl(htmlEl)}
        >
          <TransitionGroup>
            <CSSTransition key={showLowerRule && timeToMount} timeout={500} classNames="transition-fade">
              <>
                {showLowerRule && timeToMount && (
                  <>
                    <div className="lower-bar">
                      <hr
                        style={{
                          backgroundColor: horizontalRuleColor,
                          border: "0 none",
                          color: horizontalRuleColor,
                          height: props.containerHeight * (horizontalRuleSize / 100),
                          marginTop: fontSize
                        }}
                      />
                    </div>
                    <div className={classes.componentContainer}>
                      <RichTextRender htmlString={footer} />
                    </div>
                  </>
                )}
              </>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default EverettDonorList;
