import {IFolderType} from '../../folders/IFolderType';
import base_types from '../base_types';
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from '../../../../enums/folder-type-enums';
import {transitions} from "./alpine_settings";
import {FLEX_CLASSES, IMAGE_ALIGNMENT} from "../../../../enums/ui-enums";

export const alpine_one_screen_media: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.alpine_one_screen_media,
  display_name: "1 Screen Media",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'fullscreen_cycle_time'
      }
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'fullscreen_transition'
      }
    },
    fullscreen_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1080,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.AlpineContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "/images/folder_types/alpine/alpine_one_screen_media.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
