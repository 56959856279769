import { IFolderType } from "../interfaces/folder-type-interfaces";
import { IFolder } from "../interfaces/models/folder.interface";
import { IFolderFolder } from "../interfaces/models/folder__folder.interface";

export function getInheritableFields(
  folder__folders: IFolderFolder[],
  folders: IFolder[],
  folder_types: IFolderType[],
  path: number[]
) {
  let inheritableFields = {};

  if (!path) {
    return inheritableFields;
  }

  path.forEach((pathFFId) => {
    const pathFF = folder__folders.find((x) => x.id === Number(pathFFId));
    if (pathFF) {
      const pathFolder = folders.find((x) => x.id === pathFF.child_folder);
      const pathFType = folder_types.find(
        (x) => x.name === pathFolder.folder_type
      );
      if (pathFolder && pathFType) {
        const pftKeys = Object.keys(pathFType.fields);
        pftKeys.forEach((pftKey) => {
          if (
            pathFType.fields[pftKey].inheritable_settings
              ?.pass_down_to_children === true
          ) {
            if (pathFolder?.fields?.[pftKey]) {
              inheritableFields = {
                ...inheritableFields,
                [pftKey]: pathFolder.fields[pftKey]
              };
            }
          }
        });
      }
    }
  });
  return inheritableFields;
}

export function secondObjectPropsPresentInFirst(first, second) {
  const returnObj = {};

  Object.keys(first).map((firstkey) => {
    if (second[firstkey]) {
      returnObj[firstkey] = second[firstkey];
    }
  });
  return returnObj;
}

export function getFolderUsedSlots(
  folder: IFolder,
  folder__folders: IFolderFolder[],
  folders: IFolder[],
  folder_types: IFolderType[]
): number {
  const children = folder__folders.filter((x) => x.parent_folder === folder.id);
  const childrenFolders = children.map((x) => folders.find((y) => y.id === x.child_folder));
  
  const usedSlots = childrenFolders.reduce((acc, childFolder) => {
    const childFolderType = folder_types.find((x) => x.name === childFolder?.folder_type);
    const folderTypeSlots = childFolderType?.slots ?? 1;
    return acc + folderTypeSlots;
  }, 0);

  return usedSlots;
}