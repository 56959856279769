import { FOLDER_TYPE, FOLDER_CATEGORY, COMPONENT_NAME, PREVIEW_TYPE, FOLDER_TYPE_SELECTOR } from "../../../enums/folder-type-enums";
import { ITreeDroppedIntoAction, IFolderTypeField, IScreenSet, IListColumn, IDefaultChild, IFolderTypeButton, IFolderTypeWebview, IChildrenCondition } from "../../folder-type-interfaces";
import { IFolderType } from "./IFolderType";
import { IFolderTypePreview } from "./IFolderTypePreview";

export abstract class FolderType implements IFolderType {
  constructor(args: Readonly<FolderType>) {
    Object.assign(this, args)
  }

  name: FOLDER_TYPE;
  display_name?: string | undefined;
  entry_point?: string | undefined;
  acceptable_folder_types?: FOLDER_TYPE[];
  tree_dropped_into_action?: ITreeDroppedIntoAction[] | undefined;
  field_groups?: { readonly [key: string]: any; } | undefined;
  fields?: { readonly [key: string]: IFolderTypeField; } | undefined;
  default_dimensions?: { readonly x: number; readonly y: number; readonly scale_x: number; readonly scale_y: number; } | undefined;
  tabs?: { [key: string]: any; } | undefined;
  folder_category: FOLDER_CATEGORY;
  prevent_dragging_out?: boolean | undefined;
  prevent_last_instance_in_folder?: boolean | undefined;
  prevent_add_children?: boolean | undefined;
  add_instance_on_add_children?: { readonly folder_type: string; readonly add_to_content_folder_type: string; readonly add_to_folder_type: string; }[] | undefined;
  preview?: IFolderTypePreview | IFolderTypePreview[] | undefined;
  tab_previews?: { readonly tab_key: string; readonly aspect_ratio: number; readonly component_name: COMPONENT_NAME; readonly type: PREVIEW_TYPE; readonly screen_sets?: IScreenSet[] | undefined; readonly hide_border?: boolean | undefined; }[] | undefined;
  client?: { readonly component_name: COMPONENT_NAME; } | undefined;
  tree_icon_types?: { readonly open: any; readonly closed: any; } | undefined;
  list_view?: { readonly enabled?: boolean | undefined; readonly list_folder_types?: FOLDER_TYPE[] | undefined; readonly columns?: IListColumn[] | undefined; } | undefined;
  instancing_options?: { readonly enabled: boolean; readonly cross_project: boolean; } | undefined;
  hide_nested_children?: boolean | undefined;
  csv_options?: { readonly download?: boolean | undefined; readonly upload?: boolean | undefined; readonly include_in_csv?: boolean | undefined; readonly include_folder_type?: boolean | undefined; readonly include_has_instances?: boolean | undefined; readonly fields?: String[] | undefined; } | undefined;
  folder_type_selector?: { readonly show_header?: boolean | undefined; readonly element_type?: FOLDER_TYPE_SELECTOR | undefined; readonly image_url?: string | undefined; readonly scrollable?: boolean | undefined; readonly max_height?: number | undefined; readonly columns?: number | undefined; } | undefined;
  default_children?: IDefaultChild[] | undefined;
  save_options?: IFolderTypeButton[] | undefined;
  webview?: IFolderTypeWebview | undefined;
  isContent?: boolean | undefined;
  isPresentation?: boolean | undefined;
  font_groups?: { readonly enabled: boolean; readonly compatible_folder_types: string[]; } | undefined;
  children_conditions?: IChildrenCondition[] | undefined;
  slots?: number | undefined;
  object_recognition_settings?: boolean | undefined;
  show_clone_children_checkbox?: boolean | undefined;
  clone_children_checkbox_default_value?: boolean | undefined;
  hide_add_icon_in_tree?: boolean | undefined;
  optional_acceptable_folder_types?: string[] | undefined;
  cycle_field_name?: string | undefined;
  is_responsive?: boolean | undefined;
}