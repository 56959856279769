import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const los_angeles_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.los_angeles_donor_lists,
  display_name: "Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.los_angeles_donor_list, FOLDER_TYPE.los_angeles_donor_list_giving_level],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    scroll_speed: {
      type: "integer",
      title: "Scroll Speed",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 1000 }] },
      tooltip:
        "Speed at which the list will reach the last element starting at the top. This speed is absolute in terms of the rate at which it will scroll down the list regardless of the number of donors. This rate is calculated by a factor of (NumberOfDonors x ScrollSpeed) I.E: 100 x 10 = 1,000 seconds"
    },
    show_sublist_headers: {
      type: "bool",
      title: "Show Donor List Name",
      validation: { rules: [] },
      default: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.5926,
    type: PREVIEW_TYPE.COMPONENT,
    component_name: COMPONENT_NAME.LosAnglesDonorLists
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.LosAnglesDonorLists
  }
};
