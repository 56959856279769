import { FOLDER_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";

export const everett_content_donor_seas: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.everett_content_donor_seas,
  display_name: "Donor Seas",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_sea],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name
  }
};
