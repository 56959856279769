import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";
import { naplesFonts, quillFontSizes, transitions, wysiwyg } from "./naples_settings";

export const naples_template_blue_002: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.naples_template_blue_002,
  display_name: "Story with Text 2 Columns - Blue",
  tabs: {
    main: {
      title: "Main"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "main"
    },
    top_screen: {
      title: "Top Screen",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "main"
    },
    middle_screen: {
      title: "Middle Screen",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "main"
    },
    bottom_screen: {
      title: "Bottom Screen",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "main"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "general",
      default: 5
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    header: {
      title: "Header",
      field_group: "top_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.LIGHT],
            size: "60px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    subhead: {
      title: "Subhead",
      field_group: "top_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.BOLD],
            size: "60px",
            color: "#e0ac2c",
            bold: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    logo: {
      title: "Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 15 / 8,
      recommended_size: {
        width: 375,
        height: 200
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 15 / 8
      },
      field_group: "top_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background_color: {
      type: "color",
      title: "Header Subhead Background Color",
      default: "#1c2858",
      field_group: "top_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background_image_top: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 96 / 35,
      recommended_size: {
        width: 1920,
        height: 700
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 96 / 35
      },
      field_group: "top_screen"
    },
    color_overlay_top: {
      type: "color",
      title: "Background Overlay",
      default: "#1c2858",
      field_group: "top_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    body_text_left: {
      title: "Body text left",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts,
        crossButton: true
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.BOLD],
            size: "28px",
            color: "#fff"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    body_text_right: {
      title: "Body text right",
      field_group: "middle_screen",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: naplesFonts,
        crossButton: true
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.GOTHAM][FontVariants.BOLD],
            size: "28px",
            color: "#fff"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    background_image_middle: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "middle_screen"
    },
    color_overlay_middle: {
      type: "color",
      title: "Background Color",
      default: "#6b7a9b",
      field_group: "middle_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background_image_bottom: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "bottom_screen"
    },
    color_overlay_bottom: {
      type: "color",
      title: "Background Overlay",
      default: "#1c2858",
      field_group: "bottom_screen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "1x3", orientation: "landscape", horizontal: 1, vertical: 3 }],
    hide_border: true,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/naples/2-column-blue.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false
};
