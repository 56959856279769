

import React from 'react';
import { notification } from 'antd'
import { ALERT_SUCCESS_COLOR, ALERT_INFO_COLOR, ALERT_WARNING_COLOR, ALERT_ERROR_COLOR,
  ALERT_SUCCESS_BACKGROUND, ALERT_SUCCESS_BORDER, ALERT_INFO_BACKGROUND, ALERT_INFO_BORDER,
  ALERT_WARNING_BACKGROUND, ALERT_WARNING_BORDER, ALERT_ERROR_BACKGROUND, ALERT_ERROR_BORDER
} from './LayoutConstants'
import { CheckCircleOutlined, InfoCircleOutlined, ExclamationCircleOutlined, WarningOutlined} from '@ant-design/icons';

const ALERT_SUCCESS_STYLE = {
  backgroundColor: ALERT_SUCCESS_BACKGROUND,
  border: ALERT_SUCCESS_BORDER
}


const ALERT_INFO_STYLE = {
  backgroundColor: ALERT_INFO_BACKGROUND,
  border: ALERT_INFO_BORDER
}


const ALERT_WARNING_STYLE = {
    backgroundColor: ALERT_WARNING_BACKGROUND,
    border: ALERT_WARNING_BORDER
}


const ALERT_ERROR_STYLE = {
  backgroundColor: ALERT_ERROR_BACKGROUND,
  border: ALERT_ERROR_BORDER
}

export default function AlertNotification(type, message, description) {

    switch(type){
        case "success":
            notification[type]({
                message: message,
                description: description,
                style: ALERT_SUCCESS_STYLE,
                icon: <CheckCircleOutlined style={{color: ALERT_SUCCESS_COLOR}}/>
              })
            break;
        case "info":
            notification[type]({
                message: message,
                description: description,
                style: ALERT_INFO_STYLE,
                icon: <InfoCircleOutlined style={{color: ALERT_INFO_COLOR}}/>
              })
            break;
        case "warning":
            notification[type]({
                message: message,
                description: description,
                style: ALERT_WARNING_STYLE,
                icon: <ExclamationCircleOutlined style={{color: ALERT_WARNING_COLOR}}/>
              })
            break;
        case "error":
            notification[type]({
                message: message,
                description: description,
                style: ALERT_ERROR_STYLE,
                icon: <WarningOutlined style={{color: ALERT_ERROR_COLOR}}/>
              })
            break;
        default:
          break;

    }


}