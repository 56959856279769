import { call, put } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import _ from "lodash";
const audit_log_schema = new schema.Entity("audit_logs");
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  getByCompanyValidationSchema,
  getByFolderValidationSchema,
} from "../shared-global/validations/AuditLogValidation";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";

async function get_audit_logs_by_company_api(payload) {
  return Request(
    "/auditLog/get_by_company",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    getByCompanyValidationSchema
  );
}

async function get_audit_logs_by_folder_api(payload) {
  return Request(
    "/auditLog/get_by_folder",
    HTTP_METHODS.POST,
    {
      ...payload,
    },
    getByFolderValidationSchema
  );
}

const auditLogSaga = {
  get_audit_logs: function* (action) {
    try {
      let response = yield call(get_audit_logs_by_company_api, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_AUDIT_LOGS_SUCCESS",
          normalized: normalize(response.data.audit_logs, [audit_log_schema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        } else {
          yield put({ type: "GET_AUDIT_LOGS_FAILURE" });
          yield put(addError(response.errors));
          if (action.onFail) {
            action.onFail(response.response.data.data.detail);
          }
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  get_audit_logs_by_folder: function* (action) {
    try {
      let response = yield call(get_audit_logs_by_folder_api, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_AUDIT_LOGS_BY_FOLDER_SUCCESS",
          normalized: normalize(response.data.audit_logs, [audit_log_schema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        } 
      } else if(action.onFail) {
          yield put(addError(response.errors));
          if (action.onFail) {
            action.onFail(response.response.data.data.detail || "An error ocurred");
          }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },
};

export default auditLogSaga;
