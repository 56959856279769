import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { coburg_root } from "./coburg_root";
import { coburg_stories } from "./coburg_stories";
import { coburg_attract_loop } from "./coburg_attract_loop";
import { coburg_menu } from "./coburg_menu";
import { coburg_category_with_giving_level } from "./coburg_category_with_giving_level";
import { coburg_category_without_giving_level } from "./coburg_category_without_giving_level";
import { coburg_slide } from "./coburg_slide";
import { coburg_search } from "./coburg_search";
import { coburg_donor } from "./coburg_donor";
import { coburg_stories_overview } from "./coburg_stories_overview";
import { coburg_donor_list_group } from "./coburg_donor_list_group";
import { coburg_displays } from "./coburg_displays";
import { coburg_display } from "./coburg_display";
import { coburg_presentations } from "./coburg_presentations";
import { coburg_presentation } from "./coburg_presentation";
import { coburg_content } from "./coburg_content";
import { coburg_story_group } from "./coburg_story_group";
import { coburg_template_001 } from "./coburg_template_001";
import { coburg_template_002 } from "./coburg_template_002";
import { coburg_template_003 } from "./coburg_template_003";
import { coburg_template_004 } from "./coburg_template_004";
import { coburg_grouping_folder } from "./coburg_grouping_folder";
import { coburg_grouped_presentations } from "./coburg_grouped_presentations";

export const coburg_types: { [key: string]: IFolderType } = {
  coburg_root,
  coburg_stories,
  coburg_stories_overview,
  coburg_story_group,
  coburg_template_001,
  coburg_template_002,
  coburg_template_003,
  coburg_template_004,
  coburg_attract_loop,
  coburg_slide,
  coburg_search,
  coburg_menu,
  coburg_donor,
  coburg_donor_list_group,
  coburg_category_with_giving_level,
  coburg_category_without_giving_level,
  coburg_displays,
  coburg_display,
  coburg_presentations,
  coburg_presentation,
  coburg_content,
  coburg_grouping_folder,
  coburg_grouped_presentations
};
