import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import TextInput from "../form/input/TextInput";

interface ITextWithHighlightsProps {
  highlight: string,
  text: string,
  highlightStyle: any
  editable?: boolean;
  onChange?: boolean;
}

const TextWithHighlights = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [editableText, setEditableText] = useState(props.text);  

  if (!props.highlight && props.text){
      if (!props.editable) {
        return(
            props.text
        )
      } else {
        if (editMode) {
          return (
            <>
              <div style={{ width: "75%" }}>
                <TextInput
                  canUpdate
                  defaultValue={editableText}
                  fieldName="name"
                  onBlur={(n, v) => {
                    setEditableText(v);
                    props.onChange(n, v)
                    setEditMode(false);
                  }}
                  onChange={(n, v) => {
                    setEditableText(v);
                    props.onChange(n, v)
                  }}
                  showLabel={false}
                  title="Name"
                  showCharCount={false}          
                />
              </div>
            </>
          )
        } else {
          return (
            <>
              {editableText} &nbsp;
              <FontAwesomeIcon onClick={() => setEditMode(true)} className="fa-xs" icon="edit" />
            </>
          )
        }
      }
  }
  else if (!props.text){
    return(
      null
    )
  }
  else{
    let parts;
    try{
      parts = props.text.split(new RegExp(`(${props.highlight})`, "gi"));
    }
    catch{
      return props.text
    }

    let highlightCounter = 0;

    return (
        <>
          {parts.map((part) =>
            part.toLowerCase() === props.highlight.toLowerCase() ? (
              <span
                key={highlightCounter++}
                style={props.highlightStyle}
              >
                {part}
              </span>
            ) : (
              <span key={highlightCounter++}>{part}</span>
            )
          )}
        </>
      );
}
  



};

export default TextWithHighlights;
