import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/company/company_permission_fields';
import { connect } from "react-redux";
import { addPermission } from '../../actions/companyActions';
import { getCompany, updatePermission } from '../../actions/companyActions';
import AreasFromScope from '../../config/area_scopes';
import AlertNotification from '../layout/AlertNotification';
import CompanyBreadCrumbs from './CompanyBreadCrumbs';
import {BackLink} from "../utils/BackLink";
import { withRouter } from '../../hooks/withRouter';

class UpsertPermission extends Component {

  constructor(props) {
    super(props);  
    this.state = {
      initialized: false,
      fieldsConfig: {...formConfig.fields}
    };
  }

  componentDidMount() {
    this.props.getCompany(
      this.props.params.companyId,
      () => {this.onInit()},
      this.onFail
    );
  }

  onInit = () => {

    let stateObj = {
      name: '',
      scope: 'company',
      area: '',
      action_type: '',
      action: '',
      action_item: ''
    }
    if (this.props.editMode){
      stateObj = {
        ...stateObj,
        ...this.props.permission
      } 
    }
    stateObj.fieldsConfig = this.getFieldConfig(stateObj.scope, stateObj.area)
    stateObj.initialized = true;
    this.setState(stateObj);
  };

  onSubmit = values => {
    let permission = {
      name: values.name,
      area: values.area,
      action_type: values.action_type,
      scope: values.scope,
      action: values.action,
      action_item: values.action_item,
      company: this.props.company.id
    }
    if (this.props.editMode === false) {
      this.props.addPermission(
        permission,
        this.props.params.companyId,
        this.onInsert,
        this.onFail
      );
    } else {
      permission.id = this.props.permission.id
      this.props.updatePermission(
        permission,
        this.props.params.companyId,
        this.onUpdate,
        this.onFail
      );
    }
  };


  onUpdate = () => {
    AlertNotification('success', 'Success', "Permission updated")
    let url = "/company/"+this.props.company.id;
    this.props.navigate(url);
  };

  onInsert = id => {
    AlertNotification('success', 'Success', "Permission created")
    let url = "/company/"+this.props.company.id;
    this.props.navigate(url);
  };

  onError = e => {
    AlertNotification('error', 'Form error', e.message || "An error has occurred")
  };


  getFieldConfig = (scope, area) => {
    console.log("AREA", area);
    let newFieldsConfig = { ...this.state.fieldsConfig };
    let areaOptions = this.getAreasFromScope(AreasFromScope, scope);
    newFieldsConfig.area.options = areaOptions
    if (area === "folder" || area === "field"){
      newFieldsConfig.action_item = {
        type: 'text',
        title: 'Action Item',
        validation: { rules: [{ name: 'isRequired' }] },
        default: ''
      }
    }
    else{
      delete newFieldsConfig.action_item
    }
    return newFieldsConfig
  }

  getAreasFromScope = (areas, scope) => {
    let availableAreas = [];
    for (const area in areas) {
      if (areas[area].scopes.includes(scope)) {
        availableAreas.push({ title: areas[area].title, value: area });
      }
    }
    return availableAreas;
  };

  formChangeHandler = (fieldName, value, fieldValues) => {

    const newFieldsConfig = this.getFieldConfig(fieldValues.scope, fieldValues.area);

    this.setState({
      fieldsConfig: newFieldsConfig,
      name: fieldValues.name,
      scope: fieldValues.scope,
      area: fieldName === "area"? value: null,
      action_type: fieldValues.action_type,
      action: fieldValues.action
    });
  };

  render() {
    if (this.state.initialized === false) {
      return <div>Loading....</div>;
    }
    
    return (
      <div>
        <CompanyBreadCrumbs crumbs={this.props} />
        <div>
          <BackLink/>
        </div>
        <h1>{this.props.formTitle}</h1>
        <DynamicForm
          fieldGroupsConfig={formConfig.field_groups}
          fieldsConfig={this.state.fieldsConfig}
          fieldValues={{
            name: this.state.name,
            scope: this.state.scope,
            area: this.state.area,
            action_type: this.state.action_type,
            action: this.state.action,
            action_item: this.state.action_item
          }}
          mode={this.props.editMode ? "edit" : "add"}
          onSubmit={this.onSubmit}
          onError={this.onError}
          enableFieldGrouping={false}
          onChangeHandlers={[
            {
              name: "scope",
              callBackFunction: (name, value, fieldValues) =>
                this.formChangeHandler(name, value, fieldValues)
            },
            {
              name: "area",
              callBackFunction: (name, value, fieldValues) =>
                this.formChangeHandler(name, value, fieldValues)
            }
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let company, permission;
  let editMode = false;
  
  if (ownProps.params.permissionId !== "new") {
    editMode = true;
  }

  if (state.data.companies){
    company = state.data.companies[ownProps.params.companyId] || null;
  }

  let formTitle = "Add Permission"
  if (editMode){
    formTitle = "Edit Permission"
    if (state.data.permissions){
      permission = state.data.permissions[ownProps.params.permissionId] || null;
    }
  }

  return {
    permission: permission,
    company: company,
    editMode: editMode,
    formTitle: formTitle
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPermission: (permission, onSuccess, onFail) => {
      dispatch(addPermission(permission, onSuccess, onFail));
    },
    updatePermission: (permission, onSuccess, onFail) => {
      dispatch(updatePermission(permission, onSuccess, onFail));
    },
    getCompany: (id, onSuccess, onFail) => {
      dispatch(getCompany(id, onSuccess, onFail));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpsertPermission));