import _ from "lodash";
import { call, put } from "redux-saga/effects";
import request from "../utils/api/ServerRequest";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";
import { normalize, schema } from "normalizr";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  createFontGroupValidationSchema,
  destroyFontGroupValidationSchema,
  updateFontGroupValidationSchema,
} from "../shared-global/validations/FontGroupValidation";

const fontGroupSchema = new schema.Entity("font_groups");

async function addFontGroupAPI(payload) {
  return Request(
    "/font_group/create",
    HTTP_METHODS.POST,
    payload,
    createFontGroupValidationSchema
  );
}

async function updateFontGroupAPI(payload) {
  return Request(
    "/font_group/update",
    HTTP_METHODS.PUT,
    payload,
    updateFontGroupValidationSchema
  );
}

async function removeFontGroupAPI(payload) {
  return Request(
    "/font_group/destroy",
    HTTP_METHODS.DELETE,
    payload,
    destroyFontGroupValidationSchema
  );
}

const fontGroupSaga = {
  *addFontGroup(action) {
    try {
      const response = yield call(addFontGroupAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "FONT_GROUP_ADD_SUCCESS",
          normalized: normalize(response.data.font_groups, [fontGroupSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess(action.payload.type, action.payload.action);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *updateFontGroup(action) {
    try {
      const response = yield call(updateFontGroupAPI, action.payload);
      if (isResponseSuccess(response)) {
        if (action.onSuccess) {
          action.onSuccess(action.payload.type, action.payload.action);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *removeFontGroup(action) {
    try {
      const response = yield call(removeFontGroupAPI, action.payload);
      const removeKeys = [];
      if (isResponseSuccess(response)) {
        if (response.data.removed_font_groups) {
          removeKeys.push({
            entities: "font_groups",
            ids: response.data.removed_font_groups,
          });
          yield put({ type: "REMOVE_FOLDER_SUCCESS", removeKeys });
        }
        if (action.onSuccess) {
          action.onSuccess(action.payload.type, action.payload.action);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default fontGroupSaga;
