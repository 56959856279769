import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./monterrey_settings";

export const monterrey_presentation_1x1: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.monterrey_presentation_1x1,
  display_name: "Presentation Control Panel",
  acceptable_folder_types: [FOLDER_TYPE.monterrey_control_panel_attract_loop],
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: transitions
    }
  },
  preview: {
    aspect_ratio: 1.6,
    component_name: COMPONENT_NAME.Monterrey1x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  isPresentation: true
};
