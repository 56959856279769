import get from "lodash/get";
import React from "react";
import Slide1911 from "../../standard/Slide1911";
import {useFolder} from "../../../hooks/useFolder";
import PhoenixQrWidget from "./PhoenixQrWidget";

const PhoenixFullScreen = (props) => {
  const {getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue} = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const baseFolderFields = baseFolder.fields;

  const qrPosition = getBaseFolderFieldValue( "qr_position", "bottom-left")

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
        display: "grid"
      }}
    >
      <div
        style={{
          position: "relative",
          width: props.containerWidth,
          height: props.containerHeight,
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["fullscreen_image"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain"
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight,
            backgroundColor: get(
              baseFolder,
              "fields.fullscreen_image_overlay"
            )
          }}
        />
      </div>
      <PhoenixQrWidget
        {...props}
        baseFolderFields={baseFolderFields}
        qrPosition={qrPosition}
        baseFolderType={baseFolderType}
      />
    </div>
  );
};

export default PhoenixFullScreen;
