import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData } from "../../../utils/commonDataUtils";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import DonorSea2305 from "../../standard/DonorSea2305";
import { convertPxStringToEm, getFieldValue } from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import { loopIndex } from "../../../../shared-global/utils/general";

const EverettDonorSea: React.FC<IDisplayComponentProps> = (props) => {
  const [error, setError] = useState(-1);
  const [baseFolder, setBaseFolder] = useState<IFolder>(null);
  const [backgroundsFolder, setBackgroundsFolder] = useState<IFolder>(null);
  const [useColorOverlay, setUseColorOverlay] = useState(false);
  const [horizontalColorOverlay, setHorizontalColorOverlay] = useState(null);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState();
  const [currentBackgroundTimeLeft, setCurrentBackgroundTimeLeft] = useState();
  const [backgroundCycleTimes, setBackgroundsCycleTime] = useState([]);
  const [headerEl, setHeaderEl] = useState(null)
  const [lists, setLists] = useState<IFolder[]>([]);
  const [currentListIndex, setCurrentListIndex] = useState(-1)
  const [currentList, setCurrentList] = useState<IFolder>()

  // The following variable is governed by the donor sea and it's set to true when the donor sea finishes
  // prerendering donors and calculating theirs height and width so the backgrounds start playing at the
  // right time which only happens once (when the donor sea is mounted)
  const [canStartPlayingBackgroundsLoop, setCanStartPlayingBackgroundsLoop] =
    useState(false);

  const renderError = () => {
    switch (error) {
      case 0:
        return <>Backgrounds Folder not found</>;
      default:
        return <>An Unknown Error has Occured</>;
    }
  };

  const handleEndOfPlay = (data) => {
    const persistentData = {
      id: baseFolder.id,
      type: "donor_sea",
      data: { donor_index: data }
    };
    if (props.handleEndOfPlay) {
      props.handleEndOfPlay("forward", persistentData);
    }
  };

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);
    const childFolders = getChildrenData(
      props.data,
      foundBaseFolder.id,
      true
    ).folders;
    const backgroundsFolder = childFolders.find(
      (f) => f.folder_type === FOLDER_TYPE.everett_donor_sea_backgrounds
    );
    const listsFolder = childFolders.find(
      (f) => f.folder_type === FOLDER_TYPE.everett_donor_sea_lists
    );

    if (!backgroundsFolder || !listsFolder) {
      setError(0);
      return;
    }

    const lists = getChildrenData(
      props.data,
      listsFolder.id,
      true
    ).folders

    const foundHorizontalColorOverlay = get(
      foundBaseFolder,
      "fields.horizontal_color_overlay",
      null
    );

    if (foundHorizontalColorOverlay) {
      setHorizontalColorOverlay(foundHorizontalColorOverlay);
      setUseColorOverlay(true);
    }

    setBaseFolder(foundBaseFolder);
    setBackgroundsFolder(backgroundsFolder);
    setLists(lists);

    // Array of each individual background.
    const backgrounds = getChildrenData(
      props.data,
      backgroundsFolder.id,
      true
    ).folders;

    const cycleTimes = backgrounds.reduce((acc, curr) => {
      const cycleTime = get(curr, "fields.cycle_time", 5) * 1000;
      acc = [...acc, cycleTime];
      return acc;
    }, []);

    setBackgroundsCycleTime(cycleTimes);
    setCurrentListIndex(0)
    setCurrentList(lists[0])
  }, []);

  const cssTransitionKey = useColorOverlay ? "true" : "false";

  if (error > -1) {
    return renderError();
  }

  if (!props.data!.componentSpecificData || !baseFolder || !currentList) return null

  const folderType = props.data?.folder_types.find(t => t.name === baseFolder.folder_type)

  const header = getFieldValue(baseFolder, 'header', folderType)
  const description = getFieldValue(baseFolder, 'description', folderType)
  const showUpperRule = get(baseFolder, 'fields.show_horizontal_rule', true);
  const horizontalRuleColor = get(baseFolder, 'fields.horizontal_rule_color', '#c5aa75');
  const horizontalRuleSize = get(baseFolder, 'fields.horizontal_rule_size', 0.125);
  const backgroundColorOverlay = get(baseFolder, 'fields.background_image_overlay', 'transparent');
  const horizontalScreens = get(props, 'data.componentSpecificData.horizontal_screens') ?? 3
  const screen_left_and_right_padding = get(baseFolder, 'fields.screen_left_and_right_padding', 3);
  const screen_top_and_bottom_padding = get(baseFolder, 'fields.screen_top_and_bottom_padding', 3);
  const listNameStyle = {
    fontSize: convertPxStringToEm(get(baseFolder, "fields.list_name_style.fontSize", 80).toString()) * props.fontSize,
    color: get(baseFolder, "fields.list_name_style.color", '#000000'),
    fontFamily: get(baseFolder, "fields.list_name_style.font", 'Roboto'),
    lineHeight: get(baseFolder, 'fields.list_name_style.lineHeight', 1),
  }

  return (
    <>
      {/* Donor Sea Backgrounds Loop */}
      {backgroundsFolder ? (
        <ComponentLoop1911
          {...props}
          data={{
            ...props.data,
            base_folder: backgroundsFolder.id,
            slidesSlotName: props.data.componentSpecificData.slidesSlotName,
            componentSpecificData: {
              isCycleTimeBasedOnComputerClock: false,
              setCurrentSlideIndexCallback: setCurrentBackgroundIndex,
              setCurrentSlideTimeLeftCallback: setCurrentBackgroundTimeLeft,
              showPlaceholder: true,
              useDistinctFolders: true
            }
          }}
          handleEndOfPlay={null}
        />
      ) : null}

      {/* Background Overlay */}
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={1000}
          classNames="slowfadedelay"
        >
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              backgroundColor: horizontalColorOverlay,
            }}
          />
        </CSSTransition>
      </TransitionGroup>

      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColorOverlay,
        }}
      />

      <div
        ref={el => setHeaderEl(el)}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: (100 / horizontalScreens) + '%',
          padding: `${screen_top_and_bottom_padding / 2}% ${screen_left_and_right_padding / 2}%`
        }}
      >
        <div
          style={{
            padding: '1em'
          }}
          className={props.classes.componentContainer}
        >
          <div
            style={{
              marginBottom: '0.5em',
              lineHeight: 1.25,
              letterSpacing: '0.075em'
            }}
          >
            <RichTextRender htmlString={header} />
          </div>
          <div
            style={{
              lineHeight: 1.25,
              marginBottom: '1.5em'
            }}
          >
            <RichTextRender htmlString={description} />
          </div>
          <div
            style={{
              ...listNameStyle,
            }}
          >
            {currentList.name}
          </div>
        </div>
        {showUpperRule &&
          <div
            className="upper-bar"
            style={{
              width: '100%'
            }}
          >
            <hr
              style={{
                backgroundColor: horizontalRuleColor,
                border: "0 none",
                color: horizontalRuleColor,
                height: props.containerWidth * (horizontalRuleSize / 100),
                marginTop: "0.5em",
              }}
            />
          </div>
        }
      </div>

      {/* Donor Sea */}
      {headerEl && currentList && (
        <DonorSea2305
          {...props}
          key={currentList.id}
          data={{
            ...props.data,
            // base_folder: currentList.id,
            componentSpecificData: {
              ...props.data.componentSpecificData,
              isCycleTimeBasedOnComputerClock: false,
              setFinishedPrerenderCallback: () => {
                setCanStartPlayingBackgroundsLoop(true);
              },
              cycleTimes: backgroundCycleTimes,
              currentWaveCycleTimeIndex: currentBackgroundIndex,
              currentWaveTimeLeft: currentBackgroundTimeLeft,
              headerHeight: headerEl.offsetHeight,
            }
          }}
          handleEndOfPlay={handleEndOfPlay}
        />
      )}
    </>
  );
};

export default EverettDonorSea;
