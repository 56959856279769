import React from 'react';

export function countOnTrue(arr){
  let count = 0;
  arr.forEach(x => {
    if (x){
      count++
    }
  })
  return count;
}

export function convertHexToHexWithAlpha(color) {
  if (!color || !color.color) {
    return '';
  }
  let hexWithAlpha = color.color;
  if (color && color.alpha !== null && color.alpha !== undefined) {
    let alpha = Math.round(color.alpha / 100 * 255).toString(16);
    const hex = color.color;
    if (alpha.length < 2) {
      alpha = "0" + alpha;
    }
    hexWithAlpha = hex + alpha;
  }
  return hexWithAlpha;
};

export function rgbaToHex(color) {
  if (/^rgb/.test(color)) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

    // rgb to hex
    // eslint-disable-next-line no-bitwise
    let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
      .toString(16)
      .slice(1)}`;

    // added alpha param if exists
    if (rgba[3]) {
      let alpha = Math.round(Number(rgba[3]) * 255).toString(16);
      console.log(alpha);
      if (alpha.length < 2) {
        alpha = "0" + alpha;
      }
      hex += alpha;
    }

    return hex;
  }
  return color;
};
