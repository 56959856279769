import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/company/company_role_fields';
import AlertNotification from '../layout/AlertNotification';
import { getCompany, addRole, updateRole } from '../../actions/companyActions';
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils'
import { connect } from "react-redux";
import CompanyBreadCrumbs from './CompanyBreadCrumbs';
import _ from 'lodash';
import { BackLink } from "../utils/BackLink";
import { withRouter } from '../../hooks/withRouter';

function getNewFormConfig(formConfig, scopedPermissions) {
  formConfig.fields["permissions"].options = scopedPermissions;
  return formConfig;
}

class CompanyRoleForm extends Component {

  constructor(props) {
    super(props);

    this.state =
    {
      initialized: false,
      name: '',
      scope: 'company',
      permissions: [],
      submitting: false
    };
    this._dynamicFormRef = null;
  }

  componentDidMount() {
    this.props.getCompany(this.props.params.companyId, () => { this.onInit() }, this.onFail);
  }

  onInit = () => {
    if (this.props.editMode === true) {
      let rpArr = arrayFromKeyedObject(this.props.role__permissions);
      let role_id = this.props.params.roleId;
      let role = this.props.roles[role_id];
      let role__permissions_initial = rpArr.filter(x => x.role === Number(role_id));

      const permissions_initial = role__permissions_initial.map(x => x.permission
        //{
        // const permission = this.props.permissions[x.permission];
        // return {title: permission.name, value: permission.id }}
      )

      this.setState({
        name: role.name,
        initialized: true,
        scope: role.scope,
        permissions: permissions_initial
      })
    }
    else {
      this.setState({
        initialized: true
      })
    }

  }

  onSubmit = (values) => {
    this.setState({ submitting: true })
    if (this.props.editMode === false) {
      this.props.companyRoleAdd(values.permissions, values.name, this.props.params.companyId, values.scope, this.onInsert, this.onError);
    }
    else {
      this.props.updateRole(values.name, this.props.params.roleId, this.props.params.companyId, values.permissions, values.scope, this.onUpdate, this.onError);
      this.setState({
        initialized: false
      })
    }
  }

  roleChangeHandler = (name, value, fieldValues) => {
    if (this.state.scope !== value) {
      //this._dynamicFormRef.overrideFieldValue('permissions', [])
      this.setState({
        name: fieldValues.name,
        scope: value,
      })
    }
  }

  onUpdate = () => {
    this.setState({ submitting: false })
    this.onInit();
    AlertNotification("success", "Sucess", "Role updated")
  }

  onInsert = () => {
    this.setState({ submitting: false })
    AlertNotification("success", "Sucess", "Role created")
  }

  onError = (e) => {
    this.setState({ submitting: false })
  }

  filterPerm = (scopedPerms) => {
    const fieldValues = {
      name: this.state.name,
      scope: this.state.scope,
      permissions: []
    };

    for (const permission of this.state.permissions) {
      if (scopedPerms.find((sP) => sP.value === permission)) {
        fieldValues.permissions.push(permission)
      }
    }
    return fieldValues
  }


  render() {
    if (!this.state.initialized) {
      return <div>Loading...</div>
    }


    let mode = "";
    let formTitle = "";
    if (this.props.company == null || this.props.role__permissions == null) {
      return null;
    }
    else if (this.props.editMode === false) {
      mode = 'add';
      formTitle = "Add Role ";
    }
    else {
      formTitle = "Edit Role ";
      mode = 'edit';
    }

    const scopedPermissions = [];
    let permissionArray = arrayFromKeyedObject(this.props.permissions);
    permissionArray.forEach(permission => {
      if (permission.scope === this.state.scope) {
        scopedPermissions.push({ title: permission.name, value: permission.id });
      }
    });
    let fieldValues = this.filterPerm(scopedPermissions)
    let config = getNewFormConfig(formConfig, scopedPermissions);
    return (
      <div>
        <CompanyBreadCrumbs crumbs={this.props} />
        <div>
          <BackLink />
        </div>
        <h1>{formTitle}</h1>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={config.field_groups}
          fieldsConfig={config.fields}
          fieldValues={fieldValues}
          mode={mode}
          onSubmit={this.onSubmit}
          onError={this.onError}
          formName={"companyRoleForm"}
          enableFieldGrouping={false}
          onChangeHandlers={
            [{ name: "scope", callBackFunction: (name, value, fieldValues) => this.roleChangeHandler(name, value, fieldValues) }]
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  // let permissions_initial = []
  let editMode = false;
  if (ownProps.params.roleId === "new" || ownProps.params.roleId === undefined) {
    editMode = false;
  }
  else {
    editMode = true;
  }

  return {
    company: _.get(state, 'data.companies[' + ownProps.params.companyId + ']'),
    permissions: _.get(state, 'data.permissions'),
    role: _.get(state, 'data.roles[' + ownProps.params.roleId + ']'),
    roles: _.get(state, 'data.roles'),
    role__permissions: _.get(state, 'data.role__permissions', {}),
    editMode: editMode
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    companyRoleAdd: (selectedOptions, name, id, scope, onSuccess, onFail) => {
      dispatch(addRole(selectedOptions, name, id, scope, onSuccess, onFail));
    },
    updateRole: (name, roleId, company, permissions, scope, onSuccess, onFail) => {
      dispatch(updateRole(name, roleId, company, permissions, scope, onSuccess, onFail));
    },
    getCompany: (id, onSuccess, onFail) => {
      dispatch(getCompany(id, onSuccess, onFail));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyRoleForm));
