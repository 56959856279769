import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const eugene_slide: IFolderType = {
  name: FOLDER_TYPE.los_angeles_slide,
  display_name: "Slide",
  folder_category: FOLDER_CATEGORY.STORY,
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        {
          title: "Crossfade",
          value: "crossfade"
        },
        {
          title: "Fade Out",
          value: "transition-fadeout"
        },
        {
          title: "Slide In from Right",
          value: "transition-slide-in-from-right"
        },
        {
          title: "Slide In from Left",
          value: "transition-slide-in-from-left"
        },
        {
          title: "Slide In from Top",
          value: "transition-slide-in-from-top"
        },
        {
          title: "Slide In from Bottom",
          value: "transition-slide-in-from-bottom"
        }
      ]
    },
    file: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777778
      }
    }
  },
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.EugeneSlide,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.EugeneSlide
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_open
    },
    closed: {
      ...tree_icon_types.donor_closed
    }
  }
};
