import { Input, Modal, Tag } from "antd";
import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { IState } from "../../interfaces/main-state.interface";
import { callNotifications } from "../../services/notificationService";
import { ICompany } from "../../shared-global/interfaces/models/company.interface";
import { IUser } from "../../shared-global/interfaces/models/user.interface";
import { NOTIFICATION_TYPE_ALL } from "../../shared/constants";
import { TREE_MODE_RESOURCE } from "../constants";
import AlertNotification from "./AlertNotification";

interface ISwitchCompanyMenu {
  user: IUser;
  companies: { [key: string]: ICompany };
  changeCompany: any;
  fetchProjectFolders: any;
  getNotifications: any;
  setClientOptions: any;
  setFoldersExpanded: any;
}

const SwitchCompanyMenu: React.FC<ISwitchCompanyMenu> = ({
  user,
  companies,
  changeCompany,
  fetchProjectFolders,
  getNotifications,
  setClientOptions,
  setFoldersExpanded,
}) => {
  const [companySelected, setCompanySelected] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [tableData, setTableData] = useState([]);
  const filterInputRef = useRef(null)

  const user_companies_menu_details = useSelector(
    (state: IState) => state.data.user_companies_menu_details,
  );

  useEffect(() => {
    if (filterInputRef.current) {
      filterInputRef.current.focus()
      if (filterText.length) {
        filterInputRef.current.select()
      }
    }
  }, [modalVisible])

  useEffect(() => {
    if (user && user.company) {
      updateCompanySelected(user.company);
    }
  }, [user]);

  useEffect(() => {
    if (user_companies_menu_details) {
      const filteredData = filterTableData(
        user_companies_menu_details,
        filterText,
      );
      const sortedData = sortTableData(filteredData);
      setTableData(sortedData);
    }
  }, [user_companies_menu_details, filterText]);

  const filterTableData = (data, text) => {
    // We search by company name, project name and theme
    return data.filter((row) => {
      const companyName = row.company.name.toLowerCase();
      const projects = row.projects;
      const projectNames = projects.map((project) => project.name.toLowerCase());
      const themes = projects.map((project) => project.theme.toLowerCase());
      const projectNamesString = projectNames.join(" ");
      const themesString = themes.join(" ");
      return (
        companyName.includes(text.toLowerCase()) ||
        projectNamesString.includes(text.toLowerCase()) ||
        themesString.includes(text.toLowerCase())
      );
    });
  };

  const sortTableData = (data) => {
    return [...data].sort((a, b) =>
      a.company.name.localeCompare(b.company.name),
    );
  };

  const updateCompanySelected = (companyId) => {
    if (companyId && companies) {
      const myCompany = companies[companyId];
      if (myCompany) {
        setCompanySelected(myCompany);
      }
    }
  };

  const onClickCompanyInModal = (selectedCompany) => {
    if (selectedCompany.id !== user.company) {
      setClientOptions({
        lock_screen: true,
        message_for_progress: "Switching companies",
        indeterminate: true,
      });
      changeCompany(
        user.id,
        selectedCompany.id,
        user.company,
        onSuccessChangeCompany,
        onFailChangeCompany,
      );
    }
    setCompanySelected(selectedCompany);
    setModalVisible(false);
  };

  const onSuccessChangeCompany = () => {
    setClientOptions({
      lock_screen: false,
      message_for_progress: "...",
      search_clean: true,
      current_template: "All themes",
      applied_content_selected_folder: null,
    });
    if (companySelected) {
      callNotifications(
        companySelected.id,
        NOTIFICATION_TYPE_ALL,
        getNotifications,
      );
    }
    fetchProjectFolders();
    setFoldersExpanded(TREE_MODE_RESOURCE, []);
    AlertNotification("success", "Success", "Company changed successfully");
  };

  const onFailChangeCompany = (message) => {
    setClientOptions({ lock_screen: false, message_for_progress: null });
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  const companyIcon = <i className="far fa-building"></i>;
  const rowRenderer = ({ className, columns, index, rowData, style }) => {
    const backgroundColor = index === hoveredRowIndex ? "#f0f0f0" : "white";

    const rowStyle = {
      backgroundColor,
      cursor: "pointer",
      border: "1px solid #e8e8e8",
    };

    return (
      <div
        className={className}
        style={{ ...style, ...rowStyle, width: style.width - 10 }}
        onMouseOver={() => setHoveredRowIndex(index)}
        onMouseOut={() => setHoveredRowIndex(-1)}
        onClick={() => {
          onClickCompanyInModal(rowData.company);
        }}
      >
        {columns}
      </div>
    );
  };

  return (
    <div className="switch-company-menu">
      {companyIcon}{" "}
      <span onClick={() => setModalVisible(true)}>
        {companySelected ? companySelected.name : "Switch Company"}
      </span>
      <Modal
        title={
          <>
            {companyIcon} {"Select a company"}
          </>
        }
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        style={{
          minWidth: "750px",
        }}
      >
        <Input
          placeholder="Filter"
          ref={filterInputRef}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <div style={{ height: "55vh", marginTop: "1vh" }}>
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                rowHeight={90}
                rowCount={tableData.length}
                headerHeight={30}
                rowGetter={({ index }) => tableData[index]}
                rowRenderer={rowRenderer}
              >
                <Column
                  label="Company"
                  dataKey="company"
                  width={250}
                  cellRenderer={({ rowData, index }) => (
                    <div key={index} style={{ whiteSpace: "normal" }}>
                      <b>{rowData.company.name} ({rowData.projects.length})</b>
                    </div>
                  )}
                />
                <Column
                  label="Projects"
                  dataKey="projects"
                  width={250}
                  cellRenderer={({ rowData }) => (
                    <div style={
                      {
                        borderRight: '1px solid #e8e8e8',
                        borderLeft: '1px solid #e8e8e8',
                        height: '8vh',
                        padding: '10px 0px 0px 10px'
                      }} >
                      {rowData.projects.slice(0, 3).map((project) => (
                        <div key={project.id}>{project.name}</div>
                      ))}
                      {rowData.projects.length > 3 && <div>...</div>}
                      {rowData.projects.length === 0 && <div>No projects</div>}
                    </div>
                  )}
                />
                <Column
                  label="Themes"
                  dataKey="themes"
                  width={200}
                  cellRenderer={({ rowData }) => (
                    <div style={{
                      height: '8vh',
                      padding: '10px 0px 0px 10px',
                    }}>
                      {rowData.projects.slice(0, 3).map((project) => (
                        <div key={project.id} style={{ color: "#2699FB" }}><Tag color="#2699FB">{project.theme}</Tag></div>
                      ))}
                      {rowData.projects.length > 3 && <div>...</div>}
                    </div>
                  )}
                />
              </Table>
            )}
          </AutoSizer>
          {tableData.length === 0 && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '25vh',
                color: '#777'
              }}>
              No data found that matches your search criteria
            </div>
          )}
        </div>
      </Modal >
    </div >
  );
};

export default SwitchCompanyMenu;
