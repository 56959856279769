import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./everett_settings";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { textStyleOptions } from "./everett_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const everett_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.everett_root,
  display_name: "Root",
  acceptable_folder_types: [FOLDER_TYPE.everett_displays, FOLDER_TYPE.everett_presentations, FOLDER_TYPE.everett_content],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    templates: {
      title: "Templates",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "templates"
    },
  },
  fields: {
    // General
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    // Templates
    templates_select: {
      type: "select",
      title: "Choose a template to edit the default definitions",
      field_group: "templates",
      default: "none",
      options: [
        { title: "Select a template", value: "none" },
        { title: "Story", value:"story" },
        { title: "Story with quote", value:"story_with_quote" },
        { title: "Donor List", value: "donor_list" },
        { title: "Donor List with Quote", value: "donor_list_with_quote" },
        { title: "Image with Caption", value: "fullscreen_with_caption" },
        { title: "Fullscreen", value: "fullscreen" },
      ],
    },
    story_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    // Story with Quote General
    story_with_quote_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    // Story
    header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 80,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
    },
    text_top_screen: {
      type: "textstyle",
      title: "Text",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
    },
    story_background: {
      type: "color",
      title: "Background",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_call_to_action_header: {
      type: "textstyle",
      title: "Default QR Call to Action Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      show_char_count: false,
      default: {
        color: "#ffffff",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: "64px",
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_call_to_action_body: {
      type: "textstyle",
      title: "Default QR Call to Action Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        color: "#ffffff",
        fontSize: "44px",
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_qr_color: {
      type: "color",
      default: "#ffffff",
      title: "Default QR Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_logo: {
      type: "file",
      title: "Default Logo",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      default: "#0000007F",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
    },
    story_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Story with Quote
    story_with_quote_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 80,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_with_quote_text_top_screen: {
      type: "textstyle",
      title: "Text Top Screen",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_with_quote_text_bottom_screen: {
      type: "textstyle",
      title: "Text Bottom Screen",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_with_quote_attribution: {
      type: "textstyle",
      title: "Attribution",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
        fontSize: 56,
        italic: true,
        lineHeight: "1"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_with_quote_background: {
      type: "color",
      title: "Background",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_with_quote_call_to_action_header: {
      type: "textstyle",
      title: "Default QR Call to Action Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      show_char_count: false,
      default: {
        color: "#ffffff",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: "64px",
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_call_to_action_body: {
      type: "textstyle",
      title: "Default QR Call to Action Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        color: "#ffffff",
        fontSize: "44px",
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_qr_color: {
      type: "color",
      default: "#ffffff",
      title: "Default QR Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_with_quote_logo: {
      type: "file",
      title: "Default Logo",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      default: "#0000007F",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_with_quote_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_quote_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // Donor General
    donor_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    //  Quote
    quote_header: {
      type: "textstyle",
      title: "Quote Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.BOLD],
        fontSize: 64,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    quote_text_top_screen: {
      type: "textstyle",
      title: "Quote Text Top Screen",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
        fontSize: 60,
        bold: true,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    quote_text_bottom_screen: {
      type: "textstyle",
      title: "Quote Text Bottom Screen",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
        fontSize: 60,
        bold: true,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    quote_attribution: {
      type: "textstyle",
      title: "Quote Attribution",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 48,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    quote_background: {
      type: "color",
      title: "Quote Background",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // Donor List with Quote
    donor_list_header: {
      type: "textstyle",
      title: "Donor List Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 64,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    description: {
      type: "textstyle",
      title: "Donor List Description",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
        fontSize: 36,
        italic: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    category: {
      type: "textstyle",
      title: "Donor List Category",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 28,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#000000",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },    
    donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24,
        lineHeight: "1.5"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    footer: {
      type: "textstyle",
      title: "Donor List Footer",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },

    // Donor List
    donor_list_new_header: {
      type: "textstyle",
      title: "Donor List Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 64,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },
    donor_list_new_description: {
      type: "textstyle",
      title: "Donor List Description",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 36,
        italic: true,
        lineHeight: "1.75"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },
    donor_list_new_category: {
      type: "textstyle",
      title: "Donor List Category",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 28,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },
    donor_list_horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#000000",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_list_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_list_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    donor_list_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      }
    },
    donor_list_show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },
    donor_list_show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },
    donor_list_donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 32,
        lineHeight: "1.5"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
    },
    donor_list_footer: {
      type: "textstyle",
      title: "Donor List Footer",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list',
    },

    // Donor Background
    donor_background_image: {
      title: "Donor List Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_background_image_overlay: {
      type: "color",
      title: "Donor List Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_list_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Image With Caption image_with_caption_
    image_with_caption_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    image_with_caption_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Image With Caption Caption
    image_with_caption_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "right",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        fontSize: 68,
        bold: true,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
    },
    image_with_caption_body: {
      type: "textstyle",
      title: "Body",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "right",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        fontSize: 56,
        lineHeight: "1.5"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
    },
    image_with_caption_background: {
      type: "color",
      title: "Caption Background",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Image With Caption QR
    image_with_caption_call_to_action_header: {
      type: "textstyle",
      title: "Default QR Call to Action Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        color: "#ffffff",
        fontSize: "64px",
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    image_with_caption_call_to_action_body: {
      type: "textstyle",
      title: "Default QR Call to Action Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        color: "#ffffff",
        fontSize: "44px",
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    image_with_caption_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    image_with_caption_qr_color: {
      type: "color",
      default: "#ffffff",
      title: "Default QR Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    image_with_caption_logo: {
      type: "file",
      title: "Default Logo",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    image_with_caption_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      default: "#0000007F",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Image With Caption Background
    image_with_caption_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    image_with_caption_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen_with_caption',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Full Screen
    fullscreen_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_call_to_action_header: {
      type: "textstyle",
      title: "Default QR Call to Action Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
        color: "#ffffff",
        fontSize: "64px",
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fullscreen_call_to_action_body: {
      type: "textstyle",
      title: "Default QR Call to Action Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
        color: "#ffffff",
        fontSize: "44px",
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fullscreen_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fullscreen_qr_color: {
      type: "color",
      title: "Default QR Color",
      default: "#ffffff",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_logo: {
      type: "file",
      title: "Default Logo",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fullscreen_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      default: "#0000007F",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.COMPONENT
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.everett_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.everett_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.everett_content
    }
  ],
  hide_add_icon_in_tree: true
};
