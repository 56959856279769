export const tree_icon_types = {
  default_open: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "folder-open"]
    }
  },
  default_closed: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "folder"]
    }
  },
  donor_open: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "user"]
    }
  },
  donor_closed: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "user"]
    }
  },
  happy_open: {
    icon_type: "raw",
    options: {
      value: "☀️"
    }
  },
  sad_closed: {
    icon_type: "raw",
    options: {
      value: "☁️"
    }
  },
  event: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "calendar-alt"]
    }
  },
  story: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "book"]
    }
  },
  donor_bios: {
    icon_type: "custom-svg",
    options: {
      file_name: "donor-bios.svg"
    }
  },
  timeline: {
    icon_type: "custom-svg",
    options: {
      file_name: "timeline-solid.svg"
    }
  },
  client: {
    icon_type: "custom-svg",
    options: {
      file_name: "client.svg"
    }
  },
  content: {
    icon_type: "custom-svg",
    options: {
      file_name: "content.svg"
    }
  },
  displays: {
    icon_type: "custom-svg",
    options: {
      file_name: "displays.svg"
    }
  },
  presentations: {
    icon_type: "custom-svg",
    options: {
      file_name: "presentations.svg"
    }
  },
  layouts: {
    icon_type: "custom-svg",
    options: {
      file_name: "layouts.svg"
    }
  },
  donor_lists: {
    icon_type: "custom-svg",
    options: {
      file_name: "donor-lists.svg"
    }
  },
  stories: {
    icon_type: "custom-svg",
    options: {
      file_name: "stories.svg"
    }
  },
  attract_loops: {
    icon_type: "custom-svg",
    options: {
      file_name: "attract-loop.svg"
    }
  },
  slideshow: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "images"]
    }
  },
  slide: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "image"]
    }
  },
  display: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "desktop"]
    }
  },
  water: {
    icon_type: "fontawesome",
    options: {
      class_name: "fa-lg",
      icon: ["fas", "water"]
    }
  },
  presentation: {
    icon_type: "custom-svg",
    options: {
      file_name: "presentations.svg",
      class_name: "fa-lg",
      icon: ["fas", "presentation"]
    }
  }
};
