import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Tabs, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IHardware } from "../../interfaces/hardware.interface";
import { IState } from "../../interfaces/main-state.interface";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import JsonListView from "../list_view/JsonListView";
import {
  computerColumns,
  monitorColumns,
  ipOutletsColumns,
  upsColumns,
  otherColumns,
  contactColumns,
  accessColumns,
  locationColumns
} from "../../shared-global/jsonViewConfig/hardwareInformationColumns";
import {
  updateDisplayHardwareInfo,
  downloadHardwareInformationCsv,
  downloadHardwareInformationTxt
} from "../../actions/folderActions";
import get from "lodash/get";

interface IHardwareInformationProps {
  displayIndex: number;
  data: IHardware | null;
  folder: string;
  visible: boolean;
  cancel: any;
}

const tabs = [
  {
    title: "Computer",
    key: "computer",
    columns: computerColumns
  },
  {
    title: "Monitor",
    key: "monitor",
    columns: monitorColumns
  },
  {
    title: "IP Outlet",
    key: "ip_outlet",
    columns: ipOutletsColumns
  },
  {
    title: "UPS",
    key: "ups",
    columns: upsColumns
  },
  {
    title: "Other",
    key: "other",
    columns: otherColumns
  },
  {
    title: "Contacts",
    key: "contacts",
    columns: contactColumns
  },
  {
    title: "Access",
    key: "access",
    columns: accessColumns
  },
  {
    title: "Location",
    key: "location",
    columns: locationColumns
  }
];

const TabPane = Tabs.TabPane;
const HardwareInformation: React.FC<IHardwareInformationProps> = (props) => {
  const dispatch = useDispatch();
  const client_options = useSelector(
    (state: IState) => state.client_options
  );
  const folders = useSelector((state: IState) => state.data.folders);
  const [folder, setFolder] = useState<IFolder | null>(null);

  useEffect(() => {
    if (props.folder) {
      const [, folderId] = props.folder.split("-");
      if (folders[folderId]) {
        setFolder(folders[folderId]);
      }
    }
  }, [props.folder, folders]);

  const update = (folderId, area, items, onSuccess, onError) => {
    dispatch(
      updateDisplayHardwareInfo(
        folderId,
        props.displayIndex,
        area,
        items,
        onSuccess,
        onError
      )
    );
  };

  const downloadCsv = (folderId, area, onSuccess, onError) => {
    dispatch(
      downloadHardwareInformationCsv(
        folderId,
        props.displayIndex,
        area,
        onSuccess,
        onError
      )
    );
  };

  const downloadTxt = (folderId, onSuccess, onError) => {
    dispatch(downloadHardwareInformationTxt(folderId, props.displayIndex, onSuccess, onError));
  };

  const listHeight = 400;

  return (
    <>
      <Modal
        width={960}
        visible={props.visible}
        onOk={props.cancel}
        onCancel={props.cancel}
        title={
          <React.Fragment>
            <FontAwesomeIcon className="fa" icon={["fas", "file-download"]} />
            &nbsp;&nbsp;Display Report Detail / {folder?.name}
          </React.Fragment>
        }
      >
        <Tabs defaultActiveKey="computer">
          {tabs.map((t) => (
            <TabPane tab={t.title} key={t.key}>
              <JsonListView
                showAddButton={true}
                listHeight={listHeight}
                listItems={get(props.data, t.key, [])}
                listColumns={t.columns}
                addBulk={() => {}}
                updateBulk={(items, onSuccess, onError) =>
                  update(folder.id, t.key, items, onSuccess, onError)
                }
                moveBulk={() => {}}
                instanceBulk={() => {}}
                deleteBulk={() => {}}
                setUpdateListViewTouch={() => {}}
                setClientOptions={() => {}}
                toggleFolderExpanded={() => {}}
                setFoldersExpanded={() => {}}
                parentFolder={null}
                folderTypes={[]}
                recalculateWidth={[
                  client_options.detail_drawer_open,
                  client_options.tree_panel_open
                ]}
                aws_instance={null}
                resources={{}}
                resource__folders={{}}
                folder__status={{}}
                sorting="other"
                users={{}}
                applied_content_tree_expanded={false}
                client_options={client_options}
                exportCsv={(onSuccess, onError) =>
                  downloadCsv(folder.id, t.key, onSuccess, onError)
                }
              />
            </TabPane>
          ))}
        </Tabs>
        <div style={{ position: 'absolute', right: '45px', top: '80px' }}>
          <Button
            onClick={() => downloadTxt(folder.id, null, null)}
            className="list-view_button"
            type="primary"
            size="large"
            style={{ width: "auto" }}
          >
            <span className="list-view_button-text">Export All</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default HardwareInformation;
