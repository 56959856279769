import { Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFolder } from "../../actions/folderActions";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { ISelectedFolderMeta, IState } from "../../interfaces/main-state.interface";
import { TREE_TYPE } from "../../shared-global/enums/ui-enums";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { humanReadableFolderPath, isFromContentFolder, reversePathsFromNode } from "../../utils/treeUtils";
import useStyles from "./styles";
import { getChildrenData } from "../../shared/utils/commonDataUtils";
import ConfirmDeleteFolderModal from './ConfirmDeleteFolderModal'
import { isInPresentationFolder, isLowLevelItem, isInDisplayFolder } from "../../services/treeService";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IFolderType } from "../../shared-global/interfaces/folder-types/folders/IFolderType";
interface IDeleteFolderModalProps {
  onCancel: Function;
  onCompletion: Function;
  type: TREE_TYPE;
  folderId: number;
  folderFolderId: number;
  visible: boolean;
  selectedFolderMeta: ISelectedFolderMeta;
  path: number[];
  folder__folders: { [key: string]: IFolderFolder }
  folders: { [key: string]: IFolder }
  folder_types: { [key: string]: IFolderType }
}

export const DeleteFolderModal: React.FC<IDeleteFolderModalProps> = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [confirmText, setConfirmText] = useState("");

  const folder_types = useSelector((state: IState) => state.data.folder_types);

  const folders = useSelector((state: IState) => state.data.folders);

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false)

  const folder__folders: { [key: string]: IFolderFolder } = useSelector((state: IState) => {
    if (props.type === TREE_TYPE.RESOURCE) {
      return state.data.resourcetree_folder__folders;
    } else {
      return state.data.folder__folders;
    }
  });

  const folder = useSelector((state: IState) => {
    if (props.type === TREE_TYPE.RESOURCE) {
      return state.data.resourcetree_folders[props.folderId];
    } else {
      return state.data.folders[props.folderId];
    }
  });

  const foldersRelated = useSelector((state: IState) => {
    const trees = reversePathsFromNode(props.folderId, state.data.folder__folders, [props.folderFolderId]);
    return trees.map(tree => humanReadableFolderPath(tree, state.data.folders))
  });

  const path = props.selectedFolderMeta.path ? props.selectedFolderMeta.path : [];
  const fromContentFolder = isFromContentFolder(path, folder__folders, folders, folder_types);

  // We make sure that the parent folder is not a folder that the "move action" is instance in the folder type
  let moveActionIsInstance = false;

  if (props.type === TREE_TYPE.APPLIED_CONTENT) {
    if (path && path.length > 1) {
      const parentFolderFolder = folder__folders[path[path.length - 2]];
      const parentFolder = folders[parentFolderFolder.child_folder];
      const parentFolderType = folder_types[parentFolder.folder_type];
      if (parentFolderType && parentFolderType.tree_dropped_into_action && parentFolderType.tree_dropped_into_action[0].action === "instance") {
        moveActionIsInstance = true;
      }
    }
  }

  const handleSave = () => {
    const folderType = props.type || "applied_content";
    closeDeleteConfirmationModal();
    dispatch(
      setClientOptions({
        indeterminate: true,
        lock_screen: true,
        message_for_progress: 'Deleting Folders'
      })
    )
    dispatch(
      removeFolder(
        props.folderId,
        props.folderFolderId,
        folderType,
        path.join("/"),
        () => onSuccess(),
        (message) => onError(message),
        true
      )
    );
  }

  const closeDeleteConfirmationModal = () => {
    setOpenDeleteConfirmationModal(false)
  }

  const onSave = () => {
    const childData = getChildrenData(
      {
        folder__folders: Object.keys(folder__folders).map((k) => folder__folders[k]),
        folders: Object.keys(folders).map((k) => folders[k])
      },
      props.folderId
    );

    const isInPresentations = isInPresentationFolder(path, folder__folders, folders, folder_types);

    const isInDisplay = isInDisplayFolder(path, folder__folders, folders, folder_types)

    if (childData.folders.length && !isInPresentations && !isInDisplay) {
      setOpenDeleteConfirmationModal(true);
      return;
    }

    handleSave();
    setConfirmText("")
  };

  const onCancel = () => {
    props.onCancel();
  };

  const onSuccess = () => {
    dispatch(
      setClientOptions({
        indeterminate: false,
        lock_screen: false,
        message_for_progress: ''
      })
    )
    props.onCompletion({
      type: "success",
      message: "Success",
      description: "Item deleted"
    });
  };

  const onError = (message) => {
    dispatch(
      setClientOptions({
        indeterminate: false,
        lock_screen: false,
        message_for_progress: '',
      }),
    );
    props.onCompletion({
      type: 'error',
      message: 'Error',
      description: message,
    });
  };

  const renderModalContent = (folder_name) => {
    if (!props.visible) {
      return null;
    }
    return (
      <div className="flex items-center justify-end">
        <div className={['unsaved-changes-modal_header', classes.modalFontWeight].join(' ')}>Delete {folder.name}</div>
        <div className="text-center">Are you sure you want to delete {folder_name}?</div>
      </div>
    );
  };

  if (!folder) {
    return null;
  }

  if (isLowLevelItem(props.path, props.folder__folders, props.folders, props.folder_types)) {
    return (
      <Modal
        visible={props.visible}
        onCancel={onCancel}
        onOk={onCancel}
        okText="Got it"
        width={'33rem'}
        cancelButtonProps={{ style: { display: 'none' } }}
        destroyOnClose={true}
        className={classes.modal}
      >
        <div>
          <strong>Caution:</strong> You're attempting to delete a folder or item within this presentation that is also
          directly linked to the main Content area.
        </div>

        <br />

        <div>
          <p>
            <strong>Why can't I delete this item?</strong> <br />
            The folder or item you're trying to delete is a low-level item, and deleting it here will remove it not only from
            this presentation but also from the main Content area.
          </p>
        </div>

        <div>
          <p>
            <strong>What should I do instead?</strong> <br />
            To manage folders and items safely, make changes in the main Content area outside the presentation. Any
            modifications in this type of items there will be reflected in this presentation.
          </p>
        </div>
      </Modal>
    );
  } else {
    return (
      <>
        <Modal
          visible={props.visible}
          onCancel={onCancel}
          onOk={onSave}
          width={'33rem'}
          destroyOnClose={true}
          className={classes.modal}
        >
          {renderModalContent(folder.name)}
        </Modal>
        <ConfirmDeleteFolderModal
          onSave={handleSave}
          visible={openDeleteConfirmationModal}
          closeModal={closeDeleteConfirmationModal}
          folder={folder}
        />
      </>
    );
  }
};

export default DeleteFolderModal;
