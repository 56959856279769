import React, {useCallback} from "react"
import { createUseStyles } from "react-jss";
import AlpineQuote from "./AlpineQuote";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";
import MediaCarousel from "../../standard/MediaCarousel";
import {useFolder} from "../../../hooks/useFolder";
import {useScale} from "../../../hooks/useScale";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const AlpineMediaWithQuote2 = (props) => {

  const {
    getBaseFolder,
    getBaseFolderFolderType,
    getBaseFolderFieldValue,
  } = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const {
    standardSizing,
    getUnitBasedOnHeight,
  } = useScale(props.data.base_folder);


  const backgroundColor = getBaseFolderFieldValue("background_color", "transparent");
  const quoteBackgroundColor = getBaseFolderFieldValue("quote_background_color", "transparent");

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    standardSizing,
    getUnitBasedOnHeight,
    quoteBackgroundColor
  });

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();
  return (
    <div className={classes.componentContainer}>
      <div className={classes.mediaWithQuote2}>
        <div className="carouselWrapper">
          <MediaCarousel
            {...props}
            componentSpecificData={{
              customStyleImage: {
                maxHeight: '84em',
                width: "100%",
                backgroundPosition: "bottom center",
                backgroundSize: "contain"
              },
              customStyleVideo: {
                hideOffset: true,
                maxHeight: '40em',
                position: 'relative'
              },
              slickConfiguration: {
                fade: true,
                speed: 2000
              }
            }}
            resourceFoldersCarousel={resourceFoldersCarousel}
            width="100%"
            height="100%"
            slotNames={["image"]}
            dotsStyles={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: "100%",
              marginTop: "2em"
            }}
          />
        </div>
        <div className="quoteWrapper">
          <AlpineQuote
            {...props}
            quote={baseFolder}
            baseFolderType={baseFolderType}
          />
        </div>
      </div>
    </div>
  )
}

export default AlpineMediaWithQuote2
