import {COMPONENT_NAME, FIELD_TYPE, FOLDER_TYPE, PREVIEW_TYPE} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { tree_icon_types } from "../tree_icon_types";
import {QRPositionOptions, textstyle_options, transitions, verticalJustificationOptions} from "./phoenix_settings";
import {FLEX_CLASSES, IMAGE_ALIGNMENT} from "../../../../enums/ui-enums";
import {fonts, FontsFamilies, FontVariants} from "../../../../fonts";
import {textStyleOptions} from "../everett/everett_settings";

export const phoenix_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.phoenix_root,
  display_name: "Phoenix Root",
  acceptable_folder_types: [
    FOLDER_TYPE.phoenix_content,
    FOLDER_TYPE.phoenix_presentations,
    FOLDER_TYPE.phoenix_displays
  ],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    templates: {
      title: "Templates",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "templates"
    },
  },
  fields: {
    // ROOT
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    // Templates
    templates_select: {
      type: "select",
      title: "Choose a template to edit the default definitions",
      field_group: "templates",
      default: "none",
      options: [
        { title: "Select a template", value: "none" },
        { title: "Story Defaults", value: "story" },
        { title: "Message Defaults", value: "message" },
        { title: "Fullscreen Defaults", value: "fullscreen" },
        { title: "List Defaults", value: "list" },
        { title: "Donor Sea Defaults", value: "donor_sea" },
        { title: "Checkerboard Defaults", value: "checkerboard" }
      ],
    },
    // Story Fields
    story_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story"
    },
    story_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_vertical_justification: {
      type: "select",
      title: "Story Text Position",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      default: "top",
      options: verticalJustificationOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_mirror_content: {
      type: "bool",
      title: "Mirror",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story"
    },
    story_subhead: {
      type: "textstyle",
      title: "Subhead",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 68,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story"
    },
    story_body: {
      type: "textstyle",
      title: "Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 56,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story"
    },
    story_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_full_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_horizontal_rule: {
      type: "bool",
      title: "Horizontal Rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    left_bar: {
      type: "bool",
      title: "Left Vertical Accent Bar",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    left_bar_size: {
      title: "Left Vertical Accent Bar Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2.0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    left_bar_color: {
      type: "color",
      title: "Left Vertical Accent Bar Color",
      default: "#F8981D",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar: {
      type: "bool",
      title: "Right Vertical Accent Bar",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar_size: {
      title: "Right Vertical Accent Bar Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2.0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar_color: {
      type: "color",
      title: "Right Vertical Accent Bar Color",
      default: "#F8981D",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_color: {
      type: "color",
      title: "Default Story Background Color",
      default: "#104E8ABF",
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_qr_position: {
      type: "select",
      title: "Position",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      default: "bottom-left",
      options: QRPositionOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_logo: {
      title: "Default Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      validation: {
        rules: [
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_qr_color: {
      type: "color",
      title: "Default QR Color",
      default: "#ffffff",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_call_to_action_header: {
      title: "Call To Action Header",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 64,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    story_call_to_action_body: {
      title: "Call To Action Body",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 56,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    story_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    // Message
    message_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message"
    },
    message_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    message_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 96,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message"
    },
    message_body: {
      type: "textstyle",
      title: "Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.BOLD],
        fontSize: 60,
        lineHeight: "1.5",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message"
    },
    message_background_color: {
      type: "color",
      title: "Default Background Color",
      default: "#76232f",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    message_qr_position: {
      type: "select",
      title: "Position",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      default: "bottom-left",
      options: QRPositionOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    message_logo: {
      title: "Default Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    message_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      validation: {
        rules: [
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    message_qr_color: {
      type: "color",
      title: "Default QR Color",
      default: "#ffffff",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    message_call_to_action_header: {
      title: "Call To Action Header",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 64,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    message_call_to_action_body: {
      title: "Call To Action Body",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 56,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    message_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    message_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    message_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    // Fullscreen
    fullscreen_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen"
    },
    fullscreen_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_qr_position: {
      type: "select",
      title: "Position",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      default: "bottom-left",
      options: QRPositionOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_logo: {
      title: "Default Logo",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    fullscreen_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      validation: {
        rules: [
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    fullscreen_qr_color: {
      type: "color",
      title: "Default QR Color",
      default: "#ffffff",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    fullscreen_call_to_action_header: {
      title: "Call To Action Header",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 64,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    fullscreen_call_to_action_body: {
      title: "Call To Action Body",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 56,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
    },
    fullscreen_qr_background_color: {
      type: "color",
      title: "Default QR Background Color",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fullscreen",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },


    // List
    list_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_list_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    donor_list_description: {
      type: "textstyle",
      title: "Description",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    donor_list_category: {
      type: "textstyle",
      title: "Category",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 72,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    show_upper_rule: {
      type: "bool",
      title: "Donor List Upper Horizontal Rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    show_lower_rule: {
      type: "bool",
      title: "Donor List Lower Horizontal Rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Donor List Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Donor List Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 5,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    list_donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 68,
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_footer: {
      type: "textstyle",
      title: "Footer",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 48,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_margin_bezel: {
      type: "integer",
      title: "Donor List Margin Around Bezel",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    donor_line_height: {
      title: "Donor Line Height",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 1,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    donor_name_margin_bottom: {
      type: "integer",
      title: "Donor Name Bottom Margin",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list"
    },
    list_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    list_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },

    // Checkerboard
    checkerboard_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "checkerboard",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    checkerboard_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "checkerboard",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    checkerboard_words_style: {
      type: "textstyle",
      title: "Default Inspirational Words Style",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.BOLD],
        fontSize: 160,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "checkerboard"
    },
    checkerboard_related_words_style: {
      type: "textstyle",
      title: "Default Related Inspirational Words Style",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.BOLD],
        fontSize: 160,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "checkerboard"
    },
    checkerboard_words_background_color: {
      type: "color",
      title: "Default Insprational/Related Words Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "checkerboard",
    },

    // Donor Sea
    donor_sea_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea"
    },
    donor_sea_description: {
      type: "textstyle",
      title: "Description",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea"
    },
    donor_sea_category: {
      type: "textstyle",
      title: "Category",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 72,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea"
    },
    donor_sea_horizontal_rule: {
      type: "bool",
      title: "Horizontal Rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fade_in_donors_time: {
      type: "integer",
      title: "Fade In Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade in after the background is cycled into.",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fade_out_donors_time: {
      type: "integer",
      title: "Fade Out Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade out before the background is cycled.",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    screen_top_and_bottom_margin: {
      type: "integer",
      title: "Screen Top and Bottom Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      default: 7,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    screen_left_and_right_margin: {
      type: "integer",
      title: "Screen Left and Right Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      default: 4,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    min_margin_between_names_horizontally: {
      type: "integer",
      title: "Min Horizontal distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another horizontally. The distance is expressed in percentage and is relative to the width of the region minus the specified margin",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    min_margin_between_names_vertically: {
      type: "integer",
      title: "Min Vertical distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another vertically. The distance is expressed in percentage and is relative to the height of the region minus the specified margin",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_name_style: {
      type: "textstyle",
      title: "List Name Style",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 32,
        bold: true
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
    },
    donor_sea_cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      default: 40,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "donor_sea",
    },

  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  has_font_config: false,
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.phoenix_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.phoenix_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.phoenix_content
    }
  ],
  hide_add_icon_in_tree: true
};
