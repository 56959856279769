import React, {Component} from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/user_companies_fields';
import {connect} from "react-redux";
import {changeCompany} from '../../actions/userActions';
import AlertNotification from '../layout/AlertNotification';
import {arrayFromKeyedObject} from '../../shared/utils/collectionUtils';
import { withRouter } from '../../hooks/withRouter';

class ConfigureCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCompanies: true,
    };
  }

  onSubmit = (values) => {
    if (values.company_id !== this.props.user.company) {
      this.props.changeCompany(this.props.user.id, values.company_id, this.props.user.company, this.onUpdate, this.onError);
    }
  };

  onUpdate = () => {
    AlertNotification('success', 'Success', "Record successfully updated")
  };

  onError = (e) => {
    AlertNotification('error', 'Form error', e.message || 'An error has occurred')
  };

  noop = () => {};

  render() {
    const {companies, user} = this.props;

    if (companies.length > 0) {
      const options = Object.values(companies).map(x => {
        return {
          title: x.name,
          value: x.id
        };
      });
      formConfig.fields.company_id['options'] = options;
    }

    return (
      <div>
        <br/>
        <br/>
        {
          companies.length === 0 ?
            <p>Loading</p> :
            <DynamicForm
              submitting={this.state.submitting}
              fieldGroupsConfig={formConfig.field_groups}
              fieldsConfig={formConfig.fields}
              fieldValues={{
                company_id: user.company
              }}
              mode={'edit'}
              onSubmit={(values) => this.onSubmit(values)}
              onError={this.noop}
              canUpdate={true}
              submit_button_title="Submit"
            />
        }
      </div>
    )
  }
}

const getUserCompanies = (user = {}, user__companies = [], companies = []) => {
  let myCompanies = [];
  const companyArray = arrayFromKeyedObject(companies);
  const userCompanyArray = arrayFromKeyedObject(user__companies);
  if ( !!user && !!user.id && !!companyArray) {
    myCompanies = companyArray.filter( c => {
      return c.id === user.company || userCompanyArray.some( uc => uc.user === user.id && uc.company === c.id)
    })
  }
  return myCompanies;
};

const mapStateToProps = (state) => {  const user = state.data.user;
  const user__companies = state.data.user__companies;
  const companies = state.data.companies;
  const companiesByUser = getUserCompanies(user, user__companies, companies);

  return {
    user: state.data.user,
    companies: companiesByUser
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeCompany: (user_id, company_id, old_company_id, onSuccess, onFail) => {
      dispatch(changeCompany(user_id, company_id, old_company_id, onSuccess, onFail))
    },
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigureCompanies));
