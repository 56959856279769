
export default {
  field_groups: {
    default: {
      title: 'User:',
      default_expanded: true,
    }
  },
  fields: {
    first_name: {
      type: 'text',
      title: 'First Name:',
      validation: { rules: [{ name: 'isRequired' }] },
      //default: ''
    },
    last_name: {
      type: 'text',
      title: 'Last Name:',
      validation: { rules: [{ name: 'isRequired' }] },
      //default: ''
    },
    email: {
      type: 'text',
      title: 'Email:',
      validation: { rules: [{ name: 'isRequired' }] },
      //default: ''
    }
  }
};