export default {
  field_groups: {
    default: {
      title: 'New Folder',
      default_expanded: true,
    },
  },
  fields: {
    name: {
      type: 'text',
      title: 'Label',
      validation: { rules: [{ name: 'isRequired' }] },
    },
    folder_type: {
      type: 'images',
      title: 'Type',
      validation: {
        rules: [{ name: 'isFolderTypeRequired' }],
      },
      options: [],
    },
  },
};
