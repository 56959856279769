import { Checkbox, Col, Collapse, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../interfaces/main-state.interface";
import { FIELD_TYPE } from "../../shared-global/enums/folder-type-enums";
import { ISelectedInheritFieldsState } from "../../shared-global/interfaces/custom/inherited-fields-state.interface";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";

interface IInheritFieldsModalProps {
  onCancel: Function;
  onCompletion: Function;
  onOk: Function;
  selectedFolder: IFolder;
  visible: boolean;
}

export const InheritFieldsModal: React.FC<IInheritFieldsModalProps> = (props) => {
  const dispatch = useDispatch();
  const { Panel } = Collapse

  const folder_types = useSelector((state: IState) => state.data.folder_types);

  const [selectedFields, setSelectedFields] = useState({} as ISelectedInheritFieldsState)
  const [fieldsWithoutSubfields, setFieldsWithoutSubfields] = useState({} as ISelectedInheritFieldsState)
  const [checkAll, setCheckAll] = useState(false)


  const getTextStyleDisplayName = (optionKey: string) => {
    var result = optionKey.replace( /([A-Z])/g, " $1" );
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  useEffect(() => {
    if (folder_types && props.selectedFolder){
      const folder_type = folder_types[props.selectedFolder.folder_type]
      const field_state = {} as ISelectedInheritFieldsState
      if(folder_type){
      Object.keys(folder_type.fields).map((fkey) => {
        const field = folder_type.fields[fkey]
        if (field?.inheritable_settings?.pass_down_to_children){
          if (field.type === FIELD_TYPE.textstyle){

            const subfieldValues = {} as ISelectedInheritFieldsState
            Object.keys(field.options).forEach((optionKey) => {
              subfieldValues[optionKey] = {
                field_name: optionKey,
                field_title: getTextStyleDisplayName(optionKey), //TODO pretty name
                selected: false,
                field_type: field.type
              }
            })

            const textStyleField = {
              field_name: fkey,
              field_title: folder_type.fields[fkey].title, //TODO pretty name
              sub_fields: subfieldValues,
              selected: false,
              field_type: field.type
            }
            field_state[fkey] = textStyleField;
          }
          else{
            field_state[fkey] = {
              field_name: fkey,
              field_title: folder_type.fields[fkey].title,
              selected: false,
              field_type: field.type
            }
          }
        }
      })
    }
      const fieldsNoSubFields = {}
      const fullFields = {}

      for (const key of Object.keys(field_state)) {
        if (field_state[key].sub_fields && Object.keys(field_state[key].sub_fields).length) {
          fullFields[key] = field_state[key]
        } else {
          fieldsNoSubFields[key] = field_state[key]
        }
      }
      setFieldsWithoutSubfields(fieldsNoSubFields)
      setSelectedFields(fullFields);
    }
  }, [folder_types, props.selectedFolder])


  const toggleCheckAll = (ev) => {
    setCheckAll(ev.target.checked)
    const newFieldsWithoutSubFields = {...fieldsWithoutSubfields}

    for (const fieldKey of Object.keys(fieldsWithoutSubfields)) {
      newFieldsWithoutSubFields[fieldKey] = {
        ...newFieldsWithoutSubFields[fieldKey],
        selected: ev.target.checked,
      }
    }
    const newSelectedFields = {...selectedFields}
    const newSelectedSubfields = {} as ISelectedInheritFieldsState
    for (const fieldKey of Object.keys(selectedFields)) {
      if (selectedFields[fieldKey].sub_fields) {
        Object.keys(selectedFields[fieldKey].sub_fields).forEach((subfieldKey) => {
          newSelectedSubfields[subfieldKey] = {
            ...selectedFields[fieldKey].sub_fields[subfieldKey],
            selected: ev.target.checked
          }
        })
      }
      newSelectedFields[fieldKey] = {
        ...newSelectedFields[fieldKey],
        selected: ev.target.checked,
        sub_fields: newSelectedSubfields
      }
    }

    setFieldsWithoutSubfields(newFieldsWithoutSubFields)
    setSelectedFields(newSelectedFields)
  }


  const onCancel = () => {
    props.onCancel();
  };

  const onSuccess = () => {
    props.onCompletion({
      type: "success",
      message: "Success",
      description: "Fields set for all children.",
    });
  };

  const onError = (message) => {
    props.onCompletion({
      type: "error",
      message: "Error",
      description: message,
    });
  };

  const renderFields = () => {
    return (
      <>
        <div>
          <Checkbox
            defaultChecked={true}
            className="checkbox-input"
            checked={checkAll}
            onChange={(ev) => toggleCheckAll(ev)}
          >
            <label className="form-input-label" style={{fontWeight: '600'} as unknown}>SELECT ALL FIELDS</label>
          </Checkbox>
        </div>
        {
          Object.keys(fieldsWithoutSubfields).map((fieldKey, index) => {
            return (
              <div key={index}>
                <Checkbox
                  defaultChecked={true}
                  className="checkbox-input"
                  checked={fieldsWithoutSubfields[fieldKey].selected}
                  onChange={(ev) => {
                    const newFieldsWithoutSubFields = {...fieldsWithoutSubfields}
                    const newSelectedSubfields = {} as ISelectedInheritFieldsState
                    newFieldsWithoutSubFields[fieldKey] = {
                      ...newFieldsWithoutSubFields[fieldKey],
                      selected: ev.target.checked,
                      sub_fields: newSelectedSubfields
                    }
                    return setFieldsWithoutSubfields(newFieldsWithoutSubFields)
                  }}
                >
                  <label className="form-input-label" style={{fontWeight: '600'} as unknown}>{fieldsWithoutSubfields[fieldKey].field_title}</label>
                </Checkbox>
              </div>
            )
          })
        }
        {Object.keys(selectedFields).map((fieldKey, index) => {
          return (
            <Collapse bordered={false} key={index} expandIconPosition="right">
              <Panel header={selectedFields[fieldKey].field_title} key={index} style={{backgroundColor: "white", fontWeight: "bold"}}>
                <Row>
                  <Col>
                    <div style={{marginLeft: 32, marginBottom: 24}}>
                      <Checkbox
                        defaultChecked={true}
                        className="checkbox-input"
                        checked={selectedFields[fieldKey].selected}
                        onChange={(ev) => {
                          const newSelectedFields = {...selectedFields}
                          const newSelectedSubfields = {} as ISelectedInheritFieldsState
                          if (selectedFields[fieldKey].sub_fields) {
                            Object.keys(selectedFields[fieldKey].sub_fields).forEach((subfieldKey) => {
                              newSelectedSubfields[subfieldKey] = {
                                ...selectedFields[fieldKey].sub_fields[subfieldKey],
                                selected: ev.target.checked
                              }
                            })
                          }
                          newSelectedFields[fieldKey] = {
                            ...newSelectedFields[fieldKey],
                            selected: ev.target.checked,
                            sub_fields: newSelectedSubfields
                          }
                          return setSelectedFields(newSelectedFields)
                        }}
                      >
                        <label className="form-input-label" style={{fontWeight: '600'} as unknown}>Select All</label>
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {renderSubfields(fieldKey, selectedFields[fieldKey].sub_fields)}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )
        })}
      </>)
  }

  const renderSubfields = (selectedFieldKey: string, subfields:ISelectedInheritFieldsState) => {
    if (subfields && Object.keys(subfields).length){
      return (
        <div style={{marginLeft: 32}}>
          {
        Object.keys(subfields).map((subKey, index) => {
          return (
            <Checkbox
            key={index}
            defaultChecked={true}
            className="checkbox-input"
            checked={selectedFields[selectedFieldKey].sub_fields[subKey].selected}
            onChange={(ev) => {
              const newSelectedFields = {...selectedFields}
              const newSelectedSubfields = {...subfields}
              newSelectedSubfields[subKey] = {
                ...newSelectedSubfields[subKey],
                selected: ev.target.checked
              }
              newSelectedFields[selectedFieldKey] = {
                ...newSelectedFields[selectedFieldKey],
                sub_fields : newSelectedSubfields
              }
              return setSelectedFields(newSelectedFields)
            }}
          >
            <label className="form-input-label" style={{fontWeight: '600'} as unknown}>{subfields[subKey].field_title}</label>
          </Checkbox>
          )
        })}
        </div>

      )
    }
  }

  const renderModalContent = (folder_name) => {
    if (!props.visible) {
      return null;
    }
    else {
      return (
        <div>
          <div style={{maxHeight: '60vh', overflow: 'scroll'}}>
            Select what fields you would like to set for all children of {folder_name}. Clicking okay will update all the nested folders / items. This operation cannot be undone.
            <br /><br />
            {renderFields()}
          </div>
        </div>
      )
    }
  };

  if (!props.selectedFolder) {
    return null;
  }

  return (
    <Modal
      title={"Apply Defaults " + props.selectedFolder.name}
      visible={props.visible}
      onCancel={onCancel}
      onOk={() => props.onOk({...selectedFields, ...fieldsWithoutSubfields})}
      width="50vw"
      destroyOnClose={true}
    >
      {renderModalContent(props.selectedFolder.name)}
    </Modal>
  );
};

export default InheritFieldsModal
