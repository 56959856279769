import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  previewWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    background: "white"
  }
});

export default useStyles;
