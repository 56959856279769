import { COMPONENT_NAME, FIELD_TYPE, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { textStyleOptions, wysiwygSettings } from "./coburg_settings";

const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};
export const coburg_presentation: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.coburg_presentation,
  display_name: "Coburg Presentation",
  acceptable_folder_types: [FOLDER_TYPE.coburg_attract_loop, FOLDER_TYPE.coburg_search, FOLDER_TYPE.coburg_menu],
  tabs: {
    general: {
      title: "General"
    },
    defaults: {
      title: "Defaults",
      notification: {
        message: "The following settings, can override all the children folder of these projects (if apply). This would"
      }
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: false,
      order: 0,
      tab: "general"
    },
    defaults: {
      title: "Defaults",
      default_expanded: false,
      order: 0,
      tab: "defaults"
    }
  },
  fields: {
    menu_header: {
      title: "Menu Header",
      type: "hybrid_text_input",
      field_group: "general",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      }
    },
    font_groups: {
      type: FIELD_TYPE.select,
      title: "Font Group",
      field_group: "general",
      settings: {
        options: "font_groups", // This is used for populating options with dynamic data.
        callback_on_change: "apply_font_group" // Action to perform when the value in the select is changed. The name will serve to filter other actions when implemented.
      }
    },
    background: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    color_overlay: {
      type: "color",
      title: "Default Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    accent_color: {
      type: "color",
      title: "Default Accent Color",
      default: "#B14182",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    heading: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Headings",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_title: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Titles",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_items: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Items",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    headline: {
      title: "Story Templates Header",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Story Templates SubHead",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 28
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Body",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 25
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    name_list: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Donor Names",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_slider_arrow_color: {
      type: "color",
      title: "Menu Arrows",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    quote: {
      title: "Quote",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    attribution: {
      title: "Attribution",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    footer: {
      title: "Donor Lists Footer",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 18,
        italic: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    header_fund_with_giving_levels: {
      title: "Header Fund with Giving Levels",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 30,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead_fund_with_giving_levels: {
      title: "Subhead Fund with Giving Levels",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 20
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    header_fund: {
      title: "Header Fund",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 26,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead_fund: {
      title: "Subhead Fund",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: true,
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.CoburgContainer,
    type: PREVIEW_TYPE.COMPONENT
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  default_children: [
    {
      name: "Attract Loop",
      folder_type: FOLDER_TYPE.coburg_attract_loop
    },
    {
      name: "Search",
      folder_type: FOLDER_TYPE.coburg_search
    },
    {
      name: "Main Menu",
      folder_type: FOLDER_TYPE.coburg_menu
    }
  ],
  font_groups: {
    enabled: true,
    compatible_folder_types: [FOLDER_TYPE.coburg_root]
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  isPresentation: true,
  tree_dropped_into_action: [{ action: "instance", options: {} }]
};
