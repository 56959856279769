import { Component } from 'react';
import { connect } from "react-redux";
import {setClientOptions} from '../../reducers/clientOptionsSlice'

class WithInputMode extends Component {

  constructor(props){
    super(props);
    this._hasTouched = false;
    this._hasPointered = false;
    this._interactionQueued = false;
    this._touchMode = false;
  }

  componentDidMount() {
    window.addEventListener('touchstart', () => {
      this._hasTouched = true;
      this.detectInteraction();
    }, false);
    window.addEventListener('pointerdown', (e) => {
      this._hasPointered = true;
      this.detectInteraction();
    }, false);
  }

  // handlePointerDown = () => {
  //   this._hasTouched = true;
  //   this.detectInteraction();
  // }

  // handleTouchStart = () => {
  //   this._hasPointered = true;
  //   this.detectInteraction();
  // }

  // componentWillUnmount(){
  //   window.removeEventListener('touchstart', this.handleTouchStart);
  //   window.removeEventListener('touchstart', this.handlePointerDown);
  // }

  // componentDidUpdate(prevProps, prevState){
  //   if (prevState.touchMode !== this.state.touchMode){

  //   }
  // }

  detectInteraction = () => {
    if (this._interactionQueued===false){
      this._interactionQueued = true;
      clearTimeout(this.interactionTimeout)
      this.interactionTimeout = setTimeout(() => {
        this._interactionQueued = false;
        if (this._hasTouched === true) {
          if (this._touchMode === false) {
            this._touchMode = true
            this.props.setClientOptions({inputMode: 'touch'})
          }
        }
        else {
          if (this._touchMode === true) {
            this._touchMode = false
             this.props.setClientOptions({inputMode: 'html5'})
          }
        }
        this._hasTouched = false;
        this._hasPointered = false;
      }, 500);
    }
  }

  render() {
    return (
      null
    )
  }
}

const mapStateToProps = (state) => {
  return {
    client_options: state.client_options
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithInputMode);




