//import axios from "axios";
import _ from "lodash";
import { normalize, schema } from "normalizr";
import { all, call, put } from "redux-saga/effects";
// import { schema } from 'normalizr'; //normalize
import { addError, addExceptionError } from "../actions/notificationActions";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import {
  addOrRemoveUserCompanyValidationSchema,
  changePlaceholderConfigurationValidationSchema,
} from "../shared-global/validations/AdminValidation";
import {
  accountUpdateValidationSchema,
  addRoleUserValidationSchema,
  addUserValidationSchema,
  changeCompanyValidationSchema,
  deleteRoleUserValidationSchema,
  deleteUserValidationSchema,
  getUserValidationSchema,
  getUsersValidationSchema,
  resendSetPasswordEmailValidationSchema,
  updatePersistentSettingsValidationSchema,
  updateUserValidationSchema,
} from "../shared-global/validations/UserValidation";
import { HTTP_METHODS } from "../utils/api/methods";
import Request from "../utils/api/request";

const AWS = require("aws-sdk");

//const usersSchema = new schema.Entity('users');
const userSchema = new schema.Entity("users");
const role__userSchema = new schema.Entity("role__users");
const usersSchema = new schema.Array(userSchema);
const projectSchema = new schema.Entity("projects");
const locationsSchema = new schema.Entity("locations");
const permissionsSchema = new schema.Entity("permissions");
const role__permissionsSchema = new schema.Entity("role__permissions");
const rolesSchema = new schema.Entity("roles");
const role__usersSchema = new schema.Entity("role__users");
const user__companySchema = new schema.Entity("user__companies");
const companySchema = new schema.Entity("companies");
const systemSettingsSchema = new schema.Entity("system_settings");

async function userGetDataAPI(payload) {
  return Request(
    process.env.REACT_APP_SERVER_URL + "/user/get_data",
    HTTP_METHODS.GET,
    payload,
  );
}

async function updatePersistentSettingsAPI(payload) {
  return Request(
    process.env.REACT_APP_SERVER_URL + "/user/update_persistent_settings",
    HTTP_METHODS.POST,
    payload,
    updatePersistentSettingsValidationSchema,
  );
}

async function getAllUsersAPI(payload) {
  return Request(
    "/user/get_users",
    HTTP_METHODS.POST,
    { ...payload },
    getUsersValidationSchema,
  );
}

async function getAllUsersAllCompaniesAPI() {
  return Request("/User/users", HTTP_METHODS.POST);
}

async function getSingleUserAPI(payload) {
  return Request(
    "/User/get_user",
    HTTP_METHODS.POST,
    { ...payload },
    getUserValidationSchema,
  );
}

async function addUserAPI(payload) {
  return Request(
    "/User/add_user",
    HTTP_METHODS.POST,
    { ...payload },
    addUserValidationSchema,
  );
}

async function addRoleUserAPI(payload) {
  return Request(
    "/User/add_role_user",
    HTTP_METHODS.POST,
    payload,
    addRoleUserValidationSchema,
  );
}

async function deleteRoleUserAPI(payload) {
  return Request(
    "/User/delete_role_user",
    HTTP_METHODS.POST,
    { ...payload },
    deleteRoleUserValidationSchema,
  );
}

async function updateUserAPI(payload) {
  return Request(
    "/User/update_user",
    HTTP_METHODS.POST,
    { ...payload },
    updateUserValidationSchema,
  );
}

async function resendSetPasswordEmailAPI(payload) {
  return Request(
    "/User/resend_set_password_email",
    HTTP_METHODS.POST,
    { ...payload },
    resendSetPasswordEmailValidationSchema,
  );
}

async function updateUserAccountAPI(payload) {
  return Request(
    "/User/account_update",
    HTTP_METHODS.POST,
    { ...payload },
    accountUpdateValidationSchema,
  );
}

async function deleteUserAPI(payload) {
  return Request(
    "/User/delete_user",
    HTTP_METHODS.POST,
    { ...payload },
    deleteUserValidationSchema,
  );
}

async function changeCompanyAPI(payload) {
  return Request(
    "/User/change_company",
    HTTP_METHODS.POST,
    { ...payload },
    changeCompanyValidationSchema,
  );
}

async function addUserCompanyAPI(payload) {
  return Request(
    "/Admin/add_user_company",
    HTTP_METHODS.POST,
    { ...payload },
    addOrRemoveUserCompanyValidationSchema,
  );
}

async function removeUserCompanyAPI(payload) {
  return Request(
    "/Admin/remove_user_company",
    HTTP_METHODS.POST,
    { ...payload },
    addOrRemoveUserCompanyValidationSchema,
  );
}

async function changePlaceholderConfigurationAPI(payload) {
  return Request(
    "/Admin/change_placeholder_configuration",
    HTTP_METHODS.POST,
    { ...payload },
    changePlaceholderConfigurationValidationSchema,
  );
}

const userSaga = {
  addUser: function* (action) {
    let response;
    try {
      response = yield call(addUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        // console.log(JSON.stringify(response.data));
        yield all([
          put({
            type: "ADD_USER_SUCCESS",
            normalized: normalize(response.data.user, userSchema),
          }),
          put({
            type: "ADD_USER_COMPANY_SUCCESS",
            normalized: normalize(response.data.user__companies, [
              user__companySchema,
            ]),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess(response.data.user.id);
        }
      } else {
        yield put({ type: "ADD_USER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "ADD_USER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(response.data ? response.data.detail : e.message);
      }
    }
  },

  addRoleUser: function* (action) {
    try {
      const response = yield call(addRoleUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_ROLE_USER_SUCCESS",
          normalized: normalize(response.data.role__user, role__userSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  deleteRoleUser: function* (action) {
    let success = false;
    try {
      const response = yield call(deleteRoleUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_ROLE_USER_SUCCESS",
          removeKeys: [{ entities: "role__users", ids: [action.payload.id] }],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "DELETE_ROLE_USER_FAIL" });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      yield put({ type: "DELETE_ROLE_USER_FAIL" });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getSingleUser: function* (action) {
    try {
      const response = yield call(getSingleUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "GET_SINGLEUSER_SUCCESS",
            normalized: normalize(response.data.user, userSchema),
          }),
          put({
            type: "GET_ROLE__USER",
            normalized: normalize(response.data.role_user, role__userSchema),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_PROJECT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_PROJECT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  // get AllUser by Company
  getAllUser: function* (action) {
    try {
      const response = yield call(getAllUsersAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_ALLUSER_SUCCESS",
          normalized: normalize(response.data.users, usersSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_ALLUSERS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_ALLUSERS_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  // get AllUser by Company
  getAllUserAllCompanies: function* (action) {
    try {
      const response = yield call(getAllUsersAllCompaniesAPI);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_ALLUSER_SUCCESS",
          normalized: normalize(response.data.users, usersSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_ALLUSERS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_ALLUSERS_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateUser: function* (action) {
    try {
      const response = yield call(updateUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_USER_SUCCESS",
          normalized: normalize(response.data.user, userSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_USER_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_USER_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateUserAccount: function* (action) {
    try {
      const response = yield call(updateUserAccountAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_USER_ACCOUNT_SUCCESS",
          normalized: normalize(response.data.user, userSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_USER_ACCOUNT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_USER_ACCOUNT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  userGetData: function* (action) {
    let success = false;
    try {
      const response = yield call(userGetDataAPI, action.payload);
      if (isResponseSuccess(response)) {
        success = true;
        const userCompany = _.get(response, "data.user.company", null);
        if (userCompany) {
          const company = response.data.companies.find(
            (c) => c.id === userCompany,
          );
          if (
            company.accessKeyId !== null &&
            company.secretAccessKey !== null
          ) {
            AWS.config.credentials = {
              accessKeyId: company.aws_access_key,
              secretAccessKey: company.aws_secret_access_key,
              region: "us-west-2",
            };
          }
        }

        // console.log("FULL RESPONSE", response.data)
        yield put({
          type: "USER_GET_DATA_SUCCESS",
          user: response.data.user,
          aws_instance: AWS,
          roles: response.data.roles,
          permissions: response.data.permissions,
          persistent_settings: response.data.persistent_settings,
          role__permissions: response.data.role__permissions,
          role__users: response.data.role__users,
          email_subscription_types: response.data.email_subscription_types,
          // companies: response.data.companies,
          // user_companies: response.data.user__companies,
        });
        localStorage.setItem(
          "session_expire",
          response.data.user.session_expire,
        );
        yield put({
          type: "USER_GET_COMPANIES_MENU_DETAILS_SUCCESS",
          user_companies_menu_details:
            response.data.user_companies_menu_details,
        });
        yield put({
          type: "USER_GET_DATA_PROJECTS_NORMALIZED",
          normalized: normalize(response.data.projects, [projectSchema]),
        });
        yield put({
          type: "USER_GET_DATA_USER_COMPANIES_NORMALIZED",
          normalized: normalize(response.data.user__companies, [
            user__companySchema,
          ]),
        });
        yield put({
          type: "USER_GET_DATA_COMPANIES_NORMALIZED",
          normalized: normalize(response.data.companies, [companySchema]),
        });
        if (action.onSuccess) action.onSuccess();
      } else {
        yield put({ type: "USER_GET_DATA_FAIL", user: {} });
        yield put(addError(response.errors));
        if (action.onFail) action.onFail();
      }
    } catch (e) {
      yield put({ type: "USER_GET_DATA_FAIL", user: {} });
      yield put(addExceptionError(e));
      if (action.onFail) action.onFail();
    }
  },

  updatePersistentSettings: function* (action) {
    try {
      const response = yield call(updatePersistentSettingsAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_PERSISTENT_SETTINGS_SUCCESS",
          overrides: [
            {
              key: "persistent_settings",
              value: response.data.persistent_settings,
            },
          ],
        });
        if (action.onSuccess) action.onSuccess();
      } else {
        yield put({ type: "UPDATE_PERSISTENT_SETTINGS_FAIL", user: {} });
        yield put(addError(response.errors));
        if (action.onFail) action.onFail();
      }
    } catch (e) {
      yield put({ type: "UPDATE_PERSISTENT_SETTINGS_FAIL", user: {} });
      yield put(addExceptionError(e));
      if (action.onFail) action.onFail();
    }
  },

  deleteUser: function* (action) {
    try {
      const response = yield call(deleteUserAPI, action.payload);
      if (isResponseSuccess(response)) {
        if (response.data.removed_user__companies) {
          yield put({
            type: "DELETE_USER__COMPANIES_SUCCESS",
            removeKeys: [
              {
                entities: "user__companies",
                ids: response.data.removed_user__companies,
              },
            ],
          });
        }
        yield put({
          type: "DELETE_USER_SUCCESS",
          user_id: _.get(response.data, "user_id", null),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_USER_FAILURE" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(response.response.data.data.detail);
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_USER_FAILURE" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  changeCompany: function* (action) {
    try {
      const response = yield call(changeCompanyAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "CHANGE_COMPANY_USER_SUCCESS",
            company_id: action.payload.company_id,
          }),
          put({
            type: "CHANGE_COMPANY_LOCATIONS_SUCCESS",
            overrides: [
              {
                key: "locations",
                value: normalize(response.data.locations, [locationsSchema])
                  .entities.locations,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_PROJECTS_SUCCESS",
            overrides: [
              {
                key: "projects",
                value: normalize(response.data.projects, [projectSchema])
                  .entities.projects,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_PERMISSIONS_SUCCESS",
            overrides: [
              {
                key: "permissions",
                value: normalize(response.data.permissions, [permissionsSchema])
                  .entities.permissions,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_ROLE_PERMISSIONS_SUCCESS",
            overrides: [
              {
                key: "role_permissions",
                value: normalize(response.data.role__permissions, [
                  role__permissionsSchema,
                ]).entities.role__permissions,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_ROLES_SUCCESS",
            overrides: [
              {
                key: "roles",
                value: normalize(response.data.roles, [rolesSchema]).entities
                  .roles,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_ROLE__USERS_SUCCESS",
            overrides: [
              {
                key: "role__users",
                value: normalize(response.data.role__users, [role__usersSchema])
                  .entities.role__users,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_USERS_SUCCESS",
            overrides: [
              {
                key: "users",
                value: normalize(response.data.users, usersSchema).entities
                  .users,
              },
            ],
          }),
          put({
            type: "CHANGE_COMPANY_FOLDER_TYPES_SUCCESS",
            normalized: {
              entities: { folder_types: response.data.folder_types },
            },
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "CHANGE_COMPANY_FAILURE",
        });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.data
              ? response.data.detail
              : "An unexpected error happened",
          );
        }
      }
    } catch (ex) {
      yield put({ type: "CHANGE_COMPANY_FAILURE" });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  resendSetPasswordEmail: function* (action) {
    try {
      const response = yield call(resendSetPasswordEmailAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "RESEND_SET_PASSWORD_EMAIL_SUCCESS",
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "RESEND_SET_PASSWORD_EMAIL_FAIL",
        });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "An unexpected error ocurred",
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "RESEND_SET_PASSWORD_EMAIL_FAIL",
      });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message || "An unexpected error ocurred");
      }
    }
  },

  add_user_company: function* (action) {
    try {
      const response = yield call(addUserCompanyAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_USER_COMPANY_SUCCESS",
          normalized: normalize(
            response.data.newUserCompany,
            user__companySchema,
          ),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "ADD_USER_COMPANY_FAIL",
        });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            _.get(response, "data.detail", null) ||
              "An unexpected error ocurred",
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "ADD_USER_COMPANY_FAIL",
      });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message || "An unexpected error ocurred");
      }
    }
  },

  remove_user_company: function* (action) {
    try {
      const response = yield call(removeUserCompanyAPI, action.payload);
      if (isResponseSuccess(response)) {
        const userCompanyId = response.data.destroyedUserCompany.id;

        yield put({
          type: "REMOVE_USER_COMPANY_SUCCESS",
          removeKeys: [{ entities: "user__companies", ids: [userCompanyId] }],
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({
          type: "REMOVE_USER_COMPANY_FAIL",
        });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            _.get(response, "data.detail", null) ||
              "An unexpected error ocurred",
          );
        }
      }
    } catch (ex) {
      yield put({
        type: "REMOVE_USER_COMPANY_FAIL",
      });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message || "An unexpected error ocurred");
      }
    }
  },

  change_placeholder_configuration: function* (action) {
    try {
      const response = yield call(
        changePlaceholderConfigurationAPI,
        action.payload,
      );
      if (isResponseSuccess(response)) {
        yield put({
          type: "CHANGE_PLACEHOLDER_CONFIGURATION_SUCCESS",
          normalized: normalize(response.data.system_settings, [
            systemSettingsSchema,
          ]),
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },
};

export default userSaga;
