import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { wysiwygSettings } from "./coburg_settings";
import { tree_icon_types } from "../tree_icon_types";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const coburg_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.coburg_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.coburg_template_001,
    FOLDER_TYPE.coburg_template_002,
    FOLDER_TYPE.coburg_template_003,
    FOLDER_TYPE.coburg_template_004
  ],
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  fields: {
    story_title: {
      title: "Title",
      type: "hybrid_text_input",
      field_group: "general",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      }
    },
    story_description: {
      title: "Description",
      type: "hybrid_text_input",
      field_group: "general",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      }
    },
    story_spacing: {
      type: "number",
      title: "Space between title and description",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 20 }] },
      tooltip: "Unit is % of container height",
      step: 1,
      default: 0,
      field_group: "general"
    },
    gallery_title: {
      title: "Gallery Title",
      type: "hybrid_text_input",
      field_group: "general",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px",
            lineHeight: 1.2
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "gallery_title"
      }
    },
    gallery_description: {
      title: "Gallery Description",
      type: "hybrid_text_input",
      field_group: "general",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px",
            lineHeight: 1.2
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "gallery_description"
      }
    },
    gallery_spacing: {
      type: "number",
      title: "Space between Gallery Title and Gallery Description",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 20 }] },
      tooltip: "Unit is % of container height",
      step: 1,
      default: 0,
      field_group: "general"
    },
    menu_slider_arrow_color: {
      type: "color",
      title: "Stories Menu Slider Arrows Color",
      default: "#FFFFFF",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    size_options: {
      type: "select",
      title: "Size Card Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "large",
      options: [
        {
          title: "Small",
          value: "small"
        },
        {
          title: "Medium",
          value: "medium"
        },
        {
          title: "Large",
          value: "large"
        }
      ]
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      field_group: "general",
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true,
      field_group: "general"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.CoburgStories,
    type: PREVIEW_TYPE.COMPONENT
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.coburg_template_001,
      FOLDER_TYPE.coburg_template_002,
      FOLDER_TYPE.coburg_template_003,
      FOLDER_TYPE.coburg_template_004
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.coburg_template_001,
          FOLDER_TYPE.coburg_template_002,
          FOLDER_TYPE.coburg_template_003,
          FOLDER_TYPE.coburg_template_004
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.coburg_template_001,
          FOLDER_TYPE.coburg_template_002,
          FOLDER_TYPE.coburg_template_003,
          FOLDER_TYPE.coburg_template_004
        ],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.coburg_template_002, FOLDER_TYPE.coburg_template_003],
        field: "image_1",
        title: "Media 1",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.coburg_template_003],
        field: "image_2",
        title: "Media 2",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.coburg_template_001,
          FOLDER_TYPE.coburg_template_002,
          FOLDER_TYPE.coburg_template_003,
          FOLDER_TYPE.coburg_template_004
        ],
        field: "background",
        title: "Background",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.coburg_template_001,
          FOLDER_TYPE.coburg_template_002,
          FOLDER_TYPE.coburg_template_003,
          FOLDER_TYPE.coburg_template_004
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.coburg_template_001,
          FOLDER_TYPE.coburg_template_002,
          FOLDER_TYPE.coburg_template_003,
          FOLDER_TYPE.coburg_template_004
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.CoburgStories
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 300,
    columns: 2
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: true
};
