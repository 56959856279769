import { get, orderBy } from "lodash";
import React, { Props, useCallback, useEffect, useState } from "react";
import { FOLDER_TYPE, SORTING_DIRECTION, SORTING_METHOD } from "../../../../shared-global/enums/folder-type-enums";
import { IFolderFlex } from "../../../../shared-global/interfaces/models/folder.interface";
import { IFolderFolder } from "../../../../shared-global/interfaces/models/folder__folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { sortedDonorsFromFolder } from "../../../utils/collectionUtils";
import { ScrollableContainer2010 } from "../../standard/ScrollableContainer2010";
import {rowStyle, columnStyle} from './los-angles-styles'

export const LosAngelesDonorLists: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {


  const baseFolder = props.data.folders.find(
    (f) => f.id === props.data.base_folder
  );

  const [totalDonorCounter, setTotalDonorCounter] = useState(0);
  const [donorListsScrollSpeed, setDonorListsScrollSpeed] = useState(0);
  const [haveCalculatedDonors, setHaveCalculatedDonors] = useState(false);
  const [donorListsJsx, setDonorListsJsx] = useState(<span></span>);
  const [folder__folders, setFolder__folders] = useState(
    props.data.folder__folders
  );

  useEffect(() => {
    setFolder__folders(props.data.folder__folders);
    setHaveCalculatedDonors(false);
  }, [props.data.folder__folders]);


  const donorRowsPerPage = 20;
  const defaultFontSize = props.containerHeight / donorRowsPerPage;
  const generalMargin = defaultFontSize * 0.5;

  if (haveCalculatedDonors === false) {
    let donorCounter = 0;

    let donor_lists: IFolderFlex[] = props.data.folders.filter(
      (f) => f.folder_type === FOLDER_TYPE.los_angeles_donor_list
    );
    const donor_list_ids = donor_lists.map((dl) => dl.id);
    let donor_list_folderFolders = folder__folders.filter((ff: IFolderFolder) =>
      donor_list_ids.includes(ff.child_folder)
    );
    donor_list_folderFolders.forEach((ff) => {
      const dl_matched = donor_lists.find((dl) => dl.id === ff.child_folder);
      if (dl_matched) {
        dl_matched.rank = ff.rank;
      }
    });

    donor_lists = orderBy(donor_lists, [(dl) => dl.rank], ["asc"]);
    if (donor_lists.length > 0) {

      const donor_lists_folder = props.data.folders.find(
        (f) => f.folder_type === FOLDER_TYPE.los_angeles_donor_lists
      );
  
      const scrollSpeed = get(donor_lists_folder, "fields.scroll_speed", 1);
      setDonorListsScrollSpeed(scrollSpeed);

      const tsxElements = [];
      let keyCounter = 0;
      donor_lists.forEach((dl) => {
        const marginLeft =
          (get(dl, "fields.container_margin_left", 1) * props.containerHeight) /
          100;
        const marginRight =
          (get(dl, "fields.container_margin_right", 1) *
            props.containerHeight) /
          100;
        const fontSize =
          (get(dl, "fields.donor_name_font_size", 1) * props.containerHeight) /
          100;
        const maxWidth =
          (get(dl, "fields.donor_max_width", 90) * props.containerHeight) / 100;
        const marginBottom = get(dl, "fields.donor_vertical_separation", 1);
        const lineHeight = get(dl, "fields.donor_line_space", 2);
        const donorNameJustification = get(
          dl,
          "fields.donor_name_justification",
          "left"
        );
        const sortingMethod = get(
          dl,
          "fields.sorting_options",
          SORTING_METHOD.rank
        );

        const rowStyle = {
          marginLeft: generalMargin,
          marginRight: generalMargin,
          lineHeight: "100%",
          marginBottom: generalMargin,
        };
        if (get(baseFolder, "fields.show_sublist_headers", false) === true) {
          tsxElements.push(
            <div key={keyCounter++} style={rowStyle}>
              {dl.name}
              <br />
              <br />
            </div>
          );
        }
        const donor_folder__folders = folder__folders.filter(
          (ff) => ff.parent_folder === dl.id
        );

        const donor_folder_ids = donor_folder__folders.map(
          (dff) => dff.child_folder
        );
        let donor_folders = props.data.folders.filter((f) =>
          donor_folder_ids.includes(f.id)
        );
        donor_folders = sortedDonorsFromFolder(
          donor_folder__folders,
          donor_folders,
          sortingMethod,
          dl,
          SORTING_DIRECTION.asc
        );

        donor_folders.forEach((df) => {
          donorCounter += 1;

          tsxElements.push(
            <div
              style={{
                ...rowStyle,
                marginLeft,
                marginRight,
                fontSize,
                maxWidth,
                textAlign: donorNameJustification,
                marginBottom: `${marginBottom}em`,
                lineHeight: `${lineHeight}em`,
                whiteSpace: 'pre-wrap',
              }}
              key={keyCounter++}
            >
              {df.name}
            </div>
          );
        });
        // space at end or between donor lists
        tsxElements.push(
          <div key={keyCounter++} style={rowStyle}>
            <br />
            <br />
            <br />
            <br />
          </div>
        );
      });
      while (tsxElements.length < donorRowsPerPage) {
        donorCounter += 1;
        tsxElements.push(
          <div style={rowStyle} key={keyCounter++}>
            &nbsp;
          </div>
        );
      }

      setTotalDonorCounter(donorCounter);

      setDonorListsJsx(
        <div
          style={{
            textAlign: "left",
            fontSize: defaultFontSize,
          }}
        >
          {tsxElements}
        </div>
      );
      setHaveCalculatedDonors(true);
    }
  }

  const totalScrollSpeed = donorListsScrollSpeed * totalDonorCounter;

  if (!haveCalculatedDonors) {
    return <span></span>;
  }

  return (
    <div style={{position: 'relative', width: props.containerWidth}}>
      <ScrollableContainer2010
        speed={totalScrollSpeed}
        containerHeight={props.containerHeight}
        containerWidth={props.containerWidth}
        scrollableContent={donorListsJsx}
      />
      <div
        className={"transparentTopWhiteBottom"}
        style={{
          position: "absolute",
          top: 0, //props.containerHeight * 0.15,
          width: props.containerWidth,
          height: props.containerHeight * 0.1,
          right: 0,
        }}
      >
        &nbsp;
      </div>
      <div
        className={"whiteBottomTransparentTop"}
        style={{
          position: "absolute",
          bottom: 0, //props.containerHeight * 0.05,
          width: props.containerWidth,
          height: props.containerHeight * 0.1,
          right: 0,
        }}
      >
        &nbsp;
      </div>
    </div>
  );
};