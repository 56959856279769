import {
  GET_URI_ACCESS_BY_FOLDER,
  UPSERT_URI_ACCESS,
  URI_ACCESS_INITIALIZE
} from "./actionTypes";

export const getUriAccessByFolder = (
  folderId: number,
  onSuccess?,
  onFail?
) => ({
  type: GET_URI_ACCESS_BY_FOLDER,
  payload: {
    folderId: folderId
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const upsertUriAccess = (payload: any, onSuccess?, onFail?) => ({
  type: UPSERT_URI_ACCESS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const uriAccessInitialize = (
  identifier: string,
  onSuccess?,
  onFail?
) => ({
  type: URI_ACCESS_INITIALIZE,
  payload: {
    identifier: identifier
  },
  onSuccess: onSuccess,
  onFail: onFail
});
