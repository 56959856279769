import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IFolderFolder } from "../../../../shared-global/interfaces/models/folder__folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType
} from "../../../utils/commonDataUtils";
import { getFieldValue, isTextRichEmpty } from "../../../utils/generalUtils";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import Slide1911 from "../../standard/Slide1911";
import { DEFAULT_COBURG_SCROLLBAR_COLOR } from "./CoburgConstants";
import RichTextRender from "../../standard/RichTextRender";
import CoburgMenuItem from "./CoburgMenuItem";
import { createUseStyles } from "react-jss";

const FOLDER_MENU_ITEM_TYPE = [
  FOLDER_TYPE.coburg_presentation,
  FOLDER_TYPE.coburg_story_group,
  FOLDER_TYPE.coburg_stories,
  FOLDER_TYPE.coburg_stories_overview,
  FOLDER_TYPE.coburg_donor_list_group,
  FOLDER_TYPE.coburg_template_001,
  FOLDER_TYPE.coburg_template_002,
  FOLDER_TYPE.coburg_template_003,
  FOLDER_TYPE.coburg_template_004
];

const useStyles = createUseStyles({
  link: {
    "& p": {
      cursor: "pointer"
    }
  }
})

const CoburgMenu: React.FunctionComponent<IDisplayComponentProps> = (props) => {
  const [heading, setHeading] = useState('');
  const [baseFolder, setBaseFolder] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(
    props.data?.persistent_settings?.data?.show_placeholder || false
  );

  const [showOnlyActives, setShowOnlyActives] = useState(
    get(props.data, "persistent_settings.data.show_actives_on_presentations", false)
  );

  const navigate = useNavigate();
  const params = useParams<{ menuFolderId?: string | undefined }>();

  const classes = useStyles()

  useEffect(() => {
    setShowOnlyActives(get(props.data, "persistent_settings.data.show_actives_on_presentations", false))
  }, [props.data])

  const chem = (factor: number) => {
    return (props.containerHeight / 1000) * factor;
  };

  const buildUrl = (folder) => {
    let url = `${props.data.basePath}`;
    switch (folder.folder_type) {
      case FOLDER_TYPE.coburg_menu:
        url += `/menu/${folder.id}`;
        break;
      case FOLDER_TYPE.coburg_story_group:
        url += `/story_gallery/${folder.id}`;
        break;
      case FOLDER_TYPE.coburg_donor_list_group:
        const childrenFolderFolders = props.data.folder__folders.filter(
          (x) => x.parent_folder === folder.id
        );

        if (childrenFolderFolders.length > 1) {
          url += `/donor_list_gallery/${folder.id}`;
        } else {
          url += `/donor_list_group/${folder.id}/donor_list_with_category/${childrenFolderFolders[0].child_folder}`;
        }
        break;
      case FOLDER_TYPE.coburg_stories:
        url += `/stories/${folder.id}`;
        break;
      case FOLDER_TYPE.coburg_template_001:
      case FOLDER_TYPE.coburg_template_002:
      case FOLDER_TYPE.coburg_template_003:
      case FOLDER_TYPE.coburg_template_004:
        url += `/story/${folder.id}`;
        break;
    }

    return url;
  };

  const findFolder = (folderId) => {
    return props.data.folders.find((f) => f.id === folderId);
  };

  const menuItems = useMemo(() => {
    let items = [];
    let baseFolderId = props.data.base_folder;
    if (params.menuFolderId) {
      baseFolderId = Number(params.menuFolderId);
    }

    items = props.data.folder__folders
      .filter((ff) => ff.parent_folder == baseFolderId)
      .sort((a, b) => a.rank - b.rank)
      .map((fff) => {
        const folderFound = findFolder(fff.child_folder);
        return folderFound;
      })
      .filter((f) => FOLDER_MENU_ITEM_TYPE.includes(f.folder_type))
      .map((folder) => {
        const show_folder = get(folder, 'fields.show_in_presentations', true)
        let name = get(folder, 'fields.title', '');
        let id = folder?.id;
        let currentFolderId = folder?.id;
        let urlFolder = folder;
        let menuFolderFolder: IFolderFolder | null = null;
        let style: any = {
          fontSize: chem(
            get(
              baseFolder,
              "fields.menu_items.fontSize",
              (props.containerHeight / 10) * 0.45
            )
          ),
          textAlign: get(
            baseFolder,
            "fields.menu_items.alignment",
            "center"
          ),
          fontFamily: get(
            baseFolder,
            "fields.menu_items.font",
            "Roboto Slab Bold"
          ),
          fontWeight: get(
            baseFolder,
            "fields.menu_items.bold",
            false
          ) ? "bold" : "normal",
          lineHeight: get(
            baseFolder,
            "fields.menu_items.lineHeight",
            1
          ),
          fontStyle: get(
            baseFolder,
            "fields.menu_items.italic",
            false
          ) ? "italic" : "normal",
          textDecoration: get(
              baseFolder,
              "fields.menu_items.underline",
              false
          ) ? "underline" : "normal",
          color: get(
            baseFolder,
            "fields.menu_items.color",
            "#FFFFFF"
          ),
        }
        const folderType = props.data?.folder_types?.find(f => f.name === folder?.folder_type)

        if (folder?.folder_type === FOLDER_TYPE.coburg_presentation) {
          const presentationChildrenFolderFolders = props.data.folder__folders.filter(folderFolder => folderFolder.parent_folder === folder.id);
          let menu: IFolder;
          presentationChildrenFolderFolders.forEach(pff => {
            const foundFolder = props.data.folders.find(f => f.id === pff.child_folder);
            if (foundFolder?.folder_type === FOLDER_TYPE.coburg_menu) {
              menuFolderFolder = pff;
              menu = foundFolder;
              return;
            }
          });

          if (menu) {
            urlFolder = menu;
            id = menu.id;
          }
          name = getFieldValue(folder, 'menu_header', folderType, showPlaceholder, props.mode, false);
          style = {}
        }

        return {
          id,
          currentFolderId,
          name,
          folder,
          folder_type: folderType,
          url: buildUrl(urlFolder),
          menuFolderFolder,
          show_folder,
          style
        };
      });

    if (showOnlyActives) {
      items = items.filter((item) => item.show_folder);
    }

    return items;
  }, [props.data, baseFolder]);

  useEffect(() => {
    // console.log('useEffect', props.data);
    let myBaseFolder = null;
    if (params.menuFolderId) {
      myBaseFolder = props.data.folders.find((x) => x.id === Number(params.menuFolderId));
    } else {
      myBaseFolder = getBaseFolder(props.data);
    }
    const setCurrentMenu = get(props, 'data.componentSpecificData.setCurrentMenu', null);
    if (setCurrentMenu) {
      // console.log('setCurrentMenu', myBaseFolder);
      setCurrentMenu(myBaseFolder);
    }
    setBaseFolder(myBaseFolder);
  }, [props.data]);

  useEffect(() => {
    if (baseFolder) {
      const myBaseFolderType = getBaseFolderType(
        props.data,
        get(baseFolder, "folder_type", {})
      );
      setBaseFolderType(myBaseFolderType);

      const color = get(baseFolder, "fields.color_overlay", "transparent");
      setBackgroundColor(color);

      const myHeading = getFieldValue(baseFolder, "heading", myBaseFolderType, showPlaceholder, null, false);
      setHeading(myHeading);
    }
  }, [baseFolder]);

  useEffect(() => {
    if (baseFolderType) {
    }
  }, [baseFolderType]);

  return (
    <>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            base_folder: get(baseFolder, "id", null)
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColor,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "relative"
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          {/* MENU container */}
          <div
            style={{
              height: "100%",
              color: "#707070",
              overflowY: "hidden"
            }}
          >
            {!isTextRichEmpty(heading) && (
              <div style={{
                marginTop: props.containerHeight * get(baseFolder, 'fields.menu_title_margin_top', 0) / 100,
                marginLeft: props.containerWidth * get(baseFolder, 'fields.menu_title_margin_left', 0) / 100,
                marginBottom: props.containerHeight * get(baseFolder, 'fields.menu_title_margin_bottom', 0) / 100,
              }}>
                <div className="ql-editor-preview">
                  <div
                    className="ql-editor"
                    style={{
                      padding: 0,
                      fontSize: props.refHeight / 1000,
                    }}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                </div>
              </div>
            )}

            <div
              className="eugene-menu_items"
              style={{
                width: props.containerWidth * get(baseFolder, 'fields.menu_container_width', 80) / 100,
                marginTop: props.containerHeight * get(baseFolder, 'fields.menu_container_margin_top', 0) / 100,
                marginLeft: props.containerWidth * get(baseFolder, 'fields.menu_container_margin_left', 0) / 100,
              }}
            >
              <ScrollableFixContainer2103
                containerHeight={props.containerHeight * get(baseFolder, 'fields.menu_container_height', 50) / 100}
                containerWidth={props.containerWidth * get(baseFolder, 'fields.menu_container_width', 80) / 100}
                showScrollbar={false}
                barColor={DEFAULT_COBURG_SCROLLBAR_COLOR}
                hidePaddingRight
              >
                <div>
                  {menuItems.map((mi, index) => {
                    return (
                      <div
                        onClick={() => {
                          const setCurrentMenuItem = get(props, 'data.componentSpecificData.setCurrentMenuItem', null);
                          const setCurrentMenuFolderFolder = get(props, 'data.componentSpecificData.setCurrentMenuFolderFolder', null);
                          if (setCurrentMenuItem) {
                            setCurrentMenuItem(mi.currentFolderId);
                          }
                          if (setCurrentMenuFolderFolder) {
                            setCurrentMenuFolderFolder(mi.menuFolderFolder);
                          }
                          navigate(mi.url);
                        }}
                        key={`eugene-menu_item-${index}`}
                        style={{
                          ...mi.style,
                          padding: 0,
                          whiteSpace: "pre-wrap",
                          cursor: "pointer",
                          marginBottom: props.containerHeight * get(baseFolder, 'fields.menu_item_vertical_separation', 9) / 100
                        }}
                        className={classes.link}
                      >
                        <CoburgMenuItem folder={mi.folder} folderType={mi.folder_type} text={mi.name} refHeight={props.refHeight} />
                      </div>
                    );
                  })}
                </div>
              </ScrollableFixContainer2103>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoburgMenu;
