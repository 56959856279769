import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { getFileType } from "../../../shared/utils/fileUtils";
import useStyles from './styles';

interface IResourceUploaderThumbnailViewProps {
  files: File[];
  onRemove: (index: number) => void;
  onAddMore: () => void;
}

const ResourceUploaderThumbnailView: React.FC<IResourceUploaderThumbnailViewProps> = (props) => {
  const classes = useStyles({ theme: {} });
  const [filesWithThumbs, setFilesWithThumbs] = useState([]);

  useEffect(() => {
    let newFilesWithThumbs = [];
    async function getThumbs() {
      for (let i = 0; i < props.files.length; i++) {
        const fileType = getFileType(props.files[i].name);
        let thumb = null;
        if (fileType === 'image') {
          thumb = await new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
              const thumb = e.target.result;
              return resolve(thumb);
            }
            fileReader.readAsDataURL(props.files[i]);
          });
        } else if (fileType === 'video') {
          thumb = '/images/icons/video.png';
        }
        newFilesWithThumbs.push({ file: props.files[i], thumb });
      }
      setFilesWithThumbs(newFilesWithThumbs);
    }
    getThumbs();
  }, [props.files]);

  
  return (
    <div className={classes.wrapper}>
      {filesWithThumbs.map((f, index) => {
        return (
          <div key={`thumb-${index}`} className={classes.item}>
            <div id="image" style={{ background: `url(${f.thumb})` }} />
            <div id="icon" onClick={() => props.onRemove(index)}>
              <FontAwesomeIcon className="fa-md" icon={["fas", "times-circle"]} />
            </div> 
          </div>
        )
      })}
    </div>
  );
}

export default ResourceUploaderThumbnailView;