import { createSelector } from "@reduxjs/toolkit";
import { convertObjectIntoArray } from "../components/form/input/DynamicFileInput/FileInputService";

const getUserNotifications = (state, props) => state.data.user__notifications;

export const getUserNotification = createSelector(
  [getUserNotifications],
  (userNotificationsObject) => {
    const arrayUserNotifications = convertObjectIntoArray(
      userNotificationsObject
    );
    return arrayUserNotifications;
  }
);
