import _, { get, isNil } from "lodash";
import uniqBy from "lodash/uniqBy";
import { IChildData } from "../../shared-global/interfaces/custom/shared.interface";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { IDisplayComponentPropsData } from "../interfaces/display-component-props.interface";

export function getBaseFolder(data: IDisplayComponentPropsData) {
  return data.folders.find((x) => x.id === data.base_folder);
}

export function getBaseFolderType(data, type) {
  const folderTypes = data.folder_types || [];
  return folderTypes.find((x) => x.name === type);
}

export function getFoldersByType(data, type) {
  return data.folders.find((x) => x.folder_type === type);
}

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
};

export const fisherYatesShuffle = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function getChildrenData(
  data,
  parent_folder_id,
  distinctFolders = false
): IChildData {
  const folder__folders = data.folder__folders
    .filter((x) => x.parent_folder === parent_folder_id)
    .sort((a, b) => a.rank - b.rank);
  const folder_ids = distinctFolders
    ? uniqBy<IFolderFolder>(folder__folders, "child_folder").map(
      (x) => x.child_folder
    )
    : folder__folders.map((x) => x.child_folder);
  const folders = folder_ids.map((x) => data.folders.find((f) => f.id === x));
  return {
    folders,
    folder__folders
  };
}

export function hasResourceFolderAtSlot(
  folder_id: number,
  field_name: string,
  data: any
) {
  const matchedResourceFolder = data.resource__folders.find(
    (x) => x.folder === folder_id && x.fieldName === field_name
  );
  if (!isNil(matchedResourceFolder)) {
    return true;
  }
  return false;
}

export function getJsonFromUrl(url: any) {
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export const getFolderFolderPath = (client_options) => {
  const safePath = get(client_options, "selected_folder_meta.path", []);
  return safePath.join("/");
};

export function getChildFolderFromFolderFolderId(data, folderFolderId) {
  const folder__folder = data.folder__folders.find(
    (ff) => ff.id === folderFolderId
  );
  if (folder__folder) {
    return data.folders.find((f) => f.id === folder__folder.child_folder);
  } else {
    return null;
  }
}

export function sortDonors(sorting_method, donors) {
  let sortedDonors;

  if (sorting_method === "sorting_order") {
    sortedDonors = _.orderBy(
      donors,
      [(donor) => _.get(donor, "fields.sorting_order", "zzz").toLowerCase()],
      ["asc"]
    );
  } else if (sorting_method === "name") {
    sortedDonors = _.orderBy(donors, [(donor) => donor.name], ["asc"]);
  } else {
    sortedDonors = _.orderBy(donors, [(donor) => donor.rank], ["asc"]);
  }

  return sortedDonors;
}

export function getTextStyleFromFolder(folder, field, chem?) {
  if (!folder || !folder.fields[field]) {
    return {};
  }
  return {
    color: get(folder, `fields.${field}.color`, "#FFFFFF"),
    fontFamily: get(folder, `fields.${field}.font`, "Roboto"),
    textAlign: get(folder, `fields.${field}.alignment`, "left"),
    fontSize: chem ? chem(get(folder, `fields.${field}.fontSize`, "18") + "em") : get(folder, `fields.${field}.fontSize`, "18") + "em",
    lineHeight: get(folder, `fields.${field}.line_height`, 1),
    fontWeight: get(folder, `fields.${field}.bold`, false) ? "bold" : "normal",
    fontStyle: get(folder, `fields.${field}.italic`, false)
      ? "italic"
      : "normal",
    textDecoration: get(folder, `fields.${field}.underline`, false)
      ? "underline"
      : "normal"
  };
}

export function hasMovingWarnings(destinationFolderType, originFolderType, originFolder, folder__folders) {
  if (destinationFolderType.isPresentation && originFolderType.warn_moving_to_presentation_without_children) {
    for (const ffId in folder__folders) {
      if (folder__folders[ffId].parent_folder === originFolder.id) {
        return false
      }
    }
    return true
  }
  return false
}
