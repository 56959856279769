import { createSelector } from "@reduxjs/toolkit";
import { IState } from "../interfaces/main-state.interface";
import { TREE_MODE_APPLIED_CONTENT } from "../components/constants";

const getFolders = (state: IState) => {
  if (getTreeMode(state) === TREE_MODE_APPLIED_CONTENT) {
    //console.log('returning folders', state.client_options);
    return state.data.folders;
  } else {
    return state.data.resourcetree_folders;
  }
};
const getFolderFolders = (state: IState) => state.data.folder__folders;
const getFolderTypes = (state: IState) => state.data.folder_types;

const getCurrentCompany = (state: IState) => state.data.user.company;
const getTreeMode = (state) => {
  if (state.treemode_override) {
    return state.treemode_override;
  }
  return state.client_options.treemode;
};
const getResourceTreeFolderFolders = (state: IState) =>
  state.data.resourcetree_folder__folders;

const arrayFromKeyedObject = (obj, arrayId) => {
  let returnArray = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      returnArray.push({
        ...obj[key],
        // Unique identifier for tree view
        treeId: arrayId + "-" + obj[key].id,
      });
    }
  }
  return returnArray;
};

export const getCurrentCompanyJoinedFolder__FolderFolders = createSelector(
  [
    getFolders,
    getFolderFolders,
    getResourceTreeFolderFolders,
    getCurrentCompany,
    getTreeMode,
    getFolderTypes,
  ],
  (
    folders,
    folderFolders,
    resourceTreeFolderFolders,
    currentCompanyId,
    treeMode,
    folder_types
  ) => {
    if (!Object.keys(folders).length) {
      return [];
    }
    if (
      treeMode === TREE_MODE_APPLIED_CONTENT &&
      !Object.keys(folderFolders).length
    ) {
      return [];
    }
    if (!Object.keys(folder_types).length) {
      return [];
    }
    if (
      treeMode !== TREE_MODE_APPLIED_CONTENT &&
      !Object.keys(resourceTreeFolderFolders).length
    ) {
      return [];
    }

    const origin_folder__folders =
      treeMode === TREE_MODE_APPLIED_CONTENT
        ? folderFolders
        : resourceTreeFolderFolders;

    let return_folder__folders = arrayFromKeyedObject(
      origin_folder__folders,
      "folder-folder"
    ).filter((ff) => ff.company === currentCompanyId);
    return_folder__folders = return_folder__folders.sort((a, b) => {
      return a.rank - b.rank;
    });

    // Join folder_folder to folder
    const joinedfolder__foldersArr = return_folder__folders.map(
      (folder__folder) => {
        const folder = folders[folder__folder.child_folder];
        if (!folder) {
          return {};
        } else {
          const folderWithTreeId = {
            ...folder,
            treeId: `folder-${folder.id}`
          };
          return {
            ...folder__folder,
            ...folderWithTreeId,
            folder__folder_id: folder__folder.id,
            rank: folder__folder.rank,
            folder_type_config: folder_types[folder.folder_type],
          };
        }
      }
    );

    return joinedfolder__foldersArr;
  }
);
