import React from "react";
import { Select } from "antd";
import { BaseInputProps } from "./types";

export type SearchableSelectInputPros = BaseInputProps<string> & {
  options: [{ title: string; value: string }];
};

const SearchableSelectInput = (props: SearchableSelectInputPros) => {
  const selectOptions = props.options.map((option) => {
    return (
      <Select.Option key={option.value} value={option.value}>
        {option.title}
      </Select.Option>
    );
  });
  return (
    <React.Fragment>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          <br />
        </span>
      ) : null}
      <Select
        showSearch
        disabled={!props.canUpdate}
        className="select-input"
        defaultValue={props.defaultValue}
        onChange={(newValue) => props.onChange(props.fieldName, newValue)}
        onBlur={(newValue) =>
          props.onBlur(props.fieldName, newValue.toString())
        }
        value={props.defaultValue}
        style={{ minWidth: 200 }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionFilterProp="children"
      >
        {selectOptions}
      </Select>
    </React.Fragment>
  );
};

export default SearchableSelectInput;
