import React, { useState, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "./TextInput";

interface ITextLinkProps {
  onBlur: any;
  showLabel: boolean;
  fieldName: string;
  defaultValue: {
    text: string;
    url: string;
  };
  title: string;
  onChange: any;
  showCharCount: boolean;
  canUpdate: boolean;
}

const TextLink: React.FC<ITextLinkProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [text, setText] = useState(props.defaultValue?.text ?? "");
  const [url, setUrl] = useState(props.defaultValue?.url ?? "");

  useEffect(() => {
    setText(props.defaultValue?.text ?? "");
    setUrl(props.defaultValue?.url ?? "");
  }, [props.defaultValue]);

  const onOk = () => {
    props.onBlur(props.fieldName, { text, url });
    setModalOpen(false);
  };

  return (
    <>
      <a href="#" onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon className="fa" icon={["fas", "external-link"]} />
      </a>
      <Modal
        width={360}
        visible={modalOpen}
        onOk={onOk}
        onCancel={() => setModalOpen(false)}
        title={
          <React.Fragment>
            Edit {props.title}
          </React.Fragment>
        }
      >
        <TextInput
          title="Text"
          fieldName="text"
          onChange={(_f, value) => setText(value)}
          onBlur={() => {}}
          showCharCount={false}
          defaultValue={text}
          showLabel
          canUpdate
        />
        <br />
        <br />
        <TextInput
          title="Url"
          fieldName="url"
          onChange={(_f, value) => setUrl(value)}
          onBlur={() => {}}
          showCharCount={false}
          defaultValue={url}
          showLabel
          canUpdate
        />
      </Modal>
    </>
  );
};

export default TextLink;
