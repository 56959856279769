import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, Table, Row, Col } from "antd";
import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import {
  deleteDisplayIdentifier,
  getDisplayData,
  upsertFolderArModel,
  getFolderArModel,
} from "../../actions/folderActions";
import {
  upsertUriAccess,
} from "../../actions/uriAccessActions";
import { IDisplayTableRow } from "../../interfaces/display-table-row.interface";
import { IState } from "../../interfaces/main-state.interface";
import { IFolderArModel } from "../../shared-global/interfaces/models/folder_ar_model.interface";
import {
  IURIAccess,
  URI_ACCESS_TYPE,
} from "../../shared-global/interfaces/models/uri_access.interface";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { setProjectForAllChildren } from "../../utils/treeUtils";
import AlertNotification from "../layout/AlertNotification";
import HardwareInformation from "./HardwareInformation";
import URISettingsModal from "./URISettingsModal";
import ObjectRecognitionSettingsForm from "./ObjectRecognitionSettingsForm";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
interface IAdminDashboardAreaProps {
  preparePublishFolder: Function;
  openBackupsModal: Function;
}

export const AdminDashboardArea: React.FC<IAdminDashboardAreaProps> = (
  props: IAdminDashboardAreaProps
) => {
  const dispatch = useDispatch();
  const mainStateSelectedFolderId = useSelector(
    (state: IState) =>
      state.client_options.applied_content_tree_selected_id
  );
  // Do not remove this line, it is used to trigger a re-render when the folder is changed
  const state = useSelector((state: IState) => state);
  const folders = state.data.folders;
  const folder__folders = state.data.folder__folders;
  const projects = useSelector((state: IState) => state.data.projects);
  const locations = useSelector((state: IState) => state.data.locations);
  const uri_accesses = useSelector((state: IState) => state.data.uri_accesses);
  const selected_folder_id = useSelector(
    (state: IState) =>
      state.client_options.applied_content_tree_selected_id
  );

  const [showHwInfoModal, setShowHwInfoModal] = useState(false);
  const [isObjectRecornitionModalOpen, setIsObjectRecornitionModalOpen] =
    useState(false);

  const extractFolderId = (strId) => {
    return Number(
      strId
        .toString()
        .substring(mainStateSelectedFolderId.toString().indexOf("-") + 1)
    );
  };
  
  const selectedFolder = folders[extractFolderId(mainStateSelectedFolderId)];

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFolderType, setSelectedFolderType] =
    useState<IFolderType>(null);
  const [selectedURIAccess, setSelectedURIAccess] = useState<IURIAccess>(null);
  const [displayToDelete, setDisplayToDelete] = useState(null);
  const [folderArModel, setFolderArModel] = useState<IFolderArModel>(null);
  const [selectedReportedDisplayIndex, setSelectedReportedDisplayIndex] =
    useState(null);
  const [uriSettingsOpen, setUriSettingsOpen] = useState(false);

  useEffect(() => {
    let _updateTimeout = setInterval(() => {
      if (selectedFolder) {
        dispatch(getDisplayData(selectedFolder.id, null, null));
      }
    }, 30000);

    return () => {
      clearInterval(_updateTimeout);
    };
  }, []);


  const isFolder = () => {
    return mainStateSelectedFolderId.toString().startsWith("folder");
  };

  const selectedURIAccessTypeSafe = (selectedURIAccess: IURIAccess | null) => {
    if (selectedURIAccess) {
      switch (selectedURIAccess.access_type) {
        case URI_ACCESS_TYPE.NONE:
          return "";
        case URI_ACCESS_TYPE.PASSWORD:
          return ": Shared Password";
        case URI_ACCESS_TYPE.PUBLIC:
          return ": Public Access";
        case URI_ACCESS_TYPE.USER:
          return ": Limited User Access";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (isFolder()) {
      const uri_accesses_array = arrayFromKeyedObject(uri_accesses);
      const uri_access = uri_accesses_array.find(
        (uria: IURIAccess) =>
          uria.folder === extractFolderId(mainStateSelectedFolderId)
      );
      if (uri_access) {
        setSelectedURIAccess(uri_access);
      }
    }
  }, [folders, mainStateSelectedFolderId, uri_accesses]);

  const preparePublishFolder = () => {
    const api_identifier = selectedFolder.api_identifier;
    props.preparePublishFolder(api_identifier);
  };

  const onCreateBackupSuccess = () => {
    AlertNotification("success", "Success", "Backup created successfully");
    dispatch(
      setClientOptions({
        lock_screen: false,
      })
    );
  };

  const onUpsertURIAccess = () => {
    AlertNotification("success", "Success", "Access Settings Updated");
    setUriSettingsOpen(false);
  };

  const onCreateBackupFailure = (message) => {
    dispatch(
      setClientOptions({
        lock_screen: false,
      })
    );
  };

  const callDeleteDisplayIdentifier = () => {
    dispatch(
      deleteDisplayIdentifier(
        displayToDelete,
        selectedFolder.id,
        onDeleteDisplaySuccess,
        onDeleteDisplayFail
      )
    );
    setModalVisible(false);
    setDisplayToDelete(null);
  };

  const onDeleteDisplaySuccess = () => {
    AlertNotification(
      "success",
      "Success",
      "Display identifier successfully deleted"
    );
  };

  const onDeleteDisplayFail = (message) => {
    AlertNotification(
      "error",
      "Error",
      message || "Display could not been deleted"
    );
  };

  const prepareDeleteDisplay = (record_id) => {
    setModalVisible(true);
    setDisplayToDelete(record_id);
  };

  const cancelModal = () => {
    setModalVisible(false);
    setDisplayToDelete(null);
  };

  const onSubmitObjectRecognition = () => {
    setIsObjectRecornitionModalOpen(false);
    dispatch(
      upsertFolderArModel(folderArModel, () => {
        AlertNotification(
          "success",
          "Success",
          "Object Recognition Settings saved successfully"
        );
        setIsObjectRecornitionModalOpen(false);
      })
    );
  };

  const openChangeObjectRecognitionSettings = () => {
    dispatch(getFolderArModel({ folder_id: selectedFolder.id }));
    setIsObjectRecornitionModalOpen(true);
  };

  const displayTableColumns: ColumnsType<IDisplayTableRow> = [
    {
      title: "Name",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => {
        const x = a.id || "";
        const y = b.id || "";
        if (x.toLowerCase() > y.toLowerCase()) return -1;
        if (x.toLowerCase() < y.toLowerCase()) return 1;
        return 0;
      },
      render: (text, _record, index) => {
        return (
          <a
            href="#"
            onClick={() => {
              setSelectedReportedDisplayIndex(index);
              setShowHwInfoModal(true);
            }}
          >
            <b>{text}</b>
          </a>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a: any, b: any) => {
        const x = a.id || "";
        const y = b.id || "";
        if (x.toLowerCase() > y.toLowerCase()) return -1;
        if (x.toLowerCase() < y.toLowerCase()) return 1;
        return 0;
      },
    },
    {
      title: "Last reported",
      dataIndex: "last_reported",
      key: "last_reported",
      sorter: (a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1),
      render: (text, record) => {
        const date = new Date(Number(text));
        let light = null;
        const color = (record as any).traffic_light;
        switch (color) {
          case "green":
            light = (
              <i className="fas fa-circle" style={{ color: "green" }}></i>
            );
            break;
          case "yellow":
            light = (
              <i className="fas fa-circle" style={{ color: "yellow" }}></i>
            );
            break;
          case "red":
            light = <i className="fas fa-circle" style={{ color: "red" }}></i>;
            break;
          default:
            light = <i className="fas fa-circle" style={{ color: "gray" }}></i>;
            break;
        }
        return (
          <>
            <span style={{ marginRight: 10 }}>
              {date.toLocaleDateString() + " " + date.toLocaleTimeString()}
            </span>
            <span>{light}</span>
          </>
        );
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Last published",
      dataIndex: "publishedOn",
      key: "publishedOn",
      sorter: (a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1),
      render: (text, record) => {
        if (text === null) {
          return "Never";
        }
        const date = new Date(Number(text));
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text, record: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => prepareDeleteDisplay(record.id)}
        >
          <FontAwesomeIcon
            className="fa-lg"
            style={{ cursor: "pointer" }}
            icon={["far", "trash"]}
          />
        </span>
      ),
    },
  ];

 const reportedDisplays = (() => {
  let reported_arr = [];
  if (selectedFolder && selectedFolder.last_reported) {
    let obj;
    if (typeof selectedFolder.last_reported === "string") {
        //hack to handle json stored as string, will be deprecated after all is converted
      obj = JSON.parse(selectedFolder.last_reported);
    } else {
      obj = selectedFolder.last_reported;
    }
    let folder__foldersValues = Object.values(folder__folders);
    setProjectForAllChildren(folder__foldersValues);
    const project_id = folder__foldersValues.find(
      (x) => x.child_folder === selectedFolder.id
    ).project;
    const project = projects[project_id];
    const location = locations[project.location];
    reported_arr = Object.values(obj).map((x: any) => {
      const curr_timestamp = new Date().getTime();
      const diff = curr_timestamp - Number(x.last_reported);
      const diff_in_minutes = diff / (1000 * 60);
      let traffic_light;
      if (diff_in_minutes < project.yellow_alert_minutes) {
        traffic_light = "green";
      } else if (diff_in_minutes < project.red_alert_minutes) {
        traffic_light = "yellow";
      } else {
        traffic_light = "red";
      }
      return {
        id: x.display_identifier,
        last_reported: x.last_reported,
        traffic_light: traffic_light,
        location: location.name,
        publishedOn: selectedFolder.publishedOn,
        hardware: x.hardware,
      };
    });
  }
  return reported_arr;
})(); 

  const updateFolderArModel = (fArM) => {
    setFolderArModel(fArM);
  };

  return (
    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
      <div style={{ marginBottom: 20 }}>
        {selectedFolder && selectedFolder.pullable && (
          <Card title="Folder Publish">
            {selectedFolder && selectedFolder.publishing && (
            <div style={{ marginBottom: "10px" }}>
              <FontAwesomeIcon
                icon={["fas", "spinner"]}
                className="fa-1x"
                spin
              />
              &nbsp; Changes are being published
            </div>
          )}
            <div className="backup-buttons">
              {selectedFolder && selectedFolder.pullable && (
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: "auto",
                    fontSize: 14,
                    padding: 9,
                    fontWeight: "bold",
                    marginLeft: 10,
                    flex: 1,
                  }}
                  onClick={preparePublishFolder}
                  disabled={_.get(selectedFolder, "publishing", false)}
                >
                  PUBLISH
                </Button>
              )}
            </div>
          </Card>
        )}
        <Card
          title={
            "Webview and App Access Settings" +
            selectedURIAccessTypeSafe(selectedURIAccess)
          }
        >
          <div>
            {selectedURIAccess ? (
              <div style={{ textAlign: "center" }}>
                {selectedURIAccess.access_type !== URI_ACCESS_TYPE.NONE ? (
                  <div style={{ marginBottom: 10 }}>
                    URL Access:{" "}
                    <a
                      target={"_blank"}
                      href={`/webview/${selectedURIAccess.identifier}`}
                    >
                      {`${process.env.REACT_APP_CLIENT_URL}/webview/${selectedURIAccess.identifier}`}
                    </a>
                  </div>
                ) : (
                  <div>Webview access disabled</div>
                )}
              </div>
            ) : null}
            <div className="webview-buttons">
              <Button
                type="primary"
                size="large"
                onClick={() => setUriSettingsOpen(true)}
                style={{
                  width: "auto",
                  fontSize: 14,
                  padding: 9,
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                CHANGE WEBVIEW SETTINGS
              </Button>

              {selectedFolderType &&
                selectedFolderType.object_recognition_settings && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => openChangeObjectRecognitionSettings()}
                    style={{
                      width: "auto",
                      fontSize: 14,
                      padding: 9,
                      fontWeight: "bold",
                      marginLeft: 10,
                    }}
                  >
                    CHANGE OBJECT RECOGNITION SETTINGS
                  </Button>
                )}
            </div>

            {selectedFolder && selectedFolder.pullable && isFolder() && (
              <URISettingsModal
                selectedURIAccess={selectedURIAccess}
                open={uriSettingsOpen}
                handleClose={() => {
                  setUriSettingsOpen(false);
                }}
                selectedFolderId={selectedFolder.id}
                handleOkay={(payload) => {
                  dispatch(upsertUriAccess(payload, onUpsertURIAccess, null));
                }}
              />
            )}

            <Modal
              visible={isObjectRecornitionModalOpen}
              onOk={() => onSubmitObjectRecognition()}
              onCancel={() => setIsObjectRecornitionModalOpen(false)}
            >
              <ObjectRecognitionSettingsForm
                updateFolderArModel={updateFolderArModel}
              />
            </Modal>
          </div>
        </Card>
      </div>

      {_.get(selectedFolder, "pullable", false) ? (
        <div>
          <Table
            className="admin-display-table"
            title={() => "Display report"}
            rowKey={(record) => record.id}
            columns={displayTableColumns}
            dataSource={reportedDisplays}
            pagination={{ position: ["bottomRight"] }}
            bordered={true}
          ></Table>
        </div>
      ) : null}
      <Modal
        visible={modalVisible}
        onCancel={() => cancelModal()}
        onOk={() => callDeleteDisplayIdentifier()}
      >
        Are you sure that you want to delete this display identifier?
      </Modal>
      <HardwareInformation
        displayIndex={selectedReportedDisplayIndex}
        data={
          selectedReportedDisplayIndex !== null
            ? reportedDisplays[selectedReportedDisplayIndex].hardware
            : null
        }
        folder={selected_folder_id.toString()}
        visible={showHwInfoModal}
        cancel={() => setShowHwInfoModal(false)}
      />
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   folders: state.data.folders,
//   folder__folders: state.data.folder__folders,
//   grouped_permissions: state.data.user_permissions_grouped,
//   projects: state.data.projects,
//   selected_folder_id:
//     state.client_options.applied_content_tree_selected_id,
//   user: state.data.user,
//   locations: state.data.locations,
//   client_options: state.client_options,
//   folder_types: state.data.folder_types,
// });

// const mapDispatchToProps = (dispatch) => ({
//   onCreateBackup: (api_identifier, onSuccess, onFail) => {
//     dispatch(createBackup(api_identifier, onSuccess, onFail));
//   },
//   deleteDisplayIdentifier: (record_id, folder_id, onSuccess, onFail) => {
//     dispatch(deleteDisplayIdentifier(record_id, folder_id, onSuccess, onFail));
//   },
//   set_client_options: (client_options) => {
//     dispatch(setClientOptions(client_options));
//   },
//   getDisplayData: (folder_id, onSuccess, onFail) => {
//     dispatch(getDisplayData(folder_id, onSuccess, onFail));
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardArea);

export default AdminDashboardArea;
