export enum ERROR_NAME {
  DEFAULT = 'default',
  INVALID_PARAMS = 'invalid_params',
  UNAUTHORIZED = 'unauthorized',
  NOT_FOUND = 'not_found',
  INVALID_CREDS = 'invalid_creds',
  INVALID_FILE = 'invalid_file',
  RECORD_EXISTS = 'record_exists',
  RECORD_NOT_FOUND = 'record_not_found',
}

export enum INFO_MESSAGE {
  CREATE_BACKUP_SUCCESS = 'Backup created successfully',
  DEFAULT = 'Success',
  FOLDER_UPDATE_SUCCESS = 'Folder updated successfully',
  USER_COMPANY_ADDED = 'User company added successfully',
  USER_COMPANY_CREATED = 'User company created successfully',
  USER_COMPANY_REMOVED = 'User company removed successfully',
  FOLDERS_GENERATED = 'Folders generated successfully',
  PLACEHOLDER_CONFIGURATION_CHANGED = 'Placeholder configuration changed successfully',
  GENERATE_CLIENT_CONFIG_SUCCESS = 'Client config generated successfully',
  GET_BY_COMPANY_SUCCESS = 'Audit logs retrieved successfully',
  LS_DATA_SUCCESS = 'Data loaded successfully',
  LOGIN_SUCCESS = 'Login successful',
  LOGOUT_SUCCESS = 'Logout successful',
  USER_NOT_FOUND = 'User not found',
  REQUEST_PASSWORD_RESET_SUCCESS = 'Password reset request successful',
  RESET_PASSWORD_SUCCESS = 'Password updated',
  SNS_FOLDER_TYPE_ALERT_SUCCESS = 'SNS folder type alert successful',
  SNS_TEST_SUCCESS = 'SNS test successful',
  GET_BACKUP_SUCCESS = 'Backup retrieved successfully',
  RESTORE_BACKUP_SUCCESS = 'Backup restored successfully',
  EXPORT_BACKUP_SUCCESS = 'Backup exported successfully',
  CREATE_CROSS_BACKUP_SUCCESS = 'Cross backup created successfully',
  GET_COMPANIES_SUCCESS = 'Companies retrieved successfully',
  REINDEX_ELASTIC_SUCCESS = 'Elastic reindexed successfully',
  GET_COMPANY_SUCCESS = 'Company retrieved successfully',
  CREATE_COMPANY_SUCCESS = 'Company created successfully',
  UPDATE_COMPANY_SUCCESS = 'Company updated successfully',
  ADD_ROLE_SUCCESS = 'Role added successfully',
  UPDATE_ROLE_SUCCESS = 'Role updated successfully',
  DESTROY_ROLE_SUCCESS = 'Role destroyed successfully',
  DESTROY_USER_ROLE_SUCCESS = 'User role destroyed successfully',
  CREATE_DEFAULT_ROLES_SUCCESS = 'Default roles created successfully',
  ADD_PERMISSION_SUCCESS = 'Permission added successfully',
  UPDATE_PERMISSION_SUCCESS = 'Permission updated successfully',
  DELETE_PERMISSION_SUCCESS = 'Permission deleted successfully',
  CREATE_DEFAULT_PERMISSION_SUCCESS = 'Default permissions created successfully',
  DELETE_COMPANY_SUCCESS = 'Company deleted successfully',
  TOGGLE_SUBSCRIPTION_SUCCESS = 'Subscription toggled successfully',
  ADD_FOLDER_SUCCESS = 'Folder added successfully',
  CLONE_FOLDER_SUCCESS = 'Folder cloned successfully',
  MOVE_FOLDER_SUCCESS = 'Folder moved successfully',
  REMOVE_FOLDER_SUCCESS = 'Folder removed successfully',
  URI_ACCESS_GET_DATA_SUCCESS = 'Data retrieved successfully',
  UPDATE_FOLDER_SUCCESS = 'Folder updated successfully',
  UPDATE_UPLOADED_RESOURCES_SUCCESS = 'Uploaded resources updated successfully',
  DOWNLOAD_CSV_SUCCESS = 'CSV downloaded successfully',
  UPLOAD_CSV_SUCCESS = 'CSV uploaded successfully',
  INSTANCE_FOLDER_SUCCESS = 'Instance folder retrieved successfully',
  MAKE_PULLABLE_SUCCESS = 'Folder made pullable successfully',
  GET_COMPANY_ROOT_FOLDERS_SUCCESS = 'Company root folders retrieved successfully',
  GET_FOLDER_INFO_SUCCESS = 'Folder info retrieved successfully',
  UPDATE_FOLDER_CONTENTS_SUCCESS = 'Folder contents updated successfully',
  PUBLISH_FOLDER_SUCCESS = 'Folder published successfully',
  WAIT_FOR_SYNC = 'Waiting for publish to sync',
  FETCH_CONTENTS_SUCCESS = 'Contents fetched successfully',
  DATA_NOT_PUBLISHED = 'Data was not published yet',
  MOVE_BULK_FOLDERS_SUCCESS = 'Folders moved successfully',
  CLONE_BULK_FOLDERS_SUCCESS = 'Folders cloned successfully',
  DELETE_DISPLAY_IDENTIFIER_SUCCESS = 'Display identifier deleted successfully',
  GET_DISPLAY_INFO_SUCCESS = 'Display info retrieved successfully',
  ADD_DISPLAY_HARDWARE_INFO_SUCCESS = 'Display hardware info added successfully',
  UPDATE_DISPLAY_HARDWARE_INFO_SUCCESS = 'Display hardware info updated successfully',
  DOWNLOAD_DISPLAY_HARDWARE_INFO_SUCCESS = 'Display hardware info downloaded successfully',
  PUSH_DOWN_INHERITABLE_FIELDS_SUCCESS = 'Inheritable fields pushed down successfully',
  UPDATE_FOLDER_AR_MODEL_SUCCESS = 'Folder model updated successfully',
  GET_FOLDER_AR_MODEL_SUCCESS = 'Folder model retrieved successfully',
  NOTIFY_SUCCESS = 'Notification sent successfully',
  GET_FOLDER_STATUS_SUCCESS = 'Folder status retrieved successfully',
  FONT_GROUP_CREATE_SUCCESS = 'Font group created successfully',
  FONT_GROUP_UPDATE_SUCCESS = 'Font group updated successfully',
  FONT_GROUP_DELETE_SUCCESS = 'Font group deleted successfully',
  GET_LOCATIONS_SUCCESS = 'Locations retrieved successfully',
  CREATE_LOCATION_SUCCESS = 'Location created successfully',
  UPDATE_LOCATION_SUCCESS = 'Location updated successfully',
  DELETE_LOCATION_SUCCESS = 'Location deleted successfully',
  GET_NOTIFICATION_SUCCESS = 'Notification retrieved successfully',
  UPDATE_NOTIFICATION_SUCCESS = 'Notification updated successfully',
  UPDATE_PROJECT_SUCCESS = 'Project updated successfully',
  UPDATE_PROJECT_SETTINGS_SUCCESS = 'Project settings updated successfully',
  CREATE_PROJECT_SUCCESS = 'Project created successfully',
  GET_ALL_NOTIFIABLE_USERS_SUCCESS = 'Notifiable users retrieved successfully',
  DELETE_PROJECT_SUCCESS = 'Project deleted successfully',
  ADD_RESOURCES_SUCCESS = 'Resources added successfully',
  UPDATE_RESOURCES_SUCCESS = 'Resources updated successfully',
  DELETE_RESOURCES_SUCCESS = 'Resources deleted successfully',
  MOVE_RESOURCES_SUCCESS = 'Resources moved successfully',
  UPDATE_FILE_RESOURCE_SUCCESS = 'File resource updated successfully',
  ADD_TEXT_RESOURCES_SUCCESS = 'Text resources added successfully',
  UPDATE_TEXT_RESOURCE_SUCCESS = 'Text resource updated successfully',
  GET_RESOURCE_TAGS_SUCCESS = 'Resource tags retrieved successfully',
  ADD_RESOURCE_TAG_SUCCESS = 'Resource tag added successfully',
  DELETE_RESOURCE_TAG_SUCCESS = 'Resource tag deleted successfully',
  GET_RESOURCE_NOTES_SUCCESS = 'Resource notes retrieved successfully',
  ADD_RESOURCE_NOTE_SUCCESS = 'Resource note added successfully',
  DELETE_RESOURCE_NOTE_SUCCESS = 'Resource note deleted successfully',
  UPDATE_RESOURCE_FOLDER_TABLE_SUCCESS = 'Resource folder table updated successfully',
  MAIN_SEARCH_SUCCESS = 'Main search successful',
  SEARCH_TAGS_SUCCESS = 'Search tags successful',
  CHECK_UPDATE_SUCCESS = 'Check update successful',
  URI_ACCESS_INITIALIZE_SUCCESS = 'Uri access initialized successfully',
  GET_URI_ACCESS_BY_FOLDER_SUCCESS = 'Uri access retrieved successfully',
  CHANGE_URI_ACCESS_SUCCESS = 'Uri access changed successfully',
  GET_USERS_SUCCESS = 'Users retrieved successfully',
  GET_USER_SUCCESS = 'User retrieved successfully',
  UPDATE_USER_SUCCESS = 'User updated successfully',
  ADD_USER_SUCCESS = 'User added successfully',
  GET_DATA_SUCCESS = 'Data retrieved successfully',
  ACCOUNT_UPDATE_SUCCESS = 'Account updated successfully',
  UPDATE_PERSISTENT_SETTINGS_SUCCESS = 'Persistent settings updated successfully',
  DELETE_USER_SUCCESS = 'User deleted successfully',
  CHANGE_COMPANY_SUCCESS = 'Company changed successfully',
  RESEND_PASSWORD_EMAIL_SUCCESS = 'Password reset email sent successfully',
}

export enum ERROR_MESSAGE {
  USER_ACCESS_PROJECT_ERROR = 'User is not allowed to access this project',
  ADD_ROLE_USER_ERROR = 'An unexpected error occurred while trying to add the role user, our team has been informed and will look into it shortly.',
  RESEND_PASSWORD_EMAIL_ERROR = 'An unexpected error occurred while trying to resend password email, our team has been informed and will look into it shortly.',
  CHANGE_COMPANY_ERROR = 'An unexpected error occurred while trying to change the company, our team has been informed and will look into it shortly.',
  DELETE_USER_ERROR = 'An unexpected error occurred while trying to delete the user, our team has been informed and will look into it shortly.',
  UPDATE_PERSISTENT_SETTINGS_ERROR = 'An unexpected error occurred while trying to update the persistent settings, our team has been informed and will look into it shortly.',
  WRONG_CURRENT_PASSWORD = 'Wrong current password',
  UPDATE_PASSWORD_ERROR = 'An unexpected error occurred while trying to update the password, our team has been informed and will look into it shortly.',
  ACCOUNT_UPDATE_ERROR = 'An unexpected error occurred while trying to update the account, our team has been informed and will look into it shortly.',
  GET_DATA_ERROR = 'An unexpected error occurred while trying to get the data, our team has been informed and will look into it shortly.',
  DELETE_ROLE_USER_ERROR = 'An unexpected error occurred while trying to delete the role user, our team has been informed and will look into it shortly.',
  CANNOT_ADD_DUPLICATE_ROLE = 'Cannot add duplicate role',
  ONLY_SYSTEM_ADMINS_CAN_ASSIGN_ROLES = 'Only system admins can assign roles in a different company',
  EMAIL_ALREADY_EXISTS = 'Email already exists',
  ADD_USER_ERROR = 'An unexpected error occurred while trying to add a user, our team has been informed and will look into it shortly.',
  UPDATE_USER_ERROR = 'An unexpected error occurred while trying to update the user, our team has been informed and will look into it shortly.',
  GET_USER_ERROR = 'An unexpected error occurred while trying to get the user, our team has been informed and will look into it shortly.',
  GET_USERS_ERROR = 'An unexpected error occurred while trying to get the users, our team has been informed and will look into it shortly.',
  ACCESS_SETTING_ALREADY_EXISTS = 'An access setting already exists for this folder, please refresh the browser',
  URI_ACCESSS_NOT_FOUND = 'Uri access not found',
  CHANGE_URI_ACCESS_ERROR = 'An unexpected error occurred while trying to change the uri access, our team has been informed and will look into it shortly.',
  IDENTIFIER_ALREADY_EXISTS = 'Identifier already exists',
  URI_ACCESS_INITIALIZE_ERROR = 'An unexpected error occurred while trying to initialize the uri access, our team has been informed and will look into it shortly.',
  CHECK_UPDATE_ERROR = 'An unexpected error occurred while trying to check the update, our team has been informed and will look into it shortly.',
  SEARCH_TAGS_ERROR = 'An unexpected error occurred while trying to search tags, our team has been informed and will look into it shortly.',
  UPDATE_RESOURCE_FOLDER_TABLE_ERROR = 'An unexpected error occurred while trying to update the resource folder table, our team has been informed and will look into it shortly.',
  MOVE_RESOURCE_FOLDER_ERROR = 'An unexpected error occurred while trying to move the resource folder, our team has been informed and will look into it shortly.',
  DELETE_RESOURCE_NOTE_ERROR = 'An unexpected error occurred while trying to delete the resource note, our team has been informed and will look into it shortly.',
  ADD_RESOURCE_NOTE_ERROR = 'An unexpected error occurred while trying to add the resource note, our team has been informed and will look into it shortly.',
  GET_RESOURCE_NOTES_ERROR = 'An unexpected error occurred while trying to get the resource notes, our team has been informed and will look into it shortly.',
  DELETE_RESOURCE_TAG_ERROR = 'An unexpected error occurred while trying to delete the resource tag, our team has been informed and will look into it shortly.',
  ADD_RESOURCE_TAG_ERROR = 'An unexpected error occurred while trying to add the resource tag, our team has been informed and will look into it shortly.',
  GET_RESOURCE_TAGS_ERROR = 'An unexpected error occurred while trying to get the resource tags, our team has been informed and will look into it shortly.',
  EXISTING_APPLIED_CONTENT_FOUND = 'Existing applied content joins found',
  RESOURCE_NOT_FOUND = 'Resource not found',
  UPDATE_TEXT_RESOURCE_ERROR = 'An unexpected error occurred while trying to update the text resource, our team has been informed and will look into it shortly.',
  ADD_TEXT_RESOURCES_ERROR = 'An unexpected error occurred while trying to add text resources, our team has been informed and will look into it shortly.',
  UPDATE_FILE_RESOURCE_ERROR = 'An unexpected error occurred while trying to update the file resource, our team has been informed and will look into it shortly.',
  UPDATE_RESOURCES_ERROR = 'An unexpected error occurred while trying to update resources, our team has been informed and will look into it shortly.',
  MOVE_RESOURCES_ERROR = 'An unexpected error occurred while trying to move resources, our team has been informed and will look into it shortly.',
  DELETE_RESOURCES_ERROR = 'An unexpected error occurred while trying to delete resources, our team has been informed and will look into it shortly.',
  DELETE_RESOURCE_ERROR = 'An unexpected error occurred while trying to delete the resource, our team has been informed and will look into it shortly.',
  UPDATE_RESOURCE_ERROR = 'An unexpected error occurred while trying to update the resource, our team has been informed and will look into it shortly.',
  ADD_RESOURCES_ERROR = 'An unexpected error occurred while trying to add resources, our team has been informed and will look into it shortly.',
  DOWNLOAD_FILE_ERROR = 'An unexpected error occurred while trying to download the file, our team has been informed and will look into it shortly.',
  FILE_DOESNT_EXIST = 'File doesn\'t exist',
  CHECK_MD5_ERROR = 'An unexpected error occurred while trying to check the md5, our team has been informed and will look into it shortly.',
  DELETE_PROJECT_ERROR = 'An unexpected error occurred while trying to delete the project, our team has been informed and will look into it shortly.',
  GET_ALL_NOTIFIABLE_USERS_ERROR = 'An unexpected error occurred while trying to get all notifiable users, our team has been informed and will look into it shortly.',
  MISSING_TEMPLATE = 'Something went wrong trying to create the project, the provided template was not found in our database. Our team has been informed and will look into it shortly.',
  CREATE_PROJECT_ERROR = 'An unexpected error occurred while trying to create the project',
  UPDATE_PROJECT_SETTINGS_ERROR = 'An unexpected error occurred while trying to update the project settings, our team has been informed and will look into it shortly.',
  UPDATE_PROJECT_ERROR = 'An unexpected error occurred while trying to update the project, our team has been informed and will look into it shortly.',
  UPDATE_NOTIFICATION_ERROR = 'An unexpected error occurred while trying to update the notification, our team has been informed and will look into it shortly.',
  GET_NOTIFICATION_ERROR = 'An unexpected error occurred while trying to get the notification, our team has been informed and will look into it shortly.',
  LOCATION_HAS_PROJECTS = 'This location can\'t be deleted because it has projects associated',
  DELETE_LOCATION_ERROR = 'An unexpected error occurred while trying to delete a location, our team has been informed and will look into it shortly.',
  UPDATE_LOCATION_ERROR = 'An unexpected error occurred while trying to update a location, our team has been informed and will look into it shortly.',
  CREATE_LOCATION_ERROR = 'An unexpected error occurred while trying to create a location, our team has been informed and will look into it shortly.',
  GET_LOCATIONS_ERROR = 'An unexpected error occurred while trying to get the locations, our team has been informed and will look into it shortly.',
  FONT_GROUP_DELETE_ERROR = 'An unexpected error occurred while trying to delete a font group, our team has been informed and will look into it shortly.',
  FONT_GROUP_UPDATE_ERROR = 'An unexpected error occurred while trying to update a font group, our team has been informed and will look into it shortly.',
  FONT_GROUP_CREATE_ERROR = 'An unexpected error occurred while trying to create a font group, our team has been informed and will look into it shortly.',
  GET_FOLDER_STATUS_ERROR = 'An unexpected error occurred while trying to get the folder status, our team has been informed and will look into it shortly.',
  CONVERSION_KEY_NOT_FOUND = 'A \'conversion key\' couldn\'t be found in the config.js file for handling this webhook or the key does not match with the one sent from the lambda function',
  NOTIFY_ERROR = 'An unexpected error occurred while trying to notify, our team has been informed and will look into it shortly.',
  URI_ACCESS_DOESNT_EXIST = 'The specified uri access doesn\'t exist',
  URI_ACCESS_CHECK_NEW_DATA_ERROR = 'An unexpected error occurred while trying to check new data, our team has been informed and will look into it shortly.',
  INVALID_PASS_PHRASE = 'Invalid pass phrase',
  GET_FOLDER_AR_MODEL_ERROR = 'An unexpected error occurred while trying to get the folder model, our team has been informed and will look into it shortly.',
  UPDATE_FOLDER_AR_MODEL_ERROR = 'An unexpected error occurred while trying to update the folder model, our team has been informed and will look into it shortly.',
  PUSH_DOWN_INHERITABLE_FIELDS_ERROR = 'An unexpected error occurred while trying to push down inheritable fields, our team has been informed and will look into it shortly.',
  DOWNLOAD_DISPLAY_HARDWARE_INFO_ERROR = 'An unexpected error occurred while trying to download the display hardware info, our team has been informed and will look into it shortly.',
  UPDATE_DISPLAY_HARDWARE_INFO_ERROR = 'An unexpected error occurred while trying to update the display hardware info, our team has been informed and will look into it shortly.',
  ADD_DISPLAY_HARDWARE_INFO_ERROR = 'An unexpected error occurred while trying to add the display hardware info, our team has been informed and will look into it shortly.',
  GET_DISPLAY_INFO_ERROR = 'An unexpected error occurred while trying to get the display info, our team has been informed and will look into it shortly.',
  DELETE_DISPLAY_IDENTIFIER_ERROR = 'An unexpected error occurred while trying to delete the display identifier, our team has been informed and will look into it shortly.',
  CLONE_BULK_FOLDERS_ERROR = 'An unexpected error occurred while trying to clone folders, our team has been informed and will look into it shortly.',
  FOLDER_TYPES_NOT_COMPATIBLE = 'Folder types are not compatible',
  MOVE_BULK_FOLDERS_ERROR = 'An unexpected error occurred while trying to move folders, our team has been informed and will look into it shortly.',
  FETCH_CONTENTS_ERROR = 'An unexpected error occurred while trying to fetch the contents, our team has been informed and will look into it shortly.',
  PUBLISH_FOLDER_ERROR = 'An unexpected error occurred while trying to publish the folder, our team has been informed and will look into it shortly.',
  UPDATE_FOLDER_CONTENTS_ERROR = 'An unexpected error occurred while trying to update the folder contents, our team has been informed and will look into it shortly.',
  GET_FOLDER_INFO_ERROR = 'An unexpected error occurred while trying to get the folder info, our team has been informed and will look into it shortly.',
  GET_COMPANY_ROOT_FOLDERS_ERROR = 'An unexpected error occurred while trying to get the company root folders, our team has been informed and will look into it shortly.',
  MAKE_PULLABLE_ERROR = 'An unexpected error occurred while trying to make the folder pullable, our team has been informed and will look into it shortly.',
  DESTINATION_NOT_COMPATIBLE = 'The destination folder is not compatible',
  VALIDATE_FOLDER_SLOTS_ERROR = 'This item doesn\'t fit in this folder, please remove some items or choose a different item',
  INSTANCE_FOLDER_ERROR = 'An unexpected error occurred while trying to get the instance folder, our team has been informed and will look into it shortly.',
  UPLOAD_CSV_ERROR = 'An unexpected error occurred while trying to upload the csv, our team has been informed and will look into it shortly.',
  DOWNLOAD_CSV_ERROR = 'An unexpected error occurred while trying to download the csv, our team has been informed and will look into it shortly.',
  UPDATE_UPLOADED_RESOURCES_ERROR = 'An unexpected error occurred while trying to update the uploaded resources, our team has been informed and will look into it shortly.',
  UPDATE_FOLDER_ERROR = 'An unexpected error occurred while trying to update the folder, our team has been informed and will look into it shortly.',
  INCOMPATIBLE_FOLDER_CREATION = 'The specified type of folder cannot be created within the specified folder because they are not compatible',
  URI_ACCESS_GET_DATA_ERROR = 'An unexpected error occurred while trying to get the data, our team has been informed and will look into it shortly.',
  IDENTIFIER_DOESNT_EXIST = 'The specified identifier doesn\'t exist',
  FOLDER_HAS_CHILDREN = 'The folder has children and doesn\'t have more instances. No deletion was performed',
  REMOVE_FOLDER_ERROR = 'An unexpected error occurred while trying to remove the folder, our team has been informed and will look into it shortly.',
  ITEM_DOESNT_FIT_IN_FOLDER = 'This item doesn\'t fit in this folder, please remove some items or choose a different item',
  FORBIDDEN_FOLDER_MOVE = 'The specified type of folder cannot be moved within the specified folder because they are not compatible',
  MOVE_FOLDER_ERROR = 'An unexpected error occurred while trying to move the folder, our team has been informed and will look into it shortly.',
  CLONE_FOLDER_ERROR = 'An unexpected error occurred while trying to clone the folder, our team has been informed and will look into it shortly.',
  ADD_FOLDER_ERROR = 'An unexpected error occurred while trying to add a folder, our team has been informed and will look into it shortly.',
  FORBIDDEN_FOLDER_TYPE = 'This type of folder is not acceptable',
  USER_DOESNT_EXIST = 'User doesn\'t exist',
  USER_ALREADY_SUBSCRIBED_TO_EMAIL_NOTIFICATION = 'The user is already subscribed to the specified type of email notifications',
  USER_NOT_SUBSCRIBED_TO_EMAIL_NOTIFICATION = 'The user is not subscribed to the specified type of email notifications',
  TOGGLE_SUBSCRIPTION_ERROR = 'An unexpected error occurred while trying to toggle the subscription, our team has been informed and will look into it shortly.',
  DELETE_COMPANY_ERROR = 'An unexpected error occurred while trying to delete the company, our team has been informed and will look into it shortly.',
  CREATE_DEFAULT_PERMISSION_ERROR = 'An unexpected error occurred while trying to create the default permissions, our team has been informed and will look into it shortly.',
  ROLES_WITH_EXISTING_PERMISSION_EXISTS = 'There is a role with this permission, you can\'t delete it',
  DELETE_PERMISSION_ERROR = 'An unexpected error occurred while trying to delete the permission, our team has been informed and will look into it shortly.',
  UPDATE_PERMISSION_ERROR = 'An unexpected error occurred while trying to update the permission, our team has been informed and will look into it shortly.',
  ADD_PERMISSION_ERROR = 'An unexpected error occurred while trying to add a permission, our team has been informed and will look into it shortly.',
  CREATE_DEFAULT_ROLES_ERROR = 'An unexpected error occurred while trying to create the default roles, our team has been informed and will look into it shortly.',
  DESTROY_USER_ROLE_ERROR = 'An unexpected error occurred while trying to destroy the user role, our team has been informed and will look into it shortly.',
  USER_NOT_FOUND = 'User not found',
  ROLE_NOT_FOUND = 'Role not found',
  ROLE_ASSIGNED_TO_ANOTHER_USER = 'This role is assigned to another user',
  DESTROY_ROLE_ERROR = 'An unexpected error occurred while trying to destroy the role, our team has been informed and will look into it shortly.',
  UPDATE_ROLE_ERROR = 'An unexpected error occurred while trying to update the role, our team has been informed and will look into it shortly.',
  ADD_ROLE_ERROR = 'An unexpected error occurred while trying to add a role, our team has been informed and will look into it shortly.',
  UPDATE_COMPANY_ERROR = 'An unexpected error occurred while trying to update the company, our team has been informed and will look into it shortly.',
  CREATE_COMPANY_ERROR = 'An unexpected error occurred while trying to create the company, our team has been informed and will look into it shortly.',
  GET_COMPANY_ERROR = 'An unexpected error occurred while trying to get the company, our team has been informed and will look into it shortly.',
  REINDEX_ELASTIC_ERROR = 'An unexpected error occurred while trying to reindex elastic, our team has been informed and will look into it shortly.',
  REINDEX_ELASTIC_FORBIDDEN = 'You are not allowed to reindex elastic',
  GET_COMPANIES_ERROR = 'An unexpected error occurred while trying to get the companies, our team has been informed and will look into it shortly.',
  UNSUCCESSFUL_FILE_UPLOAD = 'An unexpected error occurred while trying to upload the file, our team has been informed and will look into it shortly.',
  FILE_EXTENSION_INVALID = 'File extension invalid',
  CREATE_CROSS_BACKUP_ERROR = 'An unexpected error occurred while trying to create a cross backup',
  BACKUP_DOESNT_EXIST = 'Backup doesn\'t exist',
  BACKUP_NO_FILES = 'No files found on S3 for this folder, either this project has no associated files or the folder has not been published yet',
  EXPORT_BACKUP_ERROR = 'An unexpected error occurred while trying to export the backup, our team has been informed and will look into it shortly.',
  PARENT_FOLDER_OR_BACKUP_DOESNT_EXIST = 'Either the parent folder or the backup doesn\'t exist',
  RESTORE_BACKUP_ERROR = 'An unexpected error occurred while trying to restore the backup, our team has been informed and will look into it shortly.',
  GET_BACKUP_ERROR = 'An unexpected error occurred while trying to get the backup, our team has been informed and will look into it shortly.',
  CREATE_BACKUP_ERROR = 'An unexpected error occurred while trying to create a backup, our team has been informed and will look into it shortly.',
  NO_DATA_SNAPSHOT = 'No data snapshot found. Please, first publish your changes, our team has been informed and will look into it shortly.',
  INVALID_TOKEN = 'An unexpected error occurred: Token is invalid. Maybe it has expired, please, request it again, our team has been informed and will look into it shortly.',
  TOKEN_NOT_FOUND = 'Token was already used or does not exist',
  USER_TOKEN_NOT_FOUND = 'Could not find the user related to this token',
  RESET_PASSWORD_ERROR = 'An unexpected error occurred while trying to reset the password, our team has been informed and will look into it shortly.',
  CAPTCHA_NOT_VERIFIED = 'Captcha was not verified',
  SNS_FOLDER_TYPE_ALERT_ERROR = 'An unexpected error occurred while trying to process the sns folder type alert',
  SNS_TEST_ERROR = 'An unexpected error occurred while trying to process the sns test, our team has been informed and will look into it shortly.',
  REQUEST_PASSWORD_RESET_ERROR = 'An unexpected error occurred while trying to request a password reset, our team has been informed and will look into it shortly.',
  LOGIN_ERROR = 'An unexpected error occurred while trying to login, our team has been informed and will look into it shortly.',
  LOGOUT_ERROR = 'An unexpected error occurred while trying to logout, our team has been informed and will look into it shortly.',
  MAX_FAILED_LOGIN_ATTEMPTS = 'This account has been blocked due to too many failed login attempts. Please contact a system administrator to re-enable your account',
  INVALID_USERNAME_OR_PASSWORD = 'Invalid username or password',
  UNACTIVE_ACCOUNT = 'The account does not appear to be active. Please contact a system administrator to re-enable your account',
  USER_OR_PASSWORD_NOT_FOUND = 'An unexpected error occurred: An account matching this username and password was not found',
  GET_AUDIT_LOGS_ERROR = 'An unexpected error occurred while trying to get the audit logs, our team has been informed and will look into it shortly.',
  GENERATE_CLIENT_CONFIG_ERROR = 'An unexpected error occurred while trying to generate client config, our team has been informed and will look into it shortly.',
  GENERATE_CLIENT_CONFIG_DEFAULT_INVALID_PARAMS = 'Invalid params for generate client config',
  FOLDER_NOT_FOUND = 'Folder not found',
  FOLDER_DOESNT_EXIST = 'Folder doesn\'t exist',
  FOLDER_TYPE_CONFIG_NOT_EXISTS = 'Folder type config doesn\'t exists',
  CHANGE_PLACEHOLDER_CONFIGURATION_DEFAULT = 'An unexpected error occurred while trying to change the placeholder configuration, our team has been informed and will look into it shortly.',
  ADD_USER_COMPANY_DEFAULT = 'An unexpected error occurred while trying to add a user company, our team has been informed and will look into it shortly.',
  CHANGE_PLACEHOLDER_CONFIGURATION_FORBIDDEN = 'You are not allowed to change the placeholder configuration',
  GENERATE_FOLDERS_ERROR = 'An unexpected error occurred while trying to generate folders, our team has been informed and will look into it shortly.',
  USER_COMPANY_EXISTS = 'User company already exists',
  USER_COMPANY_DOESNT_EXISTS = 'User company doesn\'t exists',
  REMOVE_USER_COMPANY_DEFAULT = 'An unexpected error occurred while trying to remove a user company, our team has been informed and will look into it shortly.',
  INVALID_SIMPLE_API_KEY = 'Simple api key does not match',
  LS_DATA_ERROR = 'An unexpected error occurred while trying to get the data, our team has been informed and will look into it shortly.',
  DEFAULT = 'An unexpected error occurred, our team has been informed and will look into it shortly.',
  FOLDER_UPDATE_DEFAULT = 'An unexpected error occurred while trying to update a folder, our team has been informed and will look into it shortly.',
  INVALID_PARAMS = 'Invalid params',
  VALIDATION_ERROR = 'There is a validation error',
  UNAUTHORIZED = 'You don\'t have permission to perform this action',
  NOT_FOUND = 'Not found',
  INVALID_CREDS = 'Invalid credentials',
  INVALID_FILE = 'Invalid file',
  RECORD_EXISTS = 'Record already exists',
  RECORD_NOT_FOUND = 'Record not found',
}

export interface ErrorObject {
  statusCode?: HTTP_STATUS,
  message?: string,
  data?: any
}

export enum ApiResponseCode {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface IApiResponse {
  code: ApiResponseCode,
  data: any
}

export enum HTTP_STATUS {

  /**
   * The server has received the request headers and the client should proceed to send the request body
   * (in the case of a request for which a body needs to be sent; for example, a POST request).
   * Sending a large request body to a server after a request has been rejected for inappropriate headers would be inefficient.
   * To have a server check the request's headers, a client must send Expect: 100-continue as a header in its initial request
   * and receive a 100 Continue status code in response before sending the body. The response 417 Expectation Failed indicates the request should not be continued.
   */
  CONTINUE = 100,

  /**
   * The requester has asked the server to switch protocols and the server has agreed to do so.
   */
  SWITCHING_PROTOCOLS = 101,

  /**
   * A WebDAV request may contain many sub-requests involving file operations, requiring a long time to complete the request.
   * This code indicates that the server has received and is processing the request, but no response is available yet.
   * This prevents the client from timing out and assuming the request was lost.
   */
  PROCESSING = 102,

  /**
   * Standard response for successful HTTP requests.
   * The actual response will depend on the request method used.
   * In a GET request, the response will contain an entity corresponding to the requested resource.
   * In a POST request, the response will contain an entity describing or containing the result of the action.
   */
  OK = 200,

  /**
   * The request has been fulfilled, resulting in the creation of a new resource.
   */
  CREATED = 201,

  /**
   * The request has been accepted for processing, but the processing has not been completed.
   * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
   */
  ACCEPTED = 202,

  /**
   * SINCE HTTP/1.1
   * The server is a transforming proxy that received a 200 OK from its origin,
   * but is returning a modified version of the origin's response.
   */
  NON_AUTHORITATIVE_INFORMATION = 203,

  /**
   * The server successfully processed the request and is not returning any content.
   */
  NO_CONTENT = 204,

  /**
   * The server successfully processed the request, but is not returning any content.
   * Unlike a 204 response, this response requires that the requester reset the document view.
   */
  RESET_CONTENT = 205,

  /**
   * The server is delivering only part of the resource (byte serving) due to a range header sent by the client.
   * The range header is used by HTTP clients to enable resuming of interrupted downloads,
   * or split a download into multiple simultaneous streams.
   */
  PARTIAL_CONTENT = 206,

  /**
   * The message body that follows is an XML message and can contain a number of separate response codes,
   * depending on how many sub-requests were made.
   */
  MULTI_STATUS = 207,

  /**
   * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response,
   * and are not being included again.
   */
  ALREADY_REPORTED = 208,

  /**
   * The server has fulfilled a request for the resource,
   * and the response is a representation of the result of one or more instance-manipulations applied to the current instance.
   */
  IM_USED = 226,

  /**
   * Indicates multiple options for the resource from which the client may choose (via agent-driven content negotiation).
   * For example, this code could be used to present multiple video format options,
   * to list files with different filename extensions, or to suggest word-sense disambiguation.
   */
  MULTIPLE_CHOICES = 300,

  /**
   * This and all future requests should be directed to the given URI.
   */
  MOVED_PERMANENTLY = 301,

  /**
   * This is an example of industry practice contradicting the standard.
   * The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect
   * (the original describing phrase was "Moved Temporarily"), but popular browsers implemented 302
   * with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307
   * to distinguish between the two behaviours. However, some Web applications and frameworks
   * use the 302 status code as if it were the 303.
   */
  FOUND = 302,

  /**
   * SINCE HTTP/1.1
   * The response to the request can be found under another URI using a GET method.
   * When received in response to a POST (or PUT/DELETE), the client should presume that
   * the server has received the data and should issue a redirect with a separate GET message.
   */
  SEE_OTHER = 303,

  /**
   * Indicates that the resource has not been modified since the version specified by the request headers If-Modified-Since or If-None-Match.
   * In such case, there is no need to retransmit the resource since the client still has a previously-downloaded copy.
   */
  NOT_MODIFIED = 304,

  /**
   * SINCE HTTP/1.1
   * The requested resource is available only through a proxy, the address for which is provided in the response.
   * Many HTTP clients (such as Mozilla and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.
   */
  USE_PROXY = 305,

  /**
   * No longer used. Originally meant "Subsequent requests should use the specified proxy."
   */
  SWITCH_PROXY = 306,

  /**
   * SINCE HTTP/1.1
   * In this case, the request should be repeated with another URI; however, future requests should still use the original URI.
   * In contrast to how 302 was historically implemented, the request method is not allowed to be changed when reissuing the original request.
   * For example, a POST request should be repeated using another POST request.
   */
  TEMPORARY_REDIRECT = 307,

  /**
   * The request and all future requests should be repeated using another URI.
   * 307 and 308 parallel the behaviors of 302 and 301, but do not allow the HTTP method to change.
   * So, for example, submitting a form to a permanently redirected resource may continue smoothly.
   */
  PERMANENT_REDIRECT = 308,

  /**
   * The server cannot or will not process the request due to an apparent client error
   * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
   */
  BAD_REQUEST = 400,

  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
   * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
   * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
   * "unauthenticated",i.e. the user does not have the necessary credentials.
   */
  UNAUTHORIZED = 401,

  /**
   * Reserved for future use. The original intention was that this code might be used as part of some form of digital
   * cash or micro payment scheme, but that has not happened, and this code is not usually used.
   * Google Developers API uses this status if a particular developer has exceeded the daily limit on requests.
   */
  PAYMENT_REQUIRED = 402,

  /**
   * The request was valid, but the server is refusing action.
   * The user might not have the necessary permissions for a resource.
   */
  FORBIDDEN = 403,

  /**
   * The requested resource could not be found but may be available in the future.
   * Subsequent requests by the client are permissible.
   */
  NOT_FOUND = 404,

  /**
   * A request method is not supported for the requested resource;
   * for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.
   */
  NOT_ACCEPTABLE = 406,

  /**
   * The client must first authenticate itself with the proxy.
   */
  PROXY_AUTHENTICATION_REQUIRED = 407,

  /**
   * The server timed out waiting for the request.
   * According to HTTP specifications:
   * "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
   */
  REQUEST_TIMEOUT = 408,

  /**
   * Indicates that the request could not be processed because of conflict in the request,
   * such as an edit conflict between multiple simultaneous updates.
   */
  CONFLICT = 409,

  /**
   * Indicates that the resource requested is no longer available and will not be available again.
   * This should be used when a resource has been intentionally removed and the resource should be purged.
   * Upon receiving a 410 status code, the client should not request the resource in the future.
   * Clients such as search engines should remove the resource from their indices.
   * Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
   */
  GONE = 410,

  /**
   * The request did not specify the length of its content, which is required by the requested resource.
   */
  LENGTH_REQUIRED = 411,

  /**
   * The server does not meet one of the preconditions that the requester put on the request.
   */
  PRECONDITION_FAILED = 412,

  /**
   * The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
   */
  PAYLOAD_TOO_LARGE = 413,

  /**
   * The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request,
   * in which case it should be converted to a POST request.
   * Called "Request-URI Too Long" previously.
   */
  URI_TOO_LONG = 414,

  /**
   * The request entity has a media type which the server or resource does not support.
   * For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
   */
  UNSUPPORTED_MEDIA_TYPE = 415,

  /**
   * The client has asked for a portion of the file (byte serving), but the server cannot supply that portion.
   * For example, if the client asked for a part of the file that lies beyond the end of the file.
   * Called "Requested Range Not Satisfiable" previously.
   */
  RANGE_NOT_SATISFIABLE = 416,

  /**
   * The server cannot meet the requirements of the Expect request-header field.
   */
  EXPECTATION_FAILED = 417,

  /**
   * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol,
   * and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by
   * teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
   */
  I_AM_A_TEAPOT = 418,

  /**
   * The request was directed at a server that is not able to produce a response (for example because a connection reuse).
   */
  MISDIRECTED_REQUEST = 421,

  /**
   * The request was well-formed but was unable to be followed due to semantic errors.
   */
  UNPROCESSABLE_ENTITY = 422,

  /**
   * The resource that is being accessed is locked.
   */
  LOCKED = 423,

  /**
   * The request failed due to failure of a previous request (e.g., a PROPPATCH).
   */
  FAILED_DEPENDENCY = 424,

  /**
   * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
   */
  UPGRADE_REQUIRED = 426,

  /**
   * The origin server requires the request to be conditional.
   * Intended to prevent "the 'lost update' problem, where a client
   * GETs a resource's state, modifies it, and PUTs it back to the server,
   * when meanwhile a third party has modified the state on the server, leading to a conflict."
   */
  PRECONDITION_REQUIRED = 428,

  /**
   * The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
   */
  TOO_MANY_REQUESTS = 429,

  /**
   * The server is unwilling to process the request because either an individual header field,
   * or all the header fields collectively, are too large.
   */
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,

  /**
   * A server operator has received a legal demand to deny access to a resource or to a set of resources
   * that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451.
   */
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  /**
   * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
   * Usually this implies future availability (e.g., a new feature of a web-service API).
   */
  NOT_IMPLEMENTED = 501,

  /**
   * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
   */
  BAD_GATEWAY = 502,

  /**
   * The server is currently unavailable (because it is overloaded or down for maintenance).
   * Generally, this is a temporary state.
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
   */
  GATEWAY_TIMEOUT = 504,

  /**
   * The server does not support the HTTP protocol version used in the request
   */
  HTTP_VERSION_NOT_SUPPORTED = 505,

  /**
   * Transparent content negotiation for the request results in a circular reference.
   */
  VARIANT_ALSO_NEGOTIATES = 506,

  /**
   * The server is unable to store the representation needed to complete the request.
   */
  INSUFFICIENT_STORAGE = 507,

  /**
   * The server detected an infinite loop while processing the request.
   */
  LOOP_DETECTED = 508,

  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  NOT_EXTENDED = 510,

  /**
   * The client needs to authenticate to gain network access.
   * Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used
   * to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
   */
  NETWORK_AUTHENTICATION_REQUIRED = 511
}
