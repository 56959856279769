import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";

export const marin_media_blank_1: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.marin_media_blank_1,
  display_name: "Media / Blank (1)",
  fields: {
    name: name,
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "media_blank_1_cycle_time"
      }
    },
    media: {
      title: "Media",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1080,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "media_blank_1_media"
      }
    }
  },
  folder_type_selector: {
    image_url: "/images/folder_types/marin/marin_media_blank_1.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  slots: 1,
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.MarinDynamicContainer,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: false,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }]
  }
};
