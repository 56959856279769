import { Button, Col, Row } from "antd";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { reset_password } from "../../actions/authActions";
import { checkPasswordValidation } from "../../utils/passwordValidator";
import FieldWrapper from "../form/FieldWrapper";
import AuthPasswordInput from "../form/input/AuthPasswordInput";
import AlertNotification from "../layout/AlertNotification";
import { withRouter } from "../../hooks/withRouter";

interface IPasswordResetProps {
  navigate: any;
  location: any;
  reset_password: Function;
}

interface IPasswordResetState {
  token: string;
  password: string;
  password_error: string;
  password_show_requirement: boolean;
  password_confirmation: string;
  password_confirmation_error: string;
  password_confirmation_show_requirement: boolean;
  captcha?: string;
}

class PasswordReset extends Component<
  IPasswordResetProps,
  IPasswordResetState
> {
  parseUrlParams(str): any {
    let obj = {};
    str
      .substring(1)
      .split("&")
      .forEach((x) => {
        const arr = x.split("=");
        obj[arr[0]] = arr[1];
      });
    return obj as any;
  }

  constructor(props) {
    super(props);
    const parsedUrlParams = this.parseUrlParams(this.props.location.search);
    this.state = {
      token: parsedUrlParams.token ? parsedUrlParams.token : null,
      password: "",
      password_error: "",
      password_show_requirement: false,
      password_confirmation: "",
      password_confirmation_error: "",
      password_confirmation_show_requirement: false,
      captcha: null
    };
  }

  onSuccess = () => {
    this.props.navigate("/login");
    AlertNotification("success", "Success", "Password changed successfully");
  };

  onFail = () => {};

  handleValidationSubmit = () => {
    let isValid = true;

    let my_password_error = "";
    let my_password_confirmation_error = "";
    let password_show_requirement = false;
    let password_confirmation_show_requirement = false;

    const passwordValidation = checkPasswordValidation(this.state.password);
    const passwordConfirmationValidation = checkPasswordValidation(
      this.state.password_confirmation
    );

    if (
      !passwordValidation.isValid ||
      !passwordConfirmationValidation.isValid
    ) {
      isValid = false;
      my_password_error = passwordValidation.isValid
        ? ""
        : "Password does not meet requirements";
      my_password_confirmation_error = passwordConfirmationValidation.isValid
        ? ""
        : "Password does not meet requirements";
      password_show_requirement = !passwordValidation.isValid;
      password_confirmation_show_requirement =
        !passwordConfirmationValidation.isValid;
    }

    if (this.state.password !== this.state.password_confirmation) {
      isValid = false;
      my_password_confirmation_error = "The introduced passwords are different";
    }

    this.setState({
      password_error: my_password_error,
      password_confirmation_error: my_password_confirmation_error,
      password_show_requirement,
      password_confirmation_show_requirement
    });

    return isValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formValid = this.handleValidationSubmit();

    if (formValid) {
      this.props.reset_password(
        this.state.token,
        this.state.password,
        this.state.captcha,
        this.onSuccess,
        this.onFail
      );
    }
  };

  onCaptchaChange = (value) => {
    this.setState({
      captcha: value
    });
  };

  goToLogin = () => {
    this.props.navigate("/login");
  };

  render() {
    return (
      <div className="auth-container">
        <img
          className="auth-container_bg"
          alt="login"
          src="https://pdg-public.s3-us-west-2.amazonaws.com/cms/assets/login_image.jpg"
        />
        <Row>
          <Col
            xs={24}
            sm={24}
            md={11}
            lg={8}
            className="auth-container_form-container"
          >
            <div className="auth-container_form-panel">
              <img
                className="auth-container_logo auth-container_logo-link"
                alt="login"
                src="/images/appello_cms_logo_H_white.png"
                onClick={this.goToLogin}
              />
              <form className="login-form auth-container_form">
                <h2 className="auth-container_h2">Reset Password</h2>
                <h3 className="auth-container_h3">
                  We recommend your password does not include your birth date,
                  name or other obvious information
                </h3>

                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <FieldWrapper
                      key="password-recover-password-key"
                      validationError={this.state.password_error}
                    >
                      <AuthPasswordInput
                        fieldName="password"
                        defaultValue={this.state.password}
                        canUpdate
                        onChange={(_name, value) =>
                          this.setState({ password: value })
                        }
                        placeholder="Password"
                        showPasswordRequirement={
                          this.state.password_show_requirement
                        }
                        passwordRequirementPosition={
                          this.state.password_show_requirement
                            ? "rightBottom"
                            : "right"
                        }
                        onBlur={null}
                        showLabel={false}
                        title={null}
                      />
                    </FieldWrapper>
                  </div>
                </div>
                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <FieldWrapper
                      key="password-recover-confirmation--password-key"
                      validationError={this.state.password_confirmation_error}
                    >
                      <AuthPasswordInput
                        fieldName="password_confirmation"
                        defaultValue={this.state.password_confirmation}
                        canUpdate
                        onChange={(_name, value) =>
                          this.setState({ password_confirmation: value })
                        }
                        placeholder="Re-enter new password"
                        showPasswordRequirement={
                          this.state.password_confirmation_show_requirement
                        }
                        passwordRequirementPosition={
                          this.state.password_confirmation_show_requirement
                            ? "rightTop"
                            : "right"
                        }
                        onBlur={null}
                        showLabel={false}
                        title={null}
                      />
                    </FieldWrapper>
                  </div>
                </div>
                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <ReCAPTCHA
                      className="auth-container_form-input-recaptcha"
                      ref="recaptcha"
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                      onChange={(e) => this.onCaptchaChange(e)}
                    />
                  </div>
                </div>
                <div className="auth-container_buttons">
                  <Button
                    className="auth-container_button"
                    onClick={this.handleSubmit}
                    htmlType="submit"
                  >
                    Change password
                  </Button>
                </div>
              </form>
            </div>
          </Col>
          <Col xs={0} sm={0} md={11} lg={13}></Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  reset_password: (token, password, captcha, onSuccess, onFail) => {
    dispatch(reset_password(token, password, captcha, onSuccess, onFail));
  }
});

export default withRouter(connect(null, mapDispatchToProps)(PasswordReset));
