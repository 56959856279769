const FONT_SIZE_FACTOR = 0.00833333;

export const getFontSizeBasedOnPreviewData = (
  folderType,
  componentName,
  screenSetToFind,
  dimensions
) => {
  try {
    let preview;
    if (Array.isArray(folderType.preview)) {
      preview = folderType.preview.find(
        (p) => p.component_name === componentName
      );
    } else {
      preview = folderType.preview;
    }
    const screenSet = preview.screen_sets?.find(
      (s) => s.name === screenSetToFind
    );

    return screenSet?.orientation === "landscape"
      ? (dimensions.containerWidth * FONT_SIZE_FACTOR) / screenSet?.horizontal
      : (dimensions.containerHeight * FONT_SIZE_FACTOR) / screenSet?.vertical;
  } catch (error) {
    console.error({ error });
    return 16;
  }
};

export function getFontSizeInEm(fontSize) {
  return `${parseInt(String(fontSize).replace(/\D/g,'')) / 16}em`
}

export function percent1080pixel(actual, pixels, type = "height") {
  switch (type) {
    case "width":
      const wPercent = pixels / 1920;
      return wPercent * actual;
    case "height":
      const hPercent = pixels / 1080;
      return hPercent * actual;
    default:
      window.logger.warning("percent1080p type must be width or height", {});
  }
}

export function bestFitOld(ratio, w_max, h_max) {
  var mxW = { width: w_max, height: w_max / ratio };
  var mxH = { width: h_max * ratio, height: h_max };
  if (mxW.w * mxW.h < mxH.w * mxW.h) {
    return mxW;
  } else {
    return mxH;
  }
}

export function bestFit(ratio_w, ratio_h, container_width, container_height) {
  if (ratio_w === 0 || ratio_h === 0) {
    return { width: container_width, height: container_height };
  }

  const width_compare = container_width / ratio_w;
  const height_compare = container_height / ratio_h;

  let width = container_width;
  let height = container_height;

  if (width_compare < height_compare) {
    height = width * (ratio_h / ratio_w);
  } else {
    width = height * (ratio_w / ratio_h);
  }
  return { width: width, height: height };
}
