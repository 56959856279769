import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT, TRANSITION_CLASS } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const new_orleans_1x2_slideshow: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  name: FOLDER_TYPE.new_orleans_1x2_slideshow,
  display_name: "1x2 Slideshow",
  acceptable_folder_types: [FOLDER_TYPE.new_orleans_1x2_slide],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "crossfade",
      options: [
        {
          title: "Fade Out",
          value: "transition-fadeout"
        },
        {
          title: "Crossfade",
          value: TRANSITION_CLASS.CROSSFADE_2S
        },
        {
          title: "Fade Out",
          value: TRANSITION_CLASS.TRANSITION_FADEOUT_2S
        }
      ]
    },
    default_overlay: {
      title: "Default Overlay",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: ["thumbnail", "small", "large"],
      aspect: 0.2815,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.2815
      }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.28125,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.new_orleans_1x2_slide], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.new_orleans_1x2_slide],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.new_orleans_1x2_slide],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.new_orleans_1x2_slide],
        field: "file",
        title: "Image / Video",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.new_orleans_1x2_slide],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
