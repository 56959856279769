import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const springfield_portrait_display: IFolderType = {
  folder_category: FOLDER_CATEGORY.DISPLAY,
  name: FOLDER_TYPE.springfield_portrait_display,
  entry_point: "/springfield_portrait",
  default_dimensions: {
    x: 0,
    y: 0,
    scale_x: 1080,
    scale_y: 1920
  },
  display_name: "Portrait Display",
  acceptable_folder_types: [FOLDER_TYPE.springfield_portrait_presentation],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.SpringfieldPortraitContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  client: {
    component_name: COMPONENT_NAME.SpringfieldPortraitContainer
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  }
};
