import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType
} from "../../../utils/commonDataUtils";
import Slide1911 from "../../standard/Slide1911";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { DEFAULT_SCROLLBAR_COLOR } from "./EugeneConstants";

const FOLDER_MENU_ITEM_TYPE = [
  FOLDER_TYPE.eugene_donor_lists,
  FOLDER_TYPE.eugene_stories,
  FOLDER_TYPE.eugene_stories_menu,
  FOLDER_TYPE.eugene_donor_list_menu,
  FOLDER_TYPE.eugene_template_001,
  FOLDER_TYPE.eugene_template_002,
  FOLDER_TYPE.eugene_template_003,
  FOLDER_TYPE.eugene_template_004
];

const EugeneMenu: React.FunctionComponent<IDisplayComponentProps> = (props) => {
  const [baseFolder, setBaseFolder] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(props.data?.persistent_settings?.data?.show_placeholder || false);
  const [headlineConverter, setHeadlineConverter] = useState(null);
  const [aligment, setAligment] = useState("center");
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();
  const chem = (factor: number) => {
    return (props.containerHeight / 1000) * factor;
  };

  const getBaseFolderMenu = (data) => {
    const myBaseFolder = getBaseFolder(data);
    return myBaseFolder;
  };

  const buildUrl = (folder) => {
    let url = `${props.data.basePath}`;
    console.log('url before', url);
    switch (folder.folder_type) {
      case FOLDER_TYPE.eugene_donor_lists:
        url += `/donor_lists_overview/${folder.id}`;
        break;
      case FOLDER_TYPE.eugene_donor_list:
        url += `/donor_list/${folder.id}`;
        break;
      case FOLDER_TYPE.eugene_donor_list_menu:
        const childrenFolderFolders = props.data.folder__folders.filter(
          (x) => x.parent_folder === folder.id
        );

        if (childrenFolderFolders.length > 1) {
          url += `/donor_lists_overview/${folder.id}`;
        } else {
          url += `/donor_list_menu/${folder.id}`;
        }
        break;
      case FOLDER_TYPE.eugene_stories:
        url += `/stories/${folder.id}`;
        break;
      case FOLDER_TYPE.eugene_stories_menu:
        url += `/stories_gallery/${folder.id}`;
        break;
      case FOLDER_TYPE.eugene_template_001:
      case FOLDER_TYPE.eugene_template_002:
      case FOLDER_TYPE.eugene_template_003:
      case FOLDER_TYPE.eugene_template_004:
        url += `/story/${folder.id}`;
        break;
    }
    console.log('url', url);
    return url;
  };

  const findFolder = (folderId) => {
    return props.data.folders.find((f) => f.id === folderId);
  };

  const getMenuItems = () => {
    let items = [];

    items = props.data.folder__folders
      .filter((ff) => ff.parent_folder == props.data.base_folder)
      .sort((a, b) => a.rank - b.rank)
      .map((fff) => {
        const folderFound = findFolder(fff.child_folder);
        return folderFound;
      })
      .filter((f) => FOLDER_MENU_ITEM_TYPE.includes(f.folder_type))
      .map((ff) => {
        return {
          id: ff.id,
          name: ff.name,
          folder_type: ff.folder_type,
          url: buildUrl(ff)
        };
      });
    return items;
  };

  useEffect(() => {
    const myBaseFolder = getBaseFolderMenu(props.data);
    setBaseFolder(myBaseFolder);
    const myMenuItems = getMenuItems();
    setMenuItems(myMenuItems);
  }, []);

  useEffect(() => {
    const myBaseFolderType = getBaseFolderType(
      props.data,
      get(baseFolder, "folder_type", {})
    );
    setBaseFolderType(myBaseFolderType);

    const color = get(baseFolder, "fields.color_overlay", "transparent");
    setBackgroundColor(color);

    const myHeadlineConverter = new QuillDeltaToHtmlConverter(
      get(baseFolder, "fields.heading.ops", []),
      { multiLineParagraph: false }
    );
    setHeadlineConverter(myHeadlineConverter);

    const aligment = get(baseFolder, "fields.aligment", "center");
    setAligment(aligment);
  }, [baseFolder]);

  useEffect(() => {
    if (baseFolderType) {
    }
  }, [baseFolderType]);

  const getPlaceholder = (placeholder) => {
    if (!showPlaceholder) {
      return "";
    }

    switch (placeholder) {
      case "heading":
        return `<p><strong class=\"ql-font-robotoslabregular ql-size-40em\">${get(
          baseFolderType,
          "fields[heading].title",
          placeholder
        )}</strong></p>`;
    }
  };

  const isTextRichEmpty = (text) => {
    if (text && text !== "<p><br/></p>") {
      return false;
    } else {
      return true;
    }
  };

  const getHeaderLineOrPlaceholder = (convertor, type) => {
    let html = convertor ? convertor.convert() : "";

    if (
      isTextRichEmpty(html) &&
      props.mode === COMPONENT_MODE_CONTEXT.PREVIEW
    ) {
      html = getPlaceholder(type);
    }

    return html;
  };

  const heading = getHeaderLineOrPlaceholder(headlineConverter, "heading");

  const onClickNavigate = (url) => () => {
    console.log("onclick navigate story push eugenemenu");
    // navigate(`${props.basePath}theme/eugene/search`);
    navigate(url);
  };

  return (
    <>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: -2
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "cover"
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColor,
          zIndex: -1
        }}
      />
      <div
        style={{
          display: "flex",
          position: "relative"
        }}
      >
        <div
          style={{
            height: "100%",
            paddingTop: (9.259259259 / 100) * props.containerHeight,
            paddingBottom: 0.05710260932 * props.containerHeight,
            paddingLeft: 0.09802410933 * props.containerWidth,
            paddingRight: 0.09802410933 * props.containerWidth
          }}
        >
          {/* MENU container */}
          <div
            style={{
              height: "100%",
              color: "#707070",
              overflowY: "hidden"
            }}
          >
            <div
              className="eugene-menu_heading"
              style={{
                paddingLeft: props.containerWidth * 0.15,
                paddingRight: props.containerWidth * 0.15,
                width: props.containerWidth * 0.8
              }}
            >
              {/* Headline */}
              <div className="ql-editor-preview">
                <div
                  className="ql-editor"
                  style={{
                    fontSize: (props.containerHeight / 1000) * 1.2,
                    padding: 0,
                    paddingRight: props.containerWidth * 0.04,
                    paddingBottom: props.containerHeight * 0.08
                  }}
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              </div>
            </div>
            <div
              className="eugene-menu_items"
              style={{
                paddingLeft: props.containerWidth * 0.15,
                paddingRight: props.containerWidth * 0.15,
                width: props.containerWidth * 0.8
              }}
            >
              <ScrollableFixContainer2103
                containerHeight={props.containerHeight * 0.7}
                containerWidth={props.containerWidth / 2}
                showScrollbar={false}
                barColor={DEFAULT_SCROLLBAR_COLOR}
              >
                <div>
                  {menuItems.map((mi, index) => {
                    return (
                      <div
                        key={`eugene-menu_item-${index}`}
                        onClick={onClickNavigate(mi.url)}
                        style={{
                          cursor: "pointer",
                          fontSize: chem(
                            get(
                              baseFolder,
                              "fields.menu_items.fontSize",
                              (props.containerHeight / 10) * 0.45
                            )
                          ),
                          padding: 0,
                          paddingBottom: props.containerHeight * 0.04,
                          textAlign: get(
                            baseFolder,
                            "fields.menu_items.alignment",
                            "center"
                          ),
                          fontFamily: get(
                            baseFolder,
                            "fields.menu_items.font",
                            "Roboto Slab Bold"
                          ),
                          color: get(
                            baseFolder,
                            "fields.menu_items.color",
                            "#FFFFFF"
                          ),
                          lineHeight: get(
                            baseFolder,
                            "fields.menu_items.lineHeight",
                            1
                          ),
                          fontWeight: get(
                            baseFolder,
                            "fields.menu_items.bold",
                            false
                          ) ? "bold" : "normal",
                          textDecoration: get(
                            baseFolder,
                            "fields.menu_items.underline",
                            false
                          ) ? "underline" : "normal",
                          fontStyle: get(
                            baseFolder,
                            "fields.menu_items.italic",
                            false
                          ) ? "italic" : "normal",
                        }}
                      >
                        {mi.name}
                      </div>
                    );
                  })}
                </div>
              </ScrollableFixContainer2103>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EugeneMenu;
