import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { springfield_portrait_template_001 } from "./springfield_portrait_template_001";
import { springfield_portrait_template_002 } from "./springfield_portrait_template_002";
import { springfield_portrait_template_003 } from "./springfield_portrait_template_003";
import { springfield_portrait_template_004 } from "./springfield_portrait_template_004";
import { springfield_portrait_presentation } from "./springfield_portrait_presentation";
import { springfield_portrait_stories } from "./springfield_portrait_stories";
import { springfield_portrait_donor } from "./springfield_portrait_donor";
import { springfield_portrait_donor_list } from "./springfield_portrait_donor_list";
import { springfield_portrait_category_with_giving_level } from "./springfield_portrait_category_with_giving_level";
import { springfield_portrait_display } from "./springfield_portrait_display";
import { springfield_portrait_root } from "./springfield_portrait_root";
import { springfield_portrait_donor_lists } from "./springfield_portrait_donor_lists";
import { springfield_portrait_content } from "./springfield_portrait_content";
import { springfield_portrait_content_group_folder } from "./springfield_portrait_content_group_folder";
import { springfield_portrait_presentations } from "./springfield_portrait_presentations";
import { springfield_portrait_displays } from "./springfield_portrait_displays";

export const springfield_portrait_types: { [key: string]: IFolderType } = {
  springfield_portrait_template_001,
  springfield_portrait_template_002,
  springfield_portrait_template_003,
  springfield_portrait_template_004,
  springfield_portrait_donor,
  springfield_portrait_donor_list,
  springfield_portrait_category_with_giving_level,
  springfield_portrait_donor_lists,
  springfield_portrait_root,
  springfield_portrait_display,
  springfield_portrait_presentation,
  springfield_portrait_stories,
  springfield_portrait_content,
  springfield_portrait_presentations,
  springfield_portrait_displays,
  springfield_portrait_content_group_folder
};
