export default {
  field_groups: {
    default: {
      title: "Company Status:",
      default_expanded: true,
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
    active: {
      type: "bool",
      title: "Active",
      validation: { rules: [] },
      default: true,
    },
    sns_alerts_enabled: {
      type: "bool",
      title: "Enable SNS Alerts",
      validation: { rules: [] },
      default: false,
    },
    sns_alerts_email_address: {
      type: "text",
      title: "SNS Alerts Email Address",
      validation: { rules: [] },
      isVisible: (value) => value.sns_alerts_enabled,
      default: null,
    },
  },
};
