import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const SwitchInput = (props) => {
  const checkedChildren = props.options[0].title;
  const unCheckedChildren = props.options[1].title;
  return (
  <React.Fragment>
    {props.showLabel ? <span><label className="form-input-label">{props.title}</label><br/></span> : null}
    <Switch
      disabled={!props.canUpdate}
      defaultChecked={props.defaultValue}
      className="switch-input"
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      onChange={(ev) => props.onChange(props.fieldName, ev )}
    />
  </React.Fragment>
)};

SwitchInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  showLabel: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default SwitchInput;
