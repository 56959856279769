import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Spin } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getFileType } from "../../../../shared/utils/fileUtils";
import Spaner from "../../../utils/Spaner";
import {
  convertObjectIntoArray,
  getAcceptableFiles, getMd5FromFile,
  getMd5FromFiles,
  removeEXIFData
} from "../DynamicFileInput/FileInputService";
import SelectExistingResource from "../SelectExistingResource";
import {useDispatch} from "react-redux";
import AlertNotification from "../../../layout/AlertNotification";

/**
 */
export const STATE_START = "START";

/**
 * this state mean it is adding more content from the content manager
 */
export const STATE_ADD_MORE = "ADD_MORE";

/**
 * this state mean add only one element
 */
export const STATE_ADD_ONLY_ONE = "STATE_ADD_ONLY_ONE";

/**
 * this state replace an image
 */
export const STATE_REPLACE = "STATE_REPLACE";

export default function ContentUploader({
  acceptable,
  newMode,
  resources,
  user,
  aws_instance,
  onChange,
  onAdd,
  onClose,
  goBack,
  folders,
  resource__folders,
  client_options,
  setClientOptions,
  browseContentLibrary,
  cancelButton
}) {

  const [calculatingMd5, _setCalculatingMd5] = useState(false);
  const calculatingMd5Ref = useRef(calculatingMd5);
  const setCalculatingMd5 = (data) => {
    calculatingMd5Ref.current = data;
    _setCalculatingMd5(data);
  };
  const [mode, setMode] = useState(newMode || STATE_START);
  const [resourcesArray, _setResourcesArray] = useState([]);

  const dispatch = useDispatch()

  const resourcesArrayRef = useRef(resourcesArray);
  const setResourcesArray = (data) => {
    resourcesArrayRef.current = data;
    _setResourcesArray(data);
  };
  const [resourceAlreadyExistVisible, setResourceAlreadyExistVisible] =
    useState(false);
  const [existingResourcesArrayForAdd, setExistingResourcesArrayForAdd] =
    useState([]);
  const [
    existingResourcesArrayForReplace,
    setExistingResourcesArrayForReplace
  ] = useState(null);
  const [replaceFile, setReplaceFile] = useState(null);
  const [addFiles, setAddFiles] = useState([]);

  useEffect(() => {
    if (newMode) {
      setMode(newMode);
    }
  }, [newMode]);

  useEffect(() => {
    const resourcesArr = convertObjectIntoArray(resources);
    setResourcesArray(resourcesArr);
  }, [resources]);

  const isInputMultiple = () => {
    return mode === STATE_ADD_MORE || mode === STATE_START;
  };

  const isReplaceImage = () => {
    return mode === STATE_REPLACE || mode === STATE_ADD_ONLY_ONE;
  };

  const existMd5InResources = async (md5value) => {
    const company = user.company;
    const result = await resourcesArrayRef.current.find(
      (r) => r.md5 === md5value && r.company === company
    );
    return result;
  };

  const existMd5sInResources = async (md5s) => {
    const company = user.company;
    const result = await resourcesArrayRef.current.filter(
      (r) => md5s.includes(r.md5) && r.company === company
    );
    return result;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      acceptedFiles = await removeEXIFData(acceptedFiles)
    } catch (e) {
      AlertNotification(
        "error",
        "Error",
        "The file you tried to upload is not compatible"
      );
      return
    }
    // Do something with the files
    if (isInputMultiple()) {
      setCalculatingMd5(true);
      const md5s = await getMd5FromFiles(acceptedFiles);
      setCalculatingMd5(false);
      const existResources = await existMd5sInResources(md5s);
      if (existResources && existResources.length > 0) {
        // open modal with options
        openModalExistingResourcesForAdd(existResources, acceptedFiles);
      } else {
        // normal add files
        await onAdd(acceptedFiles);
      }
    } else if (
      isReplaceImage() &&
      acceptedFiles &&
      acceptedFiles.length !== 0
    ) {
      setCalculatingMd5(true);
      const md5 = await getMd5FromFile(acceptedFiles[0]);
      setCalculatingMd5(false);
      const exist = await existMd5InResources(md5);
      if (exist) {
        // open modal with options
        openModalExistingResourcesForReplace(exist, acceptedFiles[0]);
      } else {
        await onChange(acceptedFiles[0]);
      }
    }
  }, []);

  let _selectExistingResource = null;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true
  });

  const openModalExistingResourcesForReplace = (resource, replaceFile) => {
    setReplaceFile(replaceFile);
    setExistingResourcesArrayForReplace(resource);
    openModalExistingResources();
  };

  const openModalExistingResourcesForAdd = (resources, replaceFiles) => {
    setAddFiles(replaceFiles);
    setExistingResourcesArrayForAdd(resources);
    openModalExistingResources();
  };

  const openModalExistingResources = (resources) => {
    setResourceAlreadyExistVisible(true);
  };

  const closeModalExistingResources = (resources) => {
    setReplaceFile(null);
    setExistingResourcesArrayForReplace(null);
    setExistingResourcesArrayForAdd([]);
    setAddFiles([]);
    setResourceAlreadyExistVisible(false);
  };

  const onCancel = () => {
    if (mode === STATE_START || mode === STATE_ADD_ONLY_ONE) {
      onClose();
    } else {
      goBack();
    }
  };

  const openGallery = () => {
    if (!client_options.resource_modal_tree_selected_id) {
      setClientOptions({ resource_modal_tree_selected_id: `company-${user.company}` });
    }


    if (_selectExistingResource) {
      _selectExistingResource.openGallery();
    }
  };

  const closeGallery = () => {
    if (_selectExistingResource) {
      _selectExistingResource.closeGallery();
    }
  };

  const selectExistingResource = async (previewResource) => {
    await selectExistingResourceFromResources(previewResource);
    closeGallery();
  };

  const selectExistingResourceFromResources = async (previewResource) => {
    const resourceWithUrls = getResourceFromTree(previewResource);

    if (
      mode === STATE_START ||
      mode === STATE_ADD_MORE ||
      mode === STATE_ADD_ONLY_ONE
    ) {
      await onAdd(
        [{ ...resourceWithUrls, selected_from_tree: true }],
        "existing"
      );
    } else {
      await onChange(
        { ...resourceWithUrls, selected_from_tree: true },
        "existing"
      );
    }
  };

  const getS3Url = (resourceName, companyId) => {
    const s3 = new aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2"
    });
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${companyId}/resource`,
      Key: resourceName
    };
    return s3.getSignedUrl("getObject", params);
  };

  const getResourceFromTree = (resource) => {
    let thumbUrl = null;
    let url = null;

    if (!resource) {
      return null;
    }
    const fileType = getFileType(resource.name);
    let previewName = null;
    let resourceUrl = null;
    if (fileType === "video") {
      const videoPresets = _.get(resource, "contents.video_presets", null);
      if (videoPresets && videoPresets[360]) {
        // resourceName = `resouce_${resource.id}_360.${resource.extension}`;
      } else {
        // resourceName = resource.name;
      }
      previewName = `${resource.id}.${resource.extension}`;
    } else if (fileType === "image") {
      // const imagePresets = _.get(resource, 'contents.image_presets', null);
      // resourceName = resource.name;
      resourceUrl = getS3Url(resource.name, resource.company);
      const thumbName = `${resource.id}_preview.${resource.extension}`;
      thumbUrl = getS3Url(thumbName, resource.company);
      previewName = `${resource.id}.${resource.extension}`;
    } else {
      // resourceName = resource.name;
      previewName = `${resource.id}.${resource.extension}`;
    }
    url = getS3Url(previewName, resource.company);

    return {
      ...resource,
      resourceUrl,
      url,
      thumbUrl
    };
  };

  const acceptableFiles = getAcceptableFiles(acceptable);
  const multipleImages = isInputMultiple();
  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <>
      <Modal
        visible={resourceAlreadyExistVisible}
        footer={[
          <Button key="1" onClick={closeModalExistingResources}>
            CANCEL
          </Button>,
          <Button
            key="2"
            onClick={async () => {
              if (replaceFile) {
                await onChange(replaceFile);
              } else if (addFiles) {
                await onAdd(addFiles);
              }
              closeModalExistingResources();
            }}
          >
            USE THIS FILE ANYWAY
          </Button>,
          <Button
            key="3"
            type="primary"
            onClick={async () => {
              if (existingResourcesArrayForReplace) {
                const fullResource = getResourceFromTree(
                  existingResourcesArrayForReplace
                );
                await selectExistingResourceFromResources(fullResource);
              } else if (existingResourcesArrayForAdd) {
                for (let i = 0; i < existingResourcesArrayForAdd.length; i++) {
                  const resourceForAdd = existingResourcesArrayForAdd[i];
                  const fullResource = getResourceFromTree(resourceForAdd);
                  await selectExistingResourceFromResources(fullResource);
                  break;
                }
              }
              closeModalExistingResources();
            }}
          >
            USE EXISTING
          </Button>
        ]}
        width={720}
        destroyOnClose
      >
        <div className="unsaved-changes-modal_header">Duplicated file</div>
        <div className="text-center">{`The File you're trying to upload already exists in our database, what would you like to do?`}</div>
      </Modal>
      <section className="content-uploader">
        <SelectExistingResource
          filters={["image", "video"]}
          onOkay={selectExistingResource}
          ref={(ref) => (_selectExistingResource = ref)}
          folders={folders}
          resource__folders={resource__folders}
        />

        <div
          {...getRootProps({ className: "dropzone content-uploader_dropzone" })}
        >

          {calculatingMd5Ref.current ? (
            <Spin indicator={antIcon} size="large" />
          ) : (
            <>
              <div className="content-uploader_dnd">
                <div className="content-uploader_image">
                  <FontAwesomeIcon size="5x" icon="image" />
                </div>
                <div className="content-uploader_h1">
                  Drag and drop files here to upload
                </div>
              </div>
              <div className="content-uploader_separator">
                <div className="content-uploader_separator-line" />
                <div className="content-uploader_separator-text"> Or</div>
                <div className="content-uploader_separator-line" />
              </div>
              <div className="content-uploader_buttons">
                <input
                  type="file"
                  id="files-content-uploader"
                  {...getInputProps()}
                  multiple={multipleImages}
                  accept={acceptableFiles}
                />
                <div className="content-uploader_buttons-main">
                  <label
                    htmlFor="files-content-uploader"
                    className="ant-btn ant-btn-primary"
                  >
                    <span>{`SELECT ${multipleImages ? "FILES" : "FILE"
                      } TO UPLOAD`}</span>
                  </label>
                  {browseContentLibrary && (
                    <Button onClick={openGallery} type="primary">
                      <Spaner width={"sm"} />
                      BROWSE CONTENT LIBRARY
                    </Button>
                  )}
                </div>
                {cancelButton && (
                  <div className="content-uploader_buttons-cancel">
                    <Button onClick={onCancel}>CANCEL</Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
