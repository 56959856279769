import React, {useEffect, useState} from "react";
import {IDisplayComponentProps} from "../../../interfaces/display-component-props.interface";
import {getBaseFolder, getBaseFolderType} from "../../../utils/commonDataUtils";
import Slide1911 from "../../standard/Slide1911";
import get from "lodash/get";

const EugeneTemplate001 = (props: IDisplayComponentProps) => {
    const [baseFolder, setBaseFolder] = useState(null);
    const [showPlaceholder, setShowPlaceholder] = useState(get(props.data.persistent_settings, 'show_placeholder', false));
    const [baseFolderType, setBaseFolderType] = useState(null);
    const [imageFieldType, setImageFieldType] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState(null);

    useEffect(() => {
        const myBaseFolder = getBaseFolder(props.data);
        setBaseFolder(myBaseFolder)
    }, [props.data])

    useEffect(() => {
        const myBaseFolderType = getBaseFolderType(props.data, get(baseFolder, 'folder_type', {}));
        const color = get(baseFolder, 'fields.color_overlay', 'transparent')
        setBackgroundColor(color);

        setBaseFolderType(myBaseFolderType)
    }, [baseFolder])

    useEffect(() => {
        if (baseFolderType) {
            const fieldTypeImage = get(baseFolderType, 'fields[background]', null)
            setImageFieldType(fieldTypeImage)
        }
    }, [baseFolderType])

    return (
        <div style={{width: props.containerWidth, height: props.containerHeight}}>
            <Slide1911
                aws={props.aws}
                aws_bucket={props.aws_bucket}
                mode={props.mode}
                containerWidth={props.containerWidth}
                containerHeight={props.containerHeight}
                handleEndOfPlay={props.handleEndOfPlay}
                data={{
                    ...props.data,
                    slotNames: ['background'],
                    placeholder: "Image",
                    fieldType: imageFieldType,
                    showPlaceholder: showPlaceholder,
                    positionRelative: true,
                }}
                webview_signedurls={props.webview_signedurls}
            />
            {/* Background Overlay */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: props.containerWidth,
                    height: props.containerHeight,
                    backgroundColor: backgroundColor,
                }}
            />
        </div>
    );
};

export default EugeneTemplate001;
