import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { wysiwyg } from "../standardized_fields";
import { glenwoodFonts, quillFontSizes } from "./glenwood_settings";

export const glenwood_story_group: IFolderType = {
  folder_category: FOLDER_CATEGORY.GROUP,
  name: FOLDER_TYPE.glenwood_story_group,
  display_name: "Stories Menu",
  acceptable_folder_types: [FOLDER_TYPE.glenwood_stories],
  tabs: {
    general: {
      title: "General"
    },
    gallery: {
      title: "Gallery"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    gallery: {
      title: "Gallery",
      default_expanded: true,
      order: 2,
      accordion: false,
      tab: "gallery"
    },
    menu_title: {
      title: "Menu Title",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "gallery"
    },
    menu_settings: {
      title: "Menu Settings",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "gallery"
    },
    horizontal_rule: {
      title: "Horizontal Rule",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "gallery"
    },
    background_accordion: {
      title: "Background",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "gallery"
    }
  },
  fields: {
    title: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    menu_title: {
      title: "Menu Heading",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "ops", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
            size: "40px"
          },
          ops: [{ insert: "Menu" }],
          dark_mode: true
        },
        textarea: "Menu"
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "menu_title"
    },
    menu_title_margin_top: {
      title: "Menu Title Margin Top",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_title"
    },
    menu_title_margin_left: {
      title: "Menu Title Margin Left",
      tooltip: "Unit is % of container width",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_title"
    },
    menu_title_margin_bottom: {
      title: "Menu Title Margin Bottom",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      default: 9,
      field_group: "menu_title"
    },
    menu_title_width: {
      title: "Menu Title Width",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      default: 100,
      field_group: "menu_title"
    },
    menu_container_margin_top: {
      title: "Menu Container Margin Top",
      type: "integer",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_margin_left: {
      title: "Menu Container Margin Left",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_height: {
      title: "Menu Container Height",
      type: "integer",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 50,
      field_group: "menu_settings"
    },
    menu_container_width: {
      title: "Menu Container Width",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 80,
      field_group: "menu_settings"
    },
    menu_item_vertical_separation: {
      title: "Menu Item Vertical Separation",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 9,
      field_group: "menu_settings"
    },
    show_subhead: {
      type: "bool",
      title: "Show Item Subhead",
      validation: { rules: [] },
      default: true,
      field_group: "menu_settings"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "background_accordion",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "background_accordion",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    accent_color: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "background_accordion",
      isVisible: () => false,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Color",
      default: "#FFFFFF",
      field_group: "horizontal_rule"
    },
    horizontal_rule_weight: {
      type: "number",
      title: "Line Weight",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0.01, max: 4 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 4,
      field_group: "horizontal_rule"
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true,
      field_group: "general"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  client: {
    component_name: COMPONENT_NAME.GlenwoodStoryGroup
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: true
};
