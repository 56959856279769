import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const new_orleans_donor: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.new_orleans_donor,
  display_name: "Donor",
  fields: {
    name: {
      type: "textarea",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: "",
      read_only: true,
      show_char_count: false,
      settings: {
        minRows: 2,
        maxRows: 6,
        crossButton: true
      }
    },
    sorting_order: {
      type: "text",
      title: "Sorting Order",
      show_char_count: false,
      validation: { rules: [{ name: "isString", min: 0, max: 75 }] },
      default: ""
    },
    preview: {
      aspect_ratio: 1.78,
      type: "image",
      path: "/example_donor_preview.png"
    }
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name", "sorting_order"]
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.NewOrleansDonor,
    type: PREVIEW_TYPE.COMPONENT
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_bios
    },
    closed: {
      ...tree_icon_types.donor_bios
    }
  }
};
