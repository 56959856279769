import React, { useEffect, useState } from "react";
import { bestFit } from "../../utils/screenUtils";
import get from "lodash/get";
import {
  SYSTEM_SETTING_PLACEHOLDER_BACKGROUND_COLOR_KEY,
  SYSTEM_SETTING_PLACEHOLDER_MULTIPLE_IMAGE_KEY,
  SYSTEM_SETTING_PLACEHOLDER_SINGLE_IMAGE_KEY,
} from "../../../shared-global/constants/placeholderKeys";

interface ISlide1911PlaceholderProp {
  containerHeight: number;
  containerWidth: number;
  placeholder: string;
  background: boolean;
  fieldType: any;
  systemSettings: any;
  widthScreen?: boolean;
  customStyleImage?: any;
}

const BACKGROUND_COLOR = "#DEDDDD";

const Slide1911Placeholder: React.FC<ISlide1911PlaceholderProp> = (props) => {
  const [widthContainer, setWidthContainer] = useState(0);
  const [heightContainer, setHeightContainer] = useState(0);
  const [widthImage, setWidthImage] = useState(0);
  const [heightImage, setHeightImage] = useState(0);
  const [placeholderUrl, setPlaceholderUrl] = useState(null);
  const [singleImageUrl, setSingleImageUrl] = useState("");
  const [multipleImageUrl, setMultipleImageUrl] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(null);

  const defaultStyle = {
    position: "relative",
    background: backgroundColor,
    height: heightContainer,
    width: widthContainer,
    top: 0,
    left: 0,
    margin: "0 auto"
  }

  const getBackgroundColor = (system_settings) => {
    return get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_BACKGROUND_COLOR_KEY}].value`,
      BACKGROUND_COLOR
    );
  };

  const getPlaceholderSingleImage = (system_settings) => {
    return get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_SINGLE_IMAGE_KEY}].value`,
      ""
    );
  };

  const getPlaceholderMultipleImage = (system_settings) => {
    return get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_MULTIPLE_IMAGE_KEY}].value`,
      ""
    );
  };

  useEffect(() => {
    const containerHeight = props.containerHeight || window.innerHeight;
    const containerWidth = props.containerWidth || window.innerWidth;

    const dims = bestFit(
      containerWidth,
      containerHeight,
      containerWidth,
      containerHeight
    );

    setWidthContainer(dims.width);
    setHeightContainer(dims.height);
    setWidthImage(dims.width / 3);
    setHeightImage(dims.height / 3);
  }, [
    props.containerHeight,
    props.containerWidth,
    props.placeholder,
    props.background,
  ]);

  useEffect(() => {
    if (props.fieldType) {
      const isMultiple =
        props.fieldType && props.fieldType.limit && props.fieldType.limit > 1;
      setPlaceholderUrl(isMultiple ? multipleImageUrl : singleImageUrl);
    }
  }, [props.fieldType, singleImageUrl, multipleImageUrl]);

  useEffect(() => {
    if (props.systemSettings) {
      const system_settings = Object.keys(props.systemSettings).reduce(
        (acc, curr) => {
          const obj = props.systemSettings[curr];
          acc[obj.key] = obj;

          return acc;
        },
        {}
      );

      setBackgroundColor(getBackgroundColor(system_settings));
      setSingleImageUrl(getPlaceholderSingleImage(system_settings));
      setMultipleImageUrl(getPlaceholderMultipleImage(system_settings));
      // setSystemSettingsFormatted(system_settings);
    }
  }, [props.systemSettings]);

  const style = props.customStyleImage ? {
    ...defaultStyle,
    ...props.customStyleImage
  } : defaultStyle

  return (
    <div
      style={style}
    >
      <div
        style={{
          position: "absolute",
          backgroundImage: `url(${placeholderUrl})`,
          backgroundSize: props?.widthScreen ? "cover" : "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: heightImage,
          width: widthImage,
          left: "50%",
          marginLeft: -widthImage / 2,
          top: "50%",
          marginTop: -heightImage / 2,
        }}
      ></div>
    </div>
  );
};

export default Slide1911Placeholder;
