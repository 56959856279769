import React from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import Slide1911 from "../../standard/Slide1911";

const GlenwoodSlide: React.FunctionComponent<IDisplayComponentProps> = (props: IDisplayComponentProps) => {
  return (
    <div
      style={{
        position: "absolute",
        height: props.containerWidth,
        width: props.containerHeight
      }}
    >
      <Slide1911 {...props} />
    </div>
  );
};

export default GlenwoodSlide;
