import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const jasper_slides: IFolderType = {
  name: FOLDER_TYPE.jasper_slides,
  display_name: "Slides",
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  acceptable_folder_types: [FOLDER_TYPE.jasper_slide],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name"]
  }
};
