import { takeLatest, takeEvery, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import folderSaga from "./folderSaga";
import utilSaga from "./utilSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import companySaga from "./companySaga";
import projectSaga from "./projectSaga";
import resourceSaga from "./resourceSaga";
import auditLogSaga from "./auditLogSaga";
import locationSaga from "./locationSaga";
import backupSaga from "./backupSaga";
import searchSaga from "./searchSaga";
import notificationSaga from "./notificationSaga";
import tagSaga from "./tagSaga";
import uriAccessSaga from "./uriAccessSaga";
import emailSubscriptionSaga from "./emailSubscriptionSaga";
import { socketInitFlow } from "./socketSaga";
import fontGroupSaga from "./fontGroupSaga";

export default function* rootSaga() {
  // Util
  yield takeLatest(actionTypes.BREADCRUMB_SET, utilSaga.breadcrumbSet);
  yield takeLatest(
    actionTypes.BROADCAST_NEW_MOBILE_HEADER,
    utilSaga.broadcastNewMobileHeader
  );
  yield takeLatest(actionTypes.INITIALIZE_S3_CACHE, utilSaga.initializeS3Cache);

  // Folder
  yield takeLatest(
    actionTypes.FETCH_PROJECT_FOLDERS,
    folderSaga.fetchProjectFolders
  );
  yield takeLatest(actionTypes.MOVE_FOLDER, folderSaga.moveFolder);
  yield takeEvery(actionTypes.UPDATE_FOLDER, folderSaga.updateFolder);
  yield takeEvery(
    actionTypes.UPDATE_RESOURCE_TREE_FOLDER,
    folderSaga.updateResourceTreeFolder
  );
  yield takeEvery(
    actionTypes.UPDATE_UPLOADED_RESOURCES,
    folderSaga.updateUploadedResources
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_FOLDER_CSV,
    folderSaga.downloadFolderCsv
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_HARDWARE_INFORMATION_CSV,
    folderSaga.downloadHardwareInformationCsv
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_HARDWARE_INFORMATION_TXT,
    folderSaga.downloadHardwareInformationTxt
  );
  yield takeEvery(actionTypes.UPLOAD_FOLDER_CSV, folderSaga.uploadFolderCsv);
  yield takeLatest(actionTypes.INSTANCE_FOLDER, folderSaga.instanceFolder);
  yield takeLatest(actionTypes.PUBLISH_FOLDER, folderSaga.publishFolder);
  yield takeLatest(
    actionTypes.DELETE_BULK_FOLDERS,
    folderSaga.deleteBulkFolders
  );
  yield takeLatest(
    actionTypes.MAKE_FOLDER_PULLABLE,
    folderSaga.makeFolderPullable
  );
  yield takeLatest(actionTypes.GET_FOLDER_INFO, folderSaga.getFolderInfo);
  yield takeLatest(
    actionTypes.DELETE_DISPLAY_IDENTIFIER,
    folderSaga.deleteDisplayIdentifier
  );
  yield takeLatest(actionTypes.GET_DISPLAY_DATA, folderSaga.get_display_info);
  yield takeLatest(actionTypes.GET_FOLDER_STATUS, folderSaga.get_folder_status);
  yield takeLatest(actionTypes.ADD_BULK_FOLDERS, folderSaga.addBulkFolders);
  yield takeLatest(
    actionTypes.UPDATE_BULK_FOLDERS,
    folderSaga.updateBulkFolders
  );
  yield takeLatest(
    actionTypes.UPDATE_FOLDER_CONTENTS,
    folderSaga.update_folder_contents
  );
  yield takeLatest(
    actionTypes.FETCH_COMPANY_ROOT_FOLDERS,
    folderSaga.fetchCompanyRootFolders
  );
  // yield takeLatest(actionTypes.UPDATE_SELECTED_FOLDER, folderSaga.updateSelectedFolder);
  yield takeLatest(
    actionTypes.SET_UPDATE_FOLDER_TOUCH,
    folderSaga.set_update_folder_touch
  );
  yield takeLatest(
    actionTypes.SET_UPDATE_LIST_VIEW_TOUCH,
    folderSaga.set_update_list_view_touch
  );
  yield takeLatest(
    actionTypes.URI_ACCESS_GET_DATA,
    folderSaga.uriAccessGetData
  );
  yield takeLatest(
    actionTypes.UPDATE_DISPLAY_HARDWARE_INFO,
    folderSaga.updateDisplayHardwareInfo
  );
  yield takeEvery(
    actionTypes.SET_FOLDER_TREE_INHERITABLE_SETTINGS,
    folderSaga.setFolderTreeInheritableSettings
  );
  yield takeLatest(
    actionTypes.PUSH_DOWN_INHERITABLE_FIELDS,
    folderSaga.pushDownInheritableFields
  );
  yield takeLatest(
    actionTypes.UPDATE_FOLDER_ALONE,
    folderSaga.updateFolderAlone
  );
  yield takeLatest(
    actionTypes.CHECK_UPDATE_FOLDER_ALONE,
    folderSaga.checkUpdateFolderAlone
  );

  yield takeLatest(
    actionTypes.UPSERT_FOLDER_AR_MODEL,
    folderSaga.upsertFolderArModel
  );
  yield takeLatest(
    actionTypes.GET_FOLDER_AR_MODEL,
    folderSaga.getFolderArModel
  );

  // Font Groups
  yield takeLatest(actionTypes.ADD_FONT_GROUP, fontGroupSaga.addFontGroup);
  yield takeLatest(
    actionTypes.UPDATE_FONT_GROUP,
    fontGroupSaga.updateFontGroup
  );
  yield takeLatest(
    actionTypes.REMOVE_FONT_GROUP,
    fontGroupSaga.removeFontGroup
  );

  // Search
  yield takeLatest(actionTypes.SEARCH_MAIN, searchSaga.searchMain);
  yield takeLatest(actionTypes.SEARCH_TAG, searchSaga.searchTag);

  yield takeLatest(actionTypes.ADD_FOLDER, folderSaga.addFolder);
  yield takeLatest(actionTypes.CLONE_FOLDER, folderSaga.cloneFolder);
  yield takeLatest(actionTypes.REMOVE_FOLDER, folderSaga.removeFolder);
  yield takeLatest(
    actionTypes.TOGGLE_FOLDER_EXPANDED,
    folderSaga.toggleFolderExpanded
  );
  yield takeLatest(
    actionTypes.TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL,
    folderSaga.toggleFolderExpandedMoveBulkModal
  );
  yield takeLatest(
    actionTypes.SET_FOLDERS_EXPANDED,
    folderSaga.setFoldersExpanded
  );
  yield takeLatest(actionTypes.BULK_MOVE_FOLDER, folderSaga.bulkMoveFolder);
  yield takeLatest(actionTypes.BULK_CLONE_FOLDER, folderSaga.bulkCloneFolder);
  yield takeLatest(
    actionTypes.GENERATE_CLIENT_CONFIG,
    folderSaga.generateClientConfig
  );

  // Auth
  yield takeLatest(actionTypes.GET_CSRF, authSaga.getCSRF);
  yield takeLatest(actionTypes.LOGIN, authSaga.login);
  yield takeLatest(actionTypes.LOGOUT, authSaga.logout);
  yield takeLatest(
    actionTypes.REQUEST_PASSWORD_RESET,
    authSaga.request_password_reset
  );
  yield takeLatest(actionTypes.RESET_PASSWORD, authSaga.reset_password);

  // User
  yield takeLatest(actionTypes.USER_GET_DATA, userSaga.userGetData);
  yield takeLatest(actionTypes.DELETE_USER, userSaga.deleteUser);
  yield takeLatest(
    actionTypes.RESEND_SET_USER_PASSWORD_EMAIL,
    userSaga.resendSetPasswordEmail
  );

  // client_options
  yield takeEvery(
    actionTypes.SAVE_SOCKET_INSTANCE,
    utilSaga.saveSocketInstance
  );

  // Resource
  yield takeEvery(actionTypes.ADD_RESOURCES, resourceSaga.addResources);
  yield takeEvery(actionTypes.UPDATE_RESOURCES, resourceSaga.updateResource);
  yield takeEvery(
    actionTypes.ADD_TEXT_RESOURCES,
    resourceSaga.addTextResources
  );
  yield takeEvery(
    actionTypes.UPDATE_TEXT_RESOURCE,
    resourceSaga.updateTextResource
  );
  yield takeEvery(
    actionTypes.UPDATE_FILE_RESOURCE,
    resourceSaga.updateFileResource
  );
  yield takeEvery(
    actionTypes.UPDATE_UPLOADED_RESOURCES_RESOURCE_TREE,
    resourceSaga.updateUploadedResources
  );
  yield takeEvery(actionTypes.DELETE_RESOURCES, resourceSaga.deleteResources);
  yield takeEvery(actionTypes.CLEAN_RESOURCES, resourceSaga.cleanResources);
  yield takeEvery(actionTypes.CLEAN_RESOURCES, resourceSaga.cleanResources);
  yield takeLatest(
    actionTypes.TOGGLE_MODAL_TREE_FOLDER_EXPANDED,
    resourceSaga.toggleModalResourceFolderExpanded
  );
  yield takeLatest(actionTypes.GET_RESOURCE_TAGS, resourceSaga.getResourceTags);
  yield takeLatest(
    actionTypes.DELETE_RESOURCE_TAGS,
    resourceSaga.deleteResourceTags
  );
  yield takeLatest(actionTypes.ADD_RESOURCE_TAGS, resourceSaga.addResourceTags);
  yield takeLatest(
    actionTypes.GET_RESOURCE_NOTES,
    resourceSaga.getResourceNotes
  );
  yield takeLatest(
    actionTypes.ADD_RESOURCE_NOTES,
    resourceSaga.addResourceNotes
  );
  yield takeLatest(
    actionTypes.DELETE_RESOURCE_NOTES,
    resourceSaga.deleteResourceNotes
  );
  yield takeLatest(
    actionTypes.UPDATE_RESOURCE_NOTES,
    resourceSaga.updateResourceNotes
  );
  yield takeLatest(
    actionTypes.MOVE_RESOURCE_FOLDER,
    resourceSaga.moveResourceFolder
  );
  yield takeLatest(
    actionTypes.DELETE_BULK_RESOURCES,
    resourceSaga.deleteBulkResources
  );
  yield takeLatest(
    actionTypes.UPDATE_BULK_RESOURCES,
    resourceSaga.updateBulkResources
  );
  yield takeLatest(
    actionTypes.MOVE_BULK_RESOURCES,
    resourceSaga.moveBulkResources
  );

  //Companies
  yield takeLatest(actionTypes.GET_COMPANIES, companySaga.getCompanies);
  yield takeLatest(actionTypes.ADD_COMPANY, companySaga.addCompany);
  yield takeLatest(actionTypes.GET_COMPANY, companySaga.getCompany);
  yield takeLatest(actionTypes.UPDATE_COMPANY, companySaga.updateCompany);
  yield takeLatest(actionTypes.UPDATE_PROJECT, projectSaga.updateProject);
  yield takeLatest(actionTypes.GET_PROJECT, projectSaga.getProject);
  yield takeLatest(actionTypes.ADD_PROJECT, projectSaga.addProject);
  yield takeLatest(actionTypes.GET_PROJECTS, projectSaga.getProjects);
  yield takeLatest(actionTypes.DELETE_COMPANY, companySaga.deleteCompany);
  yield takeLatest(
    actionTypes.DOWNLOAD_PROJECT_CSV,
    projectSaga.downloadProjectCsv
  );
  yield takeEvery(actionTypes.UPLOAD_PROJECT_CSV, projectSaga.uploadProjectCsv);
  yield takeEvery(actionTypes.REINDEX_ELASTIC, companySaga.reindexElastic);
  yield takeLatest(actionTypes.FOLDER_TYPE_ALERT, companySaga.folderTypeAlert);

  //Users
  yield takeLatest(actionTypes.GET_ALLUSER, userSaga.getAllUser);
  yield takeLatest(actionTypes.GET_SINGLEUSER, userSaga.getSingleUser);
  yield takeLatest(
    actionTypes.GET_ALLUSERS_ALLCOMPANIES,
    userSaga.getAllUserAllCompanies
  );
  yield takeLatest(actionTypes.COMPANYUSER_UPDATE, userSaga.updateUser);
  yield takeLatest(actionTypes.COMPANYUSER_ADD, userSaga.addUser);
  yield takeLatest(actionTypes.ADD_ROLE_USER, userSaga.addRoleUser);
  yield takeLatest(actionTypes.DELETE_ROLE_USER, userSaga.deleteRoleUser);
  yield takeLatest(actionTypes.USER_ACC_UPDATE, userSaga.updateUserAccount);
  yield takeLatest(
    actionTypes.UPDATE_PERSISTENT_SETTINGS,
    userSaga.updatePersistentSettings
  );
  yield takeLatest(actionTypes.CHANGE_COMPANY, userSaga.changeCompany);
  yield takeLatest(
    actionTypes.CHANGE_PLACEHOLDER_CONFIGURATION,
    userSaga.change_placeholder_configuration
  );

  yield takeLatest(actionTypes.ADD_ROLE, companySaga.addRole);
  yield takeLatest(actionTypes.UPDATE_ROLE, companySaga.updateRole);
  yield takeLatest(actionTypes.DELETE_ROLE, companySaga.deleteRole);
  yield takeLatest(actionTypes.ADD_PERMISSION, companySaga.addPermission);

  yield takeLatest(actionTypes.UPDATE_PERMISSION, companySaga.updatePermission);
  yield takeLatest(actionTypes.DELETE_PERMISSION, companySaga.deletePermission);

  yield takeLatest(actionTypes.TEST_CHANGE, utilSaga.testChange);

  // Audit logs
  yield takeLatest(actionTypes.GET_AUDIT_LOGS, auditLogSaga.get_audit_logs);
  yield takeLatest(
    actionTypes.GET_AUDIT_LOGS_BY_FOLDER,
    auditLogSaga.get_audit_logs_by_folder
  );

  // Locations
  yield takeLatest(actionTypes.ADD_LOCATION, locationSaga.addLocation);
  yield takeLatest(actionTypes.UPDATE_LOCATION, locationSaga.updateLocation);
  yield takeLatest(actionTypes.DELETE_LOCATION, locationSaga.deleteLocation);
  yield takeLatest(actionTypes.GET_LOCATIONS, locationSaga.getLocations);

  // Backup
  yield takeLatest(actionTypes.CREATE_BACKUP, backupSaga.create);
  yield takeLatest(
    actionTypes.GET_BACKUPS_BY_COMPANY,
    backupSaga.get_by_company
  );
  yield takeLatest(actionTypes.RESTORE_BACKUP, backupSaga.restore);
  yield takeLatest(actionTypes.EXPORT_BACKUP, backupSaga.export);
  yield takeLatest(
    actionTypes.CREATE_CROSS_BACKUP,
    backupSaga.create_cross_backup
  );

  // Project
  yield takeLatest(
    actionTypes.GET_ALL_NOTIFIABLE_USERS,
    projectSaga.get_all_notifiable_users
  );
  yield takeEvery(actionTypes.DELETE_PROJECT, projectSaga.delete);
  yield takeEvery(
    actionTypes.UPDATE_PROJECT_SETTINGS,
    projectSaga.updateProjectSettings
  );

  // User Company
  yield takeLatest(actionTypes.ADD_USER_COMPANY, userSaga.add_user_company);
  yield takeLatest(
    actionTypes.REMOVE_USER_COMPANY,
    userSaga.remove_user_company
  );

  yield takeLatest(
    actionTypes.GET_NOTIFICATIONS,
    notificationSaga.get_notifications
  );
  yield takeLatest(
    actionTypes.UPDATE_NOTIFICATIONS_READ,
    notificationSaga.update_notifications_read
  );
  yield takeLatest(
    actionTypes.UPDATE_NOTIFICATIONS_DELETE,
    notificationSaga.update_notifications_delete
  );

  yield fork(folderSaga.pollFolderStatusWatcher);

  // Tags
  yield takeLatest(actionTypes.GET_TAGS, tagSaga.getTags);

  // URI Access
  yield takeLatest(
    actionTypes.URI_ACCESS_INITIALIZE,
    uriAccessSaga.uriAccessInitialize
  );
  yield takeLatest(
    actionTypes.GET_URI_ACCESS_BY_FOLDER,
    uriAccessSaga.getUriAccessByFolder
  );
  yield takeLatest(
    actionTypes.UPSERT_URI_ACCESS,
    uriAccessSaga.upsertUriAccess
  );

  // Email Notification Subscriptions
  yield takeEvery(
    actionTypes.TOGGLE_EMAIL_SUBSCRIPTION_NOTIFICATION,
    emailSubscriptionSaga.toggleEmailNotificatonSubscription
  );

  // Socket
  yield takeLatest(actionTypes.INIT_SOCKET, socketInitFlow);
}
