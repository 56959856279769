import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { glenwood_template_shared } from "./glenwood_template_shared";

export const glenwood_baby_alert: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.glenwood_baby_alert,
  display_name: "Baby Alert",
  tabs: {
    general: {
      title: "General",
    },
  },
  field_groups: {
    general: {
      title: "General",
      tab: "general"
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
      },
      show_char_count: false,
      field_group: "general",
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }],
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "cycle_time",
      },
    },
    trigger: {
      type: "text",
      title: "Trigger",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
    media: {
      title: "Media",
      type: "file",
      field_group: "general",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778,
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      },
    },
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.Alert,
    type: PREVIEW_TYPE.COMPONENT,
  },
  save_options: [...glenwood_template_shared.save_options],
};
