import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IResourceFolder } from "../../../../shared-global/interfaces/models/resource__folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { shuffleArray } from "../../../utils/commonDataUtils";
import Slide1911 from "../../standard/Slide1911";

interface IScreenData {
  type: string, // word
  is_visible: boolean, // true false
  word?: string, // null or word
  folder__resource?: IResourceFolder // null or folder_resource
}

interface IScreenDatas {
  [key: string]: IScreenData
}

const patternSteps = [
  {
    name: 'slide_1',
    delay: 1000,
  },
  {
    name: 'slide_2',
    delay: 1000,
  },
  {
    name: 'slide_3',
    delay: 1000,
  },
  {
    name: 'word_1',
    delay: 1000,
  },
  {
    name: 'word_2',
    delay: 1000,
  },
  {
    name: 'word_3',
    delay: 1000,
  },
  {
    name: 'last_delay',
    delay: 1000
  }
]

const BostonCheckerboard: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const [baseFolder, setBaseFolder] = useState<IFolder | null>(null);
  const [backgroundResourceFolder, setBackgroundResourceFolder] = useState<IResourceFolder | null>(null);
  const [slideResourceFolders, setSlideResourceFolders] = useState<IResourceFolder[]>([]);
  const [inspirationalWords, setInspirationalWords] = useState<string[]>([]);
  const [relatedWords, setRelatedWords] = useState<string[]>([]);
  const [patternIndex, setPatternIndex] = useState(-1);
  const [screenData, setScreenData] = useState<IScreenDatas[]>([]);
  const [loopedThroughFirstLoop, setLoopedThroughFirstLoop] = useState(false);

  const checkerboardSlot = useMemo(() => {
    return get(props, 'data.componentSpecificData.slot_name', 'checkerboard_media');
  }, [props.data]);

  // 0-2 fade in slides
  // 3 delay before words
  // 4 fade in inspirational word
  // 5, 6 fade in related inspirational words
  // 7 delay before full transition to next checkerboard

  const currentLoopIndex = () => {
    return Math.floor(patternIndex / patternSteps.length);
  }
  
  const nextIndex = (index: number, maxIndex: number): number => {
    let newIndex = index + 1;
    if (newIndex > maxIndex) {
      return 0;
    }
    return newIndex
  }

  const getRandomIndex = (candidates) => {
    const min = 0;
    const max = candidates.length - 1;
    let randomNumber  = null;
    while (randomNumber === null) {
      randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    }
    return randomNumber;
  }

  const preparePattern = () => {
    let loopData = [];    
    let slideCandidates = slideResourceFolders.map((_v, i) => i);
    let relatedWordCandidates = relatedWords.map((_v, i) => i);
    let inspirationalWordCandidates = inspirationalWords.map((_v, i) => i);

    const patternLoops = 3;
    const cycleTime = get(props, 'cycle_time', get(baseFolder, 'fields.cycle_time', 30) * 1000);
    const stepDelay = cycleTime / patternLoops / patternSteps.length;
    patternSteps.forEach(s => s.delay = stepDelay);

    // adjust 3 for the times the pattern should repeat
    for (let x = 0; x < patternLoops; x++) {

      // SHUFFLE slide positions
      //let evenSlidePositions = shuffleArray([[0,0], [1,1], [2,0]])
      //let oddSlidePositions = shuffleArray([[0,1], [1,0], [2,2]])

      let defaultPData: IScreenData = {
        type: "slide", // word
        is_visible: false, // true false
        word: null, // null or word
        folder__resource: null // null or folder_resource
      }

      let pData = {
        s0_0: { ...defaultPData },
        s0_1: { ...defaultPData },
        s1_0: { ...defaultPData },
        s1_1: { ...defaultPData },
        s2_0: { ...defaultPData },
        s2_1: { ...defaultPData },
      }

      let evenPattern = x % 2 === 0;
      // left to right
      let slideScreens = ['s0_0', 's1_1', 's2_0'];
      let wordScreens = ['s0_1', 's1_0', 's2_1'];
      if (!evenPattern) {
        slideScreens = ['s0_1', 's1_0', 's2_1'];
        wordScreens = ['s0_0', 's1_1', 's2_0'];
      }
      slideScreens.forEach(s => {
        slideCandidates = slideCandidates.length === 0 ? slideResourceFolders.map((_v, i) => i) : slideCandidates;
        const candidateIndex = getRandomIndex(slideCandidates);
        const slideIndex = slideCandidates[candidateIndex];
        slideCandidates.splice(candidateIndex, 1);
        pData[s].folder__resource = slideResourceFolders[slideIndex];
      });

      wordScreens.forEach(w => {
        if (w === 's1_0' || w === 's1_1') {
          inspirationalWordCandidates = inspirationalWordCandidates.length === 0 ? inspirationalWords.map((_v, i) => i) : inspirationalWordCandidates;
          const candidateIndex = getRandomIndex(inspirationalWordCandidates);
          const inspirationalWordIndex = inspirationalWordCandidates[candidateIndex];
          inspirationalWordCandidates.splice(candidateIndex, 1);
          pData[w].word = inspirationalWords[inspirationalWordIndex];
          pData[w].type = 'word_inspirational';
        } else {
          relatedWordCandidates = relatedWordCandidates.length === 0 ? relatedWords.map((_v, i) => i) : relatedWordCandidates;
          const candidateIndex = getRandomIndex(relatedWordCandidates);
          const relatedWordIndex = relatedWordCandidates[candidateIndex];
          relatedWordCandidates.splice(candidateIndex, 1);
          pData[w].word = relatedWords[relatedWordIndex];
          pData[w].type = 'word_related';
        }
      });
      loopData.push(pData);
    }
    
    setScreenData(loopData);
    setPatternIndex(0);
  }

  // repeated twice
  // const patternData = [
  //   1000, 1000, 1000, // fade in slides
  //   1000, // delay before words
  //   1000, // fade in inpirational word
  //   1000, 1000, // fade in related inspirational words
  //   3000, // delay before fading out slides and words
  //   1000, 1000, 1000, // fade in slides
  //   1000, // delay before words
  //   1000, // fade in inpirational word
  //   1000, 1000, // fade in related inspirational words
  //   3000, // delay before fading out slides and words
  // ]

  // 0 for first loop, 1 for second...

  const setAllScreenDataInvisibleBut = (exclude_loop_index) => {
    for (let i = 0; i < screenData.length; i++) {
      if (exclude_loop_index !== i) {
        screenData[i].s0_0.is_visible = false;
        screenData[i].s1_1.is_visible = false;
        screenData[i].s2_0.is_visible = false;
        screenData[i].s0_1.is_visible = false;
        screenData[i].s1_0.is_visible = false;
        screenData[i].s2_1.is_visible = false;
      }
    }
  }
  
  useEffect(() => {
    if (patternIndex === -1) {
      return;
    }

    const patternLoops = 3;
    let cycle_step = (patternIndex % patternSteps.length)
    let cycle_time = patternSteps[cycle_step]?.delay || 1000;
    let current_loop_index = currentLoopIndex();
    let is_pattern_even = current_loop_index % 2 === 0;

    let cycleIndexTimer = null;
    if (screenData.length) {
      cycleIndexTimer = setTimeout(() => {
        setAllScreenDataInvisibleBut(current_loop_index);
        if (!loopedThroughFirstLoop) {
          setLoopedThroughFirstLoop(true);
        }

        switch (cycle_step) {
          case 0:
            if (is_pattern_even) {
              screenData[current_loop_index].s0_0.is_visible = true;
            } else {
              screenData[current_loop_index].s0_1.is_visible = true;
            }
            break;
          case 1:
            if (is_pattern_even) {
              screenData[current_loop_index].s1_1.is_visible = true;
            } else {
              screenData[current_loop_index].s1_0.is_visible = true;
            }
            break;
          case 2:
            if (is_pattern_even) {
              screenData[current_loop_index].s2_0.is_visible = true;
            } else {
              screenData[current_loop_index].s2_1.is_visible = true;
            }
            break;
          case 3:
            if (is_pattern_even) {
              screenData[current_loop_index].s1_0.is_visible = true;
            } else {
              screenData[current_loop_index].s1_1.is_visible = true;
            }
            break;
          case 4:
            if (is_pattern_even) {
              screenData[current_loop_index].s0_1.is_visible = true;
            } else {
              screenData[current_loop_index].s0_0.is_visible = true;
            }
            break;
          case 5:
            if (is_pattern_even) {
              screenData[current_loop_index].s2_1.is_visible = true;
            } else {
              screenData[current_loop_index].s2_0.is_visible = true;
            }
            break;
          case 6:
            break;
        }

        const nextPatternIndex = nextIndex(patternIndex, (patternSteps.length * patternLoops) - 1);
        if (nextPatternIndex === 0 && loopedThroughFirstLoop) {
          if (props.handleEndOfPlay) {
            props.handleEndOfPlay();
            return;
          }
        }
        setPatternIndex(nextPatternIndex);
      }, cycle_time);
    }
    return () => clearTimeout(cycleIndexTimer);

  }, [patternIndex]);

  useEffect(() => {
    const baseF = props.data.folders.find(
      (f) => f.id === props.data.base_folder
    );

    let resourceFolders = props.data.resource__folders.filter((x) => x.folder === baseF.id);
    const backgroundResourceFolderIndex = resourceFolders.findIndex((x) => x.fieldName === 'background');
    const mediaVerticalIndex = resourceFolders.findIndex(x => x.fieldName === 'media_vertical');

    if (backgroundResourceFolderIndex > -1) {
      setBackgroundResourceFolder(resourceFolders[backgroundResourceFolderIndex]);
      resourceFolders.splice(backgroundResourceFolderIndex, 1);
    }
    if (mediaVerticalIndex && mediaVerticalIndex > -1) {
      resourceFolders.splice(mediaVerticalIndex, 1);
    }
    setSlideResourceFolders(shuffleArray(resourceFolders.filter(rf => rf.slotName === checkerboardSlot)));
    setBaseFolder(baseF);

    let inspirationalWords = shuffleArray(get(baseF, 'fields.inspirational_words', '').split(','));
    let relatedWords = shuffleArray(get(baseF, 'fields.related_inspirational_words', '').split(','));
    inspirationalWords = inspirationalWords.map((w) => w.trim());
    relatedWords = relatedWords.map((w) => w.trim());
    setInspirationalWords(inspirationalWords);
    setRelatedWords(relatedWords);
    // get all children story folders
  }, [])

  useEffect(() => {
    if (baseFolder !== null) {
      preparePattern()
    }
  }, [baseFolder]);

  if (patternIndex < 0 || !baseFolder || !screenData.length) {
    return null;
  }

  const single_screen_width = props.containerWidth / 3
  const single_screen_height = props.containerHeight / 2

  const renderBackground = () => {
    if (!backgroundResourceFolder) {
      return null;
    }
    return <div style={{
      position: 'absolute',
      width: props.containerWidth,
      height: props.containerHeight,
      left: 0,
      top: 0,
    }}>
      <Slide1911
        aws={props.aws}
        aws_bucket={props.aws_bucket}
        mode={props.mode}
        containerWidth={props.containerWidth}
        containerHeight={props.containerHeight}
        data={{
          ...props.data,
          index: 0,
          slotNames: ["background"],
          placeholder: "Image",
          fieldType: 'image',
          showPlaceholder: false,
          backgroundSize: 'cover',
        }}
        webview_signedurls={props.webview_signedurls}
      />
    </div>
  }

  const renderScreen = (key: string, screenData: IScreenData, x: number, y: number) => {
    if (!screenData) {
      return null;
    }
    return <TransitionGroup>
      <CSSTransition
        key={key + screenData.is_visible.toString()}
        timeout={1000}
        classNames={TRANSITION_CLASS.TRASITION_FADE}
      >
        <div style={{
          position: 'absolute',
          width: single_screen_width,
          height: single_screen_height,
          left: single_screen_width * x,
          top: single_screen_height * y,
          fontSize: props.containerHeight / 1000
        }}>
          
          {screenData.is_visible && screenData.type !== 'word_inspirational' && screenData.type !== 'word_related' && (
            <div>
              <Slide1911
                aws={props.aws}
                aws_bucket={props.aws_bucket}
                mode={props.mode}
                containerWidth={single_screen_width}
                containerHeight={single_screen_height}
                data={{
                  ...props.data,
                  index: get(screenData, 'folder__resource.rank', null),
                  slotNames: ["checkerboard_media"],
                  placeholder: "Image",
                  fieldType: 'image',
                  showPlaceholder: false,
                  backgroundSize: 'cover',
                }}
                webview_signedurls={props.webview_signedurls}
              />
            </div>
          )}

          {screenData.is_visible && screenData.type === 'word_inspirational' && (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: single_screen_height,
              fontSize: get(baseFolder, 'fields.inspirational_words_style.fontSize', 10) + "em",
              color: get(baseFolder, 'fields.inspirational_words_style.color', '#FFF'),
              fontFamily: get(baseFolder, 'fields.inspirational_words_style.font', 'Roboto'),

            }}>
              {screenData.word}
            </div>
          )}

          {screenData.is_visible && screenData.type === 'word_related' && (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: single_screen_height,
              fontSize: get(baseFolder, 'fields.related_inspirational_words_style.fontSize', 10) + "em",
              color: get(baseFolder, 'fields.related_inspirational_words_style.color', '#FFF'),
              fontFamily: get(baseFolder, 'fields.related_inspirational_words_style.font', 'Roboto'),

            }}>
              {screenData.word}
            </div>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  }

  const renderContent = () => {
    const renderedS0_0 = renderScreen('s0_0', get(screenData[currentLoopIndex()], 's0_0'), 0, 0);
    const renderedS1_0 = renderScreen('s1_0', get(screenData[currentLoopIndex()], 's1_0'), 1, 0);
    const renderedS2_0 = renderScreen('s2_0', get(screenData[currentLoopIndex()], 's2_0'), 2, 0);
    const renderedS0_1 = renderScreen('s0_1', get(screenData[currentLoopIndex()], 's0_1'), 0, 1);
    const renderedS1_1 = renderScreen('s1_1', get(screenData[currentLoopIndex()], 's1_1'), 1, 1);
    const renderedS2_1 = renderScreen('s2_1', get(screenData[currentLoopIndex()], 's2_1'), 2, 1);

    return (
      <>
        {renderedS0_0}
        {renderedS1_0}
        {renderedS2_0}
        {renderedS0_1}
        {renderedS1_1}
        {renderedS2_1}
      </>
    );
  }

  return <>
    {renderBackground()}
    {renderContent()}
  </>

};


export default BostonCheckerboard;
