import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Button} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Spaner from '../../../utils/Spaner';
import {
  getCroppedImage,
  getCroppedImageFromS3,
  getImageFromFile,
  getOriginalImageFromS3,
  removeItemFromDefaultValues,
  removeItemFromItem,
} from '../DynamicFileInput/FileInputService';
import ImageLoader from '../../../utils/ImageLoader';
import TextInput from '../TextInput.tsx';
import CarouselMedia from './CarouselMedia';
import {MEDIA_FILE_INPUT_STATE_AVAILABLE} from '../DynamicFileInput/MediaFileInput';
import {STATE_ADD_MORE, STATE_REPLACE} from './ContentUploader';
import AddNewMediaFileInput from '../DynamicFileInput/AddNewMediaFileInput';
import AlertNotification from '../../../layout/AlertNotification';
import {arrayFromKeyedObject} from '../../../../shared/utils/collectionUtils';
import {
  getFileTypeGeneric,
  getFileType,
} from '../../../../shared/utils/fileUtils';
import {contMaxHeight, contMaxWidth, DEFAULT_CONTENT} from './ContentManager';
import VideoLoader from '../../../utils/VideoLoader';
import {isNull} from '../../../../shared/utils/generalUtils';
import DynamicForm from "../../DynamicForm";

const CHUNK_SIZE = 7168000;

export default function ContentView({
                                      editItem,
                                      items,
                                      defaultValue,
                                      acceptable,
                                      setClientOptions,
                                      currentImage,
                                      config,
                                      client_options,
                                      aws_instance,
                                      limit,
                                      resource__folders,
                                      resources,
                                      onCloseForced,
                                      updateFolder,
                                      onClickUpload,
                                      onClickCrop,
                                      goToImage,
                                      updateItems,
                                      onCancel,
                                      updateUploadedResources,
                                      fetchFolderStatus,
                                      updateCurrentImage,
                                      onChangeAllValues,
                                      contWidth,
                                      contHeight,
                                      updateTouch,
                                      title,
                                      selectedFolderFromWidget
                                    }) {
  const [hasImage, setHasImage] = useState(false);
  const [container, setContainer] = useState({});
  const [mediaPreviewType, setMediaPreviewType] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [darkMode, setDarkMode] = useState(null);
  const [currentIndex, _setCurrentIndex] = useState(null);
  const currentIndexRef = useRef(currentIndex);
  const setCurrentIndex = (data) => {
    currentIndexRef.current = data;
    _setCurrentIndex(data);
  };
  const [caption, _setCaption] = useState(null);
  const captionRef = useRef(caption);
  const setCaption = (data) => {
    captionRef.current = data;
    if (captionFormRef && captionFormRef.current) {
      captionFormRef.current.overrideFieldValue('caption', data);
    }
    _setCaption(data);
  };
  const [imageTitle, _setImageTitle] = useState("");
  const imageTitleRef = useRef(imageTitle);
  const setImageTitle = (data) => {
    imageTitleRef.current = data;
    if (imageTitleFormRef && imageTitleFormRef.current) {
      imageTitleFormRef.current.overrideFieldValue('modifiedName', data);
    }
    _setImageTitle(data);
  };
  const [mediaPreviewTypeExtension, setMediaPreviewTypeExtension] = useState(
    null,
  );
  const [currentResourceUrl, setCurrentResourceUrl] = useState(null);
  const imageTitleFormRef = useRef(null);
  const captionFormRef = useRef(null);

  useEffect(() => {
    if (mediaPreviewType === 'video') {
      setHasImage(true);
    } else {
      setHasImage(imagePreview !== null);
    }
  }, [imagePreview, mediaPreviewType]);

  useEffect(() => {
    let myContainer = DEFAULT_CONTENT;
    if (config && config.container) {
      if (config.container) {
        myContainer = config.container;
      }
    }
    setContainer(myContainer);
  }, [config]);

  // TODO commented for now
  // useEffect(() => {
  //   imageTitleInputRef.current.value = imageTitle;
  // }, [imageTitle]);

  useEffect(() => {
    updateImageInStateIfNeed(currentImage);
    if (currentIndex !== null) {
      const currentDefault = defaultValue[currentIndex];
      if (currentDefault && !isNull(currentDefault.caption, false)) {
        setCaption(currentDefault.caption);
      }
      if (currentDefault && !isNull(currentDefault.modifiedName, false)) {
        setImageTitle(currentDefault.modifiedName);
      }

    }
  }, [defaultValue, currentIndex]);

  useEffect(() => {
    updateImageInStateIfNeed(currentImage);
  }, [currentImage]);

  const onError = (e) => {
    AlertNotification(
      'error',
      'Error uploading files',
      e.message || 'An error has occurred',
    );
  };

  const onSuccess = () => {
    AlertNotification(
      'success',
      'Files Updated',
      'Files were updated successfully',
    );
  };

  const getSelectedFolderId = (selectedFolder, client_options) => {
    let folderId = client_options.applied_content_selected_folder;
    if (selectedFolder && selectedFolder.id) {
      folderId = selectedFolder.id;
    }
    return folderId;
  };

  const mergeImageFromDefaultValueWithItem = (image, item) => {
    const {rank} = image;
    let myImage = image;
    if (image && image.rank >= 0) {
      myImage = defaultValue.find((value) => value.rank === rank);
    }

    return {
      ...myImage,
      ...item,
    };
  };

  const updateImageInState = (image) => {
    if (image) {
      updateCurrentImage(image);
      updatePreviewImageInState(image);
    }
  };

  const getCurrentIndex = (key) => {
    const index = items.findIndex((i) => i.identifier === key);
    return index;
  };

  /**
   * image, came from image in carousel
   */
  const onClickCarouselImage = (image, item) => {
    const myImage = mergeImageFromDefaultValueWithItem(image, item);
    updateImageInState(myImage);
    if (item) {
      const newCurrentIndex = getCurrentIndex(item.identifier);
      setCurrentItem(item);
      setCurrentIndex(newCurrentIndex);
    }
  };

  const onMoveCard = async (newItems, newDefaultValue, newIndex) => {
    updateTouch(true);
    const newCurrentItem = newItems[newIndex];
    setCurrentIndex(newIndex);
    setCurrentItem(newCurrentItem);
    updateItems(newItems);
    await onChangeAllValues([...newDefaultValue]);
    goToImage(newIndex);
  };

  const uploadToS3 = async (field, progressCb) => {
    const s3 = new aws_instance.S3({
      apiVersion: '2006-03-01',
      region: 'us-west-2',
    });
    const params = {
      Body: field.file,
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/temp`,
      Key: field.name,
      CacheControl: 'no-cache, must-revalidate',
    };
    try {
      await new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }).on('httpUploadProgress', (progress) => {
          if (progressCb && progress.lengthComputable) {
            progressCb((progress.loaded / progress.total) * 100);
          }
        });
      });
      return true;
    } catch (e) {
      console.log('error uploading:', e);
      onError(e);
    }
  };

  const multipartUploadToS3 = async (field, progressCb) => {
    const s3 = new aws_instance.S3({
      apiVersion: '2006-03-01',
      region: 'us-west-2',
    });

    const multiPartResponseArray = [];
    const multipartResponse = await new Promise((resolve, reject) => {
      s3.createMultipartUpload(
        {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
          ContentType: field.file.type,
          CacheControl: 'no-cache, must-revalidate',
        },
        (err, data) => {
          if (err) {
            return reject(err);
          }

          return resolve(data);
        },
      );
    });

    let errors = 0;
    for (let j = 0; j < field.chunks.length; j++) {
      try {
        const uploadResponse = await new Promise((resolve, reject) => {
          s3.uploadPart(
            {
              Body: field.chunks[j],
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
              PartNumber: j + 1,
              UploadId: multipartResponse.UploadId,
            },
            (err, data) => {
              if (err) {
                return reject(err);
              }
              return resolve(data);
            },
          ).on('httpUploadProgress', (progress) => {
            const percentage = ((progress.loaded / progress.total) * 100) / field.chunks.length
              + (100 / field.chunks.length) * j;
            if (progressCb && progress.lengthComputable) {
              progressCb(percentage);
            }
          });
        });
        multiPartResponseArray.push({
          ETag: uploadResponse.ETag,
          PartNumber: j + 1,
        });
        errors = 0;
      } catch (e) {
        errors++;
        j--;
      }

      if (errors === 3) {
        onError("There's been an error trying to upload files");
        await new Promise((resolve, reject) => {
          s3.abortMultipartUpload(
            {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
              UploadId: multipartResponse.UploadId,
            },
            () => resolve(),
          );
        });
      }
    }

    await new Promise((resolve, reject) => {
      s3.completeMultipartUpload(
        {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
          MultipartUpload: {Parts: multiPartResponseArray},
          UploadId: multipartResponse.UploadId,
        },
        (err, data) => {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        },
      );
    });

    return true;
  };

  const getFileFields = (values) => {
    const foundFields = [];
    for (let j = 0; j < values.length; j++) {
      if (values[j].status === 'loaded' && values[j].type === 'file') {
        const str = values[j].fileName.split('.');
        const lowercaseExt = str[str.length - 1].toLowerCase();
        const newName = `${values[j].randomFileName}.${lowercaseExt}`;
        foundFields.push({
          name: newName,
          file: values[j].file,
          chunks: values[j].chunks,
        });
        delete values[j].file;
      }
    }
    return foundFields;
  };

  const uploadFiles = async (values) => {
    const fileFields = getFileFields([...values]);
    const folderId = getSelectedFolderId(
      selectedFolderFromWidget,
      client_options,
    );

    const onUploadProgress = (percentage, fileName, current, max) => {
      setClientOptions({
        indeterminate: false,
        progress: {
          percentage, fileName, current, max,
        },
      });
    };

    if (fileFields && fileFields.length > 0) {
      setClientOptions({lock_screen: true, message_for_progress: null});
      const fileNames = [];
      for (let i = 0; i < fileFields.length; i++) {
        if (fileFields[i].file.size > CHUNK_SIZE) {
          await multipartUploadToS3(fileFields[i], (percentage) => {
            onUploadProgress(
              percentage,
              fileFields[i].file.name,
              i,
              fileFields.length - 1,
            );
          });
        } else {
          await uploadToS3(fileFields[i], (percentage) => {
            onUploadProgress(
              percentage,
              fileFields[i].file.name,
              i,
              fileFields.length - 1,
            );
          });
        }
        fileNames.push(fileFields[i].name);
      }
      // updateUploadedResources(fileNames, folderId, () => {
      //   onChangeAllValues(values);
      // });
      // fetchFolderStatus();
    } else {
      // call fetchStatusFolder and close modal
      // if (onSuccess) {
      //   onSuccess();
      //   onChangeAllValues(values);
      // }
    }
    onCloseForced();
    // setClientOptions({
    //   lock_screen: false,
    //   progress: {},
    //   indeterminate: null,
    //   message_for_progress: '',
    // });
  };

  const getFieldName = () => {
    const {fieldName} = defaultValue[0];
    return fieldName;
  };

  const onSubmitDeleteImageSingleSlot = async () => {
    const folderId = getSelectedFolderId(selectedFolderFromWidget, client_options);
    const fieldName = getFieldName();
    if (defaultValue.length === 1 && defaultValue[0].resource) {
      const fields = defaultValue.map(f => ({
        id: f.id,
        fieldName: fieldName,
        resource: f.resource,
        status: 'removed'
      }));

      setClientOptions({
        lock_screen: true,
        progress: {},
        message_for_progress: "Please wait while we save your changes"
      });
      updateFolder(
        folderId,
        null,
        {[fieldName]: fields},
        null, //TODO: PATH WORK HERE
        () => {
          onCloseForced();
          setClientOptions({lock_screen: false, progress: {}, message_for_progress: ""});
          cleanState();
        },
        err => {
          console.error("content view error on submit single slot", err)
        }
      );
    } else {
      // no new resources saved, so only close modal
      onCloseForced();
      cleanState();
    }

  };

  const onSubmitChangeImageCarousel = async () => {
    const folderId = getSelectedFolderId(
      selectedFolderFromWidget,
      client_options,
    );
    const fieldName = getFieldName();
    const fields = defaultValue.map((f) => {
      const newField = {...f};
      delete newField.chunks;
      delete newField.resourceUrl;
      delete newField.thumb;
      delete newField.file;
      return newField;
    });

    setClientOptions({
      lock_screen: true,
      progress: {},
      indeterminate: true,
      message_for_progress: 'Please wait while we save your changes',
    });

    await uploadFiles(defaultValue);

    updateFolder(
      folderId,
      null,
      {[fieldName]: fields},
      null, //TODO: Folder Paths
      async () => {
        if (onSuccess) {
          onSuccess();
          fetchFolderStatus();
          setClientOptions({
            lock_screen: false,
            progress: {},
            indeterminate: null,
            message_for_progress: '',
          });
          // onChangeAllValues(values);
        }
      },
      (err) => {
        console.error('content view error on submit change carousel', err);
      },
    );
  };

  const getCropFromFolderResource = (image) => {
    let crop = null;
    const resourceId = image.resource;
    const folderId = getSelectedFolderId(
      selectedFolderFromWidget,
      client_options,
    );

    const resourceFolder = arrayFromKeyedObject(resource__folders).find(
      (rf) => rf.folder === Number(folderId) && rf.resource === resourceId,
    );

    if (
      resourceFolder
      && resourceFolder.contents
      && resourceFolder.contents.crop
    ) {
      crop = {
        crop: resourceFolder.contents.crop,
        cropOriginal: resourceFolder.contents.cropOriginal,
        content_manager_dark_mode: resourceFolder.contents.content_manager_dark_mode ? resourceFolder.contents.content_manager_dark_mode : false
      };
    }
    return crop;
  };

  const getCropFromImage = (image) => {
    // get crop data from image in memopry
    if (image.isEditingInMemory) {
      return image.crop;
    }

    // get crop data form backend
    const cropData = getCropFromFolderResource(image);
    if (cropData) {
      return cropData.crop;
    }

    return null;
  };

  const updatePreviewImageS3InState = (image) => {
    let originalImageS3;

    if (image) {
      const crop = getCropFromImage(image);
      // load original image
      originalImageS3 = getOriginalImageFromS3(image, resources, aws_instance);

      if (crop) {
        getCroppedImageFromS3(originalImageS3, crop).then((base64) => {
          setImagePreview(base64);
        });
      } else {
        setImagePreview(originalImageS3);
      }
    }
  };

  const updatePreviewVideoInState = (image) => {
    if (image && image.fileName && image.thumb) {
      const imagePreview = image.thumb;
      setImagePreview(imagePreview);
    } else {
      setImagePreview(null);
    }
  };

  const updatePreviewImageLocalInState = (image) => {
    if (image) {
      const {thumb, file} = image;
      const crop = getCropFromImage(image);

      if (crop) {
        getCroppedImage(image, crop).then((base64) => {
          setImagePreview(base64);
        });
      } else if (thumb) {
        setImagePreview(thumb);
      } else if (file) {
        getImageFromFile(file).then((base64) => {
          setImagePreview(base64);
          updateCurrentImage({
            ...image,
            thumb: base64,
          });
        });
      }
    }
  };

  const updatePreviewImageInState = (image) => {
    if (image) {
      if (isVideo(image)) {
        const {fileName} = image;
        const fileType = getFileType(fileName);
        setMediaPreviewTypeExtension(fileType);
        setMediaPreviewType('video');
        updatePreviewVideoInState(image);
      } else {
        setMediaPreviewType('image');
        const {status, isEditingInMemory, file} = image;
        if (
          status === MEDIA_FILE_INPUT_STATE_AVAILABLE
          || (isEditingInMemory && !file)
        ) {
          updatePreviewImageS3InState(image);
        } else {
          updatePreviewImageLocalInState(image);
        }
      }
    }
  };

  const cleanState = () => {
    setCurrentIndex(null);
    setCurrentItem(null);
    setImagePreview(null);
    setHasImage(false);
  };

  const updateImageInStateIfNeed = (image) => {
    // check if should update current preview
    if (image) {
      const {rank} = image;
      const index = defaultValue.findIndex((v) => v.rank === rank);
      const newCurrentItem = items[index];
      if (newCurrentItem) {
        setCurrentResourceUrl(image.resourceUrl);
        setCurrentIndex(index);
        setCurrentItem(newCurrentItem);
        setDarkMode(image.content_manager_dark_mode ? image.content_manager_dark_mode : false)
        updatePreviewImageInState(image);
      } else {
        cleanState();
      }
    } else {
      // no image, clean preview
      cleanState();
    }
  };

  const updateDefaultValueItemFromInputFile = async (
    rank,
    newDefaultValueItem,
  ) => {
    const index = defaultValue.findIndex((v) => v.rank === rank);
    if (index >= 0) {
      // modify defaultValues without change array pointer
      const foundItem = defaultValue[index];

      const itemToReplace = {
        ...foundItem,
        ...newDefaultValueItem,
      };

      if (
        !(
          foundItem.status === MEDIA_FILE_INPUT_STATE_AVAILABLE
          && foundItem.isEditingInMemory
        )
      ) {
        defaultValue.splice(index, 1, itemToReplace);
        await onChangeAllValues(defaultValue);

        if (index === currentIndex) {
          updatePreviewImageInState(itemToReplace);
        }
      }
    }
  };

  const isSingleSlot = () => {
    return limit === 1;
  };

  const onRemove = async (identifier) => {
    const allValues = _.cloneDeep(defaultValue);
    const newItems = removeItemFromItem(items, identifier, 'file', allValues);
    const allValuesNew = removeItemFromDefaultValues(
      allValues,
      identifier,
      'dynamic',
      items
    );

    updateItems(newItems);
    await onChangeAllValues(allValuesNew);
  };

  const onClickRemove = async () => {
    const newCurrentItem = items[currentIndex];

    if (isSingleSlot()) {
      await onRemove(newCurrentItem.identifier);
      await onSubmitDeleteImageSingleSlot();
    } else {
      if (newCurrentItem && newCurrentItem.identifier) {
        updateTouch(true);
        await onRemove(newCurrentItem.identifier);
        goToImage(0);
      }
    }
  };

  const onClickReplace = () => {
    onClickUpload(STATE_REPLACE);
  };

  const onClickAddMore = () => {
    onClickUpload(STATE_ADD_MORE);
  };

  const getAddButton = () => (
    <AddNewMediaFileInput
      extraClassStyle="content-manager_view-add-button"
      add={onClickAddMore}
      show={items.length < limit}
      size="full"
    />
  );

  const nextImage = () => {
    let newCurrentIndex = currentIndex + 1;
    if (newCurrentIndex >= items.length) {
      newCurrentIndex = 0;
    }
    goToImage(newCurrentIndex);
  };

  const prevImage = () => {
    let newCurrentIndex = currentIndex - 1;
    if (newCurrentIndex < 0) {
      newCurrentIndex = items.length - 1;
    }
    goToImage(newCurrentIndex);
  };

  const isVideo = (image) => {
    if (image) {
      const {fileName, isVideo, isImage} = image;
      const fileType = getFileType(fileName || '');
      return (
        image && (isVideo === true || isImage === false || fileType === 'video')
      );
    }
    return false;
  };

  const updateCurrentDefaultValue = (name, value) => {
    updateTouch(true);
    const newDefaultValue = [...defaultValue];
    const index = currentIndexRef.current;

    newDefaultValue[index][name] = value;
    onChangeAllValues(newDefaultValue);
  };

  const renderDynamicFormInputText = (formRef, field, value, title, onChangeHandlers) => {
    const fieldsConfig = buildFieldConfigInputText(field, title, value);
    const fieldValues = {
      modifiedName: value
    };
    return (
      <div className="content-manager_form-field">
        <div className="content-manager_form-h1">{title}</div>
        <DynamicForm
          ref={formRef}
          fieldGroupsConfig={{
            default: {title: '', default_expanded: true},
          }}
          fieldsConfig={fieldsConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => {
          }}
          onError={() => {
          }}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onChangeHandlers={onChangeHandlers}
          classOverride="noclass"
          canUpdate
        />
      </div>
    );
  }

  const buildFieldConfigInputText = (field, title, defaultValue) => {
    const fieldConfig = {
      [field]: {
        type: "text",
        title: title,
        validation: {rules: [{name: 'isRequired'}]},
        default: null,
        settings: {}
      }
    };
    return fieldConfig;
  }

  return (
    <div className="content-manager">
      <div className="content-manager_container">
        <div className="content-manager_section content-manager_section-top">
          <div className="content-manager_container-left">
            <div className="content-manager_image">
              {items && items.length > 0 && limit > 1 ? (
                <div className="content-manager_image-label">
                  <div className="content-manager_image-label-container">
                    <div className="content-manager_image-label-overlap"/>
                    <div className="content-manager_image-label-text">
                      {`${
                        currentIndex + 1
                      } of ${items.length}`}
                    </div>
                  </div>
                </div>
              ) : null}
              {items && items.length > 0 && limit > 1 ? (
                <Button
                  onClick={prevImage}
                  className="content-manager_image-button content-manager_image-button-prev"
                >
                  <FontAwesomeIcon
                    className="fa-lg"
                    icon={['fa', 'chevron-left']}
                  />
                </Button>
              ) : null}
              {items && items.length > 0 && limit > 1 ? (
                <Button
                  onClick={nextImage}
                  className="content-manager_image-button content-manager_image-button-next"
                >
                  <FontAwesomeIcon
                    className="fa-lg"
                    icon={['fa', 'chevron-right']}
                  />
                </Button>
              ) : null}
              <div className="content-manager_image-container">
                <div
                  style={{
                    width: contMaxWidth,
                    height: contMaxHeight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                   className={'image-cropper_container-white ' + container.flexClass}
                    style={{
                      border: '1px solid grey',
                      height: contHeight,
                      width: contWidth,
                    }}
                  >
                    {hasImage && mediaPreviewType === 'image' && (
                      <ImageLoader
                        key={currentImage}
                        index={currentIndex}
                        imgCover={container.imgCover}
                        maxWidth={contWidth}
                        maxHeight={contHeight}
                        flexClass={container.flexClass}
                        url={imagePreview}
                        spinnerSize="4x"
                      />
                    )}
                    {hasImage && mediaPreviewType === 'video' && (
                      <VideoLoader
                        index={currentIndex}
                        url={currentResourceUrl}
                        extension={mediaPreviewTypeExtension}
                        height={contHeight}
                        width={contWidth}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="content-manager_image-buttons">
              <div className="content-manager_image-buttons-left">
                <Button
                  disabled={!items || items.length === 0 || !imagePreview}
                  onClick={onClickReplace}
                  style={{width: 160}}
                >
                  <FontAwesomeIcon className="fa-md" icon="upload"/>
                  <Spaner width="sm"/>
                  REPLACE MEDIA
                </Button>
                {!isVideo(currentImage) && (
                  <Button
                    disabled={!items || items.length === 0 || !imagePreview}
                    onClick={onClickCrop}
                    style={{width: 160}}
                  >
                    <FontAwesomeIcon className="fa-md" icon="crop-alt"/>
                    <Spaner width="sm"/>
                    ADJUST / CROP
                  </Button>
                )}
              </div>

              {limit > 1 || (limit === 1 && items.length === 1) ? (
                <div className="button-delete">
                  <Button
                    danger
                    disabled={!items || items.length === 0}
                    onClick={onClickRemove}
                    style={{width: 125}}
                  >
                    <FontAwesomeIcon
                      className="fa-md"
                      icon={['far', 'trash-alt']}
                    />
                    <Spaner width="sm"/>
                    DELETE
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="content-manager_container-right">
            <div className="content-manager_form">
              <div className="content-manager_form-header">
                <div className="content-manager_form-header-h1">{title}</div>
                <div className="content-manager_form-header-h2">
                  Recommended Size
                  {' '}
                  {_.get(config, 'recommended_size.width', 1920)}
                  px x
                  {' '}
                  {_.get(config, 'recommended_size.height', 1080)}
                  px
                </div>
              </div>
              <div className="content-manager_form-body">
                {
                  renderDynamicFormInputText(
                    imageTitleFormRef,
                    'modifiedName',
                    imageTitle,
                    "Image Title",
                    [
                      {
                        name: 'modifiedName',
                        callBackFunction: updateCurrentDefaultValue,
                      },
                    ]
                  )
                }
                {config.show_caption === true ? (
                  renderDynamicFormInputText(
                    captionFormRef,
                    'caption',
                    caption,
                    "Image Caption",
                    [
                      {
                        name: 'caption',
                        callBackFunction: updateCurrentDefaultValue,
                      },
                    ],
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {limit > 1 ? (
          <div className="content-manager_section content-manager_section-bottom">
            <div className="content-manager_section-bottom-left">
              <div className="content-manager_section-bottom-container">
                <CarouselMedia
                  items={items}
                  defaultValue={defaultValue}
                  current={currentImage}
                  acceptable={acceptable}
                  updateDefaultValueItem={updateDefaultValueItemFromInputFile}
                  onClickItem={onClickCarouselImage}
                  onMoveCard={onMoveCard}
                />
              </div>
            </div>
            <div className="content-manager_section-bottom-right">
              {getAddButton()}
            </div>
          </div>
        ) : null}

        <div className="content-manager_footer">
          <Button onClick={onCancel} className="content-manager_form-button">
            Cancel
          </Button>
          <Button
            onClick={onSubmitChangeImageCarousel}
            type="primary"
            className="content-manager_form-button"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
