import {
  BREADCRUMB_SET,
  SAVE_SOCKET_INSTANCE,
  TEST_CHANGE,
  BROADCAST_NEW_MOBILE_HEADER,
  INITIALIZE_S3_CACHE
} from "./actionTypes";

export const breadcrumbSet = (breadcrumbs, onSuccess?, onFail?) => ({
  type: BREADCRUMB_SET,
  payload: {
    breadcrumbs: breadcrumbs
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const saveSocketInstance = (instance) => ({
  type: SAVE_SOCKET_INSTANCE,
  payload: { socket_instance: instance }
});

export const testChange = (val) => ({
  type: TEST_CHANGE,
  payload: { val: val }
});

export const broadcastNewMobileHeader = (
  mobile_state,
  onSuccess?,
  onFail?
) => ({
  type: BROADCAST_NEW_MOBILE_HEADER,
  payload: { mobile_state: mobile_state },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const initializeS3Cache = (data) => ({
  type: INITIALIZE_S3_CACHE,
  payload: data
});
