import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { transitions } from "./monterrey_settings";

export const monterrey_layout: IFolderType = {
  folder_category: FOLDER_CATEGORY.LAYOUT,
  name: FOLDER_TYPE.monterrey_layout,
  display_name: "Layout",
  acceptable_folder_types: [
    FOLDER_TYPE.monterrey_story_media_with_quote,
    FOLDER_TYPE.monterrey_story_with_fact,
    FOLDER_TYPE.monterrey_fullscreen_fact_story,
    FOLDER_TYPE.monterrey_profile_with_media_story,
    FOLDER_TYPE.monterrey_story_with_media,
    FOLDER_TYPE.monterrey_intro_story,
    FOLDER_TYPE.monterrey_media_blank_1,
    FOLDER_TYPE.monterrey_media_blank_2,
    FOLDER_TYPE.monterrey_media_blank_3,
    FOLDER_TYPE.monterrey_media_blank_4,
    FOLDER_TYPE.monterrey_media_blank_5,
    FOLDER_TYPE.monterrey_donor_list,
    FOLDER_TYPE.monterrey_content_donor_lists
  ],
  children_conditions: [
    { type: "slot_limit", value: 5 },
    { type: "slot_limit_skip_validation", value: true }
  ],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Default Transition",
      validation: { rules: [{ name: "isRequired" }] },
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_transition"
      }
    },
    background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_background_image_overlay"
      }
    },
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_background_image"
      }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 2.8125,
    component_name: COMPONENT_NAME.Monterrey5x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 5, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false,
  warn_moving_to_presentation_without_children: true
};
