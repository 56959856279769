import React, { Component } from "react";
import { connect } from 'react-redux';
import { Table, Button, Modal } from "antd";
import { hasPermission } from "../../utils/permissionUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { delete_location } from "../../actions/locationActions";
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from "../../hooks/withRouter";

class Locations extends Component {
  constructor(props) {
    super(props);
    this._company_id = Number(this.props.params.companyId);
    this.state = {
      confirm_delete_modal_visible: false, 
      selected_location_id: null
    }
  }

  onDeleteClick = (location_id) => {
    this.setState({
      confirm_delete_modal_visible: true,
      selected_location_id: location_id
    });
  }

  modalOnCancel = () => {
    this.setState({
      confirm_delete_modal_visible: false,
      selected_location_id: null
    }); 
  }

  modalOnOk = () => {
    if(Object.values(this.props.projects).filter(x => x.location === this.state.selected_location_id).length > 0) {
      AlertNotification('error', 'Error', "This location has projects associated and can't be deleted")
    } else {
      this.props.delete_location(this.state.selected_location_id, this.onDeleteSuccess, this.onDeleteFail);
    }
    this.setState({
      confirm_delete_modal_visible: false,
      selected_location_id: null
    });
  }

  onDeleteSuccess = () => {
    AlertNotification('success', 'Success', 'The location has been deleted successfully')
  }

  onDeleteFail = () => {
    AlertNotification('error', 'Error', 'The location could not been deleted')
  }

  render() {
    let locationTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          a.name = a.name || '';
          b.name = b.name || '';
          return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1
        }
      }, {
        title: 'Show in tree?',
        dataIndex: 'shows_in_tree',
        key: 'shows_in_tree'
      }
    ];


    let array = [];

    if(this.props.locations) {
      array = Object.values(this.props.locations).filter(x => x.company === this._company_id).map(x => ({
        key: x.id,
        id: x.id,
        name: x.name,
        shows_in_tree: x.shows_in_tree.toString()
      }));
    }

    if (hasPermission(this.props.grouped_permissions, 'location', 'update', this.props.user, null, null, this.props.user?.company ?? null)) {
      locationTableColumns.push({
        title: 'Edit',
        dataIndex: 'edit',
        render: (text, record) => {
          let url = '/company/' + this._company_id + '/location/' + record.id
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => { this.props.navigate(url); }}>
              <FontAwesomeIcon className='fa-lg' icon='edit' />
            </span>
          )
        }
      })
    }

    if (hasPermission(this.props.grouped_permissions, 'location', 'delete', this.props.user, null, null, this.props.user?.company ?? null)) {
      locationTableColumns.push({
        title: 'Delete',
        dataIndex: 'delete',
        render: (text, record) => ( 
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => this.onDeleteClick(record.id)}>
            <FontAwesomeIcon className='fa-lg' icon={['far', 'trash']} />
          </span>
        )
      })
    }

    const new_location_url = '/company/' + this._company_id + '/location/new'

    return (
      <div>
        <Modal
          visible={this.state.confirm_delete_modal_visible}
          onCancel={this.modalOnCancel}
          onOk={this.modalOnOk}
          >
          <span>Do you really want to delete this location?</span>
        </Modal>
        <Table
          style = {{margin: 0}}
          title = {() => hasPermission(this.props.grouped_permissions, 'location', 'create', this.props.user, null, null, this.props.user?.company ?? null) ?
            <Button onClick={() => this.props.navigate(new_location_url)}>Add Location</Button> : <b>Locations</b>
          }
          rowKey={record => record.key}
          columns={locationTableColumns}
          dataSource={array}
          pagination={false}
          bordered={false}
          />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  grouped_permissions: state.data.user_permissions_grouped,
  user: state.data.user,
  locations: state.data.locations,
  projects: state.data.projects
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  delete_location: (location_id, onSuccess, onFail) => {
    dispatch(delete_location(location_id, onSuccess, onFail))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));