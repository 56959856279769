
export default {
  applied_content: {
    title: "Applied Content",
    scopes: ["company", "project"]
  },
  company: {
    title: "Company",
    scopes: ["company"]
  },
  resource: {
    title: "Resource",
    scopes: ["company"]
  },
  user: {
    title: "User",
    scopes: ["company"]
  },
  folder: {
    title: "Folder",
    scopes: ["company", "project"]
  },
  field: {
    title: "Field",
    scopes: ["company", "project"]
  },
  backup: {
    title: "Backup",
    scopes: ["company", "project"]
  },
  csv: {
    title: "CSV",
    scopes: ["company", "project"]
  },
  location: {
    title: "Location",
    scopes: ["company", "project"]
  },
  audit_log: {
    title: "Audit log",
    scopes: ["company", "project"]
  },
};


