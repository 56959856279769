import React from 'react'
import RichTextRender from "../../standard/RichTextRender";

const EverettDonorText = (props) => {
  return (
    <>
      <div className="quote-top-screen-wrapper"
        style={{
          width: '100%',
          height: '50%'
        }}
      >
        <div className="quote-padding-top-screen"
          style={{
            height: '100%',
            padding: '10em 15em 1em'
          }}
        >
          <div className="quote-wrapper-top-screen"
            style={{
              display: 'flex',
              overflow: 'hidden',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <div className="quote-header"
              style={{
                marginBottom: '1em',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <RichTextRender htmlString={props.header} />
            </div>
            <div className="quote-body" style={{
              lineHeight: '1.75'
            }}>
              <RichTextRender htmlString={props.text_top_screen} />
            </div>
          </div>
        </div>
      </div>
      <div className="quote-bottom-screen-wrapper"
         style={{
           width: '100%',
           height: '50%'
         }}
      >
        <div className="quote-padding-bottom-screen"
          style={{
            height: '100%',
            padding: '1em 15em 10em'
          }}
        >
          <div className="quote-wrapper-bottom-screen"
            style={{
              display: 'flex',
              overflow: 'hidden',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div className="quote-body"
              style={{
                lineHeight: '1.75'
              }}
            >
              <RichTextRender htmlString={props.text_bottom_screen} />
            </div>
            <div className="quote-attribution"
              style={{
                width: '100%',
                marginTop: '2em'
              }}
            >
              <RichTextRender htmlString={props.attribution} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EverettDonorText
