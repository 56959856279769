import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";

interface ITextModalProps {
  text: string;
}

const TextModal: React.FC<ITextModalProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <a href="#" onClick={() => setModalOpen(true)}>
        {props.text}
      </a>
      <Modal
        width={360}
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        title={<React.Fragment>View</React.Fragment>}
      >
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {props.text}
        </div>
      </Modal>
    </>
  );
};

export default TextModal;
