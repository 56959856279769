import { Radio } from "antd";
import get from "lodash/get";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { COMPONENT_MODE_CONTEXT } from "../../../shared-global/enums/config-enums";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../utils/commonDataUtils";
import { getResourceFoldersOfFileDynamic } from "../../utils/fileUtils";
import { bestFit } from "../../utils/screenUtils";
import Slide1911 from "./Slide1911";
import Slide1911Placeholder from "./Slide1911Placeholder";


const calculateStyles = (accent_color) => {
  const styles = {
    radioDots: {
      "& .ant-radio-inner, & .ant-radio-checked::after": {
        borderColor: `${accent_color}`
      },
      "& .ant-radio-inner::after, & .ant-radio-inner::after": {
        backgroundColor: `${accent_color}`
      },
      "& .ant-radio-wrapper:hover .ant-radio, & .ant-radio:hover .ant-radio-inner, & .ant-radio-input:focus + .ant-radio-inner":
        {
          borderColor: `${accent_color}`
        },
      "& .ant-radio-button-wrapper:hover": {
        color: `${accent_color}`
      }
    }
  };

  return styles;
};

const ImagesCarousel2103: React.FC<IDisplayComponentProps> = (props) => {
  const initialContainerWidth = props.containerWidth || window.innerWidth;
  const initialContainerHeight = props.containerHeight || window.innerHeight;
  const preset = props.mode === "preview" ? "preview" : null;
  const [slotNames, setSlotNames] = useState(
    props.data.slotNames ?? ["file", "slide"]
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const accent_color = props.inheritable_fields?.accent_color || "#1890ff";
  // Callback wrapped functions for performance.

  const handleKeyDownCallback = useCallback((e) => {
    switch (e.key) {
      case "ArrowLeft": //left
        break;
      case "ArrowUp": //up
        break;
      case "ArrowRight": //right
        if (props.handleEndOfPlay) {
          props.handleEndOfPlay();
        }
        break;
      case "ArrowDown": //down
        break;
      default:
        break;
    }
  }, []);

  const useStyles = createUseStyles(
    calculateStyles(props.inheritable_fields?.accent_color || "#1890ff")
  );
  const classes = useStyles();

  const getBaseFolderCallback = useCallback(() => {
    return getBaseFolder(props.data);
  }, [props.data]);

  const getBestFitCallback = useCallback(() => {
    return bestFit(
      initialContainerWidth,
      initialContainerHeight,
      initialContainerWidth,
      initialContainerHeight
    );
  }, [initialContainerWidth, initialContainerHeight]);

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      slotNames,
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const baseFolder = getBaseFolderCallback();
  const dims = getBestFitCallback();

  const [width, setWidth] = useState(dims.width);
  const [height, setHeight] = useState(dims.height);
  const [containerWidth, setContainerWidth] = useState(initialContainerWidth);
  const [containerHeight, setContainerHeight] = useState(
    initialContainerHeight
  );

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  // Clearing timeouts and listeners.
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDownCallback);
    return () => {
      // clearTimeout(cycleTimeout);
      // clearTimeout(initPlayerTimeout);
      // clearTimeout(positionVideoTimeout);
      document.removeEventListener("keydown", handleKeyDownCallback);
    };
  }, []);

  // Check for best fit dimensions.
  useEffect(() => {
    const dims = getBestFitCallback();
    setWidth(dims.width);
    setHeight(dims.height);
  }, [props.containerHeight, props.containerWidth]);

  if (!baseFolder) {
    return null;
  }

  const renderCarouselDots = (dots) => {
    return (
      <div
        style={{
          padding: 0,
          bottom: height * 0.08
        }}
      >
        <ul style={{ margin: "0" }}> {dots} </ul>
      </div>
    );
  };

  const renderCarouselCustomPaging = (i) => {
    return (
      <Radio
        className={classes.radioDots}
        style={{
          color: "red",
          width: width * 0.09
        }}
        checked={i === currentSlide}
      ></Radio>
    );
  };

  if (props.mode === COMPONENT_MODE_CONTEXT.PREVIEW) {
    const placeholder = get(props, "data.placeholder", "placeholder");
    const placeholderBackground = get(
      props,
      "data.placeholderBackground",
      false
    );
	  const showPlaceholder = props?.data?.persistent_settings?.data?.show_placeholder || false;
    const fieldType = get(props, "data.fieldType", null);

    // show place holder
    if (
      showPlaceholder &&
      (!resourceFoldersCarousel ||
        (resourceFoldersCarousel && resourceFoldersCarousel.length === 0))
    ) {
      return (
        <Slide1911Placeholder
          containerHeight={height}
          containerWidth={width}
          placeholder={placeholder}
          background={placeholderBackground}
          fieldType={fieldType}
          systemSettings={props.data.system_settings}
        />
      );
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: width,
        height: height
      }}
    >
      <Slider
        className={"image-carousel-2103"}
        dots={true}
        infinite={true}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        width={width}
        height={height}
        adaptativeHeight={true}
        variableWidth
        style={{
          width: width,
          height: height
        }}
        appendDots={renderCarouselDots}
        customPaging={renderCarouselCustomPaging}
        beforeChange={(prev, next) => {
          setCurrentSlide(next);
        }}
      >
        {resourceFoldersCarousel.map((rf, index) => (
          <div key={`image-carousel-2103_key-${index}`}>
            <div
              style={{
                width: width,
                height: height
              }}
            >
              <Slide1911
                {...props}
                data={{
                  ...props.data,
                  index: index,
                  positionRelative: true,
                  slotNames: ["image_1"],
                  placeholder: "image 1",
                  fieldType: "image",
                  showPlaceholder: "placeholder"
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImagesCarousel2103;
