import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const coburg_grouped_presentations: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.coburg_grouped_presentations,
  display_name: "Coburg Grouped Presentations",
  acceptable_folder_types: [FOLDER_TYPE.coburg_presentation],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: false,
      order: 0,
      tab: "general"
    },
    defaults: {
      title: "Defaults",
      default_expanded: false,
      order: 0,
      tab: "defaults"
    },
    menu_settings: {
      title: "Menu Settings",
      default_expanded: false,
      order: 0,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    background: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    color_overlay: {
      type: "color",
      title: "Default Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    menu_container_margin_top: {
      title: "Menu Container Margin Top",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_margin_left: {
      title: "Menu Container Margin Left",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 0,
      field_group: "menu_settings"
    },
    menu_container_height: {
      title: "Menu Container Height",
      type: "integer",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 50,
      field_group: "menu_settings"
    },
    menu_container_width: {
      title: "Menu Container Width",
      type: "integer",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      default: 80,
      field_group: "menu_settings"
    },
    menu_item_vertical_separation: {
      title: "Menu Item Vertical Separation",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 9,
      field_group: "menu_settings"
    }
  },
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.CoburgContainer,
    type: PREVIEW_TYPE.COMPONENT
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  }
};
