import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { textStyleOptions } from "./coburg_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const coburg_search: IFolderType = {
  folder_category: FOLDER_CATEGORY.CONTENT,
  name: FOLDER_TYPE.coburg_search,
  display_name: "Search",
  acceptable_folder_types: [],
  fields: {
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    results_font_style: {
      type: "textstyle",
      title: "Results Font Style",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35
      },
      options: textStyleOptions
    },
    accent_color: {
      type: "color",
      title: "Accent Color",
      default: "",
      isVisible: () => false,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.CoburgSearch,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.CoburgSearch
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 300,
    columns: 2
  }
};
