import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./berkeley_settings";

export const berkeley_presentation_3x2: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.berkeley_presentation_3x2,
  display_name: "Presentation 3x2",
  acceptable_folder_types: [
    FOLDER_TYPE.berkeley_donor_sea,
    FOLDER_TYPE.berkeley_donor_list,
    FOLDER_TYPE.berkeley_donor_list_group,
    FOLDER_TYPE.berkeley_template_001,
    FOLDER_TYPE.berkeley_template_002,
    FOLDER_TYPE.berkeley_template_003,
    FOLDER_TYPE.berkeley_template_004,
    FOLDER_TYPE.berkeley_template_005,
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions],
    },
  },
  preview: {
    aspect_ratio: 2.666666666666667,
    component_name: COMPONENT_NAME.BerkeleyContainer,
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    hide_border: true,
    screen_sets: [
      { name: "unique", orientation: "landscape", horizontal: 3, vertical: 2 },
    ],
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation,
    },
    closed: {
      ...tree_icon_types.presentation,
    },
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  isPresentation: true,
};
