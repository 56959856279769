import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generateFontSizeClassesFromFields, getFieldValue } from '../../../utils/generalUtils';
import RichTextRender from '../../standard/RichTextRender';
import {COMPONENT_NAME, FOLDER_TYPE} from '../../../../shared-global/enums/folder-type-enums';
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import { createUseStyles } from "react-jss";
import { getBaseFolder, getBaseFolderType, getChildrenData } from '../../../utils/commonDataUtils';
import get from 'lodash/get';
import { IFolder } from '../../../../shared-global/interfaces/models/folder.interface';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DonorSea2106 from "../../standard/DonorSea2106";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import {useFolder} from "../../../hooks/useFolder";
import {useScale} from "../../../hooks/useScale";


const PhoenixDonorSea = (props) => {

  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } = useFolder(props.data.base_folder);
  const { getBaseFolderFontSize, getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);

  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const fontSize = getBaseFolderFontSize();
  const useStyles = getBaseFolderRichTextStylesFn();
  const classes = useStyles();

  const [useColorOverlay, setUseColorOverlay] = useState(false);
  const [horizontalColorOverlay, setHorizontalColorOverlay] = useState(null);
  const [headerEl, setHeaderEl] = useState(null)
  const [backgroundCycleTimes, setBackgroundsCycleTime] = useState([]);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState();
  const [currentBackgroundTimeLeft, setCurrentBackgroundTimeLeft] = useState();
  const [backgroundsFolder, setBackgroundsFolder] = useState<IFolder>(null);
  const [error, setError] = useState(-1);

  // The following variable is governed by the donor sea and it's set to true when the donor sea finishes
  // prerendering donors and calculating theirs height and width so the backgrounds start playing at the
  // right time which only happens once (when the donor sea is mounted)
  const [canStartPlayingBackgroundsLoop, setCanStartPlayingBackgroundsLoop] =
    useState(false);

  useEffect(() => {
    const childFolders = getChildrenData(
      props.data,
      baseFolder.id,
      true
    ).folders;
    const backgroundsFolder = childFolders.find(
      (f) => f.folder_type === FOLDER_TYPE.phoenix_donor_sea_backgrounds
    );

    if (!backgroundsFolder) {
      setError(0);
      return;
    }

    const foundHorizontalColorOverlay = get(
      baseFolder,
      "fields.horizontal_color_overlay",
      null
    );

    if (foundHorizontalColorOverlay) {
      setHorizontalColorOverlay(foundHorizontalColorOverlay);
      setUseColorOverlay(true);
    }

    setBackgroundsFolder(backgroundsFolder);

    // Array of each individual background.
    const backgrounds = getChildrenData(
      props.data,
      backgroundsFolder.id,
      true
    ).folders;

    const cycleTimes = backgrounds.reduce((acc, curr) => {
      const cycleTime = get(curr, "fields.cycle_time", 5) * 1000;
      acc = [...acc, cycleTime];
      return acc;
    }, []);

    setBackgroundsCycleTime(cycleTimes);
  }, []);


  const handleEndOfPlay = (data) => {
    const persistentData = {
      id: baseFolder.id,
      type: "donor_sea",
      data: { donor_index: data }
    };
    if (props.handleEndOfPlay) {
      props.handleEndOfPlay("forward", persistentData);
    }
  };

  if (!baseFolder) return null

  const horizontalRule = getBaseFolderFieldValue("horizontal_rule")
  const horizontalRuleSize = getBaseFolderFieldValue("horizontal_rule_size")
  const horizontalRuleColor = getBaseFolderFieldValue("horizontal_rule_color")
  const header = getBaseFolderFieldValue("header", baseFolderType)
  const description = getBaseFolderFieldValue("description", baseFolderType)
  const category = getBaseFolderFieldValue("category", baseFolderType)
  const backgroundColorOverlay = getBaseFolderFieldValue('background_image_overlay', '');
  const horizontalScreens = get(props, 'data.componentSpecificData.horizontal_screens') ?? 3
  const screen_left_and_right_padding = getBaseFolderFieldValue('screen_left_and_right_padding', 3);
  const screen_top_and_bottom_padding = getBaseFolderFieldValue('screen_top_and_bottom_padding', 3);

  const cssTransitionKey = useColorOverlay ? "true" : "false";

  return (
    <>
      {/* Donor Sea Backgrounds Loop */}
      {backgroundsFolder ? (
        <ComponentLoop1911
          {...props}
          data={{
            ...props.data,
            base_folder: backgroundsFolder.id,
            slidesSlotName: ["image"],
            componentSpecificData: {
              isCycleTimeBasedOnComputerClock: false,
              setCurrentSlideIndexCallback: setCurrentBackgroundIndex,
              setCurrentSlideTimeLeftCallback: setCurrentBackgroundTimeLeft,
              showPlaceholder: true,
              useDistinctFolders: true
            }
          }}
          handleEndOfPlay={null}
        />
      ) : null}
      {/* Background Overlay */}
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={1000}
          classNames="slowfadedelay"
        >
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              backgroundColor: horizontalColorOverlay,
            }}
          />
        </CSSTransition>
      </TransitionGroup>

      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColorOverlay,
        }}
      />

      <div
        ref={el => setHeaderEl(el)}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: (100 / horizontalScreens) + '%',
          paddingLeft: `${screen_left_and_right_padding / 2}%`,
          paddingRight: `${screen_left_and_right_padding / 2}%`,
          paddingTop: `${screen_top_and_bottom_padding / 2}%`
        }}
      >

        {
          (header || description || category) && (
            <div
              style={{
                padding: '1em'
              }}
              className={classes.componentContainer}
            >
              <div className={classes.componentContainer}>
                <RichTextRender htmlString={header} />
              </div>
              <div
                className={classes.componentContainer}
                style={{
                  marginTop: fontSize * 1.5
                }}
              >
                <RichTextRender htmlString={description} />
              </div>
              <div
                className={classes.componentContainer}
                style={{
                  marginTop: fontSize * 3
                }}
              >
                <RichTextRender htmlString={category} />
              </div>
            </div>
          )
        }
        {horizontalRule &&
        <div
          className="upper-bar"
          style={{
            width: '100%'
          }}
        >
          <hr
            style={{
              backgroundColor: horizontalRuleColor,
              border: "0 none",
              color: horizontalRuleColor,
              height: props.containerWidth * (horizontalRuleSize / 100),
              marginTop: "0.5em",
            }}
          />
        </div>
        }
      </div>
        {/* Donor Sea */}
        {
          headerEl && (
            <DonorSea2106
              {...props}
              data={{
                ...props.data,
                componentSpecificData: {
                  ...props.data.componentSpecificData,
                  isCycleTimeBasedOnComputerClock: false,
                  setFinishedPrerenderCallback: () => {
                    setCanStartPlayingBackgroundsLoop(true);
                  },
                  cycleTimes: backgroundCycleTimes,
                  currentWaveCycleTimeIndex: currentBackgroundIndex,
                  currentWaveTimeLeft: currentBackgroundTimeLeft,
                  categoriesFolderType: FOLDER_TYPE.phoenix_donor_sea_categories,
                  headerHeight: (header || description || category || horizontalRule) ? headerEl.offsetHeight : 0
                }
              }}
              handleEndOfPlay={props.handleEndOfPlay ? handleEndOfPlay : null}
            />
          )
        }
    </>
  )
}

export default PhoenixDonorSea
