import React, { CSSProperties, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Background } from "../Background";
import { Container } from "../Container";
import { IResourceFolder } from "../../../../shared-global/interfaces/models/resource__folder.interface";
import { CheckerboardGrid } from "./CheckerboardGrid";
import { fisherYatesShuffle } from "../../../utils/commonDataUtils";
import { loopIndex } from "../../../../shared-global/utils/general";
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";

export type CheckerboardContentType =
  | "slide"
  | "inspirationalWord"
  | "relatedWord";

export type CheckerboardGridType = CheckerboardContentType[][];

export type CheckerboardProps = {
  componentData: any;
  grids: CheckerboardGridType[];
  backgroundColorOverlay: string;
  backgroundSlots: string[];
  slides: IResourceFolder[];
  slideSlot: string;
  wordsColorOverlay: string;
  inspirationalWords: string[];
  inspirationalWordsStyle: CSSProperties;
  relatedWords: string[];
  relatedWordsStyle: CSSProperties;
  handleEndOfPlay?: () => void;
};

export const Checkerboard = (props: CheckerboardProps) => {
  const [grid, setGrid] = useState<CheckerboardGridType>();
  const [visible, setVisible] = useState(false);

  const totalCycleTime =
    (props.grids[0][0].length * props.grids[0].length + 3) * 1000;

  const swapGrid = (index) => {
    setVisible(false);
    setGrid(props.grids[index]);
    const nextIndex = loopIndex(index, 0, props.grids.length - 1, "forward");
    setTimeout(() => setVisible(true), 1000);
    if (nextIndex === 0 && props.handleEndOfPlay) {
      setTimeout(() => props.handleEndOfPlay(), totalCycleTime);
    } else {
      setTimeout(() => swapGrid(nextIndex), totalCycleTime);
    }
  };

  useEffect(() => {
    swapGrid(0);
  }, []);

  if (!grid) return null;

  return (
    <Container>
      <Background
        componentData={props.componentData}
        slotNames={["background_image"]}
        backgroundColorOverlay={props.backgroundColorOverlay}
      />
      <TransitionGroup>
        {visible && (
          <CSSTransition
            key={`index-${props.grids.indexOf(grid)}`}
            timeout={1000}
            classNames={TRANSITION_CLASS.TRASITION_FADE}
          >
            <CheckerboardGrid
              componentData={props.componentData}
              grid={grid}
              slides={props.slides}
              slideSlot={props.slideSlot}
              wordsColorOverlay={props.wordsColorOverlay}
              inspirationalWords={fisherYatesShuffle(props.inspirationalWords)}
              inspirationalWordsStyle={props.inspirationalWordsStyle}
              relatedWords={fisherYatesShuffle(props.relatedWords)}
              relatedWordsStyle={props.relatedWordsStyle}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </Container>
  );
};
