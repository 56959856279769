import { connect } from "react-redux";
import React, { Component } from 'react';
import _ from 'lodash';
import DragDropTree from '../tree/DragDropTree';
import { moveResourceFolder, setFoldersExpanded, toggleFolderExpandedMoveBulkModal } from '../../actions/folderActions';
import { setClientOptions } from '../../reducers/clientOptionsSlice';
import { getAppliedTreeData } from '../../selectors/folderSelectors';
import {TREE_MODE_APPLIED_CONTENT, TREE_MODE_SELECT_APPLIED_CONTENT, TREE_MODE_SELECT_RESOURCE} from '../constants';
import {handleTreeSearch} from '../../services/treeService'
class SelectAppliedContentModalTree extends Component {
  constructor(props) {
    super(props);
    this._searchString = null;
    this._previous_client_opts_treemode = null;
    this._searchFoldersExpanded = [];
    this._searchFolderFolders = Object.values(props.folder__folders);
  }

  onFilter = (filters) => {
    //console.log('filters in resource tree', filters)
  }

  handleToggleFolderExpanded = (treeId, metaData) => {
    if(treeId.startsWith('company-')) {
      const company = this.props.companies[treeId.replace('company-', '')]
      this.props.onSelectFolder(company);
    }
    if(treeId.startsWith('location-')) {
      const location = this.props.locations[treeId.replace('location-', '')]
      this.props.onSelectFolder(location);
    }
    else if(treeId.startsWith('project-')) {
      const project = this.props.projects[treeId.replace('project-', '')]
      this.props.onSelectFolder(project);
    }
    else {
      const folder = this.props.treeData.find((td) => td.id === Number(treeId.replace('folder-', '')));
      this.props.onSelectFolder(folder, metaData);
    }
    this.props.toggleFolderExpandedMoveBulkModal(treeId);
  }

  renderLegend() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alighItems: 'flex-start'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
          <div style={{width: '10px', height: '10px', marginRight: '.5em', backgroundColor: '#2699FB'}} />
          <span style={{color: '#2699FB'}}>Compatible</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
          <div style={{width: '10px', height: '10px', marginRight: '.5em', backgroundColor: '#808080'}} />
          <span style={{color: '#808080'}}>Non Compatible</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
          <div style={{width: '10px', height: '10px', marginRight: '.5em', backgroundColor: '#c39b13'}} />
          <span style={{color: '#c39b13'}}>Folder may have compatible folders</span>
        </div>
      </div>
    )
  }

  render() {
    let contentTree = this.props.treeData;
    const selectedTreeId = _.get(this.props.client_options, 'applied_content_tree_modal_selected_id', null);
    return (
      <>
        <DragDropTree
          toggleDetail={() => {}}
          onDrop={() => {}}
          treeData={contentTree}
          defaultIcons={this.props.tree_icon_types}
          toggleExpandedItem={(treeId, metaData) => { this.handleToggleFolderExpanded(treeId, metaData) }}
          searchText={this._searchString}
          onSearch={this.handleSearch}
          folders={this.props.folders}
          folderTypes={this.props.folder_types}
          mode={TREE_MODE_APPLIED_CONTENT} //TODO why different from modeContext?
          modeContext={TREE_MODE_SELECT_APPLIED_CONTENT}
          showSettings={false}
          onFilter={(filters) => {this.onFilter(filters)}}
          onSettings={this.showSearchSettings}
          inputMode={this.props.client_options.inputMode}
          updateSelectedTreeId={this.updateSelectedTreeId}
          folder__folders={this.props.folder__folders}
          selectedTreeId={selectedTreeId}
          width={552}
          resourcetreeFolders={this.props.resourcetreeFolders}
        />
        {this.props.appliedMode && (
          this.renderLegend()
        )}
      </>
  );
  }

  handleSearch = (value, modeContext) => {
    let result = handleTreeSearch(
      value,
      modeContext,
      this.props.folders,
      this.props.folder__folders,
      {},
      {},
      {},
      {},
      this.props.user.company,
      this.props.locations,
      this.props.projects,
    )
    this._searchString = result.cleanSearch;
    if (this._searchString){
      this.props.setFoldersExpanded(TREE_MODE_SELECT_APPLIED_CONTENT, result._searchFoldersExpanded)
    }
    else{
      this.setState({})
    }
    this.props.setClientOptions({search_string_select_applied_content: value})
  }

  updateSelectedTreeId = treeId => {
    if (treeId.startsWith('folder-')) {
      this.props.setClientOptions({
        applied_content_modal_selected_folder: Number(treeId.substring(7)),
        applied_content_tree_modal_selected_id: treeId
      });
    }
  }
}

const mapStateToProps = (state, ownProps) => {

  const appliedMode = state.client_options.treemode === TREE_MODE_APPLIED_CONTENT
  const appliedModeAction = ownProps.appliedModeAction

  let modState = {
    ...state,
    mode: ownProps.mode ? ownProps.mode : 'normal',
    expandedFolders: state.data.applied_content_modal_tree_expanded,
    appliedMode,
    selectedItems: ownProps.selectedItems,
    appliedModeAction
  }
  let treeData = getAppliedTreeData(modState);

  return {
    client_options: state.client_options,
    treeData: treeData,
    treemode: TREE_MODE_APPLIED_CONTENT,
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    folder_types: state.data.folder_types,
    searchString: state.client_options.applied_content_tree_modal_search,
    tree_icon_types: state.data.tree_icon_types,
    user : state.data.user,
    locations: state.data.locations,
    companies: state.data.companies,
    projects: state.data.projects,
    persistent_settings: state.data.persistent_settings,
    appliedMode
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleFolderExpandedMoveBulkModal: (treeId) => {
    dispatch(toggleFolderExpandedMoveBulkModal(treeId));
  },
  moveResourceFolder: (id, destId, actionType, onSuccess, onFail) => {
    dispatch(moveResourceFolder(id, destId, actionType, onSuccess, onFail));
  },
  setFoldersExpanded: (treeMode, treeIds) => {
    dispatch(setFoldersExpanded(treeMode, treeIds));
  },
  setClientOptions: (client_options) => {
    dispatch(setClientOptions(client_options))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectAppliedContentModalTree);

