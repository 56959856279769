import {
  FETCH_PROJECT_FOLDERS,
  MOVE_FOLDER,
  UPDATE_FOLDER,
  ADD_FOLDER,
  CLONE_FOLDER,
  REMOVE_FOLDER,
  TOGGLE_FOLDER_EXPANDED,
  TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL,
  MOVE_RESOURCE_FOLDER,
  SET_FOLDERS_EXPANDED,
  UPDATE_UPLOADED_RESOURCES,
  DOWNLOAD_FOLDER_CSV,
  UPLOAD_FOLDER_CSV,
  INSTANCE_FOLDER,
  PREVIEW_FOLDER,
  PUBLISH_FOLDER,
  MAKE_FOLDER_PULLABLE,
  DELETE_BULK_FOLDERS,
  BULK_MOVE_FOLDER,
  GET_FOLDER_INFO,
  GENERATE_CLIENT_CONFIG,
  DELETE_DISPLAY_IDENTIFIER,
  GET_DISPLAY_DATA,
  GET_FOLDER_STATUS,
  ADD_BULK_FOLDERS,
  UPDATE_BULK_FOLDERS,
  UPDATE_FOLDER_CONTENTS,
  START_POLL_FOLDER_STATUS,
  STOP_POLL_FOLDER_STATUS,
  FETCH_COMPANY_ROOT_FOLDERS,
  SET_UPDATE_FOLDER_TOUCH,
  SET_UPDATE_LIST_VIEW_TOUCH,
  GET_SIGNED_FOLDER_URLS,
  URI_ACCESS_GET_DATA,
  UPDATE_DISPLAY_HARDWARE_INFO,
  DOWNLOAD_HARDWARE_INFORMATION_CSV,
  DOWNLOAD_HARDWARE_INFORMATION_TXT,
  SET_FOLDER_TREE_INHERITABLE_SETTINGS,
  PULL_DOWN_INHERITABLE_FIELDS,
  PUSH_DOWN_INHERITABLE_FIELDS,
  UPDATE_FOLDER_ALONE,
  UPSERT_FOLDER_AR_MODEL,
  GET_FOLDER_AR_MODEL,
  CHECK_UPDATE_FOLDER_ALONE,
  UPDATE_RESOURCE_TREE_FOLDER,
  BULK_CLONE_FOLDER,
} from "./actionTypes";

import {
  IAction,
  IInstanceFolderPayload,
} from "../shared-global/interfaces/api/folder-payload-interfaces";
import { TREE_MODE_APPLIED_CONTENT } from "../components/constants";

export const uploadFolderCsv = (value, onProgress, onSuccess?, onFail?) => ({
  type: UPLOAD_FOLDER_CSV,
  payload: {
    value,
    request: { onProgress },
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadFolderCsv = (id, onSuccess?, onFail?) => ({
  type: DOWNLOAD_FOLDER_CSV,
  payload: {
    folder: id,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadHardwareInformationCsv = (
  id,
  display_index,
  type,
  onSuccess?,
  onFail?
) => ({
  type: DOWNLOAD_HARDWARE_INFORMATION_CSV,
  payload: {
    folder_id: id,
    display_index,
    type,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const downloadHardwareInformationTxt = (
  id,
  display_index,
  onSuccess?,
  onFail?
) => ({
  type: DOWNLOAD_HARDWARE_INFORMATION_TXT,
  payload: {
    folder_id: id,
    display_index,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateUploadedResources = (
  fileNames,
  folderId,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_UPLOADED_RESOURCES,
  payload: {
    fileNames,
    folderId,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const fetchProjectFolders = (type, onSuccess?, onFail?) => ({
  type: FETCH_PROJECT_FOLDERS,
  payload: {
    type,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const fetchCompanyRootFolders = (company_id, onSuccess?, onFail?) => ({
  type: FETCH_COMPANY_ROOT_FOLDERS,
  payload: {
    company_id,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const moveFolder = (
  origFolderFolderId,
  destFolderFolderId,
  treeType,
  orig_path,
  dest_path,
  moveAction,
  onSuccess?,
  onFail?
) => ({
  type: MOVE_FOLDER,
  payload: {
    orig_folder__folder_id: origFolderFolderId,
    dest_folder__folder_id: destFolderFolderId,
    orig_path,
    dest_path,
    tree_type: treeType,
    move_action: moveAction,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const moveResourceFolder = (
  origFolderFolderId,
  destFolderFolderId,
  destResourceFolderId,
  moveAction,
  onSuccess?,
  onFail?
) => ({
  type: MOVE_RESOURCE_FOLDER,
  payload: {
    orig_resource__folder_id: origFolderFolderId,
    dest_folder__folder_id: destFolderFolderId,
    dest_resource__folder_id: destResourceFolderId,
    move_action: moveAction,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const cloneFolder = (
  folder__folder_id,
  folder_name,
  clone_children,
  onSuccess,
  onFail
) => ({
  type: CLONE_FOLDER,
  payload: {
    folder__folder_id,
    folder_name,
    clone_children,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

// naming for content_folder_id?
export const addFolder = (
  folder_name,
  parent_folder__folder_id,
  type,
  tree_type,
  folder_path,
  onSuccess?,
  onFail?
) => ({
  type: ADD_FOLDER,
  payload: {
    folder_name,
    parent_folder__folder_id,
    type,
    tree_type,
    folder_path,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateFolder = (
  id,
  name,
  data,
  path,
  onSuccess?,
  onFail?,
  updateS3Cache = true,
  skipEffects = false
) => ({
  type: UPDATE_FOLDER,
  payload: { id: id, name: name, fields: { ...data }, updateS3Cache, path },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
  skipEffects,
});

export const updateResourceTreeFolder = (id, name, onSuccess?, onFail?) => ({
  type: UPDATE_RESOURCE_TREE_FOLDER,
  payload: { id: id, name: name },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const addBulkFolders = (data, onSuccess?, onFail?) => ({
  type: ADD_BULK_FOLDERS,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateBulkFolders = (data, onSuccess?, onFail?) => ({
  type: UPDATE_BULK_FOLDERS,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const removeFolder = (
  folder_id,
  folder__folder_id,
  type,
  origin_path,
  onSuccess?,
  onFail?,
  hasDeleteConfirmation = false
) => ({
  // naming?
  type: REMOVE_FOLDER,
  payload: {
    folder_id,
    folder__folder_id,
    type,
    origin_path,
    hasDeleteConfirmation,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const toggleFolderExpanded = (
  treeId,
  doOpenClose,
  doSelect,
  treemode,
  onSuccess?,
  onFail?
) => ({
  // naming?
  type: TOGGLE_FOLDER_EXPANDED,
  payload: {
    treeId,
    doOpenClose,
    doSelect,
    treemode: treemode,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const setFoldersExpanded = (treeMode, treeIds, onSuccess?, onFail?) => ({
  // naming?
  type: SET_FOLDERS_EXPANDED,
  payload: { treeMode, treeIds },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

//parent_folder_id, instanced_folder_ids, dest_folder_folder_id, spacer, moveIntoActionName, origin_path, destination_path

export const instanceFolder = (
  payload,
  onSuccess?,
  onFail?
): IAction<IInstanceFolderPayload> => ({
  type: INSTANCE_FOLDER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const previewFolder = (uuid, onSuccess?, onFail?) => ({
  type: PREVIEW_FOLDER,
  payload: { uuid },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getFolderInfo = (uuid, onSuccess?, onFail?) => ({
  type: GET_FOLDER_INFO,
  payload: { uuid },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const makeFolderPullable = (uuid, pullable, onSuccess?, onFail?) => ({
  type: MAKE_FOLDER_PULLABLE,
  payload: { uuid, pullable },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const publishFolder = (uuid, folderFolderPath, onSuccess?, onFail?) => ({
  type: PUBLISH_FOLDER,
  payload: { uuid, folderFolderPath },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteBulkFolders = (
  folder_id,
  folder__folder_id,
  type,
  onSuccess?,
  onFail?,
  hasDeleteConfirmation = false
) => ({
  type: DELETE_BULK_FOLDERS,
  payload: { folder_id, folder__folder_id, type, hasDeleteConfirmation },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const toggleFolderExpandedMoveBulkModal = (
  treeId,
  onSuccess?,
  onFail?
) => ({
  type: TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL,
  payload: { treeId },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const bulkMove = (
  destination_folder_id,
  folder__folder_ids,
  onSuccess?,
  onFail?
) => ({
  type: BULK_MOVE_FOLDER,
  payload: { destination_folder_id, folder__folder_ids },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const bulkClone = (
  destination_folder_id,
  folders_to_clone,
  onSuccess?,
  onFail?
) => ({
  type: BULK_CLONE_FOLDER,
  payload: { destination_folder_id, folders_to_clone },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const generateClientConfig = (folder_ids, onSuccess?, onFail?) => ({
  type: GENERATE_CLIENT_CONFIG,
  payload: { folder_ids },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const deleteDisplayIdentifier = (
  record_id,
  folder_id,
  onSuccess?,
  onFail?
) => ({
  type: DELETE_DISPLAY_IDENTIFIER,
  payload: { record_id: record_id, folder_id: folder_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getDisplayData = (folder_id, onSuccess?, onFail?) => ({
  type: GET_DISPLAY_DATA,
  payload: { folder_id: folder_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getFolderStatus = (company_id, onSuccess?, onFail?) => ({
  type: GET_FOLDER_STATUS,
  payload: { company: company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateFolderContents = (data, onSuccess?, onFail?) => ({
  type: UPDATE_FOLDER_CONTENTS,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const startFolderStatusPoll = (company_id, onSuccess?, onFail?) => ({
  type: START_POLL_FOLDER_STATUS,
  payload: { company: company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const stopFolderStatusPoll = () => ({
  type: STOP_POLL_FOLDER_STATUS,
});

export const setUpdateFolderTouch = (
  update_folder_touch,
  onSuccess?,
  onFail?
) => ({
  type: SET_UPDATE_FOLDER_TOUCH,
  payload: update_folder_touch,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const setUpdateListViewTouch = (
  update_list_view_touch,
  onSuccess?,
  onFail?
) => ({
  type: SET_UPDATE_LIST_VIEW_TOUCH,
  payload: update_list_view_touch,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const getSignedFolderUrls = (
  data: any,
  onSuccess: Function,
  onFail?: Function
) => ({
  type: GET_SIGNED_FOLDER_URLS,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const uriAccessGetData = (params, onSuccess?, onFail?) => ({
  type: URI_ACCESS_GET_DATA,
  payload: {
    params: params,
  },
  onSuccess: onSuccess,
  onFail: onFail,
});

export const updateDisplayHardwareInfo = (
  folder_id,
  display_index,
  type,
  data,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_DISPLAY_HARDWARE_INFO,
  payload: { folder_id, display_index, type, data },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const setFolderInheritableSettings = (
  settings,
  onSuccess?,
  onFail?
) => ({
  type: SET_FOLDER_TREE_INHERITABLE_SETTINGS,
  payload: { settings },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const pushDownInheritableFields = (
  folderId,
  fieldsToInherit,
  onSuccess?,
  onFail?
) => ({
  type: PUSH_DOWN_INHERITABLE_FIELDS,
  payload: { folderId, fieldsToInherit },
  onSuccess: onSuccess || null,
  onFail: onFail || null,
});

export const updateFolderAlone = (data, onSuccess?, onFail?) => ({
  type: UPDATE_FOLDER_ALONE,
  payload: data,
  onSuccess,
  onFail,
});

export const checkUpdateFolderAlone = (data, onSuccess?, onFail?) => ({
  type: CHECK_UPDATE_FOLDER_ALONE,
  payload: data,
  onSuccess,
  onFail,
});

export const upsertFolderArModel = (data, onSuccess?, onFail?) => ({
  type: UPSERT_FOLDER_AR_MODEL,
  payload: data,
  onSuccess,
  onFail,
});

export const getFolderArModel = (data, onSuccess?, onFail?) => ({
  type: GET_FOLDER_AR_MODEL,
  payload: data,
  onSuccess,
  onFail,
});
