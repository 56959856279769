import { call, put, all } from "redux-saga/effects";
import _ from "lodash";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import { normalize, schema } from "normalizr";
import {
  addPermissionValidationSchema,
  addRoleValidationSchema,
  createCompanyValidationSchema,
  deleteCompanyValidationSchema,
  deletePermissionValidationSchema,
  destroyRoleValidationSchema,
  getCompanyValidationSchema,
  updateCompanyValidationSchema,
  updatePermissionValidationSchema,
  updateRoleValidationSchema,
} from "../shared-global/validations/CompanyValidation";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";
const projectSchema = new schema.Entity("projects");
const userSchema = new schema.Entity("users");
const user__companySchema = new schema.Entity("user__companies");
const locationSchema = new schema.Entity("locations");
const permissionSchema = new schema.Entity("permissions");
const rolePermissionSchema = new schema.Entity("role__permissions");
const roleSchema = new schema.Entity("roles");
const companySchema = new schema.Entity("companies", {
  roles: [roleSchema],
  permissions: [permissionSchema],
});

const role__usersSchema = new schema.Entity("role__users");

async function getCompaniesAPI() {
  return Request("/company/companies", HTTP_METHODS.GET, {
    _: {},
  });
}

async function reindexElasticAPI() {
  return Request("/company/reindex_elastic", HTTP_METHODS.GET, {
    _: {},
  });
}

async function getCompanyAPI(payload) {
  return Request(
    "/company/getcompany",
    HTTP_METHODS.GET,
    { ...payload },
    getCompanyValidationSchema
  );
}

async function addCompaniesAPI(payload) {
  return Request(
    "/company/create",
    HTTP_METHODS.POST,
    { ...payload },
    createCompanyValidationSchema
  );
}

async function updateCompaniesAPI(payload) {
  return Request(
    "/company/update",
    HTTP_METHODS.POST,
    { ...payload },
    updateCompanyValidationSchema
  );
}
//Role api section
async function addRoleAPI(payload) {
  return Request(
    "/company/add_role",
    HTTP_METHODS.POST,
    { ...payload },
    addRoleValidationSchema
  );
}

async function updateRoleAPI(payload) {
  return Request(
    "/company/update_role",
    HTTP_METHODS.POST,
    { ...payload },
    updateRoleValidationSchema
  );
}

async function deleteRoleAPI(payload) {
  return Request(
    "/company/destroy_Role",
    HTTP_METHODS.POST,
    { ...payload },
    destroyRoleValidationSchema
  );
}

//Update Api Section
async function addPermissionAPI(payload) {
  return Request(
    "/company/add_permission",
    HTTP_METHODS.POST,
    { ...payload },
    addPermissionValidationSchema
  );
}

async function updatePermissionAPI(payload) {
  return Request(
    "/company/update_permission",
    HTTP_METHODS.POST,
    { ...payload },
    updatePermissionValidationSchema
  );
}

async function deletePermissionAPI(payload) {
  return Request(
    "/company/delete_permission",
    HTTP_METHODS.POST,
    { ...payload },
    deletePermissionValidationSchema
  );
}

async function deleteCompanyAPI(payload) {
  return Request(
    "/company/delete_company",
    HTTP_METHODS.POST,
    { ...payload },
    deleteCompanyValidationSchema
  );
}

const companySaga = {
  getCompanies: function* (action) {
    try {
      const response = yield call(getCompaniesAPI);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_COMPANIES_SUCCESS",
          normalized: normalize(response.data.companies, [companySchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_COMPANIES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getCompany: function* (action) {
    try {
      const response = yield call(getCompanyAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "GET_COMPANY_SUCCESS",
            normalized: normalize(
              response.data.companydetails.company,
              companySchema
            ),
          }),
          put({
            type: "FETCH_LOCATIONS_SUCCESS",
            normalized: normalize(response.data.companydetails.locations, [
              locationSchema,
            ]),
          }),
          put({
            type: "FETCH_PROJECTS_SUCCESS",
            normalized: normalize(response.data.companydetails.projects, [
              projectSchema,
            ]),
          }),
          put({
            type: "FETCH_USERS_SUCCESS",
            normalized: normalize(response.data.companydetails.users, [
              userSchema,
            ]),
          }),
          put({
            type: "FETCH_ROLE_PERMISSION_SUCCESS",
            normalized: normalize(
              response.data.companydetails.role__permissions,
              [rolePermissionSchema]
            ),
          }),
          put({
            type: "FETCH_ROLE__USERS_SUCCESS",
            normalized: normalize(response.data.companydetails.role__user, [
              role__usersSchema,
            ]),
          }),
          put({
            type: "FETCH_USER__COMPANIES_SUCCESS",
            normalized: normalize(
              response.data.companydetails.user__companies,
              [user__companySchema]
            ),
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_COMPANY_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_COMPANY_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  addCompany: function* (action) {
    try {
      const response = yield call(addCompaniesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_COMPANY_SUCCESS",
          normalized: normalize(response.data.company, companySchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_COMPANIES_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_COMPANIES_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  addRole: function* (action) {
    try {
      const response = yield call(addRoleAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_ROLE_SUCCESS",
          normalized: normalize(response.data.role, roleSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_ROLE_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_ROLE_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deleteRole: function* (action) {
    let response;
    try {
      response = yield call(deleteRoleAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_ROLE_SUCCESS",
          removeKeys: [{ entities: "roles", ids: [action.payload.roleId] }],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  addPermission: function* (action) {
    try {
      const response = yield call(addPermissionAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "ADD_PERMISSION_SUCCESS",
          normalized: normalize(response.data.permission, permissionSchema),
        });
        if (action.onSuccess) {
          action.onSuccess(response.data.permission.id);
        }
      } else {
        yield put({ type: "ADD_PERMISSION_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_PERMISSION_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updatePermission: function* (action) {
    try {
      const response = yield call(updatePermissionAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_PERMISSION_SUCCESS",
          normalized: normalize(response.data.permission, permissionSchema),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_PERMISSION_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_PERMISSION_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deletePermission: function* (action) {
    try {
      const response = yield call(deletePermissionAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "DELETE_PERMISSION_SUCCESS",
          removeKeys: [
            { entities: "permissions", ids: [action.payload.permissionId] },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_PERMISSION_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DELETE_PERMISSION_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateRole: function* (action) {
    try {
      const response = yield call(updateRoleAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_ROLE_SUCCESS",
          normalized: normalize(response.data.role, roleSchema),
        });
        yield put({
          type: "UPDATE_ROLE__PERMISSIONS_SUCCESS",
          normalized: normalize(response.data.role__permissions_added, [
            rolePermissionSchema,
          ]),
          removeKeys: [
            {
              entities: "role__permissions",
              ids: _.map(response.data.role__permissions_deleted, "id"),
            },
          ],
        });

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_ROLE_AND_ROLE_PERMISSIONS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_ROLE_AND_ROLE_PERMISSIONS_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateCompany: function* (action) {
    try {
      const response = yield call(updateCompaniesAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "UPDATE_COMPANY_SUCCESS",
          normalized: normalize(response.data.companies, [companySchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_COMPANY_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_COMPANY_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  deleteCompany: function* (action) {
    try {
      const response = yield call(deleteCompanyAPI, action.payload);
      let removeKeys = [];
      if (isResponseSuccess(response)) {
        if (response.data.removed_company) {
          removeKeys.push({
            entities: "companies",
            ids: response.data.removed_company,
          });
        }
        if (response.data.removed_folders) {
          removeKeys.push({
            entities: "folders",
            ids: response.data.removed_folders,
          });
          removeKeys.push({
            entities: "resourcetree_folders",
            ids: response.data.removed_folders,
          });
        }
        if (response.data.removed_folder__folders) {
          removeKeys.push({
            entities: "resourcetree_folder__folders",
            ids: response.data.removed_folder__folders,
          });
          removeKeys.push({
            entities: "folder__folders",
            ids: response.data.removed_folder__folders,
          });
        }
        if (response.data.removed_resource__folders) {
          removeKeys.push({
            entities: "resource__folders",
            ids: response.data.removed_resource__folders,
          });
          removeKeys.push({
            entities: "resourcetree_resource__folders",
            ids: response.data.removed_resource__folders,
          });
        }
        if (response.data.removed_resources) {
          removeKeys.push({
            entities: "resources",
            ids: response.data.removed_resources,
          });
        }
        if (response.data.removed_projects) {
          removeKeys.push({
            entities: "projects",
            ids: response.data.removed_projects,
          });
        }
        if (response.data.removed_role__permissions) {
          removeKeys.push({
            entities: "role__permissions",
            ids: response.data.removed_role__permissions,
          });
        }
        yield put({
          type: "DELETE_COMPANY_SUCCESS",
          removeKeys: removeKeys,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_COMPANY_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.response.data.data.detail || "Unexpected error ocurred"
          );
        }
      }
    } catch (ex) {
      yield put({ type: "DELETE_COMPANY_FAIL" });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  reindexElastic: function* (action) {
    try {
      const response = yield call(reindexElasticAPI);
      if (isResponseSuccess(response)) {
        yield put({ type: "REINDEX_ELASTIC_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "REINDEX_ELASTIC_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (ex) {
      yield put({ type: "REINDEX_ELASTIC_FAIL" });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  folderTypeAlert: function* (action) {
  }
};

export default companySaga;
