import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../interfaces/main-state.interface";
import {
  getContentLibraryResourceUrl,
  getFileType,
  getVideoThumbnailUrl
} from "../../../../shared/utils/fileUtils";
import { IResourceResourceFolder, MODE } from "../resource-explorer.interface";
import useStyles from "./styles";

export interface IResourceExplorerItemProps {
  width: number;
  height: number;
  selected: boolean;
  mode: MODE;
  resourceResourceFolder: IResourceResourceFolder;
}

const ResourceExplorerItem: React.FC<IResourceExplorerItemProps> = (props) => {
  const styleProps = {
    width: props.width,
    height: props.height,
    selected: props.selected,
    mode: props.mode,
  };
  const classes = useStyles({ theme: {}, ...styleProps });
  const { aws_instance } = useSelector((state: IState) => state.data);

  const type = useMemo(() => {
    return getFileType(props.resourceResourceFolder.resource.name);
  }, [props.resourceResourceFolder]);

  const icon: IconProp = useMemo(() => {
    switch (type) {
      case "image":
        return ["fas", "image"];
      case "video":
        return ["fas", "video"];
      default:
        return ["fas", "file"];
    }
  }, [props.resourceResourceFolder]);

  let url = null;
  if (type === "image") {
    url = getContentLibraryResourceUrl(
      props.resourceResourceFolder.resource__folder,
      aws_instance,
      process.env.REACT_APP_S3_BUCKET,
      "preview"
    ) as string;
  } else {
    url = getVideoThumbnailUrl(
      props.resourceResourceFolder.resource,
      aws_instance,
      process.env.REACT_APP_S3_BUCKET
    );
  }

  return (
    <div className={classes.item + ' grid-bg'}>
      <input type="checkbox" checked={props.selected} onChange={() => {}}/>
      {/* Video Play Overlay */}
      {type === "video" && (
        <div id="overlay">
          <img
            alt={"loading"}
            src="/images/play.png"
          />
        </div>
      )}
      {/* Image */}
      <div
        id="image"
        style={{
          background: `url(${url})`
        }}
      />
      {/* Footer */}
      <div id="footer" style={{backgroundColor: '#FFF', borderBottom: '1px solid rgb(204, 204, 204)'}}>
        <div id="icon">
          <FontAwesomeIcon className="fa-sm" icon={icon} />
        </div>
        <div id="text">
          {props.resourceResourceFolder.resource.modifiedName}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResourceExplorerItem);
