import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const marin_content_attract_loops: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  name: FOLDER_TYPE.marin_content_attract_loops,
  display_name: "Attract Loops",
  acceptable_folder_types: [FOLDER_TYPE.marin_control_panel_attract_loop],
  tree_icon_types: {
    open: {
      ...tree_icon_types.attract_loops
    },
    closed: {
      ...tree_icon_types.attract_loops
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.333,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false
};
