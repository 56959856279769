import {
  COMPONENT_NAME,
  FOLDER_TYPE, PREVIEW_TYPE, PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from '../../../../enums/folder-type-enums';
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { transitions, verticalJustificationOptions, QRPositionOptions, wysiwygSettings } from './phoenix_settings';
import { wysiwyg } from '../standardized_fields';
import { fonts, FontsFamilies, FontVariants } from '../../../../fonts';
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from '../../../../enums/ui-enums';

export const phoenix_story: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.phoenix_story,
  display_name: "Story",
  acceptable_folder_types: [],
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    story: {
      title: "Story",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    horizontal_rule: {
      title: "Horizontal Rule",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    vertical_bars: {
      title: "Vertical Accent Bars",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "Logo / QR Code",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 6,
      accordion: true,
      tab: "general"
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_transition'
      }
    },
    // Story Fields
    vertical_justification: {
      type: "select",
      title: "Story Text Position",
      field_group: "story",
      default: "top",
      options: verticalJustificationOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_vertical_justification'
      }
    },
    mirror_content: {
      type: "bool",
      title: "Mirror",
      default: false,
      field_group: "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_mirror_content'
      }
    },
    story_header: {
      title: "Header",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            align: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "80px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_subhead: {
      title: "Subhead",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            alignment: "right",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "68px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_body: {
      title: "Body",
      field_group: "story",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            alignment: "right",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "56px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_color: {
      type: "color",
      title: "Story Background Color",
      default: "#104E8ABF",
      field_group: "story",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_background_image: {
      title: "Story Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.185185,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.185185
      },
      field_group: "story",
    },
    // Horizontal Rule Fields
    horizontal_rule: {
      type: "bool",
      title: "Horizontal Rule",
      default: true,
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_horizontal_rule"
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.3,
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_horizontal_rule_size"
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "horizontal_rule",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_horizontal_rule_color"
      }
    },
    // Vertical bars
    left_bar: {
      type: "bool",
      title: "Left Vertical Accent Bar",
      default: false,
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    left_bar_size: {
      title: "Left Vertical Accent Bar Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2.0,
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    left_bar_color: {
      type: "color",
      title: "Left Vertical Accent Bar Color",
      default: "#F8981D",
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar: {
      type: "bool",
      title: "Right Vertical Accent Bar",
      default: false,
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar_size: {
      title: "Right Vertical Accent Bar Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2.0,
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    right_bar_color: {
      type: "color",
      title: "Right Vertical Accent Bar Color",
      default: "#F8981D",
      field_group: "vertical_bars",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // QR
    qr_position: {
      type: "select",
      isVisible: (values) => values.switch_to_logo !== 'none',
      title: "Position",
      field_group: "qr",
      default: "bottom-left",
      options: QRPositionOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_qr_position'
      }
    },
    switch_to_logo: {
      type: "select",
      title: "Logo or QR Code",
      default: "none",
      options: [
        { title: "None", value: "none" },
        { title: "Logo", value: "logo" },
        { title: "QR Code", value: "qrCode" }
      ],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      }
    },
    call_to_action_header: {
      title: "Call To Action Header",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "64px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_call_to_action_header"
      }
    },
    call_to_action_body: {
      title: "Call to Action Body",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "56px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_call_to_action_body"
      }
    },
    qr_link: {
      type: "text",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      title: "QR Code Link",
      show_char_count: false,
      field_group: "qr",
      validation: {
        rules: [
          //{ name: "isRequiredExclusiveBoolean", otherFieldName: 'switch_to_logo', message: "Please enter a valid link. I.E: https://www.google.com" },
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_qr_link"
      }
    },
    qr_color: {
      type: "color",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      title: "QR Color",
      default: "#ffffff",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_qr_color"
      }
    },
    qr_code_background_color: {
      type: "color",
      isVisible: (values) => values.switch_to_logo !== 'logo',
      title: "QR Background Color",
      default: "",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_qr_code_background_color"
      }
    },
    logo: {
      title: "Default Logo",
      isVisible: (values) => values.switch_to_logo === 'logo',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_logo"
      }
    },
    qr_background_color: {
      type: "color",
      isVisible: (values) => values.switch_to_logo !== 'none',
      title: "QR Container Background Color",
      default: "",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_qr_background_color"
      }
    },
    // Background
    background_color_overlay: {
      type: "color",
      title: "Background Image overlay",
      default: "",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: 'story_background_color_overlay'
      }
    },
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_full_background_image"
      }
    },
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
