import React from "react";

interface IFieldWrapper {
  children?: any;
  validationError?: string;
}

const FieldWrapper: React.FunctionComponent<IFieldWrapper> = (
  props: IFieldWrapper = { validationError: "" }
) => {
  return (
    <div className="form-field-wrapper">
      {props.children}
      {props.validationError ? (
        <div className="form-field-error">{props.validationError}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FieldWrapper;
