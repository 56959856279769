import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React from "react";
import Spaner from "../../utils/Spaner";
import {SizeType} from "antd/lib/config-provider/SizeContext";

interface ISubmitButton {
  disabled: boolean;
  title: string;
  submitting: boolean;
  size?: SizeType
}

const SubmitButton = (props: ISubmitButton) => {
  return (
    <Button
      disabled={props.disabled || false}
      type="primary"
      size={props.size || "large"}
      htmlType="submit"
      className="form-submit-button"
      style={{ width: "auto" }}
    >
      {props.title}{" "}
      {props.submitting && (
        <span>
          <Spaner width="sm" />
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={["fas", "spinner"]}
            spin
            className="fa"
          />
        </span>
      )}
    </Button>
  );
};

export default SubmitButton;
