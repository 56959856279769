import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";

export const boston_content_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.boston_content_donor_lists,
  display_name: "Boston Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor_list],
  fields: {
    name
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  }
};
