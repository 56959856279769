import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { jasper_slide } from "../jasper/jasper_slide";
import { jasper_slides } from "../jasper/jasper_slides";
import { jasper_root } from "./jasper_root";
import { jasper_stories } from "./jasper_stories";
import { jasper_template_001 } from "./jasper_template_001";
import { fremont_template_001 } from "../fremont/fremont_template_001";

export const jasper_types: { [key: string]: IFolderType } = {
  jasper_root,
  jasper_slide,
  jasper_slides,
  jasper_stories,
  jasper_template_001,
  fremont_template_001
};
