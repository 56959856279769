import { TOGGLE_EMAIL_SUBSCRIPTION_NOTIFICATION } from "./actionTypes";

export const toggleEmailNotificatonSubscription = (
  user_id,
  type,
  action,
  onSuccess?,
  onFail?
) => ({
  type: TOGGLE_EMAIL_SUBSCRIPTION_NOTIFICATION,
  payload: {
    user_id,
    type,
    action
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
