import _ from "lodash";
import React, { Component, useState } from "react";
import { IState } from "../../interfaces/main-state.interface";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { uriAccessInitialize } from "../../actions/uriAccessActions";
import { uriAccessGetData } from '../../actions/folderActions';
import { useSelector } from "react-redux";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { Input, Button } from "antd";
import { IURIAccess, URI_ACCESS_TYPE } from "../../shared-global/interfaces/models/uri_access.interface";
import WebviewContainer from "./WebviewContainer";
import { useParams } from "react-router";
import WebviewResponsiveContainer from "./WebviewResponsiveContainer";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
interface ParamTypes {
  identifier: string
}

interface IWebviewGateProps {}

enum GATE_STATE {
  INITIALIZING = 0,
  PUBLIC = 1,
  PASSWORD = 2,
  USER = 3,
  PASSWORD_AUTHORIZED = 4,
  PUBLIC_AUTHORIZED = 5
}

const WebviewGate: React.FC<IWebviewGateProps> = () => {
  const [gateState, setGateState] = useState(GATE_STATE.INITIALIZING);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [uriAccess, setUriAccess] = useState<IURIAccess>(null);
  const [folderType, setFolderType] = useState<IFolderType>(null);
  const dispatch = useDispatch();
  const { identifier } = useParams<keyof ParamTypes>()
  
  const uri_accesses_store = useSelector((state: IState) => state.data.uri_accesses);
  const folder_types_store = useSelector((state: IState) => state.data.folder_types);

  useEffect(() => {
    dispatch(uriAccessInitialize(identifier));
  }, []);

  useEffect(() => {
    const uri_accesses = arrayFromKeyedObject(uri_accesses_store)
    if (gateState === GATE_STATE.PASSWORD_AUTHORIZED || gateState === GATE_STATE.PUBLIC_AUTHORIZED){
      return;
    }
    if (!uri_accesses.length){
        return;
    }
    const uri_access = uri_accesses.find((uria: IURIAccess) => uria.identifier === identifier)
    if (!uri_access){
      return;
    }

    setUriAccess(uri_access);
    switch (uri_access.access_type) {
      case URI_ACCESS_TYPE.PUBLIC:
        dispatch(uriAccessGetData({identifier: identifier}, () => setGateState(GATE_STATE.PUBLIC_AUTHORIZED), null));
        break;
      case URI_ACCESS_TYPE.PASSWORD:
        setGateState(GATE_STATE.PASSWORD);
        break;
      case URI_ACCESS_TYPE.USER:
        setGateState(GATE_STATE.USER);
        break;
    }
  }, [uri_accesses_store]);

  useEffect(() => {
    const folder_type = folder_types_store[0];
    if (!folder_type){
      return;
    }
    setFolderType(folder_type);
  }, [folder_types_store]);

  if (gateState === GATE_STATE.INITIALIZING) {
    return null;
  }

  const renderPasswordForm = () => {
    return (
      <div className="login-form">
          <div style={{ width: "100%", height: 64, display: "inline-block" }} />

          <h2>Webview Login</h2>
          <div className="pure-g">
            <div className="login-form-field pure-u-1-2 pure-u-md-1-4 pure-u-lg-1-6">
              <Input
                placeholder="password"
                tabIndex={2}
                required
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                dir="auto"
              />
            </div>
          </div>
          <div className="pure-g">
            <div className="login-form-field pure-u-1-2 pure-u-md-1-4 pure-u-lg-1-6">
              <Button onClick={
                () => {
                  dispatch(uriAccessGetData({identifier: identifier, password: password}, () => {
                    setGateState(GATE_STATE.PASSWORD_AUTHORIZED)}, null));
                }
              } htmlType="submit" value="Log In">
                Log In
              </Button>
            </div>
          </div>
      </div>
    );
  };


  const renderUserLoginForm = () => {
    return (
      <div>
        <form className="login-form">
          <div style={{ width: "100%", height: 64, display: "inline-block" }} />

          <h2>Webview Login</h2>
          <div className="pure-g">
            <div className="login-form-field pure-u-1-2 pure-u-md-1-4 pure-u-lg-1-6">
              <Input
                autoFocus
                tabIndex={1}
                placeholder="email"
                required
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                dir="auto"
              />
            </div>
          </div>
          <div className="pure-g">
            <div className="login-form-field pure-u-1-2 pure-u-md-1-4 pure-u-lg-1-6">
              <Input
                placeholder="password"
                tabIndex={2}
                required
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                dir="auto"
              />
            </div>
          </div>
          <div className="pure-g">
            <div className="login-form-field pure-u-1-2 pure-u-md-1-4 pure-u-lg-1-6">
              <Button onClick={() => {}} htmlType="submit" value="Log In">
                Log In
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderWebviewContainer = () => {
    if (folderType.is_responsive) {
      return <WebviewResponsiveContainer uri_access={uriAccess} />
    }
    return <WebviewContainer uri_access={uriAccess} />
  }

  switch (gateState) {
    case GATE_STATE.USER:
      return renderUserLoginForm();
    case GATE_STATE.PASSWORD:
      return renderPasswordForm();
    case GATE_STATE.PUBLIC:
      return null;
    case GATE_STATE.PASSWORD_AUTHORIZED:
    case GATE_STATE.PUBLIC_AUTHORIZED:
      return renderWebviewContainer();
  }
};

export default WebviewGate;
