import { flattenDeep, countBy } from "lodash";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IResourceFolder } from "../../../../shared-global/interfaces/models/resource__folder.interface";
import { PreviewContext } from "../../../Contexts";
import { CheckerboardContentType, CheckerboardGridType } from "./Checkerboard";
import { CheckerboardCell } from "./CheckerboardCell";
import { CheckerboardWord } from "./CheckerboardWord";
import { loopIndex } from "../../../../shared-global/utils/general";
import { CheckerboardSlide } from "./CheckerboardSlide";

export type CheckerboardGridProps = {
  componentData: any;
  grid: CheckerboardGridType;
  slides: IResourceFolder[];
  slideSlot: string;
  wordsColorOverlay: string;
  inspirationalWords: string[];
  inspirationalWordsStyle: CSSProperties;
  relatedWords: string[];
  relatedWordsStyle: CSSProperties;
};

export const CheckerboardGrid = (props: CheckerboardGridProps) => {
  const { containerHeight, containerWidth } = useContext(PreviewContext);
  const [uuid, setUuid] = useState(new Date().getTime());

  const cellWidth = containerWidth / props.grid[0].length;
  const cellHeight = containerHeight / props.grid.length;

  let slideIndex = 0;
  let inspirationalWordIndex = 0;
  let relatedWordIndex = 0;

  const priorities: { [key in CheckerboardContentType]: number } = {
    slide: 1,
    inspirationalWord: 2,
    relatedWord: 3,
  };

  const counts: { [key in CheckerboardContentType]?: number } = useMemo(
    () => countBy(flattenDeep(props.grid)),
    [props.grid]
  );

  const order: number[][] = useMemo(() => {
    const counted = {
      slide: 0,
      inspirationalWord: 0,
      relatedWord: 0,
    };
    return props.grid.map((row) =>
      row.map((col) => {
        const initialCount = Object.keys(priorities)
          .filter((key) => priorities[col] > priorities[key])
          .reduce((sum, key) => counts[key] + sum, 0);
        return initialCount + counted[col]++;
      })
    );
  }, [props.grid, counts]);

  useEffect(() => {
    setUuid(new Date().getTime());
  }, [props.grid]);

  const renderSlide = () => {
    const index = slideIndex;
    slideIndex = loopIndex(slideIndex, 0, props.slides.length - 1, "forward");
    return (
      <CheckerboardSlide
        key={`slide-${index}`}
        componentData={props.componentData}
        index={index}
        width={cellWidth}
        height={cellHeight}
        slides={props.slides}
        slot={props.slideSlot}
      />
    );
  };

  const renderInspirationalWord = () => {
    const index = inspirationalWordIndex;
    inspirationalWordIndex = loopIndex(
      inspirationalWordIndex,
      0,
      props.inspirationalWords.length - 1,
      "forward"
    );
    return (
      <CheckerboardWord
        key={`inspirationalWord-${index}`}
        index={index}
        style={props.inspirationalWordsStyle}
        words={props.inspirationalWords}
      />
    );
  };

  const renderRelatedWordIndex = () => {
    const index = relatedWordIndex;
    relatedWordIndex = loopIndex(
      relatedWordIndex,
      0,
      props.relatedWords.length - 1,
      "forward"
    );
    return (
      <CheckerboardWord
        key={`relatedWord-${index}`}
        index={index}
        style={props.relatedWordsStyle}
        words={props.relatedWords}
      />
    );
  };

  return (
    <div
      key={"grid-" + uuid}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {props.grid.map((row, rowIndex) => {
        return row.map((col, colIndex) => {
          return (
            <CheckerboardCell
              key={`container-${rowIndex}-${colIndex}`}
              delay={order[rowIndex][colIndex]}
              row={rowIndex}
              col={colIndex}
              width={cellWidth}
              height={cellHeight}
              colorOverlay={
                col !== "slide" ? props.wordsColorOverlay : undefined
              }
            >
              {col === "slide" && renderSlide()}
              {col === "inspirationalWord" && renderInspirationalWord()}
              {col === "relatedWord" && renderRelatedWordIndex()}
            </CheckerboardCell>
          );
        });
      })}
    </div>
  );
};
