import request from "../utils/api/ServerRequest";
import { call, put, all } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import _ from "lodash";
import { addError, addExceptionError } from "../actions/notificationActions";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  getUriAccessByFolderValidationSchema,
  upsertUriAccessValidationSchema,
  uriAccessInitializeValidationSchema,
} from "../shared-global/validations/UriAccessValidation";
const uriAccessesSchema = new schema.Entity("uri_accesses");
const uriAccessUsers = new schema.Entity("uri_access_users");

async function uriAccessInitializeAPI(payload) {
  return Request(
    "/uriaccess/uri_access_initialize",
    HTTP_METHODS.GET,
    {
      identifier: payload.identifier,
    },
    uriAccessInitializeValidationSchema
  );
}

async function upsertUriAccessAPI(payload) {
  return Request(
    "/uriaccess/upsert_uri_access",
    HTTP_METHODS.POST,
    payload,
    upsertUriAccessValidationSchema
  );
}

async function getUriAccessByFolderAPI(payload: any) {
  return Request(
    "/uriaccess/get_uri_access_by_folder",
    HTTP_METHODS.POST,
    payload,
    getUriAccessByFolderValidationSchema
  );
}

const uriAccessSaga = {
  getUriAccessByFolder: function* (action: any) {
    try {
      const response = yield call(getUriAccessByFolderAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "URI_ACCESSES_GET_BY_FOLDER_SUCCESS",
            normalized: normalize(response.data.uri_accesses, [
              uriAccessesSchema,
            ]),
          }),
          put({
            type: "URI_ACCESS_USERS_GET_BY_FOLDER_SUCCESS",
            normalized: normalize(response.data.uri_access_users, [
              uriAccessUsers,
            ]),
          }),
        ]);

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  upsertUriAccess: function* (action: any) {
    try {
      const response = yield call(upsertUriAccessAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "URI_ACCESS_UPDATE_SUCCESS",
          normalized: normalize(response.data.uri_accesses, [
            uriAccessesSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  uriAccessInitialize: function* (action) {
    try {
      const response = yield call(uriAccessInitializeAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "URI_ACCESSES_GET_BY_FOLDER_SUCCESS",
          normalized: normalize(response.data.uri_accesses, [
            uriAccessesSchema,
          ]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },
};

export default uriAccessSaga;
