import {
  object,
  array,
  number,
  string
} from 'yup';
import { IResourceModel, IResourceNoteModel } from '../interfaces/models';

export const addResourcesValidationSchema = object().shape({
  parent_folder__folder_id: number().required()
});

export const getPresignedUploadUrlValidationSchema = object().shape({
  prospects: array().of(
    object().shape({
      name: string()
        .trim()
        .matches(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\.[a-zA-Z]+$/)
        .required(),
      parts: number().required()
    })
  )
});

export const deleteResourceBulkValidation = object().shape({
  resourceIds: array().of(number().required()).required()
});

export const moveResourcesBulkValidation = object().shape({
  resourceFolderIds: array().of(number().required()).required(),
  folderFolderId: number().required()
});

export const updateResourcesValidation = object().shape({
  id: number().required()
});

export const updateResourceBulkValidation = object().shape({
  resources: array().of(
    object().shape({
      id: number().required(),
      rank: number().required(),
      modifiedName: string().trim().required(),
      resourceTreeFolderJoinId: number().required()
    })
  ).required()
});

export const updateFileResourceValidationSchema = object().shape({
  resource_id: number().required(),
  data: object()
});

export const updateUploadedResourcesValidationSchema = object().shape({
  fileNames: array().of(string()).required(),
});

export const addTextResourcesValidationSchema = object().shape({
  parent_folder__folder_id: number().required(),
  resources: array().of(object<IResourceModel>())
});

export const updateTextResourcesValidationSchema = object().shape({
  resource_id: number().required(),
  data: object()
});

export const deleteResourcesValidationSchema = object().shape({
  resource_ids: array().of(number()).required(),
  data: object()
});

export const getResourceTagsValidationSchema = object().shape({
  resource_id: number().required(),
});

export const addResourceTagsValidationSchema = object().shape({
  tag: string(),
  tagId: number(),
  resource: number().required()
});

export const deleteResourceTagsValidationSchema = object().shape({
  resource_tag_id: number().required()
});

export const getResourceNotesValidationSchema = object().shape({
  resource_id: number().required(),
});

export const addResourceNoteValidationSchema = object().shape({
  user: number(),
  note: string().required(),
  resource: number().required()
});

export const deleteResourceNoteValidationSchema = object().shape({
  id: number().required()
});

export const updateResourceNoteValidationSchema = object().shape({
  id: number().required(),
  data: object<IResourceNoteModel>().shape({
    id: number().notRequired(),
    note: string(),
    user: number(),
    resource: number().notRequired(),
    company: number().notRequired()
  }).required()
});

export const moveResourceFolderValidationSchema = object().shape({
  orig_resource__folder_id: number().required(),
  move_action: string().required(),
  id: number(),
  destId: number(),
  actionType: string(),
  tree_type: string(),
  dest_resource__folder_id: number(),
  dest_folder__folder_id: number(),
});
