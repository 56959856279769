import React, { Component, Fragment } from "react";
import { Row, Modal } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import MediaFileInput from "./MediaFileInput";
import {
  addInputToItem,
  changeField,
  mapDefaultValuesToArray,
} from "./FileInputService";
import AddNewMediaFileInput from "./AddNewMediaFileInput";
import ContentManager from "../ContentManager/ContentManager";
import { connect } from "react-redux";
import { MEDIA_PROGRESS_STATUS_PROCESSING } from "../MediaProgressStatus/MediaProgressStatus";
import {getFolderStatusSelector} from "../../../../selectors/folderStatusSelectors";

const DYNAMIC = "dynamic";
// const FIXED = 'fixed';
// const CHUNK_SIZE = 7168000;

class DynamicFileInput extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    slots: PropTypes.array,
    limit: PropTypes.number,
    gallery: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: DYNAMIC,
    limit: 999,
    gallery: false,
  };

  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    let items = mapDefaultValuesToArray(defaultValue);

    this.state = {
      items,
      singleItem: null,
      closeCallback: null,
      processing: false,
      touch: false,
      itemsInitialState: [],
      defaultValueInitialState: [],
    };
  }

  componentDidMount() {
    const { selected_folder, folder__status } = this.props;

    if (selected_folder || folder__status) {
      const processing = this.hasItemsInProgress();
      this.setState({
        processing,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selected_folder, folder__status, defaultValue } = this.props;
    if (
      (prevProps.selected_folder !== selected_folder ||
      prevProps.folder__status !== folder__status) &&
      (selected_folder || folder__status)
    ) {
      const processing = this.hasItemsInProgress();
      this.setState({
        processing,
      });
    }
  }

  getNoRemovedFromDefaultValue = () => {
    const { defaultValue } = this.props;
    return defaultValue.filter((v) => v.status !== "removed");
  };

  getFieldName = () => {
    let fieldName = null;
    const { defaultValue } = this.props;
    if (defaultValue.length > 0) {
      fieldName = defaultValue[0].fieldName;
    }
    return fieldName;
  };

    hasItemsInProgress = () => {
    const { selected_folder, folder__status } = this.props;
    const selected_folder_id = selected_folder.id;
    const { fieldName: field_name } = this.props;
    const folderStatus = folder__status.filter(
      (status) =>
        status.folder === selected_folder_id &&
        status.field_name === field_name &&
        status.folder_status === MEDIA_PROGRESS_STATUS_PROCESSING
    );

    return folderStatus.length > 0;
  };

  handleAddInput = () => {
    const { limit } = this.props;
    const { items } = this.state;

    if (items.length < limit) {
      const aspect = _.get(this.props, "aspect", null);
      const newItems = addInputToItem(items, aspect);
      this.setState({ items: newItems });
    }
  };

  handleAddMultipleFiles = async (files, type = "new") => {
    const { items } = this.state;
    if (files && files.length !== 0) {
      for (const file in files) {
        this.handleAddInput();
        const lastItem = items[items.length - 1];
        await this.onFieldChange(
          lastItem.identifier,
          "file",
          files[file],
          type
        );
      }
    }
  };

  closeContentUploaderModal = () => {
    this.setState({
      uploadContentOpen: false,
    });
  };

  openContentManagerModalForEdit = (item) => {
    const { defaultValue } = this.props;
    const { items } = this.state;
    this.setState({
      contentManagerOpen: true,
      singleItem: item,
      touch: false,
      itemsInitialState: _.cloneDeep(items),
      defaultValueInitialState: _.cloneDeep(defaultValue),
    });
  };

  openContentManagerModalForAdd = () => {
    const { defaultValue } = this.props;
    const { items } = this.state;
    this.setState({
      contentManagerOpen: true,
      singleItem: null,
      touch: false,
      itemsInitialState: _.cloneDeep(items),
      defaultValueInitialState: _.cloneDeep(defaultValue),
    });
  };

  closeContentManagerModal = async () => {
    const { closeCallback } = this.state;
    if (closeCallback) {
      await closeCallback();
    }
    this.setState({
      singleItem: null,
      contentManagerOpen: false,
      itemsInitialState: [],
      defaultValueInitialState: [],
    });
  };

  closeContentManagerModalSafe = async (callback) => {
    const { contentManagerOpen, touch } = this.state;

    if (!touch) {
      // there is no change, just close the modal
      this.props.resetContentManager(this.props.fieldName);
      await this.closeContentManagerModal();
    } else {
      this.setState({
        closeCallback: callback ? callback : null,
      });
      if (contentManagerOpen) {
        this.setState({ showConfirmationModal: true });
      }
    }
  };

  onFieldChange = async (identifier, key, value, type = "new") => {
    const { fieldName } = this.props;
    const { onChange } = this.props;
    const { defaultValue } = this.props;
    const { items } = this.state;

    const defaultValueWithoutRemoved = this.getNoRemovedFromDefaultValue();
    const removedValues = defaultValue.filter((v) => v.status === "removed");

    const allValues = await changeField(
      identifier,
      key,
      value,
      type,
      fieldName,
      items,
      defaultValueWithoutRemoved
    );
    await onChange(fieldName, allValues.concat(removedValues));
  };

  handleEditFile = (item) => {
    this.openContentManagerModalForEdit(item);
  };

  getAddButton = () => {
    const { limit, canUpdate } = this.props;
    const { items, processing } = this.state;

    return (
      <AddNewMediaFileInput
        extraClassStyle="dynamic-file-input_add-button"
        disabled={!canUpdate}
        processing={processing}
        add={this.openContentManagerModalForAdd}
        show={items.length < limit}
      />
    );
  };

  noop = () => {};

  getDynamicRenderedInputs = () => {
    const { items } = this.state;
    const { defaultValue, acceptable, disabled } = this.props;
    const { processing } = this.state;
    
    const renderedItems = items.map((item) => {
      let data = null;
      if (defaultValue) {
        data = defaultValue.find((dv) => dv.rank === item.rank);
      }
      if (!data) {
        data = { status: "none" };
      }
      let key = item.identifier;
      if (data.thumb) {
        key = `${data.thumb} + ${item.identifier}`;
      }

      return (
        <MediaFileInput
          key={key}
          data={data}
          item={item}
          onEdit={this.handleEditFile}
          updateDefaultValueItem={this.noop}
          acceptable={acceptable}
          showIcons={!disabled && !processing}
          disabled={processing}
        />
      );
    });

    return renderedItems;
  };

  showAddButton = () => {
    const { items, limit } = this.props;
    return (!items && limit > 0) || (items && items.length < limit);
  };

  onChangeAllValues = async (values) => {
    const { fieldName, onChange } = this.props;
    await onChange(fieldName, values);
  };

  updateItems = (newItems) => {
    this.setState({
      items: newItems,
    });
  };

  updateTouchInState = (touch) => {
    this.setState({ touch });
  };

  onCancelCallback = async () => {
    const { itemsInitialState, defaultValueInitialState } = this.state;
    this.updateItems(itemsInitialState);
    await this.onChangeAllValues([...defaultValueInitialState]);
  };

  render() {
    const {
      title,
      recommendedDimensions,
      defaultValue,
      acceptable,
      limit,
      config,
    } = this.props;
    const {
      contentManagerOpen,
      showConfirmationModal,
      singleItem,
      items,
    } = this.state;
    const renderedInputs = this.getDynamicRenderedInputs();

    return (
      <Fragment>
        <div className="dynamic-file-input">
          <Row>
            <label className="form-input-label">{title}</label>
          </Row>
          {recommendedDimensions ? (
            <Row>
              <label className="form-input-label dynamic-file-input_h2">
                Recommended Size {recommendedDimensions}
              </label>
            </Row>
          ) : null}

          <Row className="dynamic-file-input_image-container">
            {this.showAddButton() ? this.getAddButton() : null}
            {renderedInputs}
          </Row>
        </div>

        <Modal
         
          wrapClassName="modal_content_manager"
          visible={contentManagerOpen}
          onCancel={() => {
            this.closeContentManagerModalSafe(this.onCancelCallback);
          }}
          onOk={() => {
            console.log("onOkay");
          }}
          destroyOnClose="true"
          width={1024}
          footer={false}
          maskClosable={false}
        >
         <div className="unsaved-changes-modal_header">Edit Content</div>
          <ContentManager
            items={items}
            defaultValue={defaultValue}
            acceptable={acceptable}
            singleItem={singleItem}
            config={config}
            limit={limit}

            handleAddMultipleFiles={this.handleAddMultipleFiles}
            onChangeAllValues={this.onChangeAllValues}
            close={this.closeContentManagerModalSafe}
            closeForced={this.closeContentManagerModal}
            onChange={this.onFieldChange}
            updateItems={this.updateItems}
            updateTouch={this.updateTouchInState}
            onCancelCallback={this.onCancelCallback}
            updateFileFields={this.props.updateFileFields}
          />
        </Modal>

        <Modal
          visible={showConfirmationModal}
          onCancel={() => {
            this.setState({
              showConfirmationModal: false,
            });
          }}
          onOk={() => {
            this.props.resetContentManager(this.props.fieldName);
            this.setState({
              showConfirmationModal: false,
            });
            this.closeContentManagerModal();
          }}
        >
          {
            "If you close this modal you will lose all your changes, are you sure?"
          }
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let selected_folder = null;
  const folder__status = getFolderStatusSelector(state, ownProps)
  if (state.client_options.applied_content_selected_folder) {
    selected_folder =
      state.data.folders[
        state.client_options.applied_content_selected_folder
      ];
    if (!selected_folder) {
      return {
        render: false,
      };
    }
  }

  return {
    folder__status,
    selected_folder,
  };
};

export default connect(mapStateToProps, null)(DynamicFileInput);
