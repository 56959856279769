import _ from "lodash";
import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
} from "../../../../shared-global/enums/folder-type-enums";
import { getChildrenData } from "../../../utils/commonDataUtils";
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import IdleLoop from "../../standard/IdleLoop";
import Slide1911 from "../../standard/Slide1911";
import MonterreyAttractLoop from "./MonterreyAttractLoop";

const MonterreyControlPanelDisplay = (props) => {
  const backgroundColor = get(props.baseFolder, "fields.background_overlay", "");
  const [currentPresentationId, setCurrentPresentationId] = useState(0);
  const mainPresentation = get(
    props.baseFolder,
    "fields.main_presentation",
    null
  );
  const [isIdle, setIsIdle] = useState(true);

  const fontSize = getFontSizeBasedOnPreviewData(
    props.baseFolderType,
    COMPONENT_NAME.Monterrey1x1Container,
    "1x1",
    {
      containerWidth: props.containerWidth,
      containerHeight: props.containerHeight,
    }
  );

  const children = useMemo(() => {
    return getChildrenData(props.data, props.baseFolder.id);
  }, [props.data, props.baseFolder]);

  const presentations = _.sortBy(
    children.folders.map((folder) => {
      const color = get(folder, "fields.button_color", "");
      return { ...folder, color };
    }),
    ({ id }) => id === mainPresentation
  ).filter((f) => f.folder_type === FOLDER_TYPE.monterrey_presentation_5x1);

  const presentation1x1 = children.folders.find(
    (f) => f.folder_type === FOLDER_TYPE.monterrey_presentation_1x1
  );
  const presentation1x1Children = getChildrenData(
    props.data,
    presentation1x1?.id
  );
  const attractLoop = presentation1x1Children.folders.find(
    (f) => f.folder_type === FOLDER_TYPE.monterrey_control_panel_attract_loop
  );

  useEffect(() => {
    const foundActivePresentationId = get(
      props,
      "electronSettings.websocketData.activePresentationId",
      null
    );
    if (foundActivePresentationId) {
      setCurrentPresentationId(Number(foundActivePresentationId));
    } else {
      const mainPresentation = get(
        props.baseFolder,
        "fields.main_presentation",
        0
      );
      setCurrentPresentationId(mainPresentation);
    }
  }, [props.electronSettings]);

  const setActivePresentation = (id) => {
    const socketSetActivePresentation = get(
      props,
      "electronSettings.websocketActions.setActivePresentation",
      null
    );
    if (socketSetActivePresentation) {
      socketSetActivePresentation(id);
    }
    setCurrentPresentationId(id);
  };

  return (
    <IdleLoop
      containerWidth={props.containeWidth}
      containerHeight={props.containerHeight}
      idle_delay={180}
      resume={() => setIsIdle(false)}
      goIdle={(status) => {
        if (status === "idle") {
          setIsIdle(true);
        }
      }}
    >
      <TransitionGroup>
        <CSSTransition key={isIdle} timeout={500} classNames="transition-fade">
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              fontSize,
            }}
          >
            {/* if the display is idle show the attract loop, if not show the control panel */}
            {isIdle ? (
              <MonterreyAttractLoop
                {...props}
                data={{ ...props.data, base_folder: attractLoop?.id }}
              />
            ) : (
              <>
                <div
                  style={{
                    backgroundColor,
                    zIndex: 1,
                    position: 'absolute',
                    width: props.containerWidth,
                    height: props.containerHeight,
                  }}
                />
                <Slide1911
                  {...props}
                  handleEndOfPlay={null}
                  containerHeight="100%"
                  containerWidth="100%"
                  data={{
                    ...props.data,
                    slotNames: ["background_image"],
                    base_folder: get(props.baseFolder, "id", null),
                    backgroundSize: "contain",
                    componentSpecificData: {
                      ...props.data.componentSpecificData,
                      useKeyCycler: false,
                      customStyleImage: {
                        width: props.containerWidth,
                        zIndex: "0",
                        height: "100%",
                      },
                    },
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "35%",
                      height: props.containerHeight,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "4.375em",
                      marginLeft: "4.375em",
                      marginRight: "4.375em",
                    }}
                  >
                    <Slide1911
                      {...props}
                      handleEndOfPlay={null}
                      containerHeight="100%"
                      containerWidth="100%"
                      data={{
                        ...props.data,
                        slotNames: ["media"],
                        base_folder: get(props.baseFolder, "id", null),
                        backgroundSize: "contain",
                        componentSpecificData: {
                          ...props.data.componentSpecificData,
                          useKeyCycler: false,
                          customStyleImage: {
                            position: "relative",
                            width: "100%",
                            backgroundPosition: "top center",
                            zIndex: "2",
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "60%",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        paddingTop: "4.375em",
                        paddingBottom: "4.375em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          alignItems: "center",
                          alignContent: "center",
                          gridColumnGap: "1.625em",
                          gridRowGap: "1.625em",
                        }}
                      >
                        {presentations.map((btn, k) => (
                          <div
                            style={{
                              display: "flex",
                              width: "23.125em",
                              height: "12.6875em",
                              justifyContent: "center",
                              alignItems: "center",
                              borderStyle: "solid",
                              borderWidth: "0.375em",
                              borderRadius: "1.5em",
                              zIndex: 5,
                              backgroundColor:
                                Number(currentPresentationId) === btn.id
                                  ? btn.color
                                  : "hsla(0, 0%, 100%, 0.6)",
                              cursor: "pointer",
                              borderColor: btn.color,
                            }}
                            key={k}
                            onClick={() => {
                              if (setActivePresentation) {
                                setActivePresentation(btn.id);
                              }
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                padding: "1em",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: "0 auto",
                                color:
                                  currentPresentationId === btn.id
                                    ? "#FFFFFF"
                                    : "#191919",
                                fontSize: "2.5em",
                                lineHeight: "1.25",
                                textAlign: "center",
                              }}
                            >
                              {btn.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </IdleLoop>
  );
};

export default MonterreyControlPanelDisplay;
