import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";

import { TRANSITION_CLASS } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import { tree_icon_types } from "../tree_icon_types";

export const glenwood_attract_loop: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  name: FOLDER_TYPE.glenwood_attract_loop,
  display_name: "Attract Loop",
  acceptable_folder_types: [FOLDER_TYPE.glenwood_slide],
  tree_icon_types: {
    open: {
      ...tree_icon_types.attract_loops
    },
    closed: {
      ...tree_icon_types.attract_loops
    }
  },
  fields: {
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: TRANSITION_CLASS.TRANSITION_FADEOUT_2S,
      options: [
        {
          title: "Crossfade",
          value: TRANSITION_CLASS.CROSSFADE_2S
        },
        {
          title: "Fade Out",
          value: TRANSITION_CLASS.TRANSITION_FADEOUT_2S
        }
      ]
    },
    default_overlay: {
      title: "Default Overlay",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: ["thumbnail", "small", "large"],
      aspect: 1.777777778,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    },
    idle_delay: {
      type: "integer",
      default: 180,
      title: "Idle timeout (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
