import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE, FOLDER_CATEGORY } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import { tree_icon_types } from "../tree_icon_types";

export const memphis_donor_list: IFolderType = {
  name: FOLDER_TYPE.memphis_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Donor List",
  acceptable_folder_types: [FOLDER_TYPE.memphis_donor],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    sorting_options: {
      type: "select",
      title: "Sorting Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "rank",
      options: [
        {
          title: "Rank",
          value: "rank"
        },
        {
          title: "Sorting Order",
          value: "sorting_order"
        }
      ]
    },
    title: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    category: {
      type: "text",
      title: "Category",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    description: {
      type: "textarea",
      title: "Description",
      validation: { rules: [{ name: "isString", min: 1, max: 75 }, { name: "isRequired" }] },
      default: ""
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    background_image: {
      title: "Background",
      type: "file",
      slot_type: "fixed",
      acceptable_contents: ["image/*"],
      limit: null,
      slots: [
        {
          name: "background_image",
          title: "Background Image"
        }
      ],
      default: null
    },
    list_random_backgrounds: {
      title: "Photos",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*"],
      limit: 10,
      slot_names: null,
      default: null,
      image_presets: ["thumbnail", "small", "large"],
      video_presets: [360, 480, 720],
      aspect: 0.9,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN, //'COVER' //'COVERCONTAINER
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_RIGHT
      }
    },
    list_random_backgrounds_2: {
      title: "Photos Cover flex bottom right",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*"],
      limit: 10,
      slot_names: null,
      default: null,
      image_presets: ["thumbnail", "small", "large"],
      video_presets: [360, 480, 720],
      aspect: 0.9,
      container: {
        imgCover: IMAGE_ALIGNMENT.COVER, //CONTAIN,  //'COVER' //'COVERCONTAINER
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_RIGHT
      }
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.memphis_donor],
    columns: [
      {
        folder_types: [FOLDER_TYPE.memphis_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.memphis_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.memphis_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.DonorList1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.DonorList1911
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: true,
    fields: ["name", "title", "category", "description"]
  }
  // THIS seemed to not be referenced anywhere
  // display: {
  //   ratio_w: 16,
  //   ratio_h: 9
  // }
};
