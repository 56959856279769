import { FOLDER_TYPE, PREVIEW_TYPE, FOLDER_CATEGORY } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const memphis_donor: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.memphis_donor,
  folder_category: FOLDER_CATEGORY.DONOR,
  display_name: "Memphis Donor",
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: "",
      read_only: true
    },
    sorting_order: {
      type: "text",
      title: "Sorting Order",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: ""
    }
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name", "sorting_order"]
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  }
};
