import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Modal, Switch, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateClientConfig,
  makeFolderPullable,
  updateFolderContents,
} from "../../actions/folderActions";
import { IState } from "../../interfaces/main-state.interface";
import AlertNotification from "../layout/AlertNotification";
import get from "lodash/get";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
import { hasPermission } from "../../utils/permissionUtils";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { createBackup } from "../../actions/backupActions";
import _ from "lodash";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { IURIAccess } from "../../shared-global/interfaces/models/uri_access.interface";
import { getUriAccessByFolder } from "../../actions/uriAccessActions";
import BackupRestoreModal from "../folder/BackupRestoreModal";

interface IAdminDashboardAreaProps {
  preparePublishFolder: Function;
  openBackupsModal: Function;
}

const SystemAdminDashboardArea = (props: IAdminDashboardAreaProps) => {
  const dispatch = useDispatch();
  const [folderConfig, setFolderConfig] = useState([]);
  const [isPublishConfirmationModalOpen, setIsPublishConfirmationModalOpen] =
    useState(false);
  const [isRestoreBackupModalOpen, setIsRestoreBackupModalOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { folders, folder_types } = useSelector((state: IState) => state.data);
  const { applied_content_selected_folder } = useSelector(
    (state: IState) => state.client_options
  );
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderType, setSelectedFolderType] =
    useState<IFolderType>(null);
  const [showBackupConfirmationModal, setShowBackupConfirmationModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const grouped_permissions = useSelector(
    (state: IState) => state.data.user_permissions_grouped
  );
  const uri_accesses = useSelector((state: IState) => state.data.uri_accesses);
  const mainStateSelectedFolderId = useSelector(
    (state: IState) => state.client_options.applied_content_tree_selected_id
  );

  const [selectedURIAccess, setSelectedURIAccess] = useState<IURIAccess>(null);

  const user = useSelector((state: IState) => state.data.user);

  const folderType = useMemo(() => {
    const folder = folders[applied_content_selected_folder];
    const ft = folder_types[folder.folder_type];
    return ft;
  }, [folders, folder_types, applied_content_selected_folder]);

  const onMakeFolderPublishable = (pullable: boolean) => {
    dispatch(
      makeFolderPullable(
        applied_content_selected_folder,
        pullable,
        onMakeFolderPublishableSuccess
      )
    );
  };

  const onMakeFolderPublishableSuccess = () => {
    setIsPublishConfirmationModalOpen(false);
    !folders[applied_content_selected_folder].pullable
      ? AlertNotification("success", "Success", "Folder is now publishable.")
      : AlertNotification("success", "Success", "Folder is now unpublishable.");
  };

  const onGenerateClientConfigSuccess = (data) => {
    setFolderConfig(data.config);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
    dispatch(
      generateClientConfig(
        applied_content_selected_folder,
        onGenerateClientConfigSuccess
      )
    );
  };

  const preparePublishFolder = () => {
    const api_identifier = selectedFolder.api_identifier;
    props.preparePublishFolder(api_identifier);
  };

  const onCreateBackupSuccess = () => {
    AlertNotification("success", "Success", "Backup created successfully");
    dispatch(
      setClientOptions({
        lock_screen: false,
      })
    );
  };

  const onCreateBackupFailure = (message) => {
    dispatch(
      setClientOptions({
        lock_screen: false,
      })
    );
  };

  const toggleAcceptableFolderType = (ft) => {
    const currentDisabledFolderTypes = get(
      folders[applied_content_selected_folder],
      "contents.disabled_acceptable_folder_types",
      []
    );
    const indexOfCurrentDisabledFolderTypes =
      currentDisabledFolderTypes.indexOf(ft);
    if (indexOfCurrentDisabledFolderTypes > -1) {
      currentDisabledFolderTypes.splice(indexOfCurrentDisabledFolderTypes, 1);
      dispatch(
        updateFolderContents({
          folder_id: applied_content_selected_folder,
          contents: JSON.stringify({
            disabled_acceptable_folder_types: [...currentDisabledFolderTypes],
          }),
        })
      );
    } else {
      dispatch(
        updateFolderContents({
          folder_id: applied_content_selected_folder,
          contents: JSON.stringify({
            disabled_acceptable_folder_types: [
              ...currentDisabledFolderTypes,
              ft,
            ],
          }),
        })
      );
    }
  };

  useEffect(() => {
    if (mainStateSelectedFolderId !== selectedFolderId) {
      if (isFolder()) {
        const folder_id = extractFolderId(mainStateSelectedFolderId);
        const folder = folders[folder_id];
        dispatch(getUriAccessByFolder(folder_id, null, null));
        setSelectedFolder(folder);
        const folderType = folder_types[folder.folder_type];
        setSelectedFolderType(folderType);
      }
      setSelectedFolderId(mainStateSelectedFolderId);
    } else {
      const folder_id = extractFolderId(mainStateSelectedFolderId);
      const folder = folders[folder_id];
      setSelectedFolder(folder);
    }
    if (isFolder()) {
      const uri_accesses_array = arrayFromKeyedObject(uri_accesses);
      const uri_access = uri_accesses_array.find(
        (uria: IURIAccess) =>
          uria.folder === extractFolderId(mainStateSelectedFolderId)
      );
      if (uri_access) {
        setSelectedURIAccess(uri_access);
      }
    }
  }, [folders, mainStateSelectedFolderId, uri_accesses]);

  const isFolder = () => {
    return mainStateSelectedFolderId.toString().startsWith("folder");
  };

  const onConfirmCreateBackup = () => {
    dispatch(
      setClientOptions({
        indeterminate: true,
        lock_screen: true,
        progress: null,
        message_for_progress: "Creating backup...",
      })
    );
    dispatch(
      createBackup(
        selectedFolder.api_identifier,
        onCreateBackupSuccess,
        onCreateBackupFailure
      )
    );
    setShowBackupConfirmationModal(false)
  }

  const extractFolderId = (strId) => {
    return Number(
      strId
        .toString()
        .substring(mainStateSelectedFolderId.toString().indexOf("-") + 1)
    );
  };

  return (
    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Card title="Tools">
        <div className="backup-buttons">
          <Button
            type="primary"
            size="large"
            style={{
              width: "auto",
              fontSize: 14,
              padding: 9,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            onClick={() => setIsPublishConfirmationModalOpen(true)}
          >
            {folders[applied_content_selected_folder].pullable
              ? "MAKE UNPUBLISHABLE"
              : "MAKE PUBLISHABLE"}
          </Button>

          <Button
            type="primary"
            size="large"
            style={{
              width: "auto",
              fontSize: 14,
              padding: 9,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            onClick={() => onModalOpen()}
          >
            GENERATE CONFIG
          </Button>
        </div>
        <br />
      </Card>

      <br />

      {selectedFolder &&
        selectedFolder.pullable &&
        hasPermission(
          grouped_permissions,
          "backup",
          "r",
          user,
          "company",
          user.company,
          user.company
        ) && (
          <Card title="Backup/Restore">
            {selectedFolder && selectedFolder.publishing && (
              <div style={{ marginBottom: "10px" }}>
                <FontAwesomeIcon
                  icon={["fas", "spinner"]}
                  className="fa-1x"
                  spin
                />
                &nbsp; Changes are being published
              </div>
            )}
            {selectedFolder && selectedFolder.pullable && (
              <div className="backup-buttons">
                {selectedFolder &&
                  selectedFolder.pullable &&
                  hasPermission(
                    grouped_permissions,
                    "backup",
                    "r",
                    user,
                    "company",
                    user.company,
                    user.company
                  ) && (
                    <>
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          width: "auto",
                          fontSize: 14,
                          padding: 9,
                          fontWeight: "bold",
                          marginLeft: 10,
                          flex: 1,
                        }}
                        onClick={() => {
                          setShowBackupConfirmationModal(true)
                        }}
                        disabled={_.get(selectedFolder, "publishing", false)}
                      >
                        CREATE BACKUP
                      </Button>

                      <Button
                        type="primary"
                        size="large"
                        onClick={() => setIsRestoreBackupModalOpen(true)}
                        style={{
                          width: "auto",
                          fontSize: 14,
                          padding: 9,
                          fontWeight: "bold",
                          marginLeft: 10,
                          flex: 1,
                        }}
                      >
                        UPLOAD BACKUP
                      </Button>

                      <Button
                        type="primary"
                        size="large"
                        onClick={() => props.openBackupsModal()}
                        style={{
                          width: "auto",
                          fontSize: 14,
                          padding: 9,
                          fontWeight: "bold",
                          marginLeft: 10,
                          flex: 1,
                        }}
                      >
                        BACKUPS
                      </Button>
                    </>
                  )}
              </div>
            )}
          </Card>
        )}

      <br />

      {folderType &&
        folderType.optional_acceptable_folder_types &&
        folderType.optional_acceptable_folder_types.length > 0 && (
          <Card title="Acceptable Folder Types">
            {folderType.optional_acceptable_folder_types.map((ft, index) => {
              const folderType = folder_types[ft];
              const disabledFolderTypes = get(
                folders[applied_content_selected_folder],
                "contents.disabled_acceptable_folder_types",
                []
              );
              const status = !disabledFolderTypes.find((dft) => dft === ft);
              return (
                <Tooltip
                  key={`folder-${index}`}
                  title={() => (
                    <>
                      Enable/Disable <b>{folderType.display_name}</b> as an
                      option in{" "}
                      <b>{folders[applied_content_selected_folder].name}</b>
                    </>
                  )}
                >
                  <span style={{ cursor: "pointer" }}>
                    <Switch
                      checked={status}
                      onChange={() => toggleAcceptableFolderType(ft)}
                      checkedChildren={folderType.display_name}
                      unCheckedChildren={folderType.display_name}
                    />
                  </span>
                </Tooltip>
              );
            })}
          </Card>
        )}
      <Modal
        width={960}
        visible={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        title={
          <React.Fragment>
            <FontAwesomeIcon className="fa" icon={["fas", "cog"]} />
            &nbsp;&nbsp;Configuration for{" "}
            {folders[applied_content_selected_folder].name}
          </React.Fragment>
        }
      >
        <div
          style={{ padding: 16, backgroundColor: "black", color: "#4287f5" }}
        >
          {folderConfig.map((line, i) => (
            <div key={`line=${i}`}>
              <pre>{line}</pre>
            </div>
          ))}
        </div>
      </Modal>

      <Modal
        visible={isPublishConfirmationModalOpen}
        onCancel={() => setIsPublishConfirmationModalOpen(false)}
        onOk={() =>
          onMakeFolderPublishable(
            !folders[applied_content_selected_folder].pullable
          )
        }
      >
        Are you sure you want to make this folder{" "}
        {folders[applied_content_selected_folder].pullable
          ? "unpublishable"
          : "publishable"}
        ?
      </Modal>

      <Modal
        visible={showBackupConfirmationModal}
        onCancel={() => setShowBackupConfirmationModal(false)}
        onOk={() => onConfirmCreateBackup() }
      >
        Are you sure you want to create a backup of this folder ?
      </Modal>

      <BackupRestoreModal
        visible={isRestoreBackupModalOpen}
        onCancel={() => setIsRestoreBackupModalOpen(false)}
      />
    </div>
  );
};

export default SystemAdminDashboardArea;
