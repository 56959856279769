import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { new_orleans_template_001 } from "./new_orleans_template_001";
import { new_orleans_template_002 } from "./new_orleans_template_002";
import { new_orleans_template_003 } from "./new_orleans_template_003";
import { new_orleans_root } from "./new_orleans_root";
import { new_orleans_donor } from "./new_orleans_donor";
import { new_orleans_donor_list } from "./new_orleans_donor_list";
import { new_orleans_donor_lists } from "./new_orleans_donor_lists";
import { new_orleans_donor_list_category } from "./new_orleans_donor_list_category";
import { new_orleans_1x2 } from "./new_orleans_1x2";
import { new_orleans_1x2_slideshow } from "./new_orleans_1x2_slideshow";
import { new_orleans_1x2_slide } from "./new_orleans_1x2_slide";
import { new_orleans_stories } from "./new_orleans_stories";
import { new_orleans_attract_loop } from "./new_orleans_attract_loop";

export const new_orleans_types: { [key: string]: IFolderType } = {
  new_orleans_1x2,
  new_orleans_1x2_slideshow,
  new_orleans_1x2_slide,
  new_orleans_attract_loop,
  new_orleans_donor,
  new_orleans_donor_list,
  new_orleans_donor_list_category,
  new_orleans_donor_lists,
  new_orleans_root,
  new_orleans_stories,
  new_orleans_template_001,
  new_orleans_template_002,
  new_orleans_template_003
};
