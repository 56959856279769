import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Row, Tooltip, Empty} from 'antd';
import MediaProgressStatusRow from './MediaProgressStatusRow';


export const MEDIA_PROGRESS_STATUS_DONE = 'done';
export const MEDIA_PROGRESS_STATUS_PROCESSING = 'processing';
export const MEDIA_PROGRESS_STATUS_ERROR = 'error';

class MediaProgressStatus extends Component {
  static propTypes = {
    /**
     * items: {id, name, fileType, status, percentage???}
     */
    items: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    minimized: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    open: false,
  };

  constructor(props) {
    super(props);
    this._componentIsMounted = false;
    this.state = {
      isOpen: true,
      isMinimized: false,
      completedQty: 0,
    };
  }

  componentDidMount() {
    const {open, items, minimized} = this.props;
    this._componentIsMounted = true;
    // console.log('props', this.props);
    if (open) {
      this.setState({isOpen: open});
    }

    if (minimized) {
      this.setState({isMinimized: minimized});
    }

    if (items) {
      this.updateCompletedQty();
    }
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("mediaProgressStatus componentDidUpdate ", this.props);
    if (prevProps !== this.props) {
      if (prevProps.items !== this.props.items) {
        this.updateCompletedQty();
      }

      if (prevProps.open !== this.props.open) {
        this.setState({isOpen: this.props.open});
      }

      if (prevProps.minimized !== this.props.minimized) {
        this.setState({
          isMinimized: this.props.minimized
        });
      }
    }
  }

  handleClose = () => {
    const {onclose} = this.props;
    this.setState({
      isOpen: false,
    });

    if (onclose) {
      onclose();
    }
  };

  updateCompletedQty = () => {
    const {items} = this.props;

    if (items.length > 0) {
      const newQty = items.reduce((accumulator, currentValue) => {
        const partialQTY = (currentValue.status === MEDIA_PROGRESS_STATUS_DONE) ? 1 : 0;
        return accumulator + partialQTY;
      }, 0);

      this.setState({
        completedQty: newQty
      });
    }
  };

  onMinimize = () => {
    this.setState({
      isMinimized: true
    });
  };

  onMaximize = () => {
    this.setState({
      isMinimized: false
    });
  };

  render() {
    const {items} = this.props;
    const {isMinimized, isOpen, completedQty} = this.state;
    const extraClass = isMinimized ? 'media-progress-status-minimized' : '';

    if (!isOpen) {
      return null;
    }

    return (
      <div className={`media-progress-status ${extraClass}`}>
        <div className="media-progress-status_header">
          <div className="media-progress-status_header-title">
            {`${completedQty} uploads complete`}
          </div>
          <div className="media-progress-status_header-buttons">
            {!isMinimized ? (
              <Tooltip title="Minimize">
                <FontAwesomeIcon className="fa-lg" style={{cursor: 'pointer'}} icon={['fas', 'angle-down']}
                                 onClick={this.onMinimize}/>
              </Tooltip>
            ) : (
              <Tooltip title="Maximize">
                <FontAwesomeIcon className="fa-lg" style={{cursor: 'pointer'}} icon={['fas', 'angle-up']}
                                 onClick={this.onMaximize}/>
              </Tooltip>
            )}
            <Tooltip title="Close">
              <FontAwesomeIcon className="fa-lg" style={{cursor: 'pointer'}} icon={['fas', 'times']}
                               onClick={this.handleClose}/>
            </Tooltip>
          </div>
        </div>
        {!isMinimized ? (
          <div className="media-progress-status_body">
            {
              items.length === 0 ? (
                <Row className="media-progress-status_body-empty">
                  <Empty/>
                </Row>

              ) : null
            }
            {
              items.map((item, index) => (
                <MediaProgressStatusRow
                  key={`key-${index}`}
                  name={item.name}
                  index={index}
                  status={item.status}
                />
              ))
            }
          </div>
        ) : null}
      </div>
    );
  }
}

export default MediaProgressStatus;
