import {
  ALPINE_BODY_LINE_HEIGHT,
  ALPINE_BODY_MARGIN_TOP,
  ALPINE_HEADING_LINE_HEIGHT,
  ALPINE_HEADING_MARGIN_TOP,
  ALPINE_RICH_TEXT_PADDING_BOTTOM, ALPINE_SUBHEAD_LINE_HEIGHT, ALPINE_SUBHEAD_MARGIN_TOP
} from "./AlpineConstants";

const styles = {
  componentContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
  },
  componentOverlay: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    backgroundColor: (props) => props.backgroundOverlay,
  },
  donorListContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    zIndex: 0,
    "& .backgroundOverlay": {
      position: "absolute",
      width: (props) => props.containerWidth,
      height: (props) => props.containerHeight,
      backgroundColor: (props) => props.backgroundColor,
      zIndex: 0,
    },
  },
  noPadding: {
    padding: 0
  },
  donorListContent: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    paddingTop: (props) => (72 / 1920) * props.containerHeight,
    paddingBottom: (props) => (72 / 1920) * props.containerHeight,
    paddingLeft: (props) => (100 / 1080) * props.containerWidth,
    paddingRight: (props) => (132 / 1080) * props.containerWidth,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly",
    "& .workspaceContainer": {
      height: (props) => props.containerHeight - props.getUnitBasedOnHeight(72 * 2),
      color: (props) => props.fontColorDefault,
      paddingTop: (props) => (0 / 1920) * props.containerHeight,
      paddingBottom: (props) => (0 / 1920) * props.containerHeight,
      paddingLeft: (props) => (0 / 1080) * props.containerWidth,
      paddingRight: (props) => (0 / 1080) * props.containerWidth,
      overflowX: "hidden",
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      "& .header": {
        width: "100%",
        minHeight: (props) => (90 / 1920) * props.containerHeight,
        "& .headerText": {
          padding: 0,
          minHeight: (props) => (56 / 1920) * props.containerHeight,
        }
      },
      "& .description": {
        minHeight: (props) => (30 / 1920) * props.containerHeight,
        "& .descriptionText": {
          marginTop: (props) => props.standardSizing(props.descriptionTop, props.containerHeight),
          marginBottom: (props) => .01 * props.containerHeight,
          padding: 0,
          whiteSpace: "break-spaces",
        }
      },
      "& .categoryText": {
        padding: 0,
        marginTop: (props) => .01 * props.containerHeight,
      },
      "& .horizontalRule": {
        backgroundColor: (props) => props.horizontalRuleColor,
        border: "0 none",
        color: (props) => props.horizontalRuleColor,
        height: (props) => props.horizontalRuleHeight
      },
      "& .footer": {
        minHeight: (props) => (30 / 1920) * props.containerHeight,
        "& .footerText": {
          whiteSpace: "break-spaces",
        }
      }
    }
  },
  profileWithMedia: {
    backgroundColor: (props) => props.backgroundColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    "& .profileWithMediaContent": {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      "& .sectionPadding": {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        marginBottom: '2em',
        padding: '7.5em 7.5em 0 7.5em',
        flexDirection: 'column',
        "& .texts": {
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          marginBottom: '2em',
          flexDirection: 'column',
          "& .headerText": {
            lineHeight: '1.15',
            padding: 0,
            marginBottom: '2.5em'
          },
          "& .bodyText": {
            lineHeight: '1.15',
            padding: 0,
          }
        }
      }
    }
  },
  storyWithFact: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .sectionPadding": {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      marginBottom: "2em",
      padding: "7.5em 7.5em 0 7.5em",
      flexDirection: "column",
    },
    "& .sectionTagline": {
      marginBottom: "1.125em",
      lineHeight: "1.15",
      padding: 0,
      letterSpacing: "0.05em",
      textTransform: "uppercase",
    },
    "& .sectionHeader": {
      marginBottom: "2.5em",
      lineHeight: "1.05",
      padding: 0,
    },
    "& .sectionBody": {
      lineHeight: "1.15",
      padding: 0,
    },
  },
  storyWithMedia: {
    backgroundColor: (props) => props.backgroundColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    "& .storyWithMediaContent": {
      width: '100%',
      height: '100%',
      "& .sectionPadding": {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        padding: '7.5em 7.5em 0 7.5em',
        "& .sectionHeader": {
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          marginBottom: '1.125em',
          flexDirection: 'column',
          "& .tagline": {
            lineHeight: '1.15',
            padding: 0,
            marginBottom: '1.125em'
          },
          "& .header": {
            lineHeight: '1.15',
            padding: 0,
            marginBottom: '2.5em'
          }
        },
      },
      "& .sectionBody": {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        marginBottom: '2em',
        flexDirection: 'column',
        padding: '0 7.5em 0 7.5em',
        "& .body": {
          lineHeight: '1.15',
          padding: 0,
        }
      }
    }
  },
  storyWithSingleImageContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    zIndex: 0
  },
  storySingleImageOverlay: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    backgroundColor: (props) => props.backgroundColor,
    zIndex: 0
  },
  storySingleImageContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flex: 1,
    flexDirection: (props) => !props.mirrorContent ? "column" : "column-reverse",
    justifyContent: "space-evenly",
    "& .image": {
      height: "50%",
      "& .imageWrapper": {
        width: "100%",
        height: "100%"
      }
    },
    "& .text": {
      height: "50%",
      color: "#000000",
      paddingLeft: (props) => 0.12 * props.containerWidth,
      paddingRight: (props) => 0.12 * props.containerWidth,
      paddingTop: (props) => 0.05 * props.containerHeight,
      paddingBottom: (props) => 0.035 * props.containerHeight,
      "& .textWrapper": {
        width: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        maxHeight: "100%",
        "& .headline": {
          padding: 0,
          lineHeight: ALPINE_HEADING_LINE_HEIGHT,
          overflowX: "hidden",
          overflowY: "hidden",
          paddingBottom: (props) => ALPINE_RICH_TEXT_PADDING_BOTTOM * props.containerHeight,
          "& .headlineText": {
            lineHeight: "1.2",
            padding: 0
          }
        },
        "& .subhead": {
          marginTop: (props) => ALPINE_SUBHEAD_MARGIN_TOP * props.containerHeight,
          "& .subheadTextWrapper": {
            padding: 0,
            overflowX: "hidden",
            overflowY: "hidden",
            paddingBottom: (props) => ALPINE_RICH_TEXT_PADDING_BOTTOM * props.containerHeight,
            lineHeight: ALPINE_SUBHEAD_LINE_HEIGHT,
            "& .subheadText": {
              lineHeight: "1.2",
              padding: 0
            }
          }
        },
        "& .body": {
          marginTop: (props) => ALPINE_BODY_MARGIN_TOP * props.containerHeight,
          "& .bodyTextWrapper": {
            padding: 0,
            lineHeight: ALPINE_BODY_LINE_HEIGHT,
            overflowX: "hidden",
            overflowY: "hidden",
            paddingBottom: (props) => ALPINE_RICH_TEXT_PADDING_BOTTOM * props.containerHeight,
            "& .bodyText": {
              lineHeight: "1.2",
              padding: 0
            }
          }
        }
      }
    }
  },
  mediaWithQuote2: {
    width: "100%",
    height: (props) => props.containerHeight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: (props) => props.backgroundColor,
    "& .carouselWrapper": {
      height: "70%",
      width: "100%"
    },
    "& .quoteWrapper": {
      width: "100%",
      height: "30%",
      padding: "6em 8em",
      backgroundColor: (props) => props.quoteBackgroundColor
    }
  },
  quoteContainer: {
    display: 'flex',
    overflow: 'hidden',
    width: "100%",
    flexDirection: 'column',
    height: '100%',
    "& .quoteHeading": {
      lineHeight: 1.30,
      padding: 0,
    }
  },
  fullscreenFact: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: (props) => props.backgroundColor
  },
  callToActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1em",
    width: (props) => props.centered ? "auto" : "100%",
    maxWidth: "50%",
    "& .handTouch": {
      marginRight: "1em",
      padding: "0.5em",
      width: "4em",
      height: "4em",
    },
  },
  QRCallToAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "1em",
    width: (props) => props.centered ? "auto" : "100%",
    maxWidth: "50%",
  },
  QRWrapper: {
    height: "auto",
    margin: "0 auto",
    maxWidth: (props) => props.refWidth * 0.0592,
    width: "100%",
    marginLeft: "1em",
  },
  cta_qr: {
    margin: "2.5em 5em",
    flexDirection: "row",
    justifyContent: "center",
    gridColumnGap: "2em",
    "& .cta_qr_wrapper": {
      width: "100%",
      marginTop: "2.5em",
      display: "flex",
      justifyContent: "center",
      gridColumnGap: "2em",
      borderTop: (props) => props.show_border
        ? `${props.lineHeight}px solid` + props.footer_line_color
        : "none",
      alignItems: "center",
    }
  }
}

export default styles
