import get from "lodash/get";
import React, { useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { IDisplayComponentProps } from "../../../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../../../utils/commonDataUtils";
import DonorListWithRegions2108 from "../../../standard/DonorListWithRegions2108";
import Slide1911 from "../../../standard/Slide1911";

const Boston3x2DiamondPlatinumList: React.FC<IDisplayComponentProps> = (
  props
) => {
  const [baseFolder, setBaseFolder] = useState(null);
  const [backgroundFieldType, setBackgroundFieldType] = useState(null);
  const [useColorOverlay, setUseColorOverlay] = useState(false);
  const [horizontalColorOverlay, setHorizontalColorOverlay] = useState(null);

  const donorNameStyle = useMemo(() => {
    return get(baseFolder, "fields.donor_name_diamond_platinum_style", {});
  }, [baseFolder]);

  const isDiamondList = useMemo(() => {
    return get(props, 'data.componentSpecificData.is_diamond_list', false);
  }, [props.data]);

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);
    const folderType = props.data.folder_types.find(
      (f) => f.name === foundBaseFolder.folder_type
    );
    const foundFieldType = folderType.fields.media_horizontal_3x2;

    const foundHorizontalColorOverlay = get(
      foundBaseFolder,
      "fields.horizontal_color_overlay",
      null
    );

    if (foundHorizontalColorOverlay) {
      setHorizontalColorOverlay(foundHorizontalColorOverlay);
      setUseColorOverlay(true);
    }

    setBaseFolder(foundBaseFolder);
    setBackgroundFieldType(foundFieldType);
  }, []);

  const cssTransitionKey = useColorOverlay ? "true" : "false";

  // If the list is diamond then we disable the screens
  const disabledScreens = isDiamondList ? "[1,0],[2,0],[1,1],[2,1]" : null;

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight
        }}
      >
        <Slide1911
          {...props}
          data={{
            ...props.data,
            slotNames: ["media_horizontal_3x2"],
            showPlaceholder: true,
            placeholder: "Image",
            fieldType: backgroundFieldType
          }}
          handleEndOfPlay={null}
        />
      </div>

      {/* Background Overlay */}
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={1000}
          classNames="slowfadedelay"
        >
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              backgroundColor: horizontalColorOverlay
            }}
          />
        </CSSTransition>
      </TransitionGroup>

      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight
        }}
      >
        <DonorListWithRegions2108
          {...props}
          data={{
            ...props.data,
            componentSpecificData: {
              ...props.data.componentSpecificData,
              horizontal_screens: 3,
              vertical_screens: 2,
              donor_name_field: "name_diamond_platinum_list",
              donor_name_style: donorNameStyle,
              disabled_screens: disabledScreens
            }
          }}
        />
      </div>
    </>
  );
};

export default Boston3x2DiamondPlatinumList;
