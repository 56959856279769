import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Carousel, Tooltip} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {mapDefaultValuesToArray} from "../DynamicFileInput/FileInputService";
import MediaFileInput from "../DynamicFileInput/MediaFileInput";
import {MEDIA_PROGRESS_STATUS_PROCESSING} from "../MediaProgressStatus/MediaProgressStatus";


export default function ContentManagerWidget({
                                               fieldName, item, fieldsConfig, defaultValue, fieldValues,
                                               selected_folder, folderType, folder__status, openContentManagerCall, canUpdate
                                             }) {
  const [items, setItems] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [dynamicRenderInputs, setDynamicRenderInputs] = useState([])
  const [defaultValueCount, setDefaultValueCount] = useState(0);
  const [config, setConfig] = useState({});
  const [singleItemIndex, setSingleItemIndex] = useState(0);
  const [folderName, setFolderName] = useState('Content Manager');

  const carouselRef = useRef(null);

  useEffect(() => {
    const isProcessing = hasFieldValuesStatusForContentManagerWidgetInProgress(fieldValues)
    setProcessing(isProcessing);
  }, [fieldValues, folder__status, fieldName]);

  useEffect(() => {
    // TODO check if this is initialized correctly every time
    if (items === null) {
      const newItems = mapDefaultValuesToArray(defaultValue);
      setItems(newItems);
    }

    return () => {
      setItems(null);
    };
  }, []);

  const hasItemsInProgress = (selected_folder_id, folder__status) => {
    const folderStatus = folder__status.filter(
      (status) =>
        status.folder === selected_folder_id &&
        status.field_name === fieldName &&
        status.folder_status === MEDIA_PROGRESS_STATUS_PROCESSING
    );

    return folderStatus.length > 0;
  };

  const hasFieldValuesStatusForContentManagerWidgetInProgress = (fieldValuesObj) => {
    let isUpdated = false;

    Object.keys(fieldValuesObj).forEach((value, index) => {
      const items = fieldValuesObj[value];
      items.forEach((item, index) => {
        const inProgress = hasItemsInProgress(item.folder, folder__status)
        if (inProgress) {
          isUpdated = true;
        }
      });
    });

    return isUpdated;
  };

  const getConfigInfo = (fieldName, fieldsConfig) => {
    let configResult = null;
    if (fieldsConfig && fieldName && config) {
      const myConfig = fieldsConfig[fieldName];
      if (myConfig) {
        configResult = myConfig;
      }
    }
    return configResult;
  };

  useEffect(() => {
    if (defaultValue) {
      if (items === null) {
        const newItems = mapDefaultValuesToArray(defaultValue);
        setItems(newItems);
      }

      const defaultValueNoRemoved = getNoRemovedFromDefaultValue();
      setDefaultValueCount(defaultValueNoRemoved.length);
      const myFolderName = item.name;
      setFolderName(myFolderName);
    }
  }, [item, defaultValue]);

  useEffect(() => {
    if (fieldsConfig) {
      setConfig(fieldsConfig);
    }

  }, [fieldsConfig]);

  const getNoRemovedFromDefaultValue = () => {
    return defaultValue.filter((v) => v.status !== "removed");
  };

  const getDynamicRenderedInputs = () => {
    const myConfig = getConfigInfo(fieldName, fieldsConfig);

    if (!myConfig) {
      return null;
    }

    const myAcceptable = myConfig.acceptable_contents;

    const renderedItems = items.map((item) => {
      let data = null;
      if (defaultValue) {
        data = defaultValue.find((dv) => dv.rank === item.rank);
      }
      if (!data) {
        data = {status: "none"};
      }
      let key = item.identifier;
      if (data.thumb) {
        key = data.thumb;
      }

      return (
        <MediaFileInput
          key={key}
          data={data}
          item={item}
          className="content-manager-widget_slide"
          onEdit={() => {
          }}
          updateDefaultValueItem={() => {
          }}
          acceptable={myAcceptable}
          showIcons={false}
          disabled={processing}
        />
      );
    });

    return renderedItems;
  };

  const prevImage = () => {
    carouselRef.current.prev();
  };

  const nextImage = () => {
    carouselRef.current.next();
  };

  const getSingleItem = () => {
    if (!items || items.length === 0) {
      return null;
    } else {
      return items[singleItemIndex];
    }
  };

  const isContentManagerAndShouldShow = () => {
    return fieldName && folderType && folderType.fields &&
      !!getConfigInfo(fieldName, folderType.fields) &&
      /* if the item is new do not show content manager  */
      !item.new;
  };

  const openContentManager = () => {
    const myConfig = getConfigInfo(fieldName, folderType.fields);
    if (myConfig) {
      const myLimit = myConfig.limit;
      const myAcceptable = myConfig.acceptable_contents;
      const myAspect = myConfig.aspect;
      const singleItem = getSingleItem();
      openContentManagerCall(fieldValues, items, defaultValue, config, myAcceptable, fieldName,
        singleItem, myLimit, folderName, myAspect, selected_folder, item);
    } else {
      console.error("ERROR LOADING CONFIG CONTENT MANAGER");
    }
  };

  const onChangeCarousel = index => {
    setSingleItemIndex(index);
  };

  useEffect(() => {
    // TODO check this is update after add or delete a image using the content manager
    if (defaultValue && items) {
      const dynamicRender = getDynamicRenderedInputs();
      setDynamicRenderInputs(dynamicRender);
    }
  }, [defaultValue, items, processing]);

  return (
    <section className="content-manager-widget">
      <div className="content-manager-widget_carousel">
        <div className="content-manager-widget_buttons">
          {!processing && defaultValueCount > 1 ? (
            <Button onClick={prevImage} className="content-manager-widget_button content-manager-widget_button-prev">
              <FontAwesomeIcon className="fa-lg" icon={['fa', 'chevron-left']}/>
            </Button>) : null}
        </div>
        <div className="content-manager-widget_images">
          <div className="content-manager-widget_images-container">
            {
              processing ? (
                <div className="content-manager-widget_images-processing">
                  <span className="fa-layers fa-fw content-manager-widget_images-processing-icon">
                    <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-1x" spin/>
                </span>
                </div>
              ) : (<Carousel ref={carouselRef} dots={false} afterChange={onChangeCarousel}>
                {dynamicRenderInputs}
              </Carousel>)
            }
          </div>
          {!processing && defaultValueCount >= 1 ?
            (<div className="content-manager-widget_buttons content-manager-widget_buttons-main">
              <div className="content-manager-widget_button-overlap"></div>
              <div className="content-manager-widget_button content-manager-widget_button-main">
                <Tooltip title="Edit">
                  <FontAwesomeIcon
                    onClick={canUpdate ? openContentManager : () => {}}
                    className="fa-1x"
                    icon="edit"
                    style={{cursor: canUpdate ? "pointer" : "not-allowed"}}
                  />
                </Tooltip>
              </div>
            </div>) :
            !processing && isContentManagerAndShouldShow() ?
              (<div className="content-manager-widget_buttons content-manager-widget_buttons-add">
                <div className="content-manager-widget_button-overlap"></div>
                <div className="content-manager-widget_button content-manager-widget_button-main">
                  <Tooltip title="Edit">
                    <FontAwesomeIcon
                      onClick={canUpdate ? openContentManager : () => {}}
                      className="fa-1x"
                      icon="edit"
                      style={{cursor: canUpdate ? "pointer" : "not-allowed"}}
                    />
                  </Tooltip>
                </div>
              </div>) : null
          }
        </div>
        <div className="content-manager-widget_buttons">
          {!processing && defaultValueCount > 1 ? (
            <Button onClick={nextImage} className="content-manager-widget_button content-manager-widget_button-next">
              <FontAwesomeIcon className='fa-lg' icon={['fa', 'chevron-right']}/>
            </Button>) : null}
        </div>
      </div>
    </section>
  );
}

