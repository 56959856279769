import { getFontSizes } from "../../../../utils/general";
import { FontsFamilies } from "../../../../fonts";
import { wysiwyg } from "../standardized_fields";

export const glenwoodFonts = [
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.SERIF,
  FontsFamilies.LATO
];

const fontMinSize = 10;
const fontMaxSize = 40;

export const textStyleFontSizes = getFontSizes(10, 40, 1, "px", (i) => {
  return `${i}px`;
});

export const quillFontSizes = { min: fontMinSize, max: fontMaxSize, type: "range" };

export const settings = {
  use_em_units: true,
  wysiwyg_input_type: "text"
};

export const textStyleOptions = {
  font: {
    enabled: true,
    items: glenwoodFonts
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: textStyleFontSizes
  },
  lineHeight: {
    enabled: true,
    items: [
      { title: ".5", value: 0.5 },
      { title: ".75", value: 0.75 },
      { title: "1", value: 1 },
      { title: "1.25", value: 1.25 },
      { title: "1.5", value: 1.5 },
      { title: "1.75", value: 1.75 },
      { title: "2", value: 2 }
    ]
  },
  bold: {
    enabled: true
  },
  color: {
    enabled: true
  },
  italic: {
    enabled: true
  },
  underline: {
    enabled: true
  }
};

export const wysiwygSettings = {
  ...wysiwyg.settings,
  font_size_unit: "px",
  fontSizeOptions: quillFontSizes,
  fonts: glenwoodFonts
};
