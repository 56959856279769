import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { bestFit } from "../../../utils/screenUtils";
import EugeneStoryCardLarge from "./EugeneStoryCardLarge";
import EugeneStoryCardMedium from "./EugeneStoryCardMedium";
import EugeneStoryCardSmall from "./EugeneStoryCardSmall";
import {
  getBackgroundColorOrDefault,
  getCardOptionOrDefault
} from "./EugeneUtils";

interface IStoriesCardsProps extends IDisplayComponentProps {
  containerHeight: number;
  containerWidth: number;
  scrollableContent?: any;
  style?: any;
}

const StoriesCards2103: React.FC<IStoriesCardsProps> = (props) => {
  const initialContainerWidth = props.containerWidth || window.innerWidth;
  const initialContainerHeight = props.containerHeight || window.innerHeight;

  const [menuFolderId, setMenuFolderId] = useState(null);
  const [storiesMenuFolderId, setStoriesMenuFolderId] = useState(null);
  const [storiesFolderId, setStoriesFolderId] = useState(null);
  const params = useParams<{ storiesmenufolderid; storiesfolderid }>();

  useEffect(() => {
    //setMenuFolderId(Number(params.menufolderid));
    setStoriesMenuFolderId(Number(params.storiesmenufolderid));
    setStoriesFolderId(Number(params.storiesfolderid));
  }, [params]);

  const getBestFitCallback = useCallback(() => {
    return bestFit(
      initialContainerWidth,
      initialContainerHeight,
      initialContainerWidth,
      initialContainerHeight
    );
  }, [initialContainerWidth, initialContainerHeight]);

  const dims = getBestFitCallback();

  const [width, setWidth] = useState(dims.width);
  const [height, setHeight] = useState(dims.height);

  // Check for best fit dimensions.
  useEffect(() => {
    const dims = getBestFitCallback();
    setWidth(dims.width);
    setHeight(dims.height);
  }, [props.containerHeight, props.containerWidth]);

  const buildCardLink = (card) => {
    let newUrl = props.data.basePath;
    if (params.storiesmenufolderid) {
      newUrl += `/stories_menu/${params.storiesmenufolderid}`;
    }
    if (params.storiesfolderid) {
      newUrl += `/stories/${params.storiesfolderid}`;
    } else {
      return null;
    }
    return newUrl + "/story/" + card.id;
  };

  return (
    <div
      className="stories-cards-2103"
      style={{
        width: width,
        height: height
      }}
    >
      <div
        className="stories-cards-2103_cards"
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        {props.data.cards.map((card, index) => {
          switch (props.data.cardType) {
            case "small":
              return (
                <div
                  key={`stories-card-2103_cards_key-${index}`}
                  className="stories-cards-2103_card"
                  style={{
                    width: width * 0.3,
                    height: (width * 0.3 * 80) / 150,
                    marginRight: height * 0.02,
                    marginBottom: height * 0.02
                  }}
                >
                  <EugeneStoryCardSmall
                    containerWidth={width * 0.3}
                    containerHeight={(width * 0.3 * 80) / 150}
                    mode={props.mode}
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    data={{
                      ...props.data,
                      headline: getCardOptionOrDefault(
                        card,
                        "overrideHeadlineSmall",
                        "headline"
                      ),
                      subhead: getCardOptionOrDefault(
                        card,
                        "overrideSubheadSmall",
                        "subhead"
                      ),
                      body: getCardOptionOrDefault(
                        card,
                        "overrideBodySmall",
                        "body"
                      ),
                      base_folder: card.base_folder,
                      slotNames: [
                        "overrideThumbnailImageSmall",
                        "image_1",
                        "image_2",
                        "background"
                      ],
                      background: getBackgroundColorOrDefault(
                        card,
                        "overrideBackgroundSmall",
                        "color_overlay"
                      ),
                      cardLink: buildCardLink(card)
                    }}
                    webview_signedurls={props.webview_signedurls}
                  ></EugeneStoryCardSmall>
                </div>
              );
            case "medium":
              return (
                <div
                  key={`stories-card-2103_cards_key-${index}`}
                  className="stories-cards-2103_card"
                  style={{
                    width: width * 0.45,
                    height: (width * 0.45 * 273) / 586,
                    marginRight: height * 0.04,
                    marginBottom: height * 0.04
                  }}
                >
                  <EugeneStoryCardMedium
                    containerWidth={width * 0.45}
                    containerHeight={(width * 0.45 * 273) / 586}
                    mode={props.mode}
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    data={{
                      ...props.data,
                      headline: getCardOptionOrDefault(
                        card,
                        "overrideHeadlineMedium",
                        "headline"
                      ),
                      subhead: getCardOptionOrDefault(
                        card,
                        "overrideSubheadMedium",
                        "subhead"
                      ),
                      body: getCardOptionOrDefault(
                        card,
                        "overrideBodyMedium",
                        "body"
                      ),
                      base_folder: card.base_folder,
                      slotNames: [
                        "overrideThumbnailImageMedium",
                        "image_1",
                        "image_2",
                        "background"
                      ],
                      background: getBackgroundColorOrDefault(
                        card,
                        "overrideBackgroundMedium",
                        "color_overlay"
                      ),
                      cardLink: buildCardLink(card)
                    }}
                    webview_signedurls={props.webview_signedurls}
                  ></EugeneStoryCardMedium>
                </div>
              );
            case "large":
              return (
                <div
                  key={`stories-card-2103_cards_key-${index}`}
                  style={{
                    marginBottom: height * 0.04
                  }}
                >
                  <EugeneStoryCardLarge
                    containerWidth={width * 0.9}
                    containerHeight={(width * 200) / 590}
                    mode={props.mode}
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    data={{
                      ...props.data,
                      headline: getCardOptionOrDefault(
                        card,
                        "overrideHeadlineLarge",
                        "headline"
                      ),
                      subhead: getCardOptionOrDefault(
                        card,
                        "overrideSubheadLarge",
                        "subhead"
                      ),
                      body: getCardOptionOrDefault(
                        card,
                        "overrideBodyLarge",
                        "body"
                      ),
                      base_folder: card.base_folder,
                      slotNames: [
                        "overrideThumbnailImageLarge",
                        "image_1",
                        "image_2",
                        "background"
                      ],
                      imageBackgroundColor: getBackgroundColorOrDefault(
                        card,
                        "overrideBackgroundLarge",
                        "color_overlay"
                      ),
                      background: getBackgroundColorOrDefault(
                        card,
                        "overrideBackgroundLarge",
                        "color_overlay"
                      ),
                      cardLink: buildCardLink(card)
                    }}
                    webview_signedurls={props.webview_signedurls}
                  ></EugeneStoryCardLarge>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};

export default StoriesCards2103;
