import React, { Component } from 'react';

class ReleaseNote extends Component {


  renderSections = (sections) => {
    return (
      sections.map((x, index) => {
        if (!x.items || !x.items.length) {
          return null;
        }
        return (
          <div key={index}>
            <b>{x.name}</b>
            <ul>
              {this.renderSectionItems(x.items)}
            </ul>
          </div>
        )
      })
    )
  }

  renderSectionItems = (items) => {
    return (
      items.map((x, index) => {
        return (
          <li key={index}><b>{x.area}: </b>{x.note}</li>
        )
      })
    )
  }

  render() {
    return (
      <div>
        <hr />
        <b>Version: {this.props.data.version}</b><br />
        <b>Date: {this.props.data.date}</b><br />
        {this.renderSections(this.props.data.sections)}
      </div>
    )
  }
}

export default ReleaseNote



