export enum FontsFamilies {
    ARIAL = 'Arial',
    BARLOW = 'Barlow',
    BEMBO = 'Bembo',
    CACHET_PRO = 'Cachet-Pro',
    GOTHAM = 'Gotham',
    HIND = 'Hind',
    LATO = 'Lato',
    LORA = 'Lora',
    MERRIWEATHER = 'Merriweather',
    META_PRO = 'Meta-Pro',
    MULI = 'Muli',
    MYRIAD_PRO = 'Myriad-Pro',
    OPEN_SANS = 'Open Sans',
    RECTOR = 'Rector',
    ROBOTO = 'Roboto',
    ROBOTO_SLAB = 'Roboto Slab',
    RUBIK = 'Rubik',
    SERIF = 'Serif',
    SANS_SERIF = 'Sans Serif',
    VERDANA = 'Verdana',
    WHITNEY = 'Whitney',
    GILROY = 'Gilroy',
    LUSITANA = 'Lusitana',
    OPTIMA = 'Optima',
    ABC_SOCIAL = 'ABC Social',
    PANTO0GRAPH_CONDENSED = 'Pantograph Condensed',
    PANTO0GRAPH_EXTENDED = 'Pantograph Extended',
    PANTO0GRAPH_WIDE = 'Pantograph Wide'
}

export enum FontVariants {
    THIN = '100 - Thin',
    EXTRA_LIGHT = '200 - Extra Light, Ultra Light',
    LIGHT = '300 - Light, Book',
    REGULAR = '400 - Normal, Regular',
    MEDIUM = '500 - Medium',
    SEMI_BOLD = '600 - Semi Bold',
    BOLD = '700 - Bold',
    EXTRA_BOLD = '800 - Extra Bold, Ultra Bold',
    HEAVY = '900 - Black, Heavy',
}

export type Font = { [key in FontVariants]?: string };

export type Fonts = { [key in FontsFamilies]: Font };

export const fonts: Fonts = {
    [FontsFamilies.ARIAL]: {
        [FontVariants.REGULAR]: 'arial',
        [FontVariants.BOLD]: 'arial-bold'
    },
    [FontsFamilies.BARLOW]: {
        [FontVariants.THIN]: 'barlow-thin',
        [FontVariants.LIGHT]: 'barlow-light',
        [FontVariants.REGULAR]: 'barlow-regular',
        [FontVariants.MEDIUM]: 'barlow-medium',
        [FontVariants.SEMI_BOLD]: 'barlow-semibold',
        [FontVariants.BOLD]: 'barlow-bold',
    },
    [FontsFamilies.BEMBO]: {
        [FontVariants.SEMI_BOLD]: 'bembo-semibold'
    },
    [FontsFamilies.CACHET_PRO]: {
        [FontVariants.BOLD]: 'cachet-pro-bold',
        [FontVariants.REGULAR]: 'cachet-pro-regular',
        [FontVariants.MEDIUM]: 'cachet-pro-medium',
    },
    [FontsFamilies.GOTHAM]: {
        [FontVariants.BOLD]: 'gotham-bold',
        [FontVariants.REGULAR]: 'gotham-book',
        [FontVariants.LIGHT]: 'gotham-light',
        [FontVariants.MEDIUM]: 'gotham-medium',
    },
    [FontsFamilies.HIND]: {
        [FontVariants.LIGHT]: 'hind-light',
        [FontVariants.REGULAR]: 'hind-regular',
    },
    [FontsFamilies.LATO]: {
        [FontVariants.REGULAR]: 'lato',
        [FontVariants.BOLD]: 'lato-bold',
    },
    [FontsFamilies.LORA]: {
        [FontVariants.REGULAR]: 'lora-regular',
        [FontVariants.MEDIUM]: 'lora-medium',
        [FontVariants.SEMI_BOLD]: 'lora-semibold',
        [FontVariants.BOLD]: 'lora-bold',
    },
    [FontsFamilies.MERRIWEATHER]: {
        [FontVariants.BOLD]: 'merriweather-bold',
        [FontVariants.REGULAR]: 'merriweather-regular',
        [FontVariants.LIGHT]: 'merriweather-light'
    },
    [FontsFamilies.META_PRO]: {
        [FontVariants.REGULAR]: 'meta-pro',
        [FontVariants.BOLD]: 'meta-pro-bold',
        [FontVariants.LIGHT]: 'meta-pro-light',
    },
    [FontsFamilies.MULI]: {
        [FontVariants.BOLD]: 'muli-bold',
        [FontVariants.LIGHT]: 'muli-light',
        [FontVariants.SEMI_BOLD]: 'muli-semi-bold',
        [FontVariants.REGULAR]: 'muli'
    },
    [FontsFamilies.MYRIAD_PRO]: {
        [FontVariants.REGULAR]: 'myriad-pro',
        [FontVariants.BOLD]: 'myriad-pro-bold',
        [FontVariants.LIGHT]: 'myriad-pro-light',
        [FontVariants.SEMI_BOLD]: 'myriad-pro-semibold'
    },
    [FontsFamilies.OPEN_SANS]: {
        [FontVariants.LIGHT]: 'open-sans-light',
        [FontVariants.REGULAR]: 'open-sans-regular',
        [FontVariants.MEDIUM]: 'open-sans-medium',
        [FontVariants.SEMI_BOLD]: 'open-sans-semi-bold',
        [FontVariants.BOLD]: 'open-sans-bold'
    },
    [FontsFamilies.ROBOTO]: {
        [FontVariants.REGULAR]: 'roboto',
        [FontVariants.LIGHT]: 'roboto-light',
        [FontVariants.MEDIUM]: 'roboto-medium',
        [FontVariants.BOLD]: 'roboto-bold'
    },
    [FontsFamilies.ROBOTO_SLAB]: {
        [FontVariants.REGULAR]: 'robotoslabregular',
    },
    [FontsFamilies.RUBIK]: {
        [FontVariants.LIGHT]: 'rubik-light',
        [FontVariants.REGULAR]: 'rubik-regular',
        [FontVariants.MEDIUM]: 'rubik-medium',
        [FontVariants.SEMI_BOLD]: 'rubik-semi-bold',
        [FontVariants.BOLD]: 'rubik-bold',
    },
    [FontsFamilies.SERIF]: {
        [FontVariants.REGULAR]: 'serif'
    },
    [FontsFamilies.SANS_SERIF]: {
        [FontVariants.REGULAR]: "sans-serif"
    },
    [FontsFamilies.VERDANA]: {
        [FontVariants.REGULAR]: "verdana-regular",
        [FontVariants.BOLD]: "verdana-bold",
    },
    [FontsFamilies.WHITNEY]: {
        [FontVariants.BOLD]: 'whitney-bold',
        [FontVariants.REGULAR]: 'whitney-book',
        [FontVariants.LIGHT]: 'whitney-light',
        [FontVariants.SEMI_BOLD]: 'whitney-semibold',
    },
    [FontsFamilies.GILROY]: {
        [FontVariants.MEDIUM]: 'gilroy-medium',
    },
    [FontsFamilies.RECTOR]: {
        [FontVariants.REGULAR]: 'rector',
        [FontVariants.MEDIUM]: 'rector-medium',
    },
    [FontsFamilies.ABC_SOCIAL]: {
        [FontVariants.REGULAR]: 'abc-social-regular',
        [FontVariants.BOLD]: 'abc-social-bold',
        [FontVariants.MEDIUM]: 'abc-social-medium',
        [FontVariants.LIGHT]: 'abc-social-book',
    },
    [FontsFamilies.LUSITANA]: {
        [FontVariants.REGULAR]: 'lusitana',
    },
    [FontsFamilies.OPTIMA]: {
        [FontVariants.REGULAR]: 'optima',
        [FontVariants.BOLD]: 'optima-bold',
    },
    [FontsFamilies.PANTO0GRAPH_CONDENSED]: {
        [FontVariants.BOLD]: 'pantograph-condensed-bold',
    },
    [FontsFamilies.PANTO0GRAPH_EXTENDED]: {
        [FontVariants.SEMI_BOLD]: 'pantograph-extended-semibold',
    },
    [FontsFamilies.PANTO0GRAPH_WIDE]: {
      [FontVariants.BOLD]: 'pantograph-wide-bold',
      [FontVariants.REGULAR]: 'pantograph-wide-regular',
      [FontVariants.LIGHT]: 'pantograph-wide-light',
    }
}

export const findFontFamily = (variant: string) => {
    return Object.values(FontsFamilies).find(f => Object.keys(fonts[f]).some(v => fonts[f][v] === variant));
}

export const findFontVariant = (variant: string) => {
    const fontFamily = findFontFamily(variant)
    if (!fontFamily) return
    return Object.values(FontVariants).find(v => fonts[fontFamily][v] === variant)
}

const defaultVariantOrder = [
    FontVariants.REGULAR,
    FontVariants.MEDIUM,
    FontVariants.LIGHT,
    FontVariants.SEMI_BOLD,
    FontVariants.EXTRA_LIGHT,
    FontVariants.BOLD,
    FontVariants.THIN,
    FontVariants.EXTRA_BOLD,
    FontVariants.HEAVY
]

export const findFontDefaultVariant = (font: FontsFamilies) => defaultVariantOrder.find((v) => fonts[font][v] !== undefined)

export const allFonts = Object.values(FontsFamilies)

export const allFontsVariants = Object.values(fonts).reduce((arr, font) => arr.concat(Object.values(font)), new Array<string>())
