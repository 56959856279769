import get from "lodash/get";
import React from "react";
import { createUseStyles } from "react-jss";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const BerkeleyTemplate003 = (props) => {
  const {getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue} = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType()
  const { getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();

  const backgroundOverlay = getBaseFolderFieldValue("background_image_overlay")
  const mirrorContent = getBaseFolderFieldValue("mirror_content")
  const storyBackground = getBaseFolderFieldValue("story_background")
  const quoteBackground = getBaseFolderFieldValue("quote_background")
  const header = getBaseFolderFieldValue("header", baseFolderType)
  const subhead = getBaseFolderFieldValue("subhead", baseFolderType)
  const body = getBaseFolderFieldValue("body", baseFolderType)
  const quote = getBaseFolderFieldValue("quote", baseFolderType)
  const attribution = getBaseFolderFieldValue("attribution", baseFolderType)

  const screenWidth = props.containerWidth / 3
  const screenHeight = props.containerHeight / 2

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundOverlay,
    mirrorContent,
    quoteBackground,
    storyBackground,
    screenWidth,
    screenHeight
  });

  const propsClasses = get(props, 'classes.componentContainer', '');

  return (
    <div className={[classes.componentContainer, propsClasses].join(" ")}>
      <div className={classes.storyWithQuoteContent}>
        <div className={classes.story}>
          <div className="storyTop">
            <RichTextRender
              htmlString={header}
            />
            <RichTextRender
              htmlString={subhead}
            />
          </div>
          <div className="storyBottom">
            <RichTextRender
              htmlString={body}
            />
          </div>
        </div>
        <div className="backgroundSection">
          <div className="backgroundContainer">
            <div className="backgroundContainerOverlay"/>
            <Slide1911
              {...props}
              handleEndOfPlay={null}
              containerHeight={props.containerHeight}
              containerWidth={screenWidth * 2}
              data={{
                ...props.data,
                muted: false,
                slotNames: ["background_image"],
                base_folder: get(baseFolder, "id", null),
                backgroundSize: "contain",
              }}
            />
          </div>
          {
            (quote || attribution) && (
              <div className="quoteContainer">
                <div className="quoteSection">
                  <div>
                    <RichTextRender
                      htmlString={quote}
                    />
                  </div>
                  <div>
                    <RichTextRender
                      htmlString={attribution}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default BerkeleyTemplate003;
