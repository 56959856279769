import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { everettFonts, quillFontSizes, transitions, list_styles } from "./everett_settings";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { sorting_options, wysiwyg } from "../standardized_fields";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { textStyleOptions } from "./everett_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const everett_donor_list_with_quote: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  name: FOLDER_TYPE.everett_donor_list_with_quote,
  display_name: "Donor List With Quote",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    quote: {
      title: "Quote",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    donor_list: {
      title: "Donor List",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    horizontal_rules: {
      title: "Horizontal Rules",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "Logo / QR Code",
      default_expanded: true,
      order: 6,
      accordion: true,
      tab: "general"
    },
    list_settings: {
      title: "List Settings",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    // General Field Group
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    donor_transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_transition"
      }
    },
    donor_cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "donor_cycle_time"
      },
    },
    sorting_options: {
      ...sorting_options,
      field_group: "general",
      default: "sorting_order"
    },
    mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "general"
    },
    // Quote Field Group
    quote_header: {
      title: "Header",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            align: "center",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.BOLD],
            size: "64px",
            bold: true,
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_text_top_screen: {
      title: "Text top screen",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            align: "center",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
            size: "60px",
            lineHeight: "1.75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_text_bottom_screen: {
      title: "Text bottom screen",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            align: "center",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
            size: "60px",
            lineHeight: "1.75"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_attribution: {
      title: "Attribution",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            align: "center",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
            size: "48px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_background: {
      type: "color",
      title: "Quote Background",
      field_group: "quote",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // Donor List Field Group
    donor_list_header: {
      title: "Donor List Header",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
            size: "64px",
            bold: true,
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    description: {
      title: "Donor List Description",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            alignment: "left",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.LIGHT],
            size: "36px",
            italic: true,
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    category: {
      title: "Donor List Category",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#000000",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
            size: "28px",
            bold: true,
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    columns: {
      title: "Columns",
      type: "integer",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
      default: 3,
      field_group: "donor_list"
    },
    column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "donor_list"
    },
    donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "donor_list"
    },
    footer: {
      title: "Footer",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "20px",
            color: "#1c2858"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    donor_list_background: {
      type: "color",
      title: "Donor List Background",
      field_group: "donor_list",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    switch_to_logo: {
      type: "select",
      title: "Logo or QR Code",
      field_group: "qr",
      default: "none",
      options: [
        { title: "None", value: "none" },
        { title: "Logo", value: "logo" },
        { title: "QR Code", value: "qrCode" },
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_switch_to_logo"
      }
    },
    mirror_logo_qr: {
      type: "bool",
      title: "Mirror Logo/QR",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: false,
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_mirror_logo_qr"
      }
    },
    call_to_action_header: {
      title: "Call To Action Header",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.BOLD],
            size: "64px",
            lineHeight: "1.5"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_call_to_action_header"
      }
    },
    call_to_action_body: {
      title: "Call to Action Body",
      field_group: "qr",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
            size: "44px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_call_to_action_body"
      }
    },
    qr_link: {
      type: "text",
      title: "QR Code Link",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      show_char_count: false,
      field_group: "qr",
      validation: {
        rules: [
          //{ name: "isRequiredExclusiveBoolean", otherFieldName: 'switch_to_logo', message: "Please enter a valid link. I.E: https://www.google.com" },
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_qr_link"
      }
    },
    qr_color: {
      type: "color",
      title: "QR Color",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      default: "#ffffff",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "quote_qr_color"
      }
    },
    logo: {
      title: "Default Logo",
      isVisible: (values) => values.switch_to_logo === 'logo',
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "quote_logo"
      }
    },
    qr_background_color: {
      type: "color",
      title: "QR / Logo Background Color",
      isVisible: (values) => values.switch_to_logo !== 'none',
      default: "#0000007F",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "quote_qr_background_color"
      }
    },
    // Horizontal Rules Field Group
    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      field_group: "horizontal_rules"
    },
    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      field_group: "horizontal_rules"
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#000",
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    // Background Field Group
    donor_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "background"
    },
    donor_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    // List Settings Field Group
    list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    list_margin_bezel: {
      type: "integer",
      title: "Donor List Margin Around Bezel",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 3,
      field_group: "list_settings"
    },
    donor_line_height: {
      title: "Donor Line Height",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 1,
      field_group: "list_settings"
    },
    donor_name_margin_bottom: {
      type: "integer",
      title: "Donor Name Bottom Margin",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    cycle_forward_key: {
      type: "text",
      title: "Cycle Forward Key",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      default: "ArrowRight",
      field_group: "list_settings"
    },
    donor_list_style: {
      type: "select",
      title: "List Style",
      field_group: "list_settings",
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name", "sorting_order"]
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 16 / 9,
    component_name: COMPONENT_NAME.Everett2x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "2x2", orientation: "landscape", horizontal: 2, vertical: 2 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/everett/donor_list.svg"
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false,
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.everett_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.everett_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.everett_donor],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  cycle_field_name: "donor_cycle_time"
};
