import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { hasPermission } from "../../utils/permissionUtils";
import { deleteUser } from "../../actions/userActions";
import AlertNotification from "../layout/AlertNotification";
import { withRouter } from "../../hooks/withRouter";


class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: "",
      confirm_modal_visible: false,
      user_to_delete_id: null,
    };
  }
  
  onDeleteClick(user_id) {
    this.setState({
      confirm_modal_visible: true,
      user_to_delete_id: user_id,
    });
  }

  onModalCancel = () => {
    this.setState({
      confirm_modal_visible: false,
      user_to_delete_id: null,
    });
  };

  onModalOk = () => {
    this.props.delete_user(
      this.state.user_to_delete_id,
      Number(this.props.companyId),
      this.onDeleteSuccess,
      this.onDeleteFail
    );
    this.setState({
      confirm_modal_visible: false,
    });
  };

  onDeleteSuccess = () => {
    AlertNotification(
      "success",
      "Success",
      "User has been successfully deleted"
    );
  };

  onDeleteFail = (message) => {
    AlertNotification("error", "Error", message || "Error deleting the user.");
  };

  render() {
    let userUrl = "/company/" + this.props.companyId + "/user/new/";

    let userTableColumns = [
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: (a, b) => {
          a.first_name = a.first_name || "";
          b.first_name = b.first_name || "";
          if (a.first_name.toLowerCase() > b.first_name.toLowerCase())
            return -1;
          if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return 1;
          return 0;
        },
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: (a, b) => {
          a.last_name = a.last_name || "";
          b.last_name = b.last_name || "";
          if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return -1;
          if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return 1;
          return 0;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email - b.email,
      }
    ];

    if (
      hasPermission(
        this.props.grouped_permissions,
        "user",
        "edit",
        this.props.user,
        null,
        null,
        this.props.user?.company ?? null
      )
    ) {
      userTableColumns.push({
        title: "Edit",
        dataIndex: "edit",
        render: (text, record) => {
          let url =
            "/company/" + this.props.companyId + "/user/" + record.id + "/";
          // let url="/project/" + record.id + "/";
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.navigate(url);
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon="edit" />
            </span>
          );
        },
      });
    }

    if (
      hasPermission(
        this.props.grouped_permissions,
        "user",
        "delete",
        this.props.user,
        null,
        null,
        this.props.user?.company ?? null
      )
    ) {
      userTableColumns.push({
        title: "Delete",
        dataIndex: "delete",
        render: (text, record) => {
          if (record.id !== this.props.user.id && !record.system_admin) {
            return (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => this.onDeleteClick(record.id)}
              >
                <FontAwesomeIcon className="fa-lg" icon={["far", "trash"]} />
              </span>
            );
          } else {
            return "";
          }
        },
      });
    }

    let user_datasource = [];

    if (this.props.user__companies) {
      user_datasource = Object.values(this.props.user__companies)
        .filter(uc => Number(uc.company) === Number(this.props.companyId))
        .map(uc => ({
          first_name: this.props.users[uc.user].first_name,
          last_name: this.props.users[uc.user].last_name,
          email: this.props.users[uc.user].email,
          key: uc.id,
          id: uc.user,
        }));
    }

    return (
      <div>
        <Modal
          visible={this.state.confirm_modal_visible}
          onCancel={this.onModalCancel}
          onOk={this.onModalOk}
        >
          <span>
            Do you really want to delete this user? This operation is
            irreversible
          </span>
        </Modal>
        <Table
          title={() =>
            hasPermission(
              this.props.grouped_permissions,
              "user",
              "create",
              this.props.user,
              null,
              null,
              this.props.user?.company ?? null
            ) ? (
              <Button onClick={() => this.props.navigate(userUrl)}>
                Add User
              </Button>
            ) : (
              <b>Users</b>
            )
          }
          rowKey={(record) => record.key}
          columns={userTableColumns}
          dataSource={user_datasource}
          pagination={false}
          bordered={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user__companies: state.data.user__companies,
    grouped_permissions: state.data.user_permissions_grouped,
    user: state.data.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  delete_user: (user_id_to_delete, company_id, onSuccess, onFail) => {
    dispatch(deleteUser(user_id_to_delete, company_id, onSuccess, onFail));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersTable)
);
