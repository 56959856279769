import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { textStyleOptions } from "./glenwood_settings";
import { glenwood_template_shared } from "./glenwood_template_shared";

const modifiedFieldGroups = {
  ...glenwood_template_shared.field_groups,
  headline: {
    ...glenwood_template_shared.field_groups.headline,
    title: "Family"
  },
  subhead: {
    ...glenwood_template_shared.field_groups.subhead,
    title: "Baby"
  },
  body: {
    ...glenwood_template_shared.field_groups.body,
    title: "Announcement"
  },
  image_1: {
    title: "Image 1",
    default_expanded: false,
    order: 5,
    accordion: true,
    tab: "main"
  }
};

delete modifiedFieldGroups.background_overlay;
delete modifiedFieldGroups.presentations;

const modifiedFields = {
  ...glenwood_template_shared.fields,
  headline: {
    ...glenwood_template_shared.fields.headline,
    title: "Name",
    default: {
      wysiwyg: {
        type: "style",
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "30px",
          color: "#00AAA6",
          align: "center"
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  },
  subhead: {
    ...glenwood_template_shared.fields.subhead,
    title: "Name",
    default: {
      wysiwyg: {
        type: "style",
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "40px",
          color: "#00AAA6",
          align: "center",
          bold: true
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  },
  body: {
    ...glenwood_template_shared.fields.body,
    title: "Announcement",
    default: {
      wysiwyg: {
        type: "style",
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "24px",
          color: "#000000",
          align: "center"
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  },
  override_headline_large: {
    ...glenwood_template_shared.fields.override_headline_large,
    default: {
      wysiwyg: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "40px",
          color: "#00AAA6",
          align: "left"
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  },
  override_subhead_large: {
    ...glenwood_template_shared.fields.override_subhead_large,
    default: {
      wysiwyg: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "28px",
          color: "#000000",
          align: "left"
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  },
  override_body_text_large: {
    ...glenwood_template_shared.fields.override_body_text_large,
    default: {
      wysiwyg: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "24px",
          color: "#000000",
          align: "left"
        },
        ops: [],
        dark_mode: true
      },
      textarea: ""
    }
  }
};

export const glenwood_template_005: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.glenwood_template_005,
  display_name: "Template 5 - Landscape - Image Left - Announcement Right",
  tabs: {
    ...glenwood_template_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...modifiedFields,
    birth_date: {
      type: "datetime",
      title: "Date",
      validation: { rules: [] },
      default: "",
      field_group: "subhead"
    },
    birth_styles: {
      type: "textstyle",
      title: "Date Style",
      show_char_count: false,
      field_group: "subhead",
      default: {
        color: "#000000",
        alignment: "center",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions
    },
    image_1: {
      title: "Image 1",
      type: "file",
      slot_type: "dynamic",
      limit: 10,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.888888889,
      recommended_size: {
        width: 960,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.888888889
      },
      field_group: "image_1"
    }
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodTemplate005,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  tab_previews: [
    {
      tab_key: "main",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.GlenwoodTemplate005,
      type: PREVIEW_TYPE.COMPONENT,
      screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
    },
    {
      tab_key: "card_view",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.GlenwoodTemplateCardView,
      type: PREVIEW_TYPE.COMPONENT,
      screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
    }
  ],
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_002.svg"
  },
  save_options: [...glenwood_template_shared.save_options]
};
