import _ from "lodash";
import React, { Component } from "react";
import { getBaseFolder } from "../../../utils/commonDataUtils";
import {
  getFileDynamic,
  getFileTypeFromResources,
  videoType,
} from "../../../utils/fileUtils";
import { bestFit } from "../../../utils/screenUtils";
import { DEFAULT_CYCLE_TIME, GREY_HEADER } from "./NewOrleansConstants";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";

interface INewOrleansTemplate003State {
  base_folder?: IFolder;
  width: number;
  height: number;
  file_type: string;
  containerHeight: number;
  containerWidth: number;
}

class NewOrleansTemplate003 extends Component<
  IDisplayComponentProps,
  INewOrleansTemplate003State
> {

  _file = null;
  _cycle_time = 0;
  _metadataLoaded = false;
  _dataLoaded = false;
  _cycleTimeout = null;
  _videoRef = null;
  _initPlayerTimeout = null;

  constructor(props) {
    super(props);

    const baseFolder = getBaseFolder(props.data);
    this._cycle_time = _.get(baseFolder, "fields.cycle_time", 5) * 1000;

    const containerHeight = props.containerHeight || window.innerHeight;
    const containerWidth = props.containerWidth || window.innerWidth;

    const dims = bestFit(
      containerWidth,
      containerHeight,
      containerWidth,
      containerHeight
    );

    this.state = {
      width: dims.width,
      height: dims.height,
      file_type: "",
      containerHeight,
      containerWidth,
      base_folder: baseFolder,
    };
  }

  componentDidMount() {
    this.initializeNewData();
    let file_type = getFileTypeFromResources(
      this.props.data.resources,
      this.props.data.resource__folders,
      ["file", "slide"],
      this.props.data.base_folder
    );
    let preset = this.props.mode === "preview" ? "preview" : null;
    if (file_type === "video") {
      this.initPlayer();
    } else {
      this.setCycleTimeout(this._cycle_time, file_type);
    }

    this._file = getFileDynamic(
      this.props.aws,
      this.props.aws_bucket,
      this.props.data.resources,
      this.props.data.resource__folders,
      ["file", "slide"],
      this.props.data.base_folder,
      preset,
      this.props.webview_signedurls
    );

    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft": //left
        //todo: handle previouse?
        break;
      case "ArrowUp": //up
        break;
      case "ArrowRight": //right
        this.props.handleEndOfPlay();
        break;
      case "ArrowDown": //down
        break;
      default:
        break;
    }
  };

  unbindEvents = () => {
    this._videoRef.removeEventListener("loadedmetadata", this.loadedMetadata);
    this._videoRef.removeEventListener("loadeddata", this.loadedData);
    this._videoRef.removeEventListener("ended", this.videoEnd);
  };

  initPlayer = () => {
    let player = this._videoRef;
    if (player) {
      player.addEventListener("loadedmetadata", this.loadedMetadata);
      player.addEventListener("loadeddata", this.loadedData);
      player.addEventListener("ended", this.videoEnd);
      if (player.readyState >= 2) {
        this.loadedMetadata();
        this.loadedData();
      }
    } else {
      clearTimeout(this._initPlayerTimeout);
      this._initPlayerTimeout = setTimeout(() => {
        this.initPlayer();
      }, 100);
    }
  };

  videoEnd = () => {
    if (this.props.handleEndOfPlay) {
      this.props.handleEndOfPlay();
    }
  };

  loadedMetadata = () => {
    this._metadataLoaded = true;
    this.checkIfAllDataLoaded();
  };

  loadedData = () => {
    this._dataLoaded = true;
    this.checkIfAllDataLoaded();
  };

  checkIfAllDataLoaded = () => {
    if (this._metadataLoaded && this._dataLoaded) {
    }
  };

  componentWillUnmount() {
    clearTimeout(this._cycleTimeout);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.base_folder !== prevProps.data.base_folder) {
      this.initializeNewData();
      this.setCycleTimeout(this._cycle_time);
    }
    if (
      this.props.data.s3Cache &&
      this.props.data.s3Cache !== prevProps.data.s3Cache
    ) {
      const preset = this.props.mode === "preview" ? "preview" : null;
      this._file = getFileDynamic(
        this.props.aws,
        this.props.aws_bucket,
        this.props.data.resources,
        this.props.data.resource__folders,
        ["file", "slide"],
        this.props.data.base_folder,
        preset,
        this.props.webview_signedurls
      );
      this.setState({});
    }
    if (
      prevProps.containerWidth !== this.props.containerWidth ||
      prevProps.containerHeight !== this.props.containerHeight
    ) {
      const dims = bestFit(
        this.props.containerWidth,
        this.props.containerHeight,
        this.props.containerWidth,
        this.props.containerHeight
      );
      this.setState({
        width: dims.width,
        height: dims.height,
      });
    }
  }

  initializeNewData() {
    let base_folder = getBaseFolder(this.props.data);

    // use prop cycle_time if available otherwise use field value cycle time
    if (this.props.cycle_time) {
      this._cycle_time = this.props.cycle_time;
    } else if (_.get(base_folder, "fields.cycle_time", null)) {
      this._cycle_time = base_folder.fields.cycle_time * 1000;
    }
    this.setState({
      base_folder: base_folder,
    });
  }

  setCycleTimeout(cycle_time, file_type = null) {
    clearTimeout(this._cycleTimeout);
    if (cycle_time === 0) {
      cycle_time = DEFAULT_CYCLE_TIME;
    }
    if (file_type === "image" || file_type === "video" || !file_type) {
      this._cycleTimeout = setTimeout(() => {
        if (this.props.handleEndOfPlay) {
          this.props.handleEndOfPlay();
        }
      }, cycle_time);
    }
  }

  renderFile = (file) => {
    if (!file) {
      return null;
    }
    let file_type = getFileTypeFromResources(
      this.props.data.resources,
      this.props.data.resource__folders,
      ["file", "slide"],
      this.props.data.base_folder
    );
    switch (file_type) {
      case "image":
        return (
          <div
            style={{
              position: "absolute",
              backgroundImage: `url(${this._file})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: this.state.width,
              height: 0.31834 * this.state.height,
              top: this.state.height * 0.23875,
            }}
          ></div>
        );
      case "video":
        // let cType = videoType(this._file);
        return (
          <div
            style={{
              position: "absolute",
              width: this.state.width,
              top: this.state.height * 0.23875,
            }}
          >
            <video
              ref={(el) => (this._videoRef = el)}
              style={{
                width: "100%",
              }}
              muted
              autoPlay
            >
              <source src={this._file} />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      default:
        console.log("Unsupported file type ", file);
        return null;
    }
  };

  render() {
    if (!this.state.base_folder || !this.state.base_folder.fields) {
      return null;
    }

    const subtitlePaddingPercentage = 0.10976;
    return (
      <div style={{ color: "black" }}>
        <div
          style={{
            position: "absolute",
            width: this.state.width,
            left: 0,
            right: 0,
            textAlign: "center",
            top: this.state.height * 0.0249,
            fontSize: this.state.height * 0.0249,
            textTransform: "uppercase",
            fontWeight: "bold",
            color: GREY_HEADER,
            fontFamily: "gotham-bold",
            whiteSpace: "pre-wrap",
            overflow: 'hidden',
            height: this.state.height * 0.037339557,
          }}
        >
          {this.state.base_folder.fields.header01}
        </div>
        <div
          style={{
            position: "absolute",
            top: this.state.height * 0.11,
            left: this.state.width * 0.09451,
            width: this.state.width * (1 - subtitlePaddingPercentage * 2),
            fontSize: this.state.height * 0.02739,
            fontFamily: "gotham-bold",
            whiteSpace: "pre-wrap",
            lineHeight: 1.25,
            display: "flex",
            alignItems: "flex-end",
            height: this.state.height * 0.11,
            overflow: "hidden"
          }}
        >
          {this.state.base_folder.fields.subtitle01}
        </div>
        {this.renderFile(this._file)}
        <div
          style={{
            position: "absolute",
            top: this.state.height * 0.57439,
            left: this.state.width * 0.09451,
            width: this.state.width * 0.814024,
            fontSize: this.state.height * 0.0184,
            fontFamily: "hind-light",
            lineHeight: 1.9,
            whiteSpace: "pre-wrap",
            maxHeight: 0.3787 * this.props.containerHeight,
            overflow: 'hidden'
          }}
        >
          {this.state.base_folder.fields.text01}
        </div>
      </div>
    );
  }
}

export default NewOrleansTemplate003;
