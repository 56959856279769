import React from 'react';

export default props => {
  const getPath = (name, fill) => {
    switch(name) {
      case 'donor-bios':
        return <path fill={fill} d="M208,256c35.3,0,64-28.7,64-64s-28.7-64-64-64s-64,28.7-64,64S172.7,256,208,256z M118.4,384h179.2
        c12.4,0,22.4-8.6,22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6c-10.8,0-18.7,8-44.8,8c-26.9,0-33.4-8-44.8-8
        c-37.1,0-67.2,25.8-67.2,57.6v19.2C96,375.4,106,384,118.4,384z M360,320h112c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8H360
        c-4.4,0-8,3.6-8,8v16C352,316.4,355.6,320,360,320z M360,256h112c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8H360c-4.4,0-8,3.6-8,8v16
        C352,252.4,355.6,256,360,256z M360,192h112c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8H360c-4.4,0-8,3.6-8,8v16
        C352,188.4,355.6,192,360,192z" />;
      case 'timeline-solid':
        return <path fill={fill} d="M243.2,409.6v76.8c0,14.1,11.5,25.6,25.6,25.6h76.8c14.1,0,25.6-11.5,25.6-25.6v-76.8c0-14.1-11.5-25.6-25.6-25.6h-76.8
		C254.7,384,243.2,395.5,243.2,409.6z M140.8,275.2v161.3c0,16.9,13.8,30.7,30.7,30.7h46.1v-38.4h-38.4V275.2h38.4v-38.4h-38.4V83.2
		h38.4V44.8h-46.1c-16.9,0-30.7,13.8-30.7,30.7v161.3 M243.2,217.6v76.8c0,14.1,11.5,25.6,25.6,25.6h76.8
		c14.1,0,25.6-11.5,25.6-25.6v-76.8c0-14.1-11.5-25.6-25.6-25.6h-76.8C254.7,192,243.2,203.5,243.2,217.6z M243.2,25.6v76.8
		c0,14.1,11.5,25.6,25.6,25.6h76.8c14.1,0,25.6-11.5,25.6-25.6V25.6c0-14.1-11.5-25.6-25.6-25.6h-76.8
		C254.7,0,243.2,11.5,243.2,25.6z" />;
      default:
        return <path />;
    }
  }

  const getViewBox = () => {
    switch(props.name) {
      case 'donor-bios':
        switch(props.context){
          case 'folder-detail':
            return '100 0 576 450';
          default:
            return '100 100 576 450';
        }     
    case 'timeline-solid':
        return '0 0 512 512';
      default:
        return '';
    }
  }

  const getHeight = () => {
    switch(props.context){
      case 'folder-detail':
        return 32;
      default:
        return 32;
    }
  }

  const getWidth = () => {
    switch(props.context){
      case 'folder-detail':
        return 32;
      default:
        return 32;
    }
  }

  return(
<svg
  width={getWidth()}
  height={getHeight()}
  style={props.style}
  className={props.className}
  viewBox={getViewBox()}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  {getPath(props.name, props.fill )}
</svg>
  )

}

