import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DynamicForm from "../form/DynamicForm";
import {
  getFolderInfo,
  makeFolderPullable,
  generateClientConfig,
  updateFolderContents,
} from "../../actions/folderActions";
import { getCompanies, reindexElastic } from "../../actions/companyActions";
import {
  addUserCompany,
  changeCompany,
  changePlaceholderConfiguration,
  getAllUserOfAllCompanies,
  removeUserCompany,
} from "../../actions/userActions";
import { exportBackup } from "../../actions/backupActions";
import AlertNotification from "../layout/AlertNotification";
import { Button } from "antd";
import { toggleEmailNotificatonSubscription } from "../../actions/emailSubscriptionActions";
import {
  SYSTEM_SETTING_PLACEHOLDER_BACKGROUND_COLOR_KEY,
  SYSTEM_SETTING_PLACEHOLDER_MULTIPLE_IMAGE_KEY,
  SYSTEM_SETTING_PLACEHOLDER_SINGLE_IMAGE_KEY,
} from "../../shared-global/constants/placeholderKeys";

const ACTION_ADD = "add";
const ACTION_REMOVE = "remove";
const USER_COMPANY_ACTIONS = [ACTION_ADD, ACTION_REMOVE];

const makePublishableForm = {
  field_groups: {
    default: {
      title: "Publishable",
      default_expanded: true,
    },
  },
  fields: {
    folder_id: {
      type: "integer",
      title: "Folder Id",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
  },
};

const generateConfigForm = {
  field_groups: {
    default: {
      title: "Generate Config",
      default_expanded: true,
    },
  },
  fields: {
    folder_ids: {
      type: "text",
      title: "Folder Ids",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
  },
};

const changeCurrentCompany = {
  field_groups: {
    default: {
      title: "Companies",
      default_expanded: true,
    },
  },
  fields: {
    company_id: {
      type: "select",
      title: "Current company",
      validation: {},
    },
  },
};

const backupForm = {
  field_groups: {
    default: {
      title: "Publishable",
      default_expanded: true,
    },
  },
  fields: {
    backup_id: {
      type: "integer",
      title: "Backup Id",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
  },
};

const changeCurrentUserCompany = {
  field_groups: {
    default: {
      title: "User Company",
      default_expanded: true,
    },
  },
  fields: {
    company_id: {
      type: "select",
      title: "Company",
      options: [],
      validation: {},
    },
    user_id: {
      type: "searchable_select",
      title: "User",
      options: [],
      validation: {},
    },
    action: {
      type: "switch",
      title: "Add or Remove User",
      default: true,
      options: [
        { title: "Add", value: "Add" },
        { title: "Remove", value: "Remove" },
      ],
      validation: {},
    },
  },
};

const buildUserCompanyActions = () => {
  return USER_COMPANY_ACTIONS.map((a) => ({
    title: a,
    value: a,
  }));
};

changeCurrentUserCompany.fields.action.options = buildUserCompanyActions();

const updateFolderContentsForm = {
  field_groups: {
    default: {
      title: "Generate Config",
      default_expanded: true,
    },
  },
  fields: {
    folder_id: {
      type: "text",
      title: "Folder Id",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
    contents: {
      type: "textarea",
      title: "Contents JSON",
      validation: { rules: [{ name: "isRequired" }] },
      default: "",
    },
  },
};

const changePlaceholderForm = {
  field_groups: {
    default: {
      title: "Placeholder",
      default_expanded: true,
    },
  },
  fields: {
    placeholder_single_image: {
      type: "text",
      title: "Url S3 for single image",
      options: [],
      validation: {},
    },
    placeholder_multiple_image: {
      type: "text",
      title: "Url S3 for multiple image",
      options: [],
      validation: {},
    },
    placeholder_background_color: {
      type: "color",
      title: "Placeholder Color Background",
      default: "",
    },
  },
};

const emailNotificationsSubscriptionForm = {
  field_groups: {
    default: {
      title: "User Company",
      default_expanded: true,
    },
  },
  fields: {
    user_id: {
      type: "searchable_select",
      title: "User",
      options: [],
      validation: {},
    },
    type: {
      type: "select",
      title: "Type",
      options: [],
      validation: {},
    },
    action: {
      type: "switch",
      title: "Add or Remove User",
      defaultValue: true,
      options: [
        { title: "Add", value: "Add" },
        { title: "Remove", value: "Remove" },
      ],
      validation: {},
    },
  },
};

interface IConfigurationProps {
  addUserCompany: Function;
  changeCompany: Function;
  companies: any;
  exportBackup: Function;
  generateClientConfig: Function;
  getFolderInfo: Function;
  makeFolderPullable: Function;
  removeUserCompany: Function;
  updateFolderContents: Function;
  reindexElastic: Function;
  user: any;
  users: any;
  email_subscription_types: any;
  toggleEmailNotificationSubscription: Function;
  changePlaceholderConfiguration: Function;
  system_settings: any;
}

interface IConfigurationState {
  client_config: any;
  companies: any;
  folder_info: any;
  loadingAllUsers: boolean;
  loadingCompanies: boolean;
  show_folder_info: boolean;
  submitting: boolean;
  users: any;
  placeholderSingleImage: string;
  placeholderMultipleImage: string;
  placeholderBackgroundColor: string;
  placeholderFormKey: string;
}

class Configuration extends React.Component<
  IConfigurationProps,
  IConfigurationState
> {
  private _dynamic_form_ref = null;
  private _placeholder_dynamic_form_ref = null;

  constructor(props) {
    super(props);
    this.state = {
      client_config: [],
      companies: [],
      folder_info: {},
      loadingAllUsers: true,
      loadingCompanies: true,
      show_folder_info: false,
      submitting: false,
      users: [],
      placeholderSingleImage: "",
      placeholderMultipleImage: "",
      placeholderBackgroundColor: "",
      placeholderFormKey: "placeholder-key",
    };
    this._placeholder_dynamic_form_ref = null;
    // Get a list of the companies
    props.getCompanies(this.onCompaniesSuccess, this.onCompaniesError);
    // get a list of all users of all companies
    props.getAllUsers(this.onAllUsersSuccess, this.onAllUsersError);
  }

  updatePlaceholderConfiguration = () => {
    const { system_settings } = this.props;
    const placeholderSingleImage = _.get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_SINGLE_IMAGE_KEY}].value`,
      ""
    );
    const placeholderMultipleImage = _.get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_MULTIPLE_IMAGE_KEY}].value`,
      ""
    );
    const placeholderBackgroundColor = _.get(
      system_settings,
      `[${SYSTEM_SETTING_PLACEHOLDER_BACKGROUND_COLOR_KEY}].value`,
      ""
    );

    this.setState({
      placeholderSingleImage,
      placeholderMultipleImage,
      placeholderBackgroundColor,
    });
  };

  componentDidMount() {
    if (this.props.system_settings) {
      this.updatePlaceholderConfiguration();
    }
  }

  updateFieldDynamicForm = (field, value) => {
    if (this._placeholder_dynamic_form_ref) {
      this._placeholder_dynamic_form_ref.overrideFieldValue(field, value);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IConfigurationProps>,
    prevState: Readonly<IConfigurationState>,
    snapshot?: any
  ) {
    if (prevProps.system_settings !== this.props.system_settings) {
      this.updatePlaceholderConfiguration();
      this.setState({
        placeholderFormKey: "placeholder-key" + new Date().getTime(),
      });
    }
  }

  onCompaniesSuccess = () => {
    this.setState({
      loadingCompanies: false,
      companies: this.props.companies,
    });
  };

  onToggleEmailSubscriptionSuccess = (type, action) => {
    const typeObj = Object.values<{ title: string; value: string }>(
      this.props.email_subscription_types
    ).find((n) => n.value === type);
    if (action) {
      AlertNotification(
        "success",
        "Success",
        `The user has subscribed to ${typeObj.title} email notifications successfully`
      );
    } else {
      AlertNotification(
        "success",
        "Success",
        `The user has desubscribed to ${typeObj.title} email notifications successfully`
      );
    }
  };

  onCompaniesError = () => {
    AlertNotification("error", "Error", "Error obtaining users");
  };

  onReindexElastic = () => {
    AlertNotification("success", "Success", "Reindex in progress.");
  };

  onAllUsersSuccess = () => {
    this.setState({
      loadingAllUsers: false,
      users: this.props.users,
    });
  };

  onAllUsersError = () => {
    AlertNotification("error", "Error", "Error obtaining companies");
  };

  onSubmitPublishFolder = (values) => {
    this.props.makeFolderPullable(
      values.folder_id,
      true,
      this.notifyPublished,
      this.onMakePullableFail
    );
  };

  onMakePullableFail = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  notifyPublished = () => {
    AlertNotification("success", "Success", "Folder is now publishable.");
  };

  onSubmitGenerateConfig = (values) => {
    this.props.generateClientConfig(
      values.folder_ids,
      this.handleClientConfig,
      this.onGenerateConfigFail
    );
  };

  onSubmitCrossExportBackup = (values) => {
    this.props.exportBackup(values.backup_id, null, this.onErrorExportBackup);
  };

  onErrorExportBackup = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  onSubmitUpdateFolderContents = (values) => {
    if (isNaN(values.folder_id) || !values.contents) {
      AlertNotification("error", "Error", "Invalid form input");
      return;
    }
    this.props.updateFolderContents(
      values,
      () => AlertNotification("success", "Success", "Folder contents updated"),
      () => AlertNotification("error", "Error", "Unexpected error happened")
    );
  };

  handleClientConfig = (data) => {
    this.setState({
      client_config: data.config,
    });
  };

  onGetFolderInfo = (values) => {
    this.props.getFolderInfo(
      values.folder_id,
      this.handleGetFolderInfo,
      this.errorFolderInfo
    );
  };

  errorFolderInfo = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  handleGetFolderInfo = (info) => {
    this.setState({
      folder_info: _.get(info, "data.folder", {}),
      show_folder_info: true,
    });
  };

  drawConfig = () => {
    return this.state.client_config.map((line) => {
      return (
        <div>
          <pre>{line}</pre>
        </div>
      );
    });
  };

  onError = () => {};

  onSuccessChangeCompany = () => {
    AlertNotification("success", "Success", "Company changed successfully");
  };

  onFailChangeCompany = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  onSubmitChooseCompany = (values) => {
    if (values.company_id !== this.props.user.company) {
      this.props.changeCompany(
        this.props.user.id,
        values.company_id,
        this.onSuccessChangeCompany,
        this.onFailChangeCompany
      );
    }
  };

  onGenerateConfigFail = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  onSuccessAddOrRemoveUserCompany = (context) => () => {
    AlertNotification("success", "Success", `Company ${context} successfully`);
  };

  onFailAddOrRemoveUserCompany = (message) => {
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  onSubmitAddOrRemoveUserCompany = (values) => {
    if (!this.hasValidDataUserCompany(values)) {
      AlertNotification("error", "Error", "Invalid fields");
      return;
    }
    const company_id = values.company_id;
    const user_id = values.user_id;
    if (this.isAddUserToCompany(values)) {
      this.props.addUserCompany(
        user_id,
        company_id,
        this.onSuccessAddOrRemoveUserCompany("added"),
        this.onFailAddOrRemoveUserCompany
      );
    } else {
      this.props.removeUserCompany(
        user_id,
        company_id,
        this.onSuccessAddOrRemoveUserCompany("removed"),
        this.onFailAddOrRemoveUserCompany
      );
    }
  };

  onSubmitAddOrRemoveUserEmailSubscription = (values) => {
    this.props.toggleEmailNotificationSubscription(
      values.user_id,
      values.type,
      values.action,
      this.onToggleEmailSubscriptionSuccess
    );
  };

  onSuccessChangePlaceholderConfiguration = () => {
    AlertNotification(
      "success",
      "Success",
      "Changed placeholder configuration"
    );
  };

  onSubmitChangePlaceholderConfiguration = (values) => {
    this.props.changePlaceholderConfiguration(
      values,
      this.onSuccessChangePlaceholderConfiguration
    );
  };

  hasValidDataUserCompany = (value) => {
    return value && !!value.company_id && !!value.user_id;
  };

  // check if user alredy below to a company
  isAddUserToCompany = (values) => {
    const { action } = values;
    return !!action;
  };

  render() {
    if (!this.state.loadingCompanies) {
      const options = Object.values(this.state.companies).map((x: any) => {
        return {
          title: x.name,
          value: x.id,
        };
      });
      changeCurrentCompany.fields.company_id["options"] = options;
      changeCurrentUserCompany.fields.company_id["options"] = options;
    }
    emailNotificationsSubscriptionForm.fields.type["options"] =
      this.props.email_subscription_types ?? [];
    if (!this.state.loadingAllUsers) {
      const options = Object.values(this.state.users).map((x: any) => {
        return {
          title: `${x.first_name} ${x.last_name} (${x.email})`,
          value: x.id,
        };
      });
      changeCurrentUserCompany.fields.user_id["options"] = options;
      emailNotificationsSubscriptionForm.fields.user_id["options"] = options;
    }

    return (
      <div>
        <h2>System Admin Configuration Options</h2>
        <br />
        <hr />
        <h3>Get Folder Info:</h3>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={makePublishableForm.field_groups}
          fieldsConfig={makePublishableForm.fields}
          fieldValues={{
            folder_id: 0,
          }}
          mode={"add"}
          onSubmit={(values) => this.onGetFolderInfo(values)}
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
        <br />
        {this.state.show_folder_info ? (
          <div>
            Folder Info: <br />
            {JSON.stringify(this.state.folder_info, null, 2)}
            <br />
            <br />
          </div>
        ) : null}

        <hr />
        <br />
        <h3>Make folder publishable:</h3>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={makePublishableForm.field_groups}
          fieldsConfig={makePublishableForm.fields}
          fieldValues={{
            folder_id: 0,
          }}
          mode={"add"}
          onSubmit={(values) => this.onSubmitPublishFolder(values)}
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
        <hr />
        <br />
        <h3>Generate Config:</h3>
        <p>
          This will generate the data needed for the config.js file to be
          included in the digital-cms-client-v2 root folder
        </p>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={generateConfigForm.field_groups}
          fieldsConfig={generateConfigForm.fields}
          fieldValues={{
            folder_id: 0,
          }}
          mode={"add"}
          onSubmit={(values) => this.onSubmitGenerateConfig(values)}
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
        <hr />
        <br />
        {this.state.client_config.length > 0 ? (
          <div
            style={{ padding: 16, backgroundColor: "black", color: "#4287f5" }}
          >
            {this.drawConfig()}
          </div>
        ) : null}
        <h3>Switch company:</h3>
        <p>
          Switches the current company that you ( system administrator ) are
          assigned to.
        </p>
        {this.state.loadingCompanies ? (
          <p>Loading</p>
        ) : (
          <DynamicForm
            submitting={this.state.submitting}
            fieldGroupsConfig={changeCurrentCompany.field_groups}
            fieldsConfig={changeCurrentCompany.fields}
            fieldValues={{
              company_id: this.props.user.company,
            }}
            mode={"edit"}
            onSubmit={(values) => this.onSubmitChooseCompany(values)}
            onError={this.onError}
            enableFieldGrouping={false}
            canUpdate={true}
            submit_button_title="Submit"
          />
        )}
        <hr />
        <br />
        <h3>Cross-enviroment backup export:</h3>
        <p>
          Enter the backup id to download a json file to later be used for
          restoring the backup in a different environment (local, staging,
          production). You can find the backup id by first searching for the
          folder id in get folder info, or looking in the content tree and
          clicking the backup modal on a published folder.
        </p>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={backupForm.field_groups}
          fieldsConfig={backupForm.fields}
          fieldValues={{
            backup_id: 0,
          }}
          mode={"add"}
          onSubmit={(values) => this.onSubmitCrossExportBackup(values)}
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
        {/*user-companies*/}
        <hr />
        <br />
        <h3>User Company:</h3>
        <p>Assign an existing user to a company.</p>
        {this.state.loadingCompanies ? (
          <p>Loading</p>
        ) : (
          <DynamicForm
            submitting={this.state.submitting}
            fieldGroupsConfig={changeCurrentUserCompany.field_groups}
            fieldsConfig={changeCurrentUserCompany.fields}
            fieldValues={{}}
            mode={"add"}
            onSubmit={(values) => this.onSubmitAddOrRemoveUserCompany(values)}
            onError={this.onError}
            enableFieldGrouping={false}
            canUpdate={true}
            submit_button_title="Submit"
          />
        )}
        <br />
        <hr />
        <br />
        <p>
          Update folder contents json. This is a flexible field that will store
          any key(s) / value(s) specified. Use config_js to store config_js
          configuration.
        </p>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={updateFolderContentsForm.field_groups}
          fieldsConfig={updateFolderContentsForm.fields}
          fieldValues={{
            folder_id: "",
            contents: '{"some_field": "value"}',
          }}
          onSubmit={(values) => this.onSubmitUpdateFolderContents(values)}
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
          mode={"edit"}
        />
        <br />
        <br />
        <hr />
        <br />
        <h3>Elastic Search:</h3>
        <p>
          Reindex Elastic Search. This is non-destructive and safe to run,
          postgres is the source of truth.
        </p>

        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            size="large"
            className="form-submit-button"
            style={{ width: "auto", marginRight: 16 }}
            onClick={() => {
              this.props.reindexElastic();
              this.onReindexElastic();
            }}
          >
            Reindex
          </Button>
        </div>
        <br />
        <br />
        <hr />
        <br />
        <h3>Email Notifications:</h3>
        <p>Add or remove users from a email notification subscription.</p>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={emailNotificationsSubscriptionForm.field_groups}
          fieldsConfig={emailNotificationsSubscriptionForm.fields}
          fieldValues={{
            company_id: this.props.user.company,
          }}
          mode={"edit"}
          onSubmit={(values) =>
            this.onSubmitAddOrRemoveUserEmailSubscription(values)
          }
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
        <br />
        <br />
        <hr />
        <br />
        <h3>Placeholder Configuration:</h3>
        <p>
          Change placeholder for single and multiple slot, and also background
          color for images.
        </p>
        <DynamicForm
          classOverride="configuration_placeholder-form"
          key={this.state.placeholderFormKey}
          ref={(el) => (this._placeholder_dynamic_form_ref = el)}
          submitting={this.state.submitting}
          fieldGroupsConfig={changePlaceholderForm.field_groups}
          fieldsConfig={changePlaceholderForm.fields}
          fieldValues={{
            placeholder_single_image: this.state.placeholderSingleImage,
            placeholder_multiple_image: this.state.placeholderMultipleImage,
            placeholder_background_color: this.state.placeholderBackgroundColor,
          }}
          mode={"edit"}
          onSubmit={(values) =>
            this.onSubmitChangePlaceholderConfiguration(values)
          }
          onError={this.onError}
          enableFieldGrouping={false}
          canUpdate={true}
          submit_button_title="Submit"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const system_settings = Object.keys(state.data.system_settings).reduce(
    (acc, curr) => {
      const obj = state.data.system_settings[curr];
      acc[obj.key] = obj;

      return acc;
    },
    {}
  );

  return {
    companies: state.data.companies,
    user: state.data.user,
    users: state.data.users,
    email_subscription_types: state.data.email_subscription_types,
    system_settings: system_settings,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFolderInfo: (folder_id, onSuccess, onFail) => {
      dispatch(getFolderInfo(folder_id, onSuccess, onFail));
    },
    makeFolderPullable: (folder_id, onSuccess, onFail) => {
      dispatch(makeFolderPullable(folder_id, onSuccess, onFail));
    },
    generateClientConfig: (folder_ids, onSuccess, onFail) => {
      dispatch(generateClientConfig(folder_ids, onSuccess, onFail));
    },
    getCompanies: (onSuccess, onFail) => {
      dispatch(getCompanies(onSuccess, onFail));
    },
    changeCompany: (user_id, company_id, onSuccess, onFail) => {
      dispatch(changeCompany(user_id, company_id, onSuccess, onFail));
    },
    exportBackup: (backup_id, onSuccess, onFail) => {
      dispatch(exportBackup(backup_id, onSuccess, onFail));
    },
    updateFolderContents: (data, onSuccess, onFail) => {
      dispatch(updateFolderContents(data, onSuccess, onFail));
    },
    getAllUsers: (onSuccess, onFail) => {
      dispatch(getAllUserOfAllCompanies(onSuccess, onFail));
    },
    addUserCompany: (user_id, company_id, onSuccess, onFail) => {
      dispatch(addUserCompany(user_id, company_id, onSuccess, onFail));
    },
    removeUserCompany: (user_id, company_id, onSuccess, onFail) => {
      dispatch(removeUserCompany(user_id, company_id, onSuccess, onFail));
    },
    reindexElastic: () => {
      dispatch(reindexElastic());
    },
    toggleEmailNotificationSubscription: (
      user_id,
      type,
      action,
      onSuccess = null,
      onFail = null
    ) => {
      dispatch(
        toggleEmailNotificatonSubscription(
          user_id,
          type,
          action,
          onSuccess,
          onFail
        )
      );
    },
    changePlaceholderConfiguration: (data, onSuccess, onFail) => {
      dispatch(changePlaceholderConfiguration(data, onSuccess, onFail));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
