import { get } from "lodash";
import React from "react";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import {
  FONT_FAMILY,
  PDG_COLORS,
} from "../../../../shared-global/enums/ui-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../../shared/interfaces/display-component-props.interface";
import { getChildrenFolderAndFolderFoldersDeep } from "../../../utils/collectionUtils";
import { getChildrenData } from "../../../utils/commonDataUtils";
import { columnStyle, rowStyle } from "./los-angles-styles";
import { LosAngelesDonorLists } from "./LosAngelesDonorLists";
import LosAngelesSlides from "./LosAngelesSlides";

export const LosAngelesThreePanel: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const baseFolder = props.data.folders.find(
    (f) => f.id === props.data.base_folder
  );

  const donorListHeader = get(baseFolder, "fields.donor_list_header", "");
  const headerHeight = 0.1 * props.containerHeight;
  const donorListHeight = 0.8 * props.containerHeight;
  const donorRowsPerPage = 20;
  const defaultFontSize = donorListHeight / donorRowsPerPage;

  const mode = get(props, "mode", COMPONENT_MODE_CONTEXT.LOCAL);
  const aws = get(props, "aws", null);
  const aws_bucket = get(props, "aws_bucket", null);

  const components = getChildrenData(props.data, props.data.base_folder);
  const { folders } = components;
  let key = "";
  let donorListKey = "";
  let slideshowData = null;
  let donorListsData = null;

  if (!folders.length) {
    return null;
  } else {
    const losAngelesSlideshow = folders.find(
      (component: IFolder) =>
        component.folder_type === FOLDER_TYPE.los_angeles_slides
    );
    slideshowData = getChildrenFolderAndFolderFoldersDeep(
      {},
      props.data.folder__folders,
      props.data.folders,
      props.data.resources,
      props.data.resource__folders,
      props.data.folder_types,
      props.data.persistent_settings,
      props.data.system_settings,
      losAngelesSlideshow
    );
    key = slideshowData.id;

    const losAngelesDonorLists = folders.find(
      (component: IFolder) =>
        component.folder_type === FOLDER_TYPE.los_angeles_donor_lists
    );
    donorListsData = getChildrenFolderAndFolderFoldersDeep(
      {},
      props.data.folder__folders,
      props.data.folders,
      props.data.resources,
      props.data.resource__folders,
      props.data.folder_types,
      props.data.persistent_settings,
      props.data.system_settings,
      losAngelesDonorLists
    );
    donorListKey = donorListsData.id;
  }

  return (
    <div
      style={{
        ...rowStyle,
        width: props.containerWidth,
        height: props.containerHeight,
        fontFamily: FONT_FAMILY.ROBOTO,
      }}
    >
      <div
        style={{
          ...columnStyle,
          height: props.containerHeight,
          flex: 2,
          position: "relative",
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        {slideshowData.folders ? (
          <LosAngelesSlides
            key={key}
            aws={aws}
            aws_bucket={aws_bucket}
            mode={mode}
            containerWidth={(props.containerWidth / 3) * 2}
            containerHeight={props.containerHeight}
            handleEndOfPlay={null}
            data={slideshowData}
            webview_signedurls={props.webview_signedurls}
          />
        ) : null}
      </div>
      <div
        style={{
          ...columnStyle,
          height: props.containerHeight,
          zIndex: 2,
          backgroundColor: PDG_COLORS.WHITE,
        }}
      >
        <div
          style={{
            ...rowStyle,
            width: "100%",
            height: headerHeight,
            fontSize: defaultFontSize * 1.5,
            justifyContent: "center",
          }}
        >
          {donorListHeader}
        </div>
        <div
          style={{
            ...rowStyle,
            width: "100%",
            height: props.containerHeight * 0.05,
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            ...rowStyle,
            width: "100%",
            height: donorListHeight,
          }}
        >
          {donorListsData.folders && (
            <LosAngelesDonorLists
              key={donorListKey}
              aws={aws}
              aws_bucket={aws_bucket}
              mode={mode}
              containerWidth={props.containerWidth / 3}
              containerHeight={donorListHeight}
              handleEndOfPlay={null}
              data={donorListsData}
              webview_signedurls={props.webview_signedurls}
            />
          )}
        </div>
        <div
          style={{
            ...rowStyle,
            width: "100%",
            height: props.containerHeight * 0.05,
          }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
};
