import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { wysiwyg } from "../standardized_fields";
import { coburgFonts, quillFontSizes } from "./coburg_settings";
import { coburg_template_shared } from "./coburg_template_shared";

const modifiedFieldGroups = {
  ...coburg_template_shared.field_groups,
  image_1: {
    title: "Image 1",
    default_expanded: false,
    order: 5,
    accordion: true,
    tab: "main"
  }
};

export const coburg_template_004: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.coburg_template_004,
  display_name: "Template 4 - Landscape - Story Left - Quote Right",
  tabs: {
    ...coburg_template_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...coburg_template_shared.fields,
    quote: {
      title: "Quote",
      field_group: "quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    attribution: {
      title: "Attribution",
      field_group: "attribution",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "cycle_time"
    }
  },
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.CoburgTemplate004,
    type: PREVIEW_TYPE.COMPONENT
  },
  tab_previews: [
    {
      tab_key: "main",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.CoburgTemplate004,
      type: PREVIEW_TYPE.COMPONENT
    },
    {
      tab_key: "card_view",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.CoburgTemplateCardView,
      type: PREVIEW_TYPE.COMPONENT
    }
  ],
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_004.svg"
  },
  save_options: [...coburg_template_shared.save_options]
};
