import React, {useCallback} from "react";
import { createUseStyles } from "react-jss";
import CycleWrapper from "../../standard/CycleWrapper";
import RichTextRender from "../../standard/RichTextRender";
import MediaCarousel from "../../standard/MediaCarousel";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";
import {useFolder} from "../../../hooks/useFolder";
import {useScale} from "../../../hooks/useScale";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const AlpineStoryWithMedia = (props) => {
  const {
    getBaseFolder,
    getBaseFolderFolderType,
    getBaseFolderFieldValue,
  } = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const {
    getBaseFolderRichTextStylesFn,
    standardSizing,
    getUnitBasedOnHeight,
  } = useScale(props.data.base_folder);
  const richTextClasses = getBaseFolderRichTextStylesFn()();

  const tagline = getBaseFolderFieldValue('tagline', baseFolderType);
  const header = getBaseFolderFieldValue('header', baseFolderType);
  const body = getBaseFolderFieldValue('body', baseFolderType);
  const backgroundColor = getBaseFolderFieldValue("background_color", baseFolderType);

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    standardSizing,
    getUnitBasedOnHeight,
  });

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["story_image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  return (
    <div className={classes.componentContainer}>
      <div className={classes.storyWithMedia}>
        {
          !resourceFoldersCarousel.length && (
            <CycleWrapper {...props}/>
          )
        }
        <div className="storyWithMediaContent">
          <div className="headerWrapper">
            <div className="sectionPadding">
              <div className={`sectionHeader ${richTextClasses.componentContainer}`} >
                <RichTextRender className="tagline" htmlString={tagline}/>
                <RichTextRender className="header" htmlString={header}/>
              </div>
            </div>
          </div>
          <div style={{
            width: '100%',
            maxHeight: "40em"
          }}>
            <MediaCarousel
              style={{
                marginBottom: '4em',
              }}
              {...props}
              componentSpecificData={{
                customStyleImage: {
                  maxHeight: '40em',
                  width: props.refWidth - (7.5 * 2 * props.fontSize),
                  backgroundPosition: "left bottom"
                },
                customStyleVideo: {
                  hideOffset: true,
                  maxHeight: '40em',
                  position: 'relative',
                  width: props.refWidth - (7.5 * 2 * props.fontSize)
                },
                slickConfiguration: {
                  fade: true,
                  speed: 2000
                }
              }}
              width="100%"
              height="40em"
              imageWidth={props.refWidth - (7.5 * 2 * props.fontSize)}
              slotNames={["story_image"]}
              resourceFoldersCarousel={resourceFoldersCarousel}
              dotsStyles={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: props.refWidth - (7.5 * 2 * props.fontSize),
                marginTop: '2em'
              }}
            />
          </div>
          <div className={`sectionBody ${richTextClasses.componentContainer}`}>
            <RichTextRender className="body" htmlString={body}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlpineStoryWithMedia
