import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { mckenzie_content } from "./mckenzie_content";
import { mckenzie_content_mobile } from "./mckenzie_content_mobile";
import { mckenzie_display } from "./mckenzie_display";
import { mckenzie_displays } from "./mckenzie_displays";
import { mckenzie_mobile } from "./mckenzie_mobile";
import { mckenzie_root } from "./mckenzie_root";

export const mckenzie_types: { [key: string]: IFolderType } = {
  mckenzie_display,
  mckenzie_displays,
  mckenzie_mobile,
  mckenzie_content,
  mckenzie_content_mobile,
  mckenzie_root
};
