/**
 * this module handle call notifications
 */
const STATUS_FOLDER_KEY = 'StatusFolder';
const NOTIFICATION_KEY = 'Notifications';

const notifications = {};

notifications[STATUS_FOLDER_KEY] = 0;
notifications[NOTIFICATION_KEY] = 0;

// for notifications //

const resetCounterNotificationsAndCallback = (callback) => {
  notifications[NOTIFICATION_KEY] = notifications[NOTIFICATION_KEY] - 1;
  if (callback) {
    callback();
  }
};

export const callNotifications = (companyId, type, apiCall, onSuccess, onFail) => {
  if (notifications[NOTIFICATION_KEY] > 0) {
    return;
  }
  notifications[NOTIFICATION_KEY] = notifications[NOTIFICATION_KEY] + 1;
  // eslint-disable-next-line max-len
  apiCall(companyId, type, () => {resetCounterNotificationsAndCallback(onSuccess)}, () => {resetCounterNotificationsAndCallback(onFail)});
};

// for status folder //

const resetCounterStatusFolderAndCallback = (callback) => {
  notifications[STATUS_FOLDER_KEY] = notifications[STATUS_FOLDER_KEY] - 1;
  if (callback) {
    callback();
  }
};

export const callStatusFolder = (companyId, apiCall, onSuccess, onFail) => {
  if (notifications[STATUS_FOLDER_KEY] > 0) {
    return;
  }
  notifications[STATUS_FOLDER_KEY] = notifications[STATUS_FOLDER_KEY] + 1;
  // eslint-disable-next-line max-len
  apiCall(companyId, () => {resetCounterStatusFolderAndCallback(onSuccess)}, () => {resetCounterStatusFolderAndCallback(onFail)});
};

export const checkIfFolderStatusInProgress = (folderStatusArr, stopFolderStatusPollCb) => {
  const itemsInProgress = folderStatusArr
    .filter(f => f.folder_status === 'processing');
  if (itemsInProgress.length === 0) {
    // Stop Polling here.
    stopFolderStatusPollCb();
  }
}