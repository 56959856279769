import React, {Component} from 'react';
import {Row, Col} from 'antd';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';
// import {
//   MEDIA_PROGRESS_STATUS_DONE,
//   MEDIA_PROGRESS_STATUS_ERROR,
//   MEDIA_PROGRESS_STATUS_PROCESSING
// } from './MediaProgressStatus';
// import {
//   NOTIFICATION_DISPLAY_ALERT_TYPE, NOTIFICATION_FOLDER_STATUS_TYPE,
// } from '../../../../shared/constants';

class MediaLinkRow extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._componentIsMounted = true;
  }

  componentWillUnmount() {
    this._componentIsMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
    }
  }

  render() {
    const {name} = this.props;

    return (
      <Row className="media-progress-status_body-row">
        <Col span={24} className="media-progress-status_body-col">
          {name}
        </Col>
      </Row>
    );
  }
}

export default MediaLinkRow;
