import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name, wysiwyg } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { bostonFonts } from "./boston_settings";

export const boston_media_with_quote: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.boston_media_with_quote,
  display_name: "Boston Media with Quote",
  fields: {
    name: name,
    default_preview: {
      type: "select",
      title: "Default Preview",
      validation: { rules: [{ name: "isRequired" }] },
      default: "1",
      options: [
        {
          title: "Mission Bernal",
          value: "1"
        },
        {
          title: "Pacific",
          value: "2"
        },
        {
          title: "Van Ness",
          value: "3"
        }
      ]
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    body_text_top: {
      ...wysiwyg,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "27em",
          color: "#06A9A0"
        },
        ops: []
      },
      settings: {
        ...wysiwyg.settings,
        fonts: bostonFonts,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 20,
          max: 40
        }
      },
      title: "Body Text Top"
    },
    body_text_bottom: {
      ...wysiwyg,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "27em",
          color: "#06A9A0"
        },
        ops: []
      },
      settings: {
        ...wysiwyg.settings,
        fonts: bostonFonts,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 20,
          max: 40
        }
      },
      title: "Body Text Bottom"
    },
    attribution_text: {
      ...wysiwyg,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "31em",
          color: "#06A9A0"
        },
        ops: []
      },
      settings: {
        ...wysiwyg.settings,
        fonts: bostonFonts,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 20,
          max: 40
        }
      },
      title: "Attribution"
    },
    media: {
      title: "Media 2x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    },
    media_vertical_1x4: {
      title: "Background Mission Bernal 1x4",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.444444444,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      }
    },
    media_vertical_2x5: {
      title: "Background Van Ness / Pacific 2x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.711111111,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      }
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true
    }
  },
  preview: [
    {
      aspect_ratio: 1.333,
      component_name: COMPONENT_NAME.Boston3x2and1x4Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "3x2 and 1x4",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/3x2_1x4.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_3x2_and_1x4
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    },
    {
      aspect_ratio: 2.666666667,
      component_name: COMPONENT_NAME.Boston3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_3x2
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  }
};
