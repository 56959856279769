import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType
} from "../../../utils/commonDataUtils";
import {
  generateFontSizeClassesFromFields,
  getFieldValue
} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {
  SPR_LANDSCAPE_BODY_LINE_HEIGHT,
  SPR_LANDSCAPE_BODY_MARGIN_TOP,
  SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
  SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM,
  SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
  SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
  SPR_LANDSCAPE_VERTICAL_MARGIN
} from "./SpringfieldConstants";

const SpringfieldTemplate002 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const imageFieldType = useMemo(() => {
    return get(baseFolderType, "fields[background]", null);
  }, [baseFolderType]);

  const color = useMemo(() => {
    return get(baseFolder, "fields.color_overlay", "transparent");
  }, [baseFolder]);

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings?.data, "show_placeholder", false);
  }, [props.data]);

  const getStyles = useCallback(() => {
    if (baseFolder) {
      const folderType = props.data?.folder_types?.find((f) => f.name === baseFolder.folder_type);
      const styles = createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder.fields));
      return styles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, [baseFolder?.folder_type, props.data?.folder_types]);

  const useStyles = getStyles();
  const classes = useStyles();


  const headline = getFieldValue(baseFolder, 'headline', baseFolderType);
  const subhead = getFieldValue(baseFolder, 'subhead', baseFolderType);
  const bodyText = getFieldValue(baseFolder, 'body', baseFolderType);
  const mirrorContent = get(baseFolder, "fields.mirror_content", false);

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            componentSpecificData: {
              ...props.data?.componentSpecificData,
              useKeyCycler: false,
            },
          }}
          handleEndOfPlay={null}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: color,
          zIndex: 0,
        }}
      />
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: !mirrorContent ? 'row' : 'row-reverse',
          alignItems: 'stretch',
        }}
      >
        <div
          style={{
            position: "relative",
            flex: 1,
            height: props.containerHeight,
          }}
        >
          <Slide1911
            aws={props.aws}
            aws_bucket={props.aws_bucket}
            mode={props.mode}
            containerWidth={props.containerWidth / 2}
            containerHeight={props.containerHeight}
            data={{
              ...props.data,
              slotNames: ["image_1"],
              placeholder: "image 1",
              fieldType: imageFieldType,
              showPlaceholder: showPlaceholder,
              componentSpecificData: {
                ...props.data?.componentSpecificData,
                useKeyCycler: false,
              },
            }}
            handleEndOfPlay={null}
            webview_signedurls={props.webview_signedurls}
          />
        </div>
        <div
          style={{
            flex: 1,
            height: props.containerHeight,
          }}
        >
          <div
            style={{
              height: "100%",
              // padding: "8% 12%",
              paddingTop: props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
              paddingBottom:
                props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
              paddingLeft: props.containerWidth * 0.06,
              paddingRight: props.containerWidth * 0.06,
            }}
          >
            {/* Text */}
            <div
              style={{
                height: "100%",
                color: "#000000",
                overflowY: "hidden",
              }}
            >
              <div style={{ width: "100%" }}>
                {/* Headline */}
                <div
                  className={classes.componentContainer}
                  style={{
                    padding: 0,
                    lineHeight: SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingBottom:
                      SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                      props.containerHeight,
                  }}
                >
                  <RichTextRender
                    htmlString={headline}
                    style={{
                      padding: 0
                    }}
                  />
                </div>
                {/* Sub Head */}
                {subhead && (
                  <div
                    className="springfield-template-002_subhead"
                    style={{
                      marginTop:
                        props.containerHeight *
                        SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
                    }}
                  >
                    <div
                      style={{
                        padding: 0,
                        lineHeight: SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
                        overflowX: "hidden",
                        overflowY: "hidden",
                        paddingBottom:
                          SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                          props.containerHeight,
                      }}
                      className={classes.componentContainer}
                    >
                      <RichTextRender
                        htmlString={subhead}
                        style={{
                          padding: 0
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* Body Text */}
                <div
                  className="springfield-template-002_body-text"
                  style={{
                    marginTop:
                      props.containerHeight * SPR_LANDSCAPE_BODY_MARGIN_TOP,
                  }}
                >
                  <div
                    className={classes.componentContainer}
                    style={{
                      padding: 0,
                      lineHeight: SPR_LANDSCAPE_BODY_LINE_HEIGHT,
                      overflowX: "hidden",
                      overflowY: "hidden",
                      paddingBottom:
                        SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                        props.containerHeight,
                    }}
                  >
                    <RichTextRender
                      htmlString={bodyText}
                      style={{
                        padding: 0
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpringfieldTemplate002;
