import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";

export const naples_fullscreen_media: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.naples_fullscreen_media,
  display_name: "Naples Full Screen Media",
  fields: {
    name: name,
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        {
          title: "Crossfade",
          value: "transition-fade1s"
        },
        {
          title: "Fade Out",
          value: "transition-fadeout"
        },
        {
          title: "Slide In from Right",
          value: "transition-slide-in-from-right"
        },
        {
          title: "Slide In from Left",
          value: "transition-slide-in-from-left"
        },
        {
          title: "Slide In from Top",
          value: "transition-slide-in-from-top"
        },
        {
          title: "Slide In from Bottom",
          value: "transition-slide-in-from-bottom"
        }
      ]
    },
    media: {
      title: "Media",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.592592593,
      recommended_size: {
        width: 1920,
        height: 3240
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.592592593
      }
    }
  },
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/naples/1x3.svg"
  }
};
