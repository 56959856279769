import get from "lodash/get";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COMPONENT_MODE_CONTEXT } from "../../../shared-global/enums/config-enums";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../utils/commonDataUtils";
import {
  getFileDynamicByRank,
  getFileTypeFromResources,
  videoType,
} from "../../utils/fileUtils";
import { bestFit } from "../../utils/screenUtils";
import VideoLoader from "../utils/VideoLoader";
import Slide1911Placeholder from "./Slide1911Placeholder";

const Slide1911: React.FC<IDisplayComponentProps> = (props) => {
  let cycleTimeout = null;
  let videoTimeout = null;
  let initPlayerTimeout = null;
  let positionVideoTimeout = null;

  const initialContainerWidth = props.containerWidth || window.innerWidth;
  const initialContainerHeight = props.containerHeight || window.innerHeight;
  
  // const [showImage, setShowImage] = useState(false);

  const [showImage, _setShowImage] = useState(false);
  const showImageRef = useRef(showImage);
  const setShowImage = data => {
    showImageRef.current = data;
    _setShowImage(data);
  }


  const preset = props.mode === "preview" ? "preview" : null;
  const [slotNames, setSlotNames] = useState(
    props.data.slotNames ?? ["file", "slide", "media"]
  );
  const useVideoPlayer = useMemo(() => {
    return get(props, "data.componentSpecificData.use_video_player", false);
  }, [props.data]);

  const loadImage = useCallback((url, retries = 5) => {
    var img = new Image();
    img.onload = () => {
      setShowImage(true);
    }
    img.onerror = () => {
      if (retries > 0) {
        setTimeout(() => {
          loadImage(url, retries -1);
        }, 1000);
      } else {
        console.log('Image not found');
      }
    }
    img.src = url;
  }, []);

  // Callback wrapped functions for performance.
  const handleKeyDownCallback = useCallback((e) => {
    switch (e.key) {
      case "ArrowLeft": //left
        break;
      case "ArrowUp": //up
        break;
      case "ArrowRight": //right
        if (props.handleEndOfPlay) {
          props.handleEndOfPlay();
        }
        break;
      case "ArrowDown": //down
        break;
      default:
        break;
    }
  }, []);

  const getBaseFolderCallback = useCallback(() => {
    return getBaseFolder(props.data);
  }, [props.data]);

  const getBestFitCallback = useCallback(() => {
    return bestFit(
      initialContainerWidth,
      initialContainerHeight,
      initialContainerWidth,
      initialContainerHeight
    );
  }, [initialContainerWidth, initialContainerHeight]);

  const getFileTypeFromResourcesCallback = () => {
    return getFileTypeFromResources(
      props.data.resources,
      props.data.resource__folders,
      slotNames,
      props.data.base_folder,
      props.data.index
    );
  };

  const getFileDynamicCallback = useCallback(() => {
    return getFileDynamicByRank(
      props.data.index || 0,
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      slotNames,
      props.data.base_folder,
      preset,
      props.webview_signedurls
    );
  }, [
    props.data.index,
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder,
    preset,
    props.webview_signedurls,
    slotNames,
  ]);

  const videoRef = useRef<HTMLVideoElement>();

  const [metadataLoaded, setMetadataLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const baseFolder = getBaseFolderCallback();
  const dims = getBestFitCallback();
  const fileType = getFileTypeFromResourcesCallback();
  const file = getFileDynamicCallback();

  const cycleTime = props.cycle_time
    ? props.cycle_time
    : get(baseFolder, "fields.cycle_time", 5) * 1000;

  const [width, setWidth] = useState(dims.width);
  const [height, setHeight] = useState(dims.height);
  const [containerWidth, setContainerWidth] = useState(initialContainerWidth);
  const [containerHeight, setContainerHeight] = useState(
    initialContainerHeight
  );
  const [videoOffsetX, setVideoOffsetX] = useState(0);
  const [videoOffsetY, setVideoOffsetY] = useState(0);
  const [videoControlsOffset, setVideoControlsOffset] = useState(0);
  const [showVideo, setShowVideo] = useState(false);

  const [backgroundSize, setBackgroundSize] = useState("contain");

  const setCycleTimeout = () => {
    clearTimeout(cycleTimeout);
    if (fileType === "image" || !fileType) {
      cycleTimeout = setTimeout(() => {
        if (props.handleEndOfPlay) {
          props.handleEndOfPlay();
        }
      }, cycleTime);
    } else if (fileType === "video") {
      if (cycleTime !== 0) {
        cycleTimeout = setTimeout(() => {
          if (props.handleEndOfPlay) {
            props.handleEndOfPlay();
          }
        }, cycleTime);
      }
    }
  };

  const initPlayer = () => {
    let player = videoRef.current;
    if (player) {
      player.addEventListener("loadedmetadata", () => setMetadataLoaded(true));
      player.addEventListener("loadeddata", () => setDataLoaded(true));
      // player.addEventListener(
      //   "ended",
      //   props.handleEndOfPlay ? props.handleEndOfPlay() : () => {}
      // );
      if (player.readyState >= 2) {
        setMetadataLoaded(true);
        setDataLoaded(true);
      }
    } else {
      clearTimeout(initPlayerTimeout);
      initPlayerTimeout = setTimeout(() => {
        initPlayer();
      }, 100);
    }
  };

  const positionVideo = () => {
    if (!videoRef.current) {
      clearTimeout(positionVideoTimeout);
      positionVideoTimeout = setTimeout(() => {
        positionVideo();
      }, 100);
      return;
    }

    const scaleFactor = get(
      props,
      "data.componentSpecificData.scale_factor",
      1
    );
    const videoHeight =
      videoRef.current.getBoundingClientRect().height / scaleFactor;
    const videoWidth =
      videoRef.current.getBoundingClientRect().width / scaleFactor;

    const videoOffsetY = (height - videoHeight) / 2;
    const videoOffsetX = (width - videoWidth) / 2;

    const videoControlsOffset = videoOffsetY + videoHeight / 2;

    setVideoOffsetX(videoOffsetX);
    setVideoOffsetY(videoOffsetY);
    setVideoControlsOffset(videoControlsOffset);
  };

  // Clearing timeouts and listeners.
  useEffect(() => {
    const useKeyCycler = get(props, "data.componentSpecificData.useKeyCycler", true);
    if (useKeyCycler) {
      document.addEventListener("keydown", handleKeyDownCallback);
    }
    return () => {
      clearTimeout(cycleTimeout);
      clearTimeout(initPlayerTimeout);
      clearTimeout(positionVideoTimeout);
      if (useKeyCycler) {
        document.removeEventListener("keydown", handleKeyDownCallback);
      }
    };
  }, []);

  // Initiate player if it's a video.
  useEffect(() => {
    if (fileType === "video") {
      initPlayer();
    }
    if (fileType === 'image' && file) {
      loadImage(file);
    }
  }, [fileType, file]);

  // Set the Cycle Timeout.
  useEffect(() => {
    setCycleTimeout();
  }, [props.data.base_folder]);

  // Check for best fit dimensions.
  useEffect(() => {
    const dims = getBestFitCallback();
    setWidth(dims.width);
    setHeight(dims.height);
  }, [props.containerHeight, props.containerWidth]);

  useEffect(() => {
    if (fileType === "video") {
      if (get(props, 'data.autoPlay', true)) {
        if (videoRef.current) {
          videoRef.current.play()
        }
      } else {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    }
  }, [props.data])

  // Show and position video.
  useEffect(() => {
    if (metadataLoaded && dataLoaded) {
      setShowVideo(true);
    }
    positionVideo();
  }, [metadataLoaded, dataLoaded]);

  useEffect(() => {
    const myBackgroundSize = get(props, "data.backgroundSize", "contain");
    setBackgroundSize(myBackgroundSize);
  }, [props.data]);

  if (!baseFolder) {
    return null;
  }
  const backgroundPosition = get(
    props,
    "data.style.backgroundPosition",
    "center"
  );
  const defaultStylesImage = {
    position: props.data.positionRelative ? "relative" : "absolute",
    backgroundImage: `url(${file})`,
    backgroundSize: props.data.isWidthScreen ? "cover" : backgroundSize,
    backgroundRepeat: "no-repeat",
    backgroundPosition: `${backgroundPosition}`,
    height: props.data?.componentSpecificData?.customStyle
      ? props.data.componentSpecificData.customStyle.height
      : height,
    width: props.data?.componentSpecificData?.customStyle
      ? props.data.componentSpecificData.customStyle.width
      : width,
    top: 0,
    left: 0,
  };

  switch (fileType) {
    case "image":
      // Only use the loader if we're in preview mode.
      // If we are in other mode we should just show the image.
      if (props.mode !== COMPONENT_MODE_CONTEXT.PREVIEW || (props.mode === COMPONENT_MODE_CONTEXT.PREVIEW && showImageRef.current)) {
        return (
          <div
            style={
              props.data?.componentSpecificData?.customStyleImage
                ? {
                    ...defaultStylesImage,
                    ...props.data?.componentSpecificData?.customStyleImage,
                  }
                : { ...defaultStylesImage }
            }
          />
        );
      }
    case "video":
      const useVideoPlayer = get(
        props,
        "data.componentSpecificData.use_video_player",
        false
      );
      const videoStyle: CSSProperties = {
        position: props.data.positionRelative ? "relative" : "absolute",
        width: width,
        height: height,
      };

      const customStyleVideo = props.data?.componentSpecificData?.customStyleVideo || {}

      let styles = { ...videoStyle }

      styles = customStyleVideo.hideOffset ? { ...styles, ...customStyleVideo } : { ...styles, ...customStyleVideo, top: videoOffsetY, left: videoOffsetY}

      return (
        <div
          className="video-no-fullscreen"
          style={{ display: showVideo ? "block" : "block" }}
        >
          {useVideoPlayer && (
            <VideoLoader
              url={file}
              style={{ ...videoStyle, top: videoOffsetY, left: videoOffsetY }}
              controls={useVideoPlayer}
            />
          )}

          {!useVideoPlayer && (
            <video
              style={styles}
              ref={videoRef}
              muted={get(props, 'data.muted', true)}
              autoPlay={get(props, 'data.autoPlay', true)}
              loop={get(props, 'data.videoIsShorterThanCycleTime', false)}
            >
              <source src={file} />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      );
    default:
      if (props.mode === COMPONENT_MODE_CONTEXT.PREVIEW) {
        const placeholder = get(props, "data.placeholder", "placeholder");
        const placeholderBackground = get(
          props,
          "data.placeholderBackground",
          false
        );
	      const showPlaceholder = props?.data?.persistent_settings?.data?.show_placeholder || false;
        const fieldType = get(props, "data.fieldType", null);
        const widthScreen = get(props, "data.isWidthScreen", false);

        // show place holder
        return showPlaceholder ? (
          <Slide1911Placeholder
            containerHeight={height}
            containerWidth={width}
            placeholder={placeholder}
            background={placeholderBackground}
            fieldType={fieldType}
            systemSettings={props.data.system_settings}
            widthScreen={widthScreen}
            customStyleImage={props.data?.componentSpecificData?.customStyle}
          />
        ) : null;
      } else {
        return null;
      }
  }
};

export default Slide1911;
