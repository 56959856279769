import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchCompanyRootFolders,
  fetchProjectFolders,
} from "../../actions/folderActions";
import { deleteProject } from "../../actions/projectActions";
import { IState } from "../../interfaces/main-state.interface";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { dateFormatted } from "../../utils/dateUtils";
import { hasPermission } from "../../utils/permissionUtils";
import CheckboxInput from "../form/input/CheckboxInput";
import TextInput from "../form/input/TextInput";
import AlertNotification from "../layout/AlertNotification";

interface IProjectProps {
  companyId: any;
  fetchCompanyRootFolders: any;
  fetchProjectFolders: any;
  folder__folders: { [key: string]: IFolderFolder };
  folders: { [key: string]: IFolder };
  grouped_permissions: any;
  history: any;
  locations: any;
  project_templates: any;
  projects: any;
  projectUrl: string;
  user: any;
}

interface IProjectTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  sorter?: any;
  render?: any;
}

const Projects: React.FunctionComponent<IProjectProps> = (
  props: IProjectProps,
) => {
  const projects = useSelector((state: IState) => state.data.projects);
  const grouped_permissions = useSelector(
    (state: IState) => state.data.user_permissions_grouped,
  );
  const user = useSelector((state: IState) => state.data.user);
  const locations = useSelector((state: IState) => state.data.locations);
  const folder__folders = useSelector(
    (state: IState) => state.data.folder__folders,
  );
  const project_templates = useSelector(
    (state: IState) => state.data.project_templates,
  );
  const folders = useSelector((state: IState) => state.data.folders);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteUnusedResources, setDeleteUnusedResources] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [confirmText, setConfirmText] = useState("");
  const [
    isConfirmDeleteProjectModalVisible,
    setIsConfirmDeleteProjectModalVisible,
  ] = useState(false);

  useEffect(() => {
    if (!folder__folders) {
      dispatch(fetchProjectFolders("applied_content", null, null));
    }
    dispatch(fetchCompanyRootFolders(props.companyId, null, null));
  }, []);

  const onShowProjectDeletionConfirmationModal = (id) => {
    setSelectedProjectId(id);
    setIsConfirmDeleteProjectModalVisible(true);
  };

  const onDeleteProjectSuccess = () => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null }),
    );
    AlertNotification(
      "success",
      "Success",
      "Project has been deleted successfully",
    );
  };

  const onDeleteProjectFail = (message) => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null }),
    );
    AlertNotification("error", "Error", message || "Unexpected error happened");
  };

  const onDeleteProject = () => {
    if (confirmText !== "delete") {
      AlertNotification(
        "error",
        "Error",
        'Please type "delete" into the confirmation text input',
      );
    } else {
      dispatch(
        setClientOptions({
          lock_screen: true,
          message_for_progress: "Please wait while we save your changes",
        }),
      );
      dispatch(
        deleteProject(
          selectedProjectId,
          deleteUnusedResources,
          onDeleteProjectSuccess,
          onDeleteProjectFail,
        ),
      );
      setIsConfirmDeleteProjectModalVisible(false);
      setSelectedProjectId(null);
      setDeleteUnusedResources(false);
      setConfirmText("");
    }
  };

  const onCancelProjectDeletion = () => {
    setSelectedProjectId(null);
    setIsConfirmDeleteProjectModalVisible(false);
    setDeleteUnusedResources(false);
    setConfirmText("");
  };

  const projectTableColumns: IProjectTableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (!a.name) {
          a.name = "";
        }
        if (!b.name) {
          b.name = "";
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
        return 0;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => {
        a = a.location || "";
        b = b.location || "";
        return a.location.toLowerCase() > b.location.toLowerCase() ? -1 : 1;
      },
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      sorter: (a, b) => {
        a = a.template || "";
        b = b.template || "";
        return a.template.toLowerCase() > b.template.toLowerCase() ? -1 : 1;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        return <span>{dateFormatted(text, "yyyymmdd")}</span>;
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  if (
    hasPermission(
      grouped_permissions,
      "project",
      "update",
      user,
      null,
      null,
      user.company,
    )
  ) {
    projectTableColumns.push({
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => {
        let url = "/company/" + props.companyId + "/project/" + record.id + "/";
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(url);
            }}
          >
            <FontAwesomeIcon
              className="fa-lg"
              icon="edit"
            />
          </span>
        );
      },
    });
  }

  if (
    hasPermission(
      grouped_permissions,
      "project",
      "delete",
      user,
      null,
      null,
      user.company,
    )
  ) {
    projectTableColumns.push({
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => {
        // let url = "/company/" + props.companyId + "/project/" + record.id + "/";
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onShowProjectDeletionConfirmationModal(record.id)}
          >
            <FontAwesomeIcon
              className="fa-lg"
              icon="trash"
            />
          </span>
        );
      },
    });
  }

  let projectArray = arrayFromKeyedObject(projects);
  let arr = [];
  projectArray.forEach((project) => {
    if (project.company === props.companyId) {
      let template = "";
      if (folder__folders) {
        //TODO: folder__folders are not available as system admin for a company you are not administering, therefore we need to fetch them in company administration view for x company
        const root_folder__folder = Object.values(folder__folders).find(
          (x) => x.parent_folder === null && x.project === project.id,
        );
        if (root_folder__folder) {
          const root_folder = folders[root_folder__folder.child_folder];
          if (root_folder) {
            const matches = root_folder.folder_type.match(/(\w+)_root/);
            if (matches) {
              template = project_templates[matches[1]].display_name;
            }
          }
        }
      }
      let obj = {
        name: project.name,
        updatedAt: new Date(project.updatedAt),
        createdAt: new Date(project.createdAt),
        key: project.id,
        id: project.id,
        location: locations[project.location].name,
        template: template,
      };
      arr.push(obj);
    }
  });

  return (
    <>
      <div>
        <Table
          style={{ margin: 0 }}
          title={() =>
            hasPermission(
              grouped_permissions,
              "project",
              "create",
              user,
              null,
              null,
              user.company,
            ) ? (
              <Button onClick={() => navigate(props.projectUrl)}>
                Add Project
              </Button>
            ) : (
              <b>Projects</b>
            )
          }
          rowKey={(record) => record.key}
          columns={projectTableColumns}
          dataSource={arr}
          pagination={false}
          bordered={false}
        />
        <Modal
          title={`Delete project`}
          visible={isConfirmDeleteProjectModalVisible}
          onCancel={onCancelProjectDeletion}
          onOk={onDeleteProject}
          width={720}
          destroyOnClose
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <span style={{ color: "#EED202" }}>
              <FontAwesomeIcon
                className="fa-4x"
                icon={["fas", "exclamation-triangle"]}
              />
            </span>
            <br />
            <br />
            <span>
              <b>WARNING: THIS IS A DESTRUCTIVE ACTION</b>
            </span>
          </div>
          <br />
          <span>
            Folders and media resources deleted as a consequence of this action
            won't be recoverable.
          </span>
          <br />
          <br />
          {selectedProjectId && (
            <span>
              {`Are you sure you want to delete project `}
              <b>{projects[selectedProjectId].name}</b>
            </span>
          )}
          <br />
          <br />
          <CheckboxInput
            canUpdate
            fieldName="delete_resource"
            title=""
            defaultValue={deleteUnusedResources}
            onChange={(_, value) => {
              console.log(value);
              setDeleteUnusedResources(value);
            }}
          />
          <span>
            Also delete media resources associated to this project that are not
            currently being used.
          </span>
          <br />
          <br />
          <b>
            <span>
              Type <i>delete</i> to confirm
            </span>
          </b>
          <TextInput
            fieldName="delete_confirm"
            showLabel={false}
            defaultValue={confirmText}
            title='Type "delete" to confirm'
            showCharCount={false}
            canUpdate
            onBlur={(_, value) => setConfirmText(value)}
            onChange={(_, value) => setConfirmText(value)}
          />
        </Modal>
      </div>
    </>
  );
};

export default Projects;
