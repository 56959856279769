const styles = {
  componentContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
  },
  componentOverlay: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    backgroundColor: (props) => props.backgroundOverlay,
  },
  componentContent: {
    display: "flex",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    flexDirection: (props) => props.mirrorContent ? 'row' : 'row-reverse',
    "& .quote": {
      width: (props) => props.containerWidth / 3,
      backgroundColor: (props) => props.quoteBackground,
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      "& .quoteTextTop": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'flex-end',
        height: (props) => props.containerHeight / 2,
        padding: "10em 10em 7.5em"
      },
      "& .quoteTextBottom": {
        height: (props) => props.containerHeight / 2,
        padding: "10em 10em 7.5em"
      }
    }
  },
  story: {
    width: (props) => props.screenWidth,
    height: (props) => props.containerHeight,
    display: "flex",
    flexDirection: "column",
    backgroundColor: (props) => props.storyBackground,
    "& .storyTop": {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      height: (props) => props.containerHeight / 2,
      padding: "10em 10em 7.5em"
    },
    "& .storyBottom": {
      display: "flex",
      height: (props) => props.containerHeight / 2,
      justifyContent: "flex-start",
      flexDirection: "column",
      padding: "10em 10em 7.5em"
    }
  },
  storyWithQuoteContent: {
    display: "flex",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    flexDirection: (props) => !props.mirrorContent ? 'row' : 'row-reverse',
    "& .backgroundSection": {
      width: (props) => props.screenWidth * 2,
      height: (props) => props.containerHeight,
      display: "flex",
      flexDirection: "column-reverse",
      "& .backgroundContainer": {
        position: "absolute",
        width: (props) => props.screenWidth * 2,
        height: (props) => props.containerHeight,
      },
      "& .backgroundContainerOverlay": {
        position: "absolute",
        width: (props) => props.screenWidth * 2,
        height: (props) => props.containerHeight,
        backgroundColor: (props) => props.backgroundOverlay,
        zIndex: 1,
      },
      "& .quoteContainer": {
        display: "flex",
        width: (props) => props.screenWidth,
        height: (props) => props.screenHeight,
        marginLeft: (props) => props.mirrorContent ? 0 : props.screenWidth,
        zIndex: 1,
        "& .quoteSection": {
          display: "flex",
          flexDirection: "column",
          backgroundColor: (props) => props.quoteBackground,
          width: (props) => props.screenWidth,
          height: (props) => props.screenHeight,
          padding: "10em 10em 7.5em"
        }
      }
    }
  },
  storyWithMedia: {
    position: 'absolute',
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    "& .imageLeft": {
      position: "absolute",
      width: (props) => props.screenWidth,
      height: (props) => props.containerHeight,
    },
    "& .storyCenter": {
      position: "absolute",
      left: (props) => props.screenWidth,
      width: (props) => props.screenWidth,
      height: (props) => props.containerHeight,
    },
    "& .imageRight": {
      position: "absolute",
      left: (props) => props.screenWidth * 2,
      width: (props) => props.screenWidth,
      height: (props) => props.containerHeight,

    },
  }
}

export default styles
