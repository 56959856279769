import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE, FOLDER_CATEGORY } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { textstyle_default } from "./boston_root";
import { FontsFamilies } from "../../../../fonts";

export const boston_donor_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.boston_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Fund",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor],
  hide_nested_children: true,
  fields: {
    name: {
      ...name,
      field_group: "general"
    },
    sorting_options: {
      type: "select",
      title: "Sorting Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "rank",
      options: [
        {
          title: "Rank",
          value: "rank"
        },
        {
          title: "Sorting Order",
          value: "sorting_order"
        }
      ],
      field_group: "diamond_platinum_list"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "diamond_platinum_list"
    },
    header: {
      type: "text",
      title: "Header",
      default: null,
      settings: {
        recommended_length: 38
      },
      field_group: "diamond_platinum_list"
    },
    header_style: {
      type: "textstyle",
      title: "Header Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        bold: {
          enabled: true
        },
        color: {
          enabled: true
        },
        italic: {
          enabled: true
        },
        underline: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "diamond_platinum_list"
    },
    description_style: {
      type: "textstyle",
      title: "Description Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        bold: {
          enabled: true
        },
        color: {
          enabled: true
        },
        italic: {
          enabled: true
        },
        underline: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "diamond_platinum_list"
    },
    description: {
      type: "textarea",
      title: "Description",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      default: null,
      settings: {
        recommended_length: 200
      },
      field_group: "diamond_platinum_list"
    },
    donor_name_style: {
      type: "textstyle",
      title: "Donor Name Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        bold: {
          enabled: true
        },
        color: {
          enabled: true
        },
        italic: {
          enabled: true
        },
        underline: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    donor_name_diamond_platinum_style: {
      type: "textstyle",
      title: "Donor Name Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        bold: {
          enabled: true
        },
        color: {
          enabled: true
        },
        italic: {
          enabled: true
        },
        underline: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "diamond_platinum_list"
    },
    screen_left_and_right_padding: {
      type: "integer",
      title: "Screen Left and Right Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "diamond_platinum_list"
    },
    screen_top_and_bottom_padding: {
      type: "integer",
      title: "Screen Top and Bottom Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "diamond_platinum_list"
    },
    description_horizontal_rule_thickness: {
      type: "integer",
      title: "Description Horizontal Rule Thickness",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 1,
      field_group: "diamond_platinum_list"
    },
    columns: {
      type: "integer",
      title: "Columns",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
      default: 2,
      field_group: "diamond_platinum_list"
    },
    donor_list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 4,
      field_group: "diamond_platinum_list"
    },
    // donor_list_separation_horizontal: {
    //   type: "integer",
    //   title: "Donor List Separation Horizontal",
    //   tooltip: "Unit is % of container height",
    //   validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
    //   default: 2.5,
    //   field_group: "diamond_platinum_list"
    // },
    // donor_list_separation_vertical: {
    //   type: "integer",
    //   title: "Donor List Separation Vertical",
    //   tooltip: "Unit is % of container height",
    //   validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
    //   default: 2.5,
    //   field_group: "diamond_platinum_list"
    // },
    description_top: {
      type: "integer",
      title: "Description Margin Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 4 }] },
      default: 11,
      field_group: "diamond_platinum_list"
    },
    donor_list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "diamond_platinum_list"
    },
    donor_list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "diamond_platinum_list"
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 2,
      field_group: "diamond_platinum_list"
    },
    donor_vertical_separation: {
      type: "integer",
      title: "Donor Vertical Separation",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      default: 4,
      field_group: "diamond_platinum_list"
    },
    horizontal_color_overlay: {
      type: "color",
      title: "Horizontal Color Overlay",
      default: "",
      sys_admin_only: true,
      tooltip: "Color overlay for the horizontal portion of the donor sea",
      field_group: "diamond_platinum_list"
    },
    vertical_color_overlay: {
      type: "color",
      title: "Vertical Color Overlay",
      default: "",
      tooltip: "Color overlay for the vertical portion of the donor sea",
      sys_admin_only: true,
      field_group: "diamond_platinum_list"
    },
    media_horizontal_3x2: {
      title: "Media Horizontal 3x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.666666667,
      recommended_size: {
        width: 5760,
        height: 2160
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.666666667
      },
      field_group: "diamond_platinum_list"
    },
    media_horizontal_5x2: {
      title: "Media Horizontal 5x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 4.444444444,
      recommended_size: {
        width: 9600,
        height: 2160
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 4.444444444
      },
      field_group: "diamond_platinum_list"
    },
    media_vertical_2x5: {
      title: "Media Vertical 2x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.711111111,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      },
      field_group: "diamond_platinum_list"
    },
    media_vertical_1x5: {
      title: "Media Vertical 1x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.355555556,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      },
      field_group: "diamond_platinum_list"
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true,
      field_group: "general"
    }
  },
  tabs: {
    general: {
      title: "General"
    },
    diamond_platinum_list: {
      title: "Diamond Platinum List"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    diamond_platinum_list: {
      title: "Diamond Platinum List",
      default_expanded: true,
      order: 2,
      accordion: false,
      tab: "diamond_platinum_list"
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.boston_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.boston_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.boston_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.boston_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: [
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston1x2and1x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "1x2, 1x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/1x2_1x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_1x2_and_1x5_and_3x2
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    }
  ],
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
