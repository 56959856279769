import { Action } from "redux";
import { EventChannel, eventChannel } from "redux-saga";
import { call, fork, put, take } from "redux-saga/effects";
import socketIOClient, { Socket } from "socket.io-client";
import sailsIOClient from "sails.io.js";
import {
  checkUpdateFolderAlone,
  updateFolderAlone,
} from "../actions/folderActions";
import get from "lodash/get";
import { CHANGE_COMPANY } from "../actions/actionTypes";
import { folderTypeAlert } from "../actions/companyActions";

function connect(): Promise<Socket> {
  const sailsSocket = sailsIOClient(socketIOClient);
  sailsSocket.sails.url = process.env.REACT_APP_SERVER_URL;
  const socket = sailsSocket.socket;

  return new Promise((resolve) => {
    socket.on("connect", () => {
      console.log("Connect Socket::::::");
      socket.get("/company/subscribe");
      resolve(socket);
    });
  });
}

function subscribe(socket: Socket): EventChannel<unknown> {
  return eventChannel((emit) => {
    socket.on("folder_update", (data) => {
      emit(updateFolderAlone(data));
    });
    socket.on("folder_updated_check", (data) => {
      emit(checkUpdateFolderAlone(data));
    });
    socket.on("folder_type_alert", (data) => {
      emit(folderTypeAlert(data));
    });
    return () => {};
  });
}

function* read(socket: Socket): Generator {
  const channel = (yield call(subscribe, socket)) as EventChannel<unknown>;
  while (true) {
    const action = (yield take(channel)) as Action;
    yield put(action);
  }
}

export function* unsubscribeToChannel(socket) {
  while (true) {
    const data = yield take(CHANGE_COMPANY);
    const oldCompanyId = data.payload.old_company_id;
    socket.get("/company/unsubscribe", { company: oldCompanyId });
  }
}

export function* subscribeToChannel(socket) {
  while (true) {
    const data = yield take("CHANGE_COMPANY_USER_SUCCESS");
    socket.get("/company/subscribe", { company: data.company_id });
  }
}

export function* socketInitFlow(): Generator {
  if (!socketIOClient.sails) {
    const socket = (yield call(connect)) as Socket;
    yield fork(read, socket);
    yield fork(unsubscribeToChannel, socket);
    yield fork(subscribeToChannel, socket);
  }
}
