import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/company/company_project_fields';

import { connect } from "react-redux";
import AlertNotification from '../layout/AlertNotification';
import { getCompany } from '../../actions/companyActions';
import { updateProject, getProject, addProject, getAllNotifiableUsers } from '../../actions/projectActions';
import { fetchProjectFolders } from '../../actions/folderActions';
import CompanyBreadCrumbs from './CompanyBreadCrumbs';
import _ from 'lodash';
import { BackLink } from "../utils/BackLink";
import { withRouter } from '../../hooks/withRouter';

class CompanyProjectForm extends Component {

  constructor(props) {
    super(props);
    let editMode = false, loading = true;

    if (props.params.projectId) {
      editMode = true;
      loading = true;
      props.getAllNotifiableUsers(props.params.projectId, this.getNotifiableUsersSuccess, this.getNotifiableUsersFail);
    } else {
      loading = false;
    }

    if (!props.locations) {
      props.getCompany(props.params.companyId);
    }

    this.state = {
      editMode: editMode,
      name: _.get(props, 'project.name', null),
      previousNotifiableUsers: [],
      loading: loading,
      location: _.get(props, 'project.location', null),
      red_alert_minutes: _.get(props, 'project.red_alert_minutes', null),
      submitting: false,
      yellow_alert_minutes: _.get(props, 'project.yellow_alert_minutes', null),
      allow_show_cross: _.get(props, 'project.settings.allow_show_cross', false)
    };
  }

  getNotifiableUsersSuccess = () => {
    this.setState({
      loading: false,
      previousNotifiableUsers: this.props.user__project_notifies || []
    });
  }

  getNotifiableUsersFail = () => {
    AlertNotification('error', 'Error', 'There was an error. Please, try later or call an administrator')
  }

  componentDidMount() {
    if (this.props.company == null) {
      this.props.getCompany(this.props.params.companyId, this.onSuccess, this.onFail);
    }
    if (!isNaN(this.props.params.projectId)) {
      this.props.getProject(this.props.params.projectId, this.onSuccess, this.onFail);
    }
    if (this.state.editMode === true) {
      // console.log("called method getcompany  on page load...");
      //this.props.getcompany(this.props.params.companyId ,this.onSuccess,this.onFail);
    }
  }


  onSubmit = (values) => {
    this.setState({ submitting: true })
    if (this.state.editMode === false) {
      this.props.addProject(this.props.params.companyId, values, this.onInsert, this.onFail);
    }
    else {
      this.props.updateProject(this.props.params.projectId, values, this.onUpdate, this.onError);
    }
  }

  onUpdate = () => {
    AlertNotification('success', 'Sucess', "Project updated")
    let url = "/company/" + this.props.params.companyId;
    this.props.navigate(url)
  }

  onInsert = () => {
    this.setState({ submitting: false })
    AlertNotification('success', 'Sucess', "Project created")
    this.props.navigate("/company/" + this.props.company.id)
    this.props.fetchProjectFolders("TODO:NOT USED", null, null)
  }


  onError = (e) => {
    this.setState({ submitting: false })
  }

  render() {
    let obj;
    if (this.state.loading) {
      return null;
    }

    let formConfigMod = this.props.formConfigMod
    if (this.state.editMode === true && this.props.project === undefined) {
      return null;
    }
    let mode = "";
    let formTitle = "";
    if (this.state.editMode === false && this.props.company === null) {
      return null;
    }
    else if (this.state.editMode === false) {
      delete formConfigMod.fields.notify_users;
      obj = {
        name: "",
      }
      mode = 'add';
      formTitle = "Add Project";
    }
    else {
      delete formConfigMod.fields.template;
      if (this.props.locations) {
        const current_location = this.props.locations[_.get(this.props, 'project.location', null)];
        if (current_location) {
          formConfigMod.fields.location.defaultValue = current_location.name;
        }
      }
      mode = 'edit';
      obj = {
        name: this.state.name,
        notify_users: Object.values(this.state.previousNotifiableUsers).map(x => x.user),
        location: this.state.location,
        red_alert_minutes: this.state.red_alert_minutes,
        yellow_alert_minutes: this.state.yellow_alert_minutes,
        allow_show_cross: this.state.allow_show_cross
      };
      formTitle = "Edit Project ";
    }

    return (
      <div>

        <CompanyBreadCrumbs crumbs={this.props} />
        <div>
          <BackLink />
        </div>
        <h1>{formTitle}</h1>
        <DynamicForm
          submitting={this.state.submitting}
          fieldGroupsConfig={formConfigMod.field_groups}
          fieldsConfig={formConfigMod.fields}
          fieldValues={obj}
          mode={mode}
          onSubmit={this.onSubmit}
          onError={this.onError}
          name={formTitle}
          enableFieldGrouping={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let project = null;
  let company = null;
  company = _.get(state, 'data.companies[' + ownProps.params.companyId + ']');
  project = _.get(state, 'data.projects[' + ownProps.params.projectId + ']');

  const mapUserObj = (current_user) => {
    const first_name = _.get(current_user, 'first_name', '');
    const last_name = _.get(current_user, 'last_name', '');
    return {
      title: `${first_name} ${last_name}`,
      value: current_user.id
    }
  }

  const formConfigMod = _.cloneDeep(formConfig);

  Object.keys(state.data.project_templates).forEach(function (key) {
    const template = state.data.project_templates[key]
    formConfigMod.fields.template.options.push(
      {
        title: template.display_name,
        value: key
      }
    )
  });

  if (state.data.locations) {
    Object.values(state.data.locations).filter(x => x.company === company.id).forEach(function (location) {
      formConfigMod.fields.location.options.push({
        title: location.name,
        value: location.id
      });
    });
  }

  if (state.data.role__users && project) {
    const uniq_user_ids = [
      ...new Set(Object.values(state.data.role__users)
        .filter(x => x.project === project.id && x.company === company.id)
        .map(x => x.user)
        .filter(userId => state.data.users[userId])
      )
    ];
    let multiselect_options = uniq_user_ids.map(x => {
      const current_user = state.data.users[x];
      return mapUserObj(current_user)
    });

    for (const usersKey in state.data.users) {
      if (
        (state.data.users.hasOwnProperty(usersKey) && state.data.users[usersKey].system_admin)
        || (company.hasOwnProperty('company_admin_users_id') && company.company_admin_users_id.includes(Number(usersKey)))
      ) {
        if (!multiselect_options.find(opt => opt.value === state.data.users[usersKey].id)) {
          multiselect_options.push(mapUserObj(state.data.users[usersKey]))
        }
      }
    }

    formConfigMod.fields.notify_users.options = multiselect_options;
  }

  return {
    company: company,
    formConfigMod: formConfigMod,
    locations: state.data.locations,
    project: project,
    user__project_notifies: state.data.user__project_notifies
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateProject: (id, values, onSuccess, onFail) => {
      dispatch(updateProject(id, values, onSuccess, onFail));
    },
    getProject: (id, onSuccess, onFail) => {
      dispatch(getProject(id, onSuccess, onFail));
    },
    addProject: (id, values, onSuccess, onFail) => {
      dispatch(addProject(id, values, onSuccess, onFail));
    },
    getCompany: (id, onSuccess, onFail) => {
      dispatch(getCompany(id, onSuccess, onFail));
    },
    getAllNotifiableUsers: (project_id, onSuccess, onFail) => {
      dispatch(getAllNotifiableUsers(project_id, onSuccess, onFail));
    },
    fetchProjectFolders: (tree_type, onSuccess, onFail) => {
      dispatch(fetchProjectFolders(tree_type, onSuccess, onFail))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyProjectForm));
