
import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const MultiSelectInput = props => {
  const selectOptions = props.options.map(option => {
    return (
      <Select.Option key={option.value} value={option.value}>
        {option.title}
      </Select.Option>
    );
  });
  return (
    <React.Fragment>
      <label className="form-input-label">{props.title}</label><br/>
      <Select
        disabled = {!props.canUpdate}
        className="multi-select-input"
        mode="multiple"
        defaultValue={props.defaultValue}
        onChange={newValue => props.onChange(props.fieldName, newValue)}
        onBlur={newValue => props.onBlur(props.fieldName, newValue)}
        value={props.defaultValue}
        style={{minWidth: 200}}
      >
        {selectOptions}
      </Select>
    </React.Fragment>
  );
};

MultiSelectInput.defaultProps = {
  onBlur: () => {}
};

MultiSelectInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

export default MultiSelectInput;