import { FontsFamilies } from "../../../../fonts";
import { getFontSizes } from "../../../../utils/general";
import { wysiwyg } from "../standardized_fields";

export const everettFonts = [
  FontsFamilies.ABC_SOCIAL,
  FontsFamilies.BARLOW,
  FontsFamilies.LORA,
  FontsFamilies.MERRIWEATHER,
  FontsFamilies.RECTOR,
  FontsFamilies.ROBOTO,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.PANTO0GRAPH_CONDENSED,
  FontsFamilies.PANTO0GRAPH_EXTENDED,
  FontsFamilies.PANTO0GRAPH_WIDE,
  FontsFamilies.OPEN_SANS
];

const fontMinSize = 1;
const fontMaxSize = 10;

export const textStyleFontSizes = getFontSizes(10, 40, 1, "px", (i) => {
  return `${i}px`;
});

export const quillFontSizes = {
  min: fontMinSize,
  max: fontMaxSize,
  type: "range"
};

export const textStyleOptions = {
  font: {
    enabled: true,
    items: everettFonts
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: textStyleFontSizes
  },
  lineHeight: {
    enabled: true
  },
  bold: {
    enabled: false
  },
  color: {
    enabled: true
  },
  italic: {
    enabled: true
  },
  underline: {
    enabled: true
  }
};

export const settings = {
  use_em_units: true,
  wysiwyg_input_type: "text"
};

export const transitions = [
  {
    title: "Fade (1/2 s)",
    value: "transition-fade"
  },
  {
    title: "Fade (1 s)",
    value: "transition-fade1s"
  },
  {
    title: "Fade (2 s)",
    value: "transition-fade-2s"
  },
  {
    title: "Slow Fade In / Fast Fade Out",
    value: "transition-slowinfastout"
  },
  {
    title: "Fast Fade In / Slow Fade Out",
    value: "transition-fastinslowout"
  },
  {
    title: "Slide in right",
    value: "transition-slide-in-from-right"
  },
  {
    title: "Slide in left",
    value: "transition-slide-in-from-left"
  },
  {
    title: "Slide in top",
    value: "transition-slide-in-from-top"
  },
  {
    title: "Slide in bottom",
    value: "transition-slide-in-from-bottom"
  }
];

export const list_styles = [
  {
    title: "Column Filled",
    value: "filled"
  },
  {
    title: "Balanced",
    value: "balanced"
  }
];

export const wysiwygSettings = {
  ...wysiwyg.settings,
  controls: {
    ...wysiwyg.settings.controls,
    bold: false
  },
  font_size_unit: "px",
  fontSizeOptions: quillFontSizes,
  fonts: everettFonts
};