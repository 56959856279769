import React, { Component } from "react";
import { Modal } from "antd";
import {
  deleteResources
} from "../../actions/resourceActions";
import { connect } from "react-redux";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { getIdFromTreeNode, reversePathsFromNode } from "../../utils/treeUtils";

class DeleteResourceModal extends Component {
  onSave = () => {
    console.log("on save");
    this.props.deleteResources([this.props.resource_id])
    this.onCancel();
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onError = () => {};

  renderStringPaths = (stringPaths) => {
    return stringPaths.map((x, index) => {
      return <div key={index}>{x}</div>;
    });
  }

  renderReferences = () => {
    return this.props.references.map((x, index) => {
      return <div key={index}>{this.renderStringPaths(x.stringPaths)}</div>;
    });
  };

  renderModalContent = () => {
    if (!this.props.visible) {
      return null;
    }
    if (this.props.canDelete) {
      return (
        <div>
          <div>
            Are you sure you want to delete this content? This action cannot be
            undone.
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>
          You must remove all references to this content before you can delete.
          <br />
          The following references exist:
          <hr />
        </div>
        {this.renderReferences()}
      </div>
    );
  };

  render() {
    return (
      <Modal
        title={"Delete Content"}
        visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onSave}
        okButtonProps={{ disabled: !this.props.canDelete }}
        width={720}
        destroyOnClose="true"
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.visible === false) {
    return {};
  }
  let resource_tree_selected_id = getIdFromTreeNode(
    state.client_options.resource_tree_selected_id,
    true,
    false
  );
  let resource_id;
  if (state.data.resourcetree_resource__folders[resource_tree_selected_id]){
    resource_id = state.data.resourcetree_resource__folders[resource_tree_selected_id].resource
  }
  else{
    console.log("is a null value here a problem?");
  }

  let references = arrayFromKeyedObject(state.data.resource__folders);
  references = references.filter(x => x.resource === resource_id);

  references.map(x => {
    x.paths = reversePathsFromNode(x.folder, state.data.folder__folders);
    x.stringPaths = []
    x.paths.map(path => {
      let stringPart = ""
      path.map(part => {
        if (state.data.folders[part]){
          stringPart+=state.data.folders[part].name + "/"
        }
        else{
          console.log("catch, is null value here a problem")
        }
        return null;
      });
      x.stringPaths.push(stringPart);
      return null;
    });
    return null;
  })

  let canDelete = references.length === 0;

  return {
    references: references,
    resource_id: resource_id,
    resource_tree_selected_id: resource_tree_selected_id,
    canDelete: canDelete
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteResources: (resource_ids, onSuccess, onFail) => {
    dispatch(
      deleteResources(resource_ids, onSuccess, onFail)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteResourceModal);
