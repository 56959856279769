import React from 'react';

const BRSpan = props => {
  
    let className = "";
    if (props.preWrap){
      className+="whiteSpaceAndBreaks"
    }

    const splitStr = () => {
      let str = props.str
      if (!str){
        return (
          <span />
        )
      }
      let splitline = false;
      if (str.includes("<br>")){
        str = str.split("<br>");
        splitline = true;
      } else if (str.includes("<BR>")) {
        str = str.split("<BR>");
        splitline = true;
      }
      if (splitline===false){
        return (
          <span className={className}>{str}</span>
        )
      }
      else{
        return (
          str.map(function (row, i) {
          return (
            <span  className={className} key={i}>
            {row}
            {i < str.length? <br /> : <span />}
            </span>
          )
          })
        )
      }
    }

    return (
      <span className={className}>{splitStr()}</span>
    )

};

export default BRSpan;
