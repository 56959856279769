import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import useStyles from "./styles";

export interface IResourceExplorerFolderProps {
  width: number;
  height: number;
  title: string;
  selected: boolean;
  icon: IconProp;
}

const ResourceExplorerFolder: React.FC<IResourceExplorerFolderProps> = (
  props
) => {
  const styleProps = {
    width: props.width,
    height: props.height,
    selected: props.selected
  };
  const classes = useStyles({ theme: {}, ...styleProps });

  return (
    <div className={classes.folder}>
      {/* Icon */}
      <div id="icon">
        <FontAwesomeIcon className="fa-3x" icon={props.icon} />
      </div>
      {/* Text */}
      <div id="text">{props.title}</div>
    </div>
  );
};

export default ResourceExplorerFolder;
