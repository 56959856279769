import get from "lodash/get";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../utils/commonDataUtils";
import {
  getFileDynamicByRank,
  getFileTypeFromResources
} from "../../utils/fileUtils";

const CycleWrapper: React.FC<IDisplayComponentProps> = (props) => {
  const [cycleTimeoutHandler, _setCycleTimeoutHandler] = useState(
    null
  );
  const cycleTimeoutHandlerRef = useRef(cycleTimeoutHandler);
  const setCycleTimeoutHandler = data => {
    cycleTimeoutHandlerRef.current = data;
    _setCycleTimeoutHandler(data);
  }

  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [canRenderVideo, setCanRenderVideo] = useState(true);
  const [calculatedCycleTime, setCalculatedCycleTime] = useState(-1);
  const [folderCycleTime, setFolderCycleTime] = useState(-1);

  const timestampOnMount = useMemo(() => new Date().getTime(), []);

  const [didCycle, _setDidCycle] = useState(false);
  const didCycleRef = useRef(didCycle);
  const setDidCycle = data => {
    didCycleRef.current = data;
    _setDidCycle(data);
  }

  const handleKeyDown = useCallback((event) => {
    if (!didCycleRef.current) {
      setDidCycle(true);
    } else {
      return;
    }
    const cycleForwardKey = get(
      props,
      "data.componentSpecificData.cycle_forward_key",
      "ArrowRight"
    );
    const cycleBackwardKey = get(
      props,
      "data.componentSpecificData.cycle_backward_key",
      "ArrowLeft"
    );
    switch (event.key) {
      case cycleForwardKey:
        if (props.handleEndOfPlay) {
          clearTimeout(cycleTimeoutHandlerRef.current);
          props.handleEndOfPlay('forward', null);
        }
        break;
      case cycleBackwardKey:
        if (props.handleEndOfPlay) {
          clearTimeout(cycleTimeoutHandlerRef.current);
          props.handleEndOfPlay('backward', null);
        }
        break;
      default:
        break;
    }
  }, [setDidCycle]);

  const setCycleTimeout = (cycleTime) => {
    clearTimeout(cycleTimeoutHandlerRef.current);
    const cycleTimeoutHandlerTmp = setTimeout(() => {
      if (props.handleEndOfPlay) {
        props.handleEndOfPlay();
      }
    }, cycleTime);
    setCycleTimeoutHandler(cycleTimeoutHandlerTmp);
  };

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      const childProps = child.props as any;

      let videoIsShorterThanCycleTime = false;
      if (calculatedCycleTime >= 0 || folderCycleTime >= 0) {
        if (
          videoUrl.length > 0 &&
          calculatedCycleTime > 0 &&
          folderCycleTime > 0
        ) {
          videoIsShorterThanCycleTime = calculatedCycleTime < folderCycleTime;
        }
      }

      if (childProps) {
        child = React.cloneElement(child as React.ReactElement<any>, {
          children: recursiveMap(childProps.children),
          data: { ...childProps.data, videoIsShorterThanCycleTime },
        });
      }

      return child;
    });
  };

  const onLoadedMetadata = useCallback(
    (e) => {
      const videoLength = e.target.duration * 1000;
      const timeDiffFromMount = new Date().getTime() - timestampOnMount;
      // Cycle Video MS Ahead of Time to account for time it takes to make the transition to the next item in a loop.
      const cycleVideoMsAhead = get(props, 'data.cycleVideoMsAhead', 0);
      const computedTimeout = videoLength - timeDiffFromMount - cycleVideoMsAhead;
      if (folderCycleTime === 0 || props.data?.playFullVideo) {
        setCycleTimeout(computedTimeout < 0 ? 0 : computedTimeout);
      } else {
        setCycleTimeout(folderCycleTime - timeDiffFromMount);
        setCalculatedCycleTime(videoLength - timeDiffFromMount);
      }
      setCanRenderVideo(false);
    },
    [folderCycleTime]
  );

  const getFileTypeFromResourcesCallback = (slotNames) => {
    return getFileTypeFromResources(
      props.data?.resources,
      props.data?.resource__folders,
      slotNames,
      props.data?.base_folder,
      props.data?.index || 0
    );
  };

  const getFileDynamicCallback = useCallback(
    (slotNames, preset = null) =>
      getFileDynamicByRank(
        props.data?.index || 0,
        props.aws,
        props.aws_bucket,
        props.data?.resources,
        props.data?.resource__folders,
        slotNames,
        props.data?.base_folder,
        preset,
        props.webview_signedurls
      ),
    [props]
  );

  useEffect(() => {
    const baseFolder = getBaseFolder(props.data);
    const folderType = (props.data?.folder_types ?? []).find(
      (fT) => fT.name === baseFolder?.folder_type
    );
    const cycleFieldName = get(folderType, "cycle_field_name", "cycle_time");
    const folderCycleTime =
      get(baseFolder, `fields.${cycleFieldName}`, 5) * 1000;
    const effectiveCycleTime = props.cycle_time
      ? props.cycle_time
      : folderCycleTime;

    setFolderCycleTime(effectiveCycleTime);

    // Let's also verify if there's a video to play, if so, let's set the cycle time to the video duration.
    const fileFieldsKeys = Object.keys(folderType?.fields ?? {});
    const fileFields = fileFieldsKeys.filter(
      (key) => folderType?.fields[key].type === "file"
    );

    let fileUrl = "";
    for (const fieldKey of fileFields) {
      const fileType = getFileTypeFromResourcesCallback([fieldKey]);
      if (fileType === "video") {
        fileUrl = getFileDynamicCallback([fieldKey], null);
        break;
      }
    }

    if (!fileUrl) {
      setCycleTimeout(effectiveCycleTime);
    } else {
      setVideoUrl(fileUrl);
    }

    return () => {
      clearTimeout(cycleTimeoutHandlerRef.current);
    }
  }, []);

  useEffect(() => {
    // Use Key Down Cycler.
    const useKeyDownCycler = get(props, 'data.componentSpecificData.useKeyDownCycler', false);
    if (useKeyDownCycler) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      clearTimeout(cycleTimeoutHandlerRef.current);
      if (useKeyDownCycler) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    }
  }, []);

  return (
    <>
      {videoUrl && canRenderVideo && (
        <video
          style={{ position: "absolute", width: 0, height: 0 }}
          ref={videoRef}
          src={videoUrl}
          onLoadedMetadata={onLoadedMetadata}
        />
      )}
      {recursiveMap(props.children)}
    </>
  );
};

export default CycleWrapper;
