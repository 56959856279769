// pass in permissionsGrouped and return result
export function checkForPermission(permissionArea, permisionType, userPermissions){
  const contentPermissions = userPermissions[permissionArea];
  let hasPermission = false;

  for (let i in contentPermissions.restrict){
    if (contentPermissions.restrict[i][permisionType])
      return hasPermission
  }

  for (let i in contentPermissions.grant){
    if (contentPermissions.grant[i][permisionType])
      hasPermission=true
  }

  return hasPermission;
}
