import { IFolderType } from "../../folders/IFolderType";
import base_types from "../base_types";
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
} from "../../../../enums/folder-type-enums";
import { textstyle_options } from "./phoenix_settings";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const textstyle_default = {
  color: "#ffffff",
  alignment: "center",
  font: fonts[FontsFamilies.ARIAL][FontVariants.BOLD],
  fontSize: 10,
  lineHeight: 1.25
};

export const phoenix_checkerboard: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.phoenix_checkerboard,
  display_name: "Checkerboard",
  tabs: {
    general: {
      title: "General",
    },
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general",
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
      },
      default: null,
      show_char_count: false,
      field_group: "general",
    },
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9,
      },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    background_color_overlay: {
      type: "color",
      title: "Background Image overlay",
      default: "",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    checkerboard_media: {
      title: "Checkerboard Media",
      type: "file",
      slot_type: "dynamic",
      field_group: "general",
      limit: 24,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920,
        height: 1080,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778,
      },
    },
    inspirational_words_color_overlay: {
      type: "color",
      title: "Words Background Overlay",
      default: "",
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    inspirational_words_style: {
      type: "textstyle",
      title: "Inspirational Words Style",
      field_group: "general",
      tooltip: "",
      show_char_count: false,
      default: textstyle_default,
      options: {
        ...textstyle_options,
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    inspirational_words: {
      type: "textarea",
      title: "Inspirational Words",
      field_group: "general",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      tooltip: "Enter a comma seperated list of inpirational words",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    related_inspirational_words_style: {
      type: "textstyle",
      title: "Related Inspirational Words Style",
      field_group: "general",
      show_char_count: false,
      default: textstyle_default,
      options: {
        ...textstyle_options,
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    related_inspirational_words: {
      type: "textarea",
      title: "Related Inpirational Words",
      field_group: "general",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      tooltip: "Enter a comma seperated list of inpirational words",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    screen_sets: [
      { name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 },
    ],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25,
    },
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"],
    },
    {
      title: "SAVE",
      action: "just_save",
    },
  ],
};
