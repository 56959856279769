import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  modal: {
    "& .ant-modal-content": {
      borderRadius: "0.25rem",
      "& .ant-modal-footer": {
        height: "4.25rem",
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
        "& button": {
          textTransform: 'uppercase'
        }
      }
    }
  },
  modalFontWeight: {
    fontWeight: "600"
  }
});

export default useStyles;
