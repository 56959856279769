import {
  TANGENT_BODY_LINE_HEIGHT,
  TANGENT_BODY_MARGIN_TOP,
  TANGENT_HEADING_LINE_HEIGHT,
  TANGENT_HEADING_MARGIN_TOP,
  TANGENT_RICH_TEXT_PADDING_BOTTOM,
  TANGENT_SUBHEAD_LINE_HEIGHT,
  TANGENT_SUBHEAD_MARGIN_TOP,
} from "./TangentConstants";

const getTopQrStyle = (mirrorContent, mirrorQR) => {
  if ((!mirrorContent && !mirrorQR) || (mirrorContent && mirrorQR)) {
    return null
  } else if (!mirrorContent && mirrorQR) {
    return "0px"
  }
}

const getBottomQrStyle = (mirrorContent, mirrorQR) => {
  if ((!mirrorContent && !mirrorQR) || (!mirrorContent && mirrorQR) || (mirrorContent && mirrorQR)) {
    return "0px"
  }
}

const getStorySingleImageTextPaddingBottom = (props) => {
  if (props.qRLogoWidget === 'none') {
    return 0.035 * props.containerHeight;
  }
  if (props.mirrorContent) {
    return (0.035 * props.containerHeight) + (props.fontSize * 25);
  }
  return 0.035 * props.containerHeight
}

const styles = {
  componentContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
  },
  componentOverlay: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    backgroundColor: (props) => props.backgroundOverlay,
  },
  donorListContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    zIndex: 0,
    "& .backgroundOverlay": {
      position: "absolute",
      width: (props) => props.containerWidth,
      height: (props) => props.containerHeight,
      backgroundColor: (props) => props.backgroundColor,
      zIndex: 0,
    },
  },
  noPadding: {
    padding: 0,
  },
  sheets: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    flex: 1,
    flexDirection: (props) =>
      !props.mirrorContent ? "column" : "column-reverse",
    "& .sheet1": {
      height: "50%",
    },
    "& .qrCode": {
      position: "relative",
      "& .qrCodeWrapper": {
        top: (props) => getTopQrStyle(props.mirrorContent, props.mirrorQr),
        bottom: (props) => getBottomQrStyle(props.mirrorContent, props.mirrorQr),
      },
    },
    "& .sheet2": {
      height: "50%",
    },
  },
  donorListContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: (props) => 0.166 * props.containerHeight,
    paddingBottom: (props) => 0.166 * props.containerHeight,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly",
    "& .workspaceContainer": {
      height: "100%",
      color: (props) => props.fontColorDefault,
      paddingTop: (props) => (100 / 1080) * props.containerWidth,
      paddingBottom: (props) => (100 / 1080) * props.containerWidth,
      paddingLeft: (props) => (100 / 1080) * props.containerWidth,
      paddingRight: (props) => (100 / 1080) * props.containerWidth,
      backgroundColor: (props) => props.donorListBackgroundColor,
      overflowX: "hidden",
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      "& .header": {
        width: "100%",
        "& .headerText": {
          padding: 0,
          textTransform: 'uppercase',
        },
      },
      "& .description": {
        "& .descriptionText": {
          marginTop: (props) =>
            props.standardSizing(props.descriptionTop, props.containerHeight),
          marginBottom: (props) => 0.01 * props.containerHeight,
          padding: 0,
          whiteSpace: "break-spaces",
        },
      },
      "& .categoryText": {
        padding: 0,
        marginTop: (props) => 0.01 * props.containerHeight,
        textTransform: 'uppercase',
      },
      "& .horizontalRule": {
        backgroundColor: (props) => props.horizontalRuleColor,
        border: "0 none",
        color: (props) => props.horizontalRuleColor,
        height: (props) => props.horizontalRuleHeight,
      },
      "& .footer": {
        "& .footerText": {
          whiteSpace: "break-spaces",
        },
      },
    },
  },
  storyWithMedia: {
    position: "absolute",
    backgroundColor: (props) => props.backgroundColor,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .sheet1": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    "& .sectionPadding": {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      padding: "7.5em 7.5em 0 7.5em",
      "& .sectionHeader": {
        display: "flex",
        overflow: "hidden",
        width: "100%",
        marginBottom: "1.125em",
        flexDirection: "column",
        "& .tagline": {
          lineHeight: "1.15",
          padding: 0,
          marginBottom: "1.125em",
        },
        "& .header": {
          lineHeight: "1.15",
          padding: 0,
          marginBottom: "2.5em",
        },
      },
    },
    "& .sectionBody": {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      marginBottom: "2em",
      flexDirection: "column",
      padding: "2em 7.5em 2em 7.5em",
      "& .body": {
        lineHeight: "1.15",
        padding: 0,
      },
    },
  },
  storyWithSingleImageContainer: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    zIndex: 0,
  },
  storySingleImageOverlay: {
    position: "absolute",
    width: (props) => props.containerWidth,
    height: (props) => props.containerHeight,
    backgroundColor: (props) => props.backgroundColorOverlay,
    zIndex: 0,
  },
  storySingleImageContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
    "& .image": {
      height: (props) => (!props.mirrorContent ? "100%" : "50%"),
      "& .imageWrapper": {
        width: "100%",
        height: "100%",
      },
    },
    "& .text": {
      position: "relative",
      height: "100%",
      color: "#000000",
      "& .textWrapper": {
        position: "absolute",
        bottom: (props) => (!props.mirrorContent ? null : "0"),
        width: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
        maxHeight: "100%",
        paddingLeft: (props) => 0.12 * props.containerWidth,
        paddingRight: (props) => 0.12 * props.containerWidth,
        paddingTop: (props) => TANGENT_HEADING_MARGIN_TOP * props.containerHeight,
        paddingBottom: (props) => getStorySingleImageTextPaddingBottom(props),
        backgroundColor: (props) => props.storyBackgroundColor,
        "& .headline": {
          padding: 0,
          lineHeight: TANGENT_HEADING_LINE_HEIGHT,
          overflowX: "hidden",
          overflowY: "hidden",
          "& .headlineText": {
            lineHeight: "1.2",
            padding: 0,
          },
        },
        "& .subhead": {
          marginTop: (props) =>
            TANGENT_SUBHEAD_MARGIN_TOP * props.containerHeight,
          "& .subheadTextWrapper": {
            padding: 0,
            overflowX: "hidden",
            overflowY: "hidden",
            lineHeight: TANGENT_SUBHEAD_LINE_HEIGHT,
            "& .subheadText": {
              lineHeight: "1.2",
              padding: 0,
            },
          },
        },
        "& .body": {
          marginTop: (props) => TANGENT_BODY_MARGIN_TOP * props.containerHeight,
          "& .bodyTextWrapper": {
            padding: 0,
            lineHeight: TANGENT_BODY_LINE_HEIGHT,
            overflowX: "hidden",
            overflowY: "hidden",
            paddingBottom: (props) =>
              TANGENT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight,
            "& .bodyText": {
              lineHeight: "1.2",
              padding: 0,
            },
          },
        },
      },
    },
  },
  mediaWithQuote: {
    width: "100%",
    height: (props) => props.containerHeight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: (props) => props.backgroundColor,
    "& .imageWrapper": {
      height: "100%",
      width: "100%",
    },
    "& .quoteWrapper": {
      width: "100%",
      padding: "20em 8em",
      backgroundColor: (props) => props.quoteBackgroundColor,
    },
    position: 'absolute'
  },
  quoteContainer: {
    fontsize: (props) => props.baseFontSize,
    display: "flex",
    overflow: "hidden",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    "& .quoteHeading": {
      lineHeight: 1.3,
      padding: 0,
    },
  },
};

export default styles;
