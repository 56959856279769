import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React, {
  createRef,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { IFolder } from "../../../shared-global/interfaces/models/folder.interface";
import { loopIndex } from "../../../shared-global/utils/general";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData, sortDonors } from "../../utils/commonDataUtils";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TRANSITION_CLASS } from "../../../shared-global/enums/ui-enums";
import transitions from "../../../shared-global/constants/transitions";

interface IDonor {
  name: string;
  show?: boolean;
  sorting_name: string;
  style: CSSProperties;
  actual_index?: number;
}

interface IScreen {
  style: {
    position: string;
    left: number;
    top: number;
    width: number;
    height: number;
  };
}
interface IRegion {
  donors: IDonor[];
  style: CSSProperties;
}

enum SORT_TYPE {
  COLUMN_TRANSVERSE_SCREEN = "COLUMN_TO_COLUMN_TO_SCREEN_BOTTOM",
  COLUMN_TO_COLUMN = "COLUMN_TO_COLUMN"
}

// Sort Type Explanation:
/*

  COLUMN_TRANSVERSE_SCREEN
    --------------------------------------------------------
    | a  f  k  | p  .  .  | .  .  .  | .  .  .  | .  .  .  |
    | b  g  l  | q  .  .  | .  .  .  | .  .  .  | .  .  .  |
    |__________|__________|_.__.__.__|_.__.__.__|_.__.__.__|
    | c  h  m  | r  .  .  | .  .  .  | .  .  .  | .  .  .  |
    | d  i  n  | s  .  .  | .  .  .  | .  .  .  | .  .  .  |
    | e  j  o  | t  .  .  | .  .  .  | .  .  .  | .  .  .  |
    --------------------------------------------------------

  COLUMN_TO_COLUMN
    --------------------------------------------------------
    | a  c  e  | p  r  .  | .  .  .  | .  .  .  | .  .  .  |
    | b  d  f  | q  s  .  | .  .  .  | .  .  .  | .  .  .  |
    |__________|__________|_.__.__.__|_.__.__.__|_.__.__.__|
    | g  j  m  | .  .  .  | .  .  .  | .  .  .  | .  .  .  |
    | h  k  n  | .  .  .  | .  .  .  | .  .  .  | .  .  .  |
    | i  l  o  | .  .  .  | .  .  .  | .  .  .  | .  .  .  |
    --------------------------------------------------------

*/

const DonorListWithRegions2108: React.FC<IDisplayComponentProps> = (props) => {
  let _highestDonorFound = null;
  let _widestDonorFound = null;
  let _narrowestDonorFound = null;

  const textWrapperRef = useRef<HTMLDivElement>(null);
  const [calledVisibleNotificationCb, setCalledVisibleNotificationCb] =
    useState(false);
  const [sortedDonors, setSortedDonors] = useState<IDonor[]>([]);
  const [finishedPrerender, setFinishedPrerender] = useState(false);
  const [regionWidth, setRegionWidth] = useState(null);
  const [regionHeight, setRegionHeight] = useState(null);
  const [screens, setScreens] = useState<IScreen[]>([]);
  const [donorRefs, setDonorRefs] = useState([]);
  const [currentDonorIndex, setCurrentDonorIndex] = useState(0);

  const timeBetweenCycles = useMemo(() => {
    if (props.data?.componentSpecificData.donor_transition) {
      const foundTransition = transitions.find(t => t.value === props.data?.componentSpecificData.donor_transition);
      if (foundTransition) {
        return foundTransition.fade_out_duration;
      }
    }
    return 500;
  }, [props.data?.componentSpecificData.donor_transition]);

  const [regions, _setRegions] = useState<IRegion[]>([]);
  const regionsRef = useRef(regions);
  const setRegions = (data) => {
    regionsRef.current = data;
    _setRegions(data);
  };
  const [regionCycles, _setRegionCycles] = useState<IRegion[][]>([]);
  const regionCyclesRef = useRef(regionCycles);
  const setRegionCycles = (data) => {
    regionCyclesRef.current = data;
    _setRegionCycles(data);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [currentRegionCycle, _setCurrentRegionCycle] = useState(0);
  const currentRegionCycleRef = useRef(currentRegionCycle);
  const setCurrentRegionCycle = (data) => {
    currentRegionCycleRef.current = data;
    _setCurrentRegionCycle(data);
  };
  const epochMountedOn = useMemo(() => new Date().getTime(), []);

  const [firstRegionLooped, _setFirstRegionLooped] = useState(false);
  const firstRegionLoopedRef = useRef(firstRegionLooped);
  const setFirstRegionLooped = (data) => {
    firstRegionLoopedRef.current = data;
    _setFirstRegionLooped(data);
  };

  const [waveTime, _setWaveTime] = useState(0);
  const waveTimeRef = useRef(waveTime);
  const setWaveTime = (data) => {
    waveTimeRef.current = data;
    _setWaveTime(data);
  };

  const [loopWaveCycleTimeoutHandler, _setLoopWaveCycleTimeoutHandler] =
    useState(null);
  const loopWaveCycleTimeoutHandlerRef = useRef(loopWaveCycleTimeoutHandler);
  const setLoopWaveCycleTimeoutHandler = (data) => {
    loopWaveCycleTimeoutHandlerRef.current = data;
    _setLoopWaveCycleTimeoutHandler(data);
  };

  const [isTextVisible, _setIsTextVisible] = useState(0);
  const isTextVisibleRef = useRef(isTextVisible);
  const setIsTextVisible = (data) => {
    isTextVisibleRef.current = data;
    _setIsTextVisible(data);
  };

  // Folder Settings state vars.
  const [baseFolder, setBaseFolder] = useState<IFolder>(null);

  const headerStyle = useMemo(() => {
    return get(baseFolder, "fields.header_style", {});
  }, [baseFolder]);

  const descriptionStyle = useMemo(() => {
    return get(baseFolder, "fields.description_style", {});
  }, [baseFolder]);

  const donor_transition = useMemo(() => {
    return get(props, "data.componentSpecificData.donor_transition", TRANSITION_CLASS.TRASITION_FADE);
  }, [baseFolder]);

  const horizontalScreens = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.horizontal_screens",
      get(baseFolder, "fields.horizontal_screens", 3)
    );
  }, [props.data, baseFolder]);

  const verticalScreens = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.vertical_screens",
      get(baseFolder, "fields.vertical_screens", 2)
    );
  }, [props.data, baseFolder]);

  const columns = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.columns",
      get(baseFolder, "fields.columns", 1)
    );
  }, [props.data, baseFolder]);

  const donorVerticalSeparation = useMemo(() => {
    return (
      (get(
        props,
        "data.componentSpecificData.donor_vertical_separation",
        get(baseFolder, "fields.donor_vertical_separation", 0)
      ) /
        100) *
      props.containerHeight
    );
  }, [props.data, baseFolder, props.containerHeight]);

  const cycleTime = useMemo(() => {
    return (
      get(props, "cycle_time", get(baseFolder, "fields.cycle_time", 30)) * 1000
    );
  }, [props.data, baseFolder]);

  const timeToFadeDonorsIn = useMemo(() => {
    return (
      get(
        props,
        "data.componentSpecificData.time_to_fade_donors_in",
        get(baseFolder, "fields.time_to_fade_donors_in", 2)
      ) * 1000
    );
  }, [props.data, baseFolder]);

  const timeToFadeDonorsOut = timeToFadeDonorsIn;

  const sortType = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.sort_type",
      get(baseFolder, "fields.sort_type", SORT_TYPE.COLUMN_TRANSVERSE_SCREEN)
    );
  }, [props.data, baseFolder]);

  const screenLeftRightPadding = useMemo(() => {
    const pad = get(
      props,
      "data.componentSpecificData.screen_left_and_right_padding",
      get(baseFolder, "fields.screen_left_and_right_padding", 0)
    );
    if (pad > 40) {
      return 40;
    }
    return pad;
  }, [props.data, baseFolder]);

  const screenTopBottomPadding = useMemo(() => {
    const pad = get(
      props,
      "data.componentSpecificData.screen_top_and_bottom_padding",
      get(baseFolder, "fields.screen_top_and_bottom_padding", 0)
    );
    if (pad > 40) {
      return 40;
    }
    return pad;
  }, [props.data, baseFolder]);

  const donorNameStyle = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.donor_name_style",
      get(baseFolder, "fields.donor_name_style", null)
    );
  }, [props.data, baseFolder]);

  const donorNameField = useMemo(() => {
    return get(props, "data.componentSpecificData.donor_name_field", "name");
  }, [props.data]);

  const descriptionHorizontalRuleThickness = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.description_horizontal_rule_thickness",
      get(baseFolder, "fields.description_horizontal_rule_thickness", 0)
    );
  }, [props.data, baseFolder]);

  const donorListMarginRight = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.donor_list_margin_right",
      get(baseFolder, "fields.donor_list_margin_right", 0)
    );
  }, [props.data, baseFolder]);

  const donorListMarginLeft = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.donor_list_margin_left",
      get(baseFolder, "fields.donor_list_margin_left", 0)
    );
  }, [props.data, baseFolder]);

  const donorListColumnMarginRight = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.donor_list_column_margin_right",
      get(baseFolder, "fields.donor_list_column_margin_right", 0)
    );
  }, [props.data, baseFolder]);

  const donorListMarginTop = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.donor_list_margin_top",
      get(baseFolder, "fields.donor_list_margin_top", 0)
    );
  }, [props.data, baseFolder]);

  const descriptionTop = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.description_top",
      get(baseFolder, "fields.description_top", 0)
    );
  }, [props.data, baseFolder]);

  const doNotRepeatDonors = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.do_not_repeat_donors",
      get(baseFolder, "fields.do_not_repeat_donors", false)
    );
  }, [props.data, baseFolder]);

  // This is the formula first implemented on the Naples theme
  const useNewEmFormat = useMemo(() => {
    return get(
      props,
      "data.componentSpecificData.use_em_new_format",
      get(baseFolder, "fields.use_em_new_format", false)
    );
  }, [props.data, baseFolder]);

  const innerNamesWidth = () => {
    return 100 - donorListMarginLeft - donorListMarginRight;
  };

  const setupDonors = () => {
    const donorFolders = getChildrenData(
      props.data,
      props.data.base_folder,
      true
    ).folders;
    const newDonors = donorFolders.map((f) => {
      let name = f.name;
      if (donorNameField !== "name" && f.fields[donorNameField]) {
        name = f.fields[donorNameField];
      }
      return {
        name,
        sorting_name: f.fields.sorting_order ?? f.name,
        style: {
          fontWeight: get(donorNameStyle, "bold", false)
            ? ("bold" as "bold")
            : ("normal" as "normal"),
          textDecoration: get(donorNameStyle, "underline", false)
            ? "underline"
            : "normal",
          fontStyle: get(donorNameStyle, "italic", false) ? "italic" : "normal",
          fontSize: get(donorNameStyle, "fontSize", 10) + "em",
          color: get(donorNameStyle, "color", "#000000"),
          lineHeight: get(donorNameStyle, "lineHeight", 1),
          fontFamily: get(donorNameStyle, "font", "Roboto"),
          whiteSpace: "pre-wrap" as "pre-wrap",
          textAlign: get(donorNameStyle, "textAlign", "left"),
        }
      };
    });

    const sorting_method = get(props.baseFolder, "fields.sorting_options", "rank");

    const newSortedDonors = sortDonors(sorting_method, newDonors);
    setSortedDonors(newSortedDonors);
  };

  const calculateRegionsDimension = () => {
    // Effective region width is total width minus the padding passed in the props for all the regions.
    // Same applies to effective region height.
    const calculatedRegionWidth = props.containerWidth / horizontalScreens;
    const calculatedRegionHeight = props.containerHeight / verticalScreens;

    let effectiveRegionWidth =
      calculatedRegionWidth -
      ((calculatedRegionWidth * screenLeftRightPadding) / 100) * 2;
    const effectiveRegionHeight =
      calculatedRegionHeight -
      ((calculatedRegionHeight * screenTopBottomPadding) / 100) * 2;


    effectiveRegionWidth = effectiveRegionWidth * (innerNamesWidth() / 100)
    setRegionWidth(effectiveRegionWidth);
    setRegionHeight(effectiveRegionHeight);
  };

  const setupScreenRegions = () => {
    if (baseFolder?.fields.header && !textWrapperRef.current) {
      setTimeout(() => {
        setupScreenRegions();
      }, 100);
      return;
    }

    /*
    I.E: Example of a 5 horizontal screens and 2 vertical screens
    |------------- props.container_width-------------------|
    -------------------------------------------------------- -
    |          |          |          |          |          | |
    |   [0,0]  |   [1,0]  |   [2,0]  |   [3,0]  |  [4,0]   | |
    |__________|__________|__________|__________|__________| props.container_height
    |          |          |          |          |          | |
    |   [0,1]  |   [1,1]  |   [2,1]  |   [3,1]  |  [4,1]   | |
    |          |          |          |          |          | |
    -------------------------------------------------------- -
    each element of the screen is a region. [0,0] is a region for instance.
    */

    const calculatedRegionWidth = props.containerWidth / horizontalScreens;
    const calculatedRegionHeight = props.containerHeight / verticalScreens;
    const totalScreens = horizontalScreens * verticalScreens;

    const arrayOfScreens: IScreen[] = [];
    for (let i = 0; i < totalScreens; i++) {
      // Vertical index or position is calculated with the module operator (%) instead of
      // looping through another set of vertical screens.
      // Vertical index corresponds to y in a [x,y] screen position.
      const horizontalIndex = Math.floor(i / verticalScreens);
      const verticalIndex = i % verticalScreens;

      // Here we check if the current screen we are checking is in the disabledScreens
      // array. I.E: Checking if current screen [0,1] is in the array [[0,1]] of disabled screens
      // Screen location is [x,y]
      const disabledScreensArr = get(
        props,
        "data.componentSpecificData.disabled_screens",
        baseFolder.fields.disabled_screens
      );
      if (disabledScreensArr) {
        // Parse the string '[0,1], [1,1]' into an array that looks like
        // [[0,1], [1,1]]
        const parsedDisabledScreensCoordinates = disabledScreensArr
          .split(",")
          .map((s) =>
            Number(s.trim().replace("[", "").replace("]", "").split(","))
          );
        let disabledScreens = [];
        let currentDisabledScreensIndex = 0;
        for (let i = 0; i < parsedDisabledScreensCoordinates.length; i += 1) {
          if (!disabledScreens[currentDisabledScreensIndex]) {
            disabledScreens.push([parsedDisabledScreensCoordinates[i]]);
          } else {
            disabledScreens[currentDisabledScreensIndex].push(
              parsedDisabledScreensCoordinates[i]
            );
            currentDisabledScreensIndex += 1;
          }
        }
        const foundDisabledScreen = disabledScreens.find((s) =>
          isEqual(s, [horizontalIndex, verticalIndex])
        );
        if (foundDisabledScreen) {
          continue;
        }
      }

      let top =
        (i % verticalScreens) * calculatedRegionHeight +
        (calculatedRegionHeight * screenTopBottomPadding) / 100;
      let left =
        Math.floor(i / verticalScreens) * calculatedRegionWidth +
        (calculatedRegionWidth * screenLeftRightPadding) / 100;

      // Check if we have a header and reduce the height of all the regions in y = 0;
      let textHeight = 0;
      let screenHeight = regionHeight;

      if (
        baseFolder?.fields.header &&
        textWrapperRef.current &&
        verticalIndex === 0
      ) {
        const textClientRect = textWrapperRef.current.getBoundingClientRect();
        textHeight = textClientRect.height;
        top += textHeight;
        screenHeight = regionHeight - textHeight;
      }

      // Margins correction
      let newRegionWidth = regionWidth;
      left += (donorListMarginLeft / 100) * regionWidth;
      if (verticalIndex === 0) {
        // Top Margin
        top += (donorListMarginTop / 100) * regionHeight;
        screenHeight = screenHeight - (donorListMarginTop / 100) * regionHeight;
      }

      arrayOfScreens.push({
        style: {
          position: "absolute",
          left: left,
          top: top,
          width: newRegionWidth,
          height: screenHeight
        }
      });
    }

    setScreens(arrayOfScreens);
  };

  const setupScreenRegionsAndDonors = (numberOfCyclesToAdd = 0) => {
    let newRegions: IRegion[] = [];
    let currentColumn = 1;
    let currentHeight = 0;
    let screenIndex = 0;
    let localCurrentDonorIndex = currentDonorIndex;
    let newRegionsCycle: IRegion[][] = [...regionCyclesRef.current];
    let didCycleGoAroundAllDonors = false;
    const donorListWidthPercentage = innerNamesWidth();

    while (screenIndex < screens.length) {
      const donor = sortedDonors[localCurrentDonorIndex];
      const marginRight =
        (donorListColumnMarginRight / 100) *
        Number(screens[screenIndex].style.width);
      const missingMarginAdder = marginRight / columns - 1;
      const donorListWidth =
        (donorListWidthPercentage / 100) *
        Number(screens[screenIndex].style.width);
      const columnWidth =
        donorListWidth / columns - marginRight + missingMarginAdder;

      if (!newRegions[screenIndex]) {
        newRegions[screenIndex] = {
          style: screens[screenIndex].style as CSSProperties,
          donors: []
        };
      }

      if (screens[screenIndex].style.height < Number(donor.style.height)) {
        // something wrong, screen height is smaller than a single donor
        break;
      }

      if (
        Number(donor.style.height) + currentHeight <
        screens[screenIndex].style.height
      ) {
        donor.style.left = screens[screenIndex].style.left;
        donor.style.top = screens[screenIndex].style.top + currentHeight;
        newRegions[screenIndex].donors.push({
          ...donor,
          style: {
            ...donor.style,
            position: "absolute",
            whiteSpace: "pre-wrap",
            top: currentHeight,
            width: columnWidth,
            maxWidth: columnWidth,
            left: (currentColumn - 1) * (columnWidth + marginRight)
          }
        });
        currentHeight += Number(donor.style.height) + donorVerticalSeparation;

        localCurrentDonorIndex = loopIndex(
          localCurrentDonorIndex,
          0,
          sortedDonors.length - 1,
          "forward"
        );
        if (localCurrentDonorIndex === 0) {
          if (doNotRepeatDonors) {
            screenIndex = screens.length
          }
          didCycleGoAroundAllDonors = true;
        }
      } else {
        if (sortType === SORT_TYPE.COLUMN_TRANSVERSE_SCREEN) {
          if (currentColumn < columns) {
            if ((screenIndex + 1) % verticalScreens !== 0) {
              // Jump to screen below
              screenIndex += 1;
            } else {
              // Jump back to first screen in the screen column array.
              currentColumn += 1;
              screenIndex += 1 - verticalScreens;
            }
          } else {
            screenIndex += 1;
            if (
              currentColumn === columns &&
              screenIndex % verticalScreens === 0
            ) {
              currentColumn = 1;
            }
          }
        } else {
          if (currentColumn < columns) {
            currentColumn += 1;
          } else {
            screenIndex += 1;
            currentColumn = 1
          }
        }
        currentHeight = 0;
      }

      if (screenIndex >= screens.length) {
        newRegionsCycle.push(newRegions);
        if (
          (!didCycleGoAroundAllDonors && numberOfCyclesToAdd === 0) ||
          (newRegionsCycle.length > 0 &&
            newRegionsCycle.length < numberOfCyclesToAdd)
        ) {
          screenIndex = 0;
          currentColumn = 1;
          currentHeight = 0;
          newRegions = [];
        }
      }
    }

    setCurrentDonorIndex(localCurrentDonorIndex);
    if (props.data.componentSpecificData.setScreensCovered) {
      props.data.componentSpecificData.setScreensCovered(newRegionsCycle)
    }
    setRegionCycles(newRegionsCycle);
  };

  const preRenderDonors = () => {
    const donorListWidthPercentage = innerNamesWidth();
    const renderedDonors = sortedDonors.map((donor, i) => {
      const marginRight =
        (donorListColumnMarginRight / 100) *
        Number(regionWidth);
      const missingMarginAdder = marginRight / columns - 1;
      const donorListWidth =
        (donorListWidthPercentage / 100) *
        Number(regionWidth);
      const columnWidth =
        donorListWidth / columns - marginRight + missingMarginAdder;
      const donorStyle: CSSProperties = {
        ...donor.style,
        opacity: 0,
        position: "absolute",
        whiteSpace: "pre-wrap",
        maxWidth: columnWidth
      };
      return (
        <span
          key={i}
          ref={(el) => (donorRefs[i] = el)}
          style={{ ...donorStyle }}
        >
          {donor.name}
        </span>
      );
    });

    return renderedDonors;
  };

  const loopWaveCycle = (timeToCheck = 0, fadeIn = false, manualCycling = false, doEndOfPlay = false) => {
    clearTimeout(loopWaveCycleTimeoutHandlerRef.current);
    const newLoopWaveCycleTimeoutHandler = setTimeout(() => {
      let shouldTriggerEndOfPlay = false;
      if (
        ((currentRegionCycleRef.current === 0 &&
        firstRegionLoopedRef.current) ||
        doEndOfPlay) &&
        props.handleEndOfPlay
      ) {
        props.handleEndOfPlay();
        return;
      }
      if (timeToCheck === 0) {
        // Calc time left to fade first set of donors in.
        timeToCheck =
          timeToFadeDonorsIn - (new Date().getTime() - epochMountedOn) / 1000;
        setRegions(regionCyclesRef.current[currentRegionCycleRef.current]);
        loopWaveCycle(timeToCheck, !fadeIn);
        return;
      } else if (fadeIn) {
        setIsVisible(fadeIn);
        if (!isTextVisibleRef.current) {
          setIsTextVisible(true);
        }
        timeToCheck = waveTimeRef.current;
        if (waveTimeRef.current === 0) {
          const timeLeft = cycleTime - (new Date().getTime() - epochMountedOn);
          timeToCheck =
            (timeLeft -
              timeBetweenCycles * regionCyclesRef.current.length -
              timeToFadeDonorsOut) /
            regionCyclesRef.current.length;
          setWaveTime(timeToCheck);
        }
      } else {
        if (!firstRegionLoopedRef.current) {
          setFirstRegionLooped(true);
        }
        const nextIndex = loopIndex(
          currentRegionCycleRef.current,
          0,
          regionCyclesRef.current.length - 1,
          "forward"
        );
        timeToCheck = timeBetweenCycles;
        if (nextIndex === 0 && !manualCycling) {
          timeToCheck = cycleTime - (new Date().getTime() - epochMountedOn);
        }
        if (
          nextIndex === 0 &&
          firstRegionLoopedRef.current
        ) {
          shouldTriggerEndOfPlay = true;
          timeToCheck = 0
        } else {
          setIsVisible(fadeIn);
          setRegions(regionCyclesRef.current[nextIndex]);
          currentRegionCycleRef.current = nextIndex;
          setCurrentRegionCycle(nextIndex);
        }
        if (props.data.componentSpecificData.setCurrentScreenIndex) {
          props.data.componentSpecificData.setCurrentScreenIndex(nextIndex);
        }
      }

      loopWaveCycle(timeToCheck, !fadeIn, false, shouldTriggerEndOfPlay);
    }, timeToCheck);
    setLoopWaveCycleTimeoutHandler(newLoopWaveCycleTimeoutHandler);
  };

  const handleKeyDown = useCallback((event) => {
    const cycleForwardKey = get(
      props,
      "data.componentSpecificData.cycle_forward_key",
      "ArrowRight"
    );
    switch (event.key) {
      case cycleForwardKey:
        clearTimeout(loopWaveCycleTimeoutHandlerRef.current);
        loopWaveCycle(1, false, true);
        break;
      default:
        break;
    }
  }, []);

  // 1st Effect
  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);
    setBaseFolder(foundBaseFolder);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      clearTimeout(loopWaveCycleTimeoutHandlerRef.current);
    };
  }, []);

  // 2nd Effect
  useEffect(() => {
    if (baseFolder) {
      calculateRegionsDimension();
      setupDonors();
    }
  }, [baseFolder]);

  // 3rd effect
  useEffect(() => {
    if (sortedDonors && sortedDonors.length) {
      // We create empty ref for each donor in the array.
      if (donorRefs.length !== sortedDonors.length) {
        Array.from(Array(sortedDonors.length).keys()).forEach(
          (_, i) => donorRefs[i] || createRef()
        );
      }

      // The reason we set a timeout is so we give enough time for donors to
      // be prerendered.
      const donorRefsTimeoutHandler = setTimeout(() => {
        if (donorRefs.length > 0 && !finishedPrerender) {
          for (let index in donorRefs) {
            const ref = donorRefs[index];
            const dimensions = ref.getBoundingClientRect();
            sortedDonors[index].style.height = dimensions.height;
            sortedDonors[index].style.width = dimensions.width;
            _highestDonorFound =
              sortedDonors[index].style.height > _highestDonorFound
                ? sortedDonors[index].style.height
                : _highestDonorFound;
            _widestDonorFound =
              sortedDonors[index].style.width > _widestDonorFound
                ? sortedDonors[index].style.width
                : _widestDonorFound;
            _narrowestDonorFound =
              sortedDonors[index].style.height > _narrowestDonorFound
                ? sortedDonors[index].style.height
                : _narrowestDonorFound;
          }
        }
        setupScreenRegions();
        setFinishedPrerender(true);
      }, sortedDonors.length);

      return () => {
        clearTimeout(donorRefsTimeoutHandler);
      };
    }
  }, [sortedDonors]);

  // 4th effect
  useEffect(() => {
    if (finishedPrerender && screens.length && regionCyclesRef.current.length === 0) {
      setupScreenRegionsAndDonors();
    }
  }, [finishedPrerender, screens]);

  // 5th effect
  useEffect(() => {
    if (regionCyclesRef.current.length > 0) {
      const setCurrentNumberOfCycles = get(
        props,
        "data.componentSpecificData.set_number_of_cycles",
        null
      );
      const adjustedNumberOfCycles = get(
        props,
        "data.componentSpecificData.number_of_cycles",
        null
      );
      if (adjustedNumberOfCycles === null && setCurrentNumberOfCycles) {
        setCurrentNumberOfCycles(regionCyclesRef.current.length);
      } else {
        loopWaveCycle();
      }
    }
  }, [regionCyclesRef.current]);

  // 6th effect
  useEffect(() => {
    const adjustedNumberOfCycles = get(
      props,
      "data.componentSpecificData.number_of_cycles",
      null
    );
    if (adjustedNumberOfCycles !== null) {
      if (adjustedNumberOfCycles !== regionCyclesRef.current.length) {
        setupScreenRegionsAndDonors(adjustedNumberOfCycles);
      } else {
        loopWaveCycle();
      }
    }
  }, [props.data]);

  // 7th effect
  useEffect(() => {
    if (
      isVisible &&
      !calledVisibleNotificationCb &&
      props.data.componentSpecificData.visible_notification_callback
    ) {
      setCalledVisibleNotificationCb(true);
      props.data.componentSpecificData.visible_notification_callback();
    }
  }, [isVisible]);

  if (!finishedPrerender && sortedDonors && sortedDonors.length) {
    if (useNewEmFormat) {
      return (
        <div>
          {preRenderDonors()}
        </div>
      );
    }
    return (
      <div style={{ fontSize: props.containerHeight / 1000 }}>
        {preRenderDonors()}
      </div>
    );
  }

  const cssTransitionKey = isVisible ? "true" : "false";

  const firstRegionLeft = get(regionsRef.current, "[0].style.left", 0);

  return (
    <div
      style={{
        fontSize: useNewEmFormat ? "inherit" : props.containerHeight / 1000,
        width: props.containerWidth,
        height: props.containerHeight
      }}
    >
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={5000}
          classNames={donor_transition}
        >
          <div key={cssTransitionKey}>
            {baseFolder?.fields.header && (
              <div
                ref={textWrapperRef}
                style={{
                  fontSize: useNewEmFormat ? "inherit" : props.containerHeight / 1000,
                  position: "absolute",
                  maxWidth: regionWidth,
                  top: (regionHeight * screenTopBottomPadding) / 100,
                  left: firstRegionLeft,
                  paddingBottom: props.containerHeight * 0.025
                }}
              >
                <div
                  style={{
                    ...headerStyle,
                    fontWeight: get(headerStyle, "bold", false)
                      ? "bold"
                      : "normal",
                    textDecoration: get(headerStyle, "underline", false)
                      ? "underline"
                      : "normal",
                    fontStyle: get(headerStyle, "italic", false)
                      ? "italic"
                      : "normal",
                    fontFamily: get(headerStyle, "font", "Roboto"),
                    fontSize: get(headerStyle, "fontSize", 10) + "em",
                    opacity: isTextVisibleRef.current ? 1 : 0
                  }}
                >
                  {baseFolder?.fields.header}
                </div>

                {/* Description Text */}
                {baseFolder?.fields.description && (
                  <div
                    style={{
                      ...descriptionStyle,
                      fontWeight: get(descriptionStyle, "bold", false)
                        ? "bold"
                        : "normal",
                      textDecoration: get(descriptionStyle, "underline", false)
                        ? "underline"
                        : "normal",
                      fontStyle: get(descriptionStyle, "italic", false)
                        ? "italic"
                        : "normal",
                      paddingTop: (descriptionTop / 100) * regionHeight,
                      fontFamily: get(descriptionStyle, "font", "Roboto"),
                      fontSize: get(descriptionStyle, "fontSize", 10) + "em",
                      opacity: isTextVisibleRef.current ? 1 : 0,
                      paddingBottom: props.containerHeight * 0.01
                    }}
                  >
                    {baseFolder?.fields.description}
                  </div>
                )}
                <div>
                  <hr
                    style={{
                      backgroundColor: "#1C1C1C",
                      border: "0 none",
                      color: "#1C1C1C",
                      height:
                        (descriptionHorizontalRuleThickness *
                          props.containerHeight) /
                        1000,
                      opacity: isTextVisibleRef.current ? 1 : 0,
                      width: regionWidth
                    }}
                  />
                </div>
              </div>
            )}
            {isVisible && (
              <>
                {regionsRef.current.map((r, ri) => {
                  return (
                    <div
                      key={`region-${ri}`}
                      style={{
                        ...r.style,
                        height:
                          Number(r.style.height) -
                          (donorListMarginTop * Number(r.style.height)) / 100
                      }}
                    >
                      {r.donors.map((d, di) => {
                        return (
                          <div key={`donor-${di}`} style={d.style}>
                            {d.name}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default DonorListWithRegions2108;
