import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {ReactComponent as DonorBiosIcon} from '../../icons/donor-bios.svg';
import { ReactComponent as TimelineIcon } from "../../icons/timeline-solid.svg";
import { ReactComponent as ClientIcon } from "../../icons/client.svg";
import { ReactComponent as ContentIcon } from "../../icons/content.svg";
import { ReactComponent as DisplaysIcon } from "../../icons/displays.svg";
import { ReactComponent as PresentationsIcon } from "../../icons/presentations.svg";
import { ReactComponent as LayoutsIcon } from "../../icons/layouts.svg";
import { ReactComponent as DonorListsIcon } from "../../icons/donor-lists.svg";
import { ReactComponent as StoriesIcon } from "../../icons/stories.svg";
import { ReactComponent as AttractLoopIcon } from "../../icons/attract-loop.svg";
import { ReactComponent as LayoutsSelectedIcon } from "../../icons/layouts-selected.svg";
import { ReactComponent as DonorListsSelectedIcon } from "../../icons/donor-lists-selected.svg";
import { ReactComponent as StoriesSelectedIcon } from "../../icons/stories-selected.svg";
import { ReactComponent as AttractLoopSelectedIcon } from "../../icons/attract-loop-selected.svg";
import { ReactComponent as ClientSelectedIcon } from "../../icons/client-selected.svg";
import { ReactComponent as DisplaysSelectedIcon } from "../../icons/displays-selected.svg";
import { ReactComponent as PresentationsSelectedIcon } from "../../icons/presentations-selected.svg";
import DynamicSVG from "../../icons/DynamicSVG";
import { PDG_COLORS } from "../../shared-global/enums/ui-enums";

interface TreeItemIconProps {
  config: any;
  isSelected: boolean;
  context: string;
}

export default (props: TreeItemIconProps) => {
  if (props.config) {
    switch (props.config.icon_type) {
      case "fontawesome":
        return (
          <span>
            <FontAwesomeIcon
              className={props.config.options.class_name}
              icon={props.config.options.icon}
            />{" "}
          </span>
        );
      case "raw":
        return <span>{props.config.options.value}</span>;
      case "custom-svg":
        switch (props.config.options.file_name) {
          case "donor-bios.svg":
            let iconColor = PDG_COLORS.MD_BLUE;
            if (props.isSelected) {
              iconColor = PDG_COLORS.WHITE;
            }
            return (
              <DynamicSVG
                fill={iconColor}
                name="donor-bios"
                context={props.context}
              />
            );
          case "timeline-solid.svg":
            return (
              <TimelineIcon
                style={{ width: 26, height: 26, fill: iconColor }}
              />
            );
          case "client.svg":
            return props.isSelected ?
              (<ClientSelectedIcon style={{ width: 18, height: 18 }}/>) :
              (<ClientIcon style={{ width: 18, height: 18 }}/>);
            case "content.svg":
            return (
              <ContentIcon
                style={{ width: 18, height: 18 }}
              />
            );
            case "displays.svg":
            return props.isSelected ?
              (<DisplaysSelectedIcon style={{ width: 18, height: 18 }}/>) :
              (<DisplaysIcon style={{ width: 18, height: 18 }}/>);
            case "presentations.svg":
            return props.isSelected ?
              (<PresentationsSelectedIcon style={{ width: 18, height: 18 }}/>) :
              (<PresentationsIcon style={{ width: 18, height: 18 }}/>);
            case "layouts.svg":
              return props.isSelected ?
                  (<LayoutsSelectedIcon style={{ width: 18, height: 18 }}/>) :
                  (<LayoutsIcon style={{ width: 18, height: 18 }}/>);
            case "stories.svg":
              return props.isSelected ?
                  (<StoriesSelectedIcon style={{ width: 18, height: 18 }}/>) :
                  (<StoriesIcon style={{ width: 18, height: 18 }}/>);
            case "attract-loop.svg":
              return props.isSelected ?
                  (<AttractLoopSelectedIcon style={{ width: 18, height: 18 }}/>) :
                  (<AttractLoopIcon style={{ width: 18, height: 18 }}/>);
            case "donor-lists.svg":
              return props.isSelected ?
                  (<DonorListsSelectedIcon style={{ width: 18, height: 18 }}/>) :
                  (<DonorListsIcon style={{ width: 18, height: 18 }}/>);
          default:
            return <span>I</span>;
        }
      default:
        return <span></span>;
    }
  }
  return null;
};
