import CryptoJS from 'crypto-js';
import piexifjs from 'piexifjs';
import _ from 'lodash';
import { arrayFromKeyedObject } from "../../../../shared/utils/collectionUtils";
import { getFileIcon, getFileType, getFileTypeGeneric, getURLFromCache } from '../../../../shared/utils/fileUtils';
import { getRandomStr } from '../../../../shared/utils/generalUtils';
import {
  MEDIA_FILE_INPUT_STATE_AVAILABLE, MEDIA_FILE_INPUT_STATE_CREATED, MEDIA_FILE_INPUT_STATE_FULL,
  MEDIA_FILE_INPUT_STATE_LOADED, MEDIA_FILE_INPUT_STATE_LOADING,
  MEDIA_FILE_INPUT_STATE_NONE, MEDIA_FILE_INPUT_STATE_REMOVED
} from './MediaFileInput';

const DYNAMIC = 'dynamic';
export const CHUNK_SIZE = 7168000;

export const mapDefaultValuesToArray = (defaultValue) => {
  let items = [];

  if (defaultValue) {
    let higherRank = 0;
    items = defaultValue.map((dv) => {
      if (dv.rank > higherRank) {
        higherRank = dv.rank;
      }
      return { rank: dv.rank, identifier: getRandomStr() };
    }).sort((a, b) => a.rank - b.rank);
  }

  return items;
};

export const addInputToItem = (items, aspect) => {
  const randomStr = getRandomStr();
  let rank = 0;
  if (items && items.length > 0) {
    const clonedItems = items.map((i) => i);
    const ranks = clonedItems.sort((a, b) => b.rank - a.rank).map((i) => i.rank);
    rank = ranks[0] + 1;
  }
  items.push({
    identifier: randomStr,
    rank,
    aspect,
  });

  return items;
};

/**
 * change order in items and defaultValues
 * also change rank number in both arrays
 */
export const moveItems = (oldIndex, newIndex, items, allValues) => {
  // change elements in items
  const itemsMoved = [...items];
  const tmpItemOrig = itemsMoved[oldIndex];
  itemsMoved[oldIndex] = itemsMoved[newIndex];
  itemsMoved[newIndex] = tmpItemOrig;

  // change rank
  itemsMoved[oldIndex].rank = oldIndex;
  itemsMoved[newIndex].rank = newIndex;

  // change elements in allValues
  const valuesMoved = [...allValues];
  const tmpValueOrig = valuesMoved[oldIndex];
  valuesMoved[oldIndex] = valuesMoved[newIndex];
  valuesMoved[newIndex] = tmpValueOrig;
  // change rank
  valuesMoved[oldIndex].rank = oldIndex;
  valuesMoved[newIndex].rank = newIndex;

  return {
    itemsMoved,
    valuesMoved,
  };
};

export const moveUpItems = (items, key) => {
  const index = items.findIndex((i) => i.identifier === key);

  if (index > 0) {
    items[index].rank--;
    items[index - 1].rank++;
    const tmpOrig = items[index];
    items[index] = items[index - 1];
    items[index - 1] = tmpOrig;
  }
  return items;
};

export const moveUpRankItems = (allValues, rank) => {
  const valueIndex = allValues.findIndex((v) => v.rank === rank);
  const valueIndexAbove = allValues.findIndex((v) => v.rank === rank - 1);
  if (valueIndex > -1) {
    allValues[valueIndex].rank--;
  }
  if (valueIndexAbove > -1) {
    allValues[valueIndexAbove].rank++;
  }

  return allValues;
};

export const moveDownItems = (items, key) => {
  const index = items.findIndex((i) => i.identifier === key);

  if (index <= items.length - 2) {
    items[index].rank++;
    items[index + 1].rank--;
    const tmpOrig = items[index];
    items[index] = items[index + 1];
    items[index + 1] = tmpOrig;
  }
  return items;
};

export const moveDownRankItems = (allValues, rank) => {
  const valueIndex = allValues.findIndex((v) => v.rank === rank);
  const valueIndexAbove = allValues.findIndex((v) => v.rank === rank + 1);
  if (valueIndex > -1) {
    allValues[valueIndex].rank++;
  }
  if (valueIndexAbove > -1) {
    allValues[valueIndexAbove].rank--;
  }

  return allValues;
};

export const removeItemFromItem = (olditems, identifier, type, allValues) => {
  const items = _.cloneDeep(olditems);
  const itemIndex = items.findIndex((i) => i.identifier === identifier);
  const item = items[itemIndex];
  const valueIndex = allValues.findIndex((v) => v.rank === item.rank);
  const value = allValues[valueIndex];
  let newItems = items;

  if (value) {
    newItems.splice(itemIndex, 1);
    newItems = items.sort((a, b) => a - b).map((element, i) => {
      element.rank = i;
      return element;
    });
  } else if (type === DYNAMIC) {
    newItems.splice(itemIndex, 1);
    newItems = items.sort((a, b) => a - b).map((element, i) => {
      element.rank = i;
      return element;
    });
  }

  return newItems;
};

export const removeItemFromDefaultValues = (allValues, identifier, type, items) => {
  let removedElements = [];
  const itemIndex = items.findIndex((i) => i.identifier === identifier);
  const item = items[itemIndex];
  const valueIndex = allValues.findIndex((v) => v.rank === item.rank);

  const value = allValues[valueIndex];
  if (value) {
    if (value.status === 'loaded') {
      allValues.splice(valueIndex, 1);
      allValues = allValues.sort((a, b) => a - b).map((element, j) => {
        element.rank = j;
        return element;
      });
    } else {
      value.status = 'removed';
    }
  }

  if (type === DYNAMIC) {
    removedElements = allValues
      .filter((v) => v.status === 'removed');
    allValues = allValues
      .filter((v) => v.status !== 'removed')
      .sort((a, b) => a - b).map((element, j) => {
        element.rank = j;
        return element;
      });

    // reorder remain elements and add removed elements (with status 'removed')
    allValues = [...allValues, ...removedElements].map((v, i) => {
      v.rank = i;
      return v;
    });
  }
  return allValues;
};

const cloneFileValue = (value) => {
  const newFile = new File([value], value.name, { type: value.type });
  return newFile;
};

const mergeFields = (obj1Outside, obj2Outside) => {
  const obj2 = _.cloneDeep(obj2Outside);
  const obj1 = _.cloneDeep(obj1Outside);
  const fileFieldValue = obj1;

  for (const field in obj2) {
    fileFieldValue[field] = obj2[field];
  }

  return fileFieldValue;
};

export const changeField = async (identifier, key, value, type, fieldName, items, defaultValue) => {
  let fileFieldValue;
  const item = items.find((i) => i.identifier === identifier);
  const { rank } = item;
  let allValues = defaultValue;
  fileFieldValue = allValues.find((dv) => dv.rank === item.rank);

  if (type === 'existing') {
    if (fileFieldValue) {
      for (const field in fileFieldValue) {
        delete fileFieldValue[field];
      }
      fileFieldValue.id = value.id;
      fileFieldValue.resource = value.id;
      fileFieldValue.fieldName = fieldName;
      fileFieldValue.fileName = value.name;
      fileFieldValue.originalName = value.name;
      fileFieldValue.modifiedName = value.modifiedName;
      fileFieldValue.thumb = value.thumb ? value.thumb : value.url;
      fileFieldValue.url = value.url;
      fileFieldValue.status = 'loaded';
      fileFieldValue.type = 'resource';
      fileFieldValue.rank = rank;
      fileFieldValue.selected_from_tree = value.selected_from_tree;
      if (value.crop) {
        fileFieldValue.crop = value.crop;
      }
    } else {
      fileFieldValue = {
        id: value.id,
        resource: value.id,
        fieldName,
        fileName: value.name,
        originalName: value.name,
        modifiedName: value.modifiedName,
        thumb: value.thumb ? value.thumb : value.url,
        url: value.url,
        status: 'loaded',
        type: 'resource',
        rank,
        crop: value.crop,
        cropOriginal: value.cropOriginal,
        selected_from_tree: value.selected_from_tree
      };

      if (allValues === null || allValues === undefined) {
        allValues = [fileFieldValue];
      } else {
        allValues.push(fileFieldValue);
      }
    }
    return allValues;
  }

  if (type === 'replace') {
    if (fileFieldValue) {
      for (const field in value) {
        fileFieldValue[field] = value[field];
      }

      const newMergeFile = mergeFields(fileFieldValue, value);
      allValues = allValues.filter((dv) => dv.rank !== item.rank);
      allValues.push(newMergeFile);

    } else {
      fileFieldValue = {
        id: value.id,
        resource: value.id,
        fieldName,
        fileName: value.name,
        originalName: value.name,
        modifiedName: value.modifiedName,
        thumb: value.thumbUrl ? value.thumbUrl : value.url,
        url: value.url,
        status: type.status,
        type: value.type,
        selected_from_tree: value.selected_from_tree,
        crop: value.crop,
        cropOriginal: value.cropOriginal,
      };

      if (allValues === null || allValues === undefined) {
        allValues = [fileFieldValue];
      } else {
        allValues.push(fileFieldValue);
      }
    }
  } else if (fileFieldValue) {
    if (value instanceof File) {
      const chunks = [];
      for (let start = 0; start < value.size; start += CHUNK_SIZE) {
        let end = start + CHUNK_SIZE;
        if (end > value.size) {
          end = value.size;
        }
        chunks.push(value.slice(start, end));
      }

      const size = await getFileSize(value);
      fileFieldValue.fieldName = fieldName;
      fileFieldValue.fileName = value.name;
      fileFieldValue.originalName = value.name;
      fileFieldValue.modifiedName = value.modifiedName;
      fileFieldValue.randomFileName = getRandomStr();
      fileFieldValue.status = 'loaded';
      fileFieldValue.file = value;
      fileFieldValue.chunks = chunks;
      fileFieldValue.size = size;
      fileFieldValue.type = 'file';
    } else {
      fileFieldValue[key] = value;
    }
  } else {
    const chunks = [];
    for (let start = 0; start < value.size; start += CHUNK_SIZE) {
      let end = start + CHUNK_SIZE;
      if (end > value.size) {
        end = value.size;
      }
      chunks.push(value.slice(start, end));
    }

    const size = await getFileSize(value);
    const md5 = await getMd5FromFile(value);

    const newFile = cloneFileValue(value);

    fileFieldValue = {
      fieldName,
      fileName: value.name,
      originalName: value.name,
      modifiedName: value.name,
      randomFileName: getRandomStr(),
      status: 'loaded',
      file: newFile,
      size,
      chunks,
      type: 'file',
      md5,
    };
    if (allValues === null || allValues === undefined) {
      allValues = [fileFieldValue];
    } else {
      allValues.push(fileFieldValue);
    }
  }

  fileFieldValue.rank = rank;
  return allValues;

};

const getFileSize = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    if (!e) {
      return reject();
    }
    return resolve(e.total);
  };
  reader.readAsDataURL(file);
});

export const getAcceptableFiles = (acceptable) => {
  const totalAcceptableMimes = [];
  if (acceptable && acceptable.length) {
    for (let i = 0; i < acceptable.length; i++) {
      if (acceptable[i].includes('csv')) {
        totalAcceptableMimes.push('.csv');
      } else {
        totalAcceptableMimes.push(acceptable[i]);
      }
      // const acceptable = getFileAcceptableMimeTypes(this.props.acceptable[i]);
      // totalAcceptableMimes = totalAcceptableMimes.concat(acceptable);
    }
  }
  return totalAcceptableMimes.join(',');
};

export const convertObjectIntoArray = (object) => Object.keys(object).map(
  (id) => ({
    ...object[id],
  }),
);

export const getImageDimensions = async (fileSrc) => {
  const fr = new FileReader();

  const imageData = await new Promise((resolve, _reject) => {
    fr.onload = function () { // file is loaded
      const img = new Image();

      img.onload = function () {
        return resolve({
          width: img.width,
          height: img.height,
        });
      };

      img.src = fr.result; // is the data URL because called with readAsDataURL
    };

    fr.readAsDataURL(fileSrc);
  });

  return imageData;
};

/**
 * note: https://stackoverflow.com/questions/42471755/convert-image-into-blob-using-javascript
 * @param url
 * @returns {Promise<Blob>}
 */
export const getImageDimensionsFromS3 = async (url) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  const base64Image = await new Promise(resolve => {
    image.onload = function () {
      return resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.src = url;
  });
  return base64Image;
};

export const getImageFromS3 = async (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then((blob) =>
      // here the image is a blob
      blob)
;

export const getInnerConstraints = (contMaxWidth, contMaxHeight, containerAspect, imgAspect) => {
  // console.log("starting", contMaxWidth, contMaxHeight, containerAspect, imgAspect)
  const contMaxAspect = contMaxWidth / contMaxHeight;

  let contWidth; let
    contHeight;
  let imgWidth; let
    imgHeight;
  if (containerAspect < contMaxAspect) { // will be height constrained
    contHeight = contMaxHeight;
    contWidth = contHeight * containerAspect;
  } else { // will be width constrained
    contWidth = contMaxWidth;
    contHeight = (1 / containerAspect) * contWidth;
  }

  if (imgAspect < containerAspect) { // img is more vertical then container
    // contain first vertically
    imgHeight = contHeight;
    imgWidth = contHeight * imgAspect;
  } else { // img is more horizontal then container
    // contain first horizontally
    imgWidth = contWidth;
    imgHeight = contWidth * (1 / imgAspect);
  }
  return {
    contWidth,
    contHeight,
    imgWidth,
    imgHeight,
  };
};

const __getBase64ImageCropped = (image, crop) => {
  const canvas = document.createElement('canvas');
  canvas.width = crop.width * image.width / 100;
  canvas.height = crop.height * image.height / 100;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x / 100 * image.naturalWidth,
    crop.y / 100 * image.naturalHeight,
    crop.width / 100 * image.naturalWidth,
    crop.height / 100 * image.naturalHeight,
    0,
    0,
    crop.width / 100 * image.width,
    crop.height / 100 * image.height,
  );

  // As Base64 string
  const base64 = canvas.toDataURL('image/jpeg');
  return base64;
};

export const getCroppedImageFromImage = async (imageSrc, crop) => {
  const image = await getImageFileFromUrl(imageSrc);
  const base64 = __getBase64ImageCropped(image, crop);
  return base64;
};

export const getCroppedImageFromFile = async (file, crop) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  const base64Image = await new Promise((resolve, reject) => {
    image.onload = function () {
      const base64 = __getBase64ImageCropped(image, crop);
      return resolve(base64);
    };
    // FileReader support
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = function () {
        image.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  });
  return base64Image;
};

export const getImageFromFile = async (file) => {
  const base64Image = await new Promise((resolve) => {
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };
      fr.readAsDataURL(file);
    }
  });
  return base64Image;
};

export const getCroppedImage = async (imageSrc, crop) => {
  if (isFileSystemMediaFile(imageSrc)) {
    if (crop) {
      return getCroppedImageFromFile(imageSrc.file, crop);
    }
    return getImageFromFile(imageSrc.file);
  }

  if (isResourceMediaFile(imageSrc)) {
    return getCroppedImageFromImage(imageSrc.url, crop);
  }
  return getCroppedImageFromImage(imageSrc.src, crop);
};

export const getCroppedImageFromS3 = async (imageSrc, crop) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  const base64Image = await new Promise((resolve) => {
    image.onload = function () {
      const base64 = __getBase64ImageCropped(image, crop);
      return resolve(base64);
    };
    image.src = imageSrc;
  });
  return base64Image;
};

const _getThumbFromS3 = async (data, fileType) => {
  let thumb = null;
  if (isResourceMediaFile(data)) {
    thumb = data.url;
  } else if (fileType === 'image' || fileType === 'video') {
    // the data should be in resourceUrl but if it do not exist get it form thumbnail
    thumb = data.thumb;
  } else {
    thumb = getFileIcon(data.fileName);
  }
  return thumb;
};

/**
 *
 * @param data: an item of defaultValues
 * @param currentState: current state of the component
 * @param callback: a callback to call with the result mediaObject
 * @param isComponentIsMounted: flag of a component
 * @param isFileInputChange: flag if fileInput change
 * @returns {Promise<{originalName: *, modifiedName: *, modalVisible: boolean, fileName: *, isImage: boolean, thumb: string, status: string}|{}|{originalName: *, modifiedName: *, fileName: *, isImage: boolean, thumb: *, status: string}|{status: string}|{originalName: *, modifiedName: *, fileName: *, thumb: *, url, status: string}|{originalName: null, modifiedName: null, fileName: string, thumb: null, status: string}>}
 */
export const callFunctionWithMediaObjectFromDefaultValue = async (callback, data, currentState, isComponentIsMounted, isFileInputChange) => {
  let fileType;
  let thumb = null;
  let mediaObject = {};

  switch (data.status) {
    case MEDIA_FILE_INPUT_STATE_AVAILABLE:
      fileType = getFileType(data.fileName);
      thumb = await _getThumbFromS3(data, fileType);

      mediaObject = {
        fileName: data.fileName,
        originalName: data.originalName,
        modifiedName: data.modifiedName,
        resource: data.resource,
        status: MEDIA_FILE_INPUT_STATE_AVAILABLE,
        thumb,
        url: data.resourceUrl,
      };
      callback(mediaObject);
      return mediaObject;
    case MEDIA_FILE_INPUT_STATE_LOADED:
      if (isComponentIsMounted) {
        fileType = getFileType(data.fileName);
        if (fileType === 'image') {
          if (data.type === 'file') {
            thumb = data.thumb;
            if (data.file) {
              if (data.crop) {
                thumb = await getCroppedImageFromFile(
                  data.file,
                  data.crop
                );
              } else {
                thumb = await getImageFromFile(data.file);
              }
            }
            mediaObject = {
              file: data.file,
              fileName: data.fileName,
              originalName: data.originalName,
              modifiedName: data.modifiedName,
              status: MEDIA_FILE_INPUT_STATE_LOADED,
              thumb,
              resource: data.resource,
              isImage: true,
            };
            callback(mediaObject);
            return mediaObject;
          } else {
            let thumb = data.thumb;
            if (isFileInputChange && data.crop) {
              delete data.crop;
            }

            mediaObject = {
              fileName: data.fileName,
              originalName: data.originalName,
              modifiedName: data.modifiedName,
              status: MEDIA_FILE_INPUT_STATE_LOADED,
              thumb,
              resource: data.resource,
              isImage: true,
            };
            callback(mediaObject);
            return mediaObject;
          }
        } else if (fileType === 'video') {
          if (!data.type === 'file') {
            mediaObject = {
              fileName: data.fileName,
              originalName: data.originalName,
              modifiedName: data.modifiedName,
              status: MEDIA_FILE_INPUT_STATE_LOADED,
              thumb,
              resource: data.resource,
              isImage: true,
            };
            callback(mediaObject);
            return mediaObject;
          }
        }
        thumb = getFileTypeGeneric(data.fileName);
        mediaObject = {
          file: data.file,
          fileName: data.fileName,
          originalName: data.originalName,
          modifiedName: data.modifiedName,
          status: MEDIA_FILE_INPUT_STATE_LOADED,
          thumb,
          isImage: false,
          resource: data.resource,
          modalVisible: false,
        };
        callback(mediaObject);
        return mediaObject;
      }
      break;
    case MEDIA_FILE_INPUT_STATE_CREATED:
      mediaObject = {
        fileName: MEDIA_FILE_INPUT_STATE_LOADING,
        originalName: null,
        modifiedName: null,
        thumb: null,
        status: MEDIA_FILE_INPUT_STATE_CREATED,
      };
      callback(mediaObject);
      return mediaObject;
    case MEDIA_FILE_INPUT_STATE_REMOVED:
      mediaObject = {
        fileName: 'Marked for removal',
        originalName: null,
        modifiedName: null,
        thumb: null,
        status: MEDIA_FILE_INPUT_STATE_REMOVED,
      };
      callback(mediaObject);
      return mediaObject;
    case MEDIA_FILE_INPUT_STATE_NONE:
      mediaObject = {
        status: MEDIA_FILE_INPUT_STATE_NONE,
      };
      callback(mediaObject);
      return mediaObject;
    case MEDIA_FILE_INPUT_STATE_FULL:
      mediaObject = {
        status: MEDIA_FILE_INPUT_STATE_FULL,
      };
      callback(mediaObject);
      return mediaObject;
    default:
      mediaObject = {};
      return mediaObject;
  }
};

export const fixCroppedImage = (finalizedCrop) => {
// console.log('finalize props', props);

  //     original:
  //     height: 166
  // width: 100
  // x: 0
  // y: -33.33333

  //     const override = {
  //       height: 100,
  //       width: 100,
  //       x: 0,
  //       y: 0
  //     }
  const {
    x, y, width, height,
  } = finalizedCrop;
  const adjustedCrop = { ...finalizedCrop };

  if (width + Math.abs(x) > 100 || x < 0) {
    let newX = 0;
    let newWidth = 0;
    if (x > 0) {
      newX = x;
      newWidth = 100 - x;
    } else {
      newX = 0;
      newWidth = (width - Math.abs(x)) > 100 ? 100 : (width - Math.abs(x));
    }
    adjustedCrop.width = newWidth;
    adjustedCrop.x = newX;
  }

  if (height + Math.abs(y) > 100 || y < 0) {
    let newY = 0;
    let newHeight = 0;
    if (y > 0) {
      newY = y;
      newHeight = 100 - y;
    } else {
      newY = 0;
      newHeight = (height - Math.abs(y)) > 100 ? 100 : (height - Math.abs(y));
    }
    adjustedCrop.height = newHeight;
    adjustedCrop.y = newY;
  }

  if (x < 0 || x + width > 100) {
    // const absX = Math.abs(x);
    // const newWidth = width - (absX * 2);
    // adjustedCrop.width = newWidth;
    // } // Portion of image's out of the cropper region.
    // if (y < 0 || y + height > 100) {
    // const absY = Math.abs(y);
    // const newHeight = height - (absY * 2);
    // adjustedCrop.height = newHeight;
    // console.log('newHeight / height', newHeight / height)

    // const yHeightPercent =  (y/100)
    // console.log("yHeightPercent",yHeightPercent )
    // console.log('newHeight', newHeight)

    // adjustedCrop.y = y - ((yHeightPercent) * newHeight )
  } // Portion of the image is out of the cropper region.

  return adjustedCrop;
};

export const getOriginalImageFromS3 = (image, resources, aws_instance) => {
  const resourceId = image.resource;
  if (resourceId) {
    const resource = resources[resourceId];
    let url = null;
    if (resource && resource.type === 'file') {
      const fileType = getFileType(resource.name);
      let { name } = resource;
      if (fileType === 'video') {
        name = `${resource.id}.${resource.extension}`;
      }
      if (fileType === 'image') {
        name = `${resource.id}.${resource.extension}`;
      }
      const params = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
        Key: name,
      };
      url = getURLFromCache(
        `resource-${resource.id}`,
        params,
        aws_instance
      );
    } else if (resource && resource.type === 'existing') {
      const fileType = getFileType(resource.name);
      if (fileType === 'video') {
        const paramsThumbnail = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.id}/resource`,
          Key: `${resource.id}_00001.png`,
        };
        url = getURLFromCache(
          `resource-${resource.id}`,
          paramsThumbnail,
          aws_instance
        );
      }
    }
    return url;
  }
  return image.url;
};

export const getImageFileFromUrl = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const isResourceMediaFile = (image) => {
  const { status, file, resource } = image;
  return status === MEDIA_FILE_INPUT_STATE_LOADED && (!file || !(file instanceof File)) && !resource;
};

export const isFileSystemMediaFile = (image) => image && image.file && image.file instanceof File;

export const getSelectedFolderDynamicInputFolderResources = (selected_folder, folder_type, resource__folders, resources, aws_instance) => {
  let fileFields = {};
  if (selected_folder) {
    let fields = _.get(folder_type, 'fields', {});
    for (let field in fields) {
      if (fields[field].type === "file" && fields[field].slot_type === "dynamic") {
        fileFields[field] = []
        let resourceFolders = arrayFromKeyedObject(resource__folders)
          .filter(rf => rf.folder === Number(selected_folder.id) && rf.rank !== null && rf.fieldName === field);
        resourceFolders = resourceFolders.map(rFolder => {
          let resourceFolder = {...rFolder};
          let resId = resourceFolder.resource;
          let resource = resources[resId];
          if (resource) {
            const fileType = getFileType(resource.extension);
            if (fileType === "video") {
              const params = {
                Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
                Key: `${resource.id}_preview.webm`
              };
              const paramsThumbnail = {
                Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
                Key: `${resource.id}_00001.png`
              };
              resourceFolder.resourceUrl = getURLFromCache(`resource-${resource.id}`, params, aws_instance);
              resourceFolder.thumb = getURLFromCache(`resource-thumbnail-${resource.id}`, paramsThumbnail, aws_instance);
            } else {
              const params = {
                Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource__folders`,
                Key: `${resourceFolder.id}.${resourceFolder.extension}`
              };
              const paramsThumbnail = {
                Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource__folders`,
                Key: `${resourceFolder.id}_thumbnail.${resourceFolder.extension}`
              };
              resourceFolder.resourceUrl = getURLFromCache(`resource-folder-${resourceFolder.id}`, params, aws_instance);
              resourceFolder.thumb = getURLFromCache(`resource-folder-thumbnail-${resourceFolder.id}`, paramsThumbnail, aws_instance);
            }
            resourceFolder.fileName = `${rFolder.id}.${resource.extension}`;
            resourceFolder.status = resource.status;
            resourceFolder.originalName = resource.originalName;
            resourceFolder.modifiedName = resource.modifiedName;
          }
          return resourceFolder;
        }).sort((a, b) => {
          return a.rank - b.rank;
        });
        fileFields[field] = resourceFolders;
      }
    }
  }

  return fileFields;
}

export const getSelectedFolderFixedInputFolderResources = (selected_folder, folder_type, resource__folders, resources, aws_instance) => {
  let fileFields = {};
  if (selected_folder) {
    let fields = _.get(folder_type, 'fields', {});
    for (let field in fields) {
      if (fields[field].type === "file" && fields[field].slot_type === "fixed") {
        fileFields[field] = [];
        let resourceFolders = arrayFromKeyedObject(resource__folders)
          .filter(rf => rf.folder === Number(selected_folder.id) && rf.rank === null && rf.fieldName === field);
        if (fields[field] && fields[field].slots) {
          for (let j = 0; j < fields[field].slots.length; j++) {
            let slot = fields[field].slots[j];
            let rFolder = resourceFolders.find(rf => rf.slotName === slot.name);
            if (rFolder) {
              let resourceFolder = {...rFolder}
              if (resources && resources[resourceFolder.resource]) {
                let resource = resources[rFolder.resource];
                const fileType = getFileType(resource.extension);

                resourceFolder.status = resources[rFolder.resource].status;
                resourceFolder.fileName = `${rFolder.id}.${resource.extension}`;
                resourceFolder.originalName = resources[rFolder.resource].originalName;
                resourceFolder.modifiedName = resources[rFolder.resource].modifiedName;
                if (fileType === "video") {
                  const params = {
                    Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
                    Key: `${resource.id}_preview.webm`
                  };
                  const paramsThumbnail = {
                    Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource`,
                    Key: `${resource.id}_00001.png`
                  };
                  resourceFolder.resourceUrl = getURLFromCache(`resource-${resource.id}`, params, aws_instance);
                  resourceFolder.thumb = getURLFromCache(`resource-thumb-${resource.id}`, paramsThumbnail, aws_instance);
                } else {
                  const params = {
                    Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource__folders`,
                    Key: `${resourceFolder.id}.${resource.extension}`
                  };
                  const paramsThumbnail = {
                    Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/${resource.company}/resource__folders`,
                    Key: `${resourceFolder.id}_thumbnail.${resource.extension}`
                  };
                  resourceFolder.resourceUrl = getURLFromCache(`resource-folder-${resourceFolder.id}`, params, aws_instance);
                  resourceFolder.thumb = getURLFromCache(`resource-folder-thumbnail-${resourceFolder.id}`, paramsThumbnail, aws_instance);
                }
              } else {
                resourceFolder.status = "created";
              }
              fileFields[field].push(resourceFolder);
            }
          }
        }
      }
    }
  }
  return fileFields;
}


function readChunked(file, chunkCallback, endCallback) {
  var fileSize   = file.size;
  var chunkSize  = 4 * 1024 * 1024; // 4MB
  var offset     = 0;
  
  var reader = new FileReader();
  reader.onload = function() {
    if (reader.error) {
      endCallback(reader.error || {});
      return;
    }
    offset += reader.result.length;
    // callback for handling read chunk
    // TODO: handle errors
    chunkCallback(reader.result, offset, fileSize); 
    if (offset >= fileSize) {
      endCallback(null);
      return;
    }
    readNext();
  };

  reader.onerror = function(err) {
    endCallback(err || {});
  };

  function readNext() {
    var fileSlice = file.slice(offset, offset + chunkSize);
    reader.readAsBinaryString(fileSlice);
  }
  readNext();
}

export const getMd5FromFile = async (file) => new Promise((resolve, reject) => {
  var md5 = CryptoJS.algo.MD5.create();
  readChunked(file, (chunk) => {
    md5.update(CryptoJS.enc.Latin1.parse(chunk));
  }, err => {
    if (err) {
      reject(err);
    } else {
      // TODO: Handle errors
      var hash = md5.finalize();
      var hashHex = hash.toString(CryptoJS.enc.Hex);
      resolve(hashHex);
    }
  });
})

export const getMd5FromFiles = async (files) => await Promise.all(await files.map(f => getMd5FromFile(f)));

export const fileToDataUrl = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    if (!e) {
      return reject();
    }
    return resolve(reader.result);
  };
  reader.readAsDataURL(file);
});

export const dataUrlToFile = async (data, name, type) => {
  const blob = await (await fetch(data)).blob();
  return new File([blob], name, { type: type });
}

export const removeEXIFDataFromFile = async (file) => {
  try {
    const fileType = getFileType(file.name)
    if (fileType !== 'image') return file
    const dataUrl = await fileToDataUrl(file)
    const dataUrlNoExif = piexifjs.remove(dataUrl)
    return dataUrlToFile(dataUrlNoExif, file.name, file.type)
  } catch (e) {
    // Returns original file on error
    // Some images fail when trying to remove exif
    return file
  }
}

export const removeEXIFData = async (files) => await Promise.all(await files.map(f => removeEXIFDataFromFile(f)))
