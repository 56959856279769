import React, { useContext, useMemo } from "react";
import Slide1911 from "../Slide1911";
import { PreviewContext } from "../../../Contexts";
import { useFolder } from "../../../hooks/useFolder";
import CycleWrapper from "../CycleWrapper";

export type AlertProps = { folderId?: number; handleEndOfPlay?: () => void };

export const Alert = (props: AlertProps) => {
  const { aws, aws_bucket, containerHeight, containerWidth, mode, webview_signedurls, data } =
    useContext(PreviewContext);

  const { getBaseFolder } = useFolder(props.folderId);

  const baseFolder = getBaseFolder();

  if (!baseFolder) return null;

  return (
    <CycleWrapper
      containerHeight={containerHeight}
      containerWidth={containerWidth}
      handleEndOfPlay={props.handleEndOfPlay}
      data={{ ...data, base_folder: baseFolder.id }}
    >
      <>
        <Slide1911
          aws={aws}
          aws_bucket={aws_bucket}
          mode={mode}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          handleEndOfPlay={null}
          data={{
            ...data,
            base_folder: baseFolder.id,
            index: 0,
            slotNames: ["media"],
            placeholder: "Image",
            fieldType: "image",
            showPlaceholder: false,
            backgroundSize: "cover"
          }}
          webview_signedurls={webview_signedurls}
        />
      </>
    </CycleWrapper>
  );
};
