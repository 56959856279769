import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {AutoComplete, Button, Input, List, Tag, Tooltip} from 'antd';
import _ from 'lodash';
import DynamicForm from "../form/DynamicForm";
import {dateFormatted} from "../../utils/dateUtils";
import {arrayFromKeyedObject} from "../../shared/utils/collectionUtils";
import AlertNotification from "../layout/AlertNotification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spaner from "../utils/Spaner";
import TextWithHighlights from "../tree/TextWithHighlights";
import {MD_WARNING} from "../layout/LayoutConstants";

interface IResourceContentLibraryUserGeneratedProps {
    selected_resource: any;
    resource__notes: any[];
    resource__tags: any[];
    tags: { [key: string]: any };
    user: any;
    users: { [key: string]: any }
    searchStringHighlight: string;
    tagSuggestionResults: any[]

    getTagResource: any;
    addTagResource: any;
    deleteTagResource: any;
    getNotesResource: any;
    addNotesResource: any;
    deleteNotesResource: any;
    updateNotesResource: any;
    updateResource: any;
    searchTag: any;
    ref?: any;
}

export const ResourceContentLibraryUserGenerated: React.FC<IResourceContentLibraryUserGeneratedProps> = forwardRef((props: IResourceContentLibraryUserGeneratedProps, ref) => {
    const [resourceData, setResourceData] = useState([]);
    const [tagsMasterArray, setTagsMasterArray] = useState([])

    const [resourceTags, setResourceTags] = useState([]);
    const [tagInputVisible, setTagInputVisible] = useState(false);
    const [tagInputValue, setTagInputValue] = useState('');
    const tagElementRef = useRef(null);
    const [allTags, setAllTags] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [autoSearchTimeout, setAutoSearchTimeout] = useState(null);
    const [tagInputSearch, setTagInputSearch] = useState('');

    const [resourceNotes, setResourceNotes] = useState([]);
    const [notesInputVisible, setNotesInputVisible] = useState(false);
    const [allNotes, setAllNotes] = useState([])
    const [resourceNoteUpdate, setResourceNoteUpdate] = useState(null)
    const notesFormRef = useRef(null);
    const nameFormRef = useRef(null);

    useImperativeHandle(ref, () => ({
        onSubmitResource() {
            onSubmitResource()
        },
    }));

    const updateNotes = (resource_id, resource__notes) => {
        const myNotes = resource__notes.filter(rn => {
            return rn.resource === resource_id
        })
        setResourceNotes(myNotes);
    }

    const updateTags = (resource_id, resource__tags, tags) => {
        const myTags = resource__tags
            .filter(rt => {
                return rt.resource === resource_id;
            })
            .map(rt2 => {
                const myTag = _.get(tags, `[${rt2.tag}]`, {})
                return {
                    ...rt2,
                    tagId: myTag.id,
                    tag: myTag.tag,
                }
            })

        setResourceTags(myTags);
    }

    useEffect(() => {
        if (props.selected_resource) {
            const data = getResourceListInformation()
            setResourceData(data);

            const resource_id = props.selected_resource.id;

            // fetch resource_tags
            props.getTagResource(resource_id)

            // fetch resource_notes
            props.getNotesResource(resource_id);

        }
    }, [props.selected_resource])

    useEffect(() => {
        if (props.resource__notes && props.selected_resource) {
            updateNotes(props.selected_resource.id, props.resource__notes);
        }
    }, [props.resource__notes, props.selected_resource])

    useEffect(() => {
        if (props.resource__tags && props.selected_resource) {
            updateTags(props.selected_resource.id, props.resource__tags, props.tags);
        }
    }, [props.resource__tags, props.selected_resource, props.tags])

    useEffect(() => {
        if (props.tags) {
            const tagsMaster = arrayFromKeyedObject(props.tags);
            setTagsMasterArray(tagsMaster)
        }
    }, [props.tags])

    useEffect(() => {
        const newAllTags = [...resourceTags];
        setAllTags(newAllTags)

        const data = getResourceListInformation(newAllTags)
        setResourceData(data);
    }, [resourceTags])

    useEffect(() => {
        const newAllNotes = [...resourceNotes];
        setAllNotes(newAllNotes)

        const data = getResourceListInformation(null, newAllNotes)
        setResourceData(data);
    }, [resourceNotes])

    useEffect(() => {
        const {tagSuggestionResults, tags} = props;
        if (tagSuggestionResults && tagSuggestionResults.length > 0) {
            const options = tagSuggestionResults.map(tsr => {
                const tag = tags[Number(tsr)];
                if (tag) {
                    return {
                        label: tag.tag,
                        value: tsr
                    }
                }
            })
            setTagOptions(options)
        } else {
            setTagOptions([])
        }
    }, [props.tagSuggestionResults])

    useEffect(() => {
        if (tagInputVisible) {
            if(tagElementRef && tagElementRef.current) {
                tagElementRef.current.focus()
            }
        }
    }, [tagInputVisible])

    const getResourceListInformation = (allTagsOutside = null, allNotesOutside = null) => {
        const {selected_resource} = props;
        const myAllTags = allTagsOutside ? allTagsOutside : resourceTags
        const myAllNotes = allNotesOutside ? allNotesOutside : allNotes
        let data = [];
        if (selected_resource) {
            data = [
                {title: 'Name', value: selected_resource.modifiedName, type: 'name'},
                {title: 'User Tags', value: myAllTags, type: 'tags'},
                {title: 'Notes', value: myAllNotes, type: 'notes'},
            ]
        }

        return data;
    }

    const handleTagClose = removedTag => {
        const {deleteTagResource} = props
        deleteTagResource(removedTag.id, onDeleteTagResourceSuccess, onDeleteTagResourceFail)
    }

    const showTagInput = () => {
        setTagInputVisible(true);
    }

    const handleTagInputChange = data => {
        setTagInputValue(data);
    }

    const handleTagInputSelect = tagId => {
        const {addTagResource, tags} = props;
        if (tagId) {
            const tagFinded = tags[Number(tagId)] || {};
            const newTag = buildNewTag(tagFinded.tag, tagId)
            addTagResource(newTag, onAddTagResourceSuccess, onAddTagResourceFail)
        }

        setTagInputValue('')
        setTagInputVisible(false)
    }

    const buildNewTag = (tagName, tagId=null) => {
        const {selected_resource, tags} = props;
        let tagFinded = null;
        const tagNameSafe = tagName ? tagName.trim().toLowerCase() : '';
        if (tagId) {
            tagFinded = tags[Number(tagId)]
        } else {
            tagFinded = tagsMasterArray.find(t => t.tag === tagNameSafe);
        }
        const objReturn: any = {
            resource: selected_resource.id
        }
        if (tagFinded) {
            objReturn.tagId = tagFinded.id;
        } else {
            objReturn.tag = tagNameSafe;
        }

        return objReturn
    }

    const onAddTagResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'New tag have been saved',
        );
    }

    const onAddTagResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to add New tag',
        );
    }

    const onDeleteTagResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'Tag have been deleted',
        );
    }

    const onDeleteTagResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to delete tag',
        );
    }

    const onAddNoteResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'New note have been saved',
        );
    }

    const onAddNoteResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to add New note',
        );
    }

    const onUpdateNoteResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'New note have been saved',
        );
    }

    const onUpdateNoteResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to add New note',
        );
    }

    const onUpdateResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'Resource have been updated',
        );
    }

    const onUpdateResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to update Resource',
        );
    }

    const onDeleteResourceSuccess = () => {
        AlertNotification(
            'success',
            'Success',
            'Note have been deleted',
        );
    }

    const onDeleteResourceFail = () => {
        AlertNotification(
            'error',
            'Error',
            'Error when try to delete Note',
        );
    }

    const handleTagInputConfirm = (data) => {
        const {addTagResource} = props;

        if (tagInputValue) {
            const newTag = buildNewTag(tagInputValue)

            addTagResource(newTag, onAddTagResourceSuccess, onAddTagResourceFail)
        }

        setTagInputValue('')
        setTagInputVisible(false)
    }

    const forMapTag = tag => {
        const tagElem = (
            <Tag
                closable
                onClose={e => {
                    e.preventDefault();
                    handleTagClose(tag);
                }}
                color="#2691F2"
                className="resource-content-library-user-generated_tag-itemtag"
            >
                <TextWithHighlights
                    text={tag.tag}
                    highlight={props.searchStringHighlight}
                    highlightStyle={{backgroundColor: MD_WARNING}}/>

            </Tag>
        );

        return (
            <span key={tag.id} style={{display: 'inline-block'}}>
                {tagElem}
            </span>
        );
    }

    const getUser = userId => {
        const {users, user} = props;
        try {
            if (users && users[userId]) {
                const userFound = users[userId];
                const first_name = _.get(userFound, 'first_name', '');
                const last_name = _.get(userFound, 'last_name', null);
                return first_name + ' ' + last_name + ':';
            } else if (user.id === userId) {
                const first_name = _.get(user, 'first_name', '');
                const last_name = _.get(user, 'last_name', null);
                return first_name + ' ' + last_name + ':';
            }
            return '';
        } catch (e) {
            return '';
        }
    };

    const onSearchTagSuggestions = (searchText: string) => {
        onSearchChange(searchText);
    };

    const onSearchChange = (searchText) => {
        clearTimeout(autoSearchTimeout);
        setAutoSearchTimeout(setTimeout(() => {
            setTagInputSearch(tagInputValue)
            doSearchTag(searchText)
        }, 500));
    };

    const doSearchTag = (searchText) => {
        props.searchTag(searchText)
    }

    const renderTags = items => {
        return (
            <div>
                {items.map(forMapTag)}
                {tagInputVisible && (
                    <AutoComplete
                        // ref={tagElementRef}
                        value={tagInputValue}
                        options={tagOptions}
                        style={{width: 78}}
                        onSelect={handleTagInputSelect}
                        onSearch={onSearchTagSuggestions}
                        onChange={handleTagInputChange}
                    >
                        <Input
                            ref={tagElementRef}
                            type="text"
                            size="small"
                            style={{width: 78}}
                            autoFocus={true}
                            onPressEnter={handleTagInputConfirm}
                        />
                    </AutoComplete>
                )}
            </div>
        )
    }

    // Notes
    const showNotesInput = () => {
        setNotesInputVisible(true);
    }

    const deleteResourceNote = (data) => {
        const {deleteNotesResource} = props;
        deleteNotesResource(data.id, onDeleteResourceSuccess, onDeleteResourceFail)
    }

    const editResourceNote = (data) => {
        setResourceNoteUpdate(data);
        setNotesInputVisible(true);
    }

    const renderTextWithNewLines = (text) => {
        // split text by new line
        const splitText = text.split(/\n/g)
        return (
            <div>
                {splitText.map((i, key) => {
                    const divStyles = i ? {} : {
                        height: 20,
                        width: '100%',
                    }
                    return <div key={key} style={divStyles}>
                        <TextWithHighlights
                            text={i}
                            highlight={props.searchStringHighlight}
                            highlightStyle={{backgroundColor: MD_WARNING}}/>
                    </div>;
                })}
            </div>);
    }

    const forMapNotes = (note, index, arr) => {
        const updatedAt = dateFormatted(_.get(note, 'createdAt', ''), 'm/dd/yyyy')
        const updatedAndSeparator = updatedAt ? updatedAt + ' - ' : ''
        const userName = getUser(note.user)

        const tagElem = (
            <div className="resource-content-library-user-generated_notes">
                <div className="resource-content-library-user-generated_notes-list">
                    <div className="resource-content-library-user-generated_notes-extra">
                    <span className="resource-content-library-user-generated_notes-extra-date">
                        {updatedAndSeparator}
                    </span>
                        <span className="resource-content-library-user-generated_notes-extra-user">
                        {userName}
                    </span>

                    </div>
                    <div className="resource-content-library-user-generated_notes-note">
                        {renderTextWithNewLines(note.note)}
                    </div>

                    {index < arr.length - 1 && (
                        <div className="resource-content-library-user-generated_notes-separator">
                        </div>
                    )}
                </div>
                <div className="resource-content-library-user-generated_notes-actions">
                    <Tooltip placement="bottom" title="Delete Note">
                        <FontAwesomeIcon
                            size="sm"
                            icon={['fas', 'trash']}
                            onClick={() => {
                                deleteResourceNote(note)
                            }}
                            className="resource-content-library-user-generated_notes-actions-icon resource-content-library-user-generated_notes-actions-delete"
                        />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Edit Note">
                        <FontAwesomeIcon
                            size="sm"
                            icon={['fas', 'edit']}
                            onClick={() => {
                                editResourceNote(note)
                            }}
                            className="resource-content-library-user-generated_notes-actions-icon resource-content-library-user-generated_notes-actions-edit"
                        />
                    </Tooltip>
                </div>
            </div>
        );

        return (
            <div key={note.id}>
                {tagElem}
            </div>
        );
    }

    const buildNewNote = (newInputNotes) => {
        const {user, selected_resource} = props;
        const objReturn: any = {
            resource: selected_resource.id,
            note: newInputNotes,
            user: user.id
        }
        return objReturn
    }

    const onSubmitNotes = () => {
        const {addNotesResource, updateNotesResource} = props;
        let isValid = notesFormRef.current.isValid();
        if (isValid) {
            let fieldValues = notesFormRef.current.getFieldValues();
            const newInputNotes = fieldValues.notes;

            const newNote = buildNewNote(newInputNotes)

            if (resourceNoteUpdate) {
                // update
                updateNotesResource({id: resourceNoteUpdate.id, ...newNote}, onUpdateNoteResourceSuccess, onUpdateNoteResourceFail)
            } else {
                // new
                addNotesResource(newNote, onAddNoteResourceSuccess, onAddNoteResourceFail)
            }

            setNotesInputVisible(false)
        } else {

        }
    }

    const onCancelNotes = () => {
        setNotesInputVisible(false);
    }

    const renderDynamicFormNotes = (key, fieldGroupsConfig, fieldsConfig, fieldValues, field) => {
        return (
            <div className="resource-content-library-user-generated_notes-form">
                <div>
                    <DynamicForm
                        ref={notesFormRef}
                        fieldGroupsConfig={fieldGroupsConfig}
                        fieldsConfig={fieldsConfig}
                        fieldValues={fieldValues}
                        mode="edit"
                        onSubmit={() => {
                        }}
                        onError={() => {
                        }}
                        showSubmitButton={false}
                        enableFieldGrouping={false}
                        showLabels={false}
                        classOverride="noclass"
                        canUpdate={true}
                        submitting={false}
                    />
                </div>
                <div className="resource-content-library-user-generated_notes-form-buttons">
                    <Button
                        size="large"
                        htmlType="submit"
                        className="form-submit-button"
                        style={{width: "auto"}}
                        onClick={onCancelNotes}
                    >
                        Cancel
                    </Button>
                    <Spaner width={"sm"}/>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="form-submit-button"
                        style={{width: "auto"}}
                        onClick={onSubmitNotes}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }

    const renderDynamicForm = (formRef, key, fieldGroupsConfig, fieldsConfig, fieldValues) => {
        return (
            <div className="resource-content-library-user-generated_available-form">
                <div>
                    <DynamicForm
                        ref={formRef}
                        fieldGroupsConfig={fieldGroupsConfig}
                        fieldsConfig={fieldsConfig}
                        fieldValues={fieldValues}
                        mode="edit"
                        onSubmit={() => {
                        }}
                        onError={() => {
                        }}
                        showSubmitButton={false}
                        enableFieldGrouping={false}
                        showLabels={false}
                        classOverride="noclass"
                        canUpdate={true}
                        submitting={false}
                    />
                </div>
            </div>
        );
    }

    const buildTypeFieldValueNote = () => {
        const typeFieldValues = {
            notes: ''
        }

        if (resourceNoteUpdate !== null) {
            typeFieldValues.notes = resourceNoteUpdate.note
        }

        return typeFieldValues;
    }

    const renderNotes = items => {
        const typeFieldGroupsConfig = {
            default: {title: '', default_expanded: true},
        };
        const typeFieldsConfig = {
            notes: {
                type: 'textarea',
                show_char_count: false,
                title: 'notes',
                validation: {rules: [{name: 'isRequired'}]},
                default: '',
                settings: {
                    autoFocus: true
                }
            }
        }
        const typeFieldValues = buildTypeFieldValueNote();

        const getReverseOrderItems = (items) => {
            return Array.from(items).reverse();
        }

        return (
            <div>
                {notesInputVisible && (
                    renderDynamicFormNotes('key-input-notes', typeFieldGroupsConfig, typeFieldsConfig, typeFieldValues, 'notes')
                )}
                {getReverseOrderItems(items).map(forMapNotes)}
            </div>
        )
    }

    const renderResourceName = name => {
        const typeFieldGroupsConfig = {
            default: {title: '', default_expanded: true},
        };
        const typeFieldsConfig = {
            modifiedName: {
                type: "text",
                title: 'Resource name',
                defaultValue: name,
                validation: {rules: [{name: 'isRequired'}]},

            }
        }
        const typeFieldValues = {
            modifiedName: name
        }

        return (
            <div className="resource-content-library-user-generated_name">
                {renderDynamicForm(nameFormRef, 'key-input-notes', typeFieldGroupsConfig, typeFieldsConfig, typeFieldValues)}
            </div>
        )
    }

    const onClickAddNewNote = () => {
        showNotesInput();
        setResourceNoteUpdate(null);
    }

    const renderList = item => {
        switch (item.type) {
            case 'tags':
                return (
                    <List.Item className={`resource-content-library-user-generated_listitem`} style={{
                      display: "flex",
                      justifyContent: "flex-start"
                    }}>
                        <div className="resource-content-library-user-generated_listitem-title">
                            <b>{item.title}:</b>
                        </div>
                        <div className={`resource-content-library-user-generated_listitem-tags-content`} style={{
                          width: "75%"
                        }}>
                            {renderTags(item.value)}
                        </div>
                        <div className="resource-content-library-user-generated_listitem-action" style={{
                          position: "relative",
                          top: 0,
                          right: 0
                        }}>
                            {!tagInputVisible && (
                                <Tooltip placement="bottom" title="Add Tag">
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={['fas', 'plus']}
                                        onClick={showTagInput}
                                        className="resource-content-library-user-generated_tag-actions-icon"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </List.Item>
                )
            case 'notes':
                return (
                    <List.Item
                        className="resource-content-library-user-generated_listitem resource-content-library-user-generated_listitem-notes">
                        <div className="resource-content-library-user-generated_listitem-header">
                            <div className="resource-content-library-user-generated_listitem-title">
                                <div><b>{item.title}</b></div>
                            </div>
                            <div className="resource-content-library-user-generated_listitem-empty">
                            </div>
                            <div className="resource-content-library-user-generated_listitem-action">
                                {!notesInputVisible && (
                                    <Tooltip placement="bottom" title="Add Note">
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={['fas', 'plus']}
                                            onClick={onClickAddNewNote}
                                            className="resource-content-library-user-generated_notes-actions-icon resource-content-library-user-generated_notes-actions-add"
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        <div className="resource-content-library-user-generated_listitem-content">
                            {renderNotes(item.value)}
                        </div>
                    </List.Item>
                )
            case 'name':
                return (
                    <List.Item className="resource-content-library-user-generated_listitem-name"
                               style={{
                                 display: "flex",
                                 alignItems: "baseline",
                                 justifyContent: "flex-start"
                               }}
                    >
                        <div className="resource-content-library-user-generated_listitem-name-title">
                            <b>{item.title}</b>:
                        </div>
                        {renderResourceName(item.value)}
                    </List.Item>
                )
            default:
                return (<List.Item><b>{item.title}</b>: {item.value}</List.Item>)
        }
    }

    const buildNewResourceToUpdate = (/*newStatus*/) => {
        const {selected_resource} = props;
        const objReturn: any = {
            id: selected_resource.id,
        }
        return objReturn
    }

    const onSubmitResource = () => {
        const {updateResource} = props;
        let isValidName = nameFormRef.current.isValid();
        if (isValidName) {
            const newResourceToUpdate = buildNewResourceToUpdate()

            let fieldValuesName = nameFormRef.current.getFieldValues();
            const newName = fieldValuesName.modifiedName;
            newResourceToUpdate.modifiedName = newName;

            updateResource(newResourceToUpdate, onUpdateResourceSuccess, onUpdateResourceFail)

        }
    }

    return (
        <div className="resource-content-library-user-generated">
            <List
                bordered
                dataSource={resourceData}
                renderItem={renderList}
            />
        </div>
    )
})

export default ResourceContentLibraryUserGenerated;
