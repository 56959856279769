const STRETCH = "stretch";
const COVER = "cover";
const CONTAIN = "contain";

const FLEX_TOP_LEFT = 'flex-top-left';
const FLEX_TOP_CENTER = 'flex-top-center';
const FLEX_TOP_RIGHT = 'flex-top-right';
const FLEX_CENTER = 'flex-center';
const FLEX_BOTTOM_LEFT = 'flex-bottom-left';
const FLEX_BOTTOM_CENTER = 'flex-bottom-center';
const FLEX_BOTTOM_RIGHT = 'flex-bottom-right';

const NOTIFICATION_FOLDER_STATUS_TITLE = 'Conversion Status';
const NOTIFICATION_FOLDER_STATUS_DESCRIPTION = 'Files started converting';
const NOTIFICATION_FOLDER_STATUS_TYPE = 'CONVERSION';
const NOTIFICATION_FOLDER_STATUS_LEVEL_PROCESSING = 'processing';
const NOTIFICATION_FOLDER_STATUS_LEVEL_DONE = 'done';
// const NOTIFICATION_FOLDER_STATUS_LEVEL_ERROR = 'error';

const NOTIFICATION_DISPLAY_ALERT_TITLE = 'Display Alert';
const NOTIFICATION_DISPLAY_ALERT_TYPE = 'DISPLAY_ALERT';
const NOTIFICATION_DISPLAY_ALERT_LEVEL_YELLOW = 'yellow';
const NOTIFICATION_DISPLAY_ALERT_LEVEL_RED = 'red';

const NOTIFICATION_TYPE_DISPLAY_ALERT = 'DISPLAY_ALERT';
const NOTIFICATION_TYPE_STATUS_FOLDER = 'STATUS_FOLDER';
const NOTIFICATION_TYPE_ALL = 'ALL';

const USER_NOTIFICATION_VIEW_STATUS_DELETED = 'deleted';
const USER_NOTIFICATION_VIEW_STATUS_READ = 'read';

module.exports = {
    STRETCH,
    COVER,
    CONTAIN,
    FLEX_TOP_LEFT,
    FLEX_TOP_CENTER,
    FLEX_TOP_RIGHT,
    FLEX_CENTER,
    FLEX_BOTTOM_LEFT,
    FLEX_BOTTOM_CENTER,
    FLEX_BOTTOM_RIGHT,
    NOTIFICATION_FOLDER_STATUS_TITLE,
    NOTIFICATION_FOLDER_STATUS_DESCRIPTION,
    NOTIFICATION_FOLDER_STATUS_TYPE,
    NOTIFICATION_FOLDER_STATUS_LEVEL_PROCESSING,
    NOTIFICATION_FOLDER_STATUS_LEVEL_DONE,
    NOTIFICATION_DISPLAY_ALERT_TITLE,
    NOTIFICATION_DISPLAY_ALERT_TYPE,
    NOTIFICATION_DISPLAY_ALERT_LEVEL_YELLOW,
    NOTIFICATION_DISPLAY_ALERT_LEVEL_RED,
    NOTIFICATION_TYPE_DISPLAY_ALERT,
    NOTIFICATION_TYPE_STATUS_FOLDER,
    NOTIFICATION_TYPE_ALL,
    USER_NOTIFICATION_VIEW_STATUS_DELETED,
    USER_NOTIFICATION_VIEW_STATUS_READ
};
