import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Dropdown, Badge } from "antd";
import { MEDIA_PROGRESS_STATUS_PROCESSING } from "../form/input/MediaProgressStatus/MediaProgressStatus";
import MediaProgressStatusRow from "../form/input/MediaProgressStatus/MediaProgressStatusRow";
import {
  NOTIFICATION_DISPLAY_ALERT_TYPE,
  NOTIFICATION_FOLDER_STATUS_TYPE,
  NOTIFICATION_TYPE_ALL,
  NOTIFICATION_TYPE_DISPLAY_ALERT,
  USER_NOTIFICATION_VIEW_STATUS_DELETED,
  USER_NOTIFICATION_VIEW_STATUS_READ,
} from "../../shared/constants";
import MediaLinkRow from "../form/input/MediaProgressStatus/MediaLinkRow";
import { callNotifications } from "../../services/notificationService";
import moment from "moment";

const TIME_TO_RE_FETCH_STATUS_FOLDER_NOTIFICATIONS = 1000 * 30; // 30 seconds
const TIME_TO_RE_FETCH_DISPLAY_NOTIFICATIONS = 1000 * 60 * 15; // 15 minutes

const CLEAR_ALL_MENU_ITEM_KEY = "clear-all-menu-item";

const NotificationMenu = ({
  notifications,
  user__notifications,
  user,
  folder__status,
  getFolderStatus,
  getNotifications,
  updateNotificationRead,
  updateNotificationDelete,
}) => {
  // const [fetching, setFetching] = useState(false);
  const notificationRef = useRef(notifications);
  notificationRef.current = notifications;
  const userNotificationRef = useRef(user__notifications);
  userNotificationRef.current = user__notifications;
  const folderStatusRef = useRef(folder__status);
  folderStatusRef.current = folder__status;

  const [notificationsStatusFolder, setNotificationsStatusFolder] = useState(
    []
  );
  const [
    notificationsStatusFolderCounterTotal,
    setNotificationsStatusFolderCounterTotal,
  ] = useState(0);
  const [
    notificationsStatusFolderCounterNoRead,
    setNotificationsStatusFolderCounterNoRead,
  ] = useState(0);

  const [notificationsDisplay, setNotificationsDisplay] = useState([]);
  const [
    notificationsDisplayCounterTotal,
    setNotificationsDisplayCounterTotal,
  ] = useState(0);
  const [
    notificationsDisplayCounterNoRead,
    setNotificationsDisplayCounterNoRead,
  ] = useState(0);

  // useEffect(() => () => {
  // });

  useEffect(() => {
    // reFetchFolderStatusNotificationsIfNeed();
    // reFetchStatusFolderIfNeed();
  }, []);

  useEffect(() => {
    if (notifications.length + user__notifications.length > 0) {
      notificationRef.current = notifications;
      userNotificationRef.current = user__notifications;
      // notification status folder
      const notStatusFolder =
        getNotificationStatusFolderFormatted(notifications);
      const counterSFTotal = notStatusFolder.length;
      const counterSFNoRead =
        getCountNotificationsInProgressNoRead(notStatusFolder);
      setNotificationsStatusFolderCounterNoRead(counterSFNoRead);
      setNotificationsStatusFolderCounterTotal(counterSFTotal);
      setNotificationsStatusFolder(notStatusFolder);
      const notStatusFolderNoDeleted =
        getNotificationsStatusNoDeleted(notifications);
      const onlyStatusFolderInProgress =
        getNotificationStatusFolderInProgressOnly(notStatusFolderNoDeleted);

      // notification display
      const notDisplay = getNotificationsDisplayFormatted(notifications);
      const counterDTotal = notDisplay.length;
      const counterDNoRead = getCountNotificationsInProgressNoRead(notDisplay);
      setNotificationsDisplayCounterTotal(counterDTotal);
      setNotificationsDisplayCounterNoRead(counterDNoRead);
      setNotificationsDisplay(notDisplay);
    }
  }, [notifications, user__notifications]);

  // call only on start
  useEffect(() => {
    // the first time the user is load should fetch notifications
    if (user && user.company) {
      fetchFolderStatusNotifications();

      // fetch display notification now
      fetchDisplayNotifications();
      // set cron to fetch display notifications each x minutes
      refetchDisplayNotifications();
    }
  }, [user]);

  useEffect(() => {
    if (folder__status) {
      folderStatusRef.current = folder__status;
    }
  }, [folder__status]);

  const onSuccess = () => {
    // setFetching(false);
    // console.log('On Success');
    // reFetchFolderStatusNotificationsIfNeed();
  };

  const onFail = () => {
    // console.log('On Fail');
    // setFetching(false);
  };

  const fetchFolderStatusNotifications = () => {
    if (user && user.company) {
      // console.log("[defaultValue] NotificationMenu fetch folder status!!!!!!!!!!!!!!!!!!!!");
      // fetch all notifications
      // getNotifications(user.company, NOTIFICATION_TYPE_ALL, onSuccess, onFail);
      callNotifications(
        user.company,
        NOTIFICATION_TYPE_ALL,
        getNotifications,
        onSuccess,
        onFail
      );
      // getFolderStatus(user.company);
      // callStatusFolder(user.company, getFolderStatus);
      // setFetching(false);
    }
  };

  const fetchStatusFolder = () => {
    if (user && user.company) {
      // callStatusFolder(user.company, getFolderStatus);
    }
  };

  const fetchDisplayNotifications = () => {
    if (user && user.company) {
      // console.log("[defaultValue] NotificationMenu fetch folder status!!!!!!!!!!!!!!!!!!!!");
      // fetch all notifications
      // eslint-disable-next-line react/prop-types
      // getNotifications(user.company, NOTIFICATION_TYPE_ALL, onSuccess, onFail);
      callNotifications(
        user.company,
        NOTIFICATION_TYPE_ALL,
        getNotifications,
        onSuccess,
        onFail
      );
    }
  };

  const getNotificationStatusFolderInProgressOnly = (notificationsArray) => {
    const filterNotifications = notificationsArray.filter(
      (n) =>
        n.type === NOTIFICATION_FOLDER_STATUS_TYPE &&
        n.level === MEDIA_PROGRESS_STATUS_PROCESSING
    );
    return filterNotifications;
  };

  const getFolderStatusInProgressOnly = (folderStatusArray) => {
    const filterFolderStatus = folderStatusArray.filter(
      (n) => n.folder_status === MEDIA_PROGRESS_STATUS_PROCESSING
    );
    return filterFolderStatus;
  };

  const getStatusFolderNotDeletedInNotification = (
    folderStatusArray,
    notificationsNoDeletedArray
  ) => {
    const notificationsIds = notificationsNoDeletedArray.map((n) => n.id);
    const filderFolderStatus = folderStatusArray.filter((fs) =>
      notificationsIds.includes(fs.notification)
    );
    return filderFolderStatus;
  };

  const getNotificationDisplayOnly = (notificationsArray) => {
    const filterNotifications = notificationsArray.filter(
      (n) => n.type === NOTIFICATION_DISPLAY_ALERT_TYPE
    );
    return filterNotifications;
  };

  const isANotificationStatusNoDeleted = (notification, userNotification) => {
    const STATE = [USER_NOTIFICATION_VIEW_STATUS_DELETED];
    // is a "CONVERSION" notification and belong to this user and is not deleted
    // eslint-disable-next-line max-len
    const isValidConversionNotificationNoDeleted =
      notification.type === NOTIFICATION_FOLDER_STATUS_TYPE &&
      userNotification &&
      !STATE.includes(userNotification.view_status);
    return isValidConversionNotificationNoDeleted;
  };

  const isANotificationDisplayNoDeleted = (notification, userNotification) => {
    const STATE = [USER_NOTIFICATION_VIEW_STATUS_DELETED];
    // is a "DISPLAY_ALERT" notification and is not deleted (it is show to all users)
    const isDisplayNotificationNoDeleted =
      notification.type === NOTIFICATION_TYPE_DISPLAY_ALERT &&
      (!userNotification || !STATE.includes(userNotification.view_status));

    return isDisplayNotificationNoDeleted;
  };

  // check for ALL types of notification if it is deleted
  const isANotificationNoDeleted = (notification, userNotification) =>
    isANotificationDisplayNoDeleted(notification, userNotification) ||
    isANotificationStatusNoDeleted(notification, userNotification);

  const isANotificationNoDeletedAndMine = (notification, userNotification) =>
    isANotificationDisplayNoDeleted(notification, userNotification) ||
    isANotificationStatusNoDeleted(notification, userNotification);

  const getNotificationsNoDeleted = (notificationsArray) => {
    const filteredNotifications = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return isANotificationNoDeleted(n, userNotification);
    });
    return filteredNotifications;
  };

  const getNotificationsNoDeletedOnlyMine = (notificationsArray) => {
    const filteredNotifications = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return isANotificationNoDeletedAndMine(n, userNotification);
    });
    return filteredNotifications;
  };

  const getNotificationsDisplayNoDeleted = (notificationsArray) => {
    const filteredNotifications = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return isANotificationDisplayNoDeleted(n, userNotification);
    });
    return filteredNotifications;
  };

  const getNotificationsStatusNoDeletedExternal = (
    notificationsArray,
    userNotificationsArray
  ) => {
    const filteredNotifications = notificationsArray.filter((n) => {
      const userNotification = userNotificationsArray.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return isANotificationStatusNoDeleted(n, userNotification);
    });
    return filteredNotifications;
  };

  const getNotificationsStatusNoDeleted = (notificationsArray) => {
    const filteredNotifications = getNotificationsStatusNoDeletedExternal(
      notificationsArray,
      user__notifications
    );
    return filteredNotifications;
  };

  const getNotificationsNoState = (notificationsArray, states = []) => {
    const findFolderStatus = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return (
        !userNotification || !states.includes(userNotification.view_status)
      );
    });
    return findFolderStatus;
  };

  const getNotificationsOnlyMine = (notificationsArray) => {
    const findFolderStatus = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return n.type === NOTIFICATION_DISPLAY_ALERT_TYPE || userNotification;
    });
    return findFolderStatus;
  };

  const getNotificationsNoStateAndMine = (notificationsArray, states = []) => {
    const findFolderStatus = notificationsArray.filter((n) => {
      const userNotification = user__notifications.find(
        (un) => un.user === user.id && un.notification === n.id
      );
      return (
        !userNotification || !states.includes(userNotification.view_status)
      );
    });
    return findFolderStatus;
  };

  const getNotificationsStatusFolderInProgress = (notificationsArray) => {
    // eslint-disable-next-line max-len
    const notificationsOnlyStatusFolderInProgress =
      getNotificationStatusFolderInProgressOnly(notificationsArray);
    const notificationsNoDeleted = getNotificationsStatusNoDeleted(
      notificationsOnlyStatusFolderInProgress
    );
    return notificationsNoDeleted;
  };

  const getNotificationsDisplay = (notificationsArray) => {
    const notificationsOnlyStatusFolderInProgress =
      getNotificationDisplayOnly(notificationsArray);
    const notificationsNoDeleted = getNotificationsDisplayNoDeleted(
      notificationsOnlyStatusFolderInProgress
    );
    return notificationsNoDeleted;
  };

  /**
   * this method check for changes in Folder status only (in notifications array)
   */
  const reFetchFolderStatusNotificationsIfNeed = () => {
    setInterval(() => {
      // I need to use Ref because I am inside a interval
      const notStatusFolderNoDeleted = getNotificationsStatusNoDeletedExternal(
        notificationRef.current,
        userNotificationRef.current
      );
      const onlyStatusFolderInProgress =
        getNotificationStatusFolderInProgressOnly(notStatusFolderNoDeleted);
      if (onlyStatusFolderInProgress && onlyStatusFolderInProgress.length > 0) {
        fetchFolderStatusNotifications();
      }
    }, TIME_TO_RE_FETCH_STATUS_FOLDER_NOTIFICATIONS);
  };

  /**
   * this method check for changes in Folder status only
   */
  const reFetchStatusFolderIfNeed = () => {
    setInterval(() => {
      // Even if the notification is deleted, the status folder is checked to unlock the state of the folder
      const onlyFolderStatusInProgress = getFolderStatusInProgressOnly(
        folderStatusRef.current
      );
      if (onlyFolderStatusInProgress && onlyFolderStatusInProgress.length > 0) {
        fetchStatusFolder();
      }
    }, TIME_TO_RE_FETCH_STATUS_FOLDER_NOTIFICATIONS);
  };

  /**
   * this method check for changes in Folder status only
   */
  const refetchDisplayNotifications = () => {
    setInterval(() => {
      fetchDisplayNotifications();
    }, TIME_TO_RE_FETCH_DISPLAY_NOTIFICATIONS);
  };

  const onClickMenu = ({ key }) => {
    if (key === CLEAR_ALL_MENU_ITEM_KEY) {
      const notificationNoDeleted =
        getNotificationsNoDeletedOnlyMine(notifications);
      const notificationNoDeletedAndMine = getNotificationsOnlyMine(
        notificationNoDeleted
      );

      if (
        notificationNoDeletedAndMine &&
        notificationNoDeletedAndMine.length > 0
      ) {
        const notificationNoReadIds = notificationNoDeletedAndMine.map(
          (nnr) => nnr.id
        );
        updateNotificationDelete(notificationNoReadIds);
      }
    }
  };

  // formated notification for "CONVERTION" only
  const getNotificationStatusFolderFormatted = (notificationsArray) => {
    const { company } = user;
    const notificationsNoDeleted =
      getNotificationsStatusNoDeleted(notificationsArray);
    return notificationsNoDeleted
      .filter((status) => status.company === company)
      .map((notification) => ({
        id: notification.id,
        name: `${moment(notification.updatedAt).format("YYYY-MM-DD HH:mm")} - ${
          notification.title
        } ${notification.description}`,
        type: notification.type,
        level: notification.level,
      }));
  };

  const getNotificationsDisplayFormatted = (notificationsArray) => {
    const { company } = user;
    const notificationsNoDeleted = getNotificationsDisplay(notificationsArray);
    return notificationsNoDeleted
      .filter((status) => status.company === company)
      .map((notification) => ({
        id: notification.id,
        name: `${moment(notification.updatedAt).format("YYYY-MM-DD HH:mm")} - ${
          notification.title
        } ${notification.description}`,
        type: notification.type,
        level: notification.level,
      }));
  };

  const getCountNotificationsInProgressNoRead = (notificationsArray) => {
    const notificationsNoRead = getNotificationsNoState(notificationsArray, [
      USER_NOTIFICATION_VIEW_STATUS_READ,
    ]);
    return notificationsNoRead.length;
  };

  const onClickDropdown = (e) => {
    e.preventDefault();
    const STATE = [
      USER_NOTIFICATION_VIEW_STATUS_DELETED,
      USER_NOTIFICATION_VIEW_STATUS_READ,
    ];
    const notificationNoRead = getNotificationsNoState(notifications, STATE);
    const notificationNoReadAndMine =
      getNotificationsOnlyMine(notificationNoRead);
    if (notificationNoReadAndMine && notificationNoReadAndMine.length > 0) {
      const notificationNoReadIds = notificationNoReadAndMine.map(
        (nnr) => nnr.id
      );
      updateNotificationRead(notificationNoReadIds);
    }
  };

  const buildNotificationsMenu = () => {
    const allNotifications = [
      ...notificationsDisplay,
      ...notificationsStatusFolder,
    ];
    return (
      <Menu onClick={onClickMenu}>
        {allNotifications.map((item, index) => (
          <Menu.Item key={`notification-menu-${index}`}>
            <MediaProgressStatusRow
              key={`key-${index}`}
              name={item.name}
              type={item.type}
              index={index}
              status={item.level}
            />
          </Menu.Item>
        ))}
        <Menu.Item key={CLEAR_ALL_MENU_ITEM_KEY}>
          <MediaLinkRow name="Clear notifications" />
        </Menu.Item>
      </Menu>
    );
  };

  const buildEmptyNotificationsMenu = () => (
    <Menu>
      <Menu.Item key="notification-menu-empty">
        <span>No new notifications</span>
      </Menu.Item>
    </Menu>
  );

  const buildNotificationsDopdownItems = () => {
    const allNotificationCount =
      notificationsStatusFolderCounterTotal + notificationsDisplayCounterTotal;
    return allNotificationCount === 0
      ? buildEmptyNotificationsMenu()
      : buildNotificationsMenu();
  };

  return (
    <div className="notification-menu">
      <Dropdown
        overlay={buildNotificationsDopdownItems}
        onClick={onClickDropdown}
        overlayStyle={{ minWidth: "360px", height: "32px" }}
      >
        <Badge
          count={
            notificationsStatusFolderCounterNoRead +
            notificationsDisplayCounterNoRead
          }
          overflowCount={9}
        >
          <FontAwesomeIcon className="fa-lg" icon={["far", "bell"]} />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default NotificationMenu;
