export function hasPermission(grouped_permissions, area, permission_name, user, scope = null, scope_id = null, company_id = null) {
  if (user && user.system_admin) {
    return true;
  }
  return fullPermissionDict(grouped_permissions, area, scope, scope_id, company_id)[permission_name];
}

export function fullPermissionDict(grouped_permissions, area, scope = null, scope_id = null, company_id = null) {
  if (grouped_permissions && grouped_permissions[area]) {
    const granted_for_area = grouped_permissions[area].grant.filter((p) => p.company === company_id);
    const restricted_for_area = grouped_permissions[area].restrict.filter((p) => p.company === company_id);
    let strictly_granted = granted_for_area.filter((x) => !restricted_for_area.map((y) => y.name).includes(x.name));
    if (scope && scope_id) {
      strictly_granted = strictly_granted.filter((x) => x[scope] === scope_id);
    }
    return {
      create: strictly_granted.some((x) => x.create),
      read: strictly_granted.some((x) => x.read),
      update: strictly_granted.some((x) => x.update),
      delete: strictly_granted.some((x) => x.delete),
    };
  }
  return {
    create: false, read: false, update: false, delete: false,
  };
}
