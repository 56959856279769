import React, { Component } from "react";
const recent_interaction_timeout = 5000;

class IdleLoop extends Component {
  constructor(props) {
    super(props);
    this._recentInteractionTimeout = null;
    this._loopDivWrapperRef = null;

    if (this.props.idle_delay) {
      this._idleDelay = this.props.idle_delay * 1000;
    } else {
      this._idleDelay = 120000;
    }

    this._idleTimeout = null;
    this.lastInteraction = new Date();
  }

  componentDidMount() {
    if (this.props.idle_delay !== 0) {
      this._loopDivWrapperRef.addEventListener("mousedown", this.pageClick, false);
      this.checkRecentInteraction();
      this.checkIdleTimeout();
      this.props.goIdle("idle");
    }
  }

  componentWillUnmount() {
    this._loopDivWrapperRef.removeEventListener("mousedown", this.pageClick, false);
    clearTimeout(this._idleTimeout);
    clearTimeout(this._recentInteractionTimeout);
  }

  pageClick = () => {
    this.lastInteraction = new Date();
    this.props.resume();
    this.checkRecentInteraction();
    this.checkIdleTimeout();
  };

  checkRecentInteraction = () => {
    let msPassed = new Date().getTime() - this.lastInteraction.getTime();

    if (msPassed > recent_interaction_timeout) {
      this.props.goIdle("recentInteraction");
    } else {
      clearTimeout(this._recentInteractionTimeout);
      this._recentInteractionTimeout = setTimeout(() => {
        this.checkRecentInteraction();
      }, recent_interaction_timeout - msPassed); //avoids extra settings of timeout below interval check
    }
  };

  checkIdleTimeout = () => {
    let msPassed = new Date().getTime() - this.lastInteraction.getTime();

    if (msPassed > this._idleDelay) {
      this.props.goIdle("idle");
    } else {
      clearTimeout(this._idleTimeout);
      this._idleTimeout = setTimeout(() => {
        this.checkIdleTimeout();
      }, this._idleDelay - msPassed); //avoids extra settings of timeout below interval check
    }
  };

  render() {
    return (
      <div
        ref={(ref) => this._loopDivWrapperRef = ref}
        style={{
          position: 'absolute',
          height: this.props.containerHeight,
          width: this.props.containerWidth,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default IdleLoop;
