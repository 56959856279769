import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  ItalicOutlined,
  LineHeightOutlined,
  UnderlineOutlined
} from "@ant-design/icons";
import {InputNumber, Select, Tooltip} from "antd";
import { get } from "lodash";
import React, {useEffect, useRef, useState} from "react";
import { PDG_COLORS } from "../../../shared-global/enums/ui-enums";
import { hexToRgbA, numericValue } from "../../../shared/utils/generalUtils";
import ColorPickerTool from "../../utils/ColorPickerTool";
import FontFamilyInput from "./FontFamilyInput";
import {useSelector} from "react-redux";
import {IState} from "../../../interfaces/main-state.interface";
import {validate} from "../../../utils/validate";
const Option = Select.Option;
interface ITextStyleInputProps {
  fieldName: string;
  defaultValue: any;
  title: string;
  onBlur?: any;
  onChange?: any;
  showLabel?: boolean;
  canUpdate?: boolean;
  showCharCount?: boolean;
  settings?: any;
  options?: any;
}

const TextStyleInput: React.FunctionComponent<ITextStyleInputProps> = (
  props: ITextStyleInputProps
) => {

  const timeoutRef = useRef(null);
  const projects = useSelector((state: IState) => state.data.projects);
  const client_options = useSelector((state: IState) => state.client_options);
  const alignments = get(props, "options.alignment.items", []);
  let fonts = get(props, "options.font.items", []);
  const fontSizes = get(props, "options.fontSize.items", []);
  const lineHeights = get(props, "options.lineHeight.items", []);

  const alignmentEnabled = get(props, "options.alignment.enabled", true);
  const fontEnabled = get(props, "options.font.enabled", true);
  const fontSizeEnabled = get(props, "options.fontSize.enabled", true);
  const colorEnabled = get(props, "options.color.enabled", true);
  const lineHeightEnabled = get(props, "options.lineHeight.enabled", true);
  const boldEnabled = get(props, "options.bold.enabled", false);
  const italicEnabled = get(props, "options.italic.enabled", false);
  const underlineEnabled = get(props, "options.underline.enabled", false);

  const [lineHeightValue, setLineHeightValue] = useState(props.defaultValue.lineHeight);
  const [validationResults, setValidationResults] = useState({});

  const projectId = get(client_options, 'selected_folder_meta.project', null)
  const project = projects[projectId]

  let showOnlyDefaultFonts = true


    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

  if (
    project &&
    project.settings &&
    project.settings.default_fonts &&
    project.settings.default_fonts.length
  ) {
    fonts = project.settings.default_fonts
    showOnlyDefaultFonts = project.settings.show_only_default_fonts
  }
    const handleLineHeightChange = (value) => {
        handleChange(value);
        setLineHeightValue(value.lineHeight);
    }
    const handleLineHeightBlur = () => {
        const result = validate(
            { line_height: [{ name: "isNumber", min: 1, max: 100 }] },
            { line_height: lineHeightValue }
        );

        setValidationResults(result.fieldResults);

        if (!result.isFormValid) {
            handleChange({ lineHeight: 1.0 });
            setLineHeightValue(props.defaultValue.lineHeight);
            timeoutRef.current = setTimeout(() => {
                setValidationResults("");
            }, 4000);
            return;
        }

        handleLineHeightChange(lineHeightValue);
    }


  const handleChange = (newValue) => {
    props.onChange(props.fieldName, {
      ...props.defaultValue,
      ...newValue
    });
    props.onBlur(props.fieldName, {
      ...props.defaultValue,
      ...newValue
    });
  };

  const getAlignmentOnProp = () => {
    switch (props.defaultValue.alignment) {
      case "left":
        return <AlignLeftOutlined />;
      case "center":
        return <AlignCenterOutlined />;
      case "right":
        return <AlignRightOutlined />;
      default:
        return <AlignCenterOutlined />;
    }
  };

  const getBackgroundColor = () => {
    if (props.defaultValue?.color) {
      const rgba = hexToRgbA(props.defaultValue.color);
      if (rgba.r + rgba.g + rgba.b >= 650) {
        return "#808080";
      }
    }
    return "#FFFFFF";
  };

    let lineHeightValidationError = '';
    if (validationResults['line_height'] && !validationResults['line_height'].isValid) {
        lineHeightValidationError = validationResults['line_height'].error;
    }

  return (
    <>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          <br />
        </span>
      ) : null}

      <div>
        {fontEnabled && (
          <span>
            <FontFamilyInput
                onChange={(newValue) => {
                  handleChange({ font: newValue });
                }}
                fonts={fonts}
                value={props.defaultValue.font}
                showOnlyDefaultFonts={showOnlyDefaultFonts}
            />
        </span>
        )}

        {lineHeightEnabled && (
            <span>
          <span style={{ marginRight: '-3px' }}>
            <i className="fas fa-line-height"></i>
          </span>
            <InputNumber
                style={{
                  marginLeft: 8,
                  marginRight: 8,
                  width: '20%'
                }}
                step={0.1}
                min={0.0}
                max={100.0}
                disabled={!props.canUpdate}
                defaultValue={props.defaultValue.lineHeight}
                onBlur={() => {
                    handleLineHeightBlur();
                }}
                onChange={(newValue) => {
                  handleLineHeightChange({ lineHeight: newValue });
                }}
                value={numericValue(props.defaultValue.lineHeight)}
            />
            {lineHeightValidationError ? (
                <div className="form-field-error">{lineHeightValidationError}</div>
            ) : (
                ""
            )}
          </span>
        )}
      </div>

      <div style={{display: 'flex', marginTop: '0.5rem' }}>
        {fontSizeEnabled && (
            <span style={{marginRight: '0.5em'}}>
              <InputNumber
                className="number-input ql-size"
                defaultValue={props.defaultValue.fontSize ? Number((String(props.defaultValue.fontSize).split('p'))[0]) : 16}
                value={props.defaultValue.fontSize ? Number((String(props.defaultValue.fontSize).split('p'))[0]) : 16}
                onBlur={(ev) => handleChange({ fontSize: `${ev.target.value}px` })}
                onChange={(ev) => handleChange({ fontSize: `${ev}px` })}
                disabled={!props.canUpdate}
                step={1}
                min={1}
                max={300}
                style={{ width: "4em" }}
              />
              <span>px</span>
            </span>
        )}

        {colorEnabled && (
            <div style={{paddingTop: '0.5rem'}}>
              <ColorPickerTool
                  value={props.defaultValue?.color}
                  onChange={(c) => {
                    handleChange({ color: c.color });
                  }}
                  onClose={(c) => {
                    handleChange({ color: c.color });
                  }}
                  swatches={get(props, 'settings.swatches', [])}
              />
            </div>
        )}

        <div style={{display: 'flex', alignItems: 'center', marginLeft: '1rem'}}>
          {boldEnabled && (
              <>
                <BoldOutlined
                    onClick={() => handleChange({ bold: !props.defaultValue.bold })}
                    style={{
                      color: props.defaultValue.bold ? "#1890ff" : "#595959",
                      cursor: "pointer",
                      marginRight: 8
                    }}
                />
              </>
          )}

          {italicEnabled && (
              <>
                <ItalicOutlined
                    onClick={() => handleChange({ italic: !props.defaultValue.italic })}
                    style={{
                      color: props.defaultValue.italic ? "#1890ff" : "#595959",
                      cursor: "pointer",
                      marginRight: 8
                    }}
                />
              </>
          )}

          {underlineEnabled && (
              <>
                <UnderlineOutlined
                    onClick={() => handleChange({ underline: !props.defaultValue.underline })}
                    style={{
                      color: props.defaultValue.underline ? "#1890ff" : "#595959",
                      cursor: "pointer",
                      marginRight: 8
                    }}
                />
              </>
          )}
        </div>

        {alignmentEnabled && (
            <div style={{marginLeft: '1rem'}}>
                <Tooltip title="Alignment" mouseEnterDelay={0.5}>
                    <Select
                        disabled={!props.canUpdate}
                        className="select-input"
                        defaultValue={props.defaultValue.alignment}
                        onChange={(newValue) => {
                            handleChange({ alignment: newValue });
                        }}
                        value={props.defaultValue.alignment}
                    >
                        {alignments.map((ao: any, iindex: number) => (
                            <Select.Option key={iindex} value={ao.value}>
                                {getAlignmentOnProp()}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </div>
        )}
      </div>



      <div style={{ fontSize: 1.08, backgroundColor: getBackgroundColor() }}>
        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 8,
            borderWidth: 0.25,
            border: ".5px solid",
            borderColor: PDG_COLORS.XLT_GRAY,
            width: "100%",
            height: "100%",
            fontFamily: props.defaultValue.font,
            fontSize: props.defaultValue.fontSize,
            textAlign: props.defaultValue.alignment,
            color: props.defaultValue.color,
            lineHeight: props.defaultValue.lineHeight,
            fontWeight: props.defaultValue.bold ? "bold" : "normal",
            fontStyle: props.defaultValue.italic ? "italic" : "normal",
            textDecoration: props.defaultValue.underline ? "underline" : "none",
            fontVariant: "normal",
            WebkitFontFeatureSettings: 'normal',
          }}
        >
          Sample Line 1<br />
          Sample Line 2
        </div>
      </div>
    </>
  );
};

export default TextStyleInput;
