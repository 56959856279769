import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { columns, sorting_options, wysiwyg } from "../standardized_fields";
import { glenwood_category_shared } from "./glenwood_category_shared";
import { glenwoodFonts, quillFontSizes, textStyleOptions } from "./glenwood_settings";

const { general, list, footer, layout_list, layout_footer, header, subhead } = glenwood_category_shared.field_groups;

const fields = {
  ...glenwood_category_shared.fields
};
delete fields.header_color;
delete fields.upper_horizontal_rule_color;
delete fields.upper_horizontal_rule_weight;
delete fields.lower_horizontal_rule_color;
delete fields.lower_horizontal_rule_weight;
export const glenwood_category_without_giving_level: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.glenwood_category_without_giving_level,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Fund",
  acceptable_folder_types: [FOLDER_TYPE.glenwood_donor],
  tabs: {
    ...glenwood_category_shared.tabs
  },
  field_groups: {
    general,
    header,
    subhead,
    list,
    footer,
    layout_list,
    layout_footer
  },
  fields: {
    ...fields,
    header: {
      type: "hybrid_text_input",
      title: "Header",
      show_char_count: false,
      field_group: "header",
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "26px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "header_fund"
      }
    },
    subhead: {
      type: "hybrid_text_input",
      title: "Subhead",
      show_char_count: false,
      field_group: "subhead",
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "24px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "subhead_fund"
      }
    },
    footer: {
      ...glenwood_category_shared.fields.footer,
      field_group: "general"
    },
    sorting_options: {
      ...sorting_options,
      field_group: "general",
      default: "sorting_order"
    },
    name_list: {
      type: "textstyle",
      title: "Names",
      show_char_count: false,
      field_group: "list",
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 15
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    columns: {
      ...columns,
      tooltip: "Number of columns in donor list",
      default: 3,
      field_group: "list"
    },
    donor_names_row_spacing: {
      type: "number",
      title: "Row Spacing",
      validation: {},
      tooltip: "Padding names / items in relation to font size. i.e: 1.2",
      step: "0.1",
      default: 2,
      field_group: "list"
    },
    giving_level_header: {
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 15,
        ops: []
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      title: "Giving Level List Header",
      field_group: "layout_list"
    },
    scrollable_menu_header: {
      type: "textstyle",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 15,
        ops: []
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      title: "Scrollable Menu Header",
      field_group: "layout_list"
    },
    list_offset_top: {
      ...glenwood_category_shared.fields.list_offset_top,
      validation: {},
      default: "0.0"
    },
    list_margin_left: {
      ...glenwood_category_shared.fields.list_margin_left,
      validation: {},
      default: "0.0"
    },
    list_margin_right: {
      ...glenwood_category_shared.fields.list_margin_right,
      validation: {},
      default: "0.0"
    },
    list_height: {
      ...glenwood_category_shared.fields.list_height,
      validation: {}
    },
    giving_level_header_margin_top: {
      type: "number",
      title: "Giving Level Header Margin Top",
      validation: {},
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0,
      field_group: "layout_list"
    },
    giving_level_header_margin_bottom: {
      type: "number",
      title: "Giving Level Header Margin Bottom",
      validation: {},
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 4,
      field_group: "layout_list"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.glenwood_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.glenwood_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.glenwood_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.glenwood_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodDonorListCategoryWrapper,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  save_options: [...glenwood_category_shared.save_options]
};
