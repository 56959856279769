import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { eugene_stories_shared } from "./eugene_stories_shared";
import { tree_icon_types } from "../tree_icon_types";

export const eugene_stories: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.eugene_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.eugene_template_001,
    FOLDER_TYPE.eugene_template_002,
    FOLDER_TYPE.eugene_template_003,
    FOLDER_TYPE.eugene_template_004
  ],
  tabs: {
    ...eugene_stories_shared.tabs
  },
  field_groups: {
    ...eugene_stories_shared.field_groups
  },
  fields: {
    ...eugene_stories_shared.fields
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  // fields: {
  //   name: {
  //     type: "text",
  //     title: "Title",
  //     validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
  //     show_char_count: false,
  //   },
  //   size_options: {
  //     type: "select",
  //     title: "Size Card Options",
  //     validation: { rules: [{ name: "isRequired" }] },
  //     default: "large",
  //     options: [
  //       {
  //         title: "Small",
  //         value: "small",
  //       },
  //       {
  //         title: "Medium",
  //         value: "medium",
  //       },
  //       {
  //         title: "Large",
  //         value: "large",
  //       },
  //     ],
  //   },
  //   background: {
  //     title: "Background",
  //     type: "file",
  //     slot_type: "dynamic",
  //     limit: 1,
  //     acceptable_contents: ["image/*"],
  //     image_presets: [],
  //     video_presets: [],
  //     aspect: 1.777777778,
  //     recommended_size: {
  //       width: 1920,
  //       height: 1080,
  //     },
  //     container: {
  //       imgCover: IMAGE_ALIGNMENT.CONTAIN,
  //       flexClass: FLEX_CLASSES.FLEX_CENTER,
  //       aspect: 1.777777778,
  //     },
  //   },
  //   color_overlay: { type: 'color', title: 'Color Overlay', default: "" },
  // },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneStoriesWrapper,
    type: PREVIEW_TYPE.COMPONENT,
    react_url: "theme/eugene"
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.eugene_template_001,
      FOLDER_TYPE.eugene_template_002,
      FOLDER_TYPE.eugene_template_003,
      FOLDER_TYPE.eugene_template_004
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "headline",
        title: "Headline",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "subhead",
        title: "Headline",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.eugene_template_002, FOLDER_TYPE.eugene_template_003],
        field: "image_1",
        title: "Media 1",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.eugene_template_003],
        field: "image_2",
        title: "Media 2",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "background",
        title: "Background",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.eugene_template_001,
          FOLDER_TYPE.eugene_template_002,
          FOLDER_TYPE.eugene_template_003,
          FOLDER_TYPE.eugene_template_004
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  client: {
    component_name: COMPONENT_NAME.EugeneStories
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 300,
    columns: 2
  }
};
