import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Tooltip } from "antd";
import _ from "lodash";
import get from 'lodash/get';
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAuditLogsByFolder } from "../../actions/auditLogActions";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import {
  pushDownInheritableFields,
  setUpdateFolderTouch,
  toggleFolderExpanded,
  updateFolder,
  updateResourceTreeFolder,
  updateUploadedResources
} from "../../actions/folderActions";
import { addSubmitFolderCallback } from "../../services/unsavedModalService";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import { hasPermission } from "../../utils/permissionUtils";
import { getCurrentTemplate } from "../../utils/themeUtils";
import AuditLogTable from "../audit_logs/AuditLogTable";
import FontGroupLinkModal from "../font_group/FontGroupLinkModal";
import DynamicForm from "../form/DynamicForm";
import {
  getSelectedFolderDynamicInputFolderResources,
  getSelectedFolderFixedInputFolderResources
} from "../form/input/DynamicFileInput/FileInputService";
import AlertNotification from "../layout/AlertNotification";
import { LT_GRAY, MD_BLUE, MD_WARNING } from "../layout/LayoutConstants";
import TextWithHighlights from "../tree/TextWithHighlights";
import TreeItemIcon from "../tree/TreeItemIcon";
import Spaner from "../utils/Spaner";
import InheritFieldsModal from "./InheritFieldsModal";
import { TREE_TYPE } from "../../shared-global/enums/ui-enums";

const CHUNK_SIZE = 7168000;

class ResourceFolderDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      submitting: false,
      touch: false,
      formFields: {}
    };


    this._dynamicFormRef = null;
  }

  componentDidMount() {
    this._initialLoadTime = new Date().getTime();
    this.uploadToS3.bind(this);
    this.multipartUploadToS3.bind(this);
    this.getS3Url.bind(this);
    if (this.props.selected_folder) {
      const fieldsConfig = _.get(this.props.folder_type, "fields", {});

      this.setState({
        touch: false,
        // originalFieldsConfig: this.props.fieldsConfig,
        fieldsConfig: fieldsConfig
      });

      this.disableFileInputIfNeed(fieldsConfig);
      this.checkContingenciesFields(fieldsConfig);
      this.checkSelectInputButtons(fieldsConfig);
      this.checkProjectSwatches(fieldsConfig);
    }
    this.setState({
      touch: false
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const fieldsConfig = _.get(this.props.folder_type, "fields", {});
    if (this.props.font_groups !== prevProps.font_groups || this.props.selected_folder !== prevProps.selected_folder) {
      this.checkSelectInputButtons(fieldsConfig, true);
    }

    if (this.props.projects !== prevProps.projects) {
      this.checkProjectSwatches(fieldsConfig, true);
    }

    if (prevProps.s3Cache !== this.props.s3Cache) {
      this.resetContentManager();
    }

    if (this.props.selected_folder) {
      const initialFields = this.removeContentManagerFieldsAndPopulateDefaultValues(this.props.selected_folder.fields ?? {});

      const initialState = {
        name: this.props.selected_folder.name,
        ...initialFields
      };
      this.setState({
        touch: false
        // fieldsInitialState: initialState,
      });
      let initialStateTouch = initialState;
      if (this._dynamicFormRef) {
        const fields = this._dynamicFormRef.getFieldValues();
        initialStateTouch =
          this.removeContentManagerFieldsAndPopulateDefaultValues(
            this._dynamicFormRef.getFieldValues(),
            this.state.fieldsConfig
          );
        // add submit callback up submit in unsaved modal
        addSubmitFolderCallback(() => {
          const myFieldValues = this._dynamicFormRef.getFieldValues();
          this.onSubmit(myFieldValues);
        });
        if (!this.checkForChanges(prevState.formFields)) {
          this.props.setUpdateFolderTouch({
            touch: false,
            initialState: initialStateTouch
          });
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      selected_folder,
      folder_type,
      folder_types,
      aws_instance,
      resources,
      resource__folders,
      canUpdate,
      user,
      grouped_permissions,
      folder_audit_logs,
      folder_paths
    } = this.props;
    const { submitting, fieldsConfig } = this.state;

    if (
      nextProps.selected_folder !== selected_folder ||
      // nextProps.folder_type !== folder_type ||
      !_.isEqual(nextProps.folder_type, folder_type) ||
      nextProps.folder_types !== folder_types ||
      nextProps.aws_instance !== aws_instance ||
      nextProps.resources !== resources ||
      nextProps.resource__folders !== resource__folders ||
      nextProps.canUpdate !== canUpdate ||
      nextProps.user !== user ||
      nextProps.grouped_permissions !== grouped_permissions ||
      nextState.submitting !== submitting ||
      (nextProps.folder_audit_logs &&
        folder_audit_logs &&
        !_.isEqual(
          [...nextProps.folder_audit_logs].sort(),
          [...folder_audit_logs].sort()
        )) ||
      nextProps.s3Cache !== this.props.s3Cache ||
      nextProps.folder_paths !== this.props.folder_paths ||
      nextState.fieldsConfig !== fieldsConfig ||
      nextProps.font_groups !== this.props.font_groups ||
      nextProps.projects !== this.props.projects
    ) {
      return true;
    }

    if (this.state.auditLogModalVisible !== nextState.auditLogModalVisible) {
      return true;
    }
    if (
      this.state.pushDownFieldsModalVisible !==
      nextState.pushDownFieldsModalVisible
    ) {
      return true;
    }
    // because notification daemon if fired here
    // we should no render because it can clean a intermediate state (for example upload an image in memory)
    return false;
  }

  componentWillUnmount() {
    let list_view_last_selected_folder = _.get(
      this.props,
      "list_view_last_selected_folder",
      null
    );
    if (list_view_last_selected_folder) {
      const updateClientOptions = {
        list_view_last_select_folder: null,
        list_view_show_in_tab: false
      };
      this.props.setClientOptions(updateClientOptions);
    }
  }

  formatValuesForSubmit = (values) => {
    const { fieldsConfig } = this.state;
    let newValues = _.cloneDeep(values);

    let fields = fieldsConfig;
    for (let name in fields) {
      if (fields[name].type === "file") {
        // all handle 'file' type are in the content manager
        delete newValues[name];
      }
    }
    return newValues;
  };

  updateInitialStateForFolderTouch() {
    if (this._dynamicFormRef) {
      const initialStateTouch =
        this.removeContentManagerFieldsAndPopulateDefaultValues(
          this._dynamicFormRef.getFieldValues()
        );
      this.props.setUpdateFolderTouch({
        touch: false,
        initialState: initialStateTouch
      });
    }
  }

  onSubmit = (values, onSuccess) => {
    this.setState({
      submitting: true,
      auditLogModalVisible: false,
      pushDownFieldsModalVisible: false
    });
    let newValues = this.formatValuesForSubmit(values);
    let name = null;
    if (newValues.name) {
      name = newValues.name;
      delete newValues.name;
    }

    const onError = (e) => this.onError(e);

    this.props.updateResourceTreeFolder(
      this.props.client_options.resource_selected_folder,
      name,
      async () => {
        this.setState({
          submitting: false
        });
        this.onSuccess();
        this.resetAllContentManager();

        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
      false
    );
  };

  getFileFields = (values) => {
    const { fieldsConfig } = this.state;
    let foundFields = [];
    let fields = fieldsConfig;
    for (let name in fields) {
      if (fields[name].type === "file") {
        if (values[name] && values[name].length > 0) {
          for (let j = 0; j < values[name].length; j++) {
            if (
              values[name][j].status === "loaded" &&
              values[name][j].type === "file"
            ) {
              let str = values[name][j].fileName.split(".");
              let lowercaseExt = str[str.length - 1].toLowerCase();
              let newName = values[name][j].randomFileName + "." + lowercaseExt;
              foundFields.push({
                name: newName,
                file: values[name][j].file,
                chunks: values[name][j].chunks
              });
              delete values[name][j].file;
            }
          }
        }
      }
    }
    return foundFields;
  };

  getSelectedFolderFixedInputFolderResources = () => {
    const {
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    } = this.props;
    const fileFields = getSelectedFolderFixedInputFolderResources(
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    );
    return fileFields;
  };

  getSelectedFolderDynamicInputFolderResources = () => {
    const {
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    } = this.props;
    const fileFields = getSelectedFolderDynamicInputFolderResources(
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    );

    return fileFields;
  };

  uploadToS3 = async (field, progressCb) => {
    const s3 = new this.props.aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2"
    });
    let params = {
      Body: field.file,
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/temp`,
      Key: field.name,
      CacheControl: "no-cache, must-revalidate"
    };
    try {
      await new Promise((resolve, reject) => {
        s3.putObject(params, function (err, data) {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }).on("httpUploadProgress", function (progress) {
          if (progressCb && progress.lengthComputable) {
            progressCb((progress.loaded / progress.total) * 100);
          }
        });
      });
      return true;
    } catch (e) {
      console.log("error uploading:", e);
      this.onError(e);
    }
  };

  multipartUploadToS3 = async (field, progressCb) => {
    const s3 = new this.props.aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2"
    });

    let multiPartResponseArray = [];
    let multipartResponse = await new Promise((resolve, reject) => {
      s3.createMultipartUpload(
        {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
          ContentType: field.file.type,
          CacheControl: "no-cache, must-revalidate"
        },
        function (err, data) {
          if (err) {
            return reject(err);
          }

          return resolve(data);
        }
      );
    });

    let errors = 0;
    for (let j = 0; j < field.chunks.length; j++) {
      try {
        let uploadResponse = await new Promise((resolve, reject) => {
          s3.uploadPart(
            {
              Body: field.chunks[j],
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
              PartNumber: j + 1,
              UploadId: multipartResponse.UploadId
            },
            function (err, data) {
              if (err) {
                return reject(err);
              }
              return resolve(data);
            }
          ).on("httpUploadProgress", function (progress) {
            const percentage =
              ((progress.loaded / progress.total) * 100) / field.chunks.length +
              (100 / field.chunks.length) * j;
            if (progressCb && progress.lengthComputable) {
              progressCb(percentage);
            }
          });
        });
        multiPartResponseArray.push({
          ETag: uploadResponse.ETag,
          PartNumber: j + 1
        });
        errors = 0;
      } catch (e) {
        errors++;
        j--;
      }

      if (errors === 3) {
        this.props.onError("There's been an error trying to upload files");
        await new Promise((resolve, reject) => {
          s3.abortMultipartUpload(
            {
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
              UploadId: multipartResponse.UploadId
            },
            function () {
              return resolve();
            }
          );
        });
      }
    }

    await new Promise((resolve, reject) => {
      s3.completeMultipartUpload(
        {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_BUCKET_PATH}/temp/${field.name}`,
          MultipartUpload: { Parts: multiPartResponseArray },
          UploadId: multipartResponse.UploadId
        },
        function (err, data) {
          if (err) {
            return reject(err);
          }
          return resolve(data);
        }
      );
    });

    return true;
  };

  getS3Url = (fileName, bucketFolder, companyId, status) => {
    let params = {};
    const s3 = new this.props.aws_instance.S3({
      apiVersion: "2006-03-01",
      region: "us-west-2"
    });
    if (status === "created") {
      params = { Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/temp`, Key: fileName };
    } else {
      params = {
        Bucket:
          `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_BUCKET_PATH}/companies/` +
          companyId +
          "/" +
          bucketFolder,
        Key: fileName
      };
    }
    const url = s3.getSignedUrl("getObject", params);
    return url;
  };

  onError = (e) => {
    console.log(e);
    AlertNotification(
      "error",
      "Form error",
      e.message || "An error has occurred"
    );
  };

  onSuccess = () => {
    AlertNotification(
      "success",
      "Record updated",
      "The record was updated successfully"
    );
  };

  onValidationError = () => {
    AlertNotification(
      "error",
      "Validation Error",
      "One or more fields have incorrect values. Please verify you entered the right information and try again."
    );
  };

  toggleAuditLogModal = (toggleOn) => {
    if (_.get(this.props, "selected_folder.id", null)) {
      this.props.getAuditLogsByFolder(this.props.selected_folder.id);
      this.setState({
        auditLogModalVisible: toggleOn
      });
    } else if (toggleOn === false) {
      this.setState({
        auditLogModalVisible: toggleOn
      });
    }
  };

  togglePushDownFieldsModal = (toggleOn) => {
    if (_.get(this.props, "selected_folder.id", null)) {
      this.setState({
        pushDownFieldsModalVisible: toggleOn
      });
    } else {
      this.setState({
        pushDownFieldsModalVisible: false
      });
    }
  };

  pushDownFields = (fieldsToInherit) => {
    this.setState({
      submitting: true
    });
    this.togglePushDownFieldsModal(false);
    this.props.pushDownInheritableFields(
      this.props.selected_folder?.id,
      fieldsToInherit,
      () => {
        this.setState({
          submitting: false
        });
        this.props.setClientOptions({
          lock_screen: false,
          message_for_progress: null
        });
        AlertNotification(
          "success",
          "Record updated",
          "Default settings have been pushed down"
        );
      },
      () => {
        this.setState({
          submitting: false
        });
      }
    );
  };

  renderCustomTitle = (onEditableContentChanged = null) => {
    const folder_type =
      this.props.client_options.resource_selected_folder && this.props.resourcetree_folders[this.props.client_options.resource_selected_folder]
        ? this.props.resourcetree_folders[this.props.client_options.resource_selected_folder].folder_type
        : null;
    let folder_name =
      this.props.client_options.resource_selected_folder && this.props.resourcetree_folders[this.props.client_options.resource_selected_folder]
        ? this.props.resourcetree_folders[this.props.client_options.resource_selected_folder].name
        : "";

    // tree icon type with default fallback
    const open_tree_icon = _.get(folder_type, "tree_icon_types.open", {
      icon_type: "fontawesome",
      options: {
        class_name: "fa-lg",
        icon: ["fas", "folder-open"]
      }
    });

    let subHeader = null;
    let readOnly = get(this.state.fieldsConfig, 'name.read_only', false);

    // add subheader TODO: hacky top property or at least dynamic from icon config
    if (folder_type && folder_type.display_name) {
      subHeader = (
        <span
          style={{
            fontStyle: "italic",
            color: LT_GRAY,
            fontSize: 14
          }}
        >
          <br />

          {open_tree_icon.icon_type === "custom-svg" ? (
            <span
              style={{
                position: "relative",
                overflow: "visible",
                width: 32,
                display: "inline-block"
              }}
            >
              <span style={{ position: "absolute", top: -23 }}>
                <TreeItemIcon config={open_tree_icon} context="folder-detail" />
              </span>
            </span>
          ) : (
            <TreeItemIcon config={open_tree_icon} context="folder-detail" />
          )}

          <TextWithHighlights
            text={folder_type.display_name}
            highlight={this.props.searchStringHighlight}
            highlightStyle={{ backgroundColor: MD_WARNING }}
          />
        </span>
      );
    }



    return (
      <>
        <div
          style={{
            color: MD_BLUE,
            fontSize: 20
          }}
        >
          <b>{folder_name}</b>
        </div>

        <span>{subHeader}</span>
        <Spaner width="sm" />

        {this.props.folder_type && this.props.folder_type.font_groups && this.props.folder_type.font_groups.enabled && this.props.user.system_admin && (
          <FontGroupLinkModal
            getFieldValues={() => this._dynamicFormRef.getFieldValues()}
            folder_type={this.props.folder_type}
          />
        )}
        <br />
        <br />
      </>
    );
  };

  disableFileInputIfNeed = (fieldsConfig) => {
    this.disableFileInputByPermissionIfNeed(fieldsConfig);
  };

  disableFileInputByPermissionIfNeed = (fieldsConfig) => {
    const { grouped_permissions, user } = this.props;
    // Add disabled to all file inputs if you don't have the create resource permission
    // or if the resource are processing

    let newFieldsConfig = { ...fieldsConfig };

    if (
      !hasPermission(
        grouped_permissions,
        "resource",
        "create",
        user,
        null,
        null,
        user.company
      )
    ) {
      Object.keys(newFieldsConfig).forEach((key) => {
        const value = newFieldsConfig[key];
        if (value.type === "file") {
          // Disable all file types.
          value["disabled"] = true;
        }
      });
    }
    this.setState({
      fieldsConfig: newFieldsConfig
    });
  };

  resetContentManager = (field) => {
    let fixedInputFields = this.getSelectedFolderFixedInputFolderResources();
    let dynamicInputFields =
      this.getSelectedFolderDynamicInputFolderResources();
    const fileInputFields = { ...fixedInputFields, ...dynamicInputFields };
    if (this._dynamicFormRef) {
      this._dynamicFormRef.overrideFieldValue(field, fileInputFields[field]);
    }
  };

  getAllContentManagerFields = () => {
    const contentMangerFieldType = [];

    let fields = this.state.fieldsConfig;
    for (let name in fields) {
      if (fields[name].type === "file") {
        contentMangerFieldType.push(name);
      }
    }

    return contentMangerFieldType;
  };

  resetAllContentManager = () => {
    try {
      let fixedInputFields = this.getSelectedFolderFixedInputFolderResources();
      let dynamicInputFields =
        this.getSelectedFolderDynamicInputFolderResources();
      const fileInputFields = { ...fixedInputFields, ...dynamicInputFields };
      if (this._dynamicFormRef) {
        const allFields = this.getAllContentManagerFields();
        allFields.forEach((value) => {
          this._dynamicFormRef.overrideFieldValue(
            value,
            fileInputFields[value]
          );
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  setUpdateFolderTouchSafe = (touch) => {
    if (!this.props.update_folder_touch.touch) {
      this.props.setUpdateFolderTouch({
        ...this.props.update_folder_touch,
        touch
      });
    }
  };

  removeContentManagerFieldsAndPopulateDefaultValues = (fields) => {
    const { fieldsConfig } = this.state;
    const copyFields = {};

    let folder_type_fields = fieldsConfig;
    Object.keys(folder_type_fields).forEach(function (key) {
      switch (folder_type_fields[key].type) {
        case "file":
          // there is a file type (content manager) so do not add to copyFields
          break;
        case "wysiwyg":
          const wysiwygContent =
            fields[key] !== undefined
              ? fields[key]
              : folder_type_fields[key].default;
          copyFields[key] = JSON.stringify(wysiwygContent);
          break;
        case "text":
          copyFields[key] = fields[key] !== undefined ? fields[key] : "";
          break;
        case 'integer':
        case "number":
          copyFields[key] =
            fields[key] !== undefined && fields[key] !== null ? fields[key] : 0;
          break;
        default:
          // add it to the
          copyFields[key] =
            fields[key] !== undefined
              ? fields[key]
              : folder_type_fields[key].default;
          break;
      }
    });
    return {
      name: this.props.selected_folder.name,
      ...copyFields
    };
  };

  checkForChanges = (fields) => {
    const { update_folder_touch } = this.props;
    const { fieldsConfig } = this.state;
    if (update_folder_touch) {
      const copyFields =
        this.removeContentManagerFieldsAndPopulateDefaultValues(
          fields,
          fieldsConfig
        );
      const fieldsInitialState = update_folder_touch.initialState;
      const areEqual = _.isEqual(fieldsInitialState, copyFields);
      return fieldsInitialState && !areEqual;
    }
  };

  checkAndUpdateUpdateFolderTouch = (fields) => {
    // this is temporary / hack, but generaly works
    const now = new Date();
    if (now.getTime() - this._initialLoadTime < 500) {
      return;
    }
    const shouldSetTouch = this.checkForChanges(fields);
    if (shouldSetTouch) {
      this.setUpdateFolderTouchSafe(true);
    }
  }

  handleTouchFormByForm = (one, two) => {
    if (this._dynamicFormRef) {
      let formFields = this._dynamicFormRef.getFieldValues();
      formFields = { ...formFields, ...one }
      this.setState({
        formFields
      })
      this.checkAndUpdateUpdateFolderTouch(formFields);
    }
  };

  /**
   * go back the the last list view visited
   */
  goBackToListView = () => {
    let folderId = _.get(this.props, "list_view_last_selected_folder", null);

    const treeId = `folder-${folderId}`;

    const updateClientOptions = {
      list_view_last_select_folder: null,
      list_view_show_in_tab: true,
      applied_content_tree_selected_id: treeId,
      applied_content_modal_selected_folder: folderId
    };

    this.props.setClientOptions(updateClientOptions);
    this.props.toggleFolderExpanded(treeId, false, true, this.props.client_options.treemode);
  };

  /**
   * this change handle is only for rich text
   */
  buildChangeHandlersForRichText = () => {
    const handler = [];

    let folder_type_fields = {
      name: {
        show_char_count: false,
        title: 'Name',
        type: "text",
        validation: {
          rules: [{
            name: "isString",
            max: 75,
            min: 1,
          }]
        }
      }
    };
    Object.keys(folder_type_fields).forEach((key) => {
      if (folder_type_fields[key].type === "wysiwyg") {
        handler.push({
          name: key,
          callBackFunction: (name, value, fieldValues) => {
            this.checkAndUpdateUpdateFolderTouch(fieldValues);
          }
        });
      }
    });

    return handler;
  };

  checkContingenciesFields = (fieldsConfig) => {
    let fieldValues = _.get(this.props.selected_folder, "fields", {});

    Object.keys(fieldsConfig).forEach((conditional_trigger_key) => {
      const conditional_trigger =
        fieldsConfig[conditional_trigger_key].conditional_trigger;

      if (conditional_trigger) {
        const defaultValue = _.get(
          fieldsConfig,
          `[${conditional_trigger_key}].default`,
          ""
        );
        const value = _.get(
          fieldValues,
          `[${conditional_trigger_key}]`,
          defaultValue
        );
        this.callbackForSelectBoxes(
          conditional_trigger_key,
          value,
          fieldsConfig
        );
      }
    });
  };

  setFieldsToFontGroups = (fontGroupId) => {
    const fontGroup = this.props.font_groups[fontGroupId];
    if (fontGroup && this._dynamicFormRef) {
      this._dynamicFormRef.mergeFieldValues({ ...fontGroup.contents, font_groups: fontGroupId });
    }
  }

  checkSelectInputButtons = (fieldsConfig, forceOptionsUpdate = false) => {
    const newConfig = { ...fieldsConfig };
    Object.keys(fieldsConfig).forEach(fieldName => {
      const fieldConfig = fieldsConfig[fieldName];
      if (fieldConfig.type === "select") {
        if (!fieldConfig.options || forceOptionsUpdate) {
          if (fieldConfig.settings && fieldConfig.settings.options && fieldConfig.settings.options === 'font_groups') {
            if (this.props.font_groups && Object.keys(this.props.font_groups).length > 0) {
              newConfig[fieldName].options = Object.values(this.props.font_groups)
                .filter(fg => fg.folder_type === this.props.folder_type.name || this.props.folder_type.font_groups.compatible_folder_types.includes(fg.folder_type))
                .map(fg => ({
                  title: fg.name,
                  value: fg.id
                }));
            } else {
              newConfig[fieldName].options = [];
            }

            this.setState({ fieldsConfig: newConfig });
          }
        }
      }
    });
  }

  callbackForSelectBoxes = (conditional_trigger_key, value, fieldsConfig) => {
    const newConfig = { ...fieldsConfig };
    Object.keys(fieldsConfig).forEach((key) => {
      if (fieldsConfig[key].conditional_show?.length) {
        const show = fieldsConfig[key].conditional_show.some(
          (cs) => cs.field === conditional_trigger_key && cs.value === value
        );
        newConfig[key]["show_conditional_met"] = show;
      } else {
        // no condition, always show
        newConfig[key]["show_conditional_met"] = true;
      }
    });

    this.setState({
      fieldsConfig: newConfig
    });
  };

  buildChangeHandlersForContingentFields = () => {
    const fieldsConfig = {
      name: {
        show_char_count: false,
        title: 'Name',
        type: "text",
        validation: {
          rules: [{
            name: "isString",
            max: 75,
            min: 1,
          }]
        }
      }
    };
    const handler = [];

    Object.keys(fieldsConfig).forEach((conditional_trigger_key) => {
      const conditional_trigger =
        fieldsConfig[conditional_trigger_key].conditional_trigger;

      if (conditional_trigger) {
        handler.push({
          name: conditional_trigger_key,
          callBackFunction: (name, value, fieldValues) => {
            this.callbackForSelectBoxes(
              conditional_trigger_key,
              value,
              fieldsConfig
            );
          }
        });
      }
    });

    return handler;
  };

  buildChangeHandlersForFontGroups = () => {
    const fieldsConfig = {
      name: {
        show_char_count: false,
        title: 'Name',
        type: "text",
        validation: {
          rules: [{
            name: "isString",
            max: 75,
            min: 1,
          }]
        }
      }
    };;
    const handler = [];

    Object.keys(fieldsConfig).forEach((key) => {
      if (fieldsConfig[key].settings && fieldsConfig[key].settings.callback_on_change) {
        if (fieldsConfig[key].settings.callback_on_change === 'apply_font_group') {
          handler.push({
            name: key,
            callBackFunction: (_n, value) => {
              this.setFieldsToFontGroups(value);
            }
          })
        }
      }
    });

    return handler;
  }

  cloneCurrentFolder = () => {

  }

  handleCustomButtonActions = (customButtonActionName) => {
    const { client_options } = this.props;
    const { selected_folder_meta } =
      client_options;
    const stringyfiedPath = selected_folder_meta.path.join("/");
    switch (customButtonActionName) {
      case "push_down_fields":
        this.togglePushDownFieldsModal(true);
        break;
      case "clone_folder":
        this.props.setClientOptions({ show_clone_modal: true, clone_folder_id: this.props.folder__folder_id })
      default:
        break;
    }
  };

  checkProjectSwatches = (fieldsConfig, forceOptionsUpdate = false) => {
    const projectId = get(this.props.client_options, 'selected_folder_meta.project', null);
    if (projectId) {
      const project = this.props.projects[projectId];
      const newConfig = { ...fieldsConfig };
      Object.keys(fieldsConfig).forEach(fieldName => {
        const fieldConfig = fieldsConfig[fieldName];
        const types = ['hybrid_text_input', 'color', 'wysiwyg', 'textstyle'];
        if (types.indexOf(fieldConfig.type) > -1) {
          if (!fieldConfig.options || forceOptionsUpdate) {
            if (!fieldConfig.settings) {
              newConfig[fieldName]['settings'] = {};
            }

            newConfig[fieldName]['settings']['swatches'] = get(project, 'settings.swatches', []);
            this.setState({ fieldsConfig: newConfig });
          }
        }
      });
    }
  }

  render() {
    const fieldsConfig = {
      name: {
        show_char_count: false,
        title: 'Name',
        type: "text",
        validation: {
          rules: [{
            name: "isString",
            max: 75,
            min: 1,
          }]
        }
      }
    };


    let fieldValues = {
      name: {
        show_char_count: false,
        title: 'Name',
        type: "text",
        validation: {
          rules: [{
            name: "isString",
            max: 75,
            min: 1,
          }]
        }
      }
    }

    let fixedInputFields = this.getSelectedFolderFixedInputFolderResources();
    let dynamicInputFields =
      this.getSelectedFolderDynamicInputFolderResources();
    let fieldGroupsConfig = _.get(this.props.folder_type, "field_groups", {});
    const tabsConfig = _.get(this.props.folder_type, "tabs", {});
    const saveOptions = _.get(this.props.folder_type, "save_options", []);

    let list_view_last_selected_folder = _.get(
      this.props,
      "list_view_last_selected_folder",
      null
    );
    let selectedFolderTreePath = "";
    let folderPathFields = {};

    if (
      this.props.client_options.selected_folder_meta &&
      this.props.client_options.selected_folder_meta.path
    ) {
      selectedFolderTreePath =
        this.props.client_options.selected_folder_meta.path.join("/");
    }

    if (selectedFolderTreePath !== "") {
      if (
        this.props.folder_paths[selectedFolderTreePath] &&
        this.props.folder_paths[selectedFolderTreePath].inheritable_fields
      ) {
        folderPathFields = {
          ...this.props.folder_paths[selectedFolderTreePath].inheritable_fields
        };
      }
    }

    let filteredFieldValuesByNonInheritable = {};
    const selectedFolderFolderType = this.props.folder_type;

    if (selectedFolderFolderType && fieldValues) {
      Object.keys(selectedFolderFolderType.fields)
        .filter((f) => !selectedFolderFolderType.fields[f].is_inheritable)
        .forEach((key) => {
          if (!_.isNil(fieldValues[key])) {
            filteredFieldValuesByNonInheritable[key] = fieldValues[key];
          }
        });
    } else {
      filteredFieldValuesByNonInheritable = fieldValues;
    }

    fieldValues = {
      ...filteredFieldValuesByNonInheritable,
      ...fixedInputFields,
      ...dynamicInputFields,
      ...folderPathFields
    };

    if (this.props.client_options.resource_selected_folder && this.props.resourcetree_folders[this.props.client_options.resource_selected_folder]) {
      let name = this.props.resourcetree_folders[this.props.client_options.resource_selected_folder].name
      if (this._dynamicFormRef) {
        name = this._dynamicFormRef.getNameValue()
      }
      fieldValues.name = name;
    }
    let mode = "add";
    if (window.location.search.indexOf("edit") !== -1) {
      mode = "edit";
    }

    // enable field grouping with more then one category or audit logs enabled
    let enableFieldGrouping = Object.keys(fieldGroupsConfig).length > 1;

    const currentTemplate = 'Resource Folder';
    const themeSettings = _.get(this.props.theme_settings, `${currentTemplate}`, {});

    return (
      <>
        <Modal
          width="90vw"
          visible={this.state.auditLogModalVisible === true}
          onOk={() => this.toggleAuditLogModal(false)}
          onCancel={() => this.toggleAuditLogModal(false)}
          destroyOnClose="true"
        >
          <AuditLogTable dataSource={this.props.folder_audit_logs} />
        </Modal>
        <InheritFieldsModal
          onCancel={() => this.togglePushDownFieldsModal(false)}
          onOk={(fieldsToInherit) => {
            // LOCK THE PAGE
            this.props.setClientOptions({
              indeterminate: true,
              lock_screen: true,
              message_for_progress:
                "Pushing down settings to child items. This may take a minute."
            });
            const myFieldValues = this._dynamicFormRef.getFieldValues();
            this.onSubmit(myFieldValues, () =>
              this.pushDownFields(fieldsToInherit)
            );
          }}
          selectedFolder={this.props.selected_folder}
          visible={this.state.pushDownFieldsModalVisible === true}
        />
        <div id="folder_detail_form_wrapper">
          <DynamicForm
            ref={(el) => (this._dynamicFormRef = el)}
            key={_.get(this.props, "client_options.resource_selected_folder", null)}
            id={this.props.client_options.resource_selected_folder}
            name={(f) => this.renderCustomTitle(f)}
            fieldsConfig={fieldsConfig}
            fieldGroupsConfig={fieldGroupsConfig}
            tabsConfig={tabsConfig}
            fieldValues={fieldValues}
            folderTypes={this.props.folder_types}
            mode={mode}
            onSubmit={this.onSubmit}
            submitting={this.state.submitting}
            onError={this.onError}
            onValidationError={this.onValidationError}
            canUpdate={this.props.canUpdate}
            enableFieldGrouping={enableFieldGrouping}
            showHistory={true}
            resetContentManager={this.resetContentManager}
            onChangeHandlers={[
              ...this.buildChangeHandlersForRichText(),
              ...this.buildChangeHandlersForContingentFields(),
              ...this.buildChangeHandlersForFontGroups(),
            ]}
            onBlur={this.handleTouchFormByForm}
            folderHasResourcesBeingProcessed={
              this.state.folderHasResourcesBeingProcessed
            }
            handleCustomButtonActions={this.handleCustomButtonActions}
            buttons={saveOptions}
            themeSettings={themeSettings}
            folderType={this.props.folder_type}
            parentFolderId={this.props.parent__folder_id}
            clientOptions={this.props.client_options}
          />
        </div>
      </>
    );
  }
}

/**
 * check if user have a restriction permission on this folder
 */
const hasRestrictPermissionInFolder = (appliedContent, projectId) => {
  const restricts = _.get(appliedContent, "restrict", []);
  const restrictFinded = restricts.find((r) => r.project === projectId);
  return !!restrictFinded;
};

/**
 * check if they have a grant permission for "applied_content" with update: true,
 * scope "project", project id of the folder
 */
const hasUpdatePermissionForProjectInFolder = (grants, projectId) => {
  const grantFinded = grants.find(
    (g) => g.scope === "project" && g.project === projectId
  );
  let hasPermission = !!grantFinded && grantFinded.update;
  return hasPermission;
};

/**
 * // check if they have a grant permission for "applied_content" with update: true scope "company"
 */
const hasUpdatePermissionForCompany = (grants) => {
  const grantFinded = grants.find((g) => g.scope === "company");
  let hasPermission = !!grantFinded && grantFinded.update;
  return hasPermission;
};

/**
 * has update permission in this folder
 */
const hasUpdatePermissionInFolder = (appliedContent, projectId) => {
  const grants = _.get(appliedContent, "grant", []);
  let hasPermission =
    hasUpdatePermissionForCompany(grants) ||
    hasUpdatePermissionForProjectInFolder(grants, projectId);

  return hasPermission;
};

/**
 * check if user have update permission on this folder
 */
const hasUpdatePermissionFolder = (state, projectId) => {
  const applied_content = state.data.user_permissions_grouped.applied_content;

  const hasRestriction = hasRestrictPermissionInFolder(
    applied_content,
    projectId
  );
  const hasUpdatePermission = hasUpdatePermissionInFolder(
    applied_content,
    projectId
  );
  const hasPermission = !hasRestriction && hasUpdatePermission;

  return hasPermission;
};

/**
 * TODO find correct project id
 */

const mapStateToProps = (state) => {
  const projectId =
    state.client_options.selected_folder_meta.project || -1;
  let folderAuditLogs = [];

  let selected_folder = null;
  let folder_type = null;
  let parent__folder_id = null;
  let folder__folder_id = null
  const path = _.get(
    state.client_options,
    "selected_folder_meta.path",
    []
  );

  if (state.client_options.applied_content_selected_folder) {
    selected_folder =
      state.data.folders[
      state.client_options.applied_content_selected_folder
      ];
    if (!selected_folder) {
      return {
        render: false
      };
    }
    folder_type = state.data.folder_types[selected_folder.folder_type];
    folderAuditLogs = arrayFromKeyedObject(state.data.audit_logs).filter(
      (x) => x.record_type === "folder" && x.record_id === selected_folder.id
    );
    folderAuditLogs.forEach((x) => {
      if (x.user && state.data.users && state.data.users[x.user]) {
        const current_user = state.data.users[x.user];
        x.user_name = current_user.first_name + " " + current_user.last_name;
        x.email = current_user.email;
      }
    });
  }

  if (
    state.client_options &&
    state.client_options.selected_folder_meta &&
    state.client_options.selected_folder_meta.folder__folder_id
  ) {
    folder__folder_id =
      state.client_options.selected_folder_meta.folder__folder_id;
    const folder__folder = state.data.folder__folders[folder__folder_id];
    parent__folder_id = _.get(folder__folder, "parent_folder", null);
  }

  const canUpdate = hasUpdatePermissionFolder(state, projectId);
  const update_folder_touch = state.data.update_folder_touch;
  const list_view_last_selected_folder = _.get(
    state,
    "client_options.list_view_last_select_folder",
    null
  );



  return {
    client_options: state.client_options,
    render: true,
    folder_paths: state.data.folder_paths,
    folder__folders: state.data.folder__folders,
    resourcetree_folders: state.data.resourcetree_folders,
    selected_folder: selected_folder,
    folder_type: folder_type,
    folder_types: state.data.folder_types,
    aws_instance: state.data.aws_instance,
    resources: state.data.resources,
    resource__folders: state.data.resource__folders,
    canUpdate,
    user: state.data.user,
    grouped_permissions: state.data.user_permissions_grouped,
    searchStringHighlight: state.client_options.searchStringHighlight,
    folder_audit_logs: folderAuditLogs,
    s3Cache: state.data.s3Cache,
    folder_status: state.data.folder__status,
    update_folder_touch,
    list_view_last_selected_folder,
    parent__folder_id,
    folder__folder_id,
    path,
    font_groups: state.data.font_groups,
    projects: state.data.projects,
    theme_settings: state.data.theme_settings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFolder: (id, name, data, path, onSuccess, onFail, updateS3Cache) => {
      dispatch(
        updateFolder(id, name, data, path, onSuccess, onFail, updateS3Cache)
      );
    },
    updateResourceTreeFolder: (id, name, onSuccess, onFail) => {
      dispatch(
        updateResourceTreeFolder(id, name, onSuccess, onFail)
      );
    },
    updateUploadedResources: (fileNames, folderId) => {
      dispatch(updateUploadedResources(fileNames, folderId));
    },
    setClientOptions: (client_options) => {
      dispatch(setClientOptions(client_options));
    },
    getAuditLogsByFolder: (folder_id) => {
      dispatch(getAuditLogsByFolder(folder_id));
    },
    setUpdateFolderTouch: (touch) => {
      dispatch(setUpdateFolderTouch(touch));
    },
    toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
      dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode));
    },
    pushDownInheritableFields: (
      folderId,
      fieldsToInherit,
      onSuccess,
      onFail
    ) => {
      dispatch(
        pushDownInheritableFields(folderId, fieldsToInherit, onSuccess, onFail)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceFolderDetailForm);
