
export default {
  field_groups: {
    default: {
      title: 'User:',
      default_expanded: true
    }
  },
  fields: {
    roles: {
      type: 'select',
      title: "Roles:",
      validation: {
        rules: [
          { name: 'isRequired' }
        ]
      }
    },
    projects: {
      type: 'select',
      title: "Projects:",
      onc: null, //this.onFieldChange,
      validation: {
        rules: [{ name: 'isRequired' }]
      }
    }
  }
};