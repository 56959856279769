import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { tree_icon_types } from "../tree_icon_types";

export const naples_content_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.naples_content_donor_lists,
  display_name: "Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.naples_fund_folder_red, FOLDER_TYPE.naples_fund_folder_blue],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "1x3", orientation: "landscape", horizontal: 1, vertical: 3 }]
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 300,
    columns: 2
  }
};
