import { object, number, string, mixed } from 'yup';
import { FOLDER_TYPE } from '../../shared-global/enums/folder-type-enums';

export const createFontGroupValidationSchema = object().shape({
  name: string().required(),
  folder_type: mixed<FOLDER_TYPE>().oneOf(Object.values(FOLDER_TYPE)).required(),
  company: number().required(),
  contents: object().nonNullable()
});

export const updateFontGroupValidationSchema = object().shape({
  id: number().required(),
  name: string().required(),
  contents: object().nonNullable()
});

export const destroyFontGroupValidationSchema = object().shape({
  id: number().required(),
});
