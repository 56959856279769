import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name_text_area } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";

export const boston_donor: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.boston_donor,
  display_name: "Donor",
  fields: {
    name: {
      ...name_text_area,
      tooltip: "This name will be used",
      settings: {
        ...name_text_area.settings,
        crossButton: true
      }
    },
    name_diamond_platinum_list: {
      ...name_text_area,
      title: "Name Diamond/Platinum",
      validation: null
    },
    sorting_order: {
      type: "text",
      title: "Sorting Order",
      show_char_count: false,
      validation: { rules: [{ name: "isString", min: 0, max: 75 }] },
      default: ""
    }
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name", "sorting_order"]
  },
  preview: [
    {
      aspect_ratio: 4.444444444,
      component_name: COMPONENT_NAME.BostonDonorSeaDonor,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: false,
      preview_tab_alias: "Donor Sea",
      use_if_parent_is: FOLDER_TYPE.boston_donor_sea
    },
    {
      aspect_ratio: 4.444444444,
      component_name: COMPONENT_NAME.BostonDiamondPlatinumListDonor,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: false,
      preview_tab_alias: "Diamond Platinum List",
      use_if_parent_is: FOLDER_TYPE.boston_diamond_platinum_list
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_bios
    },
    closed: {
      ...tree_icon_types.donor_bios
    }
  }
};
