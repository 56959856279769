import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Radio, Popover, Tooltip} from "antd";
import React, {useRef, useState} from "react";

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const FilterActions = ({filterKey, options, onFilter, onHoverIn, onHoverOut}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [value, setValue] = useState(null);
  const [classNameOnFilter, setClassNameOnFilter] = useState('');

  const onChange = (myValue) => {
    setValue(myValue.target.value);
  };

  const onClickReset = () => {
    setShowFilterModal(false);
    setValue(null);
    onFilter(filterKey, []);
    setClassNameOnFilter('');
  };

  const applyFilter = value => {
    setValue(value);
    onFilter(filterKey, [value]);
    setClassNameOnFilter('filter-action_icon-filtered');
  };

  const onClickOk = () => {
    setShowFilterModal(false);
    applyFilter(value);
  };

  const onVisibilityChange = (visible) => {
    setShowFilterModal(visible);
    if (!visible) {
      // modal closed
      applyFilter(value);
    }
  };

  const onMouseEnter = () => {
    onHoverIn();
  };

  const onMouseLeave = () => {
    onHoverOut();
  };

  const contentModal = () => {
    return (
      <div className="filter-action-modal">
        <div className="filter-action-modal_body">
          <Radio.Group onChange={onChange} value={value}>
            {options.map((option, index) => (
              <Radio key={`filter-radio-${index}`} style={radioStyle} value={option.value}>
                {option.text}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="filter-action-modal_buttons">
          <Button
            onClick={onClickReset}
            className="filter-action-modal_button"
            type="ghost"
            style={{width: 'auto', marginRight: 5}}
          >
            reset
          </Button>
          <Button
            onClick={onClickOk}
            className="filter-action-modal_button"
            type="primary"
            style={{width: 'auto', marginRight: 5}}
          >
            OK
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="filter-action_container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div
          className="filter-action_buttons"
          id="action-buttons"
        >
          <Popover
            content={contentModal}
            // title="Title"
            trigger="click"
            visible={showFilterModal}
            onVisibleChange={onVisibilityChange}
          >
            <FontAwesomeIcon
              className={`filter-action_icon ${classNameOnFilter}`}
              icon={["fas", "filter"]}
              onClick={() => {
                setShowFilterModal(!showFilterModal)
              }}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default FilterActions;
