import React from "react";
import { Checkbox } from "antd";
import InputTooltip from "./InputTooltip";
import { BaseInputProps } from "./types";

export type CheckboxInputProps = BaseInputProps<boolean>;

const CheckboxInput = (props: CheckboxInputProps) => {
  return (
    <>
      <Checkbox
        disabled={!props.canUpdate}
        defaultChecked={props.defaultValue}
        className="checkbox-input"
        checked={props.defaultValue}
        onChange={(ev) => {
          if (props.onBlur) {
            props.onBlur(props.fieldName, ev.target.checked);
          }
          return props.onChange(props.fieldName, ev.target.checked);
        }}
      >
        <label className="form-input-label">{props.title}</label>
      </Checkbox>
      {props.tooltip ? <InputTooltip tooltip={props.tooltip} /> : null}
    </>
  );
};

export default CheckboxInput;
