import get from "lodash/get";
import React from "react";
import Slide1911 from "../../standard/Slide1911";
import { useFolder } from "../../../hooks/useFolder";
import { createUseStyles } from "react-jss";
import styles from "./styles";
import RichTextRender from "../../standard/RichTextRender";
import { useScale } from "../../../hooks/useScale";

const useStyles = createUseStyles({
  ...styles,
  storyContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  storyBlock: {
    position: "relative",
    width: "33.33%",
    height: "100%",
    padding: "10em 10em 7.5em",
    "& .storySubhead, & .storyBody": {
      paddingTop: (props) => `${props.screenHeight * 0.1}px`,
    },
  },
  story1Background: {
    backgroundColor: (props) => props.story1Background,
  },
  story2Background: {
    backgroundColor: (props) => props.story2Background,
  },
});

const BerkeleyTemplate005 = (props) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } =
    useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const propsClasses = get(props, 'classes.componentContainer', '');

  const backgroundOverlay = getBaseFolderFieldValue("background_image_overlay");

  const story1header = getBaseFolderFieldValue("story1_header", baseFolderType);
  const story1subhead = getBaseFolderFieldValue(
    "story1_subhead",
    baseFolderType
  );
  const story1body = getBaseFolderFieldValue("story1_body", baseFolderType);
  const story1Background = getBaseFolderFieldValue("story1_background");

  const story2header = getBaseFolderFieldValue("story2_header", baseFolderType);
  const story2subhead = getBaseFolderFieldValue(
    "story2_subhead",
    baseFolderType
  );
  const story2body = getBaseFolderFieldValue("story2_body", baseFolderType);
  const story2Background = getBaseFolderFieldValue("story2_background");

  const screenWidth = props.containerWidth / 3;
  const screenHeight = props.containerHeight / 2;

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundOverlay,
    story1Background,
    story2Background,
    screenWidth,
    screenHeight,
  });

  return (
    <div className={[classes.componentContainer, propsClasses].join(" ")}>
      <div className={classes.componentOverlay}>
        <div className="backgroundContainer">
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            containerWidth={props.containerWidth}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["background_image"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
      </div>
      <div className={classes.storyContainer}>
        <div className={`${classes.story1Background} ${classes.storyBlock}`}>
          <div className="storyHeader">
            <RichTextRender htmlString={story1header} />
          </div>
          <div className="storySubhead">
            <RichTextRender htmlString={story1subhead} />
          </div>
          <div className="storyBody">
            <RichTextRender htmlString={story1body} />
          </div>
        </div>
        <div className={`${classes.storyBlock}`}>
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            containerWidth={props.containerWidth / 3}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["media"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
            }}
          />
        </div>
        <div className={`${classes.story2Background} ${classes.storyBlock}`}>
          <div className="storyHeader">
            <RichTextRender htmlString={story2header} />
          </div>
          <div className="storySubhead">
            <RichTextRender htmlString={story2subhead} />
          </div>
          <div className="storyBody">
            <RichTextRender htmlString={story2body} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BerkeleyTemplate005;
