import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const fremont_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.fremont_donor_lists,
  display_name: "Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.fremont_donor_list_001, FOLDER_TYPE.fremont_donor_list_category],
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
  },
  // list_view: {
  //   enabled: true,
  //   list_folder_types: [
  //     FOLDER_TYPE.fremont_donor_list_001,
  //     FOLDER_TYPE.fremont_donor_list_category,
  //   ], //will need to handle missing fields if more then one type
  //   columns: [
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "list_view_selected",
  //       title: "",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "name",
  //       title: "Name",
  //       sort: true,
  //       editable: false,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "header",
  //       title: "Header",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "description",
  //       title: "Description",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "category",
  //       title: "Category",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "sorting_options",
  //       title: "Sorting Option",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //     {
  //       folder_types: [
  //         FOLDER_TYPE.fremont_donor_list_001,
  //         FOLDER_TYPE.fremont_donor_list_category,
  //       ],
  //       field: "cycle",
  //       title: "Cycle Time",
  //       sort: true,
  //       editable: true,
  //       default_shown: true,
  //     },
  //   ],
  // },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
