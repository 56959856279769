import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, Menu, Modal, Tabs } from "antd";
import { isNil } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { IState } from "../../interfaces/main-state.interface";
import { IFolderTypeButton } from "../../shared-global/interfaces/folder-type-interfaces";
import { FontsFamilies } from "../../shared-global/fonts";
import { validate } from "../../utils/validate";
import Spaner from "../utils/Spaner";
import FieldWrapper from "./FieldWrapper";
import FormWrapper from "./FormWrapper";
import CheckboxInput from "./input/CheckboxInput";
import ColorAndTransparencyInput from "./input/ColorAndTransparencyInput";
import DateInput from "./input/DateInput";
import DynamicFileInput from "./input/DynamicFileInput/DynamicFileInput";
import FixedFileInput from "./input/FixedFileInput";
import HybridTextInput from "./input/HybridTextInput/HybridTextInput";
import ImageSelectInput from "./input/ImageSelectInput";
import MultiSelectInput from "./input/MultiSelectInput";
import NumberInput from "./input/NumberInput";
import PasswordInput from "./input/PasswordInput";
import SearchableSelectInput from "./input/SearchableSelectInput";
import SelectInput from "./input/SelectInput";
import SubmitButton from "./input/SubmitButton";
import SwitchInput from "./input/SwitchInput";
import TextAreaInput from "./input/TextAreaInput";
import TextAreaModal from "./input/TextAreaModal";
import TextInput from "./input/TextInput";
import TextLink from "./input/TextLink";
import TextObjInput from "./input/TextObjInput";
import TextObjInputLinkable from "./input/TextObjInputLinkable";
import TextStyleInput from "./input/TextStyleInput";
import WysiwygInput from "./input/WysiwygInput";
import WysiwygLinkableInput from "./input/WysiwygLinkableInput";
import DateTimeInput from "./input/DateTimeInput";

interface IDynamicFormProps {
  name?: string | Function;
  fieldGroupsConfig: any;
  fieldsConfig: any;
  fieldValues: any;
  mode: string;
  onError: any;
  canUpdate: boolean;
  submitting: boolean;
  onValidationError?: any;
  enableFieldGrouping: boolean;
  showLabels?: boolean;
  gallery?: boolean;
  resetContentManager?: boolean;
  classOverride?: string;
  showRankIcons?: boolean;
  onBlurHandlers?: any[];
  onChangeHandlers?: any[];
  onBlur?: any;
  onSubmit?: any;
  onCancel?: any;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  showModal?: boolean;
  submit_button_title?: string;
  folderHasResourcesBeingProcessed?: boolean;
  ref?: any;
  tabsConfig?: any;
  handleCustomButtonActions?: any;
  buttons?: IFolderTypeButton[];
  themeSettings?: any;
  folderType?: any;
  parentFolderId?: any;
  clientOptions?: any;
  checked?: any;
  handleClone?: (val: boolean) => void;
}

const styles = {
  accordion: {
    border: "none",
    background: "initial",
    "& .ant-collapse-item": {
      borderBottom: "none",
    },
  },
  panel: {
    "& .ant-collapse-header": {
      background: "none",
      color: "#000000!important",
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& .ant-collapse-header:hover": {
      background: "none",
      color: "#2699FB!important",
      fontWeight: "bold",
    },
    "& .ant-collapse-content": {
      borderTop: "none",
      marginLeft: "2em",
      "& .ant-collapse-content-box": {
        paddingTop: 0,
      },
    },
  },
};
const useStyles = createUseStyles(styles);

const Panel = Collapse.Panel;
const DynamicForm: React.FunctionComponent<IDynamicFormProps> = forwardRef(
  (props: IDynamicFormProps, ref) => {
    const formRef = useRef<HTMLFormElement>();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [defaultProps, setDefaultProps] = useState<IDynamicFormProps>({
      tabsConfig: {},
      fieldGroupsConfig: {
        default: {
          title: "Folder",
          default_expanded: true,
          order: 0,
          accordion: false,
        },
      },
      fieldsConfig: {},
      fieldValues: {},
      mode: "",
      resetContentManager: false,
      showSubmitButton: true,
      showCancelButton: false,
      showModal: false,
      enableFieldGrouping: true,
      showLabels: true,
      showRankIcons: true,
      gallery: true,
      canUpdate: true,
      submitting: false,
      checked: false,
      onError: () => {},
      onValidationError: () => {},
      ...props,
    });

    const user = useSelector((state: IState) => state.data.user);
    const searchStringHighlight = useSelector(
      (state: IState) => state.client_options.search_string_highlight
    );

    const getFieldValidationRules = useCallback(() => {
      const rules = {};
      for (let name in defaultProps.fieldsConfig) {
        const config = defaultProps.fieldsConfig[name];
        rules[name] =
          config.validation && config.validation.rules
            ? config.validation.rules
            : [];
      }
      return rules;
    }, [defaultProps.fieldsConfig]);

    const getUpdatedFieldValues = useCallback(() => {
      const copyOfFieldValues = cloneDeep(defaultProps.fieldValues);

      if (Object.keys(defaultProps.fieldsConfig).length) {
        Object.keys(defaultProps.fieldsConfig).forEach((key) => {
          if (
            copyOfFieldValues[key] === undefined ||
            copyOfFieldValues[key] === null
          ) {
            if (defaultProps.fieldsConfig[key].default) {
              copyOfFieldValues[key] = defaultProps.fieldsConfig[key].default;
            }
          }
        });
      }

      let fileFields = {};
      for (let name in props.fieldsConfig) {
        if (
          props.fieldValues[name] &&
          props.fieldValues[name].type === "file"
        ) {
          fileFields[name] = props.fieldValues[name];
        }
      }
      return { ...copyOfFieldValues, ...fileFields };
    }, [defaultProps.fieldValues, defaultProps.fieldsConfig]);

    const getFieldsDefaultValue = useCallback(() => {
      const fields = {};
      for (let field in defaultProps.fieldsConfig) {
        switch (defaultProps.fieldsConfig[field].type) {
          case "integer":
            fields[field] = 0;
            break;
          case "text":
            fields[field] = "";
            break;
          case "password":
            fields[field] = "";
            break;
          case "bool":
            fields[field] = false;
            break;
          case "textarea_modal":
          case "textarea":
            fields[field] = "";
            break;
          case "select":
            fields[field] = "";
            break;
          case "searchable_select":
            fields[field] = "";
            break;
          case "multiselect":
            fields[field] = [];
            break;
          case "color":
            fields[field] = "";
            break;
          case "date":
            fields[field] = "";
          case "datetime":
            fields[field] = null;
            break;
          case "file":
            fields[field] = null;
            break;
          case "wysiwyg":
            fields[field] = {};
            break;
          default:
            fields[field] = null;
            break;
        }
      }
      return fields;
    }, [defaultProps.fieldsConfig]);

    const [fieldValidationResults, setFieldValidationResults] = useState({});
    const [fieldValues, setFieldValues] = useState(getUpdatedFieldValues());
    const [fieldValidationRules, setFieldValidationRules] = useState(
      getFieldValidationRules
    );

    const fieldsConfig = useMemo(() => {
      return Object.keys(props.fieldsConfig).reduce((acc, key) => {
        const fieldConfig = props.fieldsConfig[key];
        if (fieldConfig.isVisible && !fieldConfig.isVisible(fieldValues))
          return acc;
        acc[key] = fieldConfig;
        return acc;
      }, {});
    }, [props.fieldsConfig, fieldValues]);

    useImperativeHandle(ref, () => ({
      isValid() {
        try {
          const { fieldResults, isFormValid } = validate(
            fieldValidationRules,
            fieldValues
          );
          setFieldValidationResults(fieldResults);
          return !!isFormValid;
        } catch (e) {
          defaultProps.onError(e);
          return false;
        }
      },
      getFieldValues() {
        return fieldValues;
      },
      getNameValue() {
        return fieldValues.name;
      },
      overrideFieldValue(fieldName, value) {
        setFieldValues({ ...fieldValues, [fieldName]: value });
      },
      mergeFieldValues(newFieldValues) {
        // The following logic is used for font groups
        const curatedFields = { ...fieldValues };
        Object.keys(newFieldValues).forEach((fieldName) => {
          if (
            fieldsConfig[fieldName] &&
            fieldsConfig[fieldName].type === "textstyle"
          ) {
            curatedFields[fieldName] = {
              ...curatedFields[fieldName],
              ...newFieldValues[fieldName],
            };
          } else {
            curatedFields[fieldName] = newFieldValues[fieldName];
          }
        });
        setFieldValues({ ...fieldValues, ...curatedFields });
      },
    }));

    const onFieldChange = (name, value) => {
      const copyOfFieldValues = cloneDeep(fieldValues);

      if (typeof name === "string") {
        if (value === null || value === undefined) {
          delete copyOfFieldValues[name];
        } else {
          copyOfFieldValues[name] = value;
        }
      }

      if (typeof name === "object") {
        if (name instanceof Array) {
          for (let i = 0; i < name.length; i++) {
            if (name[i].value === null || name[i].value === undefined) {
              delete copyOfFieldValues[name[i].name];
            } else {
              copyOfFieldValues[name[i].name] = name[i].value;
            }
          }
        }
      }

      setFieldValues(copyOfFieldValues);

      if (defaultProps.onChangeHandlers) {
        defaultProps.onChangeHandlers?.forEach((handler) => {
          if (handler.name === name) {
            handler.callBackFunction(name, value, copyOfFieldValues);
          }
        });
      }
    };

    const onFieldBlur = (name, value) => {
      try {
        // Validate this field but leave others alone
        const validationRules = getFieldValidationRules();
        const { fieldResults } = validate(
          { [name]: validationRules[name] },
          { [name]: value }
        );
        let newFieldValidationResults = {
          fieldValidationResults,
          [name]: fieldResults[name],
        };
        setFieldValidationResults(newFieldValidationResults);
        if (defaultProps.onBlur) {
          defaultProps.onBlur({ [name]: value }, fieldValidationResults);
        }
      } catch (e) {
        console.log(e);
        defaultProps.onError(e);
      }
    };

    const onSubmit = (ev) => {
      if (ev) {
        ev.preventDefault();
      }
      try {
        const { fieldResults, isFormValid } = validate(
          fieldValidationRules,
          fieldValues
        );
        setFieldValidationResults(fieldResults);
        if (isFormValid) {
          if (defaultProps.onSubmit) {
            defaultProps.onSubmit(fieldValues);
          }
        } else {
          if (defaultProps.onValidationError) {
            defaultProps.onValidationError();
          }
        }
      } catch (e) {
        defaultProps.onError(e);
      }
    };

    const getCurrentFieldValues = () => {
      const fields = {};
      for (let fieldName in fieldsConfig) {
        if (!isNil(fieldValues[fieldName])) {
          fields[fieldName] = fieldValues[fieldName];
        }
      }
      return fields;
    };

    const getPropsFieldValues = (fileFields = false) => {
      const fields = {};
      Object.keys(fieldsConfig)
        .filter((f) =>
          fileFields
            ? fieldsConfig[f].type === "file"
            : fieldsConfig[f].type !== "file"
        )
        .forEach((fieldName) => {
          if (
            props.fieldValues[fieldName] !== null &&
            props.fieldValues[fieldName] !== undefined
          ) {
            fields[fieldName] = props.fieldValues[fieldName];
          } else if (
            fieldValues[fieldName] !== null &&
            fieldValues[fieldName] !== undefined
          ) {
            fields[fieldName] = fieldValues[fieldName];
          }
        });
      return fields;
    };

    useEffect(() => {
      setDefaultProps({
        ...defaultProps,
        submitting: props.submitting,
      });
    }, [props.submitting]);

    useEffect(() => {
      if (!defaultProps.fieldGroupsConfig.default) {
        setDefaultProps({
          ...defaultProps,
          fieldGroupsConfig: {
            ...defaultProps.fieldGroupsConfig,
            default: {
              title: "Folder",
              default_expanded: true,
              order: 0,
              accordion: false,
            },
          },
          submitting: props.submitting,
        });
      } else {
        setDefaultProps({
          ...defaultProps,
          fieldsConfig: fieldsConfig,
        });
      }
    }, [fieldsConfig]);

    useDeepCompareEffect(() => {
      const updatedFieldValues = getUpdatedFieldValues();
      const propsNonFileFieldValues = getPropsFieldValues(false);
      const propsFileFieldValues = getPropsFieldValues(true);
      const defaultFieldValues = getFieldsDefaultValue();
      const fieldValidationRules = getFieldValidationRules();
      const currentFieldValues = getCurrentFieldValues();

      setFieldValues({
        ...defaultFieldValues,
        ...updatedFieldValues,
        ...currentFieldValues,
        ...propsNonFileFieldValues,
        ...propsFileFieldValues,
      });
      setFieldValidationRules(fieldValidationRules);
    }, [props.fieldValues]);

    const getField = (baseInputProps, config) => {
      let field;
      switch (config.type) {
        case "integer":
        case "number":
          field = (
            <NumberInput
              {...baseInputProps}
              step={config.step || 1}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "text":
          const showCharCount = get(
            baseInputProps,
            "showCharCount",
            get(props, "showCharCount", true)
          );
          baseInputProps.canUpdate =
            baseInputProps.canUpdate && !config.read_only;
          field = (
            <TextInput
              {...baseInputProps}
              showCharCount={showCharCount}
              settings={config.settings || {}}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "text_link":
          field = (
            <TextLink
              {...baseInputProps}
              settings={config.settings || {}}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "text_object":
          if (config.resource_linkable === true) {
            field = (
              <TextObjInputLinkable {...baseInputProps} onBlur={onFieldBlur} />
            );
          } else {
            field = <TextObjInput {...baseInputProps} onBlur={onFieldBlur} />;
          }
          break;
        case "wysiwyg":
          if (config.resource_linkable === true) {
            field = (
              <WysiwygLinkableInput {...baseInputProps} onBlur={onFieldBlur} />
            );
          } else {
            field = (
              <WysiwygInput
                {...baseInputProps}
                settings={config.settings || {}}
                defaultStyle={get(config, "default.style", null)}
                resetToDefaultValue={config.default}
                onBlur={onFieldBlur}
                themeSettings={props.themeSettings}
              />
            );
          }
          break;
        case "password":
          field = <PasswordInput {...baseInputProps} onBlur={onFieldBlur} />;
          break;
        case "bool":
          field = (
            <CheckboxInput
              {...baseInputProps}
              onChange={onFieldChange}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "switch":
          field = (
            <SwitchInput
              {...baseInputProps}
              onChange={onFieldChange}
              options={config.options}
            />
          );
          break;
        case "textstyle":
          field = (
            <TextStyleInput
              options={config.options}
              settings={config.settings || {}}
              {...baseInputProps}
              onChange={onFieldChange}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "textarea":
          const showCharCountTextArea = get(
            baseInputProps,
            "showCharCount",
            get(props, "showCharCount", true)
          );
          field = (
            <TextAreaInput
              settings={config.settings || {}}
              {...baseInputProps}
              showCharCount={showCharCountTextArea}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "textarea_modal":
          const showCharCountTextAreaModal = get(
            baseInputProps,
            "showCharCount",
            get(props, "showCharCount", true)
          );
          field = (
            <TextAreaModal
              settings={config.settings || {}}
              {...baseInputProps}
              showCharCount={showCharCountTextAreaModal}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "select":
          // What happens if the select has only one option
          if (config.options && config.options.length === 1) {
            baseInputProps.defaultValue = config.options[0].value;
            if (!fieldValues[baseInputProps.fieldName]) {
              onFieldChange(baseInputProps.fieldName, config.options[0].value);
            }
          }
          field = (
            <SelectInput
              {...baseInputProps}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              options={config.options || []}
              settings={config.settings || {}}
            />
          );
          break;
        case "hybrid_text_input":
          const newBaseInputProps = {
            ...baseInputProps,
            defaultValue: { ...baseInputProps.defaultValue },
          };
          field = (
            <HybridTextInput
              {...newBaseInputProps}
              settings={config.settings || {}}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              defaultStyle={get(config, "default.wysiwyg.style", null)}
              resetToDefaultValue={config.default}
              themeSettings={props.themeSettings}
            />
          );
          break;
        case "images":
          field = (
            <ImageSelectInput
              {...baseInputProps}
              settings={config.settings || {}}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              options={config.options}
            />
          );
          break;
        case "searchable_select":
          field = (
            <SearchableSelectInput
              {...baseInputProps}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              options={config.options}
            />
          );
          break;
        case "multiselect":
          field = (
            <MultiSelectInput
              {...baseInputProps}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              options={config.options}
            />
          );
          break;
        case "color":
          field = (
            <ColorAndTransparencyInput
              {...baseInputProps}
              onBlur={onFieldBlur}
              onChange={onFieldChange}
              settings={config.settings ?? {}}
            />
          );
          break;
        case "date":
          field = (
            <DateInput
              {...baseInputProps}
              utc={config.utc || false}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "datetime":
          field = (
            <DateTimeInput
              {...baseInputProps}
              utc={config.utc || false}
              onBlur={onFieldBlur}
            />
          );
          break;
        case "file":
          field =
            config.slot_type === "fixed" ? (
              <FixedFileInput
                {...baseInputProps}
                disabled={config.disabled}
                type={config.slot_type}
                slots={config.slots}
                limit={config.limit}
                showRankIcons={defaultProps.showRankIcons}
                gallery={defaultProps.gallery}
                aspect={get(config, "aspect", "")}
                cropper={get(config, "cropper", true)}
                acceptable={config.acceptable_contents}
              />
            ) : (
              <DynamicFileInput
                {...baseInputProps}
                config={config}
                disabled={config.disabled}
                type={config.slot_type}
                slots={config.slots}
                limit={config.limit}
                showRankIcons={defaultProps.showRankIcons}
                gallery={defaultProps.gallery}
                aspect={get(config, "aspect", "")}
                cropper={get(config, "cropper", true)}
                acceptable={config.acceptable_contents}
                recommendedDimensions={config.recommended_dimensions}
                resetContentManager={defaultProps.resetContentManager}
              />
            );
          break;
        case "fonts":
          return (
            <Button
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  setClientOptions({
                    show_fonts_modal: true,
                    default_fonts: baseInputProps.defaultValue,
                  })
                );
              }}
              type="primary"
              ghost
              size="large"
              className="form-submit-button"
            >
              Default Font Style
            </Button>
          );
        default:
          break;
      }

      return field;
    };

    const getRenderedFieldWithValidation = (fieldKey, props, config) => {
      let validationError = "";
      const fieldValidation = fieldValidationResults[fieldKey];
      if (fieldValidation && fieldValidation.error) {
        validationError = fieldValidation.error;
      }
      return (
        <FieldWrapper key={fieldKey} validationError={validationError}>
          {getField(props, config)}
        </FieldWrapper>
      );
    };

    const showFieldByCondition = (config) => {
      return !(config.show_conditional_met === false);
    };

    const getRenderedFieldGroup = (
      baseProps,
      fieldGroup,
      fieldGroupKey,
      groupedFields,
      accordion = false
    ) => {
      const renderedFields = Object.keys(groupedFields)
        .filter((gf) => showFieldByCondition(groupedFields[gf]))
        .filter(
          (f) =>
            (groupedFields[f].field_group === fieldGroupKey ||
              (!groupedFields[f].field_group && fieldGroupKey === "default")) &&
            (user.system_admin ||
              (!user.system_admin && !groupedFields[f].sys_admin_only))
        )
        .map((f) => {
          const props = {
            ...baseProps,
            fieldName: f,
            key: f,
            title: groupedFields[f].title,
            defaultValue: fieldValues[f],
            tooltip: groupedFields[f].tooltip,
            showCharCount: get(groupedFields[f], "show_char_count", true),
          };
          return getRenderedFieldWithValidation(f, props, groupedFields[f]);
        });
      if (renderedFields?.length > 0) {
        if (accordion) {
          return (
            <Panel
              className={classes.panel}
              header={fieldGroup.title}
              key={fieldGroupKey}
            >
              {renderedFields}
            </Panel>
          );
        }
        return <div key={fieldGroupKey}>{renderedFields}</div>;
      }
    };

    const getRenderedTabContent = (tab = null) => {
      const groupedFields = {};

      const baseInputProps = {
        onChange: onFieldChange,
        showLabel: defaultProps.showLabels === false ? false : true,
        canUpdate: defaultProps.canUpdate === false ? false : true,
      };

      // Fields.
      Object.keys(defaultProps.fieldsConfig).forEach((fieldKey) => {
        const currentFieldConfigInLoop = defaultProps.fieldsConfig[fieldKey];
        if (
          currentFieldConfigInLoop.field_group !== null &&
          currentFieldConfigInLoop.field_group !== undefined
        ) {
          groupedFields[fieldKey] = currentFieldConfigInLoop;
        } else {
          groupedFields[fieldKey] = {
            ...currentFieldConfigInLoop,
            field_group: "default",
          };
        }
      });

      // Field Groups.
      const fieldGroupsWithAccordionEnabled = {};
      const fieldGroupsWithAccordionDisabled = {};
      Object.keys(defaultProps.fieldGroupsConfig).forEach((fieldGroupKey) => {
        const currentFieldGroupInLoop =
          defaultProps.fieldGroupsConfig[fieldGroupKey];
        if (
          tab !== null &&
          currentFieldGroupInLoop.tab === tab &&
          currentFieldGroupInLoop.accordion
        ) {
          fieldGroupsWithAccordionEnabled[fieldGroupKey] =
            currentFieldGroupInLoop;
        } else {
          fieldGroupsWithAccordionDisabled[fieldGroupKey] =
            currentFieldGroupInLoop;
        }
      });

      const renderedFieldGroupsWithAccordionEnabled = Object.keys(
        fieldGroupsWithAccordionEnabled
      )
        .filter((key) => fieldGroupsWithAccordionEnabled[key].tab === tab)
        .map((fieldGroupKey) => {
          const currentFieldGroupInLoop =
            fieldGroupsWithAccordionEnabled[fieldGroupKey];
          return getRenderedFieldGroup(
            baseInputProps,
            currentFieldGroupInLoop,
            fieldGroupKey,
            groupedFields,
            true
          );
        });

      const renderedFieldGroupsWithAccordionDisabled = Object.keys(
        fieldGroupsWithAccordionDisabled
      )
        .filter(
          (key) =>
            fieldGroupsWithAccordionDisabled[key].tab === tab ||
            (!tab && !fieldGroupsWithAccordionDisabled[key].tab)
        )
        .map((fieldGroupKey) => {
          const currentFieldGroupInLoop =
            fieldGroupsWithAccordionDisabled[fieldGroupKey];
          return getRenderedFieldGroup(
            baseInputProps,
            currentFieldGroupInLoop,
            fieldGroupKey,
            groupedFields,
            false
          );
        });

      return (
        <>
          {renderedFieldGroupsWithAccordionEnabled}
          {renderedFieldGroupsWithAccordionDisabled}
        </>
      );
    };

    const handleChangeTab = (tabKey: string) => {
      dispatch(
        setClientOptions({
          applied_content_selected_tab: tabKey,
        })
      );
    };

    const setDefaultSelectedTab = (firstTab) => {
      dispatch(
        setClientOptions({
          applied_content_selected_tab: firstTab,
        })
      );
      setFirstRender(false);
    };

    const handleButtonsToRenderWrapper = (buttons) => {
      if (buttons.length > 1) {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            {getButtonsToRender(buttons)}
          </div>
        )
      }
      return getButtonsToRender(buttons)
    }

    const getButtonsToRender = (buttons) => {
      return buttons.map((btn) => {
        if (btn.action === "clone_folder") {
          return (
            <Button
              type="primary"
              size="middle"
              ghost
              className="form-submit-button"
              style={{ marginRight: "2em" }}
              onClick={() => props.handleCustomButtonActions(btn.action)}
            >
              CLONE FOLDER
            </Button>
          );
        } else if (btn.action === "just_save") {
          return (
            <SubmitButton
              submitting={submitting}
              size="middle"
              disabled={
                defaultProps.submitting || defaultProps.canUpdate === false
              }
              title={defaultProps.submit_button_title || "Save"}
            />
          );
        } else {
          return (
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setDefaultProps({
                    ...defaultProps,
                    showModal: true,
                  });
                }}
                type="primary"
                size="middle"
                className="form-submit-button"
              >
                {props.submit_button_title ?? "SAVE"}
                {props.submitting && (
                  <span>
                    <Spaner width="sm" />
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={["fas", "spinner"]}
                      spin
                      className="fa"
                    />
                  </span>
                )}
              </Button>
            </div>
          );
        }
      });
    };

    const renderFieldGroupsWithTabs = () => {
      const TabPane = Tabs.TabPane;
      const tabsInConfig = Object.keys(defaultProps.tabsConfig);

      if (tabsInConfig.length > 0) {
        const firstTab = Object.keys(defaultProps.tabsConfig)[0];
        firstRender && setDefaultSelectedTab(firstTab);

        const renderedTabs = tabsInConfig.map((tabKey) => {
          const currentTab = defaultProps.tabsConfig[tabKey];
          const fieldGroupsWithAccordionEnabled = Object.keys(
            defaultProps.fieldGroupsConfig
          )
            .filter(
              (fieldGroupKey) =>
                defaultProps.fieldGroupsConfig[fieldGroupKey].accordion &&
                defaultProps.fieldGroupsConfig[fieldGroupKey].tab &&
                defaultProps.fieldGroupsConfig[fieldGroupKey].tab === tabKey
            )
            .map((fieldGroupKey) => ({
              ...defaultProps.fieldGroupsConfig[fieldGroupKey],
              key: fieldGroupKey,
            }));

          const enabledKeys = fieldGroupsWithAccordionEnabled
            .map((f) => f.key)
            .reverse();

          return (
            <TabPane tab={currentTab.title} key={tabKey}>
              {fieldGroupsWithAccordionEnabled.length > 0 ? (
                <Collapse
                  className={classes.accordion}
                  defaultActiveKey={enabledKeys}
                >
                  {getRenderedTabContent(tabKey)}
                </Collapse>
              ) : (
                getRenderedTabContent(tabKey)
              )}
            </TabPane>
          );
        });

        return (
          <Tabs onChange={(tabKey) => handleChangeTab(tabKey)}>
            {renderedTabs}
          </Tabs>
        );
      }

      const renderedNoTabsContent = getRenderedTabContent();
      return renderedNoTabsContent;
    };

    const submitting = defaultProps.submitting ? true : false;

    // Dropdown button menu.
    let buttonsMenu = null;
    if (props.buttons && props.buttons.length > 0) {
      buttonsMenu = (
        <Menu>
          {defaultProps.buttons.map((b) => (
            <Menu.Item
              key="1"
              onClick={() => props.handleCustomButtonActions(b.action)}
            >
              {b.title}
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return (
      <>
        <FormWrapper classOverride={defaultProps.classOverride}>
          {defaultProps.name && typeof defaultProps.name === "function" && (
            <div style={{ paddingLeft: 6, paddingRight: 6 }}>
              <div className="dynamic-form-name">
                <span>
                  <b>{defaultProps.name(onFieldChange)}</b>
                </span>
              </div>
            </div>
          )}

          {defaultProps.name && typeof defaultProps.name !== "function" && (
            <div style={{ paddingLeft: 6, paddingRight: 6 }}>
              <div className="dynamic-form-name">
                <span>
                  <b>{defaultProps.name}</b>
                </span>
              </div>
            </div>
          )}
          <form onSubmit={onSubmit}>
            {renderFieldGroupsWithTabs()}
            <div style={{ textAlign: "right", width: "100%", left: 0, padding: "0 27px" }}>
              <FieldWrapper>
                {
                  defaultProps.buttons &&
                  defaultProps.buttons.length > 0 &&
                  handleButtonsToRenderWrapper(defaultProps.buttons)
                }

                {(!defaultProps.buttons || defaultProps.buttons.length === 0) &&
                  defaultProps.showCancelButton && (
                    <Button
                      size="middle"
                      className="form-submit-button"
                      onClick={defaultProps.onCancel}
                      style={{ marginRight: "8px" }}
                    >
                      Cancel
                    </Button>
                  )}

                {(!defaultProps.buttons || defaultProps.buttons.length === 0) &&
                  defaultProps.showSubmitButton && (
                    <SubmitButton
                      submitting={submitting}
                      size="middle"
                      disabled={
                        defaultProps.submitting ||
                        defaultProps.canUpdate === false
                      }
                      title={defaultProps.submit_button_title || "Save"}
                    />
                  )}
              </FieldWrapper>
            </div>
            <Modal
              visible={defaultProps.showModal}
              onCancel={() => {
                props.handleCustomButtonActions("push_down_fields");
                setDefaultProps({
                  ...defaultProps,
                  showModal: false,
                });
              }}
              onOk={(e) => {
                onSubmit(e);
                setDefaultProps({
                  ...defaultProps,
                  showModal: false,
                });
              }}
              destroyOnClose={true}
              okText="SAVE"
              cancelText="SAVE AND OVERRIDE"
            >
              <div className="unsaved-changes-modal_header">
                Do you want to save this changes or save and override default
                setting?
              </div>
              <div>This action cannot be undone</div>
            </Modal>
          </form>
        </FormWrapper>
      </>
    );
  }
);

export default DynamicForm;
