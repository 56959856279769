import { createSelector } from "@reduxjs/toolkit";
import { IState } from "../interfaces/main-state.interface";
import { reversePathsFromNode } from "../utils/treeUtils";
import { getFileType } from "../shared/utils/fileUtils";
import { convertObjectIntoArray } from "../components/form/input/DynamicFileInput/FileInputService";
import { IResourceFolder } from "../shared-global/interfaces/models/resource__folder.interface";

const getResources = (state: IState) => state.data.resources;
const getResourceTreeResourceFolders = (state: IState) =>
  state.data.resourcetree_resource__folders;
const getResourceTreeFolders = (state: IState) =>
  state.data.resourcetree_folders;

const getResourceFolders = (state: IState) => state.data.resource__folders;

const getFolders = (state: IState) => state.data.folders;
const getFolderFolders = (state: IState) => state.data.folder__folders;

const findResourceDefaultFolder = (resourceFolders) => {
  const found = convertObjectIntoArray(resourceFolders).find(
    (rf) => rf.folder_type === "resource_default"
  );
  return found;
};

export const getResourceListViewItems = (resourceTreeSelectedId: string) =>
  createSelector(
    [
      getResources,
      getResourceTreeResourceFolders,
      getResourceTreeFolders,
      getResourceFolders,
      getFolderFolders,
      getFolders,
    ],
    (
      resources,
      resourceTreeResourceFolders,
      resourceTreeFolders,
      resourceFolders,
      folderFolders,
      folders
    ) => {
      let selectedResourceFolderId = Number(
        (resourceTreeSelectedId as string).split("-")[1]
      );

      if ((resourceTreeSelectedId as string).startsWith("company")) {
        // is first folder
        const found = findResourceDefaultFolder(resourceTreeFolders);
        selectedResourceFolderId = found ? Number(found.id) : null;
      }

      const selectedFolderResources = Object.values<any>(
        resourceTreeResourceFolders
      )
        .filter((r) => r.folder === selectedResourceFolderId)
        .filter((r) => resources[r.resource])
        .map((r) => {
          const resource = resources[r.resource];
          const associations = Object.values(resourceFolders)
            .filter((rf: IResourceFolder) => rf.resource === r.resource)
            .map((rf: IResourceFolder) => {
              const stringPaths = reversePathsFromNode(
                rf.folder,
                folderFolders
              ).map((path) => {
                const namePath = path
                  .filter((p) => folders[p])
                  .map((p) => folders[p].name);
                const stringPath = namePath.join("/");
                return stringPath;
              });
              const newRf = {
                paths: reversePathsFromNode(rf.folder, folderFolders),
                stringPaths,
              };
              return newRf;
            });
          const fileType = getFileType(resource.name);
          return {
            ...resource,
            file_type: fileType,
            associations,
            resourceTreeFolderJoinId: r.id,
            rank: r.rank,
          };
        });

      return selectedFolderResources;
    }
  );
