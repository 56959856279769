import React from 'react';
import { Modal } from 'antd';
import AddFolder, { IAddFolderProps } from './AddFolder';

interface IAddFolderModalProps extends IAddFolderProps {
  visible: boolean,
}

export const AddFolderModal: React.FC<IAddFolderModalProps> = (props) => {
  return (
    <Modal visible={props.visible}
      destroyOnClose={true}
      footer={null}
    >
      <AddFolder {...props} />
    </Modal>
  )
}

export default AddFolderModal;
