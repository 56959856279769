
const checkMinLenght = str => {
    return str && str.length >= 8
}

const LOWERCASE_REGEX = /[a-z]/g;
const UPPERCASE_REGEX = /[A-Z]/g;
const NUMBER_REGEX = /[0-9]/g;
const SYMBOL_REGEX = /[!@#$%^&*{}]/g;

const checkAtLeastOneLowercase = str => {
    const innerString = str || '';
    const finded = innerString.match(LOWERCASE_REGEX)
    return finded && finded.length > 0
}

const checkAtLeastOneUppercase = str => {
    const innerString = str || '';
    const finded = innerString.match(UPPERCASE_REGEX)
    return finded && finded.length > 0
}

const checkAtLeastOneNumber = str => {
    const innerString = str || '';
    const finded = innerString.match(NUMBER_REGEX)
    return finded && finded.length > 0
}

const checkAtLeastOneSymbol = str => {
    const innerString = str || '';
    const finded = innerString.match(SYMBOL_REGEX)
    return finded && finded.length > 0
}


const RULES = [
    {
        label: 'Minimum of 8 characters',
        validation: checkMinLenght
    },
    {
        label: 'At least one lowercase character [a-z]',
        validation: checkAtLeastOneLowercase
    },
    {
        label: 'At least one numeral [0-9]',
        validation: checkAtLeastOneNumber
    },
    {
        label: 'At least one symbol [!@#$%^&*{}]',
        validation: checkAtLeastOneSymbol
    }
]


export const checkPasswordValidation = pass => {
    let isPasswordValid = true;
    const allRulesChecked = RULES.map(r => {
        const isValid = r.validation(pass)
        if (!isValid) {
            isPasswordValid = false
        }

        return {
            valid: isValid,
            label: r.label
        }
    })

    return {
        isValid: isPasswordValid,
        rules: allRulesChecked
    }
}