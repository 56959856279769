import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import { useCallback, useContext } from "react";
import { FIELD_TYPE, FOLDER_TYPE } from "../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IFolderFolder } from "../../shared-global/interfaces/models/folder__folder.interface";
import { PreviewContext } from "../Contexts";
import { getFieldValue } from "../utils/generalUtils";

/*
 * useFolder Hook
 *
 * Description:
 * The functions of this hook component are meant to be used within the preview context,
 * more specifically, within any theme component that is a child of the PreviewContainer component.
 *
 * "Base" will always refer to the base folder selected in the tree when the component is rendered in the admin.
 * It also refers to the folder which id is in props.data.base_folder
 *
 */

export const useFolder = (folderId: number | undefined = undefined) => {
  const { data } = useContext(PreviewContext);

  // Gets the base folder within the preview context
  const getBaseFolder = useCallback(() => {
    if (folderId) {
      return data?.folders?.find((x) => x.id === folderId);
    }
    return data?.folders?.find((x) => x.id === data?.base_folder);
  }, [folderId, data?.base_folder, data?.folders]);

  const getFolderById = useCallback(
    (folderId: number) => {
      return data?.folders?.find((x) => x.id === folderId);
    },
    [data?.folders]
  );

  const getBaseResourceFolders = useCallback(() => {
    const baseFolder = getBaseFolder();
    return data?.resource__folders?.filter((x) => x.folder === baseFolder?.id);
  }, [data?.resource__folders, folderId, data?.base_folder, data?.folders]);

  const getBaseResourcesBySlot = (slotName: string) =>
    getBaseResourceFolders()?.filter((rf) => rf.slotName === slotName);

  // Gets the base folder's folder type
  const getBaseFolderFolderType = useCallback(() => {
    const baseFolder = getBaseFolder();
    const folderType = data?.folder_types?.find((x) => x.name === baseFolder?.folder_type);
    return folderType;
  }, [folderId, data?.base_folder, data?.folders, data?.folder_types]);

  // Gets the folder type of a specific folder
  const getFolderFolderType = useCallback(
    (folder: IFolder) => {
      return data?.folder_types?.find((x) => x.name === folder?.folder_type);
    },
    [data?.folder_types]
  );

  /*
   * Returns the value for a field of the specified folder.
   * It will first try to get the value from the dataValue, then from the folder, then from the folderType.
   * If none of these are found, it will return the defaultValue.
   */
  const getFolderFieldValue = useCallback(
    (folder: IFolder, field: string, defaultValue?: any) => {
      const folderType = getFolderFolderType(folder);
      const folderTypeField = get(folderType, `fields.${field}`);
      if (folderTypeField?.type === FIELD_TYPE.hybrid_text_input || folderTypeField?.type === FIELD_TYPE.wysiwyg) {
        return getFieldValue(folder, field, folderType, true, "", true);
      }
      return get(folder, `fields.${field}`, get(folderType, `fields.${field}.default`, defaultValue));
    },
    [data?.folder_types, getFolderFolderType]
  );

  /*
   * Returns the value for a field of the base folder.
   * It will first try to get the value from the dataValue, then from the folder, then from the folderType.
   * If none of these are found, it will return the defaultValue.
   */
  const getBaseFolderFieldValue = useCallback(
    (field, defaultValue = null) => {
      const baseFolder = getBaseFolder();
      if (!baseFolder) {
        return defaultValue;
      }
      return getFolderFieldValue(baseFolder, field, defaultValue);
    },
    [getFolderFieldValue, getBaseFolder]
  );

  /*
   * Gets the children of the specified folder
   * If distinctFolders is true, it will not return the same folder twice
   */
  const getFolderChildren = useCallback(
    (folderId?: number, distinctFolders = false, onlyActive = false) => {
      if (!folderId) {
        return {
          folders: [],
          folderFolders: []
        };
      }
      const folderFolders = (data?.folder__folders ?? [])
        .filter((x) => x.parent_folder === folderId)
        .sort((a, b) => a.rank - b.rank);

      const folderIds = distinctFolders
        ? uniqBy<IFolderFolder>(folderFolders, "child_folder").map((x) => x.child_folder)
        : folderFolders?.map((x) => x.child_folder);

      const folders = folderIds.reduce((acc, folderId) => {
        const folder = data?.folders?.find((f) => f.id === folderId);
        if (!folder) {
          return acc;
        }
        const isActive = getFolderFieldValue(folder, "active", true);
        if (onlyActive && !isActive) {
          return acc;
        }
        acc.push(folder);
        return acc;
      }, [] as IFolder[]);

      return {
        folders,
        folderFolders
      };
    },
    [data?.folders, data?.folder__folders]
  );

  /*
   * Gets the children of the specified folder
   * It will return the folder and the folderFolder
   * If distinctFolders is true, it will not return the same folder twice
   */
  const getFolderChildrenNested = useCallback(
    (folderId?: number) => {
      return data?.folder__folders
        ?.filter((folderFolder) => folderFolder.parent_folder === folderId)
        .sort((a, b) => a.rank - b.rank)
        .map((folderFolder) => {
          const folder = data?.folders?.find((f) => f.id === folderFolder.child_folder);
          return {
            folder,
            folderFolder
          };
        }) ?? [];
    },
    [data?.folders, data?.folder__folders]
  );

  /*
   * Gets the children of the base folder
   * If distinctFolders is true, it will not return the same folder twice
   */
  const getBaseFolderChildren = useCallback(
    (distinctFolders = false, onlyActive = false) => {
      if (folderId) {
        return getFolderChildren(folderId, distinctFolders, onlyActive);
      }
      return getFolderChildren(data?.base_folder, distinctFolders, onlyActive);
    },
    [folderId, data?.base_folder]
  );

  /*
   * Gets the children of the base folder.
   * It will return the folder and the folderFolder
   * If distinctFolders is true, it will not return the same folder twice
   */
  const getBaseFolderChildrenNested = useCallback(() => {
    if (folderId) {
      return getFolderChildrenNested(folderId);
    }
    return getFolderChildrenNested(data?.base_folder);
  }, [folderId, data?.base_folder]);

  /* Gets the cycle time of a specific folder
   * unless specified it will use the folder's cycle time value
   * if it's not specified it will use the default value
   * Implementations are usually for getting the cycle time of a folder with children
   * where the cycle time is the sum of the cycle times of the children
   * Distinct is not used for getting the cycle time of a folder with children
   */
  const getFolderCycleTime = useCallback(
    (folder?: IFolder) => {
      let cycleTime = 0;
      if (!folder) {
        return cycleTime;
      }

      if (!folder) {
        throw new Error(`Folder not found while calculating cycle time`);
      }

      const childFolders = getFolderChildren(folder.id);
      if (!childFolders.folders) {
        return cycleTime;
      }

      switch (folder?.folder_type) {
        case FOLDER_TYPE.boston_donor_sea:
          const backgroundsContentFolder = childFolders.folders.find(
            (f) => f?.folder_type === FOLDER_TYPE.boston_donor_sea_backgrounds
          );
          const backgrounds = getFolderChildren(backgroundsContentFolder?.id).folders ?? [];
          cycleTime = backgrounds.reduce((acc, curr) => {
            acc += get(curr, "fields.cycle_time", 0);
            return acc;
          }, 0);
          break;
        case FOLDER_TYPE.boston_donor_list:
          cycleTime = childFolders.folders?.reduce((acc, curr) => {
            acc += getFolderFieldValue(curr, "cycle_time", 30);
            return acc;
          }, 0);
          break;
        case FOLDER_TYPE.boston_diamond_platinum_list:
          cycleTime = childFolders.folders?.reduce((acc, curr) => {
            acc += curr.fields.cycle_time;
            return acc;
          }, 0);
          break;
        default:
          cycleTime = getFolderFieldValue(folder, "cycle_time", 5);
          break;
      }
      return cycleTime * 1000;
    },
    [data?.folder_types, getFolderChildren]
  );

  /*
   * Returns all the folder it can find with the specified folder type
  */
  const findFoldersByType = useCallback(
    (folderType: string) => {
      return data?.folders?.filter((f) => f.folder_type === folderType) ?? [];
    },
    [data?.folders]
  );

  return {
    findFoldersByType,
    getBaseFolder,
    getBaseFolderChildren,
    getBaseFolderChildrenNested,
    getBaseFolderFieldValue,
    getBaseFolderFolderType,
    getBaseResourceFolders,
    getBaseResourcesBySlot,
    getFolderById,
    getFolderChildren,
    getFolderChildrenNested,
    getFolderCycleTime,
    getFolderFieldValue,
    getFolderFolderType
  };
};
