import get from "lodash/get";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import styles from "./styles";
import TangentQrWidget from "./TangentQrWidget";

const useStyles = createUseStyles(styles);

const TangentStoryWithSingleImage = (props: IDisplayComponentProps) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } =
    useFolder(props.data?.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const {
    standardSizing,
    getBaseFolderFontSize,
    getUnitBasedOnHeight,
  } = useScale(props.data?.base_folder);

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings?.data, "show_placeholder", false);
  }, [props.data]);

  const headline = getBaseFolderFieldValue("headline", baseFolderType);
  const subhead = getBaseFolderFieldValue("subhead", baseFolderType);
  const bodyText = getBaseFolderFieldValue("body", baseFolderType);
  const mirrorContent = getBaseFolderFieldValue("mirror_content", false);
  const qRLogoWidget = getBaseFolderFieldValue("switch_to_logo", 'none');
  const imageFieldType = getBaseFolderFieldValue("background_image", null);
  const storyBackgroundColor = getBaseFolderFieldValue(
    "story_background_color",
    "transparent"
  );
  const backgroundColorOverlay = getBaseFolderFieldValue(
    "background_color_overlay",
    "transparent"
  );

  const classes = useStyles({
    backgroundColorOverlay,
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    fontSize: getBaseFolderFontSize() * 0.6,
    getUnitBasedOnHeight,
    mirrorContent,
    qRLogoWidget,
    standardSizing,
    storyBackgroundColor,
  });

  const propsClasses = get(props, 'classes.componentContainer', '');

  return (
    <div className={propsClasses}>
      {/* Background Image */}
      <div className={classes.storyWithSingleImageContainer}>
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background_image"],
            style: {
              backgroundPosition: "inherit",
            }
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div className={classes.storySingleImageOverlay} />
      <div className={classes.storySingleImageContent}>
        <div className={classes.sheets}>
          <div className="sheet1">
            {/* Images */}
            <div className="image">
              <div className="imageWrapper">
                <Slide1911
                  aws={props.aws}
                  aws_bucket={props.aws_bucket}
                  mode={props.mode}
                  containerWidth={props.containerWidth}
                  containerHeight={(986 / 1920) * props.containerHeight}
                  data={{
                    ...props.data,
                    slotNames: ["image"],
                    positionRelative: "relative",
                    placeholder: "image",
                    fieldType: imageFieldType,
                    showPlaceholder: showPlaceholder,
                    style: {
                      backgroundPosition: "inherit",
                    },
                    componentSpecificData: {
                      customStyle: {
                        height: "100%",
                        width: "100%",
                      },
                      customStyleImage: {
                        backgroundPosition: mirrorContent ? "top center" : "bottom center" ,
                      },
                    },
                  }}
                  webview_signedurls={props.webview_signedurls}
                />
              </div>
            </div>
          </div>
          <TangentQrWidget {...props} {...baseFolder.fields} />
          <div className="sheet2">
            {/* Text */}
            <div className="text">
              <div className="textWrapper">
                {/* Headline */}
                <div className={`headline`}>
                  <RichTextRender className="headlineText" htmlString={headline} />
                </div>
                {/* Sub Head */}
                {subhead && (
                  <div className="subhead">
                    <div
                      className={`subheadTextWrapper`}
                    >
                      <RichTextRender
                        className="subheadText"
                        htmlString={subhead}
                      />
                    </div>
                  </div>
                )}
                {/* Body Text */}
                <div className="body">
                  <div
                    className={`bodyTextWrapper`}
                  >
                    <RichTextRender className="bodyText" htmlString={bodyText} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TangentStoryWithSingleImage;
