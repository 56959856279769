import React, { useEffect } from "react";
import { IDisplayComponentProps } from "../../interfaces/display-component-props.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let cycleTimeHandler = null;
const WaitComponent: React.FC<IDisplayComponentProps> = (props) => {
  const setupCycle = () => {
    cycleTimeHandler = setTimeout(() => {
      props.handleEndOfPlay();
    }, props.cycle_time);
  };

  useEffect(() => {
    setupCycle();
    return () => {
      clearTimeout(cycleTimeHandler);
    };
  }, []);

  return (
    <div
      style={{
        height: props.containerHeight,
        width: props.containerWidth,
        background: "#2699FB",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white"
      }}
    >
      <React.Fragment>
        <FontAwesomeIcon
          style={{ cursor: "pointer", display: "inline-block" }}
          icon={["fas", "spinner"]}
          size="lg"
          spin
        />
        &nbsp;&nbsp; Loading...
      </React.Fragment>
    </div>
  );
};

export default WaitComponent;
