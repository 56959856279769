import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { transitions, list_styles, textstyle_options, tangentFonts } from "./tangent_settings";
import { tree_icon_types } from "../tree_icon_types";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const tangent_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.tangent_root,
  display_name: "Tangent Root",
  acceptable_folder_types: [
    FOLDER_TYPE.tangent_content,
    FOLDER_TYPE.tangent_displays,
    FOLDER_TYPE.tangent_presentations
  ],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    story_fullscreen: {
      title: "Story Fullscreen",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "templates"
    },
    story_single: {
      title: "Story with Single Image",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "templates"
    },
    story_with_media: {
      title: "Story with Media",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "templates"
    },
    story_quote: {
      title: "Story with Quote",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "templates"
    },
    list: {
      title: "List",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "templates"
    }
  },
  fields: {
    // ROOT
    name: {
      type: "text",
      title: "Label",
      validation: {
        rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
      },
      default: null,
      show_char_count: false,
      field_group: "general",
    },
    cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: {
        rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }],
      },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
        related_field: "default_cycle_time",
      },
    },
    transition: {
      type: "select",
      title: "Default Transition",
      field_group: "general",
      default: "transition-fade",
      options: [...transitions],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    default_fonts: {
      type: "fonts",
      title: "Default Font Style",
      field_group: "general",
      default: tangentFonts,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: false
      }
    },
    default_background: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Default Background",
      field_group: "general",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    // STORY FULLSCREEN
    story_fullscreen_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "story_fullscreen",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_fullscreen_background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      field_group: "story_fullscreen",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    // STORY WITH SINGLE IMAGE
    story_single_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "story_single",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "story_single",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "20px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Subhead Style",
      field_group: "story_single",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "15px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "story_single",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: "11px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_image: {
      title: "Default Story Image",
      type: "file",
      slot_type: "dynamic",
      field_group: "story_single",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.095334685598377,
      recommended_size: {
        width: 986,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.095334685598377
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_single_background_color: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "#937496",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
      field_group: "story_single",
    },
    story_single_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "story_single"
    },
    story_single_background_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
      field_group: "story_single",
    },
    // STORY WITH MEDIA
    story_with_media_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 15,
      validation: {
        rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }],
      },
      tooltip: cycle_time_tooltips,
      field_group: "story_with_media",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
    },
    story_with_media_tagline: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Tagline",
      field_group: "story_with_media",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
        fontSize: 16 * 2.25 + "px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header",
      field_group: "story_with_media",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
        fontSize: 16 * 5 + "px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body",
      field_group: "story_with_media",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: 16 * 2.125 + "px",
        lineHeight: 1.05,
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
    },
    story_with_media_story_image: {
      title: "Default Media",
      type: "file",
      slot_type: "dynamic",
      field_group: "story_with_media",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 9 / 6,
      recommended_size: {
        width: 540 * 5,
        height: 240 * 5,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_TOP_LEFT,
        aspect: 9 / 4,
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
    },
    story_with_media_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      field_group: "story_with_media",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 9 / 32,
      recommended_size: {
        width: 540,
        height: 1920,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_CENTER,
        aspect: 9 / 32,
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
    },
    story_with_media_background_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "#4DA6B2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true,
      },
      field_group: "story_with_media",
    },
    // STORY QUOTE
    story_quote_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "story_quote",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "story_quote",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
        fontSize: "56px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_quote_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 9/ 32,
      recommended_size: {
        width: 540,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 9 / 32
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "story_quote"
    },
    story_quote_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Default Background Color Overlay",
      default: "#F4C454",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "story_quote"
    },
    // LIST
    list_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 5,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "list",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    sorting_options: {
      type: "select",
      title: "Default List Sorting Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "rank",
      options: [
        {
          title: "Rank",
          value: "rank"
        },
        {
          title: "Sorting Order",
          value: "sorting_order"
        },
        {
          title: "Name",
          value: "name"
        }
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "list"
    },
    list_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "list",
      show_char_count: false,
      default: {
        color: "#ffffff",
        alignment: "left",
        font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
        fontSize: "35px",
        bold: true,
        lineHeight: 1.1
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_description: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Description Style",
      field_group: "list",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
        fontSize: "25px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_category: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Category Style",
      field_group: "list",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
        fontSize: "30px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_columns: {
      type: "integer",
      title: "Default Columns",
      default: 2,
      field_group: "list"
    },
    list_footer: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Footer Style",
      field_group: "list",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
        fontSize: "30px",
        lineHeight: 1.05
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_names: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Donor Names Style",
      field_group: "list",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
        fontSize: "15px",
        lineHeight: 1.25
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    show_upper_rule: {
      type: "bool",
      title: "Default Show upper horizontal rule",
      default: true,
      field_group: "list",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    show_lower_rule: {
      type: "bool",
      title: "Default Show lower horizontal rule",
      default: true,
      field_group: "list",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Default Horizontal Rule Color",
      default: "#000000",
      field_group: "list",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    horizontal_rule_size: {
      title: "Default Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.1,
      field_group: "list",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_background: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 9 / 32,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 9 / 32,
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "list"
    },
    list_color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Default Background Color",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "list"
    },
    donor_list_style: {
      type: "select",
      title: "Default List Style",
      field_group: "list",
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles
    }
  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.tangent_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.tangent_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.tangent_content
    }
  ],
  hide_add_icon_in_tree: true
};
