// Resource Explorer
export const THUMBNAIL_WIDTH = 190;
export const MIN_WIDTH_FULL_RESOLUTION = 670;
export const MIN_WIDTH_MEDIUM_RESOLUTION = 300;
export const DOUBLE_CLICK_DELAY = 500;

// Resource Explorer Item
export const FOOTER_HEIGHT = 30;
export const ICON_WIDTH = 20;

// Resource Explorer Folder
export const ICON_FOLDER_WIDTH = 75;
export const ICON_FOLDER_PADDING = 10;