import React from 'react';

const FAStack = props => {

    const backClass = "fas fa-"+props.backClass+" fa-stack-2x"
    const frontClass = "fas fa-"+props.frontClass+" fa-stack-1x fa-inverse"

    return (
        <span className="fa-stack fa-2x" style={{ ...props.style, fontSize: props.fontSize }}>
            <i className={backClass}></i>
            <i className={frontClass}></i>
        </span>
    )

};

export default FAStack;