import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";

export const boston_presentation_3x2_and_1x4: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.boston_presentation_3x2_and_1x4,
  display_name: "Boston Presentation 3x2 and 1x4",
  acceptable_folder_types: [
    FOLDER_TYPE.boston_media_with_quote,
    FOLDER_TYPE.boston_fullscreen_media,
    FOLDER_TYPE.boston_checkerboard
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  hide_nested_children: true,
  prevent_add_children: true,
  fields: {
    name: name
  },
  preview: {
    aspect_ratio: 1.333333333,
    component_name: COMPONENT_NAME.Boston3x2and1x4Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/3x2_1x4.svg"
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  isPresentation: true
};
