import {COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { tree_icon_types } from "../tree_icon_types";
import {
  berkeleyFonts,
  textstyle_options,
  transitions,
  verticalJustificationOptions
} from "./berkeley_settings";
import {FLEX_CLASSES, IMAGE_ALIGNMENT} from "../../../../enums/ui-enums";
import {fonts, FontsFamilies, FontVariants} from "../../../../fonts";
import {textStyleOptions} from "../everett/everett_settings";

export const berkeley_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.berkeley_root,
  display_name: "Berkeley Root",
  acceptable_folder_types: [
    FOLDER_TYPE.berkeley_content,
    FOLDER_TYPE.berkeley_presentations,
    FOLDER_TYPE.berkeley_displays
  ],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    templates: {
      title: "Templates",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "templates"
    },
  },
  fields: {
    // ROOT
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    default_fonts: {
      type: "fonts",
      title: "Default Font Style",
      field_group: "general",
      default: berkeleyFonts,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: false
      }
    },
    templates_select: {
      type: "select",
      title: "Choose a template to edit the default definitions",
      field_group: "templates",
      default: "none",
      options: [
        { title: "Select a template", value: "none" },
        { title: "Story 1 Defaults", value: "fullscreen" },
        { title: "Story 2 Defaults", value: "quote" },
        { title: "Story 3 Defaults", value: "story_with_quote" },
        { title: "Story 4 Defaults", value: "story_with_media" },
        { title: "Story 5 Defaults", value: "story_5" },
        { title: "List Defaults", value: "list" },
        { title: "Donor Sea Defaults", value: "donor_sea" }
      ],
    },
    // Story 1
    fullscreen_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
    },
    fullscreen_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fullscreen_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'fullscreen',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // Story 2
    quote_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
    },
    quote_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_text_top: {
      type: "textstyle",
      title: "Text Top",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
    },
    quote_text_bottom: {
      type: "textstyle",
      title: "Text Bottom",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
    },
    quote_background: {
      type: "color",
      title: "Quote Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.666666666666667,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.666666666666667
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    quote_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // Story 3
    story_quote_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_quote_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_subhead: {
      type: "textstyle",
      title: "Subhead",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_body: {
      type: "textstyle",
      title: "Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_background: {
      type: "color",
      title: "Story Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      default: "#42709C",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_quote_mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_quote_quote: {
      type: "textstyle",
      title: "Quote",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_attribution: {
      type: "textstyle",
      title: "Attribution",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
    },
    story_quote_quote_background: {
      type: "color",
      title: "Quote Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      default: "#42709C99",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_quote_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_quote_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_quote',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // Story 4
    story_media_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
    },
    story_media_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_media_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
    },
    story_media_subhead: {
      type: "textstyle",
      title: "Subhead",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
    },
    story_media_body: {
      type: "textstyle",
      title: "Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
    },
    story_media_background: {
      type: "color",
      title: "Story Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      default: "#42709C",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_media_left: {
      title: "Media Left",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.88,
      recommended_size: {
        width: 1920,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.88
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_media_right: {
      title: "Media Right",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.88,
      recommended_size: {
        width: 1920,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.88
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    story_media_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.666666666666667,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.666666666666667
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_media_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_with_media',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
// Story 5 Fields
    story_5_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story_5_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    story_5_1_header: {
      type: "textstyle",
      title: "Default Story 1 Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story_5_1_subhead: {
      type: "textstyle",
      title: "Default Story 1 Subhead",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story_5_1_body: {
      type: "textstyle",
      title: "Default Story 1 Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story1_background: {
      type: "color",
      title: "Default Story 1 Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      default: "#42709C",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    story_5_media: {
      title: "Media",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1920 / 2160,
      recommended_size: {
        width: 1920,
        height: 2160,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1920 / 2160,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    story_5_2_header: {
      type: "textstyle",
      title: "Default Story 2 Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story_5_2_subhead: {
      type: "textstyle",
      title: "Default Story 2 Subhead",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },
    story_5_2_body: {
      type: "textstyle",
      title: "Default Story 2 Body",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25",
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
    },

    story2_background: {
      type: "color",
      title: "Default Story 1 Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      default: "#42709C",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    story_5_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 5760 / 2160,
      recommended_size: {
        width: 5760,
        height: 2160,
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 5760 / 2160,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },

    story_5_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'story_5',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },

    // List
    list_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_list_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_list_description: {
      type: "textstyle",
      title: "Description",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_list_category: {
      type: "textstyle",
      title: "Category",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 72,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 5,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      },
    },
    list_donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 68,
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_footer: {
      type: "textstyle",
      title: "Footer",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 48,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_margin_bezel: {
      type: "integer",
      title: "Donor List Margin Around Bezel",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_margin_height: {
      type: "integer",
      title: "Donor List Height",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 100,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_line_height: {
      title: "Donor Line Height",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 1,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    donor_name_margin_bottom: {
      type: "integer",
      title: "Donor Name Bottom Margin",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
    },
    list_background_color_overlay: {
      type: "color",
      title: "Default Background Image overlay",
      default: "",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    list_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 16 / 9,
      recommended_size: {
        width: 1920 * 3,
        height: 1080 * 3
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 16 / 9
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'list',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },
    // Donor Sea
    donor_sea_header: {
      type: "textstyle",
      title: "Header",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
        fontSize: 80,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
    },
    donor_sea_description: {
      type: "textstyle",
      title: "Description",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 60,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
    },
    donor_sea_category: {
      type: "textstyle",
      title: "Category",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "center",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 72,
        lineHeight: "1.25"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
    },
    donor_sea_horizontal_rule: {
      type: "bool",
      title: "Horizontal Rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fade_in_donors_time: {
      type: "integer",
      title: "Fade In Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade in after the background is cycled into.",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    fade_out_donors_time: {
      type: "integer",
      title: "Fade Out Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade out before the background is cycled.",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      default: 3,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    screen_top_and_bottom_margin: {
      type: "integer",
      title: "Screen Top and Bottom Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      default: 7,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    screen_left_and_right_margin: {
      type: "integer",
      title: "Screen Left and Right Margin",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Margin left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      default: 4,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    min_margin_between_names_horizontally: {
      type: "integer",
      title: "Min Horizontal distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another horizontally. The distance is expressed in percentage and is relative to the width of the region minus the specified margin",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    min_margin_between_names_vertically: {
      type: "integer",
      title: "Min Vertical distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another vertically. The distance is expressed in percentage and is relative to the height of the region minus the specified margin",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    donor_sea_name_style: {
      type: "textstyle",
      title: "List Name Style",
      show_char_count: false,
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
        fontSize: 32,
        bold: true
      },
      options: textstyle_options,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
    },
    donor_sea_cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      default: 40,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'donor_sea',
    },

  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  has_font_config: false,
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.berkeley_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.berkeley_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.berkeley_content
    }
  ],
  hide_add_icon_in_tree: true
};
