import React, {Component} from 'react';
import Header from './Header';
import Footer from './Footer';

import {
  faCoffee,
  faAddressBook,
  faArrowAltFromRight,
  faEdit,
  faHome,
  faPlus,
  faImages,
  faFilePlus,
  faCircle,
  faSquare,
  faSpinner,
  faQuestion,
  faQuestionCircle,
  faFileCsv,
  faCabinetFiling,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faCloudUploadAlt,
  faUser,
  faEllipsisV,
  faImage,
  faPlayCircle,
  faSyncAlt,
  faTrashAlt as faTrashSolid,
  faCaretDown,
  faCaretRight,
  faBook,
  faCalendarAlt,
  faCloudDownload,
  faUserCrown,
  faUpload,
  faCrop,
  faCropAlt,
  faAngleDown,
  faAngleUp,
  faTimes,
  faCheckCircle,
  faCopy,
  faTrash as faTrashSolidSolid,
  faSort,
  faAsterisk,
  faPlus as faPlusSolid,
  faMinus as faMinusSolid,
  faExclamationCircle,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faFileDownload,
  faExclamationTriangle,
  faFilter,
  faExternalLink,
  faSun,
  faDesktop,
  faWater,
  faMoon,
  faSearch as faSearchSolid,
  faBars as faBarsSolid,
  faPresentation,
  faDownload,
  faMobileAlt,
  faSync,
  faVideo,
  faArrowAltCircleLeft,
  faArrowAltSquareUp,
  faTimesCircle as faTimesCircleFas,
  faFont,
} from 'fcbpriv/@fortawesome/pro-solid-svg-icons';

import {
  faMinusCircle,
  faArrowCircleDown,
  faArrowCircleUp,
  faFileImage,
  faFileVideo,
  faFile,
  faArrowUp,
  faArrowDown,
  faSearch,
  faFileCode,
  faLink,
  faUnlink,
  faFileAlt,
  faInfoSquare,
  faTimesCircle,
  faCog,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faPalette,
  faText,
  faTrash,
  faBell,
  faExchange,
  faBars,
  faSortDown,
  faSortUp,
  faPlus as faPlusRegular,
  faMinus as faMinusRegular,
  faTrashAlt,
  faPlay,
  faRemoveFormat,
  faSun as faSunRegular,
  faMoon as faMoonRegular,
  faHorizontalRule,
} from 'fcbpriv/@fortawesome/pro-regular-svg-icons';

import {
  faArrowSquareDown,
  faArrowSquareLeft,
  faArrowSquareRight,
  faFolderTree,
  faThList
} from 'fcbpriv/@fortawesome/pro-light-svg-icons';

import '../../css/grids.css';
import '../../css/grids-responsive.css';
import 'antd/dist/antd.less';
import '../../css/index.css';
import '../../shared/style/css/index.css'
import '../../css/fonts.css';
import _ from 'lodash';
import 'fcbpriv/@fortawesome/fontawesome-pro/css/all.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {connect} from "react-redux";
import {getThemeClass} from '../../utils/themeUtils'
import {library} from '@fortawesome/fontawesome-svg-core'
import {Progress, Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

library.add(
  faCoffee,
  faAddressBook,
  faArrowAltFromRight,
  faArrowAltSquareUp,
  faEdit,
  faHome,
  faPlus,
  faImages,
  faFilePlus,
  faCircle,
  faSquare,
  faMinusCircle,
  faArrowCircleDown,
  faArrowCircleUp,
  faCheckCircle,
  faTimes,
  faUpload,
  faTrash,
  faUser,
  faFileImage,
  faFileVideo,
  faFile,
  faArrowUp,
  faArrowDown,
  faSearch,
  faFileCode,
  faLink,
  faUnlink,
  faCloudDownload,
  faCalendarAlt,
  faBook,
  faFileAlt,
  faImage,
  faArrowSquareDown,
  faArrowSquareLeft,
  faArrowSquareRight,
  faFolder,
  faFolderOpen,
  faFolderTree,
  faTimesCircle,
  faThList,
  faInfoSquare,
  faSpinner,
  faQuestion,
  faFileCsv,
  faTrashSolid,
  faCog,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCabinetFiling,
  faCaretDown,
  faCaretRight,
  faFolderPlus,
  faCloudUploadAlt,
  faEllipsisV,
  faPlayCircle,
  faSyncAlt,
  faQuestionCircle,
  faUserCrown,
  faCrop,
  faCropAlt,
  faPalette,
  faText,
  faTimes,
  faAngleDown,
  faAngleUp,
  faCheckCircle,
  faBell,
  faCopy,
  faExchange,
  faTrashSolidSolid,
  faSort,
  faBars,
  faSortDown,
  faSortUp,
  faAsterisk,
  faPlusRegular,
  faMinusRegular,
  faPlusSolid,
  faMinusSolid,
  faExclamationCircle,
  faChevronLeftSolid,
  faChevronRightSolid,
  faChevronDownSolid,
  faChevronUpSolid,
  faTrashAlt,
  faPlay,
  faPlayCircle,
  faFileDownload,
  faExclamationTriangle,
  faFilter,
  faRemoveFormat,
  faExternalLink,
  faSun,
  faSunRegular,
  faDesktop,
  faWater,
  faMoon,
  faMoonRegular,
  faSearchSolid,
  faBarsSolid,
  faPresentation,
  faDesktop,
  faWater,
  faDownload,
  faMobileAlt,
  faSync,
  faVideo,
  faArrowAltCircleLeft,
  faTimesCircleFas,
  faFont,
  faHorizontalRule
);

var faDonorList = {
  prefix: 'fac',
  iconName: 'donor-list',
  icon: [350, 265, [], 'e001', 'M112,128c35.3,0,64-28.7,64-64S147.3,0,112,0S48,28.7,48,64S76.7,128,112,128z M22.4,256h179.2c12.4,0,22.4-8.6,22.4-19.2 v-19.2c0-31.8-30.1-57.6-67.2-57.6c-10.8,0-18.7,8-44.8,8c-26.9,0-33.4-8-44.8-8C30.1,160,0,185.8,0,217.6v19.2 C0,247.4,10,256,22.4,256z M264,192h112c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8H264c-4.4,0-8,3.6-8,8v16C256,188.4,259.6,192,264,192z M264,128h112c4.4,0,8-3.6,8-8v-16c0-4.4-3.6-8-8-8H264c-4.4,0-8,3.6-8,8v16C256,124.4,259.6,128,264,128z M264,64h112 c4.4,0,8-3.6,8-8V40c0-4.4-3.6-8-8-8H264c-4.4,0-8,3.6-8,8v16C256,60.4,259.6,64,264,64z']
}

library.add(faDonorList)
class MainLayout extends Component {

  render() {
    let themeClass = getThemeClass(_.get(this.props, 'persistent_settings.data.theme', 'light'), 'layout-main')
    const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
    document.body.className = themeClass;
    const currentFileNumber = _.get(this.props.client_options, 'progress.current', 0);

    return (
      <React.Fragment>
        {
          this.props.client_options.lock_screen ?
            <div>
              <div style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex: 99999,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <span style={{
                  position: "relative",
                  padding: "10px 0 10px 0",
                  color: "white",
                  fontSize: "16px",
                  textAlign: "center",
                }}>
                  Please wait <br/>
                  {
                    this.props.client_options.message_for_progress ?
                      this.props.client_options.message_for_progress
                      : `Uploading file ${currentFileNumber + 1} out of ${typeof _.get(this.props.client_options, 'progress.max', "calculating...") === "number" ? _.get(this.props.client_options, 'progress.max', "calculating...") + 1 : _.get(this.props.client_options, 'progress.max', "calculating...")}`
                  }
                </span>
                {!this.props.client_options.indeterminate ?
                  <Progress
                    key={currentFileNumber}
                    type="circle"
                    percent={parseInt(_.get(this.props.client_options, 'progress.percentage', 0), 10)}
                  />
                  :
                  <Spin indicator={antIcon} size="large" />
                }
              </div>
              <div style={{
                backgroundColor: "black",
                opacity: 0.75,
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex: 99998,
                pointerEvents: "none",
                top: 0,
              }}/>
            </div>
            :
            null
        }
        <div className={"main " + themeClass}>
          <Header/>
          {this.props.children}
        </div>
        <Footer themeClass={themeClass}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    persistent_settings: state.data.persistent_settings,
    client_options: state.client_options
  };
};

export default connect(mapStateToProps, null)(MainLayout)
