import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table } from 'antd';
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd';
import { deletePermission } from '../../actions/companyActions';
import { hasPermission } from '../../utils/permissionUtils';
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from '../../hooks/withRouter';

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: ''
    };
  }

  onInsert = () => {
    AlertNotification('success', 'Success', "Record successfully deleted")
  }


  onFail = () => {
  }



  componentDidMount() {
  }

  render() {

    let permissionTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      },
      {
        title: 'Area',
        dataIndex: 'area',
        key: 'area',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      },
      {
        title: 'Action Type',
        dataIndex: 'action_type',
        key: 'action_type',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      },
      {
        title: 'Action ',
        dataIndex: 'action',
        key: 'action',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      },
      {
        title: 'Scope ',
        dataIndex: 'scope',
        key: 'scope',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      }
    ];

    if (hasPermission(this.props.grouped_permissions, 'permission', 'delete', this.props.user, null, null, this.props.user?.company ?? null)) {
      permissionTableColumns.push({
        title: 'Delete',
        dataIndex: 'delete',
        render: (text, record) => {
          // let url="/company/"+this.props.companyId+"/project/" + record.id + "/";
          // let url="/project/" + record.id + "/";
          let RecordId = record.id;
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.props.deletePermission(RecordId, this.onInsert, this.onFail);
              }}
            >

              <FontAwesomeIcon className="fa-lg" style={{ cursor: 'pointer' }} icon={['far', 'trash']} />

            </span>
          );
        },
      });
    }

    if (hasPermission(this.props.grouped_permissions, 'permission', 'update, this.props.user', null, null, this.props.user?.company ?? null)) {
      permissionTableColumns.push({
        title: 'edit',
        dataIndex: 'edit',
        render: (text, record) => {
          let url = "/company/" + this.props.companyId + "/permission/" + record.id + "/";
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                // this.props.deleteRole(url,this.props.companyId,this.onInsert,this.onFail);
                this.props.navigate(url);
              }}
            >
              <FontAwesomeIcon className="fa-lg" icon="edit" />

            </span>
          );
        }
      });
    }

    let permissionArray = arrayFromKeyedObject(this.props.permissions);
    let arr = [];
    permissionArray.forEach(permission => {
      //console.log("cid"+JSON.stringify(this.props.companyId));
      if (permission.company === this.props.companyId) {
        let obj = {
          name: permission.name,
          updatedAt: Date(permission.updatedAt),
          createdAt: Date(permission.createdAt),
          area: permission.area,
          action_type: permission.action_type,
          action: permission.action,
          scope: permission.scope,
          key: permission.id,
          id: permission.id
        }
        arr.push(obj);
      }
    });
    return (
      <div>
        <Table
          title={() => hasPermission(this.props.grouped_permissions, 'permission', 'create', this.props.user, null, null, this.props.user?.company ?? null) ?
            <Button onClick={() => this.props.navigate(this.props.permissionUrl)}>Add Permission</Button> : <b>Permissions</b>}
          rowKey={record => record.key}
          columns={permissionTableColumns}
          dataSource={arr}
          pagination={false}
          bordered={false}
        />
        {/* <Button onClick={() => {
        this.props.navigate(this.props.permissionUrl);
      }}>Add permission</Button> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.data.permissions,
    grouped_permissions: state.data.user_grouped_permissions,
    user: state.data.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deletePermission: (permissionId, onSuccess, onFail) => {
      dispatch(deletePermission(permissionId, onSuccess, onFail));
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Permissions));