import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import base_types from "../base_types";

export const jasper_template_001: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.jasper_template_001,
  display_name: "Multi Image Story",
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null
    },
    header01: {
      type: "text",
      title: "Header",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null
    },
    title01: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null
    },
    quote01: {
      type: "textarea",
      title: "Quote",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
      default: null
    },
    paragraph01: {
      type: "textarea",
      title: "Body Text",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
      default: null
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: [
        "<span><b>Unit: </b>Seconds.</span><br /><br />",
        "<span><b>Images: </b>If no cycle time is provided, the default cycle time will be of 5 seconds.</span><br /><br />",
        "<span><b>Videos: </b>Regardless of provided cycle time, template will cycle when the video is done playing.</span><br /><br />"
      ]
    },
    file: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 10,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.72,
      recommended_size: {
        width: 357,
        height: 500
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.72
      }
    }
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.FremontTemplate001,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
  }
};
