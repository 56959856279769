import React, {Component} from 'react';
import {connect} from "react-redux";
import {Menu, Dropdown} from 'antd';
import {hasPermission} from '../../utils/permissionUtils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Spaner from '../utils/Spaner';
import MenuItem from 'antd/lib/menu/MenuItem';
import FAStack from '../../shared/components/utils/FAStack';
import {broadcastNewMobileHeader} from '../../actions/utilActions';
import SearchMain from './SearchMain';
import {arrayFromKeyedObject} from '../../shared/utils/collectionUtils';
import NotificationMenu from './NotificationMenu';
import SwitchCompanyMenu from './SwitchCompanyMenu';
import {fetchProjectFolders, getFolderStatus, setFoldersExpanded} from '../../actions/folderActions';
import {getNotifications, updateNotificationDelete, updateNotificationRead} from '../../actions/notificationActions';
import {getNotification} from "../../selectors/notificationsSelectors";
import {getUserNotification} from "../../selectors/userNotificationsSelectors";
import {getFolderStatusSelector} from "../../selectors/folderStatusSelectors";
import {changeCompany} from "../../actions/userActions";
import {setClientOptions} from "../../reducers/clientOptionsSlice";
import { withRouter } from '../../hooks/withRouter';

class Header extends Component {

  onAccountLinkClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/account');
  }

  onDashboardLink = (ev) => {
    ev.preventDefault();
    this.props.navigate('/dashboard');
  }

  onLogoutLinkClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/logout');
  }

  onHomeLinkClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/dashboard');
  }

  onCompaniesLinkClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/companies');
  }

  onConfigurationLinkClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/configuration');
  }

  onReleaseNotesClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/release_notes');
  }

  onConfigureCompaniesClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/configure_companies');
  };

  onUsersClick = (ev) => {
    ev.preventDefault();
    this.props.navigate("/company/" + this.props.companyId + "/users");
  }

  onCompanyClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/company/' + this.props.companyId);
  }

  onAuditLogClick = (ev) => {
    ev.preventDefault();
    this.props.navigate('/company/' + this.props.companyId + '/audit_logs');
  }

  render() {

    let renderNotifications = false
    if (window.location.href.indexOf("dashboard") > -1) {
      renderNotifications = true
    }
    const showMainSearch = window.location.href.includes('dashboard');

    const adminDropdown = (
      <Menu>
        <Menu.Item key="dashboard">
          <a href="/dashboard" onClick={this.onDashboardLink}>Dashboard</a>
        </Menu.Item>
        {/* {
          hasPermission(this.props.permissions, 'user', 'read', this.props.user) ?
            <Menu.Item key="users">
              <a href={"/company/" + this.props.companyId + "/users"} onClick={this.onUsersClick}>Users</a>
            </Menu.Item> : ''
        } */}
        {
          hasPermission(this.props.permissions, 'company', 'read', this.props.user, null, null, this.props.user?.company ?? null) ?
            <Menu.Item key="company">
              <a href={'/company/' + this.props.companyId} onClick={this.onCompanyClick}>Company</a>
            </Menu.Item>
            : ''
        }
        {
          hasPermission(this.props.permissions, 'audit_log', 'read', this.props.user, null, null, this.props.user?.company ?? null) ?
            <Menu.Item key="audit_log">
              <a href={'/company/' + this.props.companyId + '/audit_logs'} onClick={this.onAuditLogClick}>Audit logs</a>
            </Menu.Item>
            : ''
        }
        <Menu.Item key="account">
          <a href="/account" onClick={this.onAccountLinkClick}>Account</a>
        </Menu.Item>
        <Menu.Item key="release_notes">
          <a href="/release_notes" onClick={this.onReleaseNotesClick}>Release Notes</a>
        </Menu.Item>
        {
          (!this.props.user.system_admin && this.props.companies.length > 1) ?
            <Menu.Item key="configuration_companies">
              <a href="/configure_companies" onClick={this.onConfigureCompaniesClick}>Change Company</a>
            </Menu.Item> : ''
        }
        <Menu.Item key="logout">
          <a href="/logout" onClick={this.onLogoutLinkClick}>Log Out</a>
        </Menu.Item>
      </Menu>
    );

    const systemAdminDropdown = (
      <Menu>
        <Menu.Item key="companies">
          <a href="/companies" onClick={this.onCompaniesLinkClick}>Companies</a>
        </Menu.Item>
        <Menu.Item key="configuration">
          <a href="/configuration" onClick={this.onConfigurationLinkClick}>Configuration</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        {this.props.user.id && (<header style={{position: 'fixed', zIndex: 12, width: '100%'}}>
          <Menu mode="horizontal" className="site-menu">
            <Menu.Item key="logo" className="site-menu-logo">
              <a href="/dashboard" onClick={this.onHomeLinkClick} className="site-menu-link"><span>Appello</span></a>
            </Menu.Item>
            {/* <Menu.Item className="site-menu-search">
          </Menu.Item> */}
            {renderNotifications ? <NotificationMenu
              user={this.props.user}
              notifications={this.props.notifications}
              user__notifications={this.props.user__notifications}
              folder__status={this.props.folder__status}
              getFolderStatus={this.props.getFolderStatus}
              getNotifications={this.props.getNotifications}
              updateNotificationRead={this.props.updateNotificationRead}
              updateNotificationDelete={this.props.updateNotificationDelete}
            /> : null}

              {
              this.props.user.system_admin ?
                <Menu.Item key="systemadmin-menu" className="site-menu-item systemadmin-menu">
                  <Dropdown overlay={systemAdminDropdown} trigger={['click']}>
                    <span>System Admin</span>
                  </Dropdown>
                </Menu.Item> : ''
            }

            <SwitchCompanyMenu
              user={this.props.user}
              companies={this.props.allCompanies}
              changeCompany={this.props.changeCompany}
              fetchProjectFolders={this.props.fetchProjectFolders}
              getNotifications={this.props.getNotifications}
              setClientOptions={this.props.set_client_options}
              setFoldersExpanded={this.props.setFoldersExpanded}
            ></SwitchCompanyMenu>
            {
              this.props.user.id ?
                <MenuItem key='mobile-actions' className='only-mobile site-menu-item mobile-actions'>
                <span onClick={() => this.props.broadcastNewMobileHeader('folder-tree')}>
                  <FAStack fontSize={12} backClass={'circle titlebar-text-color'}
                           frontClass={'folder-tree titlebar-color'}/>
                </span>
                  <span onClick={() => this.props.broadcastNewMobileHeader('center-view')}>
                  <FAStack fontSize={12} backClass={'circle titlebar-text-color'}
                           frontClass={'th-list titlebar-color'}/>
                </span>
                  <span onClick={() => this.props.broadcastNewMobileHeader('details')}>
                  <FAStack fontSize={12} backClass={'circle titlebar-text-color'}
                           frontClass={'info-square titlebar-color'}/>
                </span>
                </MenuItem> : ''
            }
            {
              this.props.user.id ?
                <Menu.Item key="admin-menu" className="site-menu-item admin-menu">
                  <Dropdown overlay={adminDropdown} trigger={['click']}>
                  <span><FontAwesomeIcon style={{cursor: 'pointer'}} icon={['fas', 'user']}/>
                    <Spaner width="sm"/>
                    {this.props.user.first_name}
                    <Spaner width="sm"/>
                    <FontAwesomeIcon className="fa-lg" style={{cursor: 'pointer'}} icon={['fal', 'arrow-square-down']}/> </span>
                  </Dropdown>
                </Menu.Item> : ''
            }
          </Menu>
          {showMainSearch ? <SearchMain/> : null}
        </header>)
        }

      </>

    );
  }
}

const getUserCompanies = (user = {}, user__companies = [], companies = []) => {
  let myCompanies = [];
  const companyArray = arrayFromKeyedObject(companies);
  const userCompanyArray = arrayFromKeyedObject(user__companies);
  if (!!user && !!user.id && !!companyArray) {
    myCompanies = companyArray.filter(c => {
      return c.id === user.company || userCompanyArray.some(uc => uc.user === user.id && uc.company === c.id)
    })
  }
  return myCompanies;
};


const mapStateToProps = (state, ownProps) => {
  const user = state.data.user;
  const user__companies = state.data.user__companies;
  const companies = state.data.companies;
  const companiesByUser = getUserCompanies(user, user__companies, companies);
  const notifications = getNotification(state, ownProps);
  const user__notifications = getUserNotification(state, ownProps);
  const folder__status = getFolderStatusSelector(state, ownProps)

  return {
    user: state.data.user,
    permissions: state.data.user_permissions_grouped,
    companyId: state.data.user.company,
    companies: companiesByUser,
    allCompanies: companies,
    user__notifications,
    notifications: notifications,
    folders: state.data.folders,
    folder__status
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  broadcastNewMobileHeader: (mobile_state, onSuccess, onFail) => {
    dispatch(broadcastNewMobileHeader(mobile_state, onSuccess, onFail));
  },
  getFolderStatus: (company_id, onSuccess, onFail) => {
    dispatch(getFolderStatus(company_id, onSuccess, onFail));
  },
  getNotifications: (company_id, type, onSuccess, onFail) => {
    dispatch(getNotifications(company_id, type, onSuccess, onFail));
  },
  updateNotificationRead: (notifications, onSuccess, onFail) => {
    dispatch(updateNotificationRead(notifications, onSuccess, onFail));
  },
  updateNotificationDelete: (notifications, onSuccess, onFail) => {
    dispatch(updateNotificationDelete(notifications, onSuccess, onFail));
  },
  changeCompany: (user_id, company_id, old_company_id, onSuccess, onFail) => {
    dispatch(changeCompany(user_id, company_id, old_company_id, onSuccess, onFail))
  },
  fetchProjectFolders: (onSuccess) => {
    dispatch(fetchProjectFolders(ownProps.type, onSuccess));
  },
  set_client_options: (client_options) => {
    dispatch(setClientOptions(client_options))
  },
  setFoldersExpanded: (treeMode, treeIds) => {
    dispatch(setFoldersExpanded(treeMode, treeIds));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
