import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";

export const boston_diamond_platinum_list: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.boston_diamond_platinum_list,
  display_name: "Boston Diamond Platinum List",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor_list],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_add_children: true,
  hide_nested_children: true,
  fields: {
    name: {
      ...name
    },
    default_preview: {
      type: "select",
      title: "Default Preview",
      validation: { rules: [{ name: "isRequired" }] },
      default: "1",
      options: [
        {
          title: "Mission Bernal",
          value: "1"
        },
        {
          title: "Pacific",
          value: "2"
        }
      ]
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true
    }
  },
  preview: [
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston1x2and1x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "1x2, 1x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/1x2_1x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_1x2_and_1x5_and_3x2
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  }
};
