import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolderType, IFolderTypePreview } from "../../../../shared-global/interfaces/folder-type-interfaces";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields } from "../../../utils/generalUtils";
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import Bezels2107 from "../../standard/Bezels2107";
import MonterreyIntroStory from './MonterreyIntroStory';
import MonterreyMediaBlank from './MonterreyMediaBlank';
import MonterreyControlPanelDisplay from "./MonterreyControlPanelDisplay";
import MonterreyDonorLists from "./MonterreyDonorLists";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";

const MonterreyContainer: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const [baseFolder, setBaseFolder] = useState<IFolder>();

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data ?? {});
    if (foundBaseFolder) setBaseFolder(foundBaseFolder);
  }, []);

  const showBezels = useMemo(() => {
    return get(props, 'data.componentSpecificData.bezels', true);
  }, [props]);

  let component: any = null;
  let fontSize = 16;

  const getStyles = () => {
    const baseFolder = getBaseFolder(props.data || {});
    if (baseFolder) {
      const folderTypes = get(props, 'data.folder_types', []);
      if (folderTypes && folderTypes.length) {
        const folderType = folderTypes.find(
          (f) => f.name === baseFolder.folder_type
        );
        const styles = createUseStyles(
          generateFontSizeClassesFromFields(folderType, baseFolder.fields)
        );
        return styles;
      }
      return null;
    }
  };

  const useStyles = getStyles();
  const classes = useStyles();

  let horizontal = 1;
  let vertical = 1;

  if (baseFolder) {
    const folderTypes = get(props, 'data.folder_types', []);
    if (folderTypes && folderTypes.length) {
      const folderType: IFolderType = folderTypes.find(
        (f) => f.name === baseFolder.folder_type
      );
      const folderTypePreview = folderType.preview as IFolderTypePreview;
      const screenSet = folderTypePreview.screen_sets?.find(s => s.name === 'unique');
      horizontal = screenSet?.horizontal ?? 1;
      vertical = screenSet?.vertical ?? 1;

      fontSize = getFontSizeBasedOnPreviewData(
        folderType,
        folderTypePreview.component_name,
        "unique",
        {
          containerWidth: props.containerWidth,
          containerHeight: props.containerHeight,
        }
      );
    }

    const baseFolderType = getBaseFolderType(
      props.data,
      get(baseFolder, "folder_type", {})
    );

    switch (baseFolder.folder_type) {
      case FOLDER_TYPE.monterrey_media_blank_1:
      case FOLDER_TYPE.monterrey_media_blank_2:
      case FOLDER_TYPE.monterrey_media_blank_3:
      case FOLDER_TYPE.monterrey_media_blank_4:
      case FOLDER_TYPE.monterrey_media_blank_5:
        component = (
          <MonterreyMediaBlank
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            classes={classes}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
            fontSize={fontSize}
            data={{
              ...props.data,
              slotNames: ['media']
            }}
          />
        )
        break;
      case FOLDER_TYPE.monterrey_intro_story:
        component = (
          <MonterreyIntroStory
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            classes={classes}
            fontSize={fontSize}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        )
        break;
      case FOLDER_TYPE.monterrey_display:
        horizontal = 1
        vertical = 1
        component = (
          <MonterreyControlPanelDisplay
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            fontSize={fontSize}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        )
        break;
      case FOLDER_TYPE.monterrey_donor_list:
        component = (
          <MonterreyDonorLists
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            fontSize={fontSize}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        )
        break;
        case FOLDER_TYPE.monterrey_content_donor_lists:
          component = (
            <ComponentLoop1911
              {...props}
            />
          )
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div
        className={classes.componentContainer}
        style={{ fontSize }}
      >
        {component}
      </div>

      {/* Bezels */}
      {showBezels && (
        <Bezels2107
          key="bezels"
          {...props}
          data={{
            ...props.data,
            componentSpecificData: {
              horizontal_screens: horizontal,
              vertical_screens: vertical,
              alwaysShow: props.mode === COMPONENT_MODE_CONTEXT.PREVIEW,
            },
          }}
        />
      )}
    </>
  );
};

export default MonterreyContainer;
