import React from "react";
import QRLogoWidget from "../../standard/QRLogoWidget";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { IFolderType } from "../../../../shared-global/interfaces/folder-types/folders/IFolderType";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";

interface ITangentQrWidget extends IDisplayComponentProps {
  baseFolderFields: any;
  qrPosition: string;
  baseFolderType: IFolderType;
  componentSpecificData: any;
}

const TangentQrWidget = (props: ITangentQrWidget) => {
  const { getBaseFolder } = useFolder(props.data.base_folder);
  const { getBaseFolderFontSize } = useScale();
  const baseFolder = getBaseFolder();

  const fontSize = getBaseFolderFontSize() * 0.6;

  return (
    <div className="qrCode" style={{fontSize}}>
      <div className="qrCodeWrapper">
        <QRLogoWidget
          {...props}
          {...baseFolder.fields}
        />
      </div>
    </div>
  );
};

export default TangentQrWidget;
