import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";

import { TRANSITION_CLASS } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";

export const eugene_attract_loop: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDESHOW,
  name: FOLDER_TYPE.eugene_attract_loop,
  display_name: "Eugene Attract Loop",
  acceptable_folder_types: [FOLDER_TYPE.eugene_slide, FOLDER_TYPE.eugene_template_001],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "crossfade",
      options: [
        {
          title: "Crossfade",
          value: TRANSITION_CLASS.CROSSFADE_2S
        },
        {
          title: "Fade Out",
          value: TRANSITION_CLASS.TRANSITION_FADEOUT_2S
        }
      ]
    },
    default_overlay: {
      title: "Default Overlay",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: ["thumbnail", "small", "large"],
      aspect: 1.777777778,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    idle_delay: {
      type: "integer",
      title: "Idle timeout (seconds)",
      default: 30,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.ComponentLoop1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
