import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const new_orleans_1x2: IFolderType = {
  folder_category: FOLDER_CATEGORY.PRESENTATION,
  name: FOLDER_TYPE.new_orleans_1x2,
  entry_point: "/new_orleans_1x2",
  default_dimensions: {
    x: 0,
    y: 0,
    scale_x: 1080,
    scale_y: 3840
  },
  display_name: "One by Two Display",
  acceptable_folder_types: [
    FOLDER_TYPE.new_orleans_stories,
    FOLDER_TYPE.new_orleans_attract_loop,
    FOLDER_TYPE.new_orleans_donor_lists,
    FOLDER_TYPE.new_orleans_1x2_slideshow
  ],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    zoomable: true,
    width: 226.492416,
    height: 805.306368,
    aspect_ratio: 0.28125,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 12
    },
    component_name: COMPONENT_NAME.NewOrleans1x2Container,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.NewOrleans1x2Container
  }
};
