import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import {
  COMPONENT_NAME,
  FOLDER_TYPE
} from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields } from "../../../utils/generalUtils";
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import Bezels2107 from "../../standard/Bezels2107";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import Marin1x1Container from "./Marin1x1Container";
import MarinLayout from "./MarinLayout";

const Marin8x1Container: React.FC<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const [activePresentationId, setActivePresentationId] = useState(0);
  const [baseFolder, setBaseFolder] = useState<IFolder>();
  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data ?? {});
    setBaseFolder(foundBaseFolder);
  }, []);

  let component: any = null;
  let fontSize = 16;

  useEffect(() => {
    let foundActivePresentationId = null;
    const children = getChildrenData(props.data, props.data?.base_folder);

    if (children?.folders[0]) {
      foundActivePresentationId = children.folders[0].id;
      setActivePresentationId(Number(foundActivePresentationId));
    }
  }, [props.electronSettings, baseFolder, props.data]);

  const getStyles = () => {
    const baseFolder = getBaseFolder(props.data);
    const folderType = props.data.folder_types.find(
      (f) => f.name === baseFolder.folder_type
    );

    const styles = createUseStyles(
      generateFontSizeClassesFromFields(folderType, baseFolder.fields)
    );
    return styles;
  };

  // Only used when Marin uses websocket to change active presentation
  // which only happens when there is a Control Panel display
  // const handlePresentationEndOfPlay = () => {
  //   const setActivePresentationSocket = get(props, 'electronSettings.websocketActions.setActivePresentation', null);
  //   const mainPresentation = baseFolder?.fields.main_presentation;
  //   if (mainPresentation === activePresentationId) {
  //     return;
  //   } else {
  //     setActivePresentationId(mainPresentation);
  //     if (setActivePresentationSocket) {
  //       setActivePresentationSocket(mainPresentation)
  //     }
  //   }
  // }

  const useStyles = getStyles();
  const classes = useStyles();

  if (baseFolder) {
    const folderTypes = get(props, 'data.folder_types', []);
    if (folderTypes && folderTypes.length) {
      const folderType = folderTypes.find(
        (f) => f.name === baseFolder.folder_type
      );

      fontSize = getFontSizeBasedOnPreviewData(
        folderType,
        COMPONENT_NAME.Marin8x1Container,
        "unique",
        {
          containerWidth: props.containerWidth,
          containerHeight: props.containerHeight,
        }
      );

      switch (baseFolder.folder_type) {
        case FOLDER_TYPE.marin_layout:
          component = <MarinLayout {...props} />;
          break;
        case FOLDER_TYPE.marin_presentation_5x1:
          component = (
            <ComponentLoop1911
              {...props}
              refHeight={props.containerHeight}
              refWidth={props.containerWidth}
              data={{
                ...props.data,
                slidesSlotName: ["media"],
                showPlaceholder: true,
              }}
            />
          );
          break;
        case FOLDER_TYPE.marin_display:
          component = (
            <ComponentLoop1911
              {...props}
              key={activePresentationId}
              refHeight={props.containerHeight}
              refWidth={props.containerWidth}
              data={{
                ...props.data,
                base_folder: activePresentationId,
                slidesSlotName: ["media"],
                showPlaceholder: true,
              }}
            />
          );
          break;
        case FOLDER_TYPE.marin_story_media_with_quote:
          component = (
            <Marin1x1Container
              {...props}
              refHeight={props.containerHeight}
              refWidth={props.containerWidth}
              classes={classes}
            />
          );
          break;
        case FOLDER_TYPE.marin_content_layouts:
          component = (
            <ComponentLoop1911
              {...props}
            />
          )
        default:
          break;
      }
    }
  }

  return (
    <>
      <TransitionGroup>
        <CSSTransition key={activePresentationId} timeout={2000} classNames="transition-fade-2s">
          <div className={classes.componentContainer} style={{ fontSize }}>
            {component}
          </div>
        </CSSTransition>
      </TransitionGroup>
      {/* Bezels */}
      <Bezels2107
        key="bezels"
        {...props}
        data={{
          ...props.data,
          componentSpecificData: {
            horizontal_screens: 8,
            vertical_screens: 1,
            alwaysShow: props.mode === COMPONENT_MODE_CONTEXT.PREVIEW,
          },
        }}
      />
    </>
  );
};

export default Marin8x1Container;
