import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData } from "../../../utils/commonDataUtils";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import DonorSea2106 from "../../standard/DonorSea2106";

const BostonDonorSea: React.FC<IDisplayComponentProps> = (props) => {
  const [error, setError] = useState(-1);
  const [baseFolder, setBaseFolder] = useState<IFolder>(null);
  const [backgroundsFolder, setBackgroundsFolder] = useState<IFolder>(null);
  const [useColorOverlay, setUseColorOverlay] = useState(false);
  const [horizontalColorOverlay, setHorizontalColorOverlay] = useState(null);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState();
  const [currentBackgroundTimeLeft, setCurrentBackgroundTimeLeft] = useState();
  const [backgroundCycleTimes, setBackgroundsCycleTime] = useState([]);

  // The following variable is governed by the donor sea and it's set to true when the donor sea finishes
  // prerendering donors and calculating theirs height and width so the backgrounds start playing at the
  // right time which only happens once (when the donor sea is mounted)
  const [canStartPlayingBackgroundsLoop, setCanStartPlayingBackgroundsLoop] =
    useState(false);

  const renderError = () => {
    switch (error) {
      case 0:
        return <>Backgrounds Folder not found</>;
      default:
        return <>An Unknown Error has Occured</>;
    }
  };

  const handleEndOfPlay = (data) => {
    const persistentData = {
      id: baseFolder.id,
      type: "donor_sea",
      data: { donor_index: data }
    };
    if (props.handleEndOfPlay) {
      props.handleEndOfPlay(persistentData);
    }
  };

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);
    const childFolders = getChildrenData(
      props.data,
      foundBaseFolder.id,
      true
    ).folders;
    const backgroundsFolder = childFolders.find(
      (f) => f.folder_type === FOLDER_TYPE.boston_donor_sea_backgrounds
    );

    if (!backgroundsFolder) {
      setError(0);
      return;
    }

    const foundHorizontalColorOverlay = get(
      foundBaseFolder,
      "fields.horizontal_color_overlay",
      null
    );

    if (foundHorizontalColorOverlay) {
      setHorizontalColorOverlay(foundHorizontalColorOverlay);
      setUseColorOverlay(true);
    }

    setBaseFolder(foundBaseFolder);
    setBackgroundsFolder(backgroundsFolder);

    // Array of each individual background.
    const backgrounds = getChildrenData(
      props.data,
      backgroundsFolder.id,
      true
    ).folders;

    const cycleTimes = backgrounds.reduce((acc, curr) => {
      const cycleTime = get(curr, "fields.cycle_time", 5) * 1000;
      acc = [...acc, cycleTime];
      return acc;
    }, []);

    setBackgroundsCycleTime(cycleTimes);
  }, []);

  const cssTransitionKey = useColorOverlay ? "true" : "false";

  if (error > -1) {
    return renderError();
  }
 
  return (
    <>
      {/* Donor Sea Backgrounds Loop */}
      {backgroundsFolder ? (
        <ComponentLoop1911
          {...props}
          data={{
            ...props.data,
            base_folder: backgroundsFolder.id,
            slidesSlotName: props.data.componentSpecificData.slidesSlotName,
            componentSpecificData: {
              isCycleTimeBasedOnComputerClock: false,
              setCurrentSlideIndexCallback: setCurrentBackgroundIndex,
              setCurrentSlideTimeLeftCallback: setCurrentBackgroundTimeLeft,
              showPlaceholder: true,
              useDistinctFolders: true
            }
          }}
          handleEndOfPlay={null}
        />
      ) : null}

      {/* Background Overlay */}
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={1000}
          classNames="slowfadedelay"
        >
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              backgroundColor: horizontalColorOverlay,
            }}
          />
        </CSSTransition>
      </TransitionGroup>

      {/* Donor Sea */}
      <DonorSea2106
        {...props}
        data={{
          ...props.data,
          componentSpecificData: {
            ...props.data.componentSpecificData,
            isCycleTimeBasedOnComputerClock: false,
            setFinishedPrerenderCallback: () => {
              setCanStartPlayingBackgroundsLoop(true);
            },
            cycleTimes: backgroundCycleTimes,
            currentWaveCycleTimeIndex: currentBackgroundIndex,
            currentWaveTimeLeft: currentBackgroundTimeLeft,
          }
        }}
        handleEndOfPlay={props.handleEndOfPlay ? handleEndOfPlay : null}
      />
    </>
  );
};

export default BostonDonorSea;
