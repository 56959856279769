import React from 'react';

const spanWidths = {
    xs: 4,
    sm: 8,
    md: 16,
    lg: 32
}

const Spaner = ({width}) => {
    return (
        <span 
            style={{
                display: 'inline-block',
                width: spanWidths[width]
            }} 
        />
    );
};

export default Spaner;