import get from "lodash/get";
import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import transitions from "../../../../shared-global/constants/transitions";
import { TRANSITION_CLASS } from "../../../../shared-global/enums/ui-enums";
import { IFolderType, IFolderTypePreview } from "../../../../shared-global/interfaces/folder-type-interfaces";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields, getFieldValue } from "../../../utils/generalUtils";
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import DonorListWithRegions2108 from "../../standard/DonorListWithRegions2108";
import Slide1911 from "../../standard/Slide1911";
import MarinDonorListFooter from "./MarinDonorListFooter";
import MarinDonorListHeader from "./MarinDonorListHeader";

const MarinDonorLists = (props: IDisplayComponentProps) => {
  const [canRenderDonorList, setCanRenderDonorList] = useState(false);
  const [topElements, setTopElements] = useState<HTMLDivElement | null>(null);
  const [footerEl, setFooterEl] = useState<HTMLDivElement | null>(null);
  const [donorsScreens, setDonorsScreens] = useState([]);
  const [donorsScreenIndex, setDonorsScreenIndex] = useState(0);

  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props]);
  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, baseFolder?.folder_type);
  }, [baseFolder]);

  const donorTransition = useMemo(() => {
    const transitionName = get(baseFolder, "fields.donor_transition", TRANSITION_CLASS.TRASITION_FADE);
    let foundTransition = transitions.find((t) => t.value === transitionName);
    if (!foundTransition) {
      foundTransition = transitions.find((t) => t.value === TRANSITION_CLASS.TRASITION_FADE);
    }
    return foundTransition;
  }, [baseFolder]);

  const folderTypes = get(props, "data.folder_types", []);
  const folderType: IFolderType = folderTypes.find((f) => f.name === baseFolder.folder_type);
  const folderTypePreview = folderType.preview as IFolderTypePreview;
  const fontSize = getFontSizeBasedOnPreviewData(folderType, folderTypePreview.component_name, "unique", {
    containerWidth: props.containerWidth,
    containerHeight: props.containerHeight
  });

  const colorHeight = props.containerHeight;

  const header = getFieldValue(baseFolder, "donor_list_header", baseFolderType);
  const subhead = getFieldValue(baseFolder, "subhead", baseFolderType);
  const category = getFieldValue(baseFolder, "category", baseFolderType);
  const footer = getFieldValue(baseFolder, "footer", baseFolderType);

  
  const showUpperRule = get(baseFolder, "fields.show_upper_rule", true);

  const showLowerRule = get(baseFolder, "fields.show_lower_rule", true);

  const horizontalRuleColor = get(baseFolder, "fields.horizontal_rule_color", "#000000");

  const getStyles = () => {
    const folderType = props.data.folder_types.find((f) => f.name === baseFolder?.folder_type);

    return createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder?.fields));
  };

  const donorNameFontSize = get(baseFolder, "fields.donor_names.fontSize", 16) / 16;

  const useStyles = getStyles();
  const classes = useStyles();

  const getDonorListHeightAndOffset = () => {
    let effectiveDonorListHeight = props.refHeight;
    let totalOffset = 0;

    if (topElements) {
      const topHeight = topElements.getBoundingClientRect().height;
      effectiveDonorListHeight -= topHeight;
      totalOffset += topHeight;
    }

    if (footerEl) {
      const footerHeight = footerEl.getBoundingClientRect().height;
      effectiveDonorListHeight -= footerHeight;
    }

    return { effectiveDonorListHeight, totalOffset };
  };

  const backgroundColorOverlay = get(baseFolder, "fields.donor_background_image_overlay", "");

  const setScreensCovered = (screens) => {
    setDonorsScreens(screens);
    setTimeout(() => {
      setCanRenderDonorList(true);
    }, 1000);
  };

  const setCurrentScreenIndex = (index) => {
    // Fix implemented because the cycle was starting over and on the
    // exit transition more screens were being rendered than the ones that
    // were supposed to be rendered (AV-2055)
    if (index > 0) {
      setTimeout(() => {
        setDonorsScreenIndex(index);
      }, donorTransition?.fade_out_duration);
    }
  };

  const renderLinesByScreens = () => {
    const horizontalRuleSize = get(baseFolder, "fields.horizontal_rule_size", 1);
    const lineHeight = props.containerHeight * (horizontalRuleSize / 100);
    if (!donorsScreens.length) {
      return <></>;
    }
    return donorsScreens[donorsScreenIndex].map((sc, k) => (
      <div
        key={`line-${k}`}
        style={{
          width: props.containerWidth / 4 - fontSize * 10,
          borderTop: `${lineHeight}px solid ${horizontalRuleColor}`,
          marginLeft: k > 0 ? fontSize * 10 : 0
        }}
      />
    ));
  };

  const getOverlayWidth = () => {
    return donorsScreens.length ? (props.containerWidth / 4) * donorsScreens[donorsScreenIndex].length : 0;
  };

  const getOverlayHeight = () => {
    return colorHeight;
  };

  const getOverlayTop = () => {
    return getDonorListHeightAndOffset().totalOffset;
  };

  return (
    <div
      style={{
        height: props.containerHeight,
        width: props.containerWidth,
        position: "absolute",
        fontSize: fontSize
      }}
    >
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["donor_background_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            videoIsShorterThanCycleTime: true,
            componentSpecificData: {
              ...props.data?.componentSpecificData,
              useKeyCycler: false
            }
          }}
        />
      </div>

      {/* Background color overlay */}
        <TransitionGroup>
          <CSSTransition
            key={canRenderDonorList.toString() + (donorsScreens[donorsScreenIndex] ?? []).length}
            timeout={5000}
            classNames={TRANSITION_CLASS.TRASITION_FADE}
            >
            {backgroundColorOverlay && canRenderDonorList ? (
            <div
              style={{
                position: "absolute",
                width: getOverlayWidth(),
                height: getOverlayHeight(),
                backgroundColor: backgroundColorOverlay
              }}
              />
              ) : <></>}
          </CSSTransition>
        </TransitionGroup>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start"
        }}
      >
        <TransitionGroup style={{ zIndex: 1 }}>
          <CSSTransition
            key={canRenderDonorList.toString()}
            timeout={1000}
            classNames={TRANSITION_CLASS.TRANSITION_FADE_1S}
          >
            {canRenderDonorList ? (
              <MarinDonorListHeader
                key={'header-original'}
                category={category}
                classes={classes}
                containerHeight={props.containerHeight}
                containerWidth={props.containerWidth}
                fontSize={fontSize}
                header={header}
                linesByScreens={renderLinesByScreens()}
                showUpperRule={showUpperRule}
                subhead={subhead}
              />
            ) : (
              <></>
            )}
          </CSSTransition>
        </TransitionGroup>
        <div
          style={{
            position: "relative",
            height: getDonorListHeightAndOffset().effectiveDonorListHeight,
            width: props.containerWidth,
            opacity: canRenderDonorList ? 1 : 0
          }}
        >
          <DonorListWithRegions2108
            {...props}
            key={canRenderDonorList.toString()}
            containerHeight={getDonorListHeightAndOffset().effectiveDonorListHeight}
            data={{
              ...props.data,
              componentSpecificData: {
                ...props.data.componentSpecificData,
                donor_transition: donorTransition?.value,
                setScreensCovered,
                setCurrentScreenIndex: setCurrentScreenIndex,
                horizontal_screens: 4,
                needsColor: true,
                colorWidth: getOverlayWidth(),
                colorHeight: getOverlayHeight(),
                colorTop: getOverlayTop(),
                backgroundColorOverlay: backgroundColorOverlay,
                vertical_screens: 1,
                donor_name_field: "name",
                do_not_repeat_donors: true,
                use_em_new_format: true,
                time_to_fade_donors_in: 0,
                donor_name_style: {
                  fontFamily: get(baseFolder, "fields.donor_names.font", "Gotham Book"),
                  fontSize: `${donorNameFontSize}`,
                  color: get(baseFolder, "fields.donor_names.color", "#000000"),
                  fontWeight: get(baseFolder, "fields.donor_names.bold", false) ? "bold" : "normal",
                  textDecoration: get(baseFolder, "fields.donor_names.bold", false) ? "underline" : "normal",
                  fontStyle: get(baseFolder, "fields.donor_names.italic", false) ? "italic" : "normal",
                  textAlign: get(baseFolder, "fields.donor_names.alignment", "left"),
                  lineHeight: get(baseFolder, "fields.donor_names.lineHeight", 1)
                },
                disabled_screens: null
              }
            }}
          />
        </div>

        <TransitionGroup style={{ zIndex: 1 }}>
          <CSSTransition
            key={canRenderDonorList.toString()}
            timeout={1000}
            classNames={TRANSITION_CLASS.TRANSITION_FADE_1S}
          >
            {canRenderDonorList ? (
              <MarinDonorListFooter
                key={'footer-original'}
                classes={classes}
                containerHeight={props.containerHeight}
                containerWidth={props.containerWidth}
                donorsScreenIndex={donorsScreenIndex}
                donorsScreens={donorsScreens}
                fontSize={fontSize}
                footer={footer}
                linesByScreens={renderLinesByScreens()}
                showLowerRule={showLowerRule}
              />
            ) : (
              <></>
            )}
          </CSSTransition>
        </TransitionGroup>

        {/* The following components will render offscreen and with a opacity of 0 to get the height of the header and footer.
        This is done so the overlay can be positioned correctly. */}
        <div style={{ opacity: 0 }}>
          <MarinDonorListHeader
            key={'header-dummy'}
            category={category}
            classes={classes}
            containerHeight={props.containerHeight}
            containerWidth={props.containerWidth}
            fontSize={fontSize}
            header={header}
            linesByScreens={renderLinesByScreens()}
            setTopElements={setTopElements}
            showUpperRule={showUpperRule}
            subhead={subhead}
          />
          <MarinDonorListFooter
            key={'footer-dummy'}
            classes={classes}
            containerHeight={props.containerHeight}
            containerWidth={props.containerWidth}
            donorsScreenIndex={donorsScreenIndex}
            donorsScreens={donorsScreens}
            fontSize={fontSize}
            footer={footer}
            linesByScreens={renderLinesByScreens()}
            setFooterEl={setFooterEl}
            showLowerRule={showLowerRule}
          />
        </div>
      </div>
    </div>
  );
};

export default MarinDonorLists;
