import { SEARCH_MAIN, SEARCH_TAG } from "./actionTypes";

export const searchMain = (data, onSuccess?, onFail?) => ({
  type: SEARCH_MAIN,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const searchTag = (data, onSuccess?, onFail?) => ({
  type: SEARCH_TAG,
  payload: { search: data },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
