import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { berkeley_root } from "./berkeley_root";
import { berkeley_displays } from "./berkeley_displays";
import { berkeley_display } from "./berkeley_display";
import { berkeley_content } from "./berkeley_content";
import { berkeley_presentations } from "./berkeley_presentations";
import { berkeley_presentation_3x2 } from "./berkeley_presentation_3x2";
import { berkeley_donor_list } from "./berkeley_donor_list";
import { berkeley_content_donor_seas } from "./berkeley_content_donor_seas";
import { berkeley_donor_list_group } from "./berkeley_donor_list_group";
import { berkeley_content_donor_lists } from "./berkeley_content_donor_lists";
import { berkeley_donor } from "./berkeley_donor";
import { berkeley_donor_sea } from "./berkeley_donor_sea";
import { berkeley_donor_sea_category } from "./berkeley_donor_sea_category";
import { berkeley_donor_sea_categories } from "./berkeley_donor_sea_categories";
import { berkeley_donor_sea_backgrounds } from "./berkeley_donor_sea_backgrounds";
import { berkeley_donor_sea_background } from "./berkeley_donor_sea_background";
import { berkeley_template_001 } from "./berkeley_template_001";
import { berkeley_template_002 } from "./berkeley_template_002";
import { berkeley_template_003 } from "./berkeley_template_003";
import { berkeley_template_004 } from "./berkeley_template_004";
import { berkeley_template_005 } from "./berkeley_template_005";
import { berkeley_content_stories } from "./berkeley_content_stories";

export const berkeley_types: { [key: string]: IFolderType } = {
  berkeley_root,
  berkeley_content,
  berkeley_presentations,
  berkeley_presentation_3x2,
  berkeley_displays,
  berkeley_display,
  berkeley_donor_list,
  berkeley_donor_sea,
  berkeley_template_001,
  berkeley_template_002,
  berkeley_template_003,
  berkeley_template_004,
  berkeley_template_005,
  berkeley_content_stories,
  berkeley_donor_list_group,
  berkeley_content_donor_lists,
  berkeley_content_donor_seas,
  berkeley_donor_sea_category,
  berkeley_donor,
  berkeley_donor_sea_categories,
  berkeley_donor_sea_backgrounds,
  berkeley_donor_sea_background,
};
