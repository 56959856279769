import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";

export const naples_fund_giving_level: IFolderType = {
  folder_category: FOLDER_CATEGORY.GROUP,
  name: FOLDER_TYPE.naples_fund_giving_level,
  display_name: "Fund Giving Level",
  acceptable_folder_types: [FOLDER_TYPE.naples_fund_folder_red, FOLDER_TYPE.naples_fund_folder_blue],
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    cycle: {
      type: "integer",
      title: "Default Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 1000 }] },
      default: 30
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "1x3", orientation: "landscape", horizontal: 1, vertical: 3 }]
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
