import React from "react";
import {
  generateFontSizeClassesFromFields,
  getFieldValue,
} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import { getBaseFolder } from "../../../utils/commonDataUtils";
import { createUseStyles } from "react-jss";

const AlpineStoryWithFactCarouselFact = (props) => {
  const fact_heading = getFieldValue(
    props.fact,
    "fact_heading",
    props.baseFolderType,
    props.showPlaceholder
  );
  const fact_body = getFieldValue(
    props.fact,
    "fact_body",
    props.baseFolderType,
    props.showPlaceholder
  );

  const getStyles = () => {
    const baseFolder = getBaseFolder({
      ...props.data,
      base_folder: props.fact.id,
    });
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.fact?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        width: "100%",
        flexDirection: "column",
        height: "100%",
      }}
      className={classes.componentContainer}
    >
      <RichTextRender
        className="factHeading"
        style={{
          lineHeight: 1.15,
          marginBottom: "2.125em",
          padding: 0,
        }}
        htmlString={fact_heading}
      />
      <RichTextRender
        className="factHeading"
        style={{
          lineHeight: 1.15,
          padding: 0,
        }}
        htmlString={fact_body}
      />
    </div>
  );
};

export default AlpineStoryWithFactCarouselFact;
