import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider from "react-slick";
import { loopIndex } from "../../../shared-global/utils/general";
import { getResourceFoldersOfFileDynamic } from "../../utils/fileUtils";
import CycleWrapper from "./CycleWrapper";
import Slide1911 from "./Slide1911";
import get from 'lodash/get';
import {getBaseFolder} from "../../utils/commonDataUtils";

const MediaCarousel = (props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentDot, setCurrentDot] = useState(0)
  const slideRef = useRef(null);
  const [componentHasMounted, setComponentHasMounted] = useState(false)
  const [folderCycleTime, setFolderCycleTime] = useState(1000)

  let dotsStyles = {
    position: "absolute",
    bottom: props.fontSize * 2.25,
    width: props.imageWidth || props.width,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties

  if (props.dotsStyles) {
    dotsStyles = props.dotsStyles
  }

  useEffect(() => {
    if (slideRef && slideRef.current && componentHasMounted) {
      setCurrentDot(currentSlideIndex)
      slideRef.current.slickNext();
    }
  }, [currentSlideIndex]);

  useEffect(() => {
    const baseFolder = getBaseFolder(props.data);
    const folderType = (props.data?.folder_types ?? []).find(
      (fT) => fT.name === baseFolder?.folder_type
    );
    const cycleFieldName = get(folderType, "cycle_field_name", "cycle_time");
    setFolderCycleTime(get(baseFolder, `fields.${cycleFieldName}`, 5) * 1000)
  }, [])

  const slickConfiguration = useMemo(() => {
    const fade = get(props, 'componentSpecificData.slickConfiguration.fade', false);
    const speed = get(props, 'componentSpecificData.slickConfiguration.speed', 1000);
    return { fade, speed };
  }, [props.data]);

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      props.slotNames,
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = props.resourceFoldersCarousel || getResourceFoldersOfFileDynamicCallback();

  let componentSpecificData = { useKeyCycler: false };

  if (props.componentSpecificData) {
    componentSpecificData = {
      ...props.componentSpecificData,
      customStyleImage: {
        ...props.componentSpecificData?.customStyleImage,
      },
      customStyleVideo: {
        ...props.componentSpecificData?.customStyleVideo,
      }
    }
  }

  return (
    resourceFoldersCarousel.length ? (
      <div
        style={{
          ...props.style,
          height: props.height || "100%",
          width: props.width || "100%",
        }}
      >
        <Slider
          ref={slideRef}
          dots={false}
          infinite={true}
          arrows={false}
          slidesToShow={1}
          speed={folderCycleTime < 3000 ? 500 : slickConfiguration.speed}
          initialSlide={0}
          fade={slickConfiguration.fade}
        >
          {
            resourceFoldersCarousel.map((f, index) => (
              <>
                {
                  index === currentSlideIndex && (
                    <CycleWrapper
                      key={`${index}-cycle`}
                      {...props}
                      data={{
                        ...props.data,
                        index,
                        playFullVideo: true,
                        cycleVideoMsAhead: 2000,
                      }}
                      handleEndOfPlay={() => {
                        setComponentHasMounted(true)
                        const newIndex = loopIndex(currentSlideIndex, 0, resourceFoldersCarousel.length - 1, "forward")
                        setCurrentSlideIndex(newIndex)
                        if (newIndex === 0 && props.handleEndOfPlay) {
                          props.handleEndOfPlay();
                        }
                      }}
                    />
                  )
                }
                <Slide1911
                  key={index}
                  {...props}
                  handleEndOfPlay={null}
                  data={{
                    ...props.data,
                    slotNames: props.slotNames,
                    videoIsShorterThanCycleTime: true,
                    muted: false,
                    positionRelative: true,
                    index,
                    autoPlay: index === currentSlideIndex,
                    customStyleImage: {
                      position: 'relative',
                      width: "100%",
                      backgroundPosition: 'top center'
                    },
                    componentSpecificData,
                  }}
                />
              </>
            ))
          }
        </Slider>
        {!props.hideDots && resourceFoldersCarousel.length > 1 && (
          <div style={{ ...dotsStyles }}>
            {resourceFoldersCarousel.map((item, index) => (
              <div
                className="carouselItem"
                style={{
                  width: "1em",
                  height: "1em",
                  marginRight: "1em",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderColor: "#000000",
                  borderRadius: "50%",
                  backgroundColor: index === currentDot ? "#000000" : "",
                }}
              />
            ))}
          </div>
        )}
      </div>
    ) : <></>
  );
}

export default MediaCarousel
