import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        x: 0,
        y: 0,
        aspect: this.props.aspect,
        width: 0,
        height: 0
      }
    };
  }

  imageLoaded = image => {
    const { imageLoaded, aspect } = this.props;
    imageLoaded(image);
    const bound = image.getBoundingClientRect();
    const imageAspect = bound.width / bound.height;

    if (aspect) {

      let crop;
      if (imageAspect === aspect) {

        crop = {
          x: 0,
          y: 0,
          aspect,
          width: 100,
          height: 100
        }
      } else if (imageAspect > aspect) {
        crop = {
          x: 0,
          y: 0,
          aspect,
          width: ((bound.height * aspect) / bound.width) * 100,
          height: 100
        }
      } else {
        crop = {
          x: 0,
          y: 0,
          aspect,
          width: 100,
          height: (bound.width / aspect / bound.height) * 100
        }
      }
      this.onChange(crop);
    }
  };

  render() {
    return (
      <ReactCrop
        key={this.state.cropKey}
        onChange={this.onChange}
        src={this.props.url}
        crop={this.state.crop}
        onImageLoaded={this.imageLoaded}
        keepSelection={true}
        minHeight={5}
        minWidth={5}
        className={"react-crop-background"}
      />
    );
  }

  onChange = crop => {
    console.log("crop before", crop)
    this.setState({ crop });
    this.props.onChange(crop);
  };
}

export default ImageCropper;
