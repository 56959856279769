import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields, getFieldValue } from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {
  SPR_PORTRAIT_BODY_LINE_HEIGHT,
  SPR_PORTRAIT_BODY_MARGIN_TOP,
  SPR_PORTRAIT_HEADING_LINE_HEIGHT,
  SPR_PORTRAIT_HEADING_MARGIN_TOP,
  SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM,
  SPR_PORTRAIT_SUBHEAD_LINE_HEIGHT,
  SPR_PORTRAIT_SUBHEAD_MARGIN_TOP
} from "./SpringfieldPortraitConstants";

const SpringfieldPortraitTemplate002 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const imageFieldType = useMemo(() => {
    return get(baseFolderType, "fields[background]", null);
  }, [baseFolderType]);

  const color = useMemo(() => {
    return get(baseFolder, "fields.color_overlay", "transparent");
  }, [baseFolder]);

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings?.data, "show_placeholder", false);
  }, [props.data]);

  const getStyles = useCallback(() => {
    if (baseFolder) {
      const folderType = props.data?.folder_types?.find((f) => f.name === baseFolder.folder_type);
      const styles = createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder.fields));
      return styles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, [baseFolder?.folder_type, props.data?.folder_types]);

  const useStyles = getStyles();
  const classes = useStyles();

  const headline = getFieldValue(baseFolder, "headline", baseFolderType);
  const subhead = getFieldValue(baseFolder, "subhead", baseFolderType);
  const bodyText = getFieldValue(baseFolder, "body", baseFolderType);
  const mirrorContent = get(baseFolder, "fields.mirror_content", false);

  return (
    <>
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"]
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: color,
          zIndex: 0
        }}
      />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          display: "flex",
          flex: 1,
          flexDirection: !mirrorContent ? "column" : "column-reverse",
          justifyContent: "space-evenly"
        }}
      >
        {/* Images */}
        <div
          style={{
            height: "50%"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <Slide1911
              aws={props.aws}
              aws_bucket={props.aws_bucket}
              mode={props.mode}
              containerWidth={props.containerWidth}
              containerHeight={(986 / 1920) * props.containerHeight}
              data={{
                ...props.data,
                slotNames: ["image_1"],
                positionRelative: "relative",
                placeholder: "image 1",
                fieldType: imageFieldType,
                showPlaceholder: showPlaceholder,
                componentSpecificData: {
                  customStyle: {
                    height: "100%",
                    width: "100%"
                  }
                }
              }}
              webview_signedurls={props.webview_signedurls}
            />
          </div>
        </div>
        {/* Text */}
        <div
          style={{
            height: "50%",
            color: "#000000",
            paddingLeft: 0.12 * props.containerWidth,
            paddingRight: 0.12 * props.containerWidth,
            paddingTop: SPR_PORTRAIT_HEADING_MARGIN_TOP * props.containerHeight,
            paddingBottom: 0.035 * props.containerHeight
          }}
        >
          <div
            style={{
              width: "100%",
              overflowX: "hidden",
              overflowY: "hidden",
              maxHeight: "100%"
            }}
          >
            {/* Headline */}

            <div
              style={{
                padding: 0,
                lineHeight: SPR_PORTRAIT_HEADING_LINE_HEIGHT,
                overflowX: "hidden",
                overflowY: "hidden",
                paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight
              }}
              className={classes.componentContainer}
            >
              <RichTextRender
                htmlString={headline}
                style={{
                  lineHeight: "1.2",
                  padding: 0
                }}
              />
            </div>
            {/* Sub Head */}
            {subhead && (
              <div
                className="springfield-template-002_subhead"
                style={{
                  marginTop: SPR_PORTRAIT_SUBHEAD_MARGIN_TOP * props.containerHeight
                }}
              >
                <div
                  style={{
                    padding: 0,
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight,
                    lineHeight: SPR_PORTRAIT_SUBHEAD_LINE_HEIGHT
                  }}
                  className={classes.componentContainer}
                >
                  <RichTextRender
                    htmlString={subhead}
                    style={{
                      lineHeight: "1.2",
                      padding: 0
                    }}
                  />
                </div>
              </div>
            )}
            {/* Body Text */}
            <div
              className="springfield-template-002_body-text"
              style={{
                marginTop: SPR_PORTRAIT_BODY_MARGIN_TOP * props.containerHeight
                //backgroundColor: 'teal'
              }}
            >
              <div
                style={{
                  padding: 0,
                  lineHeight: SPR_PORTRAIT_BODY_LINE_HEIGHT,
                  overflowX: "hidden",
                  overflowY: "hidden",
                  paddingBottom: SPR_PORTRAIT_RICH_TEXT_PADDING_BOTTOM * props.containerHeight
                }}
                className={classes.componentContainer}
              >
                <RichTextRender
                  htmlString={bodyText}
                  style={{
                    lineHeight: "1.2",
                    padding: 0
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpringfieldPortraitTemplate002;
