import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { los_angeles_root } from "./los_angeles_root";
import { los_angeles_slides } from "./los_angeles_slides";
import { los_angeles_slide } from "./los_angeles_slide";
import { los_angeles_three_panel } from "./los_angeles_three_panel";
import { los_angeles_donor_lists } from "./los_angeles_donor_lists";
import { los_angeles_donor_list } from "./los_angeles_donor_list";
import { los_angeles_donor_list_giving_level } from "./los_angeles_donor_list_giving_level";
import { los_angeles_donor } from "./los_angeles_donor";

export const los_angeles_types: { [key: string]: IFolderType } = {
  los_angeles_root,
  los_angeles_slides,
  los_angeles_slide,
  los_angeles_three_panel,
  los_angeles_donor_lists,
  los_angeles_donor_list,
  los_angeles_donor,
  los_angeles_donor_list_giving_level
};
