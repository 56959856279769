import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { eugene_template_shared } from "./eugene_template_shared";

const modifiedFieldGroups = { ...eugene_template_shared.field_groups };

export const eugene_template_005: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.eugene_template_005,
  display_name: "Template 5 - Landscape - Empty Left - Story Right",
  tabs: {
    ...eugene_template_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...eugene_template_shared.fields
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneTemplate005,
    type: PREVIEW_TYPE.COMPONENT
  },
  tab_previews: [
    {
      tab_key: "general",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneTemplate005,
      type: PREVIEW_TYPE.COMPONENT
    },
    {
      tab_key: "card_view",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneTemplateCardView,
      type: PREVIEW_TYPE.COMPONENT
    }
  ],
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_002.svg"
  }
};
