import { FOLDER_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const new_orleans_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.new_orleans_root,
  display_name: "New Orleans Root",
  acceptable_folder_types: [FOLDER_TYPE.new_orleans_1x2],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  }
};
