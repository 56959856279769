import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { transitions } from "./marin_settings";

export const marin_layout: IFolderType = {
  folder_category: FOLDER_CATEGORY.LAYOUT,
  name: FOLDER_TYPE.marin_layout,
  display_name: "Layout",
  acceptable_folder_types: [
    FOLDER_TYPE.marin_story_media_with_quote,
    FOLDER_TYPE.marin_story_media_with_quote_2_1,
    FOLDER_TYPE.marin_story_large_media_with_quote,
    FOLDER_TYPE.marin_story_with_fact,
    FOLDER_TYPE.marin_fullscreen_fact_story,
    FOLDER_TYPE.marin_profile_with_media_story,
    FOLDER_TYPE.marin_story_with_media,
    FOLDER_TYPE.marin_intro_story,
    FOLDER_TYPE.marin_media_blank_1,
    FOLDER_TYPE.marin_media_blank_2,
    FOLDER_TYPE.marin_media_blank_3,
    FOLDER_TYPE.marin_media_blank_4,
    FOLDER_TYPE.marin_media_blank_5,
    FOLDER_TYPE.marin_media_blank_6,
    FOLDER_TYPE.marin_media_blank_7,
    FOLDER_TYPE.marin_media_blank_8,
    FOLDER_TYPE.marin_donor_list,
    FOLDER_TYPE.marin_content_donor_lists
  ],
  children_conditions: [
    { type: "slot_limit", value: 8 },
    { type: "slot_limit_skip_validation", value: true }
  ],
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Default Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_transition"
      }
    },
    background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_background_image_overlay"
      }
    },
    background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 4.5,
      recommended_size: {
        width: 8640,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 4.5
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "layout_5_background_image"
      }
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name", "sorting_order"]
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 4.5,
    component_name: COMPONENT_NAME.Marin8x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 8, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    mode: "center",
    scrollable: true,
    option_height: 200,
    columns: 0
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false,
  warn_moving_to_presentation_without_children: true
};
