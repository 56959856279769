import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { clientOptionsSlice } from "../reducers/clientOptionsSlice";
import dataReducer from "../reducers/dataReducer";
import rootSaga from "../sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    client_options: clientOptionsSlice.reducer,
    data: dataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
