export default {
  field_groups: {
    default: {
      title: 'Update User:',
      default_expanded: true,
    }
  },
  fields: {
    first_name: {
      type: 'text',
      title: 'First Name:',
      default: ''
    },
    last_name: {
      type: 'text',
      title: 'Last Name:',
      default: ''
    },
    theme: {
      type: 'select',
      title: "Theme",
      //default: "kitchen",
      options: [{
        title: "Light (default)",
        value: "light"
      }, {
        title: "Dark",
        value: "dark"
      }]
    },
    current_password: {
      type: 'password',
      title: 'Current Password:',
      default: ''
    },
    new_password: {
      type: 'password',
      title: 'New Password:',
      default: ''
    },
    confirmpassword: {
      type: 'password',
      title: 'Confirm Password:',
      //validation: { rules: [{ name: 'matchesVal', val: 'newpassword', errMessage: 'Password must match' }]},
      default: ''
    }
  }
};