import get from "lodash/get";
import React from "react";
import Slide1911 from "../../standard/Slide1911";
import {useFolder} from "../../../hooks/useFolder";
import { createUseStyles } from "react-jss";
import styles from "./styles";
import {useScale} from "../../../hooks/useScale";

const useStyles = createUseStyles(styles);

const AlpineOneScreenMedia = (props) => {
  const { getBaseFolder, getBaseFolderFieldValue } = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const backgroundOverlay = getBaseFolderFieldValue("fullscreen_image_overlay")
  const { standardSizing, getUnitBasedOnHeight } = useScale(props.data.base_folder);

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundOverlay,
    standardSizing,
    getUnitBasedOnHeight,
  })

  return (
    <div className={classes.componentContainer}>
      <div className={classes.componentContainer}>
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["fullscreen_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            style: {
              backgroundPosition: "inherit",
            }
          }}
        />
      </div>
      <div className={classes.componentOverlay}/>
    </div>
  );
};

export default AlpineOneScreenMedia;
