import get from "lodash/get";
import React from "react";
import { getFieldValue } from "../../../utils/generalUtils";
import CycleWrapper from "../../standard/CycleWrapper";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";

const MarinIntroStory = (props) => {

  const body = getFieldValue(props.baseFolder, 'body', props.baseFolderType, props.showPlaceholder);
  const backgroundColor = get(props.baseFolder, "fields.background_color", "#76232f")

  return (
    <CycleWrapper {...props}>
      <div
        style={{
          position: 'absolute',
          width: props.containerWidth,
          height: props.containerHeight,
          fontSize: props.fontSize
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight="100%"
          containerWidth="100%"
          data={{
            ...props.data,
            slotNames: ["background_image"],
            base_folder: get(props.baseFolder, "id", null),
            backgroundSize: "contain",
            componentSpecificData: {
              ...props.data.componentSpecificData,
              customStyleImage: {
                width: props.containerWidth,
                paddingRight: "5em",
                paddingLeft: "5em",
                zIndex: "-1"
              },
            },
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: 'flex-start',
            height: "100%",
            width: "100%",
            backgroundColor
          }}
        >
          <div
            style={{
              alignItems: "flex-start",
              width: "50%",
              paddingTop: '25em',
            }}
          >
            <Slide1911
              {...props}
              handleEndOfPlay={null}
              containerHeight="100%"
              containerWidth="100%"
              data={{
                ...props.data,
                slotNames: ["media"],
                base_folder: get(props.baseFolder, "id", null),
                backgroundSize: "contain",
                componentSpecificData: {
                  ...props.data.componentSpecificData,
                  customStyleImage: {
                    width: (props.containerWidth / 2) - (props.fontSize * 10),
                    left: props.fontSize * 5,
                    top: (props.containerHeight * -0.1618)
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              height: '100%',
              padding: "15em 5em",
              width: "50%"
            }}
          >
            <div
              className={props.classes.componentContainer}
            >
              <RichTextRender
                htmlString={body}
              />
            </div>
          </div>
        </div>
      </div>
    </CycleWrapper>
  )
}

export default MarinIntroStory
