import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../interfaces/main-state.interface";
import { callNotifications } from "../../services/notificationService";
import { COMPONENT_MODE_CONTEXT } from "../../shared-global/enums/config-enums";
import { COMPONENT_NAME } from "../../shared-global/enums/folder-type-enums";
import { IFolderType } from "../../shared-global/interfaces/folder-type-interfaces";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { IURIAccess } from "../../shared-global/interfaces/models/uri_access.interface";
import MobileTemplate from "../../shared/components/standard/MobileTemplate";
import { getChildrenFolderAndFolderFoldersDeep } from "../../shared/utils/collectionUtils";

interface IWebviewResponsiveContainer {
  uri_access: IURIAccess;
}

const WebviewResponsiveContainer: React.FC<IWebviewResponsiveContainer> = (
  props
) => {
  const {
    folder__folders,
    folder_types,
    webview_signedurls,
    folders,
    resources,
    resource__folders
  } = useSelector((state: IState) => state.data);

  const [folder, setFolder] = useState<IFolder>(null);
  const [folderType, setFolderType] = useState<IFolderType>(null);
  const [baseData, setBaseData] = useState(null);

  useEffect(() => {
    let foundFolder = folders[props.uri_access.folder];
    let foundFolderType = Object.values(folder_types).find(f => f.name === foundFolder.folder_type);

    // The webview settings are set at the display level, but most of the time, it will be the child folder
    // (presentation) that will be previewed, so we look for that folder.
    if (foundFolderType.webview.use_child_folder_type) {
      const childrenFolderFolders = Object.values(folder__folders).filter(
        (ff) => ff.parent_folder === foundFolder.id
      );
      if (childrenFolderFolders.length) {
        const childFolderFolder = childrenFolderFolders[0];
        foundFolder = folders[childFolderFolder.child_folder];
        foundFolderType = Object.values(folder_types).find(f => f.name === foundFolder.folder_type);
      }
    }

    setFolder(foundFolder);
    setFolderType(foundFolderType);

    console.log(folder__folders);
    const foundBaseData = getChildrenFolderAndFolderFoldersDeep(
      {},
      Object.values(folder__folders),
      Object.values(folders),
      Object.values(resources),
      Object.values(resource__folders),
      Object.values(folder_types),
      null,
      null,
      foundFolder,
      null
    );
    setBaseData(foundBaseData);
    console.log(foundBaseData);
  }, []);

  const getComponent = () => {
    let componentName = "";
    if (Array.isArray(folderType.preview)) {
    } else {
      componentName = folderType.preview.component_name;
    }

    switch (componentName) {
      case COMPONENT_NAME.MobileTemplate:
        return (
          <MobileTemplate
            containerWidth={window.innerWidth}
            containerHeight={window.innerHeight}
            data={baseData}
            webview_signedurls={webview_signedurls}
            mode={COMPONENT_MODE_CONTEXT.WEBVIEW}
          />
        );
      default:
        return null;
    }
  };

  if (folderType && folder) {
    return getComponent();
  }

  return null;
};

export default WebviewResponsiveContainer;
