import {
  CREATE_BACKUP,
  CREATE_CROSS_BACKUP,
  EXPORT_BACKUP,
  GET_BACKUPS_BY_COMPANY,
  RESTORE_BACKUP
} from "./actionTypes";

export const createBackup = (api_identifier, onSuccess?, onFail?) => ({
  type: CREATE_BACKUP,
  payload: { api_identifier },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getBackupsByCompany = (
  company,
  folder_type,
  onSuccess?,
  onFail?
) => ({
  type: GET_BACKUPS_BY_COMPANY,
  payload: { company: company, folder_type: folder_type },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const restoreBackup = (backup_id, folder_id, onSuccess?, onFail?) => ({
  type: RESTORE_BACKUP,
  payload: { backup_id: backup_id, folder_id: folder_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const exportBackup = (backup_id, onSuccess?, onFail?) => ({
  type: EXPORT_BACKUP,
  payload: { backup_id: backup_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const createCrossBackup = (file, onSuccess?, onFail?) => ({
  type: CREATE_CROSS_BACKUP,
  payload: { file: file },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
