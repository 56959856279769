import { wysiwyg } from "../standardized_fields";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const fontOptions = [
  FontsFamilies.SERIF,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.LATO
];

export const wysiwyg_template = {
  ...wysiwyg,
  default: {
    type: "style", // Options are: style | ops
    style: {
      bold: true,
      font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
      size: "40em"
    },
    ops: []
  },
  settings: {
    fonts: fontOptions,
    fontSizeOptions: {
      type: "range", // Could be range or custom (supply)
      min: 10,
      max: 50
    },
    recommended_length: 42,
    controls: {
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      numberedList: true,
      bulletList: true,
      alignment: true,
      indentation: true,
      clearFormat: true
    },
    theme: "eugene"
  }
};

export const eugene_template_shared = {
  tabs: {
    main: {
      title: "Main"
    },
    card_view: {
      title: "Card View"
    }
  },
  field_groups: {
    name: {
      title: "Name/Title",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "main"
    },
    cycle_time: {
      title: "Cycle Time",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "main"
    },
    headline: {
      title: "Header",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "main"
    },
    subhead: {
      title: "Subhead",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "main"
    },
    body: {
      title: "Body Text",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "main"
    },
    quote: {
      title: "Quote",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "main"
    },
    attribution: {
      title: "Attribution",
      default_expanded: false,
      order: 7,
      accordion: true,
      tab: "main"
    },
    background_overlay: {
      title: "Background Overlay",
      default_expanded: false,
      order: 8,
      accordion: true,
      tab: "main"
    },
    background: {
      title: "Background",
      default_expanded: false,
      order: 9,
      accordion: true,
      tab: "main"
    },
    card_type: {
      title: "Card Type",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "card_view"
    },
    override_headline_small: {
      title: "Override Header Small",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "card_view"
    },
    override_subhead_small: {
      title: "Override Subhead Small",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "card_view"
    },
    override_body_text_small: {
      title: "Override Body Text Small",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "card_view"
    },
    override_thumbnail_image_small: {
      title: "Override Thumbnail Image Small",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "card_view"
    },
    override_background_small: {
      title: "Override Background Small",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "card_view"
    },
    override_headline_medium: {
      title: "Override Header Medium",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "card_view"
    },
    override_subhead_medium: {
      title: "Override Subhead Medium",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "card_view"
    },
    override_body_text_medium: {
      title: "Override Body Text Medium",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "card_view"
    },
    override_thumbnail_image_medium: {
      title: "Override Thumbnail Image Medium",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "card_view"
    },
    override_background_medium: {
      title: "Override Background Medium",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "card_view"
    },
    override_headline_large: {
      title: "Override Header Large",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "card_view"
    },
    override_subhead_large: {
      title: "Override Subhead Large",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "card_view"
    },
    override_body_text_large: {
      title: "Override Body Text Large",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "card_view"
    },
    override_thumbnail_image_large: {
      title: "Override Thumbnail Image Large",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "card_view"
    },
    override_background_large: {
      title: "Override Background Large",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "card_view"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Reference Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "name"
    },
    headline: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "42em",
          color: "#2699FB"
        },
        ops: []
      },
      title: "Header",
      field_group: "headline"
    },
    subhead: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "33em",
          color: "#9D9D9D"
        },
        ops: []
      },
      title: "Subhead",
      field_group: "subhead",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    body: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "25em",
          color: "#707070"
        },
        ops: []
      },
      title: "Body Text",
      field_group: "body"
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "background_overlay",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    size_options: {
      type: "select",
      title: "Card Type",
      validation: { rules: [{ name: "isRequired" }] },
      default: "large",
      options: [
        {
          title: "Small",
          value: "small"
        },
        {
          title: "Medium",
          value: "medium"
        },
        {
          title: "Large",
          value: "large"
        }
      ],
      field_group: "card_type",
      conditional_trigger: true
    },
    override_headline_small: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          color: "#2699FB",
          bold: true,
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "35em"
        },
        ops: []
      },
      title: "Override Header Small",
      field_group: "override_headline_small",
      conditional_show: [{ field: "size_options", value: "small" }]
    },
    override_subhead_small: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          color: "#2699FB",
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "30em"
        },
        ops: []
      },
      title: "Override Subhead Small",
      field_group: "override_subhead_small",
      conditional_show: [{ field: "size_options", value: "small" }]
    },
    override_body_text_small: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "21em"
        },
        ops: []
      },
      title: "Overide Body Text Small",
      field_group: "override_body_text_small",
      conditional_show: [{ field: "size_options", value: "small" }]
    },
    override_thumbnail_image_small: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "override_thumbnail_image_small",
      conditional_show: [{ field: "size_options", value: "small" }]
    },
    override_background_small: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "override_background_small",
      conditional_show: [{ field: "size_options", value: "small" }]
    },
    override_headline_medium: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "38em",
          color: "#2699FB"
        },
        ops: []
      },
      title: "Override Header Medium",
      field_group: "override_headline_medium",
      conditional_show: [{ field: "size_options", value: "medium" }]
    },
    override_subhead_medium: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "23em",
          color: "#2699FB"
        },
        ops: []
      },
      title: "Override Subhead Medium",
      field_group: "override_subhead_medium",
      conditional_show: [{ field: "size_options", value: "medium" }]
    },
    override_body_text_medium: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "25em",
          color: "#1C1C1C"
        },
        ops: []
      },
      title: "Override Body Text Medium",
      field_group: "override_body_text_medium",
      conditional_show: [{ field: "size_options", value: "medium" }]
    },
    override_thumbnail_image_medium: {
      title: "Override Background Medium",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "override_thumbnail_image_medium",
      conditional_show: [{ field: "size_options", value: "medium" }]
    },
    override_background_medium: {
      type: "color",
      title: "Override Color Overlay Medium",
      default: "",
      field_group: "override_background_medium",
      conditional_show: [{ field: "size_options", value: "medium" }]
    },
    override_headline_large: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
          size: "30em",
          color: "#2699FB"
        },
        ops: []
      },
      title: "Override Header Large",
      field_group: "override_headline_large",
      conditional_show: [{ field: "size_options", value: "large" }]
    },
    override_subhead_large: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "33em",
          color: "#2699FB"
        },
        ops: []
      },
      title: "Override Subhead Large",
      field_group: "override_subhead_large",
      conditional_show: [{ field: "size_options", value: "large" }]
    },
    override_body_text_large: {
      ...wysiwyg_template,
      default: {
        type: "style", // Options are: style | ops
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "20em",
          color: "#1C1C1C"
        },
        ops: []
      },
      title: "Override Body Text Large",
      field_group: "override_body_text_large",
      conditional_show: [{ field: "size_options", value: "large" }]
    },
    override_thumbnail_image_large: {
      title: "Override Background large",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "override_thumbnail_image_large",
      conditional_show: [{ field: "size_options", value: "large" }]
    },
    override_background_large: {
      type: "color",
      title: "Override Color Overlay",
      default: "",
      field_group: "override_background_large",
      conditional_show: [{ field: "size_options", value: "large" }]
    }
  }
};
