import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { sorting_options } from "../standardized_fields";
import {list_styles, textstyle_options, transitions, wysiwygSettings} from "./alpine_settings";

export const alpine_donor_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.alpine_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "List",
  acceptable_folder_types: [FOLDER_TYPE.alpine_donor],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    donor_list: {
      title: "Donor List",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    horizontal_rules: {
      title: "Horizontal Rules",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "general"
    },
    list_settings: {
      title: "List Settings",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    //#region general

    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 0, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      tooltip: "Cycle time in seconds",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 1000 }] },
      default: 5,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
      }
    },

    sorting_options: {
      ...sorting_options,
      field_group: "general"
    },

    // #endregion

    //#region donor_list

    header: {
      type: "hybrid_text_input",
      title: "Donor List Header",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: '#FFFFFF',
            bold: true,
            font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
            size: "21px"
          },
          ops: []
        }
      },
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_header"
      },
      field_group: "donor_list"
    },
    description: {
      type: "hybrid_text_input",
      title: "Donor List Description",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: '#FFFFFF',
            font: fonts[FontsFamilies.RECTOR][FontVariants.MEDIUM],
            size: "12px"
          },
          ops: []
        }
      },
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_description"
      },
      field_group: "donor_list"
    },
    category: {
      type: "hybrid_text_input",
      title: "Donor List Category",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: '#FFFFFF',
            font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
            size: "16px"
          },
          ops: []
        }
      },
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_category"
      },
      field_group: "donor_list"
    },
    columns: {
      type: "integer",
      title: "Columns",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 4 }] },
      default: 2,
      field_group: "donor_list"
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 5,
      field_group: "donor_list"
    },

    list_names: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#FFFFFF",
        alignment: "left",
        font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
        fontSize: "10px",
        lineHeight: 1.25
      },
      options: {
        ...textstyle_options,
        lineHeight: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "donor_list"
    },

    footer: {
      type: "hybrid_text_input",
      title: "Footer",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ABC_SOCIAL][FontVariants.REGULAR],
            size: "10px"
          },
          ops: []
        }
      },
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_footer"
      },
      field_group: "donor_list"
    },

    //#endregion

    //#region horizontal_rules

    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "horizontal_rules"
    },

    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "horizontal_rules"
    },

    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "horizontal_rules"
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 0.1,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      },
      field_group: "horizontal_rules"
    },

    //#endregion

    //#region background

    background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_background"
      },
      field_group: "background"
    },

    color_overlay: {
      type: FOLDER_TYPE_NAME.COLOR,
      title: "Background Color Overlay",
      default: "#E6955F",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "list_color_overlay"
      },
      field_group: "background"
    },

    //#endregion

    //#region advanced

    description_top: {
      type: "integer",
      title: "Description Margin Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 4 }] },
      default: 0,
      field_group: "list_settings"
    },

    container_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 2,
      field_group: "list_settings"
    },

    donor_vertical_separation: {
      type: "integer",
      title: "Donor Vertical Separation",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 2,
      field_group: "list_settings"
    },

    donor_list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },

    donor_list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },

    cycle_forward: {
      type: "text",
      title: "Cycle Forward Key",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 0, max: 75 }] },
      default: "ArrowRight",
      sys_admin_only: true,
      show_char_count: false,
      field_group: "list_settings"
    },

    donor_list_style: {
      type: "select",
      title: "List Style",
      validation: { rules: [{ name: "isRequired" }] },
      default: "filled",
      options: list_styles,
      field_group: "list_settings"
    }

    //#endregion
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.alpine_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.alpine_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.alpine_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.alpine_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.AlpineContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
