import React from 'react'
import {generateFontSizeClassesFromFields, getFieldValue} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import {getBaseFolder} from "../../../utils/commonDataUtils";
import { createUseStyles } from "react-jss";

const emptyHTML = "<p><br/></p>";

const MarinQuote = (props) => {
  let quote_body = getFieldValue(props.quote, props.quoteKey || 'quote_body', props.baseFolderType, props.showPlaceholder);

	if (quote_body === emptyHTML) {
		quote_body = '';
	}

  const getStyles = () => {
    const baseFolder = getBaseFolder({...props.data, base_folder: props.quote.id});
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.quote?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        overflow: 'hidden',
        width: "100%",
        flexDirection: 'column',
        height: '100%'
      }}
      className={classes.componentContainer}
    >
      {quote_body.length > 0 && <RichTextRender
        className="quoteHeading"
        style={{
          lineHeight: 1.30,
          padding: 0,
        }}
        htmlString={quote_body}
      />}
    </div>
  )
}

export default MarinQuote
