import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import {
  IFolderType,
  IFolderTypeField,
} from "../../shared-global/interfaces/folder-type-interfaces";
import { IFolder } from "../../shared-global/interfaces/models/folder.interface";
import { COMPONENT_MODE_CONTEXT } from "../../shared-global/enums/config-enums";
import Delta from "quill-delta";
import { TextStyleValue } from "../../shared-global/interfaces/folder-types/fields/folder-type-field-text-style-type";
import { CSSProperties, StyleHTMLAttributes } from "react";
import { isString } from "lodash";

export function isNull(value, includeNotCheck = true) {
  if (value === 0) {
    return false;
  }
  if (value === undefined || value === null) {
    return true;
  }
  if (includeNotCheck) {
    if (!value) {
      return true;
    }
  }
  return false;
}

export function safeToPercentStr(val) {
  if (typeof val === "string") {
    val = Number(val);
  }
  val = (val * 100).toFixed(1);
  return val.toString();
}

export const getRandomStr = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export function isDescendant(parent, child) {
  var node = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getFlattenedFolderFields(folder): any {
  let flattened_fields = {
    name: folder.name,
  };
  if (folder.fields) {
    flattened_fields = {
      ...flattened_fields,
      ...folder.fields,
    };
  }
  return flattened_fields;
}

export function logFolderFolderData(
  message,
  folderFolderId,
  folder__folders,
  folders
) {
  let folder__folder = folder__folders[folderFolderId];
  console.log(message, {
    folder_folder: folder__folder,
    child_folder: folders[folder__folder.child_folder],
    parent_folder: folders[folder__folder.parent_folder],
  });
}

export function strToUpper(str) {
  const words = str.split(" ");
  const wordWithFistCapitalLetter = words.map(
    (w) => `${w[0].toUpperCase()}${w.substring(1)}`
  );
  return wordWithFistCapitalLetter.join(" ");
}

export function parseQuillOpsRichText(ops) {
  let newOps = [];
  let grouppedOps = [];

  for (let i = 0; i < ops.length; i++) {
    const carriageReturnOcurrences = (ops[i].insert.match(/\n/g) || []).length;
    if (carriageReturnOcurrences === 0) {
      grouppedOps.push(ops[i]);
    } else if (carriageReturnOcurrences > 1) {
      if (grouppedOps.length) {
        newOps.push(grouppedOps);
        grouppedOps = [];
      }
      newOps.push([ops[i]]);
    } else {
      if (grouppedOps.length) {
        newOps.push(grouppedOps);
        grouppedOps = [];
      }
    }
  }

  if (grouppedOps.length) {
    newOps.push(grouppedOps);
  }

  const richText = newOps.reduce((acc, curr) => {
    const converter = new QuillDeltaToHtmlConverter(curr, {});
    const opRichText = converter.convert();
    acc += opRichText;
    return acc;
  }, "");

  return richText;
}

export function hex8ToRgbA(hex) {
  if (hex !== null && /^#([0-9a-fA-F]{8})$/.test(hex)) {
    const [r, g, b, a] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${a / 255})`;
  } else {
    return `rgba(0,0,0,0)`;
  }
}

export function hexToRgbA(hex, fallback = null) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      a: 1,
    };
    //return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
  }
  if (fallback) {
    return fallback;
  }
  return {
    r: 100,
    g: 100,
    b: 100,
    a: 1,
  };
}

export const numericValue = (val) => {
  if (!val) return 0;
  return parseFloat(val.toString()?.replace(/[^\d.]/g, ""));
};

export const convertPxStringToEm = (px: string | number) => {
  const value = numericValue(px);
  return value / 16;
};

export const richTextHasValue = (richTextFieldOps) => {
  if (richTextFieldOps.length > 0) {
    if (richTextFieldOps.length === 1) {
      if (richTextFieldOps[0]?.insert === "\n") {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

export const wysiwygDeltaOpPxToEm = (op, divideBy16?) => {
  // op.insert = op.insert.replace("†", "✝");
  if (typeof divideBy16 === "number") {
    divideBy16 = false;
  }
  const newOp = cloneDeep(op);
  if (newOp.attributes && newOp.attributes.size) {
    newOp.attributes.size = newOp.attributes.size.replace("px", "em");
    if (divideBy16) {
      const number = newOp.attributes.size.split("em")[0];
      newOp.attributes.size = `${String(number / 16).replace(".", "-")}em`;
    }
  }

  return newOp;
};

const getValueFromProperty = (
  folder,
  property,
  baseFolderType,
  fallbackStyles = {}
) => {
  const inputType = get(folder, `fields.${property}.mode`, null);
  let ops = [];
  if (inputType === null) {
    ops = cloneDeep(get(folder, `fields.${property}.ops`, []));
  } else if (inputType === "wysiwyg") {
    ops = cloneDeep(get(folder, `fields.${property}.wysiwyg.ops`, []));
  } else {
    let style: any = null;
    const inheritableStyle = {
        ...get(
          folder,
        `fields.${property}.inheritable_style`,
      {}
  )
  };
    if (inheritableStyle.align && inheritableStyle.align === "left") {
      delete inheritableStyle.align;
    }

    if (Object.keys(inheritableStyle).length) {
      style = inheritableStyle;
    } else {
      if (Object.keys(fallbackStyles).length) {
        style = fallbackStyles;
      } else {
        style = get(
          baseFolderType,
          `fields.${property}.default.wysiwyg.style`,
          {}
        );
      }
    }
    const textArea = get(folder, `fields.${property}.textarea`, "");
    if (textArea !== "") {
      const newDelta = new Delta().insert(textArea) as any;
      const mapStyles = {};
      let align = "";

      for (const key of Object.keys(style)) {
        if (key !== "align") {
          mapStyles[key] = style[key];
        } else {
          align = style[key];
        }
      }
      ops = newDelta.ops.map((op) => ({
        ...op,
        attributes: mapStyles,
      }));

      if (align) {
        ops.push({
          insert: "\n",
          attributes: {
            align,
          },
        });
      }
    }
  }

  return ops;
};

const getLineOrPlaceholder = (card, convertor, type, showPlaceHolder, mode) => {
  let html = convertor ? convertor.convert() : "";
  if (isTextRichEmpty(html) && mode === COMPONENT_MODE_CONTEXT.PREVIEW) {
    html = getPlaceholder(card, type, showPlaceHolder);
  }

  return html;
};

export const isTextRichEmpty = (text) => {
  if (text && text !== "<p><br/></p>") {
    return false;
  } else {
    return true;
  }
};

const getPlaceholder = (card, placeholder, showPlaceholder) => {
  if (!showPlaceholder) {
    return "";
  }

  return `<p><strong class=\"ql-font-robotoslabregular ql-size-3em\">Placeholder ${placeholder}</strong></p>`;
};

export const getFieldValue = (
  folder,
  key,
  baseFolderType,
  showPlaceholder = false,
  mode = "",
  divideBy16 = true
) => {
  const folderTypeField: IFolderTypeField = get(
    baseFolderType,
    `fields.${key}`,
    null
  );
  const fieldValue = get(folder, `fields.${key}`, null);
  if (folderTypeField) {
    switch (folderTypeField.type) {
      case "text":
        break;
      case "wysiwyg":
      case "hybrid_text_input":
        let ops = getValueFromProperty(folder, key, baseFolderType);
        ops = ops.map((o) => wysiwygDeltaOpPxToEm(o, divideBy16));
        const converter = new QuillDeltaToHtmlConverter(ops, {
          multiLineParagraph: false,
          customCssStyles: (op) => {
            if (op.attributes.lineHeight !== undefined) {
              return [`line-height: ${op.attributes.lineHeight}`];
            }
          },
        });
        return getLineOrPlaceholder(
          folder,
          converter,
          key,
          showPlaceholder,
          mode
        );
      case "color":
      case "datetime":
        return fieldValue ? fieldValue : get(folderTypeField, "default", null);
      default:
        break;
    }
  }
};

const getPropertyAssignation = (baseFolderType, field, type, value) => {
  return (
    value ||
    get(baseFolderType, `fields.${field}.default.wysiwyg.style.${type}`, "")
  );
};

export const mapOptionsForBulletPoints = (field, ops, baseFolderType) => {
  let colorToAssignToBullets = "";
  const bodyMap = (o, i) => {
    if (o.attributes && o.attributes.list && ops[i - 1].attributes) {
      const size = getPropertyAssignation(
        baseFolderType,
        field,
        "size",
        ops[i - 1].attributes.size
      );
      const font = getPropertyAssignation(
        baseFolderType,
        field,
        "font",
        ops[i - 1].attributes.font
      );

      o.attributes.color = colorToAssignToBullets;
      o.attributes.size = size;
      o.attributes.font = font;
      colorToAssignToBullets = "";
    } else {
      if (!colorToAssignToBullets.length) {
        colorToAssignToBullets = getPropertyAssignation(
          baseFolderType,
          field,
          "color",
          get(o, "attributes.color", false)
        );
      }
      if (o.insert && o.insert === "\n") {
        colorToAssignToBullets = "";
      }
    }

    return o;
  };

  return ops.map(bodyMap);
};

export const generateFontSizeClassesFromFields = (folderType, fields) => {
  const styles = {
    componentContainer: {
      width: "100%",
      "& .ql-editor-preview .ql-editor br": {
        height: "1em",
      },
      "& ul": {
        paddingLeft: "1.5em",
        listStylePosition: "outside",
      },
      "& ol": {
        paddingLeft: "2.1em",
        listStylePosition: "outside",
      },
      "& li": {
        marginBottom: "1em",
      },
    },
  };
  const richTextFieldKeys = Object.keys(folderType.fields).filter(
    (fieldKey) => {
      const field = folderType.fields[fieldKey];
      return field.type === "hybrid_text_input";
    }
  );
  for (const fieldKey of richTextFieldKeys) {
    try {
      const field = fields[fieldKey];
      let value;
      if (field.mode === "wysiwyg") {
        const ops = field.wysiwyg.ops;
        for (const op of ops) {
          if (
            op.hasOwnProperty("attributes") &&
            op.attributes.hasOwnProperty("size")
          ) {
            value = getFontSizeValue(op.attributes.size);
          }
          styles.componentContainer[
            `& .ql-size-${String(value).replace(".", "-")}em`
          ] = {
            fontSize: `${value}em`,
            paddingLeft: 0,
          };
          styles.componentContainer[
            `& .ql-size-${String(value).replace(".", "-")}em .ql-size-${String(
              value
            ).replace(".", "-")}em`
          ] = {
            fontSize: "inherit!important",
          };
          styles.componentContainer[
            `& .ql-size-${String(value).replace(".", "-")}em::before`
          ] = {
            fontSize: "inherit",
          };
        }
      } else {
        // We first try to get a value from inheritable styles
        let defaultSize = get(
          fields,
          `${fieldKey}.inheritable_style.size`,
          null
        );
        if (!defaultSize) {
          defaultSize = get(
            folderType,
            `fields.${fieldKey}.default.wysiwyg.style.size`,
            null
          );
        }
        value = getFontSizeValue(defaultSize);
        styles.componentContainer[
          `& .ql-size-${String(value).replace(".", "-")}em`
        ] = {
          fontSize: `${value}em`,
        };
        styles.componentContainer[
          `& .ql-size-${String(value).replace(".", "-")}em .ql-size-${String(
            value
          ).replace(".", "-")}em`
        ] = {
          fontSize: "inherit!important",
        };
      }
    } catch (error) {
      console.log({ error });
    }
  }
  return styles;
};

const getFontSizeValue = (size) => {
  let value;
  if (size.indexOf("em") === -1) {
    value = size.split("px")[0];
    return value / 16;
  } else {
    value = size.split("em")[0];
    return value;
  }
};

export const getHybridTextOps = (
  folderType: IFolderType,
  folder: IFolder,
  fieldName: string
) => {
  const hybridInputType = get(folder, `fields.${fieldName}.mode`, "wysiwyg");
  let ops = [];

  if (hybridInputType === "wysiwyg") {
    ops = cloneDeep(get(folder, `fields.${fieldName}.wysiwyg.ops`, []));
  } else {
    let style = null;
    let inheritableStyle = get(
      folder,
      `fields.${fieldName}.inheritable_style`,
      null
    );
    if (inheritableStyle) {
      if (inheritableStyle.align && inheritableStyle.align === "left") {
        delete inheritableStyle.align;
      }
      style = inheritableStyle;
    } else {
      style = get(folderType, `fields.${fieldName}.default.wysiwyg.style`, {});
    }
    const textArea = get(folder, `fields.${fieldName}.textarea`, "");
    if (textArea !== "") {
      ops = [{ insert: textArea, attributes: style }, { insert: "\n" }];
    }
  }

  return ops;
};

export const convertOpsToPlainText = (ops: any[] = []) => {
  let newValue = "";
  for (let key in ops) {
    const op = ops[key];
    if (Number(key) === ops.length - 1) {
      if (op.insert === "\n") {
        continue;
      }
    }
    if (op.insert && typeof op.insert === "string") {
      newValue += op.insert;
    } else {
      newValue += " ";
    }
  }
  return newValue;
};

export const textStyleOptionsToRegularStyle = (
  textStyleOptions: TextStyleValue
): CSSProperties => {
  const newStyle: CSSProperties = { ...textStyleOptions };

  if (textStyleOptions.bold) {
    newStyle.fontWeight = "bold";
  }
  if (textStyleOptions.underline) {
    newStyle.textDecoration = "underline";
  }
  if (textStyleOptions.italic) {
    newStyle.fontStyle = "italic";
  }
  newStyle.fontSize = `${numericValue(textStyleOptions.fontSize)}em`;
  newStyle.textAlign = textStyleOptions.alignment;

  return newStyle;
};
