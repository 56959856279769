import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const fremont_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.fremont_root,
  display_name: "Fremont Root",
  acceptable_folder_types: [FOLDER_TYPE.fremont_1x2, FOLDER_TYPE.fremont_1x4],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    file: {
      title: "Display Overview",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: ["thumbnail", "small", "large"],
      video_presets: [1080],
      aspect: 0.300173385,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.300173385
      }
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    component_name: COMPONENT_NAME.Slide1911,
    type: PREVIEW_TYPE.COMPONENT,
    aspect_ratio: 0.300173385
  }
};
