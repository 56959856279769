import React from "react"
import AlpineStoryWithFactCarousel from "./AlpineStoryWithFactCarousel";
import { createUseStyles } from "react-jss";
import {useFolder} from "../../../hooks/useFolder";
import {useScale} from "../../../hooks/useScale";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const MonterreyFullscreenFactStory = (props) => {

  const {
    getBaseFolder,
    getBaseFolderFolderType,
    getBaseFolderFieldValue,
  } = useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const {
    standardSizing,
    getUnitBasedOnHeight,
  } = useScale(props.data.base_folder);

  const factBackgroundColor = getBaseFolderFieldValue("fact_background_color", "#76232f")
  const backgroundColor = getBaseFolderFieldValue("background_color", "transparent");

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    standardSizing,
    getUnitBasedOnHeight,
  });

  return (
    <div className={classes.componentContainer}>
      <div className={classes.fullscreenFact}>
        <AlpineStoryWithFactCarousel
          {...props}
          baseFolder={baseFolder}
          baseFolderType={baseFolderType}
          factBackgroundColor={factBackgroundColor}
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '7.5em',
            paddingRight: '7.5em',
            paddingLeft: '7.5em',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            maxHeight: "98em"
          }}
          minHeight="100%"
        />
      </div>
    </div>
  )
}

export default MonterreyFullscreenFactStory
