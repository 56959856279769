import React from "react";
import QRLogoWidget from "../../standard/QRLogoWidget";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { IFolderType } from "../../../../shared-global/interfaces/folder-types/folders/IFolderType";

interface IAlpineQrWidgetProps extends IDisplayComponentProps {
  baseFolderFields: any
  baseFolderType: IFolderType | undefined
  componentSpecificData?: any
}

const AlpineQrWidget = (props: IAlpineQrWidgetProps) => {
  return (
    <QRLogoWidget
      {...props}
      {...props.baseFolderFields}
      folderType={props.baseFolderType}
      componentSpecificData={{
        // ...props.componentSpecificData,
        gridColumnGap: '2.5em',
        textMaxWidth: '',
        customStyles: {
          height: props.refHeight * 0.1333,
          maxWidth: '80%',
          padding: '3em',
          flexFlow: '',
        }
      }}
    />
  )
}

export default AlpineQrWidget;
