import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { springfield_template_001 } from "./springfield_template_001";
import { springfield_template_002 } from "./springfield_template_002";
import { springfield_template_003 } from "./springfield_template_003";
import { springfield_template_004 } from "./springfield_template_004";
import { springfield_presentation } from "./springfield_presentation";
import { springfield_stories } from "./springfield_stories";
import { springfield_donor } from "./springfield_donor";
import { springfield_donor_list } from "./springfield_donor_list";
import { springfield_category_with_giving_level } from "./springfield_category_with_giving_level";
import { springfield_display } from "./springfield_display";
import { springfield_root } from "./springfield_root";
import { springfield_donor_lists } from "./springfield_donor_lists";
import { springfield_displays } from "./springfield_displays";
import { springfield_content } from "./springfield_content";
import { springfield_content_group_folder } from "./springfield_content_group_folder";
import { springfield_presentations } from "./springfield_presentations";

export const springfield_types: { [key: string]: IFolderType } = {
  springfield_template_001,
  springfield_template_002,
  springfield_template_003,
  springfield_template_004,
  springfield_donor,
  springfield_donor_list,
  springfield_category_with_giving_level,
  springfield_donor_lists,
  springfield_root,
  springfield_display,
  springfield_presentation,
  springfield_stories,
  springfield_content,
  springfield_presentations,
  springfield_displays,
  springfield_content_group_folder
};
