import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";
import { transitions } from "./naples_settings";

export const naples_presentation_1x3: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.naples_presentation_1x3,
  display_name: "Naples Presentation 1x3",
  acceptable_folder_types: [
    FOLDER_TYPE.naples_fullscreen_media,
    FOLDER_TYPE.naples_fund_folder_blue,
    FOLDER_TYPE.naples_fund_folder_red,
    FOLDER_TYPE.naples_template_blue_001,
    FOLDER_TYPE.naples_template_blue_002,
    FOLDER_TYPE.naples_template_red_001,
    FOLDER_TYPE.naples_template_red_002
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions]
    }
  },
  preview: {
    aspect_ratio: 0.592592593,
    component_name: COMPONENT_NAME.Naples1x3Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true,
    screen_sets: [{ name: "1x3", orientation: "landscape", horizontal: 1, vertical: 3 }]
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  isPresentation: true
};
