import React, { Component } from 'react';
import ReleaseNote from './ReleaseNote';
const release_notes = require('../../config/site/release_notes.json');

class ReleaseNotes extends Component {
  
  renderReleaseNotes(){
    return(
      release_notes.map( (x, index)=> {
        return(
          <ReleaseNote key={index} data={x}/>
        )
      })
    )
  };
  
  render() {
    return (
      <div style={{margin: 32}}>
        <div className="flexcenter">
          <div>
            <br /><br /><br /><br />
            <b>Release Notes</b>
            {this.renderReleaseNotes()}
          </div>
        </div>
      </div>
    )
  }
}

export default ReleaseNotes