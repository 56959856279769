import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { monterrey_content } from "./monterrey_content";
import { monterrey_content_stories } from "./monterrey_content_stories";
import { monterrey_content_layouts } from "./monterrey_content_layouts";
import { monterrey_layout } from "./monterrey_layout";
import { monterrey_display } from "./monterrey_display";
import { monterrey_displays } from "./monterrey_displays";
import { monterrey_fullscreen_fact_story } from "./monterrey_fullscreen_fact_story";
import { monterrey_main_display } from "./monterrey_main_display";
import { monterrey_media_blank_1 } from "./monterrey_media_blank_1";
import { monterrey_media_blank_2 } from "./monterrey_media_blank_2";
import { monterrey_media_blank_3 } from "./monterrey_media_blank_3";
import { monterrey_media_blank_4 } from "./monterrey_media_blank_4";
import { monterrey_media_blank_5 } from "./monterrey_media_blank_5";
import { monterrey_presentations } from "./monterrey_presentations";
import { monterrey_presentation_1x1 } from "./monterrey_presentation_1x1";
import { monterrey_presentation_5x1 } from "./monterrey_presentation_5x1";
import { monterrey_root } from "./monterrey_root";
import { monterrey_story_fact } from "./monterrey_story_fact";
import { monterrey_story_media_with_quote } from "./monterrey_story_media_with_quote";
import { monterrey_story_with_fact } from "./monterrey_story_with_fact";
import { monterrey_profile_with_media_story } from "./monterrey_profile_with_media_story";
import { monterrey_story_with_media } from "./monterrey_story_with_media";
import { monterrey_intro_story } from "./monterrey_intro_story";
import { monterrey_control_panel_attract_loop } from "./monterrey_control_panel_attract_loop";
import { monterrey_control_panel_attract_loop_slide } from "./monterrey_control_panel_attract_loop_slide";
import { monterrey_content_attract_loops } from "./monterrey_content_attract_loops";
import { monterrey_story_quote } from "./monterrey_story_quote";
import { monterrey_donor_list } from "./monterrey_donor_list";
import { monterrey_content_donor_lists } from "./monterrey_content_donor_lists";
import { monterrey_donor } from "./monterrey_donor";

export const monterrey_types: { [key: string]: IFolderType } = {
  monterrey_root,
  monterrey_displays,
  monterrey_presentations,
  monterrey_content,
  monterrey_display,
  monterrey_presentation_5x1,
  monterrey_presentation_1x1,
  monterrey_content_stories,
  monterrey_content_layouts,
  monterrey_layout,
  monterrey_story_media_with_quote,
  monterrey_story_with_fact,
  monterrey_fullscreen_fact_story,
  monterrey_story_with_media,
  monterrey_story_fact,
  monterrey_profile_with_media_story,
  monterrey_main_display,
  monterrey_media_blank_1,
  monterrey_media_blank_2,
  monterrey_media_blank_3,
  monterrey_media_blank_4,
  monterrey_media_blank_5,
  monterrey_intro_story,
  monterrey_control_panel_attract_loop,
  monterrey_control_panel_attract_loop_slide,
  monterrey_content_attract_loops,
  monterrey_story_quote,
  monterrey_donor_list,
  monterrey_donor,
  monterrey_content_donor_lists
};
