import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { transitions, wysiwygSettings } from "./springfield_settings";

export const springfield_template_002: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.springfield_template_002,
  display_name: "Template 2 - Story with Single Image",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    story: {
      title: "Story with Single Image",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    mirror_content: {
      type: "bool",
      title: "Mirror Content",
      default: false,
      field_group: "general"
    },

    headline: {
      type: "hybrid_text_input",
      title: "Headline",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
            size: "20px",
            lineHeight: 1.05
          },
          ops: []
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_header"
      },
      field_group: "story"
    },
    subhead: {
      type: "hybrid_text_input",
      title: "Sub Head",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            bold: true,
            font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
            size: "14px",
            lineHeight: 1.05
          },
          ops: []
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_subhead"
      },
      field_group: "story"
    },
    body: {
      type: "hybrid_text_input",
      title: "Body Text",
      validation: { rules: [] },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "12px",
            lineHeight: 1.05
          },
          ops: []
        },
        textarea: ""
      },
      tooltip: [
        "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
      ],
      settings: wysiwygSettings,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_body"
      },
      field_group: "story"
    },
    image_1: {
      title: "Image 1",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.888888889,
      recommended_size: {
        width: 960,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.888888889
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_single_image"
      },
      field_group: "story"
    },
    background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_background"
      },
      field_group: "story"
    },
    color_overlay: {
      type: "color",
      title: "Background Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_single_color_overlay"
      },
      field_group: "story"
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.SpringfieldContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_002.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
