import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontsFamilies } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { textstyle_default } from "./boston_root";

export const boston_checkerboard: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.boston_checkerboard,
  display_name: "Boston Checkerboard",
  fields: {
    name: name,
    default_preview: {
      type: "select",
      title: "Default Preview",
      validation: { rules: [{ name: "isRequired" }] },
      default: "1",
      options: [
        {
          title: "Mission Bernal",
          value: "1"
        },
        {
          title: "Pacific",
          value: "2"
        },
        {
          title: "Van Ness",
          value: "3"
        }
      ]
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      show_conditional_met: false,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      default: 45
    },
    background: {
      title: "Background 3x2",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.66666,
      recommended_size: {
        width: 3840,
        height: 1440
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.66666
      }
    },
    checkerboard_media: {
      title: "Checkerboard Media",
      type: "file",
      slot_type: "dynamic",
      limit: 24,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1280,
        height: 720
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      }
    },
    media_vertical_1x4: {
      title: "Background Mission Bernal 1x4",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.444444444,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      }
    },
    media_vertical_2x5: {
      title: "Background Van Ness / Pacific 2x5",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.711111111,
      recommended_size: {
        width: 1920,
        height: 4320
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.444444444
      }
    },
    inspirational_words_style: {
      type: "textstyle",
      title: "Inspirational Words Style",
      tooltip: "",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 },
            { title: "65em", value: 65 },
            { title: "70em", value: 70 },
            { title: "75em", value: 75 },
            { title: "80em", value: 80 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    inspirational_words: {
      type: "textarea",
      title: "Inspirational Words",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      default: null,
      tooltip: "Enter a comma seperated list of inpirational words",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    related_inspirational_words_style: {
      type: "textstyle",
      title: "Related Inspirational Words Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "40em", value: 40 },
            { title: "45em", value: 45 },
            { title: "50em", value: 50 },
            { title: "55em", value: 55 },
            { title: "60em", value: 60 },
            { title: "65em", value: 65 },
            { title: "70em", value: 70 },
            { title: "75em", value: 75 },
            { title: "80em", value: 80 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    related_inspirational_words: {
      type: "textarea",
      title: "Related Inpirational Words",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      default: null,
      tooltip: "Enter a comma seperated list of inpirational words",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true
    }
  },
  preview: [
    {
      aspect_ratio: 1.333333333,
      component_name: COMPONENT_NAME.Boston3x2and1x4Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "3x2 and 1x4",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/3x2_1x4.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_3x2_and_1x4
    },
    {
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.Boston2x5and3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "2x5 and 3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_2x5_and_3x2
    },
    {
      aspect_ratio: 2.666666667,
      component_name: COMPONENT_NAME.Boston3x2Container,
      type: PREVIEW_TYPE.COMPONENT,
      hide_border: true,
      preview_tab_alias: "3x2",
      preview_tab_alias_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/3x2.svg",
      use_if_parent_is: FOLDER_TYPE.boston_presentation_3x2
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  }
};
