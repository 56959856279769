import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import {
  getBaseFolder,
  getBaseFolderType,
} from "../../../utils/commonDataUtils";
import { getFieldValue } from "../../../utils/generalUtils";
import QRLogoWidget from "../../standard/QRLogoWidget";
import Slide1911 from "../../standard/Slide1911";
import EverettDonorListWithQuote from "./EverettDonorListWithQuote";
import EverettDonorText from "./EverettDonorText";
import EverettStory from "./EverettStory";
import EverettStoryWithQuoteText from "./EverettStoryWithQuoteText";
import EverettStoryText from "./EverettStoryText";

const EverettBaseTemplate = (props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(props.data?.persistent_settings?.data?.show_placeholder || false);
  const [baseFolder, setBaseFolder] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [baseFolderFields, setBaseFolderFields] = useState({});

	const isStory = props.folderType.name === FOLDER_TYPE.everett_story;
  const isStoryWithQuote = props.folderType.name === FOLDER_TYPE.everett_story_with_quote;
	useEffect(() => {
		const myBaseFolder = getBaseFolder(props.data);
		setBaseFolder(myBaseFolder);
    setBaseFolderFields(myBaseFolder.fields)
		const myBaseFolderType = getBaseFolderType(
			props.data,
			get(myBaseFolder, "folder_type", {})
		);
		setBaseFolderType(myBaseFolderType);
	}, [props.data]);

	if (!baseFolder) {
		return <div>No base folder</div>;
	}

	const getPropertiesFromFolder = (folder) => {
    const headerTag = (isStory || isStoryWithQuote) ? 'header' : 'quote_header'
    const attributionTag = isStoryWithQuote ? "attribution" : "quote_attribution"
    const textBottomTag = isStoryWithQuote ? "text_bottom_screen" : "quote_text_bottom_screen"
    const textTopTag = (isStory || isStoryWithQuote) ? "text_top_screen" : "quote_text_top_screen"

    const header = getFieldValue(folder, headerTag, baseFolderType, props.showPlaceholder )
    const text_bottom_screen = getFieldValue(folder, textBottomTag, baseFolderType, props.showPlaceholder )
    const attribution = getFieldValue(folder, attributionTag, baseFolderType, props.showPlaceholder )
    const text_top_screen = getFieldValue(folder, textTopTag, baseFolderType, props.showPlaceholder )

		return {
			header,
      text_top_screen,
      text_bottom_screen,
      attribution,
		};
	};

	const {
    header,
    text_top_screen,
    text_bottom_screen,
    attribution,
  } = getPropertiesFromFolder(baseFolder);

	const backgroundColorOverlay = get(baseFolder, (isStory || isStoryWithQuote) ? "fields.story_background_image_overlay" : "fields.donor_background_image_overlay")
  const mirrorContent = get(
    baseFolder,
    "fields.mirror_content",
    false
  );

	return (
    <div style={{
      position: 'absolute',
      width: props.containerWidth,
      height: props.containerHeight,
      display: 'grid',
    }}>
      <div
        style={{
          position: "relative",
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: (((isStory || isStoryWithQuote) && !mirrorContent) || (!(isStory || isStoryWithQuote) && mirrorContent)) ? 'row' : 'row-reverse'
        }}
      >
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight,
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            data={{
              ...props.data,
              muted: false,
              slotNames: (isStory || isStoryWithQuote) ? ["story_background_image"] : ["donor_background_image"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "cover",
              videoIsShorterThanCycleTime: (isStory || isStoryWithQuote) ? false : true
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            width: props.containerWidth,
            height: props.containerHeight,
            backgroundColor: backgroundColorOverlay,
          }}
        />
        <div className={props.classes.componentContainer} style={{
          position: 'relative',
          width: '50%',
          height: props.containerHeight,
          backgroundColor: get(baseFolder, "fields.donor_list_background")
        }}>
          {
            (isStory || isStoryWithQuote) &&
            (
              <EverettStory
                {...props}
                baseFolder={baseFolder}
                mirrorContent={mirrorContent}
              />
            )
          }

          {
            (!isStory && !isStoryWithQuote) &&
            (
              <EverettDonorListWithQuote
                {...props}
                baseFolder={baseFolder}
                baseFolderType={baseFolderType}
                showPlaceholder={showPlaceholder}
              />
            )
          }
        </div>
        <div className={props.classes.componentContainer} style={{
          position: 'relative',
          width: '50%',
          height: props.containerHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: (isStory || isStoryWithQuote) ? get(baseFolder, "fields.story_background") : get(baseFolder, "fields.quote_background"),
        }}
        >
          {
            isStoryWithQuote && (
              <EverettStoryWithQuoteText
                header={header}
                text_top_screen={text_top_screen}
                text_bottom_screen={text_bottom_screen}
                attribution={attribution}
              />
            )
          }

          {
            isStory && (
              <EverettStoryText
                header={header}
                text_top_screen={text_top_screen}
              />
            )
          }

          {
            (!isStory && !isStoryWithQuote) && (
              <EverettDonorText
                header={header}
                text_top_screen={text_top_screen}
                text_bottom_screen={text_bottom_screen}
                attribution={attribution}
              />
            )
          }
        </div>
      </div>
      <QRLogoWidget
        {...props}
        {...baseFolderFields}
        containerWidth={0.111111111 * props.refHeight}
      />
    </div>
  );
};

export default EverettBaseTemplate;
