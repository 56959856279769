import { IPermissionModel } from './permission';
import { IUser } from './user.interface';

export enum ROLE_SCOPE {
  COMPANY = 'company',
  PROJECT = 'project'
}

export interface IRoleModel {
  id?: number;
  name: string;
  scope?: ROLE_SCOPE;
  company?: number;
  permissions?: IPermissionModel[]|number[];
  users?: IUser[];
}
