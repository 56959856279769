import { isNull } from '../shared/utils/generalUtils';
import validator from 'validator';
import _ from 'lodash';

export default {

  isRequired: function(item) {
    if (isNull(item)) {
      if (Array.isArray(item)) {
        if (item.length > 0) {
          return { valid: true, error: null }
        }
      }
      return { valid: false, error: 'Value is required' }
    }
    if (item instanceof Array && item.length === 0) {
      return { valid: false, error: 'At least one is required' }
    }
    return { valid: true, error: null }
  },

  isRequiredComposite: function(value, rule, values) {
    const otherValue = values[rule.otherFieldName];
    if (typeof otherValue === 'string' && otherValue && !value) {
      return { valid: false, error: 'Value is required' }
    }

    return { valid: true, error: null }
  },

  isRequiredExclusiveBoolean: function(value, rule, values){
    const otherValue = values[rule.otherFieldName];
    if(typeof otherValue === 'boolean' && !otherValue && !value){
      return { valid: false, error: 'Value is required' }
    }

    return { valid: true, error: null }
  },

  isFolderTypeRequired: function(item) {
    if (isNull(item)) {
      if (Array.isArray(item)) {
        if (item.length > 0) {
          return { valid: true, error: null }
        }
      }
      return { valid: false, error: 'Item type selection is required' }
    }
    if (item instanceof Array && item.length === 0) {
      return { valid: false, error: 'At least one is required' }
    }
    return { valid: true, error: null }
  },

  isString: function(item, rule) {
    if (item) {
      if (!_.isString(item)) return { valid: false, error: 'Valid string required' };
      if (rule.min && item.length < rule.min) return { valid: false, error: `Text length must be greater than ${rule.min}` }
      if (rule.max && item.length > rule.max) return { valid: false, error: `Text length must be less than ${rule.max}` }
    }
    return { valid: true, error: null };
  },

  isEmail: function(item) {
    if (!validator.isEmail(String(item))) {
      return { valid: false, error: 'Valid email required' }
    }
    return { valid: true, error: null }
  },

  isPassword: function(item) {
    if (typeof item !== 'string') {
      return { valid: false, error: 'password must be a string' }
    }
    else if (item.length < 5) {
      return { valid: false, error: 'password must be at least 5 characters' }
    }
    return { valid: true, error: null }
  },

  isUrl: function(value) {
    if (value) {
      let isValid = true;
      let url;
      try {
        url = new URL(value);
      } catch (_) {
        isValid = false;
      }
      if (!isValid || (url.protocol !== "http:" && url.protocol !== "https:")) {
        return { valid: false, error: 'Valid URL required' }
      }
    }
    return { valid: true, error: null }
  },

  matchesVal: function(item, val) {
    if (item === val) {
      return { valid: true, error: null }
    }
    return { valid: false, error: 'This value must matches ' + val }
  },

  isInt: function(item, rule) {
    if (item || item === 0) {
      if (!_.isInteger(item)) return { valid: false, error: 'This value must be a valid int' }
      if (rule.min && item < rule.min) return { valid: false, error: `This value must be greater than ${rule.min}` }
      if (rule.max && item > rule.max) return { valid: false, error: `This value must be less than ${rule.max}` }
    }
    return { valid: true, error: null }
  },

  isNumber: function(item, rule) {
    if (item || item === 0) {
      if (!_.isNumber(item)) return { valid: false, error: 'This value must be a valid number' }
      if (rule.min && item < rule.min) return { valid: false, error: `This value must be greather than ${rule.min}` }
      if (rule.max && item > rule.max) return { valid: false, error: `This value must be less than ${rule.max}` }
    }
    return { valid: true, error: null }
  },

  isIntOrStringInt: function(item) {
    item = Number(item)
    if (_.isInteger(item)) {
      return { valid: true, error: null }
    }
    else {
      return { valid: false, error: 'This value must be a valid int' }
    }
  },

  lessThan: function(item, val) {
    if (_.isNumber(item) && item < val) {
      return { valid: true, error: null }
    }
    else {
      return { valid: false, error: 'This value must be less than ' + val }
    }
  },

  lessThanOrEqualTo: function(item, val) {
    if (_.isNumber(item) && item <= val) {
      return { valid: true, error: null }
    }
    else {
      return { valid: false, error: 'This value must be less or equal to ' + val }
    }
  },

  greaterThan: function(item, val) {
    if (_.isNumber(item) && item > val) {
      return { valid: true, error: null }
    }
    else {
      return { valid: false, error: 'This value must be greater than ' + val }
    }
  },

  greaterThanOrEqualTo: function(item, val) {
    if (_.isNumber(item) && item >= val) {
      return { valid: true, error: null }
    }
    else {
      return { valid: false, error: 'This value must be greater or equal than ' + val }
    }
  },
};
