import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../../enums/ui-enums";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../cycle_time_tooltips";
import { transitions } from "./new_orleans_settings";

export const new_orleans_template_001: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.new_orleans_template_001,
  display_name: "Header, Title, Image, Quote, Body Text",
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    header01: {
      type: "text",
      title: "Header",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      settings: {
        recommended_length: 38
      },
      default: null
    },
    title01: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      settings: {
        recommended_length: 70
      }
    },
    quote01: {
      type: "textarea",
      title: "Quote",
      validation: { rules: [{ name: "isRequired" }, { name: "isString" }] },
      default: null,
      settings: {
        recommended_length: 125
      }
    },
    paragraph01: {
      type: "textarea",
      title: "Body Text",
      validation: { rules: [{ name: "isRequired" }, { name: "isString" }] },
      default: null,
      settings: {
        recommended_length: 700
      }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 0,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips
    },
    file: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.72,
      recommended_size: {
        width: 357,
        height: 500
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.72
      }
    }
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.NewOrleansTemplate001,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/new_orleans_background.jpg"
  }
};
