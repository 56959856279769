import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const jasper_slide: IFolderType = {
  name: FOLDER_TYPE.jasper_slide,
  display_name: "Slide",
  folder_category: FOLDER_CATEGORY.STORY,
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
    },
    file: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      limit: 10,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.72,
      recommended_size: {
        width: 357,
        height: 500
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.72
      }
    }
  },
  preview: {
    aspect_ratio: 1.78,
    component_name: COMPONENT_NAME.Slide1911,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.Slide1911
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_open
    },
    closed: {
      ...tree_icon_types.donor_closed
    }
  }
};
