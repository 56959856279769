import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const naples_displays: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.naples_displays,
  display_name: "Naples Displays",
  acceptable_folder_types: [FOLDER_TYPE.naples_display],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.displays
    },
    closed: {
      ...tree_icon_types.displays
    }
  },
};
