import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal } from 'antd';
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userGetData, deleteRoleUser } from '../../actions/userActions';
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from '../../hooks/withRouter';

class UserRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: '',
      deleteRoleModalVisible: false,
      deleteRoleId: null
    };
  }


  componentDidMount() {
  }

  handleDeleteUserRole = () => {
    this.props.deleteRoleUser(this.state.deleteRoleId, () => { this.onDeleteRoleSuccess() }, () => { });
    this.setState({
      deleteRoleModalVisible: false
    })
  }

  onDeleteRoleSuccess = () => {
    AlertNotification('success', 'Success', 'Record successfully deleted')
    if (Number(this.props.user.id) === Number(this.props.userId)) {
      this.props.userGetData();
    }
  }
  openDeleteRoleModal = (id) => {
    this.setState({
      deleteRoleModalVisible: true,
      deleteRoleId: id
    })
  }

  render() {
    if (this.props.roles == null) {
      return null;
    }
    let userRoleTableColumns = [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: (a, b) => {
          if (!a.role) {
            a.role = ''
          }
          if (!b.role) {
            b.role = ''
          }
          if (a.role.toLowerCase() > b.role.toLowerCase()) return -1;
          if (a.role.toLowerCase() < b.role.toLowerCase()) return 1;
          return 0;
        }
      }, {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
        sorter: (a, b) => {
          if (!a.project) {
            a.project = ''
          }
          if (!b.project) {
            b.project = ''
          }
          if (a.project.toLowerCase() > b.project.toLowerCase()) return -1;
          if (a.project.toLowerCase() < b.project.toLowerCase()) return 1;
          return 0;
        }
      }];

    if (this.props.canDelete) {
      userRoleTableColumns.push({
        title: 'Delete',
        dataIndex: 'Delete',
        render: (text, record) => {
          let recordId = record.id;
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.openDeleteRoleModal(recordId)
              }}
            >
              <FontAwesomeIcon className="fa-lg" style={{ cursor: 'pointer' }} icon={['far', 'trash']} />
            </span>
          );
        }
      })
    }

    let arr = [];

    this.props.role__users.forEach(roleUser => {
      if (!this.props.roles[roleUser.role]) {
        return;
      }
      let project = "";
      if (roleUser.project !== null || roleUser.project !== undefined) {
        project = this.props.projects[roleUser.project];
        if (project) {
          project = project.name
        }
      }
      let obj = {
        role: this.props.roles[roleUser.role].name,
        project: project,
        key: roleUser.id,
        id: roleUser.id
      };
      arr.push(obj);

    });
    return (
      <div>
        <Modal visible={this.state.deleteRoleModalVisible}
          title={"Delete User Role"}
          onCancel={() => { this.setState({ deleteRoleModalVisible: false }) }}
          onOk={() => this.handleDeleteUserRole()}
          destroyOnClose="true"
        >
          <div>Are you sure you want to delete this user role?</div>
        </Modal>
        <Table
          title={() => 'Roles:'}
          rowKey={record => record.key}
          columns={userRoleTableColumns}
          dataSource={arr}
          pagination={false}
          bordered={true}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let role__users = arrayFromKeyedObject(state.data.role__users);
  role__users = role__users
    .filter(x => x.user === Number(ownProps.userId) && Number(x.company) === Number(ownProps.params.companyId));

  return {
    role__users: role__users,
    roles: state.data.roles,
    projects: state.data.projects,
    user: state.data.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteRoleUser: (roleId, onSuccess, onFail) => {
      dispatch(deleteRoleUser(roleId, onSuccess, onFail));
    },
    userGetData: (onSuccess, onFail) => {
      dispatch(userGetData(onSuccess, onFail))
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRoles));