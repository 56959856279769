import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const coburg_grouping_folder: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.coburg_grouping_folder,
  display_name: "Coburg Grouping Folder",
  acceptable_folder_types: [FOLDER_TYPE.coburg_donor_list_group, FOLDER_TYPE.coburg_story_group, FOLDER_TYPE.coburg_stories],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
};
