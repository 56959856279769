import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getChildrenData } from "../../../utils/commonDataUtils";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import Slide1911 from "../../standard/Slide1911";
import get from "lodash/get";

const BostonVerticalBackgroundLoop: React.FC<IDisplayComponentProps> = (
  props
) => {
  const [baseFolder, setBaseFolder] = useState<IFolder>(null);
  const [useColorOverlay, setUseColorOverlay] = useState(false);
  const [verticalColorOverlay, setVerticalColorOverlay] = useState(null);

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);

    const foundVerticalColorOverlay = get(
      foundBaseFolder,
      "fields.vertical_color_overlay",
      null
    );

    if (foundVerticalColorOverlay) {
      setVerticalColorOverlay(foundVerticalColorOverlay);
      setUseColorOverlay(true);
    }

    setBaseFolder(foundBaseFolder);
  }, []);

  let component = null;
  if (baseFolder) {
    const folderType = props.data.folder_types.find(
      (f) => f.name === baseFolder.folder_type
    );
    switch (baseFolder.folder_type) {
      case FOLDER_TYPE.boston_checkerboard:
      case FOLDER_TYPE.boston_media_with_quote:
      case FOLDER_TYPE.boston_fullscreen_media:
        component = (
          <Slide1911
            {...props}
            data={{
              ...props.data,
              slotNames: get(props.data, 'componentSpecificData.slotNames', ['media_vertical_1x4']),
              showPlaceholder: true,
              placeholder: "Image",
              fieldType: folderType.fields[get(props.data, 'componentSpecificData.slotNames', 'media_vertical_1x4')]
            }}
          />
        );
        break;
      case FOLDER_TYPE.boston_donor_sea:
        const donorSeaChildFolders = getChildrenData(
          props.data,
          baseFolder.id,
          true
        ).folders;
        const backgroundsFolder = donorSeaChildFolders.find(
          (f) => f.folder_type === FOLDER_TYPE.boston_donor_sea_backgrounds
        );
        if (backgroundsFolder) {
          component = (
            <ComponentLoop1911
              {...props}
              data={{
                ...props.data,
                base_folder: backgroundsFolder.id,
                slidesSlotName: get(
                  props.data,
                  "componentSpecificData.slotNames",
                  ["media_vertical_1x4"]
                ),
                componentSpecificData: {
                  ...props.data.componentSpecificData,
                  isCycleTimeBasedOnComputerClock: false,
                  useDistinctFolders: true,
                  showPlaceholder: true
                }
              }}
            />
          );
        }
        break;
      case FOLDER_TYPE.boston_diamond_platinum_list:
        component = <ComponentLoop1911
          {...props}
          data={{
            ...props.data,
            slidesSlotName: get(
              props.data,
              "componentSpecificData.slotNames",
              ["media_vertical_2x5"]
            ),
            componentSpecificData: {
              ...props.data.componentSpecificData,
              section: "top_bottom",
              isCycleTimeBasedOnComputerClock: false,
              useDistinctFolders: true,
              showPlaceholder: true
            }
          }}
        />
        break;
      default:
        return <></>;
    }
  }

  const cssTransitionKey = useColorOverlay ? "true" : "false";

  return (
    <>
      {component}

      {/* Background Overlay */}
      <TransitionGroup>
        <CSSTransition
          key={cssTransitionKey}
          timeout={1000}
          classNames="slowfadedelay"
        >
          <div
            style={{
              position: "absolute",
              width: props.containerWidth,
              height: props.containerHeight,
              backgroundColor: verticalColorOverlay
            }}
          />
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default BostonVerticalBackgroundLoop;
