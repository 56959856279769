import { call, put } from "redux-saga/effects";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { addError, addExceptionError } from "../actions/notificationActions";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import { toggleSubscriptionValidationSchema } from "../shared-global/validations/EmailSubscriptionValidation";

async function toggleEmailNotificationSubscriptionAPI(payload) {
  return Request(
    "/email_subscription/toggle_subscription",
    HTTP_METHODS.POST,
    payload,
    toggleSubscriptionValidationSchema
  );
}

const emailSubscriptionSaga = {
  *toggleEmailNotificatonSubscription(action) {
    try {
      const response = yield call(
        toggleEmailNotificationSubscriptionAPI,
        action.payload
      );
      if (isResponseSuccess(response)) {
        if (action.onSuccess) {
          action.onSuccess(action.payload.type, action.payload.action);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default emailSubscriptionSaga;
