import { IFolderType } from "../../folders/IFolderType";
import base_types from "../base_types";
import {
	COMPONENT_NAME,
	FOLDER_TYPE,
	PREVIEW_TYPE,
	PREVIEW_VIEW_PERCENTAGE_ORIENTATION,
} from "../../../../enums/folder-type-enums";
import {
	transitions,
	wysiwygSettings,
	quillFontSizes,
	tangentFonts,
} from "./tangent_settings";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const tangent_fullscreen_media: IFolderType = {
	...base_types.story,
	name: FOLDER_TYPE.tangent_fullscreen_media,
	display_name: "Full Screen Media",
	tabs: {
		general: {
			title: "General",
		},
	},
	field_groups: {
		general: {
			title: "General",
			default_expanded: true,
			order: 1,
			accordion: true,
			tab: "general",
		},
		media: {
			title: "Media",
			default_expanded: true,
			order: 1,
			accordion: true,
			tab: "general",
		},
		qr: {
			title: "Logo / QR Code",
			default_expanded: true,
			order: 2,
			accordion: true,
			tab: "general",
		},
	},
	fields: {
		name: {
			type: "text",
			title: "Label",
			validation: {
				rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }],
			},
			show_char_count: false,
			field_group: "general",
		},
		cycle_time: {
			type: "integer",
			title: "Cycle Time (seconds)",
			validation: {
				rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }],
			},
			field_group: "general",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: true,
				related_field: "story_fullscreen_cycle_time",
			},
		},
		transition: {
			type: "select",
			title: "Transition",
			field_group: "general",
			validation: { rules: [{ name: "isRequired" }] },
			default: "parent",
			options: [
				{
					title: "Parent's",
					value: "parent",
				},
				...transitions,
			],
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: true,
				related_field: "fullscreen_transition",
			},
		},
		fullscreen_image: {
			title: "Background Image",
			type: "file",
			slot_type: "dynamic",
			limit: 1,
			acceptable_contents: ["image/*", "video/*"],
			image_presets: [],
			video_presets: [],
			aspect: 9 / 32,
			recommended_size: {
				width: 540,
				height: 1920,
			},
			container: {
				imgCover: IMAGE_ALIGNMENT.CONTAIN,
				flexClass: FLEX_CLASSES.FLEX_CENTER,
				aspect: 9 / 32,
			},
			field_group: "media",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: true,
				related_field: "story_fullscreen_background",
			},
		},
		switch_to_logo: {
			type: "select",
			title: "Logo or QR Code",
			field_group: "qr",
			default: "none",
			options: [
				{ title: "None", value: "none" },
				{ title: "Logo", value: "logo" },
				{ title: "QR Code", value: "qrCode" },
			],
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_switch_to_logo",
			},
		},
		mirror_logo_qr: {
			type: "bool",
			title: "Mirror Logo/QR",
			isVisible: (values) => values.switch_to_logo !== "none",
			default: false,
			field_group: "qr",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_mirror_logo_qr",
			},
		},
		call_to_action_header: {
			title: "Call To Action Header",
			isVisible: (values) => values.switch_to_logo === "qrCode",
			field_group: "qr",
			type: "hybrid_text_input",
			show_char_count: false,
			settings: {
				...wysiwygSettings,
				font_size_unit: "px",
				fontSizeOptions: quillFontSizes,
				fonts: tangentFonts,
			},
			default: {
				wysiwyg: {
					type: "style", // Options are: style | ops
					style: {
						color: "#ffffff",
						alignment: "left",
						font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
						size: "64px",
						lineHeight: "1.5",
					},
					ops: [],
					dark_mode: true,
				},
				textarea: "",
			},
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_call_to_action_header",
			},
		},
		call_to_action_body: {
			title: "Call to Action Body",
			field_group: "qr",
			isVisible: (values) => values.switch_to_logo === "qrCode",
			type: "hybrid_text_input",
			show_char_count: false,
			settings: {
				...wysiwygSettings,
				font_size_unit: "px",
				fontSizeOptions: quillFontSizes,
				fonts: tangentFonts,
			},
			default: {
				wysiwyg: {
					type: "style", // Options are: style | ops
					style: {
						color: "#ffffff",
						alignment: "left",
						font: fonts[FontsFamilies.RECTOR][FontVariants.REGULAR],
						size: "44px",
						lineHeight: "1.25",
					},
					ops: [],
					dark_mode: true,
				},
				textarea: "",
			},
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_call_to_action_body",
			},
		},
		qr_link: {
			type: "text",
			title: "QR Code Link",
			isVisible: (values) => values.switch_to_logo === "qrCode",
			show_char_count: false,
			field_group: "qr",
			validation: {
				rules: [
					//{ name: "isRequiredExclusiveBoolean", otherFieldName: 'switch_to_logo', message: "Please enter a valid link. I.E: https://www.google.com" },
					{
						name: "isUrl",
						message: "Please enter a valid link. I.E: https://www.google.com",
					},
				],
			},
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_qr_link",
			},
		},
		qr_color: {
			type: "color",
			title: "QR Color",
			isVisible: (values) => values.switch_to_logo === "qrCode",
			default: "#ffffff",
			field_group: "qr",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: true,
				related_field: "quote_qr_color",
			},
		},
		logo: {
			title: "Default Logo",
			isVisible: (values) => values.switch_to_logo === "logo",
			type: "file",
			slot_type: "dynamic",
			limit: 1,
			acceptable_contents: ["image/*"],
			image_presets: [],
			video_presets: [],
			field_group: "qr",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: false,
				related_field: "quote_logo",
			},
		},
		qr_background_color: {
			type: "color",
			title: "QR / Logo Background Color",
			isVisible: (values) => values.switch_to_logo !== "none",
			default: "#0000007F",
			field_group: "qr",
			inheritable_settings: {
				inherit_on_create: true,
				pass_down_to_children: true,
				related_field: "quote_qr_background_color",
			},
		},
	},
	preview: {
		aspect_ratio: 9 / 32,
		component_name: COMPONENT_NAME.TangentContainer,
		type: PREVIEW_TYPE.COMPONENT,
		screen_sets: [
			{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 2 },
		],
		zoomable: true,
		view_percentage: {
			orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
			value: 25,
		},
	},
	folder_type_selector: {
		image_url: "/images/folder_types/tangent/tangent_fullscreen_media.svg",
	},
	save_options: [
		{
			title: "",
			action: "clone_folder",
			icon: ["fas", "copy"],
		},
		{
			title: "SAVE",
			action: "just_save",
		},
	],
};
