import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./tangent_settings";

export const tangent_presentation_1x1: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.tangent_presentation_1x1,
  display_name: "Presentation 1x1",
  acceptable_folder_types: [
    FOLDER_TYPE.tangent_content_stories,
    FOLDER_TYPE.tangent_fullscreen_media,
    FOLDER_TYPE.tangent_story_with_single_image,
    FOLDER_TYPE.tangent_story_with_media,
    FOLDER_TYPE.tangent_media_with_quote,
    FOLDER_TYPE.tangent_donor_list_group,
    FOLDER_TYPE.tangent_donor_list,
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions],
    },
  },
  preview: {
    aspect_ratio: 9 / 32,
    component_name: COMPONENT_NAME.TangentContainer,
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    hide_border: true,
    screen_sets: [
      { name: "unique", orientation: "portrait", horizontal: 1, vertical: 2 },
    ],
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation,
    },
    closed: {
      ...tree_icon_types.presentation,
    },
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  isPresentation: true,
};
