import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../interfaces/main-state.interface";
import AlertNotification from "./layout/AlertNotification";

interface INotificationsProps { }

export const Notifications: React.FC<INotificationsProps> = (props) => {

    const errors = useSelector((state: IState) => state.data.errors);
    useEffect(() => {
        if (errors){
            errors.forEach((error) => {
                AlertNotification("error", "Error", error.message);
            })
        }
    }, [errors])
    return null;
}