import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { textStyleOptions } from "./everett_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const everett_donor_sea_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.everett_donor_sea_list,
  display_name: "Donor Sea List",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    },
    donor_name_style: {
      type: "textstyle",
      title: "List Name Style",
      show_char_count: false,
      default: {
        color: "#333333",
        alignment: "left",
        font: fonts[FontsFamilies.BARLOW][FontVariants.SEMI_BOLD],
        fontSize: 80,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  preview: {
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
