import { COMPONENT_NAME, FIELD_TYPE, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { sorting_options } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { textStyleOptions, transitions } from "./marin_settings";

export const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const marin_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.marin_root,
  display_name: "Root",
  acceptable_folder_types: [FOLDER_TYPE.marin_displays, FOLDER_TYPE.marin_presentations, FOLDER_TYPE.marin_content],
  tabs: {
    general: {
      title: "General"
    },
    templates: {
      title: "Templates"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    templates: {
      title: "Templates",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "templates"
    },
  },
  fields: {
    //#region General

    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    font_groups: {
      type: FIELD_TYPE.select,
      title: "Font Group",
      field_group: "general",
      settings: {
        options: "font_groups", // This is used for populating options with dynamic data.
        callback_on_change: "apply_font_group" // Action to perform when the value in the select is changed. The name will serve to filter other actions when implemented.
      }
    },

    //#endregion

    // Templates
    // Templates
    templates_select: {
      type: "select",
      title: "Choose a template to edit the default definitions",
      field_group: "templates",
      default: "none",
      options: [
        { title: "Select a template", value: "none" },
        { value: "layout_5", title: "Layout (5)" },
        { value: "media_blank_1", title: "Media / Blank (1)" },
        { value: "media_blank_2", title: "Media / Blank (2)" },
        { value: "media_blank_3", title: "Media / Blank (3)" },
        { value: "media_blank_4", title: "Media / Blank (4)" },
        { value: "media_blank_5", title: "Media / Blank (5)" },
        { value: "media_blank_6", title: "Media / Blank (6)" },
        { value: "media_blank_7", title: "Media / Blank (7)" },
        { value: "media_blank_8", title: "Media / Blank (8)" },
        { value: "intro_2", title: "Intro (2)" },
        { value: "story_with_media_1", title: "Story with Media (1)" },
        { value: "profile_with_media_1", title: "Profile with Media (1)" },
        { value: "story_with_fact_1", title: "Story with Fact (1)" },
        { value: "full_screen_fact_1", title: "Full Screen Fact (1)" },
        { value: "media_with_quote_1", title: "Media with Quote (1)" },
        { value: "media_with_quote_2", title: "Media with Quote 2 (1)" },
        { value: "large_media_with_quote_1", title: "Large Media with Quote (2)" },
        { value: "fact", title: "Fact" },
        { value: "list_4", title: "List (4)" }
      ],
    },

    //#region layout_5

    layout_5_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'layout_5',
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [...transitions],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    layout_5_background_image_overlay: {
      type: "color",
      title: "Default Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'layout_5',
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    layout_5_background_image: {
      title: "Default Background Image",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === 'layout_5',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_1

    media_blank_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_1",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_1_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_1",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.5625,
      recommended_size: {
        width: 1080,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.5625
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_2

    media_blank_2_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_2",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_2_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_2",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 2160,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_3

    media_blank_3_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_3",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_3_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_3",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.6875,
      recommended_size: {
        width: 3240,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.6875
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_4

    media_blank_4_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_4",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_4_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_4",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.25,
      recommended_size: {
        width: 4320,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.25
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_5

    media_blank_5_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_5",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_5_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_5",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_6

    media_blank_6_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_6",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_6_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_6",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_7

    media_blank_7_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_7",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_7_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_7",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_blank_8

    media_blank_8_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_8",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_blank_8_media: {
      title: "Default Media",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_blank_8",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.8125,
      recommended_size: {
        width: 5400,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.8125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region intro_2

    intro_2_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "intro_2",
      default: 10,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    intro_2_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "intro_2",
      show_char_count: false,
      default: {
        ...textstyle_default,
        width: "100%",
        height: "100%",
        alignItems: "flex-start",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#fff",
        fontSize: 16 * 4.375 + "px",
        lineHeight: "1.7",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    intro_2_media: {
      title: "Default Media",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.3,
      recommended_size: {
        width: 920,
        height: 400
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.3
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "intro_2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    intro_2_background_image: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 1920 * 2,
        height: 1080 * 2
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.125
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "intro_2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    intro_2_background_color: {
      type: "color",
      title: "Default Background Image Overlay",
      default: "#76232f",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "intro_2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region story_with_media_1

    story_with_media_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_tagline: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Tagline Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        marginBottom: "0.5em",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.25 + "px",
        lineHeight: "1.15",
        fontWeight: "400",
        letterSpacing: 16 * 0.05 + "px"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_header: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        marginBottom: 16 * 0.5 + "px",
        font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
        color: "#76232f",
        fontSize: 16 * 5 + "px",
        lineHeight: "1.05",
        fontWeight: "600"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 + 2.125 + "px",
        lineHeight: "1.15",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_story_image: {
      title: "Default Story Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.3125,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      recommended_size: {
        width: 840,
        height: 640
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_TOP_LEFT,
        aspect: 1.3125
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_call_to_action: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default Call to Action Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_qr_call_to_action: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Default QR Call to Action Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300",
        alignment: "right"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_media_1_footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_media_1",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region profile_with_media_1

    profile_with_media_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_header: {
      title: "Default Header Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        marginBottom: 16 * 0.5 + "px",
        font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
        color: "#76232f",
        fontSize: 16 + 5 + "px",
        lineHeight: "1.05",
        fontWeight: "600"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_body: {
      title: "Default Body Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.125 + "px",
        lineHeight: "1.25",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_story_image: {
      title: "Default Story Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 4 / 3,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      recommended_size: {
        width: 840,
        height: 640
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_TOP_LEFT,
        aspect: 4 / 3
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_call_to_action: {
      title: "Default Call To Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_qr_call_to_action: {
      title: "Default QR Call to Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300",
        alignment: "right"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1"
    },
    profile_with_media_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    profile_with_media_1_footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "profile_with_media_1",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region story_with_fact_1

    story_with_fact_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_tagline: {
      title: "Default Tagline Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        marginBottom: 16 * 0.5 + "px",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.25 + "px",
        lineHeight: "1.15",
        fontWeight: "400",
        letterSpacing: 16 * 0.05 + "px"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_header: {
      title: "Default Header Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        marginBottom: 16 * 0.5 + "px",
        font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
        color: "#76232f",
        fontSize: 16 * 5 + "px",
        lineHeight: "1.05",
        fontWeight: "600"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_body: {
      title: "Default Body Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.125 + "px",
        lineHeight: "1.15",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_fact_background_color: {
      type: "color",
      title: "Default Fact Background Color",
      default: "#76232f",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_call_to_action: {
      title: "Default Call To Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_qr_call_to_action: {
      title: "Default QR Call to Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 + 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300",
        alignment: "right"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1"
    },
    story_with_fact_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    story_with_fact_1_footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "story_with_fact_1",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region full_screen_fact_1

    full_screen_fact_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_fact_background_color: {
      type: "color",
      title: "Default Fact Background Color",
      default: "#76232f",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_call_to_action: {
      title: "Default Call To Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_qr_call_to_action: {
      title: "Default QR Call to Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 + 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300",
        alignment: "right"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1"
    },
    full_screen_fact_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    full_screen_fact_1_footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "full_screen_fact_1",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_with_quote_1

    media_with_quote_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_image: {
      title: "Default Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 1080,
        height: 960
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_CENTER,
        aspect: 1.125
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_quote_body: {
      title: "Default Quote Body Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#3e6f03",
        fontSize: 16 * 3.125 + "px",
        lineHeight: "1.3",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_call_to_action: {
      title: "Default Call To Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_qr_call_to_action: {
      title: "Default QR Call to Action Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        alignItems: "flex-end",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.25 + "px",
        lineHeight: "1.05",
        fontWeight: "300",
        alignment: "right"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_qr_link: {
      type: "text",
      title: "Default QR Code Link",
      show_char_count: false,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container width",
      step: "0.1",
      default: 0.15,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1"
    },
    media_with_quote_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_1_footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_1",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region media_with_quote_2

    media_with_quote_2_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_2_image: {
      title: "Default Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.125,
      recommended_size: {
        width: 1080,
        height: 960
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_CENTER,
        aspect: 1.125
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_2",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_2_quote_body: {
      title: "Default Quote Body Style",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_2",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#3e6f03",
        fontSize: 16 * 3.125 + "px",
        lineHeight: "1.3",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_2_quote_background_color: {
      type: "color",
      title: "Default Quote Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_2",
      default: "#0087C6BF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    media_with_quote_2_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "media_with_quote_2",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region large_media_with_quote_1

    large_media_with_quote_1_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    large_media_with_quote_1_image: {
      title: "Default Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.60669,
      recommended_size: {
        width: 820,
        height: 510
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_CENTER,
        aspect: 1.60669
      },
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    large_media_with_quote_1_quote_body_left: {
      title: "Default Quote Body Left",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        color: "#FFFFFF",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        fontSize: "56px",
        lineHeight: "1.3",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    large_media_with_quote_1_quote_body_right: {
      title: "Default Quote Body Right",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        color: "#FFFFFF",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        fontSize: "56px",
        lineHeight: "1.3",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    large_media_with_quote_1_quote_background_color: {
      type: "color",
      title: "Default Quote Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      default: "#5392bd",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    large_media_with_quote_1_background_color: {
      type: "color",
      title: "Default Background Color",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "large_media_with_quote_1",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region fact

    fact_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fact",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fact_heading: {
      title: "Default Fact Heading Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fact",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        marginBottom: 16 * 1 + "px",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#fff",
        fontSize: 16 * 2.125 + "px",
        lineHeight: "1.15",
        fontWeight: "600"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    fact_body: {
      title: "Default Fact Body Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "fact",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#fff",
        fontSize: 16 * 1.875 + "px",
        lineHeight: "1.35",
        fontWeight: "300"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    //#region list_4

    list_4_transition: {
      type: "select",
      title: "Default Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_donor_transition: {
      type: "select",
      title: "Default Donor Names Transition",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_cycle_time: {
      type: "integer",
      title: "Default Cycle Time",
      default: 10,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },

    list_4_sorting_options: {
      ...sorting_options,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      default: "sorting_order",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_donor_list_header: {
      title: "Default Header Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        width: "100%",
        font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
        color: "#75787a",
        fontSize: 16 * 3.125 + "px",
        lineHeight: "1.05",
        fontWeight: "600",
        textAlign: "center"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_subhead: {
      title: "Default Description Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        width: "100%",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.25 + "px",
        lineHeight: "1.05",
        fontWeight: "400",
        textAlign: "center"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_category: {
      title: "Default Category Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        position: "relative",
        width: "100%",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 2.125 + "px",
        lineHeight: "1.05",
        fontWeight: "600",
        textAlign: "center"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_columns: {
      type: "integer",
      title: "Default Columns",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
      default: 2,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_donor_list_column_margin_right: {
      type: "integer",
      title: "Default Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    list_4_donor_names: {
      title: "Default Donor Names Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.875 + "px",
        lineHeight: "1.15",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_footer: {
      title: "Default Footer Style",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        color: "#75787a",
        fontSize: 16 * 1.75 + "px",
        lineHeight: "1.05",
        fontWeight: "400"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_show_upper_rule: {
      type: "bool",
      title: "Default Show upper horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_show_lower_rule: {
      type: "bool",
      title: "Default Show lower horizontal rule",
      default: true,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_horizontal_rule_color: {
      type: "color",
      title: "Default Horizontal Rule Color",
      default: "#75787a",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_horizontal_rule_size: {
      title: "Default Horizontal Rule Size",
      type: "number",
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2,
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_donor_background_image: {
      title: "Default List Background Image",
      type: "file",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.25,
      recommended_size: {
        width: 4320,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.25
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },
    list_4_donor_background_image_overlay: {
      type: "color",
      title: "Default List Background Image Overlay",
      field_group: "templates",
      isVisible: (values) => values.templates_select === "list_4",
      default: "#fff",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    },

    //#endregion

    footer_line_color: {
      type: "color",
      title: "Footer Line Color",
      field_group: "general",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "general",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    use_control_panel: {
      type: "bool",
      title: "This project uses a Control Panel",
      default: true,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    }
  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.COMPONENT
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.marin_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.marin_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.marin_content
    }
  ],
  font_groups: {
    enabled: true,
    compatible_folder_types: [FOLDER_TYPE.marin_presentation_5x1]
  },
  hide_add_icon_in_tree: true
};
