
import axios, { AxiosError } from 'axios';
import { method } from 'lodash';
import moment from 'moment';

export default class HttpRequest {
  ajax = null;
  constructor(opts) {
    this.ajax = axios.create({
      baseURL: opts.baseUrl,
      timeout: opts.timeout || 999000
    });
    this.sessionInterceptor(this.ajax);
  }

  handleError(e) {
    const response = e.response;
    if (response) {
      return {
        errors: [{
          status: response.status,
          data: response.data.data,
          message: response.data.message
        }]
      }
    }
    return {
      errors: [{
        status: 500,
        data: null,
        message: "Something went wrong"
      }]
    }
  }

  setDefaultOption(name, value) {
    switch (name) {
      case 'csrf':
        this.ajax.defaults.headers['X-CSRF-Token'] = value;
        break;
      case 'credentials':
        this.ajax.defaults.withCredentials = value;
        break;
      default:

        break;
    }
  }

  async getCsrf(url, params = {}, options = {}) {
    return await this.ajax({
      method: 'get',
      url,
      params,
      ...options
    });
  }

  async get(url, params = {}, options = {}) {
    try {
      let response = await this.ajax({
        method: 'get',
        url,
        params,
        ...options
      });
      return {
        status: response.status,
        code: response.data.code,
        data: response.data.data,
        errors: response.data.errors,
        message: response.data.message
      }
    }
    catch (e) {
      return this.handleError(e)
    }
  }

  async getFile(url, params = {}, options = {}) {
    let response = await this.ajax({
      method: 'get',
      url,
      params,
      ...options
    });
    return {
      status: response.status,
      data: response.data,
      errors: response.data.errors,
      headers: response.headers
    }
  }

  async post(url, data = {}, options = {}) {
    try {
      let response = await this.ajax(url, {
        method: 'post',
        url,
        data,
        ...options
      });

      return {
        status: response.status,
        code: response.data.code, // deprecated
        data: response.data.data,
        errors: response.data.errors,
        //message: response.data.message // deprecated
      }
    } catch (e) {
      return this.handleError(e);
    }
  }

  async put(url, data = {}, options = {}) {
    try {
      let response = await this.ajax(url, {
        method: 'put',
        url,
        data,
        ...options
      });
      return {
        status: response.status,
        code: response.data.code,
        data: response.data.data,
        message: response.data.message
      }
    }
    catch (e) {
      return this.handleError(e)
    }
  }

  async delete(url, params = {}, options = {}) {
    try {
      let response = await this.ajax(url, {
        method: 'delete',
        url,
        params,
        ...options
      });
      return {
        status: response.status,
        code: response.data.code,
        data: response.data.data,
        message: response.data.message
      }
    }
    catch (e) {
      return this.handleError(e)
    }
  }

  sessionInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use((config) => {
      return config;
    }, (error) => {
      if (typeof error === "object" && error.response.status === 403) {
        //Unauthorized
        //redirect to Login
        const date = localStorage.getItem("session_expire");
        if (moment(date).unix() <= moment().unix()) {
          window.location.href = '/login/session-expired';
        }
      }
      throw error;
    });
  }
}