// Folder action Types
export const FETCH_PROJECT_FOLDERS = 'FETCH_PROJECT_FOLDERS';
export const MOVE_FOLDER = 'MOVE_FOLDER';
export const MOVE_RESOURCE_FOLDER = 'MOVE_RESOURCE_FOLDER';
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_RESOURCE_TREE_FOLDER = "UPDATE_RESOURCE_TREE_FOLDER"
export const FETCH_FOLDER_ICON_TYPES_SUCCESS = "FETCH_FOLDER_ICON_TYPES_SUCCESS";
export const DELETE_DISPLAY_IDENTIFIER = "DELETE_DISPLAY_IDENTIFIER";
export const GET_DISPLAY_DATA  = "GET_DISPLAY_DATA";

// export const UPDATE_SELECTED_FOLDER = 'UPDATE_SELECTED_FOLDER'; // naming?
export const ADD_FOLDER = 'ADD_FOLDER';
export const CLONE_FOLDER = 'CLONE_FOLDER';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';
export const TOGGLE_FOLDER_EXPANDED = 'TOGGLE_FOLDER_EXPANDED';
export const SET_FOLDERS_EXPANDED = 'SET_FOLDERS_EXPANDED';
export const UPDATE_UPLOADED_RESOURCES = 'UPDATE_UPLOADED_RESOURCES';
export const UPDATE_UPLOADED_RESOURCES_RESOURCE_TREE = 'UPDATE_UPLOADED_RESOURCES_RESOURCE_TREE';
export const ADD_RESOURCES = 'ADD_RESOURCES';
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES';
export const ADD_TEXT_RESOURCES = 'ADD_TEXT_RESOURCES';
export const UPDATE_TEXT_RESOURCE = 'UPDATE_TEXT_RESOURCE';
export const DELETE_RESOURCES = 'DELETE_RESOURCES';
export const CLEAN_RESOURCES = 'CLEAN_RESOURCES';
export const GET_RESOURCE_TAGS = 'GET_RESOURCE_TAGS';
export const DELETE_RESOURCE_TAGS = 'DELETE_RESOURCE_TAGS';
export const ADD_RESOURCE_TAGS = 'ADD_RESOURCE_TAGS';
export const GET_RESOURCE_NOTES = 'GET_RESOURCE_NOTES';
export const ADD_RESOURCE_NOTES = 'ADD_RESOURCE_NOTES';
export const DELETE_RESOURCE_NOTES = 'DELETE_RESOURCE_NOTES';
export const UPDATE_RESOURCE_NOTES = 'UPDATE_RESOURCE_NOTES';
export const TOGGLE_MODAL_TREE_FOLDER_EXPANDED = 'TOGGLE_MODAL_TREE_FOLDER_EXPANDED';
export const UPDATE_FILE_RESOURCE = 'UPDATE_FILE_RESOURCE';
export const DOWNLOAD_PROJECT_CSV = 'DOWNLOAD_PROJECT_CSV';
export const DOWNLOAD_FOLDER_CSV = 'DOWNLOAD_FOLDER_CSV';
export const DOWNLOAD_HARDWARE_INFORMATION_CSV = 'DOWNLOAD_HARDWARE_INFORMATION_CSV';
export const DOWNLOAD_HARDWARE_INFORMATION_TXT = 'DOWNLOAD_HARDWARE_INFORMATION_TXT';
export const UPLOAD_PROJECT_CSV = 'UPLOAD_PROJECT_CSV';
export const UPLOAD_FOLDER_CSV = 'UPLOAD_FOLDER_CSV';
export const INSTANCE_FOLDER = 'INSTANCE_FOLDER';
export const PREVIEW_FOLDER = 'PREVIEW_FOLDER';
export const PUBLISH_FOLDER = 'PUBLISH_FOLDER';
export const MAKE_FOLDER_PULLABLE = 'MAKE_FOLDER_PULLABLE';
export const GET_FOLDER_INFO = 'GET_FOLDER_INFO';
export const DELETE_BULK_FOLDERS = 'DELETE_BULK_FOLDERS';
export const DELETE_BULK_RESOURCES = 'DELETE_BULK_RESOURCES';
export const MOVE_BULK_RESOURCES = 'MOVE_BULK_RESOURCES';
export const TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL = 'TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL';
export const BULK_MOVE_FOLDER = 'BULK_MOVE_FOLDER';
export const BULK_CLONE_FOLDER = 'BULK_CLONE_FOLDER';
export const GENERATE_CLIENT_CONFIG = 'GENERATE_CLIENT_CONFIG';
export const GET_ALL_NOTIFIABLE_USERS = 'GET_ALL_NOTIFIABLE_USERS';
export const ADD_BULK_FOLDERS = 'ADD_BULK_FOLDERS';
export const UPDATE_BULK_FOLDERS = 'UPDATE_BULK_FOLDERS';
export const UPDATE_BULK_RESOURCES = 'UPDATE_BULK_RESOURCES';
export const START_POLL_FOLDER_STATUS = 'START_POLL_FOLDER_STATUS';
export const STOP_POLL_FOLDER_STATUS = 'STOP_POLL_FOLDER_STATUS';
export const FETCH_COMPANY_ROOT_FOLDERS = 'FETCH_COMPANY_ROOT_FOLDERS';
export const SET_UPDATE_FOLDER_TOUCH = 'SET_UPDATE_FOLDER_TOUCH';
export const SET_UPDATE_LIST_VIEW_TOUCH = 'SET_UPDATE_LIST_VIEW_TOUCH';
export const GET_SIGNED_FOLDER_URLS = 'GET_SIGNED_FOLDER_URLS';
export const URI_ACCESS_GET_DATA = 'URI_ACCESS_GET_DATA';
export const TOGGLE_EMAIL_SUBSCRIPTION_NOTIFICATION = 'TOGGLE_EMAIL_SUBSCRIPTION_NOTIFICATION';
export const SET_FOLDER_TREE_INHERITABLE_SETTINGS = 'SET_FOLDER_TREE_INHERITABLE_SETTINGS';
export const PULL_DOWN_INHERITABLE_FIELDS = 'PULL_DOWN_INHERITABLE_FIELDS';
export const PUSH_DOWN_INHERITABLE_FIELDS = 'PUSH_DOWN_INHERITABLE_FIELDS';
export const UPDATE_FOLDER_ALONE = 'UPDATE_FOLDER_ALONE';
export const CHECK_UPDATE_FOLDER_ALONE = 'CHECK_UPDATE_FOLDER_ALONE';
export const UPSERT_FOLDER_AR_MODEL = 'UPSERT_FOLDER_AR_MODEL';
export const GET_FOLDER_AR_MODEL = 'GET_FOLDER_AR_MODEL';

export const UPDATE_FOLDER_CONTENTS = 'UPDATE_FOLDER_CONTENTS';
// Item Action Types
export const ADD_ITEM = 'ADD_ITEM';

// Font Group
export const ADD_FONT_GROUP = "ADD_FONT_GROUP";
export const UPDATE_FONT_GROUP = "UPDATE_FONT_GROUP";
export const REMOVE_FONT_GROUP = "REMOVE_FONT_GROUP";

// Search
export const SEARCH_MAIN = 'SEARCH_MAIN';
export const SEARCH_TAG = 'SEARCH_TAG';

// Util action Types
export const BREADCRUMB_SET = 'BREADCRUMB_SET';
export const SAVE_SOCKET_INSTANCE = 'SAVE_SOCKET_INSTANCE';
export const BROADCAST_NEW_MOBILE_HEADER = 'BROADCAST_NEW_MOBILE_HEADER';
export const INITIALIZE_S3_CACHE = 'INITIALIZE_S3_CACHE';
export const UPDATE_S3_CACHE = 'INITIALIZE_S3_CACHE';
export const DELETE_S3_CACHE = 'DELETE_S3_CACHE';
export const INITIALIZE_S3_CACHE_SUCCESS = 'INITIALIZE_S3_CACHE_SUCCESS';
export const UPDATE_S3_CACHE_SUCCESS = 'UPDATE_S3_CACHE_SUCCESS';
export const DELETE_S3_CACHE_SUCCESS = 'DELETE_S3_CACHE_SUCCESS';

// Auth action Types
export const GET_CSRF = 'GET_CSRF';
export const LOGIN = 'LOGIN';
export const LOGOUT = "LOGOUT";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const URI_ACCESS_INITIALIZE = 'URI_ACCESS_INITIALIZE';

// Client Option action types
export const SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS';

// User action types
export const USER_REGISTER = 'USER_REGISTER';
export const USER_FETCH = 'USER_FETCH';
export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CREATE = 'USER_CREATE';
export const USER_GET_DATA = 'USER_GET_DATA';
export const USER_ACC_UPDATE = 'USER_ACC_UPDATE';
export const UPDATE_PERSISTENT_SETTINGS = 'UPDATE_PERSISTENT_SETTINGS';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const CHANGE_PLACEHOLDER_CONFIGURATION = 'CHANGE_PLACEHOLDER_CONFIGURATION';

//Company action types
export const GET_COMPANIES = 'GET_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const REINDEX_ELASTIC = 'REINDEX_ELASTIC';
export const FOLDER_TYPE_ALERT = 'FOLDER_TYPE_ALERT';

// project action type
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const GET_PROJECT = 'GET_PROJECT';

export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const UPDATE_PROJECT_SETTINGS = 'UPDATE_PROJECT_SETTINGS';

export const ADD_ROLE = 'ADD_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';

export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PROJECTS = 'GET_PROJECTS';

//User action type
export const GET_SINGLEUSER = 'GET_SINGLEUSER';
export const GET_ALLUSER = 'GET_ALLUSER';
export const GET_ALLUSERS_ALLCOMPANIES = 'GET_ALLUSERS_ALLCOMPANIES';
export const COMPANYUSER_UPDATE = 'COMPANYUSER_UPDATE';
export const COMPANYUSER_ADD = 'COMPANYUSER_ADD';
export const ADD_ROLE_USER = 'ADD_ROLE_USER';
export const DELETE_ROLE_USER = 'DELETE_ROLE_USER';
export const RESEND_SET_USER_PASSWORD_EMAIL = 'RESEND_SET_USER_PASSWORD_EMAIL';
export const TEST_CHANGE = 'TEST_CHANGE'

// Audit Log Action type
export const GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
export const GET_AUDIT_LOGS_BY_FOLDER = 'GET_AUDIT_LOGS_BY_FOLDER';

// Locations action Type
export const ADD_LOCATION = 'ADD_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const GET_LOCATIONS = 'GET_LOCATIONS';

// Backup action type
export const CREATE_BACKUP = 'CREATE_BACKUP';
export const GET_BACKUPS_BY_COMPANY = 'GET_BACKUPS_BY_COMPANY';
export const RESTORE_BACKUP = 'RESTORE_BACKUP';
export const EXPORT_BACKUP = 'EXPORT_BACKUP';
export const CREATE_CROSS_BACKUP = "CREATE_CROSS_BACKUP";

// user company
export const ADD_USER_COMPANY = "ADD_USER_COMPANY";
export const REMOVE_USER_COMPANY = "REMOVE_USER_COMPANY";

// notifications
export const GET_FOLDER_STATUS = 'GET_FOLDER_STATUS';
export const GET_FOLDER_STATUS_SUCCESS = 'GET_FOLDER_STATUS_SUCCESS';
export const GET_FOLDER_STATUS_FAILURE = 'GET_FOLDER_STATUS_FAILURE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_USER_NOTIFICATION_SUCCESS = 'GET_USER_NOTIFICATION_SUCCESS';

export const UPDATE_NOTIFICATIONS_READ = 'UPDATE_NOTIFICATIONS_READ';
export const UPDATE_NOTIFICATIONS_READ_SUCCESS = 'UPDATE_NOTIFICATIONS_READ_SUCCESS';
export const UPDATE_NOTIFICATIONS_READ_FAILURE = 'UPDATE_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATIONS_DELETE = 'UPDATE_NOTIFICATIONS_DELETE';
export const UPDATE_NOTIFICATIONS_DELETE_SUCCESS = 'UPDATE_NOTIFICATIONS_DELETE_SUCCESS';
export const UPDATE_NOTIFICATIONS_DELETE_FAILURE = 'UPDATE_NOTIFICATIONS_DELETE_FAILURE';

export const UPDATE_DISPLAY_HARDWARE_INFO = 'UPDATE_DISPLAY_HARDWARE_INFO';
export const UPDATE_DISPLAY_HARDWARE_INFO_SUCCESS = 'UPDATE_DISPLAY_HARDWARE_INFO_SUCCESS';
export const UPDATE_DISPLAY_HARDWARE_INFO_FAIL = 'UPDATE_DISPLAY_HARDWARE_INFO_FAIL';
// Tags
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
// URI Actions
export const GET_URI_ACCESS_BY_FOLDER = 'GET_URI_ACCESS_BY_FOLDER';
export const UPSERT_URI_ACCESS = 'UPSERT_URI_ACCESS';
// error
export const ADD_ERROR = 'ADD_ERROR'

export const INIT_SOCKET = 'INIT_SOCKET';
