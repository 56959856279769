import React, { useEffect, useState } from "react";
import { Input, Popover, Radio } from "antd";
import _, { get } from "lodash";
import {checkPasswordValidation} from "../../../utils/passwordValidator";
import {TooltipPlacement} from "antd/lib/tooltip";

interface IAuthPasswordInputProps {
  onBlur: any;
  showLabel: boolean;
  fieldName: string;
  defaultValue: string;
  title: string;
  onChange: any;
  canUpdate: boolean;
  placeholder: string;
  showPasswordRequirement: boolean;
  passwordRequirementPosition: TooltipPlacement;
}

const AuthPasswordInput: React.FC<IAuthPasswordInputProps> = (props: IAuthPasswordInputProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const [placeholder, setPlaceholder] = useState('')
  const [passwordContent, setPasswordContent] = useState(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement>('right')
  
  useEffect(()=> {
    const innerPlacehodler = get(props, 'placeholder', '');
    setPlaceholder(innerPlacehodler);
  }, [props.placeholder])

  useEffect(() => {
    if (props.passwordRequirementPosition) {
      setTooltipPlacement(props.passwordRequirementPosition);
    }
  }, [props.passwordRequirementPosition])

  useEffect(() => {
    const password = props.defaultValue;
    const innerPasswordContent = buildPasswordContent(password)

    setPasswordContent(innerPasswordContent);

  }, [props.defaultValue])

  useEffect(() => {
    if (props.showPasswordRequirement) {
      setShowTooltip(true)
    }

  }, [props.showPasswordRequirement])

  const buildPasswordContent = password => {
    const passwordValidation = checkPasswordValidation(password);
    const rules = passwordValidation.rules
    const innerPasswordContent = (
        <div className="auth-password-input-popover_container">
          <div className="auth-password-input-popover_header">
            Password Requirements
          </div>
          <div className="auth-password-input-popover_body">
            {
              rules.map((r, index) => (
                  <div className="auth-password-input-popover_rule" key={`auth-rule-${index}`}>
                    <div className="auth-password-input-popover_rule-check"><Radio checked={r.valid}/></div>
                    <div className="auth-password-input-popover_rule-label">{r.label}</div>
                  </div>
              ))
            }
          </div>
        </div>
    );
    return innerPasswordContent;
  }

  const onBlur = (ev: any) => {
    setFocused(false);
    setShowTooltip(false);
    if (props.onBlur) {
      props.onBlur(props.fieldName, ev.target.value);
    }
  };

  const onFocus = () => {
    setFocused(true);
    setShowTooltip(true);
  };

  const onVisibilityChange = () => {
    // setShowFilterModal(visible);
  }

  return (
    <React.Fragment>
      {props.showLabel ? (
        <span>
          <label className="form-input-label">{props.title}</label>
          <br />
        </span>
      ) : null}
      <Popover
          overlayClassName="auth-password-input-popover"
          content={passwordContent}
          trigger="focus"
          placement={tooltipPlacement}
          color="red"
          visible={showTooltip}
          onVisibleChange={onVisibilityChange}
      >
        <Input
            className="text-input auth-password-input_input"
            defaultValue={props.defaultValue}
            value={props.defaultValue}
            onChange={(ev) => props.onChange(props.fieldName, ev.target.value)}
            onBlur={(ev) => onBlur(ev)}
            onFocus={() => onFocus()}
            disabled={!props.canUpdate}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            placeholder={placeholder}
            required
            type="password"
        />
      </Popover>
    </React.Fragment>
  );
};

export default AuthPasswordInput;
