import {
  GET_COMPANIES,
  UPDATE_ROLE,
  ADD_COMPANY,
  GET_COMPANY,
  UPDATE_COMPANY,
  ADD_ROLE,
  DELETE_ROLE,
  ADD_PERMISSION,
  DELETE_PERMISSION,
  UPDATE_PERMISSION,
  GET_PERMISSION,
  DELETE_COMPANY,
  REINDEX_ELASTIC,
  FOLDER_TYPE_ALERT
} from "./actionTypes";

export const getCompanies = (onSuccess?, onFail?) => ({
  type: GET_COMPANIES,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getCompany = (id, onSuccess?, onFail?) => ({
  type: GET_COMPANY,
  payload: { id: id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addCompany = (name, active, onSuccess?, onFail?) => ({
  type: ADD_COMPANY,
  payload: { name: name, active: active },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addRole = (
  selectedOptions,
  name,
  companyid,
  scope,
  onSuccess?,
  onFail?
) => ({
  type: ADD_ROLE,
  payload: {
    name: name,
    company: companyid,
    scope: scope,
    selectedOptions: selectedOptions
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateRole = (
  name,
  roleId,
  company,
  permissions,
  scope,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_ROLE,
  payload: {
    name: name,
    roleId: roleId,
    company: company,
    permissions: permissions,
    scope: scope
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deleteRole = (roleId, companyid, onSuccess?, onFail?) => ({
  type: DELETE_ROLE,
  payload: { roleId: roleId, company: companyid },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getPermission = (id, onSuccess?, onFail?) => ({
  type: GET_PERMISSION,
  payload: { id: id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const addPermission = (permission, onSuccess?, onFail?) => ({
  type: ADD_PERMISSION,
  payload: { permission: permission },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updatePermission = (permission, onSuccess?, onFail?) => ({
  type: UPDATE_PERMISSION,
  payload: { permission: permission },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const deletePermission = (permissionId, onSuccess?, onFail?) => ({
  type: DELETE_PERMISSION,
  payload: { permissionId: permissionId },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateCompany = (id, name, active, sns_alerts_enabled, sns_alerts_email_address, onSuccess?, onFail?) => ({
  type: UPDATE_COMPANY,
  payload: { id: id, name: name, active: active, sns_alerts_enabled, sns_alerts_email_address: sns_alerts_email_address || null },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const delete_company = (company_id, onSuccess?, onFail?) => ({
  type: DELETE_COMPANY,
  payload: { company_id: company_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const reindexElastic = () => ({
  type: REINDEX_ELASTIC,
  payload: {},
  onSuccess: null,
  onFail: null
});

export const folderTypeAlert = (data, onSuccess?, onFail?) => ({
  type: FOLDER_TYPE_ALERT,
  payload: data,
  onSuccess,
  onFail,
});