import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { wysiwygSettings, transitions, textStyleOptions } from "./monterrey_settings";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { sorting_options } from "../standardized_fields";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";

export const monterrey_donor_list: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  name: FOLDER_TYPE.monterrey_donor_list,
  display_name: "Donor List",
  acceptable_folder_types: [FOLDER_TYPE.monterrey_donor],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    donor_list: {
      title: "Donor List",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
    horizontal_rules: {
      title: "Horizontal Rules",
      default_expanded: true,
      order: 4,
      accordion: true,
      tab: "general"
    },
    background: {
      title: "Background",
      default_expanded: true,
      order: 5,
      accordion: true,
      tab: "general"
    },
    list_settings: {
      title: "List Settings",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "advanced"
    }
  },
  fields: {
    // General Field Group
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    donor_transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 10, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_cycle_time"
      }
    },
    sorting_options: {
      ...sorting_options,
      field_group: "general",
      default: "sorting_order"
    },
    // Donor List Field Group
    donor_list_header: {
      title: "Donor List Header",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            align: "center",
            font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
            size: "50px",
            bold: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_donor_list_header"
      }
    },
    subhead: {
      title: "Donor List Description",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            align: "center",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "36px",
            italic: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_subhead"
      }
    },
    category: {
      title: "Donor List Category",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            align: "center",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.SEMI_BOLD],
            size: "34px",
            bold: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_category"
      }
    },
    columns: {
      type: "integer",
      title: "Columns",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
      default: 2,
      field_group: "donor_list"
    },
    donor_list_column_margin_right: {
      type: "integer",
      title: "Donor List Column Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "donor_list"
    },
    donor_names: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      default: {
        color: "#75787a",
        alignment: "left",
        font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
        fontSize: 30
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_donor_names"
      },
      field_group: "donor_list"
    },
    footer: {
      title: "Footer",
      field_group: "donor_list",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "28px",
            color: "#75787a",
            align: "right",
            italic: true
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_footer"
      }
    },
    // Horizontal Rules Field Group
    show_upper_rule: {
      type: "bool",
      title: "Show upper horizontal rule",
      default: true,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_show_upper_rule"
      }
    },
    show_lower_rule: {
      type: "bool",
      title: "Show lower horizontal rule",
      default: true,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_show_lower_rule"
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#75787a",
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_horizontal_rule_color"
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2,
      field_group: "horizontal_rules",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_horizontal_rule_size"
      }
    },
    // Background Field Group
    donor_background_image: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 2.25,
      recommended_size: {
        width: 4320,
        height: 1920
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 2.25
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_donor_background_image"
      },
      field_group: "background"
    },
    donor_background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "list_4_donor_background_image_overlay"
      }
    },
    // List Settings Field Group
    donor_list_margin_top: {
      type: "integer",
      title: "Donor List Margin Top",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    donor_vertical_separation: {
      type: "integer",
      title: "Donor Vertical Separation",
      tooltip: "Unit is % of container height",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 1,
      field_group: "list_settings"
    },
    donor_list_margin_left: {
      type: "integer",
      title: "Donor List Margin Left",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    donor_list_margin_right: {
      type: "integer",
      title: "Donor List Margin Right",
      tooltip: "Unit is % of container width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0,
      field_group: "list_settings"
    },
    screen_left_and_right_padding: {
      type: "integer",
      title: "Screen Left and Right Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "list_settings",
      default: 15
    },
    screen_top_and_bottom_padding: {
      type: "integer",
      title: "Screen Top and Bottom Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      sys_admin_only: true,
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "list_settings"
    },
    cycle_forward_key: {
      type: "text",
      title: "Cycle Forward Key",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      default: "ArrowRight",
      field_group: "list_settings"
    }
    // donor_list_style: {
    //   type: "select",
    //   title: "List Style",
    //   field_group: "list_settings",
    //   validation: { rules: [{ name: "isRequired" }] },
    //   default: "filled",
    //   options: list_styles,
    // },
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name", "sorting_order"]
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  slots: 4,
  preview: {
    aspect_ratio: 2.25,
    component_name: COMPONENT_NAME.MonterreyDynamicContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 4, vertical: 1 }],
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.monterrey_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.monterrey_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_donor],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_donor],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.monterrey_donor],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  cycle_field_name: "donor_cycle_time",
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false
};
