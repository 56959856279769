import React from 'react';

type HandTouchProps = { color: string }

const HandTouch: React.FunctionComponent<HandTouchProps> = ({ color }: HandTouchProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    height="100%"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <g fill={color}>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path d="M3976.2,5002.1c-63.2-9.6-174.3-34.5-249-57.5C3099,4755,2618.3,4270.4,2421,3630.7c-47.9-155.1-51.7-193.4-51.7-513.3c0-295,5.7-365.8,42.1-494.1c91.9-331.3,289.2-662.7,534.4-896.3c139.8-132.2,411.8-323.7,461.6-323.7c17.2,0,23,46,23,162.8v160.9l-90,68.9c-314.1,237.5-509.5,511.4-622.5,871.5c-38.3,126.4-44,182-44,450.1c0,296.9,3.8,312.2,63.2,488.4c74.7,212.6,122.6,302.6,266.2,494.1c193.4,254.7,547.8,486.5,867.6,568.8c208.8,53.6,496,57.5,716.3,13.4c748.9-155.1,1279.4-808.2,1279.4-1574.4c1.9-509.5-214.5-948.1-620.6-1267.9l-149.4-118.7v-168.6v-170.5l63.2,32.6c141.7,72.8,346.7,231.7,488.4,379.2c249,260.5,415.6,574.6,496.1,940.4c38.3,176.2,38.3,568.8-1.9,747c-168.5,787.2-777.6,1377.1-1560.9,1513.1C4407.2,5025.1,4160.1,5028.9,3976.2,5002.1z" />
        <path d="M4014.5,4304.9c-256.6-55.5-415.6-145.6-614.8-342.8c-168.5-168.5-268.1-340.9-323.7-557.3c-38.3-147.5-36.4-440.5,1.9-591.8c44-170.4,149.4-375.4,256.6-492.2l93.8-103.4l1.9,245.1c0,199.2-7.7,273.9-40.2,381.2c-53.6,172.4-44,429,19.2,595.6c195.4,501.8,787.2,727.8,1269.8,486.5c404.1-201.1,614.8-735.5,446.3-1137.7c-21.1-47.9-28.7-139.8-28.7-319.8l1.9-250.9l93.8,101.5c107.3,114.9,231.8,356.2,270.1,521c30.6,136,30.6,400.3,0,536.3c-34.5,143.6-157.1,392.6-247.1,501.8C4914.7,4236,4453.1,4400.7,4014.5,4304.9z" />
        <path d="M4068.2,3464.1c-162.8-53.6-270-145.6-356.2-306.4l-42.1-78.5l-9.6-2231.3l-9.6-2231.3l-84.3,229.8c-164.7,453.9-187.7,499.9-300.7,601.4c-136,126.4-249,162.8-455.8,151.3c-260.5-15.3-440.5-132.1-543.9-358.1c-40.2-84.3-51.7-136-49.8-241.3c0-122.6,32.6-235.6,369.6-1254.5c206.9-624.4,402.2-1187.5,444.4-1271.8c176.2-354.3,452-649.3,814-867.6c658.9-400.3,1741-505.6,2411.3-237.5c398.4,159,733.6,434.7,988.3,808.2c235.6,348.6,408,900.2,509.5,1626.1c63.2,459.7,23,1840.6-61.3,2120.2c-95.8,317.9-455.8,476.9-779.5,342.8c-113-46-130.2-46-130.2,5.7c0,61.3-91.9,227.9-164.7,296.9c-122.6,118.8-204.9,151.3-404.1,160.9c-155.1,7.7-185.8,3.8-279.6-40.2L5830.2,641l-44,107.2c-134.1,323.7-505.6,448.2-884.9,300.7c-30.6-11.5-32.6,51.7-38.3,990.2c-5.7,982.5-5.7,1003.6-47.9,1091.7c-57.5,122.6-166.6,239.4-281.5,296.9C4414.8,3489,4194.6,3506.2,4068.2,3464.1z M4407.2,3148.1c38.3-19.2,84.3-68.9,109.2-116.8l44-82.3V1257.7V-435.4l55.5-61.3c76.6-86.2,176.2-86.2,252.8,0l55.5,61.3V98.9c0,522.9,0,534.4,44,597.6c109.2,164.7,429,139.8,521-40.2c23-46,28.7-164.7,28.7-670.3c0-653.1,7.7-720.2,91.9-764.2c72.8-38.3,157.1-24.9,216.4,34.5l55.5,55.5V-240c0,386.9,3.8,455.8,34.5,513.3c49.8,101.5,141.7,153.2,270.1,153.2c137.9,0,220.3-49.8,270-159c34.5-72.8,38.3-130.2,38.3-632c0-358.2,7.7-565,21.1-591.8c11.5-21.1,49.8-49.8,86.2-65.1c55.5-23,74.7-23,130.3,0c34.5,15.3,70.8,38.3,80.4,51.7c9.6,15.3,21.1,197.3,26.8,404.1c9.6,409.9,15.3,427.1,137.9,519c63.2,47.9,208.8,63.2,283.5,32.6c107.2-44,137.9-114.9,164.7-371.6c40.2-400.3,70.9-1064.9,57.5-1313.9c-46-846.6-229.8-1561-511.4-1976.6c-243.2-358.2-620.6-624.4-1045.8-737.4c-170.4-46-220.3-49.8-532.4-49.8c-375.4,0-586.1,26.8-881,111.1c-580.3,168.5-1045.7,553.5-1241.1,1026.6c-72.8,176.2-760.4,2281.1-760.4,2325.2c1.9,70.9,70.9,195.4,136,241.3c53.6,38.3,88.1,46,203,46c226,0,226,0,522.9-814c145.6-396.5,266.2-695.2,291.1-722.1c57.5-61.3,166.6-59.4,239.4,1.9l53.6,46l9.6,2604.8c9.6,2597.1,9.6,2606.7,49.8,2660.3C4100.7,3178.7,4278.8,3217,4407.2,3148.1z" />
      </g>
    </g>
  </svg>
);

export default HandTouch;
