import { Button, Col, Input, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCSRF, login } from "../../actions/authActions";
import { userGetData } from "../../actions/userActions";
import { IUser } from "../../shared-global/interfaces/models/user.interface";
import AlertNotification from "../layout/AlertNotification";
import { withRouter } from "../../hooks/withRouter";

interface ILoginProps {
  user: IUser;
  getCSRF: Function;
  login: Function;
  userGetData: Function;
  navigate: any;
  params: any;
}

interface ILoginState {
  email: string;
  password: string;
  status: string;
}

class Login extends Component<ILoginProps, ILoginState> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      status: this.props.params?.status,
    };

    if (this.state.status === "session-expired") {
      AlertNotification("warning", "Your session has expired");
    }
  }

  componentDidMount() {
    if (this.props.user) {
      this.checkUserLogged(this.props.user);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user != prevProps.user) {
      this.checkUserLogged(this.props.user);
    }
  }

  checkUserLogged = (user) => {
    if (user && user.id) {
      this.goToDashboard();
    }
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    this.props.getCSRF(() => {
      this.props.login(
        this.state.email,
        this.state.password,
        this.handleSuccess
      );
    });
  };
  handleSuccess = () => {
    // Make sure user data is populated before hitting the dashboard route
    localStorage.removeItem("s3cache");
    this.props.userGetData(() => {
      this.props.navigate("/dashboard");
    });
  };

  handlePasswordReset = () => {
    this.props.navigate("/request_password_reset");
  };

  goToDashboard = () => {
    this.props.navigate("/dashboard");
  };

  render() {
    return (
      <div className="auth-container">
        <img
          className="auth-container_bg"
          alt="login"
          src="https://pdg-public.s3-us-west-2.amazonaws.com/cms/assets/login_image.jpg"
        />
        <Row>
          <Col
            xs={24}
            sm={24}
            md={11}
            lg={8}
            className="auth-container_form-container"
          >
            <div className="auth-container_form-panel">
              <img
                className="auth-container_logo"
                alt="login"
                src="/images/appello_cms_logo_H_white.png"
              />
              <form className="login-form auth-container_form">
                <h2 className="auth-container_h2">Welcome Back!</h2>

                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <Input
                      className="auth-container_form-input-text"
                      autoFocus
                      tabIndex={1}
                      placeholder="email"
                      required
                      type="text"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                      dir="auto"
                    />
                  </div>
                </div>

                <div className="auth-container_form-input-container">
                  <div className="auth-container_form-input">
                    <Input
                      className="auth-container_form-input-text"
                      placeholder="password"
                      tabIndex={2}
                      required
                      type="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                      dir="auto"
                    />
                  </div>
                </div>
                <div className="auth-container_buttons">
                  <Button
                    className="auth-container_button"
                    onClick={this.handleFormSubmit}
                    htmlType="submit"
                    value="Log In"
                  >
                    Log In
                  </Button>
                </div>
                <div className="auth-container_links">
                  <span
                    className="like-alink auth-container_link"
                    onClick={() => this.handlePasswordReset()}
                  >
                    Forgot your password?
                  </span>
                </div>
              </form>
            </div>
          </Col>
          <Col xs={0} sm={0} md={11} lg={13}></Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.data.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: (email, password, onSuccess, onFail) => {
      dispatch(login(email, password, "session", onSuccess, onFail));
    },
    getCSRF: (onSuccess) => {
      dispatch(getCSRF(onSuccess));
    },
    userGetData: (onSuccess, onFail) => {
      dispatch(userGetData(onSuccess, onFail));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
