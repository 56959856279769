import React, { Component } from 'react';
import DynamicForm from '../form/DynamicForm';
import formConfig from '../../config/applied_content_tree_add_folder_modal';
import formConfigImages from '../../config/applied_content_tree_add_folder_modal_images';
import resourceFormConfig from '../../config/resouce_tree_add_folder_modal';
import {addFolder, instanceFolder} from '../../actions/folderActions'
import { connect } from "react-redux";
import _ from "lodash";
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils';
import { setProjectForAllChildren } from '../../utils/treeUtils';
import AlertNotification from '../layout/AlertNotification';
import { FOLDER_TYPE_SELECTOR } from '../../shared-global/enums/folder-type-enums';
import {setClientOptions} from '../../reducers/clientOptionsSlice'
import {TREE_MODE_RESOURCE} from "../constants";
import { IFolderType } from '../../shared-global/interfaces/folder-type-interfaces';
import get from 'lodash/get';
import LayoutConstants, { getTreeListHeight } from "../layout/LayoutConstants";
import { IClientOptions } from '../../reducers/clientOptionsSlice';
const BOTTOM_SPACE = 80;

const inputs = {
  name: {val: '', rules: [{ name: "isRequired" }, {name: 'isString'}], isValid: false, errors: []}
}

export interface IAddFolderProps {
  dragDropTreeRef: any,
  mode: string,
  setClientOptions: any,
  instanceFolder: any,
  addFolder: any,
  onOkay: Function,
  onCancel: Function,
  parentFolder: any,
  folders: any,
  client_options: IClientOptions,
  instancing_folder_select_options: any,
  folder_types: { [key: string]: IFolderType }
  meta: any;
}

interface IAddFolderState {
  inputs?: any,
  fieldValues?: any,
  canSubmit?: boolean,
  instancing?: boolean
}

class AddFolder extends Component <IAddFolderProps, IAddFolderState> {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        ...inputs,
        name: {
          ...inputs.name
        }
      },
      fieldValues: {}
    };

  }

  private _dynamicFormRef = null;
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  onNameInputChange = (ev) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        name: {
          ...inputs.name,
          val: ev.target.value
        }
      },
      canSubmit: true
    });
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter"){
      this.onSave()
    }
  }

  onAddFolderSuccess = (new_folder_id) => {
    if (this.props.mode === TREE_MODE_RESOURCE) {
      this.props.setClientOptions({ resource_tree_selected_id: `folder-${new_folder_id}`, resource_selected_folder: new_folder_id });
    }  else {
      this.props.setClientOptions({ applied_content_tree_selected_id: `folder-${new_folder_id}`, applied_content_selected_folder: new_folder_id, selected_folder_meta: this.props.meta });
    }
  }

  onSave = () => {
    let isValid = this._dynamicFormRef.isValid();
    if (isValid){
      let folderType;
      let fieldValues = this._dynamicFormRef.getFieldValues();
      if (this.props.mode === "resource") {
        folderType = "resource";
      } else {
        folderType = fieldValues.folder_type;
      }
      if (folderType === 'instance_folder') { //TODO: is okay with all the missing params?
        this.props.instanceFolder({
          parent_folder_id: this.props.parentFolder.id,
          instanced_folder_ids: [fieldValues.instance_folder]
        });
      } else {
        this.props.addFolder(fieldValues.name, this.props.parentFolder.id, folderType, this.props.mode, this.props.client_options.selected_folder_meta.path, this.onAddFolderSuccess, (message) => this.onError(message))
      }
      this.props.onOkay(fieldValues);
    }

    this.setState({
      inputs: inputs,
      fieldValues: {}
    });
  }

  onCancel = () => {
    this.setState({
      fieldValues: {},
      instancing: false
    });
    this.props.onCancel();
  }

  onError = (message) => {
    AlertNotification('error', 'Error', message || "Something went wrong.")
  }

  onSelectChange(name, value, fieldValues) {
    this.setState({
      instancing: (value === 'instance_folder'),
      fieldValues: fieldValues
    });
  }

  render() {
    let config;
    let useControlPanel = true;
    let presentationType;

    const vHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
  
    let heightForScroll: number = Math.max(
      vHeight - LayoutConstants().dashboard.tree_v_offset,
      LayoutConstants().dashboard.tree_height_min
    );
;
    heightForScroll -= BOTTOM_SPACE;
  
    if (this.props.mode === "resource") {
      config = { ...resourceFormConfig }
    } else {
      config = _.cloneDeep(formConfig);

      const selectedFolder = this.props.parentFolder;
      if (selectedFolder) {
        let rootFolder;
        switch (selectedFolder.folder_type) {
          case /marin/.test(selectedFolder.folder_type) && selectedFolder.folder_type:
            rootFolder = _.find(this.props.folders, { folder_type: 'marin_root' });
            break;
          case /monterrey/.test(selectedFolder.folder_type) && selectedFolder.folder_type:
            rootFolder = _.find(this.props.folders, { folder_type: 'monterrey_root' });
            break;
        }

        switch (selectedFolder.folder_type) {
          case 'monterrey_presentations':
            presentationType = 'monterrey_presentations';
            break;
          case 'marin_presentations':
            presentationType = 'marin_presentations';
            break;
        }

        useControlPanel = rootFolder?.fields?.use_control_panel ?? true;

        const folderType = this.props.folder_types[selectedFolder.folder_type];
        const folderTypeSelectorSettings = folderType.folder_type_selector;

        if (folderTypeSelectorSettings && folderTypeSelectorSettings.element_type !== FOLDER_TYPE_SELECTOR.select) {
          config = _.cloneDeep(formConfigImages);
          config.fields.folder_type.settings = folderTypeSelectorSettings;
        }

        const rejectedValues = [
          'marin_content_attract_loops',
          'marin_presentation_1x1',
          'monterrey_content_attract_loops',
          'monterrey_presentation_1x1'
        ];

        const disabledAcceptableFolderTypes = get(selectedFolder, 'contents.disabled_acceptable_folder_types', []);
        const acceptableFolderTypes = get(folderType, 'acceptable_folder_types', []) as string[];

        config.fields.folder_type.options = acceptableFolderTypes
          .map(folderTypeName => this.props.folder_types[folderTypeName])
          .filter(folderType => {
            if (!folderType.display_name) return false;
            if (disabledAcceptableFolderTypes.some(f => f === folderType.name)) return false;
            if (useControlPanel && rejectedValues.includes(folderType.name)) return false;
            return true;
          })
          .map(folderType => ({
            title: folderType.display_name,
            value: folderType.name,
            url: folderType.folder_type_selector?.image_url,
          }))
      }
    }

    if (this.state.instancing) {
      delete(config.fields.name);
      config.fields.instance_folder = {
        type: 'searchable_select',
        title: "Folder to link",
        validation: {
          rules: [
            { name: 'isRequired' }
          ]
        },
        options: this.props.instancing_folder_select_options
      };
    }

    let new_field_values = {}

    let autoSelectOptionFolder;

    if(presentationType === 'marin_presentations' && !useControlPanel) {
      autoSelectOptionFolder = {name: '', folder_type: 'marin_presentation_5x1'};
    } else if(presentationType === 'monterrey_presentations' && !useControlPanel) {
      autoSelectOptionFolder = {name: '', folder_type: 'monterrey_presentation_5x1'}
    }

    const select_type_keys = Object.keys(config.fields).filter(key => config.fields[key].type === "select"
      && config.fields[key].options
      && config.fields[key].options.length === 1);
    if (select_type_keys.length > 0) {
      select_type_keys.forEach(key => new_field_values[key] = config.fields[key].options[0].value)
      new_field_values = {...new_field_values, ...this.state.fieldValues}
    }

    return (
      <div className="add_folder_form">
        <DynamicForm
          ref={ref => this._dynamicFormRef = ref}
          fieldGroupsConfig={config.field_groups}
          fieldsConfig={config.fields}
          fieldValues={autoSelectOptionFolder ? autoSelectOptionFolder : select_type_keys.length > 0 ? new_field_values : this.state.fieldValues}
          mode={'add'}
          onSubmit={this.onSave}
          onCancel={this.onCancel}
          onError={this.onError}
          showSubmitButton={true}
          showCancelButton={true}
          enableFieldGrouping={false}
          name={"Add Item to " + this.props.parentFolder.name + " folder"}
          canUpdate={true}
          submitting={false}
          onChangeHandlers={[{name: "folder_type", callBackFunction: (name, value, fieldValues) => this.onSelectChange(name, value, fieldValues) }]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let possibleInstanceFolders = ownProps.instancing_folder_select_options || [];
  const folderType = state.data?.folder_types[ownProps.parentFolder.folder_type];
  if (state.data.folder__folders && state.data.folder_types && state.data.folders && ownProps.acceptableFolderTypes && folderType?.instancing_options) {
    let array = arrayFromKeyedObject(state.data.folder__folders);
    let with_project = array.filter(x => x.project !== null);
    if (with_project.length !== array.length || possibleInstanceFolders.length === 0) {
      setProjectForAllChildren(array);
      let foldersOfSameType = arrayFromKeyedObject(state.data.folders).filter(x =>ownProps.acceptableFolderTypes.map(y => y.value).includes(x.folder_type));
      if (folderType.instancing_options.hasOwnProperty('cross_project') && !folderType.instancing_options.cross_project) {
        let projectId = array.find(x => x.child_folder === ownProps.parentFolder.id).project;
        foldersOfSameType.forEach(element => {
          let possibleFolderFolder = array.find(x => x.child_folder === element.id);
          if (possibleFolderFolder) {
            element.project = possibleFolderFolder.project;
          }
        });
        foldersOfSameType = foldersOfSameType.filter(x => x.project === projectId);
      }
      possibleInstanceFolders = foldersOfSameType.map(x => {
        return {title: x.name, value: x.id};
      });
      possibleInstanceFolders.sort((a, b) => (a.title > b.title) ? 1 : -1);
    }
  }
  return {
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    folder_types: state.data.folder_types,
    client_options: state.client_options,
    mode: state.client_options.treemode,
    instancing_folder_select_options: possibleInstanceFolders,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addFolder: (folder_name, parent_folder__folder_id, type, tree_type, client_options, onSuccess, onFail) => {
    dispatch(addFolder(folder_name, parent_folder__folder_id, type, tree_type, client_options, onSuccess, onFail));
  },
  instanceFolder: (payload, onSuccess, onFail) => {
    dispatch(instanceFolder(payload, onSuccess, onFail));
  },
  setClientOptions: (client_options) => {
    dispatch(setClientOptions(client_options))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFolder);
