import React from 'react'
import get from "lodash/get";
import Slide1911 from "../../standard/Slide1911";

const EverettStory = (props) => {
  const containerWidth = props.containerWidth / 2;

  return (
    <div
      style={{
        position: "absolute",
        height: props.containerHeight,
        width: containerWidth
      }}
    >
      <Slide1911
        {...props}
        handleEndOfPlay={null}
        containerHeight={props.containerHeight}
        containerWidth={containerWidth}
        data={{
          ...props.data,
          slotNames: ["image"],
          muted: false,
          base_folder: get(props.baseFolder, "id", null),
          backgroundSize: "cover",
        }}
      />
    </div>
  )
}


export default EverettStory
