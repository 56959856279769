import _ from "lodash";
import { normalize, schema } from "normalizr";
import { all, call, put } from "redux-saga/effects";
import request from "../utils/api/ServerRequest";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import Request from "../utils/api/request";
import { HTTP_METHODS } from "../utils/api/methods";
import {
  createProjectValidationSchema,
  deleteProjectValidationSchema,
  downloadCsvValidationSchema,
  getAllNotifiableUsersValidationSchema,
  getProjectsValidationSchema,
  getProjectValidationSchema,
  updateProjectSettingsValidationSchema,
  updateProjectValidationSchema,
} from "../shared-global/validations/ProjectValidation";
import { addError, addExceptionError } from "../actions/notificationActions";

const projectSchema = new schema.Entity("projects");
const folderSchema = new schema.Entity("folders");
const folder__folderSchema = new schema.Entity("folder__folders");

async function getProjectsAPI(payload) {
  return Request(
    "/project/all_projects",
    HTTP_METHODS.POST,
    { ...payload },
    getProjectsValidationSchema
  );
}

async function updateProjectAPI(payload) {
  return Request(
    "/project/update_project",
    HTTP_METHODS.POST,
    { ...payload },
    updateProjectValidationSchema
  );
}

async function updateProjectSettingsAPI(payload) {
  return Request(
    "/project/update_project_settings",
    HTTP_METHODS.PUT,
    { ...payload },
    updateProjectSettingsValidationSchema
  );
}

async function getProjectAPI(payload) {
  return Request(
    "/project/get_project",
    HTTP_METHODS.GET,
    { ...payload },
    getProjectValidationSchema
  );
}

async function addProjectAPI(payload) {
  return Request(
    "/project/create",
    HTTP_METHODS.GET,
    { ...payload },
    createProjectValidationSchema
  );
}

async function downloadProjectCsvAPI(payload) {
  return Request(
    "/project/download_csv",
    HTTP_METHODS.GET_FILE,
    { ...payload },
    downloadCsvValidationSchema,
    {
      responseType: "blob",
    }
  );
}

async function uploadProjectCsvAPI(payload) {
  try {
    let formData = new FormData();
    formData.append("project", payload.value.project);
    formData.append("removeAll", payload.value.removeAll);
    formData.append(payload.value.fileFieldName, payload.value.file);
    const response = await request.post("/project/upload_csv", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        if (payload.request && payload.request.onProgress) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          payload.request.onProgress(percentCompleted);
        }
      },
      timeout: 150000,
    });
    return response;
  } catch (e) {
    return e;
  }
}

async function getAllNotifiableUsersAPI(payload) {
  return Request(
    "/project/get_all_notifiable_users",
    HTTP_METHODS.GET,
    { ...payload },
    getAllNotifiableUsersValidationSchema
  );
}

async function deleteProjectAPI(payload) {
  return Request(
    "/project/delete_project",
    HTTP_METHODS.DELETE,
    { ...payload },
    deleteProjectValidationSchema
  );
}

const projectSaga = {
  getProjects: function* (action) {
    try {
      const response = yield call(getProjectsAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_PROJECTS_SUCCESS",
          normalized: normalize(response.data.projects, [projectSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_PROJECTS_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail(
            response.data ? response.data.detail : "Unexpected error ocurred"
          );
        }
      }
    } catch (ex) {
      yield put({ type: "GET_PROJECTS_FAIL" });
      yield put(addExceptionError(ex));
      if (action.onFail) {
        action.onFail(ex.message);
      }
    }
  },

  addProject: function* (action) {
    try {
      const response = yield call(addProjectAPI, action.payload);
      if (isResponseSuccess(response)) {
        let projectNormalized = normalize(response.data.project, projectSchema);
        let folderNormalized = normalize(response.data.folder, folderSchema);
        let folder__folderNormalized = normalize(
          response.data.folder__folder,
          folder__folderSchema
        );

        const normalized = {
          entities: {
            ...projectNormalized.entities,
            ...folderNormalized.entities,
            ...folder__folderNormalized.entities,
          },
        };

        yield put({
          type: "ADD_PROJECT_SUCCESS",
          normalized: normalized,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "ADD_PROJECT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "ADD_PROJECT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getProject: function* (action) {
    try {
      const response = yield call(getProjectAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_PROJECT_SUCCESS",
          normalized: normalize(response.data.project, projectSchema),
        });
        //  ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_PROJECT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_PROJECT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateProject: function* (action) {
    try {
      const response = yield call(updateProjectAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield all([
          put({
            type: "UPDATE_PROJECT_SUCCESS",
            normalized: normalize(response.data.project, projectSchema),
          }),
          put({
            type: "UPDATE_USER__PROJECT_NOTIFIES_SUCCESS",
            overrides: [
              {
                key: "user__project_notifies",
                value: response.data.user__project_notifies,
              },
            ],
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "UPDATE_PROJECT_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "UPDATE_PROJECT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateProjectSettings: function* (action) {
    try {
      const response = yield call(updateProjectSettingsAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "PROJECT_UPDATE_SETTINGS_SUCCESS",
          normalized: normalize(response.data.project, [projectSchema]),
        });
        if (action.onSuccess) {
          action.onSuccess(action.payload.type, action.payload.action);
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  downloadProjectCsv: function* (action) {
    try {
      const response = yield call(downloadProjectCsvAPI, action.payload);
      if (isResponseSuccess(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = "project.csv";
        let groups;
        if (response.headers && response.headers["content-disposition"]) {
          const regex = new RegExp('.+filename="(.+)"');
          groups = response.headers["content-disposition"].match(regex);
          if (groups) {
            filename = groups[1];
          }
        }
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DOWNLOAD_CSV_FAIL" });
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "DOWNLOAD_CSV_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  uploadProjectCsv: function* (action) {
    let error;
    try {
      const response = yield call(uploadProjectCsvAPI, action.payload);
      if (isResponseSuccess(response)) {
        let removeKeys = [];
        if (
          response.data.removed_folder__folders &&
          response.data.removed_folder__folders.length > 0
        ) {
          removeKeys.push({
            entities: "folder__folders",
            ids: response.data.removed_folder__folders,
          });
        }
        yield all([
          put({
            type: "ADD_CONTENT_FOLDER_SUCCESS",
            normalized: normalize(response.data.folders, [folderSchema]),
          }),
          put({
            type: "ADD_CONTENT_FOLDER__FOLDER_SUCCESS",
            normalized: normalize(response.data.folder__folders, [
              folder__folderSchema,
            ]),
          }),
        ]);
        if (removeKeys.length > 0) {
          yield put({
            type: "REMOVE_KEYS_SUCCESS",
            removeKeys: removeKeys,
          });
        }

        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        error = _.get(response, "errors", []);
        yield put(addError(response.errors));
        yield put({ type: "UPLOAD_CSV_FAIL" });
        if (action.onFail) {
          action.onFail(error);
        }
      }
    } catch (e) {
      yield put({ type: "UPLOAD_CSV_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },

  get_all_notifiable_users: function* (action) {
    try {
      const response = yield call(getAllNotifiableUsersAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({
          type: "GET_ALL_NOTIFIABLE_USERS_SUCCESS",
          overrides: [
            {
              key: "user__project_notifies",
              value: response.data.user__project_notifies,
            },
          ],
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "GET_ALL_NOTIFIABLE_USERS_FAIL" });
        yield put(addError(response.errors));
        action.onFail();
      }
    } catch (e) {
      yield put({ type: "GET_ALL_NOTIFIABLE_USERS_FAIL" });
      yield put(addExceptionError(e));
      action.onFail();
    }
  },

  delete: function* (action) {
    try {
      const removeKeys = [];
      const response = yield call(deleteProjectAPI, action.payload);
      if (isResponseSuccess(response)) {
        if (response.data.removed_folder__folders) {
          removeKeys.push({
            entities: "folder__folders",
            ids: response.data.removed_folder__folders,
          });
        }
        if (response.data.removed_folders) {
          removeKeys.push({
            entities: "folders",
            ids: response.data.removed_folders,
          });
        }
        if (response.data.removed_resource__folders) {
          removeKeys.push({
            entities: "resource__folders",
            ids: response.data.removed_resource__folders,
          });
          removeKeys.push({
            entities: "resourcetree_resource__folders",
            ids: response.data.removed_resource__folders,
          });
        }
        if (response.data.removed_resources) {
          removeKeys.push({
            entities: "resources",
            ids: response.data.removed_resource__folders,
          });
        }
        if (response.data.removed_projects) {
          removeKeys.push({
            entities: "projects",
            ids: response.data.removed_projects,
          });
        }
        yield put({
          type: "DELETE_PROJECT_SUCCESS",
          removeKeys,
        });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put({ type: "DELETE_PROJECT_FAIL" });
        yield put(addError(response.errors));
        action.onFail();
      }
    } catch (e) {
      yield put({ type: "DELETE_PROJECT_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },
};

export default projectSaga;
