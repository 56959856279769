import get from "lodash/get";
import React, {useCallback} from "react";
import { createUseStyles } from "react-jss";
import { generateFontSizeClassesFromFields, getFieldValue } from "../../../utils/generalUtils";
import CycleWrapper from "../../standard/CycleWrapper";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import MonterreyFooter from "./MonterreyFooter";
import MediaCarousel from "../../standard/MediaCarousel";
import {getResourceFoldersOfFileDynamic} from "../../../utils/fileUtils";

const MonterreyProfileWithMediaStory = (props) => {
  const getStyles = () => {
    const folderType = props.data.folder_types.find(
      (f) => f.name === props.baseFolder?.folder_type
    );

    return createUseStyles(
      generateFontSizeClassesFromFields(folderType, props.baseFolder?.fields)
    );
  };

  const useStyles = getStyles();
  const classes = useStyles();

  const header = getFieldValue(props.baseFolder, 'header', props.baseFolderType);
  const body = getFieldValue(props.baseFolder, 'body', props.baseFolderType);
  const backgroundColor = getFieldValue(props.baseFolder, "background_color", props.baseFolderType);

  const getResourceFoldersOfFileDynamicCallback = useCallback(() => {
    return getResourceFoldersOfFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["story_image"],
      props.data.base_folder
    );
  }, [
    props.aws,
    props.aws_bucket,
    props.data.resources,
    props.data.resource__folders,
    props.data.base_folder
  ]);

  const resourceFoldersCarousel = getResourceFoldersOfFileDynamicCallback();

  return (
    <div
      className="wrapper"
      style={{
        backgroundColor,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      {
        !resourceFoldersCarousel.length && (
          <CycleWrapper {...props}/>
        )
      }
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div
          className="section-padding"
          style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            marginBottom: '2em',
            padding: '7.5em 7.5em 0 7.5em',
            flexDirection: 'column',
          }}
        >
          <MediaCarousel
            style={{
              marginBottom: '4em',
            }}
            {...props}
            componentSpecificData={{
              customStyleImage: {
                maxHeight: '40em',
                width: props.refWidth - (7.5 * 2 * props.fontSize),
                backgroundPosition: "left bottom"
              },
              customStyleVideo: {
                hideOffset: true,
                maxHeight: '40em',
                position: 'relative',
                width: props.refWidth - (7.5 * 2 * props.fontSize)
              },
              slickConfiguration: {
                fade: true,
                speed: 2000
              }
            }}
            resourceFoldersCarousel={resourceFoldersCarousel}
            width="100%"
            height="40em"
            imageWidth={props.refWidth - (7.5 * 2 * props.fontSize)}
            slotNames={["story_image"]}
            dotsStyles={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: props.refWidth - (7.5 * 2 * props.fontSize),
              marginTop: '2em'
            }}
          />
          <div
            className={classes.componentContainer}
            style={{
              display: 'flex',
              overflow: 'hidden',
              width: '100%',
              marginBottom: '2em',
              flexDirection: 'column',
            }}
          >
            <RichTextRender
              style={{
                lineHeight: '1.15',
                padding: 0,
                marginBottom: '2.5em'
              }}
              htmlString={header}
            />

            <RichTextRender
              style={{
                lineHeight: '1.15',
                padding: 0,
              }}
              htmlString={body}
            />
          </div>
        </div>
        <div className="interactiveFooter">
          <MonterreyFooter
            {...props}
            classes={classes}
          />
        </div>
      </div>
    </div>
  )
}

export default MonterreyProfileWithMediaStory
