import get from 'lodash/get';
import React, { CSSProperties } from 'react';
import { useFolder } from '../../../hooks/useFolder';
import { useScale } from '../../../hooks/useScale';
import RichTextRender from '../../standard/RichTextRender';
import Slide1911 from '../../standard/Slide1911';
import PhoenixQrWidget from "./PhoenixQrWidget";

const PhoenixStory = (props) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } = useFolder(props.data.base_folder);
  const { getBaseFolderFontSize, getBaseFolderRichTextStylesFn } = useScale(props.data.base_folder);

  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();
  const baseFolderFields = baseFolder.fields

  const fontSize = getBaseFolderFontSize();
  const useStyles = getBaseFolderRichTextStylesFn();
  const classes = useStyles();

  const backgroundColorOverlayStory = getBaseFolderFieldValue("story_color");
  const backgroundColorOverlay = getBaseFolderFieldValue("background_color_overlay");
  const leftBar = getBaseFolderFieldValue("left_bar");
  const leftBarSize = getBaseFolderFieldValue("left_bar_size");
  const leftBarColor = getBaseFolderFieldValue("left_bar_color");
  const rightBar = getBaseFolderFieldValue("right_bar");
  const rightBarSize = getBaseFolderFieldValue("right_bar_size");
  const rightBarColor = getBaseFolderFieldValue("right_bar_color");
  const horizontalRule = getBaseFolderFieldValue("horizontal_rule");
  const horizontalRuleSize = getBaseFolderFieldValue("horizontal_rule_size");
  const horizontalRuleColor = getBaseFolderFieldValue("horizontal_rule_color");
  const verticalJustification = getBaseFolderFieldValue("vertical_justification");
  const mirrorContent = getBaseFolderFieldValue("mirror_content");
  const qrPosition = getBaseFolderFieldValue("qr_position", 'left');
  const header = getBaseFolderFieldValue("story_header");
  const subhead = getBaseFolderFieldValue("story_subhead");
  const body = getBaseFolderFieldValue("story_body");

  const singleScreenHeight = props.containerHeight / 3;

  const getTextStoryStyle = (): CSSProperties => {
    let style = {
      width: '100%',
      height: '100%',
      backgroundColor: backgroundColorOverlayStory,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      display: 'flex',
      paddingLeft: fontSize * 9,
      paddingRight: fontSize * 9,
      paddingBottom: fontSize * 9,
      paddingTop: fontSize * 9,
      position: 'relative'
    } as CSSProperties

    switch (verticalJustification) {
      case "middle":
        return {
          ...style,
          paddingTop: singleScreenHeight + (fontSize * 9)
        }
      case "bottom":
        return {
          ...style,
          paddingTop: (singleScreenHeight * 2) + (fontSize * 9)
        }
      case "top":
      default:
        return style
    }

  }

  return (
    <div
      style={{
        position: 'absolute',
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          padding: fontSize * 6
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["background_image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            videoIsShorterThanCycleTime: true,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColorOverlay
        }}
      />
      <div
        style={{
          width: props.containerWidth,
          height: props.containerHeight,
          display: 'flex',
          flexDirection: mirrorContent ? 'row-reverse' : 'row'
        }}
      >
        <div
          style={{
            width: props.containerWidth / 3,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}
        >
          {
            leftBar && (
              <div
                style={{
                  zIndex: 3,
                  width: props.containerWidth * (leftBarSize / 100),
                  height: '100%',
                  backgroundColor: leftBarColor
                }}
              />
            )
          }
          <div
            style={getTextStoryStyle()}
          >
            <div className={classes.componentContainer}>
              <RichTextRender htmlString={header} />
            </div>
            <div className={classes.componentContainer} style={{
              marginTop: fontSize,
            }}>
              <RichTextRender htmlString={subhead} />
            </div>
            {
              horizontalRule && (
                <div
                  style={{
                    width: '100%',
                    maxHeight: props.containerHeight * (horizontalRuleSize / 100),
                    minHeight: props.containerHeight * (horizontalRuleSize / 100),
                    marginTop: fontSize * 1.5,
                    backgroundColor: horizontalRuleColor
                  }}
                />
              )
            }
            <div
              style={{
                marginTop: fontSize * 4.75,
              }}
              className={classes.componentContainer}
            >
              <RichTextRender htmlString={body} />
            </div>
          </div>
          {
            rightBar && (
              <div
                style={{
                  zIndex: 3,
                  width: props.containerWidth * (rightBarSize / 100),
                  height: '100%',
                  backgroundColor: rightBarColor
                }}
              />
            )
          }
        </div>
        <div
          style={{
            position: 'absolute',
            width: props.containerWidth - (props.containerWidth / 3),
            height: props.containerHeight,
            left: mirrorContent ? 0 : props.containerWidth / 3
          }}
        >
          <Slide1911
            {...props}
            handleEndOfPlay={null}
            containerHeight={props.containerHeight}
            containerWidth={props.containerWidth - (props.containerWidth / 3)}
            data={{
              ...props.data,
              muted: false,
              slotNames: ["story_background_image"],
              base_folder: get(baseFolder, "id", null),
              backgroundSize: "contain",
              videoIsShorterThanCycleTime: true,
            }}
          />
        </div>
      </div>
      <PhoenixQrWidget
        {...props}
        baseFolderFields={baseFolderFields}
        qrPosition={qrPosition}
        baseFolderType={baseFolderType}
      />
    </div>
  )
};

export default PhoenixStory;
