import { FOLDER_TYPE } from '../enums/folder-type-enums'
export const templates = {
    brighton: {
        attr_name: 'brighton',
        root_folder: FOLDER_TYPE.brighton_root,
        display_name: 'Brighton'
    },
    fremont: {
        attr_name: 'fremont',
        root_folder: FOLDER_TYPE.fremont_root,
        display_name: 'Fremont'
    },
    jasper: {
        attr_name: 'jasper',
        root_folder: FOLDER_TYPE.jasper_root,
        display_name: 'Jasper'
    },
    los_angeles: {
        attr_name: 'los_angeles',
        root_folder: FOLDER_TYPE.los_angeles_root,
        display_name: 'Los Angeles'
    },
    memphis: {
        attr_name: 'memphis',
        root_folder: FOLDER_TYPE.memphis_root,
        display_name: 'Memphis'
    },
    nashville: {
        attr_name: 'nashville',
        root_folder: FOLDER_TYPE.nashville_root,
        display_name: 'Nashville'
    },
    new_orleans: {
        attr_name: 'new_orleans',
        root_folder: FOLDER_TYPE.new_orleans_root,
        display_name: 'New Orleans'
    },
    springfield: {
        attr_name: 'springfield',
        root_folder: FOLDER_TYPE.springfield_root,
        display_name: 'Springfield'
    },
    springfield_portrait: {
        attr_name: 'springfield_portrait',
        root_folder: FOLDER_TYPE.springfield_portrait_root,
        display_name: 'Springfield Portrait'
    },
    toledo : {
        attr_name: 'toledo',
        root_folder: FOLDER_TYPE.toledo_root,
        display_name: "Toledo"
    },
    walterville: {
        attr_name: 'walterville',
        root_folder: FOLDER_TYPE.walterville_root,
        display_name: 'Walterville'
    },
    boston: {
        attr_name: 'boston',
        root_folder: FOLDER_TYPE.boston_root,
        display_name: 'Boston'
    },
    eugene: {
        attr_name: 'eugene',
        root_folder: FOLDER_TYPE.eugene_root,
        display_name: 'Eugene'
    },
    coburg: {
        attr_name: 'coburg',
        root_folder: FOLDER_TYPE.coburg_root,
        display_name: 'Coburg'
    },
    naples: {
        attr_name: 'naples',
        root_folder: FOLDER_TYPE.naples_root,
        display_name: 'Naples'
    },
    mckenzie: {
        attr_name: 'mckenzie',
        root_folder: FOLDER_TYPE.mckenzie_root,
        display_name: 'McKenzie'
    },
    everett: {
        attr_name: 'everett',
        root_folder: FOLDER_TYPE.everett_root,
        display_name: 'Everett'
    },
    monterrey: {
        attr_name: 'monterrey',
        root_folder: FOLDER_TYPE.monterrey_root,
        display_name: 'Monterrey'
    },
    marin: {
        attr_name: 'marin',
        root_folder: FOLDER_TYPE.marin_root,
        display_name: 'Marin'
    },
    phoenix: {
        attr_name: 'phoenix',
        root_folder: FOLDER_TYPE.phoenix_root,
        display_name: 'Phoenix'
    },
    glenwood: {
        attr_name: 'glenwood',
        root_folder: FOLDER_TYPE.glenwood_root,
        display_name: 'Glenwood'
    },
    berkeley: {
        attr_name: 'berkeley',
        root_folder: FOLDER_TYPE.berkeley_root,
        display_name: 'Berkeley'
    },
    alpine: {
        attr_name: 'alpine',
        root_folder: FOLDER_TYPE.alpine_root,
        display_name: 'Alpine'
    },
    tangent: {
        attr_name: 'tangent',
        root_folder: FOLDER_TYPE.tangent_root,
        display_name: 'Tangent'
    },
}
