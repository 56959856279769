import { tree_icon_types } from "./tree_icon_types";
import { FOLDER_CATEGORY, FOLDER_TYPE } from "../../../enums/folder-type-enums";
import { IFolderType } from "../../../interfaces/folder-type-interfaces";

export const root: IFolderType = {
  folder_category: FOLDER_CATEGORY.ROOT,
  name: FOLDER_TYPE.project,
  display_name: "Project",
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: false
  }
};

export default {
  root,
  grouping_folder: {
    folder_category: FOLDER_CATEGORY.GROUP,
    name: FOLDER_TYPE.group,
    display_name: "Group",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  donor_list: {
    folder_category: FOLDER_CATEGORY.DONOR_LIST,
    name: FOLDER_TYPE.donor_list,
    display_name: "Donor List",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  stories: {
    folder_category: FOLDER_CATEGORY.STORIES,
    name: FOLDER_TYPE.stories,
    display_name: "Stories",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  donor: {
    folder_category: FOLDER_CATEGORY.DONOR,
    name: FOLDER_TYPE.donor,
    display_name: "Donor",
    acceptable_folder_types: [],
    tree_icon_types: {
      open: {
        ...tree_icon_types.donor_open
      },
      closed: {
        ...tree_icon_types.donor_closed
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  story: {
    folder_category: FOLDER_CATEGORY.STORY,
    name: FOLDER_TYPE.story,
    display_name: "Story",
    acceptable_folder_types: [],
    tree_icon_types: {
      open: {
        ...tree_icon_types.story
      },
      closed: {
        ...tree_icon_types.story
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  timeline: {
    folder_category: FOLDER_CATEGORY.TIMELINE,
    name: FOLDER_TYPE.timeline,
    display_name: "Timeline",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  event: {
    folder_category: FOLDER_CATEGORY.EVENT,
    name: FOLDER_TYPE.event,
    display_name: "Event",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  resource_root: {
    folder_category: FOLDER_CATEGORY.RESOURCE_ROOT,
    name: FOLDER_TYPE.resource_root,
    display_name: "Resource Root",
    acceptable_folder_types: [FOLDER_TYPE.resource, FOLDER_TYPE.resource_default],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  resource_default: {
    folder_category: FOLDER_CATEGORY.RESOURCE,
    name: FOLDER_TYPE.resource_default,
    display_name: "Default",
    acceptable_folder_types: [FOLDER_TYPE.resource],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  },
  resource: {
    folder_category: FOLDER_CATEGORY.RESOURCE,
    name: FOLDER_TYPE.resource,
    display_name: "Resource",
    acceptable_folder_types: [FOLDER_TYPE.resource],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    list_view: {
      enabled: false
    }
  }
};
