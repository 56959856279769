import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className={this.props.themeClass}>

            </div>
        )
    }
}

export default Footer
