import _, { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import { generateFontSizeClassesFromFields, getFieldValue } from "../../../utils/generalUtils";
import DonorList2107 from "../../standard/DonorList2107";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import { getFontSizeInEm } from "../../../utils/screenUtils";

const fontColorDefault = "#000000";

const SpringfieldPortraitDonorList = (props: IDisplayComponentProps) => {
  const [donorListHeight, setDonorListHeight] = useState(63.0952380952380952381);
  const [categoryDivElement, setCategoryDivElement] = useState(null);  
  const [donorListDivElement, setDonorListDivElement] = useState(null);  

  const { data } = props;  
  
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);
  
  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);
  
  const descriptionTop = _.get(baseFolder, "fields.description_top", 100);
  const backgroundColor = _.get(
    baseFolder,
    "fields.color_overlay",
    "transparent"
  );

  const getUnitBasedOnHeight = (mesure: any) => {
    return (mesure / 1920) * props.containerHeight;
  };

  const getWorkingSpaceHeight = () => {
    return props.containerHeight - getUnitBasedOnHeight(72 * 2);
  };

  const standardSizing = (item: number) => {
    return (item / 100) * props.containerHeight;
  }

  useEffect(() => {
    const divListHeight = donorListDivElement?.getBoundingClientRect().height;
    const containerMarginTop = get(
      baseFolder,
      "fields.container_margin_top",
      0
    );
    const effectiveDonorListHeight = divListHeight - (divListHeight / 100 * containerMarginTop);
    setDonorListHeight(effectiveDonorListHeight);
  }, [donorListDivElement?.getBoundingClientRect().height]);

  const getField = (field, fallback) => {
    return _.get(baseFolder, "fields." + field, fallback)
  }

  const getStyles = () => {
    return createUseStyles(
      generateFontSizeClassesFromFields(baseFolderType, baseFolder?.fields)
    );
  };
  const useStyles = getStyles();
  const classes = useStyles();

  const header = getFieldValue(baseFolder, 'header', baseFolderType);
  const category = getFieldValue(baseFolder, 'category', baseFolderType);
  const description = getFieldValue(baseFolder, 'description', baseFolderType);
  const footer = getFieldValue(baseFolder, 'footer', baseFolderType);

  const donorNameFontSize = getFontSizeInEm(get(baseFolder, "fields.list_names.fontSize", 16));

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColor,
          zIndex: 0,
        }}
      />

      <div
        className="spring-container"
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          paddingTop: (72 / 1920) * props.containerHeight,
          paddingBottom: (72 / 1920) * props.containerHeight,
          paddingLeft: (100 / 1080) * props.containerWidth,
          paddingRight: (132 / 1080) * props.containerWidth,
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <div
          className="spring-workspace-container"
          style={{
            height: getWorkingSpaceHeight(),
            color:
              props.inheritable_fields?.donor_list_font_color ||
              fontColorDefault,
            paddingTop: (0 / 1920) * props.containerHeight,
            paddingBottom: (0 / 1920) * props.containerHeight,
            paddingLeft: (0 / 1080) * props.containerWidth,
            paddingRight: (0 / 1080) * props.containerWidth,
            overflowX: "hidden",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <div
            className="spring-header"
            style={{
              width: "100%",
              minHeight: (90 / 1920) * props.containerHeight,
            }}
          >
            {/* Header */}
            <div
              className={classes.componentContainer}
              style={{
                padding: 0,
                minHeight: (56 / 1920) * props.containerHeight,
                fontFamily: 'Lato Bold'
              }}
            >
              <RichTextRender
                htmlString={header}
                style={{
                  padding: 0
                }}
              />
            </div>

            {/* Description */}
            <div
              className="spring-description-container"
              style={{
                minHeight: (30 / 1920) * props.containerHeight,
              }}
            >
              <div
                className={classes.componentContainer}
                style={{
                  marginTop: standardSizing(descriptionTop),
                  marginBottom: .01 * props.containerHeight,
                  padding: 0,
                  whiteSpace: "break-spaces",
                  fontFamily: 'Lato'
                }}
              >
                <RichTextRender
                  htmlString={description}
                  style={{
                    padding: 0
                  }}
                />
              </div>
            </div>
          </div>
          {/* end description */}

          {getField("show_upper_rule", true) && (
            <div className="spring-upper-bar">
              <hr
                style={{
                  backgroundColor: getField("horizontal_rule_color", "#1C1C1C"),
                  border: "0 none",
                  color: getField("horizontal_rule_color", "#1C1C1C"),
                  height: `${Math.ceil((getField("horizontal_rule_size", 0.1) / 100) * props.containerHeight)}px`
                }}
              />
            </div>
          )}

          <div
            className="spring-category-container"
            ref={(divElement) => {
              setCategoryDivElement(divElement);
            }}
          >
            <div
              className={classes.componentContainer}
              style={{
                fontFamily: 'Lato',
                padding: 0,
                marginTop: .01 * props.containerHeight,
              }}
            >
              <RichTextRender
                htmlString={category}
                style={{
                  padding: 0
                }}
              />
            </div>
          </div>

          {/* Donor list container */}
          <div
            ref={(divElement) => {
              setDonorListDivElement(divElement);
            }}
            className="spring-donorlist-container"
            style={{
              color:
                props.inheritable_fields?.donor_list_font_color ||
                fontColorDefault,
              flexGrow: 1,
            }}
          >
            <div
              style={{
                minHeight: "100%",
              }}
            >
              <div
                className="donor-list-container"
                style={{
                  position: "relative",
                }}
              >
                {categoryDivElement && donorListDivElement && (
                  <DonorList2107
                    key={baseFolder?.id}
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    mode={props.mode}
                    refWidth={props.containerWidth}
                    refHeight={props.containerHeight}
                    containerWidth={
                      props.containerWidth * (1 - (232 / 1080))
                    }
                    containerHeight={donorListHeight}
                    handleEndOfPlay={props.handleEndOfPlay}
                    data={{
                      ...data,
                      componentSpecificData: {
                        list_style: get(baseFolder, "fields.donor_list_style", "filled"),
                        columns: get(baseFolder, 'fields.columns', 2),
                        container_margin_top: get(baseFolder, 'fields.container_margin_top', 0),
                        donor_list_margin_left: getField('donor_list_margin_left', 0),
                        donor_list_margin_right: getField('donor_list_margin_right', 0),
                        donor_list_column_margin_right: getField('donor_list_column_margin_right', 0),
                        cycle: get(baseFolder, 'fields.cycle',5 ) * 1000,
                        donor_vertical_separation: get(baseFolder, 'fields.donor_vertical_separation',2.5 ),
                        donor_line_height: get(baseFolder, 'fields.list_names.lineHeight', 1 ),
                        text_alignment: get(baseFolder, 'fields.list_names.alignment', 'left' ),
                        donor_line_height_in_percentage: false,
                        donorNameStyle: {
                          fontFamily: get(baseFolder, "fields.list_names.font", "Lato"),
                          fontSize: donorNameFontSize,
                          color: get(baseFolder, "fields.list_names.color", fontColorDefault),
                          fontWeight: get(baseFolder, "fields.list_names.bold", false) ? "bold" : "normal",
                          textDecoration: get(baseFolder, "fields.list_names.bold", false) ? "underline" : "normal",
                          fontStyle: get(baseFolder, "fields.list_names.italic", false) ? "italic": "normal",
                          textAlign: get(baseFolder, "fields.list_names.alignment", "left"),
                          lineHeight: get(baseFolder, "fields.list_names.lineHeight", 1),
                        }
                      }
                    }}
                    webview_signedurls={props.webview_signedurls}
                  />
                )}
              </div>
            </div>
          </div>
          {getField("show_lower_rule", true) && (
            <div className="spring-bottom-bar">
              <hr
                style={{
                  backgroundColor: getField("horizontal_rule_color", "#1C1C1C"),
                  border: "0 none",
                  color: getField("horizontal_rule_color", "#1C1C1C"),
                  height: `${Math.ceil((getField("horizontal_rule_size", 0.1) / 100) * props.containerHeight)}px`
                }}
              />
            </div>
          )}
          <div
            className="spring-footer"
            style={{
              minHeight: (30 / 1920) * props.containerHeight,
            }}
          >
            <div
              className={classes.componentContainer}
              style={{
                fontSize: (get(baseFolder, 'fields.footer_font_size', .5 ) / 100) * props.containerHeight,
                padding: 0,
                whiteSpace: "break-spaces",
              }}
            >
              <RichTextRender
                htmlString={footer}
                style={{
                  padding: 0
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpringfieldPortraitDonorList;
