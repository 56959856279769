import { FOLDER_TYPE } from "../../shared-global/enums/folder-type-enums";

export const coburgRelevantStoryTypeNames = [
  FOLDER_TYPE.coburg_template_001,
  FOLDER_TYPE.coburg_template_002,
  FOLDER_TYPE.coburg_template_003,
  FOLDER_TYPE.coburg_template_004,
  FOLDER_TYPE.coburg_template_005,
]

export const coburgRelevantSubStoryTypeNames = [
  FOLDER_TYPE.coburg_stories,
]

export const coburgRelevantDonorListMenuTypeNames = [
  FOLDER_TYPE.coburg_donor_list_group,
]

export const coburgRelevantFolderTypeNames = [
  ...coburgRelevantStoryTypeNames,
  ...coburgRelevantSubStoryTypeNames,
  ...coburgRelevantDonorListMenuTypeNames,
  FOLDER_TYPE.coburg_donor,
]