

import React, { Component } from 'react';
// import { withRouter } from 'react-router';

class Test extends Component {

  render() {
      console.log("test is rendering")
    return (
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-2-24 pure-u-lg-8-24" style={{backgroundColor: 'red'}}>Div</div>
        <div className="pure-u-1 pure-u-md-20-24 pure-u-lg-8-24" style={{backgroundColor: 'green'}}>Div</div>
        <div className="pure-u-1 pure-u-md-2-24 pure-u-lg-8-24" style={{backgroundColor: 'red'}}>Div</div>
     </div>
    )
  }

}

export default Test;
