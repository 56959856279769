import get from "lodash/get";
import React, { useEffect, useState } from "react";
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
} from "../../../../shared-global/enums/folder-type-enums";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import {
  getBaseFolder,
  getBaseFolderType,
  getChildrenData,
} from "../../../utils/commonDataUtils";
import { getFontSizeBasedOnPreviewData } from "../../../utils/screenUtils";
import MonterreyFullscreenFactStory from "./MonterreyFullscreenFactStory";
import MonterreyMediaWithQuote from "./MonterreyMediaWithQuote";
import MonterreyProfileWithMediaStory from "./MonterreyProfileWithMediaStory";
import MonterreyStoryWithFact from "./MonterreyStoryWithFact";
import MonterreyStoryWithMedia from "./MonterreyStoryWithMedia";
import MonterreyControlPanelDisplay from "./MonterreyControlPanelDisplay";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";

const Monterrey1x1Container = (props) => {
  const [baseFolder, setBaseFolder] = useState<IFolder>();
  const [baseFolderType, setBaseFolderType] = useState(null);

  let component: any = null;

  useEffect(() => {
    const foundBaseFolder = getBaseFolder(props.data);
    setBaseFolder(foundBaseFolder);
    const myBaseFolderType = getBaseFolderType(
      props.data,
      get(foundBaseFolder, "folder_type", {})
    );
    setBaseFolderType(myBaseFolderType);
  }, [props.data]);

  let fontSize = 16;

  if (baseFolder) {
    const folderTypes = get(props.data, "folder_types", []);
    if (folderTypes && folderTypes.length) {
      const folderType = folderTypes.find(
        (f) => f.name === baseFolder.folder_type
      );
      fontSize = getFontSizeBasedOnPreviewData(
        folderType,
        COMPONENT_NAME.Monterrey1x1Container,
        "unique",
        {
          containerWidth: props.containerWidth,
          containerHeight: props.containerHeight,
        }
      );
    }

    switch (baseFolder.folder_type) {
      case FOLDER_TYPE.monterrey_story_with_media:
        component = (
          <MonterreyStoryWithMedia
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
            fontSize={fontSize}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_profile_with_media_story:
        component = (
          <MonterreyProfileWithMediaStory
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
            fontSize={fontSize}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_story_with_fact:
        component = (
          <MonterreyStoryWithFact
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_story_media_with_quote:
        component = (
          <MonterreyMediaWithQuote
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_fullscreen_fact_story:
        component = (
          <MonterreyFullscreenFactStory
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_story_quote:
        component = (
          <MonterreyMediaWithQuote
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
            isChild={true}
            child={baseFolder}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_story_fact:
        if (props.data.parents) {
          const parentFolder = props.data.parents[0];
          const parentFolderType = getBaseFolderType(
            props.data,
            get(parentFolder, "folder_type", {})
          );

          component =
            parentFolder.folder_type ===
            FOLDER_TYPE.monterrey_story_with_fact ? (
              <MonterreyStoryWithFact
                {...props}
                refHeight={props.containerHeight}
                refWidth={props.containerWidth}
                baseFolder={parentFolder}
                baseFolderType={parentFolderType}
                isChild={true}
                child={baseFolder}
              />
            ) : (
              <MonterreyFullscreenFactStory
                {...props}
                refHeight={props.containerHeight}
                refWidth={props.containerWidth}
                baseFolder={parentFolder}
                baseFolderType={parentFolderType}
                isChild={true}
                child={baseFolder}
              />
            );
        } else {
          return <></>;
        }
        break;
      case FOLDER_TYPE.monterrey_display:
        component = (
          <MonterreyControlPanelDisplay
            {...props}
            refHeight={props.containerHeight}
            refWidth={props.containerWidth}
            fontSize={fontSize}
            baseFolder={baseFolder}
            baseFolderType={baseFolderType}
          />
        );
        break;
      case FOLDER_TYPE.monterrey_control_panel_attract_loop:
        component = <ComponentLoop1911 {...props} />;
        break;
      case FOLDER_TYPE.monterrey_presentation_1x1:
        const { folders: presentationFolders } = getChildrenData(
          props.data,
          props.data.base_folder
        );
        if (presentationFolders && presentationFolders.length) {
          const attractLoop = presentationFolders[0];
          component = (
            <ComponentLoop1911
              {...props}
              data={{ ...props.data, base_folder: attractLoop.id }}
            />
          );
        }
        break;
      default:
        component = <></>;
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
        fontSize,
      }}
    >
      {component}
    </div>
  );
};

export default Monterrey1x1Container;
