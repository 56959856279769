import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../interfaces/main-state.interface";
import {
  getContentLibraryResourceUrl,
  getFileType,
  getUrlResource,
} from "../../../../shared/utils/fileUtils";
import PreviewResource from "../../../preview/PreviewResource";
import {
  IResourceResourceFolder,
  RESOURCE_EXPLORER_MODE,
} from "../resource-explorer.interface";
import useStyles from "./styles";
import ArrowBack from "../../../../icons/arrow-back.svg"

export interface IResourceExplorerPreviewProps {
  width: number;
  height: number;
  resourceResourceFolder: IResourceResourceFolder;
  resourceFolderFoldersQty: number;
  mode: RESOURCE_EXPLORER_MODE;
  setIsPreviewOpen: Function;
  getNextResourceResourceFolder: Function;
  getPreviousResourceResourceFolder: Function;
}

const ResourceExplorerPreview: React.FC<IResourceExplorerPreviewProps> = (
  props
) => {
  const styleProps = {
    width: props.width,
    height: props.height,
    props: props.mode,
  };
  const classes = useStyles({ theme: {}, ...styleProps });
  const { aws_instance, resources } = useSelector(
    (state: IState) => state.data
  );
  const client_options = useSelector((state: IState) => state.client_options);

  const url = useMemo(() => {
    if (props.resourceResourceFolder) {
      const type = getFileType(props.resourceResourceFolder.resource.name);
      let foundUrl = null;
      if (type === "image") {
        foundUrl = getContentLibraryResourceUrl(
          props.resourceResourceFolder.resource__folder,
          aws_instance,
          process.env.REACT_APP_S3_BUCKET,
          "preview"
        ) as string;
      } else {
        foundUrl = getUrlResource(
          props.resourceResourceFolder.resource__folder,
          "aws",
          aws_instance,
          process.env.REACT_APP_S3_BUCKET,
          Object.values(resources),
          null,
          null
        );
      }
      return foundUrl;
    }
  }, [props.resourceResourceFolder]);

  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case "ArrowRight":
        props.getNextResourceResourceFolder();
        break;
      case "ArrowLeft":
        props.getPreviousResourceResourceFolder();
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {/* Preview */}
      <PreviewResource
        key={url}
        transitionClassName="preview-transition-natural"
        containerWidth={props.width}
        containerHeight={props.height}
        resource={{
          ...(props?.resourceResourceFolder?.resource ?? {}),
          url,
        }}
        client_options={client_options}
      />
      {/* Back Button */}
      <div id="back-button">
        <img
          src={ArrowBack}
          alt="Go back"
          onClick={() => props.setIsPreviewOpen(false)}
        />
      </div>
      {props.resourceFolderFoldersQty > 1 && (
        <>
          {/* Previous */}
          <div
            className="arrow-wrapper arrow-wrapper-left"
            onClick={() => props.getPreviousResourceResourceFolder()}
          >
            <div id="arrow-overlay" />
            <div id="previous-button">
              <FontAwesomeIcon
                className="fa-2x"
                icon={["fas", "chevron-left"]}
              />
            </div>
          </div>
          {/* Next */}
          <div
            className="arrow-wrapper arrow-wrapper-right"
            onClick={() => props.getNextResourceResourceFolder()}
          >
            <div id="arrow-overlay" />
            <div id="next-button">
              <FontAwesomeIcon
                className="fa-2x"
                icon={["fas", "chevron-right"]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ResourceExplorerPreview;
