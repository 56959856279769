import get from "lodash/get";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IFolderType } from "../../../../shared-global/interfaces/folder-type-interfaces";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType,
  getChildrenData,
} from "../../../utils/commonDataUtils";
import { getFieldValue } from "../../../utils/generalUtils";
import Slide1911 from "../../standard/Slide1911";
import Marin1x1Container from "./Marin1x1Container";
import MarinDynamicContainer from "./MarinDynamicContainer";

const MarinLayout = (props: IDisplayComponentProps) => {
  const [storiesThatFinishedPlaying, _setStoriesThatFinishedPlaying] = useState<number[]>([]);
  const storiesThatFinishedPlayingRef = useRef(storiesThatFinishedPlaying);
  const setStoriesThatFinishedPlaying = data => {
    storiesThatFinishedPlayingRef.current = data;
    _setStoriesThatFinishedPlaying(data);
  }
  const stories = getChildrenData(props.data, props.data?.base_folder).folders;
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props]);
  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, baseFolder?.folder_type);
  }, [baseFolder]);

  let left = 0;
  let slotsAcc = 0;

  const backgroundImageOverlay = getFieldValue(
    baseFolder,
    "background_image_overlay",
    baseFolderType
  );

  const [didCycle, _setDidCycle] = useState(false);
  const didCycleRef = useRef(didCycle);
  const setDidCycle = data => {
    didCycleRef.current = data;
    _setDidCycle(data);
  }

  const handleEndOfPlay = useCallback((id) => {
    const allIds = [...new Set(stories.map((s) => s.id))];
    const newStoriesThatFinishedPlaying = [...storiesThatFinishedPlayingRef.current, id];
    // If all stories have finished playing, we can call handleEndOfPlay on the parent.
    if (allIds.every((id) => newStoriesThatFinishedPlaying.includes(id))) {
      if (props.handleEndOfPlay) {
        props.handleEndOfPlay();
      }
    } else {
      setStoriesThatFinishedPlaying(newStoriesThatFinishedPlaying);
    }
  }, [storiesThatFinishedPlaying, setStoriesThatFinishedPlaying]);

  const handleKeyDown = useCallback((event) => {
    if (!didCycleRef.current) {
      setDidCycle(true);
    } else {
      return;
    }
    const cycleForwardKey = get(
      props,
      "data.componentSpecificData.cycle_forward_key",
      "ArrowRight"
    );
    const cycleBackwardKey = get(
      props,
      "data.componentSpecificData.cycle_backward_key",
      "ArrowLeft"
    );
    switch (event.key) {
      case cycleForwardKey:
        if (props.handleEndOfPlay) {
          // clearTimeout(cycleTimeoutHandlerRef.current);
          props.handleEndOfPlay('forward', null);
        }
        break;
      case cycleBackwardKey:
        if (props.handleEndOfPlay) {
          // clearTimeout(cycleTimeoutHandlerRef.current);
          props.handleEndOfPlay('backward', null);
        }
        break;
      default:
        break;
    }
  }, [setDidCycle]);

  useEffect(() => {
    // We first need to check if the layout has a donor lists or donor list folder in it
    // if it does we don't bind the keydown event
    const donorListOrLists = stories.filter(s => s.folder_type === FOLDER_TYPE.marin_content_donor_lists || s.folder_type === FOLDER_TYPE.marin_donor_list);
    if (donorListOrLists.length === 0) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      }
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        height: props.containerHeight,
        width: props.containerWidth,
        display: "flex",
        alignItems: "flex-start",
        overflow: "hidden"
      }}
    >
      <Slide1911
        {...props}
        handleEndOfPlay={null}
        data={{
          ...props.data,
          slotNames: ["background_image"],
          componentSpecificData: {
            ...props.data?.componentSpecificData,
            useKeyCycler: false,
          }
        }}
      />

      {backgroundImageOverlay && backgroundImageOverlay !== "initial" && (
        <div
          style={{
            position: "absolute",
            backgroundColor: backgroundImageOverlay,
            height: props.containerHeight,
            width: props.containerWidth,
          }}
        />
      )}

      {stories.map((story, index) => {
        const storyFolderTypeName = story.folder_type;
        const folderTypes: IFolderType[] = get(props, "data.folder_types", []);
        let slots = 1;
        if (folderTypes.length) {
          const storyFolderType = folderTypes.find(
            (f) => f.name === storyFolderTypeName
          );
          slots = storyFolderType?.slots ?? 1;
          left = index === 0 ? 0 : slotsAcc * (props.containerWidth / 8);
          slotsAcc += slots;
        }

        return (
          <div
            key={`story-${index}`}
            style={{
              position: "absolute",
              height: props.containerHeight,
              width: (props.containerWidth / 8) * slots,
              left,
            }}
          >
            {[
              FOLDER_TYPE.marin_media_blank_1,
              FOLDER_TYPE.marin_media_blank_2,
              FOLDER_TYPE.marin_media_blank_3,
              FOLDER_TYPE.marin_media_blank_4,
              FOLDER_TYPE.marin_media_blank_5,
              FOLDER_TYPE.marin_media_blank_6,
              FOLDER_TYPE.marin_media_blank_7,
              FOLDER_TYPE.marin_media_blank_8,
              FOLDER_TYPE.marin_donor_list,
              FOLDER_TYPE.marin_intro_story,
              FOLDER_TYPE.marin_content_donor_lists,
              FOLDER_TYPE.marin_story_media_with_quote_2_1,
              FOLDER_TYPE.marin_story_large_media_with_quote
            ].indexOf(storyFolderTypeName) > -1 && (
              <MarinDynamicContainer
                {...props}
                containerWidth={(props.containerWidth / 8) * slots}
                handleEndOfPlay={() => handleEndOfPlay(story.id)}
                data={{
                  ...props.data,
                  base_folder: story.id,
                  componentSpecificData: {
                    ...props.data?.componentSpecificData,
                    bezels: false,
                  },
                }}
              />
            )}

            {[
              FOLDER_TYPE.marin_story_with_fact,
              FOLDER_TYPE.marin_story_media_with_quote,
              FOLDER_TYPE.marin_fullscreen_fact_story,
              FOLDER_TYPE.marin_profile_with_media_story,
              FOLDER_TYPE.marin_story_with_media,
            ].indexOf(storyFolderTypeName) > -1 && (
              <Marin1x1Container
                {...props}
                handleEndOfPlay={() => handleEndOfPlay(story.id)}
                containerWidth={(props.containerWidth / 8) * slots}
                data={{
                  ...props.data,
                  base_folder: story.id,
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MarinLayout;
