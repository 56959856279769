import React, { useState } from 'react'
import {Modal} from "antd";
import useStyles from "./styles";
import AlertNotification from "../layout/AlertNotification";
import TextInput from "../form/input/TextInput";
import {IFolder} from "../../shared-global/interfaces/models/folder.interface";

interface IConfirmDeleteFolderModalProps {
  onSave: Function;
  visible: boolean;
  closeModal: Function;
  folder?: IFolder;
}

const ConfirmDeleteFolderModal: React.FC<IConfirmDeleteFolderModalProps> = (props) => {

  const classes = useStyles();

  const [confirmationText, setConfirmationText] = useState("")

  const onSave = () => {
    if (confirmationText !== "delete") {
      AlertNotification(
        "error",
        "Error",
        'Please type "delete" into the confirmation text input'
      );
      return
    }
    setConfirmationText("")
    props.onSave()
  }

  const onCancel = () => {
    props.closeModal()
  }

  return (
    <Modal
      visible={props.visible}
      onCancel={onCancel}
      onOk={onSave}
      width={"33rem"}
      destroyOnClose={true}
      className={classes.modal}
    >
      <div>
        <div className={["unsaved-changes-modal_header", classes.modalFontWeight].join(" ")}>
          Delete {props.folder ? props.folder.name : 'Folders'}
        </div>
        <div className="text-center">
          {
            props.folder ?
              'Warning: This folder contains subfolders. Are you sure you want to delete this folder? This action will permanently delete the folder and all its subfolders.' :
              'Warning: One or more folders you are trying to delete have children. Are you sure you want to delete these folders? This action will also permanently remove all subfolders.'
          }
        </div>
        <br />
        <br />
        <b>
            <span>
              Type <i>delete</i> to confirm
            </span>
        </b>
        <TextInput
          fieldName="delete_confirm"
          showLabel={false}
          defaultValue={confirmationText}
          title='Type "delete" to confirm'
          showCharCount={false}
          canUpdate
          onBlur={(_, value) => setConfirmationText(value)}
          onChange={(_, value) => setConfirmationText(value)}
        />
      </div>
    </Modal>
  )
}

export default ConfirmDeleteFolderModal
