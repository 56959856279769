import base_types from "./base_types";
import { tree_icon_types } from "./tree_icon_types";
import { IMAGE_ALIGNMENT, FLEX_CLASSES } from "../../../enums/ui-enums";
import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../enums/folder-type-enums";
import { IFolderType } from "../folders/IFolderType";

export const brighton_types: { [key: string]: IFolderType } = {
  brighton_root: {
    ...base_types.root,
    name: FOLDER_TYPE.brighton_root,
    display_name: "Brighton Root",
    acceptable_folder_types: [FOLDER_TYPE.brighton_slides],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    default_dimensions: {
      x: 0,
      y: 0,
      scale_x: 1080,
      scale_y: 1920
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.client
      },
      closed: {
        ...tree_icon_types.client
      }
    }
  },
  brighton_slides: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.brighton_slides,
    display_name: "Slides",
    acceptable_folder_types: [FOLDER_TYPE.brighton_slide],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    },
    list_view: {
      enabled: true,
      list_folder_types: [FOLDER_TYPE.brighton_slide], //will need to handle missing fields if more then one type
      columns: [
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "list_view_selected",
          title: "",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "name",
          title: "Name",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "file",
          title: "Media",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "cycle_time",
          title: "Cycle Time",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "updatedAt",
          title: "Modified Date",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [FOLDER_TYPE.brighton_slide],
          field: "updatedBy",
          title: "Updated By",
          sort: true,
          filter: true,
          editable: false,
          default_shown: true
        }
      ]
    }
  },
  brighton_slide: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.brighton_slide,
    display_name: "Slide",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time (seconds)",
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] }
      },
      file: {
        title: "Image / Video",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [],
        aspect: 0.5625,
        recommended_size: {
          width: 1080,
          height: 1920
        },
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.5625
        }
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.Slide1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.Slide1911
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.donor_open
      },
      closed: {
        ...tree_icon_types.donor_closed
      }
    }
  }
};
