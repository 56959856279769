import React, { Component } from "react";
import DonorList1911 from "../../standard/DonorList1911";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import _ from "lodash";
import { GREY_HEADER } from './FremontConstants'
class FreemontDonorList001 extends Component {
  _donorListHeight = 63.0952380952380952381;
  _donorListTop = 30.47619047619047619048;
  _categoryNameHeightPercent = 2.52;
  _showCategoryCycleTimeout = null;
  _descriptionHeightParent = 2.38095238095238095238;
  _donorNameHeight = 1.35
  // _donorNameHeight = 2.545454545454545455
  constructor(props) {
    super(props);
    const { containerHeight, containerWidth, data } = this.props;
    const donorList = _.cloneDeep(
      data.folders.find((f) => f.id === data.base_folder)
    );
    const descriptionTop = _.get(
      donorList,
      "fields.description_top",
      7.73809523809523809524
    );

    this._categoryNameHeightPercent = _.get(donorList, 'fields.category_title_font_size', 2.52)
    this._descriptionHeightParent = _.get(donorList, 'fields.category_description_font_size', 2.38095238095238095238)
    this._donorNameHeight = _.get(donorList, 'fields.donor_name_font_size', this._donorNameHeight)

    this.state = {
      donorList,
      showCategory: false,
      categoryNameFontSize:
        (this._categoryNameHeightPercent * containerHeight) / 100,
      descriptionFontSize: (this._descriptionHeightParent * containerHeight) / 100,
      descriptionTop: (descriptionTop / 100) * containerHeight,
      donorListWidth: containerWidth,
      donorListHeight: (this._donorListHeight / 100) * containerHeight,
      donorListTop: (this._donorListTop / 100) * containerHeight,
      width: containerWidth,
      height: containerHeight
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.containerHeight !== this.props.containerHeight ||
      prevProps.containerWidth !== this.props.containerWidth
    ) {
      const { containerWidth, containerHeight } = this.props;
      this.setState({
        categoryNameFontSize:
          (this._categoryNameHeightPercent * containerHeight) / 100,
        donorListWidth: containerWidth,
        donorListHeight: (this._donorListHeight / 100) * containerHeight,
        donorListTop: (this._donorListTop / 100) * containerHeight,
      });
    }
  }

  componentDidMount() {
    this.setState({ showCategory: true });
    const categoryName = _.get(this.state.donorList, "fields.category", "")
    console.log(categoryName);
    if (categoryName === ""){
      this._donorListTop = 27 //30.47619047619047619048;
      this.setState({
        donorListTop: (this._donorListTop / 100) * this.props.containerHeight,
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this._showCategoryCycleTimeout);
  }

  render() {
    const {
      donorListHeight,
      donorListTop,
      donorListWidth,
      donorList,
      categoryNameFontSize,
      descriptionFontSize,
      descriptionTop,
      showCategory,
    } = this.state;
    const {
      aws,
      aws_bucket,
      containerHeight,
      containerWidth,
      mode,
      handleEndOfPlay,
      data,
    } = this.props;

    return (
      <div style={{ color: "#FFFFFF" }}>
        <div
          style={{
            position: "absolute",
            height: donorListTop,
            width: donorListWidth,
            top: 0,
          }}
        >
          <div style={{
            position: 'absolute',
            width: this.state.width,
            left: 0,
            right: 0,
            textAlign: "center",
            top: this.state.height * 0.0249,
            fontSize: this.state.height * 0.0249,
            fontWeight: "bold",
            textTransform: "uppercase",
            fontFamily: "whitney-bold",
            color: GREY_HEADER
          }}>
            {_.get(donorList, "fields.header", "")}
          </div>
          <TransitionGroup>
            <CSSTransition
              key={showCategory}
              timeout={500}
              classNames="transition-fade"
            >
              <div>
                <div
                  style={{
                    fontSize: descriptionFontSize,
                    fontFamily: "whitney-light",
                    position: "absolute",
                    top: descriptionTop,
                    paddingRight: (containerWidth / 100) * 11.64,
                    paddingLeft: (containerWidth / 100) * 11.64,
                  }}
                >
                  {_.get(donorList, "fields.description", null)}
                </div>
                <div>
                  {showCategory ? (
                    <div
                      style={{
                        width: "100%",
                        position: "absolute",
                        textAlign: "center",
                        top: (containerHeight / 100) * 23.80952380952380952381,
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "whitney-bold",
                          fontSize: categoryNameFontSize,
                          letterSpacing: ".15em",
                        }}
                      >
                        {_.get(donorList, "fields.category", "").toUpperCase()}
                      </span>
                      <hr
                        style={{
                          width: "76%",
                          borderTop: "1px solid #FFFFFF",
                          marginTop: 0,
                        }}
                      />
                    </div>
                  ) : (
                    <span />
                  )}
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <div
          style={{
            position: "absolute",
            top: donorListTop,
            height: donorListHeight,
            width: donorListWidth,
            fontFamily: "whitney-regular",
            color: "#FFFFFF",
          }}
        >
          <DonorList1911
            key={donorList.id}
            aws={aws}
            aws_bucket={aws_bucket}
            mode={mode}
            scale="width"
            containerWidth={donorListWidth}
            containerHeight={donorListHeight}
            handleEndOfPlay={handleEndOfPlay}
            data={data}
            overrides={{
              donorFontFamily: "whitney-book",
              donorFontSize: (containerHeight / 100) * this._donorNameHeight,
              fontColor: "#FFFFFF",
            }}
            webview_signedurls={this.props.webview_signedurls}
          />
        </div>
      </div>
    );
  }
}

export default FreemontDonorList001;
