import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISelectedFolderMeta } from "../interfaces/main-state.interface";
import { INPUT_MODE, TREE_TYPE } from "../shared-global/enums/ui-enums";
import { TREE_MODE_APPLIED_CONTENT } from "../components/constants";
import { getIdFromTreeNode } from "../utils/treeUtils";

export interface IClientOptions {
  // folders

  // folder clone
  show_clone_modal?: boolean;
  clone_folder_id?: number;

  // resources
  resource_tree_filters?: string[];
  resource_tree_selected_id?: string;
  resource_selected_folder?: number;
  resource_selected_resource?: number;
  resource_modal_tree_selected_id?: string;
  resource_modal_tree_selected_folder?: number;

  // search
  search_string_main?: string;
  search_string_existing_resource?: string;
  search_string_highlight?: string;

  // progress / loading
  message_for_progress?: string;
  indeterminate?: boolean;
  progress?: {
    percentage: number;
    fileName: string;
    current: number;
    max: number;
  };

  inputMode?: INPUT_MODE;
  applied_content_selected_folder?: number;
  applied_content_tree_selected_id?: string;
  applied_content_tree_modal_selected_id?: string;
  applied_content_modal_selected_folder?: number;
  detail_drawer_open?: boolean;
  tree_panel_open?: boolean;
  treemode?: TREE_TYPE;
  lock_screen?: boolean;
  manage_tree_options?: {
    show_match_siblings: boolean;
  };
  selected_folder_meta?: ISelectedFolderMeta;
  applied_content_selected_tab?: string;
  current_template?: string;
  apello_copy?: boolean;
  copied_text?: string;
  show_fonts_modal?: boolean;
  default_fonts?: string[];
  propertiesPanelIsFullyExpanded?: boolean
}

const initialState: IClientOptions = {
  applied_content_selected_folder: null,
  applied_content_tree_selected_id: null,
  detail_drawer_open: false,
  inputMode: INPUT_MODE.HTML5,
  lock_screen: false,
  manage_tree_options: { show_match_siblings: false },
  progress: null,
  resource_modal_tree_selected_folder: null,
  resource_selected_folder: null,
  resource_selected_resource: null,
  resource_tree_filters: [],
  resource_tree_selected_id: null,
  search_string_highlight: "",
  search_string_main: "",
  search_string_existing_resource: "",
  selected_folder_meta: { project: null },
  tree_panel_open: true,
  treemode: TREE_TYPE.APPLIED_CONTENT,
  applied_content_selected_tab: "",
  current_template: "All themes",
  apello_copy: false,
  copied_text: "",
  show_fonts_modal: false,
  default_fonts: [],
};

export const clientOptionsSlice = createSlice({
  name: "clientOptions",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setClientOptions: (
      state,
      action: PayloadAction<Partial<IClientOptions>>
    ) => {
      Object.assign(state, action.payload);
    },
    folderSelect: (
      state,
      action: PayloadAction<{
        treeId: string;
        treemode: string;
      }>
    ) => {
      if (action.payload.treemode === TREE_MODE_APPLIED_CONTENT) {
        state.applied_content_tree_selected_id = action.payload.treeId;
        state.applied_content_selected_folder = getIdFromTreeNode(
          action.payload.treeId,
          false,
          true
        );
      } else {
        state.resource_tree_selected_id = action.payload.treeId;
        if (action.payload.treeId.startsWith("folder-")) {
          state.resource_selected_folder = Number(
            action.payload.treeId.substring(7)
          );
        } else if (action.payload.treeId.startsWith("resource-")) {
          state.resource_selected_resource = Number(
            action.payload.treeId.substring(9)
          );
        } else {
          state.resource_selected_folder = null;
        }
      }
    },
    folderSelectModal: (
      state,
      action: PayloadAction<{
        treeId: string;
      }>
    ) => {
      state.applied_content_tree_modal_selected_id = action.payload.treeId;
      state.applied_content_modal_selected_folder = getIdFromTreeNode(
        action.payload.treeId,
        false,
        true
      );
    },
    resourceSelectModal: (
      state,
      action: PayloadAction<{
        treeId: string;
      }>
    ) => {
      state.resource_modal_tree_selected_id = action.payload.treeId;
      state.resource_modal_tree_selected_folder = getIdFromTreeNode(
        action.payload.treeId,
        false,
        true
      );
    },
  },
});

export const { setClientOptions } = clientOptionsSlice.actions;
