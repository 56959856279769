export default {
  field_groups: {
    default: {
      title: "Companies",
      default_expanded: true
    }
  },
  fields: {
    company_id: {
      type: "select",
      title: "Current company",
      validation: {}
    }
  }
};
