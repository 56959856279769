import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name } from "../standardized_fields";

export const boston_presentation_2x5_and_3x2: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.boston_presentation_2x5_and_3x2,
  display_name: "Boston Presentation 2x5 and 3x2",
  acceptable_folder_types: [
    FOLDER_TYPE.boston_media_with_quote,
    FOLDER_TYPE.boston_fullscreen_media,
    FOLDER_TYPE.boston_checkerboard,
    FOLDER_TYPE.boston_donor_sea,
    FOLDER_TYPE.boston_diamond_platinum_list
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  hide_nested_children: true,
  prevent_add_children: true,
  fields: {
    name: name
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.Boston2x5and3x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    hide_border: true
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/boston/2x5_3x2.svg"
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  isPresentation: true
};
