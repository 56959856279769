import { FOLDER_TYPE, FOLDER_TYPE_SELECTOR, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const marin_presentations: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.marin_presentations,
  display_name: "Presentations",
  acceptable_folder_types: [FOLDER_TYPE.marin_presentation_5x1, FOLDER_TYPE.marin_presentation_1x1],
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 120,
    columns: 1
  },
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentations
    },
    closed: {
      ...tree_icon_types.presentations
    }
  }
};
