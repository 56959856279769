import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { wysiwyg } from "../standardized_fields";
import { marinFonts, quillFontSizes } from "./marin_settings";

export const marin_story_large_media_with_quote: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.marin_story_large_media_with_quote,
  display_name: "Large Media with Quote (2)",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    media_with_quote: {
      title: "Media with quote",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    // General field group
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_cycle_time"
      }
    },
    image: {
      title: "Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.60669,
      recommended_size: {
        width: 820,
        height: 510
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_BOTTOM_CENTER,
        aspect: 1.60669
      },
      field_group: "media_with_quote",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_image"
      }
    },
    quote_body_left: {
      title: "Quote Body Left",
      field_group: "media_with_quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: marinFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "56px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_quote_body_left"
      }
    },
    quote_body_right: {
      title: "Quote Body Right",
      field_group: "media_with_quote",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: marinFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#FFFFFF",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "56px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_quote_body_right"
      }
    },
    quote_background_color: {
      type: "color",
      title: "Quote Background Color",
      field_group: "general",
      default: "#5392bd",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_quote_background_color"
      }
    },
    background_color: {
      type: "color",
      title: "Background Color",
      field_group: "general",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "large_media_with_quote_1_background_color"
      }
    }
  },
  folder_type_selector: {
    image_url: "/images/folder_types/marin/marin_story_large_media_with_quote.svg"
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  slots: 2,
  preview: {
    aspect_ratio: 1.125,
    component_name: COMPONENT_NAME.MarinDynamicContainer,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 2, vertical: 1 }],
    hide_border: false,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  show_clone_children_checkbox: false
};
