import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE, FOLDER_CATEGORY } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { transitions } from "./new_orleans_settings";

export const new_orleans_donor_list: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.new_orleans_donor_list,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Fund",
  acceptable_folder_types: [FOLDER_TYPE.new_orleans_donor],
  fields: {
    name: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ]
    },
    sorting_options: {
      type: "select",
      title: "Sorting Options",
      validation: { rules: [{ name: "isRequired" }] },
      default: "rank",
      options: [
        {
          title: "Rank",
          value: "rank"
        },
        {
          title: "Sorting Order",
          value: "sorting_order"
        }
      ]
    },
    header: {
      type: "text",
      title: "Header",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      settings: {
        recommended_length: 38
      }
    },
    description: {
      type: "textarea",
      title: "Description",
      validation: { rules: [{ name: "isString", min: 0, max: 500 }] },
      default: null,
      settings: {
        recommended_length: 200
      }
    },
    category: {
      type: "text",
      title: "Category",
      validation: { rules: [{ name: "isString", min: 0, max: 75 }] },
      default: null,
      settings: {
        recommended_length: 24
      }
    },
    category_description_font_size: {
      type: "number",
      title: "Category Description Font Size",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 30 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 2
    },
    category_title_font_size: {
      type: "number",
      title: "Category Title Font Size",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 30 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 2
    },
    category_title_justification: {
      type: "select",
      title: "Category Justification",
      validation: { rules: [{ name: "isRequired" }] },
      default: "left",
      options: [
        {
          title: "Left",
          value: "left"
        },
        {
          title: "Center",
          value: "center"
        },
        {
          title: "Right",
          value: "right"
        }
      ]
    },
    donor_name_font_size: {
      type: "number",
      title: "Donor Name Font Size",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 30 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 1.35
    },
    columns: {
      type: "integer",
      title: "Columns",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
      default: 2
    },
    description_top: {
      type: "integer",
      title: "Description Margin Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 20 }] },
      default: 11
    },
    container_margin_top: {
      type: "integer",
      title: "Container Margin Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 0
    },
    container_margin_left: {
      type: "integer",
      title: "Container Margin Left",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      default: 12
    },
    container_max_width: {
      type: "integer",
      title: "Donor List Container Width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      default: 76
    },
    cycle: {
      type: "integer",
      title: "Cycle",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 1000 }] },
      default: 5
    },
    donor_max_width: {
      type: "integer",
      title: "Donor Max Width",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      default: 90
    },
    donor_vertical_separation: {
      type: "integer",
      title: "Donor Vertical Separation",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      default: 4
    },
    donor_line_space: {
      type: "integer",
      title: "Donor Line Space",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 100 }] },
      default: 1
    },
    cycle_forward: {
      type: "text",
      title: "Cycle Forward Key",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: "arrow_right",
      sys_admin_only: true,
      show_char_count: false
    }
  },
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.new_orleans_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.new_orleans_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.new_orleans_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.new_orleans_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    aspect_ratio: 0.5625,
    component_name: COMPONENT_NAME.NewOrleansDonorList,
    type: PREVIEW_TYPE.COMPONENT,
    static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/new_orleans_background.jpg"
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
