export const rowStyle = {
  display: "flex",
  flexDirection: "row" as "row",
  flexWrap: "wrap" as "wrap",
  width: "100%",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
};

export const columnStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  flexBasis: "100%",
  flex: 1,
  textAlign: "center" as "center",
};
