import request from "../utils/api/ServerRequest";
import { call, put } from "redux-saga/effects";
import _ from "lodash";
import { addError, addExceptionError } from "../actions/notificationActions";
import { isResponseSuccess } from "../shared-global/constants/apiUtils";
import { HTTP_METHODS } from "../utils/api/methods";
import Request from "../utils/api/request";
import {
  loginValidationSchema,
  requestPasswordResetValidationSchema,
  resetPasswordValidationSchema,
} from "../shared-global/validations/AuthValidation";

async function getCSRF(payload) {
  try {
    let response = await request.getCsrf("/security/grant-csrf-token", {
      _: {},
    });
    return response;
  } catch (e) {
    return e;
  }
}

async function loginAPI(payload) {
  return Request(
    "/auth/login",
    HTTP_METHODS.POST,
    {
      email: payload.email,
      password: payload.password,
      method: "session",
    },
    loginValidationSchema
  );
}

async function logoutAPI(payload) {
  return Request("/auth/logout", HTTP_METHODS.GET);
}

async function request_password_reset_API(payload) {
  return Request(
    "/auth/request_password_reset",
    HTTP_METHODS.POST,
    {
      email: payload.email,
      captcha: payload.captcha,
    },
    requestPasswordResetValidationSchema
  );
}

async function reset_password_API(payload) {
  return Request(
    "/auth/reset_password",
    HTTP_METHODS.POST,
    {
      token: payload.token,
      password: payload.password,
      captcha: payload.captcha,
    },
    resetPasswordValidationSchema
  );
}

const authSaga = {
  getCSRF: function* (action) {
    try {
      const response = yield call(getCSRF, action.payload);
      if (isResponseSuccess(response)) {
        const csrf = _.get(response, "data._csrf", null);
        if (csrf !== null) {
          request.setDefaultOption("csrf", csrf);
          yield put({ type: "GET_CSRF_SUCCESS" });
          if (action.onSuccess) {
            action.onSuccess();
          }
        }
      } else {
        yield put(addError(response.errors));
        yield put({ type: "GET_CSRF_FAIL", payload: {} });
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "GET_CSRF_FAIL", payload: {} });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  login: function* (action) {
    try {
      const response = yield call(loginAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({ type: "LOGIN_SUCCESS", user: response.data.user });
        if (action.onSuccess) {
          action.onSuccess(response.data.user);
        }
      } else {
        if (action.onFail) {
          action.onFail(response.data.detail);
        }
        yield put(addError(response.errors));
        yield put({
          type: "LOGIN_FAIL",
          payload: { authorized: false, method: action.payload.method },
        });
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      yield put(addExceptionError(e));
      yield put({
        type: "LOGIN_FAIL",
        payload: { authorized: false, method: action.payload.method },
      });
    }
  },

  logout: function* (action) {
    try {
      const response = yield call(logoutAPI, action.payload);
      if (isResponseSuccess(response)) {
        yield put({ type: "LOGOUT_SUCCESS", payload: { authorized: false } });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  request_password_reset: function* (action) {
    try {
      const response = yield call(request_password_reset_API, action.payload);
      if (isResponseSuccess(response)) {
        yield put({ type: "REQUEST_PASSWORD_RESET_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put({ type: "REQUEST_PASSWORD_RESET_FAIL" });
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e.message);
      }
    }
  },

  reset_password: function* (action) {
    try {
      const response = yield call(reset_password_API, action.payload);
      if (isResponseSuccess(response)) {
        yield put({ type: "RESET_PASSWORD_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess();
        }
      } else {
        yield put(addError(response.errors));
        if (action.onFail) {
          action.onFail();
        }
      }
    } catch (e) {
      yield put(addExceptionError(e));
      if (action.onFail) {
        action.onFail(e);
      }
    }
  },
};

export default authSaga;
