import { ADD_FONT_GROUP, REMOVE_FONT_GROUP, UPDATE_FONT_GROUP } from "./actionTypes";

export const addFontGroup = (data, onSuccess?, onFail?) => ({
  type: ADD_FONT_GROUP,
  payload: data,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateFontGroup = (id, data, onSuccess?, onFail?) => ({
  type: UPDATE_FONT_GROUP,
  payload: {
    id,
    ...data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const removeFontGroup = (id, onSuccess?, onFail?) => ({
  type: REMOVE_FONT_GROUP,
  payload: {
    id,
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});