import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const boston_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.boston_root,
  display_name: "Boston Root",
  acceptable_folder_types: [FOLDER_TYPE.boston_displays, FOLDER_TYPE.boston_presentations, FOLDER_TYPE.boston_content],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    donor_name_style: {
      type: "textstyle",
      title: "Donor Name Style",
      show_char_count: false,
      default: textstyle_default,
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: false
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      }
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.COMPONENT
  }
};
