import React, { Component } from 'react';
import Breadcrumbs from '../layout/Breadcrumbs';
import _ from 'lodash';

class CompanyBreadCrumbs extends Component {



  numberOrNew = (val) => {
    if (isNaN(val)){
      return val
    }
    return Number(val)
  }

  render() {
    let props = this.props.crumbs;
    let pathArrayURL = props.location.pathname.split("/");

    let pathArray = []
    for (var i=0; i<pathArrayURL.length; i++){
      if (pathArrayURL[i]!==""){
        pathArray.push(pathArrayURL[i])
      }
    }
    let crumbs = [];

    //console.log("PATH ARRAY", pathArray);

    //companies
    if (pathArray.length>=1){
      crumbs.push({
        name: 'companies', active: pathArray.length>=1, link: '/companies'
      });
    }

    //company id
    if (pathArray.length>=2){
      let companyName =  _.get(props, 'company.name', "")
        crumbs.push({
          name:companyName, active: pathArray.length>2, link: '/company/' + _.get(pathArray, '[1]')
        });
    }

    if (pathArray.length === 2){
      if (pathArray[0] === "company" && pathArray[1] === "new"){
        crumbs.push({
          name: 'new company', active: false, link: '/'
        });
      }
    }


    let name;
    // handle areas
    if (pathArray.length >= 3) {
      switch (pathArray[2]) {
        case "company":
          // if (_.get(pathArray, '[3]') === "new") {
          //   name = "new"
          // }
          // else {
          //   name = ""
          // }
          // crumbs.push({
          //   name: name, active: false, link: '/company/'
          // });
          break;
        case "project":
          if (pathArray.length>=3){
            crumbs.push({
              name: 'project', active: false, link: '/'
            });
          }

          if (_.get(pathArray, '[3]') === "new") {
            name = "new project"
          }
          else {
            name = _.get(this.props, 'crumbs.project.name')
          }
          crumbs.push({
            name: name, active: false, link: '/project/' + this.numberOrNew(pathArray[3])
          });
          break;
        case "user":
          if (pathArray.length>=3){
            crumbs.push({
              name: 'user', active: false, link: '/'
            });
          }

          if (_.get(pathArray, '[3]') === "new") {
            name = "new"
          }
          else {
            name = _.get(this.props, 'crumbs.user.first_name') + ' ' + _.get(this.props, 'crumbs.user.last_name')
          }
          crumbs.push({
            name: name, active: false, link: '/user/' + this.numberOrNew(pathArray[3])
          });
          break;
        case "role":
          if (pathArray.length>=3){
            crumbs.push({
              name: 'role', active: false, link: '/'
            });
          }

          if (_.get(pathArray, '[3]') === "new") {
            name = "new"
          }
          else {
            name = _.get(this.props, 'crumbs.role.name')
          }
          crumbs.push({
            name: name, active: false, link: '/role/' + this.numberOrNew(pathArray[3])
          });
          break;
        case "permission":
          if (pathArray.length>=3){
            crumbs.push({
              name: 'permission', active: false, link: '/'
            });
          }

          if (_.get(pathArray, '[3]') === "new") {
            name = "new"
          }
          else {
            name = _.get(this.props, 'crumbs.permission.name')
          }
          crumbs.push({
            name: name, active: false, link: '/user/' + this.numberOrNew(pathArray[3])
          });
          break;
        case "location":
          if (pathArray.length>=3){
            crumbs.push({
              name: 'location', active: false, link: '/'
            });
          }

          if (_.get(pathArray, '[3]') === "new") {
            name = "new"
          }
          else {
            name = _.get(this.props, `crumbs.locations[${Number(pathArray[3])}].name`);
          }
          crumbs.push({
            name: name, active: false, link: '/location/' + this.numberOrNew(pathArray[3])
          });
          break;
        default:
          break;
      }
    }

    return (
      <Breadcrumbs crumbs={crumbs} />
  );
  }
}

export default CompanyBreadCrumbs;