import { FIELD_TYPE } from "../../../enums/folder-type-enums";
import { allFonts, FontVariants, FontsFamilies, fonts } from "../../../fonts";
import { IFolderTypeField } from "../../../interfaces/folder-type-interfaces";

export const name = {
  type: FIELD_TYPE.text,
  title: "Name",
  validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
  default: null
};

export const name_text_area = {
  type: "textarea",
  title: "Name",
  validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
  default: "",
  read_only: true,
  show_char_count: false,
  settings: {
    minRows: 2,
    maxRows: 6
  }
};

export const justification = {
  type: FIELD_TYPE.select,
  title: "Justification",
  validation: { rules: [{ name: "isRequired" }] },
  default: "left",
  options: [
    {
      title: "Left",
      value: "left"
    },
    {
      title: "Center",
      value: "center"
    },
    {
      title: "Right",
      value: "right"
    }
  ]
};

export const sorting_options = {
  type: FIELD_TYPE.select,
  title: "Sorting Options",
  validation: { rules: [{ name: "isRequired" }] },
  default: "sorting_order",
  options: [
    {
      title: "Rank",
      value: "rank"
    },
    {
      title: "Sorting Order",
      value: "sorting_order"
    },
    {
      title: "Name",
      value: "name"
    }
  ]
};

export const columns = {
  type: FIELD_TYPE.integer,
  title: "Columns",
  validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 4 }] },
  default: 3
};

export const color = {
  type: FIELD_TYPE.color,
  title: "Color",
  default: ""
};

export const wysiwyg: IFolderTypeField = {
  type: "wysiwyg",
  title: "Subhead",
  validation: { rules: [] },
  default: {
    type: "style", // Options are: style | ops
    style: {
      font: fonts[FontsFamilies.META_PRO][FontVariants.REGULAR],
      size: "20em"
    },
    ops: []
  },
  tooltip: [
    "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
  ],
  settings: {
    fonts: allFonts,
    fontSizeOptions: {
      type: "range", // Could be range or custom (supply)
      min: 30,
      max: 60
    },
    recommended_length: 1420,
    controls: {
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      numberedList: true,
      bulletList: true,
      alignment: true,
      indentation: true,
      clearFormat: true,
      lineHeight: true
    }
  }
};
