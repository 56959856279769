import { array, boolean, number, object, string } from 'yup';

export const getProjectsValidationSchema = object().shape({
  company: number()
});

export const getProjectValidationSchema = object().shape({
  id: number().required()
});


export const updateProjectValidationSchema = object().shape({
  id: number().required(),
  name: string(),
  location: number(),
  red_alert_minutes: string(),
  yellow_alert_minutes: string(),
  allow_show_cross: boolean(),
  notify_users: array().of(number())
});

export const updateProjectSettingsValidationSchema = object().shape({
  id: number().required(),
  settings: object().nonNullable().required()
});

export const createProjectValidationSchema = object().shape({
  name: string().required(),
  company: number().required(),
  location: number().required(),
  yellow_alert_minutes: number().required(),
  red_alert_minutes: number().required(),
  allow_show_cross: string().required(),
  template: string().required()
});

export const downloadCsvValidationSchema = object().shape({
  project: string().required()
});

export const getAllNotifiableUsersValidationSchema = object().shape({
  project_id: number().required()
});

export const deleteProjectValidationSchema = object().shape({
  id: number().required(),
  deleteUnusedResources: boolean()
});
