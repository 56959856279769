import React, {useEffect, useState} from "react";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getFileDynamic } from "../../../utils/fileUtils";
import { DEFAULT_CYCLE_TIME, GREY_HEADER } from "./NewOrleansConstants";
import RichTextRender from "../../standard/RichTextRender";
import {useFolder} from "../../../hooks/useFolder";
import {useScale} from "../../../hooks/useScale";
import {getBaseFolder} from "../../../utils/commonDataUtils";
import {createUseStyles} from "react-jss";
import {generateFontSizeClassesFromFields} from "../../../utils/generalUtils";
import CycleWrapper from "../../standard/CycleWrapper";
import SpringfieldTemplate002 from "../springfield/SpringfieldTemplate002";

const NewOrleansTemplate002: React.FunctionComponent<IDisplayComponentProps> = (props) => {

  const [file, setFile] = useState(null)
  const [cycle_time, setCycleTime] = useState(0)
  const [metadataLoaded, setMetadataLoaded] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [cycleTimeout, setCycleTimeout] = useState(null)
  const [videoRef, setVideoRef] = useState(null)
  const [initPlayerTimeout, setInitPlayerTimeout] = useState(null)

  const {
    getBaseFolder,
    getBaseFolderFieldValue,
  } = useFolder();
  const baseFolder = getBaseFolder();

  const {
    getBaseFolderRichTextStylesFn,
    getBaseFolderFontSize
  } = useScale();

  const fontSize = getBaseFolderFontSize()
  const useStyles = getBaseFolderRichTextStylesFn()
  const classes = useStyles();

  const initializeNewData = () => {
    const baseFolderCycleTime = getBaseFolderFieldValue("cycle_time", null)
    if (props.cycle_time) {
      setCycleTime(props.cycle_time)
    } else if (baseFolderCycleTime) {
      setCycleTime(baseFolder.fields.cycle_time * 1000)
    }
  }

  const _setCycleTimeout = (cycle_time) => {
    // @ts-ignore
    clearTimeout(cycleTimeout);
    if (cycle_time === 0) {
      cycle_time = DEFAULT_CYCLE_TIME;
    }
    setCycleTimeout(setTimeout(() => {
      if (props.handleEndOfPlay) {
        props.handleEndOfPlay();
      }
    }, cycle_time))
  }

  useEffect(() => {
    initializeNewData();
    _setCycleTimeout(cycle_time);
    let preset = props.mode === "preview" ? "preview" : null;

    setFile(getFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["file", "slide"],
      props.data.base_folder,
      preset,
      props.webview_signedurls
    ))

    // document.addEventListener("keydown", handleKeyDown);
    return () => {
      // @ts-ignore
      clearTimeout(cycleTimeout);
      // document.removeEventListener("keydown", handleKeyDown);
    }
  }, [])

  const unbindEvents = () => {
    videoRef.removeEventListener("loadedmetadata", loadedMetadata);
    videoRef.removeEventListener("loadeddata", loadedData);
  };

  const initPlayer = () => {
    let player = videoRef;
    if (player) {
      player.addEventListener("loadedmetadata", loadedMetadata);
      player.addEventListener("loadeddata", loadedData);
      if (player.readyState >= 2) {
        loadedMetadata();
        loadedData();
      }
    } else {
      //@ts-ignore
      clearTimeout(initPlayerTimeout);
      setInitPlayerTimeout(setTimeout(() => {
        initPlayer();
      }, 100))
    }
  };

  const loadedMetadata = () => {
    setMetadataLoaded(true)
    checkIfAllDataLoaded();
  };

  const loadedData = () => {
    setDataLoaded(true)
    checkIfAllDataLoaded();
  };

  const checkIfAllDataLoaded = () => {
    if (metadataLoaded && dataLoaded) {
    }
  };


  const txt = getBaseFolderFieldValue('text01', '');
  const header01 = getBaseFolderFieldValue('header01', '');

  return (
    <div className={classes.componentContainer} style={{ color: "black" }}>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          left: 0,
          right: 0,
          textAlign: "center",
          top: props.containerHeight * 0.0249,
          fontSize: props.containerHeight * 0.0249,
          textTransform: "uppercase",
          fontWeight: "bold",
          color: GREY_HEADER,
          fontFamily: "gotham-bold",
          whiteSpace: "pre-wrap",
          overflow: 'hidden',
          height: props.containerHeight * 0.037339557,
        }}
      >
        {header01}
      </div>

      <div
        className="ql-editor-preview"
        style={{
          position: "absolute",
          top: props.containerHeight * 0.21197, //0.2947,
          left: props.containerWidth * 0.11358,
          width: props.containerWidth * 0.814,
          fontSize,
          fontFamily: "hind-light",
          maxHeight: 0.7411 * props.containerHeight,
          overflow: 'hidden'
        }}
      >
        <div>
          <RichTextRender htmlString={txt}/>
        </div>
      </div>
    </div>
  )

}

export default NewOrleansTemplate002;
