import { COMPONENT_NAME, FIELD_TYPE, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { FOLDER_TYPE_NAME, IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { textStyleOptions } from "./glenwood_settings";
import { tree_icon_types } from "../tree_icon_types";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const glenwood_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.glenwood_root,
  field_groups: {
    general: {
      title: "General",
      default_expanded: false,
      order: 0,
      tab: "general"
    },
    defaults: {
      title: "Defaults",
      default_expanded: false,
      order: 0,
      tab: "defaults"
    }
  },
  tabs: {
    general: {
      title: "General"
    },
    defaults: {
      title: "Defaults",
      notification: {
        message: "The following settings, can override all the children folder of these projects (if apply). This would"
      }
    }
  },
  save_options: [
    {
      title: "SAVE AND OVERRIDE",
      action: "push_down_fields"
    }
  ],
  display_name: "Glenwood Root",
  acceptable_folder_types: [FOLDER_TYPE.glenwood_displays, FOLDER_TYPE.glenwood_presentations, FOLDER_TYPE.glenwood_content],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true,
      field_group: "general",
      read_only: true
    },
    font_groups: {
      type: FIELD_TYPE.select,
      title: "Font Group",
      field_group: "general",
      settings: {
        options: "font_groups", // This is used for populating options with dynamic data.
        callback_on_change: "apply_font_group" // Action to perform when the value in the select is changed. The name will serve to filter other actions when implemented.
      }
    },
    background: {
      title: "Default Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    color_overlay: {
      type: "color",
      title: "Default Color Overlay",
      default: "",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    accent_color: {
      type: "color",
      title: "Default Accent Color",
      default: "#B14182",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    heading: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Headings",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_title: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Titles",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_items: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Menu Items",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    headline: {
      title: "Story Templates Header",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Story Templates SubHead",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 28
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    body: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Body",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 25
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    name_list: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Donor Names",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    stories_menu_slider_title: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Stories Menu Title",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    stories_menu_slider_description: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Stories Menu Description",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    menu_slider_arrow_color: {
      type: "color",
      title: "Menu Arrows",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    quote: {
      title: "Quote",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    attribution: {
      title: "Attribution",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 40
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    footer: {
      title: "Donor Lists Footer",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 18,
        italic: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    header_fund_with_giving_levels: {
      title: "Header Fund with Giving Levels",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 30,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead_fund_with_giving_levels: {
      title: "Subhead Fund with Giving Levels",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 20
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    header_fund: {
      title: "Header Fund",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 26,
        bold: true
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    subhead_fund: {
      title: "Subhead Fund",
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    gallery_title: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Gallery Title",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 35,
        lineHeight: 1.2
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    },
    gallery_description: {
      type: FOLDER_TYPE_NAME.TEXT_STYLE,
      title: "Gallery Description",
      show_char_count: false,
      default: {
        ...textstyle_default,
        color: "#FFFFFF",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 24,
        lineHeight: 1.2
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: false,
        pass_down_to_children: true
      },
      field_group: "defaults"
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    react_url: "theme/glenwood",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.GlenwoodRootPreview,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 1, vertical: 1 }]
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.glenwood_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.glenwood_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.glenwood_content
    }
  ],
  font_groups: {
    enabled: true,
    compatible_folder_types: [FOLDER_TYPE.glenwood_presentation]
  },
  hide_add_icon_in_tree: true
};
