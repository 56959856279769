import React, { useEffect, useState } from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getFileDynamic } from "../../../utils/fileUtils";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";

const LosAngelesSlides: React.FunctionComponent<IDisplayComponentProps> = (
  props: IDisplayComponentProps
) => {
  const [logo, setLogo] = useState<string | null>(null);
  const [logoWidth] = useState<number>(props.containerWidth * 0.134722222);
  const [logoHeight] = useState<number>(props.containerWidth * 0.080555556);
  const [logoPosition] = useState<{top: number; left: number}>({
    left: props.containerWidth * 0.014055802,
    top: props.containerWidth * 0.028111603,
  });

  useEffect(() => {
    const preset = props.mode === "preview" ? "preview" : null;
    const fileUrl = getFileDynamic(
      props.aws,
      props.aws_bucket,
      props.data.resources,
      props.data.resource__folders,
      ["file"],
      props.data.base_folder,
      preset,
      props.webview_signedurls
    );
    setLogo(fileUrl);
  }, []);

  return (
    <>
      <ComponentLoop1911
        aws={props.aws}
        aws_bucket={props.aws_bucket}
        mode={props.mode}
        containerWidth={props.containerWidth}
        containerHeight={props.containerHeight}
        handleEndOfPlay={props.handleEndOfPlay}
        data={props.data}
        webview_signedurls={props.webview_signedurls}
      />
      <div
        style={{
          position: "absolute",
          backgroundImage: `url(${logo})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          width: logoWidth,
          height: logoHeight,
          left: logoPosition.left,
          top: logoPosition.top
        }}
      />
    </>
  );
};

export default LosAngelesSlides;
