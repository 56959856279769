import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input, Modal, Select} from "antd";
import _ from "lodash";
import React, {Component} from "react";
import {List} from "react-virtualized";
import {getFileType} from "../../shared/utils/fileUtils";
import {isDescendant} from "../../shared/utils/generalUtils";
import {
  TREE_MODE_APPLIED_CONTENT,
  TREE_MODE_RESOURCE,
  TREE_MODE_SELECT_APPLIED_CONTENT,
  TREE_MODE_SELECT_RESOURCE,
} from "../constants";
import LayoutConstants, {
  getTreeListHeight,
  LT_GRAY_BLUE,
  XXLT_GRAY,
} from "../layout/LayoutConstants";
import DragDropTreeItem from "./DragDropTreeItem";
import ScrollTrigger from "./ScrollTrigger";
import {
  addCallbackUnsavedService, executeAllCallbacksAndSubmitUnsavedService,
  executeAllCallbacksUnsavedService,
  resetUnsavedService
} from "../../services/unsavedModalService";
import { findAllPossiblePathsToFolder, isInContentFolder } from "../../services/treeService";

const {Option} = Select;
// const { SubMenu }  = Menu;
const Search = Input.Search;

class DragDropTree extends Component {
  constructor(props) {
    super(props);
    this._scrollQueued = false;
    this._listRef = null;
    this._scrollTop = 0;
    //this._selectedTreeId = null;
    this._parentElement = null;
    resetUnsavedService();
    this.state = {
      configureModalOpen: false,
      searchValue: "",
      autoUpdate: 0,
      doubleClickTime: new Date().getTime(),
      doubleClickTreeId: -1,
      unsavedChangesFolderDetailModal: false,
      ignoreUnsavedChangeModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("contextmenu", (e) => {
      let isChild = isDescendant(this._parentElement, e.target);
      if (isChild) {
        e.preventDefault();
      }
    });
    resetUnsavedService()
    this.setState({
      unsavedChangesFolderDetailModal: false,
      ignoreUnsavedChangeModal: false
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    clearTimeout(this.autoSearchTimeout);
    clearTimeout(this.dragDownTimeout);
  }

  onEndDrag = (args) => {
    if (this.props.onDrop && !this.props.isDraggingDisabled) {
      this.props.onDrop(args);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.treeData !== this.props.treeData) {
      this._listRef.forceUpdateGrid();
    }
    if (prevProps.selectedTreeId !== this.props.selectedTreeId) {
      resetUnsavedService();
      this.setState({
        unsavedChangesFolderDetailModal: false,
        ignoreUnsavedChangeModal: false
      });
    }
  }

  onScroll = (coords) => {
    this._scrollTop = coords.scrollTop;
  };

  isTouchSomewhere = () => {
    const update_folder_touch = _.get(this.props, 'update_folder_touch.touch');
    const update_list_view_touch = _.get(this.props, 'update_list_view_touch.touch');
    return update_folder_touch || update_list_view_touch;
  };

  setUpdateListViewTouchSafe = (touch) => {
    if (this.props.setUpdateListViewTouch) {
      const updateFolderTouchObj = {touch};
      this.props.setUpdateListViewTouch(updateFolderTouchObj);
    }
  };

  setUpdateFolderTouchSafe = (folerTouchObj) => {
    if (this.props.update_folder_touch.touch) {
      const updateFolderTouchObj = {...this.props.update_folder_touch, ...folerTouchObj};
      this.props.setUpdateFolderTouch(updateFolderTouchObj);
    }
  };

  handleToggleExpandedItemCheckTouchInFolderDetail = (
    treeId,
    metaData,
    created_folder = false,
    clickSource = null
  ) => {
    let callback = () => {
      this.handleToggleExpandedItem(treeId, metaData, created_folder, clickSource);
    };
    const changePropertyPanel = this.isTreePropertiesChange(treeId, metaData, created_folder, clickSource);

    if (changePropertyPanel && this.isTouchSomewhere() && (_.get(this.props, 'clientOptions.treemode', null) !== TREE_MODE_RESOURCE)) {
      if (!this.state.ignoreUnsavedChangeModal) {
        const cb = () => {
          this.setUpdateListViewTouchSafe(false);
          this.setUpdateFolderTouchSafe({touch: false, initialState: null});
          callback();
        };
        addCallbackUnsavedService(cb);
        this.setState({
          unsavedChangesFolderDetailModal: true,
        });
        return;
      }
    }
    callback();
  };

  handleActionCheckTouch = (
    clickFunction,
    params
  ) => {
    let callback = () => {
      if (Array.isArray(params)) {
        clickFunction(...params);
      } else {
        clickFunction(params);
      }
    };

    if (this.isTouchSomewhere()) {
      const cb = () => {
        this.setUpdateListViewTouchSafe(false);
        this.setUpdateFolderTouchSafe({touch: false, initialState: null});
        callback();
      };
      addCallbackUnsavedService(cb);
      this.setState({
        unsavedChangesFolderDetailModal: true,
      });
      return;
    }

    callback();

  };

  handleClickTouchInFolder = (
    clickFunction,
    params
  ) => {
    this.setState({
      ignoreUnsavedChangeModal: true
    });
    if (Array.isArray(params)) {
      clickFunction(...params);
    } else {
      clickFunction(params);
    }
  };

  handleClickTouchInFolderNoSpread = (
    clickFunction,
    params,
  ) => {
    this.setState({
      ignoreUnsavedChangeModal: true
    });
    clickFunction(params);
  };

  /**
   * this methos return true if the properties of a tree's element change
   */
  isTreePropertiesChange = (
    treeId,
    metaData,
    created_folder = false,
    clickSource = null
  ) => {
    //arrow-click
    // allow selecting without fully toggling
    const applied_content_tree_selected_id = _.get(this.props, 'clientOptions.applied_content_tree_selected_id', null);
    let doSelect = true;
    let doOpenClose = true;
    // require double click except on arrow-click / triggered elsewhere
    if (clickSource === "icon-click" || clickSource === "name-click") {
      // clicking a new node reset timer
      if (treeId === applied_content_tree_selected_id) {
        doSelect = false;
      } else if (treeId !== this.state.doubleClickTreeId) {
        doOpenClose = false;
      } else {
        doSelect = false;
      }
    } else if (clickSource === "arrow-click") {
      doSelect = false;
    }

    return doSelect;
  };

  handleToggleExpandedItem = (
    treeId,
    metaData,
    created_folder = false,
    clickSource = null
  ) => {
    //arrow-click
    // allow selecting without fully toggling
    let doOpenClose = true;
    let doSelect = true;
    // require double click except on arrow-click / triggered elsewhere
    if (clickSource === "go-to-content-click") {
      // expand content folder
      const folder_id = parseInt(treeId.replace('folder-', ''))

      const paths = findAllPossiblePathsToFolder(
        folder_id,
        Object.values(this.props.folder__folders),
      )

      const contentPath = paths.find(path => isInContentFolder(
        path,
        this.props.folder__folders,
        this.props.folders,
        this.props.folderTypes
      ))

      const foldersToExpand = contentPath
        .map(ffId => 'folder-' + this.props.folder__folders[ffId].child_folder)

      const treeIds = _.union(this.props.appliedContentTreeExpanded, foldersToExpand)

      const contentMetaData = {
        folder__folder_id: _.last(contentPath),
        path: contentPath,
        project: this.props.project
      }

      this.props.setFoldersExpanded('applied_content', treeIds)
      this.props.toggleExpandedItem(treeId, contentMetaData, true, true);
      this._listRef.scrollToPosition(0);
    } else if (clickSource === "icon-click" || clickSource === "name-click") {
      // clicking a new node reset timer
      if (treeId !== this.state.doubleClickTreeId) {
        doOpenClose = false;
        this.setState({
          doubleClickTreeId: treeId,
          doubleClickTime: new Date().getTime(),
        });
      } else {
        // TODO: move double click time to a user preference?
        // if it took too long reset timer
        if (new Date().getTime() - this.state.doubleClickTime > 1000) {
          doOpenClose = false;
          this.setState({
            doubleClickTime: new Date().getTime(),
          });
        }
      }
      if (doOpenClose) {
        // proceeding reset the treeId to prevent single click re-triggering
        this.setState({
          doubleClickTreeId: -1,
        });
      }
    } else if (clickSource === "arrow-click") {
      doSelect = false;
    }

    if (doSelect) {
      this.props.toggleDetail(true);
    }

    if (treeId) {
      this.props.toggleExpandedItem(treeId, metaData, doOpenClose, doSelect);

      if (doSelect) {
        //this._selectedTreeId = treeId;
        //console.log("props on updateSelectedTreeId", this.props);
        this.props.updateSelectedTreeId(treeId);
      }

      if (created_folder) {
        this.setState({
          autoUpdate: this.state.autoUpdate + 1,
        });
      }
      // autofocus first input
      // const possible_form_wrapper = document.getElementById(
      //   "folder_detail_form_wrapper"
      // );
      // if (possible_form_wrapper) {
      //   const possible_inputs = possible_form_wrapper.getElementsByTagName(
      //     "input"
      //   );
      //   if (possible_inputs && possible_inputs.length > 0) {
      //     possible_inputs[0].focus();
      //   }
      // }
    }
  };

  handleWindowResize = () => {
    this.setState({});
  };

  rowRenderer = (data) => {
    let {
      id,
      treeId,
      name,
      depth,
      folder__folder_id,
      expanded,
      //rank,
      folder_type_config,
      tree_type,
      type,
      modifiedName,
      project,
      resource,
      resource__folder_id,
      path,
      save_options,
      showGray,
      showChildAcceptableIcon
    } = this.props.treeData[data.index];

    //console.log("FULL ROW DATA", this.props.treeData[data.index])

    let metaData = {
      project: project,
    };

    let itemIcons =
      typeof folder_type_config !== "undefined"
        ? folder_type_config.tree_icon_types
        : {};

    //handle icons for files
    if (tree_type === "resource" && type === "file") {
      if (folder_type_config === undefined) {
        if (treeId.includes("resource-")) {
          let fileType = getFileType(name);
          switch (fileType) {
            case "image":
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-image"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-image"],
                  },
                },
              };
              break;
            case "video":
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-video"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-video"],
                  },
                },
              };
              break;
            default:
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file"],
                  },
                },
              };
              break;
          }
        }
      }
    }

    //handle icons for text / rich text
    if (
      tree_type === "resource" &&
      (type === "plain_text" || type === "rich_text")
    ) {
      if (folder_type_config === undefined) {
        if (treeId.includes("resource-")) {
          switch (type) {
            case "plain_text":
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-alt"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-alt"],
                  },
                },
              };
              break;
            case "rich_text":
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-code"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file-code"],
                  },
                },
              };
              break;
            default:
              itemIcons = {
                open: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file"],
                  },
                },
                closed: {
                  icon_type: "fontawesome",
                  options: {
                    class_name: "fa-lg",
                    icon: ["far", "file"],
                  },
                },
              };
              break;
          }
        }
      }
    }

    let folder, folder_type;
    let acceptableFolderTypes = [];
    let csvDownload = false;
    let parentFolder = null;
    let isContent = false;
    if (folder__folder_id) {
      if (this.props.mode === "resource") {
        folder = this.props.resourcetreeFolders[id];
      } else {
        folder = this.props.folders[id];
      }
      if (folder != null) {
        folder_type = this.props.folderTypes[folder.folder_type];
        isContent = _.get(folder_type, 'isContent', false);
        csvDownload = _.get(folder_type, "csv_options.download", false);
        acceptableFolderTypes = _.get(folder_type, 'acceptable_folder_types', []);
      }

      parentFolder = _.get(this.props, `folder__folders[${folder__folder_id}].parent_folder`, null);
    } else {
      if (
        this.props.treeData[data.index].type === "file" &&
        (this.props.treeData[data.index].status === "loaded" ||
          this.props.treeData[data.index].status === "created")
      ) {
        name = "Loading";
      }
    }
    return (
      <React.Fragment key={data.index + "-fragment"}>
        <DragDropTreeItem
          folder__folder_id={folder__folder_id}
          expanded={expanded}
          path={path}
          //name={name + ", ID: "+ folder__folder_id}
          name={modifiedName ? modifiedName : name}
          key={folder__folder_id}
          spacer={false}
          treeId={treeId}
          onEndDrag={(...params) => {
            this.handleClickTouchInFolderNoSpread(this.onEndDrag, params)
          }}
          addSubFolder={(params) => {
            this.handleActionCheckTouch(this.props.addSubfolder, params)
          }}
          addResource={(params) => {
            this.handleClickTouchInFolder(this.props.addResource, params)
          }}
          deleteResource={(params) => {
            this.handleClickTouchInFolder(this.props.deleteResource, params)
          }}
          deleteFolder={(...params) => {
            this.handleActionCheckTouch(this.props.deleteFolder, params)
          }}
          openCsvModal={(params) => {
            this.handleActionCheckTouch(this.props.openCsvModal, params)
          }}
          handleToggleExpandedItem={this.handleToggleExpandedItemCheckTouchInFolderDetail}
          selectedTreeId={this.props.selectedTreeId}
          searchText={this.props.searchText}
          itemIcons={itemIcons}
          folderTypes={this.props.folderTypes}
          folders={this.props.folders}
          folder__folders={this.props.folder__folders}
          parentFolder={parentFolder}
          defaultIcons={this.props.defaultIcons}
          acceptableFolderTypes={acceptableFolderTypes}
          modeContext={this.props.modeContext}
          resource_tree_crud={this.props.resource_tree_crud}
          resource={resource}
          resource__folder_id={resource__folder_id}
          resourcetreeFolder__folders={this.props.resourcetreeFolder__folders}
          resourcetreeFolders={this.props.resourcetreeFolders}
          metaData={metaData}
          style={{
            ...data.style,
            height: data.style.height - 5,
            paddingLeft: depth * 15,
            borderBottom: 0,
            borderTop: 1,
            borderStyle: "solid",
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: XXLT_GRAY,
            paddingTop: 2,
          }}
          /* openCsvModal={this.props.openCsvModal} */
          csvDownload={csvDownload}
          isContent={isContent}
          saveOptions={save_options}
          folder_type={folder_type}
          showGray={showGray}
          showChildAcceptableIcon={showChildAcceptableIcon}
        />
        <DragDropTreeItem
          folder__folder_id={folder__folder_id}
          resource__folder_id={resource__folder_id}
          folders={this.props.folders}
          folder__folders={this.props.folder__folders}
          folderTypes={this.props.folderTypes}
          expanded={false}
          key={folder__folder_id + "-spacer"}
          spacer={true}
          treeId={treeId}
          path={path}
          saveOptions={save_options}
          folder_type={folder_type}
          style={{
            ...data.style,
            top: data.style.top + data.style.height - 5,
            height: 5,
            marginLeft: depth * 15,
          }}
          showGray={showGray}
          showChildAcceptableIcon={showChildAcceptableIcon}
          resourcetreeFolders={this.props.resourcetreeFolders}
        />
      </React.Fragment>
    );
  };

  handleEnterScrollZone = (offset) => {
    if (this._scrollQueued === false) {
      this._scrollQueued = true;
      clearTimeout(this.dragDownTimeout);
      this.dragDownTimeout = setTimeout(() => {
        const scrollableDivHeight =
          this._listRef.props.rowCount * this._listRef.props.rowHeight -
          this._listRef.props.height;
        if (
          this._scrollTop + offset > scrollableDivHeight ||
          this._scrollTop + offset < 0
        ) {
          this._scrollQueued = false;
          return;
        }
        this._listRef.scrollToPosition(this._scrollTop + offset);
        this._scrollQueued = false;
      }, 7);
    }
  };

  handleLeaveScrollDown = () => {
    clearInterval(this.dragDownInterval);
  };

  onSearchChange = ({target: {value}}) => {
    clearTimeout(this.autoSearchTimeout);
    this.autoSearchTimeout = setTimeout(() => {
      this.props.onSearch(this.state.searchValue, this.props.modeContext);
    }, 250);
    this.setState({searchValue: value});
  };

  renderInnerTreeSearch() {
    let searchWidth = 1;
    const width = this.props.width
      ? this.props.width
      : LayoutConstants().dashboard.applied_content_tree_width;
    let renderInnerSearch = false;
    switch (this.props.modeContext) {
      case TREE_MODE_SELECT_RESOURCE:
      case TREE_MODE_SELECT_APPLIED_CONTENT:
        renderInnerSearch = true;
        break;
      case TREE_MODE_APPLIED_CONTENT:
      case TREE_MODE_RESOURCE:
        break;
      default:
        break;
    }

    if (!renderInnerSearch) {
      return null;
    }

    return (
      <Search
        placeholder="input search text"
        onSearch={(value) => this.props.onSearch(value, this.props.modeContext)}
        value={this.state.searchValue}
        onChange={this.onSearchChange}
        style={{
          zIndex: 15,
        }}
        size="large"
        suffix={
          <span>
            {this.state.searchValue !== "" ? (
              <span
                style={{
                  width: width * 0.2,
                  textAlign: "right",
                  marginRight: 5,
                }}
              >
                &nbsp;
                <span
                  onClick={() => {
                    this.setState({
                      searchValue: "",
                    });
                    this.props.onSearch("", this.props.modeContext);
                  }}
                >
                  <FontAwesomeIcon
                    className="fa"
                    style={{color: LT_GRAY_BLUE, cursor: "pointer"}}
                    icon={["far", "times-circle"]}
                  />
                </span>
                {/* <Icon type="close-circle" onClick={() => {
                this.setState({
                  searchValue: ''
                })
                this.props.onSearch('', this.props.modeContext)
                }} /> */}
              </span>
            ) : null}
            {this.props.showSettings ? (
              <span
                style={{
                  width: width * 0.2,
                  textAlign: "right",
                  marginRight: 8,
                }}
              >
                &nbsp;
                <span
                  onClick={() => {
                    this.props.onSettings();
                  }}
                >
                  <FontAwesomeIcon
                    className="fa"
                    style={{color: LT_GRAY_BLUE, cursor: "pointer"}}
                    icon={["far", "cog"]}
                  />
                </span>
                {/* <Icon type="setting" onClick={() => { this.props.onSettings() }} /> */}
              </span>
            ) : null}
          </span>
        }
      />
    );
  }

  render() {
    var vHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    let height = 500;
    if (this.props.inputMode === "html5") {
      height = getTreeListHeight();
    } else if (this.props.inputMode === 'modal') {
      // 200 is the offset top and bottom, 56 is the height of the modal header, 48 is the padding of the modal body, 41 is the search bar height, 70 is the legend height
      if (this.props.clientOptions && this.props.clientOptions.modalGalleryVisible) {
        height = getTreeListHeight();
      } else {
        height = this.props.height ? this.props.height : window.innerHeight - 200 - 56 - 48 - 41 - 70;
      }
    } else {
      height = this.props.height
        ? this.props.height
        : Math.max(
          vHeight - LayoutConstants().dashboard.tree_v_offset,
          LayoutConstants().dashboard.tree_height_min
        );
    }

    const width = this.props.width
      ? this.props.width
      : LayoutConstants().dashboard.applied_content_tree_width;

    return (
      <div ref={(el) => (this._parentElement = el)}>
        {/* Last Moved: {this.state.lastMoved}<br />
        Time: {this.props.time}<br />
        Touch Mode: {String(this.state.touchMode)} */}

        {this.renderInnerTreeSearch()}

        {this.props.inputMode !== "html5" && this.props.inputMode !== 'modal' ? (
          <ScrollTrigger
            scrollType="up"
            onEnter={() => this.handleEnterScrollZone(-10)}
          />
        ) : null}
        {/* <SpanHeight height={LayoutConstants().dashboard.section_margin - 5} /> */}
        <List
          ref={(el) => (this._listRef = el)}
          width={width}
          height={height}
          rowCount={this.props.treeData.length}
          rowHeight={LayoutConstants().dashboard.tree_row_height}
          onScroll={this.onScroll}
          autoUpdate={this.state.autoUpdate}
          rowRenderer={this.rowRenderer}
        />
        {this.props.inputMode !== "html5" && this.props.inputMode !== 'modal' ? (
          <ScrollTrigger
            scrollType="down"
            onEnter={() => this.handleEnterScrollZone(10)}
          />
        ) : null}

        <Modal
          visible={this.state.unsavedChangesFolderDetailModal}
          onCancel={() => {
            this.setState({
              unsavedChangesFolderDetailModal: false,
              ignoreUnsavedChangeModal: false
            })
            executeAllCallbacksUnsavedService()
          }}
          onOk={() => {
            this.setState({
              unsavedChangesFolderDetailModal: false,
              ignoreUnsavedChangeModal: false
            });
            const folder_touch = _.get(this.props, 'update_folder_touch.touch');
            const list_view_touch = _.get(this.props, 'update_list_view_touch.touch');
            executeAllCallbacksAndSubmitUnsavedService(folder_touch, list_view_touch)
          }}
          destroyOnClose="true"
          okText="SAVE"
          cancelText="DISCARD"
        >
          <div className="unsaved-changes-modal_header">You have unsaved changes!</div>
          <div>To save your changes, click Save. To discard your changes click Discard.</div>
        </Modal>
      </div>
    );
  }
}

export default DragDropTree;
