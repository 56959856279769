import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { tree_icon_types } from "../tree_icon_types";

export const everett_content_donor_lists: IFolderType = {
  folder_category: FOLDER_CATEGORY.DONOR_LISTS,
  name: FOLDER_TYPE.everett_content_donor_lists,
  display_name: "Donor Lists",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_list_group, FOLDER_TYPE.everett_donor_list_with_quote, FOLDER_TYPE.everett_donor_list],
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    scrollable: true,
    max_height: 120,
    columns: 2
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_lists
    },
    closed: {
      ...tree_icon_types.donor_lists
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.333,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  client: {
    component_name: COMPONENT_NAME.None
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: false
};
