import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { everett_content } from "./everett_content";
import { everett_content_donor_lists } from "./everett_content_donor_lists";
import { everett_content_donor_seas } from "./everett_content_donor_seas";
import { everett_content_stories } from "./everett_content_stories";
import { everett_display } from "./everett_display";
import { everett_displays } from "./everett_displays";
import { everett_presentations } from "./everett_presentations";
import { everett_root } from "./everett_root";
import { everett_presentation_2x2 } from "./everett_presentation_2x2";
import { everett_story } from "./everett_story";
import { everett_donor } from "./everett_donor";
import { everett_donor_list_with_quote } from "./everett_donor_list_with_quote";
import { everett_donor_sea } from "./everett_donor_sea";
import { everett_donor_sea_background } from "./everett_donor_sea_background";
import { everett_donor_sea_backgrounds } from "./everett_donor_sea_backgrounds";
import { everett_donor_sea_lists } from "./everett_donor_sea_lists";
import { everett_donor_sea_list } from "./everett_donor_sea_list";
import { everett_full_screen_image } from "./everett_full_screen_image";
import { everett_full_screen_image_with_caption } from "./everett_full_screen_image_with_caption";
import { everett_story_with_quote } from "./everett_story_with_quote";
import {everett_donor_list} from "./everett_donor_list";
import {everett_donor_list_group} from "./everett_donor_list_group";

export const everett_types: { [key: string]: IFolderType } = {
  everett_content,
  everett_content_donor_lists,
  everett_content_donor_seas,
  everett_content_stories,
  everett_display,
  everett_displays,
  everett_donor,
  everett_donor_list,
  everett_donor_list_group,
  everett_donor_list_with_quote,
  everett_donor_sea,
  everett_donor_sea_background,
  everett_donor_sea_backgrounds,
  everett_donor_sea_list,
  everett_donor_sea_lists,
  everett_full_screen_image,
  everett_full_screen_image_with_caption,
  everett_presentation_2x2,
  everett_presentations,
  everett_root,
  everett_story,
  everett_story_with_quote
};
