import React from "react";
import { DatePicker } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { BaseInputProps } from "./types";

const defaultDateFormat = "YYYY-MM-DD";

export type DateInputProps = BaseInputProps<number> & { utc: boolean };

const DateInput = (props: DateInputProps) => {
  const onBlur = (ev) => {
    if (props.onBlur) {
      if (props.utc) {
        props.onBlur(props.fieldName, new Date(ev.target.value).getTime());
      } else {
        props.onBlur(
          props.fieldName,
          moment(ev.target.value).utc().toDate().getTime()
        );
      }
    }
  };

  let defaultValue = moment(props.defaultValue);
  if (props.utc) {
    defaultValue = moment(props.defaultValue).utc();
  }

  return (
    <>
      {props.title !== "" && (
        <>
          <label className="form-input-label">{props.title}</label>
          <br />
        </>
      )}
      <DatePicker
        disabled={!props.canUpdate}
        defaultValue={defaultValue}
        format={defaultDateFormat}
        className="date-input"
        onChange={(moment) => props.onChange(props.fieldName, moment.utc().toDate().getTime())}
        onBlur={(ev) => onBlur(ev)}
      />
    </>
  );
};

DateInput.defaultProps = {
  onBlur: () => {},
  defaultDateFormat,
  defaultValue: moment(Date.now()).format(defaultDateFormat),
  utc: false,
};

DateInput.propTypes = {
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  utc: PropTypes.bool,
};

export default DateInput;
