import { COMPONENT_NAME, FOLDER_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const los_angeles_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.los_angeles_root,
  display_name: "Los Angeles",
  acceptable_folder_types: [FOLDER_TYPE.los_angeles_three_panel],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] }
    }
  },
  // preview: {
  //   aspect_ratio: 1.77777,
  //   component_name: COMPONENT_NAME.ComponentLoop1911,
  //   type: PREVIEW_TYPE.COMPONENT
  // },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  }
};
