import React, { useEffect, useState } from "react";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder, getBaseFolderType } from "../../../utils/commonDataUtils";
import Slide1911 from "../../standard/Slide1911";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import get from "lodash/get";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import { DEFAULT_SCROLLBAR_COLOR } from "./EugeneConstants";

const EugeneTemplate003 = (props: IDisplayComponentProps) => {
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [baseFolder, setBaseFolder] = useState(null);
    const [baseFolderType, setBaseFolderType] = useState(null);
    const [headlineConverter, setHeadlineConverter] = useState(null);
    const [subHeadConverter, setSubHeadConverter] = useState(null);
    const [bodyTextConverter, setBodyTextConverter] = useState(null);
    const [bodyTextColor, setBodyTextColor] = useState(DEFAULT_SCROLLBAR_COLOR);
    const [showPlaceholder, setShowPlaceholder] = useState(props.data?.persistent_settings?.data?.show_placeholder || false)
    const [image1FieldType, setImage1FieldType] = useState(null);
    const [image2FieldType, setImage2FieldType] = useState(null);

    const getColorFromRichText = (ops) => {
        return get(ops, '[0][attributes][color]', DEFAULT_SCROLLBAR_COLOR)
    }

    useEffect(() => {
        const myBaseFolderType = getBaseFolderType(props.data, get(baseFolder, 'folder_type', {}));

        const myHeadlineConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.headline.ops", []),
            { multiLineParagraph: false }
        );
        const mySubHeadConverter = new QuillDeltaToHtmlConverter(
            get(baseFolder, "fields.subhead.ops", []),
            { multiLineParagraph: false }
        );

        const myBodyTextOps = get(baseFolder, "fields.body.ops", []);
        const myBodyTextConverter = new QuillDeltaToHtmlConverter( myBodyTextOps, { multiLineParagraph: false });

        const myBodyTextColor = getColorFromRichText(myBodyTextOps)
        setBodyTextColor(myBodyTextColor);

        const color = get(baseFolder, 'fields.color_overlay', 'transparent')
        setBackgroundColor(color);

        setHeadlineConverter(myHeadlineConverter);
        setSubHeadConverter(mySubHeadConverter);
        setBodyTextConverter(myBodyTextConverter);

        setBaseFolderType(myBaseFolderType)
    }, [baseFolder])

    useEffect(() => {
        const myBaseFolder = getBaseFolder(props.data);
        setBaseFolder(myBaseFolder)
    }, [props.data])


    useEffect(() => {
        if (baseFolderType) {
            const fieldTypeImage1 = get(baseFolderType, 'fields[image_1]', null)
            const fieldTypeImage2 = get(baseFolderType, 'fields[image_2]', null)

            setImage1FieldType(fieldTypeImage1)
            setImage2FieldType(fieldTypeImage2)
        }
    }, [baseFolderType])


    const isTextRichEmpty = (text) => {
        if (text && text !== '<p><br/></p>') {
            return false;
        } else {
            return true;
        }
    }

    const getPlaceholder = (placeholder) => {
        if (!showPlaceholder) {
            return '';
        }

        switch (placeholder) {
            case 'headline':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-40em\">${get(baseFolderType, "fields[headline].title", '')}</strong></p>`
            case 'subhead':
                return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(baseFolderType, "fields[subhead].title", '')}</strong></p>`
            case 'body':
                return `<p><strong class=\"ql-font-roboto ql-size-21em\">${get(baseFolderType, "fields[body].title", '')}</strong></p>`

        }
    }

    const getHeaderLineOrPlaceholder = (convertor, type) => {
        let html = convertor ? convertor.convert() : '';

        if (isTextRichEmpty(html) && props.mode === COMPONENT_MODE_CONTEXT.PREVIEW) {
            html = getPlaceholder(type);
        }

        return html
    }

    const headline = getHeaderLineOrPlaceholder(headlineConverter, 'headline');
    const subhead = getHeaderLineOrPlaceholder(subHeadConverter, 'subhead');
    const bodyText = getHeaderLineOrPlaceholder(bodyTextConverter, 'body');

    return (
        <>
            {/* Background Image */}
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    zIndex: -2
                }}
            >
                <Slide1911
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    mode={props.mode}
                    containerWidth={props.containerWidth}
                    containerHeight={props.containerHeight}
                    data={{...props.data, slotNames: ["background"]}}
                    webview_signedurls={props.webview_signedurls}
                />
            </div>
            {/* Background Overlay */}
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    backgroundColor: backgroundColor,
                    zIndex: -1
                }}
            />
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    paddingTop: (9.259259259 / 100) * props.containerHeight,
                    paddingBottom: (9.259259259 / 100) * props.containerHeight,
                    paddingLeft: 0.104166667 * props.containerWidth,
                    paddingRight: 0.104166667 * props.containerWidth,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                }}
            >
                {/* Text */}
                <div
                    style={{
                        height: "100%",
                        flex: 1,
                        color: "#707070",
                    }}
                >
                    <div style={{width: props.containerWidth * 0.291666667, maxHeight: '100%', overflowY: 'hidden'}}>
                        {/* Headline */}
                        <div className="ql-editor-preview springfield-template-003_headline">
                            <div
                                className="ql-editor"
                                style={{fontSize: props.containerHeight / 1000, padding: 0}}
                                dangerouslySetInnerHTML={{__html: headline}}
                            />
                        </div>
                        {/* Sub Head */}
                        {subhead && (
                            <div className="ql-editor-preview springfield-template-003_subhead"
                                 style={{marginTop: props.containerHeight * 0.008329169}}>
                                <div
                                    className="ql-editor"
                                    style={{fontSize: props.containerHeight / 1000, padding: 0}}
                                    dangerouslySetInnerHTML={{__html: subhead}}
                                />
                            </div>
                        )}
                        {/* Body Text */}
                        <div className="ql-editor-preview springfield-template-003_body-text"
                             style={{marginTop: props.containerHeight * 0.046296296}}>
                            <ScrollableFixContainer2103
                                key={`key-${bodyTextColor}`}
                                containerHeight={props.containerHeight * 0.7}
                                containerWidth={props.containerWidth * 0.28}
                                showScrollbar
                                barColor={bodyTextColor}
                            >
                                <div
                                    className="ql-editor"
                                    style={{
                                        fontSize: props.containerHeight / 1000,
                                        padding: 0,
                                        lineHeight: '30em',
                                        color: "#707070",
                                    }}
                                    dangerouslySetInnerHTML={{__html: bodyText}}
                                />
                            </ScrollableFixContainer2103>
                        </div>
                    </div>
                </div>

                {/* Images */}
                <div
                    style={{
                        flex: 1,
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            width: 0.395833333 * props.containerWidth,
                            height: 0.384259259 * props.containerHeight,
                            position: "relative",
                        }}
                    >
                        <Slide1911
                            aws={props.aws}
                            aws_bucket={props.aws_bucket}
                            mode={props.mode}
                            containerWidth={0.395833333 * props.containerWidth}
                            containerHeight={0.384259259 * props.containerHeight}
                            data={{
                                ...props.data,
                                slotNames: ["image_1"],
                                style: {backgroundPosition: 'left bottom'},
                                placeholder: "image 1",
                                fieldType: image1FieldType,
                                showPlaceholder: showPlaceholder
                            }}
                            webview_signedurls={props.webview_signedurls}
                        />
                    </div>
                    <div
                        style={{
                            width: 0.395833333 * props.containerWidth,
                            height: 0.384259259 * props.containerHeight,
                            marginTop: 0.046296296 * props.containerHeight,
                            position: "relative",
                        }}
                    >
                        <Slide1911
                            aws={props.aws}
                            aws_bucket={props.aws_bucket}
                            mode={props.mode}
                            containerWidth={0.395833333 * props.containerWidth}
                            containerHeight={0.384259259 * props.containerHeight}
                            data={{
                                ...props.data,
                                slotNames: ["image_2"],
                                style: {backgroundPosition: 'left top'},
                                placeholder: "image 2",
                                fieldType: image2FieldType,
                                showPlaceholder: showPlaceholder
                            }}
                            webview_signedurls={props.webview_signedurls}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EugeneTemplate003;
