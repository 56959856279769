import React, { CSSProperties, useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "antd";
import get from "lodash/get";

interface ImageSelectInputProps {
  fieldName: string;
  defaultValue: string;
  title: string;
  settings: any;
  onBlur?: any;
  onChange?: any;
  showLabel?: boolean;
  canUpdate?: boolean;
  showCharCount?: boolean;
  options: any[];
}

const ImageSelectInput: React.FC<ImageSelectInputProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<{
    title: string;
  } | null>(null);
  const wrapperStyle: CSSProperties = {
    overflowY: "auto",
    height: "auto",
    maxHeight: "auto",
    padding: "inherit",
    paddingTop: 10,
    paddingBottom: 10,
  };

  if (props.settings) {
    if (props.settings.scrollable) {
      wrapperStyle.overflowY = "scroll";
      wrapperStyle.paddingLeft = 20;
      wrapperStyle.paddingRight = 20;
    }
    if (props.settings.max_height) {
      wrapperStyle.height = props.settings.max_height;
      wrapperStyle.maxHeight = props.settings.max_height;
    }
    wrapperStyle.overflowY = "visible";
  }

  const showHeader = get(props, "settings.show_header", true);
  const columns = get(props, "settings.columns", 2);
  const optionHeight = get(props, "settings.option_height", null);

  useEffect(() => {
    const found = props.options.find((o) => o.value === props.defaultValue);
    if (found) {
      setSelectedOption(found);
    }
  }, [props.defaultValue]);

  const ImageItemInColumns = () => (
    <Row style={{ margin: 0 }} gutter={[16, 16]}>
      {props.options.map((o, i) => {
        const key = `col_${i}`;
        const maxHeight =
          Number(wrapperStyle.height) /
          Math.floor(
            props.options.length / columns
          );

        return (
          <Col
            style={{
              display: "flex",
              textAlign: "center",
              fontWeight: "bold",
              maxHeight: maxHeight,
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor:
              o.value === props.defaultValue
                ? `#1890ff`
                : "transparent",
            }}
            key={key}
            span={24 / columns}
            onClick={() => props.onChange(props.fieldName, o.value)}
          >
            {o.url ? (
              <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={o.url}></img>
            ) : (
              <span>{o.title}</span>
            )}
          </Col>
        );
      })}
    </Row>
  )

  const ImageItemFluid = () => 
    props.options.map((o, i) =>
      <div
        style={{
          textAlign: 'center',
          padding: 10,
          display: 'inline-block',
          height: optionHeight,
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor:
          o.value === props.defaultValue
            ? `#1890ff`
            : "transparent",
        }}
        onClick={() => props.onChange(props.fieldName, o.value)}
      >
        {o.url ? (
          <img style={{ maxWidth: "100%", maxHeight: "calc(100% - 15px)" }} src={o.url}></img>
        ) : (
          <span>{o.title}</span>
        )}
        <br />
        <span>{o.title}</span>
      </div>)

  return (
    <div>
      {/* Optional Folder Type Title */}
      {showHeader && (
        <div
          className="image-select-input-header"
          style={{
            textAlign: "center",
            marginBottom: props.defaultValue ? 0 : 20,
          }}
        >
          Choose a Folder Type
        </div>
      )}
      {props.defaultValue && selectedOption && (
        <>
          <br />
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            {selectedOption?.title ?? ""}
          </div>
        </>
      )}
      {/* Scrollable Body with a height specified in the field configuration */}
      <div className="thumbnail-container" style={{ ...wrapperStyle }}>
        {columns > 0 && ImageItemInColumns()}
        {columns === 0 && ImageItemFluid()}
      </div>
    </div>
  );
};

export default ImageSelectInput;
