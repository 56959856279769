import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { name, wysiwyg } from "../standardized_fields";
import { everettFonts, quillFontSizes, textStyleOptions } from "./everett_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const everett_donor_sea: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.everett_donor_sea,
  display_name: "Donor Sea",
  acceptable_folder_types: [FOLDER_TYPE.everett_donor_sea_lists, FOLDER_TYPE.everett_donor_sea_backgrounds],
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    advanced: {
      title: "Advanced",
      default_expanded: true,
      order: 2,
      accordion: false,
      tab: "advanced"
    }
  },
  fields: {
    // #region General
    name: {
      ...name,
      field_group: "general"
    },
    header: {
      title: "Header",
      field_group: "general",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#333",
            alignment: "left",
            font: fonts[FontsFamilies.ROBOTO][FontVariants.MEDIUM],
            size: 4.5 * 16 + "px",
            lineHeight: "1.15"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    description: {
      title: "Description",
      field_group: "general",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: everettFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#333",
            alignment: "left",
            font: fonts[FontsFamilies.MERRIWEATHER][FontVariants.REGULAR],
            size: 2.25 * 16 + "px",
            lineHeight: "1.25"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    list_name_style: {
      type: "textstyle",
      title: "List Name Style",
      show_char_count: false,
      default: {
        color: "#333",
        alignment: "left",
        font: fonts[FontsFamilies.BARLOW][FontVariants.SEMI_BOLD],
        fontSize: 56,
        lineHeight: "1.15"
      },
      options: textStyleOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      },
      field_group: "general"
    },
    show_horizontal_rule: {
      type: "bool",
      title: "Show Horizontal Rule",
      default: true,
      field_group: "general"
    },
    horizontal_rule_color: {
      type: "color",
      title: "Horizontal Rule Color",
      default: "#333333",
      field_group: "general"
    },
    horizontal_rule_size: {
      type: "number",
      title: "Horizontal Rule Size",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0.01, max: 4 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 1,
      field_group: "general"
    },
    background_image_overlay: {
      type: "color",
      title: "Background Image Overlay",
      default: "",
      field_group: "general"
    },
    // #endregion

    // #region Advanced

    fade_in_donors_time: {
      type: "integer",
      title: "Fade In Donors Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade in after the background is cycled into.",
      field_group: "advanced",
      default: 3
    },

    fade_out_donors_time: {
      type: "integer",
      title: "Fade Out Donors Cycle Time",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip: "Time it will take for donors to fade out before the background is cycled.",
      field_group: "advanced",
      default: 3
    },

    screen_left_and_right_padding: {
      type: "integer",
      title: "Screen Left and Right Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the width of the region",
      field_group: "advanced",
      default: 4
    },

    screen_top_and_bottom_padding: {
      type: "integer",
      title: "Screen Top and Bottom Padding",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 1, max: 75 }] },
      tooltip:
        "Padding left and right on each ones of the regions (screens) expressed in percentage. Percentage is relative to the height of the region",
      field_group: "advanced",
      default: 7
    },

    min_margin_between_names_horizontally: {
      type: "integer",
      title: "Min Horizontal distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another horizontally. The distance is expressed in percentage and is relative to the width of the region minus the specified margin",
      field_group: "advanced"
    },

    min_margin_between_names_vertically: {
      type: "integer",
      title: "Min Vertical distance between names (%)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      tooltip:
        "Minimum distance a name can have from another vertically. The distance is expressed in percentage and is relative to the height of the region minus the specified margin",
      field_group: "advanced"
    }

    // #endregion
  },
  preview: {
    aspect_ratio: 16 / 9,
    component_name: COMPONENT_NAME.Everett2x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "2x2", orientation: "landscape", horizontal: 2, vertical: 2 }]
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  },
  default_children: [
    {
      name: "Backgrounds",
      folder_type: FOLDER_TYPE.everett_donor_sea_backgrounds
    },
    {
      name: "Categories",
      folder_type: FOLDER_TYPE.everett_donor_sea_lists
    }
  ]
};
