/* eslint-disable no-use-before-define */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  ADD_ERROR,
  DELETE_S3_CACHE_SUCCESS,
  FOLDER_TYPE_ALERT,
  GET_NOTIFICATION_SUCCESS,
  GET_USER_NOTIFICATION_SUCCESS,
  INITIALIZE_S3_CACHE_SUCCESS,
  UPDATE_S3_CACHE_SUCCESS,
} from "../actions/actionTypes";
import {
  TREE_MODE_APPLIED_CONTENT,
  TREE_MODE_RESOURCE,
  TREE_MODE_SELECT_APPLIED_CONTENT,
} from "../components/constants";
import AlertNotification from "../components/layout/AlertNotification";
import { IMainState } from "../interfaces/main-state.interface";
import { templates } from "../shared-global/constants/templates";
import { IFolder } from "../shared-global/interfaces/models/folder.interface";
import { IResourceFolder } from "../shared-global/interfaces/models/resource__folder.interface";
import { arrayFromKeyedObject } from "../shared/utils/collectionUtils";
import { removesURLFromCache } from "../shared/utils/fileUtils";
const AWS = require("aws-sdk");

const initialStateSchema: IMainState = {
  applied_content_folder_paths: {},
  applied_content_modal_tree_expanded: [],
  applied_content_tree_expanded: [],
  audit_logs: {},
  alert_trigger: "",
  aws_instance: null,
  breadcrumbs: [],
  companies: {},
  email_subscription_types: [],
  empty: {},
  errors: [],
  folder__folders: {},
  folder__status: {},
  folder_ar_models: {},
  folder_metas: {},
  folder_results: [],
  folder_status: null,
  folder_types: {},
  folder_paths: {},
  folders: {},
  font_groups: {},
  mobile_state: null,
  notifications: {},
  persistent_settings: {},
  project_templates: templates,
  projects: {},
  resource__folders: {},
  resource_results: [],
  resource_tree_expanded: [],
  resource_tree_modal_expanded: [],
  resource__tags: {},
  resource__notes: {},
  resources: {},
  resourcetree_folder__folders: {},
  resourcetree_folders: {},
  resourcetree_resource__folders: {},
  resource__notes_results: [],
  role__users: {},
  role_permissions: {},
  s3Cache: [],
  socket_instance: null,
  system_settings: {},
  theme_settings: {},
  user_companies_menu_details: [],
  tags: {},
  tag_results: [],
  tag_suggestion_results: [],
  test: {}, //temp for testing reselectors
  users: {},
  user__companies: {},
  user__notifications: {},
  user_permissions_grouped: {
    applied_content: {
      grant: [],
      restrict: [],
    },
    backup: {
      grant: [],
      restrict: [],
    },
    csv: {
      grant: [],
      restrict: [],
    },
    folder: {
      grant: [],
      restrict: [],
    },
    field: {
      grant: [],
      restrict: [],
    },
    user: {
      grant: [],
      restrict: [],
    },
    resource: {
      grant: [],
      restrict: [],
    },
    company: {
      grant: [],
      restrict: [],
    },
    audit_log: {
      grant: [],
      restrict: [],
    },
    location: {
      grant: [],
      restrict: [],
    },
  },
  user: {},
  webview_signedurls: null,
  uri_accesses: {},
  component_shared_state: {},
  uri_access_users: {},
  uri_access_inheritable_fields: {},
};

const getNormalized = (state, normalized, removeKeys = []) => {
  let returnObj = {};

  if (normalized || removeKeys) {
    returnObj = {
      ...state,
    };
    if (normalized) {
      Object.keys(normalized.entities).forEach((key) => {
        returnObj[key] = {
          ...returnObj[key],
          ...normalized.entities[key],
        };
      });
    }

    if (removeKeys) {
      // the redux store entities object we are removing a key from
      for (let i = 0; i < removeKeys.length; i += 1) {
        returnObj[removeKeys[i].entities] = _.omit(
          returnObj[removeKeys[i].entities],
          removeKeys[i].ids,
        );
      }
    }
  }
  return returnObj;
};

const initial_clean = _.cloneDeep(initialStateSchema);

export default (state = initialStateSchema, action) => {
  let returnObj = {
    ...state,
  };

  if (state.aws_instance) {
    returnObj.aws_instance = state.aws_instance;
  }
  if (state.socket_instance) {
    returnObj.socket_instance = state.socket_instance;
  }
  if (action && (action.normalized || action.removeKeys)) {
    returnObj = {
      ...state,
    };
    if (action.normalized) {
      Object.keys(action.normalized.entities).forEach((key) => {
        returnObj[key] = {
          ...returnObj[key],
          ...action.normalized.entities[key],
        };
      });
    }

    if (action.removeKeys) {
      // the redux store entities object we are removing a key from
      for (let i = 0; i < action.removeKeys.length; i += 1) {
        returnObj[action.removeKeys[i].entities] = _.omit(
          returnObj[action.removeKeys[i].entities],
          action.removeKeys[i].ids,
        );
      }
    }
    return returnObj;
  }

  if (action && action.overrides) {
    returnObj = {
      ...state,
    };
    action.overrides.map((x) => {
      returnObj[x.key] = x.value;
      return null;
    });
    return returnObj;
  }
  // removes key(s) from a redux store entities object when passed removeKeys
  // from the saga we will pass in data with this format: {removeKeys: [{entities: 'folders', ids: [106]}]}

  switch (action.type) {
    case "THEME_SETTINGS_SUCCESS":
      return {
        ...state,
        theme_settings: action.theme_settings,
      };
    case "FETCH_URI_ACCESS_INHERITABLE_FIELDS_SUCCESS":
      return {
        ...state,
        uri_access_inheritable_fields: action.inheritable_fields,
      };
    case "CHECK_UPDATE_FOLDER_ALONE_SUCCESS": {
      if (
        action.folder.id === action.applied_content_selected_folder &&
        action.updater !== state.user.id
      ) {
        AlertNotification(
          "warning",
          "This folder was edited by another user, saving will overwrite the changes",
        );
      }
    }
    case "BREADCRUMB_SET_SUCCESS":
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    case "USER_GET_COMPANIES_MENU_DETAILS_SUCCESS":
      return {
        ...state,
        user_companies_menu_details: action.user_companies_menu_details,
      };
    case "USER_GET_DATA_SUCCESS":
      returnObj = {
        ...state,
      };

      /**
       * map permisions with user_roles and get permision and projectId
       */
      const getPermisionProject = (permission, roleUsers) => {
        const rolePermissions = action.role__permissions;
        const roles = action.roles;

        let permissionWithProject = rolePermissions.filter(
          (rp) => rp.permission === permission.id,
        );

        permissionWithProject = _.flatMap(permissionWithProject, (frp) => {
          return roles.filter((r) => r.id === frp.role);
        });
        permissionWithProject = _.flatMap(permissionWithProject, (r) => {
          return roleUsers.filter((ru) => ru.role === r.id);
        });
        permissionWithProject = _.flatMap(permissionWithProject, (fru) => {
          return {
            ...permission,
            project: fru.project,
          };
        });

        return permissionWithProject;
      };

      /**
       * grant permission to user permission group
       */
      const grantUserPermissionsGrouped = (
        returnObject,
        roleUser,
        permission,
      ) => {
        const permissions = getPermisionProject(permission, roleUser);
        returnObject.user_permissions_grouped[permission.area].grant.push(
          ...permissions,
        );
      };

      /**
       * restrict permission to user permission group
       */
      const restrictUserPermissionsGrouped = (
        returnObject,
        roleUser,
        permission,
      ) => {
        //getPermisionProject(permission, roleUser);
        const permissions = getPermisionProject(permission, roleUser);
        returnObject.user_permissions_grouped[permission.area].restrict.push(
          ...permissions,
        );
      };

      action.permissions.map((x) => {
        x.create = false;
        x.read = false;
        x.update = false;
        x.delete = false;
        if (x.action.includes("c")) {
          x.create = true;
        }
        if (x.action.includes("r")) {
          x.read = true;
        }
        if (x.action.includes("u")) {
          x.update = true;
        }
        if (x.action.includes("d")) {
          x.delete = true;
        }
        if (x.action_type === "grant") {
          grantUserPermissionsGrouped(returnObj, action.role__users, x);
        } else {
          restrictUserPermissionsGrouped(returnObj, action.role__users, x);
        }
        return null;
      });
      returnObj.user = action.user;
      returnObj.aws_instance = action.aws_instance;
      returnObj.persistent_settings = action.persistent_settings;
      returnObj.email_subscription_types = action.email_subscription_types;
      return returnObj;
    case "SAVE_SOCKET_INSTANCE_SUCCESS":
      returnObj = {
        ...state,
        socket_instance: action.socket_instance,
      };
      return returnObj;
    case "LOGOUT_SUCCESS":
      return _.cloneDeep(initial_clean);
    case "TOGGLE_FOLDER_EXPANDED":
      returnObj = {
        ...state,
        applied_content_tree_expanded:
          state.applied_content_tree_expanded.slice(0),
        resource_tree_expanded: state.resource_tree_expanded.slice(0),
      };

      // allow to be overrided with action
      const treemode = action.payload.treemode;

      let doOpenClose = true;
      if (action.payload.doOpenClose === false) {
        doOpenClose = false;
      }

      let doSelect = true;
      if (action.payload.doSelect === false) {
        doSelect = false;
      }

      if (treemode === TREE_MODE_APPLIED_CONTENT) {
        if (doOpenClose) {
          if (
            returnObj.applied_content_tree_expanded.includes(
              action.payload.treeId,
            )
          ) {
            const index = returnObj.applied_content_tree_expanded.indexOf(
              action.payload.treeId,
            );
            if (index !== -1) {
              returnObj.applied_content_tree_expanded.splice(index, 1);
            }
          } else {
            returnObj.applied_content_tree_expanded.push(action.payload.treeId);
          }
        }
      } else {
        if (doOpenClose) {
          if (
            returnObj.resource_tree_expanded.includes(action.payload.treeId)
          ) {
            const index = returnObj.resource_tree_expanded.indexOf(
              action.payload.treeId,
            );
            if (index !== -1) {
              returnObj.resource_tree_expanded.splice(index, 1);
            }
          } else {
            returnObj.resource_tree_expanded.push(action.payload.treeId);
          }
        }
      }

      return returnObj; // /_.cloneDeep(returnObj)
    case "TOGGLE_FOLDER_EXPANDED_MOVE_BULK_MODAL_SUCCESS":
      returnObj = {
        ...state,
        applied_content_modal_tree_expanded:
          state.applied_content_modal_tree_expanded.slice(0),
      };

      if (
        returnObj.applied_content_modal_tree_expanded.includes(
          action.payload.treeId,
        )
      ) {
        const index = returnObj.applied_content_modal_tree_expanded.indexOf(
          action.payload.treeId,
        );
        if (index !== -1) {
          returnObj.applied_content_modal_tree_expanded.splice(index, 1);
        }
      } else {
        returnObj.applied_content_modal_tree_expanded.push(
          action.payload.treeId,
        );
      }
      return returnObj;
    case "TOGGLE_MODAL_TREE_FOLDER_EXPANDED_SUCCESS":
      returnObj = { ...state };

      returnObj.resource_tree_modal_expanded = _.cloneDeep(
        state.resource_tree_modal_expanded,
      );
      if (returnObj.resource_tree_modal_expanded.includes(action.treeId)) {
        const index = returnObj.resource_tree_modal_expanded.indexOf(
          action.treeId,
        );
        if (index !== -1) {
          returnObj.resource_tree_modal_expanded.splice(index, 1);
        }
      } else {
        returnObj.resource_tree_modal_expanded.push(action.treeId);
      }
      return returnObj;
    case "SET_FOLDERS_EXPANDED_SUCCESS": {
      if (action.treeMode === TREE_MODE_APPLIED_CONTENT) {
        returnObj = {
          ...state,
          applied_content_tree_expanded: action.treeIds,
        };
        return returnObj;
      } else if (action.treeMode === TREE_MODE_RESOURCE) {
        returnObj = {
          ...state,
          resource_tree_expanded: action.treeIds,
        };
        return returnObj;
      } else if (action.treeMode === TREE_MODE_SELECT_APPLIED_CONTENT) {
        returnObj = {
          ...state,
          applied_content_modal_tree_expanded: action.treeIds,
        };
        return returnObj;
      } else {
        returnObj = {
          ...state,
          resource_tree_modal_expanded: action.treeIds,
        };
        return returnObj;
      }
    }
    case "DELETE_USER_SUCCESS":
      returnObj = { ...state };
      delete returnObj.users[action.user_id]; // Delete keys for users
      const role_users_to_delete_id = Object.values(returnObj.role__users)
        .filter((x: any) => x.user === action.user_id)
        .map((x: any) => x.id);
      role_users_to_delete_id.forEach((id) => delete returnObj.role__users[id]);
      return returnObj;
    case "CHANGE_COMPANY_USER_SUCCESS":
      const company = state.companies[action.company_id];
      AWS.config.credentials = {
        accessKeyId: company.aws_access_key,
        secretAccessKey: company.aws_secret_access_key,
        region: "us-west-2",
      };
      returnObj = {
        ...state,
        applied_content_modal_tree_expanded: [],
        applied_content_tree_expanded: [],
        aws_instance: AWS,
      } as IMainState;
      returnObj.user.company = action.company_id;
      return returnObj;
    case "DELETE_DISPLAY_IDENTIFIER_SUCCESS":
    case "GET_DISPLAY_SUCCESS":
      returnObj = { ...state };
      returnObj.folders[action.folder_id].last_reported = action.new_obj;
      return returnObj;
    case "SEARCH_MAIN_SUCCESS":
      returnObj = { ...state };
      returnObj.folder_results = action.folder_results || [];
      returnObj.resource_results = action.resource_results || [];
      returnObj.tag_results = action.tag_results || [];
      returnObj.resource__notes_results = action.resource__notes_results || [];

      const resource__folders = arrayFromKeyedObject(state.resource__folders);
      returnObj.folder_results.forEach((result) => {
        const associated_join = resource__folders.find(
          (rf: IResourceFolder) => rf.folder === Number(result),
        );
        if (associated_join) {
          const existingResult = returnObj.resource_results.find(
            (rr) => rr === result,
          );
          if (!existingResult) {
            returnObj.resource_results.push(
              associated_join.resource.toString(),
            );
          }
        }
      });
      return returnObj;
    case "SEARCH_TAG_SUCCESS":
      returnObj = { ...state };
      returnObj.tag_suggestion_results = action.tag_suggestion_results || [];
      return returnObj;
    case GET_NOTIFICATION_SUCCESS:
      const oldNotifications = state.notifications;
      const copyStateN = _.cloneDeep({ notifications: state.notifications });
      const newAllState: any = getNormalized(
        copyStateN,
        action.notifications_normalizad,
      );
      const newAllNotifications = newAllState.notifications || [];
      const areEqualNotification = _.isEqual(
        newAllNotifications,
        oldNotifications,
      );
      if (areEqualNotification) {
        return state;
      }
      returnObj = { ...state };
      returnObj.notifications = newAllNotifications;

      return returnObj;
    case GET_USER_NOTIFICATION_SUCCESS:
      const oldUserNotifications = state.user__notifications;
      const copyStateUN = _.cloneDeep({
        user__notifications: state.user__notifications,
      });
      const newAllStateUserNotification: any = getNormalized(
        copyStateUN,
        action.user__notification_normalized,
      );
      const newAllUserNotifications =
        newAllStateUserNotification.user__notifications || [];
      const areEqualUserNotification = _.isEqual(
        newAllUserNotifications,
        oldUserNotifications,
      );
      if (areEqualUserNotification) {
        return state;
      }
      returnObj = { ...state };
      returnObj.user__notifications = newAllUserNotifications;

      return returnObj;
    case INITIALIZE_S3_CACHE_SUCCESS:
      return { ...state, s3Cache: action.data };
    case UPDATE_S3_CACHE_SUCCESS:
      if (action.folder__status) {
        const folderIds = [
          ...new Set(action.folder__status.map((fs) => fs.folder)),
        ];
        const touchedResourceFolders = Object.values(
          state.resource__folders,
        ).filter((rf: any) => folderIds.indexOf(rf.folder) > -1);
        const touchedResourceFoldersIds = touchedResourceFolders.map(
          (rf: any) => rf.id,
        );
        const touchedResurceIds = [
          ...new Set(touchedResourceFolders.map((rf: any) => rf.resource)),
        ];
        // Clearing s3Cache
        for (let resourceFolderId of touchedResourceFoldersIds) {
          const resFolderUuid = `resource-folder-${resourceFolderId}`;
          const resFolderPreviewUuid = `resource-folder-preview-${resourceFolderId}`;
          const resFolderThumbUuid = `resource-folder-thumbnail-${resourceFolderId}`;
          removesURLFromCache(resFolderUuid);
          removesURLFromCache(resFolderThumbUuid);
          removesURLFromCache(resFolderPreviewUuid);
        }
        for (let resourceId of touchedResurceIds) {
          const resUuid = `resource-${resourceId}`;
          const resThumbUuid = `resource-thumbnail-${resourceId}`;
          removesURLFromCache(resUuid);
          removesURLFromCache(resThumbUuid);
        }
      } else {
        for (let i = 0; i < action.resources.length; i++) {
          const res = action.resources[i];
          const resUuid = `resource-${res.id}`;
          const resThumbUuid = `resource-thumbnail-${res.id}`;
          removesURLFromCache(resUuid);
          removesURLFromCache(resThumbUuid);
        }
        for (let i = 0; i < action.resource__folders.length; i++) {
          const resF = action.resource__folders[i];
          const resFolderUuid = `resource-folder-${resF.id}`;
          const resFolderPreviewUuid = `resource-folder-preview-${resF.id}`;
          const resFolderThumbUuid = `resource-folder-thumbnail-${resF.id}`;
          removesURLFromCache(resFolderUuid);
          removesURLFromCache(resFolderThumbUuid);
          removesURLFromCache(resFolderPreviewUuid);
        }
      }
      const s3CacheStr = localStorage.getItem("s3cache");
      let s3Cache = [];
      if (s3CacheStr) {
        s3Cache = JSON.parse(s3CacheStr);
      }
      return { ...state, s3Cache };
    case DELETE_S3_CACHE_SUCCESS:
      for (let i = 0; i < action.resources.length; i++) {
        const res = action.resources[i];
        const resUuid = `resource-${res.id}`;
        const resThumbUuid = `resource-thumbnail-${res.id}`;
        removesURLFromCache(resUuid);
        removesURLFromCache(resThumbUuid);
      }
      for (let i = 0; i < action.resource__folders.length; i++) {
        const resF = action.resource__folders[i];
        const resFolderUuid = `resource-folder-${resF.id}`;
        const resFolderThumbUuid = `resource-folder-thumbnail-${resF.id}`;
        removesURLFromCache(resFolderUuid);
        removesURLFromCache(resFolderThumbUuid);
      }
      const s3CacheDeletedStr = localStorage.getItem("s3cache");
      let s3CacheDeleted = [];
      if (s3CacheDeletedStr) {
        s3CacheDeleted = JSON.parse(s3CacheDeletedStr);
      }
      return { ...state, s3Cache: s3CacheDeleted };
    case ADD_ERROR:
      return { ...state, errors: action.payload.errors };
    case FOLDER_TYPE_ALERT:
      const trigger = action.payload?.trigger ?? "";
      return { ...state, alert_trigger: trigger };
    case "UPDATE_RESOURCE_TREE":
      return {
        ...state,
        resourcetree_folders: action.resourcetree_folders as {
          [key: string]: IFolder;
        },
      };
    default:
      return state;
  }
};
