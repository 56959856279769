import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";

export const mckenzie_content_mobile: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.mckenzie_content_mobile,
  display_name: "McKenzie Content Mobile",
  acceptable_folder_types: [FOLDER_TYPE.mckenzie_mobile],
  fields: {
    name: name
  },
  preview: {
    aspect_ratio: 1.333,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE,
    hide_border: true
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.default_open
    },
    closed: {
      ...tree_icon_types.default_closed
    }
  }
};
