import { boolean, mixed, number, object, string } from 'yup';
import { URI_ACCESS_TYPE } from '../../shared-global/interfaces/models/uri_access.interface';

export const uriAccessInitializeValidationSchema = object().shape({
  identifier: string().required()
});

export const getUriAccessByFolderValidationSchema = object().shape({
  folderId: number().required()
});

export const upsertUriAccessValidationSchema = object().shape({
  isUpdate: boolean().required(),
  identifier: string().required(),
  access_type: mixed<URI_ACCESS_TYPE>().oneOf(Object.values(URI_ACCESS_TYPE)),
  folder: number().required(),
  id: number(),
  password: string()
});
