export default {
  field_groups: {
    default: {
      title: 'General',
      default_expanded: true,
    },
  },
  fields: {
    resource: {
      title: 'CSV File',
      type: 'file',
      slot_type: 'fixed',
      acceptable_contents: ['text/csv'],
      limit: null,
      slots: [{
        name: 'csv',
        title: 'CSV File',
        aspect: null,
      }],
      default: null,
      cropper: false,
    },
  },
};
