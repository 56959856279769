import { Modal } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { roleUserAdd, userGetData } from "../../actions/userActions";
import roleformConfig from "../../config/role_user";
import roleprojectformConfig from "../../config/role_user_project";
import { arrayFromKeyedObject } from "../../shared/utils/collectionUtils";
import DynamicForm from "../form/DynamicForm";
import AlertNotification from "../layout/AlertNotification";

interface IAddUserRoleModalProps {
  roles: any;
  projects: any;
  visible: boolean;
  companyId: number;
  userId: number;
  user: any;
  role_inputs: any;
  userGetData: Function;
  roleUserAdd: Function;
  onOkay: Function;
  onCancel: Function;
}

interface IAddUserRoleModalState {
  roles: any;
  submitting: boolean;
  scope: any;
  projects: any;
}

class AddUserRoleModal extends Component<
  IAddUserRoleModalProps,
  IAddUserRoleModalState
> {
  _dynamicFormRef = null;
  constructor(props) {
    super(props);
    this.state = {
      scope: "company",
      roles: "",
      projects: "",
      submitting: false
    };
  }

  getConfig(scope, formConfig, projects, roles) {
    formConfig.fields["roles"].options = roles;
    if (scope === "project") {
      formConfig.fields["projects"].options = projects;
    }
    return formConfig;
  }

  roleChangeHandler = (_fieldName, value, fieldValues) => {
    let scope;
    //get the role based on the field value, check its scope
    if (value && this.props.roles[value]) {
      scope = this.props.roles[value].scope;
    }

    if (scope === "project") {
      this.setState({
        scope: scope,
        roles: value,
        projects: fieldValues.projects
      });
    } else if (scope === "company") {
      this.setState({
        scope: scope,
        roles: value
      });
    }
  };

  onSubmit = () => {
    this.setState({
      submitting: true
    });
    let isValid = this._dynamicFormRef.isValid();
    if (isValid) {
      let fieldValues = this._dynamicFormRef.getFieldValues();
      this.props.roleUserAdd(
        fieldValues.roles,
        this.props.userId,
        fieldValues.projects,
        Number(this.props.companyId),
        this.onInsert,
      );
      this.props.onOkay();
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onInsert = () => {
    this.setState({ submitting: false });
    AlertNotification("success", "Sucess", "Record successfully inserted");
    //editing self refresh roles on self
    if (Number(this.props.user.id) === Number(this.props.userId)) {
      this.props.userGetData();
    }
  };

  onError = (message) => {
    this.setState({ submitting: false });
    AlertNotification("error", "Error", message || "Something went wrong.");
  };

  render() {
    if (this.props.roles == null) {
      return null;
    }

    let obj: { roles: any; projects?: any } = {
      roles: this.state.roles
    };
    let config = this.getConfig(
      "company",
      roleformConfig,
      null,
      this.props.role_inputs
    );
    if (this.state.scope === "project") {
      const projects = [];
      let projectArray = Object.values(this.props.projects) as {
        name: string;
        id: number;
      }[];
      projectArray.forEach((project) => {
        projects.push({ title: project.name, value: project.id });
      });
      config = this.getConfig(
        "project",
        roleprojectformConfig,
        projects,
        this.props.role_inputs
      );

      obj = {
        roles: this.state.roles,
        projects: this.state.projects
      };
    }

    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onSubmit}
        destroyOnClose={true}
      >
        <DynamicForm
          submitting={this.state.submitting}
          ref={(ref) => {
            if (ref) {
              this._dynamicFormRef = ref;
            }
          }}
          fieldGroupsConfig={config.field_groups}
          fieldsConfig={config.fields}
          fieldValues={obj}
          mode={"add"}
          onError={this.onError}
          onChangeHandlers={[
            {
              name: "roles",
              callBackFunction: (name, value, fieldValues) =>
                this.roleChangeHandler(name, value, fieldValues)
            }
          ]}
          showSubmitButton={false}
          enableFieldGrouping={false}
          canUpdate={true}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let user = null;
  let company = null;
  let roles = null;
  let projects = null;

  // TODO antipattern
  if (Object.keys(state.data.companies).length > 0) {
    try {
      company = state.data.companies[ownProps.companyId] || null;

      roles = state.data.roles || null;
      projects = state.data.projects || null;
      user = state.data.users[ownProps.userId] || null;
    } catch (Throwable) {}
  }

  projects = arrayFromKeyedObject(state.data.projects);
  projects = projects.filter((x) => x.company === Number(ownProps.companyId));

  let role_inputs = [];
  let roleArray = arrayFromKeyedObject(state.data.roles);
  roleArray.forEach((role) => {
    role_inputs.push({ title: role.name, value: role.id });
  });

  return {
    user,
    company,
    roles,
    role_inputs,
    projects
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  roleUserAdd: (role, user, project, company, onSuccess, onFail) => {
    dispatch(roleUserAdd(role, user, project, company, onSuccess, onFail));
  },
  userGetData: (onSuccess, onFail) => {
    dispatch(userGetData(onSuccess, onFail));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserRoleModal);
