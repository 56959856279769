import {
    GET_CSRF,
    LOGIN,
    LOGOUT,
    REQUEST_PASSWORD_RESET,
    RESET_PASSWORD
} from './actionTypes';

export const getCSRF = (onSuccess?, onFail?) => ({
  type: GET_CSRF,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const login = (email, password, method, onSuccess?, onFail?) => ({
  type: LOGIN,
  payload: {
    email: email,
    password: password,
    method: method
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const logout = (onSuccess) => ({
  type: LOGOUT,
  payload: {

  },
  onSuccess: onSuccess || null,
});

export const request_password_reset = (email, captcha, onSuccess?, onFail?) => ({
  type: REQUEST_PASSWORD_RESET,
  payload: {
    email: email,
    captcha: captcha
  },
  onSuccess: onSuccess,
  onFail: onFail
});

export const reset_password = (token, password, captcha, onSuccess?, onFail?) => ({
  type: RESET_PASSWORD,
  payload: {
    token,
    password,
    captcha
  },
  onSuccess: onSuccess,
  onFail: onFail
});