import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { tree_icon_types } from "../tree_icon_types";

export const eugene_presentation: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.eugene_presentation,
  display_name: "Eugene Presentation",
  acceptable_folder_types: [FOLDER_TYPE.eugene_attract_loop, FOLDER_TYPE.eugene_search, FOLDER_TYPE.eugene_menu],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    },
    cycle_time: {
      type: "integer",
      title: "Attract Loop Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: [
        ...cycle_time_tooltips,
        "<span><b>DISCLAIMER: </b>Only applicable if there's an Attract Loop folder</span><br /><br />"
      ]
    }
  },
  preview: {
    react_url: "theme/eugene",
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.EugeneContainer,
    type: PREVIEW_TYPE.COMPONENT
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  isPresentation: true
};
