import { Row } from "antd";
import React, { Fragment } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { moveItems } from "../DynamicFileInput/FileInputService";
import MediaFileInput from "../DynamicFileInput/MediaFileInput";
import Card from "./CarouselMediaCard";
import {getFileType} from "../../../../shared/utils/fileUtils";

const CarouselMedia = ({
  items,
  defaultValue,
  current,
  acceptable,
  onClickItem,
  onMoveCard,
  updateDefaultValueItem,
}) => {
  const moveCard = (dragIndex, hoverIndex) => {
    const { itemsMoved, valuesMoved } = moveItems(
      dragIndex,
      hoverIndex,
      items,
      defaultValue
    );
    onMoveCard(itemsMoved, valuesMoved, hoverIndex);
  };

  const getDynamicRenderedInputs = () => {
    let rendered = null;
    if (items) {
      rendered = items.map((item, i) => {
        let data = null;
        if (defaultValue) {
          data = defaultValue.find((dv) => dv.rank === item.rank);
        }
        if (!data) {
          data = { status: "none" };
        }
        const isActive = current && item.identifier === current.identifier;
        const extraClass = isActive
          ? "carousel-media-file-input_card-active"
          : "";
        let key = item.identifier;
        if (data.id) {
          key = data.id;
        }
        const isVideo = getFileType(data.fileName || '') === 'video';
        return (
          <Card key={key} index={i} id={item.identifier} moveCard={moveCard}>
            <div className={`carousel-media-file-input_card ${extraClass}`}>
              <MediaFileInput
                key={key}
                data={data}
                item={item}
                acceptable={acceptable}
                onClick={onClickItem}
                updateDefaultValueItem={updateDefaultValueItem}
                size="full"
                showVideoIcon={isVideo}
              />
            </div>
          </Card>
        );
      });
    }
    return rendered;
  };

  return (
    <Fragment>
      <div className="carousel-media-file-input">
        <Row className="carousel-media-file-input_image-container">
          <DndProvider backend={HTML5Backend}>
            {getDynamicRenderedInputs()}
          </DndProvider>
        </Row>
      </div>
    </Fragment>
  );
};

export default CarouselMedia;
