import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Modal, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { PDG_COLORS } from "../../shared-global/enums/ui-enums";
import { dateFormatted } from "../../utils/dateUtils";
import DynamicForm from "../form/DynamicForm";
import AlertNotification from "../layout/AlertNotification";
import { XXLT_GRAY } from "../layout/LayoutConstants";
import TextWithHighlights from "../tree/TextWithHighlights";
import Spaner from "../utils/Spaner";
import TextModal from "../utils/TextModal";
import FilterActions from "./FilterActions";
import { SortableJsonListViewMemo } from "./SortableJsonListViewMemo";

let timeoutHandler = null;
let timeoutHandler2 = null;
const MODE_SELECT = 0;
const MODE_EDIT = 1;
const MODE_SORT = 2;

const SORT_RANK = "rank";
const SORT_OTHER = "other";
const DYNAMIC = "dynamic";

const WIDTH_EXTRA_SPACE_X_2 = 1200;
const WIDTH_EXTRA_SPACE_X_3 = 1500;
const MIN_WIDTH_FULL_RESOLUTION = 670;
const MIN_WIDTH_MEDIUM_RESOLUTION = 300;

const lt_gray_border = `.5px solid ${XXLT_GRAY}`;

const getCellWidthProportionCalculation = (base, tableWidth) => {
  let proportion = 1;
  if (tableWidth > WIDTH_EXTRA_SPACE_X_3) {
    proportion = 2; // 160%
  } else if (tableWidth > WIDTH_EXTRA_SPACE_X_2) {
    proportion = 1.6; // 130%
  }
  return base * proportion;
};

const getCellWidth = (type, tableWidth) => {
  switch (type) {
    case "actions":
    case "integer":
    case "money":
    case "cycle_time":
    case "file":
    case "sorting_options":
      return 100;
    case "select":
      return 200;
    case "icon":
    case "bool":
      return 50;
    case "months-left":
      return 200;
    case "textarea":
    case "date":
    case "text_link":
    case "textarea_modal":
    case "text":
      return 150;
    case "text-lg":
      return 200;
    case "type":
      return 150;
    case "updatedBy":
      return 150;
    case "updatedAt":
      return 100;
    default:
      return 0;
  }
};

const getFilterFunction = (field) => {
  switch (field) {
    case "updatedBy":
      return (value, record) => {
        if (
          !value ||
          value.length === 0 ||
          !Array.isArray(value) ||
          record.new
        ) {
          return true;
        }
        if (value.includes("(empty)")) {
          return !record.updatedBy;
        } else {
          return value.includes(record.updatedBy);
        }
      };

    default:
      return () => {};
  }
};

const styles = {
  cell: {
    display: "inline-block",
    textOverflow: "ellipsis"
  },
  cellFlex: {
    display: "flex",
    textOverflow: "ellipsis"
  },
  rowGrow: {
    flexGrow: 2
  },
  rowRight: {
    justifyContent: "right",
    paddingRight: 15
  },
  checkboxCell: {
    marginTop: 10,
    padding: 0,
    minWidth: 50,
    width: 50
  },
  iconCell: {
    display: "flex",
    padding: 0,
    width: 50,
    minWidth: 50,
    justifyContent: "center",
    alignItems: "center"
  },
  cellEditMode: {
    marginTop: 10,
    padding: 0
  },

  beingDragged: {
    background: "#2699FB",
    color: "white",
    "& svg": {
      color: "white"
    }
  },
  smallButton: {
    width: 30,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    marginRight: 5,
    marginLeft: 5
  },
  listContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& #actions": {
      display: "flex",
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      "& #multi-action": {
        flex: 1
      },
      "& #top-buttons": {
        textAlign: "right",
        flex: 1
      }
    },
    "& #list": {
      width: "100%",
      marginTop: 20,
      overflowX: "scroll",
      "& .list_new": {
        background: "#1890ff94",
        color: "white"
      }
    },
    "& #bottom-buttons": {
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      textAlign: "right",
      marginTop: 20
    },
    "& #headers": {
      background: "#F9F9F9",
      color: "#666666",
      width: "100%"
    },
    "& .list-container": {
      height: ({ listContainerHeight }) => listContainerHeight
    },
    "& .ReactVirtualized__Grid ReactVirtualized__List list-container": {
      // width: '100%',
      "& .ReactVirtualized__Grid__innerScrollContainer": {
        // width: ({ columnsWidthSum }) => [[columnsWidthSum], '!important'],
        // maxWidth: '100%!important',
      }
    }
  }
};

const getCellTypeSafe = (column) => {
  let cellType;
  if (column.type) {
    cellType = column.type;
  } else if (column.field) {
    cellType = column.field;
  } else {
    cellType = "file";
  }
  return cellType;
};

const useStyles = createUseStyles(styles);
const getWidthFromColumns = (listColumns, componentWidth) => {
  let width = 25; // Buffer scrollbar etc
  listColumns.forEach((column) => {
    const columnType = getCellTypeSafe(column);
    const cellWidth = getCellWidth(columnType, componentWidth);

    width += cellWidth;
  });

  return width;
};

const hamburgerColumn = {
  type: "icon",
  sort: false,
  editable: false,
  title: "",
  field: "hamburger"
};

const actionsColumn = {
  sort: false,
  editable: false,
  title: "",
  field: "actions",
  type: "actions"
};

const JsonListView = (props) => {
  const wrappingDivRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const [unsavedChanges, _setUnsavedChanges] = useState(false);
  const unsavedChangesRef = useRef(unsavedChanges);
  const setUnsavedChanges = (data) => {
    unsavedChangesRef.current = data;
    props.setUpdateListViewTouch(data);
    _setUnsavedChanges(data);
  };
  const [showDeleteConfirmationModal, setShowDeleteConfirmModal] = useState(
    false
  );
  const [unsavedChangedItems, setUnsavedChangedItems] = useState([]);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [outerWidth, setOuterWidth] = useState(432);
  const [componentWidth, setComponentWidth] = useState(432);
  const [items, setItems] = useState(props.listItems);
  const [mode, setMode] = useState(MODE_SELECT);
  const [sortMode, setSortMode] = useState(SORT_OTHER);
  const [acceptableFolderTypes, setAcceptableFolderTypes] = useState([]);
  const [newAddedItemIndex, setNewAddedItemIndex] = useState(0);
  const [editButtonDisabled, setEditButtonDisabled] = useState(false);
  const [forceUpdateInt, setForceUpdateInt] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [listContainerHeight, setListContainerHeight] = useState(
    props.listHeight
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [currentSort, setCurrentSort] = useState<{
    field: string;
    direction: "asc" | "desc";
  }>({
    field: props.sorting,
    direction: "asc"
  });
  const [addedItems, setAddedItems] = useState([]);
  // In this array are saved all initial folder ids in the init of this session (list loaded first time)
  const [allFolderIdsInThisSession, setAllFolderIdsInThisSession] = useState(
    null
  );
  const [allItems, setAllItems] = useState(props.listItems);
  const [filteredItems, setFilteredItems] = useState(allItems);
  const [columns, setColumns] = useState([
    hamburgerColumn,
    ...props.listColumns,
    actionsColumn
  ]);
  const [columnsExtraClass, setColumnsExtraClass] = useState({});
  const [columnsWidthSum, setColumnsWidthSum] = useState(
    getWidthFromColumns(columns, outerWidth)
  );
  const [styleValues, setStyleValues] = useState({
    listContainerHeight,
    columnsWidthSum
  });

  // filters
  const [columnFilters, setColumnFilters] = useState({});
  const [initialColumnFilters, setInitialColumnFilters] = useState({});
  const [functionFilters, setFunctionFilters] = useState({});

  // search
  const [searchString, setSearchString] = useState(null);
  const [showAddButton, setShowAddButton] = useState(true);

  const classes = useStyles(styleValues);

  const renderReadOnlySpan = (
    key,
    cellValue,
    cellWidth,
    cellType,
    index,
    onClick = null
  ) => {
    const tooltipVisible = cellType === "text" && cellValue;
    let tagContent = null;
    let cellValueAndClick = null;

    if (cellType !== "text_link" && cellType !== "textarea_modal") {
      cellValueAndClick = onClick ? (
        <Button type="link" onClick={onClick}>
          <TextWithHighlights
            text={cellValue}
            highlight={searchString}
            highlightStyle={{ backgroundColor: PDG_COLORS.MD_WARNING }}
          />
        </Button>
      ) : (
        cellValue
      );
      tagContent = (
        <TextWithHighlights
          text={cellValueAndClick}
          highlight={searchString}
          highlightStyle={{ backgroundColor: PDG_COLORS.MD_WARNING }}
        />
      );
    } else if (cellType === "textarea_modal") {
      tagContent = <TextModal text={cellValue} />;
    } else {
      tagContent = (
        <a href={cellValue?.url} target="_blank">
          {cellValue?.text}
        </a>
      );
    }

    let tag = (
      <span
        key={key}
        className={classes.cell}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {tagContent}
      </span>
    );

    return tooltipVisible ? (
      <Tooltip key={`tooltip-${key}`} placement="leftTop" title={cellValue}>
        {tag}
      </Tooltip>
    ) : (
      tag
    );
  };

  const renderDynamicFormSpan = (
    key,
    fieldGroupsConfig,
    fieldsConfig,
    fieldValues,
    cellWidth,
    item,
    index
  ) => {
    return (
      <span
        key={key}
        className={[classes.cell, classes.cellEditMode].join(" ")}
        style={{
          width: cellWidth,
          minWidth: cellWidth
        }}
      >
        <DynamicForm
          key={key}
          fieldGroupsConfig={fieldGroupsConfig}
          fieldsConfig={fieldsConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => {}}
          onError={() => {}}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onBlur={(fields) => {
            const fieldNames = Object.keys(fields);
            fieldNames.forEach((f) => {
              onChange(f, fields[f], fields, item, index);
            });
          }}
          classOverride="noclass"
          canUpdate={mode === MODE_EDIT}
          submitting={false}
        />
      </span>
    );
  };

  const getUserNameAndLastName = (cellValue) => {
    let userName = "";
    try {
      const user = props.users[cellValue];
      if (user) {
        userName = user.first_name + " " + user.last_name;
      }
    } catch (e) {}
    return userName;
  };

  const isNewOrSavedNewInThisSession = (item) => {
    if (item.new) {
      return true;
    }
    return false;
  };

  const Row = ({ style, item, columns, mode, onChange, itemIndex }) => (
    <span
      key={item}
      className={`flex-center-left ${
        isNewOrSavedNewInThisSession(item) ? "list_new" : ""
      }`}
      style={{ ...style, border: lt_gray_border }}
    >
      {columns.map((column, index) => {
        const key = `column_${index}_row_${item.id}`;
        let cellValue = item[column.field];
        const cellType = getCellTypeSafe(column);
        const cellWidth = getCellWidth(cellType, outerWidth);
        let onClickCallback = null;

        if (column.field === "actions") {
          return (
            <div
              key={key}
              style={{
                display: "flex",
                minWidth: cellWidth,
                width: cellWidth
              }}
            >
              <Button
                className={classes.smallButton}
                onClick={() => addRecordBelow(itemIndex)}
                type="primary"
                size="large"
              >
                <FontAwesomeIcon size="xs" icon={["fas", "plus"]} />
              </Button>
              <Button
                className={classes.smallButton}
                onClick={() => {
                  handleSingleDelete(itemIndex);
                }}
                type="primary"
                size="large"
              >
                <FontAwesomeIcon size="xs" icon={["fas", "minus"]} />
              </Button>
            </div>
          );
        }

        // special checkbox
        if (column.field === "list_view_selected") {
          return (
            <span
              key={key}
              className={[classes.cell, classes.checkboxCell].join(" ")}
            >
              <DynamicForm
                key={key}
                fieldGroupsConfig={{
                  default: { title: "", default_expanded: true }
                }}
                fieldsConfig={{
                  [column.field]: { type: column.type, title: "" }
                }}
                fieldValues={{ [column.field]: item.list_view_selected }}
                mode="edit"
                onSubmit={() => {}}
                onError={() => {}}
                showSubmitButton={false}
                enableFieldGrouping={false}
                showLabels={false}
                onChangeHandlers={[
                  {
                    name: column.field,
                    callBackFunction: (name, value, fieldValues) =>
                      onChange(name, value, fieldValues, item, itemIndex)
                  }
                ]}
                classOverride="noclass"
                canUpdate={mode === MODE_SELECT}
                submitting={false}
              />
            </span>
          );
        }

        // Hamburger Icon for Dragging
        if (column.field === "hamburger") {
          return (
            <span
              key={key}
              className={[classes.iconCell, "drag-icon"].join(" ")}
              style={{
                color: "#666",
                cursor: "pointer"
              }}
            >
              <FontAwesomeIcon size="lg" icon={["far", "bars"]} />
            </span>
          );
        }

        const typeFieldGroupsConfig = {
          default: { title: "", default_expanded: true }
        };
        let typeFieldsConfig = {
          [column.field]: {
            title: "",
            type: column.type,
            show_char_count: false,
            settings: {},
            options: [],
            utc: false,
          }
        };
        let typeFieldValues = { [column.field]: cellValue };

        if (column.field === "updatedAt") {
          let updatedAt = "";
          if (cellValue) {
            updatedAt = dateFormatted(cellValue, "mmddyyyy");
          }
          return renderReadOnlySpan(
            key,
            updatedAt,
            cellWidth,
            cellType,
            itemIndex
          );
        }

        switch (column.type) {
          case "money":
            cellValue = Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(cellValue);
            typeFieldsConfig[column.field].type = "integer";
            break;
          case "months-left":
            const dateField = _.get(column, `settings.date_field`, null);
            if (dateField) {
              let color = "#4ab079";
              const foundDate = item[dateField];
              const endDate = moment(foundDate).utc().add(cellValue, "months");
              const endDateForDaysAndMonthCalc = moment(foundDate).utc().add(cellValue, "months").add(1, 'day');
              const monthsLeft = endDateForDaysAndMonthCalc.diff(moment().utc(), "months");
              const totalDaysLeft = endDateForDaysAndMonthCalc.diff(moment().utc(), "days");
              const daysLeft = endDateForDaysAndMonthCalc.diff(
                moment().utc().add(monthsLeft, "months"),
                "days"
              );
              let endsOnDateTxt = `Ends on ${endDate.format("L")}`;
              let timeLeftTxt = `${monthsLeft} months and ${daysLeft} days left`;

              if (totalDaysLeft <= 0) {
                endsOnDateTxt = `Ended on ${endDate.format("L")}`;
                timeLeftTxt = "Expired";
              }

              const states = _.get(column, "settings.states", []);
              if (states && states.length > 0) {
                const dayStates = states
                  .filter((s) => s.unit === "days")
                  .sort((a, b) => a.value - b.value);
                const monthStates = states
                  .filter((s) => s.unit === "months")
                  .sort((a, b) => a.value - b.value);

                let colorSet = false;
                if (dayStates.length > 0) {
                  for (let i = 0; i < dayStates.length; i++) {
                    if (totalDaysLeft <= dayStates[i].value) {
                      colorSet = true;
                      color = dayStates[i].color;
                    } else {
                      break;
                    }
                  }
                }
                if (monthStates.length > 0 && !colorSet) {
                  for (let i = 0; i < monthStates.length; i++) {
                    if (monthsLeft <= monthStates[i].value) {
                      color = monthStates[i].color;
                    } else {
                      break;
                    }
                  }
                }
              }

              cellValue = (
                <>
                  <span style={{ color, fontWeight: "bold" }}>
                    {timeLeftTxt}
                  </span>
                  {totalDaysLeft > 0 && (
                    <>
                      <br />
                      <span>{endsOnDateTxt}</span>
                    </>
                  )}
                </>
              );
            }
            typeFieldsConfig[column.field].type = "integer";
            break;
          case "date":
            typeFieldsConfig[column.field].utc = true;
            cellValue = moment(cellValue).utc().format("L");
            break;
          case "text-lg":
          case "text":
            typeFieldsConfig[column.field].type = "text";
            const suffix = _.get(column, "settings.suffix", "");
            const prefix = _.get(column, "settings.prefix", "");
            cellValue = `${prefix}${cellValue}${suffix}`;
            cellValue =
              cellValue === undefined || cellValue === "undefined"
                ? ""
                : cellValue;
            typeFieldsConfig[column.field].show_char_count = false;
            break;
          case "textarea_modal":
            typeFieldsConfig[column.field].title = column.title;
            break;
          case "textarea":
            typeFieldsConfig[column.field].settings = {
              autosize: {
                minRows: 1,
                maxRows: 1
              }
            };
            typeFieldsConfig[column.field].show_char_count = false;
            break;
          case "select":
            if (cellValue) {
              const foundOption = column.options.find(
                (c) => c.value === cellValue
              );
              if (foundOption) {
                cellValue = foundOption.title;
              }
            }
            typeFieldsConfig[column.field].options = column.options;
            typeFieldsConfig[column.field].settings = column.settings;
            break;
        }

        switch (mode) {
          case MODE_SELECT:
          case MODE_SORT:
            return renderReadOnlySpan(
              key,
              cellValue,
              cellWidth,
              cellType,
              itemIndex,
              onClickCallback
            );
          case MODE_EDIT:
            return renderDynamicFormSpan(
              key,
              typeFieldGroupsConfig,
              typeFieldsConfig,
              typeFieldValues,
              cellWidth,
              item,
              itemIndex
            );
        }
      })}
    </span>
  );

  useEffect(() => {
    if (props.sorting === SORT_RANK) {
      setSortMode(SORT_RANK);
    } else {
      setSortMode(SORT_OTHER);
    }
    setCurrentSort({ field: props.sorting, direction: "asc" });
  }, [props.sorting]);

  useEffect(() => {
    setItems(props.listItems);
  }, [props.listItems]);

  useEffect(() => {
    if (allFolderIdsInThisSession === null) {
      const ids = props.listItems.map((i) => i.id);
      setAllFolderIdsInThisSession(ids);
    }
    const { field, direction } = currentSort;
    const orderedItems =
      sortMode === SORT_RANK
        ? [...props.listItems].sort((a, b) => a.rank - b.rank)
        : _.orderBy(
            [...props.listItems],
            [(i) => (i[field] ? i[field].toLowerCase() : "")],
            direction
          );
    setItems(orderedItems);
  }, [props.listItems]);

  useEffect(() => {
    const foundItems = items.filter((i) => i.list_view_selected);
    setSelectedItems(foundItems);
    setSelectedItemsCount(foundItems.length);
    if (foundItems.length === items.length && foundItems.length !== 0) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [items]);

  // check also folder__status, because the item can change it status and rebuilt all tablet
  useEffect(() => {
    if (sortMode === SORT_RANK) {
      const sortedItems = [...addedItems, ...items].sort(
        (a, b) => a.rank - b.rank
      );
      setAllItems(sortedItems);
    } else {
      setAllItems([...addedItems, ...items]);
    }
  }, [items, addedItems, props.folder__status]);

  const applyAllFilters = (item, columnFilters, fnObject) => {
    const shouldBeFiltered = Object.keys(fnObject).some((fnkey) => {
      let isIncluded;
      const currentFunction = fnObject[fnkey];
      const currentFilterValue = columnFilters[fnkey];
      if (currentFunction) {
        isIncluded = currentFunction(currentFilterValue, item);
        if (!isIncluded) {
          return true;
        }
      }
    });
    if (shouldBeFiltered) {
      // return false to not be added to the filtered array
      return false;
    } else {
      // return true bo be added to the filtered array
      return true;
    }
  };

  useEffect(() => {
    const myFilteredItems = allItems.filter((ai) =>
      applyAllFilters(ai, columnFilters, functionFilters)
    );
    setFilteredItems(myFilteredItems);
  }, [allItems, columnFilters, functionFilters]);

  useEffect(() => {
    if (mode === MODE_EDIT) {
      setAllSelected(false);
      if (selectedItemsCount > 0) {
        const indexes = selectedItems.map((i) =>
          items.findIndex((ii) => ii.id === i.id)
        );
        const newItems = [...items];
        indexes.forEach((i) => (newItems[i].list_view_selected = false));
        setSelectedItems([]);
        setItems(newItems);
      }
    }
  }, [mode]);

  const handleResize = () => {
    const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
    setOuterWidth(boundingClientRect.width - 40);
    setComponentWidth(boundingClientRect.width - 40);
  };

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        handleResize();
      }
    }, 500);
    return () => clearTimeout(timeoutHandler);
  }, [wrappingDivRef, JSON.stringify(props.recalculateWidth)]);

  useEffect(() => {
    // resize
    window.addEventListener("resize", handleResize);
    // Bind the event listener
    // document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the resize event
      window.removeEventListener("resize", handleResize);
      // Unbind the event listener on clean up
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setStyleValues({ columnsWidthSum, listContainerHeight });
  }, [columnsWidthSum, listContainerHeight]);

  useEffect(() => {
    const newList = _.orderBy(
      items,
      [
        (i) => {
          if (props.sorting === SORT_RANK) {
            return i[props.sorting];
          }
          const field = _.get(i, props.sorting, "zzz");
          return field.toLowerCase();
        }
      ],
      "asc"
    );
    setItems(newList);
  }, [sortMode, props.sorting]);

  useEffect(() => {
    setEditButtonDisabled(mode === MODE_EDIT);
  }, [mode]);

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler2 = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
        setComponentWidth(boundingClientRect.width - 40);
        if (boundingClientRect.width - 40 > columnsWidthSum) {
          setOuterWidth(boundingClientRect.width - 40);
        } else {
          setOuterWidth(columnsWidthSum);
        }
      }
    }, 500);
    return () => clearTimeout(timeoutHandler2);
  }, [columnsWidthSum, JSON.stringify(props.recalculateWidth)]);

  useEffect(() => {
    setColumnsWidthSum(getWidthFromColumns(columns, outerWidth));
  }, [columns, outerWidth]);

  useEffect(() => {
    if (props.listColumns) {
      const filters = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = [];
        }
        return accumulator;
      }, {});
      setInitialColumnFilters(filters);

      const filtersFn = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = getFilterFunction(current.field);
        }
        return accumulator;
      }, {});
      setFunctionFilters(filtersFn);
    }
  }, [props.listColumns]);

  useEffect(() => {
    const searchStringHighlight = _.get(
      props,
      "client_options.search_string_highlight",
      null
    );
    setSearchString(searchStringHighlight);
  }, [props.client_options]);

  const getEmptyRecord = () => {
    const record = {};
    props.listColumns.forEach((c) => {
      if (c.field !== "hamburger" && c.field !== "list_view_selected") {
        switch (c.type) {
          case "bool":
            record[c.field] = false;
            break;
          case "text":
          case "textarea":
          case "text-lg":
            record[c.field] = "";
            break;
          case "money":
          case "months-left":
            record[c.field] = 0;
            break;
          case "date":
            record[c.field] = new Date().getTime();
            break;
          default:
            record[c.field] = null;
        }
      }
    });
    return record;
  };

  const switchMode = (mode) => {
    switch (mode) {
      case MODE_SELECT:
        break;
      case MODE_EDIT:
        setEditButtonDisabled(true);
        setMode(MODE_EDIT);
        setForceUpdateInt(forceUpdateInt + 1);
        break;
    }
  };

  const onUpdateBulkSuccess = () => {
    const notify = _.get(props, 'notifications.update', true);
    if (notify) {
      AlertNotification(
        "success",
        "Success",
        "New items have been saved and they are now  in the list according to the sorting order"
      );
    }    
    cleanStateAfterSaveSuccess();
  };

  const cleanStateAfterSaveSuccess = () => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null })
    );

    // setUnsavedChangedItems([]);
    setUnsavedChanges(false);
    setUnsavedChangesModal(false);
  };

  const onHandleSingleDeleteCancel = () => {
    setSelectedItems([]);
    setShowDeleteConfirmModal(false);
  };

  const onFail = (msg) => {
    AlertNotification("error", "Error", msg);
  };

  const handleConfirmSingleDelete = (item) => {
    setShowDeleteConfirmModal(true);
    setSelectedItems([item]);
  };

  const handleSingleDelete = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    setUnsavedChanges(true);
  };

  const checkCancelEditsPopModal = () => {
    if (unsavedChangesRef.current || addedItems.length > 0) {
      setUnsavedChangesModal(true);
    } else {
      setMode(MODE_SELECT);
      if (sortMode === SORT_RANK) {
        setUnsavedChangedItems([]);
        setUnsavedChanges(false);
        setItems(props.listItems.sort((a, b) => a.rank - b.rank));
      }
    }
  };

  const cancelEdits = () => {
    setItems(props.listItems);
    setAddedItems([]);
    setUnsavedChangesModal(false);
    setUnsavedChanges(false);
    setUnsavedChangedItems([]);
    setMode(MODE_SELECT);
    setForceUpdateInt(forceUpdateInt + 1);
    setEditButtonDisabled(false);
  };

  const saveEdits = () => {
    const { addBulk, updateBulk } = props;
    setMode(MODE_SELECT);
    setForceUpdateInt(forceUpdateInt + 1);
    setEditButtonDisabled(false);
    dispatch(
      setClientOptions({
        indeterminate: true,
        lock_screen: true,
        message_for_progress: "We are saving your changes"
      })
    );

    // If there is not items before do no call updateBulk
    const newItems = items.map((i) => {
      delete i.new;
      return i;
    });
    updateBulk(newItems, onUpdateBulkSuccess, (message) =>
      AlertNotification("error", "Error", message || "Unexpect error happened")
    );
  };

  const shouldUpdateBulk = () => {
    if (items.length > 0 && unsavedChangedItems.length > 0) {
      const folderFormattedItemsForUpdate = items.filter((i) =>
        unsavedChangedItems.includes(i.id)
      );
      return folderFormattedItemsForUpdate.length > 0;
    }
    return false;
  };

  const customSort = (field) => {
    let direction: "asc" | "desc" = "asc";
    if (currentSort.field === field) {
      direction = currentSort.direction === "asc" ? "desc" : "asc";
    }
    setSortMode(SORT_OTHER);
    setCurrentSort({ field, direction });
    const nonNewItems = items.filter((i) => i.rank >= 0);
    const sortedNoNewItems = _.orderBy(
      nonNewItems,
      [(i) => i[field].toLowerCase()],
      direction
    );
    setItems(sortedNoNewItems);
  };

  const onChange = (name, field, fieldValues, item, index, isMedia = false) => {
    // if the change is in a media file the content manager handle the save to the database
    // so we do not need to mark the component as touch
    if (!isMedia) {
      setUnsavedChanges(true);
    }
    const newItems = items;
    switch (name) {
      case "list_view_selected":
        newItems.splice(index, 1, {
          ...item,
          list_view_selected: fieldValues[name]
        });
        setItems([...newItems]);
        break;
      default:
        if (index >= 0) {
          newItems[index][name] = field;
          setItems(newItems);
        }
        break;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = [...items];
    if (newIndex >= items.length) {
      var k = newIndex - items.length + 1;
      while (k--) {
        newItems.push(undefined);
      }
    }
    newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
    setUnsavedChanges(true);
    setItems(newItems);
  };

  const onSelectAllChange = (value) => {
    const newItems = items.map((i) => {
      const newItem = { ...i };
      newItem.list_view_selected = value;
      return newItem;
    });
    setAllSelected(value);
    setItems(newItems);
  };

  const addRecord = () => {
    const newRecord = getEmptyRecord();
    newRecord["new"] = true;
    // for (const col of columns) {
    //   if (!["hamburger", "list_view_selected"].includes(col.field)) {
    //     newRecord[col.field] = "";
    //   }
    // }
    setItems([...items, newRecord]);
    setUnsavedChanges(true);
    setMode(MODE_EDIT);
  };

  const addRecordBelow = (index) => {
    const newItems = [...items];
    const newRecord = getEmptyRecord();
    newRecord["new"] = true;
    newItems.splice(index + 1, 0, newRecord);
    setItems(newItems);
    setUnsavedChanges(true);
    setMode(MODE_EDIT);
  };

  const getFilterUpdatedByObject = (data, property, include_blank = false) => {
    const returnObject = [
      ...new Set(data.filter((x) => x[property]).map((x) => x[property]))
    ].map((x) => {
      let userName = getUserNameAndLastName(x);
      return { text: userName, value: x };
    });
    if (include_blank) {
      returnObject.push({ text: "(Empty)", value: "(empty)" });
    }
    return returnObject;
  };

  const onFilterColumn = (key, filterValue) => {
    const newFilters = { ...columnFilters };
    newFilters[key] = filterValue;
    setColumnFilters({ ...columnFilters, ...newFilters });
  };

  const renderHeaders = (tableWidth, allItems) => (
    <span
      id="headers"
      className="flex-center-left"
      style={{ border: lt_gray_border, height: 50, width: outerWidth }}
    >
      {columns.map((column, index) => {
        const cellType = getCellTypeSafe(column);
        const cellWidth = getCellWidth(cellType, tableWidth);

        let sortDirection = "none";
        if (currentSort.field === column.field) {
          if (currentSort.direction === "asc") {
            sortDirection = "asc";
          } else {
            sortDirection = "desc";
          }
        }

        switch (cellType) {
          case "actions":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "icon":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "bool":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              >
                <Checkbox
                  disabled={mode === MODE_EDIT}
                  checked={allSelected}
                  onChange={(e) => onSelectAllChange(e.target.checked)}
                />
              </span>
            );
          case "date":
          case "integer":
          case "string":
          case "months-left":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth, cursor: "pointer" }}
                onClick={() => customSort(column.field)}
              >
                <b>
                  {column.title} &nbsp;&nbsp;
                  {sortDirection === "none" && (
                    <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                  )}
                  {sortDirection === "asc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-down"]} />
                  )}
                  {sortDirection === "desc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-up"]} />
                  )}
                </b>
              </span>
            );
          default:
            let extraClass = _.get(columnsExtraClass, `[${column.field}]`, "");
            return (
              <span
                key={index}
                className={`${classes.cell} ${classes.cellFlex} ${extraClass}`}
                style={{ minWidth: cellWidth }}
              >
                <div className={classes.rowGrow}>
                  <div>
                    <b>
                      {column.title} &nbsp;&nbsp;
                      {sortDirection === "none" && (
                        <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                      )}
                      {sortDirection === "asc" && (
                        <FontAwesomeIcon
                          size="sm"
                          icon={["fas", "sort-down"]}
                        />
                      )}
                      {sortDirection === "desc" && (
                        <FontAwesomeIcon size="sm" icon={["fas", "sort-up"]} />
                      )}
                    </b>
                  </div>
                </div>
                {column.filter ? (
                  <div className={classes.rowRight}>
                    <FilterActions
                      filterKey={column.field}
                      options={getFilterUpdatedByObject(
                        allItems,
                        cellType,
                        true
                      )}
                      onFilter={onFilterColumn}
                      onHoverIn={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] =
                          "list-view_header-hover";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                      onHoverOut={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] = "";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                    ></FilterActions>
                  </div>
                ) : null}
              </span>
            );
        }
      })}
    </span>
  );

  const shouldCancelStart = (event) => {
    let targetEl = event.target;
    do {
      if (typeof targetEl.className === "string") {
        if (targetEl.className.includes("drag-icon")) {
          return false;
        }
      }
      targetEl = targetEl.parentNode;
    } while (targetEl.parentNode);
    return true;
  };

  const getButtonSize = () => {
    if (componentWidth > MIN_WIDTH_FULL_RESOLUTION) {
      return "large";
    } else if (componentWidth > MIN_WIDTH_MEDIUM_RESOLUTION) {
      return "large";
    }
    return "small";
  };

  const isComponentWidthFull = componentWidth > MIN_WIDTH_FULL_RESOLUTION;

  return (
    <div className={classes.listContainer} ref={wrappingDivRef}>
      <div id="actions">
        <div id="top-buttons">
          {props.showAddButton ? (
            <Button
              onClick={() => addRecord()}
              className="list-view_button"
              type="primary"
              size={getButtonSize()}
              style={{ width: "auto", marginRight: 5 }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">Add</span>
              ) : (
                <Tooltip
                  placement="leftTop"
                  title="Add"
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={["fas", "plus"]}
                    onClick={() => addRecord()}
                  />
                </Tooltip>
              )}
            </Button>
          ) : null}
          {items.length > 0 && (
            <Button
              onClick={() => switchMode(MODE_EDIT)}
              disabled={editButtonDisabled}
              className="list-view_button"
              type="primary"
              size={getButtonSize()}
              style={{ width: "auto", marginRight: 5 }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">Edit Mode</span>
              ) : (
                <Tooltip
                  placement="leftTop"
                  title="Edit Mode"
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={["fas", "edit"]}
                    onClick={() => switchMode(MODE_EDIT)}
                  />
                </Tooltip>
              )}
            </Button>
          )}
          {props.exportCsv && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                props.exportCsv();
              }}
              disabled={editButtonDisabled}
              className="list-view_button"
              type="primary"
              size={getButtonSize()}
              style={{ width: "auto" }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">Export Data</span>
              ) : (
                <Tooltip
                  placement="leftTop"
                  title="Edit Mode"
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={["fas", "file-csv"]}
                    onClick={(e) => {
                      e.preventDefault();
                      props.exportCsv();
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          )}
        </div>
      </div>
      <div id="list">
        {filteredItems.length === 0 ? (
          <p style={{ fontSize: 14, fontStyle: "italic", textAlign: "center" }}>
            There are no items here
          </p>
        ) : (
          <>
            {renderHeaders(outerWidth, allItems)}
            <SortableJsonListViewMemo
              outerWidth={outerWidth}
              listContainerHeight={listContainerHeight}
              allItems={filteredItems}
              onSortEnd={onSortEnd}
              forceUpdateInt={forceUpdateInt}
              shouldCancelStart={shouldCancelStart}
              helperClass={classes.beingDragged}
              mode={mode}
              columns={columns}
              onChange={onChange}
              Row={Row}
            />
          </>
        )}
      </div>
      <div id="bottom-buttons">
        <Button
          onClick={checkCancelEditsPopModal}
          disabled={mode === MODE_SELECT}
          size="large"
          style={{ width: "auto" }}
        >
          CANCEL
        </Button>
        <Spaner width="sm" />
        <Button
          onClick={(e) => {
            e.preventDefault();
            saveEdits();
          }}
          disabled={!unsavedChanges}
          type="primary"
          size="large"
          style={{ width: "auto" }}
        >
          SAVE
        </Button>
      </div>

      <Modal
        visible={unsavedChangesModal}
        title="Cancel Edits"
        onCancel={() => setUnsavedChangesModal(false)}
        onOk={cancelEdits}
        destroyOnClose={true}
      >
        <div>Are you sure you want discard the changes made?</div>
      </Modal>

      <Modal
        visible={showDeleteConfirmationModal}
        title="Delete Record"
        onCancel={onHandleSingleDeleteCancel}
        onOk={handleSingleDelete}
        destroyOnClose={true}
      >
        <div>
          {`Are you sure you want to delete ${
            selectedItems[0] ? selectedItems[0].name : null
          }`}
        </div>
      </Modal>
    </div>
  );
};

export default JsonListView;
