import {name, wysiwyg} from "../standardized_fields";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { glenwoodFonts, quillFontSizes } from "./glenwood_settings";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

export const wysiwyg_template = {
  ...wysiwyg,
  default: {
    type: "style", // Options are: style | ops
    style: {
      font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
      size: "40px"
    },
    ops: []
  },
  settings: {
    font_size_unit: "px",
    fonts: glenwoodFonts,
    fontSizeOptions: quillFontSizes,
    recommended_length: 42,
    controls: {
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      numberedList: true,
      bulletList: true,
      alignment: true,
      indentation: true,
      clearFormat: true
    },
    theme: "glenwood"
  }
};

export const glenwood_template_shared = {
  tabs: {
    main: {
      title: "Main"
    },
    card_view: {
      title: "Card View"
    }
  },
  field_groups: {
    name: {
      title: "Name/Label",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "main"
    },
    cycle_time: {
      title: "Cycle Time",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "main"
    },
    headline: {
      title: "Header",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "main"
    },
    subhead: {
      title: "Subhead",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "main"
    },
    body: {
      title: "Body Text",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "main"
    },
    quote: {
      title: "Quote",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "main"
    },
    attribution: {
      title: "Attribution",
      default_expanded: false,
      order: 7,
      accordion: true,
      tab: "main"
    },
    background_overlay: {
      title: "Background Overlay",
      default_expanded: false,
      order: 8,
      accordion: true,
      tab: "main"
    },
    background: {
      title: "Background",
      default_expanded: false,
      order: 9,
      accordion: true,
      tab: "main"
    },
    override_headline_large: {
      title: "Override Header",
      default_expanded: false,
      order: 2,
      accordion: true,
      tab: "card_view"
    },
    override_subhead_large: {
      title: "Override Subhead",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "card_view"
    },
    override_body_text_large: {
      title: "Override Body Text",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "card_view"
    },
    override_thumbnail_image_large: {
      title: "Override Thumbnail Image",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "card_view"
    },
    override_background_large: {
      title: "Override Background Overlay",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "card_view"
    },
    presentations: {
      title: "Presentations",
      default_expanded: false,
      order: 7,
      accordion: true,
      tab: "main"
    }
  },
  fields: {
    name,
    title: {
      type: "text",
      title: "Title",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "name"
    },
    headline: {
      title: "Header",
      field_group: "headline",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    subhead: {
      title: "Subhead",
      field_group: "subhead",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    body: {
      title: "Body Text",
      field_group: "body",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "background",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Background Overlay",
      default: "",
      field_group: "background_overlay",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    accent_color: {
      type: "color",
      title: "Color Overlay",
      default: "",
      isVisible: () => false,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    override_headline_large: {
      title: "Override Header",
      field_group: "override_headline_large",
      conditional_show: [{ field: "size_options", value: "large" }],
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "headline"
      }
    },
    override_subhead_large: {
      title: "Override Subhead",
      field_group: "override_subhead_large",
      conditional_show: [{ field: "size_options", value: "large" }],
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "subhead"
      }
    },
    override_body_text_large: {
      title: "Override Body Text",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: glenwoodFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#FFFFFF"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      field_group: "override_body_text_large",
      conditional_show: [{ field: "size_options", value: "large" }],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "body"
      }
    },
    override_thumbnail_image_large: {
      title: "Override Thumbnail Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "override_thumbnail_image_large",
      conditional_show: [{ field: "size_options", value: "large" }],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    override_background_large: {
      title: "Override Background Overlay",
      type: "color",
      default: "",
      field_group: "override_background_large",
      conditional_show: [{ field: "size_options", value: "large" }],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "color_overlay"
      }
    },
    show_in_presentations: {
      type: "bool",
      title: "Active",
      tooltip: "If not active, item will not show in the presentation",
      validation: { rules: [] },
      default: true,
      field_group: "presentations"
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: true
};
