import {COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE} from "../../../../enums/folder-type-enums";
import {IFolderType} from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import {tree_icon_types} from "../tree_icon_types";
import {wysiwyg} from "../standardized_fields";
import {coburgFonts, quillFontSizes} from "./coburg_settings";
import {fonts, FontsFamilies, FontVariants} from "../../../../fonts";

export const coburg_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.coburg_display,
  display_name: "Coburg Display",
  prevent_add_children: true,
  acceptable_folder_types: [FOLDER_TYPE.coburg_presentation, FOLDER_TYPE.coburg_grouped_presentations],
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  hide_nested_children: true,
  tabs: {
    general: {
      title: "General"
    },
    advanced: {
      title: "Advanced"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    advanced: {
      title: "Advanced",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "advanced"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true,
      field_group: "general"
    },
    loading_message: {
      title: "Loading Message",
      field_group: "advanced",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwyg.settings,
        font_size_unit: "px",
        fontSizeOptions: quillFontSizes,
        fonts: coburgFonts
      },
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
            size: "40px",
            color: "#000000",
            align: "center"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
    },
  },
  preview: {
    react_url: "theme/coburg",
    aspect_ratio: 1.77777,
    component_name: COMPONENT_NAME.PassThroughWrapper,
    type: PREVIEW_TYPE.COMPONENT,
    pass_through_child_component_name: COMPONENT_NAME.CoburgContainer
  },
  tab_previews: [
    {
      tab_key: "general",
      aspect_ratio: 1.77777,
      component_name: COMPONENT_NAME.PassThroughWrapper,
      type: PREVIEW_TYPE.COMPONENT,
    },
    {
      tab_key: "advanced",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.LoadingDefault,
      type: PREVIEW_TYPE.COMPONENT
    }
  ],
  webview: {
    is_loading_message_enabled: true,
    loading_message_field: "loading_message",
    loading_component: COMPONENT_NAME.LoadingDefault
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  }
};
