import get from 'lodash/get';
import React from 'react';
import { useFolder } from '../../../hooks/useFolder';
import Slide1911 from '../../standard/Slide1911';

const GlenwoodBackground = (props) => {
  const { getBaseFolder } = useFolder();

  const baseFolder = getBaseFolder();

  return (
    <div
      style={{
        position: 'absolute',
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
        }}
      >
        <Slide1911
          {...props}
          handleEndOfPlay={null}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            muted: false,
            slotNames: ["image"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "contain",
            videoIsShorterThanCycleTime: true,
          }}
        />
      </div>
    </div>
  )
};

export default GlenwoodBackground;
