export function strToBool(str) {
  return str === "true";
}

export const loopIndex = (currentIndex, min, max, direction) => {
  if (direction === "forward") {
    let result = currentIndex + 1;
    if (result > max) {
      return min;
    }
    return result;
  }
  else {
    let result = currentIndex - 1;
    if (result < min) {
      return max;
    }
    return result
  }
}

export const isInQuotes = (str: string) => {
  if (str.length > 2) {
    if (str.charAt(0) === '"') {
      if (str.charAt(str.length - 1) === '"') {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const stripQuotes = (str: string) => {
  if (isInQuotes(str)) {
    str = str.slice(1);
    str = str.substring(0, str.length - 1);
    console.log("stripped to?", str);
    return str;
  } else {
    return str;
  }
};

export const getJsonFromS3SignedUrl = (url: string) => {
  try {
    let uuid = "";
    const base = url.split("?")[0];
    const baseSplit = base.split("/");
    const folderType = baseSplit[baseSplit.length - 2];
    if (folderType === "resource") {
      uuid = "resource";
    } else if (folderType === "resource__folders") {
      uuid = "resource-folder";
    }
    //console.log("1");

    const fileName = baseSplit[baseSplit.length - 1];
    const fileNameSplit = fileName.split(".");
    const fileNameNoExtension = fileNameSplit[0];

    const fsplit = fileNameNoExtension.split("_");
    const id = fsplit[0];
    let preset = null;
    if (fsplit[1]) {
      fsplit.shift();
      preset = fsplit.join("");
    }

    uuid += "-" + id;
    let uuid_preset = uuid;
    if (preset){
      uuid_preset = uuid + "-" + preset
    }

    return {
      url,
      uuid_preset
    };
  } catch (e) {
    console.log(e);
  }
};

export const getFontSizes = (min, max, increment, unit, titleModifier) => {
  let fontSizes = [];
  for (let i = min; i <= max; i += increment) {
    let title = `i${unit}`;
    if (titleModifier) {
      title = titleModifier(i);
    }
    fontSizes.push({ title, value: i });
  }
  return fontSizes;
};

export const getFieldValue = (initObject, fieldRoute: string): any[] | undefined  => {
  const routes = fieldRoute.split('.');
  function traverse(obj, routeIndex) {
    const currentField = routes[routeIndex];
    const nextField = routes[routeIndex + 1];
    if (!obj || !obj.hasOwnProperty(currentField)) {
      return [];
    }
    const fieldValue = obj[currentField];
    const isArray = Array.isArray(fieldValue);
    // If nextField is not present, we are at the lastChild.
    if (!nextField) {
      return isArray ? fieldValue : [fieldValue];
      // return isArray ? fieldValue.map((item) => item[currentField]) : [fieldValue];
    }
    if (isArray) {
      // If the current field is an array, we recursively traverse each item in the array.
      const result = [];
      fieldValue.forEach((item) => {
        const childValues = traverse(item, routeIndex + 1);
        result.push(...childValues);
      });
      return result;
    } else if (typeof fieldValue === 'object') {
      // If the current field is an object, we continue the traversal.
      return traverse(fieldValue, routeIndex + 1);
    } else {
      return fieldValue;
    }
  }
  const traverseResult = traverse(initObject, 0) as any[];
  // fucking typescript ._. doesn't allow this in tests: [...new Set(traverseResult)];
  const arr =  Array.from(new Set(traverseResult));
  return arr.length === 0 ? undefined : arr;
};