import { ArrowRightOutlined } from "@ant-design/icons";
import get from "lodash/get";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { COMPONENT_MODE_CONTEXT } from "../../../../shared-global/enums/config-enums";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType
} from "../../../utils/commonDataUtils";
import { bestFit } from "../../../utils/screenUtils";
import Slide1911 from "../../standard/Slide1911";
import Slide1911Placeholder from "../../standard/Slide1911Placeholder";

const calculateStyles = (height, width) => {
  const styles = {
    storyCardMedium2103_headline: {
      "& .ql-editor": {
        // '& p > span': {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        webkitLineClamp: 1,
        webkitBoxOrient: "vertical",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical"
        // },
      }
    },
    storyCardMedium2103_subhead: {
      "& .ql-editor": {
        "& p > span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline",
          webkitLineClamp: 1,
          webkitBoxOrient: "vertical",
          "-webkit-line-clamp": 1,
          "-webkit-box-orient": "vertical"
        }
      }
    },
    storyCardMedium2103_bodyText: {
      "& .ql-editor": {
        "& p > span": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          webkitLineClamp: 3,
          webkitBoxOrient: "vertical",
          "-webkit-line-clamp": 3,
          "-webkit-box-orient": "vertical"
        }
      }
    }
  };

  return styles;
};

const EugeneStoryCardMedium: React.FC<IDisplayComponentProps> = (props) => {
  const [baseFolder, setBaseFolder] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [headlineConverter, setHeadlineConverter] = useState(null);
  const [subHeadConverter, setSubHeadConverter] = useState(null);
  const [bodyTextConverter, setBodyTextConverter] = useState(null);
  const [baseFolderType, setBaseFolderType] = useState(null);
  const [imageFieldType, setImageFieldType] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("white");

  const initialContainerWidth = props.containerWidth || window.innerWidth;
  const initialContainerHeight = props.containerHeight || window.innerHeight;

  const navigate = useNavigate();

  const useStyles = createUseStyles(
    calculateStyles(props.containerHeight, props.containerWidth)
  );
  const classes = useStyles();

  // Clearing timeouts and listeners.
  useEffect(() => {}, []);

  useEffect(() => {
    const myBaseFolder = getBaseFolder(props.data);
    setBaseFolder(myBaseFolder);

    const myHeadlineConverter = new QuillDeltaToHtmlConverter(
      get(props.data, "headline.ops", []),
      { multiLineParagraph: false }
    );
    const mySubHeadConverter = new QuillDeltaToHtmlConverter(
      get(props.data, "subhead.ops", []),
      { multiLineParagraph: false }
    );
    const myBodyTextConverter = new QuillDeltaToHtmlConverter(
      get(props.data, "body.ops", []),
      { multiLineParagraph: false }
    );

    const myBackgroundColor = get(props.data, "background", "white");

    setHeadlineConverter(myHeadlineConverter);
    setSubHeadConverter(mySubHeadConverter);
    setBodyTextConverter(myBodyTextConverter);
    setBackgroundColor(myBackgroundColor);
  }, [props.data]);

  useEffect(() => {
    if (
      props.mode === COMPONENT_MODE_CONTEXT.PREVIEW &&
      props.data?.persistent_settings?.data.show_placeholder
    ) {
      setShowPlaceholder(true);
    }
  }, [props.data, props.mode]);

  useEffect(() => {
    if (baseFolderType) {
      const fieldTypeImage = get(baseFolderType, "fields[image_1]", null);
      setImageFieldType(fieldTypeImage);
    }
  }, [baseFolderType]);

  useEffect(() => {
    const myBaseFolderType = getBaseFolderType(
      props.data,
      get(baseFolder, "folder_type", {})
    );

    setBaseFolderType(myBaseFolderType);
  }, [baseFolder]);

  useEffect(() => {
    if (baseFolderType) {
      const fieldTypeImage = get(baseFolderType, "fields[image_1]", null);
      setImageFieldType(fieldTypeImage);
    }
  }, [baseFolderType]);

  const getBestFitCallback = useCallback(() => {
    return bestFit(
      initialContainerWidth,
      initialContainerHeight,
      initialContainerWidth,
      initialContainerHeight
    );
  }, [initialContainerWidth, initialContainerHeight]);

  const dims = getBestFitCallback();

  const [width, setWidth] = useState(dims.width);
  const [height, setHeight] = useState(dims.height);

  // Check for best fit dimensions.
  useEffect(() => {
    const dims = getBestFitCallback();
    setWidth(dims.width);
    setHeight(dims.height);
  }, [props.containerHeight, props.containerWidth]);

  const getPlaceholder = (placeholder) => {
    if (!showPlaceholder) {
      return "";
    }

    switch (placeholder) {
      case "headline":
        return `<p><strong class=\"ql-font-robotoslabregular ql-size-40em\">${get(
          props.data,
          "headline.title",
          placeholder
        )}</strong></p>`;
      case "subhead":
        return `<p><strong class=\"ql-font-robotoslabregular ql-size-28em\">${get(
          props.data,
          "subhead.title",
          placeholder
        )}</strong></p>`;
      case "body":
        return `<p><strong class=\"ql-font-roboto ql-size-21em\">${get(
          props.data,
          "body.title",
          placeholder
        )}</strong></p>`;
    }
  };

  const isTextRichEmpty = (text) => {
    if (text && text !== "<p><br/></p>") {
      return false;
    } else {
      return true;
    }
  };

  const getHeaderLineOrPlaceholder = (convertor, type) => {
    let html = convertor ? convertor.convert() : "";

    if (
      isTextRichEmpty(html) &&
      props.mode === COMPONENT_MODE_CONTEXT.PREVIEW
    ) {
      html = getPlaceholder(type);
    }

    return html;
  };

  const renderImageCard = () => {
    return showPlaceholder ? (
      <Slide1911Placeholder
        containerWidth={width * 0.5}
        containerHeight={height}
        placeholder={"image"}
        fieldType={{
          limit: 1
        }}
        background={false}
        systemSettings={props.data.system_settings}
      />
    ) : props.data.slotNames && props.data.slotNames.length ? (
      <Slide1911
        aws={props.aws}
        aws_bucket={props.aws_bucket}
        mode={props.mode}
        containerWidth={width * 0.5}
        containerHeight={height}
        data={{
          ...props.data,
          slotNames: props.data.slotNames,
          placeholder: "Image",
          fieldType: "image",
          // fieldType: imageFieldType,
          showPlaceholder: showPlaceholder,
          backgroundSize: "cover"
        }}
        webview_signedurls={props.webview_signedurls}
      />
    ) : null;
  };

  const renderQuote = () => {
    return (
      <div
        className="story-card-large-2103_quote"
        style={{
          margin: 0,
          position: "absolute",
          top: height * (1 / 2 + 1 / 6),
          left: width / 2 / 3,
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
          maxWidth: "100%"
        }}
      >
        <div
          className="story-card-large-2103_quote-container"
          style={{
            position: "relative",
            width: "100%",
            height: props.containerHeight,
            margin: "auto"
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              fontFamily: "Lora-Bold",
              fontSize: props.containerHeight * 0.8,
              top: -0.074962519 * props.containerHeight,
              color: "#cdcdcd"
            }}
          >
            “
          </div>
        </div>
      </div>
    );
  };

  const renderLeftSideCard = () => {
    const type = get(baseFolderType, "name", "");
    switch (type) {
      case FOLDER_TYPE.eugene_template_004:
        return renderQuote();
      default:
        return renderImageCard();
    }
  };

  const headline = getHeaderLineOrPlaceholder(headlineConverter, "headline");
  const subhead = getHeaderLineOrPlaceholder(subHeadConverter, "subhead");
  const bodyText = getHeaderLineOrPlaceholder(bodyTextConverter, "body");

  return (
    <div
      className="story-card-medium-2103"
      style={{
        width: width,
        height: height,
        // border: "1px solid #324C45",
        backgroundColor: backgroundColor
      }}
    >
      <div
        className="story-card-medium-2103_container"
        style={{
          display: "flex",
          position: "relative"
        }}
      >
        <div
          className="story-card-medium-2103_image"
          style={{
            // flex: 1,
            width: width * 0.5,
            height: height
          }}
        >
          {renderLeftSideCard()}
        </div>
        <div
          className="story-card-medium-2103_description"
          style={{
            // flex: 1,
            width: width * 0.5,
            height: height,
            paddingTop: height * 0.08,
            paddingLeft: height * 0.09
            // paddingBottom: height * 0.1,
            // paddingRight: height * 0.2,
          }}
        >
          <div
            className="story-card-medium-2103_texts"
            style={{
              height: height * 0.7,
              padding: height * 0.04
            }}
          >
            {/* Headline */}
            <div
              // className="ql-editor-preview"
              className={classes.storyCardMedium2103_headline}
              style={{
                width: width * 0.4
              }}
            >
              <div
                className="ql-editor"
                style={{
                  fontSize: height / 480,
                  padding: 0,
                  display: "inline"
                }}
                dangerouslySetInnerHTML={{ __html: headline }}
              />
            </div>
            {/* Sub Head */}
            {subhead && (
              <div
                className={classes.storyCardMedium2103_subhead}
                style={{
                  marginTop: height * 0.008329169,
                  marginBottom: height * 0.04
                }}
              >
                <div
                  className="ql-editor"
                  style={{
                    fontSize: height / 500,
                    padding: 0
                  }}
                  dangerouslySetInnerHTML={{ __html: subhead }}
                />
              </div>
            )}
            {/* Body Text */}
            <div
              className={classes.storyCardMedium2103_bodyText}
              style={{
                marginTop: height * 0.046296296,
                height: height * 0.3
              }}
            >
              <div
                className="ql-editor"
                style={{
                  fontSize: height / 420,
                  color: "#707070",
                  padding: 0,
                  lineHeight: "35em",
                  // overflowY: "clip"
                }}
                dangerouslySetInnerHTML={{ __html: bodyText }}
              />
            </div>
          </div>
          {/* buttons */}
          <div
            className="story-card-medium-2103_buttons"
            style={{
              // display: 'flex',
              // justifyContent: 'flex-end',
              // position: "relative",
              // height: height * 0.3,
              color: "#639cfb"
            }}
          >
            <div
              className="story-card-medium-2103_button"
              style={{
                position: "absolute",
                bottom: height * 0.05,
                right: height * 0.1,
                color: "#639cfb"
              }}
            >
              <span
                style={{
                  textAlign: "right",
                  width: "100%",
                  cursor: "pointer"
                  // width: width * 0.04,
                  // height: width * 0.04,
                }}
                onClick={() => navigate(props.data.cardLink)}
              >
                <ArrowRightOutlined
                  style={{
                    fontSize: height * 0.1
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EugeneStoryCardMedium;
