import React, {Component} from 'react'
import {connect} from "react-redux"
import DragDropTree from './DragDropTree'
import {
  fetchProjectFolders,
  toggleFolderExpanded,
  setFoldersExpanded,
  moveFolder,
  downloadFolderCsv,
  uploadFolderCsv,
  instanceFolder,
  setUpdateListViewTouch, setUpdateFolderTouch, moveResourceFolder
} from '../../actions/folderActions'
import {downloadProjectCsv, uploadProjectCsv} from '../../actions/projectActions'
import {isNull} from '../../shared/utils/generalUtils'
import {getThemeClass} from '../../utils/themeUtils'
import {arrayFromKeyedObject} from '../../shared/utils/collectionUtils'
import {setClientOptions} from '../../reducers/clientOptionsSlice'
import {getBackupsByCompany} from '../../actions/backupActions'
import _ from 'lodash';
import DeleteResourceModal from '../resource/DeleteResourceModal'
import DeleteFolderModal from '../folder/DeleteFolderModal'
import AddResourceModal from '../resource/AddResourceModal'
import {checkForPermission} from '../../services/permissionService'
import CsvModal from '../csv/CsvModal'
import PublishFolderModal from '../folder/PublishFolderModal';
import {setProjectForAllChildren} from '../../utils/treeUtils'
import {hasPermission} from '../../utils/permissionUtils'
import BackupsModal from '../folder/BackupsModal'
import {handleTreeSearch, isInPresentationFolder} from '../../services/treeService'
import {TREE_MODE_APPLIED_CONTENT, TREE_MODE_RESOURCE} from '../constants';
import AlertNotification from '../layout/AlertNotification';
import {getAppliedTreeDataResourcesNoEmpty} from "../../selectors/folderNoEmptySelectors";
import {getAppliedTreeDataResourcesFilterSiblingsNoMatch} from "../../selectors/folderResourceFilterSiblingsNoMatchSelectors";
import {getAppliedTreeDataFilterSiblingsNoMatch} from "../../selectors/folderFilterSiblingsNoMatchSelectors";
import {getChildrenData, hasMovingWarnings} from "../../shared/utils/commonDataUtils";

class PrimaryTree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      publishFolderModalVisible: false,
      publishFolderUuid: null,
      addResourceModalVisible: false,
      deleteResourceModalVisible: false,
      deleteFolderModalVisible: false,
      deleteFolderId: null,
      deleteFolderFolderId: null,
      deleteFolderType: null,
      addResourceParentFolder: {name: ""},
      csvVisible: false,
      csvModalId: null,
      backupsModalVisible: false,
      currentMeta: {},
      publishingFolder: {}
    }
    this._searchString = props.clientOptions.search_string_main;
    this._searchFoldersExpanded = [];
    this._searchFolderFolders = [];
    this._addFolderAcceptableTypes = []
    this._dragDropTreeKey = 0;
  }

  componentDidMount() {
    this.props.fetchProjectFolders();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "s") {
      // const start = new Date().getTime()
      // this.testToggle()
      // const end = new Date().getTime()
      // console.log("diff: "+(end - start))
    }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.searchString !== prevProps.searchString){
    //   this.handleSearch(this.props.searchString, _.get(this.props, 'clientOptions.treemode', 'applied_content'));
    // }
    let doSearch = false;
    let currentTreeMode = _.get(
      this.props,
      "clientOptions.treemode",
      TREE_MODE_APPLIED_CONTENT
    );
    let prevTreeMode = _.get(
      prevProps,
      "clientOptions.treemode",
      TREE_MODE_APPLIED_CONTENT
    );

    if (this.props.folderResults !== prevProps.folderResults) {
      doSearch = true;
    } else if (this.props.resourceResults !== prevProps.resourceResults) {
      doSearch = true;
    } else if (currentTreeMode !== prevTreeMode) {
      doSearch = true;
    }
    if (doSearch === true) {
      this.handleSearch(
        this.props.clientOptions.search_string_main,
        _.get(this.props, "clientOptions.treemode", TREE_MODE_APPLIED_CONTENT),
        prevProps.clientOptions.search_old_text,
        prevProps.clientOptions.search_action,
        prevProps.clientOptions.search_prior_expanded_folders
      );
    }
  };

  onErrorInstacing = (message) => {
    AlertNotification('error', message || 'An unexpected error happened', message || 'An unexpected error happened')
  };

  onSuccessInstancing = (type) => {
    if (type && type === 'instance_replace') {
      AlertNotification('success', 'Success', 'Folder successfully switched');
    } else {
      AlertNotification('success', 'Success', 'Folder instanced correctly');
    }
  };

  refreshTreeExpanded = (folder) => {
    const {applied_content_tree_expanded} = this.props;
    const {setFoldersExpanded} = this.props;

    if (!folder || !folder.child_folder) {
      return;
    }

    const folderId = folder.child_folder;

    const toRemove = [`folder-${folderId}`];

    const folder_expanded_filtered = applied_content_tree_expanded.filter(e => toRemove.indexOf(e) < 0);

    setFoldersExpanded(TREE_MODE_APPLIED_CONTENT, folder_expanded_filtered);
    setFoldersExpanded(TREE_MODE_APPLIED_CONTENT, applied_content_tree_expanded);
  };

  getOriginFolder = orig => {
    const all_folder__folders = {...this.props.folder__folders, ...this.props.resourcetree_folder__folders};
    const all_folders = {...this.props.folders, ...this.props.resourcetree_folders}
    if (orig.folder__folder_id) {
      return all_folders[all_folder__folders[orig.folder__folder_id].child_folder];
    } else if (orig.resource__folder_id) {
      return all_folders[this.props.resourcetree_resource__folders[orig.resource__folder_id].child_folder];
    }
  };

  isResourceMode = () => {
    // is a resource mode
    return this.props.clientOptions.treemode === TREE_MODE_RESOURCE;
  };

  isMoveResource = (orig) => {
    // is a resource and try to move a resource to a folder
    return this.props.clientOptions.treemode === TREE_MODE_RESOURCE && orig.resource__folder_id;
  };

  onDrop = ([error, orig, dest]) => {
    if (error) {
      if (this.isResourceMode()) {
        AlertNotification('error', "Move Resource Error", error);
      } else {
        AlertNotification('error', "Move Folder Error", error);
      }
      return null;
    };
    console.log('orig', orig);
    console.log('dest', dest);

    if (this.isMoveResource(orig)) {
      this.onDropResource([orig, dest]);
    } else {
      this.onDropFolder([orig, dest]);
    }
  };

  getFolderHiddenSecondLevel = () => {
    const resourcetree_folders_arr = arrayFromKeyedObject(this.props.resourcetree_folders);
    const folder_resource_default = resourcetree_folders_arr.find(f => f.folder_type === 'resource_default');
    return folder_resource_default;
  };

  getResourceFolderHiddenFirstLevel = () => {
    const all_resource__folders_obj = this.props.resourcetree_resource__folders
    const all_resource__folders = arrayFromKeyedObject(all_resource__folders_obj);
    const folder_hidden_first_level = this.getFolderHiddenSecondLevel();
    const all_resource__folders_first_level = all_resource__folders.filter(rf => rf.folder === folder_hidden_first_level.id);
    const all_resource__folders_first_level_order = _.orderBy(all_resource__folders_first_level, 'rank', 'asc');
    if (all_resource__folders_first_level_order) {
      return all_resource__folders_first_level_order[0];
    } else {
      return null;
    }
  };

  getFolderFolderHiddenFirstLevel = () => {
    const all_folder__folders_obj = this.props.resourcetree_folder__folders
    const all_folder__folders = arrayFromKeyedObject(all_folder__folders_obj);
    const folder_hidden_first_level = this.getFolderHiddenSecondLevel();
    const all_folder__folders_first_level = all_folder__folders.filter(rf => rf.child_folder === folder_hidden_first_level.id);
    const all_folder__folders_first_level_order = _.orderBy(all_folder__folders_first_level, 'rank', 'asc');
    if (all_folder__folders_first_level_order) {
      return all_folder__folders_first_level_order[0];
    } else {
      return null;
    }
  };

  onDropResource = ([orig, dest]) => {
    const all_folder__folders = {...this.props.resourcetree_folder__folders};
    const all_resource__folders = {...this.props.resourcetree_resource__folders}

    const origResourceFolderId = orig.resource__folder_id;
    const origFolderFolder = all_resource__folders[origResourceFolderId];

    const destFolderFolderId = dest.folder__folder_id || null;
    let destResourceFolderId = dest.resource__folder_id || null;
    const destFolderFolder = all_folder__folders[destFolderFolderId];

    let moveIntoActionName = 'move_into';
    let moveAction;

    // Handle dropping folder into first position.
    if (dest.spacer && destFolderFolder && this.props.resource_tree_expanded.includes('folder-' + destFolderFolder.child_folder)) {
      // Only make the spacer switch when the destination folder has children. If it doesn't have children let's assume the folder is being dropped after the destination folder (same level) and not into the first position within that folder.
      const childrenResources = arrayFromKeyedObject(all_resource__folders).filter(rf => rf.folder === destFolderFolder.child_folder);
      const childrenFolders = arrayFromKeyedObject(all_folder__folders).filter(rf => rf.parent_folder === destFolderFolder.child_folder);
      if ((childrenResources.length + childrenFolders.length) > 0) {
        // modify all fields as if you dropped on the folder (will need)
        dest.spacer = false;
        dest.rank = -1;
        moveIntoActionName = 'move_into_first_position';
      }
      // modify the dropped folder to be first position in the folder
    }

    // Handle Dropping resource in first position on top level (company)
    // because it hide (programmatically some levels we have to do this
    if (dest.spacer && !destFolderFolderId && !destResourceFolderId && dest.tree_id === 'company-' + this.props.user.company) {
      // search for first folder or resource programmatically
      const first_resource_folder = this.getResourceFolderHiddenFirstLevel();
      destResourceFolderId = first_resource_folder ? first_resource_folder.id : null;
      dest.spacer = false;
      dest.rank = -1;
      moveIntoActionName = 'move_into_first_position';
    }

    // only root folder__folder joins can have null parents or an assigned project
    if (origFolderFolder && origFolderFolder.parent_folder === null) {
      AlertNotification('error', "Move Folder Error", "Root folders cannot be moved.");
      return null;
    }

    if (!destFolderFolderId && !destResourceFolderId) {
      AlertNotification('error', "Move Resource Error", "Resource cannot be moved.");
      return null;
    }

    if (dest.spacer) {
      moveAction = "move_after";
    } else {
      moveAction = moveIntoActionName;
    }

    this.props.moveResourceFolder(origResourceFolderId, destFolderFolderId, destResourceFolderId, moveAction, () => {
        AlertNotification('success', "Move Okay", "The Resource was moved.");
      },
      () => AlertNotification('error', "Move Resource Error", "Server error: changes were not saved."));
  };

  handlePublishWarnings = () => {
    const childrenFolderFolders = Object.keys(this.props.folder__folders).map((ffK) => this.props.folder__folders[ffK])
    const folders = Object.keys(this.props.folders).map((fK) => this.props.folders[fK])
    const childrenData = getChildrenData(
      { folder__folders: childrenFolderFolders, folders },
      this.state.publishingFolder.id
    )

    for (const folder of childrenData.folders) {
      const grandChildrenData = getChildrenData(
        { folder__folders: childrenFolderFolders, folders },
        folder.id
      )
      const parentFolderType = this.props.folder_types[folder.folder_type]
      for (const folder1 of grandChildrenData.folders) {
        const childrenDataToCheck =  getChildrenData(
          { folder__folders: childrenFolderFolders, folders },
          folder1.id
        )
        const folderType = this.props.folder_types[folder1.folder_type]

        if (!childrenDataToCheck.folders.length && folderType.warn_moving_to_presentation_without_children && parentFolderType.isPresentation) {
          AlertNotification(
            'warning',
            "Warning Publishing Folder",
            `You have empty Layouts that won't show up in the published Presentation.  Add content under the Layout to have it appear in the presentation`
          );
          return
        }
      }
    }
  }

  // Handle warnings on moving folders
  handleMovingWarnings = (destinationFolderType, originFolderType, originFolder) => {
    if (hasMovingWarnings(destinationFolderType, originFolderType, originFolder, this.props.folder__folders)) {
      AlertNotification(
        'warning',
        "Warning Moving Folder",
        `The layout ${originFolder.name} is empty, and will be skipped during presentation, if you intended to use it as just an image, consider using template 1, story with full screen image instead`
      );
    }
  }

  // `orig` is the item that is being dragged
  // `dest` is the item where `orig` is being dragged to
  onDropFolder = ([orig, dest]) => {

    const origIsInPresentations = isInPresentationFolder(orig.path, this.props.folder__folders, this.props.folders, this.props.folder_types)
    const destIsInPresentations = isInPresentationFolder(dest.path, this.props.folder__folders, this.props.folders, this.props.folder_types)


    if (origIsInPresentations && !destIsInPresentations) {
      AlertNotification('error', "Move Folder Error", "Operation not allowed in this folder.");
      return;
    }
    const expandedIds = this.props.applied_content_tree_expanded.map((exp) => {
      const arrName = exp.split('-')
      return Number(arrName[1])
    })
    const all_folder__folders = {...this.props.folder__folders, ...this.props.resourcetree_folder__folders};
    const all_folders = {...this.props.folders, ...this.props.resourcetree_folders}

    const origFolderFolderId = orig.folder__folder_id;
    const origFolderFolder = all_folder__folders[origFolderFolderId];

    let destFolderFolderId = dest.folder__folder_id;
    let destFolderFolder = all_folder__folders[destFolderFolderId];

    const mode = this.props.clientOptions.treemode;

    let moveIntoActionName = 'move_into';
    let destFolderId, moveAction;

    // Handle dropping folder into first position.
    if (dest.spacer && destFolderFolder && this.props.applied_content_tree_expanded.includes('folder-' + destFolderFolder.child_folder)) {
      // Only make the spacer switch when the destination folder has children. If it doesn't have children let's assume the folder is being dropped after the destination folder (same level) and not into the first position within that folder.
      const children = arrayFromKeyedObject(all_folder__folders).filter(ff => ff.parent_folder === destFolderFolder.child_folder);
      if (children.length > 0) {
        // modify all fields as if you dropped on the folder (will need)
        dest.spacer = false;
        dest.rank = -1;
        moveIntoActionName = 'move_into_first_position'
      }
      // modify the dropped folder to be first position in the folder
    }

    // Handle Dropping resource in first position on top level (company)
    // because some levels are hidden (programmatically) we have to do this
    if (this.isResourceMode() && dest.spacer && !destFolderFolderId && dest.tree_id === 'company-' + this.props.user.company) {
      // search for first folder or resource programmatically
      const first_folder_folder = this.getFolderFolderHiddenFirstLevel();
      destFolderFolderId = first_folder_folder ? first_folder_folder.id : null;
      destFolderFolder = all_folder__folders[destFolderFolderId];
      dest.spacer = false;
      dest.rank = -1;
      moveIntoActionName = 'move_into_first_position';
    }

    if (!destFolderFolder) {
      AlertNotification('error', "Move Folder Error", "Operation not allow in this folder.");
      return null;
    }

    // Switch based on the tree drop action
    let origin_folder, destination, destination_folder_type, configuration;
    origin_folder = this.getOriginFolder(orig);
    // check if the origin allows drag out
    const origin_parent_folder = all_folders[origFolderFolder.parent_folder];

    if (dest.spacer) {
      destination = all_folders[destFolderFolder.parent_folder];
    } else {
      destination = all_folders[destFolderFolder.child_folder];
    }

    destination_folder_type = this.props.folder_types[destination.folder_type];
    const origin_folder_type = this.props.folder_types[origin_folder.folder_type];

    if (destination_folder_type && origin_folder_type) {
      this.handleMovingWarnings(destination_folder_type, origin_folder_type, origin_folder)
    }

    configuration = destination_folder_type.tree_dropped_into_action || [{action: 'move'}] // Add default value

    const sortingOptionDestination = _.get(destination, 'fields.sorting_options', 'rank');
    if (destination.id === origin_parent_folder.id && sortingOptionDestination !== 'rank') {
      AlertNotification("warning", "Warning", "The folder you are trying to re order is based on sorting order");
      return;
    }

    if (this.props.folder_types[origin_parent_folder.folder_type].prevent_dragging_out
      && origin_parent_folder.id !== destination.id) {
      AlertNotification("warning", "Warning", "You can't move this item out of this folder.")
      // notification['warning']({
      //   message: "Warning",
      //   description: "You can't move this item out of this folder.",
      //   style: ALERT_WARNING_STYLE,
      //   icon: <AlertWarningIcon />
      // });
      return;
    }

    switch (configuration[0]["action"]) {
      case 'instance_replace':
      case 'instance':
        // Check if the origin folder is a valid children for the destination folder
        if (!destination_folder_type.acceptable_folder_types.includes(origin_folder.folder_type)) {
          this.onErrorInstacing("This is not an allowed child type for folder " + _.get(destination, "name", ""));
        } else if (origin_parent_folder.id === destination.id) {
          // It's a reranking, that's why a move is used
          let moveFolderAction = 'move_after';
          if (moveIntoActionName === 'move_into_first_position') {
            moveFolderAction = moveIntoActionName;
          }

          this.props.moveFolder(
            origFolderFolderId,
            destFolderFolderId,
            'applied_content',
            orig.path.join('/'),
            dest.path.join('/'),
            moveFolderAction,
            this.onSuccessInstancing,
            this.onErrorInstacing
          );
        } else {
          console.log("calling instance from here");
          this.props.instanceFolder({
            parent_folder_id: destination.id,
            instanced_folder_ids: origin_folder.id,
            dest_folder_folder_id: destFolderFolderId,
            spacer: dest.spacer,
            moveIntoActionName: moveIntoActionName,
            origin_path:orig.path.join('/'),
            destination_path: dest.path.join('/')
          }, this.onSuccessInstancing, null);
        }
        break;
      case 'move':
      default:
        // only root folder__folder joins can have null parents or an assigned project
        if (origFolderFolder && origFolderFolder.parent_folder === null) {
          AlertNotification('error', "Move Folder Error", "Root folders cannot be moved.");
          return null;
        }

        // Projects, companies, locations
        if (!destFolderFolder) {
          AlertNotification('error', "Move Folder Error", "This folder does not accept children.");
          return null;
        }

        if (dest.spacer) {
          destFolderId = destFolderFolder.parent_folder;
          moveAction = "move_after";
        } else {
          moveAction = moveIntoActionName;
          destFolderId = destFolderFolder.child_folder;
        }

        // check if folder is being moved into itself or a nested child
        if (!this.isValidChildNesting(origFolderFolderId, destFolderFolderId)) {
          AlertNotification("error", "Move Folder Error", "Cannot move a folder into itself or a child of itself.")
          return null;
        }
        // check if parent folder in destination accepts moved folder as child
        if (!this.acceptableFolderType(origFolderFolder, destFolderId)) {
          AlertNotification('error', "Move Folder Error", "The item you are dragging has a folder type that is not allowed in the destination folder.");
          return null;
        }

        // Check project permissions on dest and origin folder
        // Check if all folder_folders have project.
        let folder__folders_array = Object.values(all_folder__folders)
        setProjectForAllChildren(folder__folders_array);
        if (!hasPermission(this.props.grouped_permissions, 'applied_content', 'update', this.props.user, 'project', folder__folders_array.find(x => x.id === origFolderFolderId).project, this.props.user?.company ?? null)
          || !hasPermission(this.props.grouped_permissions, 'applied_content', 'update', this.props.user, 'project', folder__folders_array.find(x => x.id === destFolderFolderId).project, this.props.user?.company ?? null)) {
          AlertNotification('error', 'Move Folder Error', "You don't have enough permissions for doing this");
          return null;
        }

        let destinationPath = null;
        if (!dest.tree_id.includes('company')) {
          destinationPath = dest.path.join('/');
        }

        this.props.moveFolder(
          origFolderFolderId,
          destFolderFolderId,
          mode,
          orig.path.join('/'),
          destinationPath,
          moveAction,
          () => {
            AlertNotification('success', "Move Okay", "The folder was moved.");
            this.refreshTreeExpanded(destFolderFolder);
          },
          () => AlertNotification('error', "Move Folder Error", "Server error: changes were not saved.")
        );
        break;
    }
  }


  acceptableFolderType = (origFolderFolder, destFolderId) => {
    if (isNull(origFolderFolder) || isNull(destFolderId)) {
      return false;
    }

    const all_folders = {...this.props.folders, ...this.props.resourcetree_folders}

    const origFolder = all_folders[origFolderFolder.child_folder]
    const destFolder = all_folders[destFolderId]
    const destFolderType = this.props.folder_types[destFolder.folder_type];

    if (!destFolderType || !Array.isArray(destFolderType.acceptable_folder_types)) {
      return false;
    }

    if (!destFolderType.acceptable_folder_types.includes(origFolder.folder_type)) {
      return false;
    }

    return true;
  }

  isValidChildNesting = (origFolderFolderId, currFolderFolderId) => {
    const all_folder__folders = {...this.props.folder__folders, ...this.props.resourcetree_folder__folders};

    let origFolder = _.get(all_folder__folders, '[' + origFolderFolderId + '].child_folder', null)
    let currFolder = _.get(all_folder__folders, '[' + currFolderFolderId + '].child_folder', null)

    if (isNull(origFolder || isNull(currFolder))) {
      //checked all the way up the tree
      return true;
    }

    if (origFolder === currFolder) {
      return false;
    }

    let treeDataFolderFolder = _.find(this.props.treeData, {folder__folder_id: currFolderFolderId});
    if (treeDataFolderFolder) {
      return this.isValidChildNesting(origFolderFolderId, treeDataFolderFolder.parent_folder__folder_id)
    }
    //checked all the way up the tree
    return true;

  }

  // testToggle = () => {
  //   const values = ["folder-38","folder-37","folder-11","folder-10","folder-14","folder-1085","folder-15","folder-1086","folder-1087","folder-1088","folder-1090","folder-1091","folder-1092","folder-1095","folder-1115","folder-1144","company-2","location-1","location-2","project-1","project-2","project-3"]
  //   this.props.setFoldersExpanded(TREE_MODE_APPLIED_CONTENT, values)
  // }

  handleSearch = (value, modeContext, oldText, action, previous_tree_state) => {
    let result = handleTreeSearch(
      value,
      modeContext,
      this.props.folders,
      this.props.folder__folders,
      this.props.resourcetree_folders,
      this.props.resources,
      this.props.resourcetree_resource__folders,
      this.props.resourcetree_folder__folders,
      this.props.user.company,
      this.props.locations,
      this.props.projects,
      this.props.folderResults,
      this.props.resourceResults,
      this.props.folder_types
    );

    this._searchString = result.cleanSearch;
    if (this._searchString) {
      this.props.setFoldersExpanded(modeContext, result._searchFoldersExpanded);
    } else {

      if(result && !result._searchFoldersExpanded && action && action === "collapse"){
        if(previous_tree_state)
          this.props.setFoldersExpanded(modeContext, previous_tree_state);
      }

      this.setState({});
    }
    this.props.setClientOptions({ search_string_main: value });
  };


  addSubfolderOpen = (data) => {
    this.props.addSubfolderOpen(data);
  }

  addResourceOpen = (selectedTreeId) => {
    let parentFolder = null;
    if (selectedTreeId.includes('folder')) {
      let folderId = Number(selectedTreeId.replace("folder-", ""));
      parentFolder = this.props.resourcetree_folders[folderId];
    } else {
      const companyId = selectedTreeId.replace("company-", "");
      const resourceRootFolder = Object.values(this.props.resourcetree_folders)
        .find(f => f.folder_type === 'resource_default' && f.company === Number(companyId));
      parentFolder = resourceRootFolder;
    }
    this.setState({
      addResourceModalVisible: true,
      addResourceParentFolder: parentFolder
    });
  }

  deleteResourceOpen = (selectedTreeId) => {
    this.setState({
      deleteResourceModalVisible: true,
    });
  }

  closeAddResourceModal = () => {
    this.setState({
      addResourceModalVisible: false
    })
  }

  closeDeleteResourceModal = () => {
    this.setState({
      deleteResourceModalVisible: false
    })
  }

  toggleDeleteFolderModal = (val, folder_id, folder__folder_id, type) => {
    this.setState({
      deleteFolderModalVisible: val,
      deleteFolderId: folder_id,
      deleteFolderFolderId: folder__folder_id,
      deleteFolderType: type
    })
  }

  getFolderContext = () => {
    if (this.props.clientOptions.treemode === TREE_MODE_APPLIED_CONTENT) {
      return this.props.folders
    } else {
      return this.props.resourcetree_folders
    }
  }

  getFolderFolderContext = () => {
    if (this.props.clientOptions.treemode === TREE_MODE_APPLIED_CONTENT) {
      return this.props.folder__folders
    } else {
      return this.props.resourcetree_folder__folders
    }
  }

  handleToggleFolderExpanded = (treeId, metaData, doOpenClose, doSelect) => {
    //console.log("THE TOGGLE", treeId, metaData, doOpenClose, doSelect)
    if (treeId.includes('folder-')) {
      let folderId = treeId.replace('folder-', '');
      //window.logger.debug("folderId", folderId)
      let folder_type = this.getFolderContext()[folderId].folder_type
      this._addFolderAcceptableTypes = _.get(this.props, `folder_types[${folder_type}].acceptable_folder_types`, []);
    } else {
      this._addFolderAcceptableTypes = [];
    }
    if (doSelect) {
      this.props.setClientOptions({selected_folder_meta: metaData});
    }
    this.props.toggleFolderExpanded(treeId, doOpenClose, doSelect, this.props.clientOptions.treemode);
  }

  // showSearchSettings = () => {
  //   if (this.props.clientOptions.treemode === TREE_MODE_RESOURCE){
  //     this.setState({
  //       resourceSearchModalVisible: true
  //     })
  //   }
  //   else{
  //     this.setState({
  //       manageSearchModalVisible: true
  //     })
  //   }
  // }

  // onChangeManageSearchSettings = (settings) => {

  //   const options = this.props.clientOptions.manage_tree_options
  //   options.show_match_siblings = false;
  //   settings.map(x => {
  //     switch (x) {
  //       case "Show Match Siblings":
  //       default:
  //         options.show_match_siblings = true;
  //         return "show_match_siblings";
  //     }
  //   })
  //   this.props.setClientOptions({ manage_tree_options: options })
  //   this.setState({
  //     manageSearchModalVisible: false
  //   })
  // }

  // onChangeContentSearchSettings = (filters) => {
  //   filters = filters.map(x => {
  //     switch (x) {
  //       case "Rich Text":
  //         return "rich_text";
  //       case "Plain Text":
  //         return "plain_text";
  //       case "Audio":
  //         return 'audio';
  //       case "Videos":
  //         return "video";
  //       case "Images":
  //         return "image"
  //       default:
  //         return x;
  //     }
  //   })
  //   this.props.setClientOptions({ resource_tree_filters: filters })
  //   this.setState({
  //     resourceSearchModalVisible: false
  //   })
  // }

  handleCsvCancel = () => {
    this.setState({
      csvVisible: false
    });
  }

  openCsvModal = ({id, type}) => {
    this.setState({
      csvVisible: true,
      csvType: type,
      csvModalId: id
    });
  }

  downloadCsv = () => {
    if (this.state.csvType === "project") {
      this.props.downloadProjectCsv(this.state.csvModalId);
    } else {
      this.props.downloadFolderCsv(this.state.csvModalId);
    }
  }

  handleCsvOk = (values) => {
    const onSuccess = () => this.csvSuccess();
    const onFail = (err) => this.csvFail(err);
    const onUploadProgress = (percentage) => {
      this.props.setClientOptions({
        progress: {percentage, fileName: 'CSV File', current: 0, max: 0}
      });
    }
    let fileObj = {removeAll: _.get(values, 'removeAll', false)};
    if (this.state.csvType === "project") {
      fileObj.project = this.state.csvModalId;
      if (values.resource && values.resource[0]) {
        fileObj.file = values.resource[0].file;
        fileObj.fileFieldName = values.resource[0].slotName;
      }
      this.props.setClientOptions({indeterminate: true, lock_screen: true, message_for_progress: null});
      this.props.uploadProjectCsv(fileObj, onUploadProgress, onSuccess, onFail);
    } else {
      fileObj.folder = this.state.csvModalId;
      if (values.resource && values.resource[0]) {
        fileObj.file = values.resource[0].file;
        fileObj.fileFieldName = values.resource[0].slotName;
      }
      this.props.setClientOptions({indeterminate: true, lock_screen: true, message_for_progress: null});
      this.props.uploadFolderCsv(fileObj, onUploadProgress, onSuccess, onFail);
    }
  }

  csvFail = (e) => {
    this.props.setClientOptions({lock_screen: false, progress: {}});
    const errors = e.join("/n");
    AlertNotification("error", "CSV File Upload Error", errors);
  }

  csvSuccess = () => {
    this.props.setClientOptions({lock_screen: false, progress: {}});
    AlertNotification("success", "CSV File Upload", "CSV File has been uploaded successfully");
    this.setState({
      csvVisible: false
    });
  }

  publishFolderModalClose = () => {
    this.setState({
      publishFolderModalVisible: false
    })
  }

  publishFolderModalOpen = (folder_uuid, publishingFolder) => {
    this.setState({
      publishFolderModalVisible: true,
      publishFolderUuid: folder_uuid,
      publishingFolder
    })
  }

  openBackupsModal = () => {
    this.setState({
      backupsModalVisible: true
    });
    const selected_id = this.props.clientOptions.applied_content_tree_selected_id;
    let folder = null;
    if (selected_id && selected_id.startsWith('folder-')) {
      const id = selected_id.substring(selected_id.indexOf('-') + 1);
      folder = this.props.folders[id];
      this.props.getBackupsByCompany(this.props.user.company, folder.folder_type);
    }
  }

  onCancelBackupsModal = () => {
    this.setState({
      backupsModalVisible: false
    })
  }

  onSaveBackupsModal = () => {
    this.setState({
      backupsModalVisible: false
    });
  }

  getSelectedTreeId = () => {
    switch (this.props.clientOptions.treemode) {
      case TREE_MODE_RESOURCE:
        return this.props.clientOptions.resource_tree_selected_id
      case TREE_MODE_APPLIED_CONTENT:
        return this.props.clientOptions.applied_content_tree_selected_id
      default:
        return null;
    }
  }

  render() {

    const {resource_tree_crud} = this.props;
    let contentTree = this.props.treeData;
    if (!this.props.defaultIcons) {
      return null;
    }
    return (
      <div className={getThemeClass(_.get(this.props, 'persistent_settings.data.theme', 'light'), 'primary-tree')}>
        <PublishFolderModal
          visible={this.state.publishFolderModalVisible}
          onCancel={this.publishFolderModalClose}
          folder_uuid={this.state.publishFolderUuid}
          onOkay={this.publishFolderModalClose}
          folderFolderPath={_.get(this.props, 'clientOptions.selected_folder_meta.path', [])}
          handlePublishWarnings={this.handlePublishWarnings}
          publishingFolderType={this.props.folder_types[this.state.publishingFolder.folder_type]}
        />
        <AddResourceModal
          visible={this.state.addResourceModalVisible && this.state.addResourceParentFolder}
          onCloseModal={this.closeAddResourceModal}
          parentFolder={this.state.addResourceParentFolder}
        />
        <DeleteResourceModal
          visible={this.state.deleteResourceModalVisible}
          onCancel={this.closeDeleteResourceModal}
        />
        <DeleteFolderModal
          visible={this.state.deleteFolderModalVisible}
          onCancel={() => this.toggleDeleteFolderModal(false, null, null)}
          folderId={this.state.deleteFolderId}
          folderFolderId={this.state.deleteFolderFolderId}
          path={_.get(this.props, 'clientOptions.selected_folder_meta.path', [])}
          folders={this.props.folders}
          folder__folders={this.props.folder__folders}
          folder_types={this.props.folder_types}
          type={this.state.deleteFolderType}
          selectedFolderMeta={this.props.clientOptions.selected_folder_meta}
          onCompletion={(notif) => {
            AlertNotification(notif.type, notif.message, notif.description)
            this.toggleDeleteFolderModal(false, null, null);
          }}
        />
        {/* <ResourceSearchModal onOk={(filters) => {this.onChangeContentSearchSettings(filters)}} onCancel={() => {this.setState({resourceSearchModalVisible: false})}} visible={this.state.resourceSearchModalVisible}/> */}
        {/* <ManageSearchModal onOk={(settings) => {this.onChangeManageSearchSettings(settings)}} onCancel={() => {this.setState({manageSearchModalVisible: false})}} visible={this.state.manageSearchModalVisible}/> */}

        <CsvModal
          visible={this.state.csvVisible}
          ok={this.handleCsvOk}
          cancel={this.handleCsvCancel}
          downloadCsv={this.downloadCsv}
          type={this.state.csvType}
        />
        <BackupsModal
          visible={this.state.backupsModalVisible}
          onCancel={this.onCancelBackupsModal}
          onSave={this.onSaveBackupsModal}
        />
        <DragDropTree
          ref={el => this._dragDropTreeRef = el}
          toggleDetail={this.props.toggleDetail}
          onDrop={this.onDrop}
          treeData={contentTree}
          defaultIcons={this.props.tree_icon_types}
          appliedContentTreeExpanded={this.props.applied_content_tree_expanded}
          setFoldersExpanded={this.props.setFoldersExpanded}
          toggleExpandedItem={(treeId, metaData, doOpenClose, doSelect) => {
            this.handleToggleFolderExpanded(treeId, metaData, doOpenClose, doSelect)
          }}
          searchText={this.props.clientOptions.search_string_highlight}
          onSearch={() => {
            console.log("on search ran?")
          }}
          addSubfolder={this.addSubfolderOpen}
          addResource={this.addResourceOpen}
          openCsvModal={this.openCsvModal}
          deleteResource={this.deleteResourceOpen}
          deleteFolder={(folder_id, folder__folder_id, type) => this.toggleDeleteFolderModal(true, folder_id, folder__folder_id, type)}
          folders={this.props.folders}
          folder__folders={this.props.folder__folders}
          resourcetreeFolders={this.props.resourcetree_folders}
          resourcetreeFolder__folders={this.props.resourcetree_folder__folders}
          folderTypes={this.props.folder_types}
          mode={this.props.clientOptions.treemode}
          modeContext={this.props.clientOptions.treemode}
          showSettings={true}
          onFilter={(filters) => {
            this.onFilter(filters)
          }}
          resource_tree_crud={resource_tree_crud}
          onSettings={() => {
            console.log("implement modal search settings?")
          }}
          inputMode={this.props.clientOptions.inputMode}
          updateSelectedTreeId={(treeId) => {
            switch (this.props.clientOptions.treemode) {
              case TREE_MODE_RESOURCE:
                const updateClientOptions = {}
                if (treeId.startsWith('folder-')) {
                  updateClientOptions.resource_selected_folder = Number(treeId.substring(7));
                } else if (treeId.startsWith('resource-')) {
                  updateClientOptions.resource_selected_resource = Number(treeId.substring(9));
                } else {
                  updateClientOptions.resource_selected_folder = null;
                }
                this.props.setClientOptions(updateClientOptions)
                break;
              case TREE_MODE_APPLIED_CONTENT:
                this.props.setClientOptions({applied_content_tree_selected_id: treeId})
                break;
              default:
                break;
            }
          }}
          selectedTreeId={this.getSelectedTreeId()}
          update_folder_touch={this.props.update_folder_touch}
          update_list_view_touch={this.props.update_list_view_touch}
          setUpdateListViewTouch={this.props.setUpdateListViewTouch}
          setUpdateFolderTouch={this.props.setUpdateFolderTouch}
          clientOptions={this.props.clientOptions}
          width={this.props.width}
        />
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  let timer = new Date();
  //console.log("state on recalculation", state);
  let treeData;

  //treeData = getAppliedTreeData(state)
  //const treeDataTest = getAppliedTreeDataTest({...state, treemode_override: TREE_MODE_RESOURCE})
  if (state.client_options.treemode === TREE_MODE_RESOURCE) {
    let filters = [];
    if (ownProps.fixedFilters === true) {
      filters = state.data.filters
    } else {
      const search_string_main = _.get(state, 'client_options.search_string_main', null)
      filters = [...state.client_options.resource_tree_filters]
      treeData = getAppliedTreeDataResourcesFilterSiblingsNoMatch({...state, filters: filters, search_string_main});
    }
  } else {
    const search_string_main = _.get(state, 'client_options.search_string_main', null)

    const show_match_children = _.get(state, 'client_options.manage_tree_options.show_match_siblings', false)
    if (show_match_children){
      treeData = getAppliedTreeDataResourcesNoEmpty({...state, search_string_main})
    }
    else{
      treeData = getAppliedTreeDataFilterSiblingsNoMatch({...state, search_string_main})
    }
  }

  // TODO: get resource_tree_crud if in 'resource' state.client_options.treemode === TREE_MODE_RESOURCE
  // if you are not in resource mode set to {}
  //if were resource tree, we should check for each of crud actions and then pass this down through the tree
  // resource_tree_permissions
  //  get CRUD true false for resource tree and create an object
  //  resource_tree_crud = {create: true, read: false, update: false, delete: true} based the permissions

  let resource_tree_crud = {};
  if (state.client_options.treemode === TREE_MODE_RESOURCE) {
    resource_tree_crud = {
      create: checkForPermission('resource', 'create', state.data.user_permissions_grouped),
      read: checkForPermission('resource', 'read', state.data.user_permissions_grouped),
      update: checkForPermission('resource', 'update', state.data.user_permissions_grouped),
      delete: checkForPermission('resource', 'delete', state.data.user_permissions_grouped),
    }
  }

  let difference = new Date().getTime() - timer.getTime();

  const update_folder_touch = state.data.update_folder_touch;
  const update_list_view_touch = state.data.update_list_view_touch;

  return {
    applied_content_tree_expanded: state.data.applied_content_tree_expanded,
    resource_tree_expanded: state.data.resource_tree_expanded,
    clientOptions: state.client_options,
    grouped_permissions: state.data.user_permissions_grouped,
    treeData: treeData,
    time: difference,
    folders: state.data.folders,
    folder__folders: state.data.folder__folders,
    resource__folders: state.data.resource__folders,
    resourcetree_folders: state.data.resourcetree_folders,
    resourcetree_folder__folders: state.data.resourcetree_folder__folders,
    folder_types: state.data.folder_types,
    searchString: state.client_options.search_string_main,
    tree_icon_types: state.data.tree_icon_types,
    user: state.data.user,
    locations: state.data.locations,
    projects: state.data.projects,
    persistent_settings: state.data.persistent_settings,
    resources: state.data.resources,
    resourcetree_resource__folders: state.data.resourcetree_resource__folders,
    defaultIcons: state.data.tree_icon_types,
    resource_tree_crud,
    folderResults: state.data.folder_results,
    resourceResults: state.data.resource_results,
    update_folder_touch,
    update_list_view_touch
  };
};


const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectFolders: (onSuccess) => {
    dispatch(fetchProjectFolders(ownProps.type, onSuccess));
  },
  toggleFolderExpanded: (treeId, doOpenClose, doSelect, treemode) => {
    dispatch(toggleFolderExpanded(treeId, doOpenClose, doSelect, treemode));
  },
  setFoldersExpanded: (treeMode, treeIds) => {
    dispatch(setFoldersExpanded(treeMode, treeIds));
  },
  moveFolder: (origFolderFolderId, destFolderFolderId, treeType, moveAction, onSuccess, onFail) => {
    dispatch(moveFolder(origFolderFolderId, destFolderFolderId, treeType, moveAction, onSuccess, onFail));
  },
  moveResourceFolder: (origResourceFolder, destFolderFolder, destResourceFolder, actionType, onSuccess, onFail) => {
    dispatch(moveResourceFolder(origResourceFolder, destFolderFolder, destResourceFolder, actionType, onSuccess, onFail));
  },
  setClientOptions: (client_options) => {
    dispatch(setClientOptions(client_options))
  },
  downloadProjectCsv: (id) => {
    dispatch(downloadProjectCsv(id))
  },
  downloadFolderCsv: (id) => {
    dispatch(downloadFolderCsv(id))
  },
  uploadProjectCsv: (value, onProgress, onSuccess, onFail) => {
    dispatch(uploadProjectCsv(value, onProgress, onSuccess, onFail))
  },
  uploadFolderCsv: (value, onProgress, onSuccess, onFail) => {
    dispatch(uploadFolderCsv(value, onProgress, onSuccess, onFail))
  },
  getBackupsByCompany: (company, folder_type) => {
    dispatch(getBackupsByCompany(company, folder_type))
  },
  instanceFolder: (payload, onSuccess, onFail) => {
    dispatch(instanceFolder(payload, onSuccess, onFail))
  },
  setUpdateListViewTouch: (touch) => {
    dispatch(setUpdateListViewTouch(touch))
  },
  setUpdateFolderTouch: (touch) => {
    dispatch(setUpdateFolderTouch(touch))
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PrimaryTree);
