import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT, TRANSITION_CLASS } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { fremont_root } from "./fremont_root";
import { fremont_1x2 } from "./fremont_1x2";
import { fremont_1x2_slideshow } from "./fremont_1x2_slideshow";
import { fremont_template_001 } from "./fremont_template_001";
import { fremont_donor_lists } from "./fremont_donor_lists";
import { fremont_donor_list_001 } from "./fremont_donor_list_001";
import { fremont_donor_001 } from "./fremont_donor_001";
import { fremont_donor_list_category } from "./fremont_donor_list_category";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";
import { fremont_donor_list_002 } from "./fremont_donor_list_002";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { cycle_time_tooltips } from './fremont_settings';

export const fremont_types: { [key: string]: IFolderType } = {
  fremont_root,
  fremont_1x2,
  fremont_1x2_slideshow,
  fremont_1x2_slide: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.fremont_1x2_slide,
    display_name: "1x2 Slide",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      file: {
        title: "Image / Video",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [],
        aspect: 0.28125,
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.28125
        },
        recommended_size: {
          width: 1080,
          height: 3840
        }
      },
      // file: {
      //   title: "Image / Video",
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ["image/*", "video/*"],
      //   video_presets: [],
      //   limit: null,
      //   slots: [
      //     {
      //       name: "File",
      //       aspect: 0.28125
      //     }
      //   ],
      //   default: null
      // },
      override_overlay: {
        title: "Override Overlay",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*"],
        image_presets: [],
        video_presets: [],
        aspect: 0.2815,
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.2815
        }
      }
      // override_overlay: {
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ['image/*'],
      //   limit: 1,
      //   slots: [{
      //     title: "Override overlay",
      //     name: "override_overlay",
      //     aspect: 0.2815
      //   }]
      // }
    },
    preview: {
      aspect_ratio: 0.28125,
      component_name: COMPONENT_NAME.FremontOverlaySlide,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.FremontOverlaySlide
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.donor_open
      },
      closed: {
        ...tree_icon_types.donor_closed
      }
    }
  },
  fremont_1x4: {
    folder_category: FOLDER_CATEGORY.PRESENTATION,
    name: FOLDER_TYPE.fremont_1x4,
    entry_point: "/fremont1x4",
    default_dimensions: {
      x: 0,
      y: 0,
      scale_x: 1080,
      scale_y: 7680
    },
    display_name: "One by Four Display",
    acceptable_folder_types: [FOLDER_TYPE.fremont_1x4_slideshow],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      zoomable: true,
      aspect_ratio: 0.140625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT,
      view_percentage: {
        orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
        value: 6
      }
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    }
  },
  fremont_1x4_slideshow: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.fremont_1x4_slideshow,
    display_name: "1x4 Slideshow",
    acceptable_folder_types: [FOLDER_TYPE.fremont_1x4_slide],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      },
      transition: {
        type: "select",
        title: "Transition",
        validation: { rules: [{ name: "isRequired" }] },
        default: "crossfade",
        options: [
          {
            title: "Crossfade",
            value: TRANSITION_CLASS.CROSSFADE_2S
          },
          {
            title: "Fade Out",
            value: TRANSITION_CLASS.TRANSITION_FADEOUT_2S
          }
        ]
      },
      // default_overlay: {
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ['image/*'],
      //   limit: 1,
      //   slots: [{
      //     title: "Default overlay",
      //     name: "default_overlay",
      //     aspect: 0.140625
      //   }]
      // }
      default_overlay: {
        title: "Default Overlay",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*"],
        image_presets: [],
        video_presets: [],
        aspect: 0.140625,
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.140625
        },
        recommended_size: {
          width: 1080,
          height: 7680
        }
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      aspect_ratio: 0.140625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    }
  },
  fremont_1x4_slide: {
    folder_category: FOLDER_CATEGORY.SLIDESHOW,
    name: FOLDER_TYPE.fremont_1x4_slide,
    display_name: "1x4 Slide",
    acceptable_folder_types: [],
    tree_dropped_into_action: [{ action: "move", options: {} }],
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      // file: {
      //   title: "Image / Video",
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ["image/*", "video/*"],
      //   video_presets: [1080],
      //   limit: null,
      //   slots: [
      //     {
      //       name: "File",
      //       aspect: 0.140625
      //     }
      //   ],
      //   default: null
      // },
      file: {
        title: "Image / Video",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [1080],
        aspect: 0.140625,
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.140625
        },
        recommended_size: {
          width: 1080,
          height: 7680
        }
      },
      override_overlay: {
        title: "Override Overlay",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*"],
        image_presets: [],
        video_presets: [1080],
        aspect: 0.140625,
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 0.140625
        }
      }
      // override_overlay: {
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ['image/*'],
      //   limit: 1,
      //   slots: [{
      //     title: "Override overlay",
      //     name: "override_overlay",
      //     aspect: 0.140625
      //   }]
      // }
    },
    preview: {
      aspect_ratio: 0.140625,
      component_name: COMPONENT_NAME.FremontOverlaySlide,
      type: PREVIEW_TYPE.COMPONENT
    },
    client: {
      component_name: COMPONENT_NAME.FremontOverlaySlide
    },
    tree_icon_types: {
      open: {
        ...tree_icon_types.donor_open
      },
      closed: {
        ...tree_icon_types.donor_closed
      }
    }
  },
  fremont_attract_loop: {
    folder_category: FOLDER_CATEGORY.CONTENT,
    name: FOLDER_TYPE.fremont_attract_loop,
    display_name: "Attract Loop",
    acceptable_folder_types: [
      FOLDER_TYPE.fremont_template_001,
      FOLDER_TYPE.fremont_template_002,
      FOLDER_TYPE.fremont_template_003,
      FOLDER_TYPE.fremont_template_004,
      FOLDER_TYPE.fremont_template_005,
      FOLDER_TYPE.fremont_media_template_001,
      FOLDER_TYPE.fremont_media_template_002,
      FOLDER_TYPE.fremont_donor_list_001,
      FOLDER_TYPE.fremont_donor_list_category
    ],
    tree_icon_types: {
      open: {
        ...tree_icon_types.attract_loops
      },
      closed: {
        ...tree_icon_types.attract_loops
      }
    },
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      }
    },
    tree_dropped_into_action: [{ action: "instance", options: {} }],
    prevent_dragging_out: true,
    prevent_last_instance_in_folder: true,
    prevent_add_children: true,
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    },
    list_view: {
      enabled: true,
      list_folder_types: [
        FOLDER_TYPE.fremont_template_001,
        FOLDER_TYPE.fremont_template_002,
        FOLDER_TYPE.fremont_template_003,
        FOLDER_TYPE.fremont_template_004,
        FOLDER_TYPE.fremont_template_005,
        FOLDER_TYPE.fremont_media_template_001,
        FOLDER_TYPE.fremont_media_template_002,
        FOLDER_TYPE.fremont_donor_list_001
      ], //will need to handle missing fields if more then one type
      columns: [
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002,
            FOLDER_TYPE.fremont_donor_list_001
          ],
          field: "list_view_selected",
          title: "",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002,
            FOLDER_TYPE.fremont_donor_list_001
          ],
          field: "name",
          title: "Name",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002,
            FOLDER_TYPE.fremont_donor_list_001
          ],
          field: "header01",
          title: "Header",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002,
            FOLDER_TYPE.fremont_donor_list_001
          ],
          field: "file",
          title: "Image / Media",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002,
            FOLDER_TYPE.fremont_donor_list_001
          ],
          field: "cycle_time",
          title: "Cycle Time",
          sort: true,
          editable: true,
          default_shown: true
        }
      ]
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    },
    instancing_options: {
      enabled: true,
      cross_project: false
    },
    hide_nested_children: true
  },
  fremont_templates: {
    folder_category: FOLDER_CATEGORY.STORIES,
    name: FOLDER_TYPE.fremont_templates,
    display_name: "Stories",
    acceptable_folder_types: [
      FOLDER_TYPE.fremont_template_001,
      FOLDER_TYPE.fremont_template_002,
      FOLDER_TYPE.fremont_template_003,
      FOLDER_TYPE.fremont_template_004,
      FOLDER_TYPE.fremont_template_005,
      FOLDER_TYPE.fremont_media_template_001,
      FOLDER_TYPE.fremont_media_template_002
    ],
    tree_icon_types: {
      open: {
        ...tree_icon_types.stories
      },
      closed: {
        ...tree_icon_types.stories
      }
    },
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false
      }
    },
    tree_dropped_into_action: [{ action: "move", options: {} }],
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.ComponentLoop1911,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    },
    list_view: {
      enabled: true,
      list_folder_types: [
        FOLDER_TYPE.fremont_template_001,
        FOLDER_TYPE.fremont_template_002,
        FOLDER_TYPE.fremont_template_003,
        FOLDER_TYPE.fremont_template_004,
        FOLDER_TYPE.fremont_template_005,
        FOLDER_TYPE.fremont_media_template_001,
        FOLDER_TYPE.fremont_media_template_002
      ], //will need to handle missing fields if more then one type
      columns: [
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "list_view_selected",
          title: "",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "name",
          title: "Name",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "header01",
          title: "Header",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "file",
          title: "Media",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "cycle_time",
          title: "Cycle Time",
          sort: true,
          editable: true,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "updatedAt",
          title: "Modified Date",
          sort: true,
          editable: false,
          default_shown: true
        },
        {
          folder_types: [
            FOLDER_TYPE.fremont_template_001,
            FOLDER_TYPE.fremont_template_002,
            FOLDER_TYPE.fremont_template_003,
            FOLDER_TYPE.fremont_template_004,
            FOLDER_TYPE.fremont_template_005,
            FOLDER_TYPE.fremont_media_template_001,
            FOLDER_TYPE.fremont_media_template_002
          ],
          field: "updatedBy",
          title: "Updated By",
          sort: true,
          filter: true,
          editable: false,
          default_shown: true
        }
      ]
    },
    client: {
      component_name: COMPONENT_NAME.ComponentLoop1911
    }
  },
  fremont_donor_list_category,
  fremont_donor_lists,
  fremont_template_001,
  fremont_template_002: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_template_002,
    display_name: "Header, Title, Image, Body Text",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        show_char_count: false
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      title01: {
        type: "text",
        title: "Title",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 70
        }
      },
      paragraph01: {
        type: "textarea",
        title: "Body Text",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
        default: null,
        settings: {
          recommended_length: 750
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      file: {
        title: "Image",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [],
        aspect: 1.966,
        recommended_size: {
          width: 873,
          height: 437
        },
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 1.966
        }
      }
      // file: {
      //   title: "Image",
      //   type: "file",
      //   slot_type: "fixed",
      //   acceptable_contents: ["image/*"],
      //   limit: null,
      //   slots: [
      //     {
      //       name: "File",
      //       aspect: 1.966
      //     }
      //   ],
      //   default: null
      // }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontTemplate002,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  fremont_template_003: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_template_003,
    display_name: "Header, Quote, Image",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        show_char_count: false,
        default: null
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      quote01: {
        type: "textarea",
        title: "Quote",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
        default: null,
        settings: {
          recommended_length: 300
        }
      },
      quotedPerson01: {
        type: "textarea",
        title: "Quoted person",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
        default: null,
        settings: {
          recommended_length: 45
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      file: {
        title: "Image",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [],
        aspect: 1,
        recommended_size: {
          width: 756,
          height: 756
        },
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 1
        }
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontTemplate003,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  fremont_template_004: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_template_004,
    display_name: "Header, Quote",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        show_char_count: false
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      quote01: {
        type: "textarea",
        title: "Quote",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
        default: null,
        settings: {
          recommended_length: 500
        }
      },
      quotedPerson01: {
        type: "textarea",
        title: "Quoted person",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 500 }] },
        default: null,
        settings: {
          recommended_length: 45
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontTemplate004,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  fremont_template_005: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_template_005,
    display_name: "Header, Rich Text",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        show_char_count: false
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      text01: {
        type: "wysiwyg",
        title: "Rich Text",
        validation: { rules: [] },
        default: {
          type: "style", // Options are: style | ops
          style: {
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: "20em"
          },
          ops: []
        },
        tooltip: [
          "<span>Recommended character count is based on normal font size settings and a standard paragraph format.</span>"
        ],
        settings: {
          fonts: [FontsFamilies.MYRIAD_PRO, FontsFamilies.WHITNEY],
          fontSizeOptions: {
            type: "range", // Could be range or custom (supply)
            min: 14,
            max: 26
          },
          recommended_length: 1420,
          // fontSizeOptions: {
          //   type: 'custom',
          //   options: [{
          //     alias: '16',
          //     em: '16'
          //   }, {
          //     alias: '24',
          //     em: '24',
          //   }, {
          //     alias: '36',
          //     em: '36',
          //   }]
          // },
          controls: {
            fontSize: true,
            bold: true,
            italic: true,
            underline: true,
            numberedList: true,
            bulletList: true,
            alignment: true,
            indentation: true,
            clearFormat: true
          }
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontTemplate005,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  fremont_media_template_001: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_media_template_001,
    display_name: "Header, Title, Media (photo, video, graphic), Body Text",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        show_char_count: false
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      subtitle01: {
        type: "text",
        title: "Title",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 70
        }
      },
      text01: {
        type: "textarea",
        title: "Body Text",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          autosize: { minRows: 3, maxRows: 6 }, // same as default used for example
          recommended_length: 625
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      file: {
        title: "Media",
        type: "file",
        slot_type: "dynamic",
        limit: 1,
        acceptable_contents: ["image/*", "video/*"],
        image_presets: [],
        video_presets: [],
        aspect: 1.7845,
        recommended_size: {
          width: 1080,
          height: 611
        },
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 1.7845
        }
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontMediaTemplate001,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  [FOLDER_TYPE.fremont_media_template_002]: {
    ...base_types.story,
    name: FOLDER_TYPE.fremont_media_template_002,
    display_name: "Header, Media (photo, video, graphic)",
    fields: {
      name: {
        type: "text",
        title: "Name",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        show_char_count: false
      },
      header01: {
        type: "text",
        title: "Header",
        validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
        default: null,
        settings: {
          recommended_length: 38
        }
      },
      cycle_time: {
        type: "integer",
        title: "Cycle Time",
        default: 0,
        validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
        tooltip: cycle_time_tooltips
      },
      file: {
        title: "Media",
        type: "file",
        slot_type: "dynamic",
        acceptable_contents: ["image/*", "video/*"],
        limit: 1,
        default: null,
        image_presets: ["thumbnail", "small", "large"],
        video_presets: [360, 480, 720],
        aspect: 1,
        recommended_size: {
          width: 1080,
          height: 1362
        },
        container: {
          imgCover: IMAGE_ALIGNMENT.CONTAIN,
          flexClass: FLEX_CLASSES.FLEX_CENTER,
          aspect: 1
        }
      }
    },
    preview: {
      aspect_ratio: 0.5625,
      component_name: COMPONENT_NAME.FremontMediaTemplate002,
      type: PREVIEW_TYPE.COMPONENT,
      static_url: "https://pdg-public.s3.us-west-2.amazonaws.com/fremont_background.jpg"
    }
  },
  fremont_donor_list_001,
  fremont_donor_list_002,
  fremont_donor_001
};

// module.exports = fremont_types
