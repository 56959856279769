import React, {useEffect, useState} from "react";
import get from "lodash/get";
import {useNavigate, useParams} from 'react-router-dom';
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html-cb";
import {IDisplayComponentProps} from "../../../interfaces/display-component-props.interface";
import {getBaseFolder, getBaseFolderType} from "../../../utils/commonDataUtils";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import {ArrowRightOutlined} from '@ant-design/icons';
import _ from "lodash";
import {FOLDER_TYPE} from "../../../../shared-global/enums/folder-type-enums";
import {getChildrenFolderAndFolderFoldersDeep} from "../../../utils/collectionUtils";
import Slide1911 from "../../standard/Slide1911";
import {DEFAULT_SCROLLBAR_COLOR} from "./EugeneConstants";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";
import RichTextRender from "../../standard/RichTextRender";

interface IEugeneDonorListGalleryList {
    color: string,
    alignment: string,
    font: string,
    fontSize: number,
    lineHeight: number
}

interface IEugeneDonorListGalleryListTitle {
    color: string,
    alignment: string,
    font: string,
    fontSize: number,
    lineHeight: number,
    bold: boolean
}

interface IEugeneDonorListGalleryHorizontalRuleField {
    color: string,
    weight: number,
}

const EugeneDonorListsOverview = (props: IDisplayComponentProps) => {
    const [baseFolder, setBaseFolder] = useState(null);
    const [baseFolderType, setBaseFolderType] = useState(null);
    const [donorLists, setDonorLists] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [menuTitle, setMenuTitle] = useState('');
    const [horizontalRule, setHorizontalRule] = useState<IEugeneDonorListGalleryHorizontalRuleField>({color: '', weight: 4});
    const [listTitle, setListTitle] = useState<IEugeneDonorListGalleryListTitle>({color: '', alignment: '', font: '', fontSize: 0, lineHeight: 1, bold: false});
    const [listDescription, setListDescription] = useState<IEugeneDonorListGalleryList>({color: '', alignment: '', font: '', fontSize: 0, lineHeight: 1});
    const [listSpacing, setListSpacing] = useState(1);

    const [menuFolderId, setMenuFolderId] = useState(null);
    const [donorListsFolderId, setDonorListsFolderId] = useState(null);

    const params = useParams<{ menufolderid, donorlistsfolderId }>();
    const navigate = useNavigate();

    useEffect(() => {
        const myBaseFolderType = getBaseFolderType(props.data, get(baseFolder, 'folder_type', {}));
        setBaseFolderType(myBaseFolderType)

        const color = get(baseFolder, 'fields.color_overlay', 'transparent')
        const horizontalRuleColor = get(baseFolder, 'fields.horizontal_rule_color', 'transparent')
        const horizontalRuleWeight = get(baseFolder, 'fields.horizontal_rule_weight', 4)
        const listTitleColor = get(baseFolder, 'fields.list_title.color', "#2699FB")
        const listTitleAlignment = get(baseFolder, 'fields.list_title.alignment', "left")
        const listTitleFont = get(baseFolder, 'fields.list_title.font', "Roboto Slab Regular")
        const listTitleFontSize = get(baseFolder, 'fields.list_title.fontSize', 50)
        const listTitleLineHeight = get(baseFolder, 'fields.list_title.lineHeight', 1)
        const listTitleBold = get(baseFolder, 'fields.list_title.bold', true)
        const listDescriptionColor = get(baseFolder, 'fields.list_description.color', "#000000")
        const listDescriptionAlignment = get(baseFolder, 'fields.list_description.alignment', "left")
        const listDescriptionFont = get(baseFolder, 'fields.list_description.font', "lato")
        const listDescriptionFontSize = get(baseFolder, 'fields.list_description.fontSize', 35)
        const listDescriptionLineHeight = get(baseFolder, 'fields.list_description.lineHeight', 1.5)
        const listSpacing = get(baseFolder, 'fields.list_spacing', 4)
    
        setMenuTitle(convertRichTextIntoHtml(get(baseFolder, 'fields[menu_title].ops', [])));
        setHorizontalRule({color: horizontalRuleColor, weight: horizontalRuleWeight});
        setListTitle({color: listTitleColor, alignment: listTitleAlignment, font: listTitleFont, fontSize: listTitleFontSize, lineHeight: listTitleLineHeight, bold: listTitleBold})
        setListSpacing(listSpacing)
        setListDescription({color: listDescriptionColor, alignment: listDescriptionAlignment, font: listDescriptionFont, fontSize: listDescriptionFontSize, lineHeight: listDescriptionLineHeight})
        setBackgroundColor(color);
    }, [baseFolder])

    useEffect(() => {
        setMenuFolderId(Number(params.menufolderid));
        setDonorListsFolderId(Number(params.donorlistsfolderId));

    }, [params])

    const ch = (factor: number) => {
        return props.containerHeight * factor;
    };

    const chemListTitle = (factor: number) => {
        return props.containerHeight / 1500 * factor;
    }
    
    const findFolder = folderId => {
        return props.data.folders.find(f => f.id === folderId);
    }

    const getOnlyDonorListsFirstLevel = (donorListFolderId, folders) => {

        const donorLists = props.data.folder__folders
            .filter(ff => ff.parent_folder == donorListFolderId)
            .map(fff => {
                const folderFound = findFolder(fff.child_folder);
                return folderFound;
            })
            .filter(fm => !!fm)
            .filter(fm => fm && (
                fm.folder_type === FOLDER_TYPE.eugene_category_with_giving_level ||
                fm.folder_type === FOLDER_TYPE.eugene_category_without_giving_level ||
                fm.folder_type === FOLDER_TYPE.eugene_donor_list))
        return donorLists;
    }



    const getBaseFolderDonorLists = (donorListFolderId, data) => {
        const myFolder = data.folders.find(f => f.id === donorListFolderId);
        return myFolder;
    }

    useEffect(() => {
        if (donorListsFolderId && props.data) {
            const myBaseFolder = getBaseFolderDonorLists(donorListsFolderId, props.data);
            setBaseFolder(myBaseFolder)

            const myDonorLists = getOnlyDonorListsFirstLevel(donorListsFolderId, props.data.folders)
            setDonorLists(myDonorLists);
        } else if (!donorListsFolderId && props.data && props.data.base_folder) {
            const myDonorListsFolderId = props.data.base_folder;
            const myBaseFolder = getBaseFolder(props.data);
            setBaseFolder(myBaseFolder)

            const myDonorLists = getOnlyDonorListsFirstLevel(myDonorListsFolderId, props.data.folders)
            setDonorLists(myDonorLists);
        }

    }, [donorListsFolderId, props.data])

    const convertRichTextIntoHtml = (ops) => {
        const convertor = new QuillDeltaToHtmlConverter(ops, { multiLineParagraph: false });
        let html = convertor ? convertor.convert() : '';
        return html
    }

    const navigateToDonorList = (donor_list: IFolder) => {
        const menuFolderId = props.data.folder__folders.find(el => el.child_folder === donor_list.id).parent_folder
        navigate(`${props.data.basePath}/donor_list_menu/${menuFolderId}/donor_list_with_category/${donor_list.id}`)
    }

    return (
        <div
            className="eugene-donor-lists"
        >
            {/* Background Image */}
            <div
                className="eugene-donor-lists_background-image"
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    zIndex: -2
                }}
            >
                <Slide1911
                    aws={props.aws}
                    aws_bucket={props.aws_bucket}
                    mode={props.mode}
                    containerWidth={props.containerWidth}
                    containerHeight={props.containerHeight}
                    data={{
                        ...props.data,
                        slotNames: ["background"],
                        base_folder: get(baseFolder, 'id', null),
                        backgroundSize: "cover",
                    }}
                    webview_signedurls={props.webview_signedurls}
                />
            </div>
            <div
                style={{
                    position: "absolute",
                    width: props.containerWidth,
                    height: props.containerHeight,
                    backgroundColor: backgroundColor,
                    zIndex: -1
                }}
            />
            <div
                className="eugene-donor-lists_container"
                style={{
                    display: "flex",
                    position: "relative",
                    flex: 1,
                    height: props.containerHeight,
                    paddingTop: props.containerHeight * 0.03,
                    paddingBottom: props.containerHeight * 0.03,
                    paddingLeft: props.containerWidth * 0.15,
                    paddingRight: props.containerWidth * 0.15,
                }}
            >
                <div
                    className="eugene-donor-lists_menu"
                    style={{
                        height: "100%",
                        width: props.containerWidth * 0.2,
                        paddingLeft: 0.0 * props.containerWidth,
                        paddingRight: 0.0 * props.containerWidth,
                    }}
                >
                </div>
                <div
                    className="eugene-donor-lists_donor-lists"
                    style={{
                        height: "100%",
                        width: props.containerWidth * 0.6,
                        paddingLeft: 0.01 * props.containerWidth,
                    }}
                >
                    <div
                        className="eugene-donor-lists_donor-lists-title"
                        style={{
                            paddingTop: props.containerHeight * 0.08,
                            paddingBottom: props.containerHeight * 0.06,
                            marginBottom: props.containerHeight * 0.03,
                            borderBottom: `${ch(horizontalRule.weight / 1000)}px solid ${horizontalRule.color}`,
                        }}
                    >
                        <RichTextRender 
                            containerHeight={props.containerHeight} 
                            htmlString={menuTitle}
                        />
                    </div>
                    <div
                        className="eugene-donor-lists_donor-lists-items"
                    >
                        <ScrollableFixContainer2103
                            containerHeight={props.containerHeight * 0.75}
                            containerWidth={props.containerWidth * 0.6}
                            showScrollbar={false}
                            barColor={DEFAULT_SCROLLBAR_COLOR}
                        >
                            <div>
                                {donorLists.map((dl, index) => {
                                    const header = get(dl, 'fields[header].ops[0].insert', []);
                                    const description = get(dl, 'fields[subhead].ops[0].insert', []);

                                    return (
                                        <div
                                            key={`eugene-donor-lists_donor-lists-items-${index}`}
                                            style={{
                                                display: "flex",
                                                backgroundColor: "#FFFFFF",
                                                flexDirection: "column",
                                                padding: props.containerHeight * 0.05,
                                                paddingTop: props.containerHeight * 0.02,
                                                paddingBottom: props.containerHeight * 0.02,
                                                marginBottom: props.containerHeight * 0.068345323741007,
                                            }}
                                        >
                                            <div className="ql-editor-preview">
                                                <div
                                                    className="ql-editor eugene-donor-lists_donor-lists-header"
                                                    style={{
                                                        marginBottom: (props.containerHeight * 0.005) * listSpacing,
                                                        paddingLeft: 0,
                                                        paddingBottom: 0,
                                                        paddingTop: props.containerHeight * 0.02,
                                                    }}
                                                >
                                                    <p style={{
                                                        fontSize: chemListTitle(listTitle.fontSize),
                                                        lineHeight: listTitle.lineHeight,
                                                        fontWeight: listTitle.bold ? "bold" : "normal",
                                                        color: listTitle.color,
                                                        fontFamily: listTitle.font,
                                                        // @ts-ignore
                                                        textAlign: listTitle.alignment,
                                                        padding: 0,
                                                        // overflowY: "clip",
                                                    }}>
                                                        {header}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="ql-editor-preview">
                                                <div
                                                    className="eugene-donor-lists_donor-lists-description"
                                                >
                                                    <p style={{
                                                        padding: 0,
                                                        // overflowY: "clip",
                                                        fontSize: chemListTitle(listDescription.fontSize),
                                                        lineHeight: listDescription.lineHeight,
                                                        color: listDescription.color,
                                                        fontFamily: listDescription.font,
                                                        // @ts-ignore
                                                        textAlign: listDescription.alignment,
                                                    }}>
                                                        {description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    alignSelf: "flex-end",
                                                    color: '#639cfb',
                                                    height: props.containerHeight * 0.06,
                                                }}
                                                >
                                                <span
                                                    style={{
                                                        textAlign: 'right',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => navigateToDonorList(dl)}
                                                >
                                                    <ArrowRightOutlined
                                                        style={{
                                                            fontSize: props.containerHeight * .036,
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </ScrollableFixContainer2103>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EugeneDonorListsOverview;
