import { Button, Slider } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Cropper from "react-easy-crop";
import { Switch } from 'antd';
import Spaner from "../../utils/Spaner";
import {
  getImageDimensions,
  getImageDimensionsFromS3,
  getInnerConstraints,
} from "./DynamicFileInput/FileInputService";

const padding = 24;
const ww = 1024 - padding * 2; // can adjust as needed or base on something different
const hh = 500;
const BUTTON_WIDTH = 120;

const sty = {
  container: {
    width: ww,
    height: hh,
    position: "relative",
    backgroundColor: "white",
  },
  cropContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
    width: ww,
    height: hh - padding * 2.5,
  },
  sliderContainer: {
    position: "absolute",
    bottom: 0,
    width: ww * 0.4,
  },
  buttonContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: ww * 0.6,
    textAlign: "right",
  },
};

const convertCroppedAreaIntoCropData = (croppedArea) => {
  const { x, y, width, height } = croppedArea;
  const adjustedCrop = { ...croppedArea };

  if (width + Math.abs(x) > 100 || x < 0) {
    let newX = 0;
    let newWidth = 0;
    if (x > 0) {
      newX = x;
      newWidth = 100 - x;
    } else {
      newX = 0;
      newWidth = width - Math.abs(x) > 100 ? 100 : width - Math.abs(x);
    }
    adjustedCrop.width = newWidth;
    adjustedCrop.x = newX;
  }

  if (height + Math.abs(y) > 100 || y < 0) {
    let newY = 0;
    let newHeight = 0;
    if (y > 0) {
      newY = y;
      newHeight = 100 - y;
    } else {
      newY = 0;
      newHeight = height - Math.abs(y) > 100 ? 100 : height - Math.abs(y);
    }
    adjustedCrop.height = newHeight;
    adjustedCrop.y = newY;
  }

  return adjustedCrop;
};

export default function ImageCropper(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropInitalState, setCropInitialState] = useState(null);
  const [croppedArea, setCroppedArea] = useState(undefined);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
  const [zoom, setZoom] = useState(2);
  const [zoomInitialState, setZoomInitialState] = useState(null);
  const [url, setUrl] = useState(null);
  const [imageData, setImageData] = useState({ width: 0, height: 0 });
  const [sliderMin, setSliderMin] = useState(1);
  const [showResetButton, setShowResetButton] = useState(false);
  const componentIsMounted = useRef(true);
  const [darkMode, setDarkMode] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    const myCrop = convertCroppedAreaIntoCropData(croppedArea);
    setCroppedArea(myCrop);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const updateCropSettingInState = (imageData) => {
    const constraints = getInnerConstraints(
      ww,
      hh,
      props.aspect,
      imageData.width / imageData.height
    );
    const minScale = Math.min(
      constraints.imgHeight / constraints.contHeight,
      constraints.imgWidth / constraints.contWidth
    );
    if (minScale < 1) {
      setSliderMin(minScale);
      setZoom(minScale);
    }
  };

  const isCroppedFromDB = (image) => {
    const cropData = getResetCropFromDB(image);
    return !!cropData.cropOriginal;
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    }
  }, [])

  useEffect(() => {
    const { image } = props;
    if (image) {
      const { file, url: resourceUrl } = image;

      if (file && file instanceof File && !url) {
        const objUrl = URL.createObjectURL(file);
        getImageDimensions(file).then((imageDataFromFile) => {
          if (componentIsMounted.current) {
            updateCropSettingInState(imageDataFromFile);
            setImageData(imageDataFromFile);
          }
        });
        setUrl(objUrl);
      } else if (image.url && !url) {
        getImageDimensionsFromS3(image.url).then((imageDataFromS3) => {
          if (componentIsMounted.current) {
            updateCropSettingInState(imageDataFromS3);
            setImageData(imageDataFromS3);
          }
        });
        setUrl(resourceUrl);
      } else if (image.resourceUrl) {
        getImageDimensionsFromS3(image.resourceUrl).then((imageDataFromS3) => {
          if (componentIsMounted.current) {
            updateCropSettingInState(imageDataFromS3);
            setImageData(imageDataFromS3);
          }
        });
        setUrl(resourceUrl);
      }

      // set crop if it exist taken form memory crop
      const cropDataFromMemory = getResetCropFromMemory(image);
      if (cropDataFromMemory.cropOriginal) {
        setCroppedAreaPixels(cropDataFromMemory.cropOriginal);
      }

      if (isCroppedFromDB(image)) {
        setShowResetButton(true);
      }
    }
  }, [props.image]);

  const finalizeCrop = async () => {
    await props.onCrop(props.image.rank, {
      crop: { ...croppedArea },
      cropOriginal: { ...croppedAreaPixels },
      content_manager_dark_mode: darkMode
    });
    props.changeMode("view");
  };

  const zoomToFill = () => {
    setCrop({ x: 0, y: 0 });
    updateCropSettingInState(imageData);
  };

  const getResetCropFromDB = (image) => {
    const cropData = {
      crop: null,
      cropOriginal: null,
    };

    // new image in memory
    cropData.crop =
      image.contents && image.contents.crop ? image.contents.crop : null;
    cropData.cropOriginal =
      image.contents && image.contents.cropOriginal
        ? image.contents.cropOriginal
        : null;

    return cropData;
  };

  const getResetCropFromMemory = (image) => {
    const cropData = {
      crop: null,
      cropOriginal: null,
    };

    // new image in memory
    cropData.crop = image.crop || null;
    cropData.cropOriginal = image.cropOriginal || null;

    return cropData;
  };

  const reset = async () => {
    const { image } = props;
    const cropData = getResetCropFromDB(image);
    if (cropData && cropData.cropOriginal) {
      setCroppedArea({ ...cropData.crop });
      setCroppedAreaPixels({ ...cropData.cropOriginal });
      setZoom(zoomInitialState);
      setCrop(cropInitalState);
    }
  };

  if (imageData.width === 0) {
    return <span />;
  }

  const setCropAndInitialStateIfNeed = (innerCrop) => {
    if (!cropInitalState) {
      setCropInitialState(innerCrop);
    }

    setCrop(innerCrop);
  };

  const setZoomAndInitialStateIfNeed = (innerZoom) => {
    if (!zoomInitialState) {
      setZoomInitialState(innerZoom); // set first zoom value
    }
    setZoom(innerZoom);
  };

  const onChangeDarkMode = (checked) => {
    setDarkMode(checked);
  };

  return (
    <div style={sty.container}>
      <div style={sty.cropContainer} className={'image-cropper_container-white'}>
        <Cropper
          key={url}
          image={url}
          crop={crop}
          zoom={zoom}
          aspect={props.aspect || 1.333}
          minZoom={sliderMin}
          onCropChange={setCropAndInitialStateIfNeed}
          onCropComplete={onCropComplete}
          onZoomChange={setZoomAndInitialStateIfNeed}
          restrictPosition={zoom > 1}
          initialCroppedAreaPixels={croppedAreaPixels}
        />
      </div>
      <div style={sty.sliderContainer}>
        <Slider
          defaultValue={zoom}
          value={zoom}
          min={sliderMin}
          max={3}
          onChange={(zoom) => {
            setZoom(zoom);
          }}
          step={0.025}
          disabled={false}
        />
      </div>
      <div style={sty.buttonContainer}>
        {/*<Switch checkedChildren="dark" unCheckedChildren="white" onChange={onChangeDarkMode}></Switch>*/}
        <Spaner width="sm" />
        <Button
          onClick={() => {
            props.changeMode("view");
          }}
          style={{ width: BUTTON_WIDTH }}
          className="image-cropper_button"
        >
          Cancel
        </Button>
        <Spaner width="sm" />
        <Button type="primary" className="image-cropper_button" style={{ width: BUTTON_WIDTH }} onClick={zoomToFill}>
          Zoom to fill
        </Button>
        <Spaner width="sm" />
        {showResetButton ? (
          <Button type="primary" className="image-cropper_button" style={{ width: BUTTON_WIDTH }} onClick={reset}>
            Reset
          </Button>
        ) : null}
        {showResetButton ? <Spaner width="sm" /> : null}
        <Button type="primary" className="image-cropper_button" style={{ width: BUTTON_WIDTH }} onClick={finalizeCrop}>
          Crop
        </Button>
      </div>
    </div>
  );
}
