import {
  COMPONENT_NAME,
  FOLDER_CATEGORY,
  FOLDER_TYPE,
  FOLDER_TYPE_SELECTOR,
  PREVIEW_TYPE
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { tree_icon_types } from "../tree_icon_types";

export const marin_content_stories: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.marin_content_stories,
  display_name: "Stories",
  acceptable_folder_types: [
    FOLDER_TYPE.marin_story_with_fact,
    FOLDER_TYPE.marin_fullscreen_fact_story,
    FOLDER_TYPE.marin_media_blank_1,
    FOLDER_TYPE.marin_media_blank_2,
    FOLDER_TYPE.marin_media_blank_3,
    FOLDER_TYPE.marin_media_blank_4,
    FOLDER_TYPE.marin_media_blank_5,
    FOLDER_TYPE.marin_media_blank_6,
    FOLDER_TYPE.marin_media_blank_7,
    FOLDER_TYPE.marin_media_blank_8,
    FOLDER_TYPE.marin_story_with_media,
    FOLDER_TYPE.marin_story_media_with_quote,
    FOLDER_TYPE.marin_story_media_with_quote_2_1,
    FOLDER_TYPE.marin_story_large_media_with_quote,
    FOLDER_TYPE.marin_profile_with_media_story,
    FOLDER_TYPE.marin_intro_story
  ],
  tree_icon_types: {
    open: {
      ...tree_icon_types.stories
    },
    closed: {
      ...tree_icon_types.stories
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    aspect_ratio: 1.333,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.NONE
  },
  list_view: {
    enabled: true,
    list_folder_types: [
      FOLDER_TYPE.marin_intro_story,
      FOLDER_TYPE.marin_story_with_fact,
      FOLDER_TYPE.marin_fullscreen_fact_story,
      FOLDER_TYPE.marin_media_blank_1,
      FOLDER_TYPE.marin_media_blank_2,
      FOLDER_TYPE.marin_media_blank_3,
      FOLDER_TYPE.marin_media_blank_4,
      FOLDER_TYPE.marin_media_blank_5,
      FOLDER_TYPE.marin_media_blank_6,
      FOLDER_TYPE.marin_media_blank_7,
      FOLDER_TYPE.marin_media_blank_8,
      FOLDER_TYPE.marin_story_with_media,
      FOLDER_TYPE.marin_story_media_with_quote,
      FOLDER_TYPE.marin_story_media_with_quote_2_1,
      FOLDER_TYPE.marin_story_large_media_with_quote,
      FOLDER_TYPE.marin_profile_with_media_story
    ], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [
          FOLDER_TYPE.marin_intro_story,
          FOLDER_TYPE.marin_story_with_fact,
          FOLDER_TYPE.marin_fullscreen_fact_story,
          FOLDER_TYPE.marin_media_blank_1,
          FOLDER_TYPE.marin_media_blank_2,
          FOLDER_TYPE.marin_media_blank_3,
          FOLDER_TYPE.marin_media_blank_4,
          FOLDER_TYPE.marin_media_blank_5,
          FOLDER_TYPE.marin_media_blank_6,
          FOLDER_TYPE.marin_media_blank_7,
          FOLDER_TYPE.marin_media_blank_8,
          FOLDER_TYPE.marin_story_with_media,
          FOLDER_TYPE.marin_story_media_with_quote,
          FOLDER_TYPE.marin_story_media_with_quote_2_1,
          FOLDER_TYPE.marin_story_large_media_with_quote,
          FOLDER_TYPE.marin_profile_with_media_story
        ],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.marin_intro_story,
          FOLDER_TYPE.marin_story_with_fact,
          FOLDER_TYPE.marin_fullscreen_fact_story,
          FOLDER_TYPE.marin_media_blank_1,
          FOLDER_TYPE.marin_media_blank_2,
          FOLDER_TYPE.marin_media_blank_3,
          FOLDER_TYPE.marin_media_blank_4,
          FOLDER_TYPE.marin_media_blank_5,
          FOLDER_TYPE.marin_media_blank_6,
          FOLDER_TYPE.marin_media_blank_7,
          FOLDER_TYPE.marin_media_blank_8,
          FOLDER_TYPE.marin_story_with_media,
          FOLDER_TYPE.marin_story_media_with_quote,
          FOLDER_TYPE.marin_story_media_with_quote_2_1,
          FOLDER_TYPE.marin_story_large_media_with_quote,
          FOLDER_TYPE.marin_profile_with_media_story
        ],
        field: "name",
        title: "Label",
        sort: true,
        editable: false,
        default_shown: true,
        required: true
      },
      {
        folder_types: [
          FOLDER_TYPE.marin_intro_story,
          FOLDER_TYPE.marin_media_blank_1,
          FOLDER_TYPE.marin_media_blank_2,
          FOLDER_TYPE.marin_media_blank_3,
          FOLDER_TYPE.marin_media_blank_4,
          FOLDER_TYPE.marin_media_blank_5,
          FOLDER_TYPE.marin_media_blank_6,
          FOLDER_TYPE.marin_media_blank_7,
          FOLDER_TYPE.marin_media_blank_8,
          FOLDER_TYPE.marin_story_with_media,
          FOLDER_TYPE.marin_story_media_with_quote,
          FOLDER_TYPE.marin_story_media_with_quote_2_1,
          FOLDER_TYPE.marin_story_large_media_with_quote,
          FOLDER_TYPE.marin_profile_with_media_story
        ],
        field: "cycle_time",
        title: "Cycle Time",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.marin_intro_story,
          FOLDER_TYPE.marin_story_with_fact,
          FOLDER_TYPE.marin_fullscreen_fact_story,
          FOLDER_TYPE.marin_media_blank_1,
          FOLDER_TYPE.marin_media_blank_2,
          FOLDER_TYPE.marin_media_blank_3,
          FOLDER_TYPE.marin_media_blank_4,
          FOLDER_TYPE.marin_media_blank_5,
          FOLDER_TYPE.marin_media_blank_6,
          FOLDER_TYPE.marin_media_blank_7,
          FOLDER_TYPE.marin_media_blank_8,
          FOLDER_TYPE.marin_story_with_media,
          FOLDER_TYPE.marin_story_media_with_quote,
          FOLDER_TYPE.marin_story_media_with_quote_2_1,
          FOLDER_TYPE.marin_story_large_media_with_quote,
          FOLDER_TYPE.marin_profile_with_media_story
        ],
        field: "updatedAt",
        title: "Modified Date",
        sort: true,
        editable: false,
        default_shown: true
      },
      {
        folder_types: [
          FOLDER_TYPE.marin_intro_story,
          FOLDER_TYPE.marin_story_with_fact,
          FOLDER_TYPE.marin_fullscreen_fact_story,
          FOLDER_TYPE.marin_media_blank_1,
          FOLDER_TYPE.marin_media_blank_2,
          FOLDER_TYPE.marin_media_blank_3,
          FOLDER_TYPE.marin_media_blank_4,
          FOLDER_TYPE.marin_media_blank_5,
          FOLDER_TYPE.marin_media_blank_6,
          FOLDER_TYPE.marin_media_blank_7,
          FOLDER_TYPE.marin_media_blank_8,
          FOLDER_TYPE.marin_story_with_media,
          FOLDER_TYPE.marin_story_media_with_quote,
          FOLDER_TYPE.marin_story_media_with_quote_2_1,
          FOLDER_TYPE.marin_story_large_media_with_quote,
          FOLDER_TYPE.marin_profile_with_media_story
        ],
        field: "updatedBy",
        title: "Updated By",
        sort: true,
        filter: true,
        editable: false,
        default_shown: true
      }
    ]
  },
  folder_type_selector: {
    element_type: FOLDER_TYPE_SELECTOR.images,
    mode: "center",
    scrollable: true,
    option_height: 200,
    columns: 0
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  client: {
    component_name: COMPONENT_NAME.None
  },
  show_clone_children_checkbox: true,
  clone_children_checkbox_default_value: false
};
