import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { cycle_time_tooltips } from "../cycle_time_tooltips";
import { tree_icon_types } from "../tree_icon_types";

export const new_orleans_1x2_slide: IFolderType = {
  folder_category: FOLDER_CATEGORY.SLIDE,
  name: FOLDER_TYPE.new_orleans_1x2_slide,
  display_name: "1x2 Slide",
  acceptable_folder_types: [],
  tree_dropped_into_action: [{ action: "move", options: {} }],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 0,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips
    },
    file: {
      title: "Image / Video",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.28125,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.28125
      }
    },
    override_overlay: {
      title: "Override Overlay",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 0.2815,
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 0.2815
      }
    }
  },
  preview: {
    aspect_ratio: 0.28125,
    component_name: COMPONENT_NAME.FremontOverlaySlide,
    type: PREVIEW_TYPE.COMPONENT
  },
  client: {
    component_name: COMPONENT_NAME.FremontOverlaySlide
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_open
    },
    closed: {
      ...tree_icon_types.donor_closed
    }
  }
};
