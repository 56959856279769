import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table } from 'antd';
import { arrayFromKeyedObject } from '../../shared/utils/collectionUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd';
import { deleteRole} from '../../actions/companyActions';
import {dateFormatted} from '../../utils/dateUtils'
import { hasPermission } from '../../utils/permissionUtils';
import AlertNotification from '../layout/AlertNotification';
import { withRouter } from '../../hooks/withRouter';

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: ''
    };
  }


  componentDidMount() {
  }

  onInsert = () => {
    AlertNotification('success', 'Success', "Record successfully deleted")
  }

  onError = (message, description) => {
    AlertNotification('error', message, description)
  }

  render() {

    let roleTableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          if (!a.name) {
            a.name = ''
          }
          if (!b.name) {
            b.name = ''
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return 0;
        }
      }, {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => {
          return(
            <span>{dateFormatted(text, 'yyyymmdd')}</span>
          )
        },
        sorter: (a, b) => a.createdAt - b.createdAt
      },
      {
        title: 'Scope',
        dataIndex: 'scope',
        key: 'scope',
        sorter: (a, b) => {
          if (!a.scope) {
            a.scope = ''
          }
          if (!b.scope) {
            b.scope = ''
          }
          if (a.scope.toLowerCase() > b.scope.toLowerCase()) return -1;
          if (a.scope.toLowerCase() < b.scope.toLowerCase()) return 1;
          return 0;
        }
      }
    ];

    if(hasPermission(this.props.grouped_permissions, 'role', 'delete', this.props.user, null, null, this.props.user?.company ?? null)) {
      roleTableColumns.push({
        title: 'Delete',
        dataIndex: 'Delete',
        render: (text, record) => {
         // let url="/company/"+this.props.companyId+"/project/" + record.id + "/";
          let roleId=record.id;
          return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              console.log("here", this.onError)
              this.props.deleteRole(
                roleId,
                this.props.companyId, 
                this.onInsert, 
                (description) => this.onError("Error", description));
            }}
          >
            <FontAwesomeIcon className="fa-lg" style={{cursor: 'pointer'}} icon={['far', 'trash']} />

          </span>
        );
        }
      });
    }

    if(hasPermission(this.props.grouped_permissions, 'role', 'update', this.props.user, null, null, this.props.user?.company ?? null)) {
      roleTableColumns.push({
          title: 'edit',
          dataIndex: 'edit',
          render: (text, record) => {
            let url = "/company/" + this.props.companyId + "/role/" + record.id + "/";
            //let url=record.id;
            return (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // this.props.deleteRole(url,this.props.companyId,this.onInsert,this.onFail);
                  this.props.navigate(url);
                }}
              >
                <FontAwesomeIcon className="fa-lg" icon="edit" />

              </span>
            );
          }
        });
    }

    let roleArray = arrayFromKeyedObject(this.props.roles);
    let arr = [];
    roleArray.forEach(role => {
     // console.log("cid"+JSON.stringify(com.name +"::"+com.id));
      if (role.company === this.props.companyId) {
        let obj = {
          name: role.name,
          updatedAt: Date(role.updatedAt),
          createdAt: Date(role.createdAt),
          scope:role.scope,
          key: role.id,
          id:role.id
        };
        arr.push(obj);
    }
    });
    return (
      <div>
      <Table
        title={() => hasPermission(this.props.grouped_permissions, 'role', 'create', this.props.user, null, null, this.props.user?.company ?? null) ?
        <Button onClick={() => this.props.navigate(this.props.roleUrl)}>Add Role</Button> : <b>Roles</b>}
        rowKey={record => record.key}
        columns={roleTableColumns}
        dataSource={arr}
        pagination={false}
        bordered={false}
      />

      {/* <Button onClick={() => {
        this.props.navigate(this.props.roleUrl);
      }}>Add role</Button> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.data.roles,
    grouped_permissions: state.data.user_grouped_permissions,
    user: state.data.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteRole: (roleId, companyId, onSuccess, onFail) => {
      dispatch(deleteRole(roleId,companyId,onSuccess,onFail));
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles));