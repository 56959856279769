import React, { useEffect, useMemo, useRef, useState } from "react";
import HandTouch from "../../../images/global/HandTouch";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../../utils/commonDataUtils";
import ComponentLoop1911 from "../../standard/ComponentLoop1911";
import get from "lodash/get";

const MonterreyAttractLoop = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(
    () => getBaseFolder(props.data ?? {}),
    [props.data]
  );

  const handIconRef = useRef<HTMLDivElement>(null);
  const [animatedIntervalHandler, _setAnimatedIntervalHandler] = useState();
  const animatedIntervalHandlerRef = useRef(animatedIntervalHandler);
  const setAnimatedIntervalHandler = (data) => {
    animatedIntervalHandlerRef.current = data;
    _setAnimatedIntervalHandler(data);
  };

  const [animatedRepeatIntervalHandler, _setAnimatedRepeatIntervalHandler] =
    useState();
  const animatedRepeatIntervalHandlerRef = useRef(
    animatedRepeatIntervalHandler
  );
  const setAnimatedRepeatIntervalHandler = (data) => {
    animatedRepeatIntervalHandlerRef.current = data;
    _setAnimatedRepeatIntervalHandler(data);
  };

  const animateOut = () => {
    const animationSet: any = [];
    for (let i = 0; i <= 25; i++) {
      animationSet.push({
        transform: `translateY(-${i / 10}%)`,
        opacity: 1 - (i / 25)
      });
    }

    handIconRef.current?.animate(animationSet, {
      fill: "forwards",
      duration: 3000,
    });
  };

  const animateIn = () => {
    const animationSet: any = [];
    for (let i = 0; i <= 25; i++) {
      animationSet.push({
        transform: `translateY(-${(25 - i) / 10}%)`,
        opacity: (1 / 25) * i
      });
    }

    handIconRef.current?.animate(animationSet, {
      fill: "forwards",
      duration: 3000,
    });
  };

  const animateHandInterval = () => {
    animateOut();
    clearTimeout(animatedIntervalHandlerRef.current);
    const animateHandIntervalHandler = setTimeout(() => {
      animateIn();
      const animateRepeat = setTimeout(() => {
        animateHandInterval();
      }, 6000);
      setAnimatedRepeatIntervalHandler(animateRepeat);
    }, 3500);
    setAnimatedIntervalHandler(animateHandIntervalHandler);
  };

  useEffect(() => {
    animateHandInterval();
    return () => {
      clearTimeout(animatedIntervalHandlerRef.current);
      clearTimeout(animatedRepeatIntervalHandlerRef.current);
    };
  }, []);

  return (
    <>
      <ComponentLoop1911 {...props} />
      <div
        style={{
          position: "absolute",
          height: props.containerHeight,
          width: props.containerWidth,
          backgroundColor: get(
            baseFolder,
            "fields.background_image_overlay",
            "#000"
          ),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <div
        ref={handIconRef}
        style={{
          position: "absolute",
          height: props.containerHeight,
          width: props.containerWidth,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{ fontFamily: "MyriadPro", fontSize: "9.75em", color: "white", lineHeight: 1 }}
        >
          Start Exploring
        </span>
        <div style={{ maxHeight: "12em", width: "12em", marginLeft: "0.5em" }}>
          <HandTouch color="#FFF" />
        </div>
      </div>
    </>
  );
};

export default MonterreyAttractLoop;
