import {
  ADD_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATIONS
} from "./actionTypes";

export const add_location = (
  companyId,
  name,
  shows_in_tree,
  onSuccess?,
  onFail?
) => ({
  type: ADD_LOCATION,
  payload: { company_id: companyId, name: name, shows_in_tree: shows_in_tree },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const update_location = (
  location_id,
  name,
  shows_in_tree,
  onSuccess?,
  onFail?
) => ({
  type: UPDATE_LOCATION,
  payload: {
    location_id: location_id,
    name: name,
    shows_in_tree: shows_in_tree
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const delete_location = (location_id, onSuccess?, onFail?) => ({
  type: DELETE_LOCATION,
  payload: { location_id: location_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const get_locations = (onSuccess?, onFail?) => ({
  type: GET_LOCATIONS,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
