import React, { useEffect, useState } from "react";
import {IDisplayComponentProps} from "../../../interfaces/display-component-props.interface";
import Slide1911 from "../../standard/Slide1911";

const EugeneSlide: React.FunctionComponent<IDisplayComponentProps> = (
    props: IDisplayComponentProps
) => {

    return (
        <div
            style={{
                position: "absolute",
                height: props.containerWidth,
                width: props.containerHeight,
            }}
        >
            <Slide1911
                aws={props.aws}
                aws_bucket={props.aws_bucket}
                mode={props.mode}
                containerWidth={props.containerWidth}
                containerHeight={props.containerHeight}
                handleEndOfPlay={props.handleEndOfPlay}
                webview_signedurls={props.webview_signedurls}
                data={{ ...props.data, style: { backgroundPosition: 'left center' } }}
            />
        </div>
    )
}

export default EugeneSlide;