import { IFolderType } from '../../folders/IFolderType';
import base_types from '../base_types';
import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from '../../../../enums/folder-type-enums';
import {wysiwyg} from "../standardized_fields";
import {MessagePositions, QRPositionOptions, transitions, wysiwygSettings} from "./phoenix_settings";
import {fonts, FontsFamilies, FontVariants} from "../../../../fonts";
import {FLEX_CLASSES, IMAGE_ALIGNMENT} from "../../../../enums/ui-enums";
import {cycle_time_tooltips} from "../fremont/fremont_settings";

export const phoenix_message: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.phoenix_message,
  display_name: "Message",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    message: {
      title: "Message",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    },
    qr: {
      title: "Logo / QR Code",
      default_expanded: true,
      order: 3,
      accordion: true,
      tab: "general"
    },
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 45,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_cycle_time"
      },
      field_group: "general"
    },
    transition: {
      type: "select",
      title: "Transition",
      field_group: "general",
      validation: { rules: [{ name: "isRequired" }] },
      default: "parent",
      options: [
        {
          title: "Parent's",
          value: "parent"
        },
        ...transitions
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_transition"
      },
    },
    message_position: {
      type: "select",
      title: "Position",
      field_group: "message",
      default: "bottom",
      options: MessagePositions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    message_header: {
      title: "Header",
      field_group: "message",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.BOLD],
            size: "96px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    message_body: {
      title: "Body",
      field_group: "message",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            font: fonts[FontsFamilies.ARIAL][FontVariants.BOLD],
            size: "60px",
            lineHeight: "1.5",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    background: {
      title: "Background Image",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_background_image"
      },
      field_group: "message"
    },
    message_background_color: {
      type: "color",
      title: "Default Background Color",
      default: "#76232f",
      field_group: "message",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      }
    },
    // QR
    switch_to_logo: {
      type: "select",
      title: "Logo Toggle Chosen",
      field_group: "qr",
      default: "none",
      options: [
        { title: "None", value: "none" },
        { title: "Logo", value: "logo" },
        { title: "QR Code", value: "qrCode" }
      ],
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
      }
    },
    qr_position: {
      type: "select",
      title: "Position",
      isVisible: (values) => values.switch_to_logo !== 'none',
      field_group: "qr",
      default: "bottom-left",
      options: QRPositionOptions,
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_qr_position"
      }
    },
    logo: {
      title: "Logo",
      type: "file",
      isVisible: (values) => values.switch_to_logo === 'logo',
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_logo"
      }
    },
    qr_link: {
      type: "text",
      title: "QR Code Link",
      show_char_count: false,
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      validation: {
        rules: [
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_qr_link"
      }
    },
    qr_color: {
      type: "color",
      title: "QR Color",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      default: "#ffffff",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_qr_color"
      }
    },
    qr_code_background_color: {
      type: "color",
      isVisible: (values) => values.switch_to_logo !== 'logo',
      title: "QR Background Color",
      default: "",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "story_qr_code_background_color"
      }
    },
    call_to_action_header: {
      title: "Call To Action Header",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.OPTIMA][FontVariants.REGULAR],
            size: "64px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_call_to_action_header"
      }
    },
    call_to_action_body: {
      title: "Call to Action Body",
      isVisible: (values) => values.switch_to_logo === 'qrCode',
      field_group: "qr",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: wysiwygSettings,
      tooltip: wysiwyg.tooltip,
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#ffffff",
            alignment: "left",
            font: fonts[FontsFamilies.ARIAL][FontVariants.REGULAR],
            size: "56px",
            lineHeight: "1.25",
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_call_to_action_body"
      }
    },
    qr_background_color: {
      type: "color",
      isVisible: (values) => values.switch_to_logo !== 'none',
      title: "QR Container Background Color",
      default: "",
      field_group: "qr",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true,
        related_field: "message_qr_background_color"
      }
    },
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ]
};
