import { createSelector } from "@reduxjs/toolkit";
import { getItemsAndColumns } from "./listContainerSelectors";

const getSearchStringMain = (state) => {
  if (state.search_string_main) {
    return state.search_string_main;
  }
  return null;
};

const getFolderResults = (state) => {
  return state.data.folder_results || [];
};

export const listContainerAndFilterSelectors = createSelector(
  [getItemsAndColumns, getFolderResults],
  (itemsAndColumns, folderResults) => {
    let itemsAndColumnsFiltered = itemsAndColumns;
    if (folderResults && folderResults.length) {
      const { listItems } = itemsAndColumns;

      const itemsFiltered = listItems.filter((i) => {
        // filter only by name?
        return i.folderId && folderResults.includes(i.folderId + "");
      });

      itemsAndColumnsFiltered = {
        ...itemsAndColumns,
        listItems: itemsFiltered,
      };
    }

    return itemsAndColumnsFiltered;
  }
);
