import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE, FOLDER_CATEGORY } from "../../../../enums/folder-type-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { sorting_options, columns, wysiwyg } from "../standardized_fields";
import { eugene_category_shared } from "./eugene_category_shared";

const fontOptions = [
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.MYRIAD_PRO,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.SERIF,
  FontsFamilies.WHITNEY,
  FontsFamilies.LATO
];

export const eugene_category_without_giving_level: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.eugene_category_without_giving_level,
  folder_category: FOLDER_CATEGORY.DONOR_LIST,
  display_name: "Fund",
  acceptable_folder_types: [FOLDER_TYPE.eugene_donor],
  tabs: {
    ...eugene_category_shared.tabs
  },
  field_groups: {
    ...eugene_category_shared.field_groups
  },
  fields: {
    ...eugene_category_shared.fields,
    sorting_options: {
      ...sorting_options,
      field_group: "general"
    },
    giving_level_header: {
      ...wysiwyg,
      settings: {
        ...wysiwyg.settings,
        fonts: fontOptions,
        theme: "eugene"
      },
      title: "Giving Level Header",
      field_group: "list"
    },
    giving_level_header_margin_top: {
      type: "number",
      title: "Giving Level Header Margin Top",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2,
      field_group: "layout_list"
    },
    giving_level_header_margin_bottom: {
      type: "number",
      title: "Giving Level Header Margin Bottom",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2,
      field_group: "layout_list"
    },
    name_list: {
      type: "textstyle",
      title: "Donor Names",
      show_char_count: false,
      field_group: "list",
      default: {
        color: "#000000",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
        fontSize: 15
      },
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: true,
          items: [
            { title: "Left", value: "left" },
            { title: "Center", value: "center" },
            { title: "Right", value: "right" }
          ]
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        },
        bold: {
          enabled: true
        },
        underline: {
          enabled: true
        },
        italic: {
          enabled: true
        }
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },

    columns: {
      ...columns,
      tooltip: "Number of columns in donor list",
      default: 3,
      field_group: "list"
    },
    donor_names_row_spacing: {
      type: "number",
      title: "Row Spacing",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 1, max: 10 }] },
      tooltip: "Padding names / items in relation to font size. i.e: 1.2",
      step: "0.1",
      default: 2,
      field_group: "list"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  list_view: {
    enabled: true,
    list_folder_types: [FOLDER_TYPE.eugene_donor], //will need to handle missing fields if more then one type
    columns: [
      {
        folder_types: [FOLDER_TYPE.eugene_donor],
        field: "list_view_selected",
        title: "",
        sort: true,
        editable: true,
        default_shown: true
      },
      {
        folder_types: [FOLDER_TYPE.eugene_donor],
        field: "name",
        title: "Name",
        sort: true,
        editable: false,
        default_shown: true
      }, //array of types should only be used if both fields are identical just on different folder_types
      {
        folder_types: [FOLDER_TYPE.eugene_donor],
        field: "sorting_order",
        title: "Sorting Order",
        sort: true,
        editable: true,
        default_shown: true
      }
    ]
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneDonorListCategoryWrapper,
    type: PREVIEW_TYPE.COMPONENT
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  }
};
