import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { cycle_time_tooltips } from "../fremont/fremont_settings";
import { eugene_template_shared, fontOptions, wysiwyg_template } from "./eugene_template_shared";

const modifiedFieldGroups = {
  ...eugene_template_shared.field_groups,
  image_1: {
    title: "Image 1",
    default_expanded: false,
    order: 5,
    accordion: true,
    tab: "main"
  }
};

export const eugene_template_004: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.eugene_template_004,
  display_name: "Template 4 - Landscape - Story Left - Quote Right",
  tabs: {
    ...eugene_template_shared.tabs
  },
  field_groups: modifiedFieldGroups,
  fields: {
    ...eugene_template_shared.fields,
    quote: {
      ...wysiwyg_template,
      title: "Quote",
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "56em"
        },
        ops: []
      },
      settings: {
        fonts: fontOptions,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 50
        },
        recommended_length: 42,
        controls: {
          fontSize: true,
          bold: true,
          italic: true,
          underline: true,
          numberedList: true,
          bulletList: true,
          alignment: true,
          indentation: true,
          clearFormat: true
        }
      },
      field_group: "quote"
    },
    attribution: {
      ...wysiwyg_template,
      title: "Attribution",
      default: {
        type: "style", // Options are: style | ops
        style: {
          bold: true,
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "42em",
          align: "center"
        },
        ops: []
      },
      settings: {
        fonts: fontOptions,
        fontSizeOptions: {
          type: "range", // Could be range or custom (supply)
          min: 10,
          max: 50
        },
        recommended_length: 244,
        controls: {
          fontSize: true,
          bold: true,
          italic: true,
          underline: true,
          numberedList: true,
          bulletList: true,
          alignment: true,
          indentation: true,
          clearFormat: true
        }
      },
      field_group: "attribution"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time",
      default: 10,
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 100 }] },
      tooltip: cycle_time_tooltips,
      field_group: "cycle_time"
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneTemplate004,
    type: PREVIEW_TYPE.COMPONENT
  },
  tab_previews: [
    {
      tab_key: "general",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneTemplate004,
      type: PREVIEW_TYPE.COMPONENT
    },
    {
      tab_key: "card_view",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneTemplateCardView,
      type: PREVIEW_TYPE.COMPONENT
    }
  ],
  folder_type_selector: {
    image_url: "https://pdg-public.s3-us-west-2.amazonaws.com/springfield/template_004.svg"
  }
};
