import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { FontsFamilies, FontVariants, fonts } from "../../../../fonts";
import { wysiwygSettings } from "./monterrey_settings";

export const monterrey_story_with_media: IFolderType = {
  ...base_types.story,
  name: FOLDER_TYPE.monterrey_story_with_media,
  display_name: "Story with Media",
  tabs: {
    general: {
      title: "General"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: true,
      tab: "general"
    },
    story_with_media: {
      title: "Story with Media",
      default_expanded: true,
      order: 2,
      accordion: true,
      tab: "general"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Label",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    cycle_time: {
      type: "integer",
      title: "Cycle Time (seconds)",
      validation: { rules: [{ name: "isRequired" }, { name: "isInt", min: 0, max: 75 }] },
      field_group: "general",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_cycle_time"
      }
    },
    tagline: {
      title: "Tagline",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      field_group: "story_with_media",
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: 16 * 2.25 + "px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_tagline"
      }
    },
    header: {
      title: "Header",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      field_group: "story_with_media",
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#76232f",
            font: fonts[FontsFamilies.BEMBO][FontVariants.SEMI_BOLD],
            size: 16 * 5 + "px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_header"
      }
    },
    body: {
      title: "Body",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      field_group: "story_with_media",
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: 16 * 2.125 + "px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_body"
      }
    },
    story_image: {
      title: "Story Image",
      type: "file",
      slot_type: "dynamic",
      acceptable_contents: ["image/*", "video/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.3125,
      field_group: "story_with_media",
      recommended_size: {
        width: 840,
        height: 640
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_TOP_LEFT,
        aspect: 1.3125
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_story_image"
      }
    },
    call_to_action: {
      title: "Call To Action",
      field_group: "story_with_media",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: 16 * 1.25 + "px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_call_to_action"
      }
    },
    qr_call_to_action: {
      title: "QR Call to Action",
      field_group: "story_with_media",
      type: "hybrid_text_input",
      show_char_count: false,
      settings: {
        ...wysiwygSettings
      },
      default: {
        wysiwyg: {
          type: "style", // Options are: style | ops
          style: {
            color: "#75787a",
            align: "right",
            font: fonts[FontsFamilies.MYRIAD_PRO][FontVariants.REGULAR],
            size: 16 * 1.25 + "px"
          },
          ops: [],
          dark_mode: true
        },
        textarea: ""
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_qr_call_to_action"
      }
    },
    qr_link: {
      type: "text",
      title: "QR Code Link",
      show_char_count: false,
      field_group: "story_with_media",
      validation: {
        rules: [
          {
            name: "isRequiredComposite",
            otherFieldName: "qr_call_to_action",
            message: "Please enter a valid link. I.E: https://www.google.com"
          },
          { name: "isUrl", message: "Please enter a valid link. I.E: https://www.google.com" }
        ]
      },
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_qr_link"
      }
    },
    horizontal_rule_size: {
      title: "Horizontal Rule Size",
      type: "number",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 100 }] },
      tooltip: "Unit is % of container height",
      step: "0.1",
      default: 2,
      field_group: "story_with_media",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_horizontal_rule_size"
      }
    },
    footer_line_color: {
      type: "color",
      title: "Horizontal Line Color",
      field_group: "story_with_media",
      default: "#D3D3D3",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_footer_line_color"
      }
    },
    background_color: {
      type: "color",
      title: "Background Color",
      field_group: "story_with_media",
      default: "#FFFFFF",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_background_color"
      }
    },
    footer_icons_color: {
      type: "color",
      title: "Footer Icons Color",
      field_group: "story_with_media",
      default: "#000000",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false,
        related_field: "story_with_media_1_footer_icons_color"
      }
    }
  },
  save_options: [
    {
      title: "",
      action: "clone_folder",
      icon: ["fas", "copy"]
    },
    {
      title: "SAVE",
      action: "just_save"
    }
  ],
  slots: 1,
  preview: {
    aspect_ratio: 9 / 16,
    component_name: COMPONENT_NAME.Monterrey1x1Container,
    type: PREVIEW_TYPE.COMPONENT,
    screen_sets: [{ name: "unique", orientation: "portrait", horizontal: 1, vertical: 1 }],
    hide_border: false,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  client: {
    component_name: COMPONENT_NAME.ComponentLoop1911
  },
  show_clone_children_checkbox: false
};
