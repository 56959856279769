import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html-cb";
import React, { Component } from "react";
import { getFileType } from "../../shared/utils/fileUtils";
import { bestFit } from "../../shared/utils/screenUtils";

class PreviewResource extends Component {
  constructor(props) {
    super(props);
    this._videoRef = null;
    this._containerRef = null;
    this.state = {
      isLoaded: false,
      videoPaused: true,
      showVideo: false,
      dimensions: { width: 0, height: 0 }
    };
  }

  static propTypes = {
    resource: PropTypes.object,
  };

  static defaultProps = {
    resource: null,
  };

  componentDidMount() {
    this.calculateVideoBestFit();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      if (this.props.resource && this.props.resource !== prevProps.resource) {
        const loaded = this.props.resource.type === "file" ? false : true;
        this.setState({
          isLoaded: loaded,
          videoPaused: true,
        });
      }
    }
  };

  componentWillUnmount() {
    if (this._videoRef) {
      this._videoRef.removeEventListener('loadedmetadata', this.videoLoadedData);
    }
  }

  calculateVideoBestFit = () => {
    if(this.props.resource && this.props.resource.url) {
      const url = new URL(this.props.resource.url);
      const splittedPathname = url.pathname.split("/");
      const name = splittedPathname[splittedPathname.length - 1];
      const fileType = getFileType(name);
      if (fileType === "video") {
        if (!this._videoRef) {
          setTimeout(() => {
            this.calculateVideoBestFit();
          }, 100);
          return;
        } else {
          this._videoRef.addEventListener('loadedmetadata', this.videoLoadedData);
        }
      }
    }
  }

  videoLoadedData = () => {
    const videoWidth = this._videoRef.videoWidth;
    const videoHeight = this._videoRef.videoHeight;
    const dimensions = bestFit(videoWidth, videoHeight, this.props.containerWidth, this.props.containerHeight);
    this.setState({ dimensions, showVideo: true });
  }

  getPreview = () => {
    let preview = null;
    if (this.props.resource) {
      if (this.props.resource.type === "file") {
        let fileType = getFileType(this.props.resource.name);
        switch (fileType) {
          case "image":
            preview = this.getImagePreview();
            break;
          case "video":
            preview = this.getVideoPreview();
            break;
          default:
            preview = null;
            break;
        }
      } else {
        switch (this.props.resource.type) {
          case "plain_text":
            preview = this.getTextPreview();
            break;
          case "rich_text":
            preview = this.getRichTextPreview();
            break;
          default:
            break;
        }
      }
    } else {
      preview = this.getBlankPreview();
    }

    return preview;
  };

  getBlankPreview = () => {
    return (
      <React.Fragment>
        <span>
          <FontAwesomeIcon icon={["fa", "image"]} className="fa-5x" />
        </span>
        <h2 style={{ fontStyle: "italic" }}>There's nothing to see here</h2>
      </React.Fragment>
    );
  };

  getImagePreview = () => {
    let display = this.state.isLoaded ? "inline" : "none";
    return (
      <React.Fragment>
        <img
          src={this.props.resource.url}
          alt=""
          style={{ maxWidth: `${this.props.containerWidth}px`, maxHeight: `${this.props.containerHeight}px`, display: display }}
          onLoad={this.handleImageLoaded}
        />
      </React.Fragment>
    );
  };

  toggleVideo = () => {
    const { videoPaused } = this.state;
    if (this._videoRef) {
      if (videoPaused) {
        this._videoRef.play();
      } else {
        this._videoRef.pause();
      }
    }
    this.setState({
      videoPaused: !videoPaused,
    });
  };

  getVideoPreview = () => {
    const { isLoaded, videoPaused } = this.state;

    return (
      <React.Fragment>
        <video
          style={{ width: this.state.dimensions.width, height: this.state.dimensions.height, border: "1px solid #CCC" }}
          ref={(ref) => {
            if (ref) {
              this._videoRef = ref;
            }
          }}
          onLoadedData={this.handleVideoLoaded}
          src={this.props.resource.url}
        >
          <source src={this.props.resource.url} />
          Your browser does not support HTML5 Video
        </video>
        <div
          style={{
            display: "flex",
            width: this.state.dimensions.width,
            height: this.state.dimensions.height,
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "absolute",
          }}
          onClick={this.toggleVideo}
        >
          {(isLoaded && videoPaused) ? (
            <>
              <div style={{ width: this.state.dimensions.width, height: this.state.dimensions.height, background: 'black', opacity: 0.5 }} />
              <img
                alt={"loading"}
                style={{
                  position: "absolute",
                  width: "auto",
                  height: "auto"
                }}
                src="/images/play.png"
              />
            </>
          ) : null}
        </div>

      </React.Fragment>
    );
  };

  getRichTextPreview = () => {
    let rich_text_json = _.get(this.props.resource, "contents.rich_text", {});
    let converter = new QuillDeltaToHtmlConverter(rich_text_json.ops, {});
    let rich_text = converter.convert();
    return (
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: rich_text }}
      ></div>
    );
  };

  getTextPreview = () => {
    return <div>{this.props.resource.string_data}</div>;
  };

  loader = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
    return (
      <div
        style={{
          width: this.props.containerWidth,
          height: this.props.containerHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin indicator={antIcon} size="large" />
      </div>
    );
  };

  handleImageLoaded = () => {
    this.setState({
      isLoaded: true,
    });
  };

  handleVideoLoaded = () => {
    let videoHeight = this._videoRef.getBoundingClientRect().height;
    let videoWidth = this._videoRef.getBoundingClientRect().width;
    this.setState({
      isLoaded: true,
    });
  };

  render() {
    const className = this.props.transitionClassName ?? 'preview-transition';
    let preview = this.getPreview();
    return (
      <div
        className={className}
        ref={el => this._containerRef = el}
      >
        {this.props.resource &&
        !this.state.isLoaded &&
        this.props.resource.type === "file" ? (
          <React.Fragment>
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              {this.loader()}
            </div>
            <div className={this.state.isLoaded ? `preview-image ${className}` : `${className}`}>
              {preview}
            </div>
          </React.Fragment>
        ) : (
          <div className={this.state.isLoaded ? `preview-image ${className}` : `${className}`}>
            {preview}
          </div>
        )}
      </div>
    );
  }
}

export default PreviewResource;
