import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./everett_settings";

export const everett_presentation_2x2: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.everett_presentation_2x2,
  display_name: "Presentation 2x2",
  acceptable_folder_types: [
    FOLDER_TYPE.everett_donor_list_group,
    FOLDER_TYPE.everett_donor_list_with_quote,
    FOLDER_TYPE.everett_donor_list,
    FOLDER_TYPE.everett_donor_sea,
    FOLDER_TYPE.everett_full_screen_image,
    FOLDER_TYPE.everett_full_screen_image_with_caption,
    FOLDER_TYPE.everett_story,
    FOLDER_TYPE.everett_story_with_quote,
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions]
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.Everett2x2Container,
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    hide_border: true,
    screen_sets: [{ name: "2x2", orientation: "landscape", horizontal: 2, vertical: 2 }]
  },
  folder_type_selector: {
    image_url: "https://pdg-public.s3.us-west-2.amazonaws.com/everett/fullscreen.svg"
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation
    },
    closed: {
      ...tree_icon_types.presentation
    }
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  isPresentation: true
};
