
import validators from './validators';
import { transform } from 'lodash'

export function validate(rules, values) {
  const fieldResults = {};
  const validateRule = function (fieldName, rule, values) {
    const validator = validators[rule.name];
    if (typeof validator === "function") {
      const value = values[fieldName]
      return validator(value, rule, values);
    }
    return null;
  }

  const plainValues = transform(values, (result, value, key) => {
    if (!value) {
      result[key] = value
    } else if (Object.hasOwn(value, 'textarea')) {
      result[key] = value.textarea
    } else {
      result[key] = value
    }
    return result
  }, {})

  let isFormValid = true;
  for (let fieldName in rules) {
    fieldResults[fieldName] = {
      isValid: true,
      error: ''
    };
    if (!rules[fieldName]) {
      throw new Error(`Field config ${fieldName} does not exist an array named 'rules'`);
    }
    if(!Array.isArray(rules[fieldName])){
      throw new Error(`Field config ${fieldName} does not contain an array named 'rules'`);
    }
    // eslint-disable-next-line
    rules[fieldName].forEach(rule => {
      const validatorResults = validateRule(fieldName, rule, plainValues);
      if (validatorResults !== null) {
        if (validatorResults.valid === false && !fieldResults[fieldName].error) {
          fieldResults[fieldName].isValid = false;
          fieldResults[fieldName].error = rule.message ?? validatorResults.error;
          isFormValid = false;
        }
      } else {
        throw new Error(`Validator ${rule.name} is not defined!`);
      }
    });
  }
  return { fieldResults, isFormValid };
}

