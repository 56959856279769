import React, { Component } from "react";
import { getFileDynamic } from "../../utils/fileUtils";
import BRSpan from "../utils/BRSpan";
import _ from "lodash";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { bestFit } from "../../utils/screenUtils";
import { sortDonors } from "../../utils/commonDataUtils";

class DonorList1911 extends Component {
  _donorRefs = [];
  _donorContainerRef = null;
  _donorCycleHandler = null;

  constructor(props) {
    super(props);
    const {
      data,
      mode,
      aws,
      aws_bucket,
      containerHeight,
      containerWidth,
      scale,
      webview_signedurls,
      columnsWidth,
      columnPaddingLeft,
      withOriginHeightAndWidth,
      donorNamelineHeightUnit,
    } = props;
    const donorList = _.cloneDeep(
      data.folders.find((f) => f.id === data.base_folder)
    );
    const donors = data.folder__folders
      .filter((ff) => ff.parent_folder === donorList.id)
      .filter((v, i, arr) => arr.indexOf(v) === i)
      .map((ff) => {
        let donor = data.folders.find((f) => f.id === ff.child_folder);
        donor.rank = ff.rank;
        return donor;
      });

    const sorting_method = _.get(donorList, "fields.sorting_options", "rank");

    let sortedDonors = sortDonors(sorting_method, donors);

    // let sortedDonors

    // if (sorting_method === 'sorting_order'){
    //   sortedDonors = _.orderBy(
    //     donors,
    //     [donor => _.get(donor, 'fields.sorting_order', 'zzz')],
    //     ["asc"]
    //   );
    // }
    // else if (sorting_method === 'name'){
    //   sortedDonors = _.orderBy(
    //     donors,
    //     [donor => donor.name],
    //     ["asc"]
    //   );
    // }
    // else{
    //   sortedDonors = _.orderBy(
    //     donors,
    //     [donor => donor.rank],
    //     ["asc"]
    //   );
    // }

    const preset = mode === "preview" ? "preview" : null;
    const background = getFileDynamic(
      aws,
      aws_bucket,
      data.resources,
      data.resource__folders,
      ["background_image"],
      data.base_folder,
      preset,
      webview_signedurls
    );
    const settings = this.getSettings(donorList);
    const { donor_max_width, container_max_width, columns, donor_line_space } =
      settings;

    const columnWidth = columnsWidth
      ? columnsWidth
      : ((container_max_width / 100) * containerWidth) / columns;
    const donorWidth = (columnWidth * donor_max_width) / 100;
    const lineHeight = `${donor_line_space}${
      donorNamelineHeightUnit ? donorNamelineHeightUnit : "em"
    }`;

    const factor =
      scale === "height" ? containerHeight / 100 : containerWidth / 100;

    this.state = {
      width: containerWidth,
      height: containerHeight,
      donors: sortedDonors,
      donorList,
      background,
      donorIndex: 0,
      lastPageCount: 0,
      drawDonors: [],
      finishedPreRender: false,
      columnWidth,
      columnPaddingLeft,
      withOriginHeightAndWidth,
      donorWidth,
      lineHeight,
      scale,
      factor,
      cycleForwardKey: _.get(donorList, "fields.cycle_forward", "ArrowRight"),
      ...settings,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.handlePreRender();
    this.cycleDonors();
  }

  componentDidUpdate(prevProps) {
    const withOriginHeightAndWidth = prevProps.withOriginHeightAndWidth;
    if (
      prevProps.containerWidth !== this.props.containerWidth ||
      prevProps.containerHeight !== this.props.containerHeight
    ) {
      const dims = bestFit(
        1920,
        1080,
        this.props.containerWidth,
        this.props.containerHeight
      );
      const { donorList } = this.state;
      const settings = this.getSettings(donorList);
      const {
        donor_max_width,
        container_max_width,
        columns,
        donor_line_space,
      } = settings;
      const { containerWidth, factor } = this.props;
      const columnWidth = prevProps.columnsWidth
        ? prevProps.columnsWidth
        : ((container_max_width / 100) * containerWidth) / columns;
      const donorWidth = (columnWidth * donor_max_width) / 100;
      const lineHeight = donor_line_space * factor;
      const lineHeightDefault = this.state.lineHeight;

      if (withOriginHeightAndWidth) {
        this.setState({
          columnWidth,
          donorWidth,
          lineHeight: !lineHeight ? lineHeightDefault : lineHeight,
          width: this.props.containerWidth,
          height: this.props.containerHeight,
        });
      } else {
        this.setState({
          columnWidth,
          donorWidth,
          lineHeight,
          width: dims.width,
          height: dims.height,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    clearTimeout(this._donorCycleHandler);
  }

  handleKeyDown = (event) => {
    switch (event.key) {
      case this.state.cycleForwardKey:
        clearTimeout(this._donorCycleHandler);
        this.cycleDonors();
        break;
      default:
        break;
    }
  };

  getSettings = (donorList) => {
    const settings = {};
    const defaultSettings = [
      { name: "columns", value: 2 },
      { name: "container_margin_top", value: 0 },
      { name: "container_margin_left", value: 12 },
      { name: "container_max_width", value: 76 },
      { name: "cycle", value: 5 },
      { name: "donor_max_width", value: 75 },
      { name: "donor_vertical_separation", value: 2.5 },
      { name: "donor_line_space", value: 1.0 },
    ];
    for (const item of defaultSettings) {
      const value = _.get(donorList, `fields.${item.name}`, item.value);
      settings[item.name] = value;
      if (item.name === "cycle") {
        settings[item.name] = value * 1000;
      }
    }
    return settings;
  };

  getInnerClass = () => {
    const innerClassName = _.get(
      this.props,
      "overrides.donorNameInnerClass",
      ""
    );

    return innerClassName;
  };

  getStyle = () => {
    const { width, height, scale } = this.state;
    const scaleBasedDim = scale === "height" ? height : width;
    const innerClassName = _.get(
      this.props,
      "overrides.donorNameInnerClass",
      ""
    );

    const style = {
      fontSize: _.get(
        this.props,
        "overrides.donorFontSize",
        scaleBasedDim * 0.025
      ),
      fontStyle: "Normal",
      fontFamily: _.get(this.props, "overrides.donorFontFamily", "Lato"),
      fontWeight: "100",
      textAlign: "left",
      color: _.get(this.props, "overrides.fontColor", "#000000"),
    };
    return style;
  };

  handleCycleInterval = () => {
    const { cycle } = this.state;
    clearTimeout(this._donorCycleHandler);
    this._donorCycleHandler = setTimeout(() => {
      this.cycleDonors();
    }, cycle);
  };

  cycleDonors = () => {
    const { donorIndex, lastPageCount, donors } = this.state;
    const { handleEndOfPlay } = this.props;
    if (donorIndex + lastPageCount >= donors.length) {
      if (handleEndOfPlay) {
        return handleEndOfPlay();
      }
      const { donors, lastPageCount: lpc, drawDonors } = this.prepareNames(0);
      const newDonorIndex = lpc;
      this.setState({
        finishedPreRender: true,
        donorIndex: newDonorIndex,
        donors,
        lastPageCount,
        drawDonors,
      });
      this.handleCycleInterval();
    } else {
      const {
        donors,
        lastPageCount: lpc,
        drawDonors,
      } = this.prepareNames(donorIndex);
      const newDonorIndex = donorIndex + lpc;
      this.setState({
        finishedPreRender: true,
        donorIndex: newDonorIndex,
        donors,
        lastPageCount,
        drawDonors,
      });
      this.handleCycleInterval();
    }
  };

  prepareNames = (donorIndex) => {
    const { columns, height, donors, donor_vertical_separation, factor } =
      this.state;
    const donorVerticalSeparation = factor * donor_vertical_separation;

    let lastPageCount = 0;
    let currentHeight = 0;
    let currentColumn = 1;
    let drawDonors = [];

    for (let i = donorIndex; i < donors.length; i = i + 1) {
      const donor = donors[i];
      //fits in column
      if (currentHeight + donor.height > height) {
        if (currentColumn < columns) {
          //reset for new column
          currentHeight = 0;
          currentColumn++;
        } else {
          break;
        }
      }
      donor.top = currentHeight;
      donor.column = currentColumn;
      currentHeight += donor.height + donorVerticalSeparation;
      drawDonors.push(donor);
      lastPageCount++;
    }
    return { donors, lastPageCount, drawDonors };
  };

  renderNames = () => {
    const {
      drawDonors,
      donorWidth,
      columnPaddingLeft,
      columnWidth,
      lineHeight,
    } = this.state;
    const style = this.getStyle();
    const innerClassName = this.getInnerClass();

    return drawDonors.map((donor, i) => {
      return (
        <div
          key={i}
          style={{
            ...style,
            position: "absolute",
            //overflow: "hidden",
            maxWidth: columnWidth,
            top: donor.top,
            left:
              (donor.column - 1) * columnWidth +
              (donor.column > 1
                ? columnPaddingLeft
                  ? columnPaddingLeft
                  : 0
                : 0),
            opacity: 1,
            width: donorWidth,
            whiteSpace: "pre-wrap",
            lineHeight,
          }}
        >
          <div className={innerClassName ? innerClassName : ""}>
            <BRSpan str={donor.name} />
            <br />
          </div>
        </div>
      );
    });
  };

  handlePreRender = () => {
    const { donors } = this.state;
    if (this._donorRefs.length > 0 && this.state.finishedPreRender === false) {
      let counter = -1;

      donors.forEach((donor) => {
        counter++;
        donor.height = this._donorRefs[counter].offsetHeight;
        donor.width = this._donorRefs[counter].offsetWidth;
      });

      this.setState({
        donors,
      });
    }
  };

  preRendered = () => {
    const { donors, columnWidth, donorWidth, lineHeight } = this.state;
    const style = this.getStyle();
    return donors.map((donor, i) => {
      return (
        <div
          key={i}
          ref={(el) => (this._donorRefs[i] = el)}
          style={{
            ...style,
            opacity: 0,
            position: "absolute",
            overflow: "hidden",
            maxWidth: columnWidth,
            width: donorWidth,
            whiteSpace: "pre-wrap",
            lineHeight,
          }}
        >
          <BRSpan str={donor.name} />
        </div>
      );
    });
  };

  render() {
    const {
      finishedPreRender,
      background,
      donorIndex,
      height,
      width,
      container_max_width,
      container_margin_top,
      container_margin_left,
      factor,
    } = this.state;
    const donors = finishedPreRender ? this.renderNames() : this.preRendered();

    return (
      <div
        style={{
          top: container_margin_top * factor,
          left: container_margin_left * factor,
          background: `url(${background})`,
          backgroundSize: "cover",
          position: "absolute",
          height,
          width: (container_max_width / 100) * width,
          color: _.get(this.props, "overrides.fontColor", "#000000"),
        }}
      >
        <div
          ref={(el) => (this._donorContainerRef = el)}
          style={{
            position: "absolute",
            height,
            width: (container_max_width / 100) * width,
          }}
        >
          <TransitionGroup>
            <CSSTransition
              key={donorIndex}
              timeout={500}
              classNames="transition-fade"
            >
              <div>{donors}</div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
  }
}

export default DonorList1911;
