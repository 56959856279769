import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Modal, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { setClientOptions } from "../../reducers/clientOptionsSlice";
import { addSubmitListViewCallback } from "../../services/unsavedModalService";
import { PDG_COLORS } from "../../shared-global/enums/ui-enums";
import { strToUpper } from "../../shared/utils/generalUtils";
import { dateFormatted } from "../../utils/dateUtils";
import { TREE_MODE_APPLIED_CONTENT } from "../constants";
import DynamicForm from "../form/DynamicForm";
import ContentManager from "../form/input/ContentManager/ContentManager";
import ContentManagerWidget from "../form/input/ContentManager/ContentManagerWidget";
import {
  addInputToItem,
  changeField,
  getSelectedFolderDynamicInputFolderResources,
  getSelectedFolderFixedInputFolderResources,
  removeItemFromDefaultValues,
  removeItemFromItem
} from "../form/input/DynamicFileInput/FileInputService";
import AlertNotification from "../layout/AlertNotification";
import { XXLT_GRAY } from "../layout/LayoutConstants";
import TextWithHighlights from "../tree/TextWithHighlights";
import Spaner from "../utils/Spaner";
import FilterActions from "./FilterActions";
import MultiFolderActions from "./MultiFolderActions";
import { SortableListMemo } from "./SortableListMemo";
import { sortDonors } from "../../shared/utils/commonDataUtils"

const FIELDS_CONFIG_ALLOW_FIELDS = [
  "type",
  "title",
  "validation",
  "show_char_count",
  "settings",
  "options",
];

let timeoutHandler = null;
let timeoutHandler2 = null;
const MODE_SELECT = 0;
const MODE_EDIT = 1;
const MODE_SORT = 2;
const SORT_RANK = "rank";
const SORT_OTHER = "other";
const DYNAMIC = "dynamic";

const WIDTH_EXTRA_SPACE_X_2 = 1200;
const WIDTH_EXTRA_SPACE_X_3 = 1500;
const MIN_WIDTH_FULL_RESOLUTION = 670;
const MIN_WIDTH_MEDIUM_RESOLUTION = 300;

const lt_gray_border = `.5px solid ${XXLT_GRAY}`;

const getCellWidthProportionCalculation = (base, tableWidth) => {
  let proportion = 1;
  if (tableWidth > WIDTH_EXTRA_SPACE_X_3) {
    proportion = 2; // 160%
  } else if (tableWidth > WIDTH_EXTRA_SPACE_X_2) {
    proportion = 1.6; // 130%
  }
  return base * proportion;
};

const countCellWith = (columns) => {
  return columns.reduce(
    (accumulator, current) => {
      const type = _.get(current, "type", _.get(current, "field", "null"));
      switch (type) {
        case "actions":
        case "integer":
        case "cycle_time":
        case "file":
        case "sorting_options":
        case "bool":
        case "hamburger":
        case "list_view_selected":
          accumulator.small += 1;
          break;
        case "select":
        case "textarea":
        case "text":
        case "type":
        case "wysiwyg":
        case "hybrid_text_input":
          accumulator.large += 1;
          break;
        default:
          accumulator.medium += 1;
          break;
      }
      return accumulator;
    },
    { large: 0, medium: 0, small: 0 }
  );
};

/**
 *
 * @param width
 * @param columnCountWith {
 *   {large: 0, medium: 0, small: 0}
 * }
 */
const getCellBaseLongWidth = (width, columnCountWith) => {
  const widthSmallItems = 60 * columnCountWith.small;
  const widthMediumAndLargeItems =
    (width - widthSmallItems) /
    (columnCountWith.large + columnCountWith.medium);
  return widthMediumAndLargeItems || 150;
};

const getCellWidth = (type, tableWidth) => {
  switch (type) {
    case "actions":
    case "integer":
    case "cycle_time":
    case "file":
    case "sorting_options":
      return 100;
    case "select":
      return 300;
    case "icon":
    case "bool":
      return 50;
    case "textarea":
    case "text":
    case "wysiwyg":
    case "hybrid_text_input":
      return getCellWidthProportionCalculation(150, tableWidth);
    case "type":
      return 300;
    case "updatedBy":
      return 150;
    case "updatedAt":
      return getCellWidthProportionCalculation(100, tableWidth);
    default:
      return 0;
  }
};

const getCellWidthRecalculated = (type, tableWidth, columns) => {
  const columnCountWith = countCellWith(columns);
  switch (type) {
    case "actions":
    case "integer":
    case "cycle_time":
    case "file":
    case "sorting_options":
      return 100;
    case "select":
      return 300;
    case "icon":
    case "bool":
      return 50;
    case "textarea":
    case "text":
    case "wysiwyg":
    case "hybrid_text_input":
      const baseWidth = getCellBaseLongWidth(tableWidth, columnCountWith);
      return baseWidth;
    case "type":
      return 300;
    case "updatedBy":
      return 150;
    case "updatedAt":
      return getCellWidthProportionCalculation(100, tableWidth);
    default:
      return 0;
  }
};

const getFilterFunction = (field) => {
  switch (field) {
    case "updatedBy":
      return (value, record) => {
        if (
          !value ||
          value.length === 0 ||
          !Array.isArray(value) ||
          record.new
        ) {
          return true;
        }
        if (value.includes("(empty)")) {
          return !record.updatedBy;
        } else {
          return value.includes(record.updatedBy);
        }
      };

    default:
      return () => { };
  }
};

const styles = {
  cell: {
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  cellFlex: {
    display: "flex",
    textOverflow: "ellipsis",
  },
  cellRichTextDisabled: {
    color: "rgba(0, 0, 0, 0.65)",
    backgroundColor: "#f5f5f5",
    border: "1px solid #d9d9d9",
    borderRadius: "2px",
    cursor: "not-allowed",
    opacity: 1,
    padding: "4px 11px",
    minHeight: 32,
  },
  rowGrow: {
    flexGrow: 2,
  },
  rowGrowPadding: {
    paddingLeft: 3,
  },
  rowRight: {
    justifyContent: "right",
    paddingRight: 15,
  },
  checkboxCell: {
    marginTop: 10,
    padding: 0,
    minWidth: 50,
    width: 50,
  },
  iconCell: {
    display: "flex",
    padding: 0,
    width: 50,
    minWidth: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  cellEditMode: {
    marginTop: 10,
    padding: 0,
  },

  beingDragged: {
    background: "#2699FB",
    color: "white",
    "& svg": {
      color: "white",
    },
  },
  smallButton: {
    width: 30,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    marginRight: 5,
    marginLeft: 5,
  },
  listContainer: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& #actions": {
      display: "flex",
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      "& #multi-action": {
        flex: 1,
      },
      "& #top-buttons": {
        textAlign: "right",
        flex: 1,
      },
    },
    "& #list": {
      width: "100%",
      marginTop: 20,
      overflowX: "scroll",
      "& .list_new": {
        background: "#1890ff94",
      },
    },
    "& #bottom-buttons": {
      width: "100%",
      // width: ({ columnsWidthSum }) => columnsWidthSum - 40,
      textAlign: "right",
      marginTop: 20,
    },
    "& #list #headers": {
      background: "#F9F9F9",
      color: "#666666",
      width: "100%",
    },
    "& .list-container": {
      height: ({ listContainerHeight }) => listContainerHeight,
    },
    "& .responsive-list div.ReactVirtualized__List.list-container.ReactVirtualized__Grid":
    {
      width: "100% !important",
    },
    "& .responsive-list div.ReactVirtualized__List.list-container.ReactVirtualized__Grid div.ReactVirtualized__Grid__innerScrollContainer":
    {
      width: "100% !important",
      maxWidth: "100% !important",
    },
    "& .responsive-list div.ReactVirtualized__List.list-container.ReactVirtualized__Grid div.ReactVirtualized__Grid__innerScrollContainer span.flex-center-left":
    {
      justifyContent: "space-between",
    },
    "& .responsive-list div.ReactVirtualized__List.list-container.ReactVirtualized__Grid div.ReactVirtualized__Grid__innerScrollContainer span.flex-center-left div":
    {
      marginLeft: "auto",
    },
  },
};

const findCellTypeFromSiblings = (column, folderTypes) => {
  let result = null;
  const acceptableFolderTypes = _.get(column, "folder_types", []);
  acceptableFolderTypes.some((aft) => {
    const folderTypeObj = folderTypes[aft];
    if (
      folderTypeObj &&
      folderTypeObj.fields &&
      folderTypeObj.fields[column.field]
    ) {
      result = folderTypeObj.fields[column.field];
      return true;
    }
  });
  return result;
};

const getCellTypeSafe = (column, folderTypes) => {
  let cellType;
  if (column.field_data) {
    cellType = column.field_data[column.field].type;
  }

  if (!cellType) {
    cellType = findCellTypeFromSiblings(column, folderTypes);
  }

  if (!cellType) {
    if (column.field) {
      cellType = column.field;
    } else {
      cellType = "file";
    }
  }

  return cellType;
};

const useStyles = createUseStyles(styles);
const getWidthFromColumns = (listColumns, folderTypes, componentWidth) => {
  let width = 25; // Buffer scrollbar etc
  listColumns.forEach((column) => {
    const columnType = getCellTypeSafe(column, folderTypes);
    const cellWidth = getCellWidth(columnType, componentWidth);

    width += cellWidth;
  });

  return width;
};

const hamburgerColumn = {
  folder_types: [],
  sort: false,
  editable: false,
  title: "",
  field: "hamburger",
  default_shown: true,
  field_data: {
    hamburger: {
      type: "icon",
      title: "",
      validation: {
        rules: [],
      },
      default: null,
    },
  },
};

const actionsColumn = {
  folder_types: [],
  sort: false,
  editable: false,
  title: "",
  field: "actions",
  default_shown: true,
  field_data: {
    actions: {
      type: "actions",
      title: "",
      validation: {
        rules: [],
      },
      default: null,
    },
  },
};

const DragDropHoc = (props) => {
  const wrappingDivRef = useRef();
  const dispatch = useDispatch();
  const [unsavedChanges, _setUnsavedChanges] = useState(false);
  const [folderType, setFolderType] = useState();
  const unsavedChangesRef = useRef(unsavedChanges);
  const setUnsavedChanges = (data) => {
    unsavedChangesRef.current = data;
    props.setUpdateListViewTouch({ touch: data });
    _setUnsavedChanges(data);
  };
  const [showDeleteConfirmationModal, setShowDeleteConfirmModal] =
    useState(false);
  const [unsavedChangedItems, _setUnsavedChangedItems] = useState([]);
  const unsavedChangedItemsRef = useRef(unsavedChangedItems);
  const setUnsavedChangedItems = (data) => {
    unsavedChangedItemsRef.current = data;
    _setUnsavedChangedItems(data);
  };
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [outerWidth, _setOuterWidth] = useState(575);
  const outerWithRef = useRef(outerWidth);
  const setOuterWidth = (data) => {
    outerWithRef.current = data;
    _setOuterWidth(data);
  };
  const detail_drawer_open = useSelector(
    (state) => state.client_options.detail_drawer_open
  );
  const tree_panel_open = useSelector(
    (state) => state.client_options.tree_panel_open
  );
  const [componentWidth, setComponentWidth] = useState(575);
  const [items, _setItems] = useState(props.listItems);
  const itemsRef = useRef(items);
  const setItems = (data) => {
    itemsRef.current = data;
    _setItems(data);
  };
  const [mode, setMode] = useState(MODE_SELECT);
  const [sortMode, setSortMode] = useState(
    props.sorting === "rank" ? SORT_RANK : SORT_OTHER
  );
  const [acceptableFolderTypes, _setAcceptableFolderTypes] = useState([]);
  const acceptableFolderTypesRef = useRef(acceptableFolderTypes);
  const setAcceptableFolderTypes = (data) => {
    acceptableFolderTypesRef.current = data;
    _setAcceptableFolderTypes(data);
  };
  const [newAddedItemIndex, setNewAddedItemIndex] = useState(
    props.listItems.length
  );
  const [editButtonDisabled, setEditButtonDisabled] = useState(false);
  const [forceUpdateInt, setForceUpdateInt] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [listContainerHeight, setListContainerHeight] = useState(
    props.listHeight
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    field: props.sorting,
    direction: "asc",
  });
  const [addedItems, _setAddedItems] = useState([]);
  const addedItemsRef = useRef(addedItems);
  const setAddedItems = (data) => {
    addedItemsRef.current = data;
    _setAddedItems(data);
  };
  // In this array are saved all initial folder ids in the init of this session (list loaded first time)
  const [allFolderIdsInThisSession, setAllFolderIdsInThisSession] =
    useState(null);
  const [allItems, setAllItems] = useState(props.listItems);
  const [filteredItems, setFilteredItems] = useState(allItems);
  const [columns, setColumns] = useState([
    hamburgerColumn,
    ...props.listColumns,
    actionsColumn,
  ]);
  const [columnsExtraClass, setColumnsExtraClass] = useState({});
  const [columnsWidthSum, setColumnsWidthSum] = useState(
    getWidthFromColumns(columns, props.folderTypes)
  );
  const [styleValues, setStyleValues] = useState({
    listContainerHeight,
    columnsWidthSum,
  });
  const [contentManagerOpen, setContentManagerOpen] = useState(false);
  const [
    contentManagerConfirmationModalOpen,
    setContentManagerConfirmationModalOpen,
  ] = useState(false);
  const [contentManagerDefaultValue, setContentManagerDefaultValue] = useState(
    []
  );
  const [contentManagerItems, setContentManagerItems] = useState([]);
  const [contentManagerAcceptable, setContentManagerAcceptable] = useState([]);
  const [contentManagerSingleItem, setContentManagerSingleItem] =
    useState(null);
  const [contentManagerConfig, setContentManagerConfig] = useState(null);
  const [contentManagerLimit, setContentManagerLimit] = useState(0);
  const [contentManagerTitle, setContentManagerTitle] = useState("");
  const [contentManagerTouch, setContentManagerTouch] = useState(false);
  const [contentManagerCloseCallback, setContentManagerCloseCallback] =
    useState(null);
  const [contentManagerItemsInitialState, setContentManagerItemsInitialState] =
    useState([]);
  const [
    contentManagerDefaultValueInitialState,
    setContentManagerDefaultValueInitialState,
  ] = useState([]);
  const [contentManagerFieldName, setContentManagerFieldName] = useState(null);
  const [contentManagerFieldValues, setContentManagerFieldValues] = useState(
    {}
  );
  const [contentManagerAspect, setContentManagerAspect] = useState(null);
  const [contentManagerSelectedFolder, setContentManagerSelectedFolder] =
    useState(null);
  const [contentManagerSelectedItem, setContentManagerSelectedItem] =
    useState(null);

  // filters
  const [columnFilters, setColumnFilters] = useState({});
  const [initialColumnFilters, setInitialColumnFilters] = useState({});
  const [functionFilters, setFunctionFilters] = useState({});

  // search
  const [searchString, setSearchString] = useState(null);

  const [showAddButton, setShowAddButton] = useState(true);

  const [focusInputKey, setFocusInputKey] = useState("");

  const classes = useStyles(styleValues);

  const getSelectedFolderFixedInputFolderResourcesWrapper = (
    item,
    selected_folder,
    folder_type
  ) => {
    const { resource__folders, resources, aws_instance } = props;
    let fileFields = [];
    if (!item || !selected_folder || !folder_type) {
      return fileFields;
    }
    fileFields = getSelectedFolderFixedInputFolderResources(
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    );
    return fileFields;
  };

  const getSelectedFolderDynamicInputFolderResourcesWrapper = (
    item,
    selected_folder,
    folder_type
  ) => {
    const { resource__folders, resources, aws_instance } = props;
    let fileFields = [];
    if (!item || !selected_folder || !folder_type) {
      return fileFields;
    }
    fileFields = getSelectedFolderDynamicInputFolderResources(
      selected_folder,
      folder_type,
      resource__folders,
      resources,
      aws_instance
    );
    return fileFields;
  };

  const openContentManagerCall = (
    fieldValues,
    cmItems,
    defaultValue,
    config,
    acceptable,
    fieldName,
    singleItem,
    limit,
    title,
    aspect,
    selectedFolder,
    selectedItem
  ) => {
    setContentManagerFieldValues(fieldValues);
    setContentManagerItems(cmItems);
    setContentManagerDefaultValue(defaultValue);
    setContentManagerConfig(config);
    setContentManagerAcceptable(acceptable);
    setContentManagerSingleItem(singleItem);
    setContentManagerLimit(limit);
    setContentManagerTitle(title);
    setContentManagerFieldName(fieldName);
    setContentManagerAspect(aspect);
    setContentManagerSelectedFolder(selectedFolder);
    setContentManagerSelectedItem(selectedItem);

    setContentManagerTouch(false);
    setContentManagerOpen(true);
  };

  const onChangeContentManager = (name, value) => {
    const fieldValues = { ...contentManagerFieldValues };
    if (typeof name === "string") {
      if (value === null || value === undefined) {
        delete fieldValues[name];
      } else {
        fieldValues[name] = value;
      }
    }
  };

  const overrideFieldValueContentManager = (fieldName, value) => {
    const fieldValues = contentManagerFieldValues;
    fieldValues[fieldName] = value;
  };

  const resetContentManager = (field) => {
    const fixedInputFields =
      getSelectedFolderFixedInputFolderResourcesWrapper();
    const dynamicInputFields =
      getSelectedFolderDynamicInputFolderResourcesWrapper();
    const fileInputFields = { ...fixedInputFields, ...dynamicInputFields };
    overrideFieldValueContentManager(field, fileInputFields);
  };

  const handleAddInputContentManager = () => {
    let newItemsReturn = contentManagerItems;
    if (contentManagerItems.length < contentManagerLimit) {
      const aspect = contentManagerAspect;
      const newItems = addInputToItem(contentManagerItems, aspect);
      newItemsReturn = [...newItems];
    }
    return newItemsReturn;
  };

  const handleAddMultipleFilesContentManager = async (files, type = "new") => {
    if (files && files.length !== 0) {
      for (const file in files) {
        const newItems = handleAddInputContentManager();
        const lastItem = newItems[newItems.length - 1];
        await onFieldChangeContentManger(
          lastItem.identifier,
          "file",
          files[file],
          type
        );
        setContentManagerItems(newItems);
      }
    }
  };

  const onChangeAllValuesContentManager = async (values) => {
    await onChangeContentManager(contentManagerFieldName, values);
  };

  const closeContentManagerModal = async () => {
    // if (contentManagerCloseCallback) {
    //   await contentManagerCloseCallback();
    // }
    setContentManagerSingleItem(null);
    setContentManagerOpen(false);
    setContentManagerItemsInitialState([]);
    setContentManagerDefaultValueInitialState([]);
  };

  const closeContentManagerModalSafe = async (callback) => {
    if (!contentManagerTouch) {
      // there is no change, just close the modal
      resetContentManager(contentManagerFieldName);
      await closeContentManagerModal();
    } else {
      setContentManagerCloseCallback(callback || null);
      if (contentManagerOpen) {
        setContentManagerConfirmationModalOpen(true);
      }
    }
  };

  const handleRemoveInputContentManager = async (identifier) => {
    const type = DYNAMIC;
    const allValues = _.cloneDeep(contentManagerDefaultValue);
    const newItems = removeItemFromItem(
      contentManagerItems,
      identifier,
      type,
      allValues
    );
    const allValuesNew = removeItemFromDefaultValues(
      allValues,
      identifier,
      type,
      contentManagerItems
    );
    setContentManagerItems(newItems);
    setContentManagerDefaultValue(allValuesNew);
    onChange(
      contentManagerFieldName,
      allValuesNew,
      [],
      contentManagerSelectedItem,
      true
    );
  };

  const onFieldChangeContentManger = async (
    identifier,
    key,
    value,
    type = "new"
  ) => {
    const allValues = await changeField(
      identifier,
      key,
      value,
      type,
      contentManagerFieldName,
      contentManagerItems,
      contentManagerDefaultValue
    );
    const newAllValues = [...allValues];
    setContentManagerDefaultValue(newAllValues);
    onChange(
      contentManagerFieldName,
      newAllValues,
      [],
      contentManagerSelectedItem,
      true
    );
  };

  const onCancelCallbackContentManager = async () => {
    setContentManagerItems(contentManagerItemsInitialState);
    await onChangeAllValuesContentManager([
      ...contentManagerDefaultValueInitialState,
    ]);
  };

  const getCellValueSafe = (cellType, cellValue) => {
    switch (cellType) {
      case "wysiwyg":
        const textRichArray = _.get(cellValue, "ops", []);
        const textRich = textRichArray.reduce((acc, curr) => {
          const text = acc + _.get(curr, "insert", "");
          return text;
        }, "");
        return textRich;
      case "hybrid_text_input":
        return _.get(cellValue, "textarea");
      default:
        return cellValue;
    }
  };

  const renderReadOnlySpan = (
    key,
    cellValue,
    cellWidth,
    cellType,
    onClick = null
  ) => {
    const tooltipVisible =
      (cellType === "text") | (cellType === "wysiwyg") && cellValue;
    const cellValueSafe = getCellValueSafe(cellType, cellValue);

    const cellValueAndClick = onClick ? (
      <Button
        key={key}
        className={`${key}`}
        type="link"
        onClick={() => onClick(key)}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
          paddingLeft: 0,
        }}
      >
        <TextWithHighlights
          text={cellValueSafe}
          highlight={searchString}
          highlightStyle={{
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </Button>
    ) : (
      cellValueSafe
    );

    const tag = (
      <span
        key={key}
        className={classes.cell}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <TextWithHighlights
          text={cellValueAndClick}
          highlight={searchString}
          highlightStyle={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </span>
    );

    return tooltipVisible ? (
      <Tooltip key={`tooltip-${key}`} placement="bottom" title={cellValueSafe}>
        {tag}
      </Tooltip>
    ) : (
      tag
    );
  };

  const renderReadOnlyRichTextSpan = (
    key,
    cellValue,
    cellWidth,
    cellType,
    column,
    item
  ) => {
    const template_type = item.type;
    const richTextExistInTemplate = _.get(
      props.folderTypes,
      `[${template_type}].fields[${column.field}]`,
      null
    );
    if (!richTextExistInTemplate) {
      return (
        <span
          key={key}
          style={{
            width: cellWidth,
            minWidth: cellWidth,
          }}
        ></span>
      );
    }

    const cellValueSafe = getCellValueSafe(cellType, cellValue);

    const tag = (
      <span
        key={key}
        className={`${classes.cell} ${classes.cellRichTextDisabled}`}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <TextWithHighlights
          text={cellValueSafe}
          highlight={searchString}
          highlightStyle={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            backgroundColor: PDG_COLORS.MD_WARNING,
          }}
        />
      </span>
    );

    return (
      <Tooltip
        key={`tooltip-${key}`}
        placement="bottom"
        title={`This is a rich text filed which cannot be edited in list view. Please edit this
                       field by exiting edit mode and clicking the link in the list view, or by selecting the
                       item in the folder tree`}
      >
        {tag}
      </Tooltip>
    );
  };

  const renderDynamicFormSelect = (key, fieldGroupsConfig, fieldsConfig, fieldValues, cellWidth, item, field, onChange) => {
    return (
      <span
        key={key}
        className={[classes.cell, classes.cellEditMode].join(" ")}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
        }}
      >
        <DynamicForm
          key={key}
          fieldGroupsConfig={fieldGroupsConfig}
          fieldsConfig={fieldsConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => { }}
          onError={() => { }}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onChangeHandlers={[
            {
              name: field,
              callBackFunction: (name, value, fieldValues) =>
                onChange(name, value, fieldValues, item),
            },
          ]}
          classOverride="noclass"
          canUpdate={props.canUpdate}
          disabled={mode === MODE_EDIT}
          showCharCount={false}
        />
      </span>
    );
  };

  const getOnlyAllowedFieldsFromFieldsConfig = (config, cellWidth) => {
    const newConfig = {};
    const minWidth = cellWidth * 0.85;
    if (config) {
      for (const fieldForm in config) {
        if (config[fieldForm]) {
          newConfig[fieldForm] = {};
          for (const key in config[fieldForm]) {
            if (FIELDS_CONFIG_ALLOW_FIELDS.includes(key)) {
              newConfig[fieldForm][key] = config[fieldForm][key];
            }
          }
          if (newConfig[fieldForm].hasOwnProperty("settings")) {
            newConfig[fieldForm].settings.minWidth = minWidth;
          } else {
            newConfig[fieldForm].settings = { minWidth };
          }
        }
      }
    }

    return newConfig;
  };

  const calculateFieldWidth = (fieldConfig, cellWidth) => {
    const minWidth = cellWidth * 0.85;

    return fieldConfig;
  };

  const renderDynamicFormSpan = (
    key,
    fieldGroupsConfig,
    fieldsConfig,
    fieldValues,
    cellWidth,
    item,
    mode,
    onChange
  ) => {
    const myFieldConfig = getOnlyAllowedFieldsFromFieldsConfig(
      fieldsConfig,
      cellWidth
    );

    return (
      <span
        key={key}
        className={[classes.cell, classes.cellEditMode].join(" ")}
        style={{
          width: cellWidth,
          minWidth: cellWidth,
        }}
      >
        <DynamicForm
          key={key}
          fieldGroupsConfig={fieldGroupsConfig}
          fieldsConfig={myFieldConfig}
          fieldValues={fieldValues}
          mode="edit"
          onSubmit={() => { }}
          onError={() => { }}
          showSubmitButton={false}
          enableFieldGrouping={false}
          showLabels={false}
          onBlur={(fields) => {
            const fieldNames = Object.keys(fields);
            fieldNames.forEach((f) => {
              onChange(f, fields[f], fields, item);
            });
          }}
          classOverride="noclass"
          canUpdate={props.canUpdate}
          disabled={mode === MODE_EDIT}
          showCharCount={false}
        />
      </span>
    );
  };

  const getUserNameAndLastName = (cellValue) => {
    let userName = "";
    try {
      const user = props.users[cellValue];
      if (user) {
        userName = user.first_name + " " + user.last_name;
      }
    } catch (e) { }
    return userName;
  };

  const isNewOrSavedNewInThisSession = (item) => {
    if (item.new) {
      return true;
    } else if (!allFolderIdsInThisSession) {
      return false;
    } else {
      return !allFolderIdsInThisSession.includes(item.folderId);
    }
  };

  const getExpandedFolderTree = (parentFolderId) => {
    try {
      const parentId = `folder-${parentFolderId}`;
      let tree_expanded = [...props.applied_content_tree_expanded];
      if (parentFolderId) {
        const findParent = tree_expanded.find((te) => te === parentId);
        if (!findParent) {
          tree_expanded = [...props.applied_content_tree_expanded, parentId];
        }
      }
      return tree_expanded;
    } catch (e) {
      return null;
    }
  };

  const Row = ({ style, item, columns, mode, onChange }) => (
    <span
      key={item}
      className={`flex-center-left ${isNewOrSavedNewInThisSession(item) ? "list_new" : ""
        }`}
      style={{ ...style, border: lt_gray_border }}
    >
      {columns.map((column, index) => {
        const key = `column_${index}_row_${item.id}`;
        let cellValue = item[column.field];
        const cellType = getCellTypeSafe(column, props.folderTypes);
        const cellWidth = getCellWidthRecalculated(
          cellType,
          outerWithRef.current,
          columns
        );
        let onClickCallback = null;

        if (column.field === "actions") {
          return (
            <div
              key={key}
              style={{
                display: "flex",
                minWidth: cellWidth,
                width: cellWidth,
              }}
            >
              <Button
                disabled={sortMode !== SORT_RANK}
                className={classes.smallButton}
                onClick={() => addRecordBelow(item)}
                type="primary"
                size="large"
              >
                <FontAwesomeIcon size="xs" icon={["fas", "plus"]} />
              </Button>
              <Button
                disabled={sortMode !== SORT_RANK}
                className={classes.smallButton}
                onClick={() => {
                  if (item.new) {
                    handleSingleDelete(item);
                  } else {
                    handleConfirmSingleDelete(item);
                  }
                }}
                type="primary"
                size="large"
              >
                <FontAwesomeIcon size="xs" icon={["fas", "minus"]} />
              </Button>
            </div>
          );
        }

        // special checkbox
        if (column.field === "list_view_selected") {
          if (item.new) {
            return (
              <span
                key={key}
                className={[classes.cell, classes.checkboxCell].join(" ")}
              />
            );
          }
          return (
            <span
              key={key}
              className={[classes.cell, classes.checkboxCell].join(" ")}
            >
              <DynamicForm
                key={key}
                fieldGroupsConfig={{
                  default: { title: "", default_expanded: true },
                }}
                fieldsConfig={column.field_data}
                fieldValues={{ [column.field]: item.list_view_selected || allSelected }}
                mode="edit"
                onSubmit={() => { }}
                onError={() => { }}
                showSubmitButton={false}
                enableFieldGrouping={false}
                showLabels={false}
                onChangeHandlers={[
                  {
                    name: column.field,
                    callBackFunction: (name, value, fieldValues) =>
                      onChange(name, value, fieldValues, item),
                  },
                ]}
                classOverride="noclass"
                canUpdate={props.canUpdate}
                disabled={mode === MODE_EDIT}
              />
            </span>
          );
        }

        if (column.field === "hamburger") {
          if (item.new) {
            return (
              <span
                key={key}
                className={classes.iconCell}
                style={{
                  color: "#666",
                }}
              >
                <FontAwesomeIcon size="lg" icon={["fas", "asterisk"]} />
              </span>
            );
          }

          const noMovable = sortMode === SORT_OTHER || mode === MODE_SELECT;
          return (
            <span
              key={key}
              className={[classes.iconCell, "drag-icon"].join(" ")}
              style={{
                color: noMovable ? "#CCC" : "#666",
                cursor: noMovable ? "not-allowed" : "pointer",
              }}
            >
              <FontAwesomeIcon size="lg" icon={["far", "bars"]} />
            </span>
          );
        }

        const typeFieldGroupsConfig = {
          default: { title: "", default_expanded: true },
        };
        let typeFieldsConfig = column.field_data || {};
        let typeFieldValues = { [column.field]: cellValue };

        if (column.field === "type") {
          const { folderTypes } = props;
          const options = acceptableFolderTypesRef.current.map((item) => ({
            title: item.display_name,
            value: item.name,
          }));
          typeFieldsConfig = {
            [column.field]: {
              type: "select",
              validation: { rules: [{ name: "isRequired" }] },
              options,
              settings: {
                minWidth: 150,
              },
            },
          };

          if (folderTypes[cellValue]) {
            cellValue = folderTypes[cellValue].display_name;
            typeFieldValues = { [column.field]: cellValue };
          } else {
            typeFieldValues = {};
          }

          if (item && item.new) {
            if (cellType === "wysiwyg") {
              return renderReadOnlySpan(key, cellValue, cellWidth, cellType);
            } else {
              return renderDynamicFormSelect(key, typeFieldGroupsConfig, typeFieldsConfig, typeFieldValues, cellWidth, item, column.field, onChange);
            }
          }
          return renderReadOnlySpan(key, cellValue, cellWidth, cellType);
        }

        if (cellType === "file" || column.field === "file") {
          const { folderTypes } = props;
          const { type } = item;
          const folderType = folderTypes[type];
          const selected_folder = {
            id: item.folderId,
          };
          const fileFields = {};
          let fieldValues = {};
          const fixedInputFields =
            getSelectedFolderFixedInputFolderResourcesWrapper(
              item,
              selected_folder,
              folderType
            );
          const dynamicInputFields =
            getSelectedFolderDynamicInputFolderResourcesWrapper(
              item,
              selected_folder,
              folderType
            );
          if (fileFields) {
            fieldValues = {
              ...fieldValues,
              ...fixedInputFields,
              ...dynamicInputFields,
            };
          }

          return (
            <span
              key={key}
              style={{
                width: cellWidth,
                minWidth: cellWidth,
              }}
            >
              <ContentManagerWidget
                item={item}
                fieldName={column.field}
                fieldsConfig={column.field_data}
                defaultValue={fieldValues[column.field]}
                fieldValues={fieldValues}
                selected_folder={selected_folder}
                folderType={folderType}
                folder__status={props.folder__status}
                canUpdate={props.canUpdate}
                openContentManagerCall={openContentManagerCall}
              />
            </span>
          );
        }

        if (column.field === "updatedAt") {
          let updatedAt = "";
          if (cellValue) {
            updatedAt = dateFormatted(cellValue, "mmddyyyy");
          }
          return renderReadOnlySpan(key, updatedAt, cellWidth, cellType);
        }

        if (column.field === "updatedBy") {
          let userName = getUserNameAndLastName(cellValue);
          return renderReadOnlySpan(key, userName, cellWidth, cellType);
        }

        if (column.field === "name") {
          const folderId = _.get(item, "folderId", null);
          const treeId = `folder-${folderId}`;
          const parentFolderId = _.get(props.parentFolder, "id", null);

          const updateClientOptions = {
            list_view_last_select_folder: parentFolderId,
            applied_content_tree_selected_id: treeId,
            applied_content_modal_selected_folder: folderId,
          };

          onClickCallback = (key) => {
            updateClientOptions.list_view_last_select_key = key;
            props.setClientOptions(updateClientOptions);
            props.toggleFolderExpanded(treeId, false, true, props.client_options.treemode);
            const tree_expanded = getExpandedFolderTree(parentFolderId);
            if (parentFolderId && tree_expanded) {
              props.setFoldersExpanded(
                TREE_MODE_APPLIED_CONTENT,
                tree_expanded
              );
            }
          };
        }

        if (column.type === "integer") {
          // set default value to 0 for integers
          typeFieldValues[column.field] =
            cellValue || typeFieldsConfig[column.field].default || 0;
        }

        if (column.type === "text") {
          typeFieldsConfig[column.field].show_char_count = false;
        }

        if (column.type === "textarea") {
          typeFieldsConfig[column.field].settings = {
            autosize: {
              minRows: 1,
              maxRows: 1,
            },
          };
          typeFieldsConfig[column.field].show_char_count = false;
        }

        switch (mode) {
          case MODE_SELECT:
          case MODE_SORT:
            return renderReadOnlySpan(
              key,
              cellValue,
              cellWidth,
              cellType,
              onClickCallback
            );
          case MODE_EDIT:
            if (cellType === "wysiwyg") {
              return renderReadOnlyRichTextSpan(
                key,
                cellValue,
                cellWidth,
                cellType,
                column,
                item
              );
            } else if(cellType === "hybrid_text_input" && column.field === "headline") {
              return renderDynamicFormSpan(
                  key,
                  typeFieldGroupsConfig,
                  {
                    "headline": {
                      "type": "text",
                      "title": "Headline",
                      "validation": {
                        "rules": []
                      },
                      "show_char_count": false,
                      "settings": {
                      },
                    }
                  },
                  {headline: getCellValueSafe("hybrid_text_input", cellValue) },
                  cellWidth,
                  item,
                  mode,
                  onChange
              );
            }
            else {
              return renderDynamicFormSpan(
                key,
                typeFieldGroupsConfig,
                typeFieldsConfig,
                typeFieldValues,
                cellWidth,
                item,
                mode,
                onChange
              );
            }
        }
      })}
    </span>
  );

  useEffect(() => {
    if (props.sorting === SORT_RANK) {
      setSortMode(SORT_RANK);
    } else {
      setSortMode(SORT_OTHER);
    }
    setCurrentSort({ field: props.sorting, direction: "asc" });
  }, [props.sorting]);

  useEffect(() => {
    const currentType = props.folderTypes[props.parentFolder.folder_type];
    const acceptableTypes = currentType.acceptable_folder_types.map(
      (t) => props.folderTypes[t]
    );
    setAcceptableFolderTypes(acceptableTypes);
  }, [props.folderTypes, props.parentFolder]);

  useEffect(() => {
    const currentType = props.folderTypes[props.parentFolder.folder_type];
    if (currentType && currentType.prevent_add_children) {
      setShowAddButton(false);
    }
    addSubmitListViewCallback(saveEdits);
  }, [props.parentFolder]);

  useEffect(() => {
    if (allFolderIdsInThisSession === null) {
      const folderIds = props.listItems.map((ai) => ai.folderId);
      setAllFolderIdsInThisSession(folderIds);
    }
    const { field, direction } = currentSort;
    const orderedItems =
      sortMode === SORT_RANK
        ? [...props.listItems].sort((a, b) => a.rank - b.rank)
        : _.orderBy(
          [...props.listItems],
          [(i) => (i[field] ? i[field].toString().toLowerCase() : "")],
          direction
        );
    setItems(orderedItems);
  }, [props.listItems]);

  useEffect(() => {
    if (acceptableFolderTypesRef.current.length > 1) {
      const typeColumn = {
        folder_types: [],
        sort: false,
        editable: false,
        title: "Type",
        field: "type",
        default_shown: true,
        field_data: {
          type: {
            type: "text",
            title: "Type",
            validation: {
              rules: [{ name: "isRequired" }],
            },
            default: null,
          },
        },
      };
      const listViewColIndex = props.listColumns.findIndex(
        (c) => c.field === "list_view_selected"
      );
      const listViewCol = props.listColumns[listViewColIndex];
      if (listViewColIndex > -1) {
        const rest = [...props.listColumns];
        rest.splice(listViewColIndex, 1);
        setColumns([hamburgerColumn, listViewCol, typeColumn, ...rest]);
      } else {
        setColumns([hamburgerColumn, ...props.listColumns, typeColumn]);
      }
    }
  }, [acceptableFolderTypes]);

  useEffect(() => {
    let foundItems = itemsRef.current.filter((i) => i.list_view_selected);
    const sorting_order = _.get(props.parentFolder, "fields.sorting_options", null)
    if (sorting_order) {
      foundItems = sortDonors(sorting_order, foundItems)
    }
    setSelectedItems(foundItems);
    setSelectedItemsCount(foundItems.length);
    if (foundItems.length === itemsRef.current.length) {
      setAllSelected(true);
    }
  }, [items]);

  // check also folder__status, because the item can change it status and rebuilt all tablet
  useEffect(() => {
    if (sortMode === SORT_RANK) {
      const sortedItems = [...addedItemsRef.current, ...itemsRef.current].sort(
        (a, b) => a.rank - b.rank
      );
      setAllItems(sortedItems);
    } else {
      setAllItems([...addedItemsRef.current, ...itemsRef.current]);
    }
  }, [items, addedItems, props.folder__status]);

  /**
   * this function check al filters in fnObject for the item 'item' checking if the item should be filtered
   * @param item
   * @param columnFilters:
   * @param fnObject: map of filter functions
   * @returns {boolean}
   */
  const applyAllFilters = (item, columnFilters, fnObject) => {
    const shouldBeFiltered = Object.keys(fnObject).some((fnkey) => {
      let isIncluded;
      const currentFunction = fnObject[fnkey];
      const currentFilterValue = columnFilters[fnkey];
      if (currentFunction) {
        isIncluded = currentFunction(currentFilterValue, item);
        if (!isIncluded) {
          return true;
        }
      }
    });
    if (shouldBeFiltered) {
      // return false to not be added to the filtered array
      return false;
    } else {
      // return true bo be added to the filtered array
      return true;
    }
  };

  useEffect(() => {
    const myFilteredItems = allItems.filter((ai) =>
      applyAllFilters(ai, columnFilters, functionFilters)
    );
    setFilteredItems(myFilteredItems);
  }, [allItems, columnFilters, functionFilters]);

  useEffect(() => {
    if (mode === MODE_EDIT) {
      setAllSelected(false);
      if (selectedItemsCount > 0) {
        const indexes = selectedItems.map((i) =>
          itemsRef.current.findIndex((ii) => ii.id === i.id)
        );
        const newItems = [...itemsRef.current];
        indexes.forEach((i) => (newItems[i].list_view_selected = false));
        setSelectedItems([]);
        setItems(newItems);
      }
    }
  }, [mode]);

  /**
   * the field type do not came in the configuration, that is why it is added here
   * if the type are only 1, then the width is 0 because the type is not show
   */
  const getTypeColumnWidth = () => {
    if (acceptableFolderTypes && acceptableFolderTypes.lenght > 1) {
      return 300;
    } else return 0;
  };

  const handleResize = () => {
    const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
    const realWidth = boundingClientRect.width + getTypeColumnWidth();
    setOuterWidth(boundingClientRect.width - 40);
    setComponentWidth(boundingClientRect.width - 40);
  };

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        handleResize();
      }
    }, 500);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [wrappingDivRef, JSON.stringify(props.recalculateWidth)]);

  useEffect(() => {
    if (wrappingDivRef && wrappingDivRef.current) {
      const boundingClientRect = wrappingDivRef.current.getBoundingClientRect();
      setListContainerHeight(boundingClientRect.height - 96 - 40 - 50 - 300);
    }
  }, [wrappingDivRef]);

  useEffect(() => {
    setFolderType(props.folderTypes[props.parentFolder.folder_type]);
    /**
     * handle clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrappingDivRef.current &&
        !wrappingDivRef.current.contains(event.target)
      ) {
        if (unsavedChangesRef.current) {
          setUnsavedChangesModal(true);
        }
      }
    }

    // resize
    window.addEventListener("resize", handleResize);
    // Bind the event listener
    // document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the resize event
      window.removeEventListener("resize", handleResize);
      // Unbind the event listener on clean up
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setStyleValues({ columnsWidthSum, listContainerHeight });
  }, [columnsWidthSum, listContainerHeight]);

  useEffect(() => {
    const newList = _.orderBy(
      itemsRef.current,
      [
        (i) => {
          if (props.sorting === SORT_RANK) {
            return i[props.sorting];
          }
          const field = _.get(i, props.sorting, "zzz");
          return field.toLowerCase();
        },
      ],
      "asc"
    );
    setItems(newList);
  }, [sortMode, props.sorting]);

  useEffect(() => {
    setEditButtonDisabled(mode === MODE_EDIT);
  }, [mode]);

  useEffect(() => {
    clearTimeout(timeoutHandler);
    timeoutHandler2 = setTimeout(() => {
      if (wrappingDivRef && wrappingDivRef.current) {
        const boundingClientRect =
          wrappingDivRef.current.getBoundingClientRect();
        setComponentWidth(boundingClientRect.width - 40);
        if (boundingClientRect.width - 40 > columnsWidthSum) {
          setOuterWidth(boundingClientRect.width - 40);
        } else {
          setOuterWidth(columnsWidthSum);
        }
      }
    }, 500);
    return () => clearTimeout(timeoutHandler2);
  }, [
    columnsWidthSum,
    JSON.stringify(props.recalculateWidth),
    detail_drawer_open,
    tree_panel_open,
  ]);

  useEffect(() => {
    setColumnsWidthSum(
      getWidthFromColumns(columns, props.folderTypes, outerWithRef.current)
    );
  }, [columns, outerWidth]);

  useEffect(() => {
    if (props.listColumns) {
      const filters = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = [];
        }
        return accumulator;
      }, {});
      setInitialColumnFilters(filters);

      const filtersFn = props.listColumns.reduce((accumulator, current) => {
        if (current.filter) {
          accumulator[current.field] = getFilterFunction(current.field);
        }
        return accumulator;
      }, {});
      setFunctionFilters(filtersFn);
    }
  }, [props.listColumns]);

  useEffect(() => {
    const searchStringHighlight = _.get(
      props,
      "client_options.search_string_highlight",
      null
    );
    setSearchString(searchStringHighlight);

    const list_view_last_select_folder = _.get(
      props,
      "client_options.list_view_last_select_folder",
      null
    );
    const list_view_last_select_key = _.get(
      props,
      "client_options.list_view_last_select_key",
      null
    );

    if (list_view_last_select_folder === null && list_view_last_select_key) {
      setTimeout(() => {
        const element = document.querySelector(`.${list_view_last_select_key}`);
        if (element) {
          element.focus();
        }
        const updateClientOptions = {
          list_view_last_select_key: null,
        };
        props.setClientOptions(updateClientOptions);
      }, 500);
    }
  }, [props.client_options]);

  const folder__folders = useSelector((state) => {
    return state.data.folder__folders;
  });
  const folders = useSelector((state) => state.data.folders);
  const switchMode = (mode) => {
    switch (mode) {
      case MODE_SELECT:
        break;
      case MODE_EDIT:
        setEditButtonDisabled(true);
        setMode(MODE_EDIT);
        setForceUpdateInt(forceUpdateInt + 1);
        break;
    }
  };

  const switchSortMode = () => {
    setSortMode(SORT_RANK);
    setCurrentSort({ field: "rank", direction: "asc" });
    setMode(MODE_SORT);
    const newItems = _.orderBy(itemsRef.current, "rank", "asc");
    setItems(newItems);
  };

  const onAddBulkSuccess = () => {
    setAddedItems([]);
    AlertNotification(
      "success",
      "Success",
      "New items have been saved and they are now  in the list according to the sorting order"
    );
  };

  const onMoveBulkSuccess = () => {
    setAddedItems([]);
    AlertNotification(
      "success",
      "Success",
      "Items have been moved successfully"
    );
  };

  const onCloneBulkSuccess = () => {
    setAddedItems([]);
    AlertNotification(
      "success",
      "Success",
      "Items have been moved successfully"
    );
  };

  const onInstanceBulkSuccess = () => {
    setAddedItems([]);
    AlertNotification(
      "success",
      "Success",
      "Items have been copied successfully"
    );
  };

  const onDeleteBulkSuccess = (callback) => {
    setSelectedItems([]);
    AlertNotification(
      "success",
      "Success",
      "Records were deleted successfully"
    );
    if (callback) {
      callback();
    }
    dispatch(
      setClientOptions({
        lock_screen: false,
        message_for_progress: 'Deleting items',
        indeterminate: true
      }),
    );
  };

  const onUpdateBulkSuccess = () => {
    AlertNotification(
      "success",
      "Success",
      "New items have been saved and they are now  in the list according to the sorting order"
    );
    cleanStateAfterSaveSuccess();
  };

  const cleanStateAfterSaveSuccess = () => {
    dispatch(
      setClientOptions({ lock_screen: false, message_for_progress: null })
    );

    setUnsavedChangedItems([]);
    setUnsavedChanges(false);
    setUnsavedChangesModal(false);
  };

  const onHandleSingleDeleteCancel = () => {
    setSelectedItems([]);
    setShowDeleteConfirmModal(false);
  };

  const onFail = (msg) => {
    AlertNotification("error", "Error", msg);
  };

  const handleBulkMove = (destFolder) => {
    const { moveBulk } = props;
    const idOfFoldersBeingMoved = selectedItems.map((f) => f.folderFolderId);
    const { id: destFolderId } = destFolder;
    moveBulk(destFolderId, idOfFoldersBeingMoved, onMoveBulkSuccess, onFail);
  };

  const errorOnClone = () => {
    AlertNotification(
      "error",
      "Error",
      "One or more folders selected are non compatible with the destination folder"
    )
  }

  const handleBulkClone = (destFolder) => {
    const { cloneBulk } = props;
    const { id: destFolderId } = destFolder;
    const destinationFolderType = props.folderTypes[destFolder.folder_type];
    if (destinationFolderType &&
      destinationFolderType.acceptable_folder_types &&
      destinationFolderType.acceptable_folder_types.length > 0
    ) {
      for (const selectedItem of selectedItems) {
        console.log({selectedItem})
        if (!destinationFolderType.acceptable_folder_types.includes(selectedItem.type)) {
          return errorOnClone()
        }
        cloneBulk(destFolderId, selectedItems, onCloneBulkSuccess, onFail);
      }
      return
    }
    errorOnClone()
  };

  const handleBulkInstance = (destFolder) => {
    const { instanceBulk } = props;
    const idOfFoldersBeingInstanced = selectedItems.map((f) => f.folderId);
    instanceBulk(
      {
        parent_folder_id: destFolder.id,
        instanced_folder_ids: idOfFoldersBeingInstanced,
        dest_folder_folder_id: destFolder.folder__folder_id,
        spacer: false,
        moveIntoActionName: "move_into_first_position",
        origin_path: props.client_options.selected_folder_meta.path.join('/')
      },
      onInstanceBulkSuccess,
      null
    );
  };

  const handleBulkDelete = (callback = null) => {
    dispatch(
      setClientOptions({
        lock_screen: true,
        message_for_progress: "Deleting items",
        indeterminate: true
      })
    );
    const data = selectedItems.reduce(
      (acc, curr) => {
        acc.folder__folders_ids.push(curr.folderFolderId);
        acc.folder_ids.push(curr.folderId);
        return acc;
      },
      { folder__folders_ids: [], folder_ids: [] }
    );

    const all_other_folder__folders = Object.values(folder__folders).filter(
      (x) =>
        data.folder_ids.includes(x.child_folder) &&
        !data.folder__folders_ids.includes(x.id)
    );
    if (
      all_other_folder__folders.length > 0 &&
      all_other_folder__folders.every((folder_folder) => {
        const folder = folders[folder_folder.parent_folder];
        return props.folderTypes[folder.folder_type]
          .prevent_last_instance_in_folder;
      })
    ) {
      setShowDeleteConfirmModal(false);
      AlertNotification(
        "error",
        "Error",
        "This item can't be deleted, as it is being used in another location."
      );
      dispatch(
        setClientOptions({
          lock_screen: false,
        })
      );
      return;
    }

    props.deleteBulk(
      data.folder_ids,
      data.folder__folders_ids,
      "applied_content",
      () => onDeleteBulkSuccess(callback),
      (error) => {
        AlertNotification(
          "error",
          "Error",
          "Unexpect error happened"
        );
        dispatch(
          setClientOptions({
            lock_screen: false,
          })
        );
      },
      true
    );
  };

  const handleConfirmSingleDelete = (item) => {
    setShowDeleteConfirmModal(true);
    setSelectedItems([item]);
  };

  const handleSingleDelete = (item) => {
    if (!item) {
      item = selectedItems[0];
    }
    if (item.new) {
      const indexAddedItem = addedItemsRef.current.indexOf(
        (i) => item.id === i.id
      );
      const indexUnsavedItems = unsavedChangedItemsRef.current.indexOf(
        (i) => i.id === item.id
      );
      const newAddedItems = [...addedItemsRef.current];
      newAddedItems.splice(indexAddedItem, 1);
      const newUnsavedChangedItems = [...unsavedChangedItemsRef.current];
      unsavedChangedItemsRef.current.splice(indexUnsavedItems, 1);
      setAddedItems(newAddedItems);
      setUnsavedChangedItems(newUnsavedChangedItems);
    } else {
      setShowDeleteConfirmModal(false);
      handleBulkDelete();
    }
  };

  const checkCancelEditsPopModal = () => {
    if (unsavedChangesRef.current || addedItems.length > 0) {
      setUnsavedChangesModal(true);
    } else {
      setMode(MODE_SELECT);
      if (sortMode === SORT_RANK) {
        setUnsavedChangedItems([]);
        setUnsavedChanges(false);
        setItems(props.listItems.sort((a, b) => a.rank - b.rank));
      }
    }
  };

  const cancelEdits = () => {
    setItems(props.listItems);
    setAddedItems([]);
    setUnsavedChangesModal(false);
    setUnsavedChanges(false);
    setUnsavedChangedItems([]);
    setMode(MODE_SELECT);
    setForceUpdateInt(forceUpdateInt + 1);
    setEditButtonDisabled(false);
  };

  const buildCallOnAddBulkSuccess = () => {
    let callOnAddBulkSuccess = () => { };
    let callCleanState = () => { };

    // only call one success message
    if (unsavedChangedItemsRef.current.length === 0) {
      callOnAddBulkSuccess = onAddBulkSuccess;
    } else {
      callOnAddBulkSuccess = () => {
        setAddedItems([]);
      };
    }

    if (!shouldUpdateBulk()) {
      callOnAddBulkSuccess = onAddBulkSuccess;
      callCleanState = cleanStateAfterSaveSuccess;
    }

    return () => {
      callOnAddBulkSuccess();
      callCleanState();
    };
  };

  const saveEdits = async () => {
    const { addBulk, updateBulk } = props;
    setForceUpdateInt(forceUpdateInt + 1);
    dispatch(
      setClientOptions({
        lock_screen: true,
        message_for_progress: "We are saving your changes",
      })
    );

    // only call one success message
    const callOnAddBulkSuccess = buildCallOnAddBulkSuccess();

    if (addedItemsRef.current.length > 0) {
      const propertiesToExclude = ["folder", "id"];
      const folderFormattedItems = addedItemsRef.current.map((i) => {
        const newItem = {
          ...i,
          tree_type: "applied_content",
          parent_folder__folder_id: i.folder,
          folder_path: props.client_options.selected_folder_meta.path,
        };

        // Object cleanance.
        propertiesToExclude.forEach((p) => delete newItem[p]);
        return newItem;
      });

      if (!itemsValid(folderFormattedItems)) {
        dispatch(
          setClientOptions({ lock_screen: false, message_for_progress: null })
        );
        AlertNotification(
          "error",
          "Error Creating",
          "Please fill all the fields on the form"
        );
        return;
      }

      await addBulk(
        { folders: folderFormattedItems },
        callOnAddBulkSuccess,
        (message) =>
          AlertNotification(
            "error",
            "Error",
            message || "Unexpect error happened"
          )
      );
    }

    // If there is not items before do no call updateBulk
    if (shouldUpdateBulk()) {
      const folderFormattedItemsForUpdate = itemsRef.current.filter((i) =>
        unsavedChangedItemsRef.current.includes(i.id)
      );

      folderFormattedItemsForUpdate.forEach((i) => {
        if(typeof i.headline === 'string') {
          i.headline = {
            "mode": "plain_text",
            "textarea": i.headline
          }
        }
      })

      if (!itemsValid(folderFormattedItemsForUpdate)) {
        dispatch(
          setClientOptions({ lock_screen: false, message_for_progress: null })
        );
        AlertNotification(
          "error",
          "Error Updating",
          "Please fill all the fields on the form"
        );
        return;
      }

      updateBulk(
        { folders: folderFormattedItemsForUpdate },
        onUpdateBulkSuccess,
        (message) => {
          AlertNotification(
            "error",
            "Error",
            message || "Unexpect error happened"
          );
        }
      );
    }

    if (addedItemsRef.current.length === 0 && !shouldUpdateBulk()) {
      onUpdateBulkSuccess();
    }
    setMode(MODE_SELECT);
    setEditButtonDisabled(false);
  };

  const itemsValid = (folderFormattedItems) => {
    const propertiesRequired = columns
      .filter((c) => c.required)
      .map((col) => col.field);
    propertiesRequired.push("type");

    for (const folderFormattedItem of folderFormattedItems) {
      for (const propertyRequired of propertiesRequired) {
        if (!folderFormattedItem[propertyRequired]) {
          return false;
        }
      }
    }

    return true;
  };

  const shouldUpdateBulk = () => {
    if (items.length > 0 && unsavedChangedItemsRef.current.length > 0) {
      const folderFormattedItemsForUpdate = itemsRef.current.filter((i) =>
        unsavedChangedItemsRef.current.includes(i.id)
      );
      return folderFormattedItemsForUpdate.length > 0;
    }
    return false;
  };

  const customSort = (field) => {
    let direction = "asc";
    if (currentSort.field === field) {
      direction = currentSort.direction === "asc" ? "desc" : "asc";
    }
    setSortMode(SORT_OTHER);
    setCurrentSort({ field, direction });
    const nonNewItems = itemsRef.current.filter((i) => i.rank >= 0);
    const sortedNoNewItems = _.orderBy(
      nonNewItems,
      [(i) => i[field].toString().toLowerCase()],
      direction
    );
    setItems(sortedNoNewItems);
  };

  // Only called when the folder type of a row is changed.
  const recalculateRow = (item, newType) => {
    const copyOfItem = { ...item };
    if (item.rank < 0) {
      const type = acceptableFolderTypes.find((t) => t.name === newType);
      const typeListViewColumns = _.get(type, "list_view.columns", []);
      const columns = typeListViewColumns.map((t) => t.field);
      Object.keys(copyOfItem).forEach((k) => {
        if (!columns.includes(k)) {
          delete copyOfItem[k];
        }
      });
      const foundIndexOfItem = addedItems.findIndex((i) => i.id === item.id);
      const newSetOfAddedItems = [...addedItems].splice(
        foundIndexOfItem,
        1,
        copyOfItem
      );
      setAddedItems(newSetOfAddedItems);
    }
  };

  const onChange = (name, field, fieldValues, item, isMedia = false) => {
    console.log('onchange');
    console.log()
    // if the change is in a media file the content manager handle the save to the database
    // so we do not need to mark the component as touch
    if (!isMedia) {
      setUnsavedChanges(true);
      const removedDuplicated = [
        ...new Set([...unsavedChangedItemsRef.current, item.id]),
      ];
      setUnsavedChangedItems(removedDuplicated);
    }
    switch (name) {
      // case 'type':
      //   recalculateRow(item, fieldValues.type);
      //   break;
      case "list_view_selected":
        const foundIndex = itemsRef.current.findIndex((i) => i.id === item.id);
        const newItems = [...itemsRef.current];
        newItems.splice(foundIndex, 1, {
          ...item,
          list_view_selected: fieldValues[name],
        });
        setItems(newItems);
        break;
      default:
        if (item.rank >= 0 && !item.new) {
          const index = itemsRef.current.findIndex((i) => i.id === item.id);
          if (index >= 0) {
            const foundItem = {
              ...itemsRef.current[index],
              [name]: field,
              changed: true,
            };
            // Remove copy item in a new array to prevent loss focus (tab press)
            const newItems = [...itemsRef.current];
            newItems.splice(index, 1, foundItem);
            setItems(newItems);
          }
        } else {
          const index = addedItemsRef.current.findIndex(
            (i) => i.id === item.id
          );
          if (index >= 0) {
            const foundItem = {
              ...addedItemsRef.current[index],
              [name]: field,
            };
            // Remove copy item in a new array to prevent loss focus (tab press)
            const newItems = [...addedItemsRef.current];
            newItems.splice(index, 1, foundItem);
            setAddedItems(newItems);
          }
        }
        break;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newAllItems = JSON.parse(JSON.stringify(allItems));
    const rankFix = newIndex > oldIndex ? 0.001 : -0.001;
    const rerankedItems = [];

    const newAddedItems = [];
    const newItems = [];

    newAllItems[oldIndex].rank = Number((newIndex + rankFix).toFixed(3));
    newAllItems
      .sort((a, b) => a.rank - b.rank)
      .forEach((item, index) => {
        item.rank = Number(index);
        rerankedItems.push(item.id);
        if (item.new) {
          newAddedItems.push(item);
        } else {
          newItems.push(item);
        }
      });
    setUnsavedChangedItems(rerankedItems);
    setUnsavedChanges(true);

    setAddedItems(newAddedItems);
    setItems(newItems);
  };

  const onSelectAllChange = (value) => {
    const newItems = items.map((i) => {
      const newItem = { ...i };
      newItem.list_view_selected = value;
      return newItem;
    });
    setAllSelected(value);
    setItems(newItems);
  };

  const addRecord = () => {
    const type = acceptableFolderTypesRef.current[0].name;
    const newRecord = {
      new: true,
      id: newAddedItemIndex,
      folder: props.parentFolder.id,
      rank: -1,
      type,
    };
    setNewAddedItemIndex(newAddedItemIndex + 1);
    for (const col of columns) {
      if (!["hamburger", "list_view_selected"].includes(col.field)) {
        newRecord[col.field] = "";
      }
    }
    setAddedItems([newRecord, ...addedItemsRef.current]);
    setMode(MODE_EDIT);
  };

  const addRecordBelow = (item) => {
    const type = acceptableFolderTypesRef.current[0].name;
    const higherRankInAddedItems = addedItemsRef.current
      .filter((i) => i.rank >= item.rank && i.rank < item.rank + 1)
      .sort((a, b) => b.rank - a.rank);
    const newRecordRank =
      higherRankInAddedItems.length > 0
        ? Number((Number(higherRankInAddedItems[0].rank) + 0.001).toFixed(3))
        : Number((Number(item.rank) + 0.001).toFixed(3));
    const newRecord = {
      new: true,
      id: newAddedItemIndex,
      folder: props.parentFolder.id,
      rank: newRecordRank,
      type,
    };
    setNewAddedItemIndex(newAddedItemIndex + 1);
    for (const col of columns) {
      if (!["hamburger", "list_view_selected"].includes(col.field)) {
        newRecord[col.field] = "";
      }
    }
    setAddedItems([newRecord, ...addedItemsRef.current]);
    if (mode !== MODE_EDIT) {
      setMode(MODE_EDIT);
    }
  };

  const getFilterUpdatedByObject = (data, property, include_blank = false) => {
    const returnObject = [
      ...new Set(data.filter((x) => x[property]).map((x) => x[property])),
    ].map((x) => {
      let userName = getUserNameAndLastName(x);
      return { text: userName, value: x };
    });
    if (include_blank) {
      returnObject.push({ text: "(Empty)", value: "(empty)" });
    }
    return returnObject;
  };

  const onFilterColumn = (key, filterValue) => {
    const newFilters = { ...columnFilters };
    newFilters[key] = filterValue;
    setColumnFilters({ ...columnFilters, ...newFilters });
  };

  const renderHeaders = (tableWidth, allItems) => (
    <span
      id="headers"
      className="flex-center-left"
      style={{
        border: lt_gray_border,
        height: 50,
        width: props.hasResponsiveDesign ? "100%" : outerWithRef.current,
      }}
    >
      {columns.map((column, index) => {
        const cellType = getCellTypeSafe(column, props.folderTypes);
        const cellWidth = getCellWidthRecalculated(
          cellType,
          tableWidth,
          columns
        );

        let sortDirection = "none";
        if (currentSort.field === column.field) {
          if (currentSort.direction === "asc") {
            sortDirection = "asc";
          } else {
            sortDirection = "desc";
          }
        }

        switch (cellType) {
          case "actions":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "icon":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              />
            );
          case "bool":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth }}
              >
                <Checkbox
                  disabled={!props.canUpdate || mode === MODE_EDIT}
                  checked={allSelected}
                  onChange={(e) => onSelectAllChange(e.target.checked)}
                />
              </span>
            );
          case "integer":
          case "text":
            return (
              <span
                key={index}
                className={classes.cell}
                style={{ minWidth: cellWidth, cursor: "pointer" }}
                onClick={() => customSort(column.field)}
              >
                <b>
                  {column.title} &nbsp;&nbsp;
                  {sortDirection === "none" && (
                    <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                  )}
                  {sortDirection === "asc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-down"]} />
                  )}
                  {sortDirection === "desc" && (
                    <FontAwesomeIcon size="sm" icon={["far", "sort-up"]} />
                  )}
                </b>
              </span>
            );
          default:
            let extraClass = _.get(columnsExtraClass, `[${column.field}]`, "");
            return (
              <span
                key={index}
                className={`${classes.cell} ${classes.cellFlex} ${extraClass}`}
                style={{
                  minWidth: cellWidth,
                  cursor: column.sort ? "pointer" : "default",
                }}
                onClick={() => customSort(column.field)}
              >
                <div
                  className={`${classes.rowGrow} ${column.filter ? classes.rowGrowPadding : ""
                    }`}
                >
                  <div>
                    <b>
                      {column.title} &nbsp;&nbsp;
                      {sortDirection === "none" && (
                        <FontAwesomeIcon size="sm" icon={["fas", "sort"]} />
                      )}
                      {sortDirection === "asc" && (
                        <FontAwesomeIcon
                          size="sm"
                          icon={["far", "sort-down"]}
                        />
                      )}
                      {sortDirection === "desc" && (
                        <FontAwesomeIcon size="sm" icon={["far", "sort-up"]} />
                      )}
                    </b>
                  </div>
                </div>
                {column.filter ? (
                  <div className={classes.rowRight}>
                    <FilterActions
                      filterKey={column.field}
                      options={getFilterUpdatedByObject(
                        allItems,
                        cellType,
                        true
                      )}
                      onFilter={onFilterColumn}
                      onHoverIn={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] =
                          "list-view_header-hover";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                      onHoverOut={() => {
                        const newColumnExtraClass = { ...columnsExtraClass };
                        newColumnExtraClass[column.field] = "";
                        setColumnsExtraClass(newColumnExtraClass);
                      }}
                    ></FilterActions>
                  </div>
                ) : null}
              </span>
            );
        }
      })}
    </span>
  );

  const shouldCancelStart = (event) => {
    if (sortMode !== SORT_RANK || mode === MODE_SELECT) {
      return true;
    }
    let targetEl = event.target;
    do {
      if (typeof targetEl.className === "string") {
        if (targetEl.className.includes("drag-icon")) {
          return false;
        }
      }
      targetEl = targetEl.parentNode;
    } while (targetEl.parentNode);
    return true;
  };

  const getButtonSize = () => {
    if (componentWidth > MIN_WIDTH_FULL_RESOLUTION) {
      return "large";
    } else if (componentWidth > MIN_WIDTH_MEDIUM_RESOLUTION) {
      return "large";
    }
    return "small";
  };

  const sortBy = `Sort by ${sortMode !== SORT_RANK ? "Rank" : strToUpper(props.sorting)
    }`;

  const isComponentWidthFull = componentWidth > MIN_WIDTH_FULL_RESOLUTION;

  return (
    <div className={classes.listContainer} ref={wrappingDivRef}>
      <div id="actions">
        <div id="multi-action">
          {mode === MODE_SELECT && selectedItemsCount > 0 && (
            <MultiFolderActions
              selectedItemsCount={selectedItemsCount}
              handleBulkMove={handleBulkMove}
              handleBulkDelete={handleBulkDelete}
              handleBulkInstance={handleBulkInstance}
              handleBulkClone={handleBulkClone}
              caller_folder={0}
              checked_folders={[]}
              maxWidth={componentWidth}
              selectedItems={selectedItems}
              folderTypes={props.folderTypes}
              onOkay={() => {
                setUnsavedChanges(false);
              }}
              folder__folders={folder__folders}
              folders={folders}
            />
          )}
        </div>
        <div id="top-buttons">
          {showAddButton ? (
            <Button
              onClick={() => addRecord()}
              className="list-view_button"
              type="primary"
              disabled={!props.canUpdate}
              size={getButtonSize()}
              style={{ width: "auto", marginRight: 5 }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">Add</span>
              ) : (
                <Tooltip
                  placement="bottom"
                  title="Add"
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon size="sm" icon={["fas", "plus"]} />
                </Tooltip>
              )}
            </Button>
          ) : null}
          {props.sorting === "rank" && (
            <Button
              disabled={!props.canUpdate || currentSort.field === "rank"}
              onClick={() => switchSortMode()}
              className="list-view_button"
              type="primary"
              size={getButtonSize()}
              style={{ width: "auto", marginRight: 5 }}
            >
              {isComponentWidthFull ? (
                <span className="list-view_button-text">{sortBy}</span>
              ) : (
                <Tooltip
                  placement="bottom"
                  title={sortBy}
                  className="list-view_button-icon"
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={["fas", "sort"]}
                    onClick={() => switchSortMode()}
                  />
                </Tooltip>
              )}
            </Button>
          )}
          <Button
            onClick={() => switchMode(MODE_EDIT)}
            disabled={!props.canUpdate || editButtonDisabled}
            className="list-view_button"
            type="primary"
            size={getButtonSize()}
            style={{ width: "auto" }}
          >
            {isComponentWidthFull ? (
              <span className="list-view_button-text">Edit Mode</span>
            ) : (
              <Tooltip
                placement="bottom"
                title="Edit Mode"
                className="list-view_button-icon"
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={["fas", "edit"]}
                  onClick={() => switchMode(MODE_EDIT)}
                />
              </Tooltip>
            )}
          </Button>
        </div>
      </div>
      <div
        id="list"
        className={props.hasResponsiveDesign ? "responsive-list" : ""}
      >
        {renderHeaders(outerWithRef.current, allItems)}
        <SortableListMemo
          outerWidth={outerWithRef.current}
          listContainerHeight={listContainerHeight}
          allItems={filteredItems}
          onSortEnd={onSortEnd}
          list={filteredItems}
          forceUpdateInt={forceUpdateInt}
          shouldCancelStart={shouldCancelStart}
          helperClass={classes.beingDragged}
          mode={mode}
          columns={columns}
          onChange={onChange}
          Row={Row}
        />
      </div>
      <div id="bottom-buttons">
        <Button
          onClick={checkCancelEditsPopModal}
          disabled={mode === MODE_SELECT}
          type="secondary"
          size="large"
          style={{ width: "auto" }}
        >
          CANCEL
        </Button>
        <Spaner width="sm" />
        <Button
          onClick={() => saveEdits()}
          disabled={mode === MODE_SELECT}
          type="primary"
          size="large"
          style={{ width: "auto" }}
        >
          SAVE
        </Button>
      </div>

      <Modal
        visible={unsavedChangesModal}
        title="Cancel Edits"
        onCancel={() => setUnsavedChangesModal(false)}
        onOk={cancelEdits}
        destroyOnClose="true"
      >
        <div>Are you sure you want discard the changes made?</div>
      </Modal>

      <Modal
        visible={showDeleteConfirmationModal}
        title="Delete Record"
        onCancel={onHandleSingleDeleteCancel}
        onOk={handleSingleDelete}
        destroyOnClose="true"
      >
        <div>
          {`Are you sure you want to delete ${selectedItems[0] ? selectedItems[0].name : null
            }`}
        </div>
      </Modal>

      <Modal
        title="Edit Content"
        wrapClassName="modal_content_manager"
        visible={contentManagerOpen}
        onCancel={() => {
          closeContentManagerModalSafe(contentManagerCloseCallback);
        }}
        onOk={() => { }}
        destroyOnClose="true"
        width={1024}
        footer={false}
        maskClosable={false}
      >
        <ContentManager
          items={contentManagerItems}
          defaultValue={contentManagerDefaultValue}
          acceptable={contentManagerAcceptable}
          singleItem={contentManagerSingleItem}
          config={contentManagerConfig}
          limit={contentManagerLimit}
          title={contentManagerTitle}
          selectedFolderFromWidget={contentManagerSelectedFolder}
          handleAddMultipleFiles={handleAddMultipleFilesContentManager}
          onChangeAllValues={onChangeAllValuesContentManager}
          close={closeContentManagerModalSafe}
          closeForced={closeContentManagerModal}
          onRemove={handleRemoveInputContentManager}
          onChange={onFieldChangeContentManger}
          updateItems={setContentManagerItems}
          updateTouch={setContentManagerTouch}
          onCancelCallback={onCancelCallbackContentManager}
        />
      </Modal>

      <Modal
        visible={contentManagerConfirmationModalOpen}
        onCancel={() => {
          setContentManagerConfirmationModalOpen(false);
        }}
        onOk={() => {
          resetContentManager(contentManagerFieldName);
          setContentManagerConfirmationModalOpen(false);
          closeContentManagerModal();
        }}
      >
        If you close this modal you will lose all your changes, are you sure?
      </Modal>
    </div>
  );
};

export default DragDropHoc;
