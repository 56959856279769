import { FontsFamilies } from "../../../../fonts";
import { getFontSizes } from "../../../../utils/general";

export const bostonFonts = [
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.MYRIAD_PRO,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.WHITNEY
];

const fontMinSize = 20;
const fontMaxSize = 40;

export const textStyleFontSizes = getFontSizes(20, 40, 1, "em", (i) => {
  return `${i}em`;
});

export const quillFontSizes = {
  min: fontMinSize,
  max: fontMaxSize,
  type: "range"
};

export const textStyleOptions = {
  font: {
    enabled: true,
    items: bostonFonts
  },
  alignment: {
    enabled: true,
    items: [
      { title: "Left", value: "left" },
      { title: "Center", value: "center" },
      { title: "Right", value: "right" }
    ]
  },
  fontSize: {
    enabled: true,
    items: textStyleFontSizes
  },
  lineHeight: {
    enabled: false
  },
  bold: {
    enabled: true
  },
  color: {
    enabled: true
  },
  italic: {
    enabled: true
  },
  underline: {
    enabled: true
  }
};
