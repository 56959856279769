/* eslint-disable */
import { createSelector } from "@reduxjs/toolkit";
import { getFileType } from "../shared/utils/fileUtils";
import _ from "lodash";
import {
  TREE_MODE_APPLIED_CONTENT,
  TREE_MODE_RESOURCE,
} from "../components/constants";
import { getCurrentCompanyJoinedFolder__FolderFolders } from "./companyFolderMergeFolderFolders";
import { getCurrentTemplate as getCurrentTheme } from "../utils/themeUtils";

const getCompanies = (state) => {
  return state.data.companies;
};

const getLocations = (state) => {
  return state.data.locations;
};

const getProjects = (state) => {
  return state.data.projects;
};

const getSearch = (state) => {
  if (state.client_options.search_string_main) {
    return state.client_options.search_string_main.toLowerCase();
  } else {
    return state.client_options.search_string_main;
  }
};

const getFolders = (state) => {
  if (getTreeMode(state) === TREE_MODE_APPLIED_CONTENT) {
    return state.data.folders;
  } else {
    return state.data.resourcetree_folders;
  }
};

const getResources = (state) => {
  if (getTreeMode(state) === TREE_MODE_APPLIED_CONTENT) {
    return state.data.empty;
  } else {
    if (state.data.resources) {
      return state.data.resources;
    }
    return state.data.empty;
  }
};

const getResourceTreeResourceFolders = (state) => {
  if (getTreeMode(state) === TREE_MODE_APPLIED_CONTENT) {
    return state.data.empty;
  } else {
    return state.data.resourcetree_resource__folders;
  }
};

const getfolder__folders = (state) => {
  if (getTreeMode(state) === TREE_MODE_APPLIED_CONTENT) {
    return state.data.folder__folders;
  } else {
    return state.data.resourcetree_folder__folders;
  }
};

const getExpandedFolders = (state) => {
  switch (state.mode) {
    case "modal":
      return state.data.resource_tree_modal_expanded;
    case "bulk_move_modal":
      return state.data.applied_content_modal_tree_expanded;
    case "normal":
    default:
      if (state.client_options.treemode === TREE_MODE_APPLIED_CONTENT) {
        return state.data.applied_content_tree_expanded;
      } else {
        return state.data.resource_tree_expanded;
      }
  }
};

const getCurrentTemplate = (state) => {
  return state.client_options.current_template;
};
// const getManageShowSiblings = (state) => {
//   return state.client_options.manage_tree_options.show_match_siblings;
// };

const getFolderTypes = (state) => {
  return state.data.folder_types;
};

const getTreeMode = (state) => {
  if (state.treemode_override) {
    return state.treemode_override;
  }
  return state.client_options.treemode;
};

const getFilters = (state) => {
  if (state.filters && state.filters.length) {
    return state.filters;
  }
  return null;
};

const getUserPermissionsGrouped = (state) => {
  return state.data.user_permissions_grouped;
};

const getUser = (state) => {
  return state.data.user;
};

const arrayFromKeyedObject = (obj, arrayId) => {
  let returnArray = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      returnArray.push({
        ...obj[key],
        // Unique identifier for tree view
        treeId: arrayId + "-" + obj[key].id,
      });
    }
  }
  return returnArray;
};

const getAppliedMode = (state) => {
  return state.appliedMode;
};

const getAppliedModeAction = (state) => {
  return state.appliedModeAction;
};

const getSelectedItemsFolderTypes = (state) => {
  return state.appliedMode
    ? state.selectedItems.map((selectedItem) => selectedItem.type)
    : [];
};

const checkIfChildrenAcceptFolder = (
  folderTypeToMove,
  folderTypeToCheck,
  folderTypes
) => {
  let resp = false;
  if (
    folderTypeToCheck.acceptable_folder_types &&
    folderTypeToCheck.acceptable_folder_types.length > 0
  ) {
    if (folderTypeToCheck.acceptable_folder_types.includes(folderTypeToMove)) {
      resp = true;
    } else {
      for (const acceptableFolderTypeChild of folderTypeToCheck.acceptable_folder_types) {
        if (
          folderTypeToCheck.acceptable_folder_types.includes(folderTypeToMove)
        ) {
          resp = true;
        }
        resp = checkIfChildrenAcceptFolder(
          folderTypeToMove,
          folderTypes[acceptableFolderTypeChild],
          folderTypes
        );
        if (resp) {
          return resp;
        }
      }
    }
  }
  return resp;
};

export const getAppliedTreeData = createSelector(
  [
    getCompanies,
    getLocations,
    getProjects,
    getSearch,
    getFolders,
    getCurrentCompanyJoinedFolder__FolderFolders,
    getExpandedFolders,
    // getManageShowSiblings,
    getFolderTypes,
    getTreeMode,
    getResources,
    getResourceTreeResourceFolders,
    getFilters,
    getUserPermissionsGrouped,
    getUser,
    getCurrentTemplate,
    getAppliedMode,
    getSelectedItemsFolderTypes,
    getAppliedModeAction,
  ],
  (
    companies,
    locations,
    projects,
    search,
    folders,
    joinedfolder__foldersArr,
    expandedFolders,
    // manageShowSiblings,
    folder_types,
    treemode,
    resources,
    resourcetree_resource__folders,
    filters,
    permissions,
    user,
    current_template,
    isAppliedMode,
    selectedItemsFolderTypes,
    appliedModeAction
  ) => {
    // company / location / project ( => root folder) / display_group
    const companiesArr = arrayFromKeyedObject(companies, "company").filter(
      (x) => x.id === user.company
    );
    const locationsArr = arrayFromKeyedObject(locations, "location");
    const projectsArr = arrayFromKeyedObject(projects, "project");

    let resourcetree_resourceFoldersArr = arrayFromKeyedObject(
      resourcetree_resource__folders,
      "resource"
    );

    resourcetree_resourceFoldersArr = resourcetree_resourceFoldersArr.sort(
      (a, b) => {
        return a.rank - b.rank;
      }
    );

    let tree;
    let newExpandedFolder = expandedFolders;
    if (treemode === TREE_MODE_APPLIED_CONTENT) {
      //content tree add location / project
      tree = companiesArr
        .map((company) => ({ ...company, depth: 1 }))
        .reduce((acc, curr) => {
          if (!expandedFolders.includes(curr.treeId)) {
            return [...acc, curr];
          }
          curr.expanded = true;
          const locations = locationsArr
            .filter((location) => location.company === curr.id)
            .map((location) => ({ ...location, depth: 2 }));
          return [...acc, curr, ...locations];
        }, [])
        .reduce((acc, curr) => {
          if (
            curr.depth !== 2 ||
            (!expandedFolders.includes(curr.treeId) &&
              !curr.treeId.includes("location")) ||
            (!expandedFolders.includes(curr.treeId) &&
              curr.treeId.includes("location") &&
              curr.shows_in_tree !== false)
          ) {
            return [...acc, curr];
          }

          curr.expanded = true;
          const projects = projectsArr.filter((project) => {
            if (project.location !== curr.id) {
              return false;
            }

            if (user.system_admin) {
              return true;
            }

            // Permissions restricting applied content at project level
            for (
              let a = 0;
              a < permissions.applied_content.restrict.length;
              a++
            ) {
              let permission = permissions.applied_content.restrict[a];
              if (
                permission.scope === "company" &&
                permission.action.includes("r")
              ) {
                return false;
              }
              if (
                permission.project === project.id &&
                permission.action.includes("r")
              ) {
                return false;
              }
            }
            // Permissions granting applied content at project level
            for (let a = 0; a < permissions.applied_content.grant.length; a++) {
              if (permissions.applied_content.grant[a].read !== true) {
                return false;
              }
              if (permissions.applied_content.grant[a].scope === "company") {
                return true;
              }
              if (permissions.applied_content.grant[a].project === project.id) {
                return true;
              }
            }
            return false;
          });
          const root_fold_proj = Object.values(projects).reduce(
            (acc2, project) => {
              let projectsDepth = 3;
              if (curr.shows_in_tree === false) {
                projectsDepth = 2;
              }
              const rootFolders = joinedfolder__foldersArr
                .filter(
                  (folder) =>
                    folder.parent_folder === null &&
                    folder.project === project.id
                )
                .filter((folder) =>
                  current_template !== "All themes"
                    ? folder.folder_type === current_template
                    : true
                )
                .map((rootFolder) => ({
                  ...rootFolder,
                  path: [rootFolder.folder__folder_id],
                  depth: projectsDepth,
                }));

              return [...acc2, ...rootFolders];
            },
            []
          );

          if (
            curr.treeId.includes("location") &&
            curr.shows_in_tree === false
          ) {
            return [...acc, ...root_fold_proj];
          }
          return [...acc, curr, ...root_fold_proj];
        }, []);
    } else {
      //resource tree
      tree = companiesArr
        .map((company) => ({ ...company, depth: 1 }))
        .reduce((acc, curr) => {
          if (curr.depth !== 1 || !expandedFolders.includes(curr.treeId)) {
            return [...acc, curr];
          }

          curr.expanded = true;
          const rootFolders = joinedfolder__foldersArr
            .filter(
              (folder) =>
                folder.folder_type === "resource_default" &&
                folder.company === user.company
            )
            .map((rootFolder) => ({
              ...rootFolder,
              depth: 2,
              path: [rootFolder.folder__folder_id],
            }));
          const extraExpandedFolder = rootFolders.map(
            (rf) => "folder-" + rf.id
          );
          newExpandedFolder = [
            ...new Set([...expandedFolders, ...extraExpandedFolder]),
          ];

          return [...acc, curr, ...rootFolders];
        }, []);
    }

    const isLastNode = (joinedfolder__foldersArr, id) => {
      const finded = joinedfolder__foldersArr.filter(
        (folder) => folder.parent_folder === id
      );
      return finded && finded.length === 0;
    };

    const checkIfSomeParentIsPresentation = (treeItem, fullTree) => {
      const parentFolderFolders = joinedfolder__foldersArr.filter(
        (folder__folder) => folder__folder.child_folder === treeItem.id
      );
      if (!parentFolderFolders.length) {
        return false;
      }
      for (const parentFolderFolder of parentFolderFolders) {
        const parentFolder = fullTree.find(
          (item) => item.id === parentFolderFolder.parent_folder
        );
        if (!parentFolder || !parentFolder.folder_type) {
          continue;
        }
        const parentFolderType = folder_types[parentFolder.folder_type];
        if (
          parentFolderType.isPresentation ||
          parentFolderType.isPresentations
        ) {
          return true;
        } else {
          return checkIfSomeParentIsPresentation(parentFolder, fullTree);
        }
      }
      return false;
    };

    const recurse = (
      arr,
      depth = 0,
      last,
      outsideExpandedFolder = expandedFolders
    ) => {
      const searchDepth = depth;
      let insertDepth = depth + 1;
      if (last && last.treeId.startsWith("resource-")) {
        return [];
      }

      return arr.reduce((acc, curr) => {
        curr.parent_folder__folder_id = last ? last.folder__folder_id : null;

        if (
          searchDepth !== curr.depth ||
          !outsideExpandedFolder.includes(curr.treeId)
        ) {
          if (curr.depth > 2 && !outsideExpandedFolder.includes(curr.treeId)) {
            curr.expanded = false;
          }
          if (curr.depth < 2 || !outsideExpandedFolder.includes(curr.treeId)) {
            curr.last_node_item = false; //0,1,2 is company, location, project // isLastNode(joinedfolder__foldersArr, curr.id);
            return [...acc, curr];
          }
        }

        if (curr.parent_folder) {
          const parentFolder = folders[curr.parent_folder];
          const folderType = folder_types[parentFolder.folder_type];
          if (
            folderType &&
            folderType.tree_dropped_into_action &&
            folderType.tree_dropped_into_action[0]
          ) {
            if (
              folderType.tree_dropped_into_action[0].action ===
              "instance_replace"
            ) {
              curr.expanded = false;
              return [...acc, curr];
            }
          }
        }

        // Not expand children if the nested children are hidden
        if (last) {
          if (
            !folder_types[last.folder_type] ||
            folder_types[last.folder_type].hide_nested_children
          ) {
            curr.expanded = false;
            return [...acc, curr];
          }
        }

        let children = joinedfolder__foldersArr
          .filter((folder) => {
            if (
              isAppliedMode &&
              folder.folder_type_config &&
              folder.folder_type_config.acceptable_folder_types &&
              folder.folder_type_config.acceptable_folder_types.length === 0
            ) {
              return false;
            }

            // Permissions restricting folder types
            if (folder.parent_folder === curr.id) {
              if (permissions.folder.restrict.length) {
                for (var a = 0; a < permissions.folder.restrict.length; a++) {
                  if (
                    folder.folder_type_config.name ===
                    permissions.folder.restrict[a].action_item
                  ) {
                    return false;
                  }
                }
              }
              return true;
            }

            return false;
          })
          .map((folder) => {
            if (curr.project) {
              folder.project = curr.project;
            }

            if (curr.path && depth > 0) {
              return {
                ...folder,
                path: [...curr.path, folder.folder__folder_id],
                depth: curr.depth + 1,
              };
            }

            return {
              ...folder,
              depth: curr.depth + 1,
            };
          });
        // Sort by custom field if defined in folder_type config.
        // Format is [ field_to_sort_by, direction ], where direction is "asc" or "desc"

        const filterByTypeExt = (resource__folder) => {
          if (!filters) {
            return true;
          }
          const resource = resources[resource__folder.resource];
          if (!resource) {
            return true;
          }
          if (resource.type === "file") {
            //custom extension handles
            const fileType = getFileType(resource.extension);
            if (filters.includes(fileType)) {
              return true;
            }
          } else {
            if (filters.includes(resource.type)) {
              return true;
            }
          }
          return false;
        };

        // Sort by rank by default otherwise allow sorting by a field selected in parent folder
        const sortConfig = _.get(
          curr,
          "folder_type_config.fields.sorting_options"
        );

        if (sortConfig && curr && curr.fields && curr.fields.sorting_options) {
          switch (curr.fields.sorting_options) {
            case "rank":
              children = _.sortBy(children, "rank");
              break;
            case "date":
              children = _.orderBy(
                children,
                "fields[" + curr.fields.sorting_options + "]",
                "asc"
              );
              break;
            case "name":
              children = _.orderBy(
                children,
                [(c) => c.name.toLowerCase()],
                "asc"
              );
            default:
              children = _.orderBy(
                children,
                [
                  (c) => {
                    const field = _.get(
                      c,
                      `fields[${curr.fields.sorting_options}]`,
                      ""
                    );
                    return field.toLowerCase();
                  },
                ],
                "asc"
              );
              break;
          }
        } else {
          if (sortConfig && sortConfig.length === 1) {
            switch (sortConfig[0].type) {
              case "date":
                children = _.orderBy(
                  children,
                  "fields[" + sortConfig[0].options.field_name + "]",
                  "asc"
                );
                break;
              default:
                children = _.sortBy(children, "rank");
                break;
            }
          } else {
            children = _.sortBy(children, "rank");
          }
        }

        if (children.length) {
          curr.last_node_item = false;
          curr.expanded = true;
          return [...acc, curr, ...recurse(children, curr.depth, curr)];
        } else {
          curr.last_node_item = true;
        }

        return acc.concat(curr);
      }, []);
    };

    let fullTree = recurse(tree, 2, null, newExpandedFolder);

    if (treemode === TREE_MODE_RESOURCE && fullTree) {
      // search for resource_root or resource_default
      let partialTree = fullTree.filter(
        (ft) =>
          ft.folder_type !== "resource_root" &&
          ft.folder_type !== "resource_default"
      );
      if (partialTree.length < fullTree.length) {
        partialTree = partialTree.map((pt) =>
          pt.depth === 3 ? { ...pt, depth: 2 } : pt
        );
      }
      return partialTree;
    }

    if (isAppliedMode) {
      const isCopyOrMoveAction =
        appliedModeAction === "copy" || appliedModeAction === "move";
      fullTree = fullTree
        .filter((treeItem) => {
          if (!treeItem.folder_type_config) {
            return true;
          }
          return (
            getCurrentTheme(
              folder_types[selectedItemsFolderTypes[0]],
              folder_types
            ) ===
            getCurrentTheme(
              folder_types[treeItem.folder_type_config.name],
              folder_types
            )
          );
        })
        .map((treeItem) => {
          let showGray = true;
          let showChildAcceptableIcon = false;
          if (!treeItem.folder_type_config) {
            return {
              ...treeItem,
              showGray: true,
              showChildAcceptableIcon: true,
            };
          }
          if (
            treeItem.folder_type_config &&
            treeItem.folder_type_config.acceptable_folder_types &&
            treeItem.folder_type_config.acceptable_folder_types.length > 0
          ) {
            for (const selectedItemFolderType of selectedItemsFolderTypes) {
              if (
                treeItem.folder_type_config.name.includes("presentation") &&
                !isCopyOrMoveAction &&
                !treeItem.folder_type_config.acceptable_folder_types.includes(
                  selectedItemFolderType
                )
              ) {
                return {
                  ...treeItem,
                  showGray: true,
                  showChildAcceptableIcon: true,
                };
              } else if (
                treeItem.folder_type_config.name.includes("presentation") &&
                isCopyOrMoveAction
              ) {
                return {
                  ...treeItem,
                  showGray: true,
                  showChildAcceptableIcon: false,
                };
              } else {
                showGray = true;
              }
              if (
                treeItem.folder_type_config.acceptable_folder_types.includes(
                  selectedItemFolderType
                )
              ) {
                if (isCopyOrMoveAction) {
                  showGray = checkIfSomeParentIsPresentation(
                    treeItem,
                    fullTree
                  );
                } else {
                  showGray = true;
                }
              } else {
                if (
                  (isCopyOrMoveAction &&
                    !treeItem.folder_type_config.name.includes(
                      "presentation"
                    ) &&
                    !treeItem.folder_type_config.name.includes("display")) ||
                  treeItem.folder_type_config.name.includes("root")
                ) {
                  for (const acceptableFolderType of treeItem.folder_type_config
                    .acceptable_folder_types) {
                    showChildAcceptableIcon = checkIfChildrenAcceptFolder(
                      selectedItemFolderType,
                      folder_types[acceptableFolderType],
                      folder_types
                    );
                    if (showChildAcceptableIcon) {
                      break;
                    }
                  }
                } else {
                  showGray = true;
                }
              }
            }
          }
          return { ...treeItem, showGray, showChildAcceptableIcon };
        });
    }
    return fullTree;
  }
);
