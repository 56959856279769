import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import {
  getBaseFolder,
  getBaseFolderType
} from "../../../utils/commonDataUtils";
import {
  generateFontSizeClassesFromFields, getFieldValue
} from "../../../utils/generalUtils";
import RichTextRender from "../../standard/RichTextRender";
import Slide1911 from "../../standard/Slide1911";
import {
  SPR_LANDSCAPE_BODY_LINE_HEIGHT,
  SPR_LANDSCAPE_BODY_MARGIN_TOP,
  SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
  SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM,
  SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
  SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
  SPR_LANDSCAPE_VERTICAL_MARGIN
} from "./SpringfieldConstants";

const SpringfieldTemplate004 = (props: IDisplayComponentProps) => {
  const baseFolder = useMemo(() => {
    return getBaseFolder(props.data ?? {});
  }, [props.data]);

  const baseFolderType = useMemo(() => {
    return getBaseFolderType(props.data, get(baseFolder, "folder_type", {}));
  }, [baseFolder]);

  const color = useMemo(() => {
    return get(baseFolder, "fields.color_overlay", "transparent");
  }, [baseFolder]);

  const getStyles = useCallback(() => {
    if (baseFolder) {
      const folderType = props.data?.folder_types?.find((f) => f.name === baseFolder.folder_type);
      const styles = createUseStyles(generateFontSizeClassesFromFields(folderType, baseFolder.fields));
      return styles;
    }
    return () => {
      return { componentContainer: "" };
    };
  }, [baseFolder?.folder_type, props.data?.folder_types]);

  const useStyles = getStyles();
  const classes = useStyles();

  const headline = getFieldValue(baseFolder, 'headline', baseFolderType);
  const subhead = getFieldValue(baseFolder, 'subhead', baseFolderType);
  const bodyText = getFieldValue(baseFolder, 'body', baseFolderType);
  const quote = getFieldValue(baseFolder, 'quote', baseFolderType);
  const attribution = getFieldValue(baseFolder, 'attribution', baseFolderType);
  const mirrorContent = get(baseFolder, "fields.mirror_content", false);
  const showQuotes = get(baseFolder, "fields.show_quotes", true);

  return (
    <div
      style={{
        position: "absolute",
        width: props.containerWidth,
        height: props.containerHeight,
      }}
    >
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: 0,
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            componentSpecificData: {
              ...props.data?.componentSpecificData,
              useKeyCycler: false,
            },
          }}
          webview_signedurls={props.webview_signedurls}
          handleEndOfPlay={null}
        />
      </div>
      {/* Background Overlay */}
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: color,
          zIndex: 0,
        }}
      />
      <div
        style={{
          width: props.containerWidth,
          height: props.containerHeight,
          position: "relative",
          display: 'flex',
          flexDirection: !mirrorContent ? 'row' : 'row-reverse',
          alignItems: 'stretch',
        }}
      >
        <div style={{ width: "50%", height: "100%", float: "left" }}>
          <div
            style={{
              height: "100%",
              // padding: "8% 12%"
              paddingTop: props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
              paddingBottom:
                props.containerHeight * SPR_LANDSCAPE_VERTICAL_MARGIN,
              paddingLeft: props.containerWidth * 0.06,
              paddingRight: props.containerWidth * 0.06,
            }}
          >
            {/* Text */}
            <div
              style={{
                height: "100%",
                color: "#000000",
              }}
            >
              <div style={{ maxHeight: "100%", overflowY: "hidden" }}>
                {/* Headline */}
                <div
                  className={classes.componentContainer}
                  style={{
                    padding: 0,
                    lineHeight: SPR_LANDSCAPE_HEADING_LINE_HEIGHT,
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingBottom:
                      SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                      props.containerHeight,
                  }}
                >
                  <RichTextRender
                    htmlString={headline}
                    style={{
                      padding: 0
                    }}
                  />
                </div>
                {/* Sub Head */}
                {subhead && (
                  <div
                    className="springfield-template-004_subhead"
                    style={{
                      marginTop:
                        props.containerHeight *
                        SPR_LANDSCAPE_SUBHEAD_MARGIN_TOP,
                    }}
                  >
                    <div
                      className={classes.componentContainer}
                      style={{
                        padding: 0,
                        lineHeight: SPR_LANDSCAPE_SUBHEAD_LINE_HEIGHT,
                        overflowX: "hidden",
                        overflowY: "hidden",
                        paddingBottom:
                          SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                          props.containerHeight,
                      }}
                    >
                      <RichTextRender
                        htmlString={subhead}
                        style={{
                          padding: 0
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* Body Text */}
                <div
                  className="springfield-template-004_body-text"
                  style={{
                    marginTop:
                      props.containerHeight * SPR_LANDSCAPE_BODY_MARGIN_TOP,
                  }}
                >
                  <div
                    className={classes.componentContainer}
                    style={{
                      padding: 0,
                      lineHeight: SPR_LANDSCAPE_BODY_LINE_HEIGHT,
                      overflowX: "hidden",
                      overflowY: "hidden",
                      paddingBottom:
                        SPR_LANDSCAPE_RICH_TEXT_PADDING_BOTTOM *
                        props.containerHeight,
                    }}
                  >
                    <RichTextRender
                      htmlString={bodyText}
                      style={{
                        padding: 0
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "50%", height: "100%", float: "right" }}>
          <div
            style={{
              height: "100%",
              paddingTop: "5%",
              paddingRight: "12%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Text */}
            <div
              style={{
                height: "100%",
                color: "#000000",
                overflowY: "hidden",
                textAlign: "center",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  // top: "50%",
                  // msTransform: "translateY(-50%)",
                  // transform: "translateY(-50%)",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 0.166583375 * props.containerHeight,
                    margin: "auto",
                    display: showQuotes ? 'block' : 'none',
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      fontFamily: "Lora-Bold",
                      fontSize: props.containerHeight * 0.277777778,
                      top: -0.074962519 * props.containerHeight,
                      color: "#cdcdcd",
                    }}
                  >
                    “
                  </div>
                </div>

                <div
                  className={classes.componentContainer}
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingTop: props.containerHeight * 0.05,
                    paddingLeft: props.containerWidth * 0.06,
                    paddingRight: props.containerWidth * 0.06,
                    paddingBottom: 0.035 * props.containerHeight,
                  }}
                >
                  <RichTextRender
                    htmlString={quote}
                    style={{
                      padding: 0,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: 0.006658337 * 0.5625 * props.containerHeight,
                  }}
                >
                  <div
                    className={classes.componentContainer}
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                      paddingTop: props.containerHeight * 0.025,
                      paddingBottom: props.containerHeight * 0.035,
                      paddingLeft: props.containerWidth * 0.06,
                      paddingRight: props.containerWidth * 0.06,
                    }}
                  >
                    <RichTextRender
                      htmlString={attribution}
                      style={{
                        padding: 0
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpringfieldTemplate004;
