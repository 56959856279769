import { COMPONENT_NAME, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { fonts, FontVariants, FontsFamilies } from "../../../../fonts";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const textstyle_default = {
  color: "#000000",
  alignment: "left",
  font: fonts[FontsFamilies.ROBOTO][FontVariants.REGULAR],
  fontSize: 15
};

export const naples_root: IFolderType = {
  ...base_types.root,
  name: FOLDER_TYPE.naples_root,
  display_name: "Naples Root",
  acceptable_folder_types: [FOLDER_TYPE.naples_displays, FOLDER_TYPE.naples_presentations, FOLDER_TYPE.naples_content],
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.client
    },
    closed: {
      ...tree_icon_types.client
    }
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.None,
    type: PREVIEW_TYPE.COMPONENT
  },
  default_children: [
    {
      name: "Displays",
      folder_type: FOLDER_TYPE.naples_displays
    },
    {
      name: "Presentations",
      folder_type: FOLDER_TYPE.naples_presentations
    },
    {
      name: "Content",
      folder_type: FOLDER_TYPE.naples_content
    }
  ],
  hide_add_icon_in_tree: true
};
