import { COMPONENT_NAME, FOLDER_CATEGORY, FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import { FLEX_CLASSES, IMAGE_ALIGNMENT } from "../../../../enums/ui-enums";
import { wysiwyg } from "../standardized_fields";
import { FontVariants, FontsFamilies, fonts } from "../../../../fonts";

const fontOptions = [
  FontsFamilies.ROBOTO,
  FontsFamilies.ROBOTO_SLAB,
  FontsFamilies.META_PRO,
  FontsFamilies.SANS_SERIF,
  FontsFamilies.SERIF,
  FontsFamilies.WHITNEY,
  FontsFamilies.LATO
];

export const eugene_stories_menu: IFolderType = {
  folder_category: FOLDER_CATEGORY.STORIES,
  name: FOLDER_TYPE.eugene_stories_menu,
  display_name: "Stories Menu",
  acceptable_folder_types: [FOLDER_TYPE.eugene_stories],
  tabs: {
    general: {
      title: "General"
    },
    gallery: {
      title: "Gallery"
    }
  },
  field_groups: {
    general: {
      title: "General",
      default_expanded: true,
      order: 1,
      accordion: false,
      tab: "general"
    },
    gallery: {
      title: "Gallery",
      default_expanded: true,
      order: 2,
      accordion: false,
      tab: "gallery"
    },
    menu_title: {
      title: "Menu Title",
      default_expanded: false,
      order: 3,
      accordion: true,
      tab: "gallery"
    },
    horizontal_rule: {
      title: "Horizontal Rule",
      default_expanded: false,
      order: 4,
      accordion: true,
      tab: "gallery"
    },
    list: {
      title: "List",
      default_expanded: false,
      order: 5,
      accordion: true,
      tab: "gallery"
    },
    background_accordion: {
      title: "Background",
      default_expanded: false,
      order: 6,
      accordion: true,
      tab: "gallery"
    }
  },
  fields: {
    name: {
      type: "text",
      title: "Reference Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      show_char_count: false,
      field_group: "general"
    },
    menu_title: {
      ...wysiwyg,
      default: {
        type: "style",
        style: {
          font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
          size: "45em",
          color: "#FFFFFF"
        },
        ops: [],
        dark_mode: true
      },
      settings: {
        ...wysiwyg.settings,
        fonts: fontOptions,
        theme: "eugene"
      },
      title: "Menu Title",
      field_group: "menu_title",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: false
      }
    },
    background: {
      title: "Background",
      type: "file",
      slot_type: "dynamic",
      limit: 1,
      acceptable_contents: ["image/*"],
      image_presets: [],
      video_presets: [],
      aspect: 1.777777778,
      recommended_size: {
        width: 1920,
        height: 1080
      },
      container: {
        imgCover: IMAGE_ALIGNMENT.CONTAIN,
        flexClass: FLEX_CLASSES.FLEX_CENTER,
        aspect: 1.777777778
      },
      field_group: "background_accordion",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    color_overlay: {
      type: "color",
      title: "Color Overlay",
      default: "",
      field_group: "background_accordion",
      inheritable_settings: {
        inherit_on_create: true,
        pass_down_to_children: true
      }
    },
    horizontal_rule_color: {
      type: "color",
      title: "Color",
      default: "#FFFFFF",
      field_group: "horizontal_rule"
    },
    horizontal_rule_weight: {
      type: "number",
      title: "Line Weight",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0.01, max: 4 }] },
      tooltip: "Unit is % of container height",
      step: "0.01",
      default: 2,
      field_group: "horizontal_rule"
    },
    list_title: {
      type: "textstyle",
      title: "List Title",
      show_char_count: false,
      field_group: "list",
      default: {
        color: "#2699FB",
        alignment: "left",
        font: fonts[FontsFamilies.ROBOTO_SLAB][FontVariants.REGULAR],
        fontSize: 36,
        lineHeight: 1
      },
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO, FontsFamilies.ROBOTO_SLAB]
        },
        alignment: {
          enabled: true,
          items: [
            { title: "Left", value: "left" },
            { title: "Center", value: "center" },
            { title: "Right", value: "right" }
          ]
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        },
        bold: {
          enabled: true
        },
        underline: {
          enabled: true
        },
        italic: {
          enabled: true
        }
      }
    },
    list_description: {
      type: "textstyle",
      title: "List Description",
      show_char_count: false,
      field_group: "list",
      default: {
        color: "#1C1C1C",
        alignment: "left",
        font: fonts[FontsFamilies.LATO][FontVariants.REGULAR],
        fontSize: 22,
        lineHeight: 1.5
      },
      options: {
        font: {
          enabled: true,
          items: [FontsFamilies.ROBOTO, FontsFamilies.SANS_SERIF, FontsFamilies.LATO]
        },
        alignment: {
          enabled: true,
          items: [
            { title: "Left", value: "left" },
            { title: "Center", value: "center" },
            { title: "Right", value: "right" }
          ]
        },
        fontSize: {
          enabled: true,
          items: [
            { title: "10em", value: 10 },
            { title: "15em", value: 15 },
            { title: "20em", value: 20 },
            { title: "25em", value: 25 },
            { title: "30em", value: 30 },
            { title: "35em", value: 35 },
            { title: "40em", value: 40 }
          ]
        },
        lineHeight: {
          enabled: true,
          items: [
            { title: ".5", value: 0.5 },
            { title: ".75", value: 0.75 },
            { title: "1", value: 1 },
            { title: "1.25", value: 1.25 },
            { title: "1.5", value: 1.5 },
            { title: "1.75", value: 1.75 },
            { title: "2", value: 2 }
          ]
        },
        color: {
          enabled: true
        },
        bold: {
          enabled: true
        },
        underline: {
          enabled: true
        },
        italic: {
          enabled: true
        }
      }
    },
    list_spacing: {
      type: "number",
      title: "Space between title and description",
      validation: { rules: [{ name: "isRequired" }, { name: "isNumber", min: 0, max: 20 }] },
      tooltip: "Unit is % of container height",
      step: 1,
      default: 7,
      field_group: "list"
    }
  },
  tree_dropped_into_action: [{ action: "move", options: {} }],
  preview: {
    react_url: "theme/eugene",
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.EugeneStoriesMenu,
    type: PREVIEW_TYPE.COMPONENT
  },
  tab_previews: [
    {
      tab_key: "general",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneStoriesMenu,
      type: PREVIEW_TYPE.COMPONENT
    },
    {
      tab_key: "gallery",
      aspect_ratio: 1.777777778,
      component_name: COMPONENT_NAME.EugeneStoriesMenuGallery,
      type: PREVIEW_TYPE.COMPONENT
    }
  ],
  client: {
    component_name: COMPONENT_NAME.EugeneStoriesMenu
  }
};
