import React from "react";
import RichTextRender from "../../standard/RichTextRender";

interface IMarinDonorListHeaderProps {
  category?: string;
  classes: any;
  containerHeight: number;
  containerWidth: number;
  fontSize: number;
  header?: string;
  linesByScreens?: any;
  setTopElements?: (divElement: HTMLDivElement | null) => void;
  showUpperRule: boolean;
  subhead?: string;
}

const MarinDonorListHeader: React.FC<IMarinDonorListHeaderProps> = (props) => {
  return (
    <div
      style={{
        maxHeight: props.containerHeight,
        width: props.containerWidth,
        zIndex: 10,
      }}
      ref={(divElement) => {
        if (props.setTopElements) {
          props.setTopElements(divElement);
        }
      }}
    >
      {(props.header || props.subhead || props.category) && (
        <div
          style={{
            paddingLeft: "5em",
            paddingRight: "5em",
            paddingTop: "2em",
            width: props.containerWidth / 4,
            display: "flex",
            flexDirection: "column"
          }}
          className={props.classes.componentContainer}
        >
          {props.header && (
            <RichTextRender
              htmlString={props.header}
              style={{
                padding: 0
              }}
            />
          )}
          {props.subhead && (
            <RichTextRender
              htmlString={props.subhead}
              style={{
                marginTop: "1em",
                padding: 0
              }}
            />
          )}
          {props.category && (
            <RichTextRender
              htmlString={props.category}
              style={{
                marginTop: "1.5em",
                padding: 0
              }}
            />
          )}
        </div>
      )}
      {props.showUpperRule && (
        <div
          style={{
            display: "flex",
            paddingLeft: "5em"
          }}
        >
          {props.linesByScreens}
        </div>
      )}
    </div>
  );
};

export default MarinDonorListHeader;
