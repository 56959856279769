import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const los_angeles_donor: IFolderType = {
  ...base_types.donor,
  name: FOLDER_TYPE.los_angeles_donor,
  display_name: "Donor",
  fields: {
    name: {
      type: "textarea",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: "",
      read_only: true,
      settings: {
        minRows: 2,
        maxRows: 6,
        crossButton: true
      }
    },
    sorting_order: {
      type: "text",
      title: "Sorting Order",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: ""
    }
  },
  csv_options: {
    download: false,
    upload: false,
    include_in_csv: true,
    fields: ["name", "sorting_order"]
  },
  preview: {
    type: PREVIEW_TYPE.NONE
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.donor_bios
    },
    closed: {
      ...tree_icon_types.donor_bios
    }
  }
};
