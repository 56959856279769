import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import TangentQuote from "./TangentQuote";
import { useFolder } from "../../../hooks/useFolder";
import { useScale } from "../../../hooks/useScale";
import styles from "./styles";
import Slide1911 from "../../standard/Slide1911";
import { get } from "lodash";
import TangentQrWidget from "./TangentQrWidget";

const useStyles = createUseStyles(styles);

const TangentMediaWithQuote = (props) => {
  const { getBaseFolder, getBaseFolderFolderType, getBaseFolderFieldValue } =
    useFolder(props.data.base_folder);
  const baseFolder = getBaseFolder();
  const baseFolderType = getBaseFolderFolderType();

  const { standardSizing, getUnitBasedOnHeight } = useScale(
    props.data.base_folder
  );

  const showPlaceholder = useMemo(() => {
    return get(props.data?.persistent_settings.data, "show_placeholder", false);
  }, [props.data]);

  const mirrorContent = getBaseFolderFieldValue("mirror_content", false);
  const imageFieldType = getBaseFolderFieldValue("media", null);

  const backgroundColor = getBaseFolderFieldValue(
    "background_color",
    "transparent"
  );
  const quoteBackgroundColor = getBaseFolderFieldValue(
    "quote_background_color",
    "transparent"
  );
  const backgroundColorOverlay = getBaseFolderFieldValue(
    "background_color_overlay",
    "transparent"
  );

  const classes = useStyles({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    backgroundColor,
    backgroundColorOverlay,
    mirrorContent,
    standardSizing,
    getUnitBasedOnHeight,
    quoteBackgroundColor,
  });

  return (
    <>
      <div className={classes.mediaWithQuote}>
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background_image"],
            style: {
              backgroundPosition: "inherit",
            }
          }}
          webview_signedurls={props.webview_signedurls}
        />
        {/* Background Overlay */}
        <div className={classes.storySingleImageOverlay} />
        <div className={classes.sheets}>
          <div className="sheet1">
            {/* Images */}
            <div className="imageWrapper">
              <Slide1911
                aws={props.aws}
                aws_bucket={props.aws_bucket}
                mode={props.mode}
                containerWidth={props.containerWidth}
                containerHeight={props.containerHeight / 2}
                data={{
                  ...props.data,
                  slotNames: ["media"],
                  positionRelative: "relative",
                  placeholder: "image",
                  fieldType: imageFieldType,
                  showPlaceholder: showPlaceholder,
                  componentSpecificData: {
                    customStyle: {
                      height: "100%",
                      width: "100%",
                    },
                    customStyleImage: {
                      backgroundPosition: mirrorContent ? "top center" : "bottom center" ,
                    },
                  },
                }}
                webview_signedurls={props.webview_signedurls}
              />
            </div>
          </div>
          <TangentQrWidget {...props} {...baseFolder.fields} />
          <div className="sheet2">
            <div className="quoteWrapper">
              <TangentQuote
                {...props}
                quote={baseFolder}
                baseFolderType={baseFolderType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TangentMediaWithQuote;
