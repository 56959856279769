import React from "react";
import RichTextRender from "../../standard/RichTextRender";

interface IMarinDonorListFooterProps {
  classes: any;
  containerHeight: number;
  containerWidth: number;
  donorsScreenIndex: number;
  donorsScreens: any;
  fontSize: number;
  footer: string;
  linesByScreens?: any;
  setFooterEl?: (divElement: HTMLDivElement | null) => void;
  showLowerRule: boolean;
}

const MarinDonorListFooter: React.FC<IMarinDonorListFooterProps> = (props) => {
  return (
    <div
      ref={(htmlEl) => {
        if (props.setFooterEl) {
          props.setFooterEl(htmlEl);
        }
      }}
      style={{
        maxHeight: props.containerHeight,
        width: props.containerWidth,
        zIndex: 10
      }}
      className={props.classes.componentContainer}
    >
      {(props.footer || props.showLowerRule) && (
        <>
          <div
            className={props.classes.componentContainer}
            style={{
              paddingLeft: "5em",
              paddingRight: "5em",
              paddingBottom: "2em",
              display: "flex",
              width: props.donorsScreens.length ? (props.containerWidth / 4) * props.donorsScreens[props.donorsScreenIndex].length : props.containerWidth
            }}
          >
            {props.showLowerRule && (
              <div
                style={{
                  display: "flex"
                }}
              >
                {props.linesByScreens}
              </div>
            )}
          </div>
          {props.footer && (
            <div
              className={props.classes.componentContainer}
              style={{
                paddingLeft: "5em",
                paddingRight: "5em",
                paddingBottom: "2em",
                width: props.donorsScreens.length ? (props.containerWidth / 4) * props.donorsScreens[props.donorsScreenIndex].length : props.containerWidth
              }}
            >
              <RichTextRender
                htmlString={props.footer}
                style={{
                  marginTop: "1em"
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MarinDonorListFooter;
