import React, {Component} from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { publishFolder } from '../../actions/folderActions';
import AlertNotification from '../layout/AlertNotification';

class PublishFolderModal extends Component {
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (this.props.visible) {
        this.onSave()
      }
    }
  }

  onPublishSuccess = () => {
    AlertNotification('success', 'Success', 'Folder was successfuly published')
  }

  onPublishFailure = (detail) => {
    AlertNotification('error', 'Error', detail || 'An error happened. Please try again.')
  }

  onSave = () => {
    if (this.props.publishingFolderType && this.props.publishingFolderType.check_warnings_on_publish) {
      this.props.handlePublishWarnings()
    }
    this.props.publishFolder(this.props.folder_uuid, this.props.folderFolderPath, this.onPublishSuccess, this.onPublishFailure)
    this.props.onOkay();
  }

  // logValues = (values) => {
  //   console.log("logging the values", values);
  // }

  onCancel = () => {
    this.props.onCancel();
  }

  render() {
    return(
      <Modal visible={this.props.visible}
        onCancel={this.onCancel}
        onOk={this.onSave}
        destroyOnClose="true">
          <span>Are you sure you want to publish the changes?</span>
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  publishFolder: (folder_id, folderFolderPath, onSuccess, onFail) => {
    dispatch(publishFolder(folder_id, folderFolderPath, onSuccess, onFail))
  }
})

export default connect(null, mapDispatchToProps)(PublishFolderModal)
