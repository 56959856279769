import { FOLDER_TYPE, PREVIEW_TYPE } from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";

export const boston_donor_sea_categories: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.boston_donor_sea_categories,
  display_name: "Donor Sea Categories",
  acceptable_folder_types: [FOLDER_TYPE.boston_donor_sea_category, FOLDER_TYPE.boston_donor_list],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  prevent_add_children: true,
  hide_nested_children: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  csv_options: {
    download: true,
    upload: true,
    include_in_csv: false,
    fields: ["name"]
  }
};
