import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { name } from "../standardized_fields";
import { tree_icon_types } from "../tree_icon_types";
import { transitions } from "./phoenix_settings";

export const phoenix_presentation_3x3: IFolderType = {
  ...base_types.stories,
  name: FOLDER_TYPE.phoenix_presentation_3x3,
  display_name: "Presentation 3x3",
  acceptable_folder_types: [
    FOLDER_TYPE.phoenix_content_stories,
    FOLDER_TYPE.phoenix_content_checkerboards,
    FOLDER_TYPE.phoenix_checkerboard,
    FOLDER_TYPE.phoenix_story,
    FOLDER_TYPE.phoenix_donor_sea,
    FOLDER_TYPE.phoenix_donor_list,
    FOLDER_TYPE.phoenix_donor_list_group,
    FOLDER_TYPE.phoenix_message,
    FOLDER_TYPE.phoenix_fullscreen,
  ],
  tree_dropped_into_action: [{ action: "instance", options: {} }],
  fields: {
    name: name,
    transition: {
      type: "select",
      title: "Transition",
      validation: { rules: [{ name: "isRequired" }] },
      default: "transition-fade1s",
      options: [...transitions],
    },
  },
  preview: {
    aspect_ratio: 1.777777778,
    component_name: COMPONENT_NAME.PhoenixContainer,
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    hide_border: true,
    screen_sets: [
      { name: "unique", orientation: "landscape", horizontal: 3, vertical: 3 },
    ],
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.presentation,
    },
    closed: {
      ...tree_icon_types.presentation,
    },
  },
  hide_add_icon_in_tree: true,
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  prevent_add_children: true,
  hide_nested_children: true,
  isPresentation: true,
};
