import {
  COMPONENT_NAME,
  FOLDER_TYPE,
  PREVIEW_TYPE,
  PREVIEW_VIEW_PERCENTAGE_ORIENTATION
} from "../../../../enums/folder-type-enums";
import { IFolderType } from "../../../../interfaces/folder-type-interfaces";
import base_types from "../base_types";
import { tree_icon_types } from "../tree_icon_types";

export const berkeley_display: IFolderType = {
  ...base_types.grouping_folder,
  name: FOLDER_TYPE.berkeley_display,
  display_name: "Display",
  prevent_add_children: true,
  acceptable_folder_types: [FOLDER_TYPE.berkeley_presentation_3x2],
  tree_dropped_into_action: [{ action: "instance_replace", options: {} }],
  prevent_dragging_out: true,
  prevent_last_instance_in_folder: true,
  hide_nested_children: true,
  fields: {
    name: {
      type: "text",
      title: "Name",
      validation: { rules: [{ name: "isRequired" }, { name: "isString", min: 1, max: 75 }] },
      default: null,
      show_char_count: false,
      sys_admin_only: true
    }
  },
  preview: {
    aspect_ratio: 2.666666666666667,
    component_name: COMPONENT_NAME.BerkeleyContainer,
    screen_sets: [{ name: "unique", orientation: "landscape", horizontal: 3, vertical: 2 }],
    type: PREVIEW_TYPE.COMPONENT,
    zoomable: true,
    view_percentage: {
      orientation: PREVIEW_VIEW_PERCENTAGE_ORIENTATION.WIDTH,
      value: 25
    }
  },
  tree_icon_types: {
    open: {
      ...tree_icon_types.display
    },
    closed: {
      ...tree_icon_types.display
    }
  }
};
