
export function dateFormatted(date, format) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  switch(format){
    case 'yyyymmdd':
        return [year, month, day].join('-');
    case 'mmddyyyy':
    default:
        return [month, day, year].join('-');
  }

}
