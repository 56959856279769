import _ from 'lodash';
import {SORTING_DIRECTION} from '../../shared-global/enums/folder-type-enums';
import {SORTING_METHOD} from '../../shared-global/enums/folder-type-enums'
import {IFolder, IFolderFlex} from '../../shared-global/interfaces/models/folder.interface';
import {IFolderFolder} from '../../shared-global/interfaces/models/folder__folder.interface';

export const arrayFromKeyedObject = (obj) => {

    let returnArray = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            returnArray.push(obj[key]);
        }
    }
    return returnArray;
}

export const arrayFromKeyedObjectPreserveKeys = (obj) => {

    let returnArray = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            obj[key].key = key
            returnArray.push(obj[key]);
        }
    }
    return returnArray;
}

export const findPropertyKeyed = (object, property, val) => {
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key][property] === val) {
                return object[key];
            }
        }
    }
    return null;
}

export const arrayFromKeys = (obj, idArray) => {
    if (!obj || !idArray) {
        return [];
    }
    return _.map(idArray, r => {
        return obj[r]
    })
}

export const keyedObjectArrayMatch = (obj, matchKey) => {
    let returnObject = null;
    Object.keys(obj).map(function (key, index) {
        if (matchKey === key) {
            returnObject = obj[key];
            return returnObject;
        }
        return null;
    }, this)
    return returnObject;
}

export function arrayMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

export function commaSeperateArrayByField(array, field) {
    let str = "";
    if (array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i][field]) {
                if (i + 1 === array.length) {
                    str += array[i][field];
                } else {
                    str += array[i][field] + ", ";
                }
            }
        }
    }
    return str;
}

export function arraysIntersect(arr1, arr2) {
    return arr1.some(r => arr2.includes(r))
}

export function getFirstFolderFolderJoinOfAFolder(folder_id, folder__folders) {
    const sortedFolderFolders = folder__folders
    .filter(folder__folder => folder__folder.child_folder === folder_id)
    .sort((a, b) => a.rank - b.rank);
    if (sortedFolderFolders.length > 0) {
            return sortedFolderFolders[0];
    }
    return null;
}

export function getChildrenFolderAndFolderFoldersDeep(acc, folder__folders, folders, resources, resource__folders, folder_types, persistent_settings, system_settings, parent_folder, meta = null) {
    if (parent_folder) {
        if (meta) {
            if (meta.folder__folder_id) {
                acc.folder__folder_id = meta.folder__folder_id;
                const found_folder__folder = folder__folders.find(ff => ff.id === meta.folder__folder_id);
                if (found_folder__folder) {
                    acc.folder__folder = found_folder__folder;
                    const folder = folders.find(f => f.id === found_folder__folder.parent_folder);
                    if (!acc.parents) {
                        acc.parents = [folder];
                    } else {
                        acc.parents.push(folder);
                    }
                }
            }
        }
        if (!acc.folders) {
            acc.folders = []
        }
        if (!acc.folder__folders) {
            acc.folder__folders = []
        }
        acc.folders.push(parent_folder);
        acc.resources = resources;
        acc.resource__folders = resource__folders;
        acc.folder_types = folder_types;
        acc.persistent_settings = persistent_settings;
        acc.system_settings = system_settings;

        const children = folder__folders.filter(x => x.parent_folder === parent_folder.id)
        acc.folder__folders = acc.folder__folders.concat(children);
        if (children.length) {
            children.forEach(child => getChildrenFolderAndFolderFoldersDeep(
                acc, folder__folders,
                folders,
                resources,
                resource__folders,
                folder_types,
                persistent_settings,
                system_settings,
                folders.find(x => x.id === child.child_folder)))
        }
    }
    if (parent_folder && parent_folder.id) {
        acc.base_folder = parent_folder.id
    }
    return acc;
}

export function sortedDonorsFromFolder(
    folderFolders: IFolderFolder[],
    folders: any,
    sorting_method: SORTING_METHOD,
    parentFolder: IFolder,
    direction: SORTING_DIRECTION.asc
) {
    let donor_folder__folders = folderFolders.filter(
        (ff) => ff.parent_folder === parentFolder.id
    );

    if (sorting_method === SORTING_METHOD.name) {
        const donor_folder_ids = donor_folder__folders.map(
            (dff) => dff.child_folder
        );
        let donor_folders = folders.filter((f) => donor_folder_ids.includes(f.id));
        return _.orderBy(donor_folders, [(donor) => donor.name.toLowerCase()], ["asc"]);
    } else if (sorting_method === SORTING_METHOD.sorting_order) {
        const donor_folder_ids = donor_folder__folders.map(
            (dff) => dff.child_folder
        );
        let donor_folders = folders.filter((f) => donor_folder_ids.includes(f.id));
        return _.orderBy(
            donor_folders,
            [(donor) => _.get(donor, "fields.sorting_order", "zzz").toLowerCase()],
            ["asc"]
        );
    } else if (SORTING_METHOD.rank) {
        const sorted_donor_folders = [];
        donor_folder__folders = _.orderBy(donor_folder__folders, "rank", direction);
        donor_folder__folders.forEach((dff: IFolderFolder) => {
            const folder = folders.find((f) =>
                f.id === dff.child_folder
            );
            if (folder) {
                folder.rank = dff.rank;
                sorted_donor_folders.push(folder);
            }
        });
        return sorted_donor_folders;
    }
}