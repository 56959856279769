
// donorlistmenuid
// donorlistmenufolderid
//menufolderid
// storiesmenufolderid
// storiesfolderid


// theme/eugene/stories_menu/:storiesmenufolderid/stories/:storiesfolderid/story/:storyfolderid`,

import get from "lodash/get";
import { FOLDER_TYPE } from "../../../../shared-global/enums/folder-type-enums";
import {DEFAULT_SCROLLBAR_COLOR} from "./EugeneConstants";
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html-cb";
import { IDisplayComponentPropsData } from "../../../interfaces/display-component-props.interface";
import { IFolder } from "../../../../shared-global/interfaces/models/folder.interface";

export const eugeneUrlFromParams = (params: any) => {
    let newUrl = 'dashboard/theme/eugene';

    if (params.menufolderid){
        newUrl += '/menu/' + params.menufolderid;
    }
    // if (params.) TODO implement for donor lists
    if (params.storiesmenufolderid){
        newUrl += `/stories_menu/${params.storiesmenufolderid}`
    }
    if (params.storiesfolderid){
        newUrl += `/stories/${params.storiesfolderid}`
    }
    if (params.storyfolderid){
        newUrl += `/stories/${params.storyfolderid}`
    }
    return '/' + newUrl;
}

export const getColorFromRichText = (ops) => {
    return get(ops, '[0][attributes][color]', DEFAULT_SCROLLBAR_COLOR)
}

export const getSlotNameOrDefault = (card, slotCard: string, baseFolder: any) => {
    // const baseFolder = getBaseFolder({...data, base_folder: card.base_folder})
    let slot;
    switch (baseFolder.folder_type) {
        case FOLDER_TYPE.eugene_template_001:
            if (slotCard) {
                slot = get(card, slotCard, 'background')
                return [slot, 'background']
            } else {
                slot = 'background';
                return [slot]
            }
        case FOLDER_TYPE.eugene_template_002:
        case FOLDER_TYPE.eugene_template_003:
            if (slotCard) {
                slot = get(card, slotCard, 'image_1')
                const allImagesSlot = ['background', slot, 'image_2', 'image_1']
                return allImagesSlot
            } else {
                slot = 'image_1';
                return [slot]
            }
        case FOLDER_TYPE.eugene_template_004:
        case FOLDER_TYPE.eugene_template_005:
            return [];

    }
}

export const getBackgroundColorOrDefault = (card, cardBackground, defaultOption) => {
    let color = get(card, cardBackground, null);

    if (!color) {
        color = get(card, defaultOption, 'white');
    }

    return color;
}

export const getCardOptionOrDefault = (card, cardOption, defaultOption) => {
    let cardText = get(card, cardOption, null);
    if (checkRichTextEmpty(cardText)){
        cardText = get(card, defaultOption, '');
    }
    return cardText;
}

const isTextRichEmpty = (text) => {
    if (text && text !== '<p><br/></p>') {
        return false;
    } else {
        return true;
    }
}

const checkRichTextEmpty = richtext => {
    const ops = get(richtext, "ops", []);
    const convertor = new QuillDeltaToHtmlConverter( ops, { multiLineParagraph: false });
    let html = convertor ? convertor.convert() : '';
    return isTextRichEmpty(html)
}

export const buildUrl = (data: IDisplayComponentPropsData, folderType: FOLDER_TYPE, folderId: number) => {

    let menuFolderId;
    let url = `${data.basePath}`;
    let parentFolderId: number, parentFolder: IFolder;
    switch (folderType) {
        case FOLDER_TYPE.eugene_donor_list_menu:
            url += `/donor_list_menu/${folderId}`
            break;
        case FOLDER_TYPE.eugene_category_with_giving_level:
            menuFolderId = data.folder__folders.find(el => el.child_folder === folderId).parent_folder
            // have to find the menu
            if (menuFolderId) {
                url += `/donor_list_menu/${menuFolderId}/donor_list_with_category/${folderId}`
            } else {
                url += `/donor_list_with_category/${folderId}`
            }
            break;
        case FOLDER_TYPE.eugene_category_without_giving_level:
            parentFolderId = data.folder__folders.find(el => el.child_folder === folderId).parent_folder
            parentFolder = data.folders.find(el => el.id === parentFolderId);
            if (parentFolder.folder_type === FOLDER_TYPE.eugene_category_with_giving_level) {
                // get the menu level one higher
                menuFolderId = data.folder__folders.find(el => el.child_folder === parentFolderId).parent_folder
                url += `/donor_list_menu/${menuFolderId}/donor_list_with_category/${parentFolderId}/donor_list_without_category/${folderId}`
            }
            else if (parentFolder.folder_type === FOLDER_TYPE.eugene_donor_list_menu) {
                // TODO we should rename donor_list_with_category to donor_list_top_level
                menuFolderId = parentFolder.id
                url += `/donor_list_menu/${menuFolderId}/donor_list_with_category/${folderId}`
            }
            break;
        case FOLDER_TYPE.eugene_donor_lists:
            url += `/donor_lists_overview/${folderId}`
            break;
        case FOLDER_TYPE.eugene_donor_list:
            url += `/donor_list/${folderId}`
            break;
        case FOLDER_TYPE.eugene_stories:
            url += `/stories/${folderId}`
            break;
        case FOLDER_TYPE.eugene_stories_menu:
            url += `/stories_menu/${folderId}`
        case FOLDER_TYPE.eugene_template_001:
        case FOLDER_TYPE.eugene_template_002:
        case FOLDER_TYPE.eugene_template_003:
        case FOLDER_TYPE.eugene_template_004:
        case FOLDER_TYPE.eugene_template_005:
            url += `/story/${folderId}`
            break;
        case FOLDER_TYPE.eugene_donor:
            // find parent folder
            parentFolderId = data.folder__folders.find(el => el.child_folder === folderId).parent_folder
            parentFolder = data.folders.find(el => el.id === parentFolderId);
            url = buildUrl(data, parentFolder.folder_type, parentFolder.id)
            break;
    }
    return url;
}