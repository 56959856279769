import React, { CSSProperties } from "react";

export type CheckerboardWordProps = {
  index: number;
  style: CSSProperties;
  words: string[];
};

export const CheckerboardWord = (props: CheckerboardWordProps) => {
  return <span style={props.style}>{props.words[props.index]}</span>;
};
