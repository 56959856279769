import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class ScrollItem extends Component {


  render() {

    let arrow = "arrow-up"
    if (this.props.scrollType === "down") {
      arrow = "arrow-down"
    }

    return (
      <div style={{ padding: 10, backgroundColor: "#90B4D8" }}>
        <div className="rcorners1" onMouseDown={() => { console.log("down") }} onMouseUp={() => { console.log("up") }} style={{ ...this.props.style, borderStyle: 'dotted', textAlign: 'center', color: 'white' }}>
          <FontAwesomeIcon className="fa-lg" icon={['far', arrow]} />
        </div>
      </div>
    );
  }
}

export default ScrollItem;
