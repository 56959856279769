
import React, { Component } from 'react';
import '../../css/grids.css';
import '../../css/grids-responsive.css';
import 'antd/dist/antd.less';
import '../../css/index.css';
import '../../shared/style/css/index.css'

class MainLayout extends Component {

  render() {

    return (
      <React.Fragment>
        <div className="main">
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default MainLayout;

