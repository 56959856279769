import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IDisplayComponentProps } from "../../../interfaces/display-component-props.interface";
import { getBaseFolder } from "../../../utils/commonDataUtils";
import ScrollableFixContainer2103 from "../../standard/ScrollableFixContainer2103";
import Slide1911 from "../../standard/Slide1911";
import { DEFAULT_SCROLLBAR_COLOR } from "./EugeneConstants";
import StoriesCards2031 from "./EugeneStoriesCards";
import EugeneTemplateCarousel from "./EugeneTemplateCarousel";

const EugeneStories = (props: IDisplayComponentProps) => {
  const [showPlaceholder, setShowPlaceholder] = useState(props.data?.persistent_settings?.data?.show_placeholder || false);
  const [baseFolder, setBaseFolder] = useState(null);

  const [storyFolderId, setStoryFolderId] = useState(null);
  const [storiesFolderId, setStoriesFolderId] = useState(null);

  const [storyCards, setStoryCards] = useState([]);
  const [sizeCard, setSizeCard] = useState(null);
  // const [showCarousel, setShowCarousel] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(null);

  const params =
    useParams<{ storiesfolderid; storymenufolderid; storyfolderid }>();

  useEffect(() => {
    let myStoryFolderId = params.storyfolderid;
    let myStoriesFolderId = params.storiesfolderid;

    setStoryFolderId(Number(myStoryFolderId));
    setStoriesFolderId(Number(myStoriesFolderId));

    // setShowCarousel(!!myStoryFolderId);
  }, [params]);

  useEffect(() => {
    const myStoryCards = getStoriesCards();
    setStoryCards(myStoryCards);

    const sizeCard = get(baseFolder, "fields.size_options", "large");
    setSizeCard(sizeCard);

    const color = get(baseFolder, "fields.color_overlay", "transparent");
    setBackgroundColor(color);
  }, [baseFolder]);

  useEffect(() => {
    if (storiesFolderId && props.data) {
      const myBaseFolder = props.data.folders.find(
        (x) => x.id === storiesFolderId
      );
      if (myBaseFolder) {
        setBaseFolder(myBaseFolder);
        return;
      }
    }
    if (!storyFolderId && props.data && props.data.base_folder) {
      const myBaseFolder = getBaseFolder(props.data);
      setBaseFolder(myBaseFolder);
    }
  }, [storiesFolderId, props.data]);

  const findFolder = (folderId) => {
    return props.data.folders.find((f) => f.id === folderId);
  };

  const getStoriesCards = () => {
    let storiesCards = [];

    if (!baseFolder) {
      return storiesCards;
    }

    const storiesCardData = props.data.folder__folders.filter(
      (ff) => ff.parent_folder === Number(baseFolder.id)
    );
    //const storiesFolderFolder = props.data.folder__folders.find(ff => storiesFolderFolderId == ff.id)
    if (storiesCardData.length) {
      storiesCards = storiesCardData
        .map((fff) => {
          const found = findFolder(fff.child_folder);
          const headline = get(found, "fields.headline", "");
          const overrideHeadlineSmall = get(
            found,
            "fields.override_headline_small",
            ""
          );
          const overrideHeadlineMedium = get(
            found,
            "fields.override_headline_medium",
            ""
          );
          const overrideHeadlineLarge = get(
            found,
            "fields.override_headline_large",
            ""
          );
          const subhead = get(found, "fields.subhead", "");
          const overrideSubheadSmall = get(
            found,
            "fields.override_subhead_small",
            ""
          );
          const overrideSubheadMedium = get(
            found,
            "fields.override_subhead_medium",
            ""
          );
          const overrideSubheadLarge = get(
            found,
            "fields.override_subhead_large",
            ""
          );
          const body = get(found, "fields.body", "");
          const overrideBodySmall = get(
            found,
            "fields.override_body_small",
            ""
          );
          const overrideBodyMedium = get(
            found,
            "fields.override_body_medium",
            ""
          );
          const overrideBodyLarge = get(
            found,
            "fields.override_body_large",
            ""
          );
          const overrideThumbnailImageSmall = "override_thumbnail_image_small";
          const overrideThumbnailImageMedium =
            "override_thumbnail_image_medium";
          const overrideThumbnailImageLarge = "override_thumbnail_image_large";
          const overrideBackgroundSmall = get(
            found,
            "fields.override_background_small",
            ""
          );
          const overrideBackgroundMedium = get(
            found,
            "fields.override_background_medium",
            ""
          );
          const overrideBackgroundLarge = get(
            found,
            "fields.override_background_large",
            ""
          );
          const baseFolder = get(found, "id", null);
          const storyFolderFolderId = get(fff, "id", null);
          return {
            ...found,
            storyFolderFolderId: storyFolderFolderId,
            base_folder: baseFolder,
            headline,
            overrideHeadlineSmall,
            overrideHeadlineMedium,
            overrideHeadlineLarge,
            subhead,
            overrideSubheadSmall,
            overrideSubheadMedium,
            overrideSubheadLarge,
            body,
            overrideBodySmall,
            overrideBodyMedium,
            overrideBodyLarge,
            overrideThumbnailImageSmall,
            overrideThumbnailImageMedium,
            overrideThumbnailImageLarge,
            overrideBackgroundSmall,
            overrideBackgroundMedium,
            overrideBackgroundLarge,
            rank: fff.rank
          };
        })
        .sort((a, b) => a.rank - b.rank);
    }
    return storiesCards;
  };

  const rightContainerWidth = props.containerWidth * 0.6;
  return (
    <div className="eugene-stories">
      {/* Background Image */}
      <div
        className="eugene-sub-stories_background"
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          zIndex: -2,
          top: 0,
          left: props.data.comesFromWrapper ? 0 : -props.containerWidth * 0.2899
        }}
      >
        <Slide1911
          aws={props.aws}
          aws_bucket={props.aws_bucket}
          mode={props.mode}
          containerWidth={props.containerWidth}
          containerHeight={props.containerHeight}
          data={{
            ...props.data,
            slotNames: ["background"],
            base_folder: get(baseFolder, "id", null),
            backgroundSize: "cover"
          }}
          webview_signedurls={props.webview_signedurls}
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: props.containerWidth,
          height: props.containerHeight,
          backgroundColor: backgroundColor,
          zIndex: -1,
          top: 0,
          left: props.data.comesFromWrapper ? 0 : -props.containerWidth * 0.2899
        }}
      />
      <div
        className="eugene-stories_container"
        style={{
          display: "flex",
          position: "relative",
          flex: 1,
          height: props.containerHeight,
          paddingTop: props.containerHeight * 0.03,
          paddingBottom: props.containerHeight * 0.03,
          paddingLeft: rightContainerWidth * 0.04,
          paddingRight: rightContainerWidth * 0.04
        }}
      >
        <div
          style={{
            height: "100%",
            width: rightContainerWidth
          }}
        >
          <div
            className="eugene-stories_title"
            style={{
              paddingTop: props.containerHeight * 0.03,
              paddingBottom: props.containerHeight * 0.03,
              color: "#FFFFFF",
              fontSize: props.containerHeight * 0.05,
              fontWeight: "bold"
            }}
          >
            {get(baseFolder, "name", "")}
          </div>
          <div
            className="eugene-stories_description"
            style={{
              paddingTop: props.containerHeight * 0.01,
              paddingBottom: props.containerHeight * 0.07,
              color: "#FFFFFF",
              fontSize: props.containerHeight * 0.032
            }}
          >
            {get(baseFolder, "fields[description]", "")}
          </div>
          <div className="eugene-stories_cards">
            <ScrollableFixContainer2103
              containerHeight={props.containerHeight * 0.54806988807}
              containerWidth={rightContainerWidth}
              barColor={DEFAULT_SCROLLBAR_COLOR}
              fadePaddingBottomPercentage={30}
            >
              <div>
                <StoriesCards2031
                  containerHeight={props.containerHeight}
                  containerWidth={rightContainerWidth}
                  scrollableContent={false}
                  mode={props.mode}
                  aws={props.aws}
                  aws_bucket={props.aws_bucket}
                  data={{
                    ...props.data,
                    showPlaceholder: showPlaceholder,
                    cards: storyCards,
                    cardType: sizeCard,
                    basePath: props.data.basePath
                  }}
                ></StoriesCards2031>
              </div>
            </ScrollableFixContainer2103>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EugeneStories;
